import React, { memo } from 'react';
import styles from '../steps.module.scss';

const Step1 = () => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <p>テクニカル指標が設定した条件を満たしたときに作成される、自動売買注文の新規取引の方向を選びます。</p>
        <p>
          買：上昇トレンドを予想して、買いの自動売買注文を作成します
          <br />
          売：下落トレンドを予想して、売りの自動売買注文を作成します
        </p>
      </div>
    </div>
  );
};

export default memo(Step1);
