import {
  SET_TUTORIAL_CARD_DATA,
  REMOVE_TUTORIAL_CARD_DATA,
  SET_TUTORIAL_TABLES_DATA,
  REMOVE_TUTORIAL_TABLES_DATA,
  SET_PROFIT_LOSS_CHART_DATA,
  REMOVE_PROFIT_LOSS_CHART_DATA,
  SET_TUTORIAL_IS_READY_AUTO_SELECT,
} from '../actionConstants/tutorialConstants';

export const initialState = {
  cardData: {},
  orderData: [],
  positionsData: [],
  executionData: [],
  profitLossChartData: [],
  isReadyProps: {
    autoSelect: false,
  },
};

// todo: probably merge with error reducer when start refactoring
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_TUTORIAL_CARD_DATA: {
      return {
        ...state,
        cardData: payload.cardData,
      };
    }
    case REMOVE_TUTORIAL_CARD_DATA: {
      return {
        ...state,
        cardData: {},
      };
    }
    case SET_TUTORIAL_TABLES_DATA: {
      const { orderData, positionsData, executionData } = payload;
      return {
        ...state,
        orderData,
        positionsData,
        executionData,
      };
    }
    case REMOVE_TUTORIAL_TABLES_DATA: {
      return {
        ...state,
        orderData: [],
        positionsData: [],
        executionData: [],
      };
    }
    case SET_PROFIT_LOSS_CHART_DATA: {
      const { profitLossChartData } = payload;

      return {
        ...state,
        profitLossChartData,
      };
    }
    case REMOVE_PROFIT_LOSS_CHART_DATA: {
      return {
        ...state,
        profitLossChartData: [],
      };
    }
    case SET_TUTORIAL_IS_READY_AUTO_SELECT: {
      const { isReadyAutoSelect = false } = payload;
      const { isReadyProps } = state;
      return {
        ...state,
        isReadyProps: {
          ...isReadyProps,
          autoSelect: isReadyAutoSelect,
        },
      };
    }
    default: {
      return state;
    }
  }
};
