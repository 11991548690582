import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CustomTooltip from '../CustomTooltip';
import styles from './infoTooltip.module.scss';

export const InfoTooltip = memo(({ message, placement, className }) => (
  <CustomTooltip message={message} placement={placement} className={classNames(styles.container, className)}>
    <i className={classNames('material-icons-outlined', styles.icon)}>info</i>
  </CustomTooltip>
));

InfoTooltip.propTypes = {
  message: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom']),
  className: PropTypes.string,
};

InfoTooltip.defaultProps = {
  placement: undefined,
  className: undefined,
};
