import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useOptionalKeyPressHandler } from '../../../../../../services/hooks';
import styles from './link.module.scss';

const Link = ({ href, target, onPress, children, isInline, tabIndex }) => {
  const handleEnter = useOptionalKeyPressHandler(onPress);
  const icon = <i className={classNames('material-icons', styles.icon)}>open_in_new</i>;
  const className = classNames(styles.wrapper, styles.link, { [styles.inline]: isInline });

  if (onPress)
    return (
      <span role="button" tabIndex={tabIndex} onClick={onPress} onKeyPress={handleEnter} className={className}>
        {icon}
        {children}
      </span>
    );

  return (
    <a href={href} target={target} tabIndex={tabIndex} className={className}>
      {icon}
      {children}
    </a>
  );
};

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  isInline: PropTypes.bool,
  onPress: PropTypes.func,
  tabIndex: PropTypes.number,
};

Link.defaultProps = {
  isInline: false,
  href: null,
  onPress: null,
  tabIndex: null,
};

export default Link;
