import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import { Modal } from '../../../../components';
import styles from './strategyCommentary.module.scss';

export const StrategyCommentary = memo(({ visible, onClose, logic }) => {
  const { title, image, description } = logic || {};
  const logicImage = useMemo(() => {
    if (image == null) {
      return null;
    }
    try {
      // eslint-disable-next-line
      return require(`../../../../assets/logic/${image}`);
    } catch {
      return null;
    }
  }, [image]);

  const modalSize = logicImage == null ? MODAL_SIZES.WIDTH_640 : MODAL_SIZES.WIDTH_1024;
  return (
    <Modal isOpen={visible} closeModal={onClose} title={title} size={modalSize}>
      <div className={styles.container}>
        <div className={styles.content}>
          {logicImage && <img src={logicImage} alt="" className={styles.image} />}
          <div className={styles.description}>
            <span style={{ whiteSpace: 'pre-line' }}>{description?.replace(/<br ?\/?>/g, '\n')}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
});

StrategyCommentary.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  logic: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    description: PropTypes.string,
  }),
};

StrategyCommentary.defaultProps = {
  logic: undefined,
};
