import { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'shared-modules/redux/actions/routerActions';
import { useIsMarginZeroByServiceId, useSummaryInfoByServiceId } from 'shared-modules/services/hooks';
import { TOOLTIP_MESSAGE } from 'shared-modules/constants';
import { useAccountInfo, useServiceName } from 'shared-modules/hooks';
import { ACCOUNT_INFORMATION, TRADE_PERFORMANCE, TAB_DETAIL, TAB_PL_TREND } from 'shared-modules/constants/portfolio';
import { changeServiceIdRequest } from 'shared-modules/redux/actions';
import { mockSummaryInfoHasApGroup, mockSummaryInfoNoApGroup } from '../../../../constants/tutorial/otherMockData';
import InfoTooltip from '../InfoTooltip';
import { BalanceMeter, Button, Tag } from '../../../../components';
import { ColoredPriceText, MarginRateText, MarginText } from '../../../../components/Texts';
import { CASH } from '../../../../constants';
import { ServiceDisabledInfo } from '../../../common';
import styles from './accountSummaryCard.module.scss';

export const AccountSummaryCard = memo(({ serviceId, height, width, onClick, hasApGroup }) => {
  const dispatch = useDispatch();
  const { isNotAvailable: isDisabled } = useAccountInfo()[serviceId];

  const actualSummaryInfo = useSummaryInfoByServiceId(serviceId);

  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);

  const mockSummaryInfo = hasApGroup ? mockSummaryInfoHasApGroup[serviceId] : mockSummaryInfoNoApGroup[serviceId];
  // tutorialモード時にmockを使用する
  const summaryInfo = tutorialMode ? mockSummaryInfo : actualSummaryInfo;

  const {
    effectiveMargin,
    effectiveMarginRate,
    positionRequiredMargin,
    orderingRequiredMargin,
    receivedMargin,
    unsettledPl,
    orderableMargin,
  } = summaryInfo;

  const serviceName = useServiceName(serviceId);

  const accountSummaryInfo = (
    <>
      <div className={styles.main}>
        <div className={styles.leftAlignRow}>
          <div className={styles.label}>証拠金預託額</div>
          <InfoTooltip content={TOOLTIP_MESSAGE[`RECEIVED_MARGIN_${serviceName}`]} />
        </div>
        <div className={styles.rightAlignRow}>
          <MarginText className={styles.margin} symbolClassName={styles.symbol} value={receivedMargin} />
        </div>
        <div className={styles.leftAlignRow}>
          <div className={styles.label}>有効証拠金</div>
          <InfoTooltip content={TOOLTIP_MESSAGE[`EFFECTIVE_MARGIN_${serviceName}`]} />
        </div>
        <div className={styles.rightAlignRow}>
          <MarginText className={styles.margin} symbolClassName={styles.symbol} value={effectiveMargin} />
        </div>
        <div className={styles.leftAlignRow}>
          <div className={styles.label}>有効比率</div>
          <InfoTooltip content={TOOLTIP_MESSAGE.EFFECTIVE_MARGIN_RATE} />
        </div>
        <div className={styles.rightAlignRow}>
          <MarginRateText className={styles.margin} symbolClassName={styles.symbol} value={effectiveMarginRate} />
        </div>
      </div>
      <div className={styles.sub}>
        <div className={styles.leftAlignRowSub}>
          <div className={styles.labelSub}>評価損益</div>
          <InfoTooltip content={TOOLTIP_MESSAGE.UNSETTLED_PL} />
        </div>
        <div className={styles.rightAlignRowSub}>
          <ColoredPriceText className={styles.priceSub} symbolClassName={styles.symbolSub} value={unsettledPl} />
        </div>
        <div className={styles.leftAlignRowSub}>
          <div className={styles.labelSub}>必要証拠金</div>
          <InfoTooltip content={TOOLTIP_MESSAGE.POSITION_REQUIRED_MARGIN} />
        </div>
        <div className={styles.rightAlignRowSub}>
          <MarginText className={styles.marginSub} symbolClassName={styles.symbolSub} value={positionRequiredMargin} />
        </div>
        <div className={styles.leftAlignRowSub}>
          <div className={styles.labelSub}>発注可能額</div>
          <InfoTooltip content={TOOLTIP_MESSAGE.ORDERABLE_MARGIN} />
        </div>
        <div className={styles.rightAlignRowSub}>
          <MarginText className={styles.marginSub} symbolClassName={styles.symbolSub} value={orderableMargin} />
        </div>
        <div className={styles.leftAlignRowSub}>
          <div className={styles.labelSub}>発注証拠金</div>
          <InfoTooltip content={TOOLTIP_MESSAGE.ORDERING_REQUIRED_MARGIN} />
        </div>
        <div className={styles.rightAlignRowSub}>
          <MarginText className={styles.marginSub} symbolClassName={styles.symbolSub} value={orderingRequiredMargin} />
        </div>
      </div>
    </>
  );
  const isMarginZero = useIsMarginZeroByServiceId(serviceId);

  const handleClick = useCallback(() => {
    dispatch(push(`/${CASH}`));
    dispatch(changeServiceIdRequest({ serviceId }));
  }, [dispatch, serviceId]);

  return (
    <div style={{ height, width }} className={styles.container} aria-hidden="true">
      <div className={styles.tag}>
        <Tag text={serviceId} />
      </div>
      <div className={styles.informationArea}>
        <div className={styles.columns}>
          <div className={styles.meter}>
            <BalanceMeter
              meterData={summaryInfo}
              isDisabled={isDisabled}
              isMarginZero={tutorialMode ? false : isMarginZero && !isDisabled}
              onClick={handleClick}
            />
          </div>
          <ServiceDisabledInfo serviceId={serviceId}>{accountSummaryInfo}</ServiceDisabledInfo>
        </div>
      </div>
      {!isDisabled && (
        <div className={styles.buttonArea}>
          <Button
            width={260}
            secondary
            className={styles.button}
            onClick={() => onClick(TAB_DETAIL)}
            disabled={isDisabled}
          >
            <i className="material-icons-outlined">wallet</i>
            {ACCOUNT_INFORMATION}
          </Button>
          <div className={styles.badgeContainer}>
            <Button
              width={260}
              secondary
              className={styles.button}
              onClick={() => onClick(TAB_PL_TREND)}
              disabled={isDisabled}
            >
              <i className="material-icons-outlined">calendar_today</i>
              {TRADE_PERFORMANCE}
            </Button>
            <div className={styles.badge}>NEW</div>
          </div>
        </div>
      )}
    </div>
  );
});

AccountSummaryCard.propTypes = {
  serviceId: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  hasApGroup: PropTypes.bool,
};

AccountSummaryCard.defaultProps = {
  hasApGroup: false,
};
