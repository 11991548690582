import { memo, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useSelectedShareItem, useSelectedStrategy, useSelectedStrategyInfoList } from 'shared-modules/hooks';
import { ItemButtons } from '../../../../components';
import { tutorialTradingView } from '../../../../constants/tutorial/classNames';
import { SelectionChart } from './SelectionChart';
import { LabChart } from './LabChart';
import styles from './priceChart.module.scss';

export const PriceChart = memo(() => {
  const selectedShareItem = useSelectedShareItem();
  const [strategyInfoList] = useSelectedStrategyInfoList();
  const [strategy, setStrategy] = useSelectedStrategy();

  const activeId = useMemo(() => {
    if (strategy == null) {
      return null;
    }
    const { strategyId, instrumentId } = strategy;
    return [strategyId, instrumentId];
  }, [strategy]);

  const options = useMemo(() => {
    if (_.isEmpty(strategyInfoList)) {
      return [];
    }
    return strategyInfoList.map(({ strategyId, instrumentId, name }) => ({
      id: [strategyId, instrumentId],
      value: name,
    }));
  }, [strategyInfoList]);

  const handleChange = useCallback(
    ([strategyId, instrumentId]) => {
      const found = strategyInfoList?.find((e) => e.strategyId === strategyId && e.instrumentId === instrumentId);
      if (found) {
        const { serviceId } = found;
        setStrategy({ serviceId, instrumentId, strategyId });
      }
    },
    [setStrategy, strategyInfoList],
  );

  useEffect(() => {
    if (_.isEmpty(strategyInfoList)) {
      setStrategy(null);
    } else {
      const [{ strategyId, instrumentId, serviceId }] = strategyInfoList;
      setStrategy({ serviceId, instrumentId, strategyId });
    }
  }, [setStrategy, strategyInfoList]);

  const chart = useMemo(() => {
    if (selectedShareItem) {
      const { strategyId } = strategy || {};
      const found = strategyInfoList?.find((strategyInfo) => strategyInfo.strategyId === strategyId);
      return <LabChart strategy={found} />;
    }
    return <SelectionChart />;
  }, [selectedShareItem, strategy, strategyInfoList]);

  return (
    <div className={styles.chartArea}>
      <div className={styles.itemButtonsContainer}>
        <ItemButtons options={options} activeId={activeId} onChange={handleChange} />
      </div>
      <div className={styles.borderWrapper}>
        <div className={styles.border} />
      </div>
      <div className={classNames(styles.chartContainer, tutorialTradingView)}>{chart}</div>
    </div>
  );
});
