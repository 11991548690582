import { memo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { PRICE_1_NAME, PRICE_2_NAME, BUY_SELL_MAIN, CHART_RESOLUTION_MAIN } from 'shared-modules/constants';
import { getColorBySide } from 'shared-modules/services/builder';
import { useGetLabServiceId } from 'shared-modules/services/hooks/labMainViewLogic';
import CommonChart from '../../../../../../components/CommonChart';
import { useErrorText } from '../../../../../../hooks';
import styles from './labChart.module.scss';

const LabChart = ({
  strategy,
  disableLeftToolbar,
  disableHeaderWidget,
  isMobile,
  withoutIndicators,
  isUseLabServiceId,
  chartSide,
}) => {
  let serviceId = useGetLabServiceId(isUseLabServiceId);
  if (!isUseLabServiceId && strategy?.serviceId) {
    serviceId = strategy.serviceId;
  }
  const { instrumentId, itemList } = strategy ?? {};
  const errorText = useErrorText(serviceId);

  const removeAllLines = (linesRef) => {
    Object.values(linesRef.current).forEach((line) => line.remove());
    linesRef.current = {}; // eslint-disable-line
  };

  const orderLinesRef = useRef({});

  const simulationOrderLineDisplay = useCallback(
    (tvRefCurrent, finishedOnChartReady, changingSymbol) => {
      if (finishedOnChartReady && !changingSymbol) {
        removeAllLines(orderLinesRef);

        itemList.forEach((i) => {
          if (i?.entryPrice1) {
            orderLinesRef.current[`${i.itemId}-${PRICE_1_NAME}`] = tvRefCurrent
              .activeChart()
              .createOrderLine()
              .setText('')
              .setQuantity('')
              .setLineColor(getColorBySide(Number(i.side)))
              .setPrice(i.entryPrice1);
          }

          if (i?.entryPrice2) {
            orderLinesRef.current[`${i.itemId}-${PRICE_2_NAME}`] = tvRefCurrent
              .activeChart()
              .createOrderLine()
              .setText('')
              .setQuantity('')
              .setLineColor(getColorBySide(Number(i.side)))
              .setPrice(i.entryPrice2);
          }
        });
      }
    },
    [itemList],
  );

  let chartContent = (
    <div className={styles.wrapper}>
      {instrumentId && (
        <CommonChart
          key={serviceId}
          serviceId={serviceId}
          selectedInstrumentId={instrumentId}
          renderChartObject={simulationOrderLineDisplay}
          disableHeaderWidget={disableHeaderWidget}
          disableLeftToolbar={disableLeftToolbar}
          isMobile={isMobile}
          withoutIndicators={withoutIndicators}
          selectedSide={isMobile ? chartSide : BUY_SELL_MAIN.BUY.CHART_ID}
          resolution={CHART_RESOLUTION_MAIN.DAYS_1.TV_ID}
        />
      )}
    </div>
  );

  if (!isMobile) {
    if (errorText) {
      chartContent = errorText;
    }
  }

  return chartContent;
};

LabChart.propTypes = {
  strategy: PropTypes.shape({
    instrumentId: PropTypes.string.isRequired,
    serviceId: PropTypes.string,
    itemList: PropTypes.arrayOf(
      PropTypes.shape({
        itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        side: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  }).isRequired,
  isMobile: PropTypes.bool,
  disableLeftToolbar: PropTypes.bool,
  disableHeaderWidget: PropTypes.bool,
  withoutIndicators: PropTypes.bool,
  isUseLabServiceId: PropTypes.bool,
  chartSide: PropTypes.number,
};

LabChart.defaultProps = {
  isMobile: false,
  disableLeftToolbar: false,
  disableHeaderWidget: false,
  withoutIndicators: false,
  isUseLabServiceId: false,
  chartSide: BUY_SELL_MAIN.BUY.CHART_ID,
};

export default memo(LabChart);
