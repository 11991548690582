/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveApData } from '../../redux/multiEdit';
import {
  AP_GROUP_SOURCES,
  BUY_SELL_MAIN,
  COUNT_INPUT_NAME,
  COUNTER_BUY_MAX,
  COUNTER_BUY_MIN,
  COUNTER_FIXED,
  COUNTER_NAME,
  COUNTER_PRECISION,
  COUNTER_SELL_MAX,
  COUNTER_SELL_MIN,
  FOLLOW_BUY_MAX,
  FOLLOW_BUY_MIN,
  FOLLOW_NAME,
  FOLLOW_PRECISION,
  FOLLOW_SELL_MAX,
  FOLLOW_SELL_MIN,
  FX,
  LOSS_CUT_WIDTH_MAX,
  LOSS_CUT_WIDTH_MIN,
  LOSS_CUT_WIDTH_NAME,
  LOSS_CUT_WIDTH_PRECISION,
  MAX_QUANTITY_AP_ORDER,
  PROFIT_MARGIN_MAX,
  PROFIT_MARGIN_MIN,
  PROFIT_MARGIN_NAME,
  PROFIT_MARGIN_PRECISION,
  TRADE_METHODS,
  VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_BUY,
  VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_SELL,
  VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_BUY,
  VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_SELL,
  VALIDATION_ERROR_AP_ORDER_LOST_CUT_PIPS,
  VALIDATION_ERROR_AP_ORDER_PROFIT_MARGIN_PIPS,
  VALIDATION_ERROR_AP_RULE_66,
} from '../../constants';
import {
  ORDER_DETAILS_EDIT_HELPERS as editHelpers,
  getAPQuantityMin,
  getAPQuantityStep,
  getAPValidateQuantityErrorMessage,
  getPortfolioChangeLogicStepByServiceId,
  getPortfolioPriceStepByServiceId,
  getServiceQuantityUnit,
  roundExactlyOnPrecisionMatching,
} from '../index';
import { useInstrumentSettings } from './index';
import { nanToBlank, rule66Types } from './multiEditLogic';
import { getPipsLabelWithParentheses, getValidationCurrencyUnitByServiceId } from '../../utils';
import { useServiceId } from '../../hooks';

const useDynamicMultiEditInfo = () => {
  const dispatch = useDispatch();

  // local state
  const [isQuantity, setIsQuantity] = useState(false);
  const [isProfit, setIsProfit] = useState(false);
  const [isLosscut, setIsLosscut] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [isCounter, setIsCounter] = useState(false);

  const [quantityValue, setQuantityValue] = useState('');
  const [profitMarginValue, setProfitMarginValue] = useState('');
  const [lossCutWidthValue, setLossCutWidthValue] = useState('');
  const [followValue, setFollowValue] = useState('');
  const [counterValue, setCounterValue] = useState('');
  const [isCounterFixed, setIsCounterFixed] = useState(false);
  const [errorsArray, changeErrorsArray] = useState([]);

  // selectors
  const { instrumentId, sourceType: sourceTypeRaw } = useSelector((state) => state.portfolio.selectedApGroupData);
  const { existingCheckbox } = useSelector((state) => state.multiEdit);
  const { checkKey } = useSelector((state) => state.multiEdit.selectedTableRows[0]);
  const submitIsLoading = useSelector((state) => state.multiEdit.isLoading);
  const serviceId = useServiceId(instrumentId);
  const isFX = serviceId === FX;

  // calculated memo variables
  const isSellSide = useMemo(
    () => (checkKey === 'side' ? Number(existingCheckbox.side) === BUY_SELL_MAIN.SELL.ID : false),
    [checkKey, existingCheckbox],
  );

  const sourceType = useMemo(
    () => (sourceTypeRaw === AP_GROUP_SOURCES.MONEY_HATCH.KEY ? TRADE_METHODS.MH_AP.ID : TRADE_METHODS.AP.ID),
    [sourceTypeRaw],
  );

  const isCounterFixedDisabled = useMemo(() => {
    if (!isCounter || counterValue === '') return true;

    if (checkKey !== 'isInactiveOrFirstCloseOrder') return false;

    return existingCheckbox.isInactiveOrFirstCloseOrder;
  }, [checkKey, existingCheckbox, isCounter, counterValue]);

  useEffect(() => {
    if (isCounterFixedDisabled) setIsCounterFixed(false);
  }, [isCounterFixedDisabled]);

  const {
    quantityPrecision,
    pricePrecision,
    buyQuantityMin,
    buyQuantityMax,
    sellQuantityMin,
    sellQuantityMax,
    buyPriceMin,
    buyPriceMax,
    sellPriceMin,
    sellPriceMax,
  } = useInstrumentSettings(instrumentId, sourceType);

  const priceStep = useMemo(() => {
    return getPortfolioPriceStepByServiceId(serviceId, instrumentId, pricePrecision);
  }, [serviceId, instrumentId, pricePrecision]);

  const counterPipsIsSelected = !isCounterFixed;
  const apQuantityStep = useMemo(() => {
    if (isFX) return getAPQuantityStep(instrumentId);
    return quantityPrecision;
  }, [instrumentId, isFX, quantityPrecision]);

  const profitMarginStep = useMemo(() => {
    return getPortfolioChangeLogicStepByServiceId(serviceId, pricePrecision);
  }, [pricePrecision, serviceId]);

  const lossCutWidthStep = useMemo(() => {
    return getPortfolioChangeLogicStepByServiceId(serviceId, pricePrecision);
  }, [pricePrecision, serviceId]);

  const followStep = useMemo(() => {
    return getPortfolioChangeLogicStepByServiceId(serviceId, pricePrecision);
  }, [pricePrecision, serviceId]);

  const currencyUnit = getValidationCurrencyUnitByServiceId(instrumentId, serviceId);
  const quantityUnit = getServiceQuantityUnit(serviceId);

  // useState setter effects
  useEffect(() => {
    changeErrorsArray((prevVal) => prevVal.filter((item) => item.inputName !== COUNTER_NAME));
  }, [counterPipsIsSelected]);

  const validateQuantity = useCallback(
    (newValue) => {
      let errorMessage;
      let minValue;
      let maxValue;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        errorMessage = getAPValidateQuantityErrorMessage(instrumentId);
        minValue = getAPQuantityMin(instrumentId);
        maxValue = MAX_QUANTITY_AP_ORDER;
      } else {
        minValue = isSellSide ? sellQuantityMin : buyQuantityMin;
        maxValue = isSellSide ? sellQuantityMax : buyQuantityMax;

        if (maxValue === 0) {
          errorMessage = '現在、この銘柄の新規売り注文は受付できません。';
        } else if (minValue === maxValue) {
          errorMessage = `${quantityPrecision}${quantityUnit}でご設定ください。`;
        } else {
          // eslint-disable-next-line max-len
          errorMessage = `${minValue}${quantityUnit}以上${maxValue}${quantityUnit}以下、${quantityPrecision}${quantityUnit}単位でご設定ください。`;
        }
      }

      return editHelpers.createValidateFunction({
        inputName: COUNT_INPUT_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision: quantityPrecision,
        changeErrorFunction: changeErrorsArray,
      })(newValue);
    },
    [
      isFX,
      quantityUnit,
      quantityPrecision,
      instrumentId,
      isSellSide,
      sellQuantityMin,
      buyQuantityMin,
      sellQuantityMax,
      buyQuantityMax,
    ],
  );

  const validateProfitMargin = useCallback(
    (rawValue) => {
      const newValue = nanToBlank(rawValue);
      let minValue;
      let maxValue;
      let valuePrecision;

      let errorMessage;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        minValue = PROFIT_MARGIN_MIN;
        maxValue = PROFIT_MARGIN_MAX;
        errorMessage = VALIDATION_ERROR_AP_ORDER_PROFIT_MARGIN_PIPS;
        valuePrecision = PROFIT_MARGIN_PRECISION;
      } else {
        minValue = isSellSide ? sellPriceMin : buyPriceMin;
        maxValue = isSellSide ? sellPriceMax : buyPriceMax;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
        valuePrecision = pricePrecision;
      }

      return editHelpers.createValidateFunction({
        inputName: PROFIT_MARGIN_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
      })(newValue);
    },
    [isSellSide, pricePrecision, isFX, sellPriceMin, buyPriceMin, sellPriceMax, buyPriceMax, currencyUnit],
  );

  const validateLossCutWidth = useCallback(
    (rawValue) => {
      let minValue;
      let maxValue;
      let errorMessage;
      let valuePrecision;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        minValue = LOSS_CUT_WIDTH_MIN;
        maxValue = LOSS_CUT_WIDTH_MAX;
        errorMessage = VALIDATION_ERROR_AP_ORDER_LOST_CUT_PIPS;
        valuePrecision = LOSS_CUT_WIDTH_PRECISION;
      } else {
        minValue = -(isSellSide ? sellPriceMax : buyPriceMax);
        maxValue = -(isSellSide ? sellPriceMin : buyPriceMin);
        valuePrecision = pricePrecision;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      const newValue = nanToBlank(rawValue);

      return editHelpers.createValidateFunction({
        inputName: LOSS_CUT_WIDTH_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        skippIfEmptyString: true,
      })(newValue);
    },
    [isFX, isSellSide, pricePrecision, sellPriceMax, buyPriceMax, sellPriceMin, buyPriceMin, currencyUnit],
  );

  const rule66Validation = useCallback(
    ({ value, type }) => {
      if (type === rule66Types.follow) {
        return value !== '' || counterValue !== '';
      }
      if (type === rule66Types.counter) {
        return value !== '' || followValue !== '';
      }
      return false;
    },
    [followValue, counterValue],
  );

  const validateFollow = useCallback(
    (newValue) => {
      let minValue;
      let maxValue;
      let errorMessage;
      let valuePrecision;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        minValue = isSellSide ? FOLLOW_SELL_MIN : FOLLOW_BUY_MIN;
        maxValue = isSellSide ? FOLLOW_SELL_MAX : FOLLOW_BUY_MAX;
        errorMessage = isSellSide
          ? VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_SELL
          : VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_BUY;
        valuePrecision = FOLLOW_PRECISION;
      } else {
        minValue = isSellSide ? -sellPriceMax : buyPriceMin;
        maxValue = isSellSide ? -sellPriceMin : buyPriceMax;
        valuePrecision = pricePrecision;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      const additionalCheck = rule66Validation({ value: newValue, type: rule66Types.follow });

      return editHelpers.createValidateFunction({
        inputName: FOLLOW_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        skippIfEmptyString: true,
        additionalCheck,
        additionalCheckErrorMessage: VALIDATION_ERROR_AP_RULE_66,
        additionalCheckFieldName: COUNTER_NAME,
      })(newValue);
    },
    [
      isFX,
      rule66Validation,
      isSellSide,
      sellPriceMax,
      buyPriceMin,
      sellPriceMin,
      buyPriceMax,
      pricePrecision,
      currencyUnit,
    ],
  );

  const validateCounter = useCallback(
    (newValue, isCounterSelected) => {
      const isCounterLocal = isCounterSelected ?? counterPipsIsSelected;
      let minValue;
      let maxValue;
      let valuePrecision;
      let errorMessage;
      let validSignResult;
      let isValidSignResult;
      let errorMessageParts;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        if (isCounterLocal) {
          minValue = isSellSide ? COUNTER_SELL_MIN : COUNTER_BUY_MIN;
          maxValue = isSellSide ? COUNTER_SELL_MAX : COUNTER_BUY_MAX;
          errorMessage = isSellSide
            ? VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_SELL
            : VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_BUY;
          valuePrecision = COUNTER_PRECISION;
        } else {
          validSignResult = isSellSide ? 1 : -1;
          errorMessageParts = isSellSide ? ['売', '正'] : ['買', '負'];
          isValidSignResult = Math.sign(newValue) === validSignResult;

          if (!isValidSignResult) {
            errorMessage = `${errorMessageParts[0]}の場合は、${errorMessageParts[1]}の値を入力してください。`;
          }
        }
      } else {
        valuePrecision = pricePrecision;
        if (isCounterLocal) {
          minValue = isSellSide ? sellPriceMin : -buyPriceMax;
          maxValue = isSellSide ? sellPriceMax : -buyPriceMin;
          // eslint-disable-next-line max-len
          errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`;
        } else {
          validSignResult = isSellSide ? 1 : -1;
          errorMessageParts = isSellSide ? ['売', '正'] : ['買', '負'];
          isValidSignResult = Math.sign(newValue) === validSignResult;

          if (!isValidSignResult) {
            errorMessage = `${errorMessageParts[0]}の場合は、${errorMessageParts[1]}の値を入力してください。`;
          }
        }
      }

      const additionalCheck = rule66Validation({ value: newValue, type: rule66Types.counter });

      return editHelpers.createValidateFunction({
        inputName: COUNTER_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        skippIfEmptyString: true,
        additionalCheck,
        additionalCheckErrorMessage: VALIDATION_ERROR_AP_RULE_66,
        additionalCheckFieldName: FOLLOW_NAME,
      })(newValue);
    },
    [
      counterPipsIsSelected,
      isFX,
      rule66Validation,
      isSellSide,
      pricePrecision,
      currencyUnit,
      sellPriceMin,
      buyPriceMax,
      sellPriceMax,
      buyPriceMin,
    ],
  );

  // TODO CFD FXかそうでないかの判定で問題ないか要確認
  // set handler
  const handleQuantityValue = useCallback(
    (val) => {
      const newValue = isFX ? val : roundExactlyOnPrecisionMatching(val, Math.round(1 / quantityPrecision));
      setQuantityValue(newValue);
    },
    [quantityPrecision, isFX],
  );

  const enableErrorsArray = useMemo(() => {
    const disabledErrorKey = [];

    if (!isQuantity) disabledErrorKey.push(COUNT_INPUT_NAME);
    if (!isProfit) disabledErrorKey.push(PROFIT_MARGIN_NAME);
    if (!isLosscut) disabledErrorKey.push(LOSS_CUT_WIDTH_NAME);
    if (!isFollow) disabledErrorKey.push(FOLLOW_NAME);
    if (!isCounter) disabledErrorKey.push(COUNTER_NAME);

    const enableErrors = errorsArray.filter((error) => !disabledErrorKey.includes(error.inputName));

    return enableErrors;
  }, [errorsArray, isQuantity, isProfit, isLosscut, isFollow, isCounter]);

  const submitHandler = useCallback(
    (callback) => () => {
      const changeApData = {
        quantity: null,
        tp: null,
        sl: null,
        follow: null,
        counter: null,
        counterFixed: false,
      };

      if (isQuantity) changeApData.quantity = editHelpers.convertToNumberOrEmptyString(quantityValue);
      if (isProfit) changeApData.tp = editHelpers.convertToNumberOrEmptyString(profitMarginValue);
      if (isLosscut) changeApData.sl = editHelpers.convertToNumberOrEmptyString(lossCutWidthValue);
      if (isFollow) changeApData.follow = editHelpers.convertToNumberOrEmptyString(followValue);
      if (isCounter) changeApData.counter = editHelpers.convertToNumberOrEmptyString(counterValue);

      changeApData.counterFixed = isCounterFixed;

      dispatch(saveApData({ changeApData }));
      if (callback) callback();
    },
    [
      dispatch,
      quantityValue,
      profitMarginValue,
      lossCutWidthValue,
      followValue,
      counterValue,
      isCounterFixed,
      isQuantity,
      isProfit,
      isLosscut,
      isFollow,
      isCounter,
    ],
  );

  const counterStep = useMemo(() => {
    return getPortfolioChangeLogicStepByServiceId(serviceId, pricePrecision);
  }, [pricePrecision, serviceId]);

  const isExistCounterValueErrors = errorsArray.some((el) => el.inputName === 'counter');

  const requiredFieldEmpty =
    (isQuantity && !quantityValue) ||
    (isProfit && !profitMarginValue) ||
    (isFollow && !followValue && isCounter && !counterValue);
  const isSubmitDisabled = useMemo(() => {
    return (
      (!isQuantity && !isProfit && !isLosscut && !isFollow && !isCounter) ||
      Boolean(enableErrorsArray.length) ||
      requiredFieldEmpty
    );
  }, [enableErrorsArray, isQuantity, isProfit, isLosscut, isFollow, isCounter, requiredFieldEmpty]);

  const pipsLabelWithParentheses = getPipsLabelWithParentheses(serviceId, instrumentId);
  return {
    isQuantity: {
      get: isQuantity,
      set: setIsQuantity,
    },
    isProfit: {
      get: isProfit,
      set: setIsProfit,
    },
    isLosscut: {
      get: isLosscut,
      set: setIsLosscut,
    },
    isFollow: {
      get: isFollow,
      set: setIsFollow,
    },
    isCounter: {
      get: isCounter,
      set: setIsCounter,
    },
    quantity: {
      get: quantityValue,
      set: handleQuantityValue,
      label: `数量(${quantityUnit})`,
      name: COUNT_INPUT_NAME,
      isDisabled: !isQuantity,
      step: apQuantityStep,
      validate: validateQuantity,
    },
    profit: {
      get: profitMarginValue,
      set: setProfitMarginValue,
      label: `利確幅${pipsLabelWithParentheses}`,
      step: profitMarginStep,
      name: PROFIT_MARGIN_NAME,
      isDisabled: !isProfit,
      validate: validateProfitMargin,
    },
    losscut: {
      get: lossCutWidthValue,
      set: setLossCutWidthValue,
      label: `損切幅${pipsLabelWithParentheses}`,
      step: lossCutWidthStep,
      name: LOSS_CUT_WIDTH_NAME,
      isDisabled: !isLosscut,
      validate: validateLossCutWidth,
      min: null,
    },
    follow: {
      get: followValue,
      set: setFollowValue,
      label: `フォロー値${pipsLabelWithParentheses}`,
      step: followStep,
      name: FOLLOW_NAME,
      isDisabled: !isFollow,
      validate: validateFollow,
      min: null,
    },
    counter: {
      get: counterValue,
      set: setCounterValue,
      label: `カウンター値${pipsLabelWithParentheses}`,
      withPips: counterPipsIsSelected,
      step: counterStep,
      name: COUNTER_NAME,
      isDisabled: !isCounter,
      validate: validateCounter,
      min: null,
    },
    counterFixed: {
      get: isCounterFixed,
      set: setIsCounterFixed,
      label: `カウンター固定`,
      name: 'isCounterFixed',
      isDisabled: isCounterFixedDisabled || isExistCounterValueErrors,
    },
    submit: {
      label: '確認する',
      handler: submitHandler,
      isLoading: submitIsLoading,
      isDisabled: isSubmitDisabled,
    },
    priceStep,
    errorsArray: enableErrorsArray,
  };
};

export default useDynamicMultiEditInfo;

export const populateApData = (apData) => {
  const inputQuantity = apData[COUNT_INPUT_NAME];
  const inputTp = apData[PROFIT_MARGIN_NAME];
  const inputSl = apData[LOSS_CUT_WIDTH_NAME];
  const inputFollow = apData[FOLLOW_NAME];
  const inputCounter = apData[COUNTER_NAME];
  const counterFixed = apData[COUNTER_FIXED];

  return { inputQuantity, inputTp, inputSl, inputFollow, inputCounter, counterFixed };
};
