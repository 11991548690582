import {
  CHANGE_SELECTED_INSTRUMENT_ID,
  CHANGE_SELECTED_SIDE,
  CHANGE_CHART_SIDE,
  CHANGE_CREATE_ORDER_VALUES,
  RESET_CREATE_ORDER_VALUES,
  CHANGE_CREATE_ORDER_VALIDATION_ERRORS,
  CREATE_ORDER_START_LOADING,
  CREATE_ORDER_STOP_LOADING,
  CHANGE_SELECTED_TABLE,
  EXECUTIONS_TABLE_START_LOADING,
  EXECUTIONS_TABLE_STOP_LOADING,
  GET_EXECUTIONS_TABLE_DATA_SUCCESS,
  CHANGE_EXECUTIONS_TABLE_META_INFO,
  ORDERS_TABLE_START_LOADING,
  ORDERS_TABLE_STOP_LOADING,
  GET_ORDERS_TABLE_DATA_SUCCESS,
  CHANGE_ORDERS_TABLE_META_INFO,
  GET_POSITIONS_START_LOADING,
  GET_POSITIONS_STOP_LOADING,
  CHANGE_POSITIONS_TABLE_META_INFO,
  CHANGE_SHARED_META_INFO,
  GET_NEWS_REQUEST_START_LOADING,
  GET_NEWS_REQUEST_STOP_LOADING,
  GET_NEWS_SUCCESS,
  GET_ORDER_INFO_SUCCESS,
  ORDER_INFO_REQUEST_START_LOADING,
  ORDER_INFO_REQUEST_STOP_LOADING,
  ORDER_CHANGE_START_LOADING,
  ORDER_CHANGE_STOP_LOADING,
  CREATE_CLOSE_ORDER_START_LOADING,
  CREATE_CLOSE_ORDER_STOP_LOADING,
  CHANGE_SELECTED_ORDER_TYPE,
  CREATE_MARKET_ORDER_REQUEST,
  UPDATE_CHANGE_ORDER_VALIDATION_ERRORS,
  UPDATE_CLOSE_ORDER_VALIDATION_ERRORS,
  RESET_CREATE_ORDERS_NON_COMMON_VALUES,
  RESET_CREATE_ORDER_ERRORS,
  RESET_CHANGE_ORDER_VALIDATION_ERRORS,
  CHANGE_POSITION_DETAILS_ORDER_LOGIC,
  SAVE_DELETING_POSITIONS,
  SAVE_SELECTED_TABLE_ROWS,
  CLOSE_FIFO_SIDE_POSITIONS_START_LOADING,
  CLOSE_FIFO_SIDE_POSITIONS_STOP_LOADING,
  RESET_EXECUTIONS_TABLE_META_INFO,
  RESET_POSITION_TABLE_META_INFO,
  RESET_ORDER_TABLE_META_INFO,
  CHANGE_SELECTED_INFO_TABLE,
  CHANGE_SELECTED_TRADE_METHOD,
  CHANGE_TRADE_METHOD_TYPE,
} from '../actionConstants/manualTradeConstants';
import { BUY_SELL_MAIN, ORDER_METHOD_MAIN, QUANTITY_STEP, FX, ETF, CFD, TRADE_METHODS } from '../../constants';
import {
  ORDER_TYPES,
  BUY_SELL_INPUTS,
  DATE_INPUTS,
  TIME_INPUTS,
  VALIDATED_INPUTS,
  DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_SELECT_SIDE,
  DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
  DEFAULT_POSITIONS_TABLE_SORT_BY,
  DEFAULT_POSITIONS_TABLE_SORT_DIR,
  DEFAULT_ORDERS_TABLE_SORT_BY,
  DEFAULT_ORDERS_TABLE_SORT_DIR,
  DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
  DEFAULT_TRADE_METHODS_TABLE_FILTER,
  DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
  DEFAULT_TABLE_ID,
  CHANGE_ORDER_PRICE_INPUT,
  CHANGE_ORDER_THEN_PRICE_INPUT,
  CHANGE_ORDER_OCO2_PRICE_INPUT,
  CHANGE_ORDER_THEN_OCO2_PRICE_INPUT,
  COUNT_INPUT,
  COMMON_VALIDATION_INPUTS,
  COMMON_INPUTS,
  CLOSE_PRICE_INPUT,
  CLOSE_LIMIT_PRICE_INPUT,
  CLOSE_STOP_PRICE_INPUT,
  CLOSE_DATE_INPUT,
  CLOSE_TIME_INPUT,
  CHANGE_DATE_INPUT,
  CHANGE_TIME_INPUT,
  CHANGE_SETTLEMENT_DATE_INPUT,
  CHANGE_SETTLEMENT_TIME_INPUT,
  ORDER_METHOD_INPUTS,
  CLOSE_ORDER_LOGIC_VALUES,
  DEFAULT_TABLE_INFO_ID,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
  KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_TRADE_METHODS_TABLE_FILTERS,
  KEY_FOR_DEFAULT_INSTRUMENT_ID_TABLE_FILTERS,
  KEY_FOR_DEFAULT_SELECT_TRADE_METHOD,
} from '../../constants/manualTrade';
import {
  getRoundedPlusOneHourCurrentTime,
  date30DaysBefore,
  getDefaultValuesFromLocalStorage,
  getDefaultValuesFromJsonOfLocalStorage,
  getRoundedPlusOneHourCurrentDate,
} from '../../services';
import { ALL_SERVICES } from '../../constants/core';

function getTradeMethodsFromLocalStorage(key, defaultValue) {
  const rawValue = getDefaultValuesFromLocalStorage({ key });
  try {
    return rawValue ? JSON.parse(rawValue) : defaultValue;
  } catch (error) {
    // if local storage have invalid data we would just use defaultValue
    if (error instanceof SyntaxError) return defaultValue;
    throw error;
  }
}

export const initialState = () => ({
  selectedTradeMethodType: TRADE_METHODS.MANUAL.ID,
  selectedOrderType: ORDER_TYPES.MARKET_ORDER.id,
  selectedInstrumentId: {
    [FX]: '',
    [ETF]: '',
    [CFD]: '',
  },
  selectedSide: Number(
    getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DEFAULT_SELECT_SIDE,
      defaultValue: BUY_SELL_MAIN.BUY.CHART_ID,
    }),
  ),
  chartSide: Number(
    getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DEFAULT_SELECT_SIDE,
      defaultValue: BUY_SELL_MAIN.BUY.CHART_ID,
    }),
  ),
  selectedTradeMethod: Number(
    getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DEFAULT_SELECT_TRADE_METHOD,
      defaultValue: TRADE_METHODS.MANUAL.ID,
    }),
  ),
  selectedTable: DEFAULT_TABLE_ID,
  selectedInfoTable: DEFAULT_TABLE_INFO_ID,
  executionsData: {
    [FX]: [],
    [ETF]: [],
    [CFD]: [],
  },
  executionsDataMetaInfo: {
    [FX]: {
      loading: true,
      pageNumber: 1,
      totalPages: 1,
      sortBy: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY[FX],
        defaultValue: DEFAULT_EXECUTIONS_TABLE_SORT_BY,
      }),
      sortDir: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR[FX],
        defaultValue: DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
      }),
      summarizedPl: null,
      summarizedSwap: null,
      summarizedComm: null,
      summarizedBuyQuantity: null,
      summarizedSellQuantity: null,
      fromDate: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'fromDate',
          defaultValue: date30DaysBefore(),
          localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[FX],
        });
        return typeof value !== 'object' ? new Date(value) : value;
      })(),
      toDate: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'toDate',
          defaultValue: new Date(),
          localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[FX],
        });
        return typeof value !== 'object' ? new Date(value) : value;
      })(),
      isClose: getDefaultValuesFromJsonOfLocalStorage({
        key: 'isClose',
        defaultValue: null,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[FX],
      }),
      side: getDefaultValuesFromJsonOfLocalStorage({
        key: 'side',
        defaultValue: null,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[FX],
      }),
      tradeMethods: getDefaultValuesFromJsonOfLocalStorage({
        key: 'tradeMethods',
        defaultValue: DEFAULT_TRADE_METHODS_TABLE_FILTER,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[FX],
      }),
      instrumentId: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'instrumentId',
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
          localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[FX],
        });
        return value === 'null' ? null : value;
      })(),
    },
    [ETF]: {
      loading: true,
      pageNumber: 1,
      totalPages: 1,
      sortBy: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY[ETF],
        defaultValue: DEFAULT_EXECUTIONS_TABLE_SORT_BY,
      }),
      sortDir: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR[ETF],
        defaultValue: DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
      }),
      summarizedPl: null,
      summarizedSwap: null,
      summarizedBuyQuantity: null,
      summarizedSellQuantity: null,
      fromDate: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'fromDate',
          defaultValue: date30DaysBefore(),
          localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[ETF],
        });
        return typeof value !== 'object' ? new Date(value) : value;
      })(),
      toDate: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'toDate',
          defaultValue: new Date(),
          localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[ETF],
        });
        return typeof value !== 'object' ? new Date(value) : value;
      })(),
      isClose: getDefaultValuesFromJsonOfLocalStorage({
        key: 'isClose',
        defaultValue: null,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[ETF],
      }),
      side: getDefaultValuesFromJsonOfLocalStorage({
        key: 'side',
        defaultValue: null,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[ETF],
      }),
      tradeMethods: getDefaultValuesFromJsonOfLocalStorage({
        key: 'tradeMethods',
        defaultValue: DEFAULT_TRADE_METHODS_TABLE_FILTER,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[ETF],
      }),
      instrumentId: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'instrumentId',
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
          localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[ETF],
        });
        return value === 'null' ? null : value;
      })(),
    },
    // TODO kazama150180 暫定で ETF のコピー
    [CFD]: {
      loading: true,
      pageNumber: 1,
      totalPages: 1,
      sortBy: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY[CFD],
        defaultValue: DEFAULT_EXECUTIONS_TABLE_SORT_BY,
      }),
      sortDir: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR[CFD],
        defaultValue: DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
      }),
      summarizedPl: null,
      summarizedSwap: null,
      summarizedBuyQuantity: null,
      summarizedSellQuantity: null,
      fromDate: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'fromDate',
          defaultValue: date30DaysBefore(),
          localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[CFD],
        });
        return typeof value !== 'object' ? new Date(value) : value;
      })(),
      toDate: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'toDate',
          defaultValue: new Date(),
          localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[CFD],
        });
        return typeof value !== 'object' ? new Date(value) : value;
      })(),
      isClose: getDefaultValuesFromJsonOfLocalStorage({
        key: 'isClose',
        defaultValue: null,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[CFD],
      }),
      side: getDefaultValuesFromJsonOfLocalStorage({
        key: 'side',
        defaultValue: null,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[CFD],
      }),
      tradeMethods: getDefaultValuesFromJsonOfLocalStorage({
        key: 'tradeMethods',
        defaultValue: DEFAULT_TRADE_METHODS_TABLE_FILTER,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[CFD],
      }),
      instrumentId: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'instrumentId',
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
          localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[CFD],
        });
        return value === 'null' ? null : value;
      })(),
    },
  },
  positionsLoadingInfo: {
    [FX]: {
      isSellPositionsDeleting: false,
      isBuyPositionsDeleting: false,
      isBothPositionsDeleting: false,
    },
    [ETF]: {
      isSellPositionsDeleting: false,
      isBuyPositionsDeleting: false,
      isBothPositionsDeleting: false,
    },
    [CFD]: {
      isSellPositionsDeleting: false,
      isBuyPositionsDeleting: false,
      isBothPositionsDeleting: false,
    },
  },
  positionsDataMetaInfo: {
    [FX]: {
      loading: true,
      sortBy: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY[FX],
        defaultValue: DEFAULT_POSITIONS_TABLE_SORT_BY,
      }),
      sortDir: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR[FX],
        defaultValue: DEFAULT_POSITIONS_TABLE_SORT_DIR,
      }),
      instrumentId: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'instrumentId',
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
          localStorageKey: KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[FX],
        });
        return value === 'null' ? null : value;
      })(),
      tradeMethods: getDefaultValuesFromJsonOfLocalStorage({
        key: 'tradeMethods',
        defaultValue: DEFAULT_TRADE_METHODS_TABLE_FILTER,
        localStorageKey: KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[FX],
      }),
      side: getDefaultValuesFromJsonOfLocalStorage({
        key: 'side',
        defaultValue: null,
        localStorageKey: KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[FX],
      }),
    },
    [ETF]: {
      loading: true,
      sortBy: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY[ETF],
        defaultValue: DEFAULT_POSITIONS_TABLE_SORT_BY,
      }),
      sortDir: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR[ETF],
        defaultValue: DEFAULT_POSITIONS_TABLE_SORT_DIR,
      }),
      instrumentId: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'instrumentId',
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
          localStorageKey: KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[ETF],
        });
        return value === 'null' ? null : value;
      })(),
      tradeMethods: getDefaultValuesFromJsonOfLocalStorage({
        key: 'tradeMethods',
        defaultValue: DEFAULT_TRADE_METHODS_TABLE_FILTER,
        localStorageKey: KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[ETF],
      }),
      side: getDefaultValuesFromJsonOfLocalStorage({
        key: 'side',
        defaultValue: null,
        localStorageKey: KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[ETF],
      }),
    },
    // TODO kazama150180 暫定で ETF のコピー
    [CFD]: {
      loading: true,
      sortBy: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY[CFD],
        defaultValue: DEFAULT_POSITIONS_TABLE_SORT_BY,
      }),
      sortDir: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR[CFD],
        defaultValue: DEFAULT_POSITIONS_TABLE_SORT_DIR,
      }),
      instrumentId: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'instrumentId',
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
          localStorageKey: KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[CFD],
        });
        return value === 'null' ? null : value;
      })(),
      tradeMethods: getDefaultValuesFromJsonOfLocalStorage({
        key: 'tradeMethods',
        defaultValue: DEFAULT_TRADE_METHODS_TABLE_FILTER,
        localStorageKey: KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[CFD],
      }),
      side: getDefaultValuesFromJsonOfLocalStorage({
        key: 'side',
        defaultValue: null,
        localStorageKey: KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[CFD],
      }),
    },
  },
  ordersData: {
    [FX]: [],
    [ETF]: [],
    [CFD]: [],
  },
  ordersDataMetaInfo: {
    [FX]: {
      pageNumber: 1,
      totalPages: 1,
      loading: true,
      sortBy: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY[FX],
        defaultValue: DEFAULT_ORDERS_TABLE_SORT_BY,
      }),
      sortDir: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR[FX],
        defaultValue: DEFAULT_ORDERS_TABLE_SORT_DIR,
      }),
      isActiveOrderSelected: (() => {
        const value = getDefaultValuesFromLocalStorage({
          key: KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED[FX],
          defaultValue: DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
        });
        return typeof value === 'string' ? value === 'true' : value;
      })(),
      instrumentId: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'instrumentId',
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
          localStorageKey: KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[FX],
        });
        return value === 'null' ? null : value;
      })(),
      tradeMethods: getDefaultValuesFromJsonOfLocalStorage({
        key: 'tradeMethods',
        defaultValue: DEFAULT_TRADE_METHODS_TABLE_FILTER,
        localStorageKey: KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[FX],
      }),
      status: getDefaultValuesFromJsonOfLocalStorage({
        key: 'status',
        defaultValue: DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
        localStorageKey: KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[FX],
      }),
    },
    [ETF]: {
      pageNumber: 1,
      totalPages: 1,
      loading: true,
      sortBy: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY[ETF],
        defaultValue: DEFAULT_ORDERS_TABLE_SORT_BY,
      }),
      sortDir: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR[ETF],
        defaultValue: DEFAULT_ORDERS_TABLE_SORT_DIR,
      }),
      isActiveOrderSelected: (() => {
        const value = getDefaultValuesFromLocalStorage({
          key: KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED[ETF],
          defaultValue: DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
        });
        return typeof value === 'string' ? value === 'true' : value;
      })(),
      instrumentId: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'instrumentId',
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
          localStorageKey: KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[ETF],
        });
        return value === 'null' ? null : value;
      })(),
      tradeMethods: getDefaultValuesFromJsonOfLocalStorage({
        key: 'tradeMethods',
        defaultValue: DEFAULT_TRADE_METHODS_TABLE_FILTER,
        localStorageKey: KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[ETF],
      }),
      status: getDefaultValuesFromJsonOfLocalStorage({
        key: 'status',
        defaultValue: DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
        localStorageKey: KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[ETF],
      }),
    },
    // TODO CFD 暫定で ETF のコピー
    [CFD]: {
      pageNumber: 1,
      totalPages: 1,
      loading: true,
      sortBy: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY[CFD],
        defaultValue: DEFAULT_ORDERS_TABLE_SORT_BY,
      }),
      sortDir: getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR[CFD],
        defaultValue: DEFAULT_ORDERS_TABLE_SORT_DIR,
      }),
      isActiveOrderSelected: (() => {
        const value = getDefaultValuesFromLocalStorage({
          key: KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED[CFD],
          defaultValue: DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
        });
        return typeof value === 'string' ? value === 'true' : value;
      })(),
      instrumentId: (() => {
        const value = getDefaultValuesFromJsonOfLocalStorage({
          key: 'instrumentId',
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
          localStorageKey: KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[CFD],
        });
        return value === 'null' ? null : value;
      })(),
      tradeMethods: getDefaultValuesFromJsonOfLocalStorage({
        key: 'tradeMethods',
        defaultValue: DEFAULT_TRADE_METHODS_TABLE_FILTER,
        localStorageKey: KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[CFD],
      }),
      status: getDefaultValuesFromJsonOfLocalStorage({
        key: 'status',
        defaultValue: DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
        localStorageKey: KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[CFD],
      }),
    },
  },
  // 一応まだ残しておく
  sharedMetaInfo: {
    [FX]: {
      tradeMethods: getTradeMethodsFromLocalStorage(
        KEY_FOR_DEFAULT_TRADE_METHODS_TABLE_FILTERS[FX],
        DEFAULT_TRADE_METHODS_TABLE_FILTER,
      ),
      instrumentId: (() => {
        const value = getDefaultValuesFromLocalStorage({
          key: KEY_FOR_DEFAULT_INSTRUMENT_ID_TABLE_FILTERS[FX],
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
        });
        return value === 'null' ? null : value;
      })(),
    },
    [ETF]: {
      tradeMethods: getTradeMethodsFromLocalStorage(
        KEY_FOR_DEFAULT_TRADE_METHODS_TABLE_FILTERS[ETF],
        DEFAULT_TRADE_METHODS_TABLE_FILTER,
      ),
      instrumentId: (() => {
        const value = getDefaultValuesFromLocalStorage({
          key: KEY_FOR_DEFAULT_INSTRUMENT_ID_TABLE_FILTERS[ETF],
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
        });
        return value === 'null' ? null : value;
      })(),
    },
    // TODO CFD 暫定で ETF のコピー
    [CFD]: {
      tradeMethods: getTradeMethodsFromLocalStorage(
        KEY_FOR_DEFAULT_TRADE_METHODS_TABLE_FILTERS[CFD],
        DEFAULT_TRADE_METHODS_TABLE_FILTER,
      ),
      instrumentId: (() => {
        const value = getDefaultValuesFromLocalStorage({
          key: KEY_FOR_DEFAULT_INSTRUMENT_ID_TABLE_FILTERS[CFD],
          defaultValue: DEFAULT_INSTRUMENT_ID_TABLE_FILTER,
        });
        return value === 'null' ? null : value;
      })(),
    },
  },
  createOrderLoading: false,
  newsData: [],
  selectedOrderInfo: [],
  selectedOrderPositionInfo: {},
  loadingSelectedOrderInfo: false,
  loadingNewsData: false,
  loadingChangeOrder: false,
  loadingCreateCloseOrder: false,
  createOrders: Object.values(ORDER_TYPES).reduce(
    (orderTypesAccumulator, orderType) => ({
      ...orderTypesAccumulator,
      [orderType.name]: Object.values(orderType.inputs).reduce((acc, inputName) => {
        if (BUY_SELL_INPUTS.includes(inputName)) {
          acc[inputName] =
            Number(
              getDefaultValuesFromLocalStorage({
                key: KEY_FOR_DEFAULT_SELECT_SIDE,
                defaultValue: BUY_SELL_MAIN.BUY.CHART_ID,
              }),
            ) === BUY_SELL_MAIN.BUY.CHART_ID
              ? BUY_SELL_MAIN.BUY.ID
              : BUY_SELL_MAIN.SELL.ID;
        } else if (DATE_INPUTS.includes(inputName)) {
          acc[inputName] = new Date(getRoundedPlusOneHourCurrentDate());
        } else if (TIME_INPUTS.includes(inputName)) {
          acc[inputName] = getRoundedPlusOneHourCurrentTime();
        } else if (ORDER_METHOD_INPUTS.includes(inputName)) {
          acc[inputName] = 0;
        } else {
          acc[inputName] = 1;
        }
        return acc;
      }, {}),
    }),
    {},
  ),
  createOrdersValidationErrors: Object.values(ORDER_TYPES).reduce(
    (orderTypesAccumulator, orderType) => ({
      ...orderTypesAccumulator,
      [orderType.name]: Object.values(orderType.inputs).reduce((acc, inputName) => {
        if (VALIDATED_INPUTS.includes(inputName)) {
          acc[inputName] = {
            errorMessage: '',
            hasValidationError: false,
          };
        }
        return acc;
      }, {}),
    }),
    {},
  ),
  createOrderClickedEventPosition: { x: 0, y: 0 },
  changeOrdersValidationErrors: {
    [CHANGE_ORDER_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CHANGE_ORDER_THEN_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CHANGE_ORDER_OCO2_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CHANGE_ORDER_THEN_OCO2_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CHANGE_DATE_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CHANGE_TIME_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CHANGE_SETTLEMENT_DATE_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CHANGE_SETTLEMENT_TIME_INPUT]: { hasValidationError: false, errorMessage: '' },
  },
  closeOrdersValidationErrors: {
    [COUNT_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CLOSE_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CLOSE_LIMIT_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CLOSE_STOP_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CLOSE_DATE_INPUT]: { hasValidationError: false, errorMessage: '' },
    [CLOSE_TIME_INPUT]: { hasValidationError: false, errorMessage: '' },
  },
  closeOrderLogicValues: {
    [CLOSE_ORDER_LOGIC_VALUES.PAYMENT_METHOD]: 0,
    [CLOSE_ORDER_LOGIC_VALUES.ORDER_METHOD]: ORDER_METHOD_MAIN.LIMIT.ID,
    [CLOSE_ORDER_LOGIC_VALUES.COUNT]: 0,
    [CLOSE_ORDER_LOGIC_VALUES.CLOSE_PRICE]: 0,
    [CLOSE_ORDER_LOGIC_VALUES.CLOSE_LIMIT_PRICE]: 0,
    [CLOSE_ORDER_LOGIC_VALUES.CLOSE_STOP_PRICE]: 0,
    [CLOSE_ORDER_LOGIC_VALUES.SETTLEMENT_EXPIRATION_TYPE]: 0,
    [CLOSE_ORDER_LOGIC_VALUES.DYNAMIC_QUANTITY_STEP]: QUANTITY_STEP.ONE,
    [CLOSE_ORDER_LOGIC_VALUES.SELECTED_DATE]: new Date(),
    [CLOSE_ORDER_LOGIC_VALUES.SELECTED_TIME]: getRoundedPlusOneHourCurrentTime(),
  },
  deletingPositions: [],
  selectedTableRows: [],
});

export default (state = initialState(), action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_SELECTED_INSTRUMENT_ID: {
      const { id, serviceId } = payload;

      return {
        ...state,
        selectedInstrumentId: {
          ...state.selectedInstrumentId,
          [serviceId]: id,
        },
      };
    }
    case CHANGE_CREATE_ORDER_VALUES: {
      const { orderType, inputName, value } = payload;
      return {
        ...state,
        createOrders: {
          ...state.createOrders,
          [orderType]: {
            ...state.createOrders[orderType],
            [inputName]: value,
          },
        },
      };
    }
    case CHANGE_CREATE_ORDER_VALIDATION_ERRORS: {
      const { orderType, inputName, errorMessage, hasValidationError } = payload;
      return {
        ...state,
        createOrdersValidationErrors: {
          ...state.createOrdersValidationErrors,
          [orderType]: {
            ...state.createOrdersValidationErrors[orderType],
            [inputName]: { errorMessage, hasValidationError },
          },
        },
      };
    }
    case CHANGE_SELECTED_ORDER_TYPE: {
      const { orderType } = payload;
      return {
        ...state,
        selectedOrderType: orderType,
      };
    }
    case CHANGE_TRADE_METHOD_TYPE: {
      const { tradeMethodType } = payload;
      return {
        ...state,
        selectedTradeMethodType: tradeMethodType,
      };
    }
    case RESET_CREATE_ORDERS_NON_COMMON_VALUES: {
      return {
        ...state,
        createOrders: Object.values(ORDER_TYPES).reduce(
          (orderTypesAccumulator, orderType) => ({
            ...orderTypesAccumulator,
            [orderType.name]: Object.values(orderType.inputs).reduce((acc, inputName) => {
              if (COMMON_INPUTS.includes(inputName)) {
                acc[inputName] = state.createOrders[orderType.name][inputName];
              } else if (DATE_INPUTS.includes(inputName)) {
                acc[inputName] = new Date(getRoundedPlusOneHourCurrentDate());
              } else if (TIME_INPUTS.includes(inputName)) {
                acc[inputName] = getRoundedPlusOneHourCurrentTime();
              } else if (ORDER_METHOD_INPUTS.includes(inputName)) {
                acc[inputName] = 0;
              } else {
                acc[inputName] = 1;
              }
              return acc;
            }, {}),
          }),
          {},
        ),
        createOrdersValidationErrors: Object.values(ORDER_TYPES).reduce(
          (orderTypesAccumulator, orderType) => ({
            ...orderTypesAccumulator,
            [orderType.name]: Object.values(orderType.inputs).reduce((acc, inputName) => {
              if (COMMON_VALIDATION_INPUTS.includes(inputName)) {
                acc[inputName] = state.createOrdersValidationErrors[orderType.name][inputName];
              } else if (VALIDATED_INPUTS.includes(inputName)) {
                acc[inputName] = {
                  errorMessage: '',
                  hasValidationError: false,
                };
              }
              return acc;
            }, {}),
          }),
          {},
        ),
      };
    }
    case RESET_CREATE_ORDER_VALUES: {
      return {
        ...state,
        createOrders: initialState().createOrders,
        createOrdersValidationErrors: initialState().createOrdersValidationErrors,
      };
    }
    case RESET_CREATE_ORDER_ERRORS: {
      return {
        ...state,
        createOrdersValidationErrors: initialState().createOrdersValidationErrors,
      };
    }
    case CREATE_ORDER_START_LOADING: {
      return {
        ...state,
        createOrderLoading: true,
      };
    }
    case CREATE_ORDER_STOP_LOADING: {
      return {
        ...state,
        createOrderLoading: false,
      };
    }
    case CHANGE_SELECTED_TABLE: {
      const { tableId } = payload;
      return {
        ...state,
        selectedTable: tableId,
      };
    }
    case GET_POSITIONS_START_LOADING: {
      const { serviceId } = payload;

      return {
        ...state,
        positionsDataMetaInfo: {
          ...state.positionsDataMetaInfo,
          [serviceId]: {
            ...state.positionsDataMetaInfo[serviceId],
            loading: true,
          },
        },
      };
    }
    case GET_POSITIONS_STOP_LOADING: {
      const { serviceId } = payload;

      return {
        ...state,
        positionsDataMetaInfo: {
          ...state.positionsDataMetaInfo,
          [serviceId]: {
            ...state.positionsDataMetaInfo[serviceId],
            loading: false,
          },
        },
      };
    }
    case CLOSE_FIFO_SIDE_POSITIONS_START_LOADING: {
      const { serviceId, side } = payload;
      let deletingType;
      switch (side) {
        case String(BUY_SELL_MAIN.BUY.ID):
          deletingType = 'isBuyPositionsDeleting';
          break;
        case String(BUY_SELL_MAIN.SELL.ID):
          deletingType = 'isSellPositionsDeleting';
          break;
        default:
          deletingType = 'isBothPositionsDeleting';
          break;
      }
      return {
        ...state,
        positionsLoadingInfo: {
          ...state.positionsLoadingInfo,
          [serviceId]: {
            ...state.positionsLoadingInfo[serviceId],
            [deletingType]: true,
          },
        },
      };
    }
    case CLOSE_FIFO_SIDE_POSITIONS_STOP_LOADING: {
      const { serviceId, side } = payload;
      let deletingType;
      switch (side) {
        case String(BUY_SELL_MAIN.BUY.ID):
          deletingType = 'isBuyPositionsDeleting';
          break;
        case String(BUY_SELL_MAIN.SELL.ID):
          deletingType = 'isSellPositionsDeleting';
          break;
        default:
          deletingType = 'isBothPositionsDeleting';
          break;
      }

      return {
        ...state,
        positionsLoadingInfo: {
          ...state.positionsLoadingInfo,
          [serviceId]: {
            ...state.positionsLoadingInfo[serviceId],
            [deletingType]: false,
          },
        },
      };
    }
    case CHANGE_POSITIONS_TABLE_META_INFO: {
      const { key, value, serviceId } = payload;

      return {
        ...state,
        positionsDataMetaInfo: {
          ...state.positionsDataMetaInfo,
          [serviceId]: {
            ...state.positionsDataMetaInfo[serviceId],
            [key]: value,
          },
        },
      };
    }

    case RESET_POSITION_TABLE_META_INFO: {
      const { serviceId } = payload;
      return {
        ...state,
        sharedMetaInfo: {
          ...state.sharedMetaInfo,
          [serviceId]: initialState().sharedMetaInfo[serviceId],
        },
        positionsDataMetaInfo: {
          ...state.positionsDataMetaInfo,
          [serviceId]: { ...initialState().positionsDataMetaInfo[serviceId], loading: false },
        },
      };
    }

    case ORDERS_TABLE_START_LOADING: {
      const { serviceId } = payload;

      return {
        ...state,
        ordersDataMetaInfo: {
          ...state.ordersDataMetaInfo,
          [serviceId]: {
            ...state.ordersDataMetaInfo[serviceId],
            loading: true,
          },
        },
      };
    }
    case ORDERS_TABLE_STOP_LOADING: {
      const { serviceId } = payload;

      return {
        ...state,
        ordersDataMetaInfo: {
          ...state.ordersDataMetaInfo,
          [serviceId]: {
            ...state.ordersDataMetaInfo[serviceId],
            loading: false,
          },
        },
      };
    }
    case GET_ORDERS_TABLE_DATA_SUCCESS: {
      const { data, totalPages, pageNumber, isFirstData, serviceId } = payload;

      const otherServices = ALL_SERVICES.filter((service) => service !== serviceId);
      const otherOrdersData = otherServices.reduce((acc, service) => {
        return { ...acc, [service]: [] };
      }, {});
      const otherOrdersDataMetaInfo = otherServices.reduce((acc, service) => {
        return {
          ...acc,
          [service]: {
            ...state.ordersDataMetaInfo[service],
            loading: true,
          },
        };
      }, {});
      return {
        ...state,
        ordersData: {
          ...otherOrdersData,
          [serviceId]: isFirstData ? data : [...state.ordersData[serviceId], ...data],
        },
        ordersDataMetaInfo: {
          ...state.ordersDataMetaInfo,
          [serviceId]: {
            ...state.ordersDataMetaInfo[serviceId],
            pageNumber,
            totalPages,
          },
          ...otherOrdersDataMetaInfo,
        },
      };
    }
    case CHANGE_ORDERS_TABLE_META_INFO: {
      const { key, value, serviceId } = payload;
      return {
        ...state,
        ordersDataMetaInfo: {
          ...state.ordersDataMetaInfo,
          [serviceId]: {
            ...state.ordersDataMetaInfo[serviceId],
            [key]: value,
          },
        },
      };
    }

    case RESET_ORDER_TABLE_META_INFO: {
      const { serviceId } = payload;
      return {
        ...state,
        sharedMetaInfo: {
          ...state.sharedMetaInfo,
          [serviceId]: initialState().sharedMetaInfo[serviceId],
        },
        ordersDataMetaInfo: {
          ...state.ordersDataMetaInfo,
          [serviceId]: initialState().ordersDataMetaInfo[serviceId],
        },
      };
    }

    case EXECUTIONS_TABLE_START_LOADING: {
      const { serviceId } = payload;

      return {
        ...state,
        executionsDataMetaInfo: {
          ...state.executionsDataMetaInfo,
          [serviceId]: {
            ...state.executionsDataMetaInfo[serviceId],
            loading: true,
          },
        },
      };
    }
    case EXECUTIONS_TABLE_STOP_LOADING: {
      const { serviceId } = payload;

      return {
        ...state,
        executionsDataMetaInfo: {
          ...state.executionsDataMetaInfo,
          [serviceId]: {
            ...state.executionsDataMetaInfo[serviceId],
            loading: false,
          },
        },
      };
    }
    case GET_EXECUTIONS_TABLE_DATA_SUCCESS: {
      const {
        data,
        totalPages,
        pageNumber,
        isFirstData,
        serviceId,
        summarizedPl = null,
        summarizedSwap = null,
        summarizedDividend = null,
        summarizedComm = null,
        summarizedBuyQuantity = null,
        summarizedSellQuantity = null,
      } = payload;

      const otherServices = ALL_SERVICES.filter((service) => service !== serviceId);
      const otherExecutionsData = otherServices.reduce((acc, service) => {
        return { ...acc, [service]: [] };
      }, {});
      const otherExecutionsDataMetaInfo = otherServices.reduce((acc, service) => {
        return {
          ...acc,
          [service]: {
            ...state.executionsDataMetaInfo[service],
            loading: true,
          },
        };
      }, {});
      return {
        ...state,
        executionsData: {
          ...otherExecutionsData,
          [serviceId]: isFirstData ? data : [...state.executionsData[serviceId], ...data],
        },
        executionsDataMetaInfo: {
          ...state.executionsDataMetaInfo,
          [serviceId]: {
            ...state.executionsDataMetaInfo[serviceId],
            pageNumber,
            totalPages,
            summarizedPl,
            summarizedSwap,
            summarizedDividend,
            summarizedComm,
            summarizedBuyQuantity,
            summarizedSellQuantity,
          },
          ...otherExecutionsDataMetaInfo,
        },
      };
    }
    case CHANGE_EXECUTIONS_TABLE_META_INFO: {
      const { key, value, serviceId } = payload;

      return {
        ...state,
        executionsDataMetaInfo: {
          ...state.executionsDataMetaInfo,
          [serviceId]: {
            ...state.executionsDataMetaInfo[serviceId],
            [key]: value,
          },
        },
      };
    }
    case RESET_EXECUTIONS_TABLE_META_INFO: {
      const { serviceId } = payload;
      return {
        ...state,
        executionsDataMetaInfo: {
          ...state.executionsDataMetaInfo,
          [serviceId]: initialState().executionsDataMetaInfo[serviceId],
        },
      };
    }
    case CHANGE_SELECTED_SIDE: {
      const { id } = payload;
      return {
        ...state,
        selectedSide: id,
      };
    }
    case CHANGE_CHART_SIDE: {
      const { id } = payload;
      return {
        ...state,
        chartSide: id,
      };
    }
    case CHANGE_SELECTED_TRADE_METHOD: {
      const { id } = payload;
      return {
        ...state,
        selectedTradeMethod: id,
      };
    }
    case GET_NEWS_REQUEST_START_LOADING: {
      return {
        ...state,
        loadingNewsData: true,
      };
    }
    case GET_NEWS_REQUEST_STOP_LOADING: {
      return {
        ...state,
        loadingNewsData: false,
      };
    }
    case GET_NEWS_SUCCESS: {
      return {
        ...state,
        newsData: payload,
      };
    }
    case GET_ORDER_INFO_SUCCESS: {
      const { array, positionInfo } = payload;
      return {
        ...state,
        selectedOrderInfo: array,
        selectedOrderPositionInfo: positionInfo,
      };
    }
    case ORDER_INFO_REQUEST_START_LOADING: {
      return {
        ...state,
        loadingSelectedOrderInfo: true,
      };
    }
    case ORDER_INFO_REQUEST_STOP_LOADING: {
      return {
        ...state,
        loadingSelectedOrderInfo: false,
      };
    }
    case ORDER_CHANGE_START_LOADING: {
      return {
        ...state,
        loadingChangeOrder: true,
      };
    }
    case ORDER_CHANGE_STOP_LOADING: {
      return {
        ...state,
        loadingChangeOrder: false,
      };
    }
    case CREATE_CLOSE_ORDER_START_LOADING: {
      return {
        ...state,
        loadingCreateCloseOrder: true,
      };
    }
    case CREATE_CLOSE_ORDER_STOP_LOADING: {
      return {
        ...state,
        loadingCreateCloseOrder: false,
      };
    }
    case CHANGE_SHARED_META_INFO: {
      const { key, value, serviceId } = payload;
      return {
        ...state,
        sharedMetaInfo: {
          ...state.sharedMetaInfo,
          [serviceId]: {
            ...state.sharedMetaInfo[serviceId],
            [key]: value,
          },
        },
      };
    }
    case CREATE_MARKET_ORDER_REQUEST: {
      const { x, y } = payload;
      return {
        ...state,
        createOrderClickedEventPosition: { x, y },
      };
    }
    case UPDATE_CHANGE_ORDER_VALIDATION_ERRORS: {
      const { inputName, errorMessage, hasValidationError } = payload;
      return {
        ...state,
        changeOrdersValidationErrors: {
          ...state.changeOrdersValidationErrors,
          [inputName]: { errorMessage, hasValidationError },
        },
      };
    }
    case RESET_CHANGE_ORDER_VALIDATION_ERRORS: {
      return {
        ...state,
        changeOrdersValidationErrors: {
          [CHANGE_ORDER_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
          [CHANGE_ORDER_THEN_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
          [CHANGE_ORDER_OCO2_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
          [CHANGE_ORDER_THEN_OCO2_PRICE_INPUT]: { hasValidationError: false, errorMessage: '' },
        },
      };
    }
    case UPDATE_CLOSE_ORDER_VALIDATION_ERRORS: {
      const { inputName, errorMessage, hasValidationError } = payload;
      return {
        ...state,
        closeOrdersValidationErrors: {
          ...state.closeOrdersValidationErrors,
          [inputName]: { errorMessage, hasValidationError },
        },
      };
    }
    case CHANGE_POSITION_DETAILS_ORDER_LOGIC: {
      const { inputName, inputValue } = payload;
      return {
        ...state,
        closeOrderLogicValues: {
          ...state.closeOrderLogicValues,
          [inputName]: inputValue,
        },
      };
    }
    case SAVE_DELETING_POSITIONS: {
      const { positions } = payload;
      return {
        ...state,
        deletingPositions: [...positions],
      };
    }
    case SAVE_SELECTED_TABLE_ROWS: {
      const { rows } = payload;
      return {
        ...state,
        selectedTableRows: [...rows],
      };
    }
    case CHANGE_SELECTED_INFO_TABLE: {
      const { tableId } = payload;
      return {
        ...state,
        selectedInfoTable: tableId,
      };
    }
    default: {
      return state;
    }
  }
};
