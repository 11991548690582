import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  DEFAULT_LAB_SELECTED_TERM_ID,
  TECHNICAL_TERM_LIST,
  STYLE_OPTIONS,
  COMPREHENSIVE_EVALUATION_LABEL,
} from 'shared-modules/constants';
import { numberExists, roundRoi, roundUpBy1000 } from 'shared-modules/utils';
import { formatNumberToPercentageString, getSlashedDateStringWithoutTime } from 'shared-modules/services';
import classNames from 'classnames';
import { Decimal } from 'decimal.js';
import { Spin } from '../../../../../../components';
import styles from './card.module.scss';
import { Evaluation } from '../../../../../../components/Evaluation/index';

const Card = ({
  ButtonElements,
  imageUrl,
  simulationStats,
  createTime,
  itemsCount,
  instanceCount,
  isLoading: outerIsLoading,
  technicalStyle,
  technicalTerm,
  isPost,
  comprehensiveEvaluation,
}) => {
  const isSimulationStatsEmpty = useMemo(() => _.isEmpty(simulationStats), [simulationStats]);
  const [stats, setStats] = useState({});
  const isLoading = isSimulationStatsEmpty && outerIsLoading;
  const getColorClass = (number) => (number >= 0 ? 'plus' : 'minus');
  const { realizedPl, unrealizedPl, marginRecommended } = simulationStats ?? {};
  const totalPl = useMemo(
    () =>
      numberExists(realizedPl) && numberExists(unrealizedPl) ? new Decimal(realizedPl).add(unrealizedPl).toNumber() : 0,
    [realizedPl, unrealizedPl],
  );
  const calculatedMarginRecommended = useMemo(
    () => (marginRecommended ? roundUpBy1000(marginRecommended) : 0),
    [marginRecommended],
  );
  const roi = useMemo(
    () => new Decimal(totalPl).div(calculatedMarginRecommended).mul(100).mul(1).toNumber(),
    [totalPl, calculatedMarginRecommended],
  );
  const roundedRoi = useMemo(() => roundRoi(roi), [roi]);

  useEffect(() => {
    if (simulationStats?.termId === DEFAULT_LAB_SELECTED_TERM_ID) setStats(simulationStats);

    return () => {
      if (isPost) setStats({});
    };
  }, [isPost, simulationStats]);

  const startDate = useMemo(() => {
    if (!stats.termStart && !createTime) return '-';

    return getSlashedDateStringWithoutTime(stats.termStart || createTime);
  }, [stats.termStart, createTime]);

  const dataEmptyElement = <div>計算中</div>;
  return (
    <div className={styles.card}>
      {isLoading ? (
        <Spin className={styles.loader} />
      ) : (
        <>
          <div className={styles.imageContainer}>
            <img src={imageUrl} className={styles.circleFrame} alt="ビルダーズシェア" />
          </div>
          <div className={styles.detailContainer}>
            <div>
              <div className={styles.row}>
                <div>収益率</div>
                {!isSimulationStatsEmpty ? (
                  <div className={classNames(roundedRoi && getColorClass(roundedRoi), styles.numberStyle)}>
                    {formatNumberToPercentageString(roundedRoi)}
                  </div>
                ) : (
                  dataEmptyElement
                )}
              </div>
              <div className={styles.row}>
                <div>{COMPREHENSIVE_EVALUATION_LABEL}</div>
                {!isSimulationStatsEmpty ? <Evaluation value={comprehensiveEvaluation} /> : dataEmptyElement}
              </div>
              <div className={styles.row}>
                <div>本数</div>
                <div className={styles.numberStyle}>{itemsCount}</div>
              </div>
              <div className={styles.row}>
                <div>稼働人数</div>
                <div className={styles.numberStyle}>{instanceCount}</div>
              </div>
              <div className={styles.row}>
                <div>稼働日</div>
                <div className={styles.valueStyle}>{startDate}</div>
              </div>
              <div className={styles.row}>
                <div>運用スタイル</div>
                <div className={styles.valueStyle}>
                  {STYLE_OPTIONS.find((item) => item.value === technicalStyle)?.label}
                </div>
              </div>
              <div className={styles.row}>
                <div>想定運用期間</div>
                <div className={styles.valueStyle}>
                  {TECHNICAL_TERM_LIST.find((item) => item.value === technicalTerm)?.label}
                </div>
              </div>
            </div>
            <div className={styles.buttonRow}>{ButtonElements}</div>
          </div>
        </>
      )}
    </div>
  );
};

Card.propTypes = {
  ButtonElements: PropTypes.element.isRequired,
  imageUrl: PropTypes.string.isRequired,
  simulationStats: PropTypes.shape({
    termId: PropTypes.number,
    roi: PropTypes.number,
  }),
  itemsCount: PropTypes.number,
  instanceCount: PropTypes.number,
  isLoading: PropTypes.bool,
  technicalStyle: PropTypes.string,
  technicalTerm: PropTypes.string,
  createTime: PropTypes.string,
  isPost: PropTypes.bool,
  comprehensiveEvaluation: PropTypes.number,
};

Card.defaultProps = {
  simulationStats: {},
  itemsCount: 0,
  instanceCount: 0,
  isLoading: true,
  technicalStyle: null,
  technicalTerm: null,
  createTime: '',
  isPost: false,
  comprehensiveEvaluation: undefined,
};

export default memo(Card);
