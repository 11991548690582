import { FX } from '../../constants';
import {
  START_LOADING,
  END_LOADING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  RESET_LOGIN_ERROR,
  CHANGE_LOGIN_VALUES,
  LOGOUT_USER,
  LOGIN_FLOW_START_LOADING,
  LOGIN_FLOW_STOP_LOADING,
  CHANGE_SERVICE_ID_SUCCESS,
  RESET_IS_AUTHENTICATED_STATUS,
  UPDATE_USERNAME,
  UPDATE_AUTH_CONTEXT,
  OPEN_LOGIN_ALERT,
  CLOSE_LOGIN_ALERT,
  CHANGE_PORTAL_ID,
} from '../actionConstants/authConstants';

export const initialState = {
  portalId: null,
  authContext: null,
  isAuthenticated: null,
  username: null,
  serviceId: FX,
  localServiceId: FX,
  isLoading: false,
  loginFlowIsLoading: false,
  error: false,
  errorMessages: [],
  loginErrorMessage: '',
  loginValues: {
    loginCheckbox: false,
    passwordCheckbox: false,
    login: '',
    password: '',
  },
  loginAlert: {
    isOpen: false,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case END_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        error: false,
        errorMessages: [],
        loginErrorMessage: '',
      };
    }
    case LOGIN_USER_FAILED: {
      const { errorMessages, loginErrorMessage } = payload;
      return {
        ...state,
        isAuthenticated: false,
        error: Boolean(errorMessages.length),
        errorMessages,
        loginErrorMessage,
      };
    }
    case RESET_LOGIN_ERROR: {
      const { name } = payload;
      return {
        ...state,
        error: false,
        errorMessages: state?.errorMessages?.filter((error) => error.inputName !== name),
        loginErrorMessage: '',
      };
    }
    case CHANGE_LOGIN_VALUES: {
      const { inputName, value } = payload;
      return {
        ...state,
        loginValues: {
          ...state.loginValues,
          [inputName]: value,
        },
      };
    }
    case CHANGE_PORTAL_ID: {
      const { portalId } = payload;
      return {
        ...state,
        portalId,
      };
    }
    case LOGOUT_USER: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case LOGIN_FLOW_START_LOADING: {
      return {
        ...state,
        loginFlowIsLoading: true,
      };
    }
    case LOGIN_FLOW_STOP_LOADING: {
      return {
        ...state,
        loginFlowIsLoading: false,
      };
    }
    case CHANGE_SERVICE_ID_SUCCESS: {
      const { serviceId, localMode } = payload;

      return {
        ...state,
        serviceId: localMode ? state.serviceId : serviceId,
        localServiceId: serviceId,
      };
    }
    case RESET_IS_AUTHENTICATED_STATUS: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case UPDATE_USERNAME: {
      return {
        ...state,
        username: payload.username,
      };
    }
    case UPDATE_AUTH_CONTEXT: {
      return {
        ...state,
        authContext: payload,
      };
    }
    case OPEN_LOGIN_ALERT: {
      return {
        ...state,
        loginAlert: {
          ...state.loginAlert,
          isOpen: true,
        },
      };
    }
    case CLOSE_LOGIN_ALERT: {
      return {
        ...state,
        loginAlert: {
          ...state.loginAlert,
          isOpen: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
