import { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spin } from '../../../../components';
import styles from './cartList.module.scss';

export const CartList = memo(({ loading, content, footer }) => {
  const listRef = useRef(null);

  useEffect(() => {
    const list = listRef.current;
    // TODO ???
    if (list && window.navigator.userAgent.indexOf('Edge') < 0) {
      list.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div ref={listRef} className={styles.listContainer}>
        <div className={styles.list}>{loading ? <Spin className={styles.loader} /> : content}</div>
      </div>
      {footer}
    </div>
  );
});

CartList.propTypes = {
  loading: PropTypes.bool,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

CartList.defaultProps = {
  loading: false,
  footer: undefined,
};
