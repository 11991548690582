import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { highlightCurrencyPairRate } from '../../../../../../services';
import { ReactComponent as DownArrow } from '../../../../../../assets/downArrow.svg';
import { ReactComponent as UpArrow } from '../../../../../../assets/upArrow.svg';
import styles from './priceValueFX.module.scss';

const PriceValueFX = ({ priceValue, instrumentId, previousValue, label }) => {
  let arrow = '';
  if (previousValue && previousValue !== priceValue) {
    arrow =
      priceValue < previousValue ? (
        <DownArrow className={styles.downArrow} key={priceValue} />
      ) : (
        <UpArrow className={styles.upArrow} key={priceValue} />
      );
  }
  return (
    <div className="position-relative">
      <div className={styles.topArrowLabel}>
        <span className={styles.differentPriceLabel}>{label}</span>
        {arrow}
      </div>
      <span className={styles.priceValue}>{highlightCurrencyPairRate(instrumentId, priceValue, 24, 1.5)}</span>
    </div>
  );
};

PriceValueFX.propTypes = {
  label: PropTypes.string.isRequired,
  priceValue: PropTypes.number,
  instrumentId: PropTypes.string.isRequired,
  previousValue: PropTypes.number,
};

PriceValueFX.defaultProps = {
  priceValue: 0,
  previousValue: 0,
};

export default memo(PriceValueFX);
