import React, { memo, useCallback, useMemo, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { BUY_SELL_MAIN } from 'shared-modules/constants';
import {
  KEY_FOR_DISPLAY_POSITIONS,
  KEY_FOR_DISPLAY_CLOSED_ORDERS,
  KEY_FOR_DISPLAY_NEW_ORDERS,
} from 'shared-modules/constants/chart';
import { useServiceId } from 'shared-modules/hooks';
import { orderLineColor, getColorBySide } from 'shared-modules/services/builder';
import { saveDefaultValuesFromLocalStorage } from 'shared-modules/services';
import { toggleDisplayPositionsFlag } from '../../../../../../redux/actions';
import CommonChart from '../../../../../../components/CommonChart';
import styles from './chartArea.module.scss';
import { store } from '../../../../../../redux/store';

const ChartArea = ({
  propsNewOrderLinesRef,
  // propsClosedOrderLinesRef,
}) => {
  const dispatch = useDispatch();
  const [widget, setWidget] = useState(null);
  const prevWidgetRef = useRef(null);

  const { id: apGroupDataId, instrumentId: selectedInstrumentId } = useSelector(
    (state) => state.multiEdit.portfolioAutoTradeCardData,
  );
  const serviceId = useServiceId(selectedInstrumentId);

  // const closedOrderLinesRef = propsClosedOrderLinesRef;
  const newOrderLinesRef = propsNewOrderLinesRef;
  /** ************************************************************
   * This block is a custom hook and function for common chart
   ************************************************************* */
  const createOrderDataSelector = (orders, selectedServiceId, isClose) => {
    const ordersData = orders[selectedServiceId];
    if (ordersData) return ordersData.filter((order) => order.isClose === isClose);

    return ordersData;
  };

  const removeAllLines = (linesRef) => {
    Object.values(linesRef.current).forEach((line) => line.remove());
    linesRef.current = {}; // eslint-disable-line
  };

  const addButton = (refCurrent, buttonRef, innerHTML, display, onClick) => () => {
    const button = refCurrent?.createButton && refCurrent.createButton();
    if (!button) return;
    buttonRef.current = button; // eslint-disable-line
    button.classList.add('button');
    if (!display) {
      button.classList.add('button-unselected');
    }
    button.addEventListener('click', onClick);
    button.innerHTML = innerHTML;
  };

  const setButtonSelected = (button, selected) => {
    if (button && button.classList) {
      if (!selected) {
        button.classList.add('button-unselected');
      } else {
        button.classList.remove('button-unselected');
      }
    }
  };

  const getLineStyleBySide = (side) => (side === BUY_SELL_MAIN.BUY.ID ? 1 : 2);

  const positions = useSelector((state) => state.currencies.positions[serviceId]);

  // const closedOrdersData = createOrderDataSelector(store.getState().orders, serviceId, true);
  const newOrdersData = createOrderDataSelector(store.getState().orders, serviceId, false);
  const positionsBySelectedInstrumentId = useMemo(
    () =>
      positions.filter((position) => {
        const isSelectedInstrument =
          position.instrumentId === selectedInstrumentId && position.apGroupId === apGroupDataId;

        return isSelectedInstrument;
      }),
    [positions, selectedInstrumentId, apGroupDataId],
  );

  // TODO：Compatible with develop_5.1.0_10 release and later
  // const buttonClosedOrdersWidget = useRef({});
  // const buttonNewOrdersWidget = useRef({});
  const buttonPositionsWidget = useRef({});

  const positionLinesRef = useRef({});

  const displayPositions = useSelector((state) => state.chart.displayPositions);
  const toggleDisplayPositions = useCallback(() => {
    dispatch(toggleDisplayPositionsFlag());
  }, [dispatch]);
  useEffect(() => {
    saveDefaultValuesFromLocalStorage({ key: KEY_FOR_DISPLAY_POSITIONS, value: displayPositions });
  }, [displayPositions]);

  const displayClosedOrders = useSelector((state) => state.chart.displayClosedOrders);
  // TODO：Compatible with develop_5.1.0_10 release and later
  // const toggleDisplayClosedOrders = useCallback(() => {
  //   dispatch(toggleDisplayClosedOrdersFlag());
  // }, [dispatch]);
  useEffect(() => {
    saveDefaultValuesFromLocalStorage({ key: KEY_FOR_DISPLAY_CLOSED_ORDERS, value: displayClosedOrders });
  }, [displayClosedOrders]);

  const displayNewOrders = useSelector((state) => state.chart.displayNewOrders);
  // TODO：Compatible with develop_5.1.0_10 release and later
  // const toggleDisplayNewOrders = useCallback(() => {
  //   dispatch(toggleDisplayNewOrdersFlag());
  // }, [dispatch]);
  useEffect(() => {
    saveDefaultValuesFromLocalStorage({ key: KEY_FOR_DISPLAY_NEW_ORDERS, value: displayNewOrders });
  }, [displayNewOrders]);

  const handleReady = useCallback((newWidget) => {
    setWidget(newWidget);
  }, []);

  useEffect(() => {
    if (prevWidgetRef.current !== widget) {
      prevWidgetRef.current = widget;
      widget
        ?.headerReady()
        .then(addButton(widget, buttonPositionsWidget, '建玉表示', displayPositions, toggleDisplayPositions));

      // TODO：Compatible with develop_5.1.0_10 release and later
      // widget
      //   ?.headerReady()
      //   .then(addButton(refCurrent, buttonNewOrdersWidget, '新規表示', displayNewOrders, toggleDisplayNewOrders));

      // widget
      //   ?.headerReady()
      //   .then(
      //     addButton(refCurrent, buttonClosedOrdersWidget, '決済表示', displayClosedOrders, toggleDisplayClosedOrders),
      //   );
    }
  }, [
    widget,
    // displayNewOrders,
    displayPositions,
    // displayClosedOrders,
    // toggleDisplayNewOrders,
    toggleDisplayPositions,
    // toggleDisplayClosedOrders,
  ]);

  const useOrderLineDisplay = useCallback(
    (refCurrent) => {
      setTimeout(() => {
        // setButtonSelected(buttonClosedOrdersWidget.current, displayClosedOrders);
        // setButtonSelected(buttonNewOrdersWidget.current, displayNewOrders);
        setButtonSelected(buttonPositionsWidget.current, displayPositions);

        removeAllLines(positionLinesRef);

        if (!displayPositions) {
          removeAllLines(positionLinesRef);
        } else {
          positionsBySelectedInstrumentId.forEach((i) => {
            const profitLine = positionLinesRef.current[i.positionId];
            if (!profitLine) {
              positionLinesRef.current[i.positionId] = refCurrent
                .activeChart()
                .createPositionLine()
                .setText('')
                .setQuantity('')
                .setPrice(i.tradePrice)
                .setLineColor(orderLineColor(Number(i.side)))
                .setLineStyle(2)
                .setLineLength(1);
            } else {
              positionLinesRef.current[i.positionId].setPrice(i.tradePrice);
            }
          });

          // removing old positions existing in positionLinesRef.current
          const positionsIdOnly = positionsBySelectedInstrumentId.map((position) => position.positionId);
          const removedPositions = Object.keys(positionLinesRef.current).filter(
            (drawnPositionId) => !positionsIdOnly.includes(drawnPositionId),
          );
          removedPositions.forEach((removedId) => {
            positionLinesRef.current[removedId].remove();
            delete positionLinesRef.current[removedId];
          });
        }

        // TODO：Compatible with develop_5.1.0_10 release and later
        // if (!displayNewOrders) {
        //   removeAllLines(newOrderLinesRef);
        // } else {
        if (Object.keys(newOrderLinesRef.current).length === 0) {
          newOrdersData.forEach((i) => {
            const profitLine = newOrderLinesRef.current[i.orderId];
            if (!profitLine) {
              newOrderLinesRef.current[i.orderId] = refCurrent
                .activeChart()
                .createOrderLine()
                .setText('')
                .setQuantity('')
                .setLineColor(getColorBySide(Number(i.side)))
                .setLineStyle(getLineStyleBySide(Number(i.side)))
                .setPrice(i.price);
            } else {
              newOrderLinesRef.current[i.orderId].setPrice(i.price);
            }
          });
          const newOrdersidOnly = newOrdersData.map((order) => order.orderId);
          const removeNewOrders = Object.keys(newOrderLinesRef.current).filter(
            (drawnId) => !newOrdersidOnly.includes(drawnId),
          );
          removeNewOrders.forEach((removedId) => {
            newOrderLinesRef.current[removedId].remove();
            delete newOrderLinesRef.current[removedId];
          });
        }
        // }

        // TODO：Compatible with develop_5.1.0_10 release and later
        // if (!displayClosedOrders) {
        //   removeAllLines(closedOrderLinesRef);
        // } else {
        // closedOrdersData.forEach(i => {
        //   const profitLine = closedOrderLinesRef.current[i.orderId];
        //   if (!profitLine) {
        //     closedOrderLinesRef.current[i.orderId] = refCurrent
        //       .activeChart()
        //       .createOrderLine()
        //       .setText('')
        //       .setQuantity('')
        //       .setLineColor(PRICE_CHART_COLORS.YELLOW)
        //       .setLineStyle(getLineStyleBySide(Number(i.side)))
        //       .setPrice(i.price);
        //   } else {
        //     closedOrderLinesRef.current[i.orderId].setPrice(i.price);
        //   }
        // });
        // const closedOrdersidOnly = closedOrdersData.map(order => order.orderId);
        // const removeClosedOrders = Object.keys(closedOrderLinesRef.current).filter(
        //   drawnId => !closedOrdersidOnly.includes(drawnId),
        // );
        // removeClosedOrders.forEach(removedId => {
        //   closedOrderLinesRef.current[removedId].remove();
        //   delete closedOrderLinesRef.current[removedId];
        // });
        // }
      }, 200);
    },
    [
      // closedOrdersData,
      // displayClosedOrders,
      // displayNewOrders,
      displayPositions,
      newOrdersData,
      positionsBySelectedInstrumentId,
      newOrderLinesRef,
      // closedOrderLinesRef,
    ],
  );

  // ************************************************************

  return (
    <div className={styles.wrapper}>
      <CommonChart
        key={serviceId}
        serviceId={serviceId}
        selectedInstrumentId={selectedInstrumentId}
        callOnChartReadyCb={useOrderLineDisplay}
        callOnChartReadyInitialCb={handleReady}
      />
    </div>
  );
};

ChartArea.propTypes = {
  propsNewOrderLinesRef: PropTypes.shape({}).isRequired,
  // propsClosedOrderLinesRef: PropTypes.shape.isRequired,
};

export default memo(ChartArea);
