import { Auth, Amplify, Hub } from 'aws-amplify';
import apiClientFactory from 'aws-api-gateway-client';
import {
  REST_API_URL,
  AMPLIFY_AUTH_AUTHENTICATION_FLOW_TYPE,
  AMPLIFY_AUTH_REGION,
  AMPLIFY_AUTH_USER_POOL_ID,
  AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
} from '../config';
import { AWS_SIGNED_IN_USER_SESSION_INVALID, CHECK_USER_AUTHENTICATED_ERROR } from '../constants/errorMesages';
import { SSO_LOGIN_ACCOUNT_TYPE } from '../constants/apiConstant';
import { checkIsWebApp } from '../services';

Amplify.configure({
  Auth: {
    region: AMPLIFY_AUTH_REGION,
    userPoolId: AMPLIFY_AUTH_USER_POOL_ID,
    userPoolWebClientId: AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: AMPLIFY_AUTH_AUTHENTICATION_FLOW_TYPE,
  },
});

const config = {
  invokeUrl: REST_API_URL,
};

export const signInUser = ({ login, password, accountType }) => {
  const loginPrefix = accountType === SSO_LOGIN_ACCOUNT_TYPE ? 'sso_' : ''; // to distinguish SSO and others
  const enhancedLogin = loginPrefix + login;

  const attributes = {
    'custom:account_type': accountType,
    'custom:id': enhancedLogin,
  };

  const signInParams = {
    username: enhancedLogin,
    password: enhancedLogin,
    attributes,
  };

  return Auth.signIn(enhancedLogin)
    .catch((e) => {
      if (e.name === 'UserNotFoundException') {
        // todo: !change password to uuid
        return Auth.signUp(signInParams).then(() => Auth.signIn(enhancedLogin));
      }
      throw e;
    })
    .then((user) => Auth.sendCustomChallengeAnswer(user, password, attributes))
    .then((user) => {
      if (user.signInUserSession && user.signInUserSession.isValid()) {
        return;
      }
      // session is invalid here
      throw Error(AWS_SIGNED_IN_USER_SESSION_INVALID);
    })
    .catch((e) => {
      Auth.signOut();
      throw e;
    });
};

export const checkAuthenticatedUser = () =>
  Auth.currentAuthenticatedUser({ bypassCache: true })
    .then((user) => {
      if (user.signInUserSession && user.signInUserSession.isValid()) {
        return user.username;
      }
      throw Error();
    })
    .catch(() => {
      Auth.signOut();
      throw Error(CHECK_USER_AUTHENTICATED_ERROR);
    });

export const logoutUser = async () => {
  await Auth.signOut();
};

export const get = 'GET';
export const post = 'POST';
export const patch = 'PATCH';
export const deleteMethod = 'DELETE';

export const PAGE_SIZE = 'pageSize=20';
export const PAGE_SIZE_WITHOUT_PAGINATION = 'pageSize=1000';

export const getIdToken = async () => {
  const sessionInfo = await Auth.currentSession({ bypassCache: true });
  return sessionInfo.getIdToken().getJwtToken();
};

export const getAccessToken = async () => {
  const sessionInfo = await Auth.currentSession({ bypassCache: true });
  return sessionInfo.getAccessToken().getJwtToken();
};

export const getRefreshToken = async () => {
  const sessionInfo = await Auth.currentSession({ bypassCache: true });
  return sessionInfo.getRefreshToken().token;
};

export const authContext = {
  isAuthenticated: false,
};

const createAdditionalParamsInternal = async () => {
  const idToken = await getIdToken();
  const accessToken = await getAccessToken();
  const refreshToken = await getRefreshToken();

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-invast-id-token': idToken,
      'x-invast-refresh-token': refreshToken,
    },
  };
};

export const createAdditionalParams = async () => {
  if (!checkIsWebApp() && !authContext.isAuthenticated) {
    return {
      headers: {},
    };
  }
  const params = await createAdditionalParamsInternal();
  return params;
};

const apiClient = apiClientFactory.newClient(config);

// @see https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
export const registerAuthEventListener = (updateAuthContext) => {
  Hub.listen('auth', (data) => {
    switch (data?.payload?.event) {
      case 'signIn':
        authContext.isAuthenticated = true;
        updateAuthContext({ isAuthenticated: true });
        break;
      case 'signIn_failure':
        authContext.isAuthenticated = false;
        updateAuthContext(null);
        break;
      case 'autoSignIn':
        authContext.isAuthenticated = true;
        updateAuthContext({ isAuthenticated: true });
        break;
      case 'autoSignIn_failure':
        authContext.isAuthenticated = false;
        updateAuthContext(null);
        break;
      case 'tokenRefresh':
        authContext.isAuthenticated = true;
        updateAuthContext({ isAuthenticated: true });
        break;
      case 'tokenRefresh_failure':
        authContext.isAuthenticated = false;
        updateAuthContext(null);
        break;
      case 'signOut':
        authContext.isAuthenticated = false;
        updateAuthContext(null);
        break;
      default:
        break;
    }
  });
};

export const refreshUserSession = async () => {
  const additionalParams = await createAdditionalParamsInternal();
  return apiClient.invokeApi({}, `register_tokens`, get, additionalParams);
};

export const invokeApi = (params, pathTemplate, ...rest) => {
  let path = pathTemplate;
  if (!checkIsWebApp() && !authContext.isAuthenticated) {
    if (!pathTemplate.startsWith('public/')) {
      path = `public/${pathTemplate}`;
    }
  }
  return apiClient.invokeApi(params, path, ...rest);
};

export const checkSession = async () => {
  // 暫定で、軽めのAPIを叩くことによりセッションチェックを行う
  const additionalParams = await createAdditionalParams();
  return invokeApi({}, `cart/grouped_items_count`, get, additionalParams);
};
