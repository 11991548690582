import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useKeyPressHandler } from '../../services/hooks';
import styles from './customCheckbox.module.scss';

const Placeholder = memo(({ isChecked }) => (
  <div className={styles.placeholderWrapper}>
    <div className={classNames(styles.placeholder, { [styles.checkedPlaceholder]: !isChecked })} />
  </div>
));

Placeholder.propTypes = {
  isChecked: PropTypes.bool.isRequired,
};

const CustomCheckbox = ({
  onChange,
  isChecked,
  label,
  isReversed,
  isDisabled,
  isHidden,
  className,
  labelClassName,
  checkboxClassName,
  hasError,
}) => {
  const handleClickCheckbox = useCallback(
    (e) => {
      e.currentTarget.blur();
      if (isDisabled) return onChange(isChecked, label);
      return onChange(!isChecked, label);
    },
    [onChange, isChecked, isDisabled, label],
  );
  const handlePressCheckbox = useKeyPressHandler(handleClickCheckbox);

  return (
    <div
      role="button"
      className={classNames(
        styles.checkboxElement,
        { [styles.isReversed]: isReversed },
        { [styles.isDisabled]: isDisabled },
        { [styles.isHidden]: isHidden },
        className,
      )}
      onClick={handleClickCheckbox}
      onKeyPress={handlePressCheckbox}
      tabIndex={isDisabled ? -1 : 0}
    >
      <Placeholder isChecked={isChecked} />
      <i
        className={classNames(
          styles.checkboxImage,
          { [styles.isChecked]: isChecked },
          { [styles.isDisabled]: isDisabled },
          { [styles.hasError]: hasError },
          { [styles.isReversedImage]: isReversed },
          checkboxClassName,
          'material-icons',
        )}
      >
        {isChecked ? 'check_box' : 'check_box_outline_blank'}
      </i>
      {label && (
        <span
          className={classNames(
            styles.checkboxLabel,
            { [styles.isReversed]: isReversed },
            { [styles.hasError]: hasError },
            labelClassName,
          )}
        >
          {label}
        </span>
      )}
    </div>
  );
};

CustomCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isReversed: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  hasError: PropTypes.bool,
};

CustomCheckbox.defaultProps = {
  isReversed: false,
  isDisabled: false,
  isHidden: false,
  className: '',
  labelClassName: '',
  checkboxClassName: '',
  label: '',
  hasError: false,
};

export default memo(CustomCheckbox);
