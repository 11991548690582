import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  LAB_POST_COMMENT_MAX_LENGTH,
  LAB_POST_CONFIRM_ROW1,
  LAB_POST_CONFIRM_ROW2,
  LAB_POST_CONFIRM_WARNING,
  LAB_POST_NAME_MAX_LENGTH,
  STYLE_OPTIONS,
  TECHNICAL_TERM_LIST,
} from 'shared-modules/constants';
import {
  apGroupsSimulationLoadingSelector,
  apGroupsSimulationResultSelector,
  formSelector,
  formServiceIdSelector,
  getAllLabsRequest,
  getSilentApGroupsMultiSimulationRequest,
  getApGroupsSimulationRequest,
  postLabRequest,
  postLabRequestLoadingSelector,
  resetForm,
  resetValidationLab,
  setFormValue,
  validateLabNameRequest,
  validationSelector,
} from 'shared-modules/redux/labs';
import { BUILDER_OVERVIEW_PLACEHOLDER, BUILDER_PAGES } from 'shared-modules/constants/builder';
import useLabCreationTime from 'shared-modules/services/hooks/useLabCreationTime';
import { useAccountInfo } from 'shared-modules/hooks';
import { useComprehensiveEvaluationTargetTermIds } from 'shared-modules/hooks/comprehensiveEvaluation';
import { Button, EditableSelect, EditableText, EditableTextArea } from '../../../../../components';
import IconChoiceModal from '../../../../../appWrappers/ModalContainer/components/IconChoiceModal';
import LabDetailModal from '../../../../../appWrappers/ModalContainer/components/LabDetailModal/LabDetailModal';
import { PostLabSuccessModal } from '../../../../../appWrappers/ModalContainer/components/LabPostModal/components/PostLabSuccessModal';
import labImages from '../../../../../assets/labImages/labImages';
import { changeBuilderActivePage, openConfirmationModal } from '../../../../../redux/actions';
import { getBase64FromImageUrl } from '../../../../../services';
import { BUILDERS_SHARE_PROGRESS_TITLE_STEP2 } from '../../../constants';
import { adjustText } from '../../../common';
import { FORM_WIDTH } from '../constants';
import { Step } from '../Step';
import styles from './shareConfirmPage.module.scss';

const isBlob = (content) => (content ?? '').indexOf('blob') === 0;

const getRandomImage = () => {
  const { name: filename, uri: content } = labImages[Math.floor(Math.random() * labImages.length)];
  return { isPreset: !isBlob(content), filename, content };
};

const isEmptyImage = (image) => {
  if (image == null) {
    return true;
  }
  const { isPreset, content, filename } = image;
  return isPreset == null || !content || !filename;
};

const getPreviewUrl = (image) => {
  if (image == null || !image.content) {
    return null;
  }
  if (image.isPreset) {
    return image.content;
  }
  return `data:image/jpeg;base64,${image.content}`;
};

// TODO ShareInputPage との共通化
export const ShareConfirmPage = memo(({ progress }) => {
  const dispatch = useDispatch();
  const activePage = useSelector((state) => state.webBuilder.activePage);
  const activePageRef = useRef(activePage);

  useEffect(() => {
    activePageRef.current = activePage;
  }, [activePage]);

  const postLabLoading = useSelector(postLabRequestLoadingSelector);
  const { errors, loading } = useSelector(validationSelector);
  const loadings = loading || postLabLoading;
  const [isError, setIsError] = useState(false);
  const [isOpenPostLabSuccessModal, setOpenPostLabSuccessModal] = useState(false);
  const { name, image, style: styleValue, technicalTerm, comment } = useSelector(formSelector);

  const [tempName, setTempName] = useState(name);
  const [tempTechnicalStyle, setTempTechnicalStyle] = useState(styleValue);
  const [tempTechnicalTerm, setTempTechnicalTerm] = useState(technicalTerm);
  const [tempComment, setTempComment] = useState(comment);
  const [tempImage, setTempImage] = useState({ ...image });

  const [editableName, setEditableName] = useState(false);
  const [editableTechnicalStyle, setEditableTechnicalStyle] = useState(false);
  const [editableTechnicalTerm, setEditableTechnicalTerm] = useState(false);
  const [editableComment, setEditableComment] = useState(false);

  const [isOpenIconChoiceModal, setOpenIconChoiceModal] = useState(false);
  const [isOpenLabDetailModal, setOpenLabDetailModal] = useState(false);

  const serviceId = useSelector(formServiceIdSelector);
  const { isNotAvailable } = useAccountInfo()[serviceId];
  const disabled = isEmptyImage(tempImage) || !tempName || !tempTechnicalStyle || !tempComment || isNotAvailable;

  const createTime = useLabCreationTime();
  const simulationData = useSelector(apGroupsSimulationResultSelector);
  const apGroupsSimulationLoading = useSelector(apGroupsSimulationLoadingSelector);

  useEffect(() => {
    if (isEmptyImage(tempImage)) {
      setTempImage(getRandomImage());
    }
  }, [tempImage]);

  useEffect(() => {
    if (errors) {
      setIsError(true);
    }
  }, [errors]);

  const handleChangeName = useCallback((e) => {
    setIsError(false);
    setTempName(e.target.value);
  }, []);

  const handleChangeTechnicalStyle = useCallback((v) => {
    setTempTechnicalStyle(v);
  }, []);

  const handleChangeTechnicalTerm = useCallback((v) => {
    setTempTechnicalTerm(v);
  }, []);

  const handleChangeComment = useCallback((e) => {
    setTempComment(e.target.value);
  }, []);

  const handleEditableName = useCallback(() => {
    setEditableName(!editableName);
  }, [editableName]);

  const handleEditableTechnicalStyle = useCallback(() => {
    setEditableTechnicalStyle(!editableTechnicalStyle);
  }, [editableTechnicalStyle]);

  const handleEditableTechnicalTerm = useCallback(() => {
    setEditableTechnicalTerm(!editableTechnicalTerm);
  }, [editableTechnicalTerm]);

  const handleEditableComment = useCallback(() => {
    setEditableComment(!editableComment);
  }, [editableComment]);

  const showIconChoiceModal = useCallback(() => {
    if (loadings) {
      return;
    }
    setOpenIconChoiceModal(true);
  }, [loadings]);

  const hideIconChoiceModal = useCallback(() => {
    setOpenIconChoiceModal(false);
  }, []);

  const handleChoiceIcon = useCallback(async (content, filename) => {
    let newContent = content;
    let newFilename = filename;
    const isPreset = !isBlob(newContent);
    if (!isPreset) {
      // convert data url to Base64 and rename crop image
      newContent = await getBase64FromImageUrl(newContent);
      newFilename = `cropImage${new Date().getTime()}.jpg`;
    }
    setTempImage({ isPreset, content: newContent, filename: newFilename });
  }, []);

  const termIdsForAssessment = useComprehensiveEvaluationTargetTermIds();
  const showSimulationModal = useCallback(() => {
    dispatch(getSilentApGroupsMultiSimulationRequest({ termIdList: termIdsForAssessment }));
    setOpenLabDetailModal(true);
  }, [termIdsForAssessment, dispatch]);

  const hideSimulationModal = useCallback(() => {
    setOpenLabDetailModal(false);
  }, []);

  const handleBack = useCallback(() => {
    dispatch(resetValidationLab());
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.BUILDERS_SHARE_INPUT_PAGE.ID }));
  }, [dispatch]);

  const handleNext = useCallback(() => {
    dispatch(resetValidationLab());
    // validation name
    dispatch(
      validateLabNameRequest({
        name: tempName,
        callback: () => {
          if (activePageRef.current !== BUILDER_PAGES.BUILDERS_SHARE_CONFIRM_PAGE.ID) {
            return;
          }
          dispatch(setFormValue({ key: 'name', value: adjustText(tempName, LAB_POST_NAME_MAX_LENGTH) }));
          dispatch(setFormValue({ key: 'style', value: tempTechnicalStyle }));
          dispatch(setFormValue({ key: 'technicalTerm', value: tempTechnicalTerm }));
          dispatch(setFormValue({ key: 'comment', value: adjustText(tempComment, LAB_POST_COMMENT_MAX_LENGTH) }));
          dispatch(setFormValue({ key: 'image', value: tempImage }));
          dispatch(
            postLabRequest({
              callback: () => {
                setOpenPostLabSuccessModal(true);
              },
            }),
          );
        },
      }),
    );
  }, [dispatch, tempName, tempComment, tempTechnicalStyle, tempTechnicalTerm, tempImage]);

  const handleOpenConfirmationModal = useCallback(() => {
    dispatch(
      openConfirmationModal({
        callback: handleNext,
        bodyText: `${LAB_POST_CONFIRM_ROW1}${LAB_POST_CONFIRM_ROW2}
        ${LAB_POST_CONFIRM_WARNING}`,
        buttonBackText: '戻る',
        buttonNextText: 'シェアする',
        successButtonIsGreen: true,
      }),
    );
  }, [dispatch, handleNext]);

  const complete = useCallback(() => {
    dispatch(getAllLabsRequest());
    setOpenLabDetailModal(false);
    setOpenPostLabSuccessModal(false);
    dispatch(resetForm());
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.BUILDER_TYPE_PAGE.ID }));
  }, [dispatch]);

  return (
    <Step
      progress={progress}
      backLabel={BUILDERS_SHARE_PROGRESS_TITLE_STEP2}
      nextLabel="次へ"
      loading={loadings}
      disabled={disabled}
      onBack={handleBack}
      onNext={handleOpenConfirmationModal}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>こちらの内容でシェアします</div>
        </div>
        {errors && isError && (
          <div className={styles.errorMessageArea}>
            <div className={styles.errorMessage}>{errors}</div>
          </div>
        )}
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={styles.row}>
              <div className={styles.imageArea}>
                <div
                  className={classNames(styles.imageContainer, { [styles.disabled]: loadings })}
                  onClick={showIconChoiceModal}
                  role="button"
                  tabIndex={loadings ? -1 : 0}
                  aria-hidden
                >
                  <img src={getPreviewUrl(tempImage)} className={styles.circleFrame} alt="" />
                  <div className={styles.change}>変更</div>
                </div>
              </div>
              <div className={styles.editArea}>
                <EditableText
                  className={styles.input}
                  label="タイトル"
                  placeholder="シェアした際に見えるタイトル"
                  maxLength={LAB_POST_NAME_MAX_LENGTH}
                  width={FORM_WIDTH}
                  value={tempName}
                  disabled={loadings}
                  editable={editableName}
                  onChange={handleChangeName}
                  onSwitch={handleEditableName}
                />
                <EditableSelect
                  className={styles.input}
                  label="運用スタイル"
                  placeholder="お選びください"
                  width={FORM_WIDTH}
                  value={tempTechnicalStyle}
                  disabled={loadings}
                  editable={editableTechnicalStyle}
                  options={STYLE_OPTIONS}
                  onChange={handleChangeTechnicalStyle}
                  onSwitch={handleEditableTechnicalStyle}
                />
                <EditableSelect
                  className={styles.input}
                  label="想定運用期間"
                  placeholder="お選びください"
                  width={FORM_WIDTH}
                  value={tempTechnicalTerm}
                  disabled={loadings}
                  editable={editableTechnicalTerm}
                  options={TECHNICAL_TERM_LIST}
                  onChange={handleChangeTechnicalTerm}
                  onSwitch={handleEditableTechnicalTerm}
                />
                <EditableTextArea
                  className={styles.input}
                  label="ビルダーの概要"
                  placeholder={BUILDER_OVERVIEW_PLACEHOLDER}
                  maxLength={LAB_POST_COMMENT_MAX_LENGTH}
                  width={FORM_WIDTH}
                  value={tempComment}
                  disabled={loadings}
                  editable={editableComment}
                  onChange={handleChangeComment}
                  onSwitch={handleEditableComment}
                />
              </div>
            </div>
            <div className={styles.buttonArea}>
              <Button onClick={showSimulationModal} disabled={disabled || loadings} secondary>
                シミュレーションを見る
              </Button>
            </div>
          </div>
        </div>
        {isOpenIconChoiceModal && (
          <IconChoiceModal
            isOpen={isOpenIconChoiceModal}
            closeModal={hideIconChoiceModal}
            finishCallback={handleChoiceIcon}
          />
        )}
        {isOpenLabDetailModal && (
          <LabDetailModal
            isOpen={isOpenLabDetailModal}
            closeModal={hideSimulationModal}
            isLoading={apGroupsSimulationLoading}
            name={tempName}
            simulationData={simulationData}
            image={getPreviewUrl(tempImage)}
            createTime={createTime}
            instanceCount={0} // 稼働人数
            technicalStyle={tempTechnicalStyle} // 運用スタイル
            technicalTerm={tempTechnicalTerm} // 想定運用期間
            description={tempComment} // コメント
            onChangeTermAction={getApGroupsSimulationRequest}
          />
        )}
        {isOpenPostLabSuccessModal && (
          <PostLabSuccessModal isOpen={isOpenPostLabSuccessModal} closeModal={complete} okHandler={complete} />
        )}
      </div>
    </Step>
  );
});

ShareConfirmPage.propTypes = {
  progress: PropTypes.node.isRequired,
};
