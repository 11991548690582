/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useEffect, useRef } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useDispatch, useSelector } from 'react-redux';
import { setReadySequentialModal, setStatusSequentialModal } from '../redux/actions';
import { SEQUENTIAL_MODALS, STATUS } from '../constants/sequentialModals';

/**
 * 順番にモーダルを出すための制御用hookです
 * args
 *   isHome: ホーム画面にいる場合true, recommendを出すかどうかの判断
 *   delay: 主にモバイル用 チュートリアルが終わったあと、当処理が始まるまでの時間
 *   isEnabled: 主にWeb用 外部Windowで起動されている時などにモーダルを出さないようにする
 * response:
 *    canOpenAdvertisements
 *    canOpenMessageModal
 *    canOpenRecommend
 *    deactivateSequentialModals: 主にモバイル用 アプリがinactiveになった時など当処理を一時的に止める
 *      activeになった時resetされるのでactivate処理は無し
 */
const useSequentialModalController = ({ isHome, delay, isEnabled = true }) => {
  const dispatch = useDispatch();

  const isFirstLogin = useSelector((state) => state.settings.isFirstLogin);
  const isInitializing = useSelector((state) => state.event.isInitializing);
  const { ready, status, ...modals } = useSelector((state) => state.sequentialModals);
  const isReady = isEnabled && ready && status === STATUS.PROCESSING;
  const isReadyForRecommend =
    isEnabled && isHome && ready && (status === STATUS.PROCESSING || status === STATUS.ALL_DONE);
  const allDone = modals && Object.values(modals).every((isDone) => isDone);

  const isFirstLoginRef = useRef(isFirstLogin);
  useEffect(() => {
    const tutorialDone = isFirstLoginRef.current && !isFirstLogin;
    if (tutorialDone) {
      setTimeout(() => {
        // チュートリアルが終わったら ready -> trueにする
        dispatch(setReadySequentialModal({ ready: true }));
      }, delay ?? 0);
    }
    isFirstLoginRef.current = isFirstLogin;
    if (isFirstLogin) {
      // チュートリアルが始まったら ready -> falseにする
      dispatch(setReadySequentialModal({ ready: false }));
    }
  }, [dispatch, isFirstLogin, delay]);

  useEffect(() => {
    if (allDone) {
      dispatch(setStatusSequentialModal({ status: STATUS.ALL_DONE }));
    }
  }, [dispatch, allDone]);

  useEffect(() => {
    if (!isInitializing) {
      dispatch(setStatusSequentialModal({ status: STATUS.PROCESSING }));
    }
  }, [dispatch, isInitializing]);

  const deactivateSequentialModals = useCallback(() => {
    dispatch(setStatusSequentialModal({ status: STATUS.NOT_READY }));
  }, [dispatch]);

  const isDone = (modalId) => modals[modalId];

  const { isLoading: isLoadingAdvertisements } = useSelector((state) => state.advertisement);
  const recommendData = useSelector((state) => state.recommend.recommendData);
  const hasRecommendData = !!recommendData;

  const canOpenAdvertisements = isReady && !isLoadingAdvertisements && !isDone(SEQUENTIAL_MODALS.Advertisements);
  const canOpenMessageModal =
    isReady && isDone(SEQUENTIAL_MODALS.Advertisements) && !isDone(SEQUENTIAL_MODALS.MessageModal);
  const canOpenRecommend = isReadyForRecommend && hasRecommendData && isDone(SEQUENTIAL_MODALS.MessageModal);

  return {
    canOpenAdvertisements,
    canOpenMessageModal,
    canOpenRecommend,
    deactivateSequentialModals,
  };
};

export default useSequentialModalController;
