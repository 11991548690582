import { put, call, select } from 'redux-saga/effects';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import {
  closeFifoSidePositionsStartLoading,
  closeFifoSidePositionsStopLoading,
} from '../../actions/manualTradeActions';
import { closeMultiplePositions } from '../../../api/manualTradeApi';
import { errorHandler } from '../errorSaga';

function* closeFifoSidePositionsRequestHandler({ payload: { positionIds, side, callback } }) {
  try {
    const serviceId = yield select((state) => state.auth.serviceId);

    yield put(closeFifoSidePositionsStartLoading({ serviceId, side }));

    yield call(closeMultiplePositions, { serviceId, requestBody: { positionIds } });
    yield put(sendNotificationSuccess({ message: '注文を受け付けました。' }));

    if (callback) callback();
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    const serviceId = yield select((state) => state.auth.serviceId);
    yield put(closeFifoSidePositionsStopLoading({ serviceId, side }));
  }
}

export default closeFifoSidePositionsRequestHandler;
