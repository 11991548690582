import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BackLink, Button } from '../../../../../components';
import styles from './step.module.scss';

export const Step = memo(({ progress, backLabel, nextLabel, loading, disabled, onBack, onNext, children }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <BackLink className={styles.link} disabled={loading} onClick={onBack}>
        {backLabel}
      </BackLink>
    </div>
    <div className={styles.contentContainer}>
      {progress}
      <div className={styles.content}>{children}</div>
    </div>
    <div className={styles.footer}>
      <Button className={styles.button} loading={loading} disabled={disabled} onClick={onNext}>
        {nextLabel}
      </Button>
    </div>
  </div>
));

Step.propTypes = {
  progress: PropTypes.node.isRequired,
  backLabel: PropTypes.string.isRequired,
  nextLabel: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

Step.defaultProps = {
  loading: false,
  disabled: false,
};
