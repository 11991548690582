import React from 'react';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { COUNTRY_TYPE } from 'shared-modules/constants';
import {
  SCREEN_ID_CASH,
  SCREEN_ID_GUIDE,
  SCREEN_ID_MESSAGE,
  SCREEN_ID_PORTFOLIO,
} from 'shared-modules/constants/screen';
import { trimLeftSlash } from 'shared-modules/utils';
import { NotificationMessage } from '../appWrappers/NotificationContainer';
import labImagesHelper from '../assets/labImages/labImagesHelper';
import { ROUTE_TO_SCREEN_ID } from '../constants';

export const openNotification = ({ message, type }) => {
  const id = uuid();
  toast(<NotificationMessage message={message} type={type} id={id} />, { toastId: id });
};

export const highlightCurrencyPairRate = (currencyPair, price, highlightSize = 24, lineHeight = 1) => {
  if (!Number.isFinite(price) || Number(price) === 0 || !currencyPair) {
    return '-';
  }

  let biggerBeginIndex;
  let biggerEndIndex;
  let priceStr;
  if (currencyPair.endsWith(COUNTRY_TYPE.JPY)) {
    // the first, second fractional digits
    biggerBeginIndex = 0;
    biggerEndIndex = 2;
    priceStr = price.toFixed(3);
  } else {
    // the third, forth fractional digits
    biggerBeginIndex = 2;
    biggerEndIndex = 4;
    priceStr = price.toFixed(5);
  }

  const [integerPart, fractionalPart] = priceStr.split('.');
  const [prefixPart, biggerPart, suffixPart] = [
    fractionalPart.slice(0, biggerBeginIndex),
    fractionalPart.slice(biggerBeginIndex, biggerEndIndex),
    fractionalPart.slice(biggerEndIndex),
  ];
  return (
    <>
      {integerPart}.{prefixPart}
      <span style={{ fontSize: highlightSize, lineHeight: `${lineHeight}em` }}>{biggerPart}</span>
      {suffixPart}
    </>
  );
};

export const openNewSameTab = (url) => {
  window.open(url, 'Invast-MyPage');
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob(
      (file) => {
        resolve(URL.createObjectURL(file));
      },
      'image/jpeg',
      0.9,
    );
  });
}

export function getBase64FromImageUrl(url) {
  return new Promise((resolve) => {
    const img = new Image();

    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = function fn() {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(this, 0, 0);

      const dataURL = canvas.toDataURL('image/jpeg');

      resolve(dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''));
    };

    img.src = url;
  });
}

export const getLabImageSrc = (image) => {
  if (!image) return '';

  if (typeof image === 'string') {
    const found = labImagesHelper[image];
    if (found) {
      return found;
    }
    return image;
  }

  return image.isPreset ? labImagesHelper[image.filename] : image.filename;
};

export const isValidImage = async (source) => {
  try {
    await createImage(source);
  } catch (e) {
    return false;
  }

  return true;
};

const ASYNC_STORAGE = Object.freeze({
  setItem: (key, value) => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(key, value);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  getItem: (key) => {
    return new Promise((resolve, reject) => {
      try {
        const item = localStorage.getItem(key);
        resolve(item);
      } catch (error) {
        reject(error);
      }
    });
  },
  removeItem: (key) => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(key);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  // その他メソッドは必要に応じて実装
});

export const getAsyncStorage = () => ASYNC_STORAGE;

const TARGET_SCREEN_SET = new Set([SCREEN_ID_PORTFOLIO, SCREEN_ID_CASH, SCREEN_ID_GUIDE, SCREEN_ID_MESSAGE]);

export const isTargetAllSemiBlockingModal = (screen) => TARGET_SCREEN_SET.has(screen);

export const reloadApp = () => {
  window.location.reload();
};

export const pathnameToScreenId = (pathname) => {
  const [firstPath] = trimLeftSlash(pathname ?? '').split('/');
  const lowerCaseFirstPath = (firstPath ?? '').toLowerCase();
  return ROUTE_TO_SCREEN_ID[lowerCaseFirstPath] ?? null;
};
