import { memo } from 'react';
import { useSelectedShareItem } from 'shared-modules/hooks';
import { SelectionTable } from './SelectionTable';
import { LabTable } from './LabTable';

export const ExecutionTable = memo(() => {
  const selectedShareItem = useSelectedShareItem();
  const Table = selectedShareItem ? LabTable : SelectionTable;
  return <Table />;
});
