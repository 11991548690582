import { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserSSOInvastAffiliateRequest } from '../../redux/actions';
import { Spin } from '../../components';
import styles from './ssoInvastAffiliate.module.scss';

export const SSOInvastAffiliate = memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { url, token, hash } = useSelector((state) => state.userSSO.userSSOInvastAffiliate);
  const [mobileData, setMobileData] = useState(null);
  const formRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search.substr(1));
    const isMobile = urlParams.get('isMobile');
    if (isMobile) {
      setMobileData({
        url: urlParams.get('url'),
        token: urlParams.get('token'),
        hash: urlParams.get('hash'),
      });
    } else {
      dispatch(getUserSSOInvastAffiliateRequest());
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    if ((url && token && hash) || mobileData) {
      formRef.current.submit();
    }
  }, [url, token, hash, mobileData]);

  return (
    <div className={styles.container}>
      <Spin className={styles.loader} />
      {/* Hidden form for submitting SSO */}
      <form action={mobileData?.url ?? url} method="post" ref={formRef} className={styles.form}>
        <input type="hidden" name="data" value={mobileData?.token ?? token} />
        <input type="hidden" name="messageDigest" value={mobileData?.hash ?? hash} />
      </form>
    </div>
  );
});
