import React, { useMemo, memo } from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import styles from './valueContainer.module.scss';

const ValueContainer = ({ dataCustomSelector, children, getValue, renderBeforeValue, ...props }) => {
  const selectedOption = useMemo(() => getValue()?.[0] || {}, [getValue]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ValueContainer {...props}>
      <div className={styles.content}>
        {renderBeforeValue && renderBeforeValue(selectedOption)}
        <div data-custom-selector={dataCustomSelector}>{children}</div>
      </div>
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  dataCustomSelector: PropTypes.string,
  getValue: PropTypes.func,
  renderBeforeValue: PropTypes.func,
};

ValueContainer.defaultProps = {
  dataCustomSelector: '',
  getValue: () => {},
  renderBeforeValue: null,
};

export default memo(ValueContainer);
