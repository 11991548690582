import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TRADE_METHOD_VALUE, ETF, CFD, FX } from 'shared-modules/constants';
import {
  DEFAULT_POSITIONS_TABLE_SORT_BY,
  DEFAULT_POSITIONS_TABLE_SORT_DIR,
  SORT_ASCENDING,
  SORT_DESCENDING,
} from 'shared-modules/constants/manualTrade';
import { sortPositionsTable } from 'shared-modules/services';
import CellPortfolioPositionTableActions from '../CellPortfolioPositionTableActions';
import { getModalPositionsTableTemplate } from '../../../../services/tableTemplate';
import { Spin, Table, Tabs } from '../../../../components';
import styles from './portfolioManualTradeDetailTable.module.scss';

const TAB_POSITIONS = 'positions';
const DEFAULT_SORTING = [
  {
    id: DEFAULT_POSITIONS_TABLE_SORT_BY,
    desc: DEFAULT_POSITIONS_TABLE_SORT_DIR === SORT_DESCENDING,
  },
];

const RESIZED = {
  [FX]: {
    KEY: 'portfolioFXManualTradeDetailTable',
    COLUMNS: [77, 71, 70, 79, 91, 86, 84, 102, 85, 90, 70, 70],
  },
  [ETF]: {
    KEY: 'portfolioETFManualTradeDetailTable',
    COLUMNS: [77, 71, 70, 79, 96, 100, 84, 102, 95, 90, 108, 70, 70],
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    KEY: 'portfolioCFDManualTradeDetailTable',
    COLUMNS: [77, 71, 70, 79, 96, 100, 84, 102, 95, 90, 108, 70, 70],
  },
};

const PortfolioManualTradeDetailTable = ({ type, currency, buySell }) => {
  const [activeTab, setActiveTab] = useState(TAB_POSITIONS);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const serviceId = useMemo(() => instrumentList?.[currency]?.serviceId, [instrumentList, currency]);
  const isLoading = useSelector((state) => state.manualTrade.positionsDataMetaInfo[serviceId].loading);

  const selectedPositionsData = useSelector((state) => state.currencies.positions[serviceId]);

  const resized = useMemo(() => {
    const { KEY, COLUMNS } = RESIZED[serviceId];
    return {
      key: KEY,
      default: [...COLUMNS],
    };
  }, [serviceId]);

  const filteredData = useMemo(
    () =>
      selectedPositionsData.filter(
        (position) =>
          position.instrumentId === currency &&
          position.side === buySell &&
          TRADE_METHOD_VALUE[position.tradeMethod] === type,
      ),
    [selectedPositionsData, type, currency, buySell],
  );

  const [positionSortBy, changePositionSortBy] = useState(DEFAULT_POSITIONS_TABLE_SORT_BY);
  const [positionSortDir, changePositionSortDir] = useState(DEFAULT_POSITIONS_TABLE_SORT_DIR);

  const positionsMetaInfo = useMemo(
    () => ({ loading: isLoading, sortBy: positionSortBy, sortDir: positionSortDir }),
    [isLoading, positionSortBy, positionSortDir],
  );

  const plRef = useRef({});
  const positionsUnrealizedProfitLoss = useSelector((state) => state.currencies.positionsUnrealizedProfitLoss);
  useEffect(() => {
    plRef.current = positionsUnrealizedProfitLoss;
  }, [positionsUnrealizedProfitLoss]);

  const sortedPositionsData = useMemo(
    () => [
      ...filteredData.sort((a, b) =>
        sortPositionsTable({ a, b, sortBy: positionSortBy, sortDir: positionSortDir, plRef }),
      ),
    ],
    [filteredData, positionSortBy, positionSortDir],
  );

  const positionSortingHandler = useCallback(
    ({ id: sortBy, desc: sortDirRaw }) => {
      const sortDir = sortDirRaw ? SORT_DESCENDING : SORT_ASCENDING;
      const previousSortBy = positionSortBy;
      const previousSortDir = positionSortDir;
      if (sortBy === previousSortBy && sortDir === previousSortDir) {
        return;
      }

      changePositionSortBy(sortBy);
      changePositionSortDir(sortDir);
    },
    [positionSortBy, positionSortDir],
  );

  const columns = useMemo(
    () =>
      [
        ...getModalPositionsTableTemplate(serviceId),
        {
          Header: '',
          disableSortBy: true,
          accessor: 'tableButtons',
          // eslint-disable-next-line react/prop-types
          Cell: ({ row }) => (
            <CellPortfolioPositionTableActions isManualTrade isForInstrument row={row} serviceId={serviceId} />
          ),
          minWidth: 89,
          sticky: 'right',
        },
      ].map((column) => {
        if (column.accessor === 'side') {
          return { ...column, Header: '売買' };
        }
        return column;
      }),
    [serviceId],
  );

  return (
    <div className={styles.table}>
      <Tabs
        containerClassName={styles.tabs}
        items={[
          {
            id: TAB_POSITIONS,
            label: '建玉照会',
            children: (
              <div className={styles.tableWrapper}>
                <Table
                  tableData={isLoading ? [] : sortedPositionsData}
                  columns={columns}
                  resized={resized}
                  className={styles.tableContainer}
                  tableMetaInfo={positionsMetaInfo}
                  defaultSorting={DEFAULT_SORTING}
                  useServerSorting
                  sortingHandler={positionSortingHandler}
                />
              </div>
            ),
          },
        ]}
        activeKey={activeTab}
        onChange={setActiveTab}
      />
      {isLoading && <Spin className={styles.loader} />}
    </div>
  );
};

PortfolioManualTradeDetailTable.propTypes = {
  type: PropTypes.string,
  currency: PropTypes.string,
  buySell: PropTypes.string,
};

PortfolioManualTradeDetailTable.defaultProps = {
  type: '',
  currency: '',
  buySell: '',
};

export default memo(PortfolioManualTradeDetailTable);
