import React, { memo, useCallback, useMemo, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BUY_SELL_MAIN, CHART_RESOLUTION_MAIN, CHART_TYPES_MAIN, DISABLE_INSTRUMENTS } from 'shared-modules/constants';
import {
  BUILDER_EXCHANGE_TYPES,
  BUILDER_ORDER_CONFIGURATION_OPTIONS,
  BUILDER_ORDER_TYPES,
  BUILDER_PAGES,
  BUILDER_TERM_OPTIONS,
} from 'shared-modules/constants/builder';
import { useChangeService } from 'shared-modules/services/hooks';
import { useServiceName } from 'shared-modules/hooks';
import { useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import { useBuilderServiceId } from '../../Builder/hooks';
import {
  changeBuilderActivePage,
  changeBuilderActiveCurrency,
  resetBuilderOptions,
  changeBuilderExchangeType,
  changeBuilderMultiOrderOption,
  changeBuilderOrderType,
} from '../../../redux/actions';
import { useArrowKeyNavigation } from '../../../services/hooks';
import { Button, Chart, Tabs, TermSelect } from '../../../components';
import { CurrencyItem } from './CurrencyItem';
import BuySellSwitch from '../../../components/BuySellSwitch';
import styles from './currencyTypePage.module.scss';

export { CurrencyItem };

export const CurrencyTypePage = memo(() => {
  const dispatch = useDispatch();
  const serviceId = useBuilderServiceId();
  const activeCurrency = useSelector((state) => state.builder.activeCurrency);
  const instrumentOptions = useApOrManualInstrumentOptions(false, serviceId) ?? [];
  const serviceName = useServiceName(serviceId);
  const [focus, setFocus] = useArrowKeyNavigation(instrumentOptions.length);
  const tradeServiceData = useChangeService();
  const [chartResolution, changeChartResolution] = useState(CHART_RESOLUTION_MAIN.DAYS_1);
  const activePage = useSelector((state) => state.webBuilder.activePage);
  const [buySell, changeBuySell] = useState(BUY_SELL_MAIN.BUY.CHART_ID);

  useEffect(() => {
    dispatch(resetBuilderOptions());

    let orderType;
    if (activePage === BUILDER_PAGES.TECH_CURRENCY_TYPE_PAGE.ID) {
      orderType = BUILDER_ORDER_TYPES.TECH.ID;
    } else if (activePage === BUILDER_PAGES.CHART_MAKE_INSTRUMENT_CHOICE_PAGE.ID) {
      orderType = BUILDER_ORDER_TYPES.CHART_MAKE.ID;
    } else {
      orderType = BUILDER_ORDER_TYPES.MULTI.ID;
    }

    dispatch(changeBuilderOrderType({ orderType }));
    dispatch(changeBuilderExchangeType({ exchangeType: BUILDER_EXCHANGE_TYPES[serviceName].ID }));
  }, [dispatch, serviceName, activePage]);

  useEffect(() => {
    // set initial focus to the first element
    setFocus(0);
  }, [setFocus]);

  const changeActiveCurrency = useCallback(
    (newCurrencyValue) => {
      dispatch(changeBuilderActiveCurrency({ activeCurrency: newCurrencyValue }));
    },
    [dispatch],
  );

  const nextPageId = useMemo(() => {
    switch (activePage) {
      case BUILDER_PAGES.TECH_CURRENCY_TYPE_PAGE.ID: {
        return BUILDER_PAGES.TECH_BUILDER_PAGE.ID;
      }
      case BUILDER_PAGES.CHART_MAKE_INSTRUMENT_CHOICE_PAGE.ID: {
        return BUILDER_PAGES.CHART_MAKE_DRAW_PAGE.ID;
      }
      default: {
        return BUILDER_PAGES.CONFIGURATION_PAGE.ID;
      }
    }
  }, [activePage]);

  const openNextPage = useCallback(() => {
    const isTQQQ = DISABLE_INSTRUMENTS.some((ins) => ins === activeCurrency);
    if (isTQQQ) {
      dispatch(
        changeBuilderMultiOrderOption({
          fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.SELECTED_SELL_BUY_ID,
          value: BUY_SELL_MAIN.SELL.ID,
        }),
      );
    }
    dispatch(changeBuilderActivePage({ activePage: nextPageId }));
  }, [dispatch, activeCurrency, nextPageId]);

  const setActiveCurrencyTimerRef = useRef(0);

  const handleCurrencyFocus = useCallback(
    (value) => {
      if (setActiveCurrencyTimerRef.current) clearTimeout(setActiveCurrencyTimerRef.current);
      setActiveCurrencyTimerRef.current = setTimeout(() => {
        changeActiveCurrency(value);
      }, 500);
    },
    [changeActiveCurrency],
  );

  const handleCurrencyEnterPress = useCallback(
    (value) => {
      changeActiveCurrency(value);
      openNextPage();
    },
    [changeActiveCurrency, openNextPage],
  );

  const handleChangeTermId = (newTermId) => {
    const foundResolution = Object.values(CHART_RESOLUTION_MAIN).find((item) => item.ID === newTermId);
    changeChartResolution(foundResolution);
  };

  // 344 -> see currencyTypePage.module.scss
  const [tabWidth, setTabWidth] = useState(344 / ((tradeServiceData.options?.length ?? 0) + 1));
  const handleResize = useCallback(
    ({ width }) => {
      const length = tradeServiceData.options?.length ?? 0;
      if (length > 0) {
        setTabWidth((width ?? 0) / length);
      }
    },
    [tradeServiceData.options],
  );

  const isTechPage = activePage === BUILDER_PAGES.TECH_CURRENCY_TYPE_PAGE.ID;
  const isChartMake = activePage === BUILDER_PAGES.CHART_MAKE_INSTRUMENT_CHOICE_PAGE.ID;

  return (
    <div className={styles.pageContainer}>
      {isChartMake && (
        <div className={styles.title}>
          <span>STEP 1/4</span>
          <span>銘柄を選択しましょう</span>
        </div>
      )}
      <div
        className={styles.container}
        style={isChartMake ? { height: 'calc(100vh - var(--app-header-height) - 72px)' } : {}}
      >
        <div className={styles.sideBar}>
          {!isChartMake && (
            <div className={styles.sideBarTitle}>
              <span>アセットを選択</span>
            </div>
          )}
          <div style={{ height: isChartMake ? 8 : 20 }} />
          <div className={styles.center}>
            <Tabs
              type="line"
              tabMinWidth={tabWidth}
              scrollWidth={tabWidth}
              containerClassName={styles.tabs}
              items={tradeServiceData.options}
              activeKey={tradeServiceData.activeServiceId}
              onChange={tradeServiceData.onChange}
              onReady={handleResize}
              onResize={handleResize}
            />
          </div>
          <div className={styles.spacer} />
          <div className={styles.sideBarTitle}>
            <span>銘柄を選択</span>
          </div>
          <div className={styles.currencies}>
            {instrumentOptions[serviceId].map((currency, index) => (
              <CurrencyItem
                key={currency.value}
                currency={currency}
                active={activeCurrency === currency.value}
                onClick={changeActiveCurrency}
                onEnter={handleCurrencyEnterPress}
                onFocus={handleCurrencyFocus}
                hasFocus={focus === index}
              />
            ))}
          </div>
          <div className={styles.buttonGroup}>
            <Button onClick={openNextPage} disabled={!activeCurrency}>
              ロジック設定へ
            </Button>
          </div>
        </div>
        <div
          className={isTechPage ? styles.chart : styles.chartShort}
          style={isChartMake ? { height: 'calc(100vh - var(--app-header-height) - 112px)' } : {}}
        >
          {activeCurrency && (
            <>
              <div className={isTechPage ? styles.techChartHeader : styles.chartHeader}>
                {!isTechPage && (
                  <TermSelect
                    title="足種"
                    options={BUILDER_TERM_OPTIONS}
                    termId={chartResolution.ID}
                    onChange={handleChangeTermId}
                  />
                )}

                <BuySellSwitch activeItemId={buySell} onChange={changeBuySell} />
              </div>
              <Chart
                serviceId={serviceId}
                selectedInstrumentId={activeCurrency}
                selectedSide={buySell}
                resolution={chartResolution.TV_ID}
                chartType={CHART_TYPES_MAIN.CANDLESTICK.ID}
                forceChartType
                disableHeaderWidget
                withoutIndicators
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
});
