import {
  OPEN_ERROR_INFO_MODAL,
  CLOSE_ERROR_INFO_MODAL,
  UPDATE_VALIDATION_ERRORS,
  SEND_ERROR_TO_BUGSNAG,
} from '../actionConstants/errorConstants';

export const openErrorInfoModal = ({
  message,
  title,
  forceLogout,
  withRefreshButton,
  buttonText,
  buttonCallback,
  linkURL,
}) => ({
  type: OPEN_ERROR_INFO_MODAL,
  payload: { message, title, forceLogout, withRefreshButton, buttonText, buttonCallback, linkURL },
});
export const closeErrorInfoModal = () => ({ type: CLOSE_ERROR_INFO_MODAL });

export const updateValidationError = ({ errorsList, form }) => ({
  type: UPDATE_VALIDATION_ERRORS,
  payload: { errorsList, form },
});

export const sendErrorToBugsnag = ({ error }) => ({
  type: SEND_ERROR_TO_BUGSNAG,
  payload: { error },
});
