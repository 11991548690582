import {
  CLOSE_RECOMMEND_MODAL,
  OPEN_RECOMMEND_MODAL,
  GET_RECOMMEND_DATA_SUCCESS,
  GET_RECOMMEND_DATA_REQUEST_END_LOADING,
  GET_RECOMMEND_DATA_REQUEST_START_LOADING,
} from '../actionConstants/recommendConstants';

export const initialState = Object.freeze({
  recommendModal: {
    isOpen: false,
  },
  recommendDataLoading: false,
  recommendData: null,
});

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case OPEN_RECOMMEND_MODAL: {
      return {
        ...state,
        recommendModal: {
          isOpen: true,
        },
      };
    }
    case CLOSE_RECOMMEND_MODAL: {
      return {
        ...state,
        recommendModal: {
          isOpen: false,
        },
      };
    }
    case GET_RECOMMEND_DATA_REQUEST_START_LOADING: {
      return {
        ...state,
        recommendDataLoading: true,
      };
    }
    case GET_RECOMMEND_DATA_REQUEST_END_LOADING: {
      return {
        ...state,
        recommendDataLoading: false,
      };
    }
    case GET_RECOMMEND_DATA_SUCCESS: {
      const { recommendData } = payload;
      return {
        ...state,
        recommendData,
      };
    }
    default: {
      return state;
    }
  }
};
