import { createAdditionalParams, invokeApi, get, patch, deleteMethod } from './index';

export const getTechnicalBuilderData = async ({ status, services }) => {
  const additionalParams = await createAdditionalParams();
  const requestQuery = `?status=${status}&services=${services}`;

  return invokeApi({}, `technical_builders${requestQuery}`, get, additionalParams);
};

export const updateTechnicalBuilderData = async ({ technicalBuilderId, requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `technical_builders/${technicalBuilderId}`, patch, additionalParams, requestBody);
};

export const deleteTechnicalBuilderData = async ({ technicalBuilderId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `technical_builders/${technicalBuilderId}`, deleteMethod, additionalParams);
};

export const updateTechnicalBuilderStatus = async ({ technicalBuilderId, status, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  const requestBody = { status, serviceId };

  return invokeApi({}, `technical_builders/${technicalBuilderId}/status`, patch, additionalParams, requestBody);
};
