import { put, takeLatest, call, select } from 'redux-saga/effects';
import { GET_REPORTS_LIST_REQUEST, GET_REPORT_FILE_REQUEST } from '../actionConstants/reportsConstants';
import { getReportsList, getReportFile } from '../../api/tradingApi';
import {
  getReportsListRequestStartLoading,
  getReportsListRequestEndLoading,
  getReportsListSuccess,
  clearReportsList,
  getReportFileRequestStartLoading,
  getReportFileRequestEndLoading,
  getReportFileSuccess,
  clearReportFile,
} from '../actions/reportsActions';
import { errorHandler } from './errorSaga';

function* getReportsListRequestHandler(action) {
  try {
    yield put(getReportsListRequestStartLoading());
    const {
      payload: { type, startDate, endDate, serviceId },
    } = action;

    const { data: reportsList } = yield call(getReportsList, { type, startDate, endDate, serviceId });
    const { isListLoading } = yield select((state) => state.reports);
    if (isListLoading) {
      yield put(getReportsListSuccess({ reportsList, serviceId }));
    }
  } catch (e) {
    yield call(errorHandler, { error: e });
    yield put(clearReportsList());
  } finally {
    yield put(getReportsListRequestEndLoading());
  }
}

function* getReportFileRequestHandler(action) {
  try {
    const {
      payload: { type, name, reportId, startTime, endTime, serviceId },
    } = action;
    yield put(getReportFileRequestStartLoading({ id: reportId, name }));

    const { data: fileData } = yield call(getReportFile, { type, reportId, startTime, endTime, serviceId });
    const { loading } = yield select((state) => state.reports.fileMetaData);
    if (loading) {
      yield put(getReportFileSuccess({ fileData }));
    }
  } catch (e) {
    yield call(errorHandler, { error: e });
    yield put(clearReportFile());
  } finally {
    yield put(getReportFileRequestEndLoading());
  }
}

export default function* reportsSagaHandler() {
  yield takeLatest(GET_REPORTS_LIST_REQUEST, getReportsListRequestHandler);
  yield takeLatest(GET_REPORT_FILE_REQUEST, getReportFileRequestHandler);
}
