import {
  GET_REPORTS_LIST_REQUEST,
  GET_REPORTS_LIST_REQUEST_START_LOADING,
  GET_REPORTS_LIST_REQUEST_END_LOADING,
  GET_REPORTS_LIST_SUCCESS,
  GET_REPORT_FILE_REQUEST,
  GET_REPORT_FILE_REQUEST_START_LOADING,
  GET_REPORT_FILE_REQUEST_END_LOADING,
  GET_REPORT_FILE_SUCCESS,
  CLEAR_REPORTS_LIST,
  CLEAR_REPORT_FILE,
} from '../actionConstants/reportsConstants';
import { FX } from '../../constants';

export const getReportsListRequest = ({ type, startDate, endDate, serviceId = FX }) => ({
  type: GET_REPORTS_LIST_REQUEST,
  payload: { type, startDate, endDate, serviceId },
});
export const getReportsListRequestStartLoading = () => ({ type: GET_REPORTS_LIST_REQUEST_START_LOADING });
export const getReportsListRequestEndLoading = () => ({ type: GET_REPORTS_LIST_REQUEST_END_LOADING });
export const getReportsListSuccess = ({ reportsList }) => ({
  type: GET_REPORTS_LIST_SUCCESS,
  payload: { reportsList },
});
export const clearReportsList = () => ({ type: CLEAR_REPORTS_LIST });

export const getReportFileRequest = ({ type, name, reportId, startTime, endTime, serviceId = FX }) => ({
  type: GET_REPORT_FILE_REQUEST,
  payload: { type, name, reportId, startTime, endTime, serviceId },
});
export const getReportFileRequestStartLoading = ({ id, name }) => ({
  type: GET_REPORT_FILE_REQUEST_START_LOADING,
  payload: { id, name },
});
export const getReportFileRequestEndLoading = () => ({ type: GET_REPORT_FILE_REQUEST_END_LOADING });
export const getReportFileSuccess = ({ fileData }) => ({
  type: GET_REPORT_FILE_SUCCESS,
  payload: { fileData },
});
export const clearReportFile = () => ({ type: CLEAR_REPORT_FILE });
