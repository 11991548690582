import { DEFAULT_MESSAGE_TEMPLATE } from '../../../constants';
import { SERVICE_VALUE_TO_ID } from '../../../constants/core';

const compareMessageIdDesc = (a, b) => {
  const aMessageId = a?.messageId ?? 0;
  const bMessageId = b?.messageId ?? 0;
  if (aMessageId === bMessageId) {
    return 0;
  }
  if (aMessageId < bMessageId) {
    return 1;
  }
  return -1;
};

const compareStartTimeDesc = (a, b) => {
  const aStartTime = a?.status?.startTime ?? a?.startTime ?? '0000-00-00T00:00:00';
  const bStartTime = b?.status?.startTime ?? b?.startTime ?? '0000-00-00T00:00:00';
  if (aStartTime === bStartTime) {
    return 0;
  }
  if (aStartTime < bStartTime) {
    return 1;
  }
  return -1;
};

const compare = (a, b) => {
  const compResult = compareStartTimeDesc(a, b);
  if (compResult === 0) {
    return compareMessageIdDesc(a, b);
  }
  return compResult;
};

export const sorted = (messages) => {
  if (!messages) {
    return messages;
  }
  messages.sort(compare);
  return messages;
};

export const compareByIsImportant = (a, b) => {
  const i1 = a?.isImportant;
  const i2 = b?.isImportant;
  if (i1 === i2) {
    return compareStartTimeDesc(a, b);
  }
  if (i2) {
    return 1;
  }
  return -1;
};

export const getDefaultMessage = (message) => {
  const defaultMessage = DEFAULT_MESSAGE_TEMPLATE[message?.messageType];
  const { serviceId: serviceValue } = message?.targetServices?.length ? message.targetServices[0] : {};
  if (defaultMessage && serviceValue) {
    const serviceName = SERVICE_VALUE_TO_ID[serviceValue].toUpperCase();
    return defaultMessage.replace(DEFAULT_MESSAGE_TEMPLATE.parameters.SERVICE_NAME, serviceName);
  }
  return undefined;
};

export const completeBody = (messages) => {
  if (!messages) {
    return messages;
  }
  return messages.map((message) => {
    return { ...message, body: message.body ?? getDefaultMessage(message) };
  });
};
