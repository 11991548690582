/* eslint-disable-next-line import/no-unresolved */
import { useMemo } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector } from 'react-redux';
import {
  calcMaxDdAssessment,
  calcRoiAssessment,
  calcTotalPriceDiffAssessment,
  calcCloseTradeCountAssessment,
  calcComprehensiveEvaluation,
  isReadySimulationResults,
} from '../utils';

import { ONE_YEAR_ID, THREE_YEAR_ID, TWO_YEAR_ID } from '../constants';

const COMPREHENSIVE_EVALUATION_TARGET_TERM_IDS = [ONE_YEAR_ID, THREE_YEAR_ID, TWO_YEAR_ID];

export const useComprehensiveEvaluationTargetTermIds = ({ includeTermId = null, excludeTermId = null } = {}) => {
  return useMemo(() => {
    const ids = [...COMPREHENSIVE_EVALUATION_TARGET_TERM_IDS];
    if (excludeTermId !== null && ids.includes(excludeTermId)) {
      ids.splice(ids.indexOf(excludeTermId), 1);
    }
    if (includeTermId !== null && !ids.includes(includeTermId)) {
      return [...ids, includeTermId];
    }
    return [...ids];
  }, [includeTermId, excludeTermId]);
};

const useComprehensiveEvaluationSimulationResultsBuilder = () => {
  const t1y = useSelector((state) => state.builder.simulationData[ONE_YEAR_ID]);
  const t2y = useSelector((state) => state.builder.simulationData[TWO_YEAR_ID]);
  const t3y = useSelector((state) => state.builder.simulationData[THREE_YEAR_ID]);
  return useMemo(() => ({ t1y, t2y, t3y }), [t1y, t2y, t3y]);
};

const useComprehensiveEvaluationSimulationResultsLab = () => {
  const t1y = useSelector((state) => state.labs.postLab.multiSimulationResults[ONE_YEAR_ID]);
  const t2y = useSelector((state) => state.labs.postLab.multiSimulationResults[TWO_YEAR_ID]);
  const t3y = useSelector((state) => state.labs.postLab.multiSimulationResults[THREE_YEAR_ID]);
  return useMemo(() => ({ t1y, t2y, t3y }), [t1y, t2y, t3y]);
};

const useComprehensiveEvaluationInfo = (simulationResults, strategyList) => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const eodRates = useSelector((state) => state.currencies.eodRates);

  const isReady = isReadySimulationResults(simulationResults);

  const maxDdAssessment = useMemo(() => calcMaxDdAssessment(simulationResults), [simulationResults]);
  const roiAssessment = useMemo(() => calcRoiAssessment(simulationResults), [simulationResults]);
  const priceDiffAssessment = useMemo(
    () => calcTotalPriceDiffAssessment(strategyList, eodRates, instrumentList),
    [strategyList, eodRates, instrumentList],
  );
  const { tradeCount = 0, positionCount = 0 } = isReady ? simulationResults.t1y.simulationStats : {};
  const closeTradeCount = isReady ? tradeCount - positionCount : null;
  const closeTradeCountAssessment = useMemo(() => calcCloseTradeCountAssessment(closeTradeCount), [closeTradeCount]);

  return useMemo(() => {
    return isReady
      ? {
          maxDdAssessment,
          roiAssessment,
          priceDiffAssessment,
          closeTradeCountAssessment,
        }
      : {};
  }, [isReady, maxDdAssessment, roiAssessment, priceDiffAssessment, closeTradeCountAssessment]);
};

export const useComprehensiveEvaluationInfoBuilder = () => {
  const simulationResults = useComprehensiveEvaluationSimulationResultsBuilder();
  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);
  const strategyList = useMemo(() => {
    if (orderSettingsList?.length) {
      // strategyListの形にする
      return [
        {
          instrumentId: orderSettingsList[0].instrumentId,
          itemList: orderSettingsList,
        },
      ];
    }
    return [];
  }, [orderSettingsList]);
  return useComprehensiveEvaluationInfo(simulationResults, strategyList);
};

export const useComprehensiveEvaluationInfoLab = () => {
  const simulationResults = useComprehensiveEvaluationSimulationResultsLab();
  const strategyList = useSelector((state) => state.labs.postLab.simulation.result?.strategyList);
  return useComprehensiveEvaluationInfo(simulationResults, strategyList);
};

export const useComprehensiveEvaluation = ({
  maxDdAssessment,
  roiAssessment,
  priceDiffAssessment,
  closeTradeCountAssessment,
}) => {
  return useMemo(() => {
    return calcComprehensiveEvaluation({
      maxDdAssessment,
      roiAssessment,
      priceDiffAssessment,
      closeTradeCountAssessment,
    });
  }, [maxDdAssessment, roiAssessment, priceDiffAssessment, closeTradeCountAssessment]);
};
