import { memo, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { QUANTITY_INPUT_LABEL, ADD_TO_CART_BUTTON } from 'shared-modules/constants/cart';
import { formatDateTimeWithoutDivider } from 'shared-modules/services';
import { useUuid } from 'shared-modules/services/hooks';
import { useServiceIds } from 'shared-modules/hooks/cart';
import { useSavedCart } from 'shared-modules/services/hooks/cartLogic';
import { Button, DeleteIconButton, Tag } from '../../../../../components';
import SelectionImage from '../../../../../components/SelectionImage';
import CustomInput from '../../../../../components/CustomInput';
import InstrumentIcon from '../../../../../components/InstrumentIcon';
import styles from './item.module.scss';

const Image = memo(({ item }) => {
  const { sourceType, parentId, parentImage, serviceId, instrumentId } = item;
  if (parentId) {
    return (
      <SelectionImage
        className={styles.image}
        image={parentImage}
        width="42px"
        isShare={sourceType === 'lab'}
        serviceId={serviceId}
      />
    );
  }
  return (
    <InstrumentIcon
      size="littleTiny"
      className={styles.currencyIcon}
      instrumentId={instrumentId}
      serviceId={serviceId}
    />
  );
});

Image.propTypes = {
  item: PropTypes.shape({
    parentId: PropTypes.number,
    parentImage: PropTypes.string,
    sourceType: PropTypes.string.isRequired,
    serviceId: PropTypes.string.isRequired,
    instrumentId: PropTypes.string.isRequired,
  }).isRequired,
};

const Card = memo(({ tooltipId, item }) => {
  const { parentSets, parentName, strategyName, strategySets, cartLogItems } = item;
  const name = parentName ?? strategyName;
  const sets = parentSets ?? strategySets;
  const serviceIds = useServiceIds(cartLogItems);
  return (
    <div className={styles.itemWrapper}>
      <div className={styles.tags}>
        {serviceIds.map((serviceId) => (
          <div key={serviceId} className={styles.tag}>
            <Tag text={serviceId} />
          </div>
        ))}
      </div>
      <div className={styles.nameRow}>
        <Image item={item} />
        <div className={classNames(styles.itemName, styles.noMaxHeight)} data-for={tooltipId} data-tip={name}>
          {name}
        </div>
        <ReactTooltip id={tooltipId} />
      </div>
      <div className={styles.countRow}>
        <div className={styles.countLabel}>{QUANTITY_INPUT_LABEL}</div>
        <CustomInput
          className={styles.countSet}
          inputClassName={styles.inputRow}
          value={sets}
          type="number"
          isDisabled
          width={100}
        />
      </div>
    </div>
  );
});

Card.propTypes = {
  tooltipId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    parentId: PropTypes.number,
    parentName: PropTypes.string,
    parentImage: PropTypes.string,
    parentSets: PropTypes.number,
    sourceType: PropTypes.string.isRequired,
    serviceId: PropTypes.string.isRequired,
    instrumentId: PropTypes.string.isRequired,
    strategyName: PropTypes.string.isRequired,
    strategySets: PropTypes.number.isRequired,
    cartLogItems: PropTypes.arrayOf(
      PropTypes.shape({
        serviceId: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export const Item = memo(({ className, item: { logId, createTime, items } }) => {
  const tooltipId = useUuid();
  const strategyIds = useMemo(
    () => items.flatMap((item) => item.cartLogItems).map(({ strategyId }) => strategyId),
    [items],
  );
  const { isLoadingAddToCart, isLoadingDeleteItem, handleDeleteItem, handleAddToCurrentCart } = useSavedCart(
    logId,
    strategyIds,
  );
  const disabled = isLoadingAddToCart || isLoadingDeleteItem;
  return (
    <div className={className}>
      <div className={styles.dateRow}>
        <div className={styles.date}>{formatDateTimeWithoutDivider(createTime)}</div>
        <div className={styles.buttons}>
          <Button
            className={styles.addButton}
            operateDisabled={disabled}
            visuallyDisabled={isLoadingAddToCart}
            onClick={handleAddToCurrentCart}
            secondary
          >
            {ADD_TO_CART_BUTTON}
          </Button>
          <div className={styles.deleteButton}>
            <DeleteIconButton onClick={handleDeleteItem} disabled={disabled} />
          </div>
        </div>
      </div>
      {items.map((item) => {
        const { parentId, sourceType, strategyId } = item;
        const key = `${parentId ?? ''}_${sourceType ?? ''}_${strategyId}`;
        return <Card key={key} tooltipId={tooltipId} item={item} />;
      })}
    </div>
  );
});

Item.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    logId: PropTypes.number.isRequired,
    createTime: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        parentId: PropTypes.number,
        parentSets: PropTypes.number,
        parentImage: PropTypes.string,
        strategyId: PropTypes.number.isRequired,
        strategyName: PropTypes.string.isRequired,
        instrumentId: PropTypes.string.isRequired,
        strategySets: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

Item.defaultProps = {
  className: undefined,
};
