import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { OPTIONS_BUY_SELL, BUY_SELL_MAIN } from 'shared-modules/constants';
import { GroupButton } from '../GroupButton';
import styles from './buySellGroupButton.module.scss';

const [SELL_OPTION, BUY_OPTION] = OPTIONS_BUY_SELL;

export const BUY = BUY_SELL_MAIN.BUY.ID;
export const SELL = BUY_SELL_MAIN.SELL.ID;

export const BuySellGroupButton = memo(({ buySell, isNotSelectable, disabledBuy, disabledSell, onChange }) => {
  const buttons = useMemo(
    () => [
      {
        ...SELL_OPTION,
        isDisabled: disabledSell,
        defaultClassName: styles.buySellButton,
        selectedClassName: styles.sell,
      },
      {
        ...BUY_OPTION,
        isDisabled: disabledBuy,
        defaultClassName: styles.buySellButton,
        selectedClassName: styles.buy,
      },
    ],
    [disabledBuy, disabledSell],
  );
  return (
    <GroupButton
      buttons={buttons}
      activeId={buySell}
      showLockIcon={disabledBuy || disabledSell}
      disabled={isNotSelectable}
      onChange={onChange}
    />
  );
});

BuySellGroupButton.propTypes = {
  buySell: PropTypes.number.isRequired,
  disabledBuy: PropTypes.bool,
  disabledSell: PropTypes.bool,
  isNotSelectable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

BuySellGroupButton.defaultProps = {
  disabledBuy: false,
  disabledSell: false,
  isNotSelectable: false,
};
