import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { MODAL_SIZES } from 'shared-modules/constants';
import { useReportsServiceChange } from 'shared-modules/services/hooks';
import ReportsDetailsPanel from './components/ReportsDetailsPanel';
import Modal from '../../../../components/Modal';

const ReportsDetails = ({ isOpen, closeModal }) => {
  const { isTabChanged, setIsTabChanged, resetData } = useReportsServiceChange();

  const closeModalAndClearState = useCallback(() => {
    closeModal();
    setTimeout(() => {
      setIsTabChanged(true);
      resetData();
    }, 300);
  }, [closeModal, resetData, setIsTabChanged]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModalAndClearState}
      title="報告書照会"
      size={MODAL_SIZES.WIDTH_710}
      isTopAligned
    >
      <ReportsDetailsPanel shouldResetToInitialState={isTabChanged} />
    </Modal>
  );
};

ReportsDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(ReportsDetails);
