import { put, call, select } from 'redux-saga/effects';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { getPositionsStartLoading, getPositionsStopLoading } from '../../actions/manualTradeActions';
import { closeMultiplePositions } from '../../../api/manualTradeApi';
import { errorHandler } from '../errorSaga';

function* closeMultiplePositionsRequestHandler(action) {
  try {
    const serviceId = yield select((state) => state.auth.serviceId);

    yield put(getPositionsStartLoading({ serviceId }));
    const {
      payload: { positionIds, callback },
    } = action;

    yield call(closeMultiplePositions, { serviceId, requestBody: { positionIds } });

    yield put(sendNotificationSuccess({ message: '注文を受け付けました。' }));

    if (callback) callback();
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    const serviceId = yield select((state) => state.auth.serviceId);
    yield put(getPositionsStopLoading({ serviceId }));
  }
}

export default closeMultiplePositionsRequestHandler;
