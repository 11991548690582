import { MAINTENANCE_INFOPAGE_URL, MY_PAGE_HOME_URL } from '../config';
import {
  LOSSCUT_MODAL_BUTTON,
  LOSSCUT_MODAL_MESSAGE,
  LOSSCUT_MODAL_TITLE,
  MAINTENANCE_ERROR_MESSAGE_WEB,
  MAINTENANCE_MODAL_BUTTON,
  MAINTENANCE_MODAL_TITLE,
  NOT_EXIST_BUTTON,
  NOT_EXIST_ERROR_MESSAGES_WEB,
  NOT_EXIST_TITLES,
} from '../constants/errorMesages';

export const makeNotExistErrorInfoParams = (serviceId) => ({
  title: NOT_EXIST_TITLES[serviceId],
  message: NOT_EXIST_ERROR_MESSAGES_WEB[serviceId],
  buttonText: NOT_EXIST_BUTTON,
  linkURL: MY_PAGE_HOME_URL,
});

export const makeMaintenanceErrorInfoParams = () => ({
  title: MAINTENANCE_MODAL_TITLE,
  message: MAINTENANCE_ERROR_MESSAGE_WEB,
  buttonText: MAINTENANCE_MODAL_BUTTON,
  linkURL: MAINTENANCE_INFOPAGE_URL,
});

export const makeLossCutErrorInfoParams = () => ({
  title: LOSSCUT_MODAL_TITLE,
  message: LOSSCUT_MODAL_MESSAGE,
  buttonText: LOSSCUT_MODAL_BUTTON,
});

export const makeNotificationErrorParams = ({ accountInfo, serviceId }) => {
  if (accountInfo[serviceId].notExist) {
    return makeNotExistErrorInfoParams(serviceId);
  }
  if (accountInfo[serviceId].isMaintenance) {
    return makeMaintenanceErrorInfoParams();
  }
  if (accountInfo[serviceId].isLossCut) {
    return makeLossCutErrorInfoParams();
  }
  return null;
};

// ニュースは口座未開設 OK
export const makeNotificationErrorParamsNews = ({ accountInfo, serviceId }) => {
  if (accountInfo[serviceId].isMaintenance) {
    return makeMaintenanceErrorInfoParams();
  }
  if (accountInfo[serviceId].isLossCut) {
    return makeLossCutErrorInfoParams();
  }
  return null;
};

// ニュースは口座未開設 OK
export const isNotAvailableNews = (accountInfo) => {
  if (accountInfo == null) {
    return true;
  }
  const { isMaintenance, isLossCut } = accountInfo;
  return isMaintenance || isLossCut;
};
