import { OPEN_ERROR_INFO_MODAL, CLOSE_ERROR_INFO_MODAL } from '../actionConstants/errorConstants';

export const initialState = {
  errorInfoModal: {
    isOpen: false,
    message: '',
    title: '',
    forceLogout: false,
    withRefreshButton: false,
    buttonText: '',
    buttonCallback: null,
    linkURL: '',
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OPEN_ERROR_INFO_MODAL: {
      const {
        message,
        title,
        forceLogout = false,
        withRefreshButton = false,
        buttonText = '',
        buttonCallback = null,
        linkURL = '',
      } = payload;

      if (state.errorInfoModal.isOpen) {
        return state;
      }

      return {
        ...state,
        errorInfoModal: {
          isOpen: true,
          message,
          title,
          forceLogout,
          withRefreshButton,
          buttonText,
          buttonCallback,
          linkURL,
        },
      };
    }
    case CLOSE_ERROR_INFO_MODAL: {
      return {
        ...state,
        errorInfoModal: {
          ...state.errorInfoModal,
          isOpen: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
