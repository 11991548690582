import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js';
import _ from 'lodash';
import { numberExists, roundRoi, roundUpBy1000 } from 'shared-modules/utils';
import { COLORS, DEFAULT_LAB_SELECTED_TERM_ID, MODAL_SIZES, YOUTUBE_REG } from 'shared-modules/constants';
import { useCalculatingMarginByStrategyList } from 'shared-modules/hooks';
import {
  useComprehensiveEvaluation,
  useComprehensiveEvaluationInfoLab,
} from 'shared-modules/hooks/comprehensiveEvaluation';
import { useCalculatingSimulationChartData } from 'shared-modules/services/hooks';
import { openYoutubeModal } from '../../../../redux/actions';
import CustomButton from '../../../../components/CustomButton';
import ProfitLossChartContainer from './components/ProfitLossChartContainer';
import Card from './components/Card';
import { Tables } from './components/Tables';
import Demo from './components/Demo/Demo';
import styles from './labDetailModal.module.scss';

const LabDetailModal = ({
  isOpen,
  closeModal,
  isLoading,
  name,
  simulationData,
  image,
  instanceCount,
  technicalStyle, // 運用スタイル
  technicalTerm, // 想定運用期間
  description, // コメント
  externalLinkName,
  externalLinkUrl,
  onChangeTermAction,
  defaultSets,
  createTime,
}) => {
  const dispatch = useDispatch();
  const handleStopPropagation = useCallback((e) => e.stopPropagation(), []);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const simulationStats = useMemo(() => simulationData?.simulationStats ?? {}, [simulationData?.simulationStats]);
  const strategyList = useMemo(() => simulationData?.strategyList ?? [], [simulationData?.strategyList]);

  const chartData = useCalculatingSimulationChartData({
    strategyList: [simulationData ?? {}],
  }).map((o) => {
    const result = { ...o };
    result.pl *= defaultSets;
    result.realizedPl *= defaultSets;
    result.sum *= defaultSets;
    result.upl *= defaultSets;
    return result;
  });

  // シェアは単一サービス
  const serviceId = simulationData?.strategyList
    ?.map((strategy) => instrumentList[strategy.instrumentId]?.serviceId)
    ?.find((sid) => sid);

  const itemsCount = useMemo(
    () =>
      simulationData.strategyList?.reduce((accumulator, currentValue) => accumulator + currentValue.itemList.length, 0),
    [simulationData],
  );

  useEffect(() => {
    dispatch(onChangeTermAction({ termId: DEFAULT_LAB_SELECTED_TERM_ID }));
  }, [dispatch, onChangeTermAction]);

  const openLink = useCallback(() => {
    if (!externalLinkUrl || !externalLinkName) {
      return;
    }

    const isYoutubeLink = YOUTUBE_REG.test(externalLinkUrl);

    if (isYoutubeLink) {
      dispatch(openYoutubeModal({ url: externalLinkUrl }));
      return;
    }

    window.open(externalLinkUrl, externalLinkName);
  }, [externalLinkUrl, externalLinkName, dispatch]);

  const comprehensiveEvaluationInfo = useComprehensiveEvaluationInfoLab();
  const comprehensiveEvaluation = useComprehensiveEvaluation(comprehensiveEvaluationInfo);

  const Table = Tables[serviceId];

  const { realizedPl, unrealizedPl, marginRecommended } = simulationStats;

  const totalPl = useMemo(
    () => (numberExists(realizedPl) && numberExists(unrealizedPl) ? new Decimal(realizedPl).add(unrealizedPl) : 0),
    [realizedPl, unrealizedPl],
  );

  const convertedStrategyList = useMemo(() => {
    return strategyList.map((strategy) => ({ serviceId, ...strategy }));
  }, [strategyList, serviceId]);

  const marginRequired = useCalculatingMarginByStrategyList(convertedStrategyList, defaultSets);

  const roundedMarginRecommended = useMemo(
    () => (marginRecommended ? roundUpBy1000(marginRecommended) : 0),
    [marginRecommended],
  );

  const calculatedRoi = useMemo(
    () => roundRoi((totalPl * defaultSets * 100) / roundedMarginRecommended),
    [totalPl, defaultSets, roundedMarginRecommended],
  );

  const renewSimulationStats = useMemo(
    () =>
      _.isEmpty(simulationStats)
        ? {}
        : {
            ...simulationStats,
            marginRecommended: roundedMarginRecommended,
            marginRequired,
            roi: calculatedRoi,
            totalPl,
          },
    [simulationStats, roundedMarginRecommended, marginRequired, calculatedRoi, totalPl],
  );

  return (
    <Modal
      show={isOpen}
      onHide={closeModal}
      centered
      onClick={handleStopPropagation}
      size={MODAL_SIZES.LARGE}
      dialogClassName={classNames(styles.wrapper)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton={false} bsPrefix={styles.header}>
        <div className={styles.nameWrapper}>
          <div className={styles.name}>
            タイトル：
            {name}
          </div>
        </div>
        {externalLinkUrl && externalLinkName && (
          <CustomButton color={COLORS.RED} onClick={openLink} className={styles.linkButton}>
            {externalLinkName}
          </CustomButton>
        )}
        <i aria-hidden className={classNames('material-icons-outlined', styles.closeIcon)} onClick={closeModal}>
          clear
        </i>
      </Modal.Header>

      <Modal.Body bsPrefix={classNames(styles.body)}>
        <div className={styles.bodyWithoutSwitchWrapper}>
          <Card
            closeModal={() => {}}
            isLoading={isLoading}
            ButtonElements={<></>}
            imageUrl={image}
            simulationStats={renewSimulationStats}
            createTime={createTime}
            itemsCount={itemsCount}
            instanceCount={instanceCount}
            technicalStyle={technicalStyle}
            technicalTerm={technicalTerm}
            comprehensiveEvaluation={comprehensiveEvaluation}
            isPost
          />
          <ProfitLossChartContainer
            chartData={chartData}
            isLoading={isLoading}
            simulationStats={renewSimulationStats}
            defaultSets={defaultSets}
            strategyList={strategyList}
            comprehensiveEvaluation={comprehensiveEvaluation}
            isPost
            isPreview
          />
          {Table && (
            <Table
              isLoading={isLoading}
              simulationData={simulationData}
              defaultSets={defaultSets}
              selectedTableTypeId={2}
              isPost
            />
          )}
          <Demo termEndDate={simulationStats.termEnd ?? new Date().toISOString()} comment={description} isPreview />
        </div>
      </Modal.Body>
    </Modal>
  );
};

LabDetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  simulationData: PropTypes.shape({
    strategyList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    simulationStats: PropTypes.shape({
      termId: PropTypes.number,
    }),
  }),
  image: PropTypes.string,
  instanceCount: PropTypes.number,
  technicalStyle: PropTypes.string, // 運用スタイル
  technicalTerm: PropTypes.string, // 想定運用期間
  description: PropTypes.string, // コメント
  externalLinkName: PropTypes.string,
  externalLinkUrl: PropTypes.string,
  onChangeTermAction: PropTypes.func.isRequired,
  defaultSets: PropTypes.number,
  createTime: PropTypes.string,
};

LabDetailModal.defaultProps = {
  simulationData: {},
  image: null,
  instanceCount: 0,
  technicalStyle: null,
  technicalTerm: null,
  description: null,
  externalLinkName: null,
  externalLinkUrl: null,
  defaultSets: 1,
  createTime: '',
};

export default memo(LabDetailModal);
