import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { UPDATE_MOBILE_CHART } from 'shared-modules/constants/apiConstant';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { isJsonString } from 'shared-modules/services';
import {
  sellAverageTradePriceLineProps,
  buyAverageTradePriceLineProps,
  isEmpty,
} from 'shared-modules/services/builder';
import {
  socketConnectionRequest,
  getInstrumentListSuccess,
  createInstrumentsOptions,
  getRatesSuccess,
  getPositionsSuccess,
} from '../../redux/actions';
import CommonChart from '../../components/CommonChart';
import styles from './mobileMarketOrderChart.module.scss';

function updateChartRelatedGlobalData(dispatch, payload) {
  const { positions, rates, instrumentList, serviceId } = payload;
  dispatch(getPositionsSuccess({ serviceId, positions }));
  dispatch(getRatesSuccess({ rates }));
  dispatch(getInstrumentListSuccess({ instrumentList }));
  ALL_SERVICES.forEach((service) => {
    dispatch(createInstrumentsOptions({ serviceId: service, options: payload[`${service}InstrumentsOptions`] }));
  });
}

const MobileMarketOrderChart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(socketConnectionRequest({ isPublic: true }));

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(UPDATE_MOBILE_CHART);
    }
  }, [dispatch]);

  const [instrumentId, changeInstrumentId] = useState(null);
  const [resolution, changeResolution] = useState(null);
  const [chartType, changeChartType] = useState(null);
  const [selectedSide, changeSelectedSide] = useState(null);
  const [serviceId, changeServiceId] = useState(null);
  const [commonFifoValue, changeCommonFifoValue] = useState(null);

  const windowEventListener = useCallback(
    ({ data }) => {
      if (!data || !isJsonString(data)) {
        return;
      }
      const parsedMessage = JSON.parse(data);

      switch (parsedMessage.message) {
        case UPDATE_MOBILE_CHART: {
          const { payload } = parsedMessage;
          changeInstrumentId(payload.instrumentId);
          changeResolution(payload.resolution);
          changeChartType(payload.chartType);
          changeSelectedSide(payload.selectedSide);
          changeServiceId(payload.serviceId);
          changeCommonFifoValue(payload.commonFifoValue);

          updateChartRelatedGlobalData(dispatch, payload);

          break;
        }
        default:
          break;
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', windowEventListener);
    document.addEventListener('message', windowEventListener);
    return () => {
      window.removeEventListener('message', windowEventListener);
      document.removeEventListener('message', windowEventListener);
    };
  }, [windowEventListener]);

  const sellAverageTradePriceLineRef = useRef({});
  const sellAverageTradePriceLineIdRef = useRef({});
  const buyAverageTradePriceLineRef = useRef({});
  const buyAverageTradePriceLineIdRef = useRef({});

  const useMarketOrderChartLogic = useCallback(
    (currentRef) => {
      const { sellAverageTradePrice, buyAverageTradePrice, sellPositionCount, buyPositionCount } = commonFifoValue;
      if (!isEmpty(sellAverageTradePriceLineRef.current)) {
        currentRef.activeChart().removeEntity(sellAverageTradePriceLineIdRef.current);
      }
      if (sellPositionCount && sellAverageTradePrice) {
        const newSellLineId = currentRef
          .activeChart()
          .createShape(
            { price: sellAverageTradePrice },
            { shape: 'horizontal_line', lock: true, disableSelection: true, overrides: sellAverageTradePriceLineProps },
          );
        sellAverageTradePriceLineRef.current = currentRef.activeChart().getShapeById(newSellLineId);
        sellAverageTradePriceLineRef.current.sendToBack();
        sellAverageTradePriceLineIdRef.current = newSellLineId;
      }

      if (!isEmpty(buyAverageTradePriceLineRef.current)) {
        currentRef.activeChart().removeEntity(buyAverageTradePriceLineIdRef.current);
      }
      if (buyPositionCount && buyAverageTradePrice) {
        const newBuyLineId = currentRef
          .activeChart()
          .createShape(
            { price: buyAverageTradePrice },
            { shape: 'horizontal_line', lock: true, disableSelection: true, overrides: buyAverageTradePriceLineProps },
          );
        buyAverageTradePriceLineRef.current = currentRef.activeChart().getShapeById(newBuyLineId);
        buyAverageTradePriceLineRef.current.sendToBack();
        buyAverageTradePriceLineIdRef.current = newBuyLineId;
      }
    },
    [commonFifoValue],
  );

  return (
    <div className={styles.chartWrapper}>
      {instrumentId && serviceId && (
        <CommonChart
          callOnChartReadyCb={useMarketOrderChartLogic}
          chartType={chartType}
          disableHeaderWidget
          disableLeftToolbar
          disableOHLCInfo
          isMobile
          key={serviceId}
          resolution={resolution}
          selectedInstrumentId={instrumentId}
          selectedSide={selectedSide}
          serviceId={serviceId}
        />
      )}
    </div>
  );
};

export default memo(MobileMarketOrderChart);
