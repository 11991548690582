// eslint-disable-next-line import/no-unresolved
import { createElement, memo } from 'react';
import { SelectedStrategyGroupProvider, SelectedStrategyProvider } from '../contexts';

// eslint-disable-next-line react/prop-types
export const AutoSelectProvider = memo(({ children }) => {
  // eslint-disable-next-line react/no-children-prop
  return createElement(SelectedStrategyGroupProvider, {
    // eslint-disable-next-line react/no-children-prop
    children: createElement(SelectedStrategyProvider, { children }),
  });
});
