import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { COLORS, MODAL_SIZES, WIDTH, INPUT_WITH_PIPS_WIDTH, BUY_SELL_MAIN, FX, CFD } from 'shared-modules/constants';
import useDynamicAPGroupChangeInfo from 'shared-modules/services/hooks/apGroupChangeLogic';
import { InputNumber } from '../../../../components';
import CustomButton from '../../../../components/CustomButton';
import Modal from '../../../../components/Modal';
import LabeledSwitch from '../../../../components/LabeledSwitch';
import styles from './portfolioAutoTradeDetailOrderType.module.scss';

const PortfolioAutoTradeDetailOrderType = ({ isOpen, closeModal, data }) => {
  const dynamicInfo = useDynamicAPGroupChangeInfo(data, isOpen);

  const { serviceId, instrumentId } = useSelector((state) => state.portfolio.selectedApGroupData);
  const isFX = useMemo(() => serviceId === FX, [serviceId]);
  const isCFD = useMemo(() => serviceId === CFD, [serviceId]);
  const { quantityUnitConvFactor } = useSelector((state) => state.settings.instrumentList[instrumentId]);

  const tooltipId = useRef(uuid()).current;

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="注文設定変更" size={MODAL_SIZES.WIDTH_360} isOverlap>
      <div className={classNames(styles.row, styles.isTextRow)}>
        <div className={styles.textLabel}>{dynamicInfo.name.label}</div>
        <div
          className={classNames(styles.textValue, styles.name)}
          data-for={tooltipId}
          data-tip={dynamicInfo.name.value}
        >
          {dynamicInfo.name.value}
        </div>
        <ReactTooltip id={tooltipId} />
      </div>
      <div className={classNames(styles.row, styles.isTextRow)}>
        <div className={styles.textLabel}>{dynamicInfo.instrumentId.label}</div>
        <div className={styles.textValue}>{dynamicInfo.instrumentId.value}</div>
      </div>
      <div className={classNames(styles.row, styles.isTextRow)}>
        <div className={styles.textLabel}>{dynamicInfo.side.label}</div>
        <div className={styles.textValue}>{dynamicInfo.side.value}</div>
      </div>

      <div className={styles.row}>
        <div>
          {dynamicInfo.quantity.label}
          {isCFD && <div>1Lot={quantityUnitConvFactor}</div>}
        </div>
        <InputNumber
          value={dynamicInfo.quantity.get}
          onChange={dynamicInfo.quantity.set}
          disabled={dynamicInfo.quantity.isDisabled}
          name={dynamicInfo.quantity.name}
          errorMessages={dynamicInfo.errorsArray}
          validateFunction={dynamicInfo.quantity.validate}
          step={dynamicInfo.quantity.step}
          withErrorTooltip
        />
      </div>

      {dynamicInfo.tradePrice.condition && (
        <div className={styles.row}>
          <div>{dynamicInfo.tradePrice.label}</div>
          <div>{dynamicInfo.tradePrice.get}</div>
        </div>
      )}

      {dynamicInfo.orderType.condition && (
        <>
          <div className={styles.row}>
            <div>{dynamicInfo.entryPrice.label}</div>
            <InputNumber
              value={dynamicInfo.entryPrice.get}
              onChange={dynamicInfo.entryPrice.set}
              disabled={dynamicInfo.entryPrice.isDisabled}
              step={dynamicInfo.priceStep}
              name={dynamicInfo.entryPrice.name}
              errorMessages={dynamicInfo.errorsArray}
              validateFunction={dynamicInfo.entryPrice.validate}
              withErrorTooltip
            />
          </div>
        </>
      )}

      {dynamicInfo.orderType2.condition && (
        <div className={styles.row}>
          <div>{dynamicInfo.entryPrice2.label}</div>
          <InputNumber
            value={dynamicInfo.entryPrice2.get}
            onChange={dynamicInfo.entryPrice2.set}
            step={dynamicInfo.priceStep}
            name={dynamicInfo.entryPrice2.name}
            errorMessages={dynamicInfo.errorsArray}
            validateFunction={dynamicInfo.entryPrice2.validate}
            withErrorTooltip
            disabled={dynamicInfo.entryPrice2.isDisabled}
          />
        </div>
      )}

      <div className={styles.row}>
        <div>{dynamicInfo.profitMargin.label}</div>
        <InputNumber
          value={dynamicInfo.profitMargin.get}
          onChange={dynamicInfo.profitMargin.set}
          step={dynamicInfo.profitMargin.step}
          name={dynamicInfo.profitMargin.name}
          errorMessages={dynamicInfo.errorsArray}
          validateFunction={dynamicInfo.profitMargin.validate}
          withErrorTooltip
        />
      </div>

      <div className={styles.row}>
        <div>{dynamicInfo.lossCutWidth.label}</div>
        <InputNumber
          value={dynamicInfo.lossCutWidth.get}
          onChange={dynamicInfo.lossCutWidth.set}
          step={dynamicInfo.lossCutWidth.step}
          name={dynamicInfo.lossCutWidth.name}
          errorMessages={dynamicInfo.errorsArray}
          validateFunction={dynamicInfo.lossCutWidth.validate}
          min={dynamicInfo.lossCutWidth.min}
          withErrorTooltip
          validateNegativeValues
        />
      </div>

      <div className={styles.row}>
        <div>{dynamicInfo.follow.label}</div>
        <InputNumber
          value={dynamicInfo.follow.get}
          onChange={dynamicInfo.follow.set}
          step={dynamicInfo.follow.step}
          name={dynamicInfo.follow.name}
          errorMessages={dynamicInfo.errorsArray}
          validateFunction={dynamicInfo.follow.validate}
          min={dynamicInfo.follow.min}
          withErrorTooltip
          validateNegativeValues={dynamicInfo.side.value === BUY_SELL_MAIN.SELL.LABEL}
        />
      </div>

      <div className={styles.row}>
        <div>{dynamicInfo.counterType.label}</div>
        <LabeledSwitch
          activeItemId={dynamicInfo.counterType.get}
          onChange={dynamicInfo.counterType.set}
          options={dynamicInfo.counterType.options}
        />
      </div>

      <div className={classNames(styles.row, styles.isCounterRow)}>
        {dynamicInfo.counterValue.label}
        <InputNumber
          value={dynamicInfo.counterValue.get}
          onChange={dynamicInfo.counterValue.set}
          className={styles.counterInput}
          withPips={isFX ? dynamicInfo.counterValue.withPips : false}
          width={dynamicInfo.counterValue.withPips && isFX ? INPUT_WITH_PIPS_WIDTH : null}
          step={dynamicInfo.counterValue.step}
          name={dynamicInfo.counterValue.name}
          errorMessages={dynamicInfo.errorsArray}
          validateFunction={dynamicInfo.counterValue.validate}
          min={dynamicInfo.counterValue.min}
          withErrorTooltip
          validateNegativeValues={
            dynamicInfo.counterValue.withPips && dynamicInfo.side.value === BUY_SELL_MAIN.BUY.LABEL
          }
        />
      </div>

      <CustomButton
        color={COLORS.RED}
        width={WIDTH.PERCENTAGE_100}
        className={styles.confirmButton}
        onClick={dynamicInfo.submit.handler(closeModal)}
        isLoading={dynamicInfo.submit.isLoading}
        isDisabled={dynamicInfo.submit.isDisabled}
      >
        {dynamicInfo.submit.label}
      </CustomButton>
    </Modal>
  );
};

PortfolioAutoTradeDetailOrderType.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    instrumentId: PropTypes.string,
    side: PropTypes.string,
    quantity: PropTypes.number,
    entryPrice1: PropTypes.number,
    entryPrice2: PropTypes.number,
    tp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    follow: PropTypes.number,
    counter: PropTypes.number,
    counterPrice: PropTypes.number,
    tradePrice: PropTypes.number,
    status: PropTypes.string,
    positionId: PropTypes.string,
    orderStatus: PropTypes.string,
    latestNewOrderPrice1: PropTypes.number,
    latestNewOrderPrice2: PropTypes.number,
    id: PropTypes.string,
  }).isRequired,
};

export default memo(PortfolioAutoTradeDetailOrderType);
