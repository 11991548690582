import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './icon.module.scss';

export const Icon = memo(({ name, wrapperStyle, wrapperClass, iconStyle, iconClass }) => (
  <div className={styles.container}>
    <div className={classNames(styles.iconWrapper, wrapperClass)} style={wrapperStyle}>
      <i aria-hidden className={classNames('material-icons-outlined', styles.icon, iconClass)} style={iconStyle}>
        {name}
      </i>
    </div>
  </div>
));

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  wrapperStyle: PropTypes.shape({}),
  wrapperClass: PropTypes.string,
  iconStyle: PropTypes.shape({}),
  iconClass: PropTypes.string,
};

Icon.defaultProps = {
  wrapperStyle: undefined,
  wrapperClass: undefined,
  iconStyle: undefined,
  iconClass: undefined,
};
