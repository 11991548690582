/* eslint-disable-next-line import/no-unresolved */
import { useMemo } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector } from 'react-redux';
import { getRangeOutKeySet } from '../utils';

export const useRangeOutKeySet = () => {
  const rangeOutState = useSelector((state) => state.message.rangeOutState);
  return useMemo(() => getRangeOutKeySet(rangeOutState), [rangeOutState]);
};
