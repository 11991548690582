import { CFD, ETF, FX } from 'shared-modules/constants';
import PriceValueCFD from './PriceValueCFD';
import PriceValueETF from './PriceValueETF';
import PriceValueFX from './PriceValueFX';

export const PriceValues = {
  [FX]: PriceValueFX,
  [ETF]: PriceValueETF,
  [CFD]: PriceValueCFD,
};
