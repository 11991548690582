export const CLASS_NAME_PREFIX = 'tutorial';

// 以下は進む時に使うclassName 戻る時にも使う場合がある
export const tutorialNavBarAutoSelect = `${CLASS_NAME_PREFIX}navBarAutoSelect`;
export const tutorialSideMenu = `${CLASS_NAME_PREFIX}SideMenu`;
export const tutorialOrderTable = `${CLASS_NAME_PREFIX}OrderTable`;
export const tutorialTradingView = `${CLASS_NAME_PREFIX}TradingView`;
export const tutorialSimulationTab = `${CLASS_NAME_PREFIX}SimulationTab`;
export const tutorialSimulationChart = `${CLASS_NAME_PREFIX}SimulationChart`;
export const tutorialSimulationInfo = `${CLASS_NAME_PREFIX}SimulationInfo`;
export const tutorialAddCart = `${CLASS_NAME_PREFIX}AddCart`;
export const tutorialRunNow = `${CLASS_NAME_PREFIX}RunNow`;
export const tutorialCountInput = `${CLASS_NAME_PREFIX}CountInput`;
export const tutorialAssetDetails = `${CLASS_NAME_PREFIX}AssetDetails`;
export const tutorialAssetDetailsMargin = `${CLASS_NAME_PREFIX}AssetDetailsMargin`;
export const tutorialSubmit = `${CLASS_NAME_PREFIX}Submit`;
export const tutorialOpenHome = `${CLASS_NAME_PREFIX}OpenHome`;
export const tutorialCard = `${CLASS_NAME_PREFIX}Card`;
export const tutorialCardDetail = `${CLASS_NAME_PREFIX}CardDetail`;
export const tutorialProfitLossTab = `${CLASS_NAME_PREFIX}ProfitLossTab`;
export const tutorialDetailChartArea = `${CLASS_NAME_PREFIX}DetailChartArea`;
export const tutorialSwitch = `${CLASS_NAME_PREFIX}Switch`;
export const tutorialExecutions = `${CLASS_NAME_PREFIX}Executions`;
export const tutorialExecutionsTable = `${CLASS_NAME_PREFIX}ExecutionsTable`;
export const tutorialPositions = `${CLASS_NAME_PREFIX}Positions`;
export const tutorialPositionsTable = `${CLASS_NAME_PREFIX}PositionsTable`;

// 以下は戻る時に使うclassName
export const tutorialNavBarHome = `${CLASS_NAME_PREFIX}navBarHome`;
export const tutorialOrders = `${CLASS_NAME_PREFIX}Orders`;
export const tutorialLogicSettingTab = `${CLASS_NAME_PREFIX}LogicSettingTab`;
export const tutorialPriceChartTab = `${CLASS_NAME_PREFIX}PriceChartTab`;
export const tutorialCommonModalClose = `${CLASS_NAME_PREFIX}CommonModalClose`;

// TradingViewない要素のclassName
export const priceAxis = 'price-axis';
export const tvChartContainer = 'chart-container';
