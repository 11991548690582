import React, { memo } from 'react';
import {
  BUILDER_CONFIGRATION_FLOW_MAIN_TITLE,
  BUILDER_CONFIGURATION_MENU1,
  BUILDER_CONFIGURATION_MENU2,
  BUILDER_CONFIGURATION_MENU3,
} from 'shared-modules/constants/builder';
import styles from '../steps.module.scss';

const Top = () => {
  const titleArea = (step) => {
    return (
      <div key={step?.[0]}>
        <span className={styles.step}>{step?.[0]}</span>
        <span className={styles.subTitle}>{step?.[1]}</span>
      </div>
    );
  };

  return (
    <div className={styles.topContent}>
      <div className={styles.innerContent}>
        <div className={styles.topTitle}>
          <p>{BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.ORDER}</p>
        </div>
        <div className={styles.stepContainer}>{BUILDER_CONFIGURATION_MENU1.map((step) => titleArea(step))}</div>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.topTitle}>
          <p>{BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.SETTLEMENT}</p>
        </div>
        <div className={styles.stepContainer}>{BUILDER_CONFIGURATION_MENU2.map((step) => titleArea(step))}</div>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.topTitle}>
          <p>{BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.REENTRY}</p>
        </div>
        <div className={styles.stepContainer}>{BUILDER_CONFIGURATION_MENU3.map((step) => titleArea(step))}</div>
      </div>
    </div>
  );
};

export default memo(Top);
