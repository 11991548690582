export const CHANGE_SELECTED_INSTRUMENT_ID = 'MANUAL_TRADE/CHANGE_SELECTED_INSTRUMENT_ID';

export const CHANGE_SELECTED_SIDE = 'MANUAL_TRADE/CHANGE_SELECTED_SIDE';
export const CHANGE_CHART_SIDE = 'MANUAL_TRADE/CHANGE_CHART_SIDE';
export const CHANGE_SELECTED_TRADE_METHOD = 'MANUAL_TRADE/CHANGE_SELECTED_TRADE_METHOD';

export const GET_NEWS_PERIODICALLY = 'MANUAL_TRADE/GET_NEWS_PERIODICALLY';

export const GET_NEWS_REQUEST = 'MANUAL_TRADE/GET_NEWS_REQUEST';
export const GET_NEWS_REQUEST_START_LOADING = 'MANUAL_TRADE/GET_NEWS_REQUEST_START_LOADING';
export const GET_NEWS_REQUEST_STOP_LOADING = 'MANUAL_TRADE/GET_NEWS_REQUEST_STOP_LOADING';
export const GET_NEWS_SUCCESS = 'MANUAL_TRADE/GET_NEWS_SUCCESS';

export const CHANGE_SELECTED_TABLE = 'MANUAL_TRADE/CHANGE_SELECTED_TABLE';
export const CHANGE_SELECTED_INFO_TABLE = 'MANUAL_TRADE/CHANGE_SELECTED_INFO_TABLE';

export const EXECUTIONS_TABLE_START_LOADING = 'MANUAL_TRADE/EXECUTIONS_TABLE_START_LOADING';
export const EXECUTIONS_TABLE_STOP_LOADING = 'MANUAL_TRADE/EXECUTIONS_TABLE_STOP_LOADING';
export const GET_EXECUTIONS_TABLE_DATA_REQUEST = 'MANUAL_TRADE/GET_EXECUTIONS_TABLE_DATA_REQUEST';
export const GET_EXECUTIONS_TABLE_DATA_SUCCESS = 'MANUAL_TRADE/GET_EXECUTIONS_TABLE_DATA_SUCCESS';

export const CHANGE_EXECUTIONS_TABLE_META_INFO = 'MANUAL_TRADE/CHANGE_EXECUTIONS_TABLE_META_INFO';
export const RESET_EXECUTIONS_TABLE_META_INFO = 'MANUAL_TRADE/RESET_EXECUTIONS_TABLE_META_INFO';
export const RESET_EXECUTIONS_TABLE_META_INFO_DATE = 'MANUAL_TRADE/RESET_EXECUTIONS_TABLE_META_INFO_DATE';
export const RESET_EXECUTIONS_TABLE_META_INFO_PARTIAL = 'MANUAL_TRADE/RESET_EXECUTIONS_TABLE_META_INFO_PARTIAL';

export const GET_POSITIONS_START_LOADING = 'MANUAL_TRADE/GET_POSITIONS_START_LOADING';
export const GET_POSITIONS_STOP_LOADING = 'MANUAL_TRADE/GET_POSITIONS_STOP_LOADING';

export const CHANGE_POSITIONS_TABLE_META_INFO = 'MANUAL_TRADE/CHANGE_POSITIONS_TABLE_META_INFO';
export const RESET_POSITION_TABLE_META_INFO = 'MANUAL_TRADE/RESET_POSITION_TABLE_META_INFO';

export const CREATE_CLOSE_ORDER_START_LOADING = 'MANUAL_TRADE/CREATE_CLOSE_ORDER_START_LOADING';
export const CREATE_CLOSE_ORDER_STOP_LOADING = 'MANUAL_TRADE/CREATE_CLOSE_ORDER_STOP_LOADING';
export const CREATE_CLOSE_ORDER_REQUEST = 'MANUAL_TRADE/CREATE_CLOSE_ORDER_REQUEST';
export const CLEAR_STATUS_AFTER_CLOSING = 'MANUAL_TRADE/CLEAR_STATUS_AFTER_CLOSING';

export const DELETE_POSITIONS_ORDER_REQUEST = 'MANUAL_TRADE/DELETE_POSITIONS_ORDER_REQUEST';

export const ORDERS_TABLE_START_LOADING = 'MANUAL_TRADE/ORDERS_TABLE_START_LOADING';
export const ORDERS_TABLE_STOP_LOADING = 'MANUAL_TRADE/ORDERS_TABLE_STOP_LOADING';
export const GET_ORDERS_TABLE_DATA_REQUEST = 'MANUAL_TRADE/GET_ORDERS_TABLE_DATA_REQUEST';
export const GET_ORDERS_TABLE_DATA_SUCCESS = 'MANUAL_TRADE/GET_ORDERS_TABLE_DATA_SUCCESS';

export const CHANGE_ORDERS_TABLE_META_INFO = 'MANUAL_TRADE/CHANGE_ORDERS_TABLE_META_INFO';
export const RESET_ORDER_TABLE_META_INFO = 'MANUAL_TRADE/RESET_ORDER_TABLE_META_INFO';

export const CHANGE_SELECTED_ORDER_TYPE = 'MANUAL_TRADE/CHANGE_SELECTED_ORDER_TYPE';

export const CHANGE_CREATE_ORDER_VALUES = 'MANUAL_TRADE/CHANGE_CREATE_ORDER_VALUES';
export const RESET_CREATE_ORDER_VALUES = 'MANUAL_TRADE/RESET_CREATE_ORDER_VALUES';
export const RESET_CREATE_ORDERS_NON_COMMON_VALUES = 'MANUAL_TRADE/RESET_CREATE_ORDERS_NON_COMMON_VALUES';
export const CHANGE_CREATE_ORDER_VALIDATION_ERRORS = 'MANUAL_TRADE/CHANGE_CREATE_ORDER_VALIDATION_ERRORS';

export const RESET_CREATE_ORDER_ERRORS = 'MANUAL_TRADE/RESET_CREATE_ORDER_ERRORS';
export const RESET_CHANGE_ORDER_VALIDATION_ERRORS = 'MANUAL_TRADE/RESET_CHANGE_ORDER_VALIDATION_ERRORS';

export const ORDER_INFO_REQUEST_START_LOADING = 'MANUAL_TRADE/ORDER_INFO_REQUEST_START_LOADING';
export const ORDER_INFO_REQUEST_STOP_LOADING = 'MANUAL_TRADE/ORDER_INFO_REQUEST_STOP_LOADING';
export const GET_ORDER_INFO_REQUEST = 'MANUAL_TRADE/GET_ORDER_INFO_REQUEST';
export const GET_ORDER_INFO_SUCCESS = 'MANUAL_TRADE/GET_ORDER_INFO_SUCCESS';

export const ORDER_CHANGE_START_LOADING = 'MANUAL_TRADE/ORDER_CHANGE_START_LOADING';
export const ORDER_CHANGE_STOP_LOADING = 'MANUAL_TRADE/ORDER_CHANGE_STOP_LOADING';
export const CHANGE_ORDER_REQUEST = 'MANUAL_TRADE/CHANGE_ORDER_REQUEST';

export const DELETE_ORDER_REQUEST = 'MANUAL_TRADE/DELETE_ORDER_REQUEST';

export const CHANGE_SHARED_META_INFO = 'MANUAL_TRADE/CHANGE_SHARED_META_INFO';

export const CREATE_ORDER_START_LOADING = 'MANUAL_TRADE/CREATE_ORDER_START_LOADING';
export const CREATE_ORDER_STOP_LOADING = 'MANUAL_TRADE/CREATE_ORDER_STOP_LOADING';
export const CREATE_MARKET_ORDER_REQUEST = 'MANUAL_TRADE/CREATE_MARKET_ORDER_REQUEST';
export const CREATE_FIFO_ORDER_REQUEST = 'MANUAL_TRADE/CREATE_FIFO_ORDER_REQUEST';
export const CREATE_STANDARD_ORDER_REQUEST = 'MANUAL_TRADE/CREATE_STANDARD_ORDER_REQUEST';
export const CREATE_OCO_ORDER_REQUEST = 'MANUAL_TRADE/CREATE_OCO_ORDER_REQUEST';
export const CREATE_IFD_ORDER_REQUEST = 'MANUAL_TRADE/CREATE_IFD_ORDER_REQUEST';
export const CREATE_IFO_ORDER_REQUEST = 'MANUAL_TRADE/CREATE_IFO_ORDER_REQUEST';

export const UPDATE_CHANGE_ORDER_VALIDATION_ERRORS = 'MANUAL_TRADE/UPDATE_CHANGE_ORDER_VALIDATION_ERRORS';

export const UPDATE_CLOSE_ORDER_VALIDATION_ERRORS = 'MANUAL_TRADE/UPDATE_CLOSE_ORDER_VALIDATION_ERRORS';

export const CHANGE_POSITION_DETAILS_ORDER_LOGIC = 'MODALS/CHANGE_POSITION_DETAILS_ORDER_LOGIC';

export const CLOSE_MULTIPLE_POSITIONS_REQUEST = 'MANUAL_TRADE/CLOSE_MULTIPLE_POSITIONS_REQUEST';
export const SAVE_DELETING_POSITIONS = 'MANUAL_TRADE/SAVE_DELETING_POSITIONS';

export const SAVE_SELECTED_TABLE_ROWS = 'MANUAL_TRADE/SAVE_SELECTED_TABLE_ROWS';

export const CLOSE_FIFO_SIDE_POSITIONS_REQUEST = 'MANUAL_TRADE/CLOSE_FIFO_SIDE_POSITIONS_REQUEST';
export const CLOSE_FIFO_SIDE_POSITIONS_START_LOADING = 'MANUAL_TRADE/CLOSE_FIFO_SIDE_POSITIONS_START_LOADING';
export const CLOSE_FIFO_SIDE_POSITIONS_STOP_LOADING = 'MANUAL_TRADE/CLOSE_FIFO_SIDE_POSITIONS_STOP_LOADING';
export const SWAP_TRANSFER_REQUEST = 'MANUAL_TRADE/SWAP_TRANSFER_REQUEST';
export const CHANGE_TRADE_METHOD_TYPE = 'MANUAL_TRADE/CHANGE_TRADE_METHOD_TYPE';
