import { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import {
  AP_GROUP_SOURCES,
  CARD_STATUS_OPTIONS,
  CARD_TYPE_OPTIONS,
  CFD,
  COLORS,
  ETF,
  FX,
  MIXED,
  SORT_TYPE_OPTIONS,
} from 'shared-modules/constants';
import { ALL_SERVICES, ASSET_TYPES, ASSET_TYPE_OPTIONS } from 'shared-modules/constants/core';
import { KEY_FOR_SELECTED_PORTFOLIO_INSTRUMENTS } from 'shared-modules/constants/portfolio';
import { setListSettings } from 'shared-modules/redux/actions/portfolioActions';
import { sendNotificationError, sendNotificationSuccess } from 'shared-modules/redux/actions/notificationActions';
import { getServiceName, makeParentKey } from 'shared-modules/utils';
import { makeNotificationErrorParams } from 'shared-modules/utils/service';
import { combineWithInstruments, saveDefaultValuesFromLocalStorage } from 'shared-modules/services';
import {
  useCreatePortfolioCard,
  usePortfolioSort,
  usePortfolioCardsFilter,
  createGroupStatusFilter,
} from 'shared-modules/services/hooks/index';
import {
  useFilterFunc,
  useGroupFilter,
  useFilter,
  useGroupedPortfolios,
  useInstrumentOptions,
} from 'shared-modules/hooks/portfolio';
import classNames from 'classnames';
import { useAccountInfo } from 'shared-modules/hooks/useAccountInfo';
import { closeTechLogicInfoModal, openTechLogicInfoModal } from '../../../../redux/actions';
import Select from '../../../../components/common/Select';
import PortfolioCard from '../PortfolioCard/index';
import CheckboxWrapper from '../../../../appWrappers/ModalContainer/components/PortfolioInstrumentSettings/components/CheckboxWrapper';
import CustomButton from '../../../../components/CustomButton';
import { Tabs } from '../../../../components';
import TechnicalBuilderGroupInfo from '../TechnicalBuilderGroupInfo';
import styles from './portfolios.module.scss';

const Portfolios = () => {
  const dispatch = useDispatch();
  const [instrumentOptionsModalVisible, setInstrumentOptionsModalVisible] = useState(false);
  const [selectedTechBuilderId, setSelectedTechBuilderId] = useState(null);
  const [selectedTechServiceId, setSelectedTechServiceId] = useState(null);
  const [selectedGroupKey, setSelectedGroupKey] = useState(null);
  // TODO: matsuzaki 一旦trueで定義。後ほどisGroupedは削除する。
  const isGrouped = true;

  // get raw data
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const fxApGroups = useSelector((state) => state.portfolio.apGroupsData[FX]);
  const fxPositions = useSelector((state) => state.currencies.positions[FX]);
  const etfApGroups = useSelector((state) => state.portfolio.apGroupsData[ETF]);
  const etfPositions = useSelector((state) => state.currencies.positions[ETF]);
  const cfdApGroups = useSelector((state) => state.portfolio.apGroupsData[CFD]);
  const cfdPositions = useSelector((state) => state.currencies.positions[CFD]);

  const techApGroups = useSelector((state) => state.tech.techGroupsData);
  const [instrumentsOptions, setInstrumentsOptions] = useState(useInstrumentOptions());

  const [localInstruments, setLocalInstruments] = useState(instrumentsOptions);

  const handleCheck = (item) => {
    const index = localInstruments.findIndex((instrument) => item.value === instrument.value);
    const copy = [...localInstruments];
    copy[index] = { ...item, checked: !item.checked };
    setLocalInstruments(copy);
  };

  const isAllSelectedByServiceId = useCallback(
    (serviceId) => localInstruments.filter((item) => item.serviceId === serviceId).every(({ checked }) => checked),
    [localInstruments],
  );

  const handleToggleSelectAllInstruments = (serviceId) => {
    const isAllSelected = isAllSelectedByServiceId(serviceId);
    setLocalInstruments(
      localInstruments.map((item) => {
        if (item.serviceId === serviceId) return { ...item, checked: !isAllSelected };
        return item;
      }),
    );
  };

  const apGroups = useMemo(
    () => [...fxApGroups, ...etfApGroups, ...cfdApGroups],
    [etfApGroups, fxApGroups, cfdApGroups],
  );
  const positions = useMemo(
    () => [...fxPositions, ...etfPositions, ...cfdPositions],
    [etfPositions, fxPositions, cfdPositions],
  );

  const shapeTechApGroup = useMemo(() => {
    const shape = techApGroups.map((t) => {
      const getApGroups = (serviceId) => {
        if (serviceId === FX) {
          return fxApGroups;
        }
        if (serviceId === ETF) {
          return etfApGroups;
        }
        if (serviceId === CFD) {
          return cfdApGroups;
        }
        return [];
      };
      const target = getApGroups(t?.serviceId)
        .filter((a) => t.apGroupIdList?.includes(a.id))
        .map((m) => ({ ...m, currency: m.instrumentId }));

      const plInfo = target.reduce(
        (acc, cur) => {
          if (cur.positionQuantity !== '-') {
            acc.count += Number(cur.positionQuantity);
          }
          acc.realizedProfitLoss += Number(cur.totalRealizedPnl);
          return acc;
        },
        {
          count: 0,
          realizedProfitLoss: 0,
        },
      );

      return {
        ...t,
        groupName: t.name,
        currency: t.parameters.instrumentId,
        type: '自動売買',
        key: `${t.technicalBuilderId} - ${t.serviceId}`,
        ...plInfo,
      };
    });

    return combineWithInstruments(shape, instrumentList, 'currency');
  }, [techApGroups, fxApGroups, etfApGroups, cfdApGroups, instrumentList]);

  const allPortfolio = combineWithInstruments(
    useCreatePortfolioCard({ apGroups, positions }),
    instrumentList,
    'currency',
  );

  // filter and sort
  const useSetDropdownValue = (key) =>
    useCallback(
      (value) => {
        dispatch(setListSettings(MIXED, { [key]: value }));
      },
      [key],
    );

  const { tradeTypeFilter, sortBy, statusFilter, assetTypeFilter } = useSelector(
    (state) => state.portfolio.listSettings[MIXED],
  );

  const getStorageItems = useInstrumentOptions();
  const instrumentFilter = usePortfolioCardsFilter(assetTypeFilter?.value, getStorageItems);

  const setAssetFilter = useSetDropdownValue('assetTypeFilter');
  const setTradeTypeFilter = useSetDropdownValue('tradeTypeFilter');
  const setSortBy = useSetDropdownValue('sortBy');
  const setStatusFilter = useSetDropdownValue('statusFilter');

  const { assetFilterFunc, tradeTypeFilterFunc, statusFilterFunc, instrumentFilterFunc } = useFilter({
    assetTypeFilter,
    tradeTypeFilter,
    statusFilter,
    instrumentFilter,
  });
  const groupFilters = useMemo(
    () => [assetFilterFunc, tradeTypeFilterFunc, instrumentFilterFunc],
    [assetFilterFunc, instrumentFilterFunc, tradeTypeFilterFunc],
  );
  const filterFunc = useFilterFunc([assetFilterFunc, tradeTypeFilterFunc, statusFilterFunc, instrumentFilterFunc]);
  const groupFilter = useGroupFilter(groupFilters);
  const groupStatusFilter = createGroupStatusFilter(statusFilter);

  const [grouped, list] = useGroupedPortfolios({
    isGrouped,
    portfolios: allPortfolio,
    techApGroups: shapeTechApGroup,
  });

  let cardList = usePortfolioSort({
    cardList: [...grouped.filter(groupFilter).filter(groupStatusFilter), ...list.filter(filterFunc)],
    sortBy,
  });

  const accountInfo = useAccountInfo();

  const notAvailableServices = useMemo(
    () => ALL_SERVICES.filter((serviceId) => accountInfo[serviceId].isNotAvailable),
    [accountInfo],
  );

  cardList = cardList
    .filter((item) => !(item.children && item.children.length === 0))
    .filter((item) => !notAvailableServices.includes(item.serviceId))
    .filter(
      (item) => !item.serviceIds || item.serviceIds.every((serviceId) => !notAvailableServices.includes(serviceId)),
    );

  const anyInstrumentSelected = useMemo(() => {
    if (assetTypeFilter.value === ASSET_TYPES.NONE.KEY) return localInstruments.some((item) => item.checked);
    return localInstruments.filter(({ serviceId }) => serviceId === assetTypeFilter.value).some((item) => item.checked);
  }, [assetTypeFilter.value, localInstruments]);

  const getTabContent = (serviceId) => (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2 }}>
        <div>
          <CustomButton onClick={() => handleToggleSelectAllInstruments(serviceId)} isSmall>
            {isAllSelectedByServiceId(serviceId) ? '全てを非選択' : '全てを選択'}
          </CustomButton>
        </div>
      </div>
      <div className="grid grid--1x3 mb-3">
        {localInstruments
          .filter((item) => item.serviceId === serviceId)
          .map((item) => (
            <CheckboxWrapper
              key={item.value}
              onChangeHandler={() => handleCheck(item)}
              item={item}
              hasError={false}
              resetError={() => {}}
            />
          ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CustomButton
          color={COLORS.RED}
          onClick={() => {
            if (!anyInstrumentSelected) {
              dispatch(sendNotificationError({ message: '少なくとも一つの銘柄を選択してください。' }));
              return;
            }

            setInstrumentsOptions(() => localInstruments);
            setInstrumentOptionsModalVisible(() => false);
            dispatch(sendNotificationSuccess({ message: '保存しました。' }));
            saveDefaultValuesFromLocalStorage({
              key: KEY_FOR_SELECTED_PORTFOLIO_INSTRUMENTS,
              value: JSON.stringify(localInstruments),
            });
          }}
        >
          保存
        </CustomButton>
      </div>
    </div>
  );
  // TODO memo
  const tabItems = ALL_SERVICES.map((serviceId) => ({
    id: serviceId,
    label: getServiceName(serviceId),
    children: getTabContent(serviceId),
    isVisuallyDisabled: accountInfo[serviceId].isNotAvailable,
    disabled: assetTypeFilter.value !== 'none' && assetTypeFilter.value !== serviceId,
  }));

  const cardOnClick = useCallback(
    (item) => {
      if (item.sourceType === AP_GROUP_SOURCES.TECH.KEY) {
        setSelectedTechBuilderId(item.technicalBuilderId);
        setSelectedTechServiceId(item.serviceId);
        dispatch(openTechLogicInfoModal());
      } else {
        setSelectedGroupKey(makeParentKey(item));
      }
    },
    [dispatch],
  );

  const isOpenTechLogicInfo = useSelector((state) => state.modals.techLogicInfo.isOpen);

  const closeTechModal = useCallback(() => {
    setSelectedTechBuilderId(null);
    setSelectedTechServiceId(null);
    dispatch(closeTechLogicInfoModal());
  }, [dispatch]);

  const handleHideGroupModal = useCallback(() => {
    setSelectedGroupKey(null);
  }, []);

  const selectedGroup = useMemo(
    () => cardList.find((i) => makeParentKey(i) === selectedGroupKey),
    [cardList, selectedGroupKey],
  );
  const selectedTech = useMemo(
    () =>
      techApGroups.find((i) => i.technicalBuilderId === selectedTechBuilderId && i.serviceId === selectedTechServiceId),
    [techApGroups, selectedTechBuilderId, selectedTechServiceId],
  );

  const activeAssetItems = useMemo(() => {
    const disabledServiceIds = ALL_SERVICES.filter((serviceId) => accountInfo[serviceId].isNotAvailable === true);
    if (!disabledServiceIds) return ASSET_TYPE_OPTIONS;
    return ASSET_TYPE_OPTIONS.filter((item) => !disabledServiceIds.includes(item.value));
  }, [accountInfo]);

  const [instrumentAsset, setInstrumentAsset] = useState(
    ALL_SERVICES.find((s) => !accountInfo[s].isNotAvailable) ?? FX,
  );

  const handleTabChange = useCallback(
    (serviceId) => {
      const errorParams = makeNotificationErrorParams({ accountInfo, serviceId });
      if (errorParams) {
        dispatch(sendNotificationError(errorParams));
      } else {
        setInstrumentAsset(serviceId);
      }
    },
    [accountInfo, dispatch],
  );

  useEffect(() => {
    if (accountInfo[assetTypeFilter.value] && accountInfo[assetTypeFilter.value].isNotAvailable) {
      setAssetFilter({ label: ASSET_TYPES.NONE.NAME, value: ASSET_TYPES.NONE.KEY });
    }
  }, [accountInfo, assetTypeFilter, setAssetFilter]);

  return (
    <div>
      <header className={styles.headerRow}>
        <div className="d-flex">
          <Select
            label="アセット"
            items={activeAssetItems}
            onItemSelect={setAssetFilter}
            selectedItem={assetTypeFilter}
          />
          <Select
            label="注文方法"
            items={CARD_TYPE_OPTIONS}
            onItemSelect={setTradeTypeFilter}
            selectedItem={tradeTypeFilter}
          />
          <Select
            label="銘柄"
            onItemSelect={() => {
              setInstrumentOptionsModalVisible(true);
              setLocalInstruments([...instrumentsOptions]);
            }}
            selectedItem={instrumentFilter.label}
          />
          <Select
            label="稼働状況"
            items={CARD_STATUS_OPTIONS}
            onItemSelect={setStatusFilter}
            selectedItem={statusFilter}
          />
          <Select label="並び替え" items={SORT_TYPE_OPTIONS} onItemSelect={setSortBy} selectedItem={sortBy} />
        </div>
      </header>
      <div style={{ height: 'auto' }}>
        {cardList?.length ? (
          <div className="cardGrid">
            {cardList.map((item) => (
              <PortfolioCard key={item.key} cardData={item} onClick={() => cardOnClick(item)} isGrouped={isGrouped} />
            ))}
          </div>
        ) : (
          <div className={styles.emptyDataMock}>
            <div className={styles.emptyDataTitle}>表示対象がありません</div>
          </div>
        )}
      </div>

      <Modal
        contentClassName="popup"
        show={instrumentOptionsModalVisible}
        onHide={() => setInstrumentOptionsModalVisible(false)}
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popup__header" closeButton closeVariant="white">
          <Modal.Title>
            <p style={{ paddingLeft: 10 }}>銘柄表示設定</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="popup__body">
          <Tabs
            containerClassName={styles.tabs}
            items={tabItems}
            activeKey={assetTypeFilter.value === ASSET_TYPES.NONE.KEY ? instrumentAsset : assetTypeFilter.value}
            onChange={handleTabChange}
          />
        </Modal.Body>
      </Modal>
      {selectedGroup?.children?.length > 1 && (
        <Modal
          contentClassName="popup popup--fit-content"
          show={selectedGroupKey != null}
          onHide={handleHideGroupModal}
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popup__header" closeButton closeVariant="white">
            <Modal.Title>
              <p style={{ paddingLeft: 10 }}>{selectedGroup?.parentName}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="popup__body">
            <div style={{ padding: '60px 10px 100px' }}>
              <div className="text-center" style={{ fontSize: '2rem', marginBottom: 30 }}>
                <p>詳細を見る自動売買グループをご選択ください</p>
              </div>
              <div className={styles.over}>
                <div className={classNames(styles.portfolios, 'grid--card-auto-fit-row')}>
                  {selectedGroup?.children?.map?.((item) => (
                    <PortfolioCard key={item.key} cardData={item} />
                  ))}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <TechnicalBuilderGroupInfo
        isOpen={isOpenTechLogicInfo}
        source={selectedTech}
        portfolioData={allPortfolio}
        onClose={closeTechModal}
      />
    </div>
  );
};

export default Portfolios;
