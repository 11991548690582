import React, { memo, forwardRef, useCallback, useState, useEffect, useRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import ja from 'date-fns/locale/ja';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { checkIfWeekday } from 'shared-modules/services';
import styles from './customDatePicker.module.scss';
import './index.scss';

registerLocale('ja', ja);

const FULL_DATE_REG =
  // eslint-disable-next-line max-len
  /^[1-2]\d{0,3}$|^[1-2]\d{3}\/[0-1]?$|^[1-2]\d{3}\/(([0][1-9])|([1][0-2]))\/?$|^[1-2]\d{3}\/(([0][1-9])|([1][0-2]))\/([0-3]|([0][1-9]|[1-2][0-9]|[3][0-1]))$/;
const YEAR_WITH_MONTH_PART_REG = /^[1-2]\d{3}[0-1]$/;
const YEAR_WITH_SLASH_REG = /^[1-2]\d{3}\/$/;
const YEAR_WITH_MONTH_AND_DAY_PART_REG = /^[1-2]\d{3}\/([0][1-9]|[1][0-2])[0-3]$/;
const YEAR_WITH_MONTH_AND_SLASH_REG = /^[1-2]\d{3}\/([0][1-9]|[1][0-2])\/$/;

const popperAllowedPlacements = [
  'auto',
  'auto-left',
  'auto-right',
  'bottom',
  'bottom-end',
  'bottom-start',
  'left',
  'left-end',
  'left-start',
  'right',
  'right-end',
  'right-start',
  'top',
  'top-end',
  'top-start',
];

const CustomDatePickerInput = forwardRef(({ isDisabled, width, isThin, isTaller, onChange, ...otherProps }, ref) => {
  const handleChange = useCallback(
    (val) => {
      const {
        target: { value },
      } = val;
      let updatedValue = String(value);
      if (updatedValue.match(YEAR_WITH_MONTH_PART_REG)) {
        updatedValue = `${updatedValue.slice(0, 4)}/${updatedValue.slice(4)}`;
      }
      if (updatedValue.match(YEAR_WITH_SLASH_REG)) {
        updatedValue = updatedValue.slice(0, 4);
      }
      if (updatedValue.match(YEAR_WITH_MONTH_AND_DAY_PART_REG)) {
        updatedValue = `${updatedValue.slice(0, 7)}/${updatedValue.slice(7)}`;
      }
      if (updatedValue.match(YEAR_WITH_MONTH_AND_SLASH_REG)) {
        updatedValue = updatedValue.slice(0, 7);
      }
      if (!updatedValue.match(FULL_DATE_REG) && updatedValue !== '') {
        return;
      }
      onChange({ target: { value: updatedValue } });
    },
    [onChange],
  );
  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...otherProps} onChange={handleChange} ref={ref} style={{ width }} />
      <i
        className={classNames(
          'material-icons',
          styles.calendarIcon,
          { [styles.isDisabled]: isDisabled },
          { [styles.isTaller]: isTaller },
          { [styles.isThin]: isThin },
        )}
      >
        event_note
      </i>
    </>
  );
});

CustomDatePickerInput.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  isThin: PropTypes.bool.isRequired,
  isTaller: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

CustomDatePickerInput.defaultProps = {
  onChange: () => {},
};

const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => (
  <div className={styles.headerWrapper}>
    <i role="button" aria-hidden className={classNames('material-icons', styles.arrowIcon)} onClick={decreaseMonth}>
      keyboard_arrow_left
    </i>
    <span>{moment(date).format('YYYY M月')}</span>
    <i role="button" aria-hidden className={classNames('material-icons', styles.arrowIcon)} onClick={increaseMonth}>
      keyboard_arrow_right
    </i>
  </div>
);

CustomHeader.propTypes = {
  date: PropTypes.shape({}).isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
};

const CustomDay = (day) => (
  <div className={styles.dayWrapper}>
    <div className={styles.dayStyle}>{day}</div>
    <div className={styles.selectedSquare} />
  </div>
);

const formatWeekDay = (day) => day[0];

const CustomDatePicker = ({
  isDisabled,
  isLighter,
  date,
  onChange,
  placeholder,
  width,
  minDate,
  maxDate,
  isTaller,
  isThin,
  popperPlacement,
  disableWeekends,
  disableSundays,
  name,
  errorMessages,
  className: outerClassname,
}) => {
  const [error, changeError] = useState(null);
  const id = useRef(uuid()).current;

  useEffect(() => {
    changeError(errorMessages.find((errorItem) => errorItem.inputName === name));
  }, [errorMessages, changeError, name]);
  const setDayClassname = useCallback(() => classNames({ isLighter }), [isLighter]);
  const filterDate = useCallback(
    (filteringDate) => {
      if (disableWeekends || disableSundays) {
        return checkIfWeekday(filteringDate, disableSundays);
      }
      return true;
    },
    [disableWeekends, disableSundays],
  );

  return (
    <>
      <div className={classNames(styles.wrapper, outerClassname)} data-for={id} data-tip={error && error.errorMessage}>
        <DatePicker
          selected={date}
          onChange={onChange}
          showPopperArrow={false}
          popperPlacement={popperPlacement}
          className={classNames(
            styles.datePickerWrapper,
            { [styles.isDisabled]: isDisabled },
            { [styles.isError]: error },
            { [styles.isLighter]: isLighter },
            { [styles.isTaller]: isTaller },
            { [styles.isThin]: isThin },
          )}
          popperClassName={classNames(styles.popper, { [styles.isLighter]: isLighter })}
          calendarClassName={classNames(styles.calendar, { [styles.isLighter]: isLighter })}
          renderCustomHeader={CustomHeader}
          renderDayContents={CustomDay}
          placeholderText={placeholder}
          dateFormat="yyyy/MM/dd"
          customInput={
            <CustomDatePickerInput isDisabled={isDisabled} width={width} isThin={isThin} isTaller={isTaller} />
          }
          formatWeekDay={formatWeekDay}
          filterDate={filterDate}
          disabled={isDisabled}
          dayClassName={setDayClassname}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          locale="ja"
        />
      </div>
      {error && error.errorMessage && (
        <ReactTooltip
          id={id}
          place="bottom"
          type="dark"
          effect="solid"
          multiline
          data-html
          insecure
          className={styles.tooltip}
        />
      )}
    </>
  );
};

CustomDatePicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLighter: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  isTaller: PropTypes.bool,
  isThin: PropTypes.bool,
  popperPlacement: PropTypes.oneOf(popperAllowedPlacements),
  disableWeekends: PropTypes.bool,
  disableSundays: PropTypes.bool,
  name: PropTypes.string,
  errorMessages: PropTypes.arrayOf(PropTypes.shape({})),
};

CustomDatePicker.defaultProps = {
  date: '',
  isDisabled: false,
  isLighter: false,
  placeholder: 'YYYY/MM/DD',
  className: '',
  width: 140,
  minDate: '',
  maxDate: '',
  isTaller: false,
  isThin: false,
  popperPlacement: 'bottom-end',
  disableWeekends: false,
  disableSundays: false,
  name: null,
  errorMessages: [],
};

export default memo(CustomDatePicker);
