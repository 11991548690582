import { call, put, select } from 'redux-saga/effects';
import { ORDER_TYPES, SUCCESS_NOTIFICATION_MESSAGE } from '../../../constants/manualTrade';
import { BUY_SELL_MAIN } from '../../../constants';
import { createIFDOrder } from '../../../api/manualTradeApi';
import { createOrderStartLoading, createOrderStopLoading } from '../../actions/manualTradeActions';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { errorHandler } from '../errorSaga';
import { createDateString } from '../../../services';

function* createIFDOrderRequestHandler() {
  try {
    yield put(createOrderStartLoading());
    const serviceId = yield select((state) => state.auth.serviceId);
    const instrumentId = yield select((state) => state.manualTrade.selectedInstrumentId[serviceId]);
    const side = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.BUY_SELL],
    );
    const type = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.ORDER_METHOD],
    );
    const quantity = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.COUNT],
    );
    const price = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.PRICE],
    );
    const expirationType = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.EXPIRATION_TYPE],
    );
    const selectedDate = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.SELECTED_DATE],
    );
    const selectedTime = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.SELECTED_TIME],
    );
    const settlementType = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.SETTLEMENT_ORDER_METHOD],
    );
    const settlementPrice = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.SETTLEMENT_PRICE],
    );
    const settlementExpirationType = yield select(
      (state) =>
        state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.SETTLEMENT_EXPIRATION_TYPE],
    );
    const settlementSelectedDate = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.SETTLEMENT_SELECTED_DATE],
    );
    const settlementSelectedTime = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFD.name][ORDER_TYPES.IFD.inputs.SETTLEMENT_SELECTED_TIME],
    );

    const expireTime = createDateString(selectedDate, selectedTime);
    const settlementExpireTime = createDateString(settlementSelectedDate, settlementSelectedTime);

    const requestBody = {
      instrumentId,
      quantity,
      newOrder: {
        side,
        type,
        price,
        expireType: expirationType,
        expireTime,
      },
      closeOrder: {
        side: side === BUY_SELL_MAIN.SELL.ID ? BUY_SELL_MAIN.BUY.ID : BUY_SELL_MAIN.SELL.ID,
        type: settlementType,
        price: settlementPrice,
        expireType: settlementExpirationType,
        expireTime: settlementExpireTime,
      },
    };

    yield call(createIFDOrder, { requestBody, serviceId });
    yield put(sendNotificationSuccess({ message: SUCCESS_NOTIFICATION_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e, form: ORDER_TYPES.IFD.name });
  } finally {
    yield put(createOrderStopLoading());
  }
}

export default createIFDOrderRequestHandler;
