import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styles from './tooltip.module.scss';

const OFFSET = 6;

export const Tooltip = ({ content, cover, children }) => {
  const targetRef = useRef(null);
  const contentRef = useRef(null);
  const [style, setStyle] = useState({});
  const [visible, setVisible] = useState(false);
  const [delayVisible, setDelayVisible] = useState(visible);

  const calculateStyles = useCallback(() => {
    const targetRect = targetRef.current?.getBoundingClientRect();
    const contentRect = contentRef.current?.getBoundingClientRect();
    if ((targetRect == null || contentRect) == null) {
      return;
    }
    let top;
    if (cover) {
      const baseY = targetRect.y + targetRect.height / 2;
      const contentHalfHeight = contentRect.height / 2;
      top = contentHalfHeight <= baseY ? baseY - contentHalfHeight : 0;
    } else {
      const contentHeight = contentRect.height;
      const baseY = targetRect.y;
      top = contentHeight + OFFSET <= baseY ? baseY - contentHeight - OFFSET : baseY + targetRect.height + OFFSET;
    }
    const baseX = targetRect.left + targetRect.width / 2;
    const contentHalfWidth = contentRect.width / 2;
    const left = contentHalfWidth <= baseX ? baseX - contentHalfWidth : 0;
    setStyle((oldStyle) => ({ ...oldStyle, top, left }));
  }, [cover]);

  const handleIn = useCallback(() => {
    setVisible(true);
  }, []);

  const handleOut = useCallback(() => {
    setVisible(false);
  }, []);

  const overlay = useMemo(() => {
    if (visible || delayVisible) {
      return createPortal(
        <div className={styles.overlay} style={style}>
          <div ref={contentRef} className={styles.content}>
            {content}
          </div>
        </div>,
        document.body,
      );
    }
    return null;
  }, [visible, delayVisible, style, content]);

  useEffect(() => {
    calculateStyles();
  }, [calculateStyles]);

  useEffect(() => {
    if (delayVisible) {
      document.addEventListener('wheel', calculateStyles);
    }
    return () => {
      if (delayVisible) {
        document.removeEventListener('wheel', calculateStyles);
      }
    };
  }, [delayVisible, calculateStyles]);

  useEffect(() => {
    calculateStyles();
    setTimeout(() => setDelayVisible(visible), 300);
  }, [visible, calculateStyles]);

  useEffect(() => {
    const visibility = delayVisible ? 'visible' : 'hidden';
    const opacity = delayVisible ? 1 : 0;
    setStyle((oldStyle) => ({ ...oldStyle, visibility, opacity }));
  }, [delayVisible]);

  return (
    <div className={styles.container}>
      <div
        ref={targetRef}
        className={styles.target}
        onFocus={handleIn}
        onMouseOver={handleIn}
        onBlur={handleOut}
        onMouseOut={handleOut}
      >
        {children}
      </div>
      {overlay}
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cover: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
  content: undefined,
  cover: false,
};
