import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isSameDate } from 'shared-modules/utils/profitLossUtils';
import { ColoredPriceText } from '../Texts';
import styles from './pLCalendar.module.scss';

const PLCalendar = ({ month, data, currentFrontDate }) => {
  const firstDayofMonth = moment(month.label + 1, 'YYYY年MM月DD');
  const offset = firstDayofMonth.weekday();
  const daysInMonth = firstDayofMonth.daysInMonth();

  const cells = [];

  for (let i = 0; i < offset; i += 1) cells.push(null);

  for (let day = 1; day <= daysInMonth; day += 1) cells.push({ day, value: null });

  const { length } = cells;

  for (let j = 0; j < 42 - length; j += 1) cells.push(null);

  const renderCell = (cell) => {
    if (!cell) return null;

    let dayElement = cell?.day;
    let plElement = <div>&nbsp;</div>;

    if (cell.day) {
      const dayStr = cell.day > 9 ? String(cell.day) : `0${cell.day}`;
      const key = firstDayofMonth.format('YYYY-MM-') + dayStr;
      const result = data?.find((o) => o.time === key);

      if (isSameDate(currentFrontDate, key))
        dayElement = (
          <div className="d-flex justify-content-center">
            <div className={styles.badge}>{cell?.day}</div>
          </div>
        );
      if (result)
        plElement = <ColoredPriceText className={styles.price} value={result.value} withoutSymbol allowZero />;
    }
    return (
      <>
        {dayElement}
        {plElement}
      </>
    );
  };

  return (
    <div className={classNames(styles.container, 'text-start')}>
      <table className="text-center">
        <thead>
          <tr>
            <th className={styles.sunday}>日</th>
            <th className={styles.weekdayHeader}>月</th>
            <th className={styles.weekdayHeader}>火</th>
            <th className={styles.weekdayHeader}>水</th>
            <th className={styles.weekdayHeader}>木</th>
            <th className={styles.weekdayHeader}>金</th>
            <th className={styles.saturday}>土</th>
          </tr>
        </thead>
        <tbody>
          {[0, 1, 2, 3, 4, 5].map((week) => (
            <tr key={String(week)}>
              <td className={styles.sunday}>{renderCell(cells[week * 7])}</td>
              <td>{renderCell(cells[week * 7 + 1])}</td>
              <td>{renderCell(cells[week * 7 + 2])}</td>
              <td>{renderCell(cells[week * 7 + 3])}</td>
              <td>{renderCell(cells[week * 7 + 4])}</td>
              <td>{renderCell(cells[week * 7 + 5])}</td>
              <td className={styles.saturday}>{renderCell(cells[week * 7 + 6])}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PLCalendar.propTypes = {
  month: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentFrontDate: PropTypes.string,
};

PLCalendar.defaultProps = {
  currentFrontDate: undefined,
};

export default PLCalendar;
