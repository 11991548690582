import { OPEN_SOCKET_ERROR_MODAL, CLOSE_SOCKET_ERROR_MODAL } from '../actionConstants/socketConstants';

export const initialState = {
  hasError: false,
  isPublic: false,
};

// todo: probably merge with error reducer when start refactoring
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case OPEN_SOCKET_ERROR_MODAL: {
      const { isPublic } = payload;
      return {
        ...state,
        hasError: true,
        isPublic,
      };
    }
    case CLOSE_SOCKET_ERROR_MODAL: {
      return {
        ...state,
        hasError: false,
      };
    }
    default: {
      return state;
    }
  }
};
