import React, { memo, useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AppHeaderHeightContext } from '../../../../contexts';
import { push } from '../../../../redux/actions';
import Logo from '../Logo';
import HeaderInfo from '../HeaderInfo';
import styles from './header.module.scss';
import NewsRow from '../../../../screens/ManualTrade/components/NewsRow';
import { HOME } from '../../../../constants';
import { InvastAffiliate } from '../InvastAffiliate';

const Header = ({ withoutSideBar, withHeaderInfo, containerClass }) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { setHeaderHeight } = useContext(AppHeaderHeightContext);

  const handleNavigateToHome = useCallback(() => {
    dispatch(push(`/${HOME}`));
  }, [dispatch]);

  useEffect(() => {
    setHeaderHeight(wrapperRef.current.offsetHeight);
  }, [setHeaderHeight]);

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles.wrapper, containerClass, { [styles.withoutSideBar]: withoutSideBar })}
    >
      <Logo onClick={handleNavigateToHome} />
      {withHeaderInfo ? (
        <HeaderInfo />
      ) : (
        <div className={styles.content}>
          <div className={styles.news}>
            <NewsRow withTradeSettings={false} isHeader />
          </div>
          <InvastAffiliate />
        </div>
      )}
    </div>
  );
};
Header.propTypes = {
  withoutSideBar: PropTypes.bool,
  withHeaderInfo: PropTypes.bool,
  containerClass: PropTypes.string.isRequired,
};
Header.defaultProps = {
  withoutSideBar: false,
  withHeaderInfo: false,
};

export default memo(Header);
