import { CASH_PAGES } from 'shared-modules/constants/cash';
import { WEB_CASH_CHANGE_ACTIVE_PAGE, WEB_CASH_RESET_OPTION } from '../actionConstants/cashConstants';

const initialState = {
  activePage: CASH_PAGES.CASH_MENU.ID,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case WEB_CASH_CHANGE_ACTIVE_PAGE: {
      const { activePage } = payload;
      return {
        ...state,
        activePage,
      };
    }
    case WEB_CASH_RESET_OPTION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
