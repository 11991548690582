import { get, patch, invokeApi, createAdditionalParams } from './index';

export const getInstruments = async ({ isPublic, serviceId }) => {
  let additionalParams;
  if (!isPublic) {
    additionalParams = await createAdditionalParams();
  }

  return invokeApi({}, `${isPublic ? 'public/' : ''}${serviceId}/instruments`, get, additionalParams);
};

export const getAccountInfo = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/account`, get, additionalParams);
};

export const getSettings = async ({ isPublic }) => {
  let additionalParams;
  if (!isPublic) {
    additionalParams = await createAdditionalParams();
  }

  return invokeApi({}, `${isPublic ? 'public/' : ''}settings`, get, additionalParams);
};

export const getOrderSettings = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/${serviceId}/user_manual_trading_default`, get, additionalParams);
};

export const updateOrderSettings = async ({ requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/${serviceId}/user_manual_trading_default`, patch, additionalParams, requestBody);
};

export const getUserSettingNewOrderSkipConfirmation = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/${serviceId}/user_new_order_skip_confirmation`, get, additionalParams);
};

export const updateUserSettingNewOrderSkipConfirmation = async ({ requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/${serviceId}/user_new_order_skip_confirmation`, patch, additionalParams, requestBody);
};

export const getUserSettingCloseOrderSkipConfirmation = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/${serviceId}/user_close_order_skip_confirmation`, get, additionalParams);
};

export const updateUserSettingCloseOrderSkipConfirmation = async ({ requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi(
    {},
    `settings/${serviceId}/user_close_order_skip_confirmation`,
    patch,
    additionalParams,
    requestBody,
  );
};

export const getUserMailSettings = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/${serviceId}/user_mail_setting`, get, additionalParams);
};

export const updateUserMailSettings = async ({ requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/${serviceId}/user_mail_setting`, patch, additionalParams, requestBody);
};

export const updateUserRatePanel = async ({ requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/${serviceId}/user_rate_panel`, patch, additionalParams, requestBody);
};

export const getUserRatePanel = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/${serviceId}/user_rate_panel`, get, additionalParams);
};

export const changeAccountInfo = async ({ serviceId, requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/account`, patch, additionalParams, requestBody);
};

export const getAccountLosscutInfo = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/account_losscut_status`, get, additionalParams);
};

export const getUserSettingLearnTriautoConfirmationRequest = async () => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/user_learn_triauto_confirmation`, get, additionalParams);
};

export const updateLearnTriautoStatus = async ({ requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/user_learn_triauto_confirmation`, patch, additionalParams, requestBody);
};
