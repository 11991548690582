import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTotalUnrealizedProfitLossForSide } from 'shared-modules/services/hooks/tradeInfo';
import HighlightValue from '../../../../components/HighlightValue';

const TotalUnrealizedProfitLossCellValue = memo(({ instrumentId, side }) => {
  const totalUnrealizedProfitLoss = useTotalUnrealizedProfitLossForSide(instrumentId, side);

  return <HighlightValue value={Math.floor(totalUnrealizedProfitLoss)} isToFormat />;
});

TotalUnrealizedProfitLossCellValue.propTypes = {
  instrumentId: PropTypes.string,
  side: PropTypes.string,
};

TotalUnrealizedProfitLossCellValue.defaultProps = {
  instrumentId: '',
  side: '',
};

export default TotalUnrealizedProfitLossCellValue;
