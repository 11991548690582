import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS, MODAL_SIZES } from 'shared-modules/constants';
import { AGREEMENT_URL } from 'shared-modules/config';
import { logoutUser, acceptAgreementInfoRequest, openConfirmationModal } from '../../../../redux/actions';
import Modal from '../../../../components/Modal';
import CustomButton from '../../../../components/CustomButton';
import styles from './agreementInfo.module.scss';

const AGREEMENT_BUTTON_WIDTH = 312;
const ACTION_BUTTON_WIDTH = 148;

const AgreementInfo = ({ isOpen, closeModal }) => {
  const [hasReadTheTerms, setReadTheTerms] = useState(false);
  const isLoading = useSelector((state) => state.settings.acceptAgreementInfoIsLoading);

  const dispatch = useDispatch();

  const handleRefuseAgreement = useCallback(() => {
    closeModal();
    setReadTheTerms(false);
    dispatch(logoutUser());
    setTimeout(() => {
      dispatch(
        openConfirmationModal({
          title: 'ログアウト',
          bodyText: 'ログアウトされました',
          buttonNextText: '画面を閉じる',
        }),
      );
    }, 300);
  }, [dispatch, closeModal]);

  const handleOpenAgreementInfo = useCallback(() => {
    setReadTheTerms(true);
    window.open(AGREEMENT_URL, '_blank');
  }, []);

  const closeModalAndResetState = useCallback(() => {
    closeModal();
    setReadTheTerms(false);
  }, [closeModal]);

  const handleAcceptAgreement = useCallback(
    () => dispatch(acceptAgreementInfoRequest({ callback: closeModalAndResetState })),
    [dispatch, closeModalAndResetState],
  );

  return (
    <Modal
      isOpen={isOpen}
      closeModal={handleRefuseAgreement}
      title="交付書面の確認"
      size={MODAL_SIZES.WIDTH_640}
      isOutsideClickDontClose
    >
      <div className={styles.title}>インヴァスト証券「トライオート」に係る交付書面の改訂に関するご確認のお願い</div>
      <div className={styles.text}>
        インヴァスト証券「トライオート」をご愛顧いただきまして、ありがとうございます。
        <br />
        このたび、本取引に係る交付書面が改訂されております。
        <br />
        「交付書面を確認する」ボタンより内容をご確認の上、ご承認いただける場合は「同意する」ボタンを押してください。
        <br />
        なお、ご同意いただけない場合、本取引をご利用いただくことができませんので、ご注意ください。
      </div>
      <CustomButton
        onClick={handleOpenAgreementInfo}
        color={COLORS.DARK_SKY_BLUE}
        width={AGREEMENT_BUTTON_WIDTH}
        className={styles.openAgreementButton}
      >
        交付書面を確認する
      </CustomButton>
      <div className={styles.actionButtonContainer}>
        <CustomButton onClick={handleRefuseAgreement} width={ACTION_BUTTON_WIDTH}>
          ログアウト
        </CustomButton>
        <CustomButton
          isDisabled={!hasReadTheTerms}
          onClick={handleAcceptAgreement}
          width={ACTION_BUTTON_WIDTH}
          color={COLORS.WHITE}
          isLoading={isLoading}
        >
          同意する
        </CustomButton>
      </div>
    </Modal>
  );
};
AgreementInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(AgreementInfo);
