import { put, call, retry, takeEvery, select } from 'redux-saga/effects';
import {
  GET_USER_SSO_CASH_FLOW_REQUEST,
  GET_USER_SSO_MONEY_HATCH_REQUEST,
  GET_USER_SSO_INVAST_AFFILIATE_REQUEST,
  GET_USER_SSO_MY_PAGE_REQUEST,
  GET_USER_SSO_PAYMENT_REQUEST,
} from '../actionConstants/userSSOConstants';
import { RETRY_MAX_TRIES, RETRY_DELAY } from '../../constants/apiConstant';
import {
  getUserSSOCashflowSuccess,
  getUserSSOMoneyHatchSuccess,
  getUserSSOInvastAffiliateSuccess,
  getUserSSOMyPageSuccess,
  getUserSSOPaymentSuccess,
} from '../actions/userSSOActions';
import {
  getUserSSOMyPage,
  getUserSSOInvastAffiliate,
  getUserSSOPayment,
  getUserSSOMoneyHatch,
  getUserSSOQuickDeposit,
} from '../../api/userSSOApi';
import { errorHandler } from './errorSaga';

export function* getUserSSOMyPageHandler() {
  try {
    const {
      data: {
        user_sso_mypage: { url, token, hash },
      },
    } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getUserSSOMyPage);
    yield put(getUserSSOMyPageSuccess({ url, token, hash }));
  } catch (e) {
    yield call(errorHandler, { error: e, isInitialRequest: true });
  }
}

export function* getUserSSOPaymentHandler(action) {
  try {
    const serviceId = yield action.payload?.serviceId || select((state) => state.auth.serviceId);

    const {
      data: {
        user_sso_payment: { url, token, hash },
      },
    } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getUserSSOPayment, { serviceId });

    yield put(getUserSSOPaymentSuccess({ url, token, hash }));
  } catch (e) {
    yield call(errorHandler, { error: e, isInitialRequest: true });
  }
}

function createGetUserSSOHandler({ apiCall, settingsField, successAction, getParams = null }) {
  return function* getUserSSOHandler() {
    try {
      let params = null;
      if (getParams) params = yield call(getParams);
      const response = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, apiCall, params);
      const { url, token, hash } = response.data[settingsField];

      yield put(successAction({ url, token, hash }));
    } catch (e) {
      yield call(errorHandler, { error: e, isInitialRequest: true });
    }
  };
}

function* getUserSSOCashflowHandler({ payload: { serviceId, bankCode, amount, redirectKey } }) {
  try {
    const {
      data: {
        user_sso_cashflow: { url, token, hash },
      },
    } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getUserSSOQuickDeposit, { serviceId, bankCode, amount, redirectKey });

    yield put(getUserSSOCashflowSuccess({ url, token, hash }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

export const getUserSSOMoneyHatchHandler = createGetUserSSOHandler({
  apiCall: getUserSSOMoneyHatch,
  settingsField: 'user_sso_moneyhatch',
  successAction: getUserSSOMoneyHatchSuccess,
});

function* getUserSSOInvastAffiliateHandler() {
  try {
    const {
      data: {
        user_sso_invast_affiliate: { url, token, hash },
      },
    } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getUserSSOInvastAffiliate);
    yield put(getUserSSOInvastAffiliateSuccess({ url, token, hash }));
  } catch (e) {
    yield call(errorHandler, { error: e, isInitialRequest: true });
  }
}

export default function* userSSOSaga() {
  yield takeEvery(GET_USER_SSO_MY_PAGE_REQUEST, getUserSSOMyPageHandler);
  yield takeEvery(GET_USER_SSO_PAYMENT_REQUEST, getUserSSOPaymentHandler);
  yield takeEvery(GET_USER_SSO_MONEY_HATCH_REQUEST, getUserSSOMoneyHatchHandler);
  yield takeEvery(GET_USER_SSO_CASH_FLOW_REQUEST, getUserSSOCashflowHandler);
  yield takeEvery(GET_USER_SSO_INVAST_AFFILIATE_REQUEST, getUserSSOInvastAffiliateHandler);
}
