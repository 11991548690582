import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../../components';
import styles from './card.module.scss';

export const Card = memo(({ icon, alt, title, summary, detail, onClick }) => {
  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <div>
          <img src={icon} className={styles.icon} alt={alt} />
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.titleArea}>
            <div className={styles.title}>{title}</div>
          </div>
          <div className={styles.summaryArea}>
            <div className={styles.summary}>{summary}</div>
          </div>
        </div>
      </div>
      <div className={styles.detailArea}>
        <div className={styles.detail}>{detail}</div>
      </div>
      <Button className={styles.button} onClick={onClick}>
        はじめる
      </Button>
    </div>
  );
});

Card.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
