import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCssVariables } from '../../hooks';
import { PercentageText } from './PercentageText';

const normalize = (v) => String(v || 0).replace(/%/g, '');

export const MarginRateText = memo(({ value, className, symbolClassName }) => {
  const variables = useCssVariables();
  const normalizedValue = useMemo(() => normalize(value), [value]);
  return (
    <PercentageText
      className={className}
      symbolClassName={symbolClassName}
      color={variables['--color-text-normal']}
      value={normalizedValue}
      withoutPlus
    />
  );
});

MarginRateText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  symbolClassName: PropTypes.string,
};

MarginRateText.defaultProps = {
  value: undefined,
  className: undefined,
  symbolClassName: undefined,
};
