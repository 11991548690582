import { get, patch, post, invokeApi, createAdditionalParams } from './index';

export const getPublishedLabs = async (tagId) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `published_labs/${tagId}`, get, additionalParams);
};

export const getUserLabs = async (serviceId) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `labs?serviceId=${serviceId}`, get, additionalParams);
};

export const getTags = async () => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `lab_tags`, get, additionalParams);
};

export const postLab = async (form) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `lab`, post, additionalParams, form);
};

export const getApGroupsSimulationData = async (serviceId, apGroupIds, termId) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi(
    {},
    `ap_groups/simulation?serviceId=${serviceId}&apGroupIds=${apGroupIds.join(',')}&termId=${termId}`,
    get,
    additionalParams,
  );
};

export const getLabDetails = async (labId, termId) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi(
    {},
    `labs/${labId}/simulation?termId=${termId}&tradesFlg=true&chartsFlg=true`,
    get,
    additionalParams,
  );
};

export const getPublishedLabDetails = async (labId, termId, tradesFlg) => {
  const additionalParams = await createAdditionalParams();
  const queryString = `?termId=${termId}&tradesFlg=${tradesFlg}&chartsFlg=true`;

  return invokeApi({}, `published_labs/${labId}/simulation${queryString}`, get, additionalParams);
};

export const cancelLab = async (labId) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `labs/${labId}/cancel`, patch, additionalParams);
};

export const addLabToCart = async ({ requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, 'cart/lab', post, additionalParams, { ...(requestBody || {}), considerParent: true });
};

export const validateApGroups = async (serviceId, apGroupIds) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi(
    {},
    `labs/validation/apGroups?serviceId=${serviceId}&apGroupIds=${apGroupIds.join(',')}`,
    get,
    additionalParams,
  );
};

export const validateLabName = async (serviceId, name) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `labs/validation/name?serviceId=${serviceId}&name=${name}`, get, additionalParams);
};
