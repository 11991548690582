import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LinkButton } from '../LinkButton';
import styles from './forwardLink.module.scss';

export const ForwardLink = memo(({ className, disabled, onClick, primary, children }) => {
  return (
    <LinkButton className={className} disabled={disabled} onClick={onClick} primary={primary}>
      <>
        <div>{children}</div>
        <i className={classNames('material-icons', styles.icon)}>arrow_forward_ios</i>
      </>
    </LinkButton>
  );
});

ForwardLink.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ForwardLink.defaultProps = {
  className: undefined,
  disabled: false,
  primary: false,
  onClick: undefined,
};
