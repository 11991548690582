import Decimal from 'decimal.js';
import { CHART_MAKE_DEFAULT_WIDTH, FX } from '../../../../constants';
import Ap from '../../Ap';
import { ceil, convertFromDispQuiantity, getSignificantDigits, pipsToPrice, priceToPips } from '../../constants';

export const RANGE_POSITION = { TOP: 0, MIDDLE: 1, BOTTOM: 2, ISOLATION_BOTTOM: 3 };

export const Range = (
  inputInstrumentId,
  instrumentSetting,
  inputSide,
  inputMax,
  inputMin,
  inputQuantity,
  inputRangePosition,
) => {
  const instrumentId = inputInstrumentId;
  const digits = getSignificantDigits(FX, instrumentId, instrumentSetting?.pricePrecision);
  const side = inputSide;
  const rangePosition = inputRangePosition;
  let min = inputMin;
  let max = inputMax;
  let minForDisp = inputMin;
  let maxForDisp = inputMax;
  let width = 0;
  let numOfOrders = 0;
  let quantity = inputQuantity;
  let tp = 0;
  let sl = null;
  const defaultSettings = {
    min: null,
    max: null,
    width: null,
    numOfOrders: null,
    quantity: null,
    tp: null,
    sl: null,
  };
  const currentSettings = {
    min: null,
    max: null,
    minForDisp: null,
    maxForDisp: null,
    width: null,
    numOfOrders: null,
    quantity: null,
    tp: null,
    sl: null,
  };

  return {
    getWidthPrice() {
      return pipsToPrice(width, instrumentId);
    },
    /**
     * 注文本数をレンジの最大値・最小値、注文幅から求め、セットする。
     */
    calcAndSetNumOfOrders() {
      numOfOrders = ceil(Decimal.div(Decimal.sub(max, min), this.getWidthPrice()), 0);
    },
    calcAndSetTp() {
      tp = width;
    },
    /**
     * 最小値の補正を行う。
     *
     * 最大値が確定している状態で使用すること。
     * 最大値と注文本数、注文幅から最低値を求め、セットする。
     */
    ajustMin() {
      min = ceil(Decimal.sub(max, Decimal.mul(numOfOrders, this.getWidthPrice())), digits);
    },
    /**
     * 最大値の補正を行う。
     *
     * 最小値が確定している状態で使用すること。
     * 最小値と注文本数、注文幅から最低値を求め、セットする。
     */
    ajustMax() {
      max = ceil(Decimal.add(this.getMin(), Decimal.mul(numOfOrders, this.getWidthPrice())), digits);
    },
    /**
     * 変更された注文設定を軸に注文幅を調整する。
     * baseMin,baseMaxが有効な場合は最小・最大値も調整する。
     *
     * @param {*} baseMin 起点とする最小値(指定値-注文幅が最小値となる)
     * @param {*} baseMax 起点とする最大値(指定値+注文幅が最大値となる)
     * @param {*} inputNumOfOrders 注文本数
     */
    recalc(baseMin, baseMax, inputNumOfOrders, isChangeRangeWidth) {
      if (baseMax || baseMax === 0) {
        max = ceil(baseMax, digits);
      }
      if (baseMin || baseMin === 0) {
        min = ceil(baseMin, digits);
      }
      if (inputNumOfOrders || isChangeRangeWidth) {
        const ajustResult = this.setNumOfOrdersAjustWidth(inputNumOfOrders || numOfOrders);
        if (!ajustResult.isValid) {
          return ajustResult;
        }
      }

      if (baseMax || baseMax === 0) {
        this.ajustMin();
      } else if (baseMin || baseMin === 0) {
        this.ajustMax();
      } else {
        this.ajustMin();
      }
      return { isValid: true };
    },
    recalcWithStartPrice(startPrice, rangeWidth, inputNumOfOrders) {
      const newNumOfOrders = inputNumOfOrders;
      const isChangeRangeWidth = rangeWidth && rangeWidth !== this.getRangeWidth();
      const newRangeWidth = isChangeRangeWidth ? rangeWidth : this.getRangeWidth();
      let recalcResult;

      switch (rangePosition) {
        case RANGE_POSITION.TOP: {
          const basePrice = startPrice ?? min;
          max = ceil(Decimal.add(basePrice, pipsToPrice(newRangeWidth, instrumentId)), digits);
          minForDisp = ceil(basePrice, digits);
          maxForDisp = max;
          recalcResult = this.recalc(basePrice, null, newNumOfOrders, isChangeRangeWidth);
          break;
        }
        case RANGE_POSITION.MIDDLE: {
          const halfRangeWidth = Decimal.div(newRangeWidth, 2).toNumber();
          const basePrice = startPrice ? Decimal.add(startPrice, pipsToPrice(halfRangeWidth, instrumentId)) : max;
          min = ceil(Decimal.sub(basePrice, pipsToPrice(newRangeWidth, instrumentId)), digits);
          maxForDisp = ceil(basePrice, digits);
          minForDisp = min;
          recalcResult = this.recalc(null, basePrice, newNumOfOrders, isChangeRangeWidth);
          break;
        }
        case RANGE_POSITION.BOTTOM:
        case RANGE_POSITION.ISOLATION_BOTTOM: {
          const basePrice = startPrice ?? max;
          min = ceil(Decimal.sub(basePrice, pipsToPrice(newRangeWidth, instrumentId)), digits);
          maxForDisp = ceil(basePrice, digits);
          minForDisp = min;
          recalcResult = this.recalc(null, basePrice, newNumOfOrders, isChangeRangeWidth);
          break;
        }
        default: {
          recalcResult = { isValid: false, errorMessage: 'レンジの位置情報が不正です。' };
        }
      }
      return recalcResult;
    },
    backupDefaultSettings() {
      defaultSettings.min = min;
      defaultSettings.max = max;
      defaultSettings.width = width;
      defaultSettings.numOfOrders = numOfOrders;
      defaultSettings.quantity = quantity;
      defaultSettings.sl = null;
      defaultSettings.tp = width;
    },
    backupCurrentSettings() {
      currentSettings.min = min;
      currentSettings.max = max;
      currentSettings.minForDisp = minForDisp;
      currentSettings.maxForDisp = maxForDisp;
      currentSettings.width = width;
      currentSettings.numOfOrders = numOfOrders;
      currentSettings.quantity = quantity;
      currentSettings.sl = sl;
      currentSettings.tp = tp;
    },
    rollbackSettings() {
      min = currentSettings.min;
      max = currentSettings.max;
      minForDisp = currentSettings.minForDisp;
      maxForDisp = currentSettings.maxForDisp;
      width = currentSettings.width;
      numOfOrders = currentSettings.numOfOrders;
      quantity = currentSettings.quantity;
      tp = currentSettings.tp;
      sl = currentSettings.sl;
    },

    reset() {
      min = defaultSettings.min;
      max = defaultSettings.max;
      minForDisp = defaultSettings.min;
      maxForDisp = defaultSettings.max;
      width = defaultSettings.width;
      numOfOrders = defaultSettings.numOfOrders;
      quantity = defaultSettings.quantity;
      tp = defaultSettings.tp;
      sl = defaultSettings.sl;
    },
    getRangeWidth() {
      return ceil(priceToPips(Decimal.sub(max, min), instrumentId), 1);
    },
    getPreciseRangeWidth() {
      return priceToPips(Decimal.sub(max, min), instrumentId);
    },
    setApSettings(settings) {
      if (settings.quantity) {
        quantity = convertFromDispQuiantity(settings.quantity);
      }
      if (settings.tp) {
        tp = settings.tp;
      }
      if (settings.sl || settings.sl === null) {
        sl = settings.sl;
      }
    },
    setNumOfOrders(value) {
      numOfOrders = value;
    },
    getNumOfOrders() {
      return numOfOrders;
    },
    getQuantity() {
      return quantity;
    },
    setMin: (value) => {
      min = value;
    },
    setMax: (value) => {
      max = value;
    },
    setMinForDisp: (value) => {
      minForDisp = value;
    },
    setMaxForDisp: (value) => {
      maxForDisp = value;
    },
    getSide() {
      return side;
    },
    getMin() {
      return min;
    },
    getMax() {
      return max;
    },
    getSl() {
      return sl;
    },
    getTp() {
      return tp;
    },
    setWidth: (value) => {
      width = value;
    },
    getWidth() {
      return width;
    },
    getRangePosition() {
      return rangePosition;
    },
    createAps() {
      const result = [];
      for (let i = 0; i < numOfOrders; i += 1) {
        result.push(this.toAp(i, side));
      }
      return result;
    },
    getStartPrice() {
      switch (rangePosition) {
        case RANGE_POSITION.TOP:
          return min;
        case RANGE_POSITION.MIDDLE:
          return ceil(Decimal.sub(max, Decimal.div(Decimal.sub(max, min), 2)), digits);
        case RANGE_POSITION.BOTTOM:
        case RANGE_POSITION.ISOLATION_BOTTOM:
          return max;
        default:
          return null;
      }
    },
    getDispQuantity() {
      return Decimal.div(quantity, 10000).toNumber();
    },
    getSettings() {
      return {
        side: side.ID,
        min: minForDisp,
        max: maxForDisp,
        preciseMin: min,
        preciseMax: max,
        rangeWidth: this.getRangeWidth(),
        startPrice: this.getStartPrice(),
        numOfOrders: this.getNumOfOrders(),
        quantity: this.getDispQuantity(),
        tp,
        sl,
      };
    },
    setNumOfOrdersAjustWidth(inputNumOfOrders) {
      const validateResult = this.validateRangeWidth(this.getRangeWidth(), inputNumOfOrders);
      if (!validateResult.isValid) {
        return validateResult;
      }
      numOfOrders = inputNumOfOrders;
      width = ceil(Decimal.div(this.getRangeWidth(), numOfOrders), 1);
      return { isValid: true };
    },
    toAp(num, apSide) {
      let entryPrice1 = 0;
      if (rangePosition === RANGE_POSITION.BOTTOM) {
        entryPrice1 = ceil(Decimal.sub(this.getMax(), Decimal.mul(Decimal.add(num, 1), this.getWidthPrice())), digits);
      } else {
        entryPrice1 = ceil(Decimal.sub(this.getMax(), Decimal.mul(num, this.getWidthPrice())), digits);
      }
      return Ap(
        apSide.ID,
        entryPrice1,
        null,
        this.getDispQuantity(),
        this.getSl(),
        this.getTp(),
        null,
        null,
        entryPrice1,
      );
    },
    validateRangeWidth(inputRangeWidth, inputNumOfOrders) {
      if (ceil(Decimal.div(inputRangeWidth, inputNumOfOrders), 1) < CHART_MAKE_DEFAULT_WIDTH) {
        return {
          isValid: false,
          errorMessage: 'この設定では注文幅が狭くなり過ぎます。レンジ幅を広くするか本数を少なくして下さい。',
        };
      }
      return { isValid: true };
    },
    getMinRangeWidth(inputNumOfOrders) {
      return ceil(Decimal.mul(CHART_MAKE_DEFAULT_WIDTH, inputNumOfOrders), 1);
    },
  };
};
