import { memo, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TRADE_TABLES } from 'shared-modules/constants';
import { changeSelectedPortfolioAutoTradeTable } from '../../../../../../../../redux/actions';
import { Tabs } from '../../../../../../../../components';
import { TableRefProvider } from '../../../../../../../../contexts';
import TableExecutions from '../TableExecutions/TableExecutions';
import TableOrders from '../TableOrders/TableOrders';
import TablePositions from '../TablePositions/TablePositions';
import { TableWrapper } from './TableWrapper';
import styles from './tableHeader.module.scss';

const TableHeader = ({ id, serviceId }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.webPortfolio.selectedTable);

  const changeActiveTab = useCallback(
    (tableId) => {
      dispatch(changeSelectedPortfolioAutoTradeTable({ tableId: Number(tableId) }));
    },
    [dispatch],
  );

  const tabItems = useMemo(() => {
    return [
      {
        id: TRADE_TABLES.ORDERS.ID,
        label: '注文設定',
        children: (
          <TableWrapper serviceId={serviceId}>
            <TableOrders id={id} serviceId={serviceId} />
          </TableWrapper>
        ),
      },
      {
        id: TRADE_TABLES.EXECUTIONS.ID,
        label: '約定照会',
        children: (
          <TableWrapper serviceId={serviceId}>
            <TableExecutions id={id} serviceId={serviceId} />
          </TableWrapper>
        ),
      },
      {
        id: TRADE_TABLES.POSITIONS.ID,
        label: '建玉照会',
        children: (
          <TableWrapper serviceId={serviceId}>
            <TablePositions id={id} serviceId={serviceId} />
          </TableWrapper>
        ),
      },
    ];
  }, [id, serviceId]);

  return (
    <TableRefProvider>
      <Tabs containerClassName={styles.tabs} items={tabItems} activeKey={activeTab} onChange={changeActiveTab} />
    </TableRefProvider>
  );
};

TableHeader.propTypes = {
  id: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
};

export default memo(TableHeader);
