import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatDateTimeWithoutDivider } from 'shared-modules/services';
import { useCartHistory } from 'shared-modules/services/hooks/cartLogic';
import { useServiceIds } from 'shared-modules/hooks/cart';
import { Button, Tag } from '../../../../../components';
import InstrumentIcon from '../../../../../components/InstrumentIcon';
import SelectionImage from '../../../../../components/SelectionImage';
import CustomInput from '../../../../../components/CustomInput';
import styles from './item.module.scss';

const Image = memo(({ item }) => {
  const { sourceType, parentId, parentImage, serviceId, instrumentId } = item;
  if (parentId) {
    return <SelectionImage image={parentImage} width="42px" isShare={sourceType === 'lab'} serviceId={serviceId} />;
  }
  return <InstrumentIcon size="littleTiny" instrumentId={instrumentId} serviceId={serviceId} />;
});

Image.propTypes = {
  item: PropTypes.shape({
    parentId: PropTypes.number,
    parentImage: PropTypes.string,
    sourceType: PropTypes.string.isRequired,
    serviceId: PropTypes.string.isRequired,
    instrumentId: PropTypes.string.isRequired,
  }).isRequired,
};

const Card = memo(({ item }) => {
  const { parentName, parentSets, strategyName, strategySets, cartLogItems } = item;
  const name = parentName ?? strategyName;
  const sets = parentSets ?? strategySets;
  const serviceIds = useServiceIds(cartLogItems);
  return (
    <div className={styles.content}>
      <div className={styles.tags}>
        {serviceIds.map((serviceId) => (
          <div key={serviceId} className={styles.tag}>
            <Tag text={serviceId} />
          </div>
        ))}
      </div>
      <div className={styles.row}>
        <div className={styles.leftItem}>
          <div className={styles.summary}>
            <div className={styles.image}>
              <Image item={item} />
            </div>
            <div className={styles.name}>{name}</div>
          </div>
        </div>
        <div className={styles.rightItem}>
          <div className={styles.label}>セット数</div>
          <CustomInput width={112} inputClassName={styles.input} value={sets} isDisabled />
        </div>
      </div>
    </div>
  );
});

Card.propTypes = {
  item: PropTypes.shape({
    parentId: PropTypes.number,
    parentName: PropTypes.string,
    parentImage: PropTypes.string,
    parentSets: PropTypes.number,
    sourceType: PropTypes.string.isRequired,
    serviceId: PropTypes.string.isRequired,
    instrumentId: PropTypes.string.isRequired,
    strategyName: PropTypes.string.isRequired,
    strategySets: PropTypes.number.isRequired,
    cartLogItems: PropTypes.arrayOf(
      PropTypes.shape({
        serviceId: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export const Item = memo(({ logId, createTime, items }) => {
  const strategyIds = useMemo(() => {
    return items?.flatMap((item) => item.cartLogItems).map(({ strategyId }) => strategyId) ?? [];
  }, [items]);
  const { isLoading, handleAddToCurrentCart } = useCartHistory(logId, strategyIds);
  return (
    <>
      <div className={styles.header}>
        <div className={styles.createTime}>{formatDateTimeWithoutDivider(createTime)}</div>
        <Button className={styles.button} onClick={handleAddToCurrentCart} loading={isLoading} secondary>
          カートに追加
        </Button>
      </div>
      <div>
        {items.map((item) => {
          const { parentId, sourceType, strategyId } = item;
          const key = `${parentId ?? ''}_${sourceType ?? ''}_${strategyId}`;
          return (
            <div key={key} className={styles.card}>
              <Card item={item} />
            </div>
          );
        })}
      </div>
    </>
  );
});

Item.propTypes = {
  logId: PropTypes.number,
  createTime: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

Item.defaultProps = {
  logId: undefined,
  createTime: undefined,
  items: undefined,
};
