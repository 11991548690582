import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getYoutubeId } from 'shared-modules/services';
import { useKeyPressHandler } from '../../../../services/hooks';
import { Spin } from '../../../../components';
import styles from './youtubeModal.module.scss';

const width = 560;
const height = 315;

const YoutubeModal = ({ isOpen, closeModal }) => {
  const handlePressClose = useKeyPressHandler(closeModal);

  const videoUrl = useSelector((state) => state.modals.youtube.url);

  return (
    <Modal
      show={isOpen}
      onHide={closeModal}
      centered
      dialogClassName={styles.wrapper}
      backdropClassName={styles.backdrop}
      style={{ zIndex: 1151 }}
    >
      <Modal.Header bsPrefix={styles.header}>
        <i
          className={classNames('material-icons', styles.closeIcon)}
          tabIndex={0}
          role="button"
          onClick={closeModal}
          onKeyPress={handlePressClose}
        >
          clear
        </i>
      </Modal.Header>
      <Modal.Body bsPrefix={styles.body}>
        <div className={styles.loaderWrapper}>
          <Spin className={styles.loader} />
        </div>
        <iframe
          className={styles.iframe}
          title="youtube"
          width={width}
          height={height}
          src={`https://www.youtube.com/embed/${getYoutubeId(videoUrl)}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Modal.Body>
    </Modal>
  );
};

YoutubeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(YoutubeModal);
