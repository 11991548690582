import { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './unreadMark.module.scss';

export const UnreadMark = memo(({ top, left, bottom, right }) => {
  return <span style={{ top, left, bottom, right }} className={styles.marker} />;
});

UnreadMark.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
};

UnreadMark.defaultProps = {
  top: undefined,
  left: undefined,
  bottom: undefined,
  right: undefined,
};
