import { memo } from 'react';
import PropTypes from 'prop-types';
import { usePortfolioPercentSummaryInfoByServiceId } from 'shared-modules/services/hooks';
import { DistributionLegend } from '../DistributionLegend';
import { Bar } from '../Bar';
import styles from './symbolDistribution.module.scss';

export const SymbolDistribution = memo(({ serviceId }) => {
  const symbolInfo = usePortfolioPercentSummaryInfoByServiceId(0, serviceId);
  const positions = [];
  const orders = [];
  const unused = [];

  symbolInfo.forEach((symbol) => {
    if (!symbol || symbol.isSimulationData) {
      return;
    }
    if (symbol.name === '発注可能額') {
      unused.push(symbol);
    } else if (symbol.name.endsWith(' 注文')) {
      orders.push(symbol);
    } else {
      positions.push(symbol);
    }
  });

  return (
    <div className={styles.container}>
      <Bar symbolInfo={symbolInfo} />
      <div className={styles.legends}>
        <div>
          <div className={styles.title}>建玉</div>
          {positions.map((item) => (
            <DistributionLegend key={item.name} color={item.color} name={item.name} value={item.percent} />
          ))}
        </div>
        <div>
          <div className={styles.title}>注文</div>
          {orders.map((item) => (
            <DistributionLegend
              key={item.name}
              color={item.color}
              name={item.name.replace(' 注文', '')}
              value={item.percent}
            />
          ))}
        </div>
        <div>
          <div className={styles.title}>発注可能額</div>
          {unused.map((item) => (
            <DistributionLegend key={item.name} color={item.color} name={item.name} value={item.percent} />
          ))}
        </div>
      </div>
    </div>
  );
});

SymbolDistribution.propTypes = {
  serviceId: PropTypes.string.isRequired,
};
