import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIsMarginZeroByServiceId } from 'shared-modules/services/hooks';
import { useDepositTransfer } from 'shared-modules/hooks/useDepositTransfer';
import { BALANCE_METER_DISABLE_MESSAGES } from 'shared-modules/constants';
import { BalanceMeter, ForwardLink, MarginInfo, Tag } from '../../../../components';
import styles from './assetDetail.module.scss';

export const AssetDetail = memo(
  ({ className, meterData, serviceId, requiredMargin, recommendedMargin, fundIndication, hideMarginInfo, onClick }) => {
    const isMarginZero = useIsMarginZeroByServiceId(serviceId);
    const { label, showLink, handleChangeAttention } = useDepositTransfer();
    const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);

    const balanceMeter = useMemo(() => {
      if (meterData.effectiveMargin == null) {
        const disableMessage = BALANCE_METER_DISABLE_MESSAGES[serviceId] ?? '';
        return <div className={styles.maintenance}>{disableMessage}</div>;
      }
      return (
        <BalanceMeter
          meterData={meterData}
          enabledFuture
          isMarginZero={tutorialMode ? false : isMarginZero}
          onClick={onClick}
          onChangeAttention={handleChangeAttention}
        />
      );
    }, [meterData, tutorialMode, isMarginZero, onClick, handleChangeAttention, serviceId]);
    return (
      <div className={classNames(styles.container, className)}>
        <div className={styles.tagContainer}>
          <Tag text={serviceId} />
        </div>
        <div className={styles.content}>
          {balanceMeter}
          <div className={styles.detail}>
            {!isMarginZero && showLink && (
              <div className={classNames(styles.linkArea, { [styles.hideMarginInfo]: hideMarginInfo })}>
                <ForwardLink className={styles.forwardLink} onClick={onClick} primary>
                  {label}
                </ForwardLink>
              </div>
            )}
            {!hideMarginInfo && (
              <MarginInfo
                className={styles.marginArea}
                requiredMargin={requiredMargin}
                recommendedMargin={recommendedMargin}
                fundIndication={fundIndication}
                hideDetail={hideMarginInfo}
              />
            )}
          </div>
        </div>
      </div>
    );
  },
);

AssetDetail.propTypes = {
  className: PropTypes.string,
  serviceId: PropTypes.string.isRequired,
  meterData: PropTypes.shape({
    effectiveMargin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    orderableMargin: PropTypes.number,
    positionRequiredMargin: PropTypes.number,
    orderingRequiredMargin: PropTypes.number,
    consumedMargin: PropTypes.number,
    effectiveMarginRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  requiredMargin: PropTypes.shape({
    get: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  recommendedMargin: PropTypes.shape({
    get: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  fundIndication: PropTypes.shape({
    get: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  hideMarginInfo: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

AssetDetail.defaultProps = {
  className: undefined,
  requiredMargin: undefined,
  recommendedMargin: undefined,
  fundIndication: undefined,
  hideMarginInfo: false,
};
