export const WEB_TUTORIAL_OPEN_SELECT_PAGE = 'TUTORIAL/WEB_TUTORIAL_OPEN_SELECT_PAGE';
export const WEB_TUTORIAL_OPEN_HOME = 'TUTORIAL/WEB_TUTORIAL_OPEN_HOME';
export const WEB_TUTORIAL_OPEN_STRATEGIES_SUCCESS = 'TUTORIAL/WEB_TUTORIAL_OPEN_STRATEGIES_SUCCESS';
export const WEB_TUTORIAL_OPEN_RUN_AUTO_SELECT = 'TUTORIAL/WEB_TUTORIAL_OPEN_RUN_AUTO_SELECT';
export const WEB_TUTORIAL_OPEN_PORTFOLIO = 'TUTORIAL/WEB_TUTORIAL_OPEN_PORTFOLIO';
export const WEB_TUTORIAL_OPEN_PORTFOLIO_DETAIL = 'TUTORIAL/WEB_TUTORIAL_OPEN_PORTFOLIO_DETAIL';

export const SET_TUTORIAL_CARD_DATA = 'TUTORIAL/SET_TUTORIAL_CARD_DATA';
export const REMOVE_TUTORIAL_CARD_DATA = 'TUTORIAL/REMOVE_TUTORIAL_CARD_DATA';

export const SET_TUTORIAL_TABLES_DATA = 'TUTORIAL/SET_TUTORIAL_TABLES_DATA';
export const REMOVE_TUTORIAL_TABLES_DATA = 'TUTORIAL/REMOVE_TUTORIAL_TABLES_DATA';

export const SET_PROFIT_LOSS_CHART_DATA = 'TUTORIAL/SET_PROFIT_LOSS_CHART_DATA';
export const REMOVE_PROFIT_LOSS_CHART_DATA = 'TUTORIAL/REMOVE_PROFIT_LOSS_CHART_DATA';

export const SET_TUTORIAL_IS_READY_AUTO_SELECT = 'TUTORIAL/SET_TUTORIAL_IS_READY_AUTO_SELECT';
