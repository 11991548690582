import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './circleCheckbox.module.scss';

export const CircleCheckbox = memo(({ checked, disabled, className, onChange }) => {
  const handleClick = useCallback(
    (e) => {
      e.currentTarget.blur();
      if (disabled) {
        return;
      }
      onChange?.(!checked);
    },
    [onChange, checked, disabled],
  );

  const handleKeyDown = useKeyDownHandler(handleClick);

  return (
    <div className={styles.wrapper}>
      <div
        role="button"
        className={classNames(styles.checkbox, { [styles.checked]: checked, [styles.disabled]: disabled }, className)}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={disabled ? -1 : 0}
      >
        <i
          className={classNames(
            styles.icon,
            { [styles.checked]: checked },
            { [styles.noChange]: checked },
            { [styles.disabled]: disabled },
            'material-icons',
          )}
        >
          {checked ? 'done' : 'radio_button_unchecked'}
        </i>
      </div>
    </div>
  );
});

CircleCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

CircleCheckbox.defaultProps = {
  disabled: false,
  className: '',
  onChange: undefined,
};
