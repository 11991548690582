/* eslint-disable react/prop-types */
import Decimal from 'decimal.js';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useBlockLayout, useResizeColumns, useRowSelect, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import {
  updateTableHasErrorArray,
  removeApData,
  checkResetSelectedTableRows,
  clearExistingCheckboxStatus,
} from 'shared-modules/redux/multiEdit';
import { useDebouncedCallback } from 'shared-modules/services/hooks';
import {
  COUNT_INPUT_NAME,
  COUNTER_FIXED,
  COUNTER_NAME,
  COUNTER_TYPE,
  FOLLOW_NAME,
  LOSS_CUT_WIDTH_NAME,
  OPTIONS_COUNTER_TYPE,
  PROFIT_MARGIN_NAME,
  BUY_SELL_MAIN,
  PRICE_1_NAME,
} from 'shared-modules/constants';
import { useMultiEditPricePrecision, useMultiEditPipConversion } from 'shared-modules/services/hooks/multiEdit';
import { decimalRounding } from 'shared-modules/services/builder';
import useDynamicMultiEditInfo from 'shared-modules/services/hooks/multiEditLogic';
import { saveSelectedTableRows, openConfirmationModal } from '../../redux/actions';
import styles from './multiEditCustomTable.module.scss';
import { store } from '../../redux/store';
import { TableLoader } from './components';

const defaultColumnParams = {
  minWidth: 70,
  width: 100,
  maxWidth: 400,
};

const add = (value, delta) => (delta ? Decimal.add(value || 0, delta).toNumber() : null);

const SETTER_CALLBACK = 'setter_callback';
const DISABLED = 'disabled';
const VALIDATE = 'validate';
const IS_CHANGED = 'is_changed';

const CreateRow = ({ apId, row, totalColumnsWidth, cb, initializeCb }) => {
  const dispatch = useDispatch();
  const { style: rowStyle, ...restRow } = row.getRowProps();
  const validate = useDynamicMultiEditInfo(row.original, store);
  const initValues = useRef({
    initQuantity: validate.quantity.get,
    initEntryPrice: validate.entryPrice.get,
    initEntryPrice2: validate.entryPrice2.get,
    initProfitMargin: validate.profitMargin.get,
    initLossCutWidth: validate.lossCutWidth.get,
    initFollow: validate.follow.get,
    initCounterType: validate.counterType.get,
    initCounterValue: validate.counterValue.get,
  });

  useEffect(() => {
    initializeCb(apId, validate);
  });

  useEffect(() => {
    cb(apId, validate);
  }, [apId, cb, validate]);

  const hasError = Boolean(validate.errorsArray.length);
  useEffect(() => {
    dispatch(updateTableHasErrorArray({ rowNum: row.id, hasError }));
  }, [dispatch, row.id, hasError]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading,
    <div {...restRow} style={{ ...rowStyle, width: totalColumnsWidth }} className={styles.tr}>
      {row.cells.map(({ getCellProps, column, render }, cellIndex, cells) => {
        const { style: cellStyle, ...restCell } = getCellProps();

        // define right position on sticky columns
        let localCellStyles = { ...cellStyle };
        if (column.sticky) {
          localCellStyles = {
            ...localCellStyles,
            right: cells?.[cellIndex + 1]?.column?.totalWidth ?? 0,
          };
        }
        return (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading,
            {...restCell}
            style={localCellStyles}
            className={classNames(styles.td, {
              [styles.isNumber]: column.isNumber,
              [styles.stickyColumn]: column.sticky,
            })}
          >
            <div
              className={classNames(styles.cellWrapper, {
                [styles.visibleTips]: column.visibleTips,
              })}
            >
              {render('Cell', { rowInfo: validate, initValues: initValues.current })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
memo(CreateRow);

const MultiEditCustomTable = ({
  columns,
  tableData,
  className,
  resized,
  tableMetaInfo,
  defaultSorting,
  useServerSorting,
  withCheckboxes,
  maxColumnWidth,
  /**
   * This prop is used only for TablePositions because of checkbox column.
   * In any other places use 'sticky: right' in 'columns' prop
   */
  stickyColumns,
  propTableRowsRef: tableRowsRef,
}) => {
  const { loading: isLoading } = tableMetaInfo;
  const { key: resizedKey, default: resizedDefault } = resized;

  const dispatch = useDispatch();

  const tableRef = useRef();
  const [tableDimensions, setTableDimensions] = useState({
    height: 0,
    width: 0,
  });

  const refsReflect = useCallback(
    (apId, validate) => {
      const ref = tableRowsRef;
      ref.current[apId][validate.quantity.name] = validate.quantity.get;
      ref.current[apId][validate.entryPrice.name] = validate.entryPrice.get;
      ref.current[apId][validate.entryPrice2.name] = validate.entryPrice2.get;
      ref.current[apId][validate.profitMargin.name] = validate.profitMargin.get;
      ref.current[apId][validate.lossCutWidth.name] = validate.lossCutWidth.get;
      ref.current[apId][validate.follow.name] = validate.follow.get;
      ref.current[apId][validate.counterType.name] = validate.counterType.get;
      ref.current[apId][validate.counterValue.name] = validate.counterValue.get;

      ref.current[apId].isSomeChanged =
        validate.quantity.isChanged ||
        validate.entryPrice.isChanged ||
        validate.entryPrice2.isChanged ||
        validate.profitMargin.isChanged ||
        validate.lossCutWidth.isChanged ||
        validate.lossCutWidth.isChanged ||
        validate.follow.isChanged ||
        validate.counterType.isChanged ||
        validate.counterValue.isChanged;
    },
    [tableRowsRef],
  );

  const setterInit = useCallback(
    (apId, validate) => {
      const ref = tableRowsRef;

      ref.current[apId] = {
        [`${validate.quantity.name}_${SETTER_CALLBACK}`]: validate.quantity.set,
        [`${validate.quantity.name}_${DISABLED}`]: validate.quantity.isDisabled,
        [`${validate.quantity.name}_${VALIDATE}`]: validate.quantity.validate,
        [`${validate.quantity.name}_${IS_CHANGED}`]: false,

        [`${validate.entryPrice.name}_${SETTER_CALLBACK}`]: validate.entryPrice.set,
        [`${validate.entryPrice.name}_${DISABLED}`]: validate.entryPrice.isDisabled,
        [`${validate.entryPrice.name}_${VALIDATE}`]: validate.entryPrice.validate,
        [`${validate.entryPrice.name}_${IS_CHANGED}`]: false,

        [`${validate.entryPrice2.name}_${SETTER_CALLBACK}`]: validate.entryPrice2.set,
        [`${validate.entryPrice2.name}_${DISABLED}`]: validate.entryPrice2.isDisabled,
        [`${validate.entryPrice2.name}_${VALIDATE}`]: validate.entryPrice2.validate,
        [`${validate.entryPrice2.name}_${IS_CHANGED}`]: false,

        [`${validate.profitMargin.name}_${SETTER_CALLBACK}`]: validate.profitMargin.set,
        [`${validate.profitMargin.name}_${DISABLED}`]: validate.profitMargin.isDisabled,
        [`${validate.profitMargin.name}_${VALIDATE}`]: validate.profitMargin.validate,
        [`${validate.profitMargin.name}_${IS_CHANGED}`]: false,

        [`${validate.lossCutWidth.name}_${SETTER_CALLBACK}`]: validate.lossCutWidth.set,
        [`${validate.lossCutWidth.name}_${DISABLED}`]: validate.lossCutWidth.isDisabled,
        [`${validate.lossCutWidth.name}_${VALIDATE}`]: validate.lossCutWidth.validate,
        [`${validate.lossCutWidth.name}_${IS_CHANGED}`]: false,

        [`${validate.follow.name}_${SETTER_CALLBACK}`]: validate.follow.set,
        [`${validate.follow.name}_${DISABLED}`]: validate.follow.isDisabled,
        [`${validate.follow.name}_${VALIDATE}`]: validate.follow.validate,
        [`${validate.follow.name}_${IS_CHANGED}`]: false,

        [`${validate.counterType.name}_${SETTER_CALLBACK}`]: validate.counterType.set,
        [`${validate.counterType.name}_${DISABLED}`]: validate.counterType.isDisabled,
        [`${validate.counterType.name}_${VALIDATE}`]: validate.counterType.validate,
        [`${validate.counterType.name}_${IS_CHANGED}`]: false,

        [`${validate.counterValue.name}_${SETTER_CALLBACK}`]: validate.counterValue.set,
        [`${validate.counterValue.name}_${DISABLED}`]: validate.counterValue.isDisabled,
        [`${validate.counterValue.name}_${VALIDATE}`]: validate.counterValue.validate,
        [`${validate.counterValue.name}_${IS_CHANGED}`]: false,

        priceIsDisabled: validate.priceIsDisabled,
        isSingle: validate.isSingle,
        isSomeChanged: false,
      };
    },
    [tableRowsRef],
  );

  const { selectedTableRows, existingCheckbox, apData, isPatch } = useSelector((state) => state.multiEdit);
  const { instrumentId, apList } = useSelector((state) => state.portfolio.selectedApGroupData);

  const pipConversion = useMultiEditPipConversion(instrumentId);
  const { pricePrecision } = useMultiEditPricePrecision(instrumentId);

  useEffect(() => {
    if (Object.keys(apData).length === 0) return;

    const ref = tableRowsRef;
    const isSellSide = Number(existingCheckbox.side) === BUY_SELL_MAIN.SELL.ID;

    const inputQuantity = apData[COUNT_INPUT_NAME];
    const inputTp = apData[PROFIT_MARGIN_NAME];
    const inputSl = apData[LOSS_CUT_WIDTH_NAME];
    const inputFollow = apData[FOLLOW_NAME];
    const inputCounter = apData[COUNTER_NAME];
    const counterFixed = apData[COUNTER_FIXED];

    let isModifyFailApExist = false;

    const changeApFailExists = () => {
      if (isModifyFailApExist) return;
      isModifyFailApExist = true;
    };

    selectedTableRows.forEach(({ isChecked, apId }) => {
      if (!isChecked || !apId) return;

      let quantity = inputQuantity;
      let tp = inputTp;
      let sl = inputSl;
      let follow = inputFollow;
      let counter = inputCounter;

      if (isPatch) {
        const previousQuantity = ref.current[apId][COUNT_INPUT_NAME];
        const previousTp = ref.current[apId][PROFIT_MARGIN_NAME];
        const previousSl = ref.current[apId][LOSS_CUT_WIDTH_NAME];
        const previousFollow = ref.current[apId][FOLLOW_NAME];
        const previousCounter = ref.current[apId][COUNTER_NAME];
        quantity = add(previousQuantity, quantity);
        tp = add(previousTp, tp);
        sl = add(previousSl, sl);
        follow = add(previousFollow, follow);
        counter = add(previousCounter, counter);
      }

      const isQuantityDisabled = ref.current[apId][`${COUNT_INPUT_NAME}_${DISABLED}`];
      const isTpDisabled = ref.current[apId][`${PROFIT_MARGIN_NAME}_${DISABLED}`];
      const isSlDisabled = ref.current[apId][`${LOSS_CUT_WIDTH_NAME}_${DISABLED}`];
      const isFollowDisabled = ref.current[apId][`${FOLLOW_NAME}_${DISABLED}`];
      const isCounterDisabled = ref.current[apId][`${COUNTER_NAME}_${DISABLED}`];

      if (quantity !== null) {
        if (!isQuantityDisabled) {
          const setter = ref.current[apId][`${COUNT_INPUT_NAME}_${SETTER_CALLBACK}`];
          setter(quantity);
          const quantityValidate = ref.current[apId][`${COUNT_INPUT_NAME}_${VALIDATE}`];
          quantityValidate(quantity);
        } else changeApFailExists();
      }

      if (tp !== null) {
        if (!isTpDisabled) {
          const setter = ref.current[apId][`${PROFIT_MARGIN_NAME}_${SETTER_CALLBACK}`];
          setter(tp);
          const tpValidate = ref.current[apId][`${PROFIT_MARGIN_NAME}_${VALIDATE}`];
          tpValidate(tp);
        } else changeApFailExists();
      }

      if (sl !== null) {
        if (!isSlDisabled) {
          const setter = ref.current[apId][`${LOSS_CUT_WIDTH_NAME}_${SETTER_CALLBACK}`];
          setter(sl);
          const slValidate = ref.current[apId][`${LOSS_CUT_WIDTH_NAME}_${VALIDATE}`];
          slValidate(sl);
        } else changeApFailExists();
      }

      if (follow !== null) {
        if (!isFollowDisabled) {
          const setter = ref.current[apId][`${FOLLOW_NAME}_${SETTER_CALLBACK}`];
          setter(follow);
          const followValidate = ref.current[apId][`${FOLLOW_NAME}_${VALIDATE}`];
          followValidate(follow);
        } else changeApFailExists();
      }

      if (counter !== null) {
        if (!isCounterDisabled) {
          const counterValueSetterCb = ref.current[apId][`${COUNTER_NAME}_${SETTER_CALLBACK}`];
          const counterTypeSetterCb = ref.current[apId][`${COUNTER_TYPE}_${SETTER_CALLBACK}`];

          if (counterFixed) {
            const targetAp = apList.filter((ap) => ap.id === apId)[0];
            const targetTp = tp || ref.current[apId][PROFIT_MARGIN_NAME];
            const initEntryPrice = targetAp.latestNewOrderPrice1 ?? ref.current[apId][PRICE_1_NAME];

            if (initEntryPrice !== '') {
              const entryPrice = isSellSide
                ? decimalRounding(Decimal(initEntryPrice), pricePrecision)
                : decimalRounding(Decimal(initEntryPrice), pricePrecision);

              const profitMarginConversion = Number(Decimal.mul(targetTp, pipConversion));
              const counterValueConversion = Number(Decimal.mul(counter, pipConversion));

              const counterFixedValue = isSellSide
                ? decimalRounding(
                    Decimal.add(entryPrice, counterValueConversion).sub(profitMarginConversion),
                    pricePrecision,
                  )
                : decimalRounding(
                    Decimal.add(entryPrice, counterValueConversion).add(profitMarginConversion),
                    pricePrecision,
                  );

              counterTypeSetterCb(OPTIONS_COUNTER_TYPE[1].id);
              setTimeout(() => {
                counterValueSetterCb(counterFixedValue);
                const counterValidate = ref.current[apId][`${COUNTER_NAME}_${VALIDATE}`];
                counterValidate(counterFixedValue);
              }, 100);
            } else changeApFailExists();
          } else {
            counterTypeSetterCb(OPTIONS_COUNTER_TYPE[0].id);
            setTimeout(() => {
              counterValueSetterCb(counter);
              const counterValidate = ref.current[apId][`${COUNTER_NAME}_${VALIDATE}`];
              counterValidate(counter);
            }, 100);
          }
        } else changeApFailExists();
      }
    });

    if (isModifyFailApExist) {
      dispatch(
        openConfirmationModal({
          title: '選択一括変更失敗',
          bodyText: '変更できなかった注文があります。',
          buttonNextText: '確認',
          isOverlap: true,
        }),
      );
    }

    batch(() => {
      dispatch(checkResetSelectedTableRows());
      dispatch(clearExistingCheckboxStatus());
      dispatch(removeApData());
    });
  }, [
    dispatch,
    apData,
    apList,
    selectedTableRows,
    tableRowsRef,
    pipConversion,
    pricePrecision,
    existingCheckbox,
    isPatch,
  ]);

  useEffect(() => {
    if (window.ResizeObserver) {
      const tableRefCopy = tableRef.current;

      const resizeObserver = new ResizeObserver((entries) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const entry of entries) {
          const { width = 0, height = 0 } = entry?.contentRect ?? {};

          setTableDimensions({
            height,
            width,
          });
        }
      });

      resizeObserver.observe(tableRef.current);

      return () => {
        resizeObserver.unobserve(tableRefCopy);
      };
    }

    return () => {};
  }, []);

  const defaultColumn = useMemo(() => ({ ...defaultColumnParams, maxWidth: maxColumnWidth }), [maxColumnWidth]);

  const columnWithWidth = useMemo(() => {
    if (!columns || !tableDimensions.width) {
      return [];
    }

    let resizedArray = resizedDefault;

    const savedResizeArray = localStorage.getItem(resizedKey);
    if (savedResizeArray) resizedArray = JSON.parse(savedResizeArray);

    const sumWidth = resizedArray.reduce((acc, item) => acc + item, 0);
    resizedArray = resizedArray.map((cell) => Math.floor((cell / sumWidth) * tableDimensions.width));

    return columns.map((item, index) => {
      const maxWidth = Math.min(item?.maxWidth ?? Infinity, defaultColumn.maxWidth);
      const minWidth = Math.max(item?.minWidth ?? -Infinity, defaultColumn.minWidth);

      let width = resizedArray[index] > maxWidth ? maxWidth : resizedArray[index];
      width = resizedArray[index] < minWidth ? minWidth : width;

      return { ...item, width };
    });
  }, [columns, tableDimensions.width, resizedDefault, resizedKey, defaultColumn.maxWidth, defaultColumn.minWidth]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows, totalColumnsWidth } =
    useTable(
      {
        columns: columnWithWidth,
        data: tableData,
        defaultColumn,
        disableMultiSort: true,
        initialState: { sortBy: defaultSorting },
        manualSortBy: useServerSorting,
        disableSortRemove: true,
      },
      useRowSelect,
      useBlockLayout,
      useResizeColumns,
      useSticky,
      (hooks) => {
        if (withCheckboxes) hooks.visibleColumns.push((hooksColumns) => [...hooksColumns, ...stickyColumns]);
      },
    );

  useEffect(() => {
    if (withCheckboxes) {
      const selectedRows = selectedFlatRows.map((el) => el.original);
      dispatch(saveSelectedTableRows({ rows: selectedRows }));
    }
  }, [dispatch, selectedFlatRows, withCheckboxes]);

  const saveResizedValue = useDebouncedCallback((data) => {
    localStorage.setItem(resizedKey, JSON.stringify(data));
  }, 1000);

  const isEmptyTable = tableData.length === 0;
  const isTableLoading = useMemo(() => isEmptyTable || isLoading, [isEmptyTable, isLoading]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...getTableProps()} className={classNames(styles.table, styles.sticky, className)} ref={tableRef}>
      <div className={classNames(styles.tableInner)}>
        <div>
          <div className={styles.header}>
            {headerGroups.map((headerGroup) => {
              const { headers, getHeaderGroupProps } = headerGroup;
              if (saveResizedValue) saveResizedValue(headers.map((column) => column.width));

              return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <div {...getHeaderGroupProps()} className={styles.tr}>
                  {headers.map((column, index) => {
                    const { style: headerStyles, ...restHeaderProps } = column.getHeaderProps();

                    // define right position on sticky columns
                    let localHeaderStyles = { ...headerStyles };
                    if (column.sticky) {
                      localHeaderStyles = {
                        ...localHeaderStyles,
                        right: headers[index + 1]?.totalWidth ?? 0,
                      };
                    }

                    return (
                      <div
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...restHeaderProps}
                        style={localHeaderStyles}
                        className={classNames(
                          styles.th,
                          styles.cellWrapper,
                          styles.headerCell,
                          {
                            [styles.isNumber]: column.isNumber,
                            [styles.sortedDesc]: column.isSortedDesc,
                            [styles.isSortedAsc]: column.isSorted && !column.isSortedDesc,
                            [styles.stickyColumn]: Boolean(column.sticky),
                          },
                          column.classname,
                        )}
                      >
                        {column.render('Header')}
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <div {...column.getResizerProps()} className={styles.resizer} />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {isTableLoading ? (
            <div className={styles.tableLoaderWrapper}>
              <TableLoader isLoading={isTableLoading} />
            </div>
          ) : (
            <div className={styles.body}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <div {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);

                  return (
                    <CreateRow
                      key={row.original.id}
                      apId={row.original.id}
                      row={row}
                      totalColumnsWidth={totalColumnsWidth}
                      cb={refsReflect}
                      initializeCb={setterInit}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MultiEditCustomTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      Cell: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      isNumber: PropTypes.bool,
      disableSortBy: PropTypes.bool,
      className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      sticky: PropTypes.oneOf(['left', 'right']),
      visibleTips: PropTypes.bool,
    }),
  ).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emptyText: PropTypes.string,
  resized: PropTypes.shape({
    key: PropTypes.string.isRequired,
    default: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  className: PropTypes.string,
  tableMetaInfo: PropTypes.shape({
    loading: PropTypes.bool,
    pageNumber: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  paginationCallback: PropTypes.func,
  defaultSorting: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, desc: PropTypes.bool.isRequired }),
  ),
  useServerSorting: PropTypes.bool,
  sortingHandler: PropTypes.func,
  withCheckboxes: PropTypes.bool,
  maxColumnWidth: PropTypes.number,
  stickyColumns: PropTypes.arrayOf(PropTypes.shape({})),
};

MultiEditCustomTable.defaultProps = {
  className: '',
  emptyText: '',
  tableMetaInfo: {
    loading: false,
    pageNumber: 1,
    totalPages: 1,
  },
  paginationCallback: null,
  defaultSorting: [],
  useServerSorting: false,
  sortingHandler: () => {},
  withCheckboxes: false,
  maxColumnWidth: defaultColumnParams.maxWidth,
  stickyColumns: [],
};

export default memo(MultiEditCustomTable);
