import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ACTIVE_COLOR } from 'shared-modules/constants';
import classNames from 'classnames';
import Option from './components/Option';
import styles from './labeledSwitch.module.scss';

const LabeledSwitch = ({
  options,
  onChange,
  activeItemId,
  isLighter,
  itemClassName,
  dataCustomSelector,
  showLockIcon,
  isNotSelectable: isSwitchDisabled,
  selectorsWrapperStyle,
}) => {
  const selectorBlock = (
    <div className={classNames(styles.selectorsWrapper, selectorsWrapperStyle)}>
      {options.map(({ id, label, activeColor, isVisuallyDisabled, isDisabled }) => (
        <Option
          id={id}
          key={id}
          label={label.toUpperCase()}
          onChange={onChange}
          isLighter={isLighter}
          activeColor={activeColor}
          isSelected={id === activeItemId}
          itemClassName={itemClassName}
          dataCustomSelector={dataCustomSelector ? dataCustomSelector + id : ''}
          isNotSelectable={isDisabled || isSwitchDisabled}
          isVisuallyDisabled={isVisuallyDisabled}
        />
      ))}
    </div>
  );
  return showLockIcon ? (
    <div className={styles.selectorsWithLock}>
      <i className={classNames('material-icons-outlined', styles.lockIcon)}>lock</i>
      {selectorBlock}
    </div>
  ) : (
    selectorBlock
  );
};

LabeledSwitch.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      activeColor: PropTypes.oneOf([ACTIVE_COLOR.BLUE, ACTIVE_COLOR.RED, '']),
      isVisuallyDisabled: PropTypes.bool,
    }),
  ).isRequired,
  activeItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  isLighter: PropTypes.bool,
  itemClassName: PropTypes.string,
  isNotSelectable: PropTypes.bool,
  dataCustomSelector: PropTypes.string,
  showLockIcon: PropTypes.bool,
  selectorsWrapperStyle: PropTypes.string,
};

LabeledSwitch.defaultProps = {
  isLighter: false,
  itemClassName: '',
  isNotSelectable: false,
  dataCustomSelector: '',
  showLockIcon: false,
  selectorsWrapperStyle: null,
};

export default memo(LabeledSwitch);
