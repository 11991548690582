import { memo } from 'react';
import PropTypes from 'prop-types';
import { TECH_BASE_PRICE_LITERAL } from 'shared-modules/constants/builder';
import { useToInstrumentPrecision } from 'shared-modules/services/hooks';

export const TechCounterPriceTableCell = memo(({ counterPrice, instrumentId }) => {
  const symbol = counterPrice.substring(TECH_BASE_PRICE_LITERAL.length, TECH_BASE_PRICE_LITERAL.length + 1);

  const sliceIndex = counterPrice.indexOf(symbol) + 1;
  const value = Number(counterPrice.slice(sliceIndex));
  const round = useToInstrumentPrecision(instrumentId, '-');
  return <>{`(スタート価格${value ? `${symbol}${round(value)}` : ''})`}</>;
});

TechCounterPriceTableCell.propTypes = {
  counterPrice: PropTypes.string.isRequired,
  instrumentId: PropTypes.string.isRequired,
};
