import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CFD, ETF, FX } from 'shared-modules/constants';
import { SORT_DESCENDING } from 'shared-modules/constants/manualTrade';
import {
  DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY,
  DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR,
} from 'shared-modules/constants/portfolio';
import { getTimeYearsDifference } from 'shared-modules/services';
import styles from '../../../../../PortfolioAutoTradeDetail/components/DetailTable/detailTable.module.scss';
import { getModalExecutionsTableTemplate } from '../../../../../../../../services/tableTemplate';
import { Table } from '../../../../../../../../components';
import { tutorialExecutionsTable } from '../../../../../../../../constants/tutorial/classNames';

const DEFAULT_SORTING = [
  {
    id: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY,
    desc: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR === SORT_DESCENDING,
  },
];

const RESIZED = {
  [FX]: {
    KEY: 'portfolioFXAutoTradeExecutionsTable',
    COLUMNS: [97, 97, 97, 85, 97, 97, 97, 97, 97, 105],
  },
  [ETF]: {
    KEY: 'portfolioETFAutoTradeExecutionsTable',
    COLUMNS: [97, 97, 97, 90, 97, 97, 97, 97, 97, 105],
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    KEY: 'portfolioCFDAutoTradeExecutionsTable',
    COLUMNS: [97, 97, 97, 90, 97, 97, 97, 97, 97, 105],
  },
};

const TutorialTableExecutions = ({ serviceId }) => {
  const tableData = useSelector((state) => state.webTutorial.executionData);

  const tableMetaInfo = useSelector((state) => state.portfolio.selectedApGroupExecutionsMetaInfo[serviceId]);

  const datesSearchIsInvalid = useMemo(
    () => getTimeYearsDifference(tableMetaInfo.toDate, tableMetaInfo.fromDate),
    [tableMetaInfo],
  );

  const columns = useMemo(() => getModalExecutionsTableTemplate(serviceId), [serviceId]);
  const defaultSorting = useMemo(() => {
    if (tableMetaInfo.sortBy == null || tableMetaInfo.sortDir == null) {
      return DEFAULT_SORTING;
    }
    return [{ id: tableMetaInfo.sortBy, desc: tableMetaInfo.sortDir === SORT_DESCENDING }];
  }, [tableMetaInfo]);

  const resized = useMemo(() => {
    const { KEY, COLUMNS } = RESIZED[serviceId];
    return {
      key: KEY,
      default: [...COLUMNS],
    };
  }, [serviceId]);

  return (
    <span className={tutorialExecutionsTable}>
      <Table
        key={serviceId}
        tableData={datesSearchIsInvalid ? [] : tableData}
        columns={columns}
        resized={resized}
        className={styles.table}
        tableMetaInfo={tableMetaInfo}
        defaultSorting={defaultSorting}
        useServerSorting
      />
    </span>
  );
};

TutorialTableExecutions.propTypes = {
  serviceId: PropTypes.string.isRequired,
};

export default memo(TutorialTableExecutions);
