import React, { memo, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MARKET_STATUSES, TRADE_METHODS } from 'shared-modules/constants';
import { calculateSpread } from 'shared-modules/services';
import { useInstrumentSettings } from 'shared-modules/services/hooks';
import { getRealInstrumentId } from 'shared-modules/utils';
import CfdInstrumentIcon from '../../../../components/CfdInstrumentIcon';
import ValueCompared from '../CurrencyPairItemETF/components/ValueCompared';
import styles from './currencyPairItemCFD.module.scss';
import { CustomFlag } from '../../../../components';

const CurrencyPairItemCFD = ({ instrumentId, innerRef, draggableProps, disabled, changeActiveCurrency }) => {
  const { bid, previousBid, ask, previousAsk } = useSelector((state) => state.currencies.rates[instrumentId]) || {};
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const { shortName, pricePrecision } = instrumentList[instrumentId];
  const { marketStatus } = useInstrumentSettings(instrumentId, TRADE_METHODS.MANUAL.ID);
  const isClosed = useMemo(() => Number(marketStatus) === MARKET_STATUSES.CLOSED.ID, [marketStatus]);
  const [firstFlagIcon, secondFlagIcon] = useMemo(() => {
    return getRealInstrumentId(instrumentId).split('/');
  }, [instrumentId]);
  const spread = useMemo(
    () => calculateSpread({ buyPrice: ask, sellPrice: bid, instrumentId, instrumentList }),
    [instrumentId, instrumentList, ask, bid],
  );
  const handleSelectItem = useCallback(() => {
    if (disabled) return false;
    return changeActiveCurrency(instrumentId);
  }, [instrumentId, disabled, changeActiveCurrency]);

  return (
    <div
      role="button"
      aria-hidden
      className={classNames(disabled ? styles.wrapperDisabled : styles.wrapper, { [styles.isDisabled]: disabled })}
      ref={innerRef}
      {...draggableProps.draggableProps} // eslint-disable-line
      onClick={handleSelectItem}
    >
      <i
        className={classNames('material-icons', styles.draggableIcon)}
        {...draggableProps.dragHandleProps} // eslint-disable-line
      >
        drag_indicator
      </i>
      {disabled ? (
        <>
          <div className={styles.currencyBlockDisabled}>
            <div className={styles.flagContainerDisabled}>
              <CustomFlag country={firstFlagIcon} className={styles.flagDisabled} />
              <CustomFlag country={secondFlagIcon} className={styles.flagDisabled} />
            </div>

            <div className={classNames(styles.currencyPairRowDisabled, { [styles.isClosed]: isClosed || disabled })}>
              {shortName}
            </div>
          </div>
          <ValueCompared
            instrumentId={instrumentId}
            value={bid}
            prevValue={previousBid}
            precision={pricePrecision}
            isClosed={isClosed || disabled}
          />
          <div className={classNames(styles.spread, { [styles.isClosed]: isClosed || disabled })}>{spread}</div>
          <ValueCompared
            instrumentId={instrumentId}
            value={ask}
            prevValue={previousAsk}
            precision={pricePrecision}
            isClosed={isClosed || disabled}
          />
        </>
      ) : (
        <>
          <div className={styles.currencyBlock}>
            <div className={styles.flagContainer}>
              <CfdInstrumentIcon instrumentId={instrumentId} size="small" />
            </div>
          </div>
          <div className={styles.valuesInner}>
            <div className={classNames(styles.currencyPairRow, { [styles.isClosed]: isClosed })}>
              <p className={styles.instrumentName}>{shortName}</p>
            </div>
            <div className={styles.valuesRow}>
              <ValueCompared value={bid} prevValue={previousBid} precision={pricePrecision} isClosed={isClosed} />
              <div className={classNames(styles.spread, { [styles.isClosed]: isClosed })}>
                {calculateSpread({
                  buyPrice: ask,
                  sellPrice: bid,
                  instrumentId,
                  instrumentList,
                })}
              </div>
              <ValueCompared value={ask} prevValue={previousAsk} precision={pricePrecision} isClosed={isClosed} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

CurrencyPairItemCFD.propTypes = {
  instrumentId: PropTypes.string.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.shape({}) })]).isRequired,
  draggableProps: PropTypes.shape({}).isRequired,
  changeActiveCurrency: PropTypes.func,
  disabled: PropTypes.bool,
};

CurrencyPairItemCFD.defaultProps = {
  changeActiveCurrency: () => {},
  disabled: false,
};

export default memo(CurrencyPairItemCFD);
