import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

export const Dot = memo(({ className, size, adjustedValue }) => {
  const [r, cx, cy] = useMemo(() => {
    const halfSize = size / 2;
    return [halfSize - (adjustedValue ?? 0), halfSize, halfSize];
  }, [size, adjustedValue]);

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle className={className} r={r} cx={cx} cy={cy} />
    </svg>
  );
});

Dot.propTypes = {
  size: PropTypes.number.isRequired,
  adjustedValue: PropTypes.number,
  className: PropTypes.string,
};

Dot.defaultProps = {
  adjustedValue: undefined,
  className: undefined,
};
