import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { MODAL_SIZES, TOOLTIP_MESSAGE } from 'shared-modules/constants';
import { Modal } from '../../../../components';
import styles from './accountSummary.module.scss';

export const AccountSummary = memo(() => {
  const [visible, setVisible] = useState(false);
  const handleOpen = useCallback(() => {
    setVisible(true);
  }, []);
  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);
  const content = (
    <>
      <div className={styles.title}>■証拠金預託額</div>
      <div className={styles.text}>トライオートFXまたはCFDまたはETFの取引口座に預けている証拠金残高</div>
      <div className={styles.title}>■有効証拠金</div>
      <div className={styles.text}>{`【FX】${TOOLTIP_MESSAGE.EFFECTIVE_MARGIN_FX}`}</div>
      <div className={styles.text}>{`【CFD】${TOOLTIP_MESSAGE.EFFECTIVE_MARGIN_CFD}`}</div>
      <div className={styles.text}>{`【ETF】${TOOLTIP_MESSAGE.EFFECTIVE_MARGIN_ETF}`}</div>
      <div className={styles.title}>■有効比率</div>
      <div className={styles.text}>
        有効証拠金÷必要証拠金×100(％)
        <br />
        ※100％以下でロスカットとなります
      </div>
      <div className={styles.title}>■バランスメーター</div>
      <div className={styles.text}>
        資金に対する建玉や新規注文の割合を4段階で評価したもの。建玉数量と注文数量をもとに、有効証拠金をうまく使えているかを表示します。
      </div>
      <div className={styles.text}>「VERY GOOD バランスが良い」を維持するよう心がけましょう。</div>
    </>
  );
  return (
    <div className={styles.container}>
      <div className={styles.iconButton} role="button" tabIndex={0} onClick={handleOpen} aria-hidden>
        <i className={classNames('material-icons-outlined', styles.icon)}>help_outline</i>
      </div>
      {visible && (
        <Modal isOpen={visible} closeModal={handleClose} title="口座状況" size={MODAL_SIZES.WIDTH_710}>
          <div className={styles.content}>{content}</div>
        </Modal>
      )}
    </div>
  );
});
