import React, { memo, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BUY_SELL_MAIN, AP_GROUP_SOURCES, AP_GROUP_ORDER, FX, ETF, CFD } from 'shared-modules/constants';
import { getServiceQuantityUnit } from 'shared-modules/services';
import {
  initialSelectedTableRows,
  clearExistingCheckboxStatus,
  initialTableHasErrorArray,
} from 'shared-modules/redux/multiEdit';
import { useServiceId } from 'shared-modules/hooks';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import MultiEditCustomTable from '../../../../../../../components/MultiEditCustomTable';
import BuySellItem from '../../../../../../../components/BuySellItem';
import { ShortName } from '../../../../../../../components/ShortName';
import CustomInput from '../../../../../../../components/CustomInput';
import CustomSwitch from '../../../../../../../components/CustomSwitch';
import LabeledSwitch from '../../../../../../../components/LabeledSwitch';
import Loader from '../../../../PortfolioAutoTradeDetail/components/DetailTable/components/Loader';
import styles from './multiEditTableBody.module.scss';
import MultiEditTableCheckbox from '../MultiEditTableCheckbox';
import { store } from '../../../../../../../redux/store';
import { orderNameWithToolTips } from '../../../../../../../services/tableTemplate';

const RESIZED = {
  [FX]: {
    KEY: `portfolioFXmultiEditTable`,
    COLUMNS: [5, 30, 80, 50, 80, 80, 80, 80, 80, 80, 150, 20, 10],
  },
  [ETF]: {
    KEY: `portfolioETFmultiEditTable`,
    COLUMNS: [5, 30, 80, 50, 80, 80, 80, 80, 80, 80, 150, 20, 10],
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    KEY: `portfolioCFDmultiEditTable`,
    COLUMNS: [5, 30, 80, 50, 80, 80, 80, 80, 80, 80, 150, 20, 10],
  },
};

const INPUT_WIDTH = 120;
const CELL_MIN_WIDTH = INPUT_WIDTH + 16;

const createItemNumberCell = (id) => {
  return Number(id) + 1;
};

const createInstrumentIdCell = (value, serviceId) => {
  return serviceId === FX ? removeSuffix(value) : <ShortName instrumentId={value} />;
};

const createSideCell = (value) => {
  return <BuySellItem type={Number(value)} />;
};

const createQuontityCell = (quantity, errorsArray, initQuantity) => {
  return (
    <CustomInput
      type="number"
      value={quantity.get}
      onChange={quantity.set}
      isDisabled={quantity.isDisabled}
      name={quantity.name}
      errorMessages={errorsArray}
      validateFunction={quantity.validate}
      step={quantity.step}
      withErrorTooltip
      className={styles.inputWrapper}
      inputClassName={classNames(styles.input, {
        [styles.inputChanged]: initQuantity !== Number(quantity.get),
      })}
      controlBlockClassName={styles.inputControlBlock}
      controlBlockDecrementClassName={styles.inputControlBlockDecrement}
      iconAllowClassName={styles.inputIconArrow}
      iconAllowIncrementClassName={styles.inputIconArrowIncrement}
      iconAllowDecrementClassName={styles.inputIconArrowDecrement}
      errorTooltipClassName={styles.inputerrorTooltip}
      width={INPUT_WIDTH}
    />
  );
};

const createEntryPriceCell = (entryPrice, errorsArray, priceStep, initEntryPrice, cb) => {
  const onChange = (newValue) => {
    entryPrice.set(newValue, cb);
  };

  return initEntryPrice === '' ? (
    '-'
  ) : (
    <CustomInput
      type="number"
      value={entryPrice.get}
      onChange={onChange}
      isDisabled={entryPrice.isDisabled}
      name={entryPrice.name}
      errorMessages={errorsArray}
      validateFunction={entryPrice.validate}
      step={priceStep}
      withErrorTooltip
      className={styles.inputWrapper}
      inputClassName={classNames(styles.input, {
        [styles.inputChanged]: initEntryPrice !== Number(entryPrice.get),
      })}
      controlBlockClassName={styles.inputControlBlock}
      controlBlockDecrementClassName={styles.inputControlBlockDecrement}
      iconAllowClassName={styles.inputIconArrow}
      iconAllowIncrementClassName={styles.inputIconArrowIncrement}
      iconAllowDecrementClassName={styles.inputIconArrowDecrement}
      errorTooltipClassName={styles.inputerrorTooltip}
      width={INPUT_WIDTH}
    />
  );
};

const createEntryPrice2Cell = (entryPrice2, errorsArray, priceStep, initEntryPrice2, cb) => {
  const onChange = (newValue) => {
    entryPrice2.set(newValue, cb);
  };

  return initEntryPrice2 === '' ? (
    '-'
  ) : (
    <CustomInput
      type="number"
      value={entryPrice2.get}
      onChange={onChange}
      isDisabled={entryPrice2.isDisabled}
      name={entryPrice2.name}
      errorMessages={errorsArray}
      validateFunction={entryPrice2.validate}
      step={priceStep}
      withErrorTooltip
      className={styles.inputWrapper}
      inputClassName={classNames(styles.input, {
        [styles.inputChanged]: initEntryPrice2 !== Number(entryPrice2.get),
      })}
      controlBlockClassName={styles.inputControlBlock}
      controlBlockDecrementClassName={styles.inputControlBlockDecrement}
      iconAllowClassName={styles.inputIconArrow}
      iconAllowIncrementClassName={styles.inputIconArrowIncrement}
      iconAllowDecrementClassName={styles.inputIconArrowDecrement}
      errorTooltipClassName={styles.inputerrorTooltip}
      width={INPUT_WIDTH}
    />
  );
};

const createProfitMarginCell = (profitMargin, errorsArray, initProfitMargin) => {
  const onChange = (newValue) => {
    profitMargin.set(newValue);
  };

  return (
    <CustomInput
      type="number"
      value={profitMargin.get}
      onChange={onChange}
      isDisabled={profitMargin.isDisabled}
      name={profitMargin.name}
      errorMessages={errorsArray}
      validateFunction={profitMargin.validate}
      step={profitMargin.step}
      withErrorTooltip
      className={styles.inputWrapper}
      inputClassName={classNames(styles.input, {
        [styles.inputChanged]: initProfitMargin !== Number(profitMargin.get),
      })}
      controlBlockClassName={styles.inputControlBlock}
      controlBlockDecrementClassName={styles.inputControlBlockDecrement}
      iconAllowClassName={styles.inputIconArrow}
      iconAllowIncrementClassName={styles.inputIconArrowIncrement}
      iconAllowDecrementClassName={styles.inputIconArrowDecrement}
      errorTooltipClassName={styles.inputerrorTooltip}
      width={INPUT_WIDTH}
    />
  );
};

const createLossCutWidthCell = (lossCutWidth, errorsArray, initLossCutWidth) => {
  const onChange = (newValue) => {
    lossCutWidth.set(newValue);
  };

  return (
    <CustomInput
      type="number"
      name={lossCutWidth.name}
      value={lossCutWidth.get}
      onChange={onChange}
      isDisabled={lossCutWidth.isDisabled}
      step={lossCutWidth.step}
      min={lossCutWidth.min}
      errorMessages={errorsArray}
      validateFunction={lossCutWidth.validate}
      withErrorTooltip
      validateNegativeValues
      className={styles.inputWrapper}
      inputClassName={classNames(styles.input, {
        [styles.inputChanged]: initLossCutWidth !== (lossCutWidth.get === '' ? '' : Number(lossCutWidth.get)),
      })}
      controlBlockClassName={styles.inputControlBlock}
      controlBlockDecrementClassName={styles.inputControlBlockDecrement}
      iconAllowClassName={styles.inputIconArrow}
      iconAllowIncrementClassName={styles.inputIconArrowIncrement}
      iconAllowDecrementClassName={styles.inputIconArrowDecrement}
      errorTooltipClassName={styles.inputerrorTooltip}
      width={INPUT_WIDTH}
    />
  );
};

const createFollowCell = (follow, side, errorsArray, initFollow) => {
  return (
    <CustomInput
      type="number"
      name={follow.name}
      value={follow.get}
      onChange={follow.set}
      isDisabled={follow.isDisabled}
      step={follow.step}
      min={follow.min}
      errorMessages={errorsArray}
      validateFunction={follow.validate}
      withErrorTooltip
      validateNegativeValues={Number(side) === BUY_SELL_MAIN.SELL.ID}
      className={styles.inputWrapper}
      inputClassName={classNames(styles.input, {
        [styles.inputChanged]: initFollow !== (follow.get === '' ? '' : Number(follow.get)),
      })}
      controlBlockClassName={styles.inputControlBlock}
      controlBlockDecrementClassName={styles.inputControlBlockDecrement}
      iconAllowClassName={styles.inputIconArrow}
      iconAllowIncrementClassName={styles.inputIconArrowIncrement}
      iconAllowDecrementClassName={styles.inputIconArrowDecrement}
      errorTooltipClassName={styles.inputerrorTooltip}
      width={INPUT_WIDTH}
    />
  );
};

const createCounterCell = (
  counterType,
  counterValue,
  side,
  errorsArray,
  initCounterType,
  initCounterValue,
  serviceId,
) => {
  let inputWidth;
  if (counterValue.withPips) inputWidth = serviceId === FX ? 100 : 138;
  else inputWidth = 122;
  return (
    <div className={styles.counnterWrapper}>
      <div className={styles.counnterLabeledSwitchWrapper}>
        <LabeledSwitch
          activeItemId={counterType.get}
          onChange={counterType.isDisabled ? () => {} : counterType.set}
          options={counterType.options}
        />
      </div>
      {counterValue.label && <span className={styles.counterVauleLabel}>{counterValue.label}</span>}
      <CustomInput
        type="number"
        value={counterValue.get}
        onChange={counterValue.set}
        isDisabled={counterValue.isDisabled}
        withPips={serviceId === FX ? counterValue.withPips : false}
        width={inputWidth}
        step={counterValue.step}
        name={counterValue.name}
        errorMessages={errorsArray}
        validateFunction={counterValue.validate}
        min={counterValue.min}
        withErrorTooltip
        validateNegativeValues={counterValue.withPips && Number(side) === BUY_SELL_MAIN.BUY.ID}
        className={styles.inputWrapper}
        inputClassName={classNames(styles.input, {
          [styles.inputChanged]:
            initCounterType !== counterType.get ||
            initCounterValue !== (counterValue.get === '' ? '' : Number(counterValue.get)),
        })}
        controlBlockClassName={styles.inputControlBlock}
        controlBlockDecrementClassName={styles.inputControlBlockDecrement}
        iconAllowClassName={styles.inputIconArrow}
        iconAllowIncrementClassName={styles.inputIconArrowIncrement}
        iconAllowDecrementClassName={styles.inputIconArrowDecrement}
        pipsClassName={styles.inputPips}
        errorTooltipClassName={styles.inputerrorTooltip}
      />
    </div>
  );
};

// for CustomSwitch that required function of onChange
function emptyFunc() {}

const createOperationCell = (original) => {
  return <CustomSwitch isDisabled isChecked={original.status === '1'} onChange={emptyFunc} />;
};

const MultiEditTableBody = ({ useNewOrderLineUpdate, propTableRowsRef }) => {
  const dispatch = useDispatch();
  const tableMetaInfo = useSelector((state) => state.portfolio.selectedApGroupMetaInfo);
  const deleteApGroupItemIsLoading = store.getState().portfolio.deletingApGroupItemIsLoading;

  const { apList, instrumentId: selectedApGroupInstrument } = store.getState().portfolio.selectedApGroupData;
  const serviceId = useServiceId(selectedApGroupInstrument);

  const newOrdersData = store.getState().orders[serviceId];
  const filteredNewOrdersData = useMemo(
    () => newOrdersData?.filter((order) => order.isClose === false) ?? [],
    [newOrdersData],
  );
  const closedOrdersData = store.getState().orders[serviceId];
  const filteredClosedOrdersData = useMemo(
    () => closedOrdersData?.filter((order) => order.isClose === true) ?? [],
    [closedOrdersData],
  );

  const isMoneyHatch = store.getState().portfolio.selectedApGroupData.sourceType === AP_GROUP_SOURCES.MONEY_HATCH.KEY;

  const tableData = useMemo(
    () =>
      apList.map((item) => {
        const newOrder = filteredNewOrdersData
          .filter((data) => data.apId === item.id)
          .map((filteredItem) => [
            [filteredItem.compositeTypeName],
            {
              ...filteredItem,
            },
          ]);

        const closedOrder = filteredClosedOrdersData
          .filter((data) => data.apId === item.id)
          .map((filteredItem) => [
            [filteredItem.compositeTypeName],
            {
              ...filteredItem,
            },
          ]);

        return {
          ...item,
          instrumentId: selectedApGroupInstrument,
          newOrder: Object.fromEntries(newOrder) || {},
          closedOrder: Object.fromEntries(closedOrder) || {},
        };
      }),
    [apList, filteredNewOrdersData, filteredClosedOrdersData, selectedApGroupInstrument],
  );

  const resized = useMemo(() => {
    const { KEY, COLUMNS } = RESIZED[serviceId];
    return {
      key: KEY,
      default: [...COLUMNS],
    };
  }, [serviceId]);

  useEffect(() => {
    dispatch(clearExistingCheckboxStatus());
  }, [dispatch]);

  // set checkbox column
  let setCheckboxRows;
  if (isMoneyHatch) {
    setCheckboxRows = tableData.map((row) => {
      // isInactiveOrFirstCloseOrder condition description
      // No order when inactive
      // LatestNewOrderPrice becomes null when inactive.

      const isInactiveOrFirstCloseOrder =
        row.status === AP_GROUP_ORDER.ACTIVITY.INACTIVE.ID ||
        (row.orderStatus === AP_GROUP_ORDER.STATUS.CLOSE_ORDER.ID && row.latestNewOrderPrice1 === null);

      return { apId: row.id, isInactiveOrFirstCloseOrder, isChecked: false };
    });
  } else {
    setCheckboxRows = tableData.map((row) => ({ apId: row.id, side: row.side, isChecked: false }));
  }

  const key = isMoneyHatch ? 'isInactiveOrFirstCloseOrder' : 'side';
  const isAllSameValue = setCheckboxRows.every((row) => row[key] === setCheckboxRows[0][key]);
  // add header column
  setCheckboxRows.unshift({ isChecked: false, isAllSameValue, checkKey: key });

  const tableHasErrorArray = tableData.map(() => ({ hasError: false }));

  useEffect(() => {
    dispatch(initialSelectedTableRows({ rows: setCheckboxRows }));
    dispatch(initialTableHasErrorArray({ tableHasErrorArray }));
    return () => {
      dispatch(initialSelectedTableRows({ rows: [] }));
      dispatch(initialTableHasErrorArray({ tableHasErrorArray: [] }));
    };
  }, [dispatch, setCheckboxRows, tableHasErrorArray]);

  const columns = useMemo(
    () => [
      {
        Header: '項番',
        accessor: 'itemNumber',
        Cell: ({ row: { id } }) => createItemNumberCell(id),
      },
      {
        Header: '銘柄',
        accessor: 'instrumentId',
        Cell: ({ cell: { value } }) => createInstrumentIdCell(value, serviceId),
      },
      {
        Header: '注文名',
        accessor: 'name',
        Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
      },
      {
        Header: '売買',
        accessor: 'side',
        Cell: ({ cell: { value } }) => createSideCell(value),
      },
      {
        Header: `数量(${getServiceQuantityUnit(serviceId)})`,
        accessor: 'quantity',
        isNumber: true,
        Cell: ({ rowInfo: { quantity, errorsArray }, initValues: { initQuantity } }) =>
          createQuontityCell(quantity, errorsArray, initQuantity),
        minWidth: CELL_MIN_WIDTH,
        visibleTips: true,
      },
      {
        Header: 'エントリー 価格1',
        accessor: 'entryPrice',
        isNumber: true,
        Cell: ({ rowInfo: { entryPrice, errorsArray, priceStep }, initValues: { initEntryPrice } }) =>
          createEntryPriceCell(entryPrice, errorsArray, priceStep, initEntryPrice, useNewOrderLineUpdate),
        minWidth: CELL_MIN_WIDTH,
        visibleTips: true,
      },
      {
        Header: 'エントリー 価格2',
        accessor: 'entryPrice2',
        isNumber: true,
        Cell: ({ rowInfo: { entryPrice2, errorsArray, priceStep }, initValues: { initEntryPrice2 } }) =>
          createEntryPrice2Cell(entryPrice2, errorsArray, priceStep, initEntryPrice2, useNewOrderLineUpdate),
        minWidth: CELL_MIN_WIDTH,
        visibleTips: true,
      },
      {
        Header: `利確幅${serviceId === FX ? '(pips)' : ''}`,
        accessor: 'profitMargin',
        isNumber: true,
        Cell: ({ rowInfo: { profitMargin, errorsArray }, initValues: { initProfitMargin } }) =>
          createProfitMarginCell(profitMargin, errorsArray, initProfitMargin),
        minWidth: CELL_MIN_WIDTH,
        visibleTips: true,
      },
      {
        Header: `損切幅${serviceId === FX ? '(pips)' : ''}`,
        accessor: 'lossCutWidth',
        isNumber: true,
        Cell: ({ rowInfo: { lossCutWidth, errorsArray }, initValues: { initLossCutWidth } }) =>
          createLossCutWidthCell(lossCutWidth, errorsArray, initLossCutWidth),
        minWidth: CELL_MIN_WIDTH,
        visibleTips: true,
      },
      {
        Header: 'フォロー値',
        accessor: 'follow',
        Cell: ({
          row: {
            original: { side },
          },
          rowInfo: { follow, errorsArray },
          initValues: { initFollow },
        }) => createFollowCell(follow, side, errorsArray, initFollow),
        minWidth: CELL_MIN_WIDTH,
        visibleTips: true,
      },
      {
        Header: 'カウンター値',
        accessor: 'counterValue',
        minWidth: 315,
        visibleTips: true,
        Cell: ({
          row: {
            original: { side },
          },
          rowInfo: { counterType, counterValue, errorsArray },
          initValues: { initCounterType, initCounterValue },
        }) =>
          createCounterCell(counterType, counterValue, side, errorsArray, initCounterType, initCounterValue, serviceId),
      },
      {
        Header: (
          <>
            稼働
            <i className={classNames('material-icons', styles.lockIcon)}>lock</i>
          </>
        ),
        accessor: 'operation',
        disableSortBy: true,
        Cell: ({ row: { original } }) => createOperationCell(original),
        sticky: 'right',
      },
      {
        Header: <MultiEditTableCheckbox rowNum={0} />,
        accessor: 'selection',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { id } }) => <MultiEditTableCheckbox rowNum={Number(id) + 1} />,
        sticky: 'right',
      },
    ],
    [serviceId, useNewOrderLineUpdate],
  );

  return deleteApGroupItemIsLoading ? (
    <Loader />
  ) : (
    <>
      <MultiEditCustomTable
        key={serviceId}
        tableData={tableData}
        columns={columns}
        resized={resized}
        emptyText="注文がありません"
        className={styles.table}
        tableMetaInfo={tableMetaInfo}
        propTableRowsRef={propTableRowsRef}
      />
    </>
  );
};

MultiEditTableBody.propTypes = {
  useNewOrderLineUpdate: PropTypes.func.isRequired,
  // useClosedOrderLineUpdate: PropTypes.func.isRequired,
  propTableRowsRef: PropTypes.shape({ current: PropTypes.shape({}).isRequired }).isRequired,
};

export default memo(MultiEditTableBody);
