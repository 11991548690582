/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Decimal from 'decimal.js';
import {
  AP_GROUP_ORDER,
  AP_GROUP_RULE_67_NOT_YEN_PAIR_MULTIPLIER,
  AP_GROUP_RULE_67_YEN_PAIR_MULTIPLIER,
  AP_GROUP_SOURCES,
  BUY_SELL_MAIN,
  COUNT_INPUT_NAME,
  COUNTER_BUY_MAX,
  COUNTER_BUY_MIN,
  COUNTER_NAME,
  COUNTER_PRECISION,
  COUNTER_PRICE_NAME,
  COUNTER_SELL_MAX,
  COUNTER_SELL_MIN,
  COUNTER_TYPE,
  COUNTRY_TYPE,
  FOLLOW_BUY_MAX,
  FOLLOW_BUY_MIN,
  FOLLOW_NAME,
  FOLLOW_PRECISION,
  FOLLOW_SELL_MAX,
  FOLLOW_SELL_MIN,
  FX,
  FX_CFD_AP_GROUP_CHANGE_LOGIC_STEP,
  LOSS_CUT_WIDTH_MAX,
  LOSS_CUT_WIDTH_MIN,
  LOSS_CUT_WIDTH_NAME,
  LOSS_CUT_WIDTH_PRECISION,
  MAX_QUANTITY_AP_ORDER,
  OPTIONS_COUNTER_TYPE,
  ORDERS_COMPOSITE_TYPES_MAIN,
  PRICE_1_NAME,
  PRICE_2_NAME,
  PRICE_MAX_MULTIPLIER,
  PRICE_MAX_NOT_PAIRED_YEN,
  PRICE_MAX_PAIRED_YEN,
  PRICE_MIN_MULTIPLIER,
  PROFIT_MARGIN_MAX,
  PROFIT_MARGIN_MIN,
  PROFIT_MARGIN_NAME,
  PROFIT_MARGIN_PRECISION,
  TRADE_METHODS,
  VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_BUY,
  VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_SELL,
  VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_BUY,
  VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_SELL,
  VALIDATION_ERROR_AP_ORDER_LOST_CUT_PIPS,
  VALIDATION_ERROR_AP_ORDER_OCO2_LESS_THAN_OCO1_BUY,
  VALIDATION_ERROR_AP_ORDER_OCO2_MORE_THAN_OCO1_SELL,
  VALIDATION_ERROR_AP_ORDER_PROFIT_MARGIN_PIPS,
  VALIDATION_ERROR_AP_RULE_66,
} from '../../constants';
import { changeApGroupItemRequest } from '../../redux/actions/portfolioActions';
import {
  createValidationErrorMessageAPOrderCounterPriceBuy,
  createValidationErrorMessageAPOrderCounterPriceSell,
  createValidationErrorMessageAPOrderEntryPrice1Buy,
  createValidationErrorMessageAPOrderEntryPrice1Sell,
  createValidationErrorMessageAPOrderEntryPrice2Buy,
  createValidationErrorMessageAPOrderEntryPrice2Sell,
  createValidationErrorMessageAPR67ProfitMarginBuy,
  createValidationErrorMessageAPR67ProfitMarginSell,
  createValidationErrorMessageAPR67LossCutWidthBuy,
  createValidationErrorMessageAPR67LossCutWidthSell,
  getAPQuantityMin,
  getAPQuantityStep,
  getAPValidateQuantityErrorMessage,
  ORDER_DETAILS_EDIT_HELPERS as editHelpers,
  getServiceQuantityUnit,
  checkIsWebApp,
  roundExactlyOnPrecisionMatching,
  roundToFixedPrecision,
  getPortfolioPriceStepByServiceId,
  getPortfolioChangeLogicStepByServiceId,
} from '../index';
import { sourceTypeSelector } from '../../redux/multiEdit';
import { getPipsLabelWithParentheses, getValidationCurrencyUnitByServiceId } from '../../utils';
import { useServiceId } from '../../hooks';

export const rule66Types = {
  follow: 0,
  counter: 1,
};

export const nanToBlank = (value) => (Number.isNaN(Number(value)) ? '' : value);

export const pipsToPrice = ({ instrumentId, valueInPips, store }) => {
  const { instrumentList } = store.getState().settings;
  const serviceId = instrumentList?.[instrumentId]?.serviceId;
  if (serviceId !== FX) return valueInPips || 0;

  const divider = instrumentId.endsWith(COUNTRY_TYPE.JPY)
    ? AP_GROUP_RULE_67_YEN_PAIR_MULTIPLIER
    : AP_GROUP_RULE_67_NOT_YEN_PAIR_MULTIPLIER;
  return Decimal.div(valueInPips || 0, divider);
};

export const useInstrumentSettingsGetOnce = (instrumentId, tradeMethod = TRADE_METHODS.MANUAL.ID, store) => {
  const { instrumentList } = store.getState().settings;
  return useMemo(() => {
    if (instrumentList[instrumentId]) {
      const {
        buyPriceMax,
        settings,
        pricePrecision,
        sellPriceMin,
        sellPriceMax,
        buyPriceMin,
        marketStatus,
        shortName,
        image,
      } = instrumentList[instrumentId];

      return {
        ...settings?.[tradeMethod],
        marketStatus,
        pricePrecision,
        sellPriceMax,
        sellPriceMin,
        buyPriceMax,
        buyPriceMin,
        shortName,
        image,
      };
    }
    return {
      shortName: instrumentId,
    };
  }, [instrumentList, instrumentId, tradeMethod]);
};

export const usePricesForBuySellGetOnce = ({ currencyPair }, store) => {
  const selectedCurrency = store.getState().currencies.rates[currencyPair];
  const { instrumentList } = store.getState().settings;
  const serviceId = instrumentList[currencyPair]?.serviceId;
  const pricePrecision = instrumentList[currencyPair]?.pricePrecision || 1;

  // TODO CFD FXとそれ以外の分岐で問題ないか要確認
  const sellPrice = useMemo(() => {
    if (!selectedCurrency || !serviceId) {
      return 0;
    }
    if (serviceId === FX) {
      return selectedCurrency.bid;
    }
    return Number(roundToFixedPrecision(selectedCurrency.bid, pricePrecision));
  }, [selectedCurrency, serviceId, pricePrecision]);

  // TODO CFD FXとそれ以外の分岐で問題ないか要確認
  const buyPrice = useMemo(() => {
    if (!selectedCurrency || !serviceId) {
      return 0;
    }
    if (serviceId === FX) {
      return selectedCurrency.ask;
    }
    return Number(roundToFixedPrecision(selectedCurrency.ask, pricePrecision));
  }, [selectedCurrency, serviceId, pricePrecision]);

  // TODO CFD FXとそれ以外の分岐で問題ないか要確認
  const previousSellPrice = useMemo(() => {
    if (!selectedCurrency || !serviceId) {
      return null;
    }
    if (serviceId === FX) {
      return selectedCurrency.previousBid;
    }
    return Number(roundToFixedPrecision(selectedCurrency.previousBid, pricePrecision));
  }, [selectedCurrency, pricePrecision, serviceId]);

  // TODO CFD FXとそれ以外の分岐で問題ないか要確認
  const previousBuyPrice = useMemo(() => {
    if (!selectedCurrency || !serviceId) {
      return null;
    }
    if (serviceId === FX) {
      return selectedCurrency.previousAsk;
    }
    return Number(roundToFixedPrecision(selectedCurrency.previousAsk, pricePrecision));
  }, [selectedCurrency, pricePrecision, serviceId]);

  // TODO CFD FXとそれ以外の分岐で問題ないか要確認
  const sellPriceHigh = useMemo(() => {
    if (!selectedCurrency || !serviceId) {
      return 0;
    }
    if (serviceId === FX) {
      return selectedCurrency.bidHigh;
    }
    return Number(roundToFixedPrecision(selectedCurrency.bidHigh, pricePrecision));
  }, [selectedCurrency, serviceId, pricePrecision]);

  // TODO CFD FXとそれ以外の分岐で問題ないか要確認
  const sellPriceLow = useMemo(() => {
    if (!selectedCurrency || !serviceId) {
      return 0;
    }
    if (serviceId === FX) {
      return selectedCurrency.askLow;
    }
    return Number(roundToFixedPrecision(selectedCurrency.askLow, pricePrecision));
  }, [selectedCurrency, serviceId, pricePrecision]);

  // TODO CFD FXとそれ以外の分岐で問題ないか要確認
  const sellPriceClose = useMemo(() => {
    if (!selectedCurrency || !serviceId) {
      return 0;
    }
    if (serviceId === FX) {
      return selectedCurrency.bidClose;
    }
    return Number(roundToFixedPrecision(selectedCurrency.bidClose, pricePrecision));
  }, [selectedCurrency, serviceId, pricePrecision]);

  const spread = Decimal.sub(buyPrice, sellPrice);

  return useMemo(
    () => ({
      sellPrice,
      buyPrice,
      previousSellPrice,
      previousBuyPrice,
      sellPriceHigh,
      sellPriceLow,
      sellPriceClose,
      spread,
    }),
    [buyPrice, previousBuyPrice, previousSellPrice, sellPrice, sellPriceClose, sellPriceHigh, sellPriceLow, spread],
  );
};

const useDynamicMultiEditInfo = (data, store) => {
  const {
    side,
    quantity,
    entryPrice1,
    entryPrice2,
    tp,
    sl,
    follow,
    counter,
    counterPrice,
    tradePrice,
    status,
    positionId,
    latestNewOrderPrice1,
    latestNewOrderPrice2,
    id,
    orderStatus,
    newOrder,
    // closedOrder,
  } = data;

  const sourceTypeRaw = useSelector(sourceTypeSelector);
  const sourceType = useMemo(
    () => (sourceTypeRaw === AP_GROUP_SOURCES.MONEY_HATCH.KEY ? TRADE_METHODS.MH_AP.ID : TRADE_METHODS.AP.ID),
    [sourceTypeRaw],
  );

  const dispatch = useDispatch();

  const isWeb = checkIsWebApp();

  // selectors
  const { instrumentId, activeApCount, id: groupId } = useSelector((state) => state.portfolio.selectedApGroupData);
  const requestIsLoading = useSelector((state) => state.portfolio.changingApGroupItemIsLoading);

  const serviceId = useServiceId(instrumentId);
  const isFX = serviceId === FX;

  const {
    quantityPrecision,
    pricePrecision,
    buyQuantityMin,
    buyQuantityMax,
    sellQuantityMin,
    sellQuantityMax,
    buyPriceMin,
    buyPriceMax,
    sellPriceMin,
    sellPriceMax,
  } = useInstrumentSettingsGetOnce(instrumentId, sourceType, store);

  const isMoneyHatch = sourceTypeRaw === AP_GROUP_SOURCES.MONEY_HATCH.KEY;

  const { sellPrice, buyPrice, spread } = usePricesForBuySellGetOnce({ currencyPair: instrumentId }, store);
  const priceRef = useRef({});
  useEffect(() => {
    priceRef.current = { sellPrice, buyPrice };
  }, [sellPrice, buyPrice]);

  // calculated memo variables
  const priceStep = useMemo(() => {
    return getPortfolioPriceStepByServiceId(serviceId, instrumentId, pricePrecision);
  }, [instrumentId, pricePrecision, serviceId]);
  const isActive = useMemo(() => status === AP_GROUP_ORDER.ACTIVITY.ACTIVE.ID, [status]);
  const hasOpenPositions = useMemo(() => positionId !== null, [positionId]);
  const isSingle = useMemo(
    () => (isActive ? !latestNewOrderPrice2 : !entryPrice2),
    [isActive, latestNewOrderPrice2, entryPrice2],
  );
  const firstOrderIsActive = useMemo(() => orderStatus === AP_GROUP_ORDER.STATUS.FIRST_ORDER.ID, [orderStatus]);

  const priceIsDisabled = (isActive && !firstOrderIsActive) || isMoneyHatch;

  const deActiveAndHasPositions = useMemo(
    () => !isActive && (hasOpenPositions || orderStatus !== AP_GROUP_ORDER.STATUS.NO_ORDER.ID),
    [isActive, hasOpenPositions, orderStatus],
  );

  const apQuantityStep = useMemo(() => {
    if (isFX) return getAPQuantityStep(instrumentId);
    return quantityPrecision;
  }, [instrumentId, isFX, quantityPrecision]);

  const profitMarginStep = useMemo(() => {
    return getPortfolioChangeLogicStepByServiceId(serviceId, pricePrecision);
  }, [pricePrecision, serviceId]);

  const lossCutWidthStep = useMemo(() => {
    return getPortfolioChangeLogicStepByServiceId(serviceId, pricePrecision);
  }, [pricePrecision, serviceId]);

  const followStep = useMemo(() => {
    return getPortfolioChangeLogicStepByServiceId(serviceId, pricePrecision);
  }, [pricePrecision, serviceId]);

  const price1 = useMemo(
    () => (isActive ? latestNewOrderPrice1 : entryPrice1),
    [isActive, latestNewOrderPrice1, entryPrice1],
  );
  const price2 = useMemo(
    () => (isActive ? latestNewOrderPrice2 : entryPrice2),
    [isActive, latestNewOrderPrice2, entryPrice2],
  );

  const initQuantity = editHelpers.getNumberValue(quantity);
  const initEntryPrice = editHelpers.getNumberValue(price1);
  const initEntryPric2 = editHelpers.getNumberValue(price2);
  const initProfitMargin = editHelpers.getNumberValue(tp);
  const initLossCutWidth = editHelpers.getNumberValue(sl);
  const initFollow = editHelpers.getNumberValue(follow);
  const initCounterType = counter ? OPTIONS_COUNTER_TYPE[0].id : OPTIONS_COUNTER_TYPE[1].id;
  const initCounterValue = editHelpers.getNumberValue(counter ?? counterPrice);

  // local state
  const [amountValue, setAmountValue] = useState(initQuantity);
  const [entryPriceValue, setEntryPriceValue] = useState(initEntryPrice);
  const [entryPriceValue2, setEntryPriceValue2] = useState(initEntryPric2);
  const [profitMarginValue, setProfitMarginValue] = useState(initProfitMargin);
  const [lossCutWidthValue, setLossCutWidthValue] = useState(initLossCutWidth);
  const [followValue, setFollowValue] = useState(initFollow);
  const [counterType, changeCounterType] = useState(initCounterType);
  const [counterValue, setCounterValue] = useState(initCounterValue);
  const [errorsArray, changeErrorsArray] = useState([]);

  const counterPipsIsSelected = useMemo(() => counterType === OPTIONS_COUNTER_TYPE[0].id, [counterType]);

  const isSellSide = useMemo(() => Number(side) === BUY_SELL_MAIN.SELL.ID, [side]);
  const currencyUnit = getValidationCurrencyUnitByServiceId(instrumentId, serviceId);
  const quantityUnit = getServiceQuantityUnit(serviceId);

  const SINGLE = ORDERS_COMPOSITE_TYPES_MAIN.SINGLE.NAME;
  const OCO1 = ORDERS_COMPOSITE_TYPES_MAIN.OCO1.NAME;
  const OCO2 = ORDERS_COMPOSITE_TYPES_MAIN.OCO2.NAME;

  const setEntryPriceValueWrap = (newValue, lineUpdate) => {
    if (Object.keys(newOrder).length !== 0) {
      const newOrderDataId = newOrder[SINGLE] ? newOrder[SINGLE].orderId : newOrder[OCO1].orderId || null;
      lineUpdate(newOrderDataId, newValue);
    }
    setEntryPriceValue(newValue);
  };

  const setEntryPriceValue2Wrap = (newValue, lineUpdate) => {
    if (Object.keys(newOrder).length !== 0) {
      const newOrderData2Id = newOrder[OCO2] ? newOrder[OCO2].orderId : null;
      if (newOrderData2Id) lineUpdate(newOrderData2Id, newValue);
    }
    setEntryPriceValue2(newValue);
  };

  const setProfitMarginValueWrap = (newValue) => {
    // if (closedOrder) {
    //   const closedOrderDataId = closedOrder[SINGLE] ? closedOrder[SINGLE].orderId
    //                                          : closedOrder[OCO1].orderId || null;
    //   lineUpdate(closedOrderDataId, newValue);
    // }
    setProfitMarginValue(newValue);
  };

  const setLossCutWidthValueWrap = (newValue) => {
    // if (closedOrder) {
    //   const closedOrderData2Id = closedOrder[OCO2] ? closedOrder[OCO2].orderId : null;
    //   if (closedOrderData2Id) lineUpdate(closedOrderData2Id, newValue);
    // }
    setLossCutWidthValue(newValue);
  };

  // validation handlers
  const validateQuantity = useCallback(
    (newValue) => {
      let errorMessage;
      let minValue;
      let maxValue;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        errorMessage = getAPValidateQuantityErrorMessage(instrumentId);
        minValue = getAPQuantityMin(instrumentId);
        maxValue = MAX_QUANTITY_AP_ORDER;
      } else {
        minValue = isSellSide ? sellQuantityMin : buyQuantityMin;
        maxValue = isSellSide ? sellQuantityMax : buyQuantityMax;

        if (maxValue === 0) {
          errorMessage = '現在、この銘柄の新規売り注文は受付できません。';
        } else if (minValue === maxValue) {
          errorMessage = `${quantityPrecision}${quantityUnit}でご設定ください。`;
        } else {
          // eslint-disable-next-line max-len
          errorMessage = `${minValue}${quantityUnit}以上${maxValue}${quantityUnit}以下、${quantityPrecision}${quantityUnit}単位でご設定ください。`;
        }
      }

      return editHelpers.createValidateFunction({
        inputName: COUNT_INPUT_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision: quantityPrecision,
        changeErrorFunction: changeErrorsArray,
      })(newValue);
    },
    [
      isFX,
      quantityUnit,
      quantityPrecision,
      instrumentId,
      isSellSide,
      sellQuantityMin,
      buyQuantityMin,
      sellQuantityMax,
      buyQuantityMax,
    ],
  );

  const validatePrice2 = useCallback(
    (newValue, entryPriceNewValue) => {
      const currentPrice = priceRef.current[isSellSide ? 'sellPrice' : 'buyPrice'];
      const compareEntryPrice = Number(entryPriceNewValue ?? entryPriceValue);

      const OCOValidation = isSellSide ? newValue < compareEntryPrice : newValue > compareEntryPrice;
      const OCOErrorMessage = isSellSide
        ? VALIDATION_ERROR_AP_ORDER_OCO2_MORE_THAN_OCO1_SELL
        : VALIDATION_ERROR_AP_ORDER_OCO2_LESS_THAN_OCO1_BUY;

      let minValue;
      let maxValue;
      let errorMessage;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        const hasYen = instrumentId.endsWith(COUNTRY_TYPE.JPY);

        minValue = Decimal.mul(currentPrice, PRICE_MIN_MULTIPLIER).toNumber();
        maxValue = Math.min(
          Decimal.mul(currentPrice, PRICE_MAX_MULTIPLIER).toNumber(),
          hasYen ? PRICE_MAX_PAIRED_YEN : PRICE_MAX_NOT_PAIRED_YEN,
        );
        errorMessage = isSellSide
          ? createValidationErrorMessageAPOrderEntryPrice2Sell(currentPrice, pricePrecision)
          : createValidationErrorMessageAPOrderEntryPrice2Buy(currentPrice, pricePrecision);
      } else {
        minValue = isSellSide ? sellPriceMin : buyPriceMin;
        maxValue = isSellSide ? sellPriceMax : buyPriceMax;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      return editHelpers.createValidateFunction({
        inputName: PRICE_2_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision: pricePrecision,
        changeErrorFunction: changeErrorsArray,
        additionalCheck: OCOValidation,
        additionalCheckErrorMessage: OCOErrorMessage,
        exclusiveMin: isFX,
      })(newValue);
    },
    [
      isSellSide,
      entryPriceValue,
      isFX,
      pricePrecision,
      instrumentId,
      sellPriceMin,
      buyPriceMin,
      sellPriceMax,
      buyPriceMax,
      currencyUnit,
    ],
  );

  const validatePrice1 = useCallback(
    (newValue) => {
      const currentPrice = priceRef.current[isSellSide ? 'sellPrice' : 'buyPrice'];

      let minValue;
      let maxValue;
      let errorMessage;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        const hasYen = instrumentId.endsWith(COUNTRY_TYPE.JPY);

        minValue = Decimal.mul(currentPrice, PRICE_MIN_MULTIPLIER).toNumber();
        maxValue = Math.min(
          Decimal.mul(currentPrice, PRICE_MAX_MULTIPLIER).toNumber(),
          hasYen ? PRICE_MAX_PAIRED_YEN : PRICE_MAX_NOT_PAIRED_YEN,
        );

        errorMessage = isSellSide
          ? createValidationErrorMessageAPOrderEntryPrice1Sell(currentPrice, pricePrecision)
          : createValidationErrorMessageAPOrderEntryPrice1Buy(currentPrice, pricePrecision);
      } else {
        minValue = isSellSide ? sellPriceMin : buyPriceMin;
        maxValue = isSellSide ? sellPriceMax : buyPriceMax;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      if (entryPriceValue2 !== '') validatePrice2(entryPriceValue2, newValue);
      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      return editHelpers.createValidateFunction({
        inputName: PRICE_1_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision: pricePrecision,
        changeErrorFunction: changeErrorsArray,
        exclusiveMin: isFX,
      })(newValue);
    },
    [
      isSellSide,
      isFX,
      pricePrecision,
      instrumentId,
      sellPriceMin,
      buyPriceMin,
      sellPriceMax,
      buyPriceMax,
      currencyUnit,
      validatePrice2,
      entryPriceValue2,
    ],
  );

  const validateProfitMargin = useCallback(
    (rawValue) => {
      const currentPrice = priceRef.current[isSellSide ? 'buyPrice' : 'sellPrice'];
      let isValid = true;
      let additionalCheckErrorMessage;

      // User is allowed to insert "-" since it is valid character for numbers
      // this might mess up with validation, so it is treated by validation as a blank value
      const valueInPips = nanToBlank(rawValue) || 0;

      if (hasOpenPositions) {
        const profit = pipsToPrice({ instrumentId, valueInPips, store });

        if (isSellSide) {
          const tpPrice = Decimal.sub(tradePrice, profit).toNumber();
          isValid = tpPrice <= currentPrice;
          additionalCheckErrorMessage = createValidationErrorMessageAPR67ProfitMarginSell(currentPrice, pricePrecision);
        } else {
          const tpPrice = Decimal.add(tradePrice, profit).toNumber();
          isValid = currentPrice <= tpPrice;
          additionalCheckErrorMessage = createValidationErrorMessageAPR67ProfitMarginBuy(currentPrice, pricePrecision);
        }
      }

      let minValue;
      let maxValue;
      let valuePrecision;

      let errorMessage;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        minValue = PROFIT_MARGIN_MIN;
        maxValue = PROFIT_MARGIN_MAX;
        errorMessage = VALIDATION_ERROR_AP_ORDER_PROFIT_MARGIN_PIPS;
        valuePrecision = PROFIT_MARGIN_PRECISION;
      } else {
        minValue = isSellSide ? sellPriceMin : buyPriceMin;
        maxValue = isSellSide ? sellPriceMax : buyPriceMax;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
        valuePrecision = pricePrecision;
      }

      return editHelpers.createValidateFunction({
        inputName: PROFIT_MARGIN_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        additionalCheckErrorMessage,
        additionalCheck: isValid,
      })(nanToBlank(rawValue));
    },
    [
      isSellSide,
      pricePrecision,
      hasOpenPositions,
      isFX,
      tradePrice,
      instrumentId,
      sellPriceMin,
      buyPriceMin,
      sellPriceMax,
      buyPriceMax,
      currencyUnit,
      store,
    ],
  );

  const validateLossCutWidth = useCallback(
    (rawValue) => {
      let minValue;
      let maxValue;
      let errorMessage;
      let valuePrecision;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        minValue = LOSS_CUT_WIDTH_MIN;
        maxValue = LOSS_CUT_WIDTH_MAX;
        errorMessage = VALIDATION_ERROR_AP_ORDER_LOST_CUT_PIPS;
        valuePrecision = LOSS_CUT_WIDTH_PRECISION;
      } else {
        minValue = -(isSellSide ? sellPriceMax : buyPriceMax);
        maxValue = -(isSellSide ? sellPriceMin : buyPriceMin);
        valuePrecision = pricePrecision;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      const currentPrice = priceRef.current[isSellSide ? 'buyPrice' : 'sellPrice'];

      let additionalCheck = true;
      const additionalCheckErrorMessage = isSellSide
        ? createValidationErrorMessageAPR67LossCutWidthSell(currentPrice, pricePrecision)
        : createValidationErrorMessageAPR67LossCutWidthBuy(currentPrice, pricePrecision);

      // User is allowed to insert "-" since it is valid character for numbers
      // this might mess up with validation, so it is treated by validation as a blank value
      const valueInPips = nanToBlank(rawValue);

      if (hasOpenPositions && valueInPips) {
        const loss = pipsToPrice({ instrumentId, valueInPips, store });

        const termWithDifference = Decimal.sub(loss, spread);
        if (isSellSide) {
          const slPrice = Decimal.sub(tradePrice, termWithDifference).toNumber();
          additionalCheck = currentPrice <= slPrice;
        } else {
          const slPrice = Decimal.add(tradePrice, termWithDifference).toNumber();
          additionalCheck = slPrice <= currentPrice;
        }
      }

      return editHelpers.createValidateFunction({
        inputName: LOSS_CUT_WIDTH_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        skippIfEmptyString: true,
        additionalCheck,
        additionalCheckErrorMessage,
      })(valueInPips);
    },
    [
      spread,
      isFX,
      isSellSide,
      pricePrecision,
      hasOpenPositions,
      sellPriceMax,
      buyPriceMax,
      sellPriceMin,
      buyPriceMin,
      currencyUnit,
      tradePrice,
      instrumentId,
      store,
    ],
  );

  const rule66Validation = useCallback(
    ({ value, type }) => {
      if (type === rule66Types.follow) {
        return value !== '' || counterValue !== '';
      }
      if (type === rule66Types.counter) {
        return value !== '' || followValue !== '';
      }
      return false;
    },
    [followValue, counterValue],
  );

  const validateFollow = useCallback(
    (newValue) => {
      let minValue;
      let maxValue;
      let errorMessage;
      let valuePrecision;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        minValue = isSellSide ? FOLLOW_SELL_MIN : FOLLOW_BUY_MIN;
        maxValue = isSellSide ? FOLLOW_SELL_MAX : FOLLOW_BUY_MAX;
        errorMessage = isSellSide
          ? VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_SELL
          : VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_BUY;
        valuePrecision = FOLLOW_PRECISION;
      } else {
        minValue = isSellSide ? -sellPriceMax : buyPriceMin;
        maxValue = isSellSide ? -sellPriceMin : buyPriceMax;
        valuePrecision = pricePrecision;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      const additionalCheck = rule66Validation({ value: newValue, type: rule66Types.follow });

      return editHelpers.createValidateFunction({
        inputName: FOLLOW_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        skippIfEmptyString: true,
        additionalCheck,
        additionalCheckErrorMessage: VALIDATION_ERROR_AP_RULE_66,
        additionalCheckFieldName: COUNTER_NAME,
      })(newValue);
    },
    [
      isFX,
      rule66Validation,
      isSellSide,
      sellPriceMax,
      buyPriceMin,
      sellPriceMin,
      buyPriceMax,
      pricePrecision,
      currencyUnit,
    ],
  );

  const validateCounter = useCallback(
    (newValue, isCounterSelected) => {
      const isCounter = isCounterSelected ?? counterPipsIsSelected;
      let minValue;
      let maxValue;
      let valuePrecision;
      let errorMessage;
      let exclusiveMin = false;
      let exclusiveMax = false;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        if (isCounter) {
          minValue = isSellSide ? COUNTER_SELL_MIN : COUNTER_BUY_MIN;
          maxValue = isSellSide ? COUNTER_SELL_MAX : COUNTER_BUY_MAX;
          errorMessage = isSellSide
            ? VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_SELL
            : VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_BUY;
          valuePrecision = COUNTER_PRECISION;
        } else {
          const currentPrice = priceRef.current[isSellSide ? 'sellPrice' : 'buyPrice'];
          minValue = PRICE_MIN_MULTIPLIER * currentPrice;
          maxValue = PRICE_MAX_MULTIPLIER * currentPrice;
          errorMessage = isSellSide
            ? createValidationErrorMessageAPOrderCounterPriceSell(currentPrice, pricePrecision)
            : createValidationErrorMessageAPOrderCounterPriceBuy(currentPrice, pricePrecision);
          valuePrecision = pricePrecision;
          exclusiveMin = true;
          exclusiveMax = true;
        }
      } else {
        valuePrecision = pricePrecision;
        if (isCounter) {
          minValue = isSellSide ? sellPriceMin : -buyPriceMax;
          maxValue = isSellSide ? sellPriceMax : -buyPriceMin;
        } else {
          minValue = isSellSide ? sellPriceMin : buyPriceMin;
          maxValue = isSellSide ? sellPriceMax : buyPriceMax;
        }
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      const additionalCheck = rule66Validation({ value: newValue, type: rule66Types.counter });

      return editHelpers.createValidateFunction({
        inputName: COUNTER_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        exclusiveMin,
        exclusiveMax,
        skippIfEmptyString: true,
        additionalCheck,
        additionalCheckErrorMessage: VALIDATION_ERROR_AP_RULE_66,
        additionalCheckFieldName: FOLLOW_NAME,
      })(newValue);
    },
    [
      counterPipsIsSelected,
      isFX,
      rule66Validation,
      isSellSide,
      pricePrecision,
      currencyUnit,
      sellPriceMin,
      buyPriceMax,
      sellPriceMax,
      buyPriceMin,
    ],
  );

  const validateChangedData = useCallback(
    (changedData) => {
      const errorObject = [];

      if (Object.prototype.hasOwnProperty.call(changedData, COUNT_INPUT_NAME)) {
        errorObject.push(validateQuantity(changedData.quantity));
      }
      if (!priceIsDisabled && Object.prototype.hasOwnProperty.call(changedData, PRICE_1_NAME)) {
        errorObject.push(validatePrice1(changedData.entryPrice1));
      }
      if (!priceIsDisabled && Object.prototype.hasOwnProperty.call(changedData, PRICE_2_NAME)) {
        errorObject.push(validatePrice2(changedData.entryPrice2));
      }
      errorObject.push(validateProfitMargin(changedData.tp));
      errorObject.push(validateLossCutWidth(changedData.sl));
      errorObject.push(validateFollow(changedData.follow));

      errorObject.push(
        validateCounter(
          changedData[
            Object.prototype.hasOwnProperty.call(changedData, COUNTER_NAME) ? COUNTER_NAME : COUNTER_PRICE_NAME
          ],
        ),
      );

      return errorObject;
    },
    [
      validateQuantity,
      validatePrice1,
      validatePrice2,
      validateProfitMargin,
      validateLossCutWidth,
      validateFollow,
      validateCounter,
      priceIsDisabled,
    ],
  );

  // TODO CFD FXかそうでないかの判定で問題ないか要確認
  const handleQuantityValue = useCallback(
    (val) => {
      const newValue = isFX ? val : roundExactlyOnPrecisionMatching(val, Math.round(1 / quantityPrecision));
      setAmountValue(newValue);
    },
    [quantityPrecision, isFX],
  );

  const switchCounterValue = useCallback(
    (type) => {
      const switchValue = editHelpers.getNumberValue(type === OPTIONS_COUNTER_TYPE[0].id ? counter : counterPrice);
      changeCounterType(type);
      changeErrorsArray((prevVal) => prevVal.filter((item) => item.inputName !== COUNTER_NAME));
      setCounterValue(switchValue);
      validateCounter(switchValue, type === OPTIONS_COUNTER_TYPE[0].id);
    },
    [counter, counterPrice, changeCounterType, setCounterValue, validateCounter],
  );

  const submitHandler = useCallback(
    (callback) => () => {
      let changedData;
      const dataTemplate = {
        tp: editHelpers.convertToNumberOrEmptyString(profitMarginValue),
        sl: editHelpers.convertToNumberOrEmptyString(lossCutWidthValue),
        follow: editHelpers.convertToNumberOrEmptyString(followValue),
        [counterPipsIsSelected ? COUNTER_NAME : COUNTER_PRICE_NAME]:
          editHelpers.convertToNumberOrEmptyString(counterValue),
      };

      // when priceIsDisabled=true, use the original price,
      //   - entryPriceX, not latestNewOrderPriceX
      const targetPrice1 = priceIsDisabled ? entryPrice1 : editHelpers.convertToNumberOrEmptyString(entryPriceValue);

      if (isSingle && !priceIsDisabled) {
        changedData = {
          quantity: editHelpers.convertToNumberOrEmptyString(amountValue),
          entryPrice1: targetPrice1,
          instrumentId,
          ...dataTemplate,
        };
      } else {
        const targetPrice2 = priceIsDisabled ? entryPrice2 : editHelpers.convertToNumberOrEmptyString(entryPriceValue2);
        changedData = {
          quantity: editHelpers.convertToNumberOrEmptyString(amountValue),
          entryPrice1: targetPrice1,
          entryPrice2: targetPrice2,
          // instrumentId,
          ...dataTemplate,
        };
      }
      const validationErrors = validateChangedData(changedData);

      if (!validationErrors.includes(true)) {
        dispatch(
          changeApGroupItemRequest({
            groupId,
            apId: id,
            data: changedData,
            callback,
            status: activeApCount === 0 ? 0 : 1,
          }),
        );
      }
    },
    [
      profitMarginValue,
      lossCutWidthValue,
      followValue,
      counterPipsIsSelected,
      counterValue,
      priceIsDisabled,
      entryPrice1,
      entryPriceValue,
      isSingle,
      validateChangedData,
      amountValue,
      entryPrice2,
      entryPriceValue2,
      dispatch,
      groupId,
      id,
      activeApCount,
      instrumentId,
    ],
  );

  const counterMinValue = useMemo(() => {
    if (!counterPipsIsSelected) {
      return 0;
    }

    return isSellSide ? 0 : null;
  }, [isSellSide, counterPipsIsSelected]);

  const counterStep = useMemo(() => {
    if (isFX && counterPipsIsSelected) {
      return FX_CFD_AP_GROUP_CHANGE_LOGIC_STEP;
    }
    return getPortfolioPriceStepByServiceId(serviceId, instrumentId, pricePrecision);
  }, [isFX, counterPipsIsSelected, serviceId, instrumentId, pricePrecision]);

  const counterTypeCurrencyUnit = !isFX && isWeb && currencyUnit ? `(${currencyUnit})` : '';
  const pipsLabelWithParentheses = getPipsLabelWithParentheses(serviceId, instrumentId);
  return {
    quantity: {
      get: amountValue,
      set: handleQuantityValue,
      label: `数量(${quantityUnit})`,
      name: COUNT_INPUT_NAME,
      validate: validateQuantity,
      isDisabled: deActiveAndHasPositions || hasOpenPositions,
      step: apQuantityStep,
      isChanged: amountValue !== initQuantity,
    },
    entryPrice: {
      get: entryPriceValue,
      set: setEntryPriceValueWrap,
      isDisabled: deActiveAndHasPositions || priceIsDisabled,
      label: isSingle ? 'エントリー価格' : 'エントリー価格1',
      name: PRICE_1_NAME,
      validate: validatePrice1,
      isChanged: entryPriceValue !== initEntryPrice,
    },
    entryPrice2: {
      get: entryPriceValue2,
      set: setEntryPriceValue2Wrap,
      label: 'エントリー価格2',
      name: PRICE_2_NAME,
      validate: validatePrice2,
      isDisabled: deActiveAndHasPositions || priceIsDisabled,
      isChanged: entryPriceValue2 !== initEntryPric2,
    },
    profitMargin: {
      get: profitMarginValue,
      set: setProfitMarginValueWrap,
      label: `利確幅${pipsLabelWithParentheses}`,
      step: profitMarginStep,
      name: PROFIT_MARGIN_NAME,
      validate: validateProfitMargin,
      isDisabled: deActiveAndHasPositions,
      isChanged: profitMarginValue !== initProfitMargin,
    },
    lossCutWidth: {
      get: lossCutWidthValue,
      set: setLossCutWidthValueWrap,
      label: `損切幅${pipsLabelWithParentheses}`,
      step: lossCutWidthStep,
      name: LOSS_CUT_WIDTH_NAME,
      validate: validateLossCutWidth,
      isDisabled: deActiveAndHasPositions,
      min: null,
      isChanged: lossCutWidthValue !== initLossCutWidth,
    },
    follow: {
      get: followValue,
      set: setFollowValue,
      label: `フォロー値${pipsLabelWithParentheses}`,
      step: followStep,
      name: FOLLOW_NAME,
      validate: validateFollow,
      isDisabled: deActiveAndHasPositions,
      min: Number(side) === BUY_SELL_MAIN.BUY.ID ? 0 : null,
      isChanged: followValue !== initFollow,
    },
    counterType: {
      get: counterType,
      set: switchCounterValue,
      label: `カウンター値${counterTypeCurrencyUnit}`,
      name: COUNTER_TYPE,
      options: OPTIONS_COUNTER_TYPE.map((row) => ({
        ...row,
        isDisabled: deActiveAndHasPositions,
      })),
      isChanged: counterType !== initCounterType,
    },
    counterValue: {
      get: counterValue,
      set: setCounterValue,
      label: counterPipsIsSelected ? '' : '@',
      withPips: counterPipsIsSelected,
      step: counterStep,
      name: COUNTER_NAME,
      validate: validateCounter,
      min: counterMinValue,
      isDisabled: deActiveAndHasPositions,
      isChanged: counterValue !== initCounterValue,
    },
    submit: {
      label: '完了',
      handler: submitHandler,
      isLoading: requestIsLoading,
      isDisabled: Boolean(errorsArray.length),
    },
    priceIsDisabled,
    isSingle,
    isAllDisabled: deActiveAndHasPositions,
    priceStep,
    errorsArray,
  };
};

export default useDynamicMultiEditInfo;
