import { put, takeEvery, retry, call, select } from 'redux-saga/effects';
import { GET_NOTIFICATION_LIST_REQUEST, OPEN_ADVERTISEMENT_REQUEST } from '../actionConstants/advertisementConstants';
import { RETRY_DELAY, RETRY_MAX_TRIES } from '../../constants/apiConstant';
import { getNotificationList, updateNotificationReadStatus } from '../../api/generalApi';
import {
  getNotificationListSuccess,
  notificationLoadingEnd,
  openAdvertisementSuccess,
  resetNotification,
} from '../actions/advertisementActions';
import { checkIsWebApp } from '../../services';
import { errorHandler } from './errorSaga';

function* getNotificationListRequestHandler() {
  try {
    // モーダルを開いている間は取得しない
    const isOpen = yield select((state) => state.advertisement.isOpen);
    if (isOpen) {
      return;
    }
    const {
      data: { list },
    } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getNotificationList, { isWeb: checkIsWebApp() });
    yield put(getNotificationListSuccess({ advertisementsList: list }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(notificationLoadingEnd());
  }
}

function* openAdvertisementRequestHandler({ payload }) {
  const { callbackAction } = payload;
  try {
    const advertisementsList = yield select((state) => state.advertisement.advertisementsList);
    if (!advertisementsList.length) {
      // 次にgetNotificationListRequestされるまでモーダルを出さない
      yield put(resetNotification());
      if (callbackAction) {
        yield put(callbackAction); // 通知が全てなくなったらコールバック
      }
      return;
    }
    const advertisementInfo = advertisementsList.shift();
    // update advertisementsList after remove first element
    yield put(getNotificationListSuccess({ advertisementsList }));

    yield put(
      openAdvertisementSuccess({ body: advertisementInfo.body, popupUrl: advertisementInfo.popupUrl, callbackAction }),
    );

    yield call(updateNotificationReadStatus, { notificationId: advertisementInfo.id });
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

export default function* advertisementSaga() {
  yield takeEvery(GET_NOTIFICATION_LIST_REQUEST, getNotificationListRequestHandler);
  yield takeEvery(OPEN_ADVERTISEMENT_REQUEST, openAdvertisementRequestHandler);
}
