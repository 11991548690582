import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  CHECK_INITIAL_DEPOSIT_REQUEST,
  GET_MESSAGES_REQUEST,
  GET_RANGE_OUTS_REQUEST,
  GET_UNREAD_COUNT_REQUEST,
  MARK_AS_READ_REQUEST,
  OPEN_MESSAGE_MODAL_REQUEST,
  REFRESH_MESSAGES_REQUEST,
  START_MESSAGE_LISTENER,
} from '../../actionConstants/messageConstants';
import { getUnreadCountRequestHandler } from './getUnreadCountRequestHandler';
import { messageListenerHandler } from './messageListenerHandler';
import { getMessagesRequestHandler } from './getMessagesRequestHandler';
import { refreshMessagesRequestHandler } from './refreshMessagesRequestHandler';
import { markAsReadRequestHandler } from './markAsReadRequestHandler';
import { checkInitialDepositRequestHandler } from './checkInitialDepositRequestHandler';
import { getRangeOutsRequestHandler } from './getRangeOutsRequestHandler';
import { openMessageModalRequestHandler } from './openMessageModalRequestHandler';

export default function* messageSagaHandler() {
  yield takeLatest(START_MESSAGE_LISTENER, messageListenerHandler);
  yield takeLatest(GET_UNREAD_COUNT_REQUEST, getUnreadCountRequestHandler);
  yield takeLatest(REFRESH_MESSAGES_REQUEST, refreshMessagesRequestHandler);
  yield takeEvery(GET_MESSAGES_REQUEST, getMessagesRequestHandler);
  yield takeEvery(MARK_AS_READ_REQUEST, markAsReadRequestHandler);
  yield takeEvery(CHECK_INITIAL_DEPOSIT_REQUEST, checkInitialDepositRequestHandler);
  yield takeEvery(GET_RANGE_OUTS_REQUEST, getRangeOutsRequestHandler);
  yield takeEvery(OPEN_MESSAGE_MODAL_REQUEST, openMessageModalRequestHandler);
}
