import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useServiceName } from 'shared-modules/hooks';
import { ServiceDisabledInfo } from '../../../../../common';
import { useStartShareButton } from '../../hooks';
import icon from '../../../../../../assets/builder/try_share.png';
import { Card } from '../Card';
import styles from './welcome.module.scss';

export const Welcome = memo(({ serviceId }) => {
  const button = useStartShareButton(serviceId, 'はじめる');
  const serviceName = useServiceName(serviceId);
  return (
    <Card>
      <ServiceDisabledInfo serviceId={serviceId}>
        <>
          <div className={styles.title}>{serviceName}のビルダーにもチャレンジしてみませんか？</div>
          <img src={icon} alt="" />
          <div className={styles.buttonArea}>{button}</div>
        </>
      </ServiceDisabledInfo>
    </Card>
  );
});

Welcome.propTypes = {
  serviceId: PropTypes.string.isRequired,
};
