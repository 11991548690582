import { memo } from 'react';
import PropTypes from 'prop-types';
import { useCartSummaryInfo } from 'shared-modules/services/hooks';
import { useSelector } from 'react-redux';
import { AssetDetail } from '../../AssetDetail';
import styles from './autoSelectAssetDetail.module.scss';
import { mockSummaryInfoHasApGroup } from '../../../../../constants/tutorial/otherMockData';

export const AutoSelectAssetDetail = memo(
  ({ serviceId, recommendedMargin, requiredMargin, hideMarginInfo, onClick }) => {
    const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);
    const meterData = useCartSummaryInfo(serviceId);
    const mockSummaryInfo = mockSummaryInfoHasApGroup[serviceId];
    const summaryInfo = tutorialMode ? mockSummaryInfo : meterData;
    meterData.consumedMargin = requiredMargin.get ?? 0;

    return (
      <AssetDetail
        className={styles.container}
        meterData={summaryInfo}
        serviceId={serviceId}
        recommendedMargin={recommendedMargin}
        requiredMargin={requiredMargin}
        onClick={onClick}
        hideMarginInfo={hideMarginInfo}
      />
    );
  },
);

AutoSelectAssetDetail.propTypes = {
  serviceId: PropTypes.string.isRequired,
  recommendedMargin: PropTypes.shape({
    get: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  requiredMargin: PropTypes.shape({
    get: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  hideMarginInfo: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

AutoSelectAssetDetail.defaultProps = {
  recommendedMargin: undefined,
  requiredMargin: undefined,
  hideMarginInfo: false,
};
