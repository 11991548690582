import { BUILDER_PAGES, BUILDER_TYPE_TAB_CREATE } from 'shared-modules/constants/builder';
import {
  CHANGE_ACTIVE_PAGE,
  CHANGE_ACTIVE_TABLE,
  RESET_WEB_BUILDER_OPTIONS,
  ADD_DRAWING_POINT,
  CHANGE_SELECT_RECOMMEND,
  CHANGE_RECOMMEND_LOGIC_LIST,
  CHANGE_RECOMMEND_MODAL,
  CHANGE_ACTIVE_TAB,
} from '../actionConstants/builderConstants';

const initialState = {
  activePage: BUILDER_PAGES.BUILDER_TYPE_PAGE.ID,
  activeTable: 0,
  sketchData: [],
  selectRecommend: 0,
  recommendLogicList: [],
  isOpenRecommendModal: false,
  activeTab: BUILDER_TYPE_TAB_CREATE,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_ACTIVE_PAGE: {
      const { activePage } = payload;
      return {
        ...state,
        activePage,
      };
    }
    case CHANGE_ACTIVE_TABLE: {
      const { tableId } = payload;

      return {
        ...state,
        activeTable: tableId,
      };
    }
    case RESET_WEB_BUILDER_OPTIONS: {
      return initialState;
    }
    case ADD_DRAWING_POINT: {
      const { sketchData } = payload;

      return {
        ...state,
        sketchData,
      };
    }
    case CHANGE_SELECT_RECOMMEND: {
      const { selectRecommend } = payload;
      return {
        ...state,
        selectRecommend,
      };
    }
    case CHANGE_RECOMMEND_LOGIC_LIST: {
      const { recommendLogicList } = payload;

      return {
        ...state,
        recommendLogicList,
      };
    }
    case CHANGE_RECOMMEND_MODAL: {
      const { isOpenRecommendModal } = payload;

      return {
        ...state,
        isOpenRecommendModal,
      };
    }
    case CHANGE_ACTIVE_TAB: {
      const { activeTab } = payload;
      return { ...state, activeTab };
    }
    default: {
      return state;
    }
  }
};
