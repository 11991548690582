import React, { memo, useCallback, useMemo, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import { removeApData } from 'shared-modules/redux/multiEdit';
import { openPortfolioAutoTradeDetailModal } from '../../../../redux/actions';
import { Modal, SplitPane } from '../../../../components';
import ChartArea from './components/ChartArea';
import MultiEditTable from './components/MultiEditTable';
import MultiEditTableFooter from './components/MultiEditTable/MultiEditTableFooter';
import { DEFAULT_CHART_HEIGHT, SPACER } from './constants';
import styles from './multiEdit.module.scss';

const MultiEdit = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const bodyRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(DEFAULT_CHART_HEIGHT);
  const [bodyHeight, setBodyHeight] = useState(0);
  const cardData = useSelector((state) => state.multiEdit.portfolioAutoTradeCardData);

  const isOpenTechLogicInfo = useSelector((state) => state.modals.techLogicInfo.isOpen);

  const handleClose = useCallback(() => {
    closeModal();
    if (!isOpenTechLogicInfo) {
      dispatch(openPortfolioAutoTradeDetailModal({ data: cardData }));
    }
  }, [dispatch, closeModal, cardData, isOpenTechLogicInfo]);

  useEffect(() => {
    return () => {
      dispatch(removeApData());
    };
  });

  const handleMove = useCallback((newSize) => {
    setChartHeight(newSize);
  }, []);

  // const closedOrderLinesRef = useRef({});
  const newOrderLinesRef = useRef({});

  const useNewOrderLineUpdate = useCallback(
    (orderId, newValue) => {
      if (newOrderLinesRef.current[orderId]) {
        newOrderLinesRef.current[orderId].setPrice(newValue);
      }
    },
    [newOrderLinesRef],
  );

  // const useCloseOrderLineUpdate = useCallback(
  //   (orderId, newValue) => {
  //     closedOrderLinesRef.current[orderId].setPrice(newValue);
  //   },
  //   [closedOrderLinesRef],
  // );

  useEffect(() => {
    const body = bodyRef.current;
    const handleResize = () => {
      const rect = body?.getBoundingClientRect?.();
      if (rect != null) {
        setBodyHeight(rect.height);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener?.('resize', handleResize);
    };
  }, []);

  const { apList } = useSelector((state) => state.portfolio.selectedApGroupData);

  const initTableRowsRef = useMemo(() => {
    const obj = {};
    apList.forEach((ap) => {
      obj[ap.id] = {};
    });
    return obj;
  }, [apList]);

  const tableRowsRef = useRef(initTableRowsRef);

  const modalTitle = '自動売買一括変更';
  return (
    <Modal isOpen={isOpen} closeModal={handleClose} title={modalTitle} size={MODAL_SIZES.WIDTH83VW_1600}>
      <div ref={bodyRef} className={styles.container}>
        <div className={styles.wrapper}>
          <SplitPane
            split="horizontal"
            defaultSize="60%"
            minSize={0}
            maxSize={bodyHeight - SPACER}
            size={chartHeight}
            onMove={handleMove}
          >
            <div className={styles.chartArea}>
              <ChartArea
                propsNewOrderLinesRef={newOrderLinesRef}
                // propsClosedOrderLinesRef={closedOrderLinesRef}
              />
            </div>
            <MultiEditTable
              useNewOrderLineUpdate={useNewOrderLineUpdate}
              // useCloseOrderLineUpdate={useCloseOrderLineUpdate}
              bodyHeight={bodyHeight}
              chartHeight={chartHeight}
              setChartHeight={setChartHeight}
              propTableRowsRef={tableRowsRef}
            />
          </SplitPane>
        </div>
        <div className={styles.footerArea}>
          <MultiEditTableFooter propTableRowsRef={tableRowsRef} />
        </div>
      </div>
    </Modal>
  );
};

MultiEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(MultiEdit);
