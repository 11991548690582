import { get, patch, deleteMethod, invokeApi, createAdditionalParams } from './index';

export const getMargin = async (serviceId) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/margin`, get, additionalParams);
};

export const getApGroups = async ({ serviceId, groupId }) => {
  const additionalParams = await createAdditionalParams();
  const requestQuery = groupId ? `?apGroupId=${groupId}` : '';
  return invokeApi({}, `${serviceId}/ap_groups${requestQuery}`, get, additionalParams);
};

export const getApGroupsById = async ({ id, serviceId }) => {
  const additionalParams = await createAdditionalParams();
  return invokeApi({}, `${serviceId}/ap_groups/${id}`, get, additionalParams);
};

export const updateGroup = async ({ groupId, serviceId, requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/ap_groups/${groupId}`, patch, additionalParams, requestBody);
};

export const deleteApGroup = async ({ groupId, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/ap_groups/${groupId}?status=0`, deleteMethod, additionalParams);
};

export const changeGroupItem = async ({ groupId, serviceId, apId, requestBody }) => {
  const additionalParams = await createAdditionalParams();
  return invokeApi({}, `${serviceId}/ap_groups/${groupId}/aps/${apId}`, patch, additionalParams, requestBody);
};

export const deleteGroupItem = async ({ groupId, serviceId, apId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/ap_groups/${groupId}/aps/${apId}`, deleteMethod, additionalParams);
};

export const getDailyPls = async ({ serviceId, from, to }) => {
  const additionalParams = await createAdditionalParams();
  return invokeApi({}, `${serviceId}/pl_summaries?termFrom=${from}&termTo=${to}`, get, additionalParams);
};
