import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import { useTechRunNowResultsLogic } from 'shared-modules/services/hooks/builder';
import { handleRedirect } from '../../../../redux/actions';
import { Button, Modal } from '../../../../components';
import styles from './techAddToPortfolioFail.module.scss';

const TechAddToPortfolioFail = ({ isOpen, closeModal, orderName }) => {
  const dispatch = useDispatch();

  const openHomeScreen = useCallback(() => {
    closeModal();
    dispatch(handleRedirect({ url: '/home', isInner: true }));
  }, [closeModal, dispatch]);

  const runNowResultsLogic = useTechRunNowResultsLogic({ orderName, closeModal });

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title={runNowResultsLogic.fail.modalTitle}
      size={MODAL_SIZES.WIDTH_400}
    >
      <div className={styles.row}>{runNowResultsLogic.fail.title1}</div>

      <div className={classNames(styles.row, styles.orderInfo)}>
        <span>・</span>
        <span className={styles.failText}>{runNowResultsLogic.fail.failLabel}</span>
        <span className={styles.orderName}>{runNowResultsLogic.fail.orderName}</span>
        <br />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        &nbsp;&nbsp;&nbsp;{runNowResultsLogic.fail.message}
      </div>

      <div className={styles.buttonWrapper}>
        <Button onClick={closeModal} className={styles.closeButton} secondary>
          {runNowResultsLogic.buttons.cancel.label}
        </Button>
        <Button onClick={openHomeScreen}>{runNowResultsLogic.buttons.submit.label}</Button>
      </div>
    </Modal>
  );
};

TechAddToPortfolioFail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  orderName: PropTypes.string.isRequired,
};

export default memo(TechAddToPortfolioFail);
