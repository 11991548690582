import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MeterCircle } from './MeterCircle';

export const SecondaryCircle = memo(({ className, size, position1, position2, circleInfo }) => {
  const { circumference } = circleInfo;
  const [strokeDashoffset, strokeDasharray] = useMemo(() => {
    if (position1 <= position2) {
      return [-position1, `${position2 - position1} ${circumference}`];
    }
    return [0, `${position2} ${circumference}`];
  }, [position1, position2, circumference]);
  return (
    <MeterCircle
      className={className}
      size={size}
      strokeDasharray={strokeDasharray}
      strokeDashoffset={strokeDashoffset}
      circleInfo={circleInfo}
      // figmaのバランスメーターの各色と#FFFを50%ずつmixした色
      color1="#FFBEBE"
      color2="#FFF2B3"
      color3="#F4F7E3"
      color4="#B9F7D8"
    />
  );
});

SecondaryCircle.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  position1: PropTypes.number.isRequired,
  position2: PropTypes.number.isRequired,
  circleInfo: PropTypes.shape({
    r: PropTypes.number.isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    circumference: PropTypes.number.isRequired,
    strokeWidth: PropTypes.number.isRequired,
  }).isRequired,
};

SecondaryCircle.defaultProps = {
  className: undefined,
};
