/* eslint-disable */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { find } from 'lodash';
import { CFD, ETF, FX } from '../constants';
import { ALL_SERVICES } from '../constants/core';

export const getOrdersRequest = createAction('ORDERS/GET_ORDERS_REQUEST');
export const getOrdersSuccess = createAction('ORDERS/GET_ORDERS_SUCCESS');
export const removeAllOrders = createAction('ORDERS/REMOVE_ALL_ORDERS');
export const stopLoading = createAction('ORDERS/STOP_LOADING');
export const updateOrder = createAction('ORDERS/UPDATE_ORDER');

const STATUS_VALID = '1';

export default createReducer(
  {
    [FX]: [],
    [ETF]: [],
    [CFD]: [],
    lastFetch: null,
    lastQuery: null,
    filter: {},
    isLoading: false,
  },
  {
    [getOrdersRequest.type]: (orders) => {
      orders.isLoading = true;
    },
    [getOrdersSuccess.type]: (orders, action) => {
      const { serviceId, data, lastQuery, filter } = action.payload;
      orders[serviceId] = data;
      orders.lastFetch = Date.now();
      orders.lastQuery = lastQuery;
      orders.isLoading = false;

      if (filter) orders.filter = filter;
    },
    [removeAllOrders.type]: (orders, action) => {
      const serviceId = action.payload?.serviceId;

      if (serviceId) {
        orders[serviceId] = [];
      } else {
        ALL_SERVICES.forEach((service) => {
          orders[service] = [];
        });
      }

      orders.lastQuery = '';
    },
    [stopLoading.type]: (orders) => {
      orders.isLoading = false;
    },
    [updateOrder.type]: (orders, action) => {
      const { serviceId, orderData } = action.payload;
      const orderList = orders[serviceId];

      if (!orderData || orderData.length == 0) return;

      for (let i = 0; i < orderData.length; i += 1) {
        const order = orderData[i];
        const index = orderList.findIndex((o) => o.orderId === order.orderId);

        if (index > -1) {
          if (order.status !== STATUS_VALID) orderList.splice(index, 1);
          else orderList[index] = order;
        } else if (order.status === STATUS_VALID && find(orderData, orders.filter)) {
          orderList.push(order);
        }
      }
    },
  },
);
