import { createContext, createElement, memo, useState } from 'react';
import PropTypes from 'prop-types';

const initialValue = null;
const initialState = [initialValue, () => {}];

export const SelectedStrategyContext = createContext(initialState);

export const SelectedStrategyProvider = memo(({ children }) => {
  const [data, setData] = useState(initialValue);
  return createElement(SelectedStrategyContext.Provider, { children, value: [data, setData] });
});

SelectedStrategyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
