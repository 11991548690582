import Decimal from 'decimal.js';

const ge = (d1, d2) => d1.comparedTo(d2) >= 0;
const gt = (d1, d2) => d1.comparedTo(d2) > 0;
const le = (d1, d2) => d1.comparedTo(d2) <= 0;
const lt = (d1, d2) => d1.comparedTo(d2) < 0;

const round = (value, scale, roundingMode) => {
  const pow = new Decimal(10).pow(scale);
  const DecimalClone = Decimal.clone({ rounding: roundingMode });
  return new DecimalClone(value).mul(pow).round().div(pow);
};

const getEodMid = (eodRates, instrumentId, scale) => {
  const rate = eodRates[instrumentId];
  if (!rate) return null;
  return round(
    new Decimal(rate.askClose).add(new Decimal(rate.bidClose)).div(new Decimal(2)),
    scale,
    Decimal.ROUND_DOWN, // 切り捨て
  );
};

const isScore5PriceDiffAssessment = (entryPriceInfo, eodMid) => {
  // 5 : 中央値 - レンジ幅*0.15(≦)～(≦)中央値 + レンジ幅*0.15
  const { median, diff15 } = entryPriceInfo;
  const val1 = new Decimal(median).minus(diff15);
  const val2 = new Decimal(median).add(diff15);
  return le(val1, eodMid) && le(eodMid, val2);
};

const isBetweenUpSideOrLowSide = (eodMid, median, diffSmall, diffBig) => {
  const val1 = new Decimal(median).add(diffSmall);
  const val2 = new Decimal(median).add(diffBig);
  const val3 = new Decimal(median).minus(diffBig);
  const val4 = new Decimal(median).minus(diffSmall);
  return (lt(val1, eodMid) && le(eodMid, val2)) || (le(val3, eodMid) && lt(eodMid, val4));
};

const createEntryPriceInfo = (itemList, scale) => {
  if (!itemList?.length) {
    return null;
  }
  const toDecimal = (price) => {
    return price ? new Decimal(price) : null;
  };
  const entryPriceList = itemList
    .flatMap((item) => [toDecimal(item.entryPrice1), toDecimal(item.entryPrice2)])
    .filter((item) => item);
  const max = entryPriceList.reduce((pre, cur) => Decimal.max(pre, cur), new Decimal(0));
  const min = entryPriceList.reduce((pre, cur) => Decimal.min(pre, cur), max);
  const median = round(new Decimal(max).add(min).div(new Decimal(2)), scale, Decimal.ROUND_DOWN); // 切り捨て
  const range = new Decimal(max).minus(min);
  const diff15 = new Decimal(range).mul(new Decimal(0.15));
  const diff30 = new Decimal(range).mul(new Decimal(0.3));
  const diff40 = new Decimal(range).mul(new Decimal(0.4));
  const diff50 = new Decimal(range).mul(new Decimal(0.5));
  return {
    median,
    range,
    diff15,
    diff30,
    diff40,
    diff50,
  };
};

export const isReadySimulationResults = (simulationResults) => {
  return !!(
    simulationResults?.t1y?.simulationStats &&
    simulationResults?.t2y?.simulationStats &&
    simulationResults?.t3y?.simulationStats
  );
};

export const calcMaxDdAssessment = (simulationResults) => {
  if (isReadySimulationResults(simulationResults)) {
    const { maxDd: maxDd_, marginRecommended: marginRecommended_ } = simulationResults.t3y.simulationStats;
    const maxDd = new Decimal(maxDd_);
    const marginRecommended = new Decimal(marginRecommended_);
    const percent = new Decimal(maxDd).div(marginRecommended).mul(100);

    let score;
    if (ge(percent, new Decimal(0)) && le(percent, new Decimal(20))) {
      score = 5;
    } else if (le(percent, new Decimal(30))) {
      score = 4;
    } else if (le(percent, new Decimal(50))) {
      score = 3;
    } else if (le(percent, new Decimal(70))) {
      score = 2;
    } else {
      score = 1;
    }
    return score;
  }
  return 0;
};
export const calcRoiAssessment = (simulationResults) => {
  if (isReadySimulationResults(simulationResults)) {
    const roi1 = new Decimal(simulationResults.t1y.simulationStats.roi);
    const roi2 = new Decimal(simulationResults.t2y.simulationStats.roi);
    const roi3 = new Decimal(simulationResults.t3y.simulationStats.roi);

    let score;

    if (gt(roi3, roi2) && gt(roi2, roi1) && ge(roi1, new Decimal(20))) {
      score = 5;
    } else if (gt(roi3, roi2) && gt(roi2, roi1) && ge(roi1, new Decimal(10))) {
      score = 4;
    } else if (gt(roi3, roi2) && gt(roi2, roi1) && lt(roi1, new Decimal(10)) && ge(roi1, new Decimal(0))) {
      score = 3;
    } else if (gt(roi1, new Decimal(0))) {
      score = 2;
    } else {
      score = 1;
    }
    return score;
  }
  return 0;
};
export const calcPriceDiffAssessment = (strategy, eodRates, instrumentList) => {
  let { instrumentId } = strategy;
  let instrument = instrumentList[instrumentId];
  if (!instrument) {
    // TODO 確認
    instrumentId = strategy.itemList?.[0]?.realInstrumentId;
    if (!instrumentId) {
      return 0;
    }
    instrument = instrumentList[instrumentId];
  }
  const scale = new Decimal(instrument.pricePrecision).dp(); // 小数のサイズ
  const eodMid = getEodMid(eodRates, instrumentId, scale);
  const entryPriceInfo = createEntryPriceInfo(strategy.itemList, scale);
  const { median, diff15, diff30, diff40, diff50 } = entryPriceInfo;

  let score;
  if (isScore5PriceDiffAssessment(entryPriceInfo, eodMid)) {
    score = 5;
  } else if (isBetweenUpSideOrLowSide(eodMid, median, diff15, diff30)) {
    score = 4;
  } else if (isBetweenUpSideOrLowSide(eodMid, median, diff30, diff40)) {
    score = 3;
  } else if (isBetweenUpSideOrLowSide(eodMid, median, diff40, diff50)) {
    score = 2;
  } else {
    score = 1;
  }
  return score;
};
export const calcTotalPriceDiffAssessment = (strategyList, eodRates, instrumentList) => {
  if (!strategyList?.length) {
    return 0;
  }
  const scores = strategyList.map((strategy) => calcPriceDiffAssessment(strategy, eodRates, instrumentList));
  const totalScore = scores.reduce((sum, cur) => sum + cur, 0);
  const DecimalClone = Decimal.clone({ rounding: Decimal.ROUND_DOWN });
  return new DecimalClone(totalScore).divToInt(scores.length).toNumber(); // 切り捨て
};
export const calcCloseTradeCountAssessment = (closeTradeCount) => {
  if (closeTradeCount === null) {
    return 0;
  }
  if (closeTradeCount >= 480) {
    return 5;
  }
  if (closeTradeCount >= 240 && closeTradeCount < 480) {
    return 4;
  }
  if (closeTradeCount >= 120 && closeTradeCount < 240) {
    return 3;
  }
  if (closeTradeCount >= 60 && closeTradeCount < 120) {
    return 2;
  }
  return 1;
};
export const calcComprehensiveEvaluation = ({
  maxDdAssessment,
  roiAssessment,
  priceDiffAssessment,
  closeTradeCountAssessment,
}) => {
  if (maxDdAssessment && roiAssessment && priceDiffAssessment && closeTradeCountAssessment) {
    const totalScore = maxDdAssessment + roiAssessment + priceDiffAssessment + closeTradeCountAssessment;
    if (totalScore >= 17) {
      return 5;
    }
    if (totalScore >= 14 && totalScore < 17) {
      return 4;
    }
    if (totalScore >= 11 && totalScore < 14) {
      return 3;
    }
    if (totalScore >= 8 && totalScore < 11) {
      return 2;
    }
    return 1;
  }
  return 0;
};

export const calcComprehensiveEvaluationByAttibute = ({
  maxDdAssessment,
  roiAssessment,
  priceDiffAssessment,
  closeTradeCount,
}) => {
  const closeTradeCountAssessment = calcCloseTradeCountAssessment(closeTradeCount);
  return calcComprehensiveEvaluation({
    maxDdAssessment,
    roiAssessment,
    priceDiffAssessment,
    closeTradeCountAssessment,
  });
};

// attributeにcomprehensiveEvaluationを追加し、新しいオブジェクトを返す
export const includeComprehensiveEvaluation = (data) => {
  return data.attribute
    ? {
        ...data,
        attribute: {
          ...data.attribute,
          // 総合評価
          comprehensiveEvaluation: calcComprehensiveEvaluationByAttibute(data.attribute),
        },
      }
    : data;
};
