import { SETTINGS_SOCKET_STATUSES } from './apiConstant';

export const COLORS = {
  WHITE: 'WHITE',
  BLACK: 'BLACK',
  LIGHT_BLACK: 'LIGHT_BLACK',
  GREEN: 'GREEN',
  LIGHT_GREY: 'LIGHT_GREY',
  BLUE: 'BLUE',
  TRANSPARENT: 'TRANSPARENT',
  DARKGREY: 'DARKGREY',
  DARK_SKY_BLUE: 'DARK_SKY_BLUE',
  RED: 'RED',
  PINK: 'PINK',
};
export const ACTIVE_COLOR = {
  BLUE: 'blue',
  RED: 'red',
};
export const BUTTON_ROLES = {
  BUTTON: 'button',
  SUBMIT: 'submit',
};
export const WIDTH = {
  PERCENTAGE_100: '100%',
  PERCENTAGE_48: '48%',
};
export const MODAL_SIZES = {
  SMALL: 'sm',
  LARGE: 'lg',
  EXTRA_LARGE: 'xl',
  MAX_CONTENT: '',
  WIDTH_294: 'WIDTH_294',
  WIDTH_360: 'WIDTH_360',
  WIDTH_400: 'WIDTH_400',
  WIDTH_450: 'WIDTH_450',
  WIDTH_512: 'WIDTH_512',
  WIDTH_640: 'WIDTH_640',
  WIDTH_710: 'WIDTH_710',
  WIDTH_960: 'WIDTH_960',
  WIDTH_1024: 'WIDTH_1024',
  WIDTH_1127: 'WIDTH_1127',
  WIDTH83VW_1600: 'WIDTH83VW_1600',
  WIDTH_400_600: 'WIDTH_400_600',
};

export const BUTTON_COLORS_AVAILABLE_VALUES = [
  COLORS.LIGHT_GREY,
  COLORS.GREEN,
  COLORS.WHITE,
  COLORS.BLUE,
  COLORS.TRANSPARENT,
  COLORS.DARK_SKY_BLUE,
  COLORS.DARKGREY,
  COLORS.RED,
  COLORS.PINK,
  '',
];
export const MOBILE_BUTTON_COLORS_AVAILABLE_VALUES = [
  COLORS.BLACK,
  COLORS.LIGHT_GREY,
  COLORS.GREEN,
  COLORS.WHITE,
  COLORS.TRANSPARENT,
  COLORS.LIGHT_BLACK,
  COLORS.RED,
  COLORS.PINK,
  '',
];
export const BUTTON_ROLES_AVAILABLE_VALUES = [BUTTON_ROLES.BUTTON, BUTTON_ROLES.SUBMIT];
export const BUTTON_WIDTH_AVAILABLE_VALUES = [WIDTH.PERCENTAGE_100, WIDTH.PERCENTAGE_48, ''];
export const MODAL_SIZES_AVAILABLE_VALUES = [
  MODAL_SIZES.SMALL,
  MODAL_SIZES.LARGE,
  MODAL_SIZES.EXTRA_LARGE,
  MODAL_SIZES.MAX_CONTENT,
  MODAL_SIZES.WIDTH_294,
  MODAL_SIZES.WIDTH_360,
  MODAL_SIZES.WIDTH_400,
  MODAL_SIZES.WIDTH_450,
  MODAL_SIZES.WIDTH_400_600,
  MODAL_SIZES.WIDTH_512,
  MODAL_SIZES.WIDTH_640,
  MODAL_SIZES.WIDTH_710,
  MODAL_SIZES.WIDTH_960,
  MODAL_SIZES.WIDTH_1024,
  MODAL_SIZES.WIDTH_1127,
  MODAL_SIZES.WIDTH83VW_1600,
  MODAL_SIZES.WIDTH_400_600,
];

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};
export const NOTIFICATION_TYPE_AVAILABLE_VALUES = [
  NOTIFICATION_TYPE.SUCCESS,
  NOTIFICATION_TYPE.WARNING,
  NOTIFICATION_TYPE.ERROR,
];

export const TAG_VALUE = {
  0: 'Excellent',
  1: 'Very Good',
  2: 'Good',
  3: 'Bad',
};

export const FX = 'fx';
export const ETF = 'etf';
export const CFD = 'cfd';
export const MIXED = 'all';
export const SPECIAL = 'スペシャル';
export const FX24 = 'fx24';
export const STOCK365 = 'stock365';
export const rateSeparationedAssets = [FX, CFD];

export const NEWS_SOURCE_TO_SERVICES = Object.freeze({
  [FX24]: Object.freeze([FX]),
  [STOCK365]: Object.freeze([ETF, CFD]),
});

export const BUY_SELL_MAIN = {
  SELL: {
    ID: 1,
    CHART_ID: 0,
    LABEL: '売',
    KEY: 'bid',
    ACTIVE_COLOR: ACTIVE_COLOR.BLUE,
  },
  BUY: {
    ID: 3,
    CHART_ID: 1,
    LABEL: '買',
    KEY: 'ask',
    ACTIVE_COLOR: ACTIVE_COLOR.RED,
  },
};

export const BUY_SELL_COLOR = {
  [BUY_SELL_MAIN.SELL.ID]: '#3284FF',
  [BUY_SELL_MAIN.BUY.ID]: '#FB6739',
};

export const OPTIONS_BUY_SELL = [
  { id: BUY_SELL_MAIN.SELL.ID, label: BUY_SELL_MAIN.SELL.LABEL, activeColor: BUY_SELL_MAIN.SELL.ACTIVE_COLOR },
  { id: BUY_SELL_MAIN.BUY.ID, label: BUY_SELL_MAIN.BUY.LABEL, activeColor: BUY_SELL_MAIN.BUY.ACTIVE_COLOR },
];

export const OPTIONS_BUY_SELL_CHART = [
  { id: BUY_SELL_MAIN.SELL.CHART_ID, label: BUY_SELL_MAIN.SELL.KEY, activeColor: ACTIVE_COLOR.WHITE },
  { id: BUY_SELL_MAIN.BUY.CHART_ID, label: BUY_SELL_MAIN.BUY.KEY, activeColor: ACTIVE_COLOR.WHITE },
];
export const BUY_SELL_CHART_VALUE = {
  [BUY_SELL_MAIN.SELL.CHART_ID]: BUY_SELL_MAIN.SELL.KEY,
  [BUY_SELL_MAIN.BUY.CHART_ID]: BUY_SELL_MAIN.BUY.KEY,
};
export const BUY_SELL_VALUE = {
  [BUY_SELL_MAIN.SELL.ID]: BUY_SELL_MAIN.SELL.LABEL,
  [BUY_SELL_MAIN.BUY.ID]: BUY_SELL_MAIN.BUY.LABEL,
};
export const BUY_SELL_AVAILABLE_VALUES = [BUY_SELL_MAIN.SELL.ID, BUY_SELL_MAIN.BUY.ID];
export const BUY_SELL_CHART_ID_BY_ID = {
  [BUY_SELL_MAIN.SELL.ID]: BUY_SELL_MAIN.SELL.CHART_ID,
  [BUY_SELL_MAIN.BUY.ID]: BUY_SELL_MAIN.BUY.CHART_ID,
};
export const BUY_SELL_OPPOSITE_ID = {
  [BUY_SELL_MAIN.SELL.ID]: BUY_SELL_MAIN.BUY.ID,
  [BUY_SELL_MAIN.BUY.ID]: BUY_SELL_MAIN.SELL.ID,
};

export const ORDER_TYPES_MAIN = {
  MARKET_ORDER: {
    ID: 0,
    NAME: '成行',
  },
  STANDARD: {
    ID: 1,
    NAME: '指値',
  },
  OCO: {
    ID: 2,
    NAME: 'OCO',
  },
  IFD: {
    ID: 3,
    NAME: 'IFD',
  },
  IFO: {
    ID: 4,
    NAME: 'IFO',
  },
};

export const ORDER_TYPES_VALUES = {
  [ORDER_TYPES_MAIN.MARKET_ORDER.ID]: ORDER_TYPES_MAIN.MARKET_ORDER.NAME,
  [ORDER_TYPES_MAIN.STANDARD.ID]: ORDER_TYPES_MAIN.STANDARD.NAME,
  [ORDER_TYPES_MAIN.OCO.ID]: ORDER_TYPES_MAIN.OCO.NAME,
  [ORDER_TYPES_MAIN.IFD.ID]: ORDER_TYPES_MAIN.IFD.NAME,
  [ORDER_TYPES_MAIN.IFO.ID]: ORDER_TYPES_MAIN.IFO.NAME,
};

export const OPTIONS_ORDER_TYPES = [
  { value: ORDER_TYPES_MAIN.MARKET_ORDER.NAME, id: ORDER_TYPES_MAIN.MARKET_ORDER.ID },
  { value: ORDER_TYPES_MAIN.STANDARD.NAME, id: ORDER_TYPES_MAIN.STANDARD.ID },
  { value: ORDER_TYPES_MAIN.OCO.NAME, id: ORDER_TYPES_MAIN.OCO.ID },
  { value: ORDER_TYPES_MAIN.IFD.NAME, id: ORDER_TYPES_MAIN.IFD.ID },
  { value: ORDER_TYPES_MAIN.IFO.NAME, id: ORDER_TYPES_MAIN.IFO.ID },
];

export const OPTIONS_CLOSE_ORDER_TYPES = [
  { label: ORDER_TYPES_MAIN.MARKET_ORDER.NAME, id: ORDER_TYPES_MAIN.MARKET_ORDER.ID },
  { label: ORDER_TYPES_MAIN.STANDARD.NAME, id: ORDER_TYPES_MAIN.STANDARD.ID },
  { label: ORDER_TYPES_MAIN.OCO.NAME, id: ORDER_TYPES_MAIN.OCO.ID },
];

export const ORDER_METHOD_MAIN = {
  LIMIT: {
    ID: 0,
    NAME: '指値',
  },
  STOP_LIMIT: {
    ID: 1,
    NAME: '逆指値',
  },
  MARKET: {
    ID: 2,
    NAME: '成行',
  },
  LOSSCUT: {
    ID: 7,
    NAME: 'ロスカット',
  },
  BATCH_CLOSE: {
    ID: 10,
    NAME: '一括決済',
  },
};

// todo: when refactoring need clarify ORDER_METHOD_VALUE === NEW_ORDER_TYPES ? if yes merge this constants
export const ORDER_METHOD_VALUE = {
  [ORDER_METHOD_MAIN.LIMIT.ID]: ORDER_METHOD_MAIN.LIMIT.NAME,
  [ORDER_METHOD_MAIN.STOP_LIMIT.ID]: ORDER_METHOD_MAIN.STOP_LIMIT.NAME,
  [ORDER_METHOD_MAIN.MARKET.ID]: ORDER_METHOD_MAIN.MARKET.NAME,
  [ORDER_METHOD_MAIN.LOSSCUT.ID]: ORDER_METHOD_MAIN.LOSSCUT.NAME,
  [ORDER_METHOD_MAIN.BATCH_CLOSE.ID]: ORDER_METHOD_MAIN.BATCH_CLOSE.NAME,
};

export const OPTIONS_ORDER_METHOD = [
  { id: ORDER_METHOD_MAIN.LIMIT.ID, label: ORDER_METHOD_MAIN.LIMIT.NAME },
  { id: ORDER_METHOD_MAIN.STOP_LIMIT.ID, label: ORDER_METHOD_MAIN.STOP_LIMIT.NAME },
];

export const CHART_RESOLUTION_MAIN = {
  MINUTES_1: {
    ID: 1,
    TV_ID: '1',
    INCREASE_AMOUNT: 1,
    INCREASE_UNIT: 'm',
    LABEL: '1 分',
  },
  MINUTES_5: {
    ID: 2,
    TV_ID: '5',
    INCREASE_AMOUNT: 5,
    INCREASE_UNIT: 'm',
    LABEL: '5 分',
  },
  MINUTES_30: {
    ID: 3,
    TV_ID: '30',
    INCREASE_AMOUNT: 30,
    INCREASE_UNIT: 'm',
    LABEL: '30 分',
  },
  HOURS_1: {
    ID: 4,
    TV_ID: '60',
    INCREASE_AMOUNT: 1,
    INCREASE_UNIT: 'h',
    LABEL: '1 時間',
  },
  HOURS_4: {
    ID: 5,
    TV_ID: '240',
    INCREASE_AMOUNT: 4,
    INCREASE_UNIT: 'h',
    LABEL: '4 時間',
    CONFIGURATION_LABEL: '4時間足',
  },
  DAYS_1: {
    ID: 6,
    TV_ID: '1D',
    INCREASE_AMOUNT: 1,
    INCREASE_UNIT: 'd',
    LABEL: '1 日',
    CONFIGURATION_LABEL: '日足',
  },
  WEEKS_1: {
    ID: 7,
    TV_ID: '1W',
    INCREASE_AMOUNT: 1,
    INCREASE_UNIT: 'w',
    LABEL: '1 週',
  },
  MONTH_1: {
    ID: 8,
    TV_ID: '1M',
    INCREASE_AMOUNT: 1,
    INCREASE_UNIT: 'M',
    LABEL: '1 月',
  },
  HOURS_8: {
    ID: 9,
    TV_ID: '480',
    INCREASE_AMOUNT: 8,
    INCREASE_UNIT: 'h',
    LABEL: '8 時間',
    CONFIGURATION_LABEL: '8時間足',
  },
};
export const CHART_RESOLUTION_VALUES = {
  [CHART_RESOLUTION_MAIN.MINUTES_1.TV_ID]: {
    ID: CHART_RESOLUTION_MAIN.MINUTES_1.ID,
    INCREASE_AMOUNT: CHART_RESOLUTION_MAIN.MINUTES_1.INCREASE_AMOUNT,
    INCREASE_UNIT: CHART_RESOLUTION_MAIN.MINUTES_1.INCREASE_UNIT,
  },
  [CHART_RESOLUTION_MAIN.MINUTES_5.TV_ID]: {
    ID: CHART_RESOLUTION_MAIN.MINUTES_5.ID,
    INCREASE_AMOUNT: CHART_RESOLUTION_MAIN.MINUTES_5.INCREASE_AMOUNT,
    INCREASE_UNIT: CHART_RESOLUTION_MAIN.MINUTES_5.INCREASE_UNIT,
  },
  [CHART_RESOLUTION_MAIN.MINUTES_30.TV_ID]: {
    ID: CHART_RESOLUTION_MAIN.MINUTES_30.ID,
    INCREASE_AMOUNT: CHART_RESOLUTION_MAIN.MINUTES_30.INCREASE_AMOUNT,
    INCREASE_UNIT: CHART_RESOLUTION_MAIN.MINUTES_30.INCREASE_UNIT,
  },
  [CHART_RESOLUTION_MAIN.HOURS_1.TV_ID]: {
    ID: CHART_RESOLUTION_MAIN.HOURS_1.ID,
    INCREASE_AMOUNT: CHART_RESOLUTION_MAIN.HOURS_1.INCREASE_AMOUNT,
    INCREASE_UNIT: CHART_RESOLUTION_MAIN.HOURS_1.INCREASE_UNIT,
  },
  [CHART_RESOLUTION_MAIN.HOURS_4.TV_ID]: {
    ID: CHART_RESOLUTION_MAIN.HOURS_4.ID,
    INCREASE_AMOUNT: CHART_RESOLUTION_MAIN.HOURS_4.INCREASE_AMOUNT,
    INCREASE_UNIT: CHART_RESOLUTION_MAIN.HOURS_4.INCREASE_UNIT,
  },
  [CHART_RESOLUTION_MAIN.HOURS_8.TV_ID]: {
    ID: CHART_RESOLUTION_MAIN.HOURS_8.ID,
    INCREASE_AMOUNT: CHART_RESOLUTION_MAIN.HOURS_8.INCREASE_AMOUNT,
    INCREASE_UNIT: CHART_RESOLUTION_MAIN.HOURS_8.INCREASE_UNIT,
  },
  [CHART_RESOLUTION_MAIN.DAYS_1.TV_ID]: {
    ID: CHART_RESOLUTION_MAIN.DAYS_1.ID,
    INCREASE_AMOUNT: CHART_RESOLUTION_MAIN.DAYS_1.INCREASE_AMOUNT,
    INCREASE_UNIT: CHART_RESOLUTION_MAIN.DAYS_1.INCREASE_UNIT,
  },
  [CHART_RESOLUTION_MAIN.WEEKS_1.TV_ID]: {
    ID: CHART_RESOLUTION_MAIN.WEEKS_1.ID,
    INCREASE_AMOUNT: CHART_RESOLUTION_MAIN.WEEKS_1.INCREASE_AMOUNT,
    INCREASE_UNIT: CHART_RESOLUTION_MAIN.WEEKS_1.INCREASE_UNIT,
  },
  [CHART_RESOLUTION_MAIN.MONTH_1.TV_ID]: {
    ID: CHART_RESOLUTION_MAIN.MONTH_1.ID,
    INCREASE_AMOUNT: CHART_RESOLUTION_MAIN.MONTH_1.INCREASE_AMOUNT,
    INCREASE_UNIT: CHART_RESOLUTION_MAIN.MONTH_1.INCREASE_UNIT,
  },
};

export const CHART_RESOLUTION_LABEL = {
  [CHART_RESOLUTION_MAIN.HOURS_4.ID]: CHART_RESOLUTION_MAIN.HOURS_4.CONFIGURATION_LABEL,
  [CHART_RESOLUTION_MAIN.HOURS_8.ID]: CHART_RESOLUTION_MAIN.HOURS_8.CONFIGURATION_LABEL,
  [CHART_RESOLUTION_MAIN.DAYS_1.ID]: CHART_RESOLUTION_MAIN.DAYS_1.CONFIGURATION_LABEL,
};

export const CHART_INDICATOR_MAIN = {
  1: '単純移動平均線',
  2: 'ボリンジャーバンド',
  3: '一目均衡表',
  4: 'DMI',
  5: 'MACD',
  6: 'RSI',
  7: 'ストキャスティクス',
  8: 'モメンタム',
};

export const ALLOWED_TRADE_TYPES = {
  DO_NOT_ALLOWED: {
    ID: 0,
    NAME: '不可',
  },
  ALLOWED: {
    ID: 1,
    NAME: '可能',
  },
  NEW_ORDERS_NOT_ALLOWED: {
    ID: 2,
    NAME: '新規取引不可',
  },
};

export const MARKET_STATUSES = {
  CLOSED: {
    ID: 0,
    NAME: 'closed',
  },
  ACTIVE: {
    ID: 1,
    NAME: 'active',
  },
};

export const MARKET_STATUSES_VALUES = {
  [SETTINGS_SOCKET_STATUSES.OPEN]: MARKET_STATUSES.ACTIVE.ID,
  [SETTINGS_SOCKET_STATUSES.CLOSE]: MARKET_STATUSES.CLOSED.ID,
};

export const MARKET_STATUSES_LABELS = {
  [MARKET_STATUSES.CLOSED.ID]: '稼働停止中',
  [MARKET_STATUSES.ACTIVE.ID]: '稼働中',
};

export const CHART_TYPES_MAIN = {
  CANDLESTICK: {
    ID: 1,
    LABEL: 'ローソク足',
  },
  HEIKIN_ASHI: {
    ID: 8,
    LABEL: '平均足',
  },
  LINE: {
    ID: 2,
    LABEL: '平均足',
  },
};

export const NEW_SETTLEMENT_VALUE = {
  0: '新規',
  1: '決済',
};

export const M_SUFFIX = '_M';
export const AP_SUFFIX = '_AP';

export const COUNTRY_TYPE = {
  AUD: 'AUD',
  CAD: 'CAD',
  CHF: 'CHF',
  EUR: 'EUR',
  GBP: 'GBP',
  JPY: 'JPY',
  MXN: 'MXN',
  NZD: 'NZD',
  PLN: 'PLN',
  TRY: 'TRY',
  USD: 'USD',
  ZAR: 'ZAR',
};

export const COUNTRIES_AVAILABLE_VALUES = [
  COUNTRY_TYPE.AUD,
  COUNTRY_TYPE.CAD,
  COUNTRY_TYPE.CHF,
  COUNTRY_TYPE.EUR,
  COUNTRY_TYPE.GBP,
  COUNTRY_TYPE.JPY,
  COUNTRY_TYPE.MXN,
  COUNTRY_TYPE.NZD,
  COUNTRY_TYPE.PLN,
  COUNTRY_TYPE.TRY,
  COUNTRY_TYPE.USD,
  COUNTRY_TYPE.ZAR,
  `${COUNTRY_TYPE.CAD}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.CHF}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.EUR}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.GBP}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.JPY}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.MXN}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.NZD}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.PLN}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.TRY}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.USD}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.ZAR}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.AUD}${AP_SUFFIX}`,
  `${COUNTRY_TYPE.CAD}${M_SUFFIX}`,
  `${COUNTRY_TYPE.CHF}${M_SUFFIX}`,
  `${COUNTRY_TYPE.EUR}${M_SUFFIX}`,
  `${COUNTRY_TYPE.GBP}${M_SUFFIX}`,
  `${COUNTRY_TYPE.JPY}${M_SUFFIX}`,
  `${COUNTRY_TYPE.MXN}${M_SUFFIX}`,
  `${COUNTRY_TYPE.NZD}${M_SUFFIX}`,
  `${COUNTRY_TYPE.PLN}${M_SUFFIX}`,
  `${COUNTRY_TYPE.TRY}${M_SUFFIX}`,
  `${COUNTRY_TYPE.USD}${M_SUFFIX}`,
  `${COUNTRY_TYPE.ZAR}${M_SUFFIX}`,
  `${COUNTRY_TYPE.AUD}${M_SUFFIX}`,
  '',
];

export const EXPIRATION_TYPE_MAIN = {
  DAY: {
    ID: 0,
    NAME: '今日中',
  },
  WEEK: {
    ID: 1,
    NAME: '今週中',
  },
  INFINITY: {
    ID: 2,
    NAME: '無期限',
  },
  CUSTOM: {
    ID: 3,
    NAME: '指定日時',
  },
};

export const SATURDAY_ID = 6;

export const EXPIRATION_TYPE_VALUES = {
  [EXPIRATION_TYPE_MAIN.DAY.ID]: EXPIRATION_TYPE_MAIN.DAY.NAME,
  [EXPIRATION_TYPE_MAIN.WEEK.ID]: EXPIRATION_TYPE_MAIN.WEEK.NAME,
  [EXPIRATION_TYPE_MAIN.INFINITY.ID]: EXPIRATION_TYPE_MAIN.INFINITY.NAME,
  [EXPIRATION_TYPE_MAIN.CUSTOM.ID]: EXPIRATION_TYPE_MAIN.CUSTOM.NAME,
};

// todo: change structure options for dropdown, should be like UnderlineTabs
export const COMMON_OPTIONS_EXPIRATION_TYPE = [
  { value: EXPIRATION_TYPE_MAIN.DAY.ID, label: EXPIRATION_TYPE_MAIN.DAY.NAME },
  { value: EXPIRATION_TYPE_MAIN.WEEK.ID, label: EXPIRATION_TYPE_MAIN.WEEK.NAME },
  { value: EXPIRATION_TYPE_MAIN.INFINITY.ID, label: EXPIRATION_TYPE_MAIN.INFINITY.NAME },
];

export const OPTIONS_NO_EXPIRATION_ONLY = [
  { value: EXPIRATION_TYPE_MAIN.INFINITY.ID, label: EXPIRATION_TYPE_MAIN.INFINITY.NAME },
];

export const OPTIONS_EXPIRATION_TYPE = [
  ...COMMON_OPTIONS_EXPIRATION_TYPE,
  { value: EXPIRATION_TYPE_MAIN.CUSTOM.ID, label: EXPIRATION_TYPE_MAIN.CUSTOM.NAME },
];

export const EMAIL_LIST_TEXTS = { part1: '第', part2: 'メールアドレス(', part3: ')に送信する' };

export const MAIL_ACTIONS = [
  { actionId: 'FXC0001', id: 'FXC0001', label: '出金通知', disabled: false, hidden: false },
  {
    actionId: 'FXC0002',
    id: 'FXC0002',
    additionalActionId: 'FXC0038',
    label: '入金通知',
    disabled: false,
    hidden: false,
  },
  { actionId: 'FXC0003', id: 'FXC0003', label: '新規/決済約定通知', disabled: false, hidden: false },
  { actionId: 'FXC0040', id: 'FXC0040', label: '自動売買注文 稼働停止通知', disabled: false, hidden: false },
  { actionId: 'FXC0026', id: 'FXC0026', label: 'トレード注文 失効通知', disabled: false, hidden: false },
  {
    actionId: 'FXC0050',
    id: 'FXC0050',
    label: 'テクニカルビルダー 自動売買グループ稼働通知',
    disabled: false,
    hidden: false,
  },
  { actionId: 'FXC0051', id: 'FXC0051', label: 'テクニカルビルダー 稼働停止通知', disabled: false, hidden: false },
  {
    actionId: 'FXC0052',
    id: 'FXC0052',
    label: 'テクニカルビルダー 反対シグナル発生通知',
    disabled: false,
    hidden: false,
  },
  { actionId: 'FXC0004', id: 'FXC0004', label: 'ロスカット通知', disabled: true, hidden: false },
  { actionId: 'FXC0005', id: 'FXC0005', label: 'アラート通知', disabled: true, hidden: false },
  { actionId: 'FXC0006', id: 'FXC0006', label: 'アラート通知', disabled: true, hidden: true },
];

export const NEW_ORDER_TYPES = {
  [ORDER_METHOD_MAIN.LIMIT.ID]: ORDER_METHOD_MAIN.LIMIT.NAME,
  [ORDER_METHOD_MAIN.STOP_LIMIT.ID]: ORDER_METHOD_MAIN.STOP_LIMIT.NAME,
  [ORDER_METHOD_MAIN.MARKET.ID]: ORDER_METHOD_MAIN.MARKET.NAME,
  [ORDER_METHOD_MAIN.LOSSCUT.ID]: ORDER_METHOD_MAIN.LOSSCUT.NAME,
  [ORDER_METHOD_MAIN.BATCH_CLOSE.ID]: ORDER_METHOD_MAIN.BATCH_CLOSE.NAME,
};

export const ORDERS_COMPOSITE_TYPES_MAIN = {
  SINGLE: {
    ID: 0,
    NAME: 'SINGLE',
    MAIN_NAME: 'SINGLE',
  },
  IFD: {
    ID: 1,
    NAME: 'IFD',
    MAIN_NAME: 'IFD',
  },
  THEN: {
    ID: 2,
    NAME: 'THEN',
    MAIN_NAME: 'IFD',
  },
  OCO1: {
    ID: 3,
    NAME: 'OCO1',
    MAIN_NAME: 'OCO',
  },
  OCO2: {
    ID: 4,
    NAME: 'OCO2',
    MAIN_NAME: 'OCO',
  },
  IFO: {
    ID: 5,
    NAME: 'IFO',
    MAIN_NAME: 'IFO',
  },
  THEN_OCO: {
    ID: 6,
    NAME: 'THEN_OCO',
    MAIN_NAME: 'IFO',
  },
  THEN_OCO2: {
    ID: 7,
    NAME: 'THEN_OCO2',
    MAIN_NAME: 'IFO',
  },
  IFC: {
    ID: 8,
    NAME: 'IFC',
    MAIN_NAME: 'IFC',
  },
  CANCEL_THEN: {
    ID: 9,
    NAME: 'CANCEL_THEN',
    MAIN_NAME: 'CANCEL_THEN',
  },
  IFCO: {
    ID: 10,
    NAME: 'IFCO',
    MAIN_NAME: 'IFCO',
  },
  CANCEL_THEN_OCO1: {
    ID: 11,
    NAME: 'IFC',
    MAIN_NAME: 'IFC',
  },
  CANCEL_THEN_OCO2: {
    ID: 12,
    NAME: 'CANCEL_THEN_OCO2',
    MAIN_NAME: 'CANCEL_THEN_OCO2',
  },
};

export const ORDERS_COMPOSITE_TYPE_NAMES = {
  [ORDERS_COMPOSITE_TYPES_MAIN.SINGLE.ID]: ORDERS_COMPOSITE_TYPES_MAIN.SINGLE.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.IFD.ID]: ORDERS_COMPOSITE_TYPES_MAIN.IFD.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.THEN.ID]: ORDERS_COMPOSITE_TYPES_MAIN.THEN.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.OCO1.ID]: ORDERS_COMPOSITE_TYPES_MAIN.OCO1.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.OCO2.ID]: ORDERS_COMPOSITE_TYPES_MAIN.OCO2.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.IFO.ID]: ORDERS_COMPOSITE_TYPES_MAIN.IFO.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.THEN_OCO.ID]: ORDERS_COMPOSITE_TYPES_MAIN.THEN_OCO.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.THEN_OCO2.ID]: ORDERS_COMPOSITE_TYPES_MAIN.THEN_OCO2.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.IFC.ID]: ORDERS_COMPOSITE_TYPES_MAIN.IFC.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.CANCEL_THEN.ID]: ORDERS_COMPOSITE_TYPES_MAIN.CANCEL_THEN.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.IFCO.ID]: ORDERS_COMPOSITE_TYPES_MAIN.IFCO.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.CANCEL_THEN_OCO1.ID]: ORDERS_COMPOSITE_TYPES_MAIN.CANCEL_THEN_OCO1.NAME,
  [ORDERS_COMPOSITE_TYPES_MAIN.CANCEL_THEN_OCO2.ID]: ORDERS_COMPOSITE_TYPES_MAIN.CANCEL_THEN_OCO2.NAME,
};

export const ORDER_SETTLEMENT_TYPE_NAMES = {
  [ORDERS_COMPOSITE_TYPES_MAIN.IFD.ID]: 'IFD2',
  [ORDERS_COMPOSITE_TYPES_MAIN.IFO.ID]: 'IFO2',
};

export const ORDER_STATUSES_MAIN = {
  WAITING: {
    ID: 0,
    NAME: '待機中',
  },
  ACTIVE: {
    ID: 1,
    NAME: '有効',
  },
  CANCELED: {
    ID: 2,
    NAME: '取消済',
  },
  CONTRACTED: {
    ID: 3,
    NAME: '約定済',
  },
  CANCEL_PROCESSING: {
    ID: 4,
    NAME: '取消処理中',
  },
  CHANGE_PROCESSING: {
    ID: 5,
    NAME: '変更処理中',
  },
  EXECUTION: {
    ID: 7,
    NAME: '執行中',
  },
};

export const ORDER_STATUSES = {
  [ORDER_STATUSES_MAIN.WAITING.ID]: ORDER_STATUSES_MAIN.WAITING.NAME,
  [ORDER_STATUSES_MAIN.ACTIVE.ID]: ORDER_STATUSES_MAIN.ACTIVE.NAME,
  [ORDER_STATUSES_MAIN.CANCELED.ID]: ORDER_STATUSES_MAIN.CANCELED.NAME,
  [ORDER_STATUSES_MAIN.CONTRACTED.ID]: ORDER_STATUSES_MAIN.CONTRACTED.NAME,
  [ORDER_STATUSES_MAIN.CANCEL_PROCESSING.ID]: ORDER_STATUSES_MAIN.CANCEL_PROCESSING.NAME,
  [ORDER_STATUSES_MAIN.CHANGE_PROCESSING.ID]: ORDER_STATUSES_MAIN.CHANGE_PROCESSING.NAME,
  [ORDER_STATUSES_MAIN.EXECUTION.ID]: ORDER_STATUSES_MAIN.EXECUTION.NAME,
};

export const ALLOWED_STATUS_FOR_CHANGES = [ORDER_STATUSES_MAIN.WAITING.ID, ORDER_STATUSES_MAIN.ACTIVE.ID];

export const TRADE_METHODS = {
  AP: { ID: 0, NAME: 'AP', LABEL: '自動売買' },
  MANUAL: { ID: 1, NAME: 'Manual', LABEL: 'マニュアル' },
  MH_AP: { ID: 2, NAME: 'マネーハッチ', LABEL: 'マネーハッチ' },
  MH_MANUAL: { ID: 3, NAME: 'マネーハッチ', LABEL: 'マネーハッチ' },
};

export const OPTIONS_TRADE_METHOD_TYPES = [
  { id: TRADE_METHODS.MANUAL.ID, label: TRADE_METHODS.MANUAL.LABEL, name: TRADE_METHODS.MANUAL.NAME },
  { id: TRADE_METHODS.AP.ID, label: TRADE_METHODS.AP.LABEL, name: TRADE_METHODS.AP.NAME },
];

export const TRADE_METHOD_VALUE = {
  [TRADE_METHODS.AP.ID]: '自動売買',
  [TRADE_METHODS.MANUAL.ID]: 'トレード',
  [TRADE_METHODS.MH_AP.ID]: TRADE_METHODS.MH_AP.NAME,
  [TRADE_METHODS.MH_MANUAL.ID]: TRADE_METHODS.MH_MANUAL.NAME,
};

export const AP_GROUP_SOURCES = {
  SELECT: { KEY: 'select', NAME: '自動売買' },
  MONEY_HATCH: { KEY: 'mh', NAME: 'マネーハッチ' },
  BUILDER: { KEY: 'builder', NAME: 'ビルダー' },
  TECH: { KEY: 'tech', NAME: 'テクニカルビルダー' },
  CHART_MAKE: { KEY: 'chartMake', NAME: 'チャートメイク' },
};

export const INDICATORS_TYPES = {
  MOVING_AVERAGE: { LABEL: '単純移動平均線', VALUE: 'Moving Average' },
  BOLLINGER_BANDS: { LABEL: 'ボリンジャーバンド', VALUE: 'Bollinger Bands' },
  ICHIMOKU_CLOUD: { LABEL: '一目均衡表', VALUE: 'Ichimoku Cloud' },
  MACD: { LABEL: 'MACD', VALUE: 'MACD' },
  MOMENTUM: { LABEL: 'モメンタム', VALUE: 'Momentum' },
  RELATIVE_STRENGTH_INDEX: { LABEL: 'RSI', VALUE: 'Relative Strength Index' },
  DMI: { LABEL: 'DMI', VALUE: 'Directional Movement' },
  STOCHASTICS: { LABEL: 'ストキャスティクス', VALUE: 'Stochastic' },
};

export const INDICATORS = [
  { label: INDICATORS_TYPES.MOVING_AVERAGE.LABEL, value: INDICATORS_TYPES.MOVING_AVERAGE.VALUE },
  { label: INDICATORS_TYPES.BOLLINGER_BANDS.LABEL, value: INDICATORS_TYPES.BOLLINGER_BANDS.VALUE },
  { label: INDICATORS_TYPES.ICHIMOKU_CLOUD.LABEL, value: INDICATORS_TYPES.ICHIMOKU_CLOUD.VALUE },
  { label: INDICATORS_TYPES.MACD.LABEL, value: INDICATORS_TYPES.MACD.VALUE },
  { label: INDICATORS_TYPES.MOMENTUM.LABEL, value: INDICATORS_TYPES.MOMENTUM.VALUE },
  { label: INDICATORS_TYPES.RELATIVE_STRENGTH_INDEX.LABEL, value: INDICATORS_TYPES.RELATIVE_STRENGTH_INDEX.VALUE },
  { label: INDICATORS_TYPES.DMI.LABEL, value: INDICATORS_TYPES.DMI.VALUE },
  { label: INDICATORS_TYPES.STOCHASTICS.LABEL, value: INDICATORS_TYPES.STOCHASTICS.VALUE },
];

export const INDICATOR_IDS = {
  [INDICATORS_TYPES.MOVING_AVERAGE.VALUE]: '1',
  [INDICATORS_TYPES.BOLLINGER_BANDS.VALUE]: '2',
  [INDICATORS_TYPES.ICHIMOKU_CLOUD.VALUE]: '3',
  [INDICATORS_TYPES.DMI.VALUE]: '4',
  [INDICATORS_TYPES.MACD.VALUE]: '5',
  [INDICATORS_TYPES.RELATIVE_STRENGTH_INDEX.VALUE]: '6',
  [INDICATORS_TYPES.STOCHASTICS.VALUE]: '7',
  [INDICATORS_TYPES.MOMENTUM.VALUE]: '8',
};

export const BAR_TYPES = [
  { value: CHART_RESOLUTION_MAIN.HOURS_4.TV_ID, label: CHART_RESOLUTION_MAIN.HOURS_4.CONFIGURATION_LABEL },
  { value: CHART_RESOLUTION_MAIN.HOURS_8.TV_ID, label: CHART_RESOLUTION_MAIN.HOURS_8.CONFIGURATION_LABEL },
  { value: CHART_RESOLUTION_MAIN.DAYS_1.TV_ID, label: CHART_RESOLUTION_MAIN.DAYS_1.CONFIGURATION_LABEL },
];

export const AP_GROUP_ORDER = {
  ACTIVITY: {
    ACTIVE: {
      ID: '1',
      NAME: '稼働中',
    },
    INACTIVE: {
      ID: '0',
      NAME: '非稼働',
    },
  },
  STATUS: {
    NO_ORDER: {
      ID: '0',
      NAME: '未発注',
    },
    FIRST_ORDER: {
      ID: '1',
      NAME: '初回新規発注中',
    },
    CLOSE_ORDER: {
      ID: '2',
      NAME: '決済発注中',
    },
    OPEN_ORDER: {
      ID: '3',
      NAME: '新規発注中',
    },
  },
};

export const OK_BUTTON_TEXT = '閉じる';

export const MANUAL_POSITION_GROUP_TYPE = 'トレード';

export const TRADE_TYPES = {
  ALL_TYPES: { KEY: 'allTypes', NAME: '指定なし' },
  AUTO: { KEY: 'auto', NAME: '自動売買' },
  TRADE: { KEY: 'trade', NAME: 'トレード' },
  TRADE_SELL: { KEY: 'tradeSell', NAME: 'トレード(売)' },
  TRADE_BUY: { KEY: 'tradeBuy', NAME: 'トレード(買)' },
  MONEY_HATCH: { KEY: 'moneyHatch', NAME: 'マネーハッチ' },
};

export const CARD_TYPE_OPTIONS = [
  { label: TRADE_TYPES.ALL_TYPES.NAME, value: TRADE_TYPES.ALL_TYPES.KEY },
  { label: TRADE_TYPES.AUTO.NAME, value: TRADE_TYPES.AUTO.KEY },
  { label: TRADE_TYPES.TRADE.NAME, value: TRADE_TYPES.TRADE.KEY },
  { label: TRADE_TYPES.TRADE_SELL.NAME, value: TRADE_TYPES.TRADE_SELL.KEY },
  { label: TRADE_TYPES.TRADE_BUY.NAME, value: TRADE_TYPES.TRADE_BUY.KEY },
];

export const ALL_BRAND_OPTION = { label: '指定なし', value: 'All brands' };
export const SELECTED_INSTRUMENT_OPTION = '指定あり';

export const EXEC_TIME_SORTING = 'byExecTime';
export const PROFITLOSS_SORTING = 'byPL';
export const QUANTITY_SORTING = 'byQuantity';
export const SORT_TYPE_OPTIONS = [
  { label: '日付順', value: EXEC_TIME_SORTING },
  { label: '評価損益順', value: PROFITLOSS_SORTING },
  // { label: '数量順', value: QUANTITY_SORTING },
];

export const AP_GROUP_STATUSES = {
  ALL_STATUSES: 'All',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const CARD_STATUS_OPTIONS = [
  { label: '指定なし', value: AP_GROUP_STATUSES.ALL_STATUSES },
  { label: '稼働 / 建玉', value: AP_GROUP_STATUSES.ACTIVE },
  { label: '稼働停止', value: AP_GROUP_STATUSES.INACTIVE },
];

export const CARD_STATUS_IDS = {
  [AP_GROUP_STATUSES.ALL_STATUSES]: 'all',
  [AP_GROUP_STATUSES.INACTIVE]: 0,
  [AP_GROUP_STATUSES.ACTIVE]: 1,
};

export const TECH_STATUS = {
  INACTIVE: '0',
  ACTIVE: '1',
};

export const UNREALIZED_PL_ETF_AMOUNT_MULTIPLIER = 1;
// TODO CFD 暫定で ETF のコピー
export const UNREALIZED_PL_CFD_AMOUNT_MULTIPLIER = 1;
export const SPREAD_YEN_PAIR_MULTIPLIER = 100;
export const SPREAD_NOT_YEN_PAIR_MULTIPLIER = 10000;
export const SPREAD_MAX_VALUE = 9999.9;
export const SPREAD_MORE_THEN_MAX_VALUE = '9999+';

export const AP_GROUP_RULE_67_YEN_PAIR_MULTIPLIER = 100;
export const AP_GROUP_RULE_67_NOT_YEN_PAIR_MULTIPLIER = 10000;

export const CHART_MAKE_ETF_NOT_YEN_PAIR_MULTIPLIER = 100;
// TODO CFD 暫定で ETF のコピー
export const CHART_MAKE_CFD_NOT_YEN_PAIR_MULTIPLIER = 100;

export const QUANTITY_STEP = {
  ONE: 1,
  ZERO_POINT_ONE: 0.1,
  TEN: 10,
};

export const QUANTITY_MINS = Object.freeze({
  fx: 0.1,
  etf: 10,
  cfd: 0.1,
});

export const QUANTITY_MAXES = Object.freeze({
  fx: 1000,
  etf: 20000,
  cfd: 100,
});

export const QUANTITY_PRECISION = 0.1;
export const QUANTITY_COUNTER_MIN_INT = 1;
export const MIN_REPORTS_YEAR_FX = 2014;
export const MIN_REPORTS_YEAR_ETF = 2017;
export const MIN_REPORTS_YEAR_CFD = 2024;

export const INSTRUMENT_PRICE_STEP_WTH_JPY = 1 / SPREAD_YEN_PAIR_MULTIPLIER;
export const INSTRUMENT_PRICE_STEP_WITHOUT_JPY = 1 / SPREAD_NOT_YEN_PAIR_MULTIPLIER;

export const VALIDATION_TIME_ERROR_MESSAGE = '有効期限は未来の日時でご設定ください。';
export const VALIDATION_SETTLEMENT_TIME_ERROR_MESSAGE =
  'Done注文の有効期限はIF注文の有効期限と同じか、それより未来を設定してください。';
export const VALIDATION_ERROR_EMPTY_FIELD = '*必須項目です';
export const VALIDATION_ERROR_INVALID_VALUE = '有効な値を入力してください';
export const VALIDATION_ERROR_AP_ORDER_QUANTITY_ONE = '1万以上200万以下、1万通貨単位でご設定ください。';
export const VALIDATION_ERROR_AP_ORDER_QUANTITY_ZERO_POINT_ONE = '0.1万以上200万以下、0.1万通貨単位でご設定ください。';
export const VALIDATION_ERROR_AP_ORDER_PROFIT_MARGIN_PIPS = '1pips以上、99999.9pips以下でご設定ください。';
export const VALIDATION_ERROR_AP_ORDER_LOST_CUT_PIPS = '-99999.9pips以上、-20pips以下でご設定ください。';
export const VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_BUY = '5pips以上、99999.9pips以下でご設定ください。';
export const VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_SELL = '-99999.9pips以上、-5pips以下でご設定ください。';
export const VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_BUY = '-99999.9pips以上、-5pips以下でご設定ください。';
export const VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_SELL = '5pips以上、99999.9pips以下でご設定ください。';
export const VALIDATION_ERROR_AP_ORDER_OCO2_LESS_THAN_OCO1_BUY =
  'エントリー価格2はエントリー価格1より高い価格でご設定ください。';
export const VALIDATION_ERROR_AP_ORDER_OCO2_MORE_THAN_OCO1_SELL =
  'エントリー価格2はエントリー価格1より低い価格でご設定ください。';
export const VALIDATION_ERROR_AP_RULE_66 = 'カウンタ―値かフォロー値のいずれかをご設定ください。';
export const COUNT_INPUT_NAME = 'quantity';
export const MAX_QUANTITY_AP_ORDER = 200;
export const INSTRUMENT_ZAR_AP_JPY = 'ZAR_AP/JPY';
export const PROFIT_MARGIN_NAME = 'tp';
export const PROFIT_MARGIN_MAX = 99999.9;
export const PROFIT_MARGIN_MIN = 1;
export const PROFIT_MARGIN_PRECISION = 0.1;
export const PROFIT_MARGIN_STEP = 0.1;
export const LOSS_CUT_WIDTH_NAME = 'sl';
export const LOSS_CUT_WIDTH_MIN = -99999.9;
export const LOSS_CUT_WIDTH_MAX = -20;
export const LOSS_CUT_WIDTH_PRECISION = 0.1;
export const LOSS_CUT_WIDTH_STEP = 0.1;
export const FOLLOW_NAME = 'follow';
export const FOLLOW_BUY_MIN = 5;
export const FOLLOW_BUY_MAX = 99999.9;
export const FOLLOW_SELL_MIN = -99999.9;
export const FOLLOW_SELL_MAX = -5;
export const FOLLOW_PRECISION = 0.1;
export const FOLLOW_STEP = 0.1;
export const COUNTER_FIXED = 'counterFixed';
export const COUNTER_NAME = 'counter';
export const COUNTER_PRICE_NAME = 'counterPrice';
export const COUNTER_TYPE = 'counterType';
export const COUNTER_SELL_MIN = 5;
export const COUNTER_BUY_MIN = -99999.9;
export const COUNTER_SELL_MAX = 99999.9;
export const COUNTER_BUY_MAX = -5;
export const COUNTER_STEP = 0.1;
export const COUNTER_PRECISION = 0.1;
export const COUNTER_PRICE_PRECISION = 0.001;
export const PRICE_1_NAME = 'entryPrice1';
export const PRICE_2_NAME = 'entryPrice2';
export const PRICE_MAX_PAIRED_YEN = 9999.999;
export const PRICE_MAX_NOT_PAIRED_YEN = 9999.99999;
export const PRICE_MIN_MULTIPLIER = 0.01;
export const PRICE_MAX_MULTIPLIER = 1.99;
export const PAIRED_YEN_PIP_MULTIPLIER = 0.01;
export const NOT_PAIRED_YEN_PIP_MULTIPLIER = 0.0001;
export const DEFAULT_QUANTITY_UNIT_CONV_FACTOR = 1;
export const FX_AP_GROUP_CHANGE_LOGIC_STEP_CROSS_YEN = 0.1;
export const FX_AP_GROUP_CHANGE_LOGIC_STEP_CROSS_CURRENCY = 0.001;
export const FX_CFD_AP_GROUP_CHANGE_LOGIC_STEP = 10;

export const BULK_CHANGE_TITLE = `一括変更処理中`;
export const BULK_CHANGE_MESSAGE = `一括変更処理中です。しばらくそのままお待ちください`;

export const LOGIN_CHECKBOX = 'loginCheckbox';
export const PASSWORD_CHECKBOX = 'passwordCheckbox';
export const LOGIN = 'login';
export const PASSWORD = 'password';
export const ETF_ACCOUNT = 'etfAccount';
export const CFD_ACCOUNT = 'cfdAccount';
export const KEY_FOR_JWT_ETF = 'custom:account_etf';
export const KEY_FOR_JWT_CFD = 'custom:account_cfd';
export const KEY_FOR_JWT_PORTAL_ID = 'custom:id';
export const KEY_FOR_PUSH_TOKEN = 'pushToken';

export const OPTIONS_COUNTER_TYPE = [
  { id: 0, label: '幅指定' },
  { id: 1, label: '価格指定' },
];

export const OPTIONS_ORDER_TYPE = [
  { label: '成行', value: 0 },
  { label: '価格指定', value: 1 },
];
export const CUSTOM_SELECT_WIDTH = 123;
export const INPUT_WITH_PIPS_WIDTH = 106;

export const MAIL_SETTINGS_NO_EMAIL_SELECTED = '最低一つのメールアドレスを通知配信先として選択する必要があります';

export const CART_DEFAULT_SETS = 1;

export const BUGSNAG_ENABLED_RELEASE_STAGES = ['Production', 'Staging', 'Development'];
export const BUGSNAG_RELEASE_STAGE_POSTFIX = '-lotus';
export const BUGSNAG_ENABLED_RELEASE_STAGES_LOTUS = BUGSNAG_ENABLED_RELEASE_STAGES.map(
  (e) => `${e}${BUGSNAG_RELEASE_STAGE_POSTFIX}`,
);

export const STRATEGY_SETS_RESULTS = {
  SUCCESS: '1',
  FAIL: '0',
};

export const FRONT_ZEROS_AND_COMMAS_REG = /^[0,]+(?=\d)/;
export const NUMBER_VALIDATION_REG = /^[^-+e,.]?((\d+\.?\d*)|(\.\d+))$/;
export const NUMBER_VALIDATION_REG_ALLOW_NEGATIVE = /^[^+e,.]?((\d+\.?\d*)|(\.\d+))$/;
export const INTEGER_VALIDATION_REG = /^\d*(\.[0]?)?$/;
export const FULL_TIME_REG = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/;

// duplicate these constants because of dependency cycle with 'manualTrade' file
const CHANGE_ORDER_PRICE_INPUT = 'innerPrice';
const CHANGE_ORDER_THEN_PRICE_INPUT = 'innerThenPrice';
const CHANGE_ORDER_OCO2_PRICE_INPUT = 'innerOco2Price';
const CHANGE_ORDER_THEN_OCO2_PRICE_INPUT = 'innerThenOco2Price';

export const ORDER_CHANGING_MANUAL_TRADE = {
  PRICE_VALUES: {
    0: '指値価格',
    1: '逆指値価格',
    2: '価格',
  },
  SETTLEMENT_COMPOSITE_TYPES: [ORDERS_COMPOSITE_TYPES_MAIN.IFD.ID, ORDERS_COMPOSITE_TYPES_MAIN.IFO.ID],
  SETTLEMENT_PRICE_INPUTS: [CHANGE_ORDER_THEN_PRICE_INPUT, CHANGE_ORDER_THEN_OCO2_PRICE_INPUT],
  OCO_PRICE_INPUTS: [CHANGE_ORDER_OCO2_PRICE_INPUT, CHANGE_ORDER_THEN_OCO2_PRICE_INPUT],
  PRICE_INPUTS: [
    CHANGE_ORDER_PRICE_INPUT,
    CHANGE_ORDER_OCO2_PRICE_INPUT,
    CHANGE_ORDER_THEN_PRICE_INPUT,
    CHANGE_ORDER_THEN_OCO2_PRICE_INPUT,
  ],
};

export const MARKET_ORDER_CUSTOM_SELECTOR = 'market_order_buysell_selector_';
export const STANDARD_CUSTOM_SELECTOR = 'standard_buysell_selector_';
export const STANDARD_ORDER_METHOD_SELECTOR = 'standard_order_method_selector_';
export const STANDARD_EXPIRATION_DATE_SELECTOR = 'standard_expiration_date_selector';
export const OCO_CUSTOM_SELECTOR = 'oco_buysell_selector_';
export const OCO_EXPIRATION_DATE_SELECTOR = 'oco_expiration_date_selector';
export const IFD_CUSTOM_SELECTOR = 'ifd_buysell_selector_';
export const IFD_SETTLEMENT_CUSTOM_SELECTOR = 'ifd_settlement_selector_';
export const IFD_ORDER_METHOD_SELECTOR = 'ifd_order_method_selector_';
export const IFD_SETTLEMENT_ORDER_METHOD_SELECTOR = 'ifd_settlement_order_method_selector_';
export const IFD_EXPIRATION_DATE_SELECTOR = 'ifd_expiration_date_selector';
export const IFD_SETTLEMENT_EXPIRATION_DATE_SELECTOR = 'ifd_settlement_expiration_date_selector';
export const IFO_CUSTOM_SELECTOR = 'ifo_buysell_selector_';
export const IFO_SETTLEMENT_CUSTOM_SELECTOR = 'ifo_settlement_selector_';
export const IFO_ORDER_METHOD_SELECTOR = 'ifo_order_method_selector_';
export const IFO_EXPIRATION_DATE_SELECTOR = 'ifo_expiration_date_selector';
export const IFO_SETTLEMENT_EXPIRATION_DATE_SELECTOR = 'ifo_settlement_expiration_date_selector';

export const AUTO_SELECT_TABLES = {
  INQUIRY: {
    ID: 0,
    LABEL: '約定照会',
  },
  SETTINGS: {
    ID: 1,
    LABEL: '注文設定',
  },
  ORDER_GROUPS: {
    ID: 2,
    LABEL: '注文グループ',
  },
};

export const POST_LAB_SELECT_TABLES = {
  ORDER_GROUPS: {
    ID: 2,
    LABEL: '注文グループ',
  },
};

export const AUTO_SELECT_TABLES_DATA = Object.values(AUTO_SELECT_TABLES).map((el) => ({
  id: el.ID,
  value: el.LABEL,
}));

export const POST_LAB_TABLES_DATA = Object.values(POST_LAB_SELECT_TABLES).map((el) => ({
  id: el.ID,
  value: el.LABEL,
}));

export const YEARS_OF_EXPERIENCE_LIST = [
  { label: '1年未満', value: '1' },
  { label: '2年', value: '2' },
  { label: '3年', value: '3' },
  { label: '4年', value: '4' },
  { label: '5年以上', value: '5' },
];

export const TECHNICAL_STYLE_LIST = [
  { label: '上昇狙い', value: '0' },
  { label: '下落狙い', value: '1' },
  { label: 'レンジ狙い', value: '2' },
];

export const TECHNICAL_TERM_LIST = [
  { label: '短期(3か月以内)', value: '0' },
  { label: '中期(3か月~6か月)', value: '1' },
  { label: '長期(6か月以上)', value: '2' },
];

export const CHART_DEBOUNCE_TIME = 300;
export const DEFAULT_DELAY = 1000;
export const OPEN_MODAL_CHECKING_DELAY = 100;

export const EXECUTIONS_EMPTY_MESSAGE_WRONG_PERIOD = '現在日付から12ヶ月以内で期間を指定してください';
export const EXECUTIONS_EMPTY_MESSAGE = '検索期間内の約定はありません';

export const POSITIONS_EMPTY_MESSAGE = '保有している建玉はありません';

export const SSO_MY_PAGE_URL = 'sso-my-page';
export const SSO_PAYMENT_URL = 'sso-payment';
export const SSO_MONEY_HATCH_URL = 'sso-money-hatch';
export const SSO_QUICK_DEPOSIT_URL = 'sso-quick-deposit';
export const SSO_PAYMENT_DEPOSIT_REDIRECT_URL = 'sso-payment-deposit';
export const SSO_PAYMENT_TRANSFER_REDIRECT_URL = 'sso-payment-transfer';
export const SSO_PAYMENT_WITHDRAWAL_REDIRECT_URL = 'sso-payment-withdrawal';
export const SSO_PAYMENT_DETAIL_REDIRECT_URL = 'sso-payment-detail';
export const SSO_INVAST_AFFILIATE_REDIRECT_URL = 'sso-invast-affiliate';

export const KEY_FOR_DEFAULT_SERVICE_ID = 'SERVICE_ID';
export const DEFAULT_SERVICE_ID = FX;

export const KEY_FOR_IS_CROSS_ORDER = {
  [FX]: 'isCrossOrderFX',
  [ETF]: 'isCrossOrderETF',
  [CFD]: 'isCrossOrderCFD',
};

export const ETF_ADDITIONAL_INSTRUMENT_ID = 'USD/JPY';

export const FX_ADDITIONAL_INSTRUMENT_IDS = Object.freeze({
  'PLN/JPY': 'PLN/JPY',
  'PLN_M/JPY': 'PLN_M/JPY',
  'PLN_AP/JPY': 'PLN_AP/JPY',
});

export const ETF_ADDITIONAL_INSTRUMENT_IDS = Object.freeze({
  [ETF_ADDITIONAL_INSTRUMENT_ID]: ETF_ADDITIONAL_INSTRUMENT_ID,
});

export const CFD_ADDITIONAL_INSTRUMENT_IDS = Object.freeze({
  'USD/JPY': 'USD/JPY',
  'GBP/JPY': 'GBP/JPY',
  'EUR/JPY': 'EUR/JPY',
});

export const ADDITIONAL_INSTRUMENT_IDS = Object.freeze({
  [FX]: FX_ADDITIONAL_INSTRUMENT_IDS,
  [ETF]: ETF_ADDITIONAL_INSTRUMENT_IDS,
  [CFD]: CFD_ADDITIONAL_INSTRUMENT_IDS,
});

export const POSITION_DATA_ACTIONS = {
  ADD_NEW: 'ADD_NEW',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  POSITION_NOT_EXIST: 'POSITION_NOT_EXIST',
};

export const TRADE_TABLES = {
  EXECUTIONS: {
    ID: 0,
  },
  POSITIONS: {
    ID: 1,
  },
  ORDERS: {
    ID: 2,
  },
};

export const TRADE_INFO_TABLES = {
  EXECUTIONS: {
    ID: 0,
  },
  POSITIONS_DETAILS: {
    ID: 1,
  },
  POSITIONS_SUMMARY: {
    ID: 2,
  },
  ORDERS: {
    ID: 3,
  },
  ORDER_HISTORY: {
    ID: 4,
  },
};

export const PORTFOLIO_INFO_TABLES = {
  EXECUTIONS: {
    ID: 0,
  },
  POSITIONS_DETAILS: {
    ID: 1,
  },
  SUMMARY: {
    ID: 2,
  },
  ORDERS: {
    ID: 3,
  },
  ORDER_HISTORY: {
    ID: 4,
  },
};

export const OPTIONS_INFO_TABLES = [
  { id: PORTFOLIO_INFO_TABLES.SUMMARY.ID, value: 'サマリー' },
  { id: PORTFOLIO_INFO_TABLES.ORDERS.ID, value: '注文設定' },
  { id: PORTFOLIO_INFO_TABLES.EXECUTIONS.ID, value: '約定照会' },
  { id: PORTFOLIO_INFO_TABLES.POSITIONS_DETAILS.ID, value: '建玉照会' },
];

export const multiEditFormTabOptions = [
  { id: 0, value: '絶対値設定' },
  { id: 1, value: '相対値設定' },
];

export const TRADING_VIEW_HOME_URL = 'tradingview.com';

export const MS_IN_SECONDS = 1000;
export const CHART_DATA_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const KEY_FOR_SYSTEM_ERROR = 'systemErrorStatus';

export const PORTFOLIO_ROUTE_NAME = {
  WEB: '/',
  MOBILE: 'portfolioStack',
};

export const CLOSE_POSITIONS_MAX_AMOUNT = 100;

// two minutes
export const POSITION_DELETION_EXPIRE_TIME = 2 * 60 * 1000;

export const TERM_ID_SUFFIX = '年1月~';

// reports constants
export const yearSelectorWidth = 85;
export const quarterSelectorWidth = 110;
export const searchButtonWidth = 76;
export const datePickerWidth = 128;
export const monthSelectorWidth = 80;

export const quarterOptions = [
  {
    label: '第1四半期',
    value: 0,
  },
  {
    label: '第2四半期',
    value: 1,
  },
  {
    label: '第3四半期',
    value: 2,
  },
  {
    label: '第4四半期',
    value: 3,
  },
];

export const monthOptions = [
  {
    label: '1月',
    value: 0,
  },
  {
    label: '2月',
    value: 1,
  },
  {
    label: '3月',
    value: 2,
  },
  {
    label: '4月',
    value: 3,
  },
  {
    label: '5月',
    value: 4,
  },
  {
    label: '6月',
    value: 5,
  },
  {
    label: '7月',
    value: 6,
  },
  {
    label: '8月',
    value: 7,
  },
  {
    label: '9月',
    value: 8,
  },
  {
    label: '10月',
    value: 9,
  },
  {
    label: '11月',
    value: 10,
  },
  {
    label: '12月',
    value: 11,
  },
];

export const reportTypesSelectorWidth = 162;

export const REPORTS_TYPES = {
  DAILY: { NAME: '日次報告書', ID: 0 },
  MONTHLY: { NAME: '月次報告書', ID: 1 },
  QUARTERLY: { NAME: '四半期報告書', ID: 2 },
  SELECTED_PERIOD: { NAME: '期間損益報告書', ID: 3 },
  // ORDER_HISTORY_CSV: { NAME: '注文履歴CSV', ID: 11 }, // todo: temporarily removed from list
  EXECUTION_HISTORY_CSV: { NAME: '約定履歴CSV', ID: 12 },
};

const reportTypeToSelectorValue = ({ NAME, ID }) => ({ label: NAME, value: ID });

export const reportsSelectorValues = Object.values(REPORTS_TYPES).map(reportTypeToSelectorValue);

export const dailyValues = [
  REPORTS_TYPES.DAILY.ID,
  REPORTS_TYPES.SELECTED_PERIOD.ID,
  // todo: temporarily commented while ORDER_HISTORY_CSV removed from a list
  // REPORTS_TYPES.ORDER_HISTORY_CSV.ID,
  REPORTS_TYPES.EXECUTION_HISTORY_CSV.ID,
];

export const DAILY_FILE_NAMES = {
  [REPORTS_TYPES.SELECTED_PERIOD.ID]: '期間損益報告書.pdf',
  // todo: temporarily commented while ORDER_HISTORY_CSV removed from a list
  // [REPORTS_TYPES.ORDER_HISTORY_CSV.ID]: '注文履歴.csv',
  [REPORTS_TYPES.EXECUTION_HISTORY_CSV.ID]: '約定履歴.csv',
};

export const YOUTUBE_REG = /^(http:\/\/|https:\/\/)(youtu\.be|www\.youtube\.com)\/([\w/]+)([?].*)?$/i;

export const ALL_CATEGORY = { label: '全て', value: '全て', desc: 'All' };

export const FX24_CATEGORIES = [
  ALL_CATEGORY,
  { label: '速報', value: '速報', desc: 'Quick announcement' },
  { label: '要人発言', value: '要人発言', desc: 'Messages from key persons' },
  { label: 'コメント', value: 'コメント', desc: 'Comment' },
  { label: '経済指標', value: '経済指標', desc: 'Economic indicator' },
  { label: 'テクニカル', value: 'テクニカル', desc: 'Technical' },
  { label: '市況等', value: '市況等', desc: 'Market conditions' },
];

export const STOCK365_CATEGORIES = [
  ALL_CATEGORY,
  {
    label: 'マーケット',
    value: 'マーケット',
    desc: 'Market',
    subCategories: [
      'マーケット―アウトルック―今日の株式見通し',
      'マーケット―アウトルック―今日の戦略',
      'マーケット―市況',
      'マーケット―株式データ',
      'マーケット―経済指標',
      '市況―通常市況',
      '市況―コメント',
    ],
  },
  {
    label: 'テクニカル',
    value: 'テクニカル',
    desc: 'Technical',
    subCategories: [
      'テクニカル―テクニカル―日経平均株価テクニカル分析',
      'テクニカル―テクニカル―日経平均サポート＆レジスタンス',
    ],
  },
  {
    label: '海外マーケット',
    value: '海外マーケット',
    desc: 'Overseas market',
    subCategories: [
      '海外-マーケット―海外アウトルック―今日のNY見通し',
      '海外-マーケット―海外市況',
      '海外-マーケット―海外株式データ',
    ],
  },
  {
    label: '先物市況',
    value: '先物市況',
    desc: 'Futures market',
    subCategories: [
      '先物―先物市況―SGX225先物',
      '先物―先物市況―海外225先物市況',
      '先物―先物市況―225先物概況',
      '先物―先物データ―225先物オプション手口情報',
      '先物―先物データ―225先物日中取引手口',
    ],
  },
  {
    label: '経済指標',
    value: '経済指標',
    desc: 'Economic indicator',
    subCategories: ['ニュース―指標結果', '指標予定―指標予定', '指標予定―指標予定'],
  },
  {
    label: 'その他',
    value: 'その他',
    desc: 'Other',
    subCategories: ['国内その他―国内その他', '海外その他―海外その他', '先物その他―先物その他'],
  },
];

export const NEWS_CATEGORIES = {
  [FX24]: FX24_CATEGORIES,
  [STOCK365]: STOCK365_CATEGORIES,
};

export const NEWS_TAB_LABELS = {
  [FX24]: '為替',
  [STOCK365]: '株式',
};

export const MOBILE_NEWS_RELOAD_CLOCK = 60 * 1000 * 5;

export const DISABLE_INSTRUMENTS = ['TQQQ.ARC/USD'];

export const PORTFOLIO_TAGS = [
  {
    id: 'portfolio',
    value: 'ポートフォリオ',
    label: 'ポートフォリオ',
  },
  {
    id: 'lab',
    value: 'ビルダーズシェア',
    label: 'ビルダーズシェア',
  },
];

// '-1:キャンセル,0:審査中,1:公開準備中,2:公開中,3:非承認準備中,4:非承認,5:シェア停止,6:削除'
export const LAB_STATUS_MAP = {
  '-1': 'キャンセル',
  0: 'シェア審査中',
  // 公開準備中 → 審査中として表示させる
  1: 'シェア審査中',
  2: 'シェア中',
  // 非承認準備中 → 審査中として表示させる
  3: 'シェア審査中',
  4: '非承認',
  5: 'シェア停止',
  6: '削除',
};
export const LAB_PUBLISHED_STATUS = {
  0: 'シェア審査中',
  1: '公開準備中',
  2: 'シェア中',
  3: '非承認準備中',
};

export const LAB_POST_AP_GROUPS_MAX = 10;

export const LAB_POST_AP_GROUPS_MIN = 2;

export const LAB_POST_NAME_MAX_LENGTH = 20;

export const LAB_POST_COMMENT_MAX_LENGTH = 100;

export const DEFAULT_LAB_SELECTED_TERM_ID = 9999;
export const DEFAULT_LAB_SETS = 1;

export const LAB_INFO_PROFITABILITY =
  '投稿者がビルダーを稼働した日時を基準とする収益シミュレーションTOP100を表示します。';
export const LAB_INFO_WELL = '収益シミュレーションにおける直近のパフォーマンスが良いTOP100を表示します。';
export const LAB_INFO_POPULAR = '「殿堂入り」を除く、稼働人数が多いTOP100を表示します。';
export const LAB_INFO_HALL_OF_FAME = '稼働人数が一度でも500人に達したものを表示します。';

export const LAB_POST_CONDITION = 'ビルダーズシェアの投稿条件を見る＞';

export const LAB_STEP1_TITLE = 'シェアするルールを選びましょう';

export const LAB_STEP3_ICON_CROP_TITLE = '画像を調整します';

export const LAB_POST_CONFIRM_ROW1 = '設定した内容でシェア申請します。';
export const LAB_POST_CONFIRM_ROW2 = 'よろしいですか？';
export const LAB_POST_CONFIRM_WARNING = '※注文数量のみ調整し公開されることがあります。';

export const LAB_POST_SUCCESS_TITLE = '申請が完了しました！';
export const LAB_POST_SUCCESS_ROW1 = '審査中のため、';
export const LAB_POST_SUCCESS_ROW2 = 'シェア結果の反映には少しお時間を頂戴します';

export const LAB_SIMULATION_EMPTY_MESSAGE =
  'シミュレーション計算中です。シミュレーション結果が表示されるまでしばらくお待ちください。';
export const LAB_POST_SUMMARY_SIMULATION_EMPTY_MESSAGE =
  'シミュレーション計算中です。ビルダーの稼働当日はシミュレーションが表示されません。';

export const LAB_POST_AP_GROUPS_EMPTY_MESSAGE = 'シェア可能なルールがありません。';

export const DATA_SIZE_10MB = 10485760;
export const LAB_ICON_ERROR_MESSAGE = 'JPG・PNGで10MB以内の画像を登録してください。';

export const LAB_POST_CONDITION_LINK = 'https://www.invast.jp/blogs/202112_builder_1';

export const MARGIN_TOOLTIP =
  '推奨証拠金は選択したシミュレーション期間の金額を表示しているため選択期間により金額が変わります。';

export const LAB_EXTERNAL_LINK_NAME = '解説記事';
export const LAB_EXTERNAL_LINK_URL = 'https://www.invast.jp/blogs/202112_builder_2';

export const TQQQ_ARC_USD = 'TQQQ.ARC/USD';

export const BUILDER_TYPE_INFO_BUILDER = '高いカスタム性でオリジナルの自動売買ロジックが作成できます。';
export const BUILDER_TYPE_INFO_CHART_MAKE =
  'チャートに未来の値動きを描くだけでオリジナルの自動売買ロジックが作成できます。';

export const PRICE_RANGE = 'priceRange';
export const RANGE_WIDTH = 'rangeWidth';
export const ITEMS_COUNT = 'itemsCount';

export const CROSS_ORDER_ITEMS_COUNT_MIN = 4;
export const ONE_SIDE_ORDER_ITEMS_COUNT_MIN = 2;

export const CHART_MAKE_WELCOME_TITLE = '「チャートメイク」をはじめよう';
export const CHART_MAKE_WELCOME_INFO1 = '始め方はとってもカンタン。';
export const CHART_MAKE_WELCOME_INFO2_1 = '過去のチャートの動きから';
export const CHART_MAKE_WELCOME_INFO2_2 = '直近一年間の予測を手書きするだけで、';
export const CHART_MAKE_WELCOME_INFO3 = 'ロジックが自動生成されます。';

export const CHART_MAKE_STEP1 = 'STEP 1/4';
export const CHART_MAKE_STEP1_TITLE = '銘柄を選択しましょう';

export const CHART_MAKE_STEP2 = 'STEP 2/4';
export const CHART_MAKE_STEP2_TITLE1 = '一年分の動きを予測して';
export const CHART_MAKE_STEP2_TITLE2 = '自由に線をかいてみましょう';

export const CHART_MAKE_STEP3 = 'STEP 3/4';
export const CHART_MAKE_STEP3_TITLE1 = '自分に合った投資スタイルを';
export const CHART_MAKE_STEP3_TITLE2 = '選択しましょう';

export const CHART_MAKE_STEP4 = 'STEP 4/4';
export const CHART_MAKE_STEP4_TITLE = 'ロジック内容を確認して稼働させましょう';

export const CHART_MAKE_FUND_INDICATION_TOOLTIP =
  '想定損失額(※)を考慮した運用資金の目安。 ※すべての注文が約定し建玉を保有したうえでプロテクトラインに到達したときの評価損。';

export const RANGE_WIDTH_INFO = `レンジ幅は、本数の設定により注文が均等割になるよう微調整される場合があります。`;

export const CORE_RANGER = 'coreRanger';
export const HALF = 'half';
export const CORE_RANGER_BULL = 'coreRangerBull';
export const CORE_RANGER_BEAR = 'coreRangerBear';
export const SWAPPER = 'swapper';
export const PROTECTOR = 'protector';
export const ZONE_PROTECTOR = 'zoneProtector';
export const HEDGER = 'hedger';

export const CHART_MAKE_LOGIC_ID = {
  1: CORE_RANGER,
  2: HALF,
  3: CORE_RANGER_BULL,
  4: CORE_RANGER_BEAR,
  5: SWAPPER,
  6: PROTECTOR,
  7: ZONE_PROTECTOR,
  8: HEDGER,
};

export const CHART_MAKE_DEFAULT_WIDTH = 20;
export const CHART_MAKE_MAX_NUM_OF_ORDERS = 100;
export const CHART_MAKE_REQUEST_LOGIC_ID = 300;
export const CHART_MAKE_CFD_ROUND_DIGIT = 1;
export const CHART_MAKE_LOGIC_NAME = {
  [CORE_RANGER]: 'コアレンジャー',
  [HALF]: 'ハーフ',
  [CORE_RANGER_BULL]: 'コアレンジャー_ブル',
  [CORE_RANGER_BEAR]: 'コアレンジャー_ベア',
  [SWAPPER]: 'スワッパー',
  [PROTECTOR]: 'プロテクター',
  [ZONE_PROTECTOR]: 'ゾーンプロテクター',
  [HEDGER]: 'ヘッジャー',
};

export const CHART_MAKE_SELECTBOX_DISABLED_LOGIC = [SWAPPER, ZONE_PROTECTOR, PROTECTOR];

export const CORE_RANGE = 'coreRange';
export const SELL_RANGE = 'sellRange';
export const BUY_RANGE = 'buyRange';
export const RANGE = 'range';
export const ZONE = 'zone';
export const ZONE_1 = 'zone1';
export const ZONE_2 = 'zone1';
export const ZONE_3 = 'zone1';
export const BUY_ZONE = 'buyZone';
export const SELL_HEDGE = 'sellHedge';

export const CHART_MAKE_RANGE_ID = {
  [CORE_RANGER]: {
    [SELL_RANGE]: 1,
    [CORE_RANGE]: 0,
    [BUY_RANGE]: 3,
  },
  [HALF]: {
    [SELL_RANGE]: 1,
    [BUY_RANGE]: 3,
  },
  [CORE_RANGER_BULL]: {
    [BUY_RANGE]: 3,
    [CORE_RANGE]: 0,
  },
  [CORE_RANGER_BEAR]: {
    [SELL_RANGE]: 1,
    [CORE_RANGE]: 0,
  },
  [SWAPPER]: {
    [RANGE]: 3,
  },
  [PROTECTOR]: {
    [ZONE]: 3,
  },
  [ZONE_PROTECTOR]: {
    [ZONE]: 3,
  },
  [HEDGER]: {
    [BUY_ZONE]: 3,
    [SELL_HEDGE]: 1,
  },
};

export const CHART_MAKE_CHANGE_TARGET_OPTIONS = {
  [FX]: {
    [CORE_RANGER]: [
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER][SELL_RANGE], value: 'サブレンジ_売', label: 'サブレンジ_売' },
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER][CORE_RANGE], value: 'コアレンジ', label: 'コアレンジ' },
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER][BUY_RANGE], value: 'サブレンジ_買', label: 'サブレンジ_買' },
    ],
    [HALF]: [
      { id: CHART_MAKE_RANGE_ID[HALF][SELL_RANGE], value: '売りレンジ', label: '売りレンジ' },
      { id: CHART_MAKE_RANGE_ID[HALF][BUY_RANGE], value: '買いレンジ', label: '買いレンジ' },
    ],
    [CORE_RANGER_BULL]: [
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER_BULL][BUY_RANGE], value: 'サブレンジ_買', label: 'サブレンジ_買' },
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER_BULL][CORE_RANGE], value: 'コアレンジ', label: 'コアレンジ' },
    ],
    [CORE_RANGER_BEAR]: [
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER_BEAR][SELL_RANGE], value: 'サブレンジ_売', label: 'サブレンジ_売' },
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER_BEAR][CORE_RANGE], value: 'コアレンジ', label: 'コアレンジ' },
    ],
    [SWAPPER]: [{ id: CHART_MAKE_RANGE_ID[SWAPPER][RANGE], value: 'レンジ', label: 'レンジ' }],
  },
  [ETF]: {
    [CORE_RANGER]: [
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER][SELL_RANGE], value: 'サブレンジ_売', label: 'サブレンジ_売' },
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER][CORE_RANGE], value: 'コアレンジ', label: 'コアレンジ' },
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER][BUY_RANGE], value: 'サブレンジ_買', label: 'サブレンジ_買' },
    ],
    [PROTECTOR]: [{ id: CHART_MAKE_RANGE_ID[PROTECTOR][ZONE], value: 'ゾーン', label: 'ゾーン' }],
    [ZONE_PROTECTOR]: [{ id: CHART_MAKE_RANGE_ID[ZONE_PROTECTOR][ZONE], value: 'ゾーン', label: 'ゾーン' }],
    [HEDGER]: [
      { id: CHART_MAKE_RANGE_ID[HEDGER][BUY_ZONE], value: '買い注文', label: '買い注文' },
      { id: CHART_MAKE_RANGE_ID[HEDGER][SELL_HEDGE], value: '売りヘッジ注文', label: '売りヘッジ注文' },
    ],
  },
  // TODO kazama150180 必要なくなるかもしれないけれども、暫定で ETF のコピー
  [CFD]: {
    [CORE_RANGER]: [
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER][SELL_RANGE], value: 'サブレンジ_売', label: 'サブレンジ_売' },
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER][CORE_RANGE], value: 'コアレンジ', label: 'コアレンジ' },
      { id: CHART_MAKE_RANGE_ID[CORE_RANGER][BUY_RANGE], value: 'サブレンジ_買', label: 'サブレンジ_買' },
    ],
    [PROTECTOR]: [{ id: CHART_MAKE_RANGE_ID[PROTECTOR][ZONE], value: 'ゾーン', label: 'ゾーン' }],
    [ZONE_PROTECTOR]: [{ id: CHART_MAKE_RANGE_ID[ZONE_PROTECTOR][ZONE], value: 'ゾーン', label: 'ゾーン' }],
    [HEDGER]: [
      { id: CHART_MAKE_RANGE_ID[HEDGER][BUY_ZONE], value: '買い注文', label: '買い注文' },
      { id: CHART_MAKE_RANGE_ID[HEDGER][SELL_HEDGE], value: '売りヘッジ注文', label: '売りヘッジ注文' },
    ],
  },
};

export const THREE_MONTH = '3か月';
export const SIX_MONTH = '6か月';
export const ONE_YEAR = '1年';
export const TWO_YEAR = '2年';
export const THREE_YEAR = '3年';

export const THREE_MONTH_ID = '1';
export const SIX_MONTH_ID = '2';
export const ONE_YEAR_ID = '3';
export const TWO_YEAR_ID = '4';
export const THREE_YEAR_ID = '5';

export const termOptions = [
  {
    label: '3か月',
    value: '1',
  },
  {
    label: '6か月',
    value: '2',
  },
  {
    label: '1年',
    value: '3',
  },
  {
    label: '2年',
    value: '4',
  },
  {
    label: '3年',
    value: '5',
  },
];

export const ETF_USD_JPY_INSTRUMENT_ID = `${ETF}.USD/JPY`;
// TODO CFD 必要ないかもしれない
export const CFD_USD_JPY_INSTRUMENT_ID = `${CFD}.USD/JPY`;
export const CFD_EUR_JPY_INSTRUMENT_ID = `${CFD}.EUR/JPY`;
export const CFD_GBP_JPY_INSTRUMENT_ID = `${CFD}.GBP/JPY`;

export const CHART_MAKE_BUY_SELL_MAIN = {
  ...BUY_SELL_MAIN,
  STRADDLE: {
    ID: 0,
    CHART_ID: 3,
    LABEL: '両建',
    KEY: 'ask',
    ACTIVE_COLOR: ACTIVE_COLOR.RED,
  },
};

export const BALANCE_METER_ATTENTION = {
  danger: {
    level: 0,
    basePoint: 1.2,
    badge: 'WARNING',
    overview: '余力が少ない',
    tooltip: '証拠金に対して注文・建玉が多い状態です。有効比率が低下するとロスカットの危険性があります。',
    location: 4,
    color: '#ffd400',
  },
  warning: {
    level: 1,
    basePoint: 1.5,
    badge: 'WARNING',
    overview: '余力が少ない',
    tooltip: '証拠金に対して注文・建玉が多い状態です。有効比率が低下するとロスカットの危険性があります。',
    location: 3,
    color: '#ffd400',
  },
  aggressive: {
    level: 2,
    basePoint: 3.0,
    badge: 'AGGRESSIVE',
    overview: '資金効率が高い',
    tooltip: '証拠金に対して注文・建玉がやや多い状態です。有効比率の低下に注意して運用しましょう。',
    location: 2,
    color: '#dae5a1',
  },
  veryGood: {
    level: 3,
    basePoint: 5.0,
    badge: 'VERY GOOD',
    overview: 'バランスが良い',
    tooltip: '証拠金に対して注文・建玉のバランスが良い状態です。今後もこの状態を続けられるようにしましょう。',
    location: 1,
    color: '#00E573',
  },
  conservative: {
    level: 4,
    basePoint: null,
    badge: 'CONSERVATIVE',
    overview: '資金効率が低い',
    tooltip: '証拠金に対して注文・建玉は少ない状態です。状況に応じて注文の追加を検討しましょう。',
    location: 0,
    color: '#dae5a1',
  },
};

export const BALANCE_METER_NO_TRADE_TOOLTIP = '発注すると証拠金に対する注文・建玉のバランスが表示されます。';

export const TOOLTIP_MESSAGE = {
  TRADE_INFORMATION:
    '稼働した自動売買ルールやトレード建玉の詳細を見ることができます。\n自動売買ルールは評価損益や実現損益等を確認できます。' +
    '設定値の確認・変更もこちらから行えます。\nトレード建玉は銘柄・売買方向ごとに集約され、建玉数量や評価損益を確認できます。',
  EFFECTIVE_MARGIN_FX: '証拠金預託額±未決済建玉評価損益±未実現スワップ-出金指示額',
  EFFECTIVE_MARGIN_ETF: '証拠金預託額±未決済建玉評価損益±分配見込み±未実現金利相当額－出金指示額',
  EFFECTIVE_MARGIN_CFD: '証拠金預託額±未決済建玉評価損益±未実現金利・配当相当額－出金指示額',
  EFFECTIVE_MARGIN_RATE: '有効証拠金額 ÷ 必要証拠金額 × 100（100％以下でロスカットになります）',
  RECEIVED_MARGIN_FX: 'トライオートFXの取引口座に預けている証拠金残高',
  RECEIVED_MARGIN_ETF: 'トライオートETFの取引口座に預けている証拠金残高',
  RECEIVED_MARGIN_CFD: 'トライオートCFDの取引口座に預けている証拠金残高',
  UNSETTLED_PL: '保有建玉の評価損益（未確定の損益）',
  POSITION_REQUIRED_MARGIN: '現在保有している建玉の維持に必要な証拠金額',
  ORDERING_REQUIRED_MARGIN: '注文中（未約定）の新規注文に必要な証拠金額',
  ORDERABLE_MARGIN: '新規発注に利用できる証拠金額',
  DEPOSIT_BALANCE: 'マネーハッチで使用する次回投資分の積立金額',
};

const BALANCE_METER_DISABLE_MESSAGE_FX =
  '対象サービスがメンテナンス中またはロスカット中のため証拠金シミュレーションができません。';
export const BALANCE_METER_DISABLE_MESSAGE_OTHER =
  '対象サービスがメンテナンス中またはロスカット中や口座未開設のため証拠金シミュレーションができません。';
export const BALANCE_METER_DISABLE_MESSAGES = {
  [FX]: BALANCE_METER_DISABLE_MESSAGE_FX,
  [ETF]: BALANCE_METER_DISABLE_MESSAGE_OTHER,
  [CFD]: BALANCE_METER_DISABLE_MESSAGE_OTHER,
};

export const TRADE_METHOD_OPTION_MAP = {
  ap: {
    key: 'ap',
    id: [TRADE_METHODS.AP.ID],
    label: TRADE_METHOD_VALUE[TRADE_METHODS.AP.ID],
    selected: true,
  },
  manual: {
    key: 'manual',
    id: [TRADE_METHODS.MANUAL.ID],
    label: TRADE_METHOD_VALUE[TRADE_METHODS.MANUAL.ID],
    selected: true,
  },
  moneyHatch: {
    key: 'moneyHatch',
    id: [TRADE_METHODS.MH_AP.ID, TRADE_METHODS.MH_MANUAL.ID],
    label: TRADE_METHODS.MH_MANUAL.NAME, // Both moneyhatch methods have the same name
    selected: true,
  },
};

export const TRADE_METHOD_FILTER_INITIAL_STATES = {
  // Since then all 2 options are picked, no filter would be applied
  // FX filter would always have 1 trade method id selected, which is compatible with how API works for FX at this point
  [FX]: [TRADE_METHOD_OPTION_MAP.ap, TRADE_METHOD_OPTION_MAP.manual],
  [ETF]: [TRADE_METHOD_OPTION_MAP.ap, TRADE_METHOD_OPTION_MAP.manual],
  [CFD]: [TRADE_METHOD_OPTION_MAP.ap, TRADE_METHOD_OPTION_MAP.manual],
};

export const LINK_TYPE_ARTICLE = 'ページリンク';
export const LINK_TYPE_PAGE_LINK = 'ページリンク';
export const LINK_TYPE_VIDEO = '動画';
export const GUIDE_LINK_TYPE_PAGE_LINK = 'ページリンク';
export const GUIDE_LINK_TYPE_VIDEO = '動画';
export const GUIDE_LINK_TYPE = {
  [LINK_TYPE_PAGE_LINK]: 0,
  [LINK_TYPE_VIDEO]: 1,
};
export const START_UP_GUIDE_PRIORITY_LEVEL = 9999;
export const ONE_HUNDRED = 100;
export const ALERT_RATE = 150;
export const AUTO_SELECT_TAB_LOGIC_SETTING = 'LOGIC_SETTING';
export const AUTO_SELECT_TAB_SIMULATION = 'SIMULATION';

export const REQUIRED_MARGIN_TOOLTIP = '発注するために必要な最低証拠金。';
export const REQUIRED_MARGIN_LABEL = '発注証拠金目安';

export const RECOMMENDED_MARGIN_LABEL = '推奨証拠金';

export const COMPREHENSIVE_EVALUATION_LABEL = '総合評価';

export const DEPOSIT_TRANSFER = '入金・振替';

// server side enum
export const CHANNEL_ALL = '0';
export const CHANNEL_BROWSER = '1';
export const CHANNEL_MOBILE_APP = '2';

// TODO CFD バックエンドの enum に合わせる必要あり
export const SERVICE_ID_ALL = '0';
export const SERVICE_ID_FX = '1';
export const SERVICE_ID_ETF = '2';
export const SERVICE_ID_CFD = '3';

export const AUTO_TRADE_STATUS_DEACTIVATE_AP = '0';
export const AUTO_TRADE_STATUS_ACTIVATE_AP = '1';
// 損切 - 設定あり
export const SL_SETTING_ALL = 0;
// 損切 - 一部設定あり
export const SL_SETTING_SOME = 1;
// 損切 - 設定なし
export const SL_SETTING_NO = 2;

// TODO この辺はバックエンドから取得したい。。。
export const PERIOD = {
  SHORT: { value: '0', label: '短期' },
  MIDDLE: { value: '1', label: '中期' },
  LONG: { value: '2', label: '長期' },
};

export const STYLE = {
  UP: { value: '0', label: '上昇狙い' },
  DOWN: { value: '1', label: '下落狙い' },
  RANGE: { value: '2', label: 'レンジ狙い' },
};

export const STYLES = Object.freeze(
  Object.values(STYLE)
    .map(({ value: id, label: value }) => ({
      id,
      value,
    }))
    .sort((a, b) => Number(a.id) - Number(b.id)),
);

export const STYLE_OPTIONS = Object.freeze(Object.values(STYLE).sort((a, b) => Number(a.value) - Number(b.value)));

export const LEARN_TRIAUTO_MENU = {
  BEGINNER: {
    ID: 0,
    NAME: 'beginnerReadStatusList',
    MAIN_TITLE: 'トライオートの運用をはじめよう',
    STEPS: [
      { key: 'beginner1', title: 'チュートリアルを見る', component: 'TryDeposit' },
      { key: 'beginner2', title: '口座に入金する', component: 'TryCart' },
      { key: 'beginner3', title: '自動売買ルールを稼働する', component: 'TryExec' },
    ],
  },
};

export const LEARN_TRIAUTO_STATUS = {
  READ_STATUS: {
    UNREAD: '0',
    READ: '1',
  },
  HOME: {
    NOT_SHOW_HOME: '0',
    SHOW_HOME: '1',
  },
};

export const COUNTER_FIXED_INFO_MESSAGE = `カウンター固定にチェックを入れると、カウンター値が価格指定となります。`;

export const CASH_METHOD_YOUTUBE = 'https://www.youtube.com/watch?v=7YCuyg4Pwfw';

export const NEWS_COPYRIGHT_TITLE = '株式会社ＤＺＨフィナンシャルリサーチ　著作権について';
// eslint-disable-next-line max-len
export const NEWS_COPYRIGHT_TEXT = `最終的な投資判断はお客様ご自身の判断でなさるようお願いします。内容については正確性、信頼性、安全性の確保に努めておりますが、保証するものではありません。この情報に基づくいかなる損害についても株式会社ＤＺＨフィナンシャルリサーチは責任を一切負いかねます。すべての情報は、株式会社ＤＺＨフィナンシャルリサーチが著作権を有しています。許可なく転用、複製、複写、改変、販売することは重大な法律違反となります。`;
export const NEWS_COPYRIGHT_NOTES =
  '本サービスの利用によって生じたいかなる損害についてもインヴァスト証券株式会社は一切の責任を負いかねますので、あらかじめご了承ください。';
export const NEWS_COPYRIGHT = '(C)DZH Financial Research, Inc. All rights reserved.';

export const ORDER_HISTORY_EMPTY_MESSAGE = '自動売買の稼働履歴がありません';

export const SWAP_TRANSFER_LABEL = {
  [FX]: { multipleLabel: '一括スワップ振替', singleLabel: 'スワップ振替', width: 120 },
  [ETF]: { multipleLabel: '一括金利振替', singleLabel: '金利振替', width: 100 },
  [CFD]: { multipleLabel: '一括金利・配当相当額振替', singleLabel: '金利・配当相当額振替', width: 170 },
};

export const SWAP_LABEL_MAP = {
  [FX]: 'スワップ',
  [ETF]: '金利',
  [CFD]: '金利・配当相当額',
};

export const SWAP_INTEREST_LABEL_MAP = {
  [FX]: '累計スワップ',
  [ETF]: '金利等',
  [CFD]: '金利・配当相当額',
};

export const AMOUNT_UNIT_MAP = {
  [FX]: '万',
  [ETF]: '口',
  [CFD]: 'Lot',
};

export const INVAST_AFFILIATE_ALT = 'お友だち紹介キャンペーン';

export const FUNCTION_DESCRIPTION = '機能の説明';
export const DISPLAY_DESCRIPTION = '表示の説明';

export const NARROWING_DOWN = '絞込';
export const SORTING = '並び替え';
export const SEARCHING = '検索';
export const OPERATION_STYLE = '運用スタイル';
export const ASSUMED_OPERATION_PERIOD = '想定運用期間';
export const COMPREHENSIVE_EVALUATION = '総合評価';
export const LABEL_DOING_WELL = '好調';
export const LABEL_LESS_TRADING = '取引少なめ';
export const LESS_TRADING_THRESHOLD = 60;

export const MESSAGE_TYPE = {
  EVENT: '0',
  NO_DEPOSIT: '1',
  RANGE_OUT: '2',
  NOTIFICATION: '3',
};

export const DEFAULT_MESSAGE_TEMPLATE = {
  parameters: {
    SERVICE_NAME: '@SERVICE_NAME',
  },
  [MESSAGE_TYPE.NO_DEPOSIT]: 'トライオート@SERVICE_NAMEでお取引を始めるには、取引口座へのご入金が必要です。',
};

export const ACCOUNT_TYPE_MAP = {
  0: [FX],
  1: [FX, ETF],
  2: [FX, ETF, CFD],
};
