import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import Modal from '../../../../components/Modal';
import styles from './searchAutoSelectRuleName.module.scss';
import { Button, InputText } from '../../../../components';

const SearchAutoSelectRuleName = ({ isOpen, closeModal, autoSelectData }) => {
  const [searchText, setSearchText] = useState(autoSelectData.search.get);

  const handleClose = useCallback(() => {
    autoSelectData.search.set(searchText);
    closeModal();
  }, [closeModal, searchText, autoSelectData.search]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title={autoSelectData.search.modal.title}
      size={MODAL_SIZES.WIDTH_400}
    >
      <div className={styles.searchArea}>
        <InputText
          value={searchText}
          onChange={(value) => setSearchText(value)}
          placeholder={autoSelectData.search.placeholder}
          isSearch
        />
      </div>
      <div className="d-flex justify-content-center">
        <Button onClick={handleClose} className={styles.searchButton}>
          {autoSelectData.search.modal.submit}
        </Button>
      </div>
    </Modal>
  );
};

SearchAutoSelectRuleName.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  autoSelectData: PropTypes.shape({
    search: PropTypes.shape({
      get: PropTypes.string.isRequired,
      set: PropTypes.func.isRequired,
      placeholder: PropTypes.string.isRequired,
      modal: PropTypes.shape({ title: PropTypes.string.isRequired, submit: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default memo(SearchAutoSelectRuleName);
