import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useKeyPressHandler } from '../../services/hooks';
import { ReactComponent as SettingsSvg } from '../../assets/settings.svg';
import styles from './settingsIcon.module.scss';

const SettingsIcon = ({ width, height, onClick, className }) => {
  const handleClick = useCallback(
    (e) => {
      onClick();
      e.currentTarget.blur();
    },
    [onClick],
  );
  const handlePressHandler = useKeyPressHandler(handleClick);

  return (
    <div
      className={classNames(styles.wrapper, className)}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handlePressHandler}
    >
      <SettingsSvg width={width} height={height} />
    </div>
  );
};
SettingsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
SettingsIcon.defaultProps = {
  width: 20,
  height: 20,
  onClick: () => {},
  className: '',
};

export default memo(SettingsIcon);
