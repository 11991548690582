import React, { memo } from 'react';
import CustomSwitch from '../../../../../../../../components/CustomSwitch';
import OptionsDropdown from '../../../../../../../../components/OptionsDropdown';
import styles from '../../../../../PortfolioAutoTradeDetail/components/DetailTable/components/SwitchComponent/switchComponent.module.scss';

const DummySwitchComponent = memo(() => {
  return (
    <div className={styles.tableTogglesWrapper}>
      <CustomSwitch isChecked onChange={() => {}} />
      <OptionsDropdown onClick={() => {}} options={[]} onToggle={() => {}} />
    </div>
  );
});

export default DummySwitchComponent;
