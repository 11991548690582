import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { BUY_SELL_VALUE, BUY_SELL_AVAILABLE_VALUES, BUY_SELL_MAIN } from 'shared-modules/constants';
import styles from './buySellItem.module.scss';

const BuySellItem = ({ type }) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.isBuy]: type === BUY_SELL_MAIN.BUY.ID })}>
      {BUY_SELL_VALUE[type]}
    </div>
  );
};
BuySellItem.propTypes = {
  type: PropTypes.oneOf(BUY_SELL_AVAILABLE_VALUES).isRequired,
};

export default memo(BuySellItem);
