import PropTypes from 'prop-types';
import { toCommaSeparatedNumber } from 'shared-modules/services';

export const NumberCell = ({ cell: { value } }) => (value ? toCommaSeparatedNumber(value) : '-');

NumberCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};
