import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ReactGridLayout from 'react-grid-layout';
import { useAccountInfo } from 'shared-modules/hooks/useAccountInfo';
import { changeServiceIdRequest } from 'shared-modules/redux/actions';
import { getCategoryListRequest } from 'shared-modules/redux/actions/guideActions';
import { FX, LEARN_TRIAUTO_STATUS } from 'shared-modules/constants';
import { TAB_DETAIL, TAB_PL_TREND, KEY_FOR_PORTFOLIO_SUMMARY_CARD_ORDER } from 'shared-modules/constants/portfolio';
import { apGroupsSelector } from 'shared-modules/redux/portfolio';
import { getDefaultValuesFromLocalStorage, saveDefaultValuesFromLocalStorage } from 'shared-modules/services';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { useAutoReloadPortfolioData } from 'shared-modules/hooks/portfolio';
import { AccountSummaryCard } from './components/AccountSummaryCard';
import Portfolios from './components/Portfolios';
import { Spin, Tabs } from '../../components';
import { useScrollOrigin, useSmallHTMLFontSize } from '../../hooks';
import { AccountStatusDetail } from './components/AccountStatusDetail';
import { PlTrendTab } from './components/PlTrendTab';
import { AccountSummary } from './components/AccountSummary';
import { TradeInformation } from './components/TradeInformation';
import SelectionLearnTriautoContent from '../../components/SelectionLearnTriautoContent';
import HorizontalScroll from '../Media/components/HorizontalScroll';
import StartUpGuide from '../../components/StartUpGuide/index';
import { DRAGGABLE_CANCEL_SELECTOR_NAME, WEB_GUIDE_CATEGORY_LIST_ARTICLE_DISPLAY_NUM } from '../../constants';
import { DangerLevelNotificationCard } from './components/DangerLevelNotificationCard';
import styles from './portfolioPage.module.scss';

const SUMMARY_CARD_HEIGHT = 314;
const SUMMARY_CARD_MARGIN = 10;
const SUMMARY_CARD_MARGINS = SUMMARY_CARD_MARGIN * 2;
const SUMMARY_CARD_WIDTH = 590;
const COLS = ALL_SERVICES.length;
const GRID_WIDTH = (SUMMARY_CARD_WIDTH + SUMMARY_CARD_MARGIN) * COLS;
const DEFAULT_PORTFOLIO_SUMMARY_CARD_ORDER = ALL_SERVICES.map((service, index) => ({
  x: index,
  y: 0,
  w: 1,
  h: 1,
  i: service,
}));
const getPortfolioSummaryCardOrder = () => {
  const jsonOrObj = getDefaultValuesFromLocalStorage({
    key: KEY_FOR_PORTFOLIO_SUMMARY_CARD_ORDER,
    defaultValue: DEFAULT_PORTFOLIO_SUMMARY_CARD_ORDER,
  });
  if (jsonOrObj === DEFAULT_PORTFOLIO_SUMMARY_CARD_ORDER) {
    return jsonOrObj;
  }
  const obj = JSON.parse(jsonOrObj);
  return obj;
};
const savePortfolioSummaryCardOrder = (value) => {
  const json = JSON.stringify(value);
  saveDefaultValuesFromLocalStorage({ key: KEY_FOR_PORTFOLIO_SUMMARY_CARD_ORDER, value: json });
};

const PortfolioPage = () => {
  const { beginnerReadStatusList } = useSelector((state) => state.settings.learnTriautoStatus);
  const isAllRead = Object.values(beginnerReadStatusList).every(
    (value) => value === LEARN_TRIAUTO_STATUS.READ_STATUS.READ,
  );
  const isFirstLogin = useSelector((state) => state.settings.isFirstLogin);
  const [modalVisible, setModalVisible] = useState(false);

  const accountInfo = useAccountInfo();

  // TODO CFD 元々は render のたびに評価していたが、そもそも useState の初期値にしか使われていない
  const defaultServiceId = useMemo(() => {
    const availableService = ALL_SERVICES.find((service) => !accountInfo[service].isNotAvailable);
    return availableService ?? FX;
  }, [accountInfo]);
  const [selectedServiceId, setSelectedServiceId] = useState(defaultServiceId);

  const dispatch = useDispatch();

  useScrollOrigin();

  useSmallHTMLFontSize();

  const [activeTab, setActiveTab] = useState(TAB_DETAIL);

  useAutoReloadPortfolioData();

  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const isTrading = useSelector((state) => state.portfolio.isTrading);
  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);

  const { isOpen: isTutorialModalOpen } = useSelector((state) => state.modals.tutorialModal);
  useEffect(() => {
    const root = document.getElementById('root');
    // チュートリアルモーダルが開いている際は画面最下部までスクロールした状態にする
    if (isTutorialModalOpen) {
      root?.scrollTo?.(0, root?.scrollHeight ?? 0);
    } else {
      root?.scrollTo?.(0, 0);
    }
  }, [isTutorialModalOpen]);

  useEffect(() => {
    if (isTrading === false) {
      dispatch(
        getCategoryListRequest({
          pageSize: WEB_GUIDE_CATEGORY_LIST_ARTICLE_DISPLAY_NUM,
          contentsLimit: WEB_GUIDE_CATEGORY_LIST_ARTICLE_DISPLAY_NUM,
          alertFlg: false,
        }),
      );
    }
  }, [dispatch, isTrading]);

  const tabItems = useMemo(() => {
    return [
      {
        id: TAB_DETAIL,
        label: '口座状況詳細',
        children: <AccountStatusDetail className={styles.tabContent} serviceId={selectedServiceId} />,
      },
      {
        id: TAB_PL_TREND,
        label: '運用成績',
        children: <PlTrendTab serviceId={selectedServiceId} />,
      },
    ];
  }, [selectedServiceId]);

  const handleSummaryCardClick = (serviceId, selectedTab) => {
    // resetSelectedMonth();
    setSelectedServiceId(serviceId);
    dispatch(changeServiceIdRequest({ serviceId }));
    setActiveTab(selectedTab);
    setModalVisible(true);
  };

  const techApGroups = useSelector((state) => state.tech.techGroupsData);
  const apGroups = useSelector(apGroupsSelector);
  const notTrading = useMemo(
    () => apGroups?.length === 0 && techApGroups?.length === 0 && isTrading === false,
    [apGroups?.length, isTrading, techApGroups?.length],
  );

  const portfolioArea = useMemo(() => {
    if (isAuth && isTrading === null) {
      return (
        <div className={styles.loaderContainer}>
          <Spin className={styles.loader} />
        </div>
      );
    }
    if (notTrading && !tutorialMode) {
      return <StartUpGuide isHome />;
    }
    if (!notTrading || tutorialMode) {
      return (
        <section className="mt-5">
          <h2>
            取引情報
            <TradeInformation />
          </h2>

          <Portfolios />
        </section>
      );
    }
    return <></>;
  }, [isAuth, isTrading, notTrading, tutorialMode]);

  const [layout, setLayout] = useState(getPortfolioSummaryCardOrder());

  const handleChangeLayout = useCallback((newLayout) => {
    savePortfolioSummaryCardOrder(newLayout);
    setLayout(newLayout);
  }, []);

  return (
    <div className={styles.container}>
      <section className="mb-5">
        <h2>
          口座状況
          <AccountSummary />
        </h2>
        <div style={{ width: '88vw' }}>
          <HorizontalScroll topOffset="45%">
            <div style={{ marginVertical: 6, height: SUMMARY_CARD_HEIGHT, width: GRID_WIDTH }}>
              <ReactGridLayout
                cols={COLS}
                maxRows={1}
                rowHeight={SUMMARY_CARD_HEIGHT}
                width={GRID_WIDTH}
                containerPadding={[0, 0]}
                margin={[SUMMARY_CARD_MARGINS, 0]}
                layout={layout}
                compactType="horizontal"
                autoSize={false}
                isResizable={false}
                measureBeforeMount={false}
                draggableCancel={`.${DRAGGABLE_CANCEL_SELECTOR_NAME}`}
                onLayoutChange={handleChangeLayout}
                isBounded
              >
                {ALL_SERVICES.map((service) => (
                  <div key={service}>
                    <AccountSummaryCard
                      key={service}
                      width={SUMMARY_CARD_WIDTH}
                      height={SUMMARY_CARD_HEIGHT}
                      serviceId={service}
                      onClick={(selectedTab) => handleSummaryCardClick(service, selectedTab)}
                      // 未ログイン時のモックに関わる props
                      hasApGroup={service === FX ? Boolean(apGroups.length) : false}
                    />
                  </div>
                ))}
              </ReactGridLayout>
            </div>
          </HorizontalScroll>
        </div>
        <div className={styles.dangerLevelNotificationArea}>
          {ALL_SERVICES.map((service) => (
            <DangerLevelNotificationCard
              key={service}
              serviceId={service}
              hasApGroup={service === FX ? Boolean(apGroups.length) : false}
            />
          ))}
        </div>
      </section>

      {(!isAllRead || isFirstLogin) && (
        <section>
          <SelectionLearnTriautoContent />
        </section>
      )}
      {portfolioArea}

      <Modal
        contentClassName="popup popup--fit-content"
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popup__header" closeButton closeVariant="white" />
        <Modal.Body className="popup__body">
          <Tabs containerClassName={styles.tabs} items={tabItems} activeKey={activeTab} onChange={setActiveTab} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default memo(PortfolioPage);
