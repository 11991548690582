import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { WIDTH, MODAL_SIZES } from 'shared-modules/constants';
import { useBuilderRunNowLogic } from 'shared-modules/services/hooks/builder';
import { useIsMarginZeroByServiceId, useSummaryInfo } from 'shared-modules/services/hooks';
import {
  changeServiceIdRequest,
  openTechAddToPortfolioFailModal,
  openTechAddToPortfolioSuccessModal,
  push,
} from '../../../../redux/actions';
import { CASH } from '../../../../constants';
import { Button, InputText, InputNumber, Modal, MarginInfo } from '../../../../components';
import { AssetDetail } from '../AssetDetail';
import styles from './techAddToPortfolio.module.scss';

const TechAddToPortfolio = ({ isOpen, closeModal, isOrderSetting }) => {
  const dispatch = useDispatch();

  const openSuccessModal = useCallback(
    (orderName) => {
      dispatch(openTechAddToPortfolioSuccessModal({ orderName }));
    },
    [dispatch],
  );

  const openFailModal = useCallback(
    (orderName) => {
      dispatch(openTechAddToPortfolioFailModal({ orderName }));
    },
    [dispatch],
  );

  const runNowLogic = useBuilderRunNowLogic({
    isModalOpen: isOpen,
    closeModal,
    handleSuccess: openSuccessModal,
    handleFail: openFailModal,
    isTechBuilder: true,
  });

  const chartData = useSummaryInfo();
  chartData.consumedMargin = runNowLogic.marginRequired.get;

  const serviceId = useSelector((state) => state.auth.serviceId);
  const isMarginZero = useIsMarginZeroByServiceId(serviceId);

  const handleClick = useCallback(() => {
    closeModal();
    dispatch(push(`/${CASH}`));
    dispatch(changeServiceIdRequest({ serviceId }));
  }, [dispatch, closeModal, serviceId]);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title={runNowLogic.modalTitle} size={MODAL_SIZES.WIDTH_512}>
      <div className={styles.row}>{runNowLogic.name.label}</div>
      <InputText
        className={styles.row}
        width={WIDTH.PERCENTAGE_100}
        value={runNowLogic.name.get}
        onChange={runNowLogic.name.set}
        name={runNowLogic.name.name}
        validateFunction={runNowLogic.name.validateFunction}
        errorMessages={runNowLogic.errorMessages}
        withErrorTooltip
      />
      <div className={styles.row}>
        <div>{runNowLogic.sets.label}</div>
        <InputNumber
          value={runNowLogic.sets.get}
          onChange={runNowLogic.sets.set}
          name={runNowLogic.sets.name}
          validateFunction={runNowLogic.sets.validateFunction}
          errorMessages={runNowLogic.errorMessages}
          withErrorTooltip
          onlyIntegerAllowed
        />
      </div>
      <div className={styles.row}>{runNowLogic.message}</div>
      <div className={styles.headline}>
        <div className={styles.label}>稼働後のバランスメーター</div>
      </div>

      <AssetDetail
        className={styles.assetDetail}
        meterData={chartData}
        serviceId={serviceId}
        isMarginZero={isMarginZero}
        onClick={handleClick}
        hideMarginInfo
      />
      <MarginInfo
        className={styles.marginArea}
        requiredMargin={runNowLogic.marginRequired}
        recommendedMargin={isOrderSetting ? null : runNowLogic.margin}
      />

      <div className={styles.buttonArea}>
        <Button
          onClick={runNowLogic.techSubmit.handler}
          loading={runNowLogic.techSubmit.isLoading}
          disabled={runNowLogic.techSubmit.isDisabled}
        >
          {runNowLogic.techSubmit.label}
        </Button>
      </div>
    </Modal>
  );
};

TechAddToPortfolio.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOrderSetting: PropTypes.bool.isRequired,
};

export default memo(TechAddToPortfolio);
