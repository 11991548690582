import { call, put, select } from 'redux-saga/effects';
import Logger from '../../../services/Logger';
import { checkInitialDeposit } from '../../../api/messageApi';
import { checkInitialDepositSuccess } from '../../actions/messageActions';
import { ALL_SERVICES } from '../../../constants/core';
import { getAccountInfo } from '../common';

export function* checkInitialDepositRequestHandler() {
  try {
    const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
    if (!isAuthenticated) {
      // 未ログイン時はスキップ
      return;
    }
    const accountInfo = yield* getAccountInfo();
    if (ALL_SERVICES.every((serviceId) => accountInfo[serviceId].isNotAvailable)) {
      // 有効な口座が一つもない場合はスキップ
      return;
    }
    const { data: initialDepositState } = yield call(checkInitialDeposit);
    put(checkInitialDepositSuccess({ initialDepositState }));
  } catch (error) {
    // 高い確率で片側メンテによるOMSリクエスト直前での例外スローによるエラー
    // 上記以外の原因だとしても握りつぶしてしまっても問題ない
    Logger.error(error);
  }
}
