// eslint-disable-next-line import/no-unresolved
import { useCallback, useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { useCalculatingChartDataWithLabels, useCartSummaryInfo, useIsMarginZeroByServiceId } from '../services/hooks';
import { BALANCE_METER_ATTENTION, BUY_SELL_MAIN, CART_DEFAULT_SETS, CFD, ETF, FX } from '../constants';
import { compareServiceId } from '../utils';
import { transformCurrentItems } from '../utils/cart';
import { TECH_BASE_PRICE_LITERAL } from '../constants/builder';
import { ALL_SERVICES } from '../constants/core';
import { useMeterAttention } from './balanceMeter';

export const useCurrentData = (termId) => {
  const selectedTermId = useSelector((state) => state.cart.selectedTermId);
  const currentData = useSelector((state) => state.cart.currentData);
  return currentData[termId ?? selectedTermId] ?? [];
};

export const useItemGroup = (itemId, termId) => {
  const itemList = useCurrentData(termId);
  return useMemo(() => {
    const transformedItemList = transformCurrentItems(itemList);
    return transformedItemList.find((item) => item.itemId === itemId);
  }, [itemId, itemList]);
};

export const useConvertStrategyList = ({ strategyList, rates }) => {
  const convertEntryPrice1Value = useCallback(
    (price, buySell, instrumentId) => {
      if (!price) return price;

      if (!price?.includes(TECH_BASE_PRICE_LITERAL)) {
        return price;
      }

      const formatPrice = Number(price.replace(TECH_BASE_PRICE_LITERAL, '')?.replaceAll(' ', ''));
      const rate =
        buySell === String(BUY_SELL_MAIN.BUY.ID) ? rates[instrumentId]?.askClose : rates[instrumentId]?.bidClose;
      return formatPrice + rate;
    },
    [rates],
  );

  return useMemo(() => {
    return (strategyList ?? []).map((strategy) => ({
      ...strategy,
      strategyDetail: {
        ...strategy.strategyDetail,
        itemList: strategy.strategyDetail.itemList.map((strategyItem) => ({
          ...strategyItem,
          entryPrice1: convertEntryPrice1Value(
            strategyItem.entryPrice1,
            strategyItem.side,
            strategy.strategyDetail.instrumentId,
          ),
        })),
      },
    }));
  }, [strategyList, convertEntryPrice1Value]);
};

export const useCalculatedMargin = ({ item }) => {
  const rates = useSelector((state) => state.currencies.eodRates);
  const convertedStrategyList = useConvertStrategyList({ strategyList: item?.cartItems, rates });

  const { roundedMarginRecommended, marginRequired } = useCalculatingChartDataWithLabels({
    strategyList: convertedStrategyList,
    defaultSets: CART_DEFAULT_SETS,
  });

  return useMemo(
    () => ({
      marginRecommended: roundedMarginRecommended?.value ?? 0,
      marginRequired: marginRequired?.value ?? 0,
    }),
    [roundedMarginRecommended, marginRequired],
  );
};

const compareCreateTimeDesc = (a, b) => {
  if (a.createTime === b.createTime) {
    return 0;
  }
  if (a.createTime > b.createTime) {
    return -1;
  }
  return 1;
};

const compareSortNoAsc = (a, b) => {
  if (a.sortNo === b.sortNo) {
    return 0;
  }
  if (a.sortNo < b.sortNo) {
    return -1;
  }
  return 1;
};

const useLogData = (logData) => {
  return useMemo(() => {
    logData.sort(compareCreateTimeDesc);
    const cartLogData = logData.map((elem) => {
      const { items, ...rest } = elem;
      const data = items.reduce(
        (acc, curr) => {
          const { groups, transformed } = acc;
          const { parentId, sourceType, strategyId, ...restProps } = curr;
          const groupKey = parentId == null ? strategyId : `${parentId ?? ''}_${sourceType ?? ''}`;
          if (parentId == null) {
            transformed.push({ cartLogItems: [curr], ...curr, groupKey });
          } else if (groups[groupKey] == null) {
            groups[groupKey] = [curr];
            transformed.push({
              ...restProps,
              groupKey,
              strategyId,
              parentId,
              sourceType,
              cartLogItems: groups[groupKey],
            });
          } else {
            groups[groupKey].push(curr);
          }
          return { groups, transformed };
        },
        { groups: {}, transformed: [] },
      );
      data.transformed.forEach((e) => {
        e.cartLogItems.sort(compareSortNoAsc);
      });
      return { ...rest, items: data.transformed };
    });
    return cartLogData;
  }, [logData]);
};

export const useSavedData = () => {
  const isLoading = useSelector((state) => state.cart.savedDataIsLoading);
  const savedData = useSelector((state) => state.cart.savedData);
  const savedCartData = useLogData(savedData);
  return { savedCartData, loading: isLoading };
};

export const useHistoryData = () => {
  const historyData = useSelector((state) => state.cart.historyData);
  return useLogData(historyData);
};

export const useServiceIds = (cartLogItems) => {
  return useMemo(() => {
    const serviceIdSet = cartLogItems.reduce((set, cartLogItem) => {
      set.add(cartLogItem.serviceId);
      return set;
    }, new Set());
    const ids = [...serviceIdSet];
    ids.sort(compareServiceId);
    return ids;
  }, [cartLogItems]);
};

const useAttention = (servieId, consumedMargin) => {
  const meterData = { ...useCartSummaryInfo(servieId) };
  meterData.consumedMargin = consumedMargin;
  const isMarginZero = useIsMarginZeroByServiceId(servieId);

  const { attention } = useMeterAttention(meterData);

  return useMemo(() => (isMarginZero ? null : attention), [attention, isMarginZero]);
};

const normalize = (level) => {
  if (level === BALANCE_METER_ATTENTION.veryGood.level) {
    return Number.MAX_VALUE;
  }
  return level;
};

export const useAttentionSummary = (data) => {
  const { marginRequiredFX, marginRequiredETF, marginRequiredCFD, serviceIdSet } = data || {};
  const attentions = {
    [FX]: useAttention(FX, marginRequiredFX?.value ?? 0),
    [ETF]: useAttention(ETF, marginRequiredETF?.value ?? 0),
    [CFD]: useAttention(CFD, marginRequiredCFD?.value ?? 0),
  };

  const target = ALL_SERVICES.filter((s) => serviceIdSet.has(s))
    .map((s) => attentions[s])
    .filter((attension) => attension)
    .reduce((min, curr) => {
      if (curr) {
        if (min) {
          return normalize(min.level) < normalize(curr.level) ? min : curr;
        }
        return curr;
      }
      return min;
    }, null);
  return useMemo(() => target, [target]);
};
