import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLabTermOptions, useSimulationChartDataLab, useSimulationSummaryLab } from 'shared-modules/hooks/select';
import { changePublishedLabSelectedTermId, getPublishedLabDetailsRequest } from 'shared-modules/redux/labs';
import { CommonChart } from './CommonChart';

export const LabChart = memo(() => {
  const dispatch = useDispatch();
  const { chartData, simulationData, serviceId, loading, labId, strategyList, defaultSets } =
    useSimulationChartDataLab();
  const termOptions = useLabTermOptions();
  const termId = useSelector((state) => state.labs.publishedLabs.selectedTermId);
  const simulationSummary = useSimulationSummaryLab();

  const handleChangeTermId = useCallback(
    (newTermId) => {
      dispatch(changePublishedLabSelectedTermId({ termId: newTermId }));
      dispatch(getPublishedLabDetailsRequest({ labId, termId: newTermId, termIdLoader: true }));
    },
    [dispatch, labId],
  );
  return (
    <CommonChart
      chartData={chartData}
      simulationData={simulationData}
      simulationSummary={simulationSummary}
      strategyList={strategyList}
      defaultSets={defaultSets}
      serviceId={serviceId}
      loading={loading}
      scale="point"
      termId={termId}
      termOptions={termOptions}
      onChangeTermId={handleChangeTermId}
    />
  );
});
