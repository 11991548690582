import React, { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import {
  HOW_TO_READ_CARD,
  HOW_TO_READ_CARD_BOLD_LINES,
  HOW_TO_READ_CARD_TITLE_LINES,
} from 'shared-modules/constants/select';
import { MODAL_SIZES } from 'shared-modules/constants';
import { Modal } from '../../../../components';
import styles from './cardDescription.module.scss';

export const CardDescription = memo(() => {
  const [visible, setVisible] = useState(false);
  const handleOpen = useCallback(() => {
    setVisible(true);
  }, []);
  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <div className={styles.container}>
      <div>使い方</div>
      <div className={styles.iconButton} role="button" tabIndex={0} onClick={handleOpen} aria-hidden>
        <i className={classNames('material-icons-outlined', styles.icon)}>help_outline</i>
      </div>
      {visible && (
        <Modal
          isOpen={visible}
          closeModal={handleClose}
          title="セレクトの使い方"
          size={MODAL_SIZES.WIDTH_960}
          titleStyle={styles.title}
        >
          <div className={styles.content}>
            {HOW_TO_READ_CARD.map((line, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {HOW_TO_READ_CARD_TITLE_LINES.includes(line) ? <hr className={styles.divider} /> : null}
                <span
                  className={classNames({
                    [styles.title]: HOW_TO_READ_CARD_TITLE_LINES.includes(line),
                    [styles.boldLine]: HOW_TO_READ_CARD_BOLD_LINES.includes(line),
                  })}
                >
                  {line}
                  <br />
                </span>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
});
