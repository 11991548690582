import { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './evaluation.module.scss';

const Star = memo(({ index, value, className }) => {
  const fill = index + 1 <= value;
  return (
    <div className={classNames(styles.star, { [styles.fill]: fill })}>
      <div className={styles.iconContainer}>
        <i className={classNames(styles.icon, 'material-icons', className)}>star</i>
      </div>
    </div>
  );
});

Star.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Star.defaultProps = {
  className: undefined,
};

const NO_RATING = '-';

export const Evaluation = memo(({ className, iconClassName, scoreClassName, value, count }) => {
  const [delayValue, setDelayValue] = useState(0);
  const [delayDisplayValue, setDelayDisplayValue] = useState(NO_RATING);

  useEffect(() => {
    const v = value ?? 0;
    setTimeout(() => setDelayValue(v), 100);
  }, [value]);

  useEffect(() => {
    const v = delayValue === 0 ? NO_RATING : delayValue;
    setTimeout(() => setDelayDisplayValue(v), 300);
  }, [delayValue]);

  return (
    <div className={classNames(styles.container, className)}>
      {new Array(count).fill(0).map((item, index) => (
        <Star key={item || index} className={iconClassName} index={index} value={delayValue} />
      ))}
      <div className={classNames(styles.score, { [styles.hyphen]: delayDisplayValue === NO_RATING }, scoreClassName)}>
        {delayDisplayValue}
      </div>
    </div>
  );
});

Evaluation.propTypes = {
  value: PropTypes.number,
  count: PropTypes.number,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  scoreClassName: PropTypes.string,
};

Evaluation.defaultProps = {
  value: undefined,
  count: 5,
  className: undefined,
  iconClassName: undefined,
  scoreClassName: undefined,
};
