import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { RECOMMENDED_MARGIN_LABEL, REQUIRED_MARGIN_LABEL } from 'shared-modules/constants';
import { InfoTooltip } from '../InfoTooltip';
import { MarginText } from '../Texts';
import styles from './marginInfo.module.scss';

export const MarginInfo = memo(({ className, requiredMargin, recommendedMargin, fundIndication }) => (
  <div className={classNames(styles.container, className)}>
    {Boolean(fundIndication?.get) && (
      <div className={styles.row}>
        <div className={styles.labelContainer}>
          {fundIndication.label || '運用資金目安'}
          {fundIndication.tooltip && <InfoTooltip message={fundIndication.tooltip} />}
        </div>
        <MarginText className={styles.margin} value={fundIndication.get} />
      </div>
    )}
    {Boolean(recommendedMargin?.get) && (
      <div className={styles.row}>
        <div className={styles.labelContainer}>
          {recommendedMargin.label ?? RECOMMENDED_MARGIN_LABEL}
          {recommendedMargin.tooltip && <InfoTooltip message={recommendedMargin.tooltip} />}
        </div>
        <MarginText className={styles.margin} value={recommendedMargin.get} />
      </div>
    )}
    {Boolean(requiredMargin?.get) && (
      <div className={styles.row}>
        <div className={styles.labelContainer}>
          {requiredMargin.label || REQUIRED_MARGIN_LABEL}
          {requiredMargin.tooltip && <InfoTooltip message={requiredMargin.tooltip} />}
        </div>
        <MarginText className={styles.margin} value={requiredMargin.get} />
      </div>
    )}
  </div>
));

MarginInfo.propTypes = {
  className: PropTypes.string,
  effectiveMargin: PropTypes.shape({
    get: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  requiredMargin: PropTypes.shape({
    get: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  recommendedMargin: PropTypes.shape({
    get: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  fundIndication: PropTypes.shape({
    get: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }),
};

MarginInfo.defaultProps = {
  className: undefined,
  effectiveMargin: undefined,
  requiredMargin: undefined,
  recommendedMargin: undefined,
  fundIndication: undefined,
};
