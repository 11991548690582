import React, { memo, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CASH_PAGES, CASH_PAGE_TITLE } from 'shared-modules/constants/cash';
import { resetData } from 'shared-modules/redux/cash';

import { useCashPageTransition } from '../../services/hooks';
import { useScrollOrigin } from '../../hooks';

import { CashMenu } from './CashMenu';
import QuickDepositSelect from './components/QuickDeposit/QuickDepositSelect';
import QuickDepositConfirm from './components/QuickDeposit/QuickDepositConfirm';
import DepositView from './components/Deposit/DepositView';

import styles from './Cash.module.scss';

const Cash = () => {
  const dispatch = useDispatch();
  const activePage = useSelector((state) => state.webCash.activePage);
  const pageTransition = useCashPageTransition();

  const isSelect = useSelector((state) => state.router.location.state?.isSelect);

  const key = useSelector((state) => state.router.location.key);

  useScrollOrigin();

  useEffect(() => {
    if (isSelect) {
      pageTransition(CASH_PAGES.CASH_QUICK_DEPOSIT_SELECT.ID);
    } else {
      dispatch(resetData());
      pageTransition(CASH_PAGES.CASH_MENU.ID);
    }
  }, [dispatch, key, pageTransition, isSelect]);

  const pageTitle = useMemo(() => {
    switch (activePage) {
      case CASH_PAGES.CASH_MENU.ID: {
        return CASH_PAGE_TITLE.CASH;
      }
      case CASH_PAGES.CASH_QUICK_DEPOSIT_SELECT.ID:
      case CASH_PAGES.CASH_QUICK_DEPOSIT_CONFIRM.ID:
      case CASH_PAGES.CASH_QUICK_DEPOSIT_SUCCESS.ID:
      case CASH_PAGES.CASH_QUICK_DEPOSIT_FAILED.ID: {
        return CASH_PAGE_TITLE.QUICK_DEPOSIT;
      }
      case CASH_PAGES.CASH_DEPOSIT_VIEW.ID: {
        return CASH_PAGE_TITLE.DEPOSIT;
      }
      default: {
        return CASH_PAGE_TITLE.CASH;
      }
    }
  }, [activePage]);

  const contents = useMemo(() => {
    switch (activePage) {
      case CASH_PAGES.CASH_MENU.ID: {
        return <CashMenu />;
      }
      case CASH_PAGES.CASH_QUICK_DEPOSIT_SELECT.ID: {
        return <QuickDepositSelect />;
      }
      case CASH_PAGES.CASH_QUICK_DEPOSIT_CONFIRM.ID: {
        return <QuickDepositConfirm />;
      }
      case CASH_PAGES.CASH_DEPOSIT_VIEW.ID: {
        return <DepositView />;
      }
      default: {
        return <span />;
      }
    }
  }, [activePage]);

  return (
    <div className={styles.pageWrapper}>
      <span className={styles.pageTitle}>{pageTitle}</span>
      {contents}
    </div>
  );
};

export default memo(Cash);
