/* eslint-disable import/no-unresolved */
import Decimal from 'decimal.js';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { numberExists } from 'shared-modules/utils';
import {
  BUY_SELL_MAIN,
  COUNTRY_TYPE,
  FX,
  QUANTITY_STEP,
  VALIDATION_ERROR_EMPTY_FIELD,
  VALIDATION_ERROR_AP_RULE_66,
} from '../../constants';
import {
  AMOUNT_PRECISION,
  BUILDER_ORDER_CONFIGURATION_OPTIONS as BUILDER_STORE_KEY,
  COUNTER_STEP,
  ETF_INSTRUMENT_WITH_QUANTITY_STEP_TEN,
  ETF_JPY_PRICE_STEP,
  ETF_NOT_JPY_PRICE_STEP,
  FOLLOW_STEP,
  ITEMS_COUNT_STEP,
  PROFIT_MARGIN_STEP,
  RANGE_ETF_JPY_SPREAD_STEP,
  RANGE_ETF_NOT_JPY_SPREAD_STEP,
  RANGE_SPREAD_STEP,
  STOP_LOSS_STEP,
} from '../../constants/builder';
import { getAPQuantityStep, getBuilderPriceStep, getServiceQuantityUnit } from '../index';
import {
  useBuilderInstrumentSettings,
  useTechBuilderMultiDisabledValidateOptions,
  useBuilderPricePrecision,
  useTechConfigOption,
  useBuilderMultiOption,
  useTechBuilderValidateMultiOptions,
  usePipConversion,
} from './builder';
import {
  updateTechnicalBuilderDataRequest,
  updateTechnicalBuilderNameRequest,
  resetConfigOption,
  changeConfigOption,
} from '../../redux/tech';
import { decimalRounding } from '../builder';
import { getPipsLabelWithParentheses } from '../../utils';

// TODO CFD FXかそうでないかの判定で問題ないか要確認
export const useTechConfigChangeLogic = ({ technicalBuilderId, serviceId, name, params, isOpen, onSubmitClose }) => {
  const dispatch = useDispatch();

  /**
   * ローディングフラグ
   */
  const isChartDataLoading = useSelector((state) => state.builder.chartDataIsLoading);
  const multiOrderValidationErrors = useSelector((state) => state.builder.multiOrderValidationErrors);
  const isTechConfigChangeLoading = useSelector((state) => state.tech.isLoading);

  /**
   * 単位表示。丸め用
   */
  const instrumentId = useSelector((state) => state.builder.activeCurrency);

  const isFX = serviceId === FX;
  const pipsLabelWithParentheses = getPipsLabelWithParentheses(serviceId, instrumentId);
  const quantityUnit = getServiceQuantityUnit(serviceId);
  const { quantityPrecision } = useBuilderInstrumentSettings();
  const { pricePrecision, pipsPrecision } = useBuilderPricePrecision();

  /**
   * バリデーション関係
   */
  const validationDisabledOptions = useTechBuilderMultiDisabledValidateOptions(true);
  const validateOptions = useTechBuilderValidateMultiOptions(true);
  const validationOptionsRef = useRef(null);
  validationOptionsRef.current = validateOptions;

  /**
   * 項目とチェックボックスstate
   */
  // 売買セット
  const [, setSellBuyIdRaw] = useBuilderMultiOption({
    fieldName: BUILDER_STORE_KEY.TECH.SELECTED_SELL_BUY_ID,
  });

  // 反対シグナル時決済
  const [isAllSettlementChecked, setCheckAllSettlementValue] = useTechConfigOption({
    fieldName: BUILDER_STORE_KEY.TECH.IS_ALL_SETTLEMENT_IS_CHECKED,
  });
  const [isAllSettlement, setIsAllSettlement] = useTechConfigOption({
    fieldName: BUILDER_STORE_KEY.TECH.IS_ALL_SETTLEMENT,
  });

  // レンジ幅
  const [isRangeSpreadChecked, setCheckRangeSpreadValue] = useTechConfigOption({
    fieldName: BUILDER_STORE_KEY.TECH.RANGE_SPREAD_IS_CHECKED,
  });
  const [rangeSpread, setRangeSpreadRaw] = useBuilderMultiOption({
    fieldName: BUILDER_STORE_KEY.MULTI.RANGE_SPREAD,
    precision: pipsPrecision,
  });

  // 本数
  const [isItemsCountChecked, setCheckItemsCountValue] = useTechConfigOption({
    fieldName: BUILDER_STORE_KEY.TECH.ITEMS_COUNT_IS_CHECKED,
  });
  const [itemsCount, setItemsCount] = useBuilderMultiOption({
    fieldName: BUILDER_STORE_KEY.MULTI.ITEMS_COUNT,
    precision: 0,
  });

  // 数量
  const [isAmountChecked, setCheckAmountValue] = useTechConfigOption({
    fieldName: BUILDER_STORE_KEY.TECH.AMOUNT_IS_CHECKED,
  });
  const [amount, setAmount] = useBuilderMultiOption({
    fieldName: BUILDER_STORE_KEY.MULTI.AMOUNT,
    precision: isFX ? AMOUNT_PRECISION : -Math.log10(quantityPrecision),
  });

  // 利確幅
  const [isProfitMarginChecked, setCheckProfitMarginValue] = useTechConfigOption({
    fieldName: BUILDER_STORE_KEY.TECH.PROFIT_MARGIN_IS_CHECKED,
  });
  const [profitMargin, setProfitMargin] = useBuilderMultiOption({
    fieldName: BUILDER_STORE_KEY.MULTI.PROFIT_MARGIN,
    precision: pipsPrecision,
  });

  // 損切幅
  const [isStopLossChecked, setCheckStopLossChecked] = useTechConfigOption({
    fieldName: BUILDER_STORE_KEY.TECH.STOP_LOSS_SPREAD_IS_CHECKED,
  });
  const [stopLossValue, setStopLossValue] = useBuilderMultiOption({
    fieldName: BUILDER_STORE_KEY.MULTI.STOP_LOSS_SPREAD,
    precision: pipsPrecision,
  });

  // フォロー値
  const [isFollowValueChecked, setCheckFollowValue] = useTechConfigOption({
    fieldName: BUILDER_STORE_KEY.TECH.FOLLOW_VALUE_IS_CHECKED,
  });
  const [followValue, setFollowValue] = useBuilderMultiOption({
    fieldName: BUILDER_STORE_KEY.MULTI.FOLLOW_VALUE,
    precision: pipsPrecision,
  });

  // カウンター値
  const [isCounterValueChecked, setCheckCounterValue] = useTechConfigOption({
    fieldName: BUILDER_STORE_KEY.TECH.COUNTER_VALUE_IS_CHECKED,
  });
  const [counterValue, setCounterValue] = useBuilderMultiOption({
    fieldName: BUILDER_STORE_KEY.MULTI.COUNTER_VALUE,
    precision: pipsPrecision,
  });

  // カウンター固定
  const [isCounterFixChecked, setCheckCounterFixValue] = useTechConfigOption({
    fieldName: BUILDER_STORE_KEY.TECH.COUNTER_IS_FIXED_CHECKED,
  });
  const [isCounterFixed, setCounterFixed] = useBuilderMultiOption({
    fieldName: BUILDER_STORE_KEY.TECH.COUNTER_IS_FIXED,
  });

  /**
   * 初期表示処理 変更前のテクニカルビルダー設定値を保存する
   */
  const defaultSettingsRef = useRef(null);

  useEffect(() => {
    if (isOpen && technicalBuilderId) {
      defaultSettingsRef.current = params;
    }

    if (!isOpen) {
      defaultSettingsRef.current = null;
      dispatch(resetConfigOption());
    }
  }, [dispatch, params, isOpen, technicalBuilderId]);

  const [init, setInit] = useState(true);
  /**
   * 初期表示処理 各項目のsetterを用いて受け取ったparametersを代入
   */
  useEffect(() => {
    if (isOpen && init) {
      setSellBuyIdRaw(Number(params?.buySellSignType));
      setIsAllSettlement(params?.isAutoSettle);
      setRangeSpreadRaw(params?.priceRange);
      setItemsCount(params?.orderNums);
      setAmount(params?.quantity);
      setProfitMargin(params?.tp);
      setStopLossValue(numberExists(params?.sl) ? params?.sl : ''); // InputNumberがnullの時エラーになる
      setFollowValue(numberExists(params?.follow) ? params?.follow : ''); // InputNumberがnullの時エラーになる
      setCounterValue(numberExists(params?.counter) ? params?.counter : ''); // InputNumberがnullの時エラーになる
      setCounterFixed(params?.fixedCounterPrice);
      setInit(false);
      // レンジ幅未チェック時の利確幅バリデーションに利用するためレンジ幅の初期値をストアに保存
      dispatch(changeConfigOption({ key: BUILDER_STORE_KEY.MULTI.RANGE_SPREAD, value: params?.priceRange }));
    }

    if (!isOpen && !init) {
      setInit(true);
    }
  }, [
    dispatch,
    setSellBuyIdRaw,
    setIsAllSettlement,
    setRangeSpreadRaw,
    setItemsCount,
    setAmount,
    setProfitMargin,
    setStopLossValue,
    setFollowValue,
    setCounterValue,
    setCounterFixed,
    technicalBuilderId,
    init,
    isOpen,
    params,
  ]);

  /**
   * 利確幅のバリデにレンジ幅が使用されるのと、本Logic内でvalidationを定義すると
   * １度の変更で２度バリデが実行されるため、項目かチェック状態が変更されたら全てのバリデをまとめて実行する
   */
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        validationOptionsRef.current();
      });
    }
  }, [
    isOpen,
    isRangeSpreadChecked,
    rangeSpread,
    isItemsCountChecked,
    itemsCount,
    isAmountChecked,
    amount,
    isProfitMarginChecked,
    profitMargin,
    isStopLossChecked,
    stopLossValue,
    isFollowValueChecked,
    followValue,
    isCounterValueChecked,
    counterValue,
  ]);

  // TODO CFD FXかそうでないかの判定で問題ないか要確認(また、定数名をどうするか)
  /**
   * 項目毎のstep定義
   */
  const rangeStep = useMemo(() => {
    if (isFX) {
      return RANGE_SPREAD_STEP;
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return RANGE_ETF_JPY_SPREAD_STEP;
    }
    return RANGE_ETF_NOT_JPY_SPREAD_STEP;
  }, [isFX, instrumentId]);

  // TODO CFD FXかそうでないかの判定で問題ないか要確認
  const amountStep = useMemo(() => {
    if (isFX) {
      return getAPQuantityStep(instrumentId);
    }
    if (instrumentId === ETF_INSTRUMENT_WITH_QUANTITY_STEP_TEN) {
      return QUANTITY_STEP.TEN;
    }
    return QUANTITY_STEP.ONE;
  }, [isFX, instrumentId]);

  // TODO CFD FXかそうでないかの判定で問題ないか要確認(また、定数名をどうするか)
  const priceStep = useMemo(() => {
    if (isFX) {
      return getBuilderPriceStep(instrumentId);
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return ETF_JPY_PRICE_STEP;
    }
    return ETF_NOT_JPY_PRICE_STEP;
  }, [isFX, instrumentId]);

  const pipConversion = usePipConversion(instrumentId);

  /**
   * 変更確定用関数
   * エントリー価格とカウンター固定価格の計算
   */
  const submit = useCallback(() => {
    const useRangeSpread = isRangeSpreadChecked ? rangeSpread : params?.priceRange;
    const useProfitMargin = isProfitMarginChecked ? profitMargin : params?.tp;
    const useCounterValue = isCounterValueChecked ? counterValue : params?.counter;

    const getOrderSpace = () => {
      const useItemsCount = isItemsCountChecked ? itemsCount : params?.orderNums;

      if (!Number(useRangeSpread) || !Number(useItemsCount)) return 0;
      return decimalRounding(Decimal.div(useRangeSpread, useItemsCount), pipsPrecision);
    };

    const orderSpace = getOrderSpace();
    const isBuy = Number(params?.buySellSignType) === BUY_SELL_MAIN.BUY.ID;
    const entryPricePipsValue = decimalRounding(Decimal.div(useRangeSpread, 2), pipsPrecision);
    const entryPriceRelative = Number(Decimal.mul(entryPricePipsValue, pipConversion));

    const orderSpaceConversion = Number(Decimal.mul(orderSpace, pipConversion));
    const profitMarginConversion = Number(Decimal.mul(useProfitMargin, pipConversion));
    const entryPrice1 = isBuy
      ? decimalRounding(Decimal.sub(entryPriceRelative, orderSpaceConversion), pricePrecision)
      : decimalRounding(Decimal.sub(0, Decimal.sub(entryPriceRelative, orderSpaceConversion)), pricePrecision);

    let counterPrice = '';
    if (useCounterValue) {
      const counterValueConversion = Number(Decimal.mul(useCounterValue, pipConversion));
      counterPrice = isBuy
        ? decimalRounding(Decimal.add(entryPrice1, counterValueConversion).add(profitMarginConversion), pricePrecision)
        : decimalRounding(Decimal.add(entryPrice1, counterValueConversion).sub(profitMarginConversion), pricePrecision);
    }

    dispatch(
      updateTechnicalBuilderDataRequest({
        technicalBuilderId,
        serviceId,
        name,
        entryPrice1,
        counterPrice,
        instrumentId,
        defaultParams: {
          ...defaultSettingsRef?.current,
        },
        callback: onSubmitClose,
      }),
    );
  }, [
    dispatch,
    technicalBuilderId,
    serviceId,
    name,
    params,
    isRangeSpreadChecked,
    rangeSpread,
    isProfitMarginChecked,
    profitMargin,
    isItemsCountChecked,
    itemsCount,
    isCounterValueChecked,
    counterValue,
    pricePrecision,
    pipsPrecision,
    pipConversion,
    onSubmitClose,
    instrumentId,
  ]);

  const hasCounterValue = useMemo(
    () => (!isCounterValueChecked && params?.counter) || (isCounterValueChecked && counterValue),
    [counterValue, isCounterValueChecked, params?.counter],
  );

  const hasFollowValue = useMemo(
    () => (!isFollowValueChecked && params?.follow) || (isFollowValueChecked && followValue),
    [followValue, isFollowValueChecked, params?.follow],
  );

  // エラーメッセージ定義
  const errorMessages = useMemo(() => {
    if (isChartDataLoading) return [];
    return Object.entries(multiOrderValidationErrors).reduce((messages, [inputName, info]) => {
      if (info.hasValidationError && !validationDisabledOptions.includes(inputName)) {
        if (inputName === BUILDER_STORE_KEY.TECH.COUNTER_VALUE && info.errorMessage === VALIDATION_ERROR_EMPTY_FIELD) {
          if (!hasFollowValue) {
            messages.push({ inputName, errorMessage: VALIDATION_ERROR_AP_RULE_66 });
          }
        } else if (
          inputName === BUILDER_STORE_KEY.TECH.FOLLOW_VALUE &&
          info.errorMessage === VALIDATION_ERROR_EMPTY_FIELD
        ) {
          if (!hasCounterValue) {
            messages.push({ inputName, errorMessage: VALIDATION_ERROR_AP_RULE_66 });
          }
        } else if (
          !(inputName === BUILDER_STORE_KEY.TECH.STOP_LOSS_SPREAD && info.errorMessage === VALIDATION_ERROR_EMPTY_FIELD)
        ) {
          messages.push({ inputName, errorMessage: info.errorMessage });
        }
      }
      return messages;
    }, []);
  }, [isChartDataLoading, multiOrderValidationErrors, validationDisabledOptions, hasFollowValue, hasCounterValue]);

  // 項目にチェックがついてるものを検索する
  const config = useSelector((state) => state.tech.config);
  const isChecked = useMemo(
    () =>
      Object.keys(config)
        ?.filter((c) => /.*Checked/.test(c))
        ?.map((m) => config[m])
        ?.some((s) => s),
    [config],
  );

  return {
    errorMessages,
    button: {
      submit,
      isDisabled: Boolean(errorMessages.length) || isTechConfigChangeLoading || !isChecked,
    },
    isAllSettlement: {
      label: '反対シグナルが出たときに決済するか',
      check: isAllSettlementChecked,
      checkSet: setCheckAllSettlementValue,
      get: isAllSettlement,
      set: setIsAllSettlement,
      isDisabled: !isAllSettlementChecked || isChartDataLoading,
    },
    rangeSpread: {
      name: BUILDER_STORE_KEY.MULTI.RANGE_SPREAD,
      label: `レンジ幅${pipsLabelWithParentheses}`,
      check: isRangeSpreadChecked,
      checkSet: setCheckRangeSpreadValue,
      get: rangeSpread,
      set: setRangeSpreadRaw,
      isDisabled: !isRangeSpreadChecked || isChartDataLoading,
      step: rangeStep,
    },
    itemsCount: {
      name: BUILDER_STORE_KEY.MULTI.ITEMS_COUNT,
      label: '本数',
      check: isItemsCountChecked,
      checkSet: setCheckItemsCountValue,
      get: itemsCount,
      set: setItemsCount,
      isDisabled: !isItemsCountChecked || isChartDataLoading,
      step: ITEMS_COUNT_STEP,
    },
    quantity: {
      name: BUILDER_STORE_KEY.MULTI.AMOUNT,
      label: `数量(${quantityUnit})`,
      check: isAmountChecked,
      checkSet: setCheckAmountValue,
      get: amount,
      set: setAmount,
      isDisabled: !isAmountChecked || isChartDataLoading,
      step: amountStep,
    },
    profitMargin: {
      name: BUILDER_STORE_KEY.MULTI.PROFIT_MARGIN,
      label: `利確幅${pipsLabelWithParentheses}`,
      check: isProfitMarginChecked,
      checkSet: setCheckProfitMarginValue,
      get: profitMargin,
      set: setProfitMargin,
      isDisabled: !isProfitMarginChecked || isChartDataLoading,
      step: isFX ? PROFIT_MARGIN_STEP : priceStep,
    },
    stopLoss: {
      name: BUILDER_STORE_KEY.MULTI.STOP_LOSS_SPREAD,
      label: `損切幅${pipsLabelWithParentheses}`,
      check: isStopLossChecked,
      checkSet: setCheckStopLossChecked,
      get: stopLossValue,
      set: setStopLossValue,
      isDisabled: !isStopLossChecked || isChartDataLoading,
      step: isFX ? STOP_LOSS_STEP : priceStep,
      min: null,
    },
    follow: {
      name: BUILDER_STORE_KEY.MULTI.FOLLOW_VALUE,
      label: `フォロー値${pipsLabelWithParentheses}`,
      check: isFollowValueChecked,
      checkSet: setCheckFollowValue,
      get: followValue,
      set: setFollowValue,
      isDisabled: !isFollowValueChecked || isChartDataLoading,
      step: isFX ? FOLLOW_STEP : priceStep,
      min: null,
    },
    counter: {
      name: BUILDER_STORE_KEY.MULTI.COUNTER_VALUE,
      label: `カウンター値${pipsLabelWithParentheses}`,
      check: isCounterValueChecked,
      checkSet: setCheckCounterValue,
      get: counterValue,
      set: setCounterValue,
      isDisabled: !isCounterValueChecked || isChartDataLoading,
      step: isFX ? COUNTER_STEP : priceStep,
      min: null,
    },
    counterFixed: {
      label: 'カウンター固定',
      check: isCounterFixChecked,
      checkSet: setCheckCounterFixValue,
      get: isCounterFixed,
      set: setCounterFixed,
      isDisabled: !isCounterFixChecked || isChartDataLoading,
    },
  };
};

export const useTechNameSubmitLogic = ({ technicalBuilderId, serviceId, onClose }) => {
  const dispatch = useDispatch();

  /**
   * 変更確定関数
   */
  const submitSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  /**
   * 変更実行用関数
   */
  const submit = useCallback(
    (newName) => {
      dispatch(
        updateTechnicalBuilderNameRequest({
          technicalBuilderId,
          serviceId,
          name: newName,
          callback: submitSuccess,
        }),
      );
    },
    [dispatch, technicalBuilderId, serviceId, submitSuccess],
  );

  return submit;
};
