import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { reportTypesSelectorWidth } from 'shared-modules/constants';
import { useReportsLogic, useGetReportFile } from 'shared-modules/services/hooks';
import CustomSelect from '../../../../../../components/CustomSelect';
import { useKeyPressHandler } from '../../../../../../services/hooks';
import { Spin, Tabs } from '../../../../../../components';
import ReportsSettings from '../ReportsDetailsSettings';
import styles from './reportsDetailsPanel.module.scss';

const ReportFile = memo(({ file, serviceId }) => {
  const { getReportFile, isLoading, loadingId } = useGetReportFile({ file, serviceId });

  const getReport = useCallback(
    (e) => {
      e.currentTarget.blur();
      getReportFile();
    },
    [getReportFile],
  );
  const getReportKeyboard = useKeyPressHandler(getReport);

  return (
    <div role="button" tabIndex={0} className={styles.resultsItem} onClick={getReport} onKeyPress={getReportKeyboard}>
      <div>{file.name}</div>

      {!isLoading && <i className={classNames('material-icons', styles.downloadIcon)}>get_app</i>}

      {isLoading && file.id === loadingId && <Spin className={styles.fileLoader} />}
    </div>
  );
});

ReportFile.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  serviceId: PropTypes.string.isRequired,
};

const ReportsDetailsPanel = ({ shouldResetToInitialState }) => {
  const {
    tabs,
    booleanTypes,
    reportSelector,
    executionWarningText,
    list,
    fileData,
    serviceId,
    searchReports,
    areResultsShown,
  } = useReportsLogic({ shouldResetToInitialState });
  const { file, isFileLoading, fileName } = fileData;

  const downloadLink = useMemo(() => document.createElement('a'), []);

  useEffect(() => {
    if (file.report && !isFileLoading) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Microsoft Edge
        const byteCharacters = atob(file.report);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        downloadLink.href = `data:text/plain;base64,${file.report}`;
        downloadLink.download = fileName;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
      }
    }
  }, [downloadLink, file.report, fileName, isFileLoading]);

  const tabItem = useMemo(
    () => (
      <div className={styles.tabContainer}>
        <div
          className={classNames(styles.settingsWrapper, {
            [styles.isOnNextRow]: booleanTypes.isMonthly || booleanTypes.isQuarterly,
          })}
        >
          <CustomSelect
            isLighter
            options={reportSelector.options}
            selectItemId={reportSelector.get}
            onChange={reportSelector.set}
            isDisabled={reportSelector.isDisabled}
            width={reportTypesSelectorWidth}
          />

          <ReportsSettings
            selectedReportType={reportSelector.get}
            searchReports={searchReports}
            booleanTypes={booleanTypes}
            serviceId={serviceId}
          />
        </div>

        {booleanTypes.isExecutionHistory && (
          <div className={styles.warningText}>
            {executionWarningText.partOne}
            <br />
            {executionWarningText.partTwo}
          </div>
        )}

        <div className={styles.results}>
          {list.isLoading && <Spin className={styles.loader} />}

          {areResultsShown && !list.isEmpty && (
            <>
              {list.data.map((fileInfo) => (
                <ReportFile file={fileInfo} key={fileInfo.id} serviceId={serviceId} />
              ))}
            </>
          )}

          {areResultsShown && list.isEmpty && <div className={styles.emptyResultsText}>{list.emptyMessage}</div>}
        </div>
      </div>
    ),
    [booleanTypes, reportSelector, executionWarningText, list, serviceId, searchReports, areResultsShown],
  );

  const tabItems = useMemo(() => {
    return tabs.options.map((option) => ({ ...option, children: tabItem }));
  }, [tabs.options, tabItem]);

  return (
    <div className={styles.wrapper}>
      <Tabs containerClassName={styles.tabs} items={tabItems} activeKey={tabs.get} onChange={tabs.set} />
    </div>
  );
};

ReportsDetailsPanel.propTypes = {
  shouldResetToInitialState: PropTypes.bool.isRequired,
};

export default memo(ReportsDetailsPanel);
