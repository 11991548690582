import { get, post, invokeApi, createAdditionalParams } from './index';

export const getUnreadCount = async ({ channel }) => {
  const additionalParams = await createAdditionalParams();
  const url = `messages/unread_count?channel=${channel}`;
  return invokeApi({}, url, get, additionalParams);
};

export const getMessages = async ({ isIndividual, channel }) => {
  const additionalParams = await createAdditionalParams();
  const url = `messages?channel=${channel}&isIndividual=${isIndividual}`;
  return invokeApi({}, url, get, additionalParams);
};

export const markAsRead = async ({ messageId, requestBody }) => {
  const additionalParams = await createAdditionalParams();
  const defaultRequestBody = { isOpenModal: false };
  const url = `messages/${messageId}`;
  return invokeApi({}, url, post, additionalParams, requestBody ?? defaultRequestBody);
};

export const checkInitialDeposit = async () => {
  const additionalParams = await createAdditionalParams();
  const url = `messages/check_initial_deposit`;
  return invokeApi({}, url, post, additionalParams);
};

export const getRangeOuts = async () => {
  const additionalParams = await createAdditionalParams();
  const url = `messages/range_outs`;
  return invokeApi({}, url, get, additionalParams);
};
