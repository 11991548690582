import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useCheckOnPositionIsBeingDeleted, useGetStillDeletingPositions } from 'shared-modules/services/hooks';
import {
  openConfirmationModal,
  deletePositionsOrderById,
  saveDeletingPositions,
} from '../../../../../../redux/actions';
import CustomButton from '../../../../../../components/CustomButton';

const PositionQuickCloseButton = ({ positionId, apGroupId, instrumentId }) => {
  const dispatch = useDispatch();

  const checkOnPositionIsBeingDeleted = useCheckOnPositionIsBeingDeleted();
  const getStillDeletingPositions = useGetStillDeletingPositions();

  const closeOrder = useCallback(() => {
    const payload = {
      orderId: positionId,
      apGroupId,
      instrumentId,
      callback: () => {
        const stillDeletingPositions = getStillDeletingPositions();

        // save position IDs with timestamps in order to prevent clicking its checkboxes for two minutes
        const positions = [{ positionId, deletionStartedAt: Date.now() }, ...stillDeletingPositions];

        dispatch(saveDeletingPositions({ positions }));
      },
    };

    if (instrumentId) payload.instrumentId = instrumentId;

    dispatch(deletePositionsOrderById(payload));
  }, [positionId, apGroupId, instrumentId, dispatch, getStillDeletingPositions]);

  const handleClick = useCallback(() => {
    checkOnPositionIsBeingDeleted({
      positionId,
      successCallback: () => {
        dispatch(
          openConfirmationModal({
            title: 'クイック注文決済',
            bodyText: '注文中の決済注文を取り消し、全建玉数量の成行決済注文を発注いたします。よろしいでしょうか？',
            callback: closeOrder,
            buttonBackText: '戻る',
            buttonNextText: '決済',
            isOverlap: true,
          }),
        );
      },
    });
  }, [checkOnPositionIsBeingDeleted, positionId, dispatch, closeOrder]);

  return (
    <CustomButton isSmall onClick={handleClick}>
      クイック
    </CustomButton>
  );
};

PositionQuickCloseButton.propTypes = {
  positionId: PropTypes.string.isRequired,
  apGroupId: PropTypes.string,
  instrumentId: PropTypes.string,
};

PositionQuickCloseButton.defaultProps = {
  instrumentId: null,
  apGroupId: null,
};

export default memo(PositionQuickCloseButton);
