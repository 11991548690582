import { RESET, SET_STATUS, SET_READY, DONE_SUCCESS, DONE_REQUEST } from '../actionConstants/sequentialModals';

export const resetSequentialModals = () => ({ type: RESET });
export const doneRequestSequentialModal = ({ modalId, delay }) => ({ type: DONE_REQUEST, payload: { modalId, delay } });
export const doneSuccessSequentialModal = ({ modalId, toNotReady }) => ({
  type: DONE_SUCCESS,
  payload: { modalId, toNotReady },
});
export const setReadySequentialModal = ({ ready = true }) => ({ type: SET_READY, payload: { ready } });
export const setStatusSequentialModal = ({ status }) => ({ type: SET_STATUS, payload: { status } });
