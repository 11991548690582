import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { YOUTUBE_REG } from 'shared-modules/constants';
import { useAccountInfo } from 'shared-modules/hooks';
import {
  LOSSCUT_MODAL_MESSAGE,
  LOSSCUT_MODAL_TITLE,
  MAINTENANCE_ERROR_MESSAGE_WEB,
  MAINTENANCE_MODAL_TITLE,
  NOT_EXIST_ERROR_MESSAGES_WEB,
  NOT_EXIST_TITLES,
} from 'shared-modules/constants/errorMesages';
import { MAINTENANCE_INFOPAGE_URL, MY_PAGE_HOME_URL } from 'shared-modules/config';
import { openYoutubeModal } from './redux/actions';
import {
  AppCssVariablesContext,
  AppHeaderHeightContext,
  SubWindowManagementContext,
  TableRefContext,
} from './contexts';
import { ErrorText } from './components/ErrorText';

export const useAppHeaderHeight = () => {
  const { headerHeight } = useContext(AppHeaderHeightContext);
  return headerHeight;
};

export const useCssVariables = () => {
  const { variables } = useContext(AppCssVariablesContext);
  return variables;
};

export const useValueToColor = () => {
  const variables = useCssVariables();

  const getNumericalValueColor = useCallback(
    (value) => {
      const num = Number(value ?? 0);
      if (num === 0 || !Number.isFinite(num)) {
        return variables['--color-text-normal'];
      }
      if (num > 0) {
        return variables['--color-text-plus'];
      }
      return variables['--color-text-minus'];
    },
    [variables],
  );

  const getRankColor = useCallback(
    (value) => {
      const num = Number(value ?? 0);
      if (!Number.isFinite(num)) {
        return variables['--color-text-normal'];
      }
      switch (num) {
        case 0:
          return variables['--color-text-gray'];
        case 1:
          return variables['--color-text-plus'];
        case 2:
          return variables['--color-text-plus'];
        case 3:
          return variables['--color-text-normal'];
        case 4:
          return variables['--color-text-minus'];
        default:
          return variables['--color-text-minus'];
      }
    },
    [variables],
  );

  return { getNumericalValueColor, getRankColor };
};

export const useOpenLink = (url, name) => {
  const dispatch = useDispatch();
  const openLink = useCallback(() => {
    if (!url || !name) {
      return;
    }
    if (YOUTUBE_REG.test(url)) {
      dispatch(openYoutubeModal({ url }));
      return;
    }
    window.open(url, name);
  }, [dispatch, url, name]);
  return openLink;
};

// @deprecated
export const useSmallHTMLFontSize = () => {
  useEffect(() => {
    const htmlFontSize = document.documentElement.style.fontSize;
    document.documentElement.style.fontSize = '62.5%';

    return () => {
      document.documentElement.style.fontSize = htmlFontSize;
    };
  }, []);
};

export const useTableRef = () => {
  return useContext(TableRefContext);
};

export const useScrollOrigin = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    root?.scrollTo?.(0, 0);
  }, []);
};

export const useErrorText = (serviceId) => {
  const accountInfo = useAccountInfo();
  if (!serviceId) {
    return null;
  }
  const { notExist, isMaintenance, isLossCut } = accountInfo[serviceId];
  if (notExist) {
    const message = NOT_EXIST_ERROR_MESSAGES_WEB;
    return <ErrorText title={NOT_EXIST_TITLES[serviceId]} message={message[serviceId]} linkURL={MY_PAGE_HOME_URL} />;
  }
  if (isMaintenance) {
    const message = MAINTENANCE_ERROR_MESSAGE_WEB;
    return <ErrorText title={MAINTENANCE_MODAL_TITLE} message={message} linkURL={MAINTENANCE_INFOPAGE_URL} />;
  }
  if (isLossCut) {
    const message = LOSSCUT_MODAL_MESSAGE;
    return <ErrorText title={LOSSCUT_MODAL_TITLE} message={message} />;
  }
  return null;
};

export const useAddSubWindow = () => {
  const { addSubWindow } = useContext(SubWindowManagementContext);
  return addSubWindow;
};

export const useCloseAllSubWindows = () => {
  const { closeAllSubWindows } = useContext(SubWindowManagementContext);
  return closeAllSubWindows;
};
