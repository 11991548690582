import { uniqBy } from 'lodash';
import { START_UP_GUIDE_PRIORITY_LEVEL } from '../../constants';
import {
  GET_CATEGORY_LIST_START_LOADING,
  GET_CATEGORY_LIST_END_LOADING,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CONTENTS_LIST_SUCCESS,
  SET_SELECTED_CATEGORY_ID,
  DELETE_SELECTED_CATEGORY_ID,
  DELETE_PAGE_INFO,
} from '../actionConstants/guideConstants';

export const initialState = {
  pageInfo: {
    pageSize: 0,
    pageNumber: 1,
    totalSize: 0,
    totalPages: 1,
  },
  categoryList: [
    {
      categoryId: null,
      categoryName: '',
      priorityLevel: null,
      description: '',
      items: [
        {
          itemId: null,
          type: '',
          thumbnailUrl: '',
          linkUrl: '',
          publishedTime: '',
          title: '',
          serviceId: '',
          customerAttributes: [],
        },
      ],
    },
  ],
  startUpGuide: [
    {
      categoryId: null,
      categoryName: '',
      priorityLevel: null,
      description: '',
      items: [
        {
          itemId: null,
          type: '',
          thumbnailUrl: '',
          linkUrl: '',
          publishedTime: '',
          title: '',
          serviceId: '',
          customerAttributes: [],
        },
      ],
    },
  ],
  isLoading: false,
  selectedCategoryId: null,
};

// todo: probably merge with error reducer when start refactoring
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CATEGORY_LIST_START_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_CATEGORY_LIST_END_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_CATEGORY_LIST_SUCCESS: {
      const { categoryList } = payload;

      const shapeCategoryList = categoryList
        .filter(({ priorityLevel }) => priorityLevel !== START_UP_GUIDE_PRIORITY_LEVEL)
        .map((category) => {
          return { ...category, items: category.items.slice(0, 15) };
        });

      const startUpGuide =
        categoryList.find(({ priorityLevel }) => priorityLevel === START_UP_GUIDE_PRIORITY_LEVEL) ?? {};

      return {
        ...state,
        categoryList: shapeCategoryList,
        startUpGuide,
      };
    }
    case GET_CONTENTS_LIST_SUCCESS: {
      const { pageInfo, category, isFirstData } = payload;

      const index = state.categoryList.findIndex((i) => i.categoryId === category.categoryId);

      // 最初のページでないなら既存の記事リスト(items)にレスポンスのitemsを結合
      if (!isFirstData) {
        // itemIdが重複しているitems要素を削除
        category.items = uniqBy([...state.categoryList[index]?.items, ...category.items], 'itemId');
      }

      const categoryList = [...state.categoryList];
      categoryList.splice(index, 1, category);

      return {
        ...state,
        pageInfo,
        categoryList,
      };
    }
    case SET_SELECTED_CATEGORY_ID: {
      return {
        ...state,
        selectedCategoryId: payload.categoryId,
      };
    }
    case DELETE_SELECTED_CATEGORY_ID: {
      return {
        ...state,
        selectedCategoryId: null,
      };
    }
    case DELETE_PAGE_INFO: {
      return {
        ...state,
        pageInfo: initialState.pageInfo,
      };
    }
    default: {
      return state;
    }
  }
};
