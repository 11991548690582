import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import PropTypes from 'prop-types';
import { useChangeService } from 'shared-modules/services/hooks';
import {
  TRADE_INFO_TABLES,
  CLOSE_POSITIONS_MAX_AMOUNT,
  SWAP_TRANSFER_LABEL,
  SWAP_LABEL_MAP,
} from 'shared-modules/constants';
import {
  SORT_DESCENDING,
  DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
  DEFAULT_POSITIONS_TABLE_SORT_BY,
  DEFAULT_POSITIONS_TABLE_SORT_DIR,
  DEFAULT_ORDERS_TABLE_SORT_BY,
  DEFAULT_ORDERS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES,
} from 'shared-modules/constants/manualTrade';
import {
  changeExecutionsTableMetaInfo,
  changeSelectedTableInInfoTable,
  getExecutionsTableDataRequest,
  getOrdersTableDataRequest,
  resetExecutionsTableMetaInfo,
  resetOrderTableMetaInfo,
  resetPositionTableMetaInfo,
  swapTransferRequest,
} from 'shared-modules/redux/actions/manualTradeActions';
import { removeKeyFromLocalStorage, saveJsonStyleDefaultValuesToLocalStorage } from 'shared-modules/services';
import { SWAP_TRANSFER_POSITIONS_VALIDATION_MESSAGE } from 'shared-modules/constants/errorMesages';
import { openConfirmationModal, openErrorInfoModal } from '../../redux/actions';
import { Button, ItemButtons, LinkButton, Tabs } from '../../components';
import TablePositionSummary from './components/TablePositionSummary';
import { OrderHistory } from './components/TableOrderHistory';
import TableExecutions from '../../components/TableExecutions';
import TablePositions from '../../components/TablePositions/TablePositions';
import TableOrders from '../../components/TableOrders';
import DeletePositionsButton from '../../components/TablePositions/components/DeletePositionsButton/DeletePositionsButton';
import CloseMultiSummaryPositionsButton from './components/TablePositionSummary/CloseMultiSummaryPositionsButton';
import { useTableRef } from '../../hooks';
import styles from './tradeInfo.module.scss';
import { DATEPICKER_WIDTH } from '../ManualTrade/constants';
import CustomDatePicker from '../../components/CustomDatePicker';

const OPTIONS = [
  {
    id: TRADE_INFO_TABLES.EXECUTIONS.ID,
    value: '約定照会',
    className: styles.itemButton,
  },
  {
    id: TRADE_INFO_TABLES.POSITIONS_DETAILS.ID,
    value: '建玉照会',
    className: styles.itemButton,
  },
  {
    id: TRADE_INFO_TABLES.POSITIONS_SUMMARY.ID,
    value: '建玉サマリー',
    className: styles.itemButton,
  },
  {
    id: TRADE_INFO_TABLES.ORDERS.ID,
    value: '注文照会',
    className: styles.itemButton,
  },
  {
    id: TRADE_INFO_TABLES.ORDER_HISTORY.ID,
    value: '稼働履歴',
    className: styles.itemButton,
  },
];

const TabPane = memo(({ setDisabledTabs }) => {
  const dispatch = useDispatch();
  const serviceId = useSelector((state) => state.auth.serviceId);
  const positionsDataMetaInfo = useSelector((state) => state.manualTrade.positionsDataMetaInfo[serviceId]);
  const selectedPositions = useSelector((state) => state.manualTrade.selectedTableRows);
  const selectedInfoTable = useSelector((state) => state.manualTrade.selectedInfoTable);
  const isNonePositionsSelected = selectedPositions.length === 0;
  const [activeTab, setActiveTab] = useState(selectedInfoTable);
  const [buttonStr, setButtonStr] = useState();
  const [transferWidth, setTransferWidth] = useState(120);
  const [localStorageKey, setLocalStorageKey] = useState();
  const [sortByKey, setSortByKey] = useState();
  const [sortDirKey, setSortDirKey] = useState();

  const tableRef = useTableRef();

  const handleChange = useCallback(
    (newActiveId) => {
      setActiveTab(newActiveId);
      dispatch(changeSelectedTableInInfoTable({ tableId: newActiveId }));
    },
    [dispatch],
  );

  useEffect(() => {
    setButtonStr(SWAP_LABEL_MAP[serviceId]);
    setTransferWidth(SWAP_TRANSFER_LABEL[serviceId].width);

    switch (activeTab) {
      case TRADE_INFO_TABLES.EXECUTIONS.ID:
        setLocalStorageKey(KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[serviceId]);
        setSortByKey(KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY[serviceId]);
        setSortDirKey(KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR[serviceId]);
        break;
      case TRADE_INFO_TABLES.POSITIONS_DETAILS.ID:
        setLocalStorageKey(KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[serviceId]);
        setSortByKey(KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY[serviceId]);
        setSortDirKey(KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR[serviceId]);
        break;
      case TRADE_INFO_TABLES.ORDERS.ID:
        setLocalStorageKey(KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[serviceId]);
        setSortByKey(KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY[serviceId]);
        setSortDirKey(KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR[serviceId]);
        break;

      default:
        break;
    }
  }, [activeTab, serviceId]);

  const filterClear = useCallback(() => {
    removeKeyFromLocalStorage({ key: localStorageKey });
    removeKeyFromLocalStorage({ key: sortByKey });
    removeKeyFromLocalStorage({ key: sortDirKey });

    switch (activeTab) {
      case TRADE_INFO_TABLES.EXECUTIONS.ID:
        batch(() => {
          dispatch(resetExecutionsTableMetaInfo({ serviceId }));
          dispatch(getExecutionsTableDataRequest({ isFirstData: true }));
          tableRef.current?.setSortBy([
            { id: DEFAULT_EXECUTIONS_TABLE_SORT_BY, desc: DEFAULT_EXECUTIONS_TABLE_SORT_DIR === SORT_DESCENDING },
          ]);
        });
        break;
      case TRADE_INFO_TABLES.POSITIONS_DETAILS.ID:
        dispatch(resetPositionTableMetaInfo({ serviceId }));
        tableRef.current?.setSortBy([
          { id: DEFAULT_POSITIONS_TABLE_SORT_BY, desc: DEFAULT_POSITIONS_TABLE_SORT_DIR === SORT_DESCENDING },
        ]);
        break;
      case TRADE_INFO_TABLES.ORDERS.ID:
        removeKeyFromLocalStorage({
          key: KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED[serviceId],
        });
        batch(() => {
          dispatch(resetOrderTableMetaInfo({ serviceId }));
          dispatch(getOrdersTableDataRequest({ isFirstData: true }));
          tableRef.current?.setSortBy([
            { id: DEFAULT_ORDERS_TABLE_SORT_BY, desc: DEFAULT_ORDERS_TABLE_SORT_DIR === SORT_DESCENDING },
          ]);
        });
        break;

      default:
        break;
    }
  }, [dispatch, serviceId, tableRef, localStorageKey, sortByKey, sortDirKey, activeTab]);

  const handleMountOrderHistory = useCallback(() => {
    setDisabledTabs(true);
  }, [setDisabledTabs]);

  const handleUnmountOrderHistory = useCallback(() => {
    setDisabledTabs(false);
  }, [setDisabledTabs]);

  const tableDataRaw = useSelector((state) => state.currencies.positions[serviceId]);
  const target = tableDataRaw
    .map((el) => ({
      positionId: el.positionId,
      unrealizedSwapPl: el.unrealizedSwapPl,
    }))
    .filter((e) => selectedPositions.flatMap((ew) => ew.positionId).includes(e.positionId));
  const filteredTargetPositions = target.filter((filteredPositions) => {
    return filteredPositions.unrealizedSwapPl !== 0;
  });
  const selectedPositionsAmount = filteredTargetPositions.length;
  const isMoreThanMax = selectedPositionsAmount > CLOSE_POSITIONS_MAX_AMOUNT;

  const multiTarget = tableDataRaw
    .map((el) => ({
      positionId: el.positionId,
      unrealizedSwapPl: el.unrealizedSwapPl,
    }))
    .filter((e) => selectedPositions.flatMap((ew) => ew.positionIds).includes(e.positionId));
  const filteredMultiTargetPositions = multiTarget.filter((filteredPositions) => {
    return filteredPositions.unrealizedSwapPl !== 0;
  });
  const selectedMultiPositionsAmount = filteredMultiTargetPositions.length;
  const isMoreThanMaxMultiPosition = selectedMultiPositionsAmount > CLOSE_POSITIONS_MAX_AMOUNT;

  const swapTransfer = useCallback(() => {
    dispatch(
      swapTransferRequest({
        positionIds: filteredTargetPositions.map((el) => el.positionId),
      }),
    );
  }, [dispatch, filteredTargetPositions]);

  const totalSwapTransfer = useCallback(() => {
    dispatch(
      swapTransferRequest({
        positionIds: filteredMultiTargetPositions.map((el) => el.positionId),
      }),
    );
  }, [dispatch, filteredMultiTargetPositions]);

  const openSwapTransferModal = useCallback(() => {
    if (isMoreThanMax) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: SWAP_TRANSFER_POSITIONS_VALIDATION_MESSAGE,
        }),
      );
    } else if (filteredTargetPositions.length === 0) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: `選択した建玉に対して未実現${buttonStr}振替処理は行えません。`,
        }),
      );
    } else {
      dispatch(
        openConfirmationModal({
          title: `一括${buttonStr}振替`,
          bodyText: `建玉に紐づく未実現${buttonStr}を全て実現します。よろしいですか？`,
          callback: swapTransfer,
          buttonBackText: '戻る',
          buttonNextText: '確定',
          isOverlap: true,
        }),
      );
    }
  }, [buttonStr, dispatch, filteredTargetPositions.length, isMoreThanMax, swapTransfer]);

  const openSwapTransferSummaryModal = useCallback(() => {
    if (isMoreThanMaxMultiPosition) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: SWAP_TRANSFER_POSITIONS_VALIDATION_MESSAGE,
        }),
      );
    } else if (filteredMultiTargetPositions.length === 0) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: `選択した建玉に対して未実現${buttonStr}振替処理は行えません。`,
        }),
      );
    } else {
      dispatch(
        openConfirmationModal({
          title: `一括${buttonStr}振替`,
          bodyText: `建玉に紐づく未実現${buttonStr}を全て実現します。よろしいですか？`,
          callback: totalSwapTransfer,
          buttonBackText: '戻る',
          buttonNextText: '確定',
          isOverlap: true,
        }),
      );
    }
  }, [buttonStr, dispatch, filteredMultiTargetPositions.length, isMoreThanMaxMultiPosition, totalSwapTransfer]);

  const { fromDate: fromDateInfo, toDate: toDateInfo } = useSelector(
    (state) => state.manualTrade.executionsDataMetaInfo[serviceId],
  );

  const filterOptions = useMemo(() => {
    return {
      fromDateInfo,
      toDateInfo,
      maxDate: new Date(),
    };
  }, [fromDateInfo, toDateInfo]);

  const filterHandler = useCallback(
    ({ value }) => {
      batch(() => {
        dispatch(changeExecutionsTableMetaInfo({ key: 'fromDate', value: value?.fromDate, serviceId }));
        dispatch(changeExecutionsTableMetaInfo({ key: 'toDate', value: value?.toDate, serviceId }));
      });
      dispatch(getExecutionsTableDataRequest({ isFirstData: true }));

      saveJsonStyleDefaultValuesToLocalStorage({
        key: 'execTime',
        value,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[serviceId],
      });
    },
    [dispatch, serviceId],
  );

  const filterDatePickerHandler = useCallback(
    ({ fromDate, toDate }) => {
      if (fromDate) {
        if (fromDate.getTime() > toDateInfo.getTime()) {
          filterHandler({ value: { fromDate, toDate: fromDate } });
        } else {
          filterHandler({ value: { fromDate, toDate: toDateInfo } });
        }
      }
      if (toDate) {
        if (toDate.getTime() >= fromDateInfo.getTime()) {
          filterHandler({ value: { fromDate: fromDateInfo, toDate } });
        } else {
          filterHandler({ value: { fromDate: toDate, toDate } });
        }
      }
    },
    [filterHandler, fromDateInfo, toDateInfo],
  );

  const datePicker = useMemo(() => {
    const { maxDate } = filterOptions || {};
    return (
      <div className={styles.datePicker}>
        <CustomDatePicker
          date={fromDateInfo}
          maxDate={maxDate}
          onChange={(fromDate) => filterDatePickerHandler({ fromDate })}
          isLighter
          placeholder="日付を選択"
          width={DATEPICKER_WIDTH}
          isThin
        />
        <div className={styles.datePickerText}>〜</div>
        <CustomDatePicker
          date={toDateInfo}
          maxDate={maxDate}
          onChange={(toDate) => filterDatePickerHandler({ toDate })}
          isLighter
          width={DATEPICKER_WIDTH}
          isThin
        />
      </div>
    );
  }, [filterDatePickerHandler, filterOptions, fromDateInfo, toDateInfo]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerBar}>
        <div className={styles.itemButtonArea}>
          <ItemButtons options={OPTIONS} activeId={activeTab} onChange={handleChange} className={styles.button} />
        </div>
        <div className={styles.toolbox}>
          {TRADE_INFO_TABLES.EXECUTIONS.ID === activeTab && <div>{datePicker}</div>}
          {[
            TRADE_INFO_TABLES.POSITIONS_DETAILS.ID,
            TRADE_INFO_TABLES.EXECUTIONS.ID,
            TRADE_INFO_TABLES.ORDERS.ID,
          ].includes(activeTab) && (
            <LinkButton className={styles.linkButton} onClick={filterClear}>
              フィルターをすべてクリア
            </LinkButton>
          )}

          {TRADE_INFO_TABLES.POSITIONS_SUMMARY.ID === activeTab && (
            <div className={styles.buttons}>
              <Button
                className={styles.headerButton}
                width={transferWidth}
                disabled={isNonePositionsSelected || positionsDataMetaInfo.isLoading}
                onClick={openSwapTransferSummaryModal}
              >
                {SWAP_TRANSFER_LABEL[serviceId].multipleLabel}
              </Button>
              <CloseMultiSummaryPositionsButton />
            </div>
          )}

          {TRADE_INFO_TABLES.POSITIONS_DETAILS.ID === activeTab && (
            <div className={styles.buttons}>
              <Button
                className={styles.headerButton}
                width={transferWidth}
                disabled={isNonePositionsSelected}
                onClick={openSwapTransferModal}
              >
                {SWAP_TRANSFER_LABEL[serviceId].multipleLabel}
              </Button>
              <DeletePositionsButton />
            </div>
          )}
        </div>
      </div>
      <div className={styles.tableWrapper}>
        {activeTab === TRADE_INFO_TABLES.EXECUTIONS.ID && <TableExecutions />}
        {activeTab === TRADE_INFO_TABLES.POSITIONS_DETAILS.ID && <TablePositions />}
        {activeTab === TRADE_INFO_TABLES.POSITIONS_SUMMARY.ID && <TablePositionSummary />}
        {activeTab === TRADE_INFO_TABLES.ORDERS.ID && <TableOrders />}
        {activeTab === TRADE_INFO_TABLES.ORDER_HISTORY.ID && (
          <OrderHistory onMount={handleMountOrderHistory} onUnmount={handleUnmountOrderHistory} />
        )}
      </div>
    </div>
  );
});

TabPane.propTypes = {
  setDisabledTabs: PropTypes.func.isRequired,
};

const TradeInfo = () => {
  const [disabledTabs, setDisabledTabs] = useState(false);
  const tradeServiceData = useChangeService();
  const tabItems = useMemo(
    () =>
      tradeServiceData.options.map(({ isVisuallyDisabled, ...rest }) => ({
        ...rest,
        isVisuallyDisabled,
        style: isVisuallyDisabled ? {} : undefined,
        children: <TabPane setDisabledTabs={setDisabledTabs} />,
      })),
    [tradeServiceData.options],
  );
  return (
    <Tabs
      disabled={disabledTabs}
      items={tabItems}
      activeKey={tradeServiceData.activeServiceId}
      onChange={tradeServiceData.onChange}
    />
  );
};

export default memo(TradeInfo);
