import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { BUY_SELL_MAIN, FX } from 'shared-modules/constants';
import { DEFAULT_INSTRUMENT_ID } from 'shared-modules/constants/manualTrade';
import { tutorialTradingView } from '../../../../../../constants/tutorial/classNames';
import Chart from '../../../../../../components/Chart';
import styles from './tutorialPriceChart.module.scss';

const TutorialPriceChart = ({ selectedInstrumentId, apGroupId, serviceId }) => {
  return (
    <div className={classNames(styles.chart, tutorialTradingView)}>
      <Chart
        serviceId={serviceId}
        selectedInstrumentId={selectedInstrumentId}
        selectedSide={BUY_SELL_MAIN.SELL.CHART_ID}
        withPositions
        onlyApGroupPositions={apGroupId}
      />
    </div>
  );
};

TutorialPriceChart.propTypes = {
  serviceId: PropTypes.string.isRequired,
  selectedInstrumentId: PropTypes.string,
  apGroupId: PropTypes.string,
};

TutorialPriceChart.defaultProps = {
  selectedInstrumentId: DEFAULT_INSTRUMENT_ID[FX],
  apGroupId: '',
};

export default memo(TutorialPriceChart);
