/* eslint-disable react/prop-types */
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FX, ETF, CFD } from 'shared-modules/constants';
import { POPUP_MESSAGES } from '../../constants';
import {
  replace,
  getInstrumentListRequest,
  changeSelectedSide,
  socketConnectionRequest,
  getRatesRequest,
  changeServiceIdSuccess,
  getPositionsRequest,
  getSelectionModalInfoRequest,
  updateServiceMaintenanceStatus,
} from '../../redux/actions';
import AutoSelectDetailChart from '../../appWrappers/ModalContainer/components/AutoSelectDetails/components/AutoSelectDetailChart';
import styles from '../ManualTradeChart/manualTradeChart.module.scss';

const OtherWindowAutoSelectDetailChart = () => {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.router.location.query);
  const serviceId = query?.serviceId;
  const selectedInstrumentId = query?.selectedInstrumentId;
  const isFXMaintenance = query?.isFXMaintenance === 'true';
  const isETFMaintenance = query?.isETFMaintenance === 'true';
  const isCFDMaintenance = query?.isCFDMaintenance === 'true';
  const selectedStrategyId = Number(query?.selectedStrategyId);
  const selectedSide = Number(query?.selectedSide);
  const withPositions = query?.withPositions;
  const autoSelectOrderPositions = Boolean(query?.autoSelectOrderPositions);
  const termId = query?.termId;
  const selectionId = Number(query?.selectionId);
  const selectionVersion = Number(query?.selectionVersion);
  const chartProps = { serviceId };

  if (selectedInstrumentId) chartProps.selectedInstrumentId = selectedInstrumentId;
  if (selectedStrategyId) chartProps.selectedStrategyId = selectedStrategyId;
  if (selectedSide) chartProps.selectedSide = selectedSide;
  if (withPositions) chartProps.withPositions = withPositions;
  if (autoSelectOrderPositions) chartProps.autoSelectOrderPositions = autoSelectOrderPositions;
  if (termId) chartProps.termId = termId;
  if (selectionId) chartProps.selectionId = selectionId;
  if (selectionVersion) chartProps.selectionVersion = selectionVersion;

  useEffect(() => {
    if (!window.opener) {
      dispatch(replace('/404'));
    } else {
      if (isFXMaintenance) {
        dispatch(updateServiceMaintenanceStatus({ serviceId: FX, isMaintenance: true }));
      }
      if (isETFMaintenance) {
        dispatch(updateServiceMaintenanceStatus({ serviceId: ETF, isMaintenance: true }));
      }
      if (isCFDMaintenance) {
        dispatch(updateServiceMaintenanceStatus({ serviceId: CFD, isMaintenance: true }));
      }

      dispatch(getInstrumentListRequest());
      dispatch(getRatesRequest());
      dispatch(getPositionsRequest());
      dispatch(socketConnectionRequest());
      dispatch(
        getSelectionModalInfoRequest({
          selectionId,
          selectionVersion,
          termId,
        }),
      );
    }
  }, [
    dispatch,
    serviceId,
    selectedInstrumentId,
    termId,
    selectionId,
    selectionVersion,
    isFXMaintenance,
    isETFMaintenance,
    isCFDMaintenance,
  ]);

  const windowEventListener = useCallback(
    ({ data }) => {
      switch (data.message) {
        case POPUP_MESSAGES.GET_CHART_TYPE_SUCCESS: {
          dispatch(changeSelectedSide({ id: data.payload.selectedSide }));
          dispatch(changeServiceIdSuccess({ serviceId: data.payload.serviceId }));
          break;
        }
        default: {
          // empty
        }
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', windowEventListener);
    return () => {
      window.removeEventListener('message', windowEventListener);
    };
  }, [windowEventListener]);

  return (
    <div className={styles.chartWrapper}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading, */}
      <AutoSelectDetailChart {...chartProps} isFullScreen withPositions />
    </div>
  );
};

export default memo(OtherWindowAutoSelectDetailChart);
