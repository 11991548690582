import Bugsnag from '@bugsnag/js';
import { takeEvery, put } from 'redux-saga/effects';
import { BUGSNAG_WEB_API_KEY } from 'shared-modules/config';
import { HANDLE_REDIRECT } from 'shared-modules/redux/actionConstants/commonConstants';
import { SEND_ERROR_TO_BUGSNAG } from 'shared-modules/redux/actionConstants/errorConstants';
import Logger from 'shared-modules/services/Logger';
import { push } from '../actions';

function* redirectHandler(action) {
  try {
    const {
      payload: { url, isInner },
    } = action;
    if (isInner) {
      yield put(push(url));
    } else {
      window.location.href = url;
    }
  } catch (e) {
    Logger.error(e);
  }
}

function sendErrorToBugsnagHandler({ payload }) {
  if (payload.error && BUGSNAG_WEB_API_KEY) Bugsnag.notify(payload.error);
}

export default function* commonSagaHandler() {
  yield takeEvery(HANDLE_REDIRECT, redirectHandler);
  yield takeEvery(SEND_ERROR_TO_BUGSNAG, sendErrorToBugsnagHandler);
}
