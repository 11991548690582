import { memo } from 'react';
import PropTypes from 'prop-types';
import { useFeatureTags } from 'shared-modules/hooks/featureTags';
import styles from './featureTags.module.scss';

const FeatureTag = memo(({ label, backgroundColor }) => (
  <div className={styles.tag} style={{ backgroundColor }}>
    <div className={styles.label}>{label}</div>
  </div>
));

FeatureTag.propTypes = {
  label: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export const FeatureTags = memo(({ className, styleValue, periodValue, recentRoi, closeTradeCount }) => {
  const tags = useFeatureTags(FeatureTag, { styleValue, periodValue, recentRoi, closeTradeCount });
  return <div className={className || styles.container}>{tags}</div>;
});

FeatureTags.propTypes = {
  className: PropTypes.string,
  styleValue: PropTypes.string,
  periodValue: PropTypes.string,
  recentRoi: PropTypes.number,
  closeTradeCount: PropTypes.number,
};

FeatureTags.defaultProps = {
  className: undefined,
  styleValue: undefined,
  periodValue: undefined,
  recentRoi: undefined,
  closeTradeCount: undefined,
};
