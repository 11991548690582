import {
  CLOSE_RECOMMEND_MODAL,
  GET_RECOMMEND_DATA_REQUEST_END_LOADING,
  GET_RECOMMEND_DATA_REQUEST_START_LOADING,
  OPEN_RECOMMEND_MODAL,
  GET_RECOMMEND_REQUEST,
  GET_RECOMMEND_DATA_SUCCESS,
  UPDATE_LAST_RECOMMEND_VIEW_TIME,
} from '../actionConstants/recommendConstants';

export const openRecommendModal = () => ({ type: OPEN_RECOMMEND_MODAL });
export const closeRecommendModal = () => ({ type: CLOSE_RECOMMEND_MODAL });

export const getRecommendDataRequestStartLoading = () => ({ type: GET_RECOMMEND_DATA_REQUEST_START_LOADING });
export const getRecommendDataRequestEndLoading = () => ({ type: GET_RECOMMEND_DATA_REQUEST_END_LOADING });
export const getRecommendRequest = () => ({
  type: GET_RECOMMEND_REQUEST,
});
export const getRecommendDataSuccess = ({ recommendData }) => ({
  type: GET_RECOMMEND_DATA_SUCCESS,
  payload: { recommendData },
});

export const updateLastRecommendViewTime = () => ({
  type: UPDATE_LAST_RECOMMEND_VIEW_TIME,
});
