import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRangeOutList } from 'shared-modules/hooks/message';
import styles from './rangeOutContainer.module.scss';

const RangeOutContainer = memo(({ message }) => {
  const { messageId, linkUrl, status = {}, targetServices = [] } = message;
  const serviceValues = targetServices?.map(({ serviceId: serviceValue }) => serviceValue) ?? [];
  const { parameters = null } = status;
  const apGroups = useRangeOutList({ parameters, serviceValues });

  const handleClick = useCallback(() => {
    window.open(linkUrl, messageId);
  }, [linkUrl, messageId]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          レンジアウトになると、新規取引が行われなくなることや相場が予想と逆方向に動いた場合、
          <br />
          未決済建玉の評価損失が拡大するリスクがあります。
          <br />
          ホーム画面から対象の自動売買をご確認いただき、設定値の見直しや新しいルールの稼働をご検討ください。
        </div>
        <div className={styles.card}>
          <ul className={styles.list}>
            {apGroups.map(({ id, name }) => (
              <li key={id}>
                <div className={styles.name}>{name}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {linkUrl && (
        <div className={styles.footer}>
          ※レンジアウト時の対処法については
          <div className={styles.link} onClick={handleClick} role="button" aria-hidden>
            こちら
          </div>
          をご確認ください
        </div>
      )}
    </div>
  );
});

RangeOutContainer.propTypes = {
  message: PropTypes.shape({
    messageId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    action: PropTypes.string,
    linkUrl: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    status: PropTypes.shape({
      startTime: PropTypes.string,
      readFlg: PropTypes.bool,
      parameters: PropTypes.string,
    }),
    targetServices: PropTypes.arrayOf(
      PropTypes.shape({
        serviceId: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default RangeOutContainer;
