import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ACTIVE_COLOR } from 'shared-modules/constants';
import { useKeyPressHandler } from '../../../../services/hooks';
import styles from './option.module.scss';

const Option = ({
  onChange,
  id,
  isSelected,
  isLighter,
  label,
  activeColor,
  itemClassName,
  isNotSelectable,
  dataCustomSelector,
  isVisuallyDisabled,
}) => {
  const handleSelectorClick = useCallback(
    (e) => {
      if (isNotSelectable) {
        return;
      }
      e.currentTarget.blur();
      onChange(id);
    },
    [onChange, id, isNotSelectable],
  );
  const handleSelectorPress = useKeyPressHandler(handleSelectorClick);

  return (
    <div
      role="button"
      onClick={handleSelectorClick}
      onKeyPress={handleSelectorPress}
      tabIndex={isNotSelectable ? -1 : 0}
      className={classNames(
        styles.selector,
        {
          [styles.isLighter]: isLighter,
          [styles.isSelected]: isSelected,
          [styles.isBlue]: activeColor === ACTIVE_COLOR.BLUE,
          [styles.isRed]: activeColor === ACTIVE_COLOR.RED,
          [styles.isNotSelectable]: isNotSelectable,
          [styles.isVisuallyDisabled]: isVisuallyDisabled,
        },
        itemClassName,
      )}
      data-custom-selector={dataCustomSelector}
    >
      {label}
    </div>
  );
};
Option.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isSelected: PropTypes.bool.isRequired,
  isLighter: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  activeColor: PropTypes.oneOf([ACTIVE_COLOR.BLUE, ACTIVE_COLOR.RED, '']),
  itemClassName: PropTypes.string,
  isNotSelectable: PropTypes.bool,
  dataCustomSelector: PropTypes.string,
  isVisuallyDisabled: PropTypes.bool,
};
Option.defaultProps = {
  activeColor: '',
  itemClassName: '',
  isNotSelectable: false,
  dataCustomSelector: '',
  isVisuallyDisabled: false,
};

export default memo(Option);
