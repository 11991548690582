import includes from 'lodash/includes';
import { TRADE_METHODS } from 'shared-modules/constants';
import { removeSuffix } from 'shared-modules/hooks/symbol';

const getTradeMethodFilter = (item, tradeMethods, isManual) => {
  const tradeMethod = Number(item?.tradeMethod);
  if (isManual) {
    return tradeMethod === TRADE_METHODS.MANUAL.ID;
  }
  return !tradeMethods?.length || includes(tradeMethods, tradeMethod);
};

export const filterPosition = ({ item, tradeMethods, instrumentId, side, isManual }) => {
  const passedTradeMethodFilter = getTradeMethodFilter(item, tradeMethods, isManual);
  const passedInstrumentFilter = !instrumentId || removeSuffix(item.instrumentId) === instrumentId;
  const passedSideFilter = !side?.length || includes(side, Number(item.side));
  return passedTradeMethodFilter && passedInstrumentFilter && passedSideFilter;
};
