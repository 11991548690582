import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useValueToColor } from '../../hooks';
import { Icon } from './Icon';

const convert = (value) => {
  if (value === 0) {
    return 0;
  }
  if (value > 0) {
    return -45;
  }
  return 45;
};

export const RateOfReturnIcon = memo(({ value }) => {
  const { getNumericalValueColor } = useValueToColor();

  const backgroundColor = useMemo(() => {
    return getNumericalValueColor(value);
  }, [value, getNumericalValueColor]);

  const transform = useMemo(() => {
    return `rotate(${convert(value)}deg)`;
  }, [value]);

  return (
    <Icon
      name="east"
      wrapperStyle={{ backgroundColor }}
      iconStyle={{ transition: 'transform 200ms ease', transform }}
    />
  );
});

RateOfReturnIcon.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

RateOfReturnIcon.defaultProps = {
  value: undefined,
};
