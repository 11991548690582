import Decimal from 'decimal.js';
import {
  GET_MARGIN_SUCCESS,
  GET_MARGIN_REQUEST_START_LOADING,
  GET_MARGIN_REQUEST_END_LOADING,
  UPDATE_MARGIN_BY_SOCKET,
  RESET_MARGIN_DATA,
  GET_AP_GROUPS_SUCCESS,
  GET_AP_GROUPS_REQUEST_START_LOADING,
  GET_AP_GROUPS_REQUEST_END_LOADING,
  GET_AP_GROUP_BY_ID_SUCCESS,
  GET_AP_GROUP_BY_ID_SUCCESS_DO_NOT_UPDATE_SELECTED_DATA,
  CLEAR_AP_GROUP_BY_SERVICE,
  GET_AP_GROUP_BY_ID_REQUEST_START_LOADING,
  GET_AP_GROUP_BY_ID_REQUEST_END_LOADING,
  CLEAR_SELECTED_AP_GROUP_DATA,
  CLEAR_AP_GROUP_DATA,
  CLEAR_MANUAL_POSITIONS_DATA,
  GET_EXECUTIONS_INFO_SUCCESS,
  GET_EXECUTIONS_INFO_REQUEST_START_LOADING,
  GET_EXECUTIONS_INFO_REQUEST_END_LOADING,
  SET_EXECUTIONS_SUMMARY_INFO_FOR_TUTORIAL,
  GET_POSITIONS_INFO_REQUEST_END_LOADING,
  GET_POSITIONS_INFO_REQUEST_START_LOADING,
  GET_POSITIONS_INFO_SUCCESS,
  UPDATE_AP_GROUP_REQUEST_START_LOADING,
  UPDATE_AP_GROUP_REQUEST_END_LOADING,
  DELETE_AP_GROUP_REQUEST_START_LOADING,
  DELETE_AP_GROUP_REQUEST_END_LOADING,
  CHANGE_AP_GROUP_ITEM_STATUS,
  CHANGE_SELECTED_EXECUTIONS_META_INFO,
  GET_PL_EXECUTIONS_INFO_REQUEST_START_LOADING,
  GET_PL_EXECUTIONS_INFO_REQUEST_END_LOADING,
  GET_PL_EXECUTIONS_INFO_SUCCESS,
  CHANGE_AP_GROUP_ITEM_REQUEST_START_LOADING,
  CHANGE_AP_GROUP_ITEM_REQUEST_END_LOADING,
  CHANGE_SELECTED_AP_GROUP_STATUS,
  TOGGLE_CHANGE_AP_GROUP_ABILITY,
  DELETE_AP_GROUP_ITEM_REQUEST_START_LOADING,
  DELETE_AP_GROUP_ITEM_REQUEST_END_LOADING,
  SET_LIST_SETTINGS,
  REMOVE_AP_GROUP,
  RESET_SELECTED_EXECUTIONS_META_INFO,
  CHANGE_SELECTED_AP_GROUP_META_INFO,
  RESET_SELECTED_AP_GROUP_META_INFO,
  CHANGE_SELECTED_POSITIONS_META_INFO,
  RESET_SELECTED_POSITIONS_META_INFO,
  CHANGE_CARD_DATA,
  RESET_CARD_DATA,
  GET_PORTFOLIO_DATA_REQUEST_START_LOADING,
  GET_PORTFOLIO_DATA_REQUEST_END_LOADING,
} from '../actionConstants/portfolioConstants';
import { FX, ETF, SORT_TYPE_OPTIONS, CARD_STATUS_OPTIONS, CARD_TYPE_OPTIONS, MIXED, CFD } from '../../constants';
import {
  DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY,
  DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR,
  DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_BY,
  DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_DIR,
} from '../../constants/portfolio';
import { date30DaysBefore, getFormattedPercentage } from '../../services';
import { ALL_SERVICES, ASSET_TYPE_OPTIONS } from '../../constants/core';

const isPublicMarginResponse = (marginData) => {
  if (marginData == null) {
    // 判断がつかないため public と判定しない
    return false;
  }
  // public api のレスポンスは summary と instrumentGroups に null が設定されるため厳密比較を行う
  return marginData.summary === null && marginData.instrumentGroups === null;
};

const initialListSettings = {
  sortBy: SORT_TYPE_OPTIONS[0].value,
  statusFilter: CARD_STATUS_OPTIONS[0].value,
  tradeTypeFilter: CARD_TYPE_OPTIONS[0].value,
};

const initialMarginData = {
  summary: {
    date: '',
    effectiveMargin: 0,
    receivedMargin: 0,
    cashBalance: 0,
    unsettledPl: 0,
    unsettledSwap: 0,
    orderingRequiredMargin: 0,
    marginRatio: 0,
    positionRequiredMargin: 0,
    unrealizedSwapPl: 0,
    unrealizedCashFlow: 0,
    registeredCashOut: 0,
    effectiveMarginRate: '-',
    orderableMargin: 0,
  },
  instrumentGroups: [],
  loading: false,
};

export const initialState = {
  marginData: {
    [FX]: initialMarginData,
    [ETF]: initialMarginData,
    [CFD]: initialMarginData,
  },
  isTrading: null,
  apGroupsData: {
    [FX]: [],
    [ETF]: [],
    [CFD]: [],
  },
  selectedCardData: null,
  selectedApGroupData: {
    id: '',
    name: '',
    instrumentId: '',
    totalApCount: 0,
    activeApCount: 0,
    positionQuantity: 0,
    totalRealizedPnl: 0,
    unrealizedPnl: 0,
    sourceType: '',
    sourceId: '',
    entryDate: '',
    entryDateTime: '',
    apList: [],
    serviceId: '',
  },
  selectedApGroupStatus: '',
  selectedApGroupMetaInfo: {
    loading: false,
    [FX]: {
      side: null,
    },
    [ETF]: {
      side: null,
    },
    [CFD]: {
      side: null,
    },
  },
  selectedApGroupExecutionsData: [],
  selectedApGroupExecutionsMetaInfo: {
    [FX]: {
      pageNumber: 1,
      totalPages: 1,
      loading: false,
      fromDate: date30DaysBefore(),
      toDate: new Date(),
      sortBy: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY,
      sortDir: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR,
      isClose: null,
      side: null,
    },
    [ETF]: {
      pageNumber: 1,
      totalPages: 1,
      loading: false,
      fromDate: date30DaysBefore(),
      toDate: new Date(),
      sortBy: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY,
      sortDir: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR,
      isClose: null,
      side: null,
    },
    [CFD]: {
      pageNumber: 1,
      totalPages: 1,
      loading: false,
      fromDate: date30DaysBefore(),
      toDate: new Date(),
      sortBy: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY,
      sortDir: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR,
      isClose: null,
      side: null,
    },
  },
  selectedPositionsData: [],
  selectedPositionsMetaInfo: {
    loading: false,
    [FX]: {
      side: null,
      sortBy: DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_BY,
      sortDir: DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_DIR,
    },
    [ETF]: {
      side: null,
      sortBy: DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_BY,
      sortDir: DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_DIR,
    },
    [CFD]: {
      side: null,
      sortBy: DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_BY,
      sortDir: DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_DIR,
    },
  },
  changingApNameIsLoading: false,
  deleteApGroupIsLoading: false,
  profitLossExecutionsData: [],
  profitLossExecutionsDataIsLoading: false,
  changingApGroupItemIsAvailable: true,
  changingApGroupItemIsLoading: false,
  deletingApGroupItemIsLoading: false,
  changingSummaryInfoLoading: false,
  changingApGroupListLoading: false,
  listSettings: {
    [FX]: initialListSettings,
    [ETF]: initialListSettings,
    [CFD]: initialListSettings,
    [MIXED]: {
      sortBy: SORT_TYPE_OPTIONS[0],
      statusFilter: CARD_STATUS_OPTIONS[1],
      tradeTypeFilter: CARD_TYPE_OPTIONS[0],
      assetTypeFilter: ASSET_TYPE_OPTIONS[0],
    },
  },
  portfolioDataLoading: false,
};

function calcEffectiveMargin(summary, unrealizedProfitLoss) {
  const { receivedMargin, registeredCashOut, unsettledSwap, unrealizedCashFlow } = summary;
  // if one of the operands couldn't be parsed, error will be raised and 0 returned
  try {
    return Decimal.add(receivedMargin, unrealizedProfitLoss)
      .add(registeredCashOut)
      .add(unsettledSwap)
      .add(unrealizedCashFlow)
      .toNumber();
  } catch {
    return 0;
  }
}

function calcOrderableMargin(effectiveMargin, summary, serviceId) {
  const { positionRequiredMargin, orderingRequiredMargin, depositeBalance, cpAddMarginAmount } = summary ?? {};
  try {
    // if one of the operands couldn't be parsed, error will be raised and 0 returned
    let orderableMargin = Decimal.sub(effectiveMargin, positionRequiredMargin).sub(orderingRequiredMargin);

    if (serviceId !== FX) {
      orderableMargin = orderableMargin.sub(depositeBalance).sub(cpAddMarginAmount);
    }

    return Number(orderableMargin);
  } catch {
    return 0;
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MARGIN_SUCCESS: {
      const { marginData, serviceId } = payload;
      const oldMarginData = state.marginData[serviceId];
      const otherTotalMargin = ALL_SERVICES.filter((service) => service !== serviceId).reduce((acc, curr) => {
        const otherMarginData = state.marginData[curr]?.summary;
        return acc + (otherMarginData?.orderingRequiredMargin ?? 0) + (otherMarginData?.positionRequiredMargin ?? 0);
      }, 0);
      const newSummary = payload.marginData.summary;

      const effectiveMargin = oldMarginData?.effectiveMargin || newSummary?.effectiveMargin;

      let isTrading = 0;
      if (newSummary) {
        const { orderingRequiredMargin, positionRequiredMargin } = newSummary;
        isTrading = orderingRequiredMargin + positionRequiredMargin + otherTotalMargin > 0;
      }
      // TODO タイミングにより、public/{asset}/margin が叩かれてしまうことがあることに対しての暫定措置
      // 本来であれば API を叩かないか、利用側で null safe access を行うべき
      const newMarginData = isPublicMarginResponse(marginData) ? initialMarginData : marginData;
      return {
        ...state,
        marginData: {
          ...state.marginData,
          [serviceId]: {
            ...newMarginData,
            effectiveMargin,
            effectiveMarginRate: getFormattedPercentage(effectiveMargin, newSummary?.positionRequiredMargin ?? 0, 2),
            unsettledPl: oldMarginData.unsettledPl || newSummary?.unsettledPl,
            orderableMargin: calcOrderableMargin(effectiveMargin, newSummary, serviceId),
          },
        },
        isTrading,
      };
    }
    case GET_MARGIN_REQUEST_START_LOADING:
      return {
        ...state,
        changingSummaryInfoLoading: true,
      };
    case GET_MARGIN_REQUEST_END_LOADING:
      return {
        ...state,
        changingSummaryInfoLoading: false,
      };
    case UPDATE_MARGIN_BY_SOCKET: {
      const { unrealizedProfitLoss, serviceId } = payload;
      const oldMarginData = state.marginData[serviceId];
      const { summary } = oldMarginData;

      const effectiveMargin = calcEffectiveMargin(summary, unrealizedProfitLoss);

      return {
        ...state,
        marginData: {
          ...state.marginData,
          [serviceId]: {
            ...oldMarginData,
            effectiveMargin,
            unsettledPl: unrealizedProfitLoss,
            effectiveMarginRate: getFormattedPercentage(effectiveMargin, summary.positionRequiredMargin, 2),
            orderableMargin: calcOrderableMargin(effectiveMargin, summary, serviceId),
          },
        },
      };
    }
    case RESET_MARGIN_DATA: {
      const { serviceId } = payload;

      return {
        ...state,
        marginData: {
          ...state.marginData,
          [serviceId]: {
            ...initialMarginData,
          },
        },
      };
    }
    case GET_AP_GROUPS_SUCCESS: {
      const { serviceId, apGroupsData, groupId } = payload;

      let newApGroupsData;

      if (groupId) {
        let groupIdIsUpdated = false;

        newApGroupsData = state.apGroupsData[serviceId].map((item) => {
          if (item.id === groupId) {
            groupIdIsUpdated = true;
            return apGroupsData[0];
          }

          return item;
        });

        if (!groupIdIsUpdated) {
          newApGroupsData.push(apGroupsData[0]);
        }
      } else {
        newApGroupsData = apGroupsData;
      }

      return {
        ...state,
        apGroupsData: {
          ...state.apGroupsData,
          [serviceId]: newApGroupsData,
        },
      };
    }
    case REMOVE_AP_GROUP: {
      const { serviceId, groupId } = payload;

      return {
        ...state,
        apGroupsData: {
          ...state.apGroupsData,
          [serviceId]: state.apGroupsData[serviceId]?.filter((el) => el.id !== groupId),
        },
      };
    }
    case GET_AP_GROUPS_REQUEST_START_LOADING: {
      return {
        ...state,
        changingApGroupListLoading: true,
      };
    }
    case GET_AP_GROUPS_REQUEST_END_LOADING: {
      return {
        ...state,
        changingApGroupListLoading: false,
      };
    }
    case GET_AP_GROUP_BY_ID_SUCCESS: {
      const { data, serviceId } = payload;
      const apGroupsData = state.apGroupsData[serviceId].map((item) =>
        // ID 取得の api レスポンスには一覧取得の api レスポンスにある、一部属性が存在しない
        item.id === data[0].id ? { ...item, ...data[0] } : item,
      );

      return {
        ...state,
        apGroupsData: {
          ...state.apGroupsData,
          [serviceId]: apGroupsData,
        },
        selectedApGroupData: {
          ...payload.data[0],
          serviceId,
        },
      };
    }
    case GET_AP_GROUP_BY_ID_SUCCESS_DO_NOT_UPDATE_SELECTED_DATA: {
      const { data, serviceId } = payload;
      const apGroupsData = state.apGroupsData[serviceId].map((item) =>
        // ID 取得の api レスポンスには一覧取得の api レスポンスにある、一部属性が存在しない
        item.id === data[0].id ? { ...item, ...data[0] } : item,
      );

      return {
        ...state,
        apGroupsData: {
          ...state.apGroupsData,
          [serviceId]: apGroupsData,
        },
      };
    }
    case CLEAR_AP_GROUP_BY_SERVICE: {
      const { serviceId } = payload;

      return {
        ...state,
        apGroupsData: {
          ...state.apGroupsData,
          [serviceId]: initialState.apGroupsData[serviceId],
        },
      };
    }
    case CLEAR_SELECTED_AP_GROUP_DATA: {
      return {
        ...state,
        selectedApGroupData: {
          id: '',
          name: '',
          instrumentId: '',
          totalApCount: 0,
          activeApCount: 0,
          positionQuantity: 0,
          totalRealizedPnl: 0,
          unrealizedPnl: 0,
          sourceType: '',
          sourceId: '',
          entryDate: '',
          entryDateTime: '',
          apList: [],
          serviceId: '',
        },
      };
    }
    case CLEAR_AP_GROUP_DATA: {
      return {
        ...state,
        selectedApGroupData: {
          id: '',
          name: '',
          instrumentId: '',
          totalApCount: 0,
          activeApCount: 0,
          positionQuantity: 0,
          totalRealizedPnl: 0,
          unrealizedPnl: 0,
          sourceType: '',
          sourceId: '',
          entryDate: '',
          entryDateTime: '',
          apList: [],
          serviceId: '',
        },
        selectedApGroupExecutionsData: [],
        selectedPositionsData: [],
        profitLossExecutionsData: [],
      };
    }
    case CLEAR_MANUAL_POSITIONS_DATA: {
      return {
        ...state,
        selectedPositionsData: [],
      };
    }
    case GET_AP_GROUP_BY_ID_REQUEST_START_LOADING: {
      return {
        ...state,
        selectedApGroupMetaInfo: {
          ...state.selectedApGroupMetaInfo,
          loading: true,
        },
      };
    }
    case GET_AP_GROUP_BY_ID_REQUEST_END_LOADING: {
      return {
        ...state,
        selectedApGroupMetaInfo: {
          ...state.selectedApGroupMetaInfo,
          loading: false,
        },
      };
    }
    case GET_EXECUTIONS_INFO_SUCCESS: {
      const { data, totalPages, pageNumber, isFirstData, serviceId, summary } = payload;
      return {
        ...state,
        selectedApGroupExecutionsData: isFirstData ? data : [...state.selectedApGroupExecutionsData, ...data],
        selectedApGroupExecutionsMetaInfo: {
          ...state.selectedApGroupExecutionsMetaInfo,
          [serviceId]: {
            ...state.selectedApGroupExecutionsMetaInfo[serviceId],
            pageNumber,
            totalPages,
            ...summary,
          },
        },
      };
    }
    case GET_EXECUTIONS_INFO_REQUEST_START_LOADING: {
      const { serviceId } = payload;
      return {
        ...state,
        selectedApGroupExecutionsMetaInfo: {
          ...state.selectedApGroupExecutionsMetaInfo,
          [serviceId]: {
            ...state.selectedApGroupExecutionsMetaInfo[serviceId],
            loading: true,
          },
        },
      };
    }
    case GET_EXECUTIONS_INFO_REQUEST_END_LOADING: {
      const { serviceId } = payload;
      return {
        ...state,
        selectedApGroupExecutionsMetaInfo: {
          ...state.selectedApGroupExecutionsMetaInfo,
          [serviceId]: {
            ...state.selectedApGroupExecutionsMetaInfo[serviceId],
            loading: false,
          },
        },
      };
    }
    case SET_EXECUTIONS_SUMMARY_INFO_FOR_TUTORIAL: {
      const { serviceId, summary } = payload;
      return {
        ...state,
        selectedApGroupExecutionsMetaInfo: {
          ...state.selectedApGroupExecutionsMetaInfo,
          [serviceId]: {
            ...state.selectedApGroupExecutionsMetaInfo[serviceId],
            ...summary,
          },
        },
      };
    }
    case CHANGE_SELECTED_EXECUTIONS_META_INFO: {
      const { key, value, serviceId } = payload;
      return {
        ...state,
        selectedApGroupExecutionsMetaInfo: {
          ...state.selectedApGroupExecutionsMetaInfo,
          [serviceId]: {
            ...state.selectedApGroupExecutionsMetaInfo[serviceId],
            [key]: value,
          },
        },
      };
    }
    case RESET_SELECTED_EXECUTIONS_META_INFO: {
      const { serviceId } = payload;
      return {
        ...state,
        selectedApGroupExecutionsMetaInfo: {
          ...state.selectedApGroupExecutionsMetaInfo,
          [serviceId]: {
            ...initialState.selectedApGroupExecutionsMetaInfo[serviceId],
          },
        },
      };
    }

    case GET_PL_EXECUTIONS_INFO_REQUEST_START_LOADING: {
      return {
        ...state,
        profitLossExecutionsDataIsLoading: true,
      };
    }
    case GET_PL_EXECUTIONS_INFO_REQUEST_END_LOADING: {
      return {
        ...state,
        profitLossExecutionsDataIsLoading: false,
      };
    }
    case GET_PL_EXECUTIONS_INFO_SUCCESS: {
      return {
        ...state,
        profitLossExecutionsData: payload,
      };
    }
    case GET_POSITIONS_INFO_REQUEST_START_LOADING: {
      return {
        ...state,
        selectedPositionsMetaInfo: {
          ...state.selectedPositionsMetaInfo,
          loading: true,
        },
      };
    }
    case GET_POSITIONS_INFO_REQUEST_END_LOADING: {
      return {
        ...state,
        selectedPositionsMetaInfo: {
          ...state.selectedPositionsMetaInfo,
          loading: false,
        },
      };
    }
    case GET_POSITIONS_INFO_SUCCESS: {
      return {
        ...state,
        selectedPositionsData: payload,
      };
    }
    case UPDATE_AP_GROUP_REQUEST_START_LOADING: {
      return {
        ...state,
        changingApNameIsLoading: true,
      };
    }
    case UPDATE_AP_GROUP_REQUEST_END_LOADING: {
      return {
        ...state,
        changingApNameIsLoading: false,
      };
    }
    case DELETE_AP_GROUP_REQUEST_START_LOADING: {
      return {
        ...state,
        deleteApGroupIsLoading: true,
      };
    }
    case DELETE_AP_GROUP_REQUEST_END_LOADING: {
      return {
        ...state,
        deleteApGroupIsLoading: false,
      };
    }
    case CHANGE_CARD_DATA: {
      const { cardData } = payload;
      return {
        ...state,
        selectedCardData: cardData,
      };
    }
    case RESET_CARD_DATA: {
      return {
        ...state,
        selectedCardData: null,
      };
    }
    case CHANGE_AP_GROUP_ITEM_STATUS: {
      const { apId, status } = payload;
      return {
        ...state,
        selectedApGroupData: {
          ...state.selectedApGroupData,
          apList: state.selectedApGroupData.apList.map((groupItem) =>
            groupItem.id === apId ? { ...groupItem, status: String(status) } : groupItem,
          ),
        },
      };
    }
    case CHANGE_AP_GROUP_ITEM_REQUEST_START_LOADING: {
      return {
        ...state,
        changingApGroupItemIsLoading: true,
        changingApGroupItemIsAvailable: false,
      };
    }
    case CHANGE_AP_GROUP_ITEM_REQUEST_END_LOADING: {
      return {
        ...state,
        changingApGroupItemIsLoading: false,
        changingApGroupItemIsAvailable: true,
      };
    }
    case CHANGE_SELECTED_AP_GROUP_STATUS: {
      const { status } = payload;

      return {
        ...state,
        selectedApGroupStatus: status,
      };
    }
    case TOGGLE_CHANGE_AP_GROUP_ABILITY: {
      return {
        ...state,
        changingApGroupItemIsAvailable: !state.changingApGroupItemIsAvailable,
      };
    }
    case DELETE_AP_GROUP_ITEM_REQUEST_START_LOADING: {
      return {
        ...state,
        deletingApGroupItemIsLoading: true,
      };
    }
    case DELETE_AP_GROUP_ITEM_REQUEST_END_LOADING: {
      return {
        ...state,
        deletingApGroupItemIsLoading: false,
      };
    }
    case SET_LIST_SETTINGS: {
      const { serviceId, settings } = payload;
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          [serviceId]: { ...state.listSettings[serviceId], ...settings },
        },
      };
    }
    case CHANGE_SELECTED_AP_GROUP_META_INFO: {
      const { key, value, serviceId } = payload;
      return {
        ...state,
        selectedApGroupMetaInfo: {
          ...state.selectedApGroupMetaInfo,
          [serviceId]: {
            ...state.selectedApGroupMetaInfo[serviceId],
            [key]: value,
          },
        },
      };
    }
    case RESET_SELECTED_AP_GROUP_META_INFO: {
      const { serviceId } = payload;
      return {
        ...state,
        selectedApGroupMetaInfo: {
          ...state.selectedApGroupMetaInfo,
          [serviceId]: {
            ...initialState.selectedApGroupMetaInfo[serviceId],
          },
        },
      };
    }
    case CHANGE_SELECTED_POSITIONS_META_INFO: {
      const { key, value, serviceId } = payload;
      return {
        ...state,
        selectedPositionsMetaInfo: {
          ...state.selectedPositionsMetaInfo,
          [serviceId]: {
            ...state.selectedPositionsMetaInfo[serviceId],
            [key]: value,
          },
        },
      };
    }
    case RESET_SELECTED_POSITIONS_META_INFO: {
      const { serviceId } = payload;
      return {
        ...state,
        selectedPositionsMetaInfo: {
          ...state.selectedPositionsMetaInfo,
          [serviceId]: {
            ...initialState.selectedPositionsMetaInfo[serviceId],
          },
        },
      };
    }
    case GET_PORTFOLIO_DATA_REQUEST_START_LOADING: {
      return {
        ...state,
        portfolioDataLoading: true,
      };
    }
    case GET_PORTFOLIO_DATA_REQUEST_END_LOADING: {
      return {
        ...state,
        portfolioDataLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
