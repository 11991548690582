import React, { memo, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AP_GROUP_SOURCES } from 'shared-modules/constants';
import { getSingleAPGroupErrorMessage } from 'shared-modules/services';
import {
  openConfirmationModal,
  openPortfolioAutoTradeDetailOrderType,
  changeApGroupItemRequest,
  sendNotificationError,
  closeConfirmationModal,
  deleteApGroupItemRequest,
  deleteApGroupRequest,
  closePortfolioAutoTradeDetailModal,
} from '../../../../../../../../redux/actions';
import CustomSwitch from '../../../../../../../../components/CustomSwitch';
import OptionsDropdown from '../../../../../../../../components/OptionsDropdown';
import styles from './switchComponent.module.scss';

const tableSelectOptions = [
  { label: '変更', value: 0 },
  { label: '削除', value: 1 },
];

const SwitchComponent = memo(({ rowData, groupId }) => {
  const dispatch = useDispatch();
  const {
    apList,
    instrumentId,
    id,
    name: groupName,
    serviceId,
    activeApCount,
  } = useSelector((state) => state.portfolio.selectedApGroupData);
  const { marketStatus } = useSelector((state) => state.settings.instrumentList[instrumentId]);

  const changingApGroupItemIsAvailable = useSelector((state) => state.portfolio.changingApGroupItemIsAvailable);
  const { sourceType: tableSourceType } =
    useSelector((state) => state.portfolio.apGroupsData[serviceId])?.find((item) => item.id === id) ?? {};
  const isMoneyHatch = useMemo(() => tableSourceType === AP_GROUP_SOURCES.MONEY_HATCH.KEY, [tableSourceType]);

  const isTheLastItem = useMemo(() => apList.length === 1, [apList]);
  const checked = useMemo(() => rowData.status === '1', [rowData]);
  const hasPosition = useMemo(() => rowData.positionId !== null, [rowData]);
  const hasOrder = useMemo(() => Number(rowData.orderStatus) !== 0, [rowData]);

  const handleToggle = useCallback(() => {
    if (checked) {
      dispatch(
        openConfirmationModal({
          title: '稼働停止',
          bodyText: `自動売買注文を停止します。
          既に発注されている新規注文は取消されます。
          既に保有されている建玉や発注されている決済注文は有効です。稼働停止後、次の新規注文は発注されません。よろしいですか。 `,
          callback: () =>
            dispatch(
              changeApGroupItemRequest({
                groupId,
                apId: rowData.id,
                data: { status: 0 },
                status: 1,
                nextStatus: activeApCount === 1 ? 0 : 1,
                serviceId,
              }),
            ),
          buttonBackText: '戻る',
          buttonNextText: '停止',
          isOverlap: true,
        }),
      );
    } else if (tableSourceType === AP_GROUP_SOURCES.TECH.KEY) {
      dispatch(
        sendNotificationError({
          title: '再稼働できません',
          message: `テクニカルロジックに紐づく自動売買は再稼働できません。`,
        }),
      );
    } else if (hasPosition) {
      dispatch(
        sendNotificationError({
          title: '再稼働できません',
          message: `当該自動売買注文は建玉保有中のため再稼働できません。
              【照会画面・建玉照会】より建玉をご確認ください。`,
        }),
      );
    } else if (hasOrder) {
      dispatch(
        sendNotificationError({
          title: '再稼働できません',
          message: `当該自動売買注文は新規注文が発注中のため再稼働できません。
              【照会画面・注文照会】にて注文取消し後、再稼働いただけます。`,
        }),
      );
    } else if (isMoneyHatch && !marketStatus) {
      dispatch(
        sendNotificationError({
          title: 'エラー',
          message: `対象銘柄は取引時間外のため、成行注文は受付できず、再稼働はできません。`,
          buttonText: '閉じる',
        }),
      );
    } else {
      const infoMessage = isMoneyHatch
        ? `当該自動売買注文を稼働し、成行で発注します。よろしいですか。`
        : `当該自動売買注文を稼働します。よろしいですか。`;
      dispatch(
        openConfirmationModal({
          title: '稼働開始',
          bodyText: infoMessage,
          callback: () =>
            dispatch(
              changeApGroupItemRequest({
                groupId,
                apId: rowData.id,
                data: { status: 1 },
                status: activeApCount === 0 ? 0 : 1,
                nextStatus: 1,
                serviceId,
              }),
            ),
          buttonBackText: '戻る',
          buttonNextText: '確定',
          isOverlap: true,
        }),
      );
    }
  }, [
    checked,
    hasPosition,
    hasOrder,
    isMoneyHatch,
    marketStatus,
    tableSourceType,
    dispatch,
    groupId,
    rowData.id,
    activeApCount,
    serviceId,
  ]);

  const onCloseConfirmationModal = useCallback(() => dispatch(closeConfirmationModal()), [dispatch]);

  const onDeleteApGroupItem = useCallback(
    () =>
      dispatch(
        deleteApGroupItemRequest({
          groupId,
          apId: rowData.id,
          callback: onCloseConfirmationModal,
          successMessage: `${rowData.name} が削除されました。`,
          status: activeApCount === 0 ? 0 : 1,
          serviceId,
        }),
      ),
    [dispatch, groupId, rowData.id, rowData.name, onCloseConfirmationModal, activeApCount, serviceId],
  );

  const onDeleteApGroupSuccess = useCallback(() => {
    dispatch(closePortfolioAutoTradeDetailModal());
  }, [dispatch]);

  const onDeleteApGroup = useCallback(
    () =>
      dispatch(
        deleteApGroupRequest({
          groupId: id,
          callback: onDeleteApGroupSuccess,
          successMessage: `${groupName} が削除されました。`,
          serviceId,
        }),
      ),
    [dispatch, id, onDeleteApGroupSuccess, groupName, serviceId],
  );

  const handleChangeValues = useCallback(
    (tab) => {
      const isDeleting = Number(tab) === tableSelectOptions[1].value;
      const hasError = getSingleAPGroupErrorMessage(hasPosition, hasOrder, isDeleting);

      if (checked && !isDeleting) {
        dispatch(openPortfolioAutoTradeDetailOrderType({ ...rowData, instrumentId }));
      } else if (hasError) {
        dispatch(
          sendNotificationError({
            title: '自動売買注文変更',
            message: hasError,
          }),
        );
      } else if (isDeleting) {
        dispatch(
          openConfirmationModal({
            title: '自動売買削除',
            bodyText: `稼働停止している自動売買を削除します。削除すると戻すことができなくなります。削除してよろしいですか？`,
            callback: isTheLastItem ? onDeleteApGroup : onDeleteApGroupItem,
            buttonBackText: '戻る',
            buttonNextText: '削除',
            isOverlap: true,
          }),
        );
      } else if (!isDeleting && !activeApCount) {
        dispatch(
          sendNotificationError({
            title: '注文設定変更',
            message: '全ての自動売買が停止されている状態の自動売買グループの注文設定変更は行えません。',
            buttonText: '閉じる',
          }),
        );
      } else {
        dispatch(openPortfolioAutoTradeDetailOrderType({ ...rowData, instrumentId }));
      }
    },
    [
      dispatch,
      rowData,
      hasPosition,
      hasOrder,
      checked,
      instrumentId,
      onDeleteApGroupItem,
      activeApCount,
      onDeleteApGroup,
      isTheLastItem,
    ],
  );

  const actionsBlock = useRef();

  const onToggle = useCallback((isOpen) => {
    /**
     * This workaround is required because of sticky column functionality which assign its own zIndex.
     * That zIndex breaks our UI so we need to redefine it
     */
    actionsBlock.current.parentElement.parentElement.style.zIndex = isOpen ? 100 : 3;
  }, []);

  return (
    <div ref={actionsBlock} className={styles.tableTogglesWrapper}>
      <CustomSwitch isDisabled={!changingApGroupItemIsAvailable} isChecked={checked} onChange={handleToggle} />
      <OptionsDropdown
        onClick={handleChangeValues}
        options={tableSelectOptions}
        disabledOptionValue={checked ? tableSelectOptions[1].value : ''}
        onToggle={onToggle}
      />
    </div>
  );
});

SwitchComponent.propTypes = {
  rowData: PropTypes.shape({
    status: PropTypes.string,
    id: PropTypes.string,
    positionId: PropTypes.string,
    orderStatus: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
};

export default SwitchComponent;
