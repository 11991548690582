export const SCREEN_ID_PORTFOLIO = 'portfolio';
export const SCREEN_ID_BUILDER = 'builder';
export const SCREEN_ID_SELECT = 'select';
export const SCREEN_ID_TRADE = 'trade';
export const SCREEN_ID_CHART = 'chart';
export const SCREEN_ID_INQUIRY = 'inquiry';
export const SCREEN_ID_CART = 'cart';
export const SCREEN_ID_CASH = 'cash';
export const SCREEN_ID_GUIDE = 'guide';
export const SCREEN_ID_MESSAGE = 'message';
export const SCREEN_ID_LOGIN = 'login';
export const SCREEN_ID_MENU = 'menu';
export const SCREEN_ID_NEWS = 'news';
export const SCREEN_ID_REPORT = 'report';
export const SCREEN_ID_NOTIFICATION = 'notification';
