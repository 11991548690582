import React, { memo, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NOTIFICATION_TYPE, NOTIFICATION_TYPE_AVAILABLE_VALUES } from 'shared-modules/constants';
import 'react-toastify/dist/ReactToastify.css';
import styles from './notificationContainer.module.scss';

const notificationDisplayTime = 6000;

// todo: not sure, we need warning and error notification or not,
export const NotificationMessage = ({ message, type, id }) => {
  const handleClose = useCallback(() => {
    toast.dismiss(id);
  }, [id]);

  return (
    <div
      className={classNames(
        styles.notification,
        { [styles.isYellow]: type === NOTIFICATION_TYPE.WARNING },
        { [styles.isRed]: type === NOTIFICATION_TYPE.ERROR },
      )}
    >
      <div className={styles.messageContainer}>
        <i
          className={classNames(
            'material-icons',
            styles.messageIcon,
            { [styles.isYellow]: type === NOTIFICATION_TYPE.WARNING },
            { [styles.isRed]: type === NOTIFICATION_TYPE.ERROR },
          )}
        >
          {type === NOTIFICATION_TYPE.SUCCESS ? 'check_circle' : 'error_outline'}
        </i>
        {message}
      </div>
      <i aria-hidden className={classNames('material-icons', styles.closeIcon)} onClick={handleClose}>
        close
      </i>
    </div>
  );
};
NotificationMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(NOTIFICATION_TYPE_AVAILABLE_VALUES),
  id: PropTypes.string.isRequired,
};
NotificationMessage.defaultProps = {
  type: NOTIFICATION_TYPE.SUCCESS,
};

const NotificationContainer = () => {
  return (
    <ToastContainer
      className={styles.wrapper}
      toastClassName={styles.notificationContainer}
      autoClose={notificationDisplayTime}
      closeButton={false}
      closeOnClick={false}
      pauseOnVisibilityChange
      hideProgressBar
      pauseOnHover
      draggable
    />
  );
};

export default memo(NotificationContainer);
