import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useCheckOnPositionIsBeingDeleted, useGetStillDeletingPositions } from 'shared-modules/services/hooks';
import {
  openManualTradeTableCloseOrder,
  openConfirmationModal,
  deletePositionsOrderById,
  saveDeletingPositions,
} from '../../../../redux/actions';
import styles from './deleteButton.module.scss';
import CustomButton from '../../../CustomButton';

const DeleteButton = ({ original }) => {
  const { positionId, side, instrumentId, quantity, settlingQuantity } = original;
  const dispatch = useDispatch();

  const checkOnPositionIsBeingDeleted = useCheckOnPositionIsBeingDeleted();
  const getStillDeletingPositions = useGetStillDeletingPositions();

  const deletePosition = useCallback(() => {
    dispatch(
      deletePositionsOrderById({
        orderId: positionId,
        side,
        instrumentId,
        callback: () => {
          const stillDeletingPositions = getStillDeletingPositions();

          // save position IDs with timestamps in order to prevent clicking its checkboxes for two minutes
          const positions = [{ positionId, deletionStartedAt: Date.now() }, ...stillDeletingPositions];

          dispatch(saveDeletingPositions({ positions }));
        },
      }),
    );
  }, [dispatch, getStillDeletingPositions, instrumentId, positionId, side]);

  const openQuickCloseModal = useCallback(() => {
    checkOnPositionIsBeingDeleted({
      positionId,
      successCallback: () => {
        dispatch(
          openConfirmationModal({
            title: 'クイック注文決済',
            bodyText: '注文中の決済注文を取り消し、全建玉数量の成行決済注文を発注いたします。よろしいでしょうか？',
            callback: deletePosition,
            buttonBackText: '戻る',
            buttonNextText: '決済',
            isOverlap: true,
          }),
        );
      },
    });
  }, [checkOnPositionIsBeingDeleted, deletePosition, dispatch, positionId]);

  const isDisabled = useMemo(() => quantity - settlingQuantity === 0, [quantity, settlingQuantity]);

  return (
    <div className={styles.buttonWrapper}>
      <CustomButton
        isSmall
        className={styles.marginRight}
        // eslint-disable-next-line
        isDisabled={isDisabled}
        onClick={() => dispatch(openManualTradeTableCloseOrder({ positionInfo: original }))}
      >
        決済
      </CustomButton>
      <CustomButton isSmall onClick={openQuickCloseModal}>
        クイック
      </CustomButton>
    </div>
  );
};

DeleteButton.propTypes = {
  original: PropTypes.shape({
    instrumentId: PropTypes.string.isRequired,
    side: PropTypes.string.isRequired,
    positionId: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    settlingQuantity: PropTypes.number.isRequired,
  }).isRequired,
};

export default DeleteButton;
