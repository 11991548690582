import { memo, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SAVED_CART_EMPTY_MESSAGE } from 'shared-modules/constants/cart';
import { getSavedCartRequest } from 'shared-modules/redux/actions/cartActions';
import { useSavedData } from 'shared-modules/hooks/cart';
import { CartList } from '../CartList';
import { EmptyMessage } from '../EmptyMessage';
import { Item } from './Item';
import styles from './savedCart.module.scss';

export const SavedCart = memo(() => {
  const dispatch = useDispatch();
  const { savedCartData, loading } = useSavedData();

  const content = useMemo(() => {
    if (savedCartData?.length) {
      return savedCartData.map((item) => <Item key={item.logId} className={styles.item} item={item} />);
    }
    return <EmptyMessage>{SAVED_CART_EMPTY_MESSAGE}</EmptyMessage>;
  }, [savedCartData]);

  useEffect(() => {
    dispatch(getSavedCartRequest());
  }, [dispatch]);

  return <CartList loading={loading} content={content} />;
});
