import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { COLORS, MODAL_SIZES, WIDTH } from 'shared-modules/constants';
import Modal from '../../../../components/Modal';
import CustomButton from '../../../../components/CustomButton';
import CustomInput from '../../../../components/CustomInput';
import styles from './inputConfirmation.module.scss';

const InputConfirmation = ({
  isOpen,
  closeModal,
  title,
  callback,
  bodyText,
  buttonBackText,
  buttonNextText,
  value,
  passValueWithKey,
  isOverlap,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const onConfirmClick = useCallback(() => {
    const payload = passValueWithKey ? { [passValueWithKey]: inputValue } : inputValue;
    if (callback) callback(payload);
    closeModal();
  }, [callback, closeModal, inputValue, passValueWithKey]);

  useEffect(() => setInputValue(value), [value]);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title={title} isOverlap={isOverlap} size={MODAL_SIZES.WIDTH_400}>
      <div className={styles.bodyText}>{bodyText}</div>
      <CustomInput
        className={styles.inputStyle}
        value={inputValue}
        width={WIDTH.PERCENTAGE_100}
        onChange={setInputValue}
      />
      <div className={styles.buttonRow}>
        <CustomButton onClick={closeModal} className={styles.backButton}>
          {buttonBackText}
        </CustomButton>
        <CustomButton onClick={onConfirmClick} color={buttonBackText ? COLORS.WHITE : ''}>
          {buttonNextText}
        </CustomButton>
      </div>
    </Modal>
  );
};
InputConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  callback: PropTypes.func,
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonBackText: PropTypes.string,
  buttonNextText: PropTypes.string,
  value: PropTypes.string,
  passValueWithKey: PropTypes.string,
  isOverlap: PropTypes.bool,
};
InputConfirmation.defaultProps = {
  title: '',
  callback: null,
  bodyText: '',
  buttonBackText: '戻る',
  buttonNextText: '変更',
  value: '',
  passValueWithKey: '',
  isOverlap: false,
};

export default memo(InputConfirmation);
