import { memo } from 'react';
import PropTypes from 'prop-types';
import { MeterCircle } from './MeterCircle';

export const PrimaryCircle = memo(({ className, size, position, circleInfo }) => {
  return (
    <MeterCircle
      className={className}
      size={size}
      strokeDasharray={`${position} ${circleInfo.circumference}`}
      circleInfo={circleInfo}
      color1="#FF2626"
      color2="#FFD400"
      color3="#DAE5A1"
      color4="#17E57E"
    />
  );
});

PrimaryCircle.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  circleInfo: PropTypes.shape({
    r: PropTypes.number.isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    circumference: PropTypes.number.isRequired,
    strokeWidth: PropTypes.number.isRequired,
  }).isRequired,
};

PrimaryCircle.defaultProps = {
  className: undefined,
};
