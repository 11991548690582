import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatNumberToDisplayedString } from 'shared-modules/services';
import styles from './highlightValue.module.scss';

const HighlightValue = ({ value, withYenIcon, showZero, isToFormat, withoutPlus }) => {
  let displayedValue;
  let isNegative;
  const isZero = value === 0;

  if (!value && !(isZero && showZero)) {
    displayedValue = '-';
  } else {
    isNegative = value < 0;
    if (isToFormat) {
      displayedValue = formatNumberToDisplayedString({ value, withYenIcon, withoutPlus });
    } else {
      displayedValue = isNegative ? value : `+${value}`;
      displayedValue = `${displayedValue}${withYenIcon ? '円' : ''}`;
    }
  }

  return (
    <span className={classNames(styles.highlightValue, { [styles.isRed]: isNegative, [styles.isColorless]: isZero })}>
      {displayedValue}
    </span>
  );
};
HighlightValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withYenIcon: PropTypes.bool,
  isToFormat: PropTypes.bool,
  withoutPlus: PropTypes.bool,
  showZero: PropTypes.bool,
};
HighlightValue.defaultProps = {
  value: '',
  withYenIcon: false,
  isToFormat: false,
  withoutPlus: false,
  showZero: false,
};

export default memo(HighlightValue);
