import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'shared-modules/redux/actions/routerActions';
import { CFD, ETF, FX, MODAL_SIZES } from 'shared-modules/constants';
import { SEQUENTIAL_MODALS } from 'shared-modules/constants/sequentialModals';
import { doneRequestSequentialModal } from 'shared-modules/redux/actions';
import { navigateToRecommendSelectionStart } from 'shared-modules/redux/actions/autoSelectActions';
import { updateLastRecommendViewTime } from 'shared-modules/redux/actions/recommendActions';
import { Button, Modal } from '../../../../components';
import { AUTO_SELECT } from '../../../../constants';
import styles from './RecommendModal.module.scss';
import { CardItem } from '../../../../screens/AutoSelect/components/CardItem';

const RecommendModal = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const recommendData = useSelector((state) => state.recommend.recommendData);

  // maintenanceInfoを常に取得
  const FXisMaintenance = useSelector((state) => state.settings.maintenanceInfo[FX]?.isMaintenance);
  const ETFisMaintenance = useSelector((state) => state.settings.maintenanceInfo[ETF]?.isMaintenance);
  const CFDisMaintenance = useSelector((state) => state.settings.maintenanceInfo[CFD]?.isMaintenance);

  const onClose = useCallback(() => {
    dispatch(updateLastRecommendViewTime());
    closeModal();
    dispatch(doneRequestSequentialModal({ modalId: SEQUENTIAL_MODALS.Recommend }));
  }, [dispatch, closeModal]);

  const handleClickToSelectedSelection = useCallback(
    (tagId, selectionInfo) => {
      onClose();
      dispatch(navigateToRecommendSelectionStart({ parentTagId: 1, childrenTagId: tagId, selectionInfo }));
      dispatch(replace(`/${AUTO_SELECT}`));
    },
    [onClose, dispatch],
  );

  const openSeminarUrl = useCallback((url) => {
    window.open(url, '_blank');
  }, []);

  const handleClickToSelect = useCallback(() => {
    onClose();
    dispatch(replace(`/${AUTO_SELECT}`));
  }, [onClose, dispatch]);

  // recommendDataがない場合はモーダルを閉じる
  useEffect(() => {
    if (!recommendData) {
      closeModal();
    }
  }, [recommendData, closeModal]);

  useEffect(() => {
    if (FXisMaintenance || ETFisMaintenance || CFDisMaintenance) {
      closeModal();
      dispatch(doneRequestSequentialModal({ modalId: SEQUENTIAL_MODALS.Recommend }));
    }
  }, [FXisMaintenance, ETFisMaintenance, CFDisMaintenance, dispatch, closeModal]);

  if (!recommendData) {
    return null; // 早期リターン
  }

  const { title, description, recommendRules, isSeminarRequired, seminarImageUrl, seminarUrl } = recommendData;

  return (
    <>
      {isOpen && (
        <Modal
          closeModal={onClose}
          isOpen={isOpen}
          centered
          size={MODAL_SIZES.WIDTH_450}
          aria-labelledby="contained-modal-title-vcenter"
          title={title}
        >
          <div className={styles.description}>{description}</div>
          <div>
            {recommendRules.map(({ tagId, selectionInfo }) => (
              <div className={styles.selections} key={`${selectionInfo.selectionId}_${selectionInfo.selectVersionId}`}>
                <CardItem data={selectionInfo} onClick={() => handleClickToSelectedSelection(tagId, selectionInfo)} />
              </div>
            ))}
          </div>
          {isSeminarRequired && (
            <button
              onClick={() => openSeminarUrl(seminarUrl)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  openSeminarUrl(seminarUrl);
                }
              }}
              aria-label="seminar"
              type="button"
            >
              <img src={seminarImageUrl} alt="seminar" className={styles.image} />
            </button>
          )}
          <Button className={styles.button} onClick={handleClickToSelect} secondary>
            セレクトでえらぶ
          </Button>
        </Modal>
      )}
    </>
  );
};

RecommendModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(RecommendModal);
