import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CURRENT_CART_EMPTY_TEXT, CART_TABLE_TITLE } from 'shared-modules/constants/cart';
import { useCartTableData } from 'shared-modules/services/hooks/cartLogic';
import { multiplyAndRoundDecimalsIfAllExist, toCommaSeparatedNumber } from 'shared-modules/services';
import BuySellItem from '../../../../components/BuySellItem';
import { getCartTableTemplate } from '../../../../services/tableTemplate';
import { Table } from '../../../../components';
import styles from './cartTable.module.scss';

const resized = {
  key: 'cart-table',
  default: [82, 107, 70, 72, 82, 82, 70, 82, 82, 82, 70, 97],
};

const DEFAULT_SETTINGS_SORTING = [{ id: 'name', desc: false }];
const SETTINGS_TABLE_DIFFERENCES_BY_ACCESSOR = {
  quantity: {
    Header: '数量(万/Lot/口)',
    Cell: ({ row }) => {
      const {
        original: { quantity, strategySets },
      } = row;
      const value = multiplyAndRoundDecimalsIfAllExist([quantity, strategySets]);
      return toCommaSeparatedNumber(value);
    },
  },
  tp: { Header: '利確幅' },
  sl: { Header: '損切幅' },
};
const CART_SETTINGS_TABLE_TEMPLATE = getCartTableTemplate().map((row) => ({
  ...row,
  ...(SETTINGS_TABLE_DIFFERENCES_BY_ACCESSOR[row.accessor] || null),
  disableSortBy: row.accessor !== 'name',
}));

const buySellCell = ({ cell: { value } }) => <BuySellItem type={value} />;
buySellCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export const CartTable = memo(() => {
  const { currentDataIsLoading, settingsTableData } = useCartTableData();
  const tableMetaInfo = useMemo(
    () => ({
      loading: currentDataIsLoading,
      pageNumber: 1,
      totalPages: 1,
    }),
    [currentDataIsLoading],
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>{CART_TABLE_TITLE}</div>
      <Table
        tableData={settingsTableData}
        columns={CART_SETTINGS_TABLE_TEMPLATE}
        emptyText={CURRENT_CART_EMPTY_TEXT}
        resized={resized}
        defaultSorting={DEFAULT_SETTINGS_SORTING}
        tableMetaInfo={tableMetaInfo}
        useServerSorting
      />
    </div>
  );
});
