import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { components } from 'react-select';
import { CLEAR } from '../../constants';
import styles from './option.module.scss';

const Option = ({ isSelected, children, disabledValues, renderBeforeOption, ...props }) => {
  const { data } = props;
  const { value } = data;
  const isDisabled = useMemo(() => disabledValues.includes(value), [disabledValues, value]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props} isDisabled={isDisabled}>
      {renderBeforeOption && renderBeforeOption(data)}
      {isSelected && children !== CLEAR ? (
        <>
          {children}
          <i className={classNames(styles.checkIcon, 'material-icons')}>check</i>
        </>
      ) : (
        children
      )}
    </components.Option>
  );
};

Option.propTypes = {
  renderBeforeOption: PropTypes.func,
  data: PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]) }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disabledValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

Option.defaultProps = {
  renderBeforeOption: null,
};

export default memo(Option);
