import React, { memo } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { FX } from 'shared-modules/constants';
import styles from '../steps.module.scss';

const Step6 = ({ serviceId }) => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <p>
          保有中の建玉が買った（売った）価格から利益の方向にどのくらい相場が動いたら利益確定（利確）するかを値幅
          {serviceId === FX && '（pips）'}で指定します。
        </p>
        {serviceId === FX && (
          <p className={styles.subContent}>
            <br />※ pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）
          </p>
        )}
      </div>
    </div>
  );
};

Step6.propTypes = {
  serviceId: propTypes.string,
};

Step6.defaultProps = {
  serviceId: '',
};

export default memo(Step6);
