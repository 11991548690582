/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useContext } from 'react';
import { KEY_SYSTEM_RELOADED } from '../constants/core';
import { ReloadingAppContext } from '../contexts';
import Logger from '../services/Logger';

export const useIsReloadingApp = () => {
  const { reloading } = useContext(ReloadingAppContext);
  return reloading;
};

export const useReloadApp = () => {
  const { setReloading, storage, reload } = useContext(ReloadingAppContext);
  return useCallback(async () => {
    if (storage) {
      try {
        await storage.setItem(KEY_SYSTEM_RELOADED, String(true));
      } catch (error) {
        Logger.error(error);
      }
    }
    setReloading(true);
    reload?.();
  }, [setReloading, storage, reload]);
};

export const useReloadedApp = () => {
  const { reloaded, setReloaded } = useContext(ReloadingAppContext);
  return [reloaded, setReloaded];
};
