import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveDefaultValuesFromLocalStorage } from 'shared-modules/services';
import { KEY_FOR_DEFAULT_SERVICE_ID } from 'shared-modules/constants';
import { useInstrumentOptions } from 'shared-modules/hooks';
import { changeBuilderActiveCurrency } from '../../../redux/actions';

export const useBuilderServiceId = () => {
  const dispatch = useDispatch();
  const serviceId = useSelector((state) => state.auth.serviceId);
  const currencyPairsOptions = useInstrumentOptions()[serviceId];
  useEffect(() => {
    dispatch(changeBuilderActiveCurrency({ activeCurrency: currencyPairsOptions?.[0]?.value }));
    saveDefaultValuesFromLocalStorage({ key: KEY_FOR_DEFAULT_SERVICE_ID, value: serviceId });
  }, [dispatch, serviceId, currencyPairsOptions]);
  return serviceId;
};
