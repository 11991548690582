import React, { memo, useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  MODAL_SIZES,
  COLORS,
  WIDTH,
  COMMON_OPTIONS_EXPIRATION_TYPE,
  OPTIONS_ORDER_TYPES,
  QUANTITY_STEP,
  ETF,
  QUANTITY_MAXES,
} from 'shared-modules/constants';
import { useServiceName } from 'shared-modules/hooks';
import { useChangeOrderSettingsCount, useManualTradeSelectedInstrumentSettings } from 'shared-modules/services/hooks';
import { getFxCfdQuantityStep, getServiceQuantityUnit, validateOrderSettingsCount } from 'shared-modules/services';
import { COUNT_INPUT } from 'shared-modules/constants/manualTrade';
import { updateOrderSettingsRequest, getOrderSettingsRequest } from '../../../../redux/actions';
import { Modal, Spin, InputNumber } from '../../../../components';
import CustomSelect from '../../../../components/CustomSelect';
import CustomButton from '../../../../components/CustomButton';
import styles from './manualTradeConfig.module.scss';

const ORDER_TYPES = OPTIONS_ORDER_TYPES.map((order) => ({ label: order.value, value: order.id }));
const DROPDOWN_WIDTH = 123;

const ManualTradeConfig = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();

  const serviceId = useSelector((state) => state.auth.serviceId);
  const { orderType, quantity, expirationType } = useSelector((state) => state.settings[serviceId].orderSettings);
  const isSettingsLoading = useSelector((state) => state.settings.loadingGetOrderSettings);
  const isLoading = useSelector((state) => state.settings.loadingUpdateOrderSettings);

  const [innerOrderType, changeInnerOrderType] = useState(0);
  const [innerCount, setInnerCount] = useState(0);
  const [innerExpirationType, changeInnerExpirationType] = useState(0);
  const [countError, setCountError] = useState({ isValid: true, errorMessage: '' });

  const { quantityPrecision } = useManualTradeSelectedInstrumentSettings();
  const isETF = serviceId === ETF;
  const quantityIsInteger = isETF && quantityPrecision >= 1;

  const validationMessages = useMemo(() => {
    const messages = [];
    if (!countError.isValid) {
      messages.push({ inputName: COUNT_INPUT, errorMessage: countError.errorMessage });
    }

    return messages;
  }, [countError]);
  const buttonIsDisabled = useMemo(() => Boolean(validationMessages.length), [validationMessages]);

  const changeInnerCount = useChangeOrderSettingsCount(setInnerCount, setCountError, serviceId);
  const changeInnerCountRef = useRef({});
  useEffect(() => {
    if (changeInnerCountRef?.current) {
      changeInnerCountRef.current = { changeInnerCount };
    }
  }, [changeInnerCount]);

  useEffect(() => {
    if (isOpen) {
      dispatch(getOrderSettingsRequest());
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    const {
      current: { changeInnerCount: changeCount },
    } = changeInnerCountRef;
    if (isOpen) {
      changeInnerOrderType(Number(orderType));
      changeCount(Number(quantity));
      changeInnerExpirationType(Number(expirationType));
    }
  }, [isOpen, orderType, quantity, expirationType]);

  const handleUpdateSetting = useCallback(() => {
    const countValidMessage = validateOrderSettingsCount(innerCount, serviceId);
    if (!countValidMessage.isValid) {
      return setCountError(countValidMessage);
    }

    return dispatch(
      updateOrderSettingsRequest({
        callback: closeModal,
        orderType: innerOrderType,
        quantity: innerCount,
        expirationType: innerExpirationType,
      }),
    );
  }, [closeModal, dispatch, innerOrderType, innerCount, innerExpirationType, serviceId]);

  const serviceName = useServiceName(serviceId);
  const modalTitle = `${serviceName}トレード設定`;
  const quantityType = `数量(${getServiceQuantityUnit(serviceId)})`;
  const quantityMax = QUANTITY_MAXES[serviceId];

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title={modalTitle} size={MODAL_SIZES.WIDTH_360}>
      <div className={classNames(styles.wrapper, { [styles.isSettingsLoading]: isSettingsLoading })}>
        {isSettingsLoading && <Spin className={styles.loader} />}
        <div className={styles.titleRow}>注文初期設定(デフォルト値)</div>
        <div className={styles.orderTypeRow}>
          注文種別
          <CustomSelect
            options={ORDER_TYPES}
            selectItemId={innerOrderType}
            onChange={changeInnerOrderType}
            width={DROPDOWN_WIDTH}
          />
        </div>
        <div>
          <div className={styles.countRow}>
            {quantityType}
            <InputNumber
              value={innerCount}
              name={COUNT_INPUT}
              onChange={changeInnerCount}
              type="number"
              step={isETF ? QUANTITY_STEP.ONE : getFxCfdQuantityStep}
              max={quantityMax}
              // No min limit to prevent messing with user input
              // when user typing new number
              onlyIntegerAllowed={quantityIsInteger}
              errorMessages={validationMessages}
              withErrorTooltip
            />
          </div>
          {serviceId === ETF && <div className={styles.note}>※TOPIXの設定は設定値の10倍となります</div>}
        </div>
        <div className={styles.expirationTypeRow}>
          有効期限
          <CustomSelect
            options={COMMON_OPTIONS_EXPIRATION_TYPE}
            selectItemId={innerExpirationType}
            onChange={changeInnerExpirationType}
            width={DROPDOWN_WIDTH}
          />
        </div>
        <CustomButton
          color={COLORS.RED}
          width={WIDTH.PERCENTAGE_100}
          onClick={handleUpdateSetting}
          className={styles.button}
          isLoading={isLoading}
          isDisabled={buttonIsDisabled}
        >
          保存
        </CustomButton>
      </div>
    </Modal>
  );
};

ManualTradeConfig.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(ManualTradeConfig);
