import { memo } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { QUANTITY_INPUT_LABEL } from 'shared-modules/constants/cart';
import { useCurrentCartLogic } from 'shared-modules/services/hooks/cartLogic';
import { useCalculatedMargin, useItemGroup } from 'shared-modules/hooks/cart';
import { RECOMMENDED_MARGIN_LABEL, REQUIRED_MARGIN_LABEL } from 'shared-modules/constants';
import InstrumentIcon from '../../../../../components/InstrumentIcon';
import SelectionImage from '../../../../../components/SelectionImage';
import { Button, InputNumber, Tag } from '../../../../../components';
import { MarginText } from '../../../../../components/Texts';
import styles from './item.module.scss';

export const Item = memo(({ item, errorMessages, resetErrorFunction, disabledSave, disabledDelete }) => {
  const {
    instrumentId,
    name,
    serviceIds,
    count,
    changeCount,
    validateInput,
    isLoadingDelete,
    handleDeleteItem,
    handleUpdateStrategySetsRequest,
    itemNameRefId,
    inputNameRefId,
    handleSaveItem,
  } = useCurrentCartLogic({ item, resetErrorFunction });
  const disabled = isLoadingDelete || disabledSave || disabledDelete;
  // 選択されたシミュレーション期間のデータを取得する
  // ここでの item.itemId は `${parentId}_${sourceType}` or itemId
  const itemGroup = useItemGroup(item.itemId);
  const { marginRecommended, marginRequired } = useCalculatedMargin({ item: itemGroup });
  const itemName = item.parentName ?? name;
  return (
    <div className={styles.container}>
      <div className={styles.tags}>
        {serviceIds.map((serviceId) => (
          <div key={serviceId} className={styles.tag}>
            <Tag text={serviceId} />
          </div>
        ))}
      </div>
      <div className={styles.nameArea}>
        {item.parentId ? (
          <SelectionImage
            image={item.parentImage}
            width="48px"
            isShare={item.sourceType === 'lab'}
            serviceId={serviceIds[0]}
          />
        ) : (
          <InstrumentIcon size="small" instrumentId={instrumentId} serviceId={serviceIds[0]} />
        )}

        <div
          className={classNames(styles.itemName, styles.noMaxHeight)}
          data-for={itemNameRefId.current}
          data-tip={itemName}
        >
          {itemName}
        </div>
        <ReactTooltip id={itemNameRefId.current} />
      </div>
      <div className={styles.marginArea}>
        <div className={styles.row}>
          <div className={styles.label}>{RECOMMENDED_MARGIN_LABEL}</div>
          <MarginText className={styles.value} symbolClassName={styles.symbol} value={marginRecommended} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>{REQUIRED_MARGIN_LABEL}</div>
          <MarginText className={styles.value} symbolClassName={styles.symbol} value={marginRequired} />
        </div>
      </div>
      <div className={styles.separator} />
      <div className={styles.footer}>
        <div className={styles.label}>{QUANTITY_INPUT_LABEL}</div>
        <InputNumber
          value={count}
          onChange={changeCount}
          name={inputNameRefId.current}
          errorMessages={errorMessages}
          validateFunction={validateInput}
          onBlurSendRequest={handleUpdateStrategySetsRequest}
          withErrorTooltip
          onlyIntegerAllowed
          disabled={disabled}
          width={45}
        />
        <Button className={styles.button} disabled={disabled} onClick={handleSaveItem} secondary>
          保存する
        </Button>
        <Button className={styles.button} disabled={disabled} onClick={handleDeleteItem} secondary>
          削除する
        </Button>
      </div>
    </div>
  );
});

Item.propTypes = {
  item: PropTypes.shape({
    cartItems: PropTypes.arrayOf(PropTypes.shape({})),
    parentId: PropTypes.number,
    parentName: PropTypes.string,
    parentImage: PropTypes.string,
    sourceType: PropTypes.string,
    itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    strategySets: PropTypes.number.isRequired,
    strategyDetail: PropTypes.shape({
      instrumentId: PropTypes.string,
      serviceId: PropTypes.string,
      name: PropTypes.string,
      simulationStats: PropTypes.shape({
        roi: PropTypes.number.isRequired,
        marginRecommended: PropTypes.number,
      }),
    }),
  }).isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({ inputName: PropTypes.string.isRequired, errorMessage: PropTypes.string.isRequired }),
  ).isRequired,
  resetErrorFunction: PropTypes.func.isRequired,
  disabledSave: PropTypes.bool,
  disabledDelete: PropTypes.bool,
};

Item.defaultProps = {
  disabledSave: false,
  disabledDelete: false,
};
