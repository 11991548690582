import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DeleteIconButton } from '../../../../../components';

export const DeleteIcon = memo(({ id, onClick }) => {
  const allowOrderEdit = useSelector((state) => state.builder.allowOrderEdit);
  const simulationDataIsLoading = useSelector((state) => state.builder.simulationDataIsLoading);

  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return <>{allowOrderEdit && !simulationDataIsLoading && <DeleteIconButton onClick={handleClick} />}</>;
});

DeleteIcon.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
