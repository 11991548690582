import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch, batch } from 'react-redux';
import { openAdvertisementRequest } from '../../../../redux/actions';
import { useKeyPressHandler } from '../../../../services/hooks';
import styles from './advertisement.module.scss';

const Advertisement = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { body, popupUrl, callbackAction } = useSelector((state) => state.advertisement.activeAdvertisementInfo);

  const onCloseClick = useCallback(
    (e) => {
      batch(() => {
        closeModal();
        dispatch(openAdvertisementRequest({ callbackAction }));
      });
      e.currentTarget.blur();
    },
    [closeModal, dispatch, callbackAction],
  );
  const handlePressClose = useKeyPressHandler(onCloseClick);

  const onAdClick = useCallback(() => {
    if (popupUrl) {
      window.open(popupUrl);
    }
  }, [popupUrl]);

  return (
    <Modal
      backdrop="static"
      show={isOpen}
      onHide={closeModal}
      centered
      dialogClassName={styles.wrapper}
      backdropClassName={styles.backdrop}
    >
      <Modal.Header bsPrefix={styles.header}>
        <i
          className={classNames('material-icons', styles.closeIcon)}
          tabIndex={0}
          role="button"
          onClick={onCloseClick}
          onKeyPress={handlePressClose}
        >
          clear
        </i>
      </Modal.Header>
      <Modal.Body bsPrefix={classNames(styles.body, { [styles.withPopupUrl]: popupUrl })}>
        {/* eslint-disable-next-line react/no-danger */}
        <div aria-hidden dangerouslySetInnerHTML={{ __html: body }} onClick={onAdClick} />
      </Modal.Body>
    </Modal>
  );
};
Advertisement.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(Advertisement);
