import fxPCWeb from './FX_PCWeb.png';
import etfPCWeb from './ETF_PCWeb.png';
import cfdPCWeb from './CFD_PCWeb.png';
import japanPostPCWeb from './JapanPost_PCWeb.png';
import mizuhoPCWeb from './Mizuho_PCWeb.png';
import mufgPCWeb from './Mufg_PCWeb.png';
import payPayPCWeb from './PayPay_PCWeb.png';
import rakutenPCWeb from './Rakuten_PCWeb.png';
import sbiPCWeb from './SBI_PCWeb.png';
import smbcPCWeb from './Smbc_PCWeb.png';

const bankImagesHelper = {
  'FX_PCWeb.png': fxPCWeb,
  'ETF_PCWeb.png': etfPCWeb,
  'CFD_PCWeb.png': cfdPCWeb,
  'JapanPost_PCWeb.png': japanPostPCWeb,
  'Mizuho_PCWeb.png': mizuhoPCWeb,
  'Mufg_PCWeb.png': mufgPCWeb,
  'PayPay_PCWeb.png': payPayPCWeb,
  'Rakuten_PCWeb.png': rakutenPCWeb,
  'SBI_PCWeb.png': sbiPCWeb,
  'Smbc_PCWeb.png': smbcPCWeb,
};

export default bankImagesHelper;
