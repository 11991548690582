import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeRecommendModal, getRecommendDataSuccess } from 'shared-modules/redux/actions/recommendActions';
import { closeMessageModal } from 'shared-modules/redux/actions/messageActions';
import {
  closeConfirmationModal,
  closeInputConfirmationModal,
  closePortfolioManualTradeDetailModal,
  closePortfolioAutoTradeDetailModal,
  closeBuilderConfigurationFlowModal,
  closeBuilderAddToCartModal,
  closeBuilderAddToPortfolioModal,
  closeBuilderAddToPortfolioSuccessModal,
  closeBuilderAddToPortfolioFailModal,
  closeChartMakeAddToCartModal,
  closeChartMakeRunNowConfirmationModal,
  closeManualTradeTableCloseOrder,
  closeManualTradeTableCloseOrderConfirmation,
  closeManualTradeCurrencySettings,
  closeManualTradeConfig,
  closeManualTradeNews,
  closeManualTradeConfirmation,
  closeManualTradeTableChangeOrder,
  closeMultiEdit,
  closeMultiEditCheckComfirm,
  closeMultiEditSelect,
  closeMultiEditFail,
  closePortfolioAutoTradeDetailOrderType,
  closeAutoSelectRunNowConfirmation,
  closeAutoSelectAddToCartConfirmation,
  closeHelpInfo,
  closeCopyrightInfo,
  closeAgreementInfo,
  closeNotificationSettings,
  closeReportsDetails,
  closeAdvertisement,
  closeBuilderWarningInfo,
  closeAutoSelectWarningInfo,
  closeStrategiesSuccessModal,
  closeErrorInfoModal,
  closeYoutubeModal,
  closeLabTagInfoModal,
  closeRangeOutConfirmation,
  closeTechAddToCartModal,
  closeTechAddToPortfolioModal,
  closeTechAddToPortfolioSuccessModal,
  closeTechAddToPortfolioFailModal,
  closeTutorialModal,
  closeTutorialPortfolioDetailModal,
  closeTechBuilderConfigurationFlowModal,
  closeBeginDepositModal,
  closeBeginRunAutoModal,
  closeSearchAutoSelectRuleNameModal,
} from '../../redux/actions';
import BuilderConfigurationFlow from './components/BuilderConfigurationFlow';
import BuilderAddToCart from './components/BuilderAddToCart';
import BuilderAddToPortfolio from './components/BuilderAddToPortfolio';
import BuilderAddToPortfolioSuccess from './components/BuilderAddToPortfolioSuccess';
import BuilderAddToPortfolioFail from './components/BuilderAddToPortfolioFail';
import ChartMakeAddToCart from './components/ChartMakeAddToCart';
import ChartMakeRunNowConfirmation from './components/ChartMakeRunNowConfirmation';
import TechAddToCart from './components/TechAddToCart';
import TechAddToPortfolio from './components/TechAddToPortfolio';
import TechAddToPortfolioSuccess from './components/TechAddToPortfolioSuccess';
import TechAddToPortfolioFail from './components/TechAddToPortfolioFail';
import Confirmation from './components/Confirmation';
import InputConfirmation from './components/InputConfirmation';
import PortfolioManualTradeDetail from './components/PortfolioManualTradeDetail';
import PortfolioAutoTradeDetail from './components/PortfolioAutoTradeDetail';
import ManualTradeTableCloseOrder from './components/ManualTradeTableCloseOrder';
import ManualTradeTableCloseOrderConfirmation from './components/ManualTradeTableCloseOrderConfirmation';
import ManualTradeCurrencySettings from './components/ManualTradeCurrencySettings';
import ManualTradeConfig from './components/ManualTradeConfig';
import ManualTradeNews from './components/ManualTradeNews';
import ManualTradeConfirmation from './components/ManualTradeConfirmation';
import ManualTradeTableChangeOrder from './components/ManualTradeTableChangeOrder';
import MultiEdit from './components/MultiEdit';
import MultiEditCheckComfirm from './components/MultiEditCheckComfirm';
import MultiEditSelect from './components/MultiEditSelect';
import MultiEditFail from './components/MultiEditFail';
import PortfolioAutoTradeDetailOrderType from './components/PortfolioAutoTradeDetailOrderType';
import AutoSelectRunNowConfirmation from './components/AutoSelectRunNowConfirmation';
import AutoSelectAddToCartConfirmation from './components/AutoSelectAddToCartConfirmation';
import HelpInfo from './components/HelpInfo';
import CopyrightInfo from './components/CopyrightInfo';
import NotificationSettings from './components/NotificationSettings';
import AgreementInfo from './components/AgreementInfo';
import ReportsDetails from './components/ReportsDetails';
import Advertisement from './components/Advertisement';
import ErrorInfo from './components/ErrorInfo';
import BuilderWarningInfo from './components/BuilderWarningInfo';
import AutoSelectWarningInfo from './components/AutoSelectWarningInfo';
import SocketError from './components/SocketError';
import MultiEditNotification from './components/MultiEditNotification';
import StrategiesSuccess from './components/StrategiesSuccess';
import YoutubeModal from './components/YoutubeModal';
import LabCategoryInfo from './components/LabCategoryInfo';
import { RangeOutConfirmation } from './components/RangeOutConfirmation';
import { Tutorial } from './components/Tutorial';
import TutorialPortfolioDetail from './components/TutorialPortfolioDetail';
import TechBuilderConfigurationFlow from './components/TechBuilderConfigurationFlow/TechBuilderConfigurationFlow';
import BeginDeposit from './components/BeginDeposit';
import BeginRunAuto from './components/BeginRunAuto';
import { closeTechBuilderWarningInfo } from '../../redux/actions/modalActions';
import TechBuilderWarningInfo from './components/TechBuilderWarningInfo';
import { BlockingModal } from './components/BlockingModal';
import SearchAutoSelectRuleName from './components/SearchAutoSelectRuleName';
import MessageModal from './components/MessageModal';
import RecommendModal from './components/RecommendModal/index';

const ModalContainer = () => {
  const dispatch = useDispatch();

  const {
    isOpen: isOpenConfirmation,
    title: confirmationTitle,
    callback: confirmationCallback,
    bodyText: confirmationBodyText,
    buttonBackText: confirmationButtonBackText,
    buttonNextText: confirmationButtonNextText,
    isOverlap: confirmationIsOverlap,
    successButtonIsGreen: confirmationSuccessButtonIsGreen,
  } = useSelector((state) => state.modals.confirmation);

  const handleCloseConfirmation = useCallback(() => {
    dispatch(closeConfirmationModal());
  }, [dispatch]);

  const { isOpen: isOpenCopyright, isOverlap: copyrightIsOverlap } = useSelector((state) => state.modals.copyrightInfo);

  const handleCloseCopyrightInfo = useCallback(() => {
    dispatch(closeCopyrightInfo());
  }, [dispatch]);

  const {
    isOpen: isOpenInputConfirmation,
    title: inputConfirmationTitle,
    callback: inputConfirmationCallback,
    bodyText: inputConfirmationBodyText,
    buttonBackText: inputConfirmationButtonBackText,
    buttonNextText: inputConfirmationButtonNextText,
    value: inputConfirmationValue,
    passValueWithKey: inputConfirmationValueKey,
    isOverlap: inputConfirmationIsOverlap,
  } = useSelector((state) => state.modals.inputConfirmation);

  const handleCloseInputConfirmation = useCallback(() => {
    dispatch(closeInputConfirmationModal());
  }, [dispatch]);

  const handleAcceptConfirmation = useCallback(() => {
    dispatch(closeConfirmationModal());
    if (confirmationCallback) {
      confirmationCallback();
    }
  }, [dispatch, confirmationCallback]);

  const { isOpen: isOpenPortfolioManualTradeDetail, data: portfolioManualTradeDetailData } = useSelector(
    (state) => state.modals.portfolioManualTradeDetail,
  );

  const handleClosePortfolioManualTradeDetail = useCallback(() => {
    dispatch(closePortfolioManualTradeDetailModal());
  }, [dispatch]);

  const { isOpen: isOpenPortfolioAutoTradeDetail, data: portfolioAutoTradeDetailData } = useSelector(
    (state) => state.modals.portfolioAutoTradeDetail,
  );

  const handleClosePortfolioAutoTradeDetail = useCallback(() => {
    dispatch(closePortfolioAutoTradeDetailModal());
  }, [dispatch]);

  const { isOpen: isOpenBuilderConfigurationFlowModal } = useSelector((state) => state.modals.builderConfigurationFlow);

  const handleCloseBuilderConfigurationFlowModal = useCallback(() => {
    dispatch(closeBuilderConfigurationFlowModal());
  }, [dispatch]);

  const { isOpen: isOpenTechBuilderConfigurationFlowModal } = useSelector(
    (state) => state.modals.techBuilderConfigurationFlow,
  );

  const handleCloseTechBuilderConfigurationFlowModal = useCallback(() => {
    dispatch(closeTechBuilderConfigurationFlowModal());
  }, [dispatch]);

  const { isOpen: isOpenBuilderAddToCartModal, isOrderSetting: isOrderSettingBuilderAddToCartModal } = useSelector(
    (state) => state.modals.builderAddToCart,
  );

  const handleCloseBuilderAddToCartModal = useCallback(() => {
    dispatch(closeBuilderAddToCartModal());
  }, [dispatch]);

  const { isOpen: isOpenBuilderAddToPortfolio, isOrderSetting: isOrderSettingBuilderAddToPortfolio } = useSelector(
    (state) => state.modals.builderAddToPortfolio,
  );

  const handleCloseBuilderAddToPortfolioModal = useCallback(() => {
    dispatch(closeBuilderAddToPortfolioModal());
  }, [dispatch]);

  const { isOpen: isOpenBuilderAddToPortfolioSuccess, orderName: builderAddToPortfolioSuccessOrderName } = useSelector(
    (state) => state.modals.builderAddToPortfolioSuccess,
  );

  const handleCloseBuilderAddToPortfolioSuccessModal = useCallback(() => {
    dispatch(closeBuilderAddToPortfolioSuccessModal());
  }, [dispatch]);

  const { isOpen: isOpenBuilderAddToPortfolioFail, orderName: builderAddToPortfolioFailOrderName } = useSelector(
    (state) => state.modals.builderAddToPortfolioFail,
  );

  const handleCloseBuilderAddToPortfolioFailModal = useCallback(() => {
    dispatch(closeBuilderAddToPortfolioFailModal());
  }, [dispatch]);

  const { isOpen: isOpenChartMakeAddToCart } = useSelector((state) => state.modals.chartMakeAddToCart);
  const handleCloseChartMakeAddToCart = useCallback(() => dispatch(closeChartMakeAddToCartModal()), [dispatch]);

  const { isOpen: isOpenChartMakeRunNowConfirmation } = useSelector(
    (state) => state.modals.chartMakeRunNowConfirmation,
  );

  const handleCloseChartMakeRunNowConfirmation = useCallback(
    () => dispatch(closeChartMakeRunNowConfirmationModal()),
    [dispatch],
  );

  const { isOpen: isOpenTechAddToCartModal, isOrderSetting: isOrderSettingTechAddToCart } = useSelector(
    (state) => state.modals.techAddToCart,
  );

  const handleCloseTechAddToCartModal = useCallback(() => {
    dispatch(closeTechAddToCartModal());
  }, [dispatch]);

  const { isOpen: isOpenTechAddToPortfolio, isOrderSetting: isOrderSettingTechAddToPortfolio } = useSelector(
    (state) => state.modals.techAddToPortfolio,
  );

  const handleCloseTechAddToPortfolioModal = useCallback(() => {
    dispatch(closeTechAddToPortfolioModal());
  }, [dispatch]);

  const { isOpen: isOpenTechAddToPortfolioSuccess, orderName: techAddToPortfolioSuccessOrderName } = useSelector(
    (state) => state.modals.techAddToPortfolioSuccess,
  );

  const handleCloseTechAddToPortfolioSuccessModal = useCallback(() => {
    dispatch(closeTechAddToPortfolioSuccessModal());
  }, [dispatch]);

  const { isOpen: isOpenTechAddToPortfolioFail, orderName: techAddToPortfolioFailOrderName } = useSelector(
    (state) => state.modals.techAddToPortfolioFail,
  );

  const handleCloseTechAddToPortfolioFailModal = useCallback(() => {
    dispatch(closeTechAddToPortfolioFailModal());
  }, [dispatch]);

  const { isOpen: isOpenManualTradeTableCloseOrder } = useSelector((state) => state.modals.manualTradeTableCloseOrder);

  const handleCloseManualTradeTableCloseOrder = useCallback(
    () => dispatch(closeManualTradeTableCloseOrder()),
    [dispatch],
  );

  const {
    isOpen: isOpenManualTradeTableCloseOrderConfirmation,
    callback: manualTradeTableCloseOrderConfirmationCallback,
    data: manualTradeTableCloseOrderConfirmationData,
  } = useSelector((state) => state.modals.manualTradeTableCloseOrderConfirmation);

  const handleCloseManualTradeTableCloseOrderConfirmation = useCallback(
    () => dispatch(closeManualTradeTableCloseOrderConfirmation()),
    [dispatch],
  );

  const { isOpen: isOpenManualTradeCurrencySettingsModal } = useSelector(
    (state) => state.modals.manualTradeCurrencySettingsModal,
  );

  const handleCloseManualTradeCurrencySettings = useCallback(() => {
    dispatch(closeManualTradeCurrencySettings());
  }, [dispatch]);

  const { isOpen: isOpenManualTradeConfigModal } = useSelector((state) => state.modals.manualTradeConfigModal);

  const handleCloseManualTradeConfig = useCallback(() => {
    dispatch(closeManualTradeConfig());
  }, [dispatch]);

  const { isOpen: isOpenManualTradeNewsModal } = useSelector((state) => state.modals.manualTradeNewsModal);

  const handleCloseManualTradeNewsModal = useCallback(() => {
    dispatch(closeManualTradeNews());
  }, [dispatch]);

  const {
    isOpen: isOpenManualTradeConfirmation,
    callback: manualTradeConfirmationCallback,
    orderType: manualTradeConfirmationOrderType,
  } = useSelector((state) => state.modals.manualTradeConfirmation);
  const handleCloseManualTradeConfirmation = useCallback(() => {
    dispatch(closeManualTradeConfirmation());
  }, [dispatch]);

  const {
    isOpen: isOpenManualTradeTableChangeOrder,
    orderId: manualTradeTableChangeOrderOrderId,
    isManual: isManualManualTradeTableChangeOrder,
  } = useSelector((state) => state.modals.manualTradeTableChangeOrder);

  const handleCloseManualTradeTableChangeOrder = useCallback(() => {
    dispatch(closeManualTradeTableChangeOrder());
  }, [dispatch]);

  const { isOpen: isOpenMultiEdit } = useSelector((state) => state.modals.multiEdit);
  const handleCloseMultiEdit = useCallback(() => {
    dispatch(closeMultiEdit());
  }, [dispatch]);

  const { isOpen: isOpenMultiEditCheckComfirm, rowNum: rowNumMultiEditCheckComfirm } = useSelector(
    (state) => state.modals.multiEditComfirmCheck,
  );
  const handleCloseMultiEditCheckComfirm = useCallback(() => {
    dispatch(closeMultiEditCheckComfirm());
  }, [dispatch]);

  const { isOpen: isOpenMultiEditSelect } = useSelector((state) => state.modals.multiEditSelect);
  const handleCloseMultiEditSelect = useCallback(() => {
    dispatch(closeMultiEditSelect());
  }, [dispatch]);

  const { isOpen: isOpenMultiEditFail } = useSelector((state) => state.modals.multiEditFail);
  const handleCloseMultiEditFail = useCallback(() => {
    dispatch(closeMultiEditFail());
  }, [dispatch]);

  const { isOpen: isOpenPortfolioAutoTradeDetailOrderTypeModal, data: portfolioAutoTradeDetailOrderTypeData } =
    useSelector((state) => state.modals.portfolioAutoTradeDetailOrderTypeModal);

  const handleClosePortfolioAutoTradeDetailOrderType = useCallback(
    () => dispatch(closePortfolioAutoTradeDetailOrderType()),
    [dispatch],
  );

  const { isOpen: isOpenAutoSelectRunNowConfirmation, data: autoSelectRunNowConfirmationData } = useSelector(
    (state) => state.modals.autoSelectRunNowConfirmation,
  );

  const { isOpen: isOpenAutoSelectAddToCartConfirmation, data: autoSelectAddToCartConfirmationData } = useSelector(
    (state) => state.modals.autoSelectAddToCartConfirmation,
  );

  const handleCloseAutoSelectRunNowConfirmation = useCallback(
    () => dispatch(closeAutoSelectRunNowConfirmation()),
    [dispatch],
  );

  const handleCloseAutoSelectAddToCartConfirmation = useCallback(
    () => dispatch(closeAutoSelectAddToCartConfirmation()),
    [dispatch],
  );

  const { isOpen: isOpenHelpInfo } = useSelector((state) => state.modals.helpInfo);
  const handleCloseHelpInfo = useCallback(() => {
    dispatch(closeHelpInfo());
  }, [dispatch]);

  const { isOpen: isOpenNotificationSettings } = useSelector((state) => state.modals.notificationSettings);
  const handleCloseNotificationSettings = useCallback(() => {
    dispatch(closeNotificationSettings());
  }, [dispatch]);

  const { isOpen: isOpenAgreementInfo } = useSelector((state) => state.modals.agreementInfo);
  const handleCloseAgreementInfo = useCallback(() => {
    dispatch(closeAgreementInfo());
  }, [dispatch]);

  const { isOpen: isOpenReportsDetails } = useSelector((state) => state.modals.reportsDetails);
  const handleCloseReportsDetails = useCallback(() => {
    dispatch(closeReportsDetails());
  }, [dispatch]);

  const { isOpen: isOpenAdvertisement } = useSelector((state) => state.advertisement);
  const handleCloseAdvertisement = useCallback(() => {
    dispatch(closeAdvertisement());
  }, [dispatch]);

  const { isOpen: isOpenMessageModal } = useSelector((state) => state.message);
  const handleCloseMessageModal = useCallback(() => {
    dispatch(closeMessageModal());
  }, [dispatch]);

  const {
    isOpen: isOpenErrorInfo,
    message: errorInfoMessage,
    title: errorInfoTitle,
    forceLogout: errorInfoForceLogout,
    withRefreshButton: errorInfoWithRefreshButton,
    linkURL: errorInfoLinkUrl,
    buttonText: errorInfoButtonText,
  } = useSelector((state) => state.error.errorInfoModal);
  const handleCloseErrorInfo = useCallback(() => {
    dispatch(closeErrorInfoModal());
  }, [dispatch]);

  const { isOpen: isOpenBuilderWarningInfo } = useSelector((state) => state.modals.builderWarningInfo);
  const handleCloseBuilderWarningInfo = useCallback(() => {
    dispatch(closeBuilderWarningInfo());
  }, [dispatch]);

  const { isOpen: isOpenTechBuilderWarningInfo } = useSelector((state) => state.modals.techBuilderWarningInfo);
  const handleCloseTechBuilderWarningInfo = useCallback(() => {
    dispatch(closeTechBuilderWarningInfo());
  }, [dispatch]);

  const {
    isOpen: isOpenAutoSelectWarningInfo,
    termEndDate: autoSelectWarningInfoTermEndDate,
    isLabPreview: isLabPreviewAutoSelectWarningInfo,
  } = useSelector((state) => state.modals.autoSelectWarningInfo);
  const handleCloseAutoSelectWarningInfo = useCallback(() => {
    dispatch(closeAutoSelectWarningInfo());
  }, [dispatch]);

  const isOpenSocketError = useSelector((state) => state.socket.hasError);
  const socketErrorIsPublic = useSelector((state) => state.socket.isPublic);

  const { isOpen: isOpenStrategiesSuccess } = useSelector((state) => state.modals.strategiesSuccess);
  const handleCloseStrategiesSuccess = useCallback(() => {
    dispatch(closeStrategiesSuccessModal());
  }, [dispatch]);

  const { isOpen: isOpenYoutube } = useSelector((state) => state.modals.youtube);
  const handleCloseYoutube = useCallback(() => {
    dispatch(closeYoutubeModal());
  }, [dispatch]);

  const { isOpen: isOpenLabTagInfo } = useSelector((state) => state.modals.labTagInfo);
  const handleCloseLabTagInfo = useCallback(() => {
    dispatch(closeLabTagInfoModal());
  }, [dispatch]);

  const { isOpen: isOpenRangeOutConfirmation } = useSelector((state) => state.modals.rangeOutConfirmation);
  const handleCloseRangeOutConfirmation = useCallback(() => {
    dispatch(closeRangeOutConfirmation());
  }, [dispatch]);

  const { isOpen: isOpenTutorialModal } = useSelector((state) => state.modals.tutorialModal);
  const handleCloseTutorialModal = useCallback(() => {
    dispatch(closeTutorialModal());
  }, [dispatch]);

  const { isOpen: isOpenTutorialPortfolioDetail } = useSelector((state) => state.modals.tutorialPortfolioDetail);
  const handleCloseTutorialDetailModal = useCallback(() => {
    dispatch(closeTutorialPortfolioDetailModal());
  }, [dispatch]);

  const { isOpen: isOpenBeginDepositModal } = useSelector((state) => state.modals.beginDepositModal);
  const handleCloseBeginDepositModal = useCallback(() => {
    dispatch(closeBeginDepositModal());
  }, [dispatch]);

  const { isOpen: isOpenBeginRunAutoModal } = useSelector((state) => state.modals.beginRunAutoModal);
  const handleCloseBeginRunAutoModal = useCallback(() => {
    dispatch(closeBeginRunAutoModal());
  }, [dispatch]);

  const { isOpen: isOpenSearchAutoSelectRuleNameModal, autoSelectData } = useSelector(
    (state) => state.modals.searchAutoSelectRuleNameModal,
  );
  const handleCloseSearchAutoSelectRuleNameModal = useCallback(() => {
    dispatch(closeSearchAutoSelectRuleNameModal());
  }, [dispatch]);

  const { isOpen: isOpenRecommendModal } = useSelector((state) => state.recommend.recommendModal);
  const handleCloseRecommendModal = useCallback(() => {
    dispatch(getRecommendDataSuccess({ recommendData: null }));
    dispatch(closeRecommendModal());
  }, [dispatch]);

  return (
    <>
      {isOpenConfirmation && (
        <Confirmation
          isOpen={isOpenConfirmation}
          closeModal={handleCloseConfirmation}
          title={confirmationTitle}
          callback={handleAcceptConfirmation}
          bodyText={confirmationBodyText}
          buttonBackText={confirmationButtonBackText}
          buttonNextText={confirmationButtonNextText}
          isOverlap={confirmationIsOverlap}
          successButtonIsGreen={confirmationSuccessButtonIsGreen}
        />
      )}
      {isOpenCopyright && (
        <CopyrightInfo isOpen={isOpenCopyright} closeModal={handleCloseCopyrightInfo} isOverlap={copyrightIsOverlap} />
      )}
      {isOpenInputConfirmation && (
        <InputConfirmation
          isOpen={isOpenInputConfirmation}
          closeModal={handleCloseInputConfirmation}
          title={inputConfirmationTitle}
          callback={inputConfirmationCallback}
          bodyText={inputConfirmationBodyText}
          buttonBackText={inputConfirmationButtonBackText}
          buttonNextText={inputConfirmationButtonNextText}
          value={inputConfirmationValue}
          passValueWithKey={inputConfirmationValueKey}
          isOverlap={inputConfirmationIsOverlap}
        />
      )}
      {isOpenPortfolioManualTradeDetail && (
        <PortfolioManualTradeDetail
          isOpen={isOpenPortfolioManualTradeDetail}
          closeModal={handleClosePortfolioManualTradeDetail}
          data={portfolioManualTradeDetailData}
        />
      )}
      {isOpenPortfolioAutoTradeDetail && (
        <PortfolioAutoTradeDetail
          isOpen={isOpenPortfolioAutoTradeDetail}
          closeModal={handleClosePortfolioAutoTradeDetail}
          data={portfolioAutoTradeDetailData}
        />
      )}
      {isOpenBuilderConfigurationFlowModal && (
        <BuilderConfigurationFlow
          isOpen={isOpenBuilderConfigurationFlowModal}
          closeModal={handleCloseBuilderConfigurationFlowModal}
        />
      )}
      {isOpenTechBuilderConfigurationFlowModal && (
        <TechBuilderConfigurationFlow
          isOpen={isOpenTechBuilderConfigurationFlowModal}
          closeModal={handleCloseTechBuilderConfigurationFlowModal}
        />
      )}
      {isOpenBuilderAddToCartModal && (
        <BuilderAddToCart
          isOpen={isOpenBuilderAddToCartModal}
          closeModal={handleCloseBuilderAddToCartModal}
          isOrderSetting={isOrderSettingBuilderAddToCartModal}
        />
      )}
      {isOpenBuilderAddToPortfolio && (
        <BuilderAddToPortfolio
          isOpen={isOpenBuilderAddToPortfolio}
          closeModal={handleCloseBuilderAddToPortfolioModal}
          isOrderSetting={isOrderSettingBuilderAddToPortfolio}
        />
      )}
      {isOpenBuilderAddToPortfolioSuccess && (
        <BuilderAddToPortfolioSuccess
          isOpen={isOpenBuilderAddToPortfolioSuccess}
          closeModal={handleCloseBuilderAddToPortfolioSuccessModal}
          orderName={builderAddToPortfolioSuccessOrderName}
        />
      )}
      {isOpenBuilderAddToPortfolioFail && (
        <BuilderAddToPortfolioFail
          isOpen={isOpenBuilderAddToPortfolioFail}
          closeModal={handleCloseBuilderAddToPortfolioFailModal}
          orderName={builderAddToPortfolioFailOrderName}
        />
      )}
      {isOpenChartMakeAddToCart && (
        <ChartMakeAddToCart isOpen={isOpenChartMakeAddToCart} closeModal={handleCloseChartMakeAddToCart} />
      )}
      {isOpenChartMakeRunNowConfirmation && (
        <ChartMakeRunNowConfirmation
          isOpen={isOpenChartMakeRunNowConfirmation}
          closeModal={handleCloseChartMakeRunNowConfirmation}
        />
      )}
      {isOpenTechAddToCartModal && (
        <TechAddToCart
          isOpen={isOpenTechAddToCartModal}
          closeModal={handleCloseTechAddToCartModal}
          isOrderSetting={isOrderSettingTechAddToCart}
        />
      )}
      {isOpenTechAddToPortfolio && (
        <TechAddToPortfolio
          isOpen={isOpenTechAddToPortfolio}
          closeModal={handleCloseTechAddToPortfolioModal}
          isOrderSetting={isOrderSettingTechAddToPortfolio}
        />
      )}
      {isOpenTechAddToPortfolioSuccess && (
        <TechAddToPortfolioSuccess
          isOpen={isOpenTechAddToPortfolioSuccess}
          closeModal={handleCloseTechAddToPortfolioSuccessModal}
          orderName={techAddToPortfolioSuccessOrderName}
        />
      )}
      {isOpenTechAddToPortfolioFail && (
        <TechAddToPortfolioFail
          isOpen={isOpenTechAddToPortfolioFail}
          closeModal={handleCloseTechAddToPortfolioFailModal}
          orderName={techAddToPortfolioFailOrderName}
        />
      )}
      {isOpenManualTradeTableCloseOrder && (
        <ManualTradeTableCloseOrder
          isOpen={isOpenManualTradeTableCloseOrder}
          closeModal={handleCloseManualTradeTableCloseOrder}
        />
      )}
      {isOpenManualTradeTableCloseOrderConfirmation && (
        <ManualTradeTableCloseOrderConfirmation
          isOpen={isOpenManualTradeTableCloseOrderConfirmation}
          closeModal={handleCloseManualTradeTableCloseOrderConfirmation}
          callback={manualTradeTableCloseOrderConfirmationCallback}
          data={manualTradeTableCloseOrderConfirmationData}
        />
      )}
      {isOpenManualTradeCurrencySettingsModal && (
        <ManualTradeCurrencySettings
          isOpen={isOpenManualTradeCurrencySettingsModal}
          closeModal={handleCloseManualTradeCurrencySettings}
        />
      )}
      {isOpenManualTradeConfigModal && (
        <ManualTradeConfig isOpen={isOpenManualTradeConfigModal} closeModal={handleCloseManualTradeConfig} />
      )}
      {isOpenManualTradeNewsModal && (
        <ManualTradeNews isOpen={isOpenManualTradeNewsModal} closeModal={handleCloseManualTradeNewsModal} />
      )}
      {isOpenManualTradeConfirmation && (
        <ManualTradeConfirmation
          isOpen={isOpenManualTradeConfirmation}
          closeModal={handleCloseManualTradeConfirmation}
          callback={manualTradeConfirmationCallback}
          orderType={manualTradeConfirmationOrderType}
        />
      )}
      {isOpenManualTradeTableChangeOrder && (
        <ManualTradeTableChangeOrder
          isOpen={isOpenManualTradeTableChangeOrder}
          closeModal={handleCloseManualTradeTableChangeOrder}
          orderId={manualTradeTableChangeOrderOrderId}
          isManual={isManualManualTradeTableChangeOrder}
        />
      )}
      {isOpenMultiEdit && <MultiEdit isOpen={isOpenMultiEdit} closeModal={handleCloseMultiEdit} />}
      {isOpenMultiEditCheckComfirm && (
        <MultiEditCheckComfirm
          isOpen={isOpenMultiEditCheckComfirm}
          closeModal={handleCloseMultiEditCheckComfirm}
          rowNum={rowNumMultiEditCheckComfirm}
        />
      )}
      {isOpenMultiEditSelect && (
        <MultiEditSelect isOpen={isOpenMultiEditSelect} closeModal={handleCloseMultiEditSelect} />
      )}
      {isOpenMultiEditFail && <MultiEditFail isOpen={isOpenMultiEditFail} closeModal={handleCloseMultiEditFail} />}
      {isOpenPortfolioAutoTradeDetailOrderTypeModal && (
        <PortfolioAutoTradeDetailOrderType
          isOpen={isOpenPortfolioAutoTradeDetailOrderTypeModal}
          closeModal={handleClosePortfolioAutoTradeDetailOrderType}
          data={portfolioAutoTradeDetailOrderTypeData}
        />
      )}
      {isOpenAutoSelectRunNowConfirmation && (
        <AutoSelectRunNowConfirmation
          isOpen={isOpenAutoSelectRunNowConfirmation}
          closeModal={handleCloseAutoSelectRunNowConfirmation}
          data={autoSelectRunNowConfirmationData}
        />
      )}
      {isOpenAutoSelectAddToCartConfirmation && (
        <AutoSelectAddToCartConfirmation
          isOpen={isOpenAutoSelectAddToCartConfirmation}
          closeModal={handleCloseAutoSelectAddToCartConfirmation}
          data={autoSelectAddToCartConfirmationData}
        />
      )}
      {isOpenHelpInfo && <HelpInfo isOpen={isOpenHelpInfo} closeModal={handleCloseHelpInfo} />}
      {isOpenNotificationSettings && (
        <NotificationSettings isOpen={isOpenNotificationSettings} closeModal={handleCloseNotificationSettings} />
      )}
      {isOpenAgreementInfo && <AgreementInfo isOpen={isOpenAgreementInfo} closeModal={handleCloseAgreementInfo} />}
      {isOpenReportsDetails && <ReportsDetails isOpen={isOpenReportsDetails} closeModal={handleCloseReportsDetails} />}
      {/* don't display advertisement if some error occur or agreementInfo not accepted */}
      {isOpenAdvertisement && !isOpenErrorInfo && !isOpenAgreementInfo && (
        <Advertisement isOpen={isOpenAdvertisement} closeModal={handleCloseAdvertisement} />
      )}
      {isOpenMessageModal && <MessageModal isOpen={isOpenMessageModal} closeModal={handleCloseMessageModal} />}
      {isOpenErrorInfo && (
        <ErrorInfo
          isOpen={isOpenErrorInfo}
          closeModal={handleCloseErrorInfo}
          message={errorInfoMessage}
          title={errorInfoTitle}
          forceLogout={errorInfoForceLogout}
          withRefreshButton={errorInfoWithRefreshButton}
          linkURL={errorInfoLinkUrl}
          buttonText={errorInfoButtonText}
        />
      )}
      {isOpenBuilderWarningInfo && (
        <BuilderWarningInfo isOpen={isOpenBuilderWarningInfo} closeModal={handleCloseBuilderWarningInfo} />
      )}
      {isOpenTechBuilderWarningInfo && (
        <TechBuilderWarningInfo isOpen={isOpenTechBuilderWarningInfo} closeModal={handleCloseTechBuilderWarningInfo} />
      )}
      {isOpenAutoSelectWarningInfo && (
        <AutoSelectWarningInfo
          isOpen={isOpenAutoSelectWarningInfo}
          closeModal={handleCloseAutoSelectWarningInfo}
          termEndDate={autoSelectWarningInfoTermEndDate}
          isLabPreview={isLabPreviewAutoSelectWarningInfo}
        />
      )}
      {isOpenSocketError && <SocketError isOpen={isOpenSocketError} isPublic={socketErrorIsPublic} />}
      {isOpenStrategiesSuccess && (
        <StrategiesSuccess isOpen={isOpenStrategiesSuccess} closeModal={handleCloseStrategiesSuccess} />
      )}
      {isOpenYoutube && <YoutubeModal isOpen={isOpenYoutube} closeModal={handleCloseYoutube} />}

      {isOpenLabTagInfo && <LabCategoryInfo isOpen={isOpenLabTagInfo} closeModal={handleCloseLabTagInfo} />}

      {isOpenTutorialModal && <Tutorial isOpen={isOpenTutorialModal} closeModal={handleCloseTutorialModal} />}
      {isOpenTutorialPortfolioDetail && (
        <TutorialPortfolioDetail isOpen={isOpenTutorialPortfolioDetail} closeModal={handleCloseTutorialDetailModal} />
      )}

      {isOpenBeginDepositModal && (
        <BeginDeposit isOpen={isOpenBeginDepositModal} closeModal={handleCloseBeginDepositModal} />
      )}

      {isOpenBeginRunAutoModal && (
        <BeginRunAuto isOpen={isOpenBeginRunAutoModal} closeModal={handleCloseBeginRunAutoModal} />
      )}

      {isOpenSearchAutoSelectRuleNameModal && (
        <SearchAutoSelectRuleName
          isOpen={isOpenSearchAutoSelectRuleNameModal}
          closeModal={handleCloseSearchAutoSelectRuleNameModal}
          autoSelectData={autoSelectData}
        />
      )}

      {isOpenRangeOutConfirmation && (
        <RangeOutConfirmation isOpen={isOpenRangeOutConfirmation} closeModal={handleCloseRangeOutConfirmation} />
      )}

      {isOpenRecommendModal && <RecommendModal isOpen={isOpenRecommendModal} closeModal={handleCloseRecommendModal} />}

      <BlockingModal />
      <MultiEditNotification />
    </>
  );
};

export default memo(ModalContainer);
