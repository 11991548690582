/* eslint-disable-next-line import/no-unresolved */
import { useMemo } from 'react';
import { SL_SETTING_ALL, SL_SETTING_NO, SL_SETTING_SOME } from '../constants';

const CHECK_SL_FUNC = ({ sl }) => sl != null;

export const useSimulationSummary = ({ loading, simulationTradesData, autoTradeSettingsData }) => {
  return useMemo(() => {
    if (loading) {
      return { sides: null, slSetting: null, newCount: null, closeCount: null };
    }
    const count = autoTradeSettingsData.length;
    let slSetting = SL_SETTING_NO;
    if (autoTradeSettingsData.every(CHECK_SL_FUNC)) {
      slSetting = SL_SETTING_ALL;
    } else if (autoTradeSettingsData.some(CHECK_SL_FUNC)) {
      slSetting = SL_SETTING_SOME;
    }
    const ret = simulationTradesData.reduce(
      (summary, row) => {
        let { newCount, closeCount } = summary;
        const { openTime, closeTime } = row;
        if (openTime) {
          newCount += 1;
        }
        if (closeTime) {
          closeCount += 1;
        }
        return { newCount, closeCount };
      },
      { newCount: 0, closeCount: 0 },
    );
    const sideSet = autoTradeSettingsData.reduce((acc, curr) => {
      const { side } = curr;
      if (side) {
        acc.add(side);
      }
      return acc;
    }, new Set());
    const sides = Array.from(sideSet);
    return { count, slSetting, sides, ...ret };
  }, [loading, simulationTradesData, autoTradeSettingsData]);
};
