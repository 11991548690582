/* eslint-disable-next-line import/no-unresolved */
import { useMemo } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector } from 'react-redux';
import { publishedLabServiceIdSelector } from '../../redux/labs';

export const useGetLabServiceId = (isUseLabServiceId) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const labServiceId = useSelector(publishedLabServiceIdSelector);

  return useMemo(() => (isUseLabServiceId ? labServiceId : serviceId), [serviceId, labServiceId, isUseLabServiceId]);
};
