export const getStockLeft = (strategyGroup) => {
  if (strategyGroup == null) {
    return 0;
  }
  if (strategyGroup.labId == null) {
    return strategyGroup.stockLeft ?? 0;
  }
  return strategyGroup.simulationStats?.stockLeft ?? 0;
};

export const normalizeSelectionStrategy = (strategy) => {
  const {
    strategyId,
    strategySets,
    strategyDetail: { instrumentId, name, serviceId, itemList, simulationTradeList, simulationStats },
  } = strategy;
  return {
    strategyId,
    instrumentId,
    serviceId,
    name,
    itemList,
    strategySets,
    simulationTradeList,
    simulationStats,
  };
};

export const normalizeLabStrategy = (strategy, serviceId) => {
  const { id, instrumentId, name, itemList, strategySets, simulationTradeList, simulationStats } = strategy;
  return {
    strategyId: Number(id),
    instrumentId,
    serviceId,
    name,
    itemList,
    strategySets,
    simulationTradeList,
    simulationStats,
  };
};
