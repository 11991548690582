import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as JPCJPY } from '../../assets/flagsCFD/JPCJPY.svg';
import { ReactComponent as DJCUSD } from '../../assets/flagsCFD/DJCUSD.svg';
import { ReactComponent as NACUSD } from '../../assets/flagsCFD/NACUSD.svg';
import { ReactComponent as SPCUSD } from '../../assets/flagsCFD/SPCUSD.svg';
import { ReactComponent as RTCUSD } from '../../assets/flagsCFD/RTCUSD.svg';
import { ReactComponent as GECEUR } from '../../assets/flagsCFD/GECEUR.svg';
import { ReactComponent as UKCGBP } from '../../assets/flagsCFD/UKCGBP.svg';

import styles from './customFlagCFD.module.scss';

const CFD_FLAG_TYPE = {
  JPCJPY,
  DJCUSD,
  NACUSD,
  SPCUSD,
  RTCUSD,
  GECEUR,
  UKCGBP,
  JPC_MJPY: JPCJPY,
  DJC_MUSD: DJCUSD,
  NAC_MUSD: NACUSD,
  SPC_MUSD: SPCUSD,
  RTC_MUSD: RTCUSD,
  GEC_MEUR: GECEUR,
  UKC_MGBP: UKCGBP,
  JPC_APJPY: JPCJPY,
  DJC_APUSD: DJCUSD,
  NAC_APUSD: NACUSD,
  SPC_APUSD: SPCUSD,
  RTC_APUSD: RTCUSD,
  GEC_APEUR: GECEUR,
  UKC_APGBP: UKCGBP,
};

const CustomFlagCFD = ({ flagType, className }) => {
  const currencyFlagType = flagType.replace('/', '').split('.')[0];
  const Tag = useMemo(() => CFD_FLAG_TYPE[currencyFlagType], [currencyFlagType]);

  if (!Tag) return null;
  return <Tag className={classNames(styles.flagImage, { [className]: className })} />;
};

CustomFlagCFD.propTypes = {
  flagType: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CustomFlagCFD.defaultProps = {
  className: '',
};

export default memo(CustomFlagCFD);
