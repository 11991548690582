import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import PropTypes from 'prop-types';
import { SORT_DESCENDING, SORT_ASCENDING } from 'shared-modules/constants/manualTrade';
import {
  DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_BY,
  DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_DIR,
} from 'shared-modules/constants/portfolio';
import { CFD, ETF, FX, POSITIONS_EMPTY_MESSAGE } from 'shared-modules/constants';
import { sortPositionsTable } from 'shared-modules/services';
import { changeSelectedPositionsMetaInfo } from 'shared-modules/redux/actions/portfolioActions';
import { Table } from '../../../../../../../../components';
import CellPortfolioPositionTableActions from '../../../../../CellPortfolioPositionTableActions';
import styles from '../../detailTable.module.scss';
import { getModalPositionsTableTemplate } from '../../../../../../../../services/tableTemplate';

const DEFAULT_SORTING = [
  {
    id: DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_BY,
    desc: DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_DIR === SORT_DESCENDING,
  },
];

const RESIZED = {
  [FX]: {
    KEY: 'portfolioFXAutoTradePositionsTable',
    COLUMNS: [78, 68, 40, 78, 78, 78, 74, 74, 84, 88, 80, 74],
  },
  [ETF]: {
    KEY: 'portfolioETFAutoTradePositionsTable',
    COLUMNS: [80, 68, 40, 74, 78, 74, 74, 74, 74, 95, 87, 74, 74],
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    KEY: 'portfolioCFDAutoTradePositionsTable',
    COLUMNS: [80, 68, 40, 74, 78, 74, 74, 74, 74, 95, 87],
  },
};

const TablePositions = ({ id, serviceId }) => {
  const dispatch = useDispatch();

  const tableDataRaw = useSelector((state) => state.currencies.positions[serviceId]);
  const isLoadingPositionsData = useSelector((state) => state.manualTrade.positionsDataMetaInfo[serviceId].loading);
  const selectedPositionsMetaInfo = useSelector((state) => state.portfolio.selectedPositionsMetaInfo[serviceId]);

  const tableMetaInfo = useMemo(
    () => ({
      loading: isLoadingPositionsData,
      ...selectedPositionsMetaInfo,
    }),
    [isLoadingPositionsData, selectedPositionsMetaInfo],
  );

  const selectedPositionsData = useMemo(
    () =>
      tableDataRaw
        .filter((position) => position.apGroupId === id)
        .filter((position) => (tableMetaInfo.side ? position.side === tableMetaInfo.side : true)),
    [id, tableDataRaw, tableMetaInfo.side],
  );

  const resized = useMemo(() => {
    const { KEY, COLUMNS } = RESIZED[serviceId];
    return {
      key: KEY,
      default: [...COLUMNS],
    };
  }, [serviceId]);

  const defaultSorting = useMemo(() => {
    if (tableMetaInfo.sortBy == null || tableMetaInfo.sortDir == null) {
      return DEFAULT_SORTING;
    }
    return [
      {
        id: tableMetaInfo.sortBy,
        desc: tableMetaInfo.sortDir === SORT_DESCENDING,
      },
    ];
  }, [tableMetaInfo]);

  const columns = useMemo(
    () => [
      ...getModalPositionsTableTemplate(serviceId),
      {
        Header: '',
        accessor: 'tableButton',
        disableSortBy: true,
        sticky: 'right',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <CellPortfolioPositionTableActions isForInstrument serviceId={serviceId} row={row} />,
      },
    ],
    [serviceId],
  );

  const sortingHandler = useCallback(
    ({ id: sortBy, desc: isDesc }) => {
      const sortDir = isDesc ? SORT_DESCENDING : SORT_ASCENDING;
      const { sortBy: previousSortBy, sortDir: previousSortDir } = tableMetaInfo;
      if (sortBy === previousSortBy && sortDir === previousSortDir) {
        return;
      }
      batch(() => {
        dispatch(changeSelectedPositionsMetaInfo({ key: 'sortBy', value: sortBy, serviceId }));
        dispatch(changeSelectedPositionsMetaInfo({ key: 'sortDir', value: sortDir, serviceId }));
      });
    },
    [dispatch, tableMetaInfo, serviceId],
  );

  const filterHandler = useCallback(
    ({ key, value }) => {
      if (['side'].includes(key)) {
        dispatch(changeSelectedPositionsMetaInfo({ key, value, serviceId }));
      }
    },
    [dispatch, serviceId],
  );

  const plRef = useRef({});
  const positionsUnrealizedProfitLoss = useSelector((state) => state.currencies.positionsUnrealizedProfitLoss);
  const positionsUnrealizedProfitLossContainer = useRef({});

  useEffect(() => {
    positionsUnrealizedProfitLossContainer.current = positionsUnrealizedProfitLoss;
  }, [positionsUnrealizedProfitLoss]);

  useEffect(() => {
    plRef.current = positionsUnrealizedProfitLoss;
  }, [positionsUnrealizedProfitLoss]);

  const tableData = useMemo(
    () => [
      ...selectedPositionsData
        .sort((a, b) =>
          sortPositionsTable({
            a,
            b,
            sortBy: tableMetaInfo.sortBy,
            sortDir: tableMetaInfo.sortDir,
            plRef,
          }),
        )
        .map((data) => ({ ...data, totalPl: data.pl + data.unrealizedSwapPl })),
    ],
    [selectedPositionsData, tableMetaInfo],
  );

  return (
    <Table
      key={serviceId}
      tableData={tableData}
      columns={columns}
      resized={resized}
      emptyText={POSITIONS_EMPTY_MESSAGE}
      className={styles.table}
      tableMetaInfo={tableMetaInfo}
      defaultSorting={defaultSorting}
      sortingHandler={sortingHandler}
      filterHandler={filterHandler}
      useServerSorting
    />
  );
};

TablePositions.propTypes = {
  id: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
};

export default memo(TablePositions);
