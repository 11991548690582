import React, { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TUTORIAL_MODE_SELECT_CHILD_TAG, TUTORIAL_MODE_SELECT_PARENT_TAG } from 'shared-modules/constants/tutorial';
import { changeParentTagId, setInitialChildTag } from 'shared-modules/redux/actions/autoSelectActions';
import { push } from 'shared-modules/redux/actions/routerActions';
import { updateIsFirstLogin } from 'shared-modules/redux/actions/settingsActions';
import {
  STEP1_TITLE,
  STEP1_MESSAGE,
  STEP2_TITLE,
  STEP2_MESSAGE,
  STEP3_TITLE,
  STEP3_MESSAGE,
  STEP4_TITLE,
  STEP4_MESSAGE,
  STEP5_TITLE,
  STEP5_MESSAGE,
  STEP6_TITLE,
  STEP6_MESSAGE,
  STEP7_TITLE,
  STEP7_MESSAGE,
  STEP8_TITLE,
  STEP8_MESSAGE,
  STEP9_TITLE,
  STEP9_MESSAGE,
  STEP10_TITLE,
  STEP10_MESSAGE,
  STEP11_TITLE,
  STEP11_MESSAGE,
  STEP12_TITLE,
  STEP12_MESSAGE,
  STEP13_TITLE,
  STEP13_MESSAGE,
  STEP14_TITLE,
  STEP14_MESSAGE,
  STEP15_TITLE,
  STEP15_MESSAGE,
  STEP16_TITLE,
  STEP16_MESSAGE,
  CENTER,
  LEFT,
  RIGHT,
  TOP,
  MIDDLE,
  BOTTOM,
} from '../../../../constants/tutorial/index';
import { AUTO_SELECT, HOME } from '../../../../constants';
import recomend from '../../../../assets/tutorial/recomend.png';
import { Button } from '../../../../components';
import useTutorial from './useTutorialHooks';
import styles from './tutorial.module.scss';

const LAST_STEP = 16;

const stepMessage = {
  1: { title: STEP1_TITLE, message: STEP1_MESSAGE },
  2: { title: STEP2_TITLE, message: STEP2_MESSAGE },
  3: { title: STEP3_TITLE, message: STEP3_MESSAGE },
  4: { title: STEP4_TITLE, message: STEP4_MESSAGE },
  5: { title: STEP5_TITLE, message: STEP5_MESSAGE },
  6: { title: STEP6_TITLE, message: STEP6_MESSAGE },
  7: { title: STEP7_TITLE, message: STEP7_MESSAGE },
  8: { title: STEP8_TITLE, message: STEP8_MESSAGE },
  9: { title: STEP9_TITLE, message: STEP9_MESSAGE },
  10: { title: STEP10_TITLE, message: STEP10_MESSAGE },
  11: { title: STEP11_TITLE, message: STEP11_MESSAGE },
  12: { title: STEP12_TITLE, message: STEP12_MESSAGE },
  13: { title: STEP13_TITLE, message: STEP13_MESSAGE },
  14: { title: STEP14_TITLE, message: STEP14_MESSAGE },
  15: { title: STEP15_TITLE, message: STEP15_MESSAGE },
  16: { title: STEP16_TITLE, message: STEP16_MESSAGE },
};

const handleStopPropagation = (e) => e.stopPropagation();

export const Tutorial = memo(({ isOpen, closeModal }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [isInterrupt, setInterrupt] = useState(false);

  const incrementStep = useCallback(() => {
    setStep(step + 1);
  }, [step, setStep]);

  const decrementStep = useCallback(() => {
    setStep(step - 1);
  }, [step, setStep]);

  const interrupt = useCallback(() => {
    setInterrupt(true);
  }, [setInterrupt]);

  const resume = useCallback(() => {
    setInterrupt(false);
  }, [setInterrupt]);

  const exit = useCallback(() => {
    setInterrupt(false);
    closeModal();
    dispatch(updateIsFirstLogin());
    dispatch(push(`/${HOME}`));
  }, [closeModal, dispatch]);

  const { next, previous, modalStyle, isReadyNext } = useTutorial(step, incrementStep, decrementStep, isInterrupt);

  const openFinishPage = useCallback(() => {
    closeModal();
    // changeParentTagIdについては、やってはいるが、実は意味がない。
    // セットしても、遷移時に消されて、結局「すべて」になる。「すべて」以外が必要な場合、使用箇所の要修正
    dispatch(changeParentTagId({ parentTagId: TUTORIAL_MODE_SELECT_PARENT_TAG }));
    dispatch(setInitialChildTag({ fullName: TUTORIAL_MODE_SELECT_CHILD_TAG }));
    dispatch(push(`/${AUTO_SELECT}`));
  }, [dispatch, closeModal]);

  const body = useMemo(() => {
    if (isInterrupt) {
      return (
        <>
          <div className={styles.message}>
            <div>チュートリアルを中断してもよろしいでしょうか。</div>
            <div>※左上の三本線のアイコンをクリックして、いつでも確認することができます。</div>
          </div>
          <div className={styles.footer}>
            <Button onClick={resume} secondary>
              再開する
            </Button>
            <Button onClick={exit}>終了する</Button>
          </div>
        </>
      );
    }

    if (step === LAST_STEP) {
      return (
        <div>
          <div className={classNames(styles.title, styles.center)}>{stepMessage[step]?.title}</div>
          <div className={styles.center}>{stepMessage[step]?.message}</div>

          <div className={styles.recomendImgContainer}>
            <img src={recomend} loading="lazy" className={styles.recomendImg} alt="" />
          </div>

          <div className={styles.center}>{stepMessage[step]?.message2}</div>

          <div className={styles.lastFooter}>
            <Button className={styles.endButton} onClick={openFinishPage}>
              セレクトでみる
            </Button>
          </div>
          <div className={styles.interrupt}>
            <div onClick={interrupt} role="button" aria-hidden>
              チュートリアルを終了する
            </div>
          </div>
        </div>
      );
    }
    const noPrevStep = [1];
    const noNextStep = [3, 9, 13, 14];

    return (
      <>
        <div className={styles.title}>{stepMessage[step]?.title}</div>
        <div className={styles.message}>{stepMessage[step]?.message}</div>
        <div className={styles.step}>
          {!noPrevStep.includes(step) && (
            <div>
              <Button onClick={previous} secondary width={160}>
                戻る
              </Button>
            </div>
          )}
          {!noNextStep.includes(step) && (
            <div>
              <Button onClick={next} width={160} disabled={!isReadyNext}>
                次へ
              </Button>
            </div>
          )}
        </div>
        <div className={styles.interrupt}>
          <div onClick={interrupt} role="button" aria-hidden>
            チュートリアルを終了する
          </div>
        </div>
      </>
    );
  }, [step, isInterrupt, interrupt, previous, next, resume, exit, openFinishPage, isReadyNext]);

  return (
    <Modal
      show={isOpen}
      centered
      onClick={handleStopPropagation}
      dialogClassName={classNames(
        styles.modal,
        {
          [styles.left]: modalStyle.horizontal === LEFT,
          [styles.right]: modalStyle.horizontal === RIGHT,
          [styles.center]: modalStyle.horizontal === CENTER,
          [styles.top]: modalStyle.vertical === TOP,
          [styles.middle]: modalStyle.vertical === MIDDLE,
          [styles.bottom]: modalStyle.vertical === BOTTOM,
        },
        modalStyle.custom ? styles[modalStyle.custom] : undefined,
      )}
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName={classNames(styles.backdrop, 'backdrop')}
      style={{ zIndex: 1101 }}
    >
      <Modal.Body bsPrefix={styles.body}>
        <div className={styles.container}>{body}</div>
      </Modal.Body>
    </Modal>
  );
});

Tutorial.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
