export const RETRY_MAX_TRIES = 3;
export const RETRY_DELAY = 300;
export const SOCKET_RETRY_DELAY = 3000;
export const SUCCESSFULLY_CLOSE_SOCKET_CODE = 1000;
export const DEBOUNCE_DELAY = 300;
export const DEBOUNCE_DELAY_ONE_SECOND = 1000;
export const THROTTLE_DELAY = 100;
export const DELAY_FOR_UPDATE_CURRENCY_PAIR = 600;
export const DELAY_FOR_UPDATE_NEWS = 60000;
export const DELAY_FOR_UPDATE_MARGIN = 10000;
export const DELAY_FOR_UPDATE_MESSAGE = 60000;

export const INVAST_LOGIN_ACCOUNT_TYPE = 'invast';
export const IMS_LOGIN_ACCOUNT_TYPE = 'ims';
export const SSO_LOGIN_ACCOUNT_TYPE = 'sso';
export const DEFAULT_LOGIN_ACCOUNT_TYPE = IMS_LOGIN_ACCOUNT_TYPE;
export const LOGIN_ACCOUNT_TYPES = {
  1: IMS_LOGIN_ACCOUNT_TYPE,
  2: INVAST_LOGIN_ACCOUNT_TYPE,
};

export const ORDERS_SOCKET_MESSAGES = {
  EXECUTING: 'executing',
  FILLED: 'filled',
  CANCELED: 'canceled',
  ACTIVE: 'active',
  NEW: 'new',
  EXPIRED: 'expired',
};
export const ACCOUNT_SOCKET_MESSAGES_FOR_UPDATE_MARGIN = ['withdraw', 'deposit', 'swap'];

export const LOSSCUT_STATUSES = {
  START: {
    event: 'losscut_start',
    ID: 1,
  },
  END: {
    event: 'losscut_end',
    ID: 0,
  },
};
export const LOSSCUT_IDS = {
  [LOSSCUT_STATUSES.START.event]: LOSSCUT_STATUSES.START.ID,
  [LOSSCUT_STATUSES.END.event]: LOSSCUT_STATUSES.END.ID,
};
export const LOSSCUT_EVENTS = [LOSSCUT_STATUSES.START.event, LOSSCUT_STATUSES.END.event];

export const UPDATE_MOBILE_CHART = 'UPDATE_MOBILE_CHART';
export const UPDATE_BUILDER_MOBILE_CHART = 'UPDATE_BUILDER_MOBILE_CHART';

export const SET_DISPLAY_CLOSED_ORDERS_MOBILE_CHART = 'SET_DISPLAY_CLOSED_ORDERS_MOBILE_CHART';
export const SET_DISPLAY_NEW_ORDERS_MOBILE_CHART = 'SET_DISPLAY_NEW_ORDERS_MOBILE_CHART';
export const SET_DISPLAY_POSITIONS_MOBILE_CHART = 'SET_DISPLAY_POSITIONS_MOBILE_CHART';

export const SETTINGS_SOCKET_TYPES = {
  MAINTENANCE: 'maintenance',
  MARKET: 'market',
};

export const IMS_SYSTEM_KBN_CLICK = '01';
export const IMS_SYSTEM_KBN_FX = '14';
export const IMS_SYSTEM_KBN_ETF = '17';
export const IMS_SYSTEM_KBN_CFD = '20';
export const IMS_SYSTEM_KBN_MAIMATE = '18';

export const IMS_EXEC_FAILED_MESSAGE =
  '処理に失敗した可能性があります。お手数ですが画面を更新しご確認の上、しばらくたってから再度お試しください。';

export const SYSTEM_KBN_NAMES = {
  [IMS_SYSTEM_KBN_CLICK]: 'くりっく365',
  [IMS_SYSTEM_KBN_FX]: 'トライオートFX',
  [IMS_SYSTEM_KBN_ETF]: 'トライオートETF',
  [IMS_SYSTEM_KBN_CFD]: 'トライオートCFD',
  [IMS_SYSTEM_KBN_MAIMATE]: 'マイメイト',
};

export const SETTINGS_SOCKET_SERVICES = {
  SELECTION: 'selection',
  FX: 'fx',
  ETF: 'etf',
  CFD: 'cfd',
};

export const SETTINGS_SOCKET_STATUSES = {
  START: 'start',
  END: 'end',
  OPEN: 'open',
  CLOSE: 'closed',
};

export const SYSTEM_NOT_MAINTENANCE_STATUS = '0';
export const SYSTEM_MAINTENANCE_STATUS = '1';

export const MAINTENANCE_REDIRECT_RESPONSE_CODE = 503;
export const NOT_FOUND_CODE = 404;

export const SESSION_EXPIRED_CODE = 403;
export const NEW_SESSION_WITH_THIS_CREDENTIAL_CODE = 401;
export const CONNECTION_TIMEOUT_CODE = 504;

export const DEFAULT_ERROR_CODE = 400;

export const STRATEGIES_TARGET = {
  CART: 'cart',
  PORTFOLIO: 'portfolio',
  LAB: 'lab',
};
