import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './monthItemButton.module.scss';

const MonthItemButton = memo(({ children, className, onClick, isActive }) => {
  return (
    <button
      type="button"
      className={classNames(className, styles.button, isActive ? styles.active : '')}
      onClick={onClick}
    >
      {children}
    </button>
  );
});

MonthItemButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isActive: PropTypes.bool,
};

MonthItemButton.defaultProps = {
  className: '',
  onClick: undefined,
  children: undefined,
  isActive: false,
};

export default MonthItemButton;
