import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useProfitLossExecutionsChartData } from 'shared-modules/services/hooks';
import ProfitLossChart from '../../../../../../components/ProfitLossChart';
import { Spin } from '../../../../../../components';
import styles from './detailChart.module.scss';

const chartMargin = {
  top: 0,
  left: 0,
  right: 20,
  bottom: 0,
};

const DetailChart = () => {
  const profitLossExecutionsDataIsLoading = useSelector((state) => state.portfolio.profitLossExecutionsDataIsLoading);

  const chartData = useProfitLossExecutionsChartData();

  return (
    <div className={styles.chart}>
      {profitLossExecutionsDataIsLoading ? (
        <Spin className={styles.loader} />
      ) : (
        <ProfitLossChart chartMargin={chartMargin} data={chartData} withTimeLabels useDefaultYAxisWidth />
      )}
    </div>
  );
};

export default memo(DetailChart);
