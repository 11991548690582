/* eslint-disable import/no-unresolved */
import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccountInfo } from 'shared-modules/hooks/useAccountInfo';
import {
  DAILY_FILE_NAMES,
  dailyValues,
  monthOptions,
  quarterOptions,
  REPORTS_TYPES,
  reportsSelectorValues,
} from '../../constants';
import {
  clearReportFile,
  clearReportsList,
  getReportFileRequest,
  getReportFileRequestEndLoading,
  getReportsListRequest,
  getReportsListRequestEndLoading,
} from '../../redux/actions/reportsActions';
import { useCheckAndNotifyAvailableService } from '../../hooks/service';
import {
  createDateStringWithoutTime,
  currentQuarter,
  getLastDayInMonthDate,
  getPreviousMonthWorkday,
  getPreviousWorkday,
  getPreviousYearWorkday,
  getQuarterDates,
  lastMonthValue,
  minReportsYear,
  yearOptionList,
  date30DaysBefore,
} from '../index';
import { ALL_SERVICES } from '../../constants/core';
import { getServiceName } from '../../utils';

const getDisabledArray = (array, limitVal, isMaxLimit = true) =>
  array.reduce((arr, { value }) => {
    if (isMaxLimit ? value > limitVal : value < limitVal) arr.push(value);
    return arr;
  }, []);

// todo: temporarily commented while ORDER_HISTORY_CSV removed from a list
// const CSV_ORDER_HISTORY_WARNING_TEXT =
//   '大量のデータの検索はできません。(指定期間が長い場合、検索に失敗する可能性があります。)指定期間を短くして検索してください。';

// TODO: matsuzaki100983 mobileはauthServiceIdを参照し、PCはuseErportsLogicで定義されたstateを参照している。
// ここは今後共通化するべきだが、一旦はisMobileの分岐で対応する。
export const useReportsLogic = ({ shouldResetToInitialState, isMobile = false } = {}) => {
  const dispatch = useDispatch();
  const storedServiceId = useSelector((state) => state.auth.serviceId);
  const [localServiceId, setServiceId] = useState(storedServiceId);
  const serviceId = isMobile ? storedServiceId : localServiceId;
  const serviceIdRef = useRef(serviceId);

  const {
    list,
    isListLoading,
    file,
    fileMetaData: { name, loading: isFileLoading } = {},
  } = useSelector((state) => state.reports);
  // if etfAccount is undefined, convert it to null for null checks later on
  const accountInfo = useAccountInfo();

  const options = useMemo(() => {
    return ALL_SERVICES.map((service) => ({
      id: service,
      value: getServiceName(service),
      isVisuallyDisabled: accountInfo[service].isNotAvailable,
    }));
  }, [accountInfo]);

  const [isSearchedView, setIsSearchedView] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState(REPORTS_TYPES.DAILY.ID);

  const areResultsShown = !isListLoading && isSearchedView;

  // todo: temporarily commented while ORDER_HISTORY_CSV removed from a list
  // const isOrderHistory = reportsType === REPORTS_TYPES.ORDER_HISTORY_CSV.ID;
  const isDaily = dailyValues.includes(selectedReportType);
  const isMonthly = selectedReportType === REPORTS_TYPES.MONTHLY.ID;
  const isQuarterly = selectedReportType === REPORTS_TYPES.QUARTERLY.ID;
  const isExecutionHistory = selectedReportType === REPORTS_TYPES.EXECUTION_HISTORY_CSV.ID;

  const reportOptions = reportsSelectorValues;

  const resetLocalData = useCallback(() => {
    setSelectedReportType(REPORTS_TYPES.DAILY.ID);
    setIsSearchedView(false);
  }, []);

  useEffect(() => {
    if (shouldResetToInitialState) resetLocalData();
  }, [shouldResetToInitialState, resetLocalData]);

  const clearForm = useCallback(() => {
    setIsSearchedView(false);

    dispatch(clearReportsList());
    dispatch(clearReportFile());
  }, [dispatch]);

  const checkAndNotify = useCheckAndNotifyAvailableService();
  const onChangeTab = useCallback(
    (tab) => {
      if (checkAndNotify(accountInfo, tab)) {
        return;
      }
      setServiceId(tab);
      serviceIdRef.current = tab;
      setSelectedReportType(REPORTS_TYPES.DAILY.ID);
      clearForm();
    },
    [clearForm, checkAndNotify, accountInfo],
  );

  useEffect(() => {
    const validServices = ALL_SERVICES.filter((service) => !accountInfo[service].isNotAvailable);
    if (validServices.length === 0) {
      // 有効なサービスがない場合は何もしない
      return;
    }
    if (validServices.length > 0 && validServices.includes(serviceIdRef.current)) {
      // 選択しているサービスが有効であれば何もしない
      return;
    }
    const [validService] = validServices;
    onChangeTab(validService);
  }, [accountInfo, onChangeTab]);

  const changeSelectedReportType = useCallback(
    (type) => {
      setSelectedReportType(type);
      clearForm();
    },
    [clearForm],
  );

  const searchReports = useCallback(
    ({ startDate, endDate }) => {
      dispatch(getReportsListRequest({ type: selectedReportType, startDate, endDate, serviceId }));
      setIsSearchedView(true);
    },
    [dispatch, selectedReportType, serviceId],
  );

  return useMemo(
    () => ({
      tabs: {
        options,
        get: serviceId,
        set: onChangeTab,
      },
      booleanTypes: {
        isDaily,
        isMonthly,
        isQuarterly,
        isExecutionHistory,
      },
      reportSelector: {
        options: reportOptions,
        get: selectedReportType,
        set: changeSelectedReportType,
        isDisabled: isListLoading || isFileLoading,
      },
      executionWarningText: {
        partOne: '大量のデータの検索はできません',
        partTwo: '検索期間は現在日付から30日以内をご指定ください。失敗する場合は指定期間を短くして再度お試しください。',
      },
      list: {
        data: list,
        isEmpty: !list || list.length === 0,
        isLoading: isListLoading,
        emptyMessage: '報告書がありません',
      },
      fileData: {
        file,
        fileName: name,
        isFileLoading,
      },
      serviceId,
      searchReports,
      areResultsShown,
      resetLocalData,
    }),
    [
      options,
      onChangeTab,
      isDaily,
      isMonthly,
      isQuarterly,
      isExecutionHistory,
      reportOptions,
      selectedReportType,
      changeSelectedReportType,
      isListLoading,
      isFileLoading,
      list,
      file,
      name,
      serviceId,
      searchReports,
      areResultsShown,
      resetLocalData,
    ],
  );
};

export const useGetReportFile = ({ file, serviceId }) => {
  const { id, name, type } = file;

  const dispatch = useDispatch();

  const { loading: isLoading, id: loadingId } = useSelector((state) => state.reports.fileMetaData);

  const getReportFile = useCallback(() => {
    if (!isLoading) dispatch(getReportFileRequest({ type, name, reportId: id, serviceId }));
  }, [dispatch, id, isLoading, name, serviceId, type]);

  return useMemo(
    () => ({
      getReportFile,
      isLoading,
      loadingId,
    }),
    [getReportFile, isLoading, loadingId],
  );
};

export const useReportDailySettings = ({ serviceId, selectedReportType, searchReports }) => {
  const dispatch = useDispatch();

  const currentDate = useMemo(() => new Date(), []);
  const minDate = useMemo(() => new Date(minReportsYear(serviceId), 0, 1), [serviceId]);

  const isListType = selectedReportType === REPORTS_TYPES.DAILY.ID;
  const isPDFType = selectedReportType === REPORTS_TYPES.SELECTED_PERIOD.ID;
  const isExecutionHistory = selectedReportType === REPORTS_TYPES.EXECUTION_HISTORY_CSV.ID;

  const [startDailyDate, setStartDailyDate] = useState('');
  const [endDailyDate, setEndDailyDate] = useState(getPreviousWorkday());

  const { isListLoading, fileMetaData } = useSelector((state) => state.reports);

  const isFileLoading = fileMetaData?.loading;

  useEffect(() => {
    let tempStartDate = getPreviousMonthWorkday();
    if (isPDFType) {
      tempStartDate = getPreviousYearWorkday();
    }
    if (isExecutionHistory) {
      tempStartDate = date30DaysBefore();
    }
    const tempEndDate = isExecutionHistory ? new Date() : getPreviousWorkday();
    setStartDailyDate(tempStartDate);
    setEndDailyDate(tempEndDate);
  }, [isPDFType, isExecutionHistory, serviceId]);

  const searchDailyReports = useCallback(() => {
    const startDate = createDateStringWithoutTime(startDailyDate);
    const endDate = createDateStringWithoutTime(endDailyDate);

    if (isListType) {
      searchReports({
        startDate,
        endDate,
      });
    } else {
      dispatch(
        getReportFileRequest({
          type: selectedReportType,
          name: DAILY_FILE_NAMES[selectedReportType],
          startTime: startDate,
          endTime: endDate,
          serviceId,
        }),
      );
    }
  }, [dispatch, endDailyDate, isListType, searchReports, selectedReportType, serviceId, startDailyDate]);

  const isLoading = useMemo(() => {
    if (isListType) return isListLoading;
    return isListLoading || !!isFileLoading;
  }, [isListType, isListLoading, isFileLoading]);

  return useMemo(
    () => ({
      startDate: {
        get: startDailyDate,
        set: setStartDailyDate,
        max: endDailyDate,
        min: minDate,
      },
      endDate: {
        get: endDailyDate,
        set: setEndDailyDate,
        max: currentDate,
        min: startDailyDate,
      },
      submit: {
        label: '検索',
        handler: searchDailyReports,
        isLoading,
      },
    }),
    [currentDate, endDailyDate, isLoading, minDate, searchDailyReports, startDailyDate],
  );
};

export const useReportMonthlySettings = ({ serviceId, searchReports }) => {
  const isListLoading = useSelector((state) => state.reports.isListLoading);

  const currentDate = useMemo(() => new Date(), []);
  const yearOptions = useMemo(() => yearOptionList(serviceId), [serviceId]);

  const [startYear, setStartYear] = useState(currentDate.getFullYear());
  const [endYear, setEndYear] = useState(currentDate.getFullYear());
  const [startMonth, setStartMonth] = useState(lastMonthValue());
  const [endMonth, setEndMonth] = useState(lastMonthValue());
  const [disabledStartYears, setDisabledStartYears] = useState(getDisabledArray(yearOptions, endYear));
  const [disabledEndYears, setDisabledEndYears] = useState(getDisabledArray(yearOptions, startYear, false));
  const [disabledStartMonth, setDisabledStartMonth] = useState(getDisabledArray(monthOptions, endMonth));
  const [disabledEndMonth, setDisabledEndMonth] = useState(getDisabledArray(monthOptions, startMonth, false));

  const changeMonthOptions = useCallback(
    (firstYear, secondYear, firstMonth = startMonth, secondMonth = endMonth) => {
      const areYearsEqual = firstYear === secondYear;

      setDisabledStartMonth(areYearsEqual ? getDisabledArray(monthOptions, secondMonth) : []);
      setDisabledEndMonth(areYearsEqual ? getDisabledArray(monthOptions, firstMonth, false) : []);
    },
    [setDisabledStartMonth, setDisabledEndMonth, startMonth, endMonth],
  );

  const changeStartYear = useCallback(
    (year) => {
      if (year > endYear) return;

      setStartYear(year);
      setDisabledEndYears(getDisabledArray(yearOptions, year, false));

      if (year === endYear && startMonth > endMonth) {
        setStartMonth(endMonth);
        changeMonthOptions(year, endYear, endMonth);
      } else {
        changeMonthOptions(year, endYear);
      }
    },
    [setStartYear, endYear, setDisabledEndYears, startMonth, endMonth, changeMonthOptions, setStartMonth, yearOptions],
  );

  const changeEndYear = useCallback(
    (year) => {
      if (year < startYear) return;

      setEndYear(year);
      setDisabledStartYears(getDisabledArray(yearOptions, year));

      if (year === startYear && startMonth > endMonth) {
        setEndMonth(startMonth);
        changeMonthOptions(year, startYear, startMonth, startMonth);
      } else {
        changeMonthOptions(year, startYear);
      }
    },
    [setEndYear, startYear, setDisabledStartYears, setEndMonth, startMonth, endMonth, changeMonthOptions, yearOptions],
  );

  const changeStartMonth = useCallback(
    (month) => {
      if (startYear === endYear && month > endMonth) return;

      setStartMonth(month);
      changeMonthOptions(startYear, endYear, month);
    },
    [endMonth, setStartMonth, startYear, endYear, changeMonthOptions],
  );

  const changeEndMonth = useCallback(
    (month) => {
      if (startYear === endYear && month < startMonth) return;

      setEndMonth(month);
      changeMonthOptions(startYear, endYear, startMonth, month);
    },
    [startMonth, setEndMonth, startYear, endYear, changeMonthOptions],
  );

  const searchMonthReports = useCallback(() => {
    const selectedStartMonth = createDateStringWithoutTime(new Date(startYear, startMonth, 1));
    const selectedEndMonth = createDateStringWithoutTime(getLastDayInMonthDate(new Date(endYear, endMonth, 1)));

    searchReports({
      startDate: selectedStartMonth,
      endDate: selectedEndMonth,
    });
  }, [searchReports, startYear, startMonth, endYear, endMonth]);

  return useMemo(
    () => ({
      yearOptions,
      startYear: {
        get: startYear,
        set: changeStartYear,
        disabledValues: disabledStartYears,
      },
      startMonth: {
        get: startMonth,
        set: changeStartMonth,
        disabledValues: disabledStartMonth,
      },
      endYear: {
        get: endYear,
        set: changeEndYear,
        disabledValues: disabledEndYears,
      },
      endMonth: {
        get: endMonth,
        set: changeEndMonth,
        disabledValues: disabledEndMonth,
      },
      submit: {
        label: '検索',
        handler: searchMonthReports,
        isLoading: isListLoading,
      },
    }),
    [
      changeEndMonth,
      changeEndYear,
      changeStartMonth,
      changeStartYear,
      disabledEndMonth,
      disabledEndYears,
      disabledStartMonth,
      disabledStartYears,
      endMonth,
      endYear,
      isListLoading,
      searchMonthReports,
      startMonth,
      startYear,
      yearOptions,
    ],
  );
};

export const useReportQuarterSettings = ({ serviceId, searchReports }) => {
  const { isListLoading } = useSelector((state) => state.reports);

  const currentDate = useMemo(() => new Date(), []);
  const yearOptions = useMemo(() => yearOptionList(serviceId), [serviceId]);

  const [startYear, setStartYear] = useState(currentDate.getFullYear());
  const [endYear, setEndYear] = useState(currentDate.getFullYear());
  const [startQuarter, setStartQuarter] = useState(quarterOptions[0].value);
  const [endQuarter, setEndQuarter] = useState(currentQuarter());
  const [disabledStartYears, setDisabledStartYears] = useState(getDisabledArray(yearOptions, endYear));
  const [disabledEndYears, setDisabledEndYears] = useState(getDisabledArray(yearOptions, startYear, false));
  const [disabledStartQuarter, setDisabledStartQuarter] = useState(getDisabledArray(quarterOptions, endQuarter));
  const [disabledEndQuarter, setDisabledEndQuarter] = useState(getDisabledArray(quarterOptions, startQuarter, false));

  const changeQuarterOptions = useCallback(
    (firstYear, secondYear, firstQuarter = startQuarter, secondQuarter = endQuarter) => {
      const areYearsEqual = firstYear === secondYear;

      setDisabledStartQuarter(areYearsEqual ? getDisabledArray(quarterOptions, secondQuarter) : []);
      setDisabledEndQuarter(areYearsEqual ? getDisabledArray(quarterOptions, firstQuarter, false) : []);
    },
    [setDisabledStartQuarter, setDisabledEndQuarter, startQuarter, endQuarter],
  );

  const changeStartYear = useCallback(
    (year) => {
      if (year > endYear) return;

      setStartYear(year);
      setDisabledEndYears(getDisabledArray(yearOptions, year, false));

      if (year === endYear && startQuarter > endQuarter) {
        setStartQuarter(endQuarter);
        changeQuarterOptions(year, endYear, endQuarter);
      } else {
        changeQuarterOptions(year, endYear);
      }
    },
    [
      setStartYear,
      endYear,
      setStartQuarter,
      startQuarter,
      endQuarter,
      changeQuarterOptions,
      setDisabledEndYears,
      yearOptions,
    ],
  );

  const changeEndYear = useCallback(
    (year) => {
      if (year < startYear) return;

      setEndYear(year);
      setDisabledStartYears(getDisabledArray(yearOptions, year));

      if (year === startYear && startQuarter > endQuarter) {
        setEndQuarter(startQuarter);
        changeQuarterOptions(year, startYear, startQuarter, startQuarter);
      } else {
        changeQuarterOptions(year, startYear);
      }
    },
    [
      setEndYear,
      startYear,
      setEndQuarter,
      startQuarter,
      endQuarter,
      changeQuarterOptions,
      setDisabledStartYears,
      yearOptions,
    ],
  );

  const changeStartQuarter = useCallback(
    (quarter) => {
      if (startYear === endYear && quarter > endQuarter) return;

      setStartQuarter(quarter);
      changeQuarterOptions(startYear, endYear, quarter);
    },
    [setStartQuarter, endQuarter, startYear, endYear, changeQuarterOptions],
  );

  const changeEndQuarter = useCallback(
    (quarter) => {
      if (startYear === endYear && quarter < startQuarter) return;

      setEndQuarter(quarter);
      changeQuarterOptions(startYear, endYear, startQuarter, quarter);
    },
    [setEndQuarter, startQuarter, startYear, endYear, changeQuarterOptions],
  );

  const searchQuarterReports = useCallback(() => {
    const startYearQuarters = getQuarterDates(new Date(String(startYear)));
    const endYearQuarters = getQuarterDates(new Date(String(endYear)));

    searchReports({
      startDate: createDateStringWithoutTime(startYearQuarters[startQuarter].startDate),
      endDate: createDateStringWithoutTime(endYearQuarters[endQuarter].endDate),
    });
  }, [searchReports, startYear, startQuarter, endYear, endQuarter]);

  return useMemo(
    () => ({
      yearOptions,
      startYear: {
        get: startYear,
        set: changeStartYear,
        disabledValues: disabledStartYears,
      },
      endYear: {
        get: endYear,
        set: changeEndYear,
        disabledValues: disabledEndYears,
      },
      startQuarter: {
        get: startQuarter,
        set: changeStartQuarter,
        disabledValues: disabledStartQuarter,
      },
      endQuarter: {
        get: endQuarter,
        set: changeEndQuarter,
        disabledValues: disabledEndQuarter,
      },
      submit: {
        label: '検索',
        handler: searchQuarterReports,
        isLoading: isListLoading,
      },
    }),
    [
      changeEndQuarter,
      changeEndYear,
      changeStartQuarter,
      changeStartYear,
      disabledEndQuarter,
      disabledEndYears,
      disabledStartQuarter,
      disabledStartYears,
      endQuarter,
      endYear,
      isListLoading,
      searchQuarterReports,
      startQuarter,
      startYear,
      yearOptions,
    ],
  );
};

export const useReportsServiceChange = () => {
  const [isTabChanged, setIsTabChanged] = useState(true);

  const dispatch = useDispatch();

  const resetData = useCallback(() => {
    dispatch(clearReportsList());
    dispatch(clearReportFile());
    dispatch(getReportFileRequestEndLoading());
    dispatch(getReportsListRequestEndLoading());
  }, [dispatch]);

  return {
    isTabChanged,
    setIsTabChanged,
    resetData,
  };
};
