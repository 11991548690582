/* eslint-disable-next-line import/no-unresolved */
import { useMemo } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector } from 'react-redux';
import { getInstrumentServiceId } from './symbol';

export const useServiceId = (instrumentId) => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  return useMemo(() => getInstrumentServiceId(instrumentId, instrumentList), [instrumentId, instrumentList]);
};
