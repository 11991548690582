import { memo } from 'react';
import { SplitPane } from '../../../components';
import { ExecutionTable } from './ExecutionTable';
import { SimulationChart } from './SimulationChart';

export const Simulation = memo(() => (
  <SplitPane split="horizontal" defaultSize="60%" minSize={200}>
    <SimulationChart />
    <ExecutionTable />
  </SplitPane>
));
