import Decimal from 'decimal.js';
import { CFD } from '../../../../constants';
import Ap from '../../Ap';
import { ceil, getSignificantDigits } from '../../constants';

export const RANGE_POSITION = { TOP: 0, MIDDLE: 1, BOTTOM: 2, ISOLATION_TOP: 3, ISOLATION_BOTTOM: 4 };

export const Range = (
  inputInstrumentId,
  instrumentSetting,
  inputSide,
  inputMax,
  inputMin,
  inputQuantity,
  inputRangePosition,
) => {
  const instrumentId = inputInstrumentId;
  const digits = getSignificantDigits(CFD, instrumentId, instrumentSetting?.pricePrecision);
  const side = inputSide;
  const rangePosition = inputRangePosition;
  const subtractNumberForNumOfOrders =
    rangePosition === RANGE_POSITION.MIDDLE ||
    rangePosition === RANGE_POSITION.ISOLATION_BOTTOM ||
    rangePosition === RANGE_POSITION.ISOLATION_TOP
      ? 1
      : 0;
  const MIN_RANGEWIDTH = 100;
  let min = inputMin;
  let max = inputMax;
  let minForDisp = inputMin;
  let maxForDisp = inputMax;
  let width = 0;
  let numOfOrders = 0;
  let quantity = inputQuantity;
  let tp = [];
  let sl = null;
  const defaultSettings = {
    min: null,
    max: null,
    width: null,
    numOfOrders: null,
    quantity: null,
    tp: null,
    sl: null,
  };
  const currentSettings = {
    min: null,
    max: null,
    minForDisp: null,
    maxForDisp: null,
    width: null,
    numOfOrders: null,
    quantity: null,
    tp: null,
    sl: null,
  };
  const expandUpToMinRangeWidth = () => {
    switch (rangePosition) {
      case RANGE_POSITION.TOP:
      case RANGE_POSITION.ISOLATION_TOP: {
        if (Decimal.sub(max, min).toNumber() < MIN_RANGEWIDTH) {
          max = Decimal.add(min, MIN_RANGEWIDTH).toNumber();
        }
        break;
      }
      case RANGE_POSITION.MIDDLE: {
        if (Decimal.sub(max, min).toNumber() < MIN_RANGEWIDTH) {
          const halfRangeWidth = Decimal.div(MIN_RANGEWIDTH, 2).toNumber();
          const basePrice = ceil(Decimal.sub(max, halfRangeWidth), digits);
          min = ceil(Decimal.sub(basePrice, MIN_RANGEWIDTH, instrumentId), digits);
          max = ceil(Decimal.add(basePrice, MIN_RANGEWIDTH, instrumentId), digits);
        }
        break;
      }
      case RANGE_POSITION.BOTTOM:
      case RANGE_POSITION.ISOLATION_BOTTOM: {
        if (Decimal.sub(max, min).toNumber() < MIN_RANGEWIDTH) {
          min = Decimal.sub(max, MIN_RANGEWIDTH).toNumber();
        }
        break;
      }
      default: {
        break;
      }
    }
  };
  expandUpToMinRangeWidth();

  return {
    expandUpToMinRangeWidth,
    /**
     * 注文本数をレンジの最大値・最小値、注文幅から求め、セットする。
     */
    calcAndSetNumOfOrders() {
      numOfOrders = ceil(Decimal.add(Decimal.div(Decimal.sub(max, min), this.getWidth()), 1), 0);
    },
    /**
     * 最小値の補正を行う。
     *
     * 最大値が確定している状態で使用すること。
     * 最大値と注文本数、注文幅から最低値を求め、セットする。
     */
    ajustMin() {
      min = ceil(
        Decimal.sub(max, Decimal.mul(Decimal.sub(numOfOrders, subtractNumberForNumOfOrders), this.getWidth())),
        digits,
      );
    },
    /**
     * 最大値の補正を行う。
     *
     * 最小値が確定している状態で使用すること。
     * 最小値と注文本数、注文幅から最低値を求め、セットする。
     */
    ajustMax() {
      max = ceil(
        Decimal.add(
          this.getMin(),
          Decimal.mul(Decimal.sub(numOfOrders, subtractNumberForNumOfOrders), this.getWidth()),
        ),
        digits,
      );
    },
    /**
     * 変更された注文設定を軸に注文幅を調整する。
     * baseMin,baseMaxが有効な場合は最小・最大値も調整する。
     *
     * @param {*} baseMin 起点とする最小値(指定値-注文幅が最小値となる)
     * @param {*} baseMax 起点とする最大値(指定値+注文幅が最大値となる)
     * @param {*} inputNumOfOrders 注文本数
     */
    recalc(baseMin, baseMax, inputNumOfOrders, isChangeRangeWidth) {
      if (baseMax || baseMax === 0) {
        max = ceil(baseMax, digits);
      }
      if (baseMin || baseMin === 0) {
        min = ceil(baseMin, digits);
      }
      if (inputNumOfOrders || isChangeRangeWidth) {
        const ajustResult = this.setNumOfOrdersAjustWidth(inputNumOfOrders || numOfOrders);
        if (!ajustResult.isValid) {
          return ajustResult;
        }
      }

      if (baseMax || baseMax === 0) {
        this.ajustMin();
      } else if (baseMin || baseMin === 0) {
        this.ajustMax();
      } else {
        this.ajustMin();
      }
      return { isValid: true };
    },
    recalcWithStartPrice(startPrice, rangeWidth, newNumOfOrders) {
      const isChangeRangeWidth = rangeWidth && rangeWidth !== this.getRangeWidth();
      const newRangeWidth = isChangeRangeWidth ? rangeWidth : this.getRangeWidth();
      let recalcResult;
      switch (rangePosition) {
        case RANGE_POSITION.TOP:
        case RANGE_POSITION.ISOLATION_TOP: {
          const basePrice = startPrice ?? min;
          max = ceil(Decimal.add(basePrice, newRangeWidth), digits);
          minForDisp = ceil(basePrice, digits);
          maxForDisp = max;
          recalcResult = this.recalc(basePrice, null, newNumOfOrders, isChangeRangeWidth);
          break;
        }
        case RANGE_POSITION.MIDDLE: {
          const halfRangeWidth = Decimal.div(newRangeWidth, 2);
          const basePrice = startPrice ? Decimal.add(startPrice, halfRangeWidth) : max;
          min = ceil(Decimal.sub(basePrice, newRangeWidth), digits);
          maxForDisp = ceil(basePrice, digits);
          minForDisp = min;
          recalcResult = this.recalc(null, basePrice, newNumOfOrders, isChangeRangeWidth);
          break;
        }
        case RANGE_POSITION.BOTTOM:
        case RANGE_POSITION.ISOLATION_BOTTOM: {
          const basePrice = startPrice ?? max;
          min = ceil(Decimal.sub(basePrice, newRangeWidth), digits);
          maxForDisp = ceil(basePrice, digits);
          minForDisp = min;
          recalcResult = this.recalc(null, basePrice, newNumOfOrders, isChangeRangeWidth);
          break;
        }
        default:
          recalcResult = { isValid: false, errorMessage: 'レンジの位置情報が不正です。' };
      }
      return recalcResult;
    },
    backupDefaultSettings() {
      defaultSettings.min = min;
      defaultSettings.max = max;
      defaultSettings.width = width;
      defaultSettings.numOfOrders = numOfOrders;
      defaultSettings.quantity = quantity;
      defaultSettings.sl = sl;
      defaultSettings.tp = tp;
    },
    reset() {
      min = defaultSettings.min;
      max = defaultSettings.max;
      minForDisp = defaultSettings.min;
      maxForDisp = defaultSettings.max;
      width = defaultSettings.width;
      numOfOrders = defaultSettings.numOfOrders;
      quantity = defaultSettings.quantity;
      tp = defaultSettings.tp;
      sl = defaultSettings.sl;
    },
    backupCurrentSettings() {
      currentSettings.min = min;
      currentSettings.max = max;
      currentSettings.minForDisp = minForDisp;
      currentSettings.maxForDisp = maxForDisp;
      currentSettings.width = width;
      currentSettings.numOfOrders = numOfOrders;
      currentSettings.quantity = quantity;
      currentSettings.sl = sl;
      currentSettings.tp = tp;
    },
    rollbackSettings() {
      min = currentSettings.min;
      max = currentSettings.max;
      minForDisp = currentSettings.minForDisp;
      maxForDisp = currentSettings.maxForDisp;
      width = currentSettings.width;
      numOfOrders = currentSettings.numOfOrders;
      quantity = currentSettings.quantity;
      tp = currentSettings.tp;
      sl = currentSettings.sl;
    },
    getRangeWidth() {
      return ceil(Decimal.sub(max, min), digits);
    },
    getPreciseRangeWidth() {
      return Decimal.sub(max, min);
    },
    setApSettings: (settings) => {
      if (settings.quantity) {
        quantity = settings.quantity;
      }
      if (settings.tp) {
        tp = settings.tp;
      }
      if (settings.sl || settings.sl === null) {
        sl = settings.sl;
      }
    },
    setNumOfOrders: (value) => {
      numOfOrders = value;
    },
    getNumOfOrders() {
      return numOfOrders;
    },
    getQuantity() {
      return quantity;
    },
    setMin: (value) => {
      min = value;
    },
    setMax: (value) => {
      max = value;
    },
    setMinForDisp: (value) => {
      minForDisp = value;
    },
    setMaxForDisp: (value) => {
      maxForDisp = value;
    },
    getMin() {
      return min;
    },
    getMax() {
      return max;
    },
    getMinForDisp() {
      return minForDisp;
    },
    getMaxForDisp() {
      return maxForDisp;
    },
    setWidth: (value) => {
      width = value;
    },
    getWidth() {
      return width;
    },
    setTp: (value) => {
      tp = value;
    },
    getTp() {
      return tp;
    },
    setSl: (value) => {
      sl = value;
    },
    getSl() {
      return sl;
    },
    getSide() {
      return side;
    },
    getStartPrice() {
      switch (rangePosition) {
        case RANGE_POSITION.TOP:
        case RANGE_POSITION.ISOLATION_TOP:
          return min;
        case RANGE_POSITION.MIDDLE:
          return ceil(Decimal.sub(max, Decimal.div(Decimal.sub(max, min), 2)), digits);
        case RANGE_POSITION.BOTTOM:
        case RANGE_POSITION.ISOLATION_BOTTOM:
          return max;
        default:
          return null;
      }
    },
    getSettings() {
      return {
        side: side.ID,
        min: minForDisp,
        max: maxForDisp,
        preciseMin: min,
        preciseMax: max,
        rangeWidth: this.getRangeWidth(),
        startPrice: this.getStartPrice(),
        numOfOrders: this.getNumOfOrders(),
        quantity,
        tp,
        sl,
      };
    },
    setNumOfOrdersAjustWidth(inputNumOfOrders) {
      const validateResult = this.validateRangeWidth(this.getRangeWidth(), inputNumOfOrders);
      if (!validateResult.isValid) {
        return validateResult;
      }
      numOfOrders = inputNumOfOrders;
      const tempWidth =
        numOfOrders === 1
          ? this.getRangeWidth()
          : Decimal.div(Decimal.sub(max, min), Decimal.sub(numOfOrders, subtractNumberForNumOfOrders));
      width = ceil(tempWidth, digits);
      return { isValid: true };
    },
    createAps() {
      const result = [];
      for (let i = 0; i < numOfOrders; i += 1) {
        result.push(this.toAp(i, side));
      }
      return result;
    },
    toAp(num, apSide) {
      let entryPrice1 = 0;
      if (rangePosition === RANGE_POSITION.BOTTOM) {
        entryPrice1 = ceil(Decimal.sub(this.getMax(), Decimal.mul(Decimal.add(num, 1), this.getWidth())), digits);
      } else {
        entryPrice1 = ceil(Decimal.sub(max, Decimal.mul(num, this.getWidth())), digits);
      }

      return Ap(apSide.ID, entryPrice1, null, this.getQuantity(), sl, tp[num % tp.length], null, null, entryPrice1);
    },
    getMinRangeWidth(inputNumOfOrders) {
      return Math.max(ceil(Decimal.mul(this.getMinWidth(), Decimal.sub(inputNumOfOrders, 1)), digits), MIN_RANGEWIDTH);
    },
    getMinWidth() {
      return Decimal.div(1, 10).pow(digits);
    },
    validateRangeWidth(inputRangeWidth, inputNumOfOrders) {
      const tempWidth =
        inputNumOfOrders === 1
          ? this.getRangeWidth()
          : Decimal.div(inputRangeWidth, Decimal.sub(inputNumOfOrders, subtractNumberForNumOfOrders));
      if (tempWidth < this.getMinWidth()) {
        return {
          isValid: false,
          errorMessage: 'この設定では注文幅が狭くなり過ぎます。レンジ幅を広くするか本数を少なくして下さい。',
        };
      }
      return { isValid: true };
    },
  };
};
