// eslint-disable-next-line import/no-unresolved
import { useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useDispatch, useSelector } from 'react-redux';
import { ALL_SERVICES } from '../constants/core';
import { DEFAULT_LAB_SELECTED_TERM_ID } from '../constants';
import { getMyLabDetailsRequest } from '../redux/labs';

const isEmptySimulationStats = (strategy) => {
  const simulationStats = strategy?.simulationStats;
  if (simulationStats == null) {
    return true;
  }
  if (Object.keys(simulationStats).length === 0) {
    return true;
  }
  return Object.values(simulationStats).every((v) => v == null);
};

export const useStrategyListForRiskAssessment = (simulationData) => {
  const { strategyList } = simulationData || {};
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  return useMemo(
    () =>
      (strategyList || [])
        .filter((strategy) => !isEmptySimulationStats(strategy))
        .map((strategy) => {
          const {
            id,
            instrumentId,
            itemList,
            name,
            simulationTradeList,
            strategySets,
            simulationStats,
            simulationChartList,
          } = strategy;
          const { serviceId } = instrumentList?.[instrumentId] ?? {};
          const strategyDetail = {
            instrumentId,
            name,
            serviceId,
            itemList,
            simulationTradeList,
            simulationStats,
            simulationChartList,
          };
          return { strategyId: id, strategySets, strategyDetail };
        }),
    [strategyList, instrumentList],
  );
};

export const useMyLabDetails = () => {
  const dispatch = useDispatch();
  const myLabs = useSelector((state) => state.labs.myLabs);
  const idKey = useMemo(() => {
    return ALL_SERVICES.map((serviceId) => {
      if (myLabs == null) {
        return null;
      }
      const labs = myLabs[serviceId];
      if (labs == null) {
        return null;
      }
      const [lab] = labs;
      if (lab == null) {
        return null;
      }
      const { id } = lab;
      return id;
    })
      .filter((id) => id != null)
      .sort()
      .join('|');
  }, [myLabs]);

  useEffect(() => {
    if (!idKey) {
      return;
    }
    const labIds = idKey.split('|').map((labId) => Number(labId));
    dispatch(getMyLabDetailsRequest({ labIds, termId: DEFAULT_LAB_SELECTED_TERM_ID }));
  }, [dispatch, idKey]);
};
