import { memo, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './tag.module.scss';

export const Tag = memo(({ text, className }) => {
  const tagColor = useMemo(() => styles[text] ?? styles.other, [text]);
  return <div className={classNames(styles.tag, tagColor, className)}>{text.toUpperCase()}</div>;
});

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Tag.defaultProps = {
  className: undefined,
};
