import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MODAL_SIZES, STYLES } from 'shared-modules/constants';
import {
  COMPREHENSIVE_EVALUATIONS,
  DESCRIPTION_RANGE_OUT,
  KEY_ASSETS,
  KEY_COMPREHENSIVE_EVALUATIONS,
  KEY_MARGINS,
  KEY_PERIODS,
  KEY_STYLES,
  LABEL_CLEAR,
  LABEL_OK,
  MARGINS,
  PERIODS,
  TITLE_ASSET,
  TITLE_COMPREHENSIVE_EVALUATION,
  TITLE_FILTER_CONDITION,
  TITLE_PERIOD,
  TITLE_RANGE_OUT,
  TITLE_RECOMMENDED_MARGIN,
  TITLE_STYLE,
} from 'shared-modules/constants/select';
import { useFilterCondition, useFilterConditionModal } from 'shared-modules/hooks/select';
import { useKeyDownHandler } from '../../../../services/hooks';
import { useCssVariables } from '../../../../hooks';
import { Button, CheckButton, Modal, Switch } from '../../../../components';
import styles from './filterCondition.module.scss';

const BUTTON_WIDTH = '100%';

const Item = memo(({ title, items, className, stateKey, state, onChange }) => (
  <>
    <div className={styles.item}>
      <div className={styles.title}>{title}</div>
      <div className={styles.checks}>
        {(items ?? []).map((item) => {
          const { id, value } = item;
          const checked = state[stateKey]?.[id] ?? false;
          const handleChange = (v) => onChange(v, stateKey, item);
          return <CheckButton key={id} className={className} checked={checked} label={value} onChange={handleChange} />;
        })}
      </div>
    </div>
    <div className={styles.borderLine} />
  </>
));

Item.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      assets: PropTypes.shape({}),
      margins: PropTypes.shape({}),
    }),
  ),
  className: PropTypes.string,
  stateKey: PropTypes.string.isRequired,
  state: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

Item.defaultProps = {
  items: undefined,
  className: undefined,
};

const FilterConditionModal = memo(
  ({ visible, filterCondition, selectedAll, assets, onClose, onOk, onChange, onClear }) => {
    const primaryColor = useCssVariables()['--color-text-primary'];
    const { handleChangeAssets, handleChangeRangeOuts, checkedRangeOuts } = useFilterConditionModal({
      filterCondition,
      onChange,
    });
    return (
      <Modal isOpen={visible} closeModal={onClose} title={TITLE_FILTER_CONDITION} size={MODAL_SIZES.WIDTH_710}>
        <div className={styles.content}>
          {selectedAll && (
            <Item
              className={styles.itemWidth3}
              title={TITLE_ASSET}
              items={assets}
              stateKey={KEY_ASSETS}
              state={filterCondition}
              onChange={handleChangeAssets}
            />
          )}
          <Item
            className={styles.itemWidth4}
            title={TITLE_RECOMMENDED_MARGIN}
            items={MARGINS}
            stateKey={KEY_MARGINS}
            state={filterCondition}
            onChange={onChange}
          />
          <Item
            className={styles.itemWidth3}
            title={TITLE_STYLE}
            items={STYLES}
            stateKey={KEY_STYLES}
            state={filterCondition}
            onChange={onChange}
          />
          <Item
            className={styles.itemWidth3}
            title={TITLE_PERIOD}
            items={PERIODS}
            stateKey={KEY_PERIODS}
            state={filterCondition}
            onChange={onChange}
          />
          <Item
            className={styles.itemWidth5}
            title={TITLE_COMPREHENSIVE_EVALUATION}
            items={COMPREHENSIVE_EVALUATIONS}
            stateKey={KEY_COMPREHENSIVE_EVALUATIONS}
            state={filterCondition}
            onChange={onChange}
          />
          <div className={styles.rangeOut}>
            <div>
              <div className={styles.title}>{TITLE_RANGE_OUT}</div>
              <div className={styles.rangeOutText}>{DESCRIPTION_RANGE_OUT}</div>
            </div>
            <Switch onColor={primaryColor} checked={checkedRangeOuts} onChange={handleChangeRangeOuts} />
          </div>
          <div className={styles.borderLine} />
        </div>
        <div className={styles.footer}>
          <Button width={BUTTON_WIDTH} onClick={onClear} secondary>
            {LABEL_CLEAR}
          </Button>
          <Button width={BUTTON_WIDTH} onClick={onOk}>
            {LABEL_OK}
          </Button>
        </div>
      </Modal>
    );
  },
);

FilterConditionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  filterCondition: PropTypes.shape({}).isRequired,
  selectedAll: PropTypes.bool.isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      assets: PropTypes.shape({}),
      margins: PropTypes.shape({}),
    }),
  ).isRequired,
  onOk: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export const FilterCondition = memo(({ tabs }) => {
  const {
    visible,
    nothing,
    filterCondition,
    selectedAll,
    assets,
    handleOpen,
    handleClose,
    handleOk,
    handleChange,
    handleClear,
  } = useFilterCondition(tabs);
  const handleKeyDownOpen = useKeyDownHandler(handleOpen);
  return (
    <>
      <div className={styles.filter} role="button" tabIndex={0} onClick={handleOpen} onKeyDown={handleKeyDownOpen}>
        <i className={classNames('material-icons-outlined', styles.icon, { [styles.filtered]: !nothing })}>
          filter_alt
        </i>
        <div className={classNames({ [styles.filtered]: !nothing })}>{nothing ? '絞込なし' : '絞込あり'}</div>
      </div>
      {visible && (
        <FilterConditionModal
          visible={visible}
          filterCondition={filterCondition}
          selectedAll={selectedAll}
          assets={assets}
          onOk={handleOk}
          onClose={handleClose}
          onChange={handleChange}
          onClear={handleClear}
        />
      )}
    </>
  );
});

FilterCondition.propTypes = {
  tabs: PropTypes.shape({
    get: PropTypes.number,
    set: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
