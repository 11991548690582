import { FX } from '../constants';
import { TECH_BASE_PRICE_LITERAL, TECH_START_PRICE_LITERAL } from '../constants/builder';
import { roundToFixedPrecision, toCommaSeparatedNumber } from '../services';

export const transformCurrentItems = (currentItems) => {
  const data = (currentItems ?? []).reduce(
    (acc, curr) => {
      const { groups, transformed } = acc;
      const { parentId, sourceType, itemId } = curr;
      const groupKey = parentId == null ? itemId : `${parentId ?? ''}_${sourceType ?? ''}`;
      if (parentId == null) {
        transformed.push({ cartItems: [curr], ...curr });
      } else if (groups[groupKey] == null) {
        groups[groupKey] = [curr];
        transformed.push({
          itemId: groupKey,
          parentId,
          parentName: curr.parentName,
          parentImage: curr.parentImage,
          sourceType: curr.sourceType,
          strategySets: curr.parentSets,
          strategyDetail: {},
          cartItems: groups[groupKey],
        });
      } else {
        groups[groupKey].push(curr);
      }
      return { groups, transformed };
    },
    { groups: {}, transformed: [] },
  );
  return data.transformed;
};

export const convertEntryPrice1Value = (value) => {
  if (value == null) {
    return value;
  }
  const strValue = String(value);
  if (!strValue.includes(TECH_BASE_PRICE_LITERAL)) {
    return value;
  }
  return strValue.replace(TECH_BASE_PRICE_LITERAL, TECH_START_PRICE_LITERAL);
};

export const formatAndRoundValue = (value, serviceId, pricePrecision) => {
  // TODO CFD FXかそうでないかの判定で問題ないか要確認
  if (serviceId === FX || String(value).includes(TECH_START_PRICE_LITERAL)) {
    return value || '-';
  }
  return value ? toCommaSeparatedNumber(roundToFixedPrecision(Number(value), pricePrecision)) : '-';
};
