/* eslint-disable import/no-unresolved */
import { useMemo } from 'react';
/* eslint-disable import/no-unresolved */
import { useSelector } from 'react-redux';
import { useAccountInfo } from './useAccountInfo';
import { calculateMarginRequired } from '../utils';

// TODO CFD メンテナンスやロスカット時の挙動を要テスト
export const useCalculatingMarginByStrategyList = (strategyList, sets) => {
  const marginRates = useSelector((state) => state.currencies.marginRates);
  const accountInfo = useAccountInfo();
  const margin = useMemo(
    () => calculateMarginRequired(strategyList, sets, accountInfo, marginRates),
    [accountInfo, marginRates, sets, strategyList],
  );
  return margin;
};
