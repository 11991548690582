import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ORDER_TYPES } from 'shared-modules/constants/manualTrade';
import { BUY_SELL_VALUE, CFD, EXPIRATION_TYPE_MAIN, EXPIRATION_TYPE_VALUES } from 'shared-modules/constants';
import {
  createDateString,
  formatNumberToDisplayedString,
  getServiceQuantityUnit,
  trimFrontZeros,
} from 'shared-modules/services';
import { useGetInstrumentDisplayName } from 'shared-modules/hooks';
import { useCalculateManualMargin } from 'shared-modules/services/hooks';
import useFormatPrice from '../../hooks/useFormatPrice';
import styles from './ocoOrder.module.scss';

const OCOOrder = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const quantityUnit = getServiceQuantityUnit(serviceId);
  const instrumentId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  const formatPrice = useFormatPrice(instrumentId);
  const getInstrumentDisplayName = useGetInstrumentDisplayName();
  const { quantityUnitConvFactor } = useSelector((state) => state.settings.instrumentList[instrumentId]);

  const buySell = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.BUY_SELL],
  );
  const count = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.COUNT],
  );
  const limitPrice = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.LIMIT_PRICE],
  );
  const stopPrice = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.STOP_PRICE],
  );
  const expirationType = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.EXPIRATION_TYPE],
  );
  const selectedDate = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.SELECTED_DATE],
  );
  const selectedTime = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.SELECTED_TIME],
  );

  const price = useMemo(() => (limitPrice >= stopPrice ? limitPrice : stopPrice), [limitPrice, stopPrice]);

  const calculatedMargin = useCalculateManualMargin(count, price);

  const isCFD = useMemo(() => serviceId === CFD, [serviceId]);

  return (
    <>
      <div className={styles.headerRow}>新規</div>
      <div className={styles.brandRow}>
        銘柄
        <span>{getInstrumentDisplayName(instrumentId, serviceId)}</span>
      </div>
      <div className={styles.orderTypeRow}>
        注文条件
        <span>OCO</span>
      </div>
      <div className={styles.buySellRow}>
        売買
        <span>{BUY_SELL_VALUE[buySell]}</span>
      </div>
      <div className={styles.countRow}>
        <div>
          数量
          {isCFD && <div>1Lot={quantityUnitConvFactor}</div>}
        </div>
        <span className={styles.boldText}>{`${trimFrontZeros(count)}${quantityUnit}`}</span>
      </div>
      <div className={styles.calculatingMarginRow}>
        発注証拠金目安
        <div className={styles.boldText}>
          {formatNumberToDisplayedString({ value: calculatedMargin, withoutPlus: true })}
        </div>
      </div>
      <div className={styles.limitPriceRow}>
        指値価格
        <span>{formatPrice(limitPrice)}</span>
      </div>
      <div className={styles.stopPriceRow}>
        逆指値価格
        <span>{formatPrice(stopPrice)}</span>
      </div>
      <div className={styles.expirationTypeRow}>
        有効期限
        <span>
          {expirationType === EXPIRATION_TYPE_MAIN.CUSTOM.ID
            ? createDateString(selectedDate, selectedTime, true)
            : EXPIRATION_TYPE_VALUES[expirationType]}
        </span>
      </div>
    </>
  );
};

export default memo(OCOOrder);
