import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  useCartSummaryInfo,
  useIsMarginZeroByServiceId,
  useServiceUnavailableCheck,
} from 'shared-modules/services/hooks';
import { useDepositTransfer } from 'shared-modules/hooks/useDepositTransfer';
import {
  BALANCE_METER_DISABLE_MESSAGES,
  RECOMMENDED_MARGIN_LABEL,
  REQUIRED_MARGIN_LABEL,
} from 'shared-modules/constants';
import { push } from 'shared-modules/redux/actions/routerActions';
import { changeServiceIdRequest } from 'shared-modules/redux/actions';
import { BalanceMeter, ForwardLink, MarginInfo } from '../../../../components';
import { CASH } from '../../../../constants';
import styles from './cartAssetDetail.module.scss';

export const CartAssetDetail = memo(({ marginRequired, marginRecommended, serviceId }) => {
  const dispatch = useDispatch();
  const isServiceUnavailable = useServiceUnavailableCheck(serviceId);
  const meterDataRaw = useCartSummaryInfo(serviceId);
  const isMarginZero = useIsMarginZeroByServiceId(serviceId);

  const [recommended, consumedMargin] = useMemo(() => {
    return [marginRecommended?.value ?? 0, marginRequired?.value ?? 0];
  }, [marginRequired, marginRecommended]);

  const { meterData, recommendedMargin, requiredMargin } = useMemo(() => {
    return {
      meterData: { ...meterDataRaw, consumedMargin },
      recommendedMargin: {
        get: recommended,
        label: RECOMMENDED_MARGIN_LABEL,
      },
      requiredMargin: {
        get: consumedMargin,
        label: REQUIRED_MARGIN_LABEL,
      },
    };
  }, [meterDataRaw, recommended, consumedMargin]);

  const handleClick = useCallback(() => {
    dispatch(push(`/${CASH}`));
    dispatch(changeServiceIdRequest({ serviceId }));
  }, [dispatch, serviceId]);

  const { label, showLink, handleChangeAttention } = useDepositTransfer();

  if (isServiceUnavailable) {
    return <div className={styles.centerLabel}>{BALANCE_METER_DISABLE_MESSAGES[serviceId]}</div>;
  }

  return (
    <div className={styles.container}>
      <BalanceMeter
        meterData={meterData}
        enabledFuture
        isMarginZero={isMarginZero}
        onClick={handleClick}
        onChangeAttention={handleChangeAttention}
      />
      <MarginInfo className={styles.marginArea} recommendedMargin={recommendedMargin} requiredMargin={requiredMargin} />
      {!isMarginZero && showLink && (
        <div className={styles.rowAlignRight}>
          <ForwardLink className={styles.forwardLink} onClick={handleClick} primary>
            {label}
          </ForwardLink>
        </div>
      )}
    </div>
  );
});

CartAssetDetail.propTypes = {
  serviceId: PropTypes.string.isRequired,
  marginRequired: PropTypes.shape({
    value: PropTypes.number,
  }),
  marginRecommended: PropTypes.shape({
    value: PropTypes.number,
  }),
};

CartAssetDetail.defaultProps = {
  marginRequired: undefined,
  marginRecommended: undefined,
};
