import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserSSOPaymentRequest } from '../../redux/actions';
import { Spin } from '../../components';
import styles from './ssoPayment.module.scss';

const SSOPayment = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const { url, token, hash } = useSelector((state) => state.userSSO.userSSOPayment);
  const [mobileData, setMobileData] = useState(null);

  const ssoPaymentFormRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search.substr(1));
    const isMobile = urlParams.get('isMobile');
    const serviceId = urlParams.get('serviceId') || '';

    if (isMobile) {
      setMobileData({
        url: urlParams.get('url'),
        token: urlParams.get('token'),
        hash: urlParams.get('hash'),
      });
    } else dispatch(getUserSSOPaymentRequest({ serviceId }));
  }, [dispatch, location.search]);

  useEffect(() => {
    if ((url && token && hash) || mobileData) {
      ssoPaymentFormRef.current.submit();
    }
  }, [url, token, hash, mobileData]);

  return (
    <div className={styles.wrapper}>
      <Spin className={styles.loader} />
      {/* Hidden form for submitting SSO */}
      <form
        id="ssoPaymentForm"
        action={mobileData?.url ?? url}
        method="post"
        ref={ssoPaymentFormRef}
        style={{ visibility: 'hidden' }}
      >
        <input type="hidden" name="data" value={mobileData?.token ?? token} />
        <input type="hidden" name="messageDigest" value={mobileData?.hash ?? hash} />
      </form>
    </div>
  );
};

export default memo(SSOPayment);
