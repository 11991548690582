import React, { memo, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { AP_GROUP_ORDER, AP_GROUP_SOURCES, CFD, ETF, FX } from 'shared-modules/constants';
import { getServiceQuantityUnit } from 'shared-modules/services';
import { changeSelectedApGroupMetaInfo } from 'shared-modules/redux/actions/portfolioActions';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import { Table } from '../../../../../../../../components';
import BuySellItem from '../../../../../../../../components/BuySellItem';
import { ShortName } from '../../../../../../../../components/ShortName';
import InstrumentPrecisionTableCell from '../../../../../../../../components/InstrumentPrecisionTableCell';
import SwitchComponent from '../SwitchComponent';
import Loader from '../Loader';
import styles from '../../detailTable.module.scss';
import { orderNameWithToolTips, quantityFooter, sideHeader } from '../../../../../../../../services/tableTemplate';

const RESIZED = {
  [FX]: {
    KEY: `portfolioFXAutoTradeOrdersTable`,
    COLUMNS: [88, 99, 44, 100, 99, 106, 84, 84, 82, 90, 80],
  },
  [ETF]: {
    KEY: `portfolioETFAutoTradeOrdersTable`,
    COLUMNS: [87, 87, 44, 87, 87, 87, 87, 87, 87, 87, 100],
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    KEY: `portfolioCFDAutoTradeOrdersTable`,
    COLUMNS: [87, 87, 44, 87, 87, 87, 87, 87, 87, 87, 100],
  },
};

const createEntryPriceAccessor = (entryPriceKey, latestNewOrderPriceKey, isMoneyHatch) => (row) => {
  const isActive = row.status === AP_GROUP_ORDER.ACTIVITY.ACTIVE.ID;
  let result = row[entryPriceKey];

  if (isActive) {
    result = row[latestNewOrderPriceKey];
  } else if (!isActive && isMoneyHatch) {
    result = '-';
  }
  return result;
};

const TableOrders = ({ id, serviceId }) => {
  const dispatch = useDispatch();

  const selectedApGroupMetaInfo = useSelector((state) => state.portfolio.selectedApGroupMetaInfo);
  const tableMetaInfo = useMemo(
    () => ({ loading: selectedApGroupMetaInfo.loading, ...selectedApGroupMetaInfo[serviceId] }),
    [selectedApGroupMetaInfo, serviceId],
  );

  const deleteApGroupItemIsLoading = useSelector((state) => state.portfolio.deletingApGroupItemIsLoading);

  const {
    apList,
    instrumentId: selectedApGroupInstrument,
    id: selectedApGroupId,
  } = useSelector((state) => state.portfolio.selectedApGroupData);
  const singleOrder = useSelector((state) => state.portfolio.apGroupsData?.[serviceId])?.find(
    (item) => item.instrumentId === selectedApGroupInstrument,
  );

  const tableSourceType = useMemo(() => (singleOrder ? singleOrder.sourceType : ''), [singleOrder]);

  const tableData = useMemo(
    () =>
      apList
        .map((item) => ({ ...item, instrumentId: selectedApGroupInstrument }))
        .filter((item) => (tableMetaInfo.side ? item.side === tableMetaInfo.side : true)),
    [apList, selectedApGroupInstrument, tableMetaInfo.side],
  );

  const isMoneyHatch = useMemo(() => tableSourceType === AP_GROUP_SOURCES.MONEY_HATCH.KEY, [tableSourceType]);

  const isLoading = useMemo(() => {
    const isIdMismatch = selectedApGroupId !== id;
    return deleteApGroupItemIsLoading || isIdMismatch;
  }, [deleteApGroupItemIsLoading, selectedApGroupId, id]);

  const resized = useMemo(() => {
    const { KEY, COLUMNS } = RESIZED[serviceId];
    return {
      key: KEY,
      default: [...COLUMNS],
    };
  }, [serviceId]);

  const columns = useMemo(
    () => [
      {
        Header: '銘柄',
        accessor: 'instrumentId',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (serviceId === FX ? removeSuffix(value) : <ShortName instrumentId={value} />),
      },
      {
        Header: '注文名',
        accessor: 'name',
        Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
      },
      {
        Header: sideHeader,
        accessor: 'side',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
      },
      {
        Header: `数量(${getServiceQuantityUnit(serviceId)})`,
        accessor: 'quantity',
        isNumber: true,
        Footer: quantityFooter,
      },
      {
        id: 'entryPrice1',
        Header: 'エントリー 価格1',
        accessor: createEntryPriceAccessor('entryPrice1', 'latestNewOrderPrice1', isMoneyHatch),
        isNumber: true,
        Cell: InstrumentPrecisionTableCell,
      },
      {
        id: 'entryPrice2',
        Header: 'エントリー 価格2',
        accessor: createEntryPriceAccessor('entryPrice2', 'latestNewOrderPrice2', isMoneyHatch),
        isNumber: true,
        Cell: InstrumentPrecisionTableCell,
      },
      {
        Header: `利確幅${serviceId === FX ? '(pips)' : ''}`,
        accessor: 'tp',
        isNumber: true,
        // eslint-disable-next-line react/prop-types
        Cell: serviceId === FX ? ({ cell: { value } }) => value || '-' : InstrumentPrecisionTableCell,
      },
      {
        Header: `損切幅${serviceId === FX ? '(pips)' : ''}`,
        accessor: 'sl',
        isNumber: true,
        // eslint-disable-next-line react/prop-types
        Cell: serviceId === FX ? ({ cell: { value } }) => value || '-' : InstrumentPrecisionTableCell,
      },
      {
        Header: 'フォロー値',
        accessor: 'follow',
        // eslint-disable-next-line react/prop-types
        Cell: serviceId === FX ? ({ cell: { value } }) => value || '-' : InstrumentPrecisionTableCell,
        isNumber: true,
      },
      {
        Header: 'カウンター値(価格指定)',
        // can't pass in multi accessor values, so use function -> we can customize what to display simply
        accessor: ({ counterPrice, counter }) => (counterPrice ? `(${counterPrice})` : counter),
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => value || '-',
        disableSortBy: true,
        minWidth: 95,
      },
      {
        Header: '稼働',
        accessor: 'operation',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => <SwitchComponent rowData={original} groupId={selectedApGroupId} />,
        sticky: 'right',
      },
    ],
    [selectedApGroupId, serviceId, isMoneyHatch],
  );

  const filterHandler = useCallback(
    ({ key, value }) => {
      if (['side'].includes(key)) {
        dispatch(changeSelectedApGroupMetaInfo({ key, value, serviceId }));
      }
    },
    [dispatch, serviceId],
  );

  return isLoading ? (
    <Loader />
  ) : (
    <Table
      key={serviceId}
      tableData={tableData}
      columns={columns}
      resized={resized}
      emptyText="注文がありません"
      className={styles.table}
      tableMetaInfo={tableMetaInfo}
      filterHandler={filterHandler}
      useServerSorting
    />
  );
};

TableOrders.propTypes = {
  id: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
};

export default memo(TableOrders);
