import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SIZES, WIDTH, COLORS } from 'shared-modules/constants';
import { useDispatch, useSelector } from 'react-redux';
import { ORDER_TYPES } from 'shared-modules/constants/manualTrade';
import { toggleUserSettingNewOrderSkipConfirmationRequest } from '../../../../redux/actions';
import Modal from '../../../../components/Modal';
import MarketOrder from './components/MarketOrder';
import StandardOrder from './components/StandardOrder';
import OCOOrder from './components/OCOOrder';
import IFDOrder from './components/IFDOrder';
import IFOOrder from './components/IFOOrder';
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import styles from './manualTradeConfirmation.module.scss';

const ManualTradeConfirmation = ({ isOpen, closeModal, callback, orderType }) => {
  const [innerOrderType, changeInnerOrderType] = useState(null);
  useEffect(() => {
    if (isOpen) {
      changeInnerOrderType(orderType);
    }
  }, [isOpen, orderType, changeInnerOrderType]);

  const dispatch = useDispatch();

  const serviceId = useSelector((state) => state.auth.serviceId);
  const withoutConfirmation = useSelector((state) => state.settings[serviceId].skipNewOrderConfirmation);
  const [thisModalWithoutConfirmation, changeThisModalWithoutConfirmation] = useState(withoutConfirmation);

  const handleChangeThisModalWithoutConfirmation = useCallback(() => {
    changeThisModalWithoutConfirmation(!thisModalWithoutConfirmation);
  }, [thisModalWithoutConfirmation]);

  const handleConfirm = useCallback(
    // Get X, Y position of clickedEvent
    ({ clientX, clientY }) => {
      if (withoutConfirmation !== thisModalWithoutConfirmation)
        dispatch(toggleUserSettingNewOrderSkipConfirmationRequest());
      callback({ x: Math.round(clientX), y: Math.round(clientY) }); // Send X, Y position of clickedEvent
      closeModal();
    },
    [callback, closeModal, dispatch, withoutConfirmation, thisModalWithoutConfirmation],
  );

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="注文確認" size={MODAL_SIZES.WIDTH_360}>
      <div className={styles.wrapper}>
        {innerOrderType === ORDER_TYPES.MARKET_ORDER.id && <MarketOrder />}
        {innerOrderType === ORDER_TYPES.STANDARD.id && <StandardOrder />}
        {innerOrderType === ORDER_TYPES.OCO.id && <OCOOrder />}
        {innerOrderType === ORDER_TYPES.IFD.id && <IFDOrder />}
        {innerOrderType === ORDER_TYPES.IFO.id && <IFOOrder />}
        <CustomCheckbox
          label="次回以降は確認を省略する"
          isChecked={thisModalWithoutConfirmation}
          onChange={handleChangeThisModalWithoutConfirmation}
          isReversed
          className={styles.checkboxRow}
          labelClassName={styles.checkboxLabel}
        />
        <CustomButton onClick={handleConfirm} className={styles.button} color={COLORS.RED} width={WIDTH.PERCENTAGE_100}>
          注文確定
        </CustomButton>
      </div>
    </Modal>
  );
};
ManualTradeConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  callback: PropTypes.func,
  orderType: PropTypes.number,
};
ManualTradeConfirmation.defaultProps = {
  callback: () => {},
  orderType: null,
};

export default memo(ManualTradeConfirmation);
