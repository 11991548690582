import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './alert.module.scss';

export const Alert = memo(({ className, title, children }) => {
  const [close, setClose] = useState(false);

  const handleClose = useCallback(() => {
    setClose(true);
  }, []);

  if (close) {
    return null;
  }

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <i aria-hidden className={classNames('material-icons', styles.icon)} onClick={handleClose}>
          close
        </i>
      </div>
      {children}
    </div>
  );
});

Alert.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Alert.defaultProps = {
  className: undefined,
  title: undefined,
};
