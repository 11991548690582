import moment from 'moment-timezone';
import { put, call, takeEvery, select, all } from 'redux-saga/effects';
import {
  GET_CHART_DATA_REQUEST,
  GET_SIMULATION_DATA_REQUEST,
  GET_SILENT_MULTI_SIMULATION_DATA_REQUEST,
  ADD_TO_CART_REQUEST,
  ADD_TO_PORTFOLIO_REQUEST,
  ADD_TO_PORTFOLIO_TECH_REQUEST,
  ADD_TO_CART_TECH_REQUEST,
  GET_TECH_SIMULATION_DATA_REQUEST,
} from '../actionConstants/builderConstants';
import {
  BUY_SELL_MAIN,
  CHART_RESOLUTION_MAIN,
  CHART_DATA_FORMAT,
  STRATEGY_SETS_RESULTS,
  DEFAULT_SERVICE_ID,
} from '../../constants';
import { BUILDER_EXCHANGE_TYPE_TO_SERVICE_ID, BUILDER_ORDER_TYPES } from '../../constants/builder';
import { STRATEGIES_TARGET } from '../../constants/apiConstant';
import {
  getBuilderChartDataRequestStartLoading,
  getBuilderChartDataRequestEndLoading,
  getBuilderChartDataSuccess,
  getBuilderSimulationDataSuccess,
  getBuilderSimulationDataRequestStartLoading,
  getBuilderSimulationDataRequestEndLoading,
  addToCartBuilderRequestStartLoading,
  addToCartBuilderRequestEndLoading,
  addToPortfolioBuilderRequestStartLoading,
  addToPortfolioBuilderRequestEndLoading,
  getCloseRateDataSuccess,
} from '../actions/builderActions';
import { getChartData } from '../../api/chartApi';
import {
  getStrategySimulation,
  addStrategy,
  addCartChartMakeStrategy,
  addTechnicalBuilder,
  getTechBuilderSimulation,
} from '../../api/strategyApi';
import { getCurrencyCartItemsCount } from '../../api/cartApi';
import { errorHandler } from './errorSaga';
import { sendNotificationError } from '../actions/notificationActions';
import { getAccountInfo } from './common';
import { DEBUG } from '../../config';

function entryPriceErrorHandler(error) {
  const matchStr = 'entryPrice1の値が無効です';
  const entryPriceError = error?.response?.data?.errorFieldList?.find((errorField) =>
    errorField?.reason?.match(new RegExp(matchStr)),
  );
  if (entryPriceError?.reason) {
    const priceRegexp = /\d+?\.\d+/g;
    const errorPriceText = Array.from([...entryPriceError.reason.substr(matchStr.length).matchAll(priceRegexp)], (m) =>
      Number(m[0]),
    );
    // change error message
    if (error?.response?.data?.message) {
      // eslint-disable-next-line no-param-reassign
      error.response.data.message = `エントリー価格1が${errorPriceText[0]}以上${errorPriceText[1]}以下になるように調整してください`;
    }
  }
}

function* getChartDataHandler() {
  try {
    yield put(getBuilderChartDataRequestStartLoading());
    const activeCurrency = yield select((state) => state.builder.activeCurrency);
    const orderType = yield select((state) => state.builder.orderType);

    const barType = yield select((state) => state.builder.techOrder.barType);
    let startTime;
    let resolutionId;
    if (orderType === BUILDER_ORDER_TYPES.TECH.ID && !(barType === CHART_RESOLUTION_MAIN.DAYS_1.TV_ID)) {
      let terms;
      if (barType === CHART_RESOLUTION_MAIN.HOURS_4.TV_ID) {
        resolutionId = CHART_RESOLUTION_MAIN.HOURS_4.ID;
        terms = 2;
      } else if (barType === CHART_RESOLUTION_MAIN.HOURS_8.TV_ID) {
        resolutionId = CHART_RESOLUTION_MAIN.HOURS_8.ID;
        terms = 4;
      }
      startTime = moment().tz('Asia/Tokyo').subtract(terms, 'month').format(CHART_DATA_FORMAT);
    } else {
      startTime = moment().tz('Asia/Tokyo').subtract(1, 'year').format(CHART_DATA_FORMAT);
      resolutionId = 6;
    }
    const endTime = moment().tz('Asia/Tokyo').format(CHART_DATA_FORMAT);

    const exchangeType = yield select((state) => state.builder.exchangeType);
    const serviceId = BUILDER_EXCHANGE_TYPE_TO_SERVICE_ID[exchangeType] ?? DEFAULT_SERVICE_ID;
    const accountInfo = yield* getAccountInfo();
    if (accountInfo[serviceId].isNotAvailable) {
      return;
    }
    const { data: askData } = yield call(getChartData, {
      instrumentId: activeCurrency,
      resolution: resolutionId,
      askBid: BUY_SELL_MAIN.BUY.CHART_ID,
      startTime,
      endTime,
      isMobile: false,
      serviceId,
    });
    const { data: bidData } = yield call(getChartData, {
      instrumentId: activeCurrency,
      resolution: resolutionId,
      askBid: BUY_SELL_MAIN.SELL.CHART_ID,
      startTime,
      endTime,
      isMobile: false,
      serviceId,
    });

    const chartData = askData;

    yield put(getBuilderChartDataSuccess({ chartData }));

    if (orderType === BUILDER_ORDER_TYPES.TECH.ID) {
      const isDay = barType === CHART_RESOLUTION_MAIN.DAYS_1.TV_ID;
      const barCloseMins = isDay ? 60 * 24 - 1 : Number(barType) - 1;
      const askChartData = Object.values(askData).slice(-2);
      const askStartDateTime = new Date(askChartData[1].startTime);
      const askEndDateTime = new Date(askChartData[1].endTime);
      const isClosedRatAsk = parseInt((askEndDateTime - askStartDateTime) / 1000 / 60, 10) === barCloseMins;
      const lastAskChart = isClosedRatAsk ? askChartData[1] : askChartData[0];

      const bidChartData = Object.values(bidData).slice(-2);
      const bidStartDateTime = new Date(bidChartData[1].startTime);
      const bidEndDateTime = new Date(bidChartData[1].endTime);
      const isClosedRateBid = parseInt((bidEndDateTime - bidStartDateTime) / 1000 / 60, 10) === barCloseMins;
      const lastBidChart = isClosedRateBid ? bidChartData[1] : bidChartData[0];

      yield put(
        getCloseRateDataSuccess({
          askCloseRate: lastAskChart.close,
          bidCloseRate: lastBidChart.close,
        }),
      );
    }
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getBuilderChartDataRequestEndLoading());
  }
}

function* getSimulationDataRequestHandler(action) {
  try {
    yield put(getBuilderSimulationDataRequestStartLoading());
    const {
      payload: { orderRequestData, callback, termId },
    } = action;

    const { data } = yield call(getStrategySimulation, { requestBody: orderRequestData, termId });

    yield put(getBuilderSimulationDataSuccess({ simulationData: data, termId }));
    if (callback) callback();
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getBuilderSimulationDataRequestEndLoading());
  }
}

function* getTechSimulationDataRequestHandler(action) {
  try {
    yield put(getBuilderSimulationDataRequestStartLoading());
    const {
      payload: { orderRequestData, callback, termId },
    } = action;

    // API用にリクエストボディを整形する
    const requestBody = {
      ...orderRequestData,
      strategy: orderRequestData?.strategyInfo,
      termId,
    };

    // 不要データを削除してリクエストボディを軽量化する
    delete requestBody.strategyInfo;

    const { data } = yield call(getTechBuilderSimulation, { requestBody });

    yield put(getBuilderSimulationDataSuccess({ simulationData: data, termId }));
    if (callback) callback();
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getBuilderSimulationDataRequestEndLoading());
  }
}

function* addToCartRequestHandler(action) {
  try {
    yield put(addToCartBuilderRequestStartLoading());
    const {
      payload: { strategySets, orderRequestData, callback, isChartMake },
    } = action;
    const { data: cartItemsCount } = yield call(getCurrencyCartItemsCount);
    if (cartItemsCount >= 10) {
      yield put(
        sendNotificationError({
          message: `カートに追加できる自動売買は10個までです。`,
        }),
      );
    } else {
      if (isChartMake) {
        yield call(addCartChartMakeStrategy, { strategySets, requestBody: orderRequestData });
      } else {
        yield call(addStrategy, { strategySets, target: STRATEGIES_TARGET.CART, requestBody: orderRequestData });
      }
      if (callback) callback();
    }
  } catch (e) {
    yield entryPriceErrorHandler(e);
    yield call(errorHandler, { error: e });
  } finally {
    yield put(addToCartBuilderRequestEndLoading());
  }
}

function* addToCartTechRequestHandler(action) {
  try {
    yield put(addToCartBuilderRequestStartLoading());
    const {
      payload: { strategySets, orderRequestData, callback },
    } = action;
    const { data: cartItemsCount } = yield call(getCurrencyCartItemsCount);
    if (cartItemsCount >= 10) {
      yield put(
        sendNotificationError({
          message: `カートに追加できる自動売買は10個までです。`,
        }),
      );
    } else {
      yield call(addTechnicalBuilder, { strategySets, target: STRATEGIES_TARGET.CART, requestBody: orderRequestData });
      if (callback) callback();
    }
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(addToCartBuilderRequestEndLoading());
  }
}

function* addToPortfolioRequestHandler(action) {
  const {
    payload: { strategySets, orderRequestData, successCallback, failCallback },
  } = action;
  try {
    yield put(addToPortfolioBuilderRequestStartLoading());
    const {
      data: { status },
    } = yield call(addStrategy, { strategySets, target: STRATEGIES_TARGET.PORTFOLIO, requestBody: orderRequestData });

    if (status === STRATEGY_SETS_RESULTS.SUCCESS) {
      successCallback?.(); // eslint-disable-line
    } else {
      failCallback?.(); // eslint-disable-line
    }
  } catch (e) {
    yield entryPriceErrorHandler(e);
    yield call(errorHandler, { error: e });
  } finally {
    yield put(addToPortfolioBuilderRequestEndLoading());
  }
}

function* addToPortfolioTechRequestHandler(action) {
  const {
    payload: { strategySets, orderRequestData, successCallback, failCallback },
  } = action;
  try {
    yield put(addToPortfolioBuilderRequestStartLoading());
    yield call(addTechnicalBuilder, {
      strategySets,
      target: STRATEGIES_TARGET.PORTFOLIO,
      requestBody: orderRequestData,
    });

    successCallback?.(); // eslint-disable-line
  } catch (e) {
    const { status } = e?.response;
    if (status === 400) {
      failCallback?.(); // eslint-disable-line
    } else {
      yield call(errorHandler, { error: e });
    }
  } finally {
    // 確認
    yield put(addToPortfolioBuilderRequestEndLoading());
  }
}

function* getSilentSimulationData({ orderRequestData, termId }) {
  try {
    yield put(getBuilderSimulationDataSuccess({ simulationData: null, termId }));
    const { data } = yield call(getStrategySimulation, { requestBody: orderRequestData, termId });
    yield put(getBuilderSimulationDataSuccess({ simulationData: data, termId }));
  } catch (e) {
    // no error handler
    if (DEBUG) {
      console.error(e); // eslint-disable-line
    }
  }
}

function* getSilentMultiSimulationDataRequestHandler(action) {
  const {
    payload: { orderRequestData, callback, termIdList },
  } = action;

  // parallel and join
  yield all(termIdList.map((termId) => call(getSilentSimulationData, { orderRequestData, termId })));

  if (callback) callback();
}

export default function* builderSaga() {
  yield takeEvery(GET_CHART_DATA_REQUEST, getChartDataHandler);
  yield takeEvery(GET_SIMULATION_DATA_REQUEST, getSimulationDataRequestHandler);
  yield takeEvery(GET_SILENT_MULTI_SIMULATION_DATA_REQUEST, getSilentMultiSimulationDataRequestHandler);
  yield takeEvery(GET_TECH_SIMULATION_DATA_REQUEST, getTechSimulationDataRequestHandler);
  yield takeEvery(ADD_TO_CART_REQUEST, addToCartRequestHandler);
  yield takeEvery(ADD_TO_CART_TECH_REQUEST, addToCartTechRequestHandler);
  yield takeEvery(ADD_TO_PORTFOLIO_REQUEST, addToPortfolioRequestHandler);
  yield takeEvery(ADD_TO_PORTFOLIO_TECH_REQUEST, addToPortfolioTechRequestHandler);
}
