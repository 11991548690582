import { memo, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TOOLTIP_HOW_TO_READ_CHART, TOOLTIP_HOW_TO_READ_CHART_WITH_SETTLEMENT } from 'shared-modules/constants/chart';
import styles from './chartDescription.module.scss';

export const DescriptionType = Object.freeze({
  default: 'default',
  settlement: 'settlement',
});
const DESCRIPTION_MAP = {
  [DescriptionType.default]: TOOLTIP_HOW_TO_READ_CHART,
  [DescriptionType.settlement]: TOOLTIP_HOW_TO_READ_CHART_WITH_SETTLEMENT,
};

export const ChartDescription = memo(({ className, descriptionType }) => {
  const tooltipId = useRef(uuid());
  const DESCRIPTION = DESCRIPTION_MAP[descriptionType];

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.label}>チャートの見方</div>
      <i
        className={classNames('material-icons-outlined', styles.icon)}
        data-for={tooltipId.current}
        data-tip={DESCRIPTION}
      >
        info
      </i>
      <ReactTooltip id={tooltipId.current} className={styles.description} />
    </div>
  );
});

ChartDescription.propTypes = {
  className: PropTypes.string,
  descriptionType: PropTypes.string,
};

ChartDescription.defaultProps = {
  className: undefined,
  descriptionType: DescriptionType.default,
};
