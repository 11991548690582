import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { BUY_SELL_CHART_VALUE, FX } from 'shared-modules/constants';
import { getColorBySide } from 'shared-modules/services/builder';
import { widget } from 'shared-modules/thirdPartyLibrary/TradingView/charting_library';
import { removeSuffix, useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import {
  drawingAccess,
  getLineStyleBySide,
  studiesAccess,
  studiesOverrides,
  removeAllLines,
  overrides,
} from './Chart/Chart';
import datafeed from './Chart/datafeed';
import indicators from './Chart/indicators';
import { changeChartSide } from '../redux/actions';

const defaultWidgetOptions = {
  interval: '1D',
  library_path: '/charting_library/',
  custom_css_url: 'customStyle.css',
  fullscreen: false,
  autosize: true,
  drawings_access: drawingAccess,
  studies_access: studiesAccess,
  studies_overrides: studiesOverrides,
  locale: 'ja',
  timezone: 'Asia/Tokyo',
  custom_indicators_getter(PineJS) {
    return Promise.resolve(indicators(PineJS));
  },
  disabled_features: [
    'display_market_status',
    'header_screenshot',
    'header_saveload',
    'header_undo_redo',
    'header_fullscreen_button',
    'header_compare',
    'timeframes_toolbar',
    'study_dialog_search_control',
    'create_volume_indicator_by_default',
    'header_symbol_search',
    'symbol_search_hot_key',
    'items_favoriting',
  ],
  favorites: {
    intervals: ['1H', '4H', '1D', '1W', '1M'],
  },
  enabled_features: ['hide_left_toolbar_by_default', 'chart_zoom'],
  theme: 'Dark',
  overrides,
  settings_overrides: overrides,
};

const getSymbol = ({ selectedInstrumentId, serviceId, instrumentList, chartSide }) =>
  `${serviceId === FX ? removeSuffix(selectedInstrumentId) : instrumentList[selectedInstrumentId].shortName}(${
    BUY_SELL_CHART_VALUE[chartSide]
  })`;

const TVChart = ({ selectedInstrumentId, orders }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const tvWidgetRef = useRef();
  const ordersRef = useRef({});
  const dropdownRef = useRef(null);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const serviceId = instrumentList?.[selectedInstrumentId]?.serviceId;
  const instrumentOptions = useApOrManualInstrumentOptions(false, serviceId);
  const selectedSide = useSelector((state) => state.manualTrade.chartSide);
  const createAskBidToggle = (title) => <div className="askbid-toggle">{title}</div>;

  const toggleChartSide = useCallback(
    (value) => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ side: value }));
      }
      dispatch(changeChartSide({ id: value }));
    },
    [dispatch],
  );
  const handleDropdownItemSelect = useCallback(
    (title, value) => {
      dropdownRef.current.applyOptions({
        title,
      });
      toggleChartSide(value);
    },
    [toggleChartSide],
  );
  const createDropdownItem = useCallback(
    (title, value) => ({
      title,
      onSelect: () => {
        handleDropdownItemSelect(title, value);
      },
    }),
    [handleDropdownItemSelect],
  );
  const dropdownOptionsRef = useRef(null);
  const dropdownOptions = useMemo(
    () => ({
      title: createAskBidToggle(Number(selectedSide) ? 'ASK' : 'BID'),
      items: [createDropdownItem(createAskBidToggle('BID'), 0), createDropdownItem(createAskBidToggle('ASK'), 1)],
    }),
    [createDropdownItem, selectedSide],
  );

  const drawOrderLine = (o) => {
    ordersRef.current[o.orderId] = tvWidgetRef.current
      .activeChart()
      .createOrderLine()
      .setText('')
      .setQuantity('')
      .setLineColor(getColorBySide(Number(o.side)))
      .setLineStyle(getLineStyleBySide(Number(o.side)))
      .setPrice(Number(o.price));
  };

  useEffect(() => {
    toggleChartSide(selectedSide);
  }, [selectedSide, toggleChartSide]);

  useEffect(() => {
    const widgetOptions = {
      ...defaultWidgetOptions,
      symbol: getSymbol({ selectedInstrumentId, serviceId, instrumentList, chartSide: selectedSide }),
      datafeed: datafeed({ instrumentOptions, isMobile: false, serviceId, instrumentList }),
      container: ref.current,
    };
    tvWidgetRef.current = new widget(widgetOptions); // eslint-disable-line

    tvWidgetRef.current.onChartReady(() => {
      tvWidgetRef.current.headerReady().then(() => {
        const addDropDown = async () => {
          dropdownRef.current = await tvWidgetRef.current.createDropdown(dropdownOptionsRef.current);
        };
        addDropDown();
      });
    });

    return () => {
      if (tvWidgetRef.current !== null) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstrumentId, instrumentList, selectedSide, serviceId]);

  useEffect(() => {
    dropdownRef.current?.applyOptions(dropdownOptions);
    dropdownOptionsRef.current = dropdownOptions;
  }, [dropdownOptions]);

  useEffect(() => {
    tvWidgetRef.current.onChartReady(() => {
      try {
        removeAllLines(ordersRef);
        orders.forEach(drawOrderLine);
      } catch (error) {
        // do nothing
      }
    });
  }, [orders, selectedSide]);

  return <div ref={ref} style={{ height: '100%' }} />;
};

TVChart.propTypes = {
  selectedInstrumentId: PropTypes.string.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})),
};

TVChart.defaultProps = {
  orders: [],
};

export default TVChart;
