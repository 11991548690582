import { call, put, retry, select } from 'redux-saga/effects';
import { getNews } from '../../../api/manualTradeApi';
import {
  getNewsSuccess,
  getNewsRequestStartLoading,
  getNewsRequestStopLoading,
} from '../../actions/manualTradeActions';
import { RETRY_MAX_TRIES, RETRY_DELAY } from '../../../constants/apiConstant';
import { NEWS_SOURCE_TO_SERVICES } from '../../../constants';
import { checkIsWebApp } from '../../../services';
import { isNotAvailableNews } from '../../../utils/service';
import { errorHandler } from '../errorSaga';
import { getAccountInfo } from '../common';

function* getNewsRequestHandler() {
  try {
    yield put(getNewsRequestStartLoading());
    const selectedNewsTab = yield select((state) => state.webManualTrade?.newsActiveTabId);
    const selectedMobileNewsTab = yield select((state) => state.mobileServices?.newsActiveTabId);
    const selectedTab = checkIsWebApp() ? selectedNewsTab : selectedMobileNewsTab;
    const currentServiceId = yield select((state) => state.auth.serviceId);
    const serviceIds = NEWS_SOURCE_TO_SERVICES[selectedTab] ?? [currentServiceId];
    const accountInfo = yield* getAccountInfo();
    if (serviceIds.every((service) => isNotAvailableNews(accountInfo[service]))) {
      return;
    }
    const serviceId = serviceIds.find((service) => !isNotAvailableNews(accountInfo[service]));
    if (serviceId) {
      const res = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getNews, { serviceId });
      yield put(getNewsSuccess(res?.data?.list));
    }
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getNewsRequestStopLoading());
  }
}

export default getNewsRequestHandler;
