import { get, patch, invokeApi, createAdditionalParams } from './index';

export const getRecommendData = async ({
  userId,
  accountType,
  hasMadeOrders,
  firstDepositDate,
  firstDepositAmount,
}) => {
  const additionalParams = await createAdditionalParams();
  const requestQuery = `?userId=${userId}&accountType=${accountType}&hasMadeOrders=${hasMadeOrders}
    &firstDepositDate=${firstDepositDate}&firstDepositAmount=${firstDepositAmount}`;
  return invokeApi({}, `recommends${requestQuery}`, get, additionalParams);
};

export const updateLastRecommendViewTime = async ({ userId }) => {
  const additionalParams = await createAdditionalParams();
  const requestQuery = `?userId=${userId}`;
  return invokeApi({}, `user_strategy_information/last_recommend_view_time${requestQuery}`, patch, additionalParams);
};
