import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  MODAL_SIZES,
  NEWS_COPYRIGHT,
  NEWS_COPYRIGHT_NOTES,
  NEWS_COPYRIGHT_TEXT,
  NEWS_COPYRIGHT_TITLE,
} from 'shared-modules/constants';
import Modal from '../../../../components/Modal';
import CustomButton from '../../../../components/CustomButton';
import styles from './copyrightInfo.module.scss';

const BUTTON_WIDTH = 312;

const CopyrightInfo = ({ isOpen, closeModal, isOverlap }) => (
  <Modal
    isOpen={isOpen}
    closeModal={closeModal}
    isOverlap={isOverlap}
    title={NEWS_COPYRIGHT_TITLE}
    size={MODAL_SIZES.WIDTH_640}
  >
    <div className={styles.bodyText}>
      <p>
        {NEWS_COPYRIGHT_TEXT}
        <br />
        {NEWS_COPYRIGHT_NOTES}
      </p>
      <p>{NEWS_COPYRIGHT}</p>
    </div>
    <div className={styles.buttonRow}>
      <CustomButton width={BUTTON_WIDTH} onClick={closeModal} className={styles.confirmButton}>
        閉じる
      </CustomButton>
    </div>
  </Modal>
);

CopyrightInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOverlap: PropTypes.bool,
};
CopyrightInfo.defaultProps = {
  isOverlap: false,
};

export default memo(CopyrightInfo);
