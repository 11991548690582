/* eslint-disable import/no-unresolved */
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  CFD,
  ETF,
  FX,
  MARGIN_TOOLTIP,
  RECOMMENDED_MARGIN_LABEL,
  REQUIRED_MARGIN_LABEL,
  REQUIRED_MARGIN_TOOLTIP,
} from '../../constants';
import { calculateMaxItemForCart } from '../index';
import { useTotalMarginForModal } from '.';
import { useSimulationChartDataLab, useSimulationChartDataSelection } from '../../hooks/select';
import { useCalculatingMarginByStrategyList } from '../../hooks';
import { getServiceId } from '../../utils';

const MIN_COUNT_VALUE = 1;
const INPUT_NAME = 'count';

const useAutoSelectRunNowConfirmation = ({ isOpen, onClose, data }) => {
  const { sets, callback, isLab } = data;

  const [countValue, setCount] = useState(MIN_COUNT_VALUE);
  const [errorMessages, changeErrorMessages] = useState([]);

  useEffect(() => {
    if (isOpen) changeErrorMessages([]);
  }, [isOpen]);

  useEffect(() => {
    if (sets) setCount(sets);
  }, [sets, isOpen]);

  const isDisableConfirmButton = useMemo(() => Boolean(errorMessages.length), [errorMessages]);

  const closeModalAndRefreshState = useCallback(() => {
    onClose();
    setCount(sets ?? MIN_COUNT_VALUE);
  }, [onClose, sets]);

  const onConfirm = useCallback(() => {
    if (errorMessages.length) return;
    closeModalAndRefreshState();
    if (callback) {
      callback(countValue);
    }
  }, [callback, countValue, closeModalAndRefreshState, errorMessages]);

  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const termId = useSelector((state) => state.autoSelect.selectedTermId);
  const selectStrategyList = useSelector((state) => state.autoSelect.selectionModalInfo[termId]?.strategyList);
  const labStrategyList = useSelector((state) => state.labs.publishedLabs.simulation.result.strategyList) || [];

  const selectionSimulationData = useSimulationChartDataSelection(countValue);
  const { simulationData } = useSimulationChartDataLab(countValue);
  const labMarginRecommended = simulationData?.marginRecommended ?? {};
  const {
    marginRecommended: selectionMarginRecommended,
    marginRecommendedFX,
    marginRecommendedETF,
    marginRecommendedCFD,
  } = selectionSimulationData.simulationData ?? {};

  let strategyList;
  let marginRecommended;
  if (isLab) {
    strategyList = labStrategyList.map((strategy) => {
      const { strategySets, ...strategyDetail } = strategy;
      const { instrumentId } = strategyDetail;
      const serviceId = getServiceId(instrumentId, instrumentList);
      return {
        strategySets,
        strategyDetail: {
          serviceId,
          ...strategyDetail,
        },
      };
    });
    marginRecommended = labMarginRecommended;
  } else {
    strategyList = selectStrategyList;
    marginRecommended = selectionMarginRecommended;
  }

  const totalMarginRequired = useCalculatingMarginByStrategyList(strategyList, countValue);
  const totalMarginRecommended = useTotalMarginForModal(isOpen, marginRecommended, countValue);
  const totalMarginRecommendedFX = useTotalMarginForModal(isOpen, marginRecommendedFX, countValue);
  const totalMarginRecommendedETF = useTotalMarginForModal(isOpen, marginRecommendedETF, countValue);
  const totalMarginRecommendedCFD = useTotalMarginForModal(isOpen, marginRecommendedCFD, countValue);

  const strategyListFx = strategyList?.filter((x) => x.strategyDetail.serviceId === FX) ?? [];
  let totalMarginRequiredFX = useCalculatingMarginByStrategyList(strategyListFx, countValue);
  if (!strategyListFx.length) {
    totalMarginRequiredFX = null;
  }
  const strategyListEtf = strategyList?.filter((x) => x.strategyDetail.serviceId === ETF) ?? [];
  let totalMarginRequiredETF = useCalculatingMarginByStrategyList(strategyListEtf, countValue);
  if (!strategyListEtf.length) {
    totalMarginRequiredETF = null;
  }
  const strategyListCfd = strategyList?.filter((x) => x.strategyDetail.serviceId === CFD) ?? [];
  let totalMarginRequiredCFD = useCalculatingMarginByStrategyList(strategyListCfd, countValue);
  if (!strategyListCfd.length) {
    totalMarginRequiredCFD = null;
  }

  const calculatedMaxValue = useMemo(
    () =>
      calculateMaxItemForCart({
        strategyList,
        instrumentList,
      }),
    [strategyList, instrumentList],
  );

  const validateInput = useCallback(
    (value) => {
      if (MIN_COUNT_VALUE <= value && value <= calculatedMaxValue) {
        changeErrorMessages([]);
      } else {
        changeErrorMessages([
          { inputName: INPUT_NAME, errorMessage: `セット数は1以上${calculatedMaxValue}までご設定ください。` },
        ]);
      }
    },
    [calculatedMaxValue],
  );

  return useMemo(
    () => ({
      modalTitle: '今すぐ稼働 確認',
      errorMessages,
      countInput: {
        get: countValue,
        set: setCount,
        label: 'セット数',
        name: INPUT_NAME,
        validateFunction: validateInput,
      },
      runNote:
        'セット数を変更すると、取引数量も変更されます。稼働開始後、必ずホーム画面のポートフォリオより取引数量をご確認ください。',
      totalMarginRecommended: {
        get: totalMarginRecommended,
        label: RECOMMENDED_MARGIN_LABEL,
        tooltip: MARGIN_TOOLTIP,
      },
      totalMarginRecommendedFX: {
        get: totalMarginRecommendedFX,
      },
      totalMarginRecommendedETF: {
        get: totalMarginRecommendedETF,
      },
      totalMarginRecommendedCFD: {
        get: totalMarginRecommendedCFD,
      },
      totalMarginRequired: {
        get: totalMarginRequired,
        label: REQUIRED_MARGIN_LABEL,
        tooltip: REQUIRED_MARGIN_TOOLTIP,
      },
      totalMarginRequiredFX: {
        get: totalMarginRequiredFX,
      },
      totalMarginRequiredETF: {
        get: totalMarginRequiredETF,
      },
      totalMarginRequiredCFD: {
        get: totalMarginRequiredCFD,
      },
      cancelButton: {
        label: '戻る',
        handler: closeModalAndRefreshState,
      },
      submitButton: {
        label: '稼働開始',
        isDisabled: isDisableConfirmButton,
        handler: onConfirm,
      },
    }),
    [
      closeModalAndRefreshState,
      countValue,
      errorMessages,
      isDisableConfirmButton,
      onConfirm,
      totalMarginRecommended,
      totalMarginRecommendedFX,
      totalMarginRecommendedETF,
      totalMarginRecommendedCFD,
      totalMarginRequired,
      totalMarginRequiredFX,
      totalMarginRequiredETF,
      totalMarginRequiredCFD,
      validateInput,
    ],
  );
};

export default useAutoSelectRunNowConfirmation;
