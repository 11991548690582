import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import China from '../../assets/flagsETF/China.svg';
import EU from '../../assets/flagsETF/EU.svg';
import Gold from '../../assets/flagsETF/Gold.svg';
import JP from '../../assets/flagsETF/JP.svg';
import Oil from '../../assets/flagsETF/Oil.svg';
import US from '../../assets/flagsETF/US.svg';
import World from '../../assets/flagsETF/World.svg';
import Invast from '../../assets/flagsETF/invast-circle.svg';
import Gold2 from '../../assets/flagsETF/Gold_2.svg';
import Silver from '../../assets/flagsETF/Silver.svg';
import TOPIX from '../../assets/flagsETF/1306.svg';
import NIKKEI225 from '../../assets/flagsETF/1321.svg';
import TSE1357 from '../../assets/flagsETF/1357.svg';
import TSE1570 from '../../assets/flagsETF/1570.svg';
import ACWI from '../../assets/flagsETF/ACWI.svg';
import FAS from '../../assets/flagsETF/FAS.svg';
import HYG from '../../assets/flagsETF/HYG.svg';
import IWD from '../../assets/flagsETF/IWD.svg';
import IWM from '../../assets/flagsETF/IWM.svg';
import LQD from '../../assets/flagsETF/LQD.svg';
import QQQ from '../../assets/flagsETF/QQQ.svg';
import SPY from '../../assets/flagsETF/SPY.svg';
import SSO from '../../assets/flagsETF/SSO.svg';
import TQQQ from '../../assets/flagsETF/TQQQ.svg';
import VWO from '../../assets/flagsETF/VWO.svg';
import XLE from '../../assets/flagsETF/XLE.svg';
import XLF from '../../assets/flagsETF/XLF.svg';
import XLI from '../../assets/flagsETF/XLI.svg';
import ARKK from '../../assets/flagsETF/ARKK.svg';
import VXX from '../../assets/flagsETF/VXX.svg';
import XLY from '../../assets/flagsETF/XLY.svg';
import XLK from '../../assets/flagsETF/XLK.svg';
import XLP from '../../assets/flagsETF/XLP.svg';
import XLU from '../../assets/flagsETF/XLU.svg';
import styles from './etfInstrumentIcon.module.scss';

const ICONS = {
  'China.svg': China,
  'EU.svg': EU,
  'Gold.svg': Gold,
  'JP.svg': JP,
  'Oil.svg': Oil,
  'US.svg': US,
  'World.svg': World,
  'invast-circle.svg': Invast,
  'Gold_2.svg': Gold2,
  'Silver.svg': Silver,
  '1306.svg': TOPIX,
  '1321.svg': NIKKEI225,
  '1357.svg': TSE1357,
  '1570.svg': TSE1570,
  'ACWI.svg': ACWI,
  'FAS.svg': FAS,
  'HYG.svg': HYG,
  'IWD.svg': IWD,
  'IWM.svg': IWM,
  'LQD.svg': LQD,
  'QQQ.svg': QQQ,
  'SPY.svg': SPY,
  'SSO.svg': SSO,
  'TQQQ.svg': TQQQ,
  'VWO.svg': VWO,
  'XLE.svg': XLE,
  'XLF.svg': XLF,
  'XLI.svg': XLI,
  'ARKK.svg': ARKK,
  'VXX.svg': VXX,
  'XLY.svg': XLY,
  'XLP.svg': XLP,
  'XLU.svg': XLU,
  'XLK.svg': XLK,
};

const FULL_SIZE_ICON = Invast;

const EtfInstrumentIcon = ({ instrumentId, className, size, noFullSize }) => {
  const instrumentMap = useSelector((state) => state.settings.instrumentList);

  const src = useMemo(
    () => _.get(ICONS, _.get(instrumentMap, [instrumentId, 'image']), null),
    [instrumentId, instrumentMap],
  );

  const innerClassName = useMemo(() => {
    return classNames(className, styles[size], { [styles.fullSize]: FULL_SIZE_ICON === src && !noFullSize });
  }, [className, size, src, noFullSize]);

  if (src) {
    return <img className={innerClassName} src={src} alt="" />;
  }
  return <div className={innerClassName} />;
};

EtfInstrumentIcon.propTypes = {
  instrumentId: PropTypes.string.isRequired,
  className: PropTypes.string,
  noFullSize: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small', 'littleTiny', 'tiny']),
};

EtfInstrumentIcon.defaultProps = {
  className: '',
  size: 'medium',
  noFullSize: false,
};

export default memo(EtfInstrumentIcon);
