import { put, takeEvery, retry, call, takeLatest, select, all, join, spawn } from 'redux-saga/effects';
import {
  ETF,
  ETF_ADDITIONAL_INSTRUMENT_ID,
  FX,
  ONE_YEAR,
  SIX_MONTH,
  termOptions,
  THREE_MONTH,
  THREE_YEAR,
  TWO_YEAR,
  CFD,
  CFD_ADDITIONAL_INSTRUMENT_IDS,
  FX_ADDITIONAL_INSTRUMENT_IDS,
} from '../../constants';
import {
  GET_INSTRUMENTS_LIST_REQUEST,
  GET_ACCOUNT_INFO_REQUEST,
  GET_SETTINGS_REQUEST,
  UPDATE_ORDER_SETTINGS_REQUEST,
  GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST,
  TOGGLE_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST,
  GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST,
  TOGGLE_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST,
  ACCEPT_AGREEMENT_INFO_REQUEST,
  GET_DISPLAYING_CURRENCY_PAIR_REQUEST,
  UPDATE_DISPLAYING_CURRENCY_PAIR,
  GET_USER_MAIL_SETTINGS_REQUEST,
  UPDATE_USER_MAIL_SETTINGS_REQUEST,
  GET_ORDER_SETTINGS_REQUEST,
  LOSSCUT_STATUS_REQUEST,
  GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST,
  UPDATE_USER_LEARN_TRIAUTO_STATUS_REQUEST,
} from '../actionConstants/settingsConstants';
import {
  DISPLAYED_CURRENCY_PAIR_STATUS,
  KEY_FOR_DEFAULT_SELECTED_CFD_INSTRUMENT_ID,
  KEY_FOR_DEFAULT_SELECTED_ETF_INSTRUMENT_ID,
  KEY_FOR_DEFAULT_SELECTED_FX_INSTRUMENT_ID,
} from '../../constants/manualTrade';
import { KEY_FOR_DEFAULT_SELECTED_PORTFOLIO_INSTRUMENTS } from '../../constants/portfolio';
import {
  RETRY_MAX_TRIES,
  RETRY_DELAY,
  SYSTEM_NOT_MAINTENANCE_STATUS,
  LOGIN_ACCOUNT_TYPES,
  DEFAULT_LOGIN_ACCOUNT_TYPE,
  NOT_FOUND_CODE,
} from '../../constants/apiConstant';
import {
  getInstruments,
  getAccountInfo,
  getSettings,
  getOrderSettings,
  updateOrderSettings,
  getUserSettingNewOrderSkipConfirmation,
  updateUserSettingNewOrderSkipConfirmation,
  getUserSettingCloseOrderSkipConfirmation,
  updateUserSettingCloseOrderSkipConfirmation,
  getUserMailSettings,
  updateUserMailSettings,
  updateUserRatePanel,
  getUserRatePanel,
  changeAccountInfo,
  getAccountLosscutInfo,
  getUserSettingLearnTriautoConfirmationRequest,
  updateLearnTriautoStatus,
} from '../../api/settingsApi';
import {
  getInstrumentListSuccess,
  getAccountInfoSuccess,
  getSettingsSuccess,
  updateOrderSettingsSuccess,
  updateOrderSettingsStartLoading,
  updateOrderSettingsStopLoading,
  getUserSettingNewOrderSkipConfirmationRequest,
  getUserSettingNewOrderSkipConfirmationSuccess,
  getUserSettingCloseOrderSkipConfirmationRequest,
  getUserSettingCloseOrderSkipConfirmationSuccess,
  getUserMailSettingsSuccess,
  updateUserMailSettingsSuccess,
  acceptAgreementInfoRequestStartLoading,
  acceptAgreementInfoRequestEndLoading,
  getDisplayingCurrencyPairRequest,
  getDisplayingCurrencyPairSuccess,
  getOrderSettingsSuccess,
  getOrderSettingsStartLoading,
  getOrderSettingsStopLoading,
  getLoginMethodSuccess,
  getInstrumentListStartLoading,
  getInstrumentListStopLoading,
  getAccountInfoStartLoading,
  getAccountInfoStopLoading,
  getSettingsStartLoading,
  getSettingsStopLoading,
  getUserMailSettingsStartLoading,
  getUserMailSettingsStopLoading,
  changeLosscutStatus,
  getUserSettingLearnTriautoConfirmationRequestSuccess,
  updateDisclaimerFlg,
} from '../actions/settingsActions';
import { changeTradeSelectedInstrumentId } from '../actions/manualTradeActions';
import {
  createInstrumentsOptions,
  createSelectionTermsOptions,
  setDefaultSelectionTerm,
} from '../actions/cosntantsActions';
import { LOSSCUT_MODAL_MESSAGE, LOSSCUT_MODAL_TITLE } from '../../constants/errorMesages';
import { errorHandler } from './errorSaga';
import { checkIsWebApp, getDefaultValuesFromLocalStorage, saveDefaultValuesFromLocalStorage } from '../../services';
import { sendNotificationError } from '../actions/notificationActions';
import { getAccountInfo as getAccountInfoGen } from './common';
import { addSuffixInstrumentId } from '../../hooks/symbol';

function* instrumentListRequestHandler(action) {
  const { payload: { isPublic, isRefetch } = {} } = action;
  try {
    if (!isRefetch) {
      yield put(getInstrumentListStartLoading());
    }

    const [{ data: FXData }, { data: ETFData }, { data: CFDData }] = yield all([
      retry(RETRY_MAX_TRIES, RETRY_DELAY, getInstruments, { isPublic, serviceId: FX }),
      retry(RETRY_MAX_TRIES, RETRY_DELAY, getInstruments, { isPublic, serviceId: ETF }),
      retry(RETRY_MAX_TRIES, RETRY_DELAY, getInstruments, { isPublic, serviceId: CFD }),
    ]);
    const requestsResult = [
      ...FXData.map((item) => ({ ...item, serviceId: FX })),
      ...ETFData.map((item) => {
        if (item.instrumentId === ETF_ADDITIONAL_INSTRUMENT_ID) {
          return {
            ...item,
            serviceId: ETF,
            instrumentId: `${ETF}.${ETF_ADDITIONAL_INSTRUMENT_ID}`,
          };
        }
        return { ...item, serviceId: ETF };
      }),
      ...CFDData.map((item) => {
        if (CFD_ADDITIONAL_INSTRUMENT_IDS[item.instrumentId]) {
          return {
            ...item,
            serviceId: CFD,
            instrumentId: `${CFD}.${item.instrumentId}`,
          };
        }
        return { ...item, serviceId: CFD };
      }),
    ];

    const instrumentList = requestsResult.reduce((acc, item) => {
      acc[item.instrumentId] = {
        ...item,
        margins: item.margins.reduce((marginAcc, marginItem) => {
          return {
            ...marginAcc,
            [marginItem.marginGroup]: marginItem,
          };
        }, {}),
        settings: item.settings.reduce((settingsAcc, settingsItem) => {
          return {
            ...settingsAcc,
            [settingsItem.tradeMethod]: settingsItem,
          };
        }, {}),
      };
      return acc;
    }, {});

    yield put(getInstrumentListSuccess({ instrumentList }));

    const FXOptions = [];
    FXData.forEach(({ instrumentId, symbol }) => {
      if (!FX_ADDITIONAL_INSTRUMENT_IDS[instrumentId]) {
        FXOptions.push({ value: instrumentId, label: symbol });
      }
    });
    yield put(
      createInstrumentsOptions({
        serviceId: FX,
        options: FXOptions,
      }),
    );

    const ETFOptions = [];
    ETFData.forEach(({ instrumentId, shortName }) => {
      if (instrumentId !== ETF_ADDITIONAL_INSTRUMENT_ID) {
        ETFOptions.push({ value: instrumentId, label: shortName });
      }
    });

    yield put(
      createInstrumentsOptions({
        serviceId: ETF,
        options: ETFOptions,
      }),
    );

    const CFDOptions = [];
    CFDData.forEach(({ instrumentId, shortName }) => {
      if (!CFD_ADDITIONAL_INSTRUMENT_IDS[instrumentId]) {
        CFDOptions.push({ value: instrumentId, label: shortName });
      }
    });

    yield put(
      createInstrumentsOptions({
        serviceId: CFD,
        options: CFDOptions,
      }),
    );

    const selectedPortfolioInstruments = getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DEFAULT_SELECTED_PORTFOLIO_INSTRUMENTS[FX],
    });

    if (!selectedPortfolioInstruments) {
      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_SELECTED_PORTFOLIO_INSTRUMENTS[FX],
        value: JSON.stringify(FXOptions),
      });
      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_SELECTED_PORTFOLIO_INSTRUMENTS[ETF],
        value: JSON.stringify(ETFOptions),
      });
      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_SELECTED_PORTFOLIO_INSTRUMENTS[CFD],
        value: JSON.stringify(CFDOptions),
      });
    }

    const isMobile = !checkIsWebApp();
    const {
      [FX]: persistedFX,
      [ETF]: persistedETF,
      [CFD]: persistedCFD,
    } = yield select((state) => state.manualTrade.selectedInstrumentId);

    /**
     * use persisted instrument on MOBILE if there is some
     * the first instrument for WEB as default
     */
    const FXDefaultInstrument = isMobile && persistedFX ? persistedFX : FXOptions[0].value;
    const ETFDefaultInstrument = isMobile && persistedETF ? persistedETF : ETFOptions[0].value;
    const CFDDefaultInstrument = isMobile && persistedCFD ? persistedCFD : CFDOptions[0].value;

    const selectedFXInstrumentId = addSuffixInstrumentId(
      getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_SELECTED_FX_INSTRUMENT_ID,
        defaultValue: FXDefaultInstrument,
      }),
    );

    const selectedETFInstrumentId = getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DEFAULT_SELECTED_ETF_INSTRUMENT_ID,
      defaultValue: ETFDefaultInstrument,
    });

    const selectedCFDInstrumentId = getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DEFAULT_SELECTED_CFD_INSTRUMENT_ID,
      defaultValue: CFDDefaultInstrument,
    });

    yield put(changeTradeSelectedInstrumentId({ id: selectedFXInstrumentId, serviceId: FX }));
    yield put(changeTradeSelectedInstrumentId({ id: selectedETFInstrumentId, serviceId: ETF }));
    yield put(changeTradeSelectedInstrumentId({ id: selectedCFDInstrumentId, serviceId: CFD }));
  } catch (e) {
    yield call(errorHandler, { error: e, isInitialRequest: true });
  } finally {
    if (!isRefetch) {
      yield put(getInstrumentListStopLoading());
    }
  }
}

export function* accountInfoRequestHandler(action) {
  const { payload: { isRefetch } = {} } = action;
  try {
    if (!isRefetch) {
      yield put(getAccountInfoStartLoading());
    }

    const accountInfo = yield* getAccountInfoGen();

    let FXAccount;
    let ETFAccount = null;
    let CFDAccount = null;

    // TODO kazama150180 all で並列でアカウント情報を取るようにした方が効率が良いのでは？
    let getFXAccountTask;
    if (!accountInfo[FX].isMaintenance) {
      getFXAccountTask = yield spawn(getAccountInfo, { serviceId: FX });
    }

    if (!accountInfo[ETF].isMaintenance) {
      try {
        if (!accountInfo[ETF].notExist) {
          const ETFResponse = yield call(getAccountInfo, { serviceId: ETF });
          ETFAccount = ETFResponse.data;
        }
      } catch (e) {
        if (e.response?.status === NOT_FOUND_CODE) {
          ETFAccount = null;
        } else {
          throw e;
        }
      }
    }

    if (!accountInfo[CFD].isMaintenance) {
      try {
        if (!accountInfo[CFD].notExist) {
          const CFDResponse = yield call(getAccountInfo, { serviceId: CFD });
          CFDAccount = CFDResponse.data;
        }
      } catch (e) {
        if (e.response?.status === NOT_FOUND_CODE) {
          CFDAccount = null;
        } else {
          throw e;
        }
      }
    }

    if (!accountInfo[FX].isMaintenance) {
      const getFXAccountResult = yield join(getFXAccountTask);
      FXAccount = getFXAccountResult.data;
    }

    yield put(getAccountInfoSuccess({ [FX]: FXAccount, [ETF]: ETFAccount, [CFD]: CFDAccount }));

    if (!isRefetch) {
      yield put(getAccountInfoStopLoading());
    }
  } catch (e) {
    yield call(errorHandler, { error: e, isInitialRequest: true });
    throw e;
  }
}

export function* getLoginMethodHandler() {
  try {
    const {
      data: { login_method: loginMethod },
    } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getSettings, { isPublic: true });

    if (loginMethod === '1') {
      yield put(getLoginMethodSuccess({ loginMethod: LOGIN_ACCOUNT_TYPES[1] }));
    } else {
      yield put(getLoginMethodSuccess({ loginMethod: LOGIN_ACCOUNT_TYPES[2] ?? DEFAULT_LOGIN_ACCOUNT_TYPE }));
    }
    return true;
  } catch (e) {
    yield call(errorHandler, { error: e, isInitialRequest: true });
    return false;
  }
}

const makeServiceSettings = (service, isCrossOrder) => {
  return {
    orderSettings: {
      orderType: service.user_manual_trading_default.type,
      quantity: service.user_manual_trading_default.quantity,
      expirationType: service.user_manual_trading_default.expirationType,
    },
    skipNewOrderConfirmation: Boolean(Number(service.user_new_order_skip_confirmation)),
    skipCloseOrderConfirmation: Boolean(Number(service.user_close_order_skip_confirmation)),
    isMaintenance: service.system_status !== SYSTEM_NOT_MAINTENANCE_STATUS,
    displayedInstruments: service.user_rate_panel.map(({ sort, visible, instrumentId }) => ({
      id: sort,
      displayed: visible === DISPLAYED_CURRENCY_PAIR_STATUS,
      instrumentId,
    })),
    isCrossOrder,
    labForbiddenInstruments: service.lab_forbidden_instruments,
    chartMakeSettings: service.chart_make_settings,
  };
};

export function* getSettingsHandler(action) {
  const {
    payload: { isPublic, isRefetch },
  } = action;
  try {
    if (!isRefetch) {
      yield put(getSettingsStartLoading());
    }

    const { data } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getSettings, { isPublic });
    const {
      login_method: loginMethod,
      selection_maintenance_status: selectionMaintenanceStatus,
      selection_term_id_list: termListRaw,
      selection_default_term_id: termId,
      isFirstLogin,
    } = data;

    const settings = yield select((state) => state.settings);

    yield put(
      getSettingsSuccess({
        loginMethod: LOGIN_ACCOUNT_TYPES[loginMethod] ?? DEFAULT_LOGIN_ACCOUNT_TYPE,
        selectionMaintenanceStatus,
        [FX]: makeServiceSettings(data[FX], settings[FX].isCrossOrder),
        [ETF]: makeServiceSettings(data[ETF], settings[ETF].isCrossOrder),
        [CFD]: makeServiceSettings(data[CFD], settings[CFD].isCrossOrder),
        isFirstLogin,
      }),
    );

    yield put(
      createSelectionTermsOptions({
        termList: termListRaw.map((termItem) => {
          switch (termItem) {
            case THREE_MONTH:
              return { value: '1', label: termItem };
            case SIX_MONTH:
              return { value: '2', label: termItem };
            case ONE_YEAR:
              return { value: '3', label: termItem };
            case TWO_YEAR:
              return { value: '4', label: termItem };
            case THREE_YEAR:
              return { value: '5', label: termItem };
            default:
              return { value: '6', label: termItem };
          }
        }),
      }),
    );
    yield put(setDefaultSelectionTerm({ termId: termOptions.find((item) => item.label === termId).value }));
  } catch (e) {
    yield call(errorHandler, { error: e, isInitialRequest: true });
    throw e;
  } finally {
    if (!isRefetch) yield put(getSettingsStopLoading());
  }
}

function* getOrderSettingsHandler() {
  try {
    yield put(getOrderSettingsStartLoading());
    const serviceId = yield select((state) => state.auth.serviceId);

    const {
      data: {
        user_manual_trading_default: { type: orderType, quantity, expirationType },
      },
    } = yield call(getOrderSettings, { serviceId });

    yield put(getOrderSettingsSuccess({ orderType, quantity, expirationType, serviceId }));
  } catch (error) {
    yield call(errorHandler, { error, isInitialRequest: false });
  } finally {
    yield put(getOrderSettingsStopLoading());
  }
}

function* getUserMailSettingsHandler({ payload: { serviceId } }) {
  try {
    const accountInfo = yield* getAccountInfoGen();

    if (accountInfo[serviceId].notExist) {
      return;
    }

    yield put(getUserMailSettingsStartLoading());

    // TODO メール通知移管のことで、一旦不要となる削除
    const {
      data: {
        user_mail_setting: { primaryMailAddress, secondaryMailAddress, mailActions },
      },
    } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getUserMailSettings, { serviceId });

    yield put(
      getUserMailSettingsSuccess({
        primaryMailAddress,
        secondaryMailAddress,
        mailActions,
        serviceId,
      }),
    );
  } catch (e) {
    yield call(errorHandler, { error: e, isInitialRequest: true });
  } finally {
    yield put(getUserMailSettingsStopLoading());
  }
}

function* updateUserMailSettingsHandler(action) {
  try {
    const {
      payload: { primaryMailAddress, secondaryMailAddress, mailActions, callback, serviceId },
    } = action;
    const requestBody = mailActions;

    yield put(getUserMailSettingsStartLoading());

    yield put(updateUserMailSettingsSuccess({ primaryMailAddress, secondaryMailAddress, mailActions, serviceId }));

    yield call(updateUserMailSettings, { requestBody, serviceId });

    if (callback) callback();
  } catch (e) {
    const {
      payload: { primaryMailAddress, secondaryMailAddress, oldMailActions },
    } = action;

    yield put(updateUserMailSettingsSuccess({ primaryMailAddress, secondaryMailAddress, oldMailActions }));
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getUserMailSettingsStopLoading());
  }
}

function* updateOrderSettingsHandler(action) {
  try {
    yield put(updateOrderSettingsStartLoading());
    const {
      payload: { orderType, quantity, expirationType, callback },
    } = action;

    const serviceId = yield select((state) => state.auth.serviceId);
    const requestBody = {
      type: orderType,
      quantity,
      expirationType,
    };

    yield call(updateOrderSettings, { requestBody, serviceId });
    if (callback) callback();
    yield put(updateOrderSettingsSuccess({ orderType, quantity, expirationType, serviceId }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(updateOrderSettingsStopLoading());
  }
}

function* getUserSettingNewOrderSkipConfirmationRequestHandler() {
  try {
    const serviceId = yield select((state) => state.auth.serviceId);

    const { user_new_order_skip_confirmation: result } = yield call(getUserSettingNewOrderSkipConfirmation, {
      serviceId,
    });

    yield put(getUserSettingNewOrderSkipConfirmationSuccess({ value: Boolean(Number(result)), serviceId }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

function* toggleUserSettingNewOrderSkipConfirmationRequestHandler() {
  try {
    const serviceId = yield select((state) => state.auth.serviceId);
    const previousValue = yield select((state) => state.settings[serviceId].skipNewOrderConfirmation);

    yield put(getUserSettingNewOrderSkipConfirmationSuccess({ value: !previousValue, serviceId }));

    yield call(updateUserSettingNewOrderSkipConfirmation, { requestBody: String(Number(!previousValue)), serviceId });
  } catch (e) {
    yield call(errorHandler, { error: e });
    yield put(getUserSettingNewOrderSkipConfirmationRequest());
  }
}

function* getUserSettingCloseOrderSkipConfirmationRequestHandler() {
  try {
    const serviceId = yield select((state) => state.auth.serviceId);
    const { user_close_order_skip_confirmation: result } = yield call(getUserSettingCloseOrderSkipConfirmation, {
      serviceId,
    });

    yield put(getUserSettingCloseOrderSkipConfirmationSuccess({ value: Boolean(Number(result)), serviceId }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

function* toggleUserSettingCloseOrderSkipConfirmationRequestHandler() {
  try {
    const serviceId = yield select((state) => state.auth.serviceId);
    const previousValue = yield select((state) => state.settings[serviceId].skipCloseOrderConfirmation);
    yield put(getUserSettingCloseOrderSkipConfirmationSuccess({ value: !previousValue, serviceId }));

    yield call(updateUserSettingCloseOrderSkipConfirmation, { requestBody: String(Number(!previousValue)), serviceId });
  } catch (e) {
    yield call(errorHandler, { error: e });
    yield put(getUserSettingCloseOrderSkipConfirmationRequest());
  }
}

// CFD 要リファクタリング
function* acceptAgreementInfoHandler(action) {
  try {
    const { callback } = action.payload;

    const settings = yield select((state) => state.settings);
    const { accountInfo } = settings;

    const shouldSendFXRequest =
      !settings[FX].isMaintenance && accountInfo[FX] && !accountInfo[FX]?.disclaimerReadFlgMobile;
    const shouldSendETFRequest =
      !settings[ETF].isMaintenance && accountInfo[ETF] && !accountInfo[ETF]?.disclaimerReadFlgMobile;
    const shouldSendCFDRequest =
      !settings[CFD].isMaintenance && accountInfo[CFD] && !accountInfo[CFD]?.disclaimerReadFlgMobile;

    yield put(acceptAgreementInfoRequestStartLoading());

    const callAcceptAgreement = (serviceId) =>
      call(changeAccountInfo, { serviceId, requestBody: { disclaimerReadFlg: true } });

    const calls = [];
    if (shouldSendFXRequest) calls.push(callAcceptAgreement(FX));
    if (shouldSendETFRequest) calls.push(callAcceptAgreement(ETF));
    if (shouldSendCFDRequest) calls.push(callAcceptAgreement(CFD));
    yield all(calls);

    if (callback) callback();
    yield put(updateDisclaimerFlg());
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(acceptAgreementInfoRequestEndLoading());
  }
}

function* getDisplayingCurrencyPairRequestHandler() {
  try {
    const serviceId = yield select((state) => state.auth.serviceId);

    const {
      data: { user_rate_panel: ratePanel },
    } = yield call(getUserRatePanel, { serviceId });

    const {
      [FX]: { instrumentsOptions: FXInstrumentOptions },
      [ETF]: { instrumentsOptions: ETFInstrumentOptions },
      [CFD]: { instrumentsOptions: CFDInstrumentOptions },
    } = yield select((state) => state.constants);

    const selectedFXInstrumentId = getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DEFAULT_SELECTED_FX_INSTRUMENT_ID,
      defaultValue: FXInstrumentOptions[0].value,
    });

    const selectedETFInstrumentId = getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DEFAULT_SELECTED_ETF_INSTRUMENT_ID,
      defaultValue: ETFInstrumentOptions[0].value,
    });

    const selectedCFDInstrumentId = getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DEFAULT_SELECTED_CFD_INSTRUMENT_ID,
      defaultValue: CFDInstrumentOptions[0].value,
    });

    yield put(changeTradeSelectedInstrumentId({ id: selectedFXInstrumentId, serviceId: FX }));
    yield put(changeTradeSelectedInstrumentId({ id: selectedETFInstrumentId, serviceId: ETF }));
    yield put(changeTradeSelectedInstrumentId({ id: selectedCFDInstrumentId, serviceId: CFD }));

    yield put(
      getDisplayingCurrencyPairSuccess({
        displayedInstruments: ratePanel.map(({ sort, visible, instrumentId }) => ({
          id: sort,
          displayed: visible === DISPLAYED_CURRENCY_PAIR_STATUS,
          instrumentId,
        })),
        serviceId,
      }),
    );
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

function* updateDisplayingCurrencyPairHandler(action) {
  try {
    const {
      payload: { array, serviceId },
    } = action;

    const requestBody = array.map(({ displayed, instrumentId }, index) => ({
      visible: displayed ? '1' : '0',
      sort: String(index + 1),
      instrumentId,
    }));
    yield call(updateUserRatePanel, { requestBody, serviceId });
  } catch (e) {
    yield call(errorHandler, { error: e });
    yield put(getDisplayingCurrencyPairRequest());
  }
}

function* getLosscutStatusRequestHandler(action) {
  const { serviceId, callback } = action.payload;

  try {
    const {
      data: { losscutStatus },
    } = yield call(getAccountLosscutInfo, { serviceId });

    yield put(changeLosscutStatus({ status: losscutStatus, serviceId }));

    if (losscutStatus) {
      yield put(
        sendNotificationError({ title: LOSSCUT_MODAL_TITLE, message: LOSSCUT_MODAL_MESSAGE, buttonText: '閉じる' }),
      );
    } else {
      callback();
    }
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

function* getUserLearnTriautoSettingHandler() {
  try {
    const { data } = yield call(getUserSettingLearnTriautoConfirmationRequest);
    if (!data) return;
    yield put(
      getUserSettingLearnTriautoConfirmationRequestSuccess({
        value: data.user_learn_triauto_confirmation,
      }),
    );
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

function* updateUserLearnTriautoStatusHandler() {
  try {
    const learnTriautoStatus = yield select((state) => state.settings.learnTriautoStatus);
    yield call(updateLearnTriautoStatus, { requestBody: learnTriautoStatus });
    yield* getUserLearnTriautoSettingHandler();
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

export default function* settingsSagaHandler() {
  yield takeEvery(GET_INSTRUMENTS_LIST_REQUEST, instrumentListRequestHandler);
  yield takeEvery(GET_ACCOUNT_INFO_REQUEST, accountInfoRequestHandler);
  yield takeEvery(GET_SETTINGS_REQUEST, getSettingsHandler);
  yield takeEvery(GET_USER_MAIL_SETTINGS_REQUEST, getUserMailSettingsHandler);
  yield takeEvery(UPDATE_USER_MAIL_SETTINGS_REQUEST, updateUserMailSettingsHandler);
  yield takeEvery(GET_ORDER_SETTINGS_REQUEST, getOrderSettingsHandler);
  yield takeEvery(UPDATE_ORDER_SETTINGS_REQUEST, updateOrderSettingsHandler);
  yield takeEvery(
    GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST,
    getUserSettingNewOrderSkipConfirmationRequestHandler,
  );
  yield takeLatest(
    TOGGLE_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST,
    toggleUserSettingNewOrderSkipConfirmationRequestHandler,
  );
  yield takeEvery(
    GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST,
    getUserSettingCloseOrderSkipConfirmationRequestHandler,
  );
  yield takeLatest(
    TOGGLE_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST,
    toggleUserSettingCloseOrderSkipConfirmationRequestHandler,
  );
  yield takeEvery(ACCEPT_AGREEMENT_INFO_REQUEST, acceptAgreementInfoHandler);
  yield takeEvery(GET_DISPLAYING_CURRENCY_PAIR_REQUEST, getDisplayingCurrencyPairRequestHandler);
  yield takeLatest(UPDATE_DISPLAYING_CURRENCY_PAIR, updateDisplayingCurrencyPairHandler);
  yield takeLatest(LOSSCUT_STATUS_REQUEST, getLosscutStatusRequestHandler);
  yield takeEvery(GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST, getUserLearnTriautoSettingHandler);
  yield takeEvery(UPDATE_USER_LEARN_TRIAUTO_STATUS_REQUEST, updateUserLearnTriautoStatusHandler);
}
