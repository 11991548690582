import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { COLORS, SSO_QUICK_DEPOSIT_URL } from 'shared-modules/constants';
import { CASH_PAGES, DEPOSIT_TARGET_LIST, KEY_FOR_BANK_LIST } from 'shared-modules/constants/cash';
import { formatNumberZeroEn, getDefaultValuesFromSessionStorage } from 'shared-modules/services';

import { useCashPageTransition } from '../../../../../services/hooks';
import { push } from '../../../../../redux/actions';

import CustomButton from '../../../../../components/CustomButton';

import commonStyles from '../../../common/cashCommon.module.scss';
import styles from './quickDepositConfirm.module.scss';

const QuickDepositConfirm = () => {
  const dispatch = useDispatch();
  const pageTransition = useCashPageTransition();

  const { depositSourceId, depositTargetId, paymentAmount, bankList: _bankList } = useSelector((state) => state.cash);

  const bankList = useMemo(() => {
    const storageBankList = getDefaultValuesFromSessionStorage({
      key: KEY_FOR_BANK_LIST,
      defaultValue: _bankList,
    });
    return typeof storageBankList === 'string' ? JSON.parse(storageBankList) : storageBankList;
  }, [_bankList]);

  const depositSourceName = useMemo(
    () => bankList.filter((d) => d.bankCode === depositSourceId)[0]?.bankName,
    [depositSourceId, bankList],
  );

  const depositTargetService = useMemo(
    () => DEPOSIT_TARGET_LIST.filter((d) => d.id === depositTargetId)[0]?.alt,
    [depositTargetId],
  );

  const depositExec = useCallback(() => {
    dispatch(push(`/${SSO_QUICK_DEPOSIT_URL}`));
  }, [dispatch]);

  return (
    <>
      <span className={commonStyles.title}>入金内容のご確認</span>

      <table className={styles.table}>
        <tbody>
          <tr>
            <td className={styles.colTitle}>金融機関</td>
            <td className={styles.colData}>{depositSourceName}</td>
          </tr>
          <tr>
            <td className={styles.colTitle}>振込入金先</td>
            <td className={styles.colData}>{depositTargetService}</td>
          </tr>
          <tr>
            <td className={styles.colTitle}>入金額</td>
            <td className={styles.colData}>{formatNumberZeroEn({ value: paymentAmount, withoutPlus: true })}</td>
          </tr>
        </tbody>
      </table>

      <div className={styles.buttonRow}>
        <CustomButton
          color={COLORS.GREY}
          className={styles.button}
          onClick={() => pageTransition(CASH_PAGES.CASH_QUICK_DEPOSIT_SELECT.ID)}
          width={270}
        >
          戻る
        </CustomButton>
        <CustomButton color={COLORS.RED} className={styles.button} onClick={depositExec} width={270}>
          実行する
        </CustomButton>
      </div>
    </>
  );
};

export default memo(QuickDepositConfirm);
