import { memo } from 'react';
import { useSelectedShareItem } from 'shared-modules/hooks';
import { SelectionChart } from './SelectionChart';
import { LabChart } from './LabChart';

export const SimulationChart = memo(() => {
  const selectedShareItem = useSelectedShareItem();
  const Chart = selectedShareItem ? LabChart : SelectionChart;
  return <Chart />;
});
