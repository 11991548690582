import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { COLORS, MODAL_SIZES } from 'shared-modules/constants';
import { QUICK_DEPOSIT_INFO_LINKS } from 'shared-modules/constants/core';
import {
  CASH_PAGES,
  DEPOSIT_TARGET_LIST,
  KEY_FOR_DEPOSIT_SOURCE_ID,
  KEY_FOR_PAYMENT_AMOUNT,
  KEY_FOR_BANK_LIST,
} from 'shared-modules/constants/cash';
import { useAccountInfo } from 'shared-modules/hooks';
import { postQuickDepositTermsAgreeRequest, setData, setQuickDepositTermsReadTime } from 'shared-modules/redux/cash';
import { changeServiceIdRequest } from 'shared-modules/redux/actions/authActions';
import { losscutStatusRequest } from 'shared-modules/redux/actions/settingsActions';
import {
  formatDateTimeWithoutDivider,
  saveDefaultValuesFromSessionStorage,
  getDefaultValuesFromSessionStorage,
} from 'shared-modules/services';
import { MY_PAGE_BASE_URL } from 'shared-modules/config';
import { useKeyPressHandler, useCashPageTransition } from '../../../../../services/hooks';
import { push } from '../../../../../redux/actions';
import { Spin } from '../../../../../components';
import CustomButton from '../../../../../components/CustomButton';
import CustomInput from '../../../../../components/CustomInput';
import Modal from '../../../../../components/Modal';
import bankImagesHelper from '../../../../../assets/bankImages/bankImagesHelper';
import bankInfo from './bankInfo.json';
import styles from './quickDepositSelect.module.scss';
import commonStyles from '../../../common/cashCommon.module.scss';
import { CASH } from '../../../../../constants';

const QuickDepositSelect = () => {
  const dispatch = useDispatch();
  const pageTransition = useCashPageTransition();
  const serviceId = useSelector((state) => state.auth.serviceId);
  const accountInfo = useAccountInfo();
  const { linkUrl } = useSelector((state) => state.cash.disclaimer);
  const {
    depositSourceId: _depositSourceId,
    depositTargetId: _depositTargetId,
    paymentAmount: _paymentAmount,
    bankList: _bankList,
    isLoading,
  } = useSelector((state) => state.cash);

  const defaultDepositSourceId = getDefaultValuesFromSessionStorage({
    key: KEY_FOR_DEPOSIT_SOURCE_ID,
    defaultValue: _depositSourceId,
  });

  const defaultPaymentAmount = getDefaultValuesFromSessionStorage({
    key: KEY_FOR_PAYMENT_AMOUNT,
    defaultValue: _paymentAmount,
  });

  const bankList = useMemo(() => {
    const storageBankList = getDefaultValuesFromSessionStorage({
      key: KEY_FOR_BANK_LIST,
      defaultValue: _bankList,
    });
    return typeof storageBankList === 'string' ? JSON.parse(storageBankList) : storageBankList;
  }, [_bankList]);

  const [depositSourceId, setDepositSourceId] = useState(defaultDepositSourceId || bankList[0]?.bankCode);
  const [depositTargetId, setDepositTargetId] = useState(
    _depositTargetId || DEPOSIT_TARGET_LIST.filter((f) => f.serviceId === serviceId)[0]?.id,
  );

  const [paymentAmount, setPaymentAmount] = useState(defaultPaymentAmount);

  const [isValidationError, setIsValidationError] = useState(false);

  useEffect(() => {
    if (!depositSourceId) {
      setDepositSourceId(bankList[0]?.bankCode);
    }
  }, [depositSourceId, bankList]);

  useEffect(() => {
    setDepositTargetId(DEPOSIT_TARGET_LIST.filter((f) => f.serviceId === serviceId)[0]?.id);
  }, [serviceId]);

  const ontChangePaymentAmountHandler = useCallback((amount) => {
    if (amount.slice(0, 1) === '0') {
      setPaymentAmount('');
    } else if (amount.length < 9) {
      setPaymentAmount(amount);
    }
    setIsValidationError(/\D/.test(amount) || amount <= 0 || amount.slice(0, 1) === '0');
  }, []);

  const errorMessages = useMemo(
    () =>
      isValidationError
        ? [{ inputName: 'amount', errorMessage: '入金額は１円以上１億円未満、１円単位、半角数字で入力してください' }]
        : [],
    [isValidationError],
  );

  const isQuickDepositTermsAgreed = useSelector((state) => state.cash.isQuickDepositTermsAgreed);

  const [isTermsModal, setIsTermsModal] = useState(!isQuickDepositTermsAgreed);
  const [isTermsRead, setIsTermsRead] = useState(false);

  useEffect(() => {
    setIsTermsModal(!isQuickDepositTermsAgreed);
  }, [isQuickDepositTermsAgreed]);

  const openDepositRule = useCallback(() => {
    window.open(MY_PAGE_BASE_URL + linkUrl, 'Invast-MyPage-Rule-PDF');
    dispatch(setQuickDepositTermsReadTime({ readTs: formatDateTimeWithoutDivider(new Date()) }));
    setIsTermsRead(true);
  }, [dispatch, linkUrl]);

  const openDepositInfo = useCallback(() => {
    const qdLinkUrl = QUICK_DEPOSIT_INFO_LINKS[serviceId];
    if (qdLinkUrl) {
      window.open(qdLinkUrl, 'Invast-MyPage-Deposit-Info');
    }
  }, [serviceId]);

  const failureCallback = useCallback(() => {
    pageTransition(CASH_PAGES.CASH_MENU.ID);
  }, [pageTransition]);

  const onCloseModal = useCallback(() => {
    dispatch(postQuickDepositTermsAgreeRequest({ serviceId, failureCallback }));
    setIsTermsModal(false);
    setIsTermsRead(false);
  }, [dispatch, setIsTermsModal, setIsTermsRead, serviceId, failureCallback]);

  const handleCashMenu = useCallback(() => dispatch(push(`/${CASH}`)), [dispatch]);

  const onCLickHandlerSuccessCallback = useCallback(() => {
    saveDefaultValuesFromSessionStorage({ key: KEY_FOR_DEPOSIT_SOURCE_ID, value: depositSourceId });
    saveDefaultValuesFromSessionStorage({ key: KEY_FOR_PAYMENT_AMOUNT, value: paymentAmount });
    saveDefaultValuesFromSessionStorage({ key: KEY_FOR_BANK_LIST, value: JSON.stringify(bankList) });
    dispatch(setData({ depositSourceId, depositTargetId, paymentAmount }));
    pageTransition(CASH_PAGES.CASH_QUICK_DEPOSIT_CONFIRM.ID);
  }, [dispatch, depositSourceId, depositTargetId, paymentAmount, bankList, pageTransition]);

  const onCLickHandler = useCallback(() => {
    const selectServiceId = DEPOSIT_TARGET_LIST.filter((f) => f.id === depositTargetId)[0]?.serviceId;
    dispatch(losscutStatusRequest({ serviceId: selectServiceId, callback: onCLickHandlerSuccessCallback }));
  }, [dispatch, depositTargetId, onCLickHandlerSuccessCallback]);

  const handleKeyPressOpenRulePDF = useKeyPressHandler(openDepositRule);
  const handleKeyPressOpenDepositInfo = useKeyPressHandler(openDepositInfo);

  return (
    <>
      <span className={commonStyles.title}>振込元</span>

      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Spin className={styles.loader} />
        </div>
      ) : (
        <div className={styles.mainRow}>
          {bankList.map((d) => {
            return (
              <div className={styles.imageWrapper} key={`source-${d.bankCode}`}>
                {d.bankCode === depositSourceId && (
                  <>
                    <span className={styles.select}>選択中</span>
                    <div className={styles.selectWrapper} />
                  </>
                )}
                <img
                  src={bankImagesHelper[bankInfo.find((bank) => bank.bankCode === d.bankCode)?.image]}
                  className={styles.image}
                  alt={d.bankName}
                  onClick={() => setDepositSourceId(d.bankCode)}
                  aria-hidden
                  key={d.bankCode}
                />
              </div>
            );
          })}
        </div>
      )}

      <i className={classNames('material-icons-outlined', styles.icon)}>keyboard_arrow_down</i>

      <span className={commonStyles.title}>振込先</span>

      <div className={styles.mainRow}>
        {DEPOSIT_TARGET_LIST.map((d) => {
          if (accountInfo[d.serviceId]?.notExist === true) {
            return null;
          }

          return (
            <div className={styles.imageWrapper} key={`target-${d.id}`}>
              {d.id === depositTargetId && (
                <>
                  <span className={styles.select}>選択中</span>
                  <div className={styles.selectWrapper} />
                </>
              )}
              <img
                src={bankImagesHelper[bankInfo.find((bank) => bank.code === d.id)?.image]}
                className={styles.image}
                alt={d.alt}
                onClick={() => {
                  dispatch(
                    changeServiceIdRequest({
                      serviceId: DEPOSIT_TARGET_LIST.filter((f) => f.id === d.id)[0]?.serviceId,
                    }),
                  );
                }}
                aria-hidden
                key={d.id}
              />
            </div>
          );
        })}
      </div>

      <div className={styles.paymentRow}>
        <span className={commonStyles.title}>入金額</span>
        <CustomInput
          label=""
          value={paymentAmount}
          onChange={ontChangePaymentAmountHandler}
          className={styles.input}
          inputClassName={styles.numberInput}
          width={200}
          placeholder="金額を入力してください"
          withErrorTooltip
          name="amount"
          errorMessages={errorMessages}
        />
        <span className={styles.yen}>円</span>
      </div>

      <div className={styles.mainRow}>
        <CustomButton
          color={COLORS.RED}
          className={styles.button}
          onClick={() => onCLickHandler()}
          width={270}
          isDisabled={!paymentAmount || isValidationError || !depositSourceId || !depositTargetId}
        >
          確認する
        </CustomButton>
      </div>

      {isTermsModal && (
        <Modal
          isOpen={isTermsModal}
          closeModal={onCloseModal}
          title="即時入金利用規約の確認"
          size={MODAL_SIZES.LARGE}
          isOverlap
          withoutCloseButton
          isOutsideClickDontClose
        >
          <div className={styles.textRow}>
            <div className={styles.text}>インヴァスト証券「即時入金利用規約」のご承諾をお願いします。</div>
            <div className={styles.text}>
              即時入金は、提携金融機関から24時間即時振込が可能で、夜間や祝日でもご利用いただけるサービスです。
            </div>
            <div className={styles.flex}>
              <div>即時入金の詳細は</div>
              <div
                role="button"
                onClick={openDepositInfo}
                className={styles.textLink}
                onKeyPress={handleKeyPressOpenDepositInfo}
                tabIndex={0}
              >
                こちら
              </div>
            </div>
            <div className={styles.text}>
              なお、ご承諾いただけない場合、本サービスをご利用いただくことはできかねますので、ご注意ください。
            </div>
          </div>

          <div className={styles.row}>
            <div
              role="button"
              onClick={openDepositRule}
              className={styles.link}
              onKeyPress={handleKeyPressOpenRulePDF}
              tabIndex={0}
            >
              即時入金利用規約
            </div>
          </div>

          <div className={styles.row}>
            <CustomButton
              className={styles.button}
              onClick={() => {
                setIsTermsModal(false);
                handleCashMenu();
              }}
              width={270}
            >
              戻る
            </CustomButton>

            <CustomButton
              color={COLORS.RED}
              className={styles.button}
              onClick={() => onCloseModal()}
              width={270}
              isDisabled={!isTermsRead}
            >
              同意する
            </CustomButton>
          </div>
        </Modal>
      )}
    </>
  );
};

export default memo(QuickDepositSelect);
