import {
  get,
  post,
  patch,
  deleteMethod,
  PAGE_SIZE,
  PAGE_SIZE_WITHOUT_PAGINATION,
  invokeApi,
  createAdditionalParams,
} from './index';
import { createDateStringWithoutTime } from '../services';
import {
  DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
  DEFAULT_ORDERS_TABLE_SORT_DIR,
  DEFAULT_ORDERS_TABLE_SORT_BY,
  DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
} from '../constants/manualTrade';
import { ORDER_STATUSES_MAIN } from '../constants';

export const getRates = async ({ isPublic, serviceId }) => {
  let additionalParams;
  if (!isPublic) {
    additionalParams = await createAdditionalParams();
  }

  return invokeApi({}, `${isPublic ? 'public/' : ''}${serviceId}/rates`, get, additionalParams);
};

export const createSingleOrder = async ({ requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders/single`, post, additionalParams, requestBody);
};

export const createOcoOrder = async ({ requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders/oco`, post, additionalParams, requestBody);
};

export const createIFDOrder = async ({ requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders/ifd`, post, additionalParams, requestBody);
};

export const createIFOOrder = async ({ requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders/ifo`, post, additionalParams, requestBody);
};

export const createFIFOOrder = async ({ requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders/fifo`, post, additionalParams, requestBody);
};

export const getExecutions = async ({
  pageNumber,
  apGroupId,
  fromDate,
  toDate,
  sortBy = DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  sortDir = DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
  instrumentId = null,
  tradeMethods = [],
  withoutPagination = false,
  serviceId,
  isClose,
  side,
}) => {
  const additionalParams = await createAdditionalParams();

  let requestQuery = `${
    withoutPagination ? PAGE_SIZE_WITHOUT_PAGINATION : PAGE_SIZE
  }&pageNumber=${pageNumber}&sortBy=${sortBy}&sortDir=${sortDir}`;

  if (apGroupId) {
    requestQuery += `&apGroupId=${apGroupId}`;
  }
  if (fromDate) {
    requestQuery += `&fromDate=${createDateStringWithoutTime(fromDate)}`;
  }
  if (toDate) {
    requestQuery += `&toDate=${createDateStringWithoutTime(toDate)}`;
  }
  if (instrumentId) {
    requestQuery += `&instrumentId=${instrumentId}`;
  }
  if (tradeMethods.length > 0) {
    requestQuery += `&tradeMethod=${tradeMethods.join(',')}`;
  }
  if (isClose != null) {
    requestQuery += `&isClose=${isClose}`;
  }
  if (side) {
    requestQuery += `&buySellType=${side}`;
  }

  return invokeApi({}, `${serviceId}/executions?${requestQuery}`, get, additionalParams);
};

export const getPositions = async ({
  // can't be changed to constant, server don't provide needed sorting
  // use client side sorting for positions
  sortBy = 'positionId',
  sortDir = 'desc',
  instrumentId,
  side,
  pageNumber = 1,
  apGroupId,
  serviceId,
}) => {
  const additionalParams = await createAdditionalParams();

  let requestQuery = `${PAGE_SIZE_WITHOUT_PAGINATION}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortDir=${sortDir}`;
  if (apGroupId) {
    requestQuery += `&apGroupId=${apGroupId}`;
  }
  if (instrumentId) {
    requestQuery += `&instrumentId=${instrumentId}`;
  }
  if (side) {
    requestQuery += `&side=${side}`;
  }

  return invokeApi({}, `${serviceId}/positions?${requestQuery}`, get, additionalParams);
};

export const closeMultiplePositions = async ({ serviceId, requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/positions`, post, additionalParams, requestBody);
};

export const deletePositionsOrder = async ({ positionId, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/positions/${positionId}`, deleteMethod, additionalParams);
};

export const getOrders = async ({
  pageNumber,
  sortBy = DEFAULT_ORDERS_TABLE_SORT_BY,
  sortDir = DEFAULT_ORDERS_TABLE_SORT_DIR,
  isActiveOrderSelected = DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
  instrumentId = null,
  tradeMethods = [],
  serviceId,
}) => {
  const additionalParams = await createAdditionalParams();

  let requestQuery = `?${PAGE_SIZE}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortDir=${sortDir}`;
  if (isActiveOrderSelected) {
    requestQuery += `&status=${ORDER_STATUSES_MAIN.ACTIVE.ID}`;
  }
  if (instrumentId) {
    requestQuery += `&instrumentId=${instrumentId}`;
  }
  if (tradeMethods.length > 0) {
    requestQuery += `&tradeMethod=${tradeMethods.join(',')}`;
  }

  return invokeApi({}, `${serviceId}/orders${requestQuery}`, get, additionalParams);
};

export const getNews = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/news?${PAGE_SIZE_WITHOUT_PAGINATION}&pageNumber=1`, get, additionalParams);
};

export const getOrderInfo = async ({ orderId, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders/${orderId}`, get, additionalParams);
};

export const changeOrder = async ({ routePath, requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders/${routePath}`, patch, additionalParams, requestBody);
};

export const getPositionsOrderById = async ({ positionId, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/positions/${positionId}`, get, additionalParams);
};

export const deleteOrder = async ({ orderId, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders/${orderId}`, deleteMethod, additionalParams);
};

export const createCloseOrder = async ({ routePath, requestBody, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders/${routePath}`, post, additionalParams, requestBody);
};

export const swapTransfer = async ({ serviceId, requestBody }) => {
  const additionalParams = await createAdditionalParams();
  return invokeApi({}, `${serviceId}/swap_amounts`, post, additionalParams, requestBody);
};
