import { all, call } from 'redux-saga/effects';
import advertisementSaga from 'shared-modules/redux/sagas/advertisementSaga';
import authSagaHandler from 'shared-modules/redux/sagas/authSaga';
import autoSelectSaga from 'shared-modules/redux/sagas/autoSelectSaga';
import cartSaga from 'shared-modules/redux/sagas/cartSaga';
import cashSaga from 'shared-modules/redux/sagas/cashSaga';
import currenciesSaga from 'shared-modules/redux/sagas/currenciesSaga';
import errorsSaga from 'shared-modules/redux/sagas/errorSaga';
import manualTradeSagaHandler from 'shared-modules/redux/sagas/manualTradeSaga';
import portfolioSagaHandler from 'shared-modules/redux/sagas/portfolioSaga';
import settingsSagaHandler from 'shared-modules/redux/sagas/settingsSaga';
import socketSagaHandler from 'shared-modules/redux/sagas/socketSaga';
import reportsSaga from 'shared-modules/redux/sagas/reportsSaga';
import userSSOSaga from 'shared-modules/redux/sagas/userSSOSaga';
import builderSaga from 'shared-modules/redux/sagas/builderSaga';
import orderSaga from 'shared-modules/redux/sagas/orderSaga';
import multiEditSaga from 'shared-modules/redux/sagas/multiEditSaga';
import labSaga from 'shared-modules/redux/sagas/labSaga';
import techSaga from 'shared-modules/redux/sagas/techSaga';
import guideSaga from 'shared-modules/redux/sagas/guideSaga';
import messageSaga from 'shared-modules/redux/sagas/messageSaga';
import sequentialModalsSaga from 'shared-modules/redux/sagas/sequentialModalsSaga';
import notificationsSaga from './notificationsSaga';
import tutorialSaga from './tutorialSaga';
import commonSaga from './commonSaga';
import { authWebSagaHandler } from './authWebSaga';

export default function* rootSaga() {
  yield all([
    call(advertisementSaga),
    call(authSagaHandler),
    call(authWebSagaHandler),
    call(autoSelectSaga),
    call(builderSaga),
    call(cartSaga),
    call(cashSaga),
    call(currenciesSaga),
    call(errorsSaga),
    call(manualTradeSagaHandler),
    call(portfolioSagaHandler),
    call(reportsSaga),
    call(settingsSagaHandler),
    call(socketSagaHandler),
    call(techSaga),
    call(notificationsSaga),
    call(userSSOSaga),
    call(commonSaga),
    call(orderSaga),
    call(multiEditSaga),
    call(labSaga),
    call(guideSaga),
    call(messageSaga),
    call(tutorialSaga),
    call(sequentialModalsSaga),
  ]);
}
