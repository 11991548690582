import moment from 'moment';

import { all, put, call, takeEvery, select, debounce, takeLatest } from 'redux-saga/effects';
import { errorHandler } from './errorSaga';
import {
  getPublishedLabs,
  getTags,
  postLab,
  getApGroupsSimulationData,
  getUserLabs,
  getPublishedLabDetails,
  getLabDetails,
  cancelLab,
  validateApGroups,
  validateLabName,
} from '../../api/labApi';
import {
  getPublishedLabsRequest,
  getPublishedLabsSuccess,
  getTagsRequest,
  getTagsSuccess,
  getTagsRequestEndLoading,
  getPublishedLabsRequestEndLoading,
  postLabRequest,
  postLabRequestEndLoading,
  formSelector,
  getApGroupsSimulationRequest,
  getApGroupsSimulationSuccess,
  getApGroupsSimulationRequestEndLoading,
  getLabsRequest,
  getLabsSuccess,
  getLabsRequestEndLoading,
  getAllLabsRequest,
  getAllLabsSuccess,
  getLabDetailsRequest,
  getLabDetailsSuccess,
  getLabDetailsRequestEndLoading,
  getPublishedLabDetailsRequest,
  getPublishedLabDetailsSuccess,
  getPublishedLabDetailsRequestEndLoading,
  publishedSelectedLabIdSelector,
  publishedLabServiceIdSelector,
  selectedLabIdSelector,
  cancelRequest,
  cancelRequestEndLoading,
  cancelLabRequest,
  validateApGroupsRequest,
  validateApGroupsSuccess,
  validateApGroupsRequestEndLoading,
  validateLabNameRequest,
  validateLabNameSuccess,
  validateLabNameRequestEndLoading,
  getPostForbiddenApGroups,
  getPostForbiddenApGroupsEndLoading,
  getPostForbiddenApGroupsSuccess,
  getMyLabDetailsRequest,
  getMyLabDetailsRequestEndLoading,
  getMyLabDetailsRequestStartLoading,
  getMyLabDetailsSuccess,
  getMyLabDetailsFailure,
  getSilentApGroupsMultiSimulationRequest,
  getSilentApGroupsMultiSimulationSuccess,
} from '../labs';
import { BUY_SELL_MAIN, TQQQ_ARC_USD } from '../../constants';
import { ALL_SERVICES } from '../../constants/core';
import { DEFAULT_ERROR_CODE } from '../../constants/apiConstant';
import { getApGroupsById } from '../../api/portfolioApi';
import { getAccountInfo } from './common';
import {
  calcComprehensiveEvaluationByAttibute,
  getServiceName,
  includeComprehensiveEvaluation,
  isArray,
} from '../../utils';
import { DEBUG } from '../../config';

export function* getUserLabsHandler() {
  const serviceId = yield select((state) => state.auth.serviceId);

  try {
    const { data } = yield call(getUserLabs, serviceId);
    data.forEach((d) => {
      if (d.simulationChartList.length)
        d.simulationChartList.unshift({
          targetDate: moment(d.simulationChartList[0].targetDate).subtract(1, 'month').format('YYYY-MM-DD'),
          realizedPl: 0,
          unrealizedPl: 0,
        });
    });

    yield put(getLabsSuccess({ data, serviceId }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getLabsRequestEndLoading());
  }
}

function* getAllUserLabsHandler() {
  const allData = {};
  try {
    const accountInfo = yield* getAccountInfo();
    // TODO airbnb rule 配下で何か他に良い方法があれば(Promise.all は順序保証はあるがパフォーマンス以上に可読性に劣る)
    // eslint-disable-next-line no-restricted-syntax
    for (const serviceId of ALL_SERVICES) {
      if (!accountInfo[serviceId].isNotAvailable) {
        const { data } = yield call(getUserLabs, serviceId);
        data.forEach((d) => {
          if (d.simulationChartList.length)
            d.simulationChartList.unshift({
              targetDate: moment(d.simulationChartList[0].targetDate).subtract(1, 'month').format('YYYY-MM-DD'),
              realizedPl: 0,
              unrealizedPl: 0,
            });
        });
        // 総合評価
        allData[serviceId] = data.map(includeComprehensiveEvaluation);
      }
    }
    yield put(getAllLabsSuccess({ data: allData }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getLabsRequestEndLoading());
  }
}

const transformLabData = (data) =>
  data.map((row) => {
    const serviceIds = [row.serviceId];
    return { ...row, serviceIds };
  });

function* getPublishedLabsHandler(action) {
  try {
    const { tagId } = action.payload;
    if (tagId == null) {
      return;
    }
    const tagIds = (isArray(tagId) ? tagId : [tagId]).filter((id) => id != null && id !== '');
    const res = yield all(tagIds.map((id) => call(getPublishedLabs, id)));
    const data = res
      .reduce((acc, curr) => acc.concat(transformLabData(curr.data)), [])
      .map(includeComprehensiveEvaluation); // 総合評価
    yield put(getPublishedLabsSuccess(data));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getPublishedLabsRequestEndLoading());
  }
}

function* getLabTagsRequestHandler() {
  try {
    const { data: tags } = yield call(getTags);
    const result = tags
      .sort((a, b) => {
        if (a.sortNo > b.sortNo) return 1;
        if (a.sortNo < b.sortNo) return -1;
        return 0;
      })
      .reduce((transformedData, item) => {
        if (item.parentTagId === null) transformedData.push({ ...item, children: [] });
        else {
          const parentIndex = transformedData.findIndex(({ tagId }) => tagId === Number(item.parentTagId));
          if (parentIndex !== -1) transformedData[parentIndex].children.push(item);
        }
        return transformedData;
      }, []);
    yield put(getTagsSuccess(result));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getTagsRequestEndLoading());
  }
}

function* postLabRequestHandler(action) {
  try {
    const { callback } = action.payload;

    const form = yield select(formSelector);

    yield call(postLab, form);
    if (callback) callback();
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(postLabRequestEndLoading());
  }
}

function* getApGroupsSimulationRequestHandler(action) {
  try {
    const { apGroupIds, serviceId } = yield select(formSelector);
    const { termId } = action.payload;

    if (apGroupIds.length === 0) return;

    const { data } = yield call(getApGroupsSimulationData, serviceId, apGroupIds, termId);

    yield put(getApGroupsSimulationSuccess(data));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getApGroupsSimulationRequestEndLoading());
  }
}

function* getLabDetailsRequestHandler(action) {
  try {
    let { labId } = action.payload;
    const { termId } = action.payload;
    if (!labId) labId = yield select(selectedLabIdSelector);
    const { data } = yield call(getLabDetails, labId, termId);
    // 総合評価
    if (data.attribute) {
      data.attribute.comprehensiveEvaluation = calcComprehensiveEvaluationByAttibute(data.attribute);
    }
    yield put(getLabDetailsSuccess(data));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getLabDetailsRequestEndLoading());
  }
}

function* getMyLabDetailsRequestHandler(action) {
  try {
    yield put(getMyLabDetailsRequestStartLoading());
    const { labIds } = action.payload;
    const { termId } = action.payload;
    const responses = yield all((labIds ?? []).map((labId) => call(getLabDetails, labId, termId)));
    const instrumentList = yield select((state) => state.settings.instrumentList);
    const myLabDetails = responses.reduce((acc, curr) => {
      const { data } = curr;
      // 総合評価
      if (data.attribute) {
        data.attribute.comprehensiveEvaluation = calcComprehensiveEvaluationByAttibute(data.attribute);
      }
      const found = (data.strategyList ?? []).find(
        ({ instrumentId }) => instrumentList?.[instrumentId]?.serviceId != null,
      );
      if (found == null) {
        return acc;
      }
      const { serviceId } = instrumentList[found.instrumentId];
      const list = acc[serviceId] ?? [];
      list.push(data);
      return {
        ...acc,
        [serviceId]: list,
      };
    }, {});
    yield put(getMyLabDetailsSuccess(myLabDetails));
  } catch (e) {
    yield put(getMyLabDetailsFailure({}));
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getMyLabDetailsRequestEndLoading());
  }
}

function* cancelRequestHandler(action) {
  try {
    const { callback } = action.payload;
    const labId = yield select(selectedLabIdSelector);
    yield call(cancelLab, labId);
    if (callback) {
      callback();
    }
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(cancelRequestEndLoading());
  }
}

function* getPublishedLabDetailsRequestHandler(action) {
  try {
    let { labId } = action.payload;
    const { termId, tradesFlg = true } = action.payload;
    if (!labId) labId = yield select(publishedSelectedLabIdSelector);
    const { data } = yield call(getPublishedLabDetails, labId, termId, tradesFlg);

    const serviceId = yield select(publishedLabServiceIdSelector);
    const accountInfo = yield* getAccountInfo();
    if (accountInfo[serviceId].isNotAvailable) {
      data.simulationStats.marginRequired = 0;
    }
    ALL_SERVICES.forEach((service) => {
      const serviceName = getServiceName(serviceId);
      data.simulationStats[`marginRequired${serviceName}`] =
        service === serviceId ? data.simulationStats.marginRequired : 0;
    });

    // 総合評価
    if (data.attribute) {
      data.attribute.comprehensiveEvaluation = calcComprehensiveEvaluationByAttibute(data.attribute);
    }

    yield put(getPublishedLabDetailsSuccess(data));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getPublishedLabDetailsRequestEndLoading());
  }
}

function* validateApGroupsHandler(action) {
  try {
    const { apGroupIds, callback } = action.payload;

    const serviceId = yield select((state) => state.labs.postLab.form.serviceId);

    yield call(validateApGroups, serviceId, apGroupIds);
    yield put(validateApGroupsSuccess(null));

    if (callback) callback();
  } catch (e) {
    if (e?.response?.status === DEFAULT_ERROR_CODE) {
      const {
        data: { message },
      } = e.response;
      yield put(validateApGroupsSuccess(message));
    } else {
      yield call(errorHandler, { error: e });
    }
  } finally {
    yield put(validateApGroupsRequestEndLoading());
  }
}

function* validateLabNameHandler(action) {
  try {
    const { name, callback } = action.payload;

    const serviceId = yield select((state) => state.labs.postLab.form.serviceId);

    yield call(validateLabName, serviceId, name);
    yield put(validateLabNameSuccess(null));

    if (callback) callback();
  } catch (e) {
    if (e?.response?.status === DEFAULT_ERROR_CODE) {
      const {
        data: { message },
      } = e.response;
      yield put(validateLabNameSuccess(message));
    } else {
      yield call(errorHandler, { error: e });
    }
  } finally {
    yield put(validateLabNameRequestEndLoading());
  }
}

const getTQQQPortfolioOrders = (effects) => {
  return all(
    effects.map((effect) =>
      call(function* getApGroupOrders() {
        const { data } = yield effect;
        return data[0];
      }),
    ),
  );
};
function* getPostForbiddenApGroupsRequestHandler() {
  try {
    const serviceId = yield select((state) => state.labs.postLab.form.serviceId);
    const apGroups = yield select((state) => state.portfolio.apGroupsData[serviceId]);
    const TQQQApGroups = apGroups.filter((g) => g.instrumentId === TQQQ_ARC_USD);
    if (TQQQApGroups.length <= 0) return;
    const resultApGroups = yield getTQQQPortfolioOrders(
      TQQQApGroups.map((ap) => call(getApGroupsById, { id: ap.id, serviceId })),
    );
    const TQQQinBuyApGroupIds = resultApGroups
      .filter((ap) => ap.apList?.some((a) => Number(a.side) === BUY_SELL_MAIN.BUY.ID))
      .map((r) => r.id);
    yield put(getPostForbiddenApGroupsSuccess({ postForbiddenApGroupIds: TQQQinBuyApGroupIds }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getPostForbiddenApGroupsEndLoading());
  }
}

function* getSilentApGroupsSimulationData(serviceId, apGroupIds, termId) {
  try {
    const { data } = yield call(getApGroupsSimulationData, serviceId, apGroupIds, termId);
    return { [termId]: data };
  } catch (e) {
    // no error handler
    if (DEBUG) {
      console.error(e); // eslint-disable-line
    }
    return { [termId]: null };
  }
}

function* getSilentApGroupsMultiSimulationRequestHandler(action) {
  const { apGroupIds, serviceId } = yield select(formSelector);
  const { termIdList } = action.payload;

  if (apGroupIds.length === 0) return;

  yield put(getSilentApGroupsMultiSimulationSuccess({}));
  const resultList = yield all(
    termIdList.map((termId) => call(getSilentApGroupsSimulationData, serviceId, apGroupIds, termId)),
  );
  const multiSimulationResults = resultList.reduce((pre, cur) => ({ ...pre, ...cur }), {});
  yield put(getSilentApGroupsMultiSimulationSuccess(multiSimulationResults));
}

export default function* labSaga() {
  yield takeEvery(getTagsRequest.type, getLabTagsRequestHandler);
  yield takeLatest(getPublishedLabsRequest.type, getPublishedLabsHandler);
  yield takeEvery(postLabRequest.type, postLabRequestHandler);
  yield takeEvery(getApGroupsSimulationRequest.type, getApGroupsSimulationRequestHandler);
  yield takeEvery(getSilentApGroupsMultiSimulationRequest.type, getSilentApGroupsMultiSimulationRequestHandler);
  yield takeEvery(getLabsRequest.type, getUserLabsHandler);
  yield takeEvery(getAllLabsRequest.type, getAllUserLabsHandler);
  yield takeEvery(getLabDetailsRequest.type, getLabDetailsRequestHandler);
  yield takeEvery(cancelRequest.type, cancelRequestHandler);
  yield takeEvery(cancelLabRequest.type, cancelRequestHandler);
  yield debounce(100, getPublishedLabDetailsRequest.type, getPublishedLabDetailsRequestHandler);
  yield takeEvery(validateApGroupsRequest.type, validateApGroupsHandler);
  yield takeEvery(validateLabNameRequest.type, validateLabNameHandler);
  yield takeLatest(getPostForbiddenApGroups.type, getPostForbiddenApGroupsRequestHandler);
  yield takeEvery(getMyLabDetailsRequest.type, getMyLabDetailsRequestHandler);
}
