/* eslint-disable import/no-unresolved */
import { useCallback, useMemo } from 'react';
/* eslint-disable import/no-unresolved */
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Decimal from 'decimal.js';
import { useAccountInfo } from './useAccountInfo';
import { BUILDER_ORDER_TYPES, TECH_BASE_PRICE_LITERAL } from '../constants/builder';
import { BUY_SELL_MAIN, CFD, ETF, FX } from '../constants';
import { calculateMarginRequired } from '../utils';

const useChartsAndStats = ({ strategyList, defaultSets }) => {
  const instruments = useSelector((state) => state.settings.instrumentList);
  return useMemo(() => {
    if (!strategyList || !strategyList.length || !defaultSets || !Object.keys(instruments).length) {
      return { charts: [], stats: {} };
    }

    const selectionSets = new Decimal(defaultSets);

    const calculateStats = (sets, strategies) => {
      const stats = {
        totalPl: new Decimal(0),
        maxTotalPl: new Decimal(0),
        realizedPl: new Decimal(0),
        unrealizedPl: new Decimal(0),
        minUnrealizedPl: new Decimal(0),
        maxUnrealizedPl: new Decimal(0),
        maxDd: new Decimal(0),
        marginRecommended: new Decimal(0),
        riskReturn: 0,
      };

      if (!strategies.length) return [stats, []];

      const charts = _(strategies)
        .reduce(
          (reducedCharts, strategy) =>
            _(reducedCharts).mergeWith(
              // multiply p&l with strategy_sets
              _(strategy.strategyDetail.simulationChartList)
                .map((item) => ({
                  date: item.targetDate,
                  realizedPl: new Decimal(item.realizedPl).mul(strategy.strategySets).mul(sets),
                  upl: new Decimal(item.unrealizedPl).mul(strategy.strategySets).mul(sets),
                }))
                .keyBy('date')
                .value(),
              (obj, src) => {
                if (obj === undefined || src === undefined) {
                  const v = obj || src;
                  if (!v) return v;
                  return {
                    date: v.date,
                    realizedPl: new Decimal(v.realizedPl),
                    upl: new Decimal(v.upl),
                  };
                }
                return {
                  date: src.date,
                  realizedPl: new Decimal(src.realizedPl).add(obj.realizedPl),
                  upl: new Decimal(src.upl).add(obj.upl),
                };
              },
            ),
          {},
        )
        .values()
        .sortBy('date')
        .value();

      _(charts).forEach((chart) => {
        stats.realizedPl = stats.realizedPl.add(chart.realizedPl);
        stats.unrealizedPl = chart.upl;
        stats.totalPl = stats.realizedPl.add(stats.unrealizedPl);
        chart.pl = new Decimal(stats.realizedPl); // eslint-disable-line no-param-reassign
        chart.sum = chart.pl.add(chart.upl); // eslint-disable-line no-param-reassign
        stats.maxTotalPl = stats.maxTotalPl.comparedTo(chart.sum) > 0 ? stats.maxTotalPl : chart.sum;
        const dd = stats.maxTotalPl.sub(chart.sum);
        stats.maxDd = stats.maxDd.comparedTo(dd) > 0 ? stats.maxDd : dd;
        stats.minUnrealizedPl = stats.minUnrealizedPl.comparedTo(chart.upl) < 0 ? stats.minUnrealizedPl : chart.upl;
        stats.maxUnrealizedPl = stats.maxUnrealizedPl.comparedTo(chart.upl) > 0 ? stats.maxUnrealizedPl : chart.upl;
      });

      // marginRecommended
      stats.marginRecommended = _(strategies).reduce((margin, strategy) => {
        return margin.add(
          new Decimal(strategy.strategyDetail.simulationStats.marginRequired)
            .mul(strategy.strategySets)
            .mul(selectionSets)
            .mul(instruments[strategy.strategyDetail.instrumentId].recommendMarginRatio),
        );
      }, new Decimal(0));

      stats.marginRecommended = stats.marginRecommended.add(stats.maxDd);

      return [stats, charts];
    };

    const [stats, charts] = calculateStats(selectionSets, strategyList);
    const [statsFX] = calculateStats(
      selectionSets,
      strategyList.filter((stg) => stg.strategyDetail.serviceId === FX),
    );
    const [statsETF] = calculateStats(
      selectionSets,
      strategyList.filter((stg) => stg.strategyDetail.serviceId === ETF),
    );
    const [statsCFD] = calculateStats(
      selectionSets,
      strategyList.filter((stg) => stg.strategyDetail.serviceId === CFD),
    );

    // maxDd = FX.maxDd + ETF.maxDd + CFD.maxDb
    stats.maxDd = statsFX.maxDd.add(statsETF.maxDd).add(statsCFD.maxDd);
    stats.maxDdFX = statsFX.maxDd;
    stats.maxDdETF = statsETF.maxDd;
    stats.maxDdCFD = statsCFD.maxDd;
    // marginRecommended = FX.marginRecommended + ETF.marginRecommended + CFD.marginRecommended
    stats.marginRecommended = statsFX.marginRecommended.add(statsETF.marginRecommended).add(statsCFD.marginRecommended);
    stats.marginRecommendedFX = statsFX.marginRecommended;
    stats.marginRecommendedETF = statsETF.marginRecommended;
    stats.marginRecommendedCFD = statsCFD.marginRecommended;

    // riskReturn
    if (stats.maxDd.comparedTo(0) > 0) {
      stats.riskReturn = Number(stats.maxTotalPl.div(stats.maxDd).toFixed(2, Decimal.ROUND_UP));
    } else {
      stats.riskReturn = stats.maxTotalPl.comparedTo(0) > 0 ? 100 : 0;
    }
    Object.keys(stats).forEach((statsKey) => {
      if (stats[statsKey] instanceof Decimal) {
        stats[statsKey] = stats[statsKey].toNumber();
      }
    });
    charts.forEach((chartsItem) => {
      Object.keys(chartsItem).forEach((chartKey) => {
        if (chartsItem[chartKey] instanceof Decimal) {
          // eslint-disable-next-line no-param-reassign
          chartsItem[chartKey] = chartsItem[chartKey].toNumber();
        }
      });
    });
    return { charts, stats };
  }, [strategyList, defaultSets, instruments]);
};

// todo: need rebuild this function!
export const useCalculatingChartData = ({ strategyList, defaultSets }) => {
  const accountInfo = useAccountInfo();
  const marginRates = useSelector((state) => state.currencies.marginRates);
  const rates = useSelector((state) => state.currencies.eodRates);

  const { charts: chartData, stats } = useChartsAndStats({ strategyList, defaultSets });

  const convertEntryPrice1Value = useCallback(
    (price, buySell, instrumentId) => {
      if (!price) return price;

      if (!String(price)?.includes(TECH_BASE_PRICE_LITERAL)) {
        return price;
      }

      const formatPrice = Number(price.replace(TECH_BASE_PRICE_LITERAL, '')?.replaceAll(' ', ''));
      const rate =
        buySell === String(BUY_SELL_MAIN.BUY.ID) ? rates[instrumentId]?.askClose : rates[instrumentId]?.bidClose;
      return formatPrice + rate;
    },
    [rates],
  );

  const convertStrategyList = useMemo(() => {
    if (strategyList == null || strategyList.length === 0) {
      return [];
    }
    if (strategyList.some((strategy) => strategy.sourceType === BUILDER_ORDER_TYPES.TECH.NAME)) {
      return strategyList.map((strategy) => ({
        ...strategy,
        strategyDetail: {
          ...strategy.strategyDetail,
          itemList: strategy.strategyDetail.itemList.map((item) => ({
            ...item,
            entryPrice1: convertEntryPrice1Value(item.entryPrice1, item.side, strategy.strategyDetail.instrumentId),
          })),
        },
      }));
    }
    return strategyList;
  }, [strategyList, convertEntryPrice1Value]);

  const {
    calculatedMarginRequired: marginRequired,
    calculatedMarginRequiredFX: marginRequiredFX,
    calculatedMarginRequiredETF: marginRequiredETF,
    calculatedMarginRequiredCFD: marginRequiredCFD,
  } = useMemo(() => {
    // userMarginRequired
    const calculatedMarginRequired = calculateMarginRequired(
      convertStrategyList,
      defaultSets,
      accountInfo,
      marginRates,
    );
    const calculatedMarginRequiredFX = calculateMarginRequired(
      convertStrategyList.filter((x) => x.strategyDetail.serviceId === FX),
      defaultSets,
      accountInfo,
      marginRates,
    );
    const calculatedMarginRequiredETF = calculateMarginRequired(
      convertStrategyList.filter((x) => x.strategyDetail.serviceId === ETF),
      defaultSets,
      accountInfo,
      marginRates,
    );
    const calculatedMarginRequiredCFD = calculateMarginRequired(
      convertStrategyList.filter((x) => x.strategyDetail.serviceId === CFD),
      defaultSets,
      accountInfo,
      marginRates,
    );
    return {
      calculatedMarginRequired,
      calculatedMarginRequiredFX,
      calculatedMarginRequiredETF,
      calculatedMarginRequiredCFD,
    };
  }, [convertStrategyList, defaultSets, accountInfo, marginRates]);

  const chartStats = useMemo(
    () => ({ ...stats, marginRequired, marginRequiredFX, marginRequiredETF, marginRequiredCFD }),
    [stats, marginRequired, marginRequiredFX, marginRequiredETF, marginRequiredCFD],
  );

  return { chartData, chartStats };
};
