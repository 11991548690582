import { ENABLE_TUTORIAL_MODE, DISABLE_TUTORIAL_MODE } from '../actionConstants/tutorialConstants';

export const initialState = {
  tutorialMode: false,
};

// todo: probably merge with error reducer when start refactoring
export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ENABLE_TUTORIAL_MODE: {
      return {
        ...state,
        tutorialMode: true,
      };
    }
    case DISABLE_TUTORIAL_MODE: {
      return {
        ...state,
        tutorialMode: false,
      };
    }
    default: {
      return state;
    }
  }
};
