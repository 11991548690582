/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useDispatch, useSelector } from 'react-redux';
import { ALL_CATEGORY, FX, FX24, NEWS_SOURCE_TO_SERVICES, NEWS_TAB_LABELS, STOCK365 } from '../constants';
import { useCheckServiceAvailability } from './service';
import { sendNotificationError } from '../redux/actions';
import { getNewsRequest } from '../redux/actions/manualTradeActions';
import { isNotAvailableNews, makeNotificationErrorParamsNews } from '../utils/service';

export const useNewsData = ({ categories, selectedCategory }) => {
  const newsData = useSelector((state) => state.manualTrade.newsData);
  return useMemo(() => {
    if (selectedCategory === ALL_CATEGORY.value) {
      return newsData;
    }
    const subCategories = categories.find((c) => c.value === selectedCategory)?.subCategories;
    if (subCategories) {
      return newsData.filter(({ category }) => subCategories.includes(category));
    }
    return newsData.filter(({ category }) => category === selectedCategory);
  }, [selectedCategory, categories, newsData]);
};

const getTabByServiceId = (serviceId) => {
  if (serviceId === FX) {
    return FX24;
  }
  return STOCK365;
};

export const useNewsTab = ({ tabSelector, changeTabAction }) => {
  const dispatch = useDispatch();
  const checkServiceAvailability = useCheckServiceAvailability();
  const serviceId = useSelector((state) => state.auth.serviceId);
  // tab と service が 1:N なので有効なサービスを記録しておく
  const [currentServiceId, setCurrentServiceId] = useState(serviceId);
  const newsActiveTabId = useSelector(tabSelector);
  const [activeTab, setActiveTab] = useState(newsActiveTabId || getTabByServiceId(currentServiceId));
  const currentActiveTabRef = useRef(activeTab);

  useEffect(() => {
    currentActiveTabRef.current = activeTab;
  }, [activeTab]);

  const changeCurrentServiceId = useCallback(
    (checkResults) => {
      const availableServiceIds = checkResults
        .filter(([error]) => !error)
        .map(([_, availableServiceId]) => availableServiceId);
      if (!availableServiceIds.includes(currentServiceId)) {
        setCurrentServiceId(availableServiceIds[0]);
      }
    },
    [currentServiceId],
  );

  const onChangeTab = useCallback(
    (tab, checkOnly) => {
      const services = NEWS_SOURCE_TO_SERVICES[tab];
      const results = services.map((service) =>
        checkServiceAvailability({
          currentServiceId,
          serviceId: service,
          isNotAvailableFunc: isNotAvailableNews,
          evaluateErrorFunc: makeNotificationErrorParamsNews,
        }),
      );
      // results が空になることはない想定
      if (results.every(([error]) => error)) {
        const [errorParams] = results.find(([error]) => error);
        dispatch(sendNotificationError(errorParams));
      } else if (!checkOnly) {
        changeCurrentServiceId(results);
        setActiveTab(tab);
        dispatch(changeTabAction({ tab }));
        dispatch(getNewsRequest());
      }
    },
    [dispatch, checkServiceAvailability, changeTabAction, changeCurrentServiceId, currentServiceId],
  );

  const tryChangeTab = useCallback(
    (tab) => {
      const services = NEWS_SOURCE_TO_SERVICES[tab];
      const results = services.map((service) =>
        checkServiceAvailability({
          currentServiceId,
          serviceId: service,
          isNotAvailableFunc: isNotAvailableNews,
          evaluateErrorFunc: makeNotificationErrorParamsNews,
        }),
      );
      // results が空になることはない想定
      if (!results.every(([error]) => error)) {
        changeCurrentServiceId(results);
        if (tab !== currentActiveTabRef.current) {
          setActiveTab(tab);
          dispatch(changeTabAction({ tab }));
          dispatch(getNewsRequest());
        }
        return true;
      }
      return false;
    },
    [dispatch, checkServiceAvailability, changeTabAction, changeCurrentServiceId, currentServiceId],
  );

  useEffect(() => {
    if (tryChangeTab(activeTab)) {
      return;
    }
    const otherTabs = Object.keys(NEWS_TAB_LABELS).filter((tab) => tab !== activeTab);
    // ↓繰り返しの書き方の統一を促しているだけで、実質無意味なルール
    // eslint-disable-next-line no-restricted-syntax
    for (const otherTab of otherTabs) {
      if (tryChangeTab(otherTab)) {
        return;
      }
    }
  }, [activeTab, tryChangeTab]);
  // useMemo いらないかも
  return useMemo(() => [activeTab, onChangeTab], [activeTab, onChangeTab]);
};
