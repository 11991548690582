import { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { orderBy } from 'lodash';
import { getCategoryListRequest } from 'shared-modules/redux/actions/guideActions';
import { useCategory } from 'shared-modules/hooks/guide';
import FilmStripItems from './components/FilmStripItems';
import {
  WEB_GUIDE_ALL_SEE_BUTTON_DISPLAY_ARTICLE_NUM,
  WEB_GUIDE_CATEGORY_LIST_ARTICLE_DISPLAY_NUM,
} from '../../constants';
import styles from './media.module.scss';

const CategoryList = memo(() => {
  const dispatch = useDispatch();

  const { categoryList, selectedCategoryId } = useSelector((state) => state.guide);
  const scrollRef = useRef({});

  const sortedCategoryList = orderBy(categoryList, (i) => i.priorityLevel, ['asc']);
  const { getMarginData } = useCategory();

  useEffect(() => {
    async function getCategoryList() {
      const alertFlg = await getMarginData();
      dispatch(
        getCategoryListRequest({
          pageSize: WEB_GUIDE_CATEGORY_LIST_ARTICLE_DISPLAY_NUM,
          contentsLimit: WEB_GUIDE_CATEGORY_LIST_ARTICLE_DISPLAY_NUM,
          alertFlg,
        }),
      );
    }
    getCategoryList();
  }, [dispatch, getMarginData]);

  useEffect(() => {
    if (!selectedCategoryId) {
      window.scrollTo(0, 0);
      return;
    }

    Object.values(scrollRef.current.childNodes).forEach((node) => {
      if (node.id === String(selectedCategoryId)) {
        node.scrollIntoView();
      }
    });
  }, [scrollRef, selectedCategoryId]);

  return (
    <div ref={scrollRef} className={classNames('container-fluid', styles.wrapper)}>
      {Object.keys(categoryList).length === 1 && categoryList[0].categoryId === null ? (
        <div className={classNames('spinner-border', styles.loader)} role="status" />
      ) : (
        <>
          <h1 className={styles.h1}>トライオートガイド</h1>
          {sortedCategoryList.map((category) => (
            <div id={category.categoryId} key={category.categoryId}>
              {category.items.length > 0 && (
                <>
                  <div className={styles.dividedLine} />
                  <div className={styles.items}>
                    <h2 className={styles.h2}>
                      {category.categoryName}
                      {category.items.length > WEB_GUIDE_ALL_SEE_BUTTON_DISPLAY_ARTICLE_NUM && (
                        <Link
                          to={`/media/${category.categoryId}`}
                          className={styles.categoryLink}
                          style={{ textDecoration: 'none' }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            すべて見る
                            <i className="material-icons" style={{ fontSize: 15, display: 'inline' }}>
                              chevron_right
                            </i>
                          </div>
                        </Link>
                      )}
                    </h2>
                    <h3 className={styles.h3}>{category.description}</h3>
                    <FilmStripItems items={category.items.slice(0, WEB_GUIDE_CATEGORY_LIST_ARTICLE_DISPLAY_NUM)} />
                  </div>
                </>
              )}
            </div>
          ))}
          <div className={styles.footer} />
        </>
      )}
    </div>
  );
});

export default CategoryList;
