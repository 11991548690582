import { put, call, select } from 'redux-saga/effects';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import {
  getPositionsInfoRequest,
  getPositionsInfoRequestStartLoading,
  getPositionsInfoRequestEndLoading,
} from '../../actions/portfolioActions';
import { deletePositionsOrder } from '../../../api/manualTradeApi';
import { errorHandler } from '../errorSaga';

function* deletePositionsOrderRequestHandler(action) {
  try {
    yield put(getPositionsInfoRequestStartLoading());
    const {
      payload: { orderId: positionId, side, instrumentId, apGroupId, callback },
    } = action;

    const instrumentList = yield select((state) => state.settings.instrumentList);
    const serviceId = instrumentList?.[instrumentId]?.serviceId;

    yield call(deletePositionsOrder, { positionId, serviceId });
    yield put(getPositionsInfoRequest({ instrumentId, side, apGroupId, serviceId }));

    yield put(sendNotificationSuccess({ message: '決済注文が発注されました。' }));

    if (callback) callback();
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getPositionsInfoRequestEndLoading());
  }
}

export default deletePositionsOrderRequestHandler;
