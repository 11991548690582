import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  COLORS,
  quarterOptions,
  quarterSelectorWidth,
  searchButtonWidth,
  yearSelectorWidth,
} from 'shared-modules/constants';
import { useReportQuarterSettings } from 'shared-modules/services/hooks';
import CustomSelect from '../../../../../../components/CustomSelect';
import CustomButton from '../../../../../../components/CustomButton';
import styles from './reportsDetailsQuarterOptions.module.scss';

const ReportsDetailsQuarterOptions = ({ serviceId, searchReports }) => {
  const { yearOptions, startYear, endYear, startQuarter, endQuarter, submit } = useReportQuarterSettings({
    serviceId,
    searchReports,
  });

  return (
    <>
      <CustomSelect
        options={yearOptions}
        selectItemId={startYear.get}
        onChange={startYear.set}
        disabledValues={startYear.disabledValues}
        width={yearSelectorWidth}
        isLighter
      />

      <CustomSelect
        options={quarterOptions}
        selectItemId={startQuarter.get}
        onChange={startQuarter.set}
        disabledValues={startQuarter.disabledValues}
        className={styles.formatItem}
        width={quarterSelectorWidth}
        isLighter
      />

      <div className={styles.dataSeparator}>〜</div>

      <CustomSelect
        options={yearOptions}
        selectItemId={endYear.get}
        onChange={endYear.set}
        disabledValues={endYear.disabledValues}
        className={styles.formatItem}
        width={yearSelectorWidth}
        isLighter
      />

      <CustomSelect
        options={quarterOptions}
        selectItemId={endQuarter.get}
        onChange={endQuarter.set}
        disabledValues={endQuarter.disabledValues}
        className={styles.formatItem}
        width={quarterSelectorWidth}
        isLighter
      />

      <CustomButton
        color={COLORS.WHITE}
        width={searchButtonWidth}
        className={styles.searchButton}
        onClick={submit.handler}
        isLoading={submit.isLoading}
      >
        {submit.label}
      </CustomButton>
    </>
  );
};

ReportsDetailsQuarterOptions.propTypes = {
  serviceId: PropTypes.string.isRequired,
  searchReports: PropTypes.func.isRequired,
};

export default memo(ReportsDetailsQuarterOptions);
