import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { CFD, ETF } from 'shared-modules/constants';
import CustomFlag from '../../../../components/CustomFlag';
import CustomFlagETF from '../../../../components/CustomFlagETF';
import CustomFlagCFD from '../../../../components/CustomFlagCFD';
import styles from './flagElement.module.scss';

const FlagElement = ({ instrumentId, isFX, isAdditionalInstrument }) => {
  const instrument = useSelector((state) => state.settings.instrumentList[instrumentId]);
  const instrumentType = useMemo(() => {
    if (isFX || isAdditionalInstrument) {
      const [firstFlagIcon, secondFlagIcon] = instrument.symbol.split('/');

      return (
        <>
          <CustomFlag country={firstFlagIcon} className={styles.firstFlag} />
          <CustomFlag country={secondFlagIcon} className={styles.secondFlag} />
          {instrument.symbol}
        </>
      );
    }
    const { image, shortName, serviceId } = instrument;
    const [flagName] = image.split('.');
    const flagNameCFD = instrumentId.replace('/', '');

    if (serviceId === ETF) {
      return (
        <>
          <CustomFlagETF flagType={flagName} className={styles.etfFlag} />
          {shortName}
        </>
      );
    }
    if (serviceId === CFD) {
      return (
        <>
          <CustomFlagCFD flagType={flagNameCFD} className={styles.cfdFlag} />
          {shortName}
        </>
      );
    }
    return null;
  }, [isFX, isAdditionalInstrument, instrument, instrumentId]);

  return (
    <>
      <div
        className={classNames(styles.brandClickableArea, {
          [styles.isDisable]: isAdditionalInstrument,
        })}
        aria-hidden
      >
        {instrumentType}
      </div>
    </>
  );
};

FlagElement.propTypes = {
  instrumentId: PropTypes.string.isRequired,
  isFX: PropTypes.bool.isRequired,
  isAdditionalInstrument: PropTypes.bool.isRequired,
};

export default memo(FlagElement);
