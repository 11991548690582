import { memo } from 'react';
import { useSelector } from 'react-redux';
import { formatNumberToDisplayedString, truncToPrecision } from 'shared-modules/services';
import PropTypes from 'prop-types';

const ValueWithPrecision = ({ value, instrumentId }) => {
  const pricePrecision = useSelector((state) => state.settings.instrumentList[instrumentId]?.pricePrecision);

  if (!value) return '-';

  return formatNumberToDisplayedString({
    value: truncToPrecision(value, pricePrecision || 1),
    withoutPlus: true,
    withYenIcon: false,
  });
};

ValueWithPrecision.propTypes = {
  value: PropTypes.number,
  instrumentId: PropTypes.string.isRequired,
};

ValueWithPrecision.defaultProps = {
  value: 0,
};

export default memo(ValueWithPrecision);
