import Decimal from 'decimal.js';
import {
  CHART_MAKE_DEFAULT_WIDTH,
  CORE_RANGER_BEAR,
  CORE_RANGER_BULL,
  FX,
  CHART_MAKE_CHANGE_TARGET_OPTIONS,
  CHART_MAKE_BUY_SELL_MAIN,
} from '../../../../constants/index';
import Ap from '../../Ap';
import { ceil, getSignificantDigits, getBaseQuantity, pipsToPrice } from '../../constants';
import Logic from './Logic';
import { Range, RANGE_POSITION } from './Range';

const OneSideCoreRange = (instrumentId, instrumentSetting, modePrice, midPrice, high, low, quantity) => {
  let rangePosition;
  let min = 0;
  let max = 0;
  const digits = getSignificantDigits(FX, instrumentId, instrumentSetting?.pricePrecision);

  // 設定したレンジの最大がhighの範囲を超える場合はhighを超えないように最大を設定し、最小は「最大-high-lowの60%」にする。
  // 最小についても同様にlowを超えないように制御
  if (modePrice >= midPrice) {
    max = ceil(high, digits);
    min = ceil(Decimal.sub(max, Decimal.mul(Decimal.sub(high, low), 0.6)), digits);
    rangePosition = RANGE_POSITION.TOP;
  } else {
    min = ceil(low, digits);
    max = ceil(Decimal.add(min, Decimal.mul(Decimal.sub(high, low), 0.6)), digits);
    rangePosition = RANGE_POSITION.BOTTOM;
  }
  const range = Range(
    instrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.STRADDLE,
    max,
    min,
    quantity,
    rangePosition,
  );

  return {
    isUpperSubRange() {
      return rangePosition === RANGE_POSITION.BOTTOM;
    },
    calcAndSetWidth(hendouPips, step) {
      // 幅は近似1日変動値幅から計算した値と規定値のMAXを採用
      range.setWidth(Math.max(ceil(Decimal.div(hendouPips, Decimal.sub(4, step)), 1), CHART_MAKE_DEFAULT_WIDTH));
    },
    ...range,
    // overrides
    ajustMin() {
      this.setMin(
        this.getNumOfOrderPoints() === 1
          ? this.getMax()
          : ceil(
              Decimal.sub(this.getMax(), Decimal.mul(Decimal.sub(this.getNumOfOrderPoints(), 1), this.getWidthPrice())),
              digits,
            ),
      );
    },
    ajustMax() {
      this.setMax(
        this.getNumOfOrderPoints() === 1
          ? this.getMin()
          : ceil(
              Decimal.add(this.getMin(), Decimal.mul(Decimal.sub(this.getNumOfOrderPoints(), 1), this.getWidthPrice())),
              digits,
            ),
      );
    },
    setNumOfOrdersAjustWidth(inputNumOfOrders) {
      const validateResult = this.validateRangeWidth(this.getRangeWidth(), inputNumOfOrders);
      if (!validateResult.isValid) {
        return validateResult;
      }
      this.setNumOfOrders(inputNumOfOrders);
      this.setWidth(
        this.getNumOfOrderPoints() === 1
          ? this.getRangeWidth()
          : ceil(Decimal.div(this.getRangeWidth(), Decimal.sub(this.getNumOfOrderPoints(), 1)), 1),
      );
      return { isValid: true };
    },
    calcAndSetNumOfOrders() {
      this.setNumOfOrders(
        ceil(Decimal.add(Decimal.div(Decimal.sub(this.getMax(), this.getMin()), this.getWidthPrice()), 1), 0),
      );
    },
    recalcWithStartPrice(startPrice, rangeWidth, inputNumOfOrders) {
      const newNumOfOrders = inputNumOfOrders ? ceil(Decimal.div(inputNumOfOrders, 2), 0) : null;
      const newRangeWidth = rangeWidth ?? this.getRangeWidth();
      let recalcResult;

      switch (rangePosition) {
        case RANGE_POSITION.TOP: {
          const basePrice = startPrice ?? this.getMin();
          this.setMax(ceil(Decimal.add(basePrice, pipsToPrice(newRangeWidth, instrumentId)), digits));
          this.setMaxForDisp(this.getMax());
          this.setMinForDisp(ceil(basePrice, digits));
          recalcResult = this.recalc(basePrice, null, newNumOfOrders, rangeWidth);
          break;
        }
        case RANGE_POSITION.BOTTOM: {
          const basePrice = startPrice ?? this.getMax();
          this.setMin(ceil(Decimal.sub(basePrice, pipsToPrice(newRangeWidth, instrumentId)), digits));
          this.setMaxForDisp(ceil(basePrice, digits));
          this.setMinForDisp(this.getMin());
          recalcResult = this.recalc(null, basePrice, newNumOfOrders, rangeWidth);
          break;
        }
        default: {
          recalcResult = { isValid: false, errorMessage: 'レンジの位置情報が不正です。' };
        }
      }
      return recalcResult;
    },
    createAps() {
      const result = [];
      for (let i = 0; i < this.getNumOfOrderPoints(); i += 1) {
        result.push(this.toAp(i, CHART_MAKE_BUY_SELL_MAIN.SELL));
        result.push(this.toAp(i, CHART_MAKE_BUY_SELL_MAIN.BUY));
      }
      return result;
    },
    toAp(num, apSide) {
      const entryPrice1 = ceil(Decimal.sub(this.getMax(), Decimal.mul(num, this.getWidthPrice())), digits);
      return Ap(
        apSide.ID,
        entryPrice1,
        null,
        this.getDispQuantity(),
        this.getSl(),
        this.getTp(),
        null,
        null,
        entryPrice1,
      );
    },
    validateRangeWidth(rangeWidth, numOfOrders) {
      if (
        (numOfOrders === 1 && ceil(rangeWidth, 1) < CHART_MAKE_DEFAULT_WIDTH) ||
        ceil(Decimal.div(rangeWidth, Decimal.sub(numOfOrders, 1)), 1) < CHART_MAKE_DEFAULT_WIDTH
      ) {
        return {
          isValid: false,
          errorMessage: 'この設定では注文幅が狭くなり過ぎます。レンジ幅を広くするか本数を少なくして下さい。',
        };
      }
      return { isValid: true };
    },
    getNumOfOrders() {
      return range.getNumOfOrders() * 2;
    },
    getNumOfOrderPoints() {
      return range.getNumOfOrders();
    },
    getMinRangeWidth(inputNumOfOrders) {
      const validateNumOfOrdersResult = this.validateNumOfOrders(inputNumOfOrders);

      if (!validateNumOfOrdersResult.isValid) {
        return validateNumOfOrdersResult;
      }
      return ceil(Decimal.mul(CHART_MAKE_DEFAULT_WIDTH, Decimal.sub(Decimal.div(inputNumOfOrders, 2), 1)), 1);
    },
    validateNumOfOrders(inputNumOfOrders) {
      if (inputNumOfOrders % 2 !== 0) {
        return {
          isValid: false,
          errorMessage: 'コアレンジの本数は偶数を指定してください。売注文と買注文が半分ずつ同じ本数設定されます。',
        };
      }
      return { isValid: true };
    },
  };
};

const OneSideSubRange = (
  inputInstrumentId,
  instrumentSetting,
  inputSide,
  inputMin,
  inputMax,
  inputQuantity,
  inputRangePosition,
) => {
  const range = Range(
    inputInstrumentId,
    instrumentSetting,
    inputSide,
    inputMin,
    inputMax,
    inputQuantity,
    inputRangePosition,
  );
  return {
    calcAndSetMaxMin(coreRange, high, low) {
      if (this.getRangePosition() === RANGE_POSITION.TOP) {
        range.setMax(high);
        range.setMin(coreRange.getMax());
      } else {
        range.setMax(coreRange.getMin());
        range.setMin(low);
      }
    },
    calcAndSetWidth(coreRangewidth) {
      // サブレンジの幅はコアレンジの4倍
      range.setWidth(ceil(Decimal.mul(coreRangewidth, 4), 1));
    },
    ...range,
  };
};

const OneSideCoreRanger = (bullFlg, instrumentId, modePrice, midPrice, inputHigh, inputLow, instrumentSetting) => {
  const digits = getSignificantDigits(FX, instrumentId, instrumentSetting?.pricePrecision);
  const high = ceil(inputHigh, digits);
  const low = ceil(inputLow, digits);
  const logic = Logic(bullFlg ? CORE_RANGER_BULL : CORE_RANGER_BEAR, high, low);
  const side = bullFlg ? CHART_MAKE_BUY_SELL_MAIN.BUY : CHART_MAKE_BUY_SELL_MAIN.SELL;
  const coreRange = OneSideCoreRange(
    instrumentId,
    instrumentSetting,
    modePrice,
    midPrice,
    high,
    low,
    Decimal.mul(getBaseQuantity(FX, instrumentId), 2).toNumber(),
  );
  const subRange = OneSideSubRange(
    instrumentId,
    instrumentSetting,
    side,
    0,
    0,
    getBaseQuantity(FX, instrumentId),
    coreRange.getRangePosition() === RANGE_POSITION.TOP ? RANGE_POSITION.BOTTOM : RANGE_POSITION.TOP,
  );
  return {
    getNumOfOrders() {
      return coreRange.getNumOfOrders() + subRange.getNumOfOrders();
    },
    createUnsortedAps() {
      const coreRangeAps = coreRange.createAps();
      const subRangeAps = subRange.createAps();
      return subRangeAps.concat(coreRangeAps);
    },
    getAllRanges() {
      return [subRange, coreRange];
    },
    getRecalcResource(settings) {
      let targetRange; // 注文変更ターゲットレンジ
      const upperRanges = []; // ターゲットレンジより上のレンジ
      const lowerRanges = []; // ターゲットレンジより下のレンジ
      let newNumOfOrders; // 合計本数バリデーション用 ターゲットレンジの注文本数
      let otherNumOfOrders; // 合計本数バリデーション用 ターゲットレンジ以外の注文本数
      // ターゲットのレンジ別の処理
      switch (settings.target) {
        case CHART_MAKE_CHANGE_TARGET_OPTIONS[FX][CORE_RANGER_BULL][0].id:
        case CHART_MAKE_CHANGE_TARGET_OPTIONS[FX][CORE_RANGER_BEAR][0].id:
          otherNumOfOrders = coreRange.getNumOfOrders();
          newNumOfOrders = settings.recalc.numOfOrders;
          targetRange = subRange;
          if (coreRange.isUpperSubRange()) {
            lowerRanges.push(coreRange);
          } else {
            upperRanges.push(coreRange);
          }
          break;
        case CHART_MAKE_CHANGE_TARGET_OPTIONS[FX][CORE_RANGER_BULL][1].id:
        case CHART_MAKE_CHANGE_TARGET_OPTIONS[FX][CORE_RANGER_BEAR][1].id:
          targetRange = coreRange;
          if (settings.recalc.numOfOrders) {
            const validateNumOfOrdersResult = targetRange.validateNumOfOrders(settings.recalc.numOfOrders);

            if (!validateNumOfOrdersResult.isValid) {
              return validateNumOfOrdersResult;
            }
            newNumOfOrders = settings.recalc.numOfOrders;
          }
          otherNumOfOrders = subRange.getNumOfOrders();

          if (coreRange.isUpperSubRange()) {
            upperRanges.push(subRange);
          } else {
            lowerRanges.push(subRange);
          }
          break;
        default:
          return null;
      }
      return {
        isValid: true,
        targetRange,
        upperRanges,
        lowerRanges,
        newNumOfOrders,
        otherNumOfOrders,
      };
    },
    backupCurrentSettings() {
      coreRange.backupCurrentSettings();
      subRange.backupCurrentSettings();
    },
    rollbackSettings() {
      coreRange.rollbackSettings();
      subRange.rollbackSettings();
    },
    backupDefaultSettings() {
      coreRange.backupDefaultSettings();
      subRange.backupDefaultSettings();
    },
    calcAndSetRangeSettings(hendou, step) {
      this.calcAndSetCoreRangeMin(hendou, step);
      this.calcAndSetSubRangeMaxMin();
    },
    calcAndSetCoreRangeMin(hendou, step) {
      // 近似1日変動値幅とstepでコアレンジの幅、その幅から本数を計算
      coreRange.calcAndSetWidth(hendou, step);
      coreRange.calcAndSetNumOfOrders();
    },
    calcAndSetSubRangeMaxMin() {
      // コアレンジの幅からサブレンジの幅を計算
      subRange.calcAndSetWidth(coreRange.getWidth());
      subRange.calcAndSetMaxMin(coreRange, this.getHigh(), this.getLow());
      // 求めた幅、最大最小から本数を計算
      subRange.calcAndSetNumOfOrders();
    },
    ...logic,
    calcAfterFixedWidthProcess() {
      if (coreRange.isUpperSubRange()) {
        coreRange.ajustMin();
        subRange.ajustMax();
      } else {
        coreRange.ajustMax();
        subRange.ajustMin();
      }
    },
  };
};

export default OneSideCoreRanger;
