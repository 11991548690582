import {
  START_MESSAGE_LISTENER,
  GET_UNREAD_COUNT_REQUEST,
  GET_UNREAD_COUNT_SUCCESS,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_REQUEST_START_LOADING,
  GET_MESSAGES_REQUEST_STOP_LOADING,
  MARK_AS_READ_REQUEST,
  REFRESH_MESSAGES_REQUEST,
  REFRESH_MESSAGES_SUCCESS,
  CLEAR_MESSAGES,
  START_AUTO_REFRESH_MESSAGE,
  SET_CURRENT_TAB,
  STOP_AUTO_REFRESH_MESSAGE,
  CHECK_INITIAL_DEPOSIT_REQUEST,
  CHECK_INITIAL_DEPOSIT_SUCCESS,
  GET_RANGE_OUTS_REQUEST,
  GET_RANGE_OUTS_SUCCESS,
  OPEN_MESSAGE_MODAL,
  CLOSE_MESSAGE_MODAL,
  OPEN_MESSAGE_MODAL_REQUEST,
  SET_DEFAULT_OPENED_MESSAGE_ID,
  RESET_MESSAGE_MODAL,
} from '../actionConstants/messageConstants';

export const startMessageListener = () => ({ type: START_MESSAGE_LISTENER });
export const getUnreadCountRequest = ({ channel }) => ({
  type: GET_UNREAD_COUNT_REQUEST,
  payload: { channel },
});
export const getUnreadCountSuccess = ({ unreadCountEvent = 0, unreadCountIndividual = 0 }) => ({
  type: GET_UNREAD_COUNT_SUCCESS,
  payload: { unreadCountEvent, unreadCountIndividual },
});
export const getMessagesRequest = ({ isIndividual, channel }) => ({
  type: GET_MESSAGES_REQUEST,
  payload: { isIndividual, channel },
});
export const getMessagesSuccess = ({ messages }) => ({ type: GET_MESSAGES_SUCCESS, payload: { messages } });
export const getMessagesRequestStartLoading = () => ({ type: GET_MESSAGES_REQUEST_START_LOADING });
export const getMessagesRequestStopLoading = () => ({ type: GET_MESSAGES_REQUEST_STOP_LOADING });
export const markAsReadRequest = ({ messageId, isIndividual, channel }) => ({
  type: MARK_AS_READ_REQUEST,
  payload: { messageId, isIndividual, channel },
});
export const refreshMessagesRequest = ({ isIndividual, channel }) => ({
  type: REFRESH_MESSAGES_REQUEST,
  payload: { isIndividual, channel },
});
export const refreshMessagesSuccess = ({ messages }) => ({ type: REFRESH_MESSAGES_SUCCESS, payload: { messages } });
export const clearMessages = () => ({ type: CLEAR_MESSAGES });
export const startAutoRefreshMessage = () => ({ type: START_AUTO_REFRESH_MESSAGE });
export const stopAutoRefreshMessage = () => ({ type: STOP_AUTO_REFRESH_MESSAGE });
export const setCurrentTab = ({ currentTab }) => ({ type: SET_CURRENT_TAB, payload: { currentTab } });
export const checkInitialDepositRequest = () => ({ type: CHECK_INITIAL_DEPOSIT_REQUEST });
export const checkInitialDepositSuccess = ({ initialDepositState }) => ({
  type: CHECK_INITIAL_DEPOSIT_SUCCESS,
  payload: { initialDepositState },
});
export const getRangeOutsRequest = () => ({ type: GET_RANGE_OUTS_REQUEST });
export const getRangeOutsSuccess = ({ rangeOutState }) => ({
  type: GET_RANGE_OUTS_SUCCESS,
  payload: { rangeOutState },
});
export const openMessageModal = ({ message }) => ({
  type: OPEN_MESSAGE_MODAL,
  payload: { message },
});
export const closeMessageModal = () => ({ type: CLOSE_MESSAGE_MODAL });
export const resetMessageModal = () => ({ type: RESET_MESSAGE_MODAL });
export const openMessageModalRequest = ({ isIndividual, channel }) => ({
  type: OPEN_MESSAGE_MODAL_REQUEST,
  payload: { isIndividual, channel },
});
export const setDefaultOpendMessageId = ({ defaultOpenedMessageId }) => ({
  type: SET_DEFAULT_OPENED_MESSAGE_ID,
  payload: { defaultOpenedMessageId },
});
