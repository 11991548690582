/* eslint-disable */
import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import { AP_GROUP_ORDER, AP_GROUP_SOURCES, CFD, ETF, FX, LAB_PUBLISHED_STATUS } from '../constants';

export const getLabsRequestEndLoading = createAction('LABS/GET_LABS_REQUEST_END_LOADING');
export const getLabsRequest = createAction('LABS/GET_LABS_REQUEST');
export const getLabsSuccess = createAction('LABS/GET_LABS_SUCCESS');
export const getLabDetailsRequestEndLoading = createAction('LABS/GET_LAB_DETAILS_REQUEST_END_LOADING');
export const getLabDetailsRequest = createAction('LABS/GET_LAB_DETAILS_REQUEST');
export const getLabDetailsSuccess = createAction('LABS/GET_LAB_DETAILS_SUCCESS');
export const cancelRequestEndLoading = createAction('LABS/CANCEL_REQUEST_END_LOADING');
export const cancelRequest = createAction('LABS/CANCEL_REQUEST');
export const cancelSuccess = createAction('LABS/CANCEL_SUCCESS');
export const getAllLabsRequest = createAction('LABS/GET_ALL_LABS_REQUEST');
export const getAllLabsSuccess = createAction('LABS/GET_ALL_LABS_SUCCESS');
export const cancelLabRequest = createAction('LABS/CANCEL_LAB_REQUEST');

export const getPublishedLabsRequestEndLoading = createAction('LABS/GET_PUBLISHED_LABS_REQUEST_END_LOADING');
export const getPublishedLabsRequest = createAction('LABS/GET_PUBLISHED_LABS_REQUEST');
export const getPublishedLabsSuccess = createAction('LABS/GET_PUBLISHED_LABS_SUCCESS');
export const getPublishedLabDetailsRequestEndLoading = createAction(
  'LABS/GET_PUBLISHED_LAB_DETAILS_REQUEST_END_LOADING',
);
export const getPublishedLabDetailsRequest = createAction('LABS/GET_PUBLISHED_LAB_DETAILS_REQUEST');
export const getPublishedLabDetailsSuccess = createAction('LABS/GET_PUBLISHED_LAB_DETAILS_SUCCESS');
export const resetPublishedLabDetails = createAction('LABS/RESET_PUBLISHED_LAB_DETAILS');
export const changePublishedLabSelectedTermId = createAction('LABS/CHANGE_PUBLISHED_LAB_SELECTED_TERM_ID');

export const getTagsRequestEndLoading = createAction('LABS/GET_TAGS_REQUEST_END_LOADING');
export const getTagsRequest = createAction('LABS/GET_TAGS_REQUEST');
export const getTagsSuccess = createAction('LABS/GET_TAGS_SUCCESS');

export const setFormValue = createAction('LABS/SET_FORM_VALUE');
export const resetForm = createAction('LABS/RESET_FORM');
export const postLabRequest = createAction('LABS/POST_LAB_REQUEST');
export const postLabRequestEndLoading = createAction('LABS/POST_LAB_REQUEST_END_LOADING');
export const getApGroupsSimulationRequest = createAction('LABS/GET_AP_GROUPS_SIMULATION_REQUEST');
export const getApGroupsSimulationSuccess = createAction('LABS/GET_AP_GROUPS_SIMULATION_SUCCESS');
export const getApGroupsSimulationRequestEndLoading = createAction('LABS/GET_AP_GROUPS_SIMULATION_REQUEST_END_LOADING');
export const resetPostLabApGroupsSimulation = createAction('LABS/RESET_POST_LAB_AP_GROUPS_SIMULATION');
export const validateApGroupsRequest = createAction('LABS/VALIDATE_AP_GROUPS_REQUEST');
export const validateApGroupsSuccess = createAction('LABS/VALIDATE_AP_GROUPS_SUCCESS');
export const validateApGroupsRequestEndLoading = createAction('LABS/VALIDATE_AP_GROUPS_REQUEST_END_LOADING');
export const validateLabNameRequest = createAction('LABS/VALIDATE_LAB_NAME_REQUEST');
export const validateLabNameSuccess = createAction('LABS/VALIDATE_LAB_NAME_SUCCESS');
export const validateLabNameRequestEndLoading = createAction('LABS/VALIDATE_LAB_NAME_REQUEST_END_LOADING');
export const resetValidationLab = createAction('LABS/RESET_VALIDATION_LAB');
export const setIsModalOpen = createAction('LABS/SET_IS_MODAL_OPEN');

export const getPostForbiddenApGroups = createAction('LABS/GET_TQQQ_ORDERS');
export const getPostForbiddenApGroupsSuccess = createAction('LABS/GET_POST_FORBIDDEN_AP_GROUPS');
export const getPostForbiddenApGroupsEndLoading = createAction('LABS/');

export const getMyLabDetailsRequestStartLoading = createAction('LABS/GET_MY_LAB_DETAILS_REQUEST_END_LOADING');
export const getMyLabDetailsRequestEndLoading = createAction('LABS/GET_MY_LAB_DETAILS_REQUEST_END_LOADING');
export const getMyLabDetailsRequest = createAction('LABS/GET_MY_LAB_DETAILS_REQUEST');
export const getMyLabDetailsSuccess = createAction('LABS/GET_MY_LAB_DETAILS_SUCCESS');
export const getMyLabDetailsFailure = createAction('LABS/GET_MY_LAB_DETAILS_FAILURE');

export const getSilentApGroupsMultiSimulationRequest = createAction(
  'LABS/GET_SILENT_AP_GROUPS_MULTI_SIMULATION_REQUEST',
);
export const getSilentApGroupsMultiSimulationSuccess = createAction(
  'LABS/GET_SILENT_AP_GROUPS_MULTI_SIMULATION_SUCCESS',
);

const initialFormState = {
  serviceId: '',
  name: '',
  apGroupIds: [],
  image: {
    isPreset: false,
    filename: '',
    content: '',
    uri: '',
  },
  style: '',
  comment: '',
  postForbiddenApGroupIds: [],
};

const initialSimulationState = { loading: false, result: {} };
const initialValidationState = { loading: false, errors: null };

Object.freeze(initialFormState);
Object.freeze(initialSimulationState);
Object.freeze(initialValidationState);

const initialState = {
  postLab: {
    form: { ...initialFormState },
    loading: false,
    simulation: { ...initialSimulationState },
    validation: { ...initialValidationState },
    multiSimulationResults: {},
  },
  myLabs: {
    [FX]: [],
    [ETF]: [],
    [CFD]: [],
    loading: false,
    selectedLabId: null,
    simulation: { ...initialSimulationState },
    canceling: false,
  },
  publishedLabs: {
    list: [],
    loading: false,
    labServiceId: FX,
    selectedLabId: null,
    searchQuery: '',
    currentPage: 1,
    pageSize: 20,
    selectedTermId: null,
    changingSimulationTerm: false,
    simulation: { ...initialSimulationState },
  },
  tags: { list: [], loading: false },
  myLabDetails: {},
  myLabDetailsLoading: false,
};

export default createReducer(initialState, {
  [getLabsRequest.type]: (labs) => {
    labs.myLabs.loading = true;
  },
  [getLabsRequestEndLoading.type]: (labs) => {
    labs.myLabs.loading = false;
  },
  [getLabsSuccess.type]: (labs, action) => {
    const { data, serviceId } = action.payload;
    labs.myLabs[serviceId] = data;
  },
  [getLabDetailsRequest.type]: (labs, action) => {
    labs.myLabs.simulation.loading = true;
    const { labId } = action.payload;
    if (labId) labs.myLabs.selectedLabId = labId;
  },
  [getLabDetailsRequestEndLoading.type]: (labs) => {
    labs.myLabs.simulation.loading = false;
  },
  [getLabDetailsSuccess.type]: (labs, action) => {
    labs.myLabs.simulation.loading = false;
    labs.myLabs.simulation.result = action.payload;
  },
  [cancelRequest.type]: (labs, action) => {
    labs.myLabs.canceling = true;
  },
  [cancelRequestEndLoading.type]: (labs) => {
    labs.myLabs.canceling = false;
  },
  [getAllLabsRequest.type]: (labs) => {
    labs.myLabs.loading = true;
  },
  [getAllLabsSuccess.type]: (labs, action) => {
    const { data } = action.payload;
    Object.keys(data).forEach((serviceId) => {
      labs.myLabs[serviceId] = data[serviceId];
    });
  },
  [cancelLabRequest.type]: (labs, action) => {
    labs.myLabs.canceling = true;
    const { labId } = action.payload;
    if (labId) {
      labs.myLabs.selectedLabId = labId;
    }
  },
  [getPublishedLabsRequest.type]: (labs, action) => {
    labs.publishedLabs.loading = true;
    labs.publishedLabs.list = [];

    const { serviceId } = action.payload;
    if (serviceId) labs.publishedLabs.labServiceId = serviceId;
  },
  [getPublishedLabsRequestEndLoading.type]: (labs) => {
    labs.publishedLabs.loading = false;
  },
  [getPublishedLabsSuccess.type]: (labs, action) => {
    labs.publishedLabs.loading = false;
    labs.publishedLabs.list = action.payload;
  },
  [getPublishedLabDetailsRequest.type]: (labs, action) => {
    const { labId, termIdLoader } = action.payload;
    if (termIdLoader) {
      labs.publishedLabs.changingSimulationTerm = true;
    }
    labs.publishedLabs.simulation.loading = true;
    if (labId) labs.publishedLabs.selectedLabId = labId;
  },
  [getPublishedLabDetailsRequestEndLoading.type]: (labs) => {
    labs.publishedLabs.simulation.loading = false;
    labs.publishedLabs.changingSimulationTerm = false;
  },
  [getPublishedLabDetailsSuccess.type]: (labs, action) => {
    labs.publishedLabs.simulation.loading = false;
    labs.publishedLabs.changingSimulationTerm = false;
    labs.publishedLabs.simulation.result = action.payload;
  },
  [resetPublishedLabDetails.type]: (labs) => {
    labs.publishedLabs.simulation = { ...initialSimulationState };
    labs.publishedLabs.changingSimulationTerm = false;
    labs.publishedLabs.selectedTermId = null;
  },
  [changePublishedLabSelectedTermId.type]: (labs, action) => {
    labs.publishedLabs.selectedTermId = action.payload.termId;
  },
  [getTagsRequest.type]: (labs, action) => {
    labs.tags.loading = true;
  },
  [getTagsRequestEndLoading.type]: (labs) => {
    labs.tags.loading = false;
  },
  [getTagsSuccess.type]: (labs, action) => {
    labs.tags.loading = false;
    labs.tags.list = action.payload;
  },
  [setFormValue.type]: (labs, action) => {
    const { key, value } = action.payload;
    labs.postLab.form[key] = value;
  },
  [resetForm.type]: (labs, action) => {
    labs.postLab.form = { ...initialFormState };
  },
  [postLabRequest.type]: (labs, action) => {
    labs.postLab.loading = true;
  },
  [postLabRequestEndLoading.type]: (labs, action) => {
    labs.postLab.loading = false;
  },
  [getApGroupsSimulationRequest.type]: (labs, action) => {
    labs.postLab.simulation.loading = true;
  },
  [getApGroupsSimulationSuccess.type]: (labs, action) => {
    labs.postLab.simulation.loading = false;
    labs.postLab.simulation.result = action.payload;
  },
  [getApGroupsSimulationRequestEndLoading.type]: (labs, action) => {
    labs.postLab.simulation.loading = false;
  },
  [resetPostLabApGroupsSimulation.type]: (labs, action) => {
    labs.postLab.simulation = { ...initialSimulationState };
  },
  [getSilentApGroupsMultiSimulationSuccess.type]: (labs, action) => {
    labs.postLab.multiSimulationResults = action.payload;
  },
  [validateApGroupsRequest.type]: (labs, action) => {
    labs.postLab.validation.loading = true;
  },
  [validateApGroupsSuccess.type]: (labs, action) => {
    labs.postLab.validation.errors = action.payload;
  },
  [validateApGroupsRequestEndLoading.type]: (labs, action) => {
    labs.postLab.validation.loading = false;
  },
  [validateLabNameRequest.type]: (labs, action) => {
    labs.postLab.validation.loading = true;
  },
  [validateLabNameSuccess.type]: (labs, action) => {
    labs.postLab.validation.errors = action.payload;
  },
  [validateLabNameRequestEndLoading.type]: (labs, action) => {
    labs.postLab.validation.loading = false;
  },
  [resetValidationLab.type]: (labs, action) => {
    labs.postLab.validation = { ...initialValidationState };
  },
  [setIsModalOpen.type]: (labs, action) => {
    labs.isModalOpen = action.payload;
  },
  [getMyLabDetailsRequestStartLoading.type]: (labs, _action) => {
    labs.myLabDetailsLoading = true;
  },
  [getMyLabDetailsRequestEndLoading.type]: (labs, _action) => {
    labs.myLabDetailsLoading = false;
  },
  [getMyLabDetailsSuccess.type]: (labs, action) => {
    labs.myLabDetails = action.payload;
  },
  [getMyLabDetailsFailure.type]: (labs, action) => {
    labs.myLabDetails = action.payload;
  },
  [getPostForbiddenApGroups.type]: (labs, action) => {
    labs.isLoading = true;
  },
  [getPostForbiddenApGroupsSuccess.type]: (labs, action) => {
    labs.postForbiddenApGroupIds = action.payload.postForbiddenApGroupIds;
  },
  [getPostForbiddenApGroupsEndLoading.type]: (labs, action) => {
    labs.isLoading = false;
  },
});

export const formSelector = (state) => state.labs.postLab.form;
export const apGroupsSimulationResultSelector = (state) => state.labs.postLab.simulation.result;
export const apGroupsSimulationLoadingSelector = (state) => state.labs.postLab.simulation.loading;
export const getMyLabsRequestLoadingSelector = (state) => state.labs.myLabs.loading;
export const postLabRequestLoadingSelector = (state) => state.labs.postLab.loading;
export const labCancelingSelector = (state) => state.labs.myLabs.canceling;
export const formServiceIdSelector = (state) => state.labs.postLab.form.serviceId;
export const SelectedApGroupIdsSelector = (state) => state.labs.postLab.form.apGroupIds;
export const validationSelector = (state) => state.labs.postLab.validation;

export const labApGroupsSelector = createSelector(
  (state) => state.labs.postLab.form.serviceId,
  (state) => state.portfolio.apGroupsData,
  (state) => state.labs.myLabs,
  (state) => state.labs.postForbiddenApGroupIds,
  (state) => state.settings.instrumentList,
  (state) => state.settings[state.labs.postLab.form.serviceId]?.labForbiddenInstruments,
  (serviceId, apGroupsData, myLabs, postForbiddenApGroupIds, instrumentList, labForbiddenInstruments) => {
    labForbiddenInstruments = labForbiddenInstruments || [];
    return orderBy(
      apGroupsData[serviceId]?.filter(
        (a) =>
          a.status === AP_GROUP_ORDER.ACTIVITY.ACTIVE.ID && // 稼働中のみ
          (a.sourceType === AP_GROUP_SOURCES.BUILDER.KEY || a.sourceType === AP_GROUP_SOURCES.CHART_MAKE.KEY) && // sourceTypeがbuilderまたはchartMakeのもの
          !labForbiddenInstruments.map((item) => item.instrumentId).includes(a.instrumentId) && // 制御銘柄でないもの
          !postForbiddenApGroupIds?.includes(a.id) &&
          !myLabs[serviceId] // 投稿中のものでないもの
            .filter((l) => LAB_PUBLISHED_STATUS[l.status] !== undefined)
            .map((l) => l.apGroups?.id)
            .includes(a.id),
      ) || [],
      // instrumentListの銘柄の昇順、稼働日の降順にソート
      [
        (apGroup) => Object.keys(instrumentList).indexOf(apGroup.instrumentId),
        (apGroup) => new Date(apGroup.entryDateTime),
      ],
      ['asc', 'desc'],
    );
  },
);

export const labSelector = createSelector(
  (state) => state.auth.serviceId,
  (state) => state.labs.myLabs,
  (state) => state.labs.myLabs.selectedLabId,
  (serviceId, myLabs, selectedLabId) => myLabs[serviceId].find((lab) => lab.id === selectedLabId),
);
export const labSimulationSelector = (state) => state.labs.myLabs.simulation;
export const selectedLabIdSelector = (state) => state.labs.myLabs.selectedLabId;

export const publishedLabsSelector = (state) => state.labs.publishedLabs;
export const publishedLabSelector = createSelector(
  (state) => state.labs.publishedLabs.list,
  (state) => state.labs.publishedLabs.selectedLabId,
  (list, selectedLabId) => list?.find((lab) => lab.labId === selectedLabId),
);
export const publishedLabSimulationSelector = (state) => state.labs.publishedLabs.simulation;
export const publishedLabServiceIdSelector = (state) => state.labs.publishedLabs.labServiceId;
export const publishedSelectedLabIdSelector = (state) => state.labs.publishedLabs.selectedLabId;
