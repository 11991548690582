import React, { memo } from 'react';
import { FX } from 'shared-modules/constants';
import PropTypes from 'prop-types';
import styles from '../steps.module.scss';

const Step11 = ({ serviceId }) => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <p>
          カウンター値は、買いの場合は決済価格からどのくらい下がったら、次新たに買うかを設定する、再エントリーの条件になります。
          <br />
          売りの場合は反対で、決済価格からどのくらい上がったらまた売るかを設定します。
        </p>
        <p>
          【カウンター固定】
          <br />
          ONの場合は、カウンター値が価格固定となり、決済後、各注文はそれぞれ決まった価格で新規発注を繰り返すことができます。
          <br />
          OFFの場合は、決済価格からカウンター値の値幅分{serviceId === FX && '（pips）'}離れたところに新規発注します。
        </p>
        {serviceId === FX && (
          <p className={styles.subContent}>
            <br />※ pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）
          </p>
        )}
      </div>
    </div>
  );
};

Step11.propTypes = {
  serviceId: PropTypes.string,
};

Step11.defaultProps = {
  serviceId: '',
};

export default memo(Step11);
