import { memo, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FX,
  ETF,
  ETF_ACCOUNT,
  CFD_ACCOUNT,
  CFD,
  OPTIONS_TRADE_METHOD_TYPES,
  rateSeparationedAssets,
} from 'shared-modules/constants';
import { FULL_SCREEN_COLUMN_NAMES } from 'shared-modules/constants/manualTrade';
import { changeLoginValues } from 'shared-modules/redux/actions/authActions';
import { useChangeTradeMethodType } from 'shared-modules/services/hooks';
import { transformInstrumentList } from 'shared-modules/hooks/symbol';
import { POPUP_MESSAGES } from '../../constants';
import {
  replace,
  updateDisplayingTradeCurrencyPair,
  socketConnectionRequest,
  getInstrumentListRequest,
  getRatesRequest,
  changeServiceIdSuccess,
  updateServiceMaintenanceStatus,
} from '../../redux/actions';
import { Spin, Tabs } from '../../components';
import RowElement from './components/RowElement';
import styles from './currencyPairPage.module.scss';

const headerArray = [
  { id: 0, label: '銘柄', name: FULL_SCREEN_COLUMN_NAMES.INSTRUMENT_ID },
  { id: 1, label: '売(BID)', name: FULL_SCREEN_COLUMN_NAMES.BID },
  { id: 2, label: '買(ASK)', name: FULL_SCREEN_COLUMN_NAMES.ASK },
  { id: 3, label: 'スプレッド', name: FULL_SCREEN_COLUMN_NAMES.SWAP },
  { id: 4, label: 'High(高値)', name: FULL_SCREEN_COLUMN_NAMES.HIGH },
  { id: 5, label: 'Low(安値)', name: FULL_SCREEN_COLUMN_NAMES.LOW },
  { id: 6, label: '前日比', name: FULL_SCREEN_COLUMN_NAMES.PREVIOUS_DAY },
];

const Instruments = memo(({ tradeMethodType, className }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const displayedInstruments = useSelector((state) => state.settings[serviceId].displayedInstruments);
  const data = transformInstrumentList(tradeMethodType, displayedInstruments, serviceId);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  return (
    <div className={className}>
      {Object.keys(instrumentList).length === 0 ? (
        <Spin className={styles.loader} />
      ) : (
        <>
          <div className={styles.header}>
            {headerArray.map((headerItem) => (
              <div
                key={headerItem.id}
                data-name={headerItem.name}
                className={classNames(styles.headerCell, {
                  [styles.isCentered]: headerItem.name === FULL_SCREEN_COLUMN_NAMES.INSTRUMENT_ID,
                })}
              >
                {headerItem.label}
              </div>
            ))}
          </div>
          <div className={styles.bodyContainer}>
            {data
              .filter((item) => item.displayed)
              .map((item) => (
                <RowElement item={item} key={item.instrumentId} />
              ))}
          </div>
        </>
      )}
    </div>
  );
});

Instruments.propTypes = {
  className: PropTypes.string.isRequired,
  tradeMethodType: PropTypes.number.isRequired,
};

const CurrencyPairPage = () => {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.router.location.query);
  const isFXMaintenance = query?.isFXMaintenance === 'true';
  const isETFMaintenance = query?.isETFMaintenance === 'true';
  const isETFAccountExist = query?.isETFAccountExist === 'true';
  const isCFDMaintenance = query?.isCFDMaintenance === 'true';
  const isCFDAccountExist = query?.isCFDAccountExist === 'true';
  const [selectedTradeMethodType, changeTradeMethodType] = useChangeTradeMethodType();

  useEffect(() => {
    if (!window.opener) {
      dispatch(replace('/404'));
    } else {
      if (isFXMaintenance) dispatch(updateServiceMaintenanceStatus({ serviceId: FX, isMaintenance: true }));
      if (isETFMaintenance) dispatch(updateServiceMaintenanceStatus({ serviceId: ETF, isMaintenance: true }));
      if (isETFAccountExist)
        dispatch(
          changeLoginValues({
            inputName: ETF_ACCOUNT,
            value: 'exist',
          }),
        );
      if (isCFDMaintenance) dispatch(updateServiceMaintenanceStatus({ serviceId: CFD, isMaintenance: true }));
      if (isCFDAccountExist)
        dispatch(
          changeLoginValues({
            inputName: CFD_ACCOUNT,
            value: 'exist',
          }),
        );

      dispatch(getInstrumentListRequest());
      dispatch(getRatesRequest());
      dispatch(socketConnectionRequest());
      window.opener.postMessage({ message: POPUP_MESSAGES.GET_DISPLAYED_CURRENCY_LIST_REQUEST }, '*');
    }
  }, [dispatch, isFXMaintenance, isETFMaintenance, isETFAccountExist, isCFDMaintenance, isCFDAccountExist]);

  const serviceId = useSelector((state) => state.auth.serviceId);

  const displayedInstruments = useSelector((state) => state.settings[serviceId].displayedInstruments);

  useEffect(() => {
    if (displayedInstruments.length) {
      window.opener.postMessage(
        {
          message: POPUP_MESSAGES.GET_DISPLAYED_CURRENCY_LIST_SUCCESS,
          payload: { displayedInstruments, serviceId },
        },
        '*',
      );
    }
  }, [displayedInstruments, serviceId]);

  const windowEventListener = useCallback(
    ({ data }) => {
      switch (data.message) {
        case POPUP_MESSAGES.GET_DISPLAYED_CURRENCY_LIST_SUCCESS: {
          if (JSON.stringify(displayedInstruments) !== JSON.stringify(data.payload.displayedInstruments)) {
            dispatch(
              updateDisplayingTradeCurrencyPair({
                array: data.payload.displayedInstruments,
                serviceId: data.payload.serviceId,
              }),
            );
          }
          if (serviceId !== data.payload.serviceId) {
            dispatch(changeServiceIdSuccess({ serviceId: data.payload.serviceId }));
          }
          break;
        }
        case POPUP_MESSAGES.CLOSE_POPUP: {
          window.close();
          break;
        }
        default: {
          // empty
        }
      }
    },
    [dispatch, displayedInstruments, serviceId],
  );
  useEffect(() => {
    window.addEventListener('message', windowEventListener);
    return () => {
      window.removeEventListener('message', windowEventListener);
    };
  }, [windowEventListener]);

  const tabItems = useMemo(() => {
    return OPTIONS_TRADE_METHOD_TYPES.map((item) => ({
      ...item,
      children: <Instruments className={styles.container} tradeMethodType={selectedTradeMethodType} />,
    }));
  }, [selectedTradeMethodType]);

  if (rateSeparationedAssets.includes(serviceId)) {
    return <Tabs items={tabItems} activeKey={selectedTradeMethodType} onChange={changeTradeMethodType} />;
  }
  return (
    <div>
      <Instruments className={styles.wrapper} tradeMethodType={selectedTradeMethodType} />
    </div>
  );
};

export default memo(CurrencyPairPage);
