import { put, call, select, takeEvery } from 'redux-saga/effects';
import { GET_CATEGORY_LIST_REQUEST, GET_CONTENTS_LIST_REQUEST } from '../actionConstants/guideConstants';
import {
  getCategoryListStartLoading,
  getCategoryListEndLoading,
  getCategoryListSuccess,
  getContentsListSuccess,
} from '../actions/guideActions';
import { getCategoryList, getContentsList } from '../../api/guideApi';
import { errorHandler } from './errorSaga';

function* getCategoryListRequestHandler(action) {
  const {
    payload: { pageSize, contentsLimit, alertFlg },
  } = action;

  try {
    yield put(getCategoryListStartLoading());

    const isAuth = yield select((state) => state.auth.isAuthenticated);
    const portalId = yield select((state) => state.auth.portalId);
    const userId = isAuth ? portalId : null;

    const {
      data: { pageInfo, categoryList },
    } = yield call(getCategoryList, { pageSize, pageNumber: 1, contentsLimit, alertFlg, userId });

    yield put(getCategoryListSuccess({ pageInfo, categoryList }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getCategoryListEndLoading());
  }
}

function* getContentsListRequestHandler(action) {
  const {
    payload: { categoryId, pageSize, isFirstData, alertFlg, userId },
  } = action;

  if (isFirstData) yield put(getCategoryListStartLoading());

  try {
    const tableMetaInfo = yield select((state) => state.guide.pageInfo);
    const newPageNumber = isFirstData ? 1 : tableMetaInfo.pageNumber + 1;

    if (newPageNumber > tableMetaInfo.totalPages || !categoryId) {
      return;
    }

    const {
      data: { pageInfo, categoryName, description, items, priorityLevel },
    } = yield call(getContentsList, { categoryId, pageNumber: newPageNumber, pageSize, alertFlg, userId });

    yield put(
      getContentsListSuccess({
        pageInfo,
        category: { categoryId, categoryName, description, items, priorityLevel },
        isFirstData,
      }),
    );
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getCategoryListEndLoading());
  }
}

export default function* guideSaga() {
  yield takeEvery(GET_CATEGORY_LIST_REQUEST, getCategoryListRequestHandler);
  yield takeEvery(GET_CONTENTS_LIST_REQUEST, getContentsListRequestHandler);
}
