import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './chartMakeDrawPage.module.scss';
import { useKeyPressHandler } from '../../../services/hooks';
import logicImages from '../../../assets/logic/logicIMagesHelper';

const InfoModal = ({ logicImageSrc, logicName, description, closeModal }) => {
  const handlePressClose = useKeyPressHandler((e) => {
    closeModal();
    e.currentTarget.blur();
  });
  return (
    <div className={styles.recommendInfoModal}>
      <div className={styles.demoWrapper}>
        <i
          className={classNames('material-icons', styles.recommendInfoModalCloseIcon)}
          tabIndex={0}
          role="button"
          onClick={closeModal}
          onKeyPress={handlePressClose}
        >
          clear
        </i>
        <img src={logicImageSrc} alt="demo" className={styles.demoImage} />
        <div className={styles.demoTextWrapper}>
          <span className={styles.demoTitle}>{`「${logicName}」とは？`}</span>

          <span className={styles.demoDesc} style={{ whiteSpace: 'pre-line' }}>
            {description?.replace(/<br ?\/?>/g, '\n')}
          </span>
        </div>
      </div>
    </div>
  );
};
InfoModal.propTypes = {
  logicImageSrc: PropTypes.string.isRequired,
  logicName: PropTypes.string.isRequired,
  description: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
};

InfoModal.defaultProps = {
  description: '',
};

const RecommendModal = ({ isOpen, closeModal, recommendLogicItems, selectRecommend, onSelect }) => {
  const handleStopPropagation = useCallback((e) => e.stopPropagation(), []);
  const [isShowInfoModal, setIsShowInfoModal] = useState(false);
  const [infoModalLogicId, setInfoModalLogicId] = useState(null);
  const infoLogic = recommendLogicItems.find((logic) => logic.id === infoModalLogicId);
  const customDialog = (
    <div className={styles.customDialog}>
      {recommendLogicItems.map((logic, index) => (
        <div
          key={logic.id}
          onClick={() => {
            onSelect(logic.id);
          }}
          onKeyPress={() => {
            onSelect(logic.id);
          }}
          role="button"
          tabIndex={index}
          className={classNames('modal-content', styles.modalContent, {
            [styles.recommendSelected]: selectRecommend === logic.id,
          })}
        >
          <div className={styles.recommendCard}>
            <div className={styles.recommendTitle}>投資スタイル</div>
            <div className={styles.recommendSubTitle}>{logic.title}</div>
            <i
              onClick={() => {
                setIsShowInfoModal(true);
                setInfoModalLogicId(logic.id);
              }}
              onKeyPress={() => {
                setIsShowInfoModal(true);
                setInfoModalLogicId(logic.id);
              }}
              key={logic.id}
              tabIndex={index}
              role="button"
              className={classNames('material-icons-outlined', styles.infoIcon, {
                [styles.recommendSelected]: selectRecommend === logic.id,
              })}
            >
              info
            </i>
            <img alt={logic.title} className={styles.recommendImage} src={logicImages[logic?.image]} />
          </div>
        </div>
      ))}
      {isShowInfoModal && logicImages && (
        <InfoModal
          logicImageSrc={logicImages[infoLogic?.descriptionImage]}
          logicName={infoLogic?.title}
          description={infoLogic?.description}
          closeModal={() => {
            setIsShowInfoModal(false);
          }}
        />
      )}
    </div>
  );

  return (
    <Modal
      key="recommendModal"
      backdrop={false}
      show={isOpen}
      onHide={closeModal}
      onClick={handleStopPropagation}
      bsPrefix={styles.modal}
      dialogAs={customDialog.type}
    >
      {customDialog}
    </Modal>
  );
};

export default memo(RecommendModal);

RecommendModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  recommendLogicItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ).isRequired,
  selectRecommend: PropTypes.number,
  onSelect: PropTypes.func,
};

RecommendModal.defaultProps = {
  closeModal: () => {},
  selectRecommend: null,
  onSelect: () => {},
};
