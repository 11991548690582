import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { BUY_SELL_VALUE, TRADE_TYPES, FX } from 'shared-modules/constants';
import { useServiceId } from 'shared-modules/hooks';
import { useUpdateApGroupNameHandler } from 'shared-modules/hooks/portfolio';
import { checkForInteger, getServiceQuantityUnit } from 'shared-modules/services';
import {
  useAPGroupDeleteAbility,
  useCalculateUnrealizedProfitLossForPortfolioCard,
} from 'shared-modules/services/hooks';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import { tutorialCardDetail } from '../../../../../../constants/tutorial/classNames';
import CardHeader from '../../../../../../components/CardHeader';
import OptionsDropdown from '../../../../../../components/OptionsDropdown';
import {
  openConfirmationModal,
  closeConfirmationModal,
  openInputConfirmationModal,
  updateApGroupRequest,
  deleteApGroupRequest,
  sendNotificationError,
} from '../../../../../../redux/actions';
import { ColoredPriceText, NumberText } from '../../../../../../components/Texts';
import styles from './card.module.scss';

const iconSelectOptions = [
  { label: 'グループ名を変更', value: 0 },
  { label: 'グループ削除', value: 1 },
];

const extractor = ({ apGroupName }) => apGroupName;

// @deprecated
const Card = ({ data, closeModal }) => {
  const {
    type,
    currency,
    count,
    creationTime,
    groupName,
    realizedProfitLoss = '-',
    totalCount = 0,
    activeCount = 0,
    buySell,
    id,
    image,
    shortName,
    status,
  } = data || {};

  const serviceId = useServiceId(currency);
  const symbol = removeSuffix(currency);

  const dispatch = useDispatch();

  const onDeleteSuccess = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onCloseConfirmationModal = useCallback(() => dispatch(closeConfirmationModal()), [dispatch]);

  const onDeleteAccept = useCallback(() => {
    dispatch(
      deleteApGroupRequest({
        serviceId,
        groupId: id,
        callback: onDeleteSuccess,
        successMessage: `${groupName} が削除されました。`,
      }),
    );
  }, [onDeleteSuccess, id, dispatch, groupName, serviceId]);

  const onUpdate = useCallback(
    (payload) =>
      dispatch(
        updateApGroupRequest({
          serviceId,
          groupId: id,
          ...payload,
        }),
      ),
    [dispatch, id, serviceId],
  );

  const handleUpdateApGroupName = useUpdateApGroupNameHandler({ serviceId, groupId: id, extractor });

  const onStopLogicClick = useCallback(
    () =>
      dispatch(
        openConfirmationModal({
          title: '全停止',
          bodyText:
            '全ての自動売買を停止します。\n既に発注されている新規注文は取り消されます。\n既に保有されている建玉や発注されている決済注文は有効です。\n稼働停止後、次の新規注文は発注されません。\nよろしいですか？',
          callback: () => onUpdate({ status: 0, allStop: true }),
          buttonBackText: '戻る',
          buttonNextText: '全停止',
          isOverlap: true,
          isLoading: false,
        }),
      ),
    [dispatch, onUpdate],
  );

  const apGroupDeleteMessage = useAPGroupDeleteAbility();

  const handleAPGroupDelete = useCallback(() => {
    if (apGroupDeleteMessage) {
      dispatch(
        openConfirmationModal({
          title: 'グループ削除',
          bodyText: apGroupDeleteMessage,
          callback: onCloseConfirmationModal,
          buttonNextText: '閉じる',
          isOverlap: true,
          isLoading: false,
        }),
      );
    } else
      dispatch(
        openConfirmationModal({
          title: 'グループ削除',
          bodyText: `全稼働停止している自動売買グループを削除します。
						削除するとホーム画面から消え、戻すことができなくなります。
						過去に稼働した自動売買グループは照会の稼働履歴より再度カートに追加できます。
						削除してよろしいですか？`,
          callback: onDeleteAccept,
          buttonBackText: '戻る',
          buttonNextText: '削除',
          isOverlap: true,
          isLoading: false,
        }),
      );
  }, [dispatch, onDeleteAccept, apGroupDeleteMessage, onCloseConfirmationModal]);

  const changingApNameIsLoading = useSelector((state) => state.portfolio.changingApNameIsLoading);

  const deleteCardIsLoading = useSelector((state) => state.portfolio.deleteApGroupIsLoading);

  const onUpdateGroupClick = useCallback(
    (tab) => {
      if (changingApNameIsLoading && deleteCardIsLoading) return;
      if (Number(tab) === iconSelectOptions[0].value && Boolean(activeCount)) {
        dispatch(
          openInputConfirmationModal({
            title: 'グループ名を変更する',
            bodyText: 'グループ名',
            callback: handleUpdateApGroupName,
            buttonBackText: '戻る',
            buttonNextText: '変更',
            value: groupName,
            passValueWithKey: 'apGroupName',
            isOverlap: true,
          }),
        );
      } else if (Number(tab) === iconSelectOptions[0].value && !activeCount) {
        dispatch(
          sendNotificationError({
            title: 'グループ名変更',
            message: '停止されている自動売買グループのグループ名は変更できません。',
            buttonText: '閉じる',
          }),
        );
      } else handleAPGroupDelete();
    },
    [
      dispatch,
      groupName,
      handleUpdateApGroupName,
      changingApNameIsLoading,
      deleteCardIsLoading,
      handleAPGroupDelete,
      activeCount,
    ],
  );

  const displayedType = useMemo(() => {
    if (type === TRADE_TYPES.TRADE.NAME) {
      return `${type}•${BUY_SELL_VALUE[buySell]}`;
    }
    return type;
  }, [type, buySell]);

  const unrealizedProfitLoss = useCalculateUnrealizedProfitLossForPortfolioCard({
    instrumentId: currency,
    apGroupId: id,
    buySell,
  });

  const symbolName = serviceId === FX ? `${shortName}（${symbol}）` : shortName;

  return (
    <div className="card card--small" aria-hidden="true">
      <CardHeader
        displayedType={displayedType}
        image={image}
        currency={currency}
        activeCount={activeCount}
        status={status}
        type={type}
        apGroupId={id}
      />

      <p className="symbol mb-5">{symbolName}</p>
      <div className={tutorialCardDetail}>
        <div className={styles.row}>
          <div className={styles.label}>建玉数量</div>
          <NumberText
            className={styles.value}
            symbolClassName={styles.symbol}
            value={checkForInteger(count === 0 ? '-' : count)}
            symbol={getServiceQuantityUnit(serviceId)}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>評価損益</div>
          <ColoredPriceText className={styles.value} symbolClassName={styles.symbol} value={unrealizedProfitLoss} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>実現損益</div>
          <ColoredPriceText className={styles.value} symbolClassName={styles.symbol} value={realizedProfitLoss} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>本数</div>
          {/* TODO without 3桁カンマ */}
          <div className={styles.value}>{`${totalCount}(${activeCount}稼働中)`}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>稼働日</div>
          <div className={styles.value}>{creationTime}</div>
        </div>
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, position: 'relative' }}
      >
        <button
          type="button"
          className="btn btn-outline-danger"
          style={{ fontWeight: 600, fontSize: '1.4rem', border: 0 }}
          onClick={onStopLogicClick}
          disabled={!activeCount}
        >
          全停止する
        </button>
        <div style={{ position: 'absolute', right: 0 }}>
          <OptionsDropdown isBig isNonBordered onClick={onUpdateGroupClick} options={iconSelectOptions} />
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    groupName: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    realizedProfitLoss: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    buySell: PropTypes.string,
    creationTime: PropTypes.string,
    number: PropTypes.string,
    image: PropTypes.string,
    shortName: PropTypes.string,
    sourceType: PropTypes.string,
    activeCount: PropTypes.number,
  }).isRequired,
};

export default memo(Card);
