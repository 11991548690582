import { useCallback, useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
  SSO_PAYMENT_DETAIL_REDIRECT_URL,
  SSO_PAYMENT_TRANSFER_REDIRECT_URL,
  SSO_PAYMENT_WITHDRAWAL_REDIRECT_URL,
} from 'shared-modules/constants';
import { CASH_PAGES, CASH_PAGE_TITLE } from 'shared-modules/constants/cash';
import {
  getBankListRequest,
  getCashInitRequest,
  getDepositOnlyBankInfoRequest,
  getQuickDepositTermsAgreeRequest,
  resetData,
} from 'shared-modules/redux/cash';

import CashDepositViewIcon from '../../assets/cashIcon/cashDepositView.png';
import CashDetailsIcon from '../../assets/cashIcon/cashDetails.png';
import CashQuickDepositIcon from '../../assets/cashIcon/cashQuickDeposit.png';
import CashTransferIcon from '../../assets/cashIcon/cashTransfer.png';
import CashWithdrawalIcon from '../../assets/cashIcon/cashWithdrawal.png';

import { useCashPageTransition } from '../../services/hooks';
import { openNewSameTab } from '../../services';

import styles from './cashMenu.module.scss';

export const useCashMenu = () => {
  const dispatch = useDispatch();

  const pageTransition = useCashPageTransition();

  const nextPage = useCallback(
    (pageId) => {
      pageTransition(pageId);
      dispatch(resetData());
    },
    [dispatch, pageTransition],
  );

  const failureCallback = useCallback(() => pageTransition(CASH_PAGES.CASH_MENU.ID), [pageTransition]);

  const handleQuickDeposit = useCallback(
    (serviceId) => {
      nextPage(CASH_PAGES.CASH_QUICK_DEPOSIT_SELECT.ID);

      const successCallback = () => {
        batch(() => {
          dispatch(getQuickDepositTermsAgreeRequest({ serviceId, failureCallback }));
          dispatch(getBankListRequest({ serviceId }));
        });
      };

      dispatch(getCashInitRequest({ serviceId, failureCallback, successCallback }));
    },
    [dispatch, failureCallback, nextPage],
  );

  const handleDepositView = useCallback(
    (serviceId) => {
      nextPage(CASH_PAGES.CASH_DEPOSIT_VIEW.ID);

      const successCallback = () => {
        dispatch(getDepositOnlyBankInfoRequest({ serviceId, failureCallback }));
      };

      dispatch(getCashInitRequest({ serviceId, failureCallback, successCallback }));
    },
    [dispatch, failureCallback, nextPage],
  );

  const keyboardArrowRightIcon = useMemo(
    () => <i className={classNames('material-icons-outlined', styles.buttonRightIcon)}>keyboard_arrow_right</i>,
    [],
  );

  const opeInNewIcon = useMemo(
    () => <i className={classNames('material-icons', styles.buttonRightIcon)}>open_in_new</i>,
    [],
  );

  return useMemo(
    () => [
      {
        title: CASH_PAGE_TITLE.QUICK_DEPOSIT,
        description: 'インターネットでの入金',
        remarks: '（原則24時間可）',
        callBack: (serviceId) => handleQuickDeposit(serviceId),
        key: 1,
        icon: (
          <img className={styles.buttonLeftIcon} src={CashQuickDepositIcon} width="45" height="45" alt="アイコン" />
        ),
        subIcon: keyboardArrowRightIcon,
      },
      {
        title: CASH_PAGE_TITLE.DEPOSIT,
        description: '銀行窓口やATMなどから入金',
        callBack: (serviceId) => handleDepositView(serviceId),
        key: 2,
        icon: <img className={styles.buttonLeftIcon} src={CashDepositViewIcon} width="45" height="45" alt="アイコン" />,
        subIcon: keyboardArrowRightIcon,
      },
      {
        title: CASH_PAGE_TITLE.TRANSFER,
        description: '他サービスへの資金振替',
        callBack: () => openNewSameTab(`/${SSO_PAYMENT_TRANSFER_REDIRECT_URL}`),
        key: 3,
        icon: <img className={styles.transfer} src={CashTransferIcon} width="65" height="35" alt="アイコン" />,
        subIcon: opeInNewIcon,
      },
      {
        title: CASH_PAGE_TITLE.WITHDRAWAL,
        description: '取引口座から指定先金融口座に出金',
        callBack: () => openNewSameTab(`/${SSO_PAYMENT_WITHDRAWAL_REDIRECT_URL}`),
        key: 4,
        icon: <img className={styles.buttonLeftIcon} src={CashWithdrawalIcon} width="45" height="45" alt="アイコン" />,
        subIcon: opeInNewIcon,
      },
      {
        title: CASH_PAGE_TITLE.DETAILS,
        description: '入出金・振替履歴を表示',
        callBack: () => openNewSameTab(`/${SSO_PAYMENT_DETAIL_REDIRECT_URL}`),
        key: 5,
        icon: <img className={styles.buttonLeftIcon} src={CashDetailsIcon} width="45" height="45" alt="アイコン" />,
        subIcon: opeInNewIcon,
      },
    ],
    [handleDepositView, handleQuickDeposit, keyboardArrowRightIcon, opeInNewIcon],
  );
};
