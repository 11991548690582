/* eslint-disable import/no-unresolved */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { FX, CFD, ETF } from '../../constants';
import profitLossUtils from '../../utils/profitLossUtils';
import { getDailyPls } from '../../api/portfolioApi';
import { useAccountInfo } from '../../hooks/useAccountInfo';

const QUERY_DATE_FORMAT = 'YYYY-MM-DD';

const {
  getChartData,
  getInstrumentsPl,
  getTotalPl,
  getTotalSwapPl,
  getNumberOfEntries,
  getNumberOfExits,
  monthOptions,
} = profitLossUtils;

const TEXT_CONTENTS = {
  [FX]: {
    toolTipPlTotal: '実現損益とスワップの合計',
    toolTipPl: '取引日ベースで計算した売買損益の合計',
    toolTipPlSwap: '取引日ベースで決済時に確定、もしくは振替で実現したスワップ損益の合計',
    titlePlSwap: 'スワップ',
  },
  [CFD]: {
    toolTipPlTotal: '実現損益と金利・配当の合計',
    toolTipPl: '取引日ベースで計算した売買損益の合計',
    toolTipPlSwap: '取引日ベースで決済時に確定、もしくは振替で実現した金利・配当損益の合計',
    titlePlSwap: '金利・配当',
  },
  [ETF]: {
    toolTipPlTotal: '実現損益と金利・分配の合計',
    toolTipPl: '取引日ベースで計算した売買損益の合計',
    toolTipPlSwap: '取引日ベースで決済時に確定、もしくは振替で実現した金利と付与された分配相当額の合計',
    titlePlSwap: '金利・分配',
  },
};

const EMPTY_MONTH_DATA = {
  chartData: [],
  plByInstrument: [],
  totalPl: 0,
  totalSwapPl: 0,
  numberOfEntries: 0,
  numberOfExits: 0,
};

const useProfitLossFilter = (serviceId) => {
  const [byMonthDataMap, setByMonthDataMap] = useState({});
  const [selectedMonthData, setSelectedMonthData] = useState(EMPTY_MONTH_DATA);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[monthOptions.length - 1]);
  const accountInfo = useAccountInfo()[serviceId];
  const textContents = TEXT_CONTENTS[serviceId];
  const [currentFrontDate, setCurrentFrontDate] = useState(null);

  const handleMonthSelect = (item) => {
    setSelectedMonth(item);
    setSelectedMonthData(byMonthDataMap[item.value] ?? EMPTY_MONTH_DATA);
  };

  const getByMonthDataMap = useCallback(
    (data) => {
      const result = {};
      if (data && data.length) {
        monthOptions.forEach((month) => {
          const filtered = data.filter((item) => item.date.includes(month.value));
          const plByInstrument = getInstrumentsPl(filtered, { doSummaryInstrumentId: true, instrumentList });
          const byMonthData = {
            chartData: getChartData(filtered),
            plByInstrument,
            totalPl: getTotalPl(plByInstrument),
            totalSwapPl: getTotalSwapPl(plByInstrument),
            numberOfEntries: getNumberOfEntries(plByInstrument),
            numberOfExits: getNumberOfExits(plByInstrument),
          };
          result[month.value] = byMonthData;
        });
      }
      return result;
    },
    [instrumentList],
  );

  const isPreLoad = Object.keys(byMonthDataMap).length === 0;
  const [isLoading, setIsLoading] = useState(false);
  const doFetchData = isPreLoad && !isLoading;

  const fetchPlData = useCallback(async () => {
    const from = moment().tz('Asia/Tokyo').subtract(1, 'year').format(QUERY_DATE_FORMAT);
    const to = moment().tz('Asia/Tokyo').format(QUERY_DATE_FORMAT);
    setIsLoading(true);
    const { data: result } = await getDailyPls({
      serviceId,
      from,
      to,
    });
    const dataMap = getByMonthDataMap(result.list);
    setByMonthDataMap(dataMap);
    setSelectedMonthData(dataMap[selectedMonth.value] ?? EMPTY_MONTH_DATA);
    setIsLoading(false);
    setCurrentFrontDate(result?.currentFrontDate);
  }, [getByMonthDataMap, selectedMonth, serviceId]);

  useEffect(() => {
    if (!accountInfo.isNotAvailable && doFetchData) {
      fetchPlData();
    }
  }, [accountInfo.isNotAvailable, doFetchData, fetchPlData]);

  const resetSelectedMonth = () => handleMonthSelect(monthOptions[monthOptions.length - 1]);

  return {
    instrumentList,
    plByInstrument: selectedMonthData.plByInstrument,
    chartData: selectedMonthData.chartData,
    totalPl: selectedMonthData.totalPl,
    totalSwapPl: selectedMonthData.totalSwapPl,
    numberOfEntries: selectedMonthData.numberOfEntries,
    numberOfExits: selectedMonthData.numberOfExits,
    monthOptions,
    handleMonthSelect,
    selectedMonth,
    setSelectedMonth,
    resetSelectedMonth,
    textContents,
    byMonthDataMap,
    currentFrontDate,
  };
};

export default useProfitLossFilter;
