import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  LAB_POST_COMMENT_MAX_LENGTH,
  LAB_POST_NAME_MAX_LENGTH,
  STYLE_OPTIONS,
  TECHNICAL_TERM_LIST,
} from 'shared-modules/constants';
import { BUILDER_OVERVIEW_PLACEHOLDER, BUILDER_PAGES } from 'shared-modules/constants/builder';
import {
  formSelector,
  formServiceIdSelector,
  resetValidationLab,
  setFormValue,
  validateLabNameRequest,
  validationSelector,
} from 'shared-modules/redux/labs';
import { useAccountInfo } from 'shared-modules/hooks';
import { EditableSelect, EditableText, EditableTextArea } from '../../../../../components';
import { changeBuilderActivePage } from '../../../../../redux/actions';
import { BUILDERS_SHARE_PROGRESS_TITLE_STEP1 } from '../../../constants';
import { adjustText } from '../../../common';
import { FORM_WIDTH } from '../constants';
import { Step } from '../Step';
import styles from './shareInputPage.module.scss';

// TODO ShareConfirmPage との共通化
export const ShareInputPage = memo(({ progress }) => {
  const dispatch = useDispatch();
  const activePage = useSelector((state) => state.webBuilder.activePage);
  const activePageRef = useRef(activePage);

  useEffect(() => {
    activePageRef.current = activePage;
  }, [activePage]);

  const { errors, loading } = useSelector(validationSelector);
  const [isError, setIsError] = useState(false);
  const { name, style: styleValue, comment, technicalTerm } = useSelector(formSelector);

  const [tempName, setTempName] = useState(name);
  const [tempTechnicalStyle, setTempTechnicalStyle] = useState(styleValue);
  const [tempTechnicalTerm, setTempTechnicalTerm] = useState(technicalTerm);

  const [tempComment, setTempComment] = useState(comment);

  const serviceId = useSelector(formServiceIdSelector);
  const { isNotAvailable } = useAccountInfo()[serviceId];
  const disabled = !tempName || !tempTechnicalStyle || !tempTechnicalTerm || !tempComment || isError || isNotAvailable;

  useEffect(() => {
    if (errors) {
      setIsError(true);
    }
  }, [errors]);

  const handleChangeName = useCallback((e) => {
    setIsError(false);
    setTempName(e.target.value);
  }, []);

  const handleChangeTechnicalStyle = useCallback((v) => {
    setTempTechnicalStyle(v);
  }, []);

  const handleChangeTechnicalTerm = useCallback((v) => {
    setTempTechnicalTerm(v);
  }, []);

  const handleChangeComment = useCallback((e) => {
    setTempComment(e.target.value);
  }, []);

  const handleBack = useCallback(() => {
    dispatch(resetValidationLab());
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.BUILDERS_SHARE_SELECT_PAGE.ID }));
  }, [dispatch]);

  const handleNext = useCallback(() => {
    dispatch(resetValidationLab());
    // validation name
    dispatch(
      validateLabNameRequest({
        name: tempName,
        callback: () => {
          if (activePageRef.current !== BUILDER_PAGES.BUILDERS_SHARE_INPUT_PAGE.ID) {
            return;
          }
          dispatch(setFormValue({ key: 'name', value: adjustText(tempName, LAB_POST_NAME_MAX_LENGTH) }));
          dispatch(setFormValue({ key: 'style', value: tempTechnicalStyle }));
          dispatch(setFormValue({ key: 'technicalTerm', value: tempTechnicalTerm }));
          dispatch(setFormValue({ key: 'comment', value: adjustText(tempComment, LAB_POST_COMMENT_MAX_LENGTH) }));
          dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.BUILDERS_SHARE_CONFIRM_PAGE.ID }));
        },
      }),
    );
  }, [dispatch, tempName, tempComment, tempTechnicalStyle, tempTechnicalTerm]);

  return (
    <Step
      progress={progress}
      backLabel={BUILDERS_SHARE_PROGRESS_TITLE_STEP1}
      nextLabel="次へ"
      loading={loading}
      disabled={disabled}
      onBack={handleBack}
      onNext={handleNext}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>シェアするルールについてご記入ください</div>
        </div>
        {errors && isError && (
          <div className={styles.errorMessageArea}>
            <div className={styles.errorMessage}>{errors}</div>
          </div>
        )}
        <div className={styles.contentContainer}>
          <EditableText
            className={styles.input}
            label="タイトル"
            placeholder="シェアした際に見えるタイトル"
            maxLength={LAB_POST_NAME_MAX_LENGTH}
            width={FORM_WIDTH}
            value={tempName}
            disabled={loading}
            onChange={handleChangeName}
            editOnly
            focusFirstTime
          />
          <EditableSelect
            className={styles.input}
            label="運用スタイル"
            placeholder="お選びください"
            width={FORM_WIDTH}
            value={tempTechnicalStyle}
            disabled={loading}
            options={STYLE_OPTIONS}
            onChange={handleChangeTechnicalStyle}
            editOnly
          />
          <EditableSelect
            className={styles.input}
            label="想定運用期間"
            placeholder="お選びください"
            width={FORM_WIDTH}
            value={tempTechnicalTerm}
            disabled={loading}
            options={TECHNICAL_TERM_LIST}
            onChange={handleChangeTechnicalTerm}
            editOnly
          />
          <EditableTextArea
            className={styles.input}
            label="ビルダーの概要"
            placeholder={BUILDER_OVERVIEW_PLACEHOLDER}
            maxLength={LAB_POST_COMMENT_MAX_LENGTH}
            width={FORM_WIDTH}
            value={tempComment}
            disabled={loading}
            onChange={handleChangeComment}
            editOnly
          />
        </div>
      </div>
    </Step>
  );
});

ShareInputPage.propTypes = {
  progress: PropTypes.node.isRequired,
};
