import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MeterCircle } from './MeterCircle';

// darken 36% colors
export const BackCircle = memo(({ className, size, circleInfo, position }) => {
  const { effectiveRange, circumference } = circleInfo;
  const [strokeDashoffset, strokeDasharray] = useMemo(() => {
    if (position <= effectiveRange) {
      return [-position, `${effectiveRange - position} ${circumference}`];
    }
    return [0, `${effectiveRange} ${circumference}`];
  }, [position, effectiveRange, circumference]);
  return (
    <MeterCircle
      className={className}
      size={size}
      strokeDasharray={strokeDasharray}
      strokeDashoffset={strokeDashoffset}
      circleInfo={circleInfo}
      // figmaのバランスメーターの各色と#2A2A2Aを3:7の割合でmixして算出した色
      color1="#6C2B2B"
      color2="#6C5F20"
      color3="#616450"
      color4="#266445"
    />
  );
});

BackCircle.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  circleInfo: PropTypes.shape({
    r: PropTypes.number.isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    circumference: PropTypes.number.isRequired,
    effectiveRange: PropTypes.number.isRequired,
    strokeWidth: PropTypes.number.isRequired,
  }).isRequired,
};

BackCircle.defaultProps = {
  className: undefined,
};
