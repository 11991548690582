import { get, invokeApi, createAdditionalParams } from './index';

// todo: avoid linter error. will be added new exporting items later
// eslint-disable-next-line
export const getChartData = async ({ instrumentId, resolution, askBid, startTime, endTime, isMobile, serviceId }) => {
  let additionalParams;
  if (!isMobile) {
    additionalParams = await createAdditionalParams();
  }

  return invokeApi(
    {},
    /* eslint-disable max-len */
    `${
      isMobile ? 'public/' : ''
    }${serviceId}/charts?instrumentId=${instrumentId}&type=${resolution}&askBid=${askBid}&startTime=${startTime}&endTime=${endTime}`,
    /* eslint-enable max-len */
    get,
    additionalParams,
  );
};
