import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { UPDATE_MOBILE_CHART } from 'shared-modules/constants/apiConstant';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { isJsonString } from 'shared-modules/services';
import {
  socketConnectionRequest,
  getInstrumentListSuccess,
  createInstrumentsOptions,
  getRatesSuccess,
  getSelectionModalInfoSuccess,
} from '../../redux/actions';
import AutoSelectDetailChart from '../../appWrappers/ModalContainer/components/AutoSelectDetails/components/AutoSelectDetailChart';
import styles from '../MobileChart/mobileChart.module.scss';

function updateChartRelatedGlobalData(dispatch, payload) {
  const { rates, instrumentList, selectionModalInfo, termId } = payload;
  dispatch(getRatesSuccess({ rates }));
  dispatch(getInstrumentListSuccess({ instrumentList }));
  ALL_SERVICES.forEach((service) => {
    dispatch(createInstrumentsOptions({ serviceId: service, options: payload[`${service}InstrumentsOptions`] }));
  });
  dispatch(
    getSelectionModalInfoSuccess({
      selectionModalInfo,
      termId,
    }),
  );
}

const MobileAutoSelectPriceChart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(socketConnectionRequest({ isPublic: true }));
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(UPDATE_MOBILE_CHART);
    }
  }, [dispatch]);

  const [serviceId, changeServiceId] = useState(null);
  const [selectedInstrumentId, changeSelectedInstrumentId] = useState(null);
  const [selectedSide, changeSelectedSide] = useState(null);
  const [selectedStrategyId, changeSelectedStrategyId] = useState(null);
  const [termId, changeTermId] = useState(null);
  const [autoSelectOrderPositions, changeAutoSelectOrderPositions] = useState(null);

  const windowEventListener = useCallback(
    ({ data }) => {
      if (!data || !isJsonString(data)) {
        return;
      }
      const parsedMessage = JSON.parse(data);

      switch (parsedMessage.message) {
        case UPDATE_MOBILE_CHART: {
          const { payload } = parsedMessage;
          changeServiceId(payload.serviceId);
          changeSelectedInstrumentId(payload.selectedInstrumentId);
          changeSelectedSide(payload.selectedSide);
          changeSelectedStrategyId(payload.selectedStrategyId);
          changeTermId(payload.termId);
          changeAutoSelectOrderPositions(payload.autoSelectOrderPositions);

          updateChartRelatedGlobalData(dispatch, payload);

          break;
        }
        default:
          break;
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', windowEventListener);
    document.addEventListener('message', windowEventListener);
    return () => {
      window.removeEventListener('message', windowEventListener);
      document.removeEventListener('message', windowEventListener);
    };
  }, [windowEventListener]);

  const chartContainer = useMemo(() => {
    return (
      <div className={styles.chartWrapper}>
        {selectedStrategyId && selectedInstrumentId && serviceId && (
          <AutoSelectDetailChart
            key={serviceId}
            serviceId={serviceId}
            selectedInstrumentId={selectedInstrumentId}
            selectedSide={selectedSide}
            selectedStrategyId={selectedStrategyId}
            autoSelectOrderPositions={autoSelectOrderPositions}
            termId={termId}
            isMobile
            withoutIndicators
          />
        )}
      </div>
    );
  }, [autoSelectOrderPositions, selectedInstrumentId, selectedStrategyId, selectedSide, serviceId, termId]);

  return chartContainer;
};

export default memo(MobileAutoSelectPriceChart);
