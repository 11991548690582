import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useCheckOnPositionSummaryIsBeingDeleted } from 'shared-modules/services/hooks';
import { CustomCheckbox } from '../../../../components';

export const PositionSummaryCheckbox = memo(({ checked: isChecked, onChange, positionIds, isToggleAll, tableData }) => {
  const checkOnPositionSummaryIsBeingDeleted = useCheckOnPositionSummaryIsBeingDeleted();
  const handleChange = useCallback(
    (checked) => {
      const toggleCheckbox = () => {
        onChange({ target: { checked } });
      };

      if (isToggleAll && !checked) {
        toggleCheckbox();
      } else {
        checkOnPositionSummaryIsBeingDeleted({
          positionIds,
          successCallback: toggleCheckbox,
          tableData,
          isToggleAll,
        });
      }
    },
    [isToggleAll, positionIds, tableData, onChange, checkOnPositionSummaryIsBeingDeleted],
  );
  return <CustomCheckbox isChecked={isChecked} onChange={handleChange} />;
});

PositionSummaryCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  positionIds: PropTypes.arrayOf(PropTypes.string),
  isToggleAll: PropTypes.bool,
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
};

PositionSummaryCheckbox.defaultProps = {
  isToggleAll: false,
  tableData: [],
  positionIds: [],
};
