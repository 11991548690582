import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { EditableInput } from '../EditableInput';
import styles from './editableText.module.scss';

export const EditableText = ({
  label,
  value,
  disabled,
  editable,
  placeholder,
  maxLength,
  width,
  className,
  focusFirstTime,
  editOnly,
  onChange,
  onSwitch,
}) => {
  const editRef = useRef(null);
  const inputRef = useRef(null);
  const focusFirstTimeRef = useRef(focusFirstTime);
  const [numOfChars, setNumOfChars] = useState(value == null ? 0 : value.length);

  useEffect(() => {
    if (focusFirstTimeRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  const handleChange = useCallback(
    (e) => {
      if (disabled) {
        return;
      }
      if (maxLength != null) {
        setNumOfChars(e.target.value.length);
      }
      if (onChange) {
        onChange(e);
      }
    },
    [disabled, maxLength, onChange],
  );

  const handleBlur = useCallback(
    (e) => {
      if (disabled) {
        return;
      }
      if (onSwitch) {
        onSwitch(e);
      }
      if (!editOnly) {
        setTimeout(() => editRef.current?.focus());
      }
    },
    [disabled, editOnly, onSwitch],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (disabled) {
        return;
      }
      if (!editOnly) {
        if (e.key === 'Enter') {
          inputRef.current?.blur();
        }
        if (e.key === 'Tab' || e.key === 'Escape') {
          e.preventDefault();
          inputRef.current?.blur();
        }
      }
    },
    [disabled, editOnly, inputRef],
  );

  return (
    <EditableInput
      ref={editRef}
      inputRef={inputRef}
      label={label}
      value={value}
      disabled={disabled}
      editable={editable}
      editOnly={editOnly}
      width={width}
      className={className}
      textClassName={styles.text}
      onClick={onSwitch}
    >
      <div className={styles.container}>
        <input
          ref={inputRef}
          type="text"
          value={value}
          disabled={disabled}
          style={{ width }}
          className={classNames(styles.input, styles.text)}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
        {maxLength != null && <div className={styles.count}>{`${numOfChars}/${maxLength}文字`}</div>}
      </div>
    </EditableInput>
  );
};

EditableText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  focusFirstTime: PropTypes.bool,
  editOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onSwitch: PropTypes.func,
};

EditableText.defaultProps = {
  label: undefined,
  value: undefined,
  disabled: false,
  editable: undefined,
  placeholder: undefined,
  maxLength: undefined,
  width: 'auto',
  className: undefined,
  focusFirstTime: false,
  editOnly: false,
  onChange: undefined,
  onSwitch: undefined,
};
