import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COUNTER_FIXED_INFO_MESSAGE, MODAL_SIZES, WIDTH } from 'shared-modules/constants';
import useDynamicMultiEditInfo from 'shared-modules/services/hooks/multiEditSelectLogic';
import Modal from '../../../../components/Modal';
import CustomInput from '../../../../components/CustomInput';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import styles from './multiEditSelect.module.scss';
import { Button } from '../../../../components';

const MultiEditSelect = ({ isOpen, closeModal }) => {
  const dynamicInfo = useDynamicMultiEditInfo();

  const onClickDone = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const initValueRef = useRef({});

  useEffect(() => {
    if (!Object.keys(initValueRef.current).length) {
      const initValueObj = {
        initQuantity: dynamicInfo.quantity.get,
        initProfit: dynamicInfo.profit.get,
        initLosscut: dynamicInfo.losscut.get,
        initFollow: dynamicInfo.follow.get,
        initCounter: dynamicInfo.counter.get,
      };
      initValueRef.current = initValueObj;
    }
  }, [
    initValueRef,
    dynamicInfo.quantity.get,
    dynamicInfo.profit.get,
    dynamicInfo.losscut.get,
    dynamicInfo.follow.get,
    dynamicInfo.counter.get,
  ]);

  const isQuantityChange =
    dynamicInfo.quantity.get !== initValueRef.current.initQuantity && !dynamicInfo.quantity.isDisabled;
  const isProfitChange = dynamicInfo.profit.get !== initValueRef.current.initProfit && !dynamicInfo.profit.isDisabled;
  const isLosscutChange =
    dynamicInfo.losscut.get !== initValueRef.current.initLosscut && !dynamicInfo.losscut.isDisabled;
  const isFollowChange = dynamicInfo.follow.get !== initValueRef.current.initFollow && !dynamicInfo.follow.isDisabled;
  const isCounterChange =
    dynamicInfo.counter.get !== initValueRef.current.initCounter && !dynamicInfo.counter.isDisabled;

  useEffect(() => {
    if (isCounterChange) dynamicInfo.counter.validate(dynamicInfo.counter.get, !dynamicInfo.counterFixed.get);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCounterChange, dynamicInfo.counterFixed.get]);

  const counterFixedInfoElement = useMemo(
    () => <div className={styles.infoText}>{COUNTER_FIXED_INFO_MESSAGE}</div>,
    [],
  );

  const modalTitle = '選択一括入力';

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title={modalTitle} isOverlayModal size={MODAL_SIZES.WIDTH_400}>
      <div className={styles.wrapper}>
        <div className={styles.descriptionRow}>
          選択された自動売買注文の注文設定を一括変更します。
          <br />
          一括変更したい設定項目をチェックし、設定値をご入力
          <br />
          ください。
        </div>
        <div className={styles.checkboxDescriptionRow}>設定項目</div>
        <div className={styles.quantityRow}>
          <CustomCheckbox isChecked={dynamicInfo.isQuantity.get} onChange={dynamicInfo.isQuantity.set} />
          <div>
            <div className={styles.columnTitle}>{dynamicInfo.quantity.label}</div>
            <CustomInput
              type="number"
              inputClassName={classNames({ [styles.inputChanged]: isQuantityChange })}
              value={dynamicInfo.quantity.get}
              onChange={dynamicInfo.quantity.set}
              isDisabled={dynamicInfo.quantity.isDisabled}
              name={dynamicInfo.quantity.name}
              errorMessages={dynamicInfo.errorsArray}
              step={dynamicInfo.quantity.step}
              withErrorTooltip
              validateFunction={dynamicInfo.quantity.validate}
            />
          </div>
        </div>

        <div className={styles.profitRow}>
          <CustomCheckbox isChecked={dynamicInfo.isProfit.get} onChange={dynamicInfo.isProfit.set} />
          <div>
            <div className={styles.columnTitle}>{dynamicInfo.profit.label}</div>
            <CustomInput
              type="number"
              inputClassName={classNames({ [styles.inputChanged]: isProfitChange })}
              value={dynamicInfo.profit.get}
              onChange={dynamicInfo.profit.set}
              isDisabled={dynamicInfo.profit.isDisabled}
              name={dynamicInfo.profit.name}
              errorMessages={dynamicInfo.errorsArray}
              step={dynamicInfo.profit.step}
              validateFunction={dynamicInfo.profit.validate}
              withErrorTooltip
            />
          </div>
        </div>

        <div className={styles.losscutRow}>
          <CustomCheckbox isChecked={dynamicInfo.isLosscut.get} onChange={dynamicInfo.isLosscut.set} />
          <div>
            <div className={styles.columnTitle}>{dynamicInfo.losscut.label}</div>
            <CustomInput
              type="number"
              inputClassName={classNames({ [styles.inputChanged]: isLosscutChange })}
              value={dynamicInfo.losscut.get}
              onChange={dynamicInfo.losscut.set}
              isDisabled={dynamicInfo.losscut.isDisabled}
              name={dynamicInfo.losscut.name}
              errorMessages={dynamicInfo.errorsArray}
              validateFunction={dynamicInfo.losscut.validate}
              step={dynamicInfo.losscut.step}
              min={dynamicInfo.losscut.min}
              withErrorTooltip
              validateNegativeValues
            />
          </div>
        </div>

        <div className={styles.followRow}>
          <CustomCheckbox isChecked={dynamicInfo.isFollow.get} onChange={dynamicInfo.isFollow.set} />
          <div>
            <div className={styles.columnTitle}>{dynamicInfo.follow.label}</div>
            <CustomInput
              type="number"
              inputClassName={classNames({ [styles.inputChanged]: isFollowChange })}
              value={dynamicInfo.follow.get}
              onChange={dynamicInfo.follow.set}
              isDisabled={dynamicInfo.follow.isDisabled}
              name={dynamicInfo.follow.name}
              errorMessages={dynamicInfo.errorsArray}
              validateFunction={dynamicInfo.follow.validate}
              step={dynamicInfo.follow.step}
              min={dynamicInfo.follow.min}
              withErrorTooltip
              validateNegativeValues
            />
          </div>
        </div>

        <div className={styles.counterRow}>
          <CustomCheckbox isChecked={dynamicInfo.isCounter.get} onChange={dynamicInfo.isCounter.set} />
          <div>
            <div className={styles.columnTitle}>{dynamicInfo.counter.label}</div>
            <CustomInput
              type="number"
              inputClassName={classNames({ [styles.inputChanged]: isCounterChange })}
              value={dynamicInfo.counter.get}
              onChange={dynamicInfo.counter.set}
              isDisabled={dynamicInfo.counter.isDisabled}
              name={dynamicInfo.counter.name}
              errorMessages={dynamicInfo.errorsArray}
              validateFunction={dynamicInfo.counter.validate}
              step={dynamicInfo.counter.step}
              min={dynamicInfo.counter.min}
              withErrorTooltip
              validateNegativeValues
            />
          </div>
        </div>

        <div className={styles.counterFixedRow}>
          <div>
            <div className={styles.columnTitle}>
              {dynamicInfo.counterFixed.label}
              <CustomTooltip message={counterFixedInfoElement} className={styles.infoWrapper}>
                <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
              </CustomTooltip>
            </div>
            <CustomCheckbox
              isChecked={dynamicInfo.counterFixed.get}
              onChange={dynamicInfo.counterFixed.set}
              isDisabled={dynamicInfo.counterFixed.isDisabled}
            />
          </div>
        </div>

        <div className={styles.buttonRow}>
          <Button
            onClick={dynamicInfo.submit.handler(onClickDone)}
            width={WIDTH.PERCENTAGE_100}
            tabIndex="0"
            loading={dynamicInfo.submit.isLoading}
            disabled={dynamicInfo.submit.isDisabled}
          >
            {dynamicInfo.submit.label}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

MultiEditSelect.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(MultiEditSelect);
