import { memo } from 'react';
import PropTypes from 'prop-types';
import TableHeader from './components/TableHeader';
import styles from './detailTable.module.scss';

const DetailTable = ({ id, serviceId }) => {
  return (
    <div className={styles.wrapper}>
      <TableHeader id={id} serviceId={serviceId} />
    </div>
  );
};

DetailTable.propTypes = {
  id: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
};

export default memo(DetailTable);
