import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LinkButton } from '../LinkButton';
import styles from './backLink.module.scss';

export const BackLink = memo(({ className, disabled, onClick, children }) => {
  return (
    <LinkButton className={className} disabled={disabled} onClick={onClick}>
      <>
        <i className={classNames('material-icons', styles.icon)}>arrow_back_ios</i>
        <div>{children}</div>
      </>
    </LinkButton>
  );
});

BackLink.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

BackLink.defaultProps = {
  className: undefined,
  disabled: false,
  onClick: undefined,
};
