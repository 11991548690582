import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { WIDTH, MODAL_SIZES } from 'shared-modules/constants';
import { useChartMakeRunNowLogic } from 'shared-modules/services/hooks/chartMake';
import { useSummaryInfo } from 'shared-modules/services/hooks';
import { push } from 'shared-modules/redux/actions/routerActions';
import {
  changeServiceIdRequest,
  openBuilderAddToPortfolioFailModal,
  openBuilderAddToPortfolioSuccessModal,
} from '../../../../redux/actions';
import CustomInput from '../../../../components/CustomInput';
import { Button, InputNumber, MarginInfo, Modal } from '../../../../components';
import { AssetDetail } from '../AssetDetail';
import styles from './chartMakeRunNowConfirmation.module.scss';
import { CASH } from '../../../../constants';

const ChartMakeRunNowConfirmation = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();

  const openSuccessModal = useCallback(
    (orderName) => {
      dispatch(openBuilderAddToPortfolioSuccessModal({ orderName }));
    },
    [dispatch],
  );

  const openFailModal = useCallback(
    (orderName) => {
      dispatch(openBuilderAddToPortfolioFailModal({ orderName }));
    },
    [dispatch],
  );

  const runNowLogic = useChartMakeRunNowLogic({
    isModalOpen: isOpen,
    closeModal,
    handleSuccess: openSuccessModal,
    handleFail: openFailModal,
  });

  const {
    receivedMargin,
    effectiveMargin,
    orderableMargin,
    instrumentGroups,
    orderingRequiredMargin,
    positionRequiredMargin,
  } = useSummaryInfo();

  const chartData = useMemo(
    () => ({
      effectiveMargin,
      orderableMargin,
      receivedMargin,
      instrumentGroups,
      orderingRequiredMargin,
      positionRequiredMargin,
    }),
    [
      effectiveMargin,
      orderableMargin,
      receivedMargin,
      instrumentGroups,
      orderingRequiredMargin,
      positionRequiredMargin,
    ],
  );
  chartData.consumedMargin = runNowLogic.requiredMargin.get;

  const serviceId = useSelector((state) => state.auth.serviceId);
  const handleClick = useCallback(() => {
    closeModal();
    dispatch(push(`/${CASH}`));
    dispatch(changeServiceIdRequest({ serviceId }));
  }, [closeModal, dispatch, serviceId]);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title={runNowLogic.modalTitle} size={MODAL_SIZES.WIDTH_512}>
      <div className={styles.row}>{runNowLogic.name.label}</div>
      <CustomInput
        className={styles.row}
        width={WIDTH.PERCENTAGE_100}
        value={runNowLogic.name.get}
        onChange={runNowLogic.name.set}
        name={runNowLogic.name.name}
        validateFunction={runNowLogic.name.validateFunction}
        errorMessages={runNowLogic.errorMessages}
        withErrorTooltip
      />

      <div className={styles.row}>
        <div>{runNowLogic.sets.label}</div>
        <InputNumber
          value={runNowLogic.sets.get}
          onChange={runNowLogic.sets.set}
          name={runNowLogic.sets.name}
          validateFunction={runNowLogic.sets.validateFunction}
          errorMessages={runNowLogic.errorMessages}
          withErrorTooltip
          onlyIntegerAllowed
        />
      </div>

      <div className={styles.row}>{runNowLogic.message}</div>

      <div className={styles.headline}>
        <div className={styles.label}>稼働後のバランスメーター</div>
      </div>

      <AssetDetail
        className={styles.assetDetail}
        meterData={chartData}
        serviceId={serviceId}
        onClick={handleClick}
        hideMarginInfo
      />
      <MarginInfo
        className={styles.marginArea}
        requiredMargin={runNowLogic.requiredMargin}
        fundIndication={runNowLogic.fundIndication}
      />
      <div className={styles.buttonArea}>
        <Button
          loading={runNowLogic.submit.isLoading}
          disabled={runNowLogic.submit.isDisabled}
          onClick={runNowLogic.submit.handler}
        >
          {runNowLogic.submit.label}
        </Button>
      </div>
    </Modal>
  );
};

ChartMakeRunNowConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(ChartMakeRunNowConfirmation);
