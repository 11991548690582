import React, { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './groupButton.module.scss';

const makeCommonClasses = (defaultClassName, selected, isFirst, isLast, disabled, isVisuallyDisabled) =>
  classNames(
    {
      [styles.selected]: selected,
      [styles.first]: isFirst,
      [styles.last]: isLast,
      [styles.disabled]: disabled,
      [styles.isVisuallyDisabled]: isVisuallyDisabled,
    },
    defaultClassName,
  );

const Button = memo(
  ({
    id,
    label,
    defaultClassName,
    selectedClassName,
    selected,
    isFirst,
    isLast,
    disabled,
    isVisuallyDisabled,
    width,
    onChange,
  }) => {
    const handleClick = useCallback(
      (e) => {
        if (disabled) {
          return;
        }
        e.currentTarget.blur();
        onChange(id);
      },
      [id, disabled, onChange],
    );
    const handleKeyDown = useKeyDownHandler(handleClick);
    const commonClasses = makeCommonClasses(defaultClassName, selected, isFirst, isLast, disabled, isVisuallyDisabled);
    let style;
    if (width != null) {
      style = { width };
    }
    return (
      <div style={style} className={classNames(styles.buttonWrapper, commonClasses)}>
        <div
          role="button"
          tabIndex={disabled ? -1 : 0}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          className={classNames(styles.button, commonClasses)}
        >
          <div className={classNames({ [selectedClassName]: selected })}>{label}</div>
        </div>
      </div>
    );
  },
);

Button.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  defaultClassName: PropTypes.string,
  selectedClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isVisuallyDisabled: PropTypes.bool,
  width: PropTypes.number,
};

Button.defaultProps = {
  defaultClassName: undefined,
  selectedClassName: undefined,
  disabled: false,
  isVisuallyDisabled: false,
  width: undefined,
};

export const GroupButton = memo(({ buttons, onChange, activeId, disabled, buttonWidth, showLockIcon }) => {
  const hasDisabled = useMemo(() => {
    return disabled || buttons.some((button) => button.isDisabled || button.isVisuallyDisabled);
  }, [disabled, buttons]);
  const groupButton = (
    <div className={classNames(styles.container, { [styles.disabled]: hasDisabled })}>
      {buttons.map(({ id, label, isDisabled, isVisuallyDisabled, defaultClassName, selectedClassName }, i) => (
        <Button
          id={id}
          key={id}
          defaultClassName={defaultClassName}
          selectedClassName={selectedClassName}
          label={label}
          selected={id === activeId}
          onChange={onChange}
          isFirst={i === 0}
          isLast={i === buttons.length - 1}
          disabled={isDisabled || disabled}
          isVisuallyDisabled={isVisuallyDisabled}
          width={buttonWidth}
        />
      ))}
    </div>
  );
  if (showLockIcon) {
    return (
      <div className={styles.selectorsWithLock}>
        <i className={classNames('material-icons-outlined', styles.lockIcon)}>lock</i>
        {groupButton}
      </div>
    );
  }
  return groupButton;
});

GroupButton.propTypes = {
  disabled: PropTypes.bool,
  buttonWidth: PropTypes.number,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      defaultClassName: PropTypes.string,
      selectedClassName: PropTypes.string,
      isDisabled: PropTypes.bool,
      isVisuallyDisabled: PropTypes.bool,
    }),
  ).isRequired,
  activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  showLockIcon: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

GroupButton.defaultProps = {
  buttonWidth: undefined,
  disabled: false,
  showLockIcon: false,
};
