import { useEffect, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { changeParentTagId, setInitialChildTag } from 'shared-modules/redux/actions/autoSelectActions';
import { TUTORIAL_MODE_SELECT_CHILD_TAG, TUTORIAL_MODE_SELECT_PARENT_TAG } from 'shared-modules/constants/tutorial';
import {
  tutorialOpenSelectPage,
  tutorialOpenStrategiesSuccess,
  tutorialOpenPortfolio,
  tutorialOpenHomePage,
} from '../../../../redux/actions/tutorialActions';
import {
  tutorialNavBarAutoSelect,
  tutorialOrderTable,
  tutorialSideMenu,
  tutorialTradingView,
  tutorialSimulationTab,
  tutorialSimulationChart,
  tutorialRunNow,
  tutorialCountInput,
  tutorialAssetDetails,
  tutorialSubmit,
  tutorialOpenHome,
  tutorialCard,
  tutorialNavBarHome,
  tutorialLogicSettingTab,
  tutorialCommonModalClose,
  tvChartContainer,
  tutorialSimulationInfo,
  tutorialAssetDetailsMargin,
} from '../../../../constants/tutorial/classNames';
import { CENTER, TOP, MIDDLE, BOTTOM, LEFT, RIGHT } from '../../../../constants/tutorial/index';
import { closeTutorialModal } from '../../../../redux/actions';

const containerClassName = 'tutorialHighlightContainer';
const ADD_HIGHLIGHT_DELAY = 300;

const tutorialStepAttributes = {
  3: { targetClassNames: [tutorialNavBarAutoSelect], tagType: 'button' },
  4: {
    targetClassNames: [tutorialSideMenu],
    tagType: 'div',
    previousStepTargets: [tutorialNavBarHome],
    isReadyNext: 'autoSelect',
  },
  5: {
    targetClassNames: [tutorialLogicSettingTab, tvChartContainer],
    tagType: 'div',
    tvClassName: tvChartContainer,
  },
  6: {
    targetClassNames: [tutorialLogicSettingTab, tutorialOrderTable],
    tagType: 'div',
    nextStepTarget: [tutorialSimulationTab],
  },
  7: {
    targetClassNames: [tutorialSimulationTab, tutorialSimulationChart, tutorialSimulationInfo],
    noBorderClassNames: [tutorialSimulationChart], // 穴あけするが、白い線をつけない
    layardClassNames: [tutorialSimulationInfo], // 穴あけが重なっているので、穴あけはしない
    tagType: 'div',
    previousStepTargets: [tutorialLogicSettingTab],
  },
  8: { targetClassNames: [tutorialSimulationChart], tagType: 'div' },
  9: { targetClassNames: [tutorialRunNow], tagType: 'button', elementOnClick: true },
  10: {
    targetClassNames: [tutorialCountInput, tutorialAssetDetailsMargin],
    tagType: 'div',
    previousStepTargets: [tutorialCommonModalClose],
  },
  11: { targetClassNames: [tutorialAssetDetails], tagType: 'div' },
  12: { targetClassNames: [tutorialAssetDetails], tagType: 'div' },
  13: { targetClassNames: [tutorialSubmit], tagType: 'button' },
  14: {
    targetClassNames: [tutorialOpenHome],
    tagType: 'button',
    previousStepTargets: [tutorialCommonModalClose, tutorialRunNow],
  },
  15: {
    targetClassNames: [tutorialCard],
    tagType: 'div',
    previousStepTargets: [tutorialNavBarAutoSelect],
  },
  16: {},
};

// モーダルの表示位置を返す
// key: ステップ番号, value: { horizontal: LEFT or CENTER or RIGHT, vertical: TOP or MIDDLE or BOTTOM }
const modalStyle = {
  1: { horizontal: CENTER, vertical: MIDDLE },
  2: { horizontal: CENTER, vertical: MIDDLE },
  3: { horizontal: CENTER, vertical: MIDDLE },
  4: { horizontal: CENTER, vertical: MIDDLE },
  5: { horizontal: LEFT, vertical: MIDDLE },
  6: { horizontal: CENTER, vertical: MIDDLE },
  7: { horizontal: CENTER, vertical: BOTTOM },
  8: { horizontal: CENTER, vertical: BOTTOM },
  9: { horizontal: CENTER, vertical: MIDDLE },
  10: { horizontal: RIGHT, vertical: MIDDLE },
  11: { horizontal: CENTER, vertical: BOTTOM },
  12: { horizontal: CENTER, vertical: BOTTOM },
  13: { horizontal: CENTER, vertical: MIDDLE },
  14: { horizontal: CENTER, vertical: TOP },
  15: { horizontal: CENTER, vertical: BOTTOM },
  16: { horizontal: CENTER, vertical: undefined, custom: 'step16' }, // ちょうど真ん中にする
};
// OKボタン押下時のaction定義
// key: ステップ番号, value: アクションの配列
const nextStepActions = {
  3: [
    changeParentTagId({ parentTagId: TUTORIAL_MODE_SELECT_PARENT_TAG }),
    setInitialChildTag({ fullName: TUTORIAL_MODE_SELECT_CHILD_TAG }),
    tutorialOpenSelectPage(),
  ],
  13: [tutorialOpenStrategiesSuccess()],
  14: [tutorialOpenPortfolio()],
};

// 戻るボタン押下時のaction定義
// key: ステップ番号, value: アクションの配列
const previousStepActions = {
  4: [tutorialOpenHomePage()],
  15: [tutorialOpenStrategiesSuccess()],
};

// ハイライト表示位置の共通
const stepCommonHighlightPosition = (targetElement) => {
  const rect = targetElement?.getBoundingClientRect();

  return {
    left: rect?.x,
    top: rect?.y,
    width: targetElement?.clientWidth || rect?.width,
    height: targetElement?.clientHeight || rect?.height,
  };
};

// TradingView内部の要素を取得する
const tradingViewInnerElementSelector = (tvClassName) => {
  const [tvElement] = Array.from(document.getElementsByClassName(tutorialTradingView));
  const [content] = Array.from(tvElement?.getElementsByTagName('iframe')).filter((iframe) => iframe.contentDocument);
  const tradingView = content?.contentDocument;
  const [tvInnerElements] = tradingView?.getElementsByClassName(tvClassName);

  return tvInnerElements;
};

const getBackdropElement = () => {
  const [backdrop] = Array.from(document.getElementsByClassName('backdrop'));
  return backdrop;
};
const getBackdropPath = () => {
  const backdrop = getBackdropElement();
  const backdropPath = `M 0 0 v ${backdrop.clientHeight} h ${backdrop.clientWidth} v -${backdrop.clientHeight} z`;
  return backdropPath;
};
const clearBackdropClipPath = () => {
  const backdrop = getBackdropElement();
  backdrop.style.clipPath = ''; // backdropに開けた穴を閉じる
};

const getHighlightPositionStyle = ({ left, top, width, height }) => {
  // 要素が端っこじゃなければ外枠にゆとりを持たせる
  const padding = 14;
  return top !== 0 && left !== 0
    ? {
        left: `${left - padding / 2}px`,
        top: `${top - padding / 2}px`,
        width: `${width + padding}px`,
        height: `${height + padding}px`,
        padding: `${padding}px`,
      }
    : {
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`,
      };
};

// ステップ毎の固有のハイライト表示位置計算
const calcTVHighlightPosition = (tvClassName) => {
  const [tvElement] = Array.from(document.getElementsByClassName(tutorialTradingView));
  const rect = tvElement?.getBoundingClientRect();
  const tvInnerElements = tradingViewInnerElementSelector(tvClassName);
  const tvrect = tvInnerElements?.getBoundingClientRect();

  const left = rect?.x + tvrect?.x + 14; // TradingViewWrapperのはmarginLeftの値
  const top = rect?.y + tvrect?.y;
  const width = tvInnerElements?.clientWidth;
  const height = tvInnerElements?.clientHeight;

  return { left, top, width, height };
};

const useTutorial = (step, incrementStep, decrementStep, isInterrupt) => {
  const isReadyProps = useSelector((state) => state.webTutorial.isReadyProps);
  const isReadyNext = tutorialStepAttributes[step]?.isReadyNext
    ? isReadyProps[tutorialStepAttributes[step].isReadyNext]
    : true;

  const dispatch = useDispatch();

  // ハイライト削除
  const removeHighlight = useCallback((curStep) => {
    const id = `step${curStep}playArea`;
    document.getElementById(id)?.remove();
  }, []);

  const next = useCallback(() => {
    removeHighlight(step);

    // actionリストが定義済みなら順に実行
    batch(() => {
      nextStepActions[step]?.forEach((action) => {
        dispatch(action);
      });
    });

    const attribute = tutorialStepAttributes[step];

    attribute?.nextStepTarget?.forEach((nextTarget) => {
      const [targetElement] = Array.from(document.getElementsByClassName(nextTarget));
      targetElement.click();
    });

    incrementStep();
  }, [dispatch, step, incrementStep, removeHighlight]);

  const previous = useCallback(() => {
    removeHighlight(step);

    batch(() => {
      previousStepActions[step]?.forEach((action) => {
        dispatch(action);
      });
    });

    const attribute = tutorialStepAttributes[step];

    attribute?.previousStepTargets?.forEach((previousTarget) => {
      const [targetElement] = Array.from(document.getElementsByClassName(previousTarget));
      targetElement?.click();
    });

    decrementStep();
  }, [dispatch, step, decrementStep, removeHighlight]);

  const getBackdropClipPath = useCallback(() => {
    // ハイライト用のid
    const id = `step${step}playArea`;
    const attributes = tutorialStepAttributes[step];
    if (!document.getElementById(id) || !attributes || !attributes?.targetClassNames) {
      return '';
    }

    let buttonPath = '';
    const { targetClassNames, tvClassName, layardClassNames } = attributes;
    targetClassNames?.forEach((className) => {
      // 重なっているときは穴あけしない
      const isLayerd = layardClassNames?.includes(className);
      if (isLayerd) {
        return;
      }
      let position;
      if (tvClassName === className) {
        position = calcTVHighlightPosition(tvClassName);
      } else {
        const [targetElement] = Array.from(document.getElementsByClassName(className));
        position = stepCommonHighlightPosition(targetElement);
      }
      const { left, top, width, height } = position;
      buttonPath += `M ${left} ${top}  h ${width} v ${height} h -${width} z `;
    });

    // backdropに穴をあける
    const backdropPath = getBackdropPath();
    return `path('${buttonPath} ${backdropPath}')`;
  }, [step]);

  const resetBackdropClipPath = useCallback(() => {
    const backdrop = getBackdropElement();
    if (backdrop) {
      backdrop.style.clipPath = getBackdropClipPath();
    }
  }, [getBackdropClipPath]);

  const addHighlight = useCallback(
    (container, className, position, tagType, elementOnClick, noBorder) => {
      const [targetElement] = Array.from(document.getElementsByClassName(className));

      const playArea = document.createElement(tagType);
      playArea.className = `${className}highlight`;
      playArea.style.position = 'absolute';

      const { left, top, width, height, padding } = getHighlightPositionStyle(position);
      playArea.style.left = left;
      playArea.style.top = top;
      playArea.style.width = width;
      playArea.style.height = height;
      playArea.style.padding = padding;

      playArea.style.backgroundColor = '#00000000';
      playArea.style.border = 'none';
      playArea.style.display = 'flex';

      if (tagType === 'button') {
        // 不可視のボタンを追加
        playArea.type = 'button';
        playArea.style.zIndex = 1102;

        if (elementOnClick) {
          playArea.onclick = () => {
            targetElement.click();
            next();
          };
        } else {
          playArea.onclick = next;
        }

        // ピンクの外枠追加
        playArea.style.borderColor = '#F45266';
        playArea.style.boxShadow = '0 0 20px 5px #F45266';
      } else {
        // 白の外枠追加
        playArea.style.zIndex = 1101;
        if (!noBorder) {
          playArea.style.borderColor = '#FFFFFF';
          playArea.style.boxShadow = '0 0 20px 2px #FFFFFF';
        }
      }

      if (!noBorder) {
        playArea.style.borderRadius = '14px';
        playArea.style.borderWidth = 3;
        playArea.style.borderStyle = 'solid';
      }

      container.appendChild(playArea);
    },
    [next],
  );

  const addHighlightElement = useCallback(() => {
    // ハイライト用のid
    const id = `step${step}playArea`;
    const attributes = tutorialStepAttributes[step];

    if (document.getElementById(id) || !attributes || !attributes?.targetClassNames) {
      clearBackdropClipPath();
      return;
    }

    const root = document.getElementById('root');
    const container = document.createElement('div');
    container.id = id;
    container.className = containerClassName;

    const { targetClassNames, tagType, elementOnClick, tvClassName } = attributes;
    targetClassNames?.forEach((className) => {
      const noBorder = attributes?.noBorderClassNames?.includes(className); // 枠線はいらない
      let position;
      if (tvClassName === className) {
        position = calcTVHighlightPosition(tvClassName);
        addHighlight(container, tvClassName, position, tagType, elementOnClick, noBorder);
      } else {
        const [targetElement] = Array.from(document.getElementsByClassName(className));
        // 追加要素の基準位置定義
        // ハイライト表示位置に関する固有処理をここて行う
        position = stepCommonHighlightPosition(targetElement);
        addHighlight(container, className, position, tagType, elementOnClick, noBorder);
      }
    });
    root.appendChild(container);
  }, [addHighlight, step]);

  // ステップ数の増減とチュートリアル中断を検知してハイライト要素を設定する
  useEffect(() => {
    if (isInterrupt) {
      removeHighlight(step);
      return;
    }

    setTimeout(() => {
      addHighlightElement();
      resetBackdropClipPath();
    }, ADD_HIGHLIGHT_DELAY);
  }, [isInterrupt, step, addHighlightElement, removeHighlight, resetBackdropClipPath]);

  // ハイライトしたい要素の移動と拡縮を検知してハイライトの形を合わせる
  useEffect(() => {
    const attributes = tutorialStepAttributes[step];

    if (!attributes || !attributes.targetClassNames) return () => {};

    const { tvClassName } = attributes;

    const highlightAdjuster = (className) => {
      const [highlight] = Array.from(document.getElementsByClassName(`${className}highlight`));
      if (!highlight) return;
      let position;
      if (tvClassName === className) {
        position = calcTVHighlightPosition(tvClassName);
      } else {
        const [element] = Array.from(document.getElementsByClassName(className));
        position = stepCommonHighlightPosition(element);
      }
      const { width, height, top, left } = getHighlightPositionStyle(position);
      highlight.style.width = width;
      highlight.style.height = height;
      highlight.style.top = top;
      highlight.style.left = left;
    };

    const listeners = []; // 無名関数を配列にpushすることで正常にremoveEventListenerできる
    // backdropに穴をあける
    listeners.push(() => resetBackdropClipPath());
    // highlightのリサイズ
    attributes?.targetClassNames.forEach((className) => {
      listeners.push(() => highlightAdjuster(className));
    });

    listeners.forEach((l) => {
      window.addEventListener('resize', l);
    });

    return () => {
      listeners.forEach((l) => {
        window.removeEventListener('resize', l);
      });
    };
  }, [step, resetBackdropClipPath]);

  // 画面更新を検知してハイライトを消す
  useEffect(() => {
    const removeElement = () => removeHighlight(step);
    window.addEventListener('beforeunload', removeElement);

    return () => {
      window.removeEventListener('beforeunload', removeElement);
    };
  }, [removeHighlight, step]);

  // 戻るボタンを押した際はチュートリアルを強制終了する
  useEffect(() => {
    const forceExitTutorial = () => {
      dispatch(closeTutorialModal());
      removeHighlight(step);
    };

    window.addEventListener('popstate', forceExitTutorial);

    return () => {
      window.removeEventListener('popstate', forceExitTutorial);
    };
  }, [dispatch, removeHighlight, step]);

  return { next, previous, modalStyle: modalStyle[step], isReadyNext };
};

export default useTutorial;
