import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CFD, ETF, FX } from 'shared-modules/constants';
import styles from './instrumentIcon.module.scss';
import EtfInstrumentIcon from '../EtfInstrumentIcon';
import CfdInstrumentIcon from '../CfdInstrumentIcon';
import CustomFlag from '../CustomFlag';

const InstrumentIcon = ({ size, instrumentId, serviceId, className }) => {
  let content = null;

  if (serviceId === FX) {
    const [firstCountry, secondCountry] = instrumentId.split('/');
    content = (
      <div className={styles.fxIconCanvas}>
        <CustomFlag country={firstCountry} className={styles.flag} />
        <CustomFlag country={secondCountry} className={styles.flag} />
      </div>
    );
  } else if (serviceId === ETF) {
    content = <EtfInstrumentIcon size={size} instrumentId={instrumentId} />;
  } else if (serviceId === CFD) {
    content = <CfdInstrumentIcon size={size} instrumentId={instrumentId} />;
  }

  return <div className={classNames(styles.wrapper, className, styles[size], styles[serviceId])}>{content}</div>;
};

InstrumentIcon.propTypes = {
  size: PropTypes.oneOf(['medium', 'small', 'littleTiny', 'tiny']),
  instrumentId: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

InstrumentIcon.defaultProps = {
  size: 'medium',
  className: '',
};

export default InstrumentIcon;
