import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './itemWrapper.module.scss';

export const ItemWrapper = memo(({ children, disabled, onClick }) => {
  const handleClick = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      onClick(event);
    },
    [disabled, onClick],
  );
  const handleKeyDown = useKeyDownHandler(handleClick);
  return (
    <div className={styles.container} role="button" tabIndex={-1} onClick={handleClick} onKeyDown={handleKeyDown}>
      {children}
    </div>
  );
});

ItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ItemWrapper.defaultProps = {
  disabled: false,
};
