import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import {
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
  KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR,
} from 'shared-modules/constants/manualTrade';
import { KEY_FOR_SELECTED_PORTFOLIO_INSTRUMENTS } from 'shared-modules/constants/portfolio';
import { CLEAR_STORAGE } from 'shared-modules/redux/actionConstants/authConstants';
import {
  resetExecutionsTableMetaInfo,
  resetOrderTableMetaInfo,
  resetPositionTableMetaInfo,
} from 'shared-modules/redux/actions/manualTradeActions';
import Logger from 'shared-modules/services/Logger';

const clearStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    Logger.error(e);
  }
  return Promise.resolve();
};

function* clearStorageHandler() {
  yield call(clearStorageItem, KEY_FOR_SELECTED_PORTFOLIO_INSTRUMENTS);
  yield all(
    ALL_SERVICES.map((serviceId) => [
      KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[serviceId],
      KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY[serviceId],
      KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR[serviceId],
      KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[serviceId],
      KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY[serviceId],
      KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR[serviceId],
      KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[serviceId],
      KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED[serviceId],
      KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY[serviceId],
      KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR[serviceId],
    ])
      .flatMap((keys) => keys)
      .map((key) => call(clearStorageItem, key)),
  );
  // ここで照会画面の絞り込み条件をリセットしないと、ログイン後 reload されるまで何故か絞り込み条件が復元されてしまう。。。
  yield all(
    ALL_SERVICES.flatMap((serviceId) => [
      put(resetExecutionsTableMetaInfo({ serviceId })),
      put(resetPositionTableMetaInfo({ serviceId })),
      put(resetOrderTableMetaInfo({ serviceId })),
    ]),
  );
}

export function* authWebSagaHandler() {
  yield takeEvery(CLEAR_STORAGE, clearStorageHandler);
}
