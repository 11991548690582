import {
  GET_SELECTION_TAGS_REQUEST,
  GET_SELECTION_TAGS_REQUEST_START_LOADING,
  GET_SELECTION_TAGS_REQUEST_END_LOADING,
  GET_SELECTION_TAGS_SUCCESS,
  GET_SELECTIONS_BY_PARAMS_REQUEST,
  GET_SELECTIONS_BY_PARAMS_REQUEST_START_LOADING,
  GET_SELECTIONS_BY_PARAMS_REQUEST_END_LOADING,
  GET_SELECTIONS_BY_PARAMS_SUCCESS,
  GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_START_LOADING,
  GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_STOP_LOADING,
  GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_REQUEST,
  GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_SUCCESS,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_REQUEST_START_LOADING,
  ADD_TO_CART_REQUEST_END_LOADING,
  GET_SELECTION_MODAL_INFO_REQUEST,
  GET_SELECTION_MODAL_INFO_REQUEST_START_LOADING,
  GET_SELECTION_MODAL_INFO_REQUEST_STOP_LOADING,
  GET_SELECTION_MODAL_INFO_SUCCESS,
  CLEAR_SELECTION_MODAL_INFO,
  CREATE_SELECTION_AP_GROUP_REQUEST,
  CREATE_SELECTION_AP_GROUP_REQUEST_START_LOADING,
  CREATE_SELECTION_AP_GROUP_REQUEST_END_LOADING,
  CHANGE_SELECTED_INSTRUMENT_ID,
  CHANGE_SELECTED_TERM_ID,
  GET_SELECTION_MODAL_TERM_INFO_REQUEST_START_LOADING,
  GET_SELECTION_MODAL_TERM_INFO_REQUEST_STOP_LOADING,
  SET_FILTER_KEYWORD,
  REMOVE_FILTER_KEYWORD,
  SAVE_FILTER_CONDITION,
  CLEAR_FILTER_CONDITION,
  CHANGE_PARENT_TAG_ID,
  SET_INITIAL_TAG,
  REMOVE_INITAL_TAG,
  SAVE_SORT_ORDER,
  CLEAR_SORT_ORDER,
  SAVE_SEARCH_AUTO_SELECT_DATA,
  CLEAR_SEARCH_AUTO_SELECT_DATA,
  NAVIGATE_TO_RECOMMEND_SELECT_START,
  NAVIGATE_TO_RECOMMEND_SELECT_END,
} from '../actionConstants/autoSelectConstants';

export const getSelectionTagsRequestStartLoading = () => ({ type: GET_SELECTION_TAGS_REQUEST_START_LOADING });
export const getSelectionTagsRequestEndLoading = () => ({ type: GET_SELECTION_TAGS_REQUEST_END_LOADING });
export const getSelectionTagsRequest = () => ({ type: GET_SELECTION_TAGS_REQUEST });
export const getSelectionTagsSuccess = ({ selectionTagsData }) => ({
  type: GET_SELECTION_TAGS_SUCCESS,
  payload: { selectionTagsData },
});

export const getSelectionsByParamsRequestStartLoading = () => ({
  type: GET_SELECTIONS_BY_PARAMS_REQUEST_START_LOADING,
});
export const getSelectionsByParamsRequestEndLoading = () => ({
  type: GET_SELECTIONS_BY_PARAMS_REQUEST_END_LOADING,
});
export const getSelectionsByParamsRequest = ({ tagId }) => ({
  type: GET_SELECTIONS_BY_PARAMS_REQUEST,
  payload: { tagId },
});
export const getSelectionsByParamsSuccess = ({ selectionsData }) => ({
  type: GET_SELECTIONS_BY_PARAMS_SUCCESS,
  payload: { selectionsData },
});

export const getSelectionForValidatingAddToCartStartLoading = () => ({
  type: GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_START_LOADING,
});
export const getSelectionForValidatingAddToCartStopLoading = () => ({
  type: GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_STOP_LOADING,
});
export const getSelectionForValidatingAddToCartRequest = ({ selectionId, selectionVersion }) => ({
  type: GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_REQUEST,
  payload: { selectionId, selectionVersion },
});
export const getSelectionForValidatingAddToCartSuccess = ({ strategyList }) => ({
  type: GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_SUCCESS,
  payload: { strategyList },
});

export const addToCartRequest = (payload) => ({
  type: ADD_TO_CART_REQUEST,
  payload,
});
export const addToCartRequestStartLoading = ({ id }) => ({ type: ADD_TO_CART_REQUEST_START_LOADING, payload: { id } });
export const addToCartRequestEndLoading = () => ({ type: ADD_TO_CART_REQUEST_END_LOADING });

export const getSelectionModalInfoRequestStartLoading = () => ({
  type: GET_SELECTION_MODAL_INFO_REQUEST_START_LOADING,
});
export const getSelectionModalInfoRequestStopLoading = () => ({ type: GET_SELECTION_MODAL_INFO_REQUEST_STOP_LOADING });

export const getSelectionModalTermInfoRequestStartLoading = () => ({
  type: GET_SELECTION_MODAL_TERM_INFO_REQUEST_START_LOADING,
});
export const getSelectionModalTermInfoRequestEndLoading = () => ({
  type: GET_SELECTION_MODAL_TERM_INFO_REQUEST_STOP_LOADING,
});
export const getSelectionModalInfoRequest = ({ selectionId, selectionVersion, termId, termIdLoader }) => ({
  type: GET_SELECTION_MODAL_INFO_REQUEST,
  payload: { selectionId, selectionVersion, termId, termIdLoader },
});
export const getSelectionModalInfoSuccess = ({ selectionModalInfo, termId }) => ({
  type: GET_SELECTION_MODAL_INFO_SUCCESS,
  payload: { selectionModalInfo, termId },
});

export const clearSelectionModalInfo = () => ({ type: CLEAR_SELECTION_MODAL_INFO });

export const createSelectionApGroupRequest = (payload) => ({
  type: CREATE_SELECTION_AP_GROUP_REQUEST,
  payload,
});
export const createSelectionApGroupRequestStartLoading = () => ({
  type: CREATE_SELECTION_AP_GROUP_REQUEST_START_LOADING,
});
export const createSelectionApGroupRequestEndLoading = () => ({ type: CREATE_SELECTION_AP_GROUP_REQUEST_END_LOADING });

export const changeSelectedInstrumentIdAutoTrade = ({ serviceId, instrumentId, strategyId }) => ({
  type: CHANGE_SELECTED_INSTRUMENT_ID,
  payload: { serviceId, instrumentId, strategyId },
});

export const changeSelectedTermIdAutoTrade = ({ termId }) => ({ type: CHANGE_SELECTED_TERM_ID, payload: { termId } });

export const setFilterInitKeyword = ({ keyword }) => ({ type: SET_FILTER_KEYWORD, payload: { keyword } });
export const removeFilterInitKeyword = () => ({ type: REMOVE_FILTER_KEYWORD });

export const setInitialChildTag = ({ mode, fullName }) => ({
  type: SET_INITIAL_TAG,
  payload: { mode, fullName },
});
export const removeInitialChildTag = () => ({ type: REMOVE_INITAL_TAG });

export const saveAutoSelectFilterCondition = ({ filterCondition }) => ({
  type: SAVE_FILTER_CONDITION,
  payload: { filterCondition },
});
export const clearAutoSelectFilterCondition = () => ({ type: CLEAR_FILTER_CONDITION });
export const saveAutoSelectSortOrder = ({ sortOrder }) => ({
  type: SAVE_SORT_ORDER,
  payload: { sortOrder },
});
export const clearAutoSelectSortOrder = () => ({ type: CLEAR_SORT_ORDER });

export const changeParentTagId = ({ parentTagId }) => ({ type: CHANGE_PARENT_TAG_ID, payload: { parentTagId } });

export const saveSearchAutoSelectData = ({ search }) => ({
  type: SAVE_SEARCH_AUTO_SELECT_DATA,
  payload: { search },
});
export const clearSearchAutoSelectData = () => ({ type: CLEAR_SEARCH_AUTO_SELECT_DATA });

export const navigateToRecommendSelectionStart = ({ parentTagId, childrenTagId, selectionInfo }) => ({
  type: NAVIGATE_TO_RECOMMEND_SELECT_START,
  payload: {
    parentTagId,
    childrenTagId,
    selectionInfo,
  },
});
export const navigateToRecommendSelectionEnd = () => ({ type: NAVIGATE_TO_RECOMMEND_SELECT_END });
