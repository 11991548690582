export const GET_MARGIN_PERIODICALLY = 'PORTFOLIO/GET_MARGIN_PERIODICALLY';
export const GET_MARGIN_REQUEST_IMMEDIATELY = 'PORTFOLIO/GET_MARGIN_REQUEST_IMMEDIATELY';
export const GET_MARGIN_REQUEST = 'PORTFOLIO/GET_MARGIN_REQUEST';
export const GET_MARGIN_SUCCESS = 'PORTFOLIO/GET_MARGIN_SUCCESS';

export const GET_MARGIN_REQUEST_START_LOADING = 'PORTFOLIO/GET_MARGIN_REQUEST_START_LOADING';
export const GET_MARGIN_REQUEST_END_LOADING = 'PORTFOLIO/GET_MARGIN_REQUEST_END_LOADING';

export const UPDATE_MARGIN_BY_SOCKET = 'PORTFOLIO/UPDATE_MARGIN_BY_SOCKET';

export const GET_AP_GROUPS_REQUEST = 'PORTFOLIO/GET_AP_GROUPS_REQUEST';
export const GET_AP_GROUPS_SUCCESS = 'PORTFOLIO/GET_AP_GROUPS_SUCCESS';

export const REMOVE_AP_GROUP = 'PORTFOLIO/REMOVE_AP_GROUP';

export const GET_AP_GROUPS_REQUEST_START_LOADING = 'PORTFOLIO/GET_AP_GROUPS_REQUEST_START_LOADING';
export const GET_AP_GROUPS_REQUEST_END_LOADING = 'PORTFOLIO/GET_AP_GROUPS_REQUEST_END_LOADING';

export const GET_AP_GROUP_BY_ID_REQUEST = 'PORTFOLIO/GET_AP_GROUP_BY_ID_REQUEST';
export const GET_AP_GROUP_BY_ID_REQUEST_START_LOADING = 'PORTFOLIO/GET_AP_GROUP_BY_ID_REQUEST_START_LOADING';
export const GET_AP_GROUP_BY_ID_REQUEST_END_LOADING = 'PORTFOLIO/GET_AP_GROUP_BY_ID_REQUEST_END_LOADING';
export const GET_AP_GROUP_BY_ID_SUCCESS = 'PORTFOLIO/GET_AP_GROUP_BY_ID_SUCCESS';
export const GET_AP_GROUP_BY_ID_SUCCESS_DO_NOT_UPDATE_SELECTED_DATA =
  'PORTFOLIO/GET_AP_GROUP_BY_ID_SUCCESS_DO_NOT_UPDATE_SELECTED_DATA';
export const CLEAR_AP_GROUP_BY_SERVICE = 'PORTFOLIO/CLEAR_AP_GROUP_BY_SERVICE';

export const GET_AP_GROUP_BY_ID_MULTI_REQUEST = 'PORTFOLIO/GET_AP_GROUP_BY_ID_MULTI_REQUEST';

export const UPDATE_AP_GROUP_REQUEST = 'PORTFOLIO/UPDATE_AP_GROUP_REQUEST';
export const UPDATE_AP_GROUP_REQUEST_START_LOADING = 'PORTFOLIO/UPDATE_AP_GROUP_REQUEST_START_LOADING';
export const UPDATE_AP_GROUP_REQUEST_END_LOADING = 'PORTFOLIO/UPDATE_AP_GROUP_REQUEST_END_LOADING';

export const DELETE_AP_GROUP_REQUEST = 'PORTFOLIO/DELETE_AP_GROUP_REQUEST';
export const DELETE_AP_GROUP_REQUEST_START_LOADING = 'PORTFOLIO/DELETE_AP_GROUP_REQUEST_START_LOADING';
export const DELETE_AP_GROUP_REQUEST_END_LOADING = 'PORTFOLIO/DELETE_AP_GROUP_REQUEST_END_LOADING';

export const CHANGE_AP_GROUP_ITEM_STATUS = 'PORTFOLIO/CHANGE_AP_GROUP_ITEM_STATUS_REQUEST';
export const CHANGE_AP_GROUP_ITEM_REQUEST = 'PORTFOLIO/CHANGE_AP_GROUP_ITEM_REQUEST';
export const CHANGE_AP_GROUP_ITEM_REQUEST_START_LOADING = 'PORTFOLIO/CHANGE_AP_GROUP_ITEM_REQUEST_START_LOADING';
export const CHANGE_AP_GROUP_ITEM_REQUEST_END_LOADING = 'PORTFOLIO/CHANGE_AP_GROUP_ITEM_REQUEST_END_LOADING';
export const CHANGE_SELECTED_AP_GROUP_STATUS = 'PORTFOLIO/CHANGE_SELECTED_AP_GROUP_STATUS';

export const TOGGLE_CHANGE_AP_GROUP_ABILITY = 'PORTFOLIO/TOGGLE_CHANGE_AP_GROUP_ABILITY';

export const DELETE_AP_GROUP_ITEM_REQUEST = 'PORTFOLIO/DELETE_AP_GROUP_ITEM_REQUEST';
export const DELETE_AP_GROUP_ITEM_REQUEST_START_LOADING = 'PORTFOLIO/DELETE_AP_GROUP_ITEM_REQUEST_START_LOADING';
export const DELETE_AP_GROUP_ITEM_REQUEST_END_LOADING = 'PORTFOLIO/DELETE_AP_GROUP_ITEM_REQUEST_END_LOADING';

export const CLEAR_SELECTED_AP_GROUP_DATA = 'PORTFOLIO/CLEAR_SELECTED_AP_GROUP_DATA';
export const CLEAR_AP_GROUP_DATA = 'PORTFOLIO/CLEAR_AP_GROUP_DATA';
export const CLEAR_MANUAL_POSITIONS_DATA = 'PORTFOLIO/CLEAR_MANUAL_POSITIONS_DATA';

export const GET_EXECUTIONS_INFO_REQUEST = 'PORTFOLIO/GET_EXECUTIONS_INFO_REQUEST';
export const GET_EXECUTIONS_INFO_SUCCESS = 'PORTFOLIO/GET_EXECUTIONS_INFO_SUCCESS';
export const GET_EXECUTIONS_INFO_REQUEST_START_LOADING = 'PORTFOLIO/GET_EXECUTIONS_INFO_REQUEST_START_LOADING';
export const GET_EXECUTIONS_INFO_REQUEST_END_LOADING = 'PORTFOLIO/GET_EXECUTIONS_INFO_REQUEST_END_LOADING';
export const SET_EXECUTIONS_SUMMARY_INFO_FOR_TUTORIAL = 'PORTFOLIO/SET_EXECUTIONS_SUMMARY_INFO_FOR_TUTORIAL';

export const GET_POSITIONS_INFO_SUCCESS = 'PORTFOLIO/GET_POSITIONS_INFO_SUCCESS';

export const GET_POSITIONS_INFO_REQUEST = 'PORTFOLIO/GET_POSITIONS_INFO_REQUEST';
export const GET_POSITIONS_INFO_REQUEST_START_LOADING = 'PORTFOLIO/GET_POSITIONS_INFO_REQUEST_START_LOADING';
export const GET_POSITIONS_INFO_REQUEST_END_LOADING = 'PORTFOLIO/GET_POSITIONS_INFO_REQUEST_END_LOADING';

export const CHANGE_SELECTED_EXECUTIONS_META_INFO = 'PORTFOLIO/CHANGE_SELECTED_EXECUTIONS_META_INFO';
export const RESET_SELECTED_EXECUTIONS_META_INFO = 'PORTFOLIO/RESET_SELECTED_EXECUTIONS_META_INFO';
export const RESET_SELECTED_EXECUTIONS_META_INFO_DATE = 'PORTFOLIO/RESET_SELECTED_EXECUTIONS_META_INFO_DATE';
export const RESET_SELECTED_EXECUTIONS_META_INFO_PARTIAL = 'PORTFOLIO/RESET_SELECTED_EXECUTIONS_META_INFO_PARTIAL';

export const GET_PL_EXECUTIONS_INFO_REQUEST = 'PORTFOLIO/GET_PL_EXECUTIONS_INFO_REQUEST';
export const GET_PL_EXECUTIONS_INFO_REQUEST_START_LOADING = 'PORTFOLIO/GET_PL_EXECUTIONS_INFO_REQUEST_START_LOADING';
export const GET_PL_EXECUTIONS_INFO_REQUEST_END_LOADING = 'PORTFOLIO/GET_PL_EXECUTIONS_INFO_REQUEST_END_LOADING';
export const GET_PL_EXECUTIONS_INFO_SUCCESS = 'PORTFOLIO/GET_PL_EXECUTIONS_INFO_SUCCESS';

export const SET_LIST_SETTINGS = 'PORTFOLIO/SET_LIST_SETTINGS';

export const CHANGE_CARD_DATA = 'PORTFOLIO/CHANGE_CARD_DATA';
export const RESET_CARD_DATA = 'PORTFOLIO/RESET_CARD_DATA';

export const RESET_MARGIN_DATA = 'PORTFOLIO/RESET_MARGIN_DATA';

export const DELETE_ALL_MANUAL_TRADE_POSITIONS_REQUEST = 'PORTFOLIO/DELETE_ALL_MANUAL_TRADE_POSITIONS_REQUEST';

export const CHANGE_SELECTED_AP_GROUP_META_INFO = 'PORTFOLIO/CHANGE_SELECTED_AP_GROUP_META_INFO';
export const RESET_SELECTED_AP_GROUP_META_INFO = 'PORTFOLIO/RESET_SELECTED_AP_GROUP_META_INFO';

export const CHANGE_SELECTED_POSITIONS_META_INFO = 'PORTFOLIO/CHANGE_SELECTED_POSITIONS_META_INFO';
export const RESET_SELECTED_POSITIONS_META_INFO = 'PORTFOLIO/RESET_SELECTED_POSITIONS_META_INFO';

export const GET_PORTFOLIO_DATA_REQUEST = 'PORTFOLIO/GET_PORTFOLIO_DATA_REQUEST';
export const GET_PORTFOLIO_DATA_REQUEST_START_LOADING = 'PORTFOLIO/GET_PORTFOLIO_DATA_REQUEST_START_LOADING';
export const GET_PORTFOLIO_DATA_REQUEST_END_LOADING = 'PORTFOLIO/GET_PORTFOLIO_DATA_REQUEST_END_LOADING';
