import { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCheckOnNegativePriceWhenSimulate,
  useGetBuilderOrderRequestData,
} from 'shared-modules/services/hooks/builder';
import { useComprehensiveEvaluationTargetTermIds } from 'shared-modules/hooks/comprehensiveEvaluation';
import { Button } from '../../../../../components';
import {
  changeBuilderOrderEditStatus,
  getBuilderSimulationDataRequest,
  getSilentMultiBuilderSimulationDataRequest,
  openBuilderAddToCartModal,
  openBuilderAddToPortfolioModal,
} from '../../../../../redux/actions';
import styles from './footer.module.scss';

export const Footer = memo(() => {
  const dispatch = useDispatch();
  const allowOrderEdit = useSelector((state) => state.builder.allowOrderEdit);
  const logicGroupsList = useSelector((state) => state.builder.logicGroupsList);
  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);
  const simulationDataIsLoading = useSelector((state) => state.builder.simulationDataIsLoading);

  const getOrderRequestData = useGetBuilderOrderRequestData();

  const checkOnNegativePrice = useCheckOnNegativePriceWhenSimulate();

  const [isOrderSetting, setIsOrderSetting] = useState(true);

  const changeEditMode = useCallback(
    () => dispatch(changeBuilderOrderEditStatus({ allowOrderEdit: !allowOrderEdit })),
    [dispatch, allowOrderEdit],
  );

  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const termIdsForAssessment = useComprehensiveEvaluationTargetTermIds({ excludeTermId: defaultSelectionTermId });

  const getSimulationData = useCallback(() => {
    const successCallback = () => {
      const orderRequestData = getOrderRequestData();

      dispatch(
        getSilentMultiBuilderSimulationDataRequest({
          orderRequestData,
          termIdList: termIdsForAssessment,
        }),
      );
      dispatch(
        getBuilderSimulationDataRequest({ orderRequestData, callback: changeEditMode, termId: defaultSelectionTermId }),
      );
    };

    checkOnNegativePrice(successCallback);
  }, [
    changeEditMode,
    checkOnNegativePrice,
    dispatch,
    getOrderRequestData,
    termIdsForAssessment,
    defaultSelectionTermId,
  ]);

  const onAddToCartClick = useCallback(() => {
    const successCallback = () => {
      dispatch(openBuilderAddToCartModal(isOrderSetting));
    };
    checkOnNegativePrice(successCallback);
  }, [dispatch, checkOnNegativePrice, isOrderSetting]);

  const onRunNowClick = useCallback(() => {
    const successCallback = () => {
      dispatch(openBuilderAddToPortfolioModal(isOrderSetting));
    };
    checkOnNegativePrice(successCallback);
  }, [dispatch, checkOnNegativePrice, isOrderSetting]);

  const isDisabled = useMemo(
    () => !logicGroupsList.length && !orderSettingsList.length,
    [logicGroupsList, orderSettingsList],
  );

  useEffect(() => {
    if (isDisabled) {
      dispatch(changeBuilderOrderEditStatus({ allowOrderEdit: true }));
    }
  }, [isDisabled, dispatch]);

  return (
    <div className={styles.container}>
      {allowOrderEdit ? (
        <div className={styles.buttonGroup}>
          <Button
            disabled={isDisabled}
            loading={simulationDataIsLoading}
            tabIndex="0"
            onClick={() => {
              setIsOrderSetting(false);
              getSimulationData();
            }}
          >
            シミュレーションを見る
          </Button>
          <Button disabled={isDisabled} width={200} onClick={onAddToCartClick} tabIndex="0" secondary>
            カートに追加
          </Button>
          <Button disabled={isDisabled} width={200} onClick={onRunNowClick} tabIndex="0">
            今すぐ稼働
          </Button>
        </div>
      ) : (
        <div className={styles.buttonGroup}>
          <Button
            width={240}
            onClick={() => {
              setIsOrderSetting(true);
              changeEditMode();
            }}
            tabIndex="0"
            secondary
          >
            プライスチャートに戻る
          </Button>

          <Button width={200} onClick={onAddToCartClick} tabIndex="0" secondary>
            カートに追加
          </Button>

          <Button width={200} onClick={onRunNowClick} tabIndex="0">
            今すぐ稼働
          </Button>
        </div>
      )}
    </div>
  );
});
