import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  COLORS,
  monthOptions,
  monthSelectorWidth,
  searchButtonWidth,
  yearSelectorWidth,
} from 'shared-modules/constants';
import { useReportMonthlySettings } from 'shared-modules/services/hooks';
import CustomSelect from '../../../../../../components/CustomSelect';
import CustomButton from '../../../../../../components/CustomButton';
import styles from './reportsDetailsMonthOptions.module.scss';

const ReportsDetailsMonthOptions = ({ serviceId, searchReports }) => {
  const { yearOptions, startYear, endYear, startMonth, endMonth, submit } = useReportMonthlySettings({
    serviceId,
    searchReports,
  });

  return (
    <>
      <CustomSelect
        options={yearOptions}
        selectItemId={startYear.get}
        onChange={startYear.set}
        disabledValues={startYear.disabledValues}
        width={yearSelectorWidth}
        isLighter
      />

      <CustomSelect
        options={monthOptions}
        selectItemId={startMonth.get}
        onChange={startMonth.set}
        disabledValues={startMonth.disabledValues}
        className={styles.formatItem}
        width={monthSelectorWidth}
        isLighter
      />

      <div className={styles.dataSeparator}>〜</div>

      <CustomSelect
        options={yearOptions}
        selectItemId={endYear.get}
        onChange={endYear.set}
        disabledValues={endYear.disabledValues}
        className={styles.formatItem}
        width={yearSelectorWidth}
        isLighter
      />

      <CustomSelect
        options={monthOptions}
        selectItemId={endMonth.get}
        onChange={endMonth.set}
        disabledValues={endMonth.disabledValues}
        className={styles.formatItem}
        width={monthSelectorWidth}
        isLighter
      />

      <CustomButton
        color={COLORS.WHITE}
        width={searchButtonWidth}
        className={styles.searchButton}
        onClick={submit.handler}
        isLoading={submit.isLoading}
      >
        {submit.label}
      </CustomButton>
    </>
  );
};

ReportsDetailsMonthOptions.propTypes = {
  serviceId: PropTypes.string.isRequired,
  searchReports: PropTypes.func.isRequired,
};

export default memo(ReportsDetailsMonthOptions);
