export const START_MESSAGE_LISTENER = 'MESSAGE/START_MESSAGE_LISTENER';
export const GET_UNREAD_COUNT_REQUEST = 'MESSAGE/GET_UNREAD_COUNT_REQUEST';
export const GET_UNREAD_COUNT_SUCCESS = 'MESSAGE/GET_UNREAD_COUNT_SUCCESS';
export const GET_MESSAGES_REQUEST = 'MESSAGE/GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'MESSAGE/GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_REQUEST_START_LOADING = 'MESSAGE/GET_MESSAGES_REQUEST_START_LOADING';
export const GET_MESSAGES_REQUEST_STOP_LOADING = 'MESSAGE/GET_MESSAGES_REQUEST_STOP_LOADING';
export const MARK_AS_READ_REQUEST = 'MESSAGE/MARK_AS_READ_REQUEST';
export const REFRESH_MESSAGES_REQUEST = 'MESSAGE/REFRESH_MESSAGES_REQUEST';
export const REFRESH_MESSAGES_SUCCESS = 'MESSAGE/REFRESH_MESSAGES_SUCCESS';
export const CLEAR_MESSAGES = 'MESSAGE/CLEAR_MESSAGES';
export const START_AUTO_REFRESH_MESSAGE = 'MESSAGE/START_AUTO_REFRESH_MESSAGES';
export const STOP_AUTO_REFRESH_MESSAGE = 'MESSAGE/STOP_AUTO_REFRESH_MESSAGE';
export const SET_CURRENT_TAB = 'MESSAGE/SET_CURRENT_TAB';
export const CHECK_INITIAL_DEPOSIT_REQUEST = 'MESSAGE/CHECK_INITIAL_DEPOSIT_REQUEST';
export const CHECK_INITIAL_DEPOSIT_SUCCESS = 'MESSAGE/CHECK_INITIAL_DEPOSIT_SUCCESS';
export const GET_RANGE_OUTS_REQUEST = 'MESSAGE/GET_RANGE_OUTS_REQUEST';
export const GET_RANGE_OUTS_SUCCESS = 'MESSAGE/GET_RANGE_OUTS_SUCCESS';
export const OPEN_MESSAGE_MODAL = 'MESSAGE/OPEN_MESSAGE_MODAL';
export const CLOSE_MESSAGE_MODAL = 'MESSAGE/CLOSE_MESSAGE_MODAL';
export const RESET_MESSAGE_MODAL = 'MESSAGE/RESET_MESSAGE_MODAL';
export const OPEN_MESSAGE_MODAL_REQUEST = 'MESSAGE/OPEN_MESSAGE_MODAL_REQUEST';
export const SET_DEFAULT_OPENED_MESSAGE_ID = 'MESSAGE/SET_DEFAULT_OPENED_MESSAGE_ID';
