import { memo, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import {
  AP_GROUP_ORDER,
  AP_GROUP_SOURCES,
  AP_GROUP_STATUSES,
  BUY_SELL_COLOR,
  BUY_SELL_VALUE,
  CARD_STATUS_IDS,
  CHART_INDICATOR_MAIN,
  CHART_RESOLUTION_LABEL,
  COLORS,
  MIXED,
  MODAL_SIZES,
  TECH_STATUS,
} from 'shared-modules/constants';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { BUILDER_EXCHANGE_TYPES, BUILDER_ORDER_TYPES } from 'shared-modules/constants/builder';
import { useServiceName } from 'shared-modules/hooks';
import { date3MonthBefore, getServiceQuantityUnit } from 'shared-modules/services';
import {
  deleteTechnicalBuilderDataRequest,
  getTechnicalBuilderDataRequest,
  updateTechnicalBuilderStatusRequest,
} from 'shared-modules/redux/tech';
import { useTechConfigChangeLogic, useTechNameSubmitLogic } from 'shared-modules/services/hooks/techLogic';
import { makeTechDeleteAbility } from 'shared-modules/services/hooks';
import { getPipsLabelWithParentheses } from 'shared-modules/utils';
import {
  closeConfirmationModal,
  openConfirmationModal,
  closePortfolioAutoTradeDetailModal,
  openPortfolioAutoTradeDetailModal,
  getBuilderChartDataRequest,
  resetBuilderChartData,
  changeBuilderActiveCurrency,
  resetBuilderActiveCurrency,
  changeBuilderOrderType,
  resetBuilderOrderType,
  changeBuilderExchangeType,
  resetBuilderExchangeType,
  openInputConfirmationModal,
  sendNotificationError,
  clearSelectedApGroupData,
  getApGroupByIdRequest,
  getApGroupByIdMultiRequest,
  getPlExecutionsInfoRequest,
  changeSelectedApGroupStatus,
  getApGroupRequest,
} from '../../../../redux/actions';
import DetailTable from '../../../../appWrappers/ModalContainer/components/PortfolioAutoTradeDetail/components/DetailTable';
import Card from '../../../../appWrappers/ModalContainer/components/PortfolioAutoTradeDetail/components/Card';
import TradingViewChart from '../../../../appWrappers/ModalContainer/components/PortfolioAutoTradeDetail/components/TradingViewChart';
import DetailChart from '../../../../appWrappers/ModalContainer/components/PortfolioAutoTradeDetail/components/DetailChart';
import { Button, InputNumber, Switch, Tabs } from '../../../../components';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import CustomButton from '../../../../components/CustomButton';
import PortfolioCard from '../PortfolioCard/index';
import OptionsDropdown from '../../../../components/OptionsDropdown';
import styles from './technicalBuilderCard.module.scss';
import { ChartDescription, DescriptionType } from '../../../AutoSelect/components/ChartDescription';

/**
 * 文言表示行コンポーネント
 */
const Row = ({ label, value }) => (
  <div className={styles.row}>
    <span className={styles.label}>{label}</span>
    <span className={styles.value}>{value || '-'}</span>
  </div>
);

Row.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Row.defaultProps = {
  value: null,
};

/**
 * 入力行コンポーネント
 */
const InputRow = ({
  label,
  checkValue,
  checkOnChange,
  value,
  onChange,
  disabled,
  step,
  min = 0,
  name,
  errorMessages,
  withErrorTooltip,
}) => (
  <div className={styles.inputRow}>
    <div>
      <CustomCheckbox isChecked={checkValue} onChange={checkOnChange} label={label} />
    </div>
    <InputNumber
      value={value}
      onChange={onChange}
      disabled={disabled}
      step={step}
      min={min}
      name={name}
      errorMessages={errorMessages}
      withErrorTooltip={withErrorTooltip}
      validateNegativeValues
    />
  </div>
);

InputRow.propTypes = {
  label: PropTypes.string.isRequired,
  checkValue: PropTypes.bool.isRequired,
  checkOnChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  step: PropTypes.number.isRequired,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  withErrorTooltip: PropTypes.bool.isRequired,
};

InputRow.defaultProps = {
  value: null,
  onChange: () => {},
  disabled: null,
  min: null,
};

/**
 * スイッチ行コンポーネント
 */
const SwitchRow = ({ label, checkValue, checkOnChange, switchValue, onChange, disabled }) => (
  <div className={styles.inputRow}>
    <div>
      <CustomCheckbox isChecked={checkValue} onChange={checkOnChange} label={label} />
    </div>
    <Switch checked={switchValue} onChange={onChange} disabled={disabled} />
  </div>
);

SwitchRow.propTypes = {
  label: PropTypes.string.isRequired,
  checkValue: PropTypes.bool.isRequired,
  checkOnChange: PropTypes.func.isRequired,
  switchValue: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const TECH_PORTFOLIO_INFO_MODAL_KEY = 0;
const CONFIG_DETAIL_MODAL_KEY = 1;
const TECH_PORTFOLIO_DETAIL_MODAL_KEY = 2;

const LOGIC_NAME_CHANGE_KEY = 0;
const LOGIC_DELETE_KEY = 1;
const iconSelectOptions = [
  { label: 'テクニカルロジック名を変更', value: LOGIC_NAME_CHANGE_KEY },
  { label: 'ロジック削除', value: LOGIC_DELETE_KEY },
];

/**
 * テクニカルビルダー基本情報モーダル
 */
const TechPortfolioInfo = ({
  params,
  apGroups,
  status,
  stopTech,
  execTech,
  openTechPortfolioDetail,
  openConfigDetail,
  onUpdateGroupClick,
}) => {
  const isActive = String(status) === AP_GROUP_ORDER.ACTIVITY.ACTIVE.ID;

  const techLoading = useSelector((state) => state.tech.isLoading);

  return (
    <div className="popup__body" style={{ minWidth: '100%' }}>
      <div className={styles.wrap}>
        <div className={styles.wrap}>
          <CustomButton color={COLORS.TRANSPARENT} onClick={isActive ? stopTech : execTech} isDisabled={techLoading}>
            <span style={{ marginRight: 10, fontSize: '1.5rem', color: '#FF0000' }}>
              {`テクニカルロジックを${isActive ? '停止する' : '再稼働する'}`}
            </span>
          </CustomButton>
          <OptionsDropdown isBig isNonBordered onClick={onUpdateGroupClick} options={iconSelectOptions} />
        </div>
      </div>
      {Object.keys(params)?.length && (
        <>
          <div>
            <div className={styles.wrapper}>
              <div className={styles.title}>
                <span className={styles.paragraph}>テクニカルロジックの条件</span>
                <CustomButton color={COLORS.LIGHT_GREY} className={styles.detail} onClick={openConfigDetail}>
                  <span style={{ margin: 'auto' }}>詳細を見る</span>
                </CustomButton>
              </div>
              <div className="grid grid--1x3--fixed">
                <div className={styles.grid}>
                  <Row label="インジケーター1" value={CHART_INDICATOR_MAIN[params?.indicatorIdList[0]]} />
                  <Row label="足種" value={CHART_RESOLUTION_LABEL[params?.chartType]} />
                </div>
                <div className={styles.grid}>
                  <Row label="インジケーター2" value={CHART_INDICATOR_MAIN[params?.indicatorIdList[1]]} />
                  <Row label="反対シグナルが出たときに決済するか" value={params.isAutoSettle ? 'ON' : 'OFF'} />
                </div>
                <div className={styles.grid}>
                  <div className={styles.row}>
                    <span className={styles.label}>売買シグナル</span>
                    <span className={styles.value} style={{ color: BUY_SELL_COLOR[params?.buySellSignType] }}>
                      {BUY_SELL_VALUE[params?.buySellSignType]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: '41px 0' }}>
            {apGroups?.length ? (
              <>
                <div className="text-center" style={{ fontSize: '2rem', marginBottom: 10 }}>
                  <p>詳細を見る自動売買グループをご選択ください</p>
                </div>
                <div className={styles.over}>
                  <div className={classNames(styles.portfolios, 'grid--card-auto-fit-row')}>
                    {apGroups?.map((apGroup) => (
                      <PortfolioCard
                        onClick={() => openTechPortfolioDetail(apGroup)}
                        key={apGroup.key}
                        cardData={apGroup}
                        isTech
                      />
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.over}>
                <div className="text-center" style={{ fontSize: '2rem', marginBottom: 10 }}>
                  <p>テクニカルロジックの条件に従い、シグナルが出た際に自動売買グループが作成されます。</p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

TechPortfolioInfo.propTypes = {
  params: PropTypes.shape({
    buySellSignType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chartType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    indicatorIdList: PropTypes.arrayOf(PropTypes.number),
    isAutoSettle: PropTypes.bool,
  }),
  apGroups: PropTypes.arrayOf(PropTypes.shape({})),
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  openConfigDetail: PropTypes.func.isRequired,
  openTechPortfolioDetail: PropTypes.func.isRequired,
  stopTech: PropTypes.func.isRequired,
  execTech: PropTypes.func.isRequired,
  onUpdateGroupClick: PropTypes.func.isRequired,
};

TechPortfolioInfo.defaultProps = {
  params: {},
  apGroups: [],
};

/**
 * テクニカルビルダー設定詳細モーダル
 */
const TechConfigDetail = ({ params, serviceId, openConfigChange }) => {
  const pipsLabelWithParentheses = getPipsLabelWithParentheses(serviceId, params?.instrumentId);
  const unit = getServiceQuantityUnit(serviceId);

  return (
    <div className="popup__body" style={{ minWidth: '100%' }}>
      <div className={styles.title}>
        <span style={{ fontSize: '1.7rem', fontWeight: '600' }}>設定</span>
        <CustomButton color={COLORS.LIGHT_GREY} className={styles.config} onClick={openConfigChange}>
          <span style={{ margin: 'auto' }}>変更する</span>
        </CustomButton>
      </div>

      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <div className={styles.leftBlock}>
            <div className={styles.sub}>
              <span className={styles.paragraph}>テクニカルロジックの条件</span>
            </div>
            <div className={styles.info}>
              <Row label="インジケーター1" value={CHART_INDICATOR_MAIN[params?.indicatorIdList?.[0]]} />
              <Row label="インジケーター2" value={CHART_INDICATOR_MAIN[params?.indicatorIdList?.[1]]} />
              <Row label="足種" value={CHART_RESOLUTION_LABEL[params?.chartType]} />
              <Row label="売買シグナル" value={BUY_SELL_VALUE[params?.buySellSignType]} />
              <Row label="反対シグナルが出たときに決済するか" value={params?.isAutoSettle ? 'ON' : 'OFF'} />
            </div>
          </div>

          <div className={styles.leftBlock}>
            <div className={styles.sub}>
              <span className={styles.paragraph}>新規注文の条件</span>
            </div>
            <div className={styles.info}>
              <Row label={`レンジ幅${pipsLabelWithParentheses}`} value={params?.priceRange} />
              <Row label="本数" value={params?.orderNums} />
              <Row label={`数量(${unit})`} value={params?.quantity} />
            </div>
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <div className={styles.rightBlock}>
            <div className={styles.sub}>
              <span className={styles.paragraph}>決済の条件</span>
            </div>
            <div className={styles.info}>
              <Row label={`利確幅${pipsLabelWithParentheses}`} value={params?.tp} />
              <Row label={`損切幅${pipsLabelWithParentheses}`} value={params?.sl} />
            </div>
          </div>

          <div className={styles.rightBlock}>
            <div className={styles.sub}>
              <span className={styles.paragraph}>再エントリーの条件</span>
            </div>
            <div className={styles.info}>
              <Row label={`フォロー値${pipsLabelWithParentheses}`} value={params?.follow} />
              <Row label={`カウンター値${pipsLabelWithParentheses}`} value={params?.counter} />
              <Row label="カウンター固定" value={params?.fixedCounterPrice ? 'ON' : 'OFF'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TechConfigDetail.propTypes = {
  params: PropTypes.shape({
    buySellSignType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chartType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fixedCounterPrice: PropTypes.bool,
    follow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    indicatorIdList: PropTypes.arrayOf(PropTypes.number),
    instrumentId: PropTypes.string,
    isAutoSettle: PropTypes.bool,
    orderNums: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    priceRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  serviceId: PropTypes.oneOf(ALL_SERVICES.concat([''])).isRequired,
  openConfigChange: PropTypes.func.isRequired,
};

TechConfigDetail.defaultProps = {
  params: {},
};

const TAB_PRICE = 'price';
const TAB_REALIZED_PL = 'realizedPl';
/**
 * テクニカルビルダー自動売買詳細モーダル
 */
const TechPortfolioDetail = ({ data, modalKey, serviceId, onClose }) => {
  const dispatch = useDispatch();

  const { id, currency } = data || {};

  const [cardData, setCardData] = useState(data);
  const [key, setKey] = useState(TAB_PRICE);

  const selectedApGroup = useSelector((state) => state.portfolio.selectedApGroupData);
  const { statusFilter } = useSelector((state) => state.portfolio.listSettings[MIXED]);
  useEffect(() => {
    if (modalKey === TECH_PORTFOLIO_DETAIL_MODAL_KEY && data && Object.keys(data).length > 0) {
      setCardData(data);
      dispatch(getApGroupByIdRequest({ id, serviceId, status: data.activeCount === 0 ? '0' : '1' }));
      dispatch(
        getPlExecutionsInfoRequest({ apGroupId: id, fromDate: date3MonthBefore(), toDate: new Date(), serviceId }),
      );
    }
  }, [modalKey, data, dispatch, id, serviceId]);

  useEffect(() => {
    if (modalKey === TECH_PORTFOLIO_DETAIL_MODAL_KEY && selectedApGroup.id) {
      setCardData({
        ...data,
        id: selectedApGroup.id,
        type:
          selectedApGroup.sourceType === AP_GROUP_SOURCES.MONEY_HATCH.KEY
            ? AP_GROUP_SOURCES.MONEY_HATCH.NAME
            : AP_GROUP_SOURCES.SELECT.NAME,
        currency: selectedApGroup.instrumentId,
        groupName: selectedApGroup.name,
        count: selectedApGroup.positionQuantity || '-',
        realizedProfitLoss: selectedApGroup.totalRealizedPnl || '-',
        creationTime: selectedApGroup.entryDate ? new Date(selectedApGroup.entryDate).toLocaleDateString('ja') : '-',
        totalCount: selectedApGroup.totalApCount || 0,
        activeCount: selectedApGroup.activeApCount || 0,
        status: selectedApGroup.status || data.status,
      });
      dispatch(changeSelectedApGroupStatus({ status: selectedApGroup.status }));
    }
  }, [dispatch, modalKey, selectedApGroup, data]);

  const isOpenMultiEdit = useSelector((state) => state.modals.multiEdit.isOpen);
  useEffect(() => {
    if (!isOpenMultiEdit) {
      dispatch(openPortfolioAutoTradeDetailModal({ data, withoutModal: true }));
    }
  }, [dispatch, data, isOpenMultiEdit]);

  const onModalClose = useCallback(() => {
    dispatch(clearSelectedApGroupData());
    dispatch(getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[statusFilter.value] }));
    onClose();
  }, [dispatch, onClose, statusFilter.value]);

  const tabItems = useMemo(() => {
    return [
      {
        id: TAB_PRICE,
        label: 'プライスチャート',
        children: <TradingViewChart selectedInstrumentId={currency} apGroupId={id} serviceId={serviceId} />,
      },
      {
        id: TAB_REALIZED_PL,
        label: '実現損益',
        children: <DetailChart />,
      },
    ];
  }, [currency, id, serviceId]);

  const toolbar = useMemo(() => {
    if (key !== TAB_PRICE) {
      return null;
    }
    return <ChartDescription className={classNames(styles.toolbar)} descriptionType={DescriptionType.settlement} />;
  }, [key]);

  return (
    <div className="popup__body" style={{ minWidth: '100%' }}>
      <div className={styles.apInfo}>
        <Card data={cardData} closeModal={onModalClose} />
        <div className={styles.chartTabs}>
          <Tabs containerClassName={styles.tabs} items={tabItems} activeKey={key} onChange={setKey} toolbar={toolbar} />
        </div>
        <DetailTable id={id} serviceId={serviceId} />
      </div>
    </div>
  );
};

TechPortfolioDetail.propTypes = {
  data: PropTypes.shape({
    activeCount: PropTypes.number,
    buySellSignType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chartType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fixedCounterPrice: PropTypes.bool,
    follow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    indicatorIdList: PropTypes.arrayOf(PropTypes.number),
    instrumentId: PropTypes.string,
    isAutoSettle: PropTypes.bool,
    orderNums: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    priceRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
  }),
  serviceId: PropTypes.oneOf(ALL_SERVICES.concat([''])).isRequired,
  onClose: PropTypes.func.isRequired,
  modalKey: PropTypes.number.isRequired,
};

TechPortfolioDetail.defaultProps = {
  data: {},
};

/**
 * テクニカルビルダー設定変更モーダル
 */
const TechConfigChange = ({ technicalBuilderId, params, serviceId, name, isOpen, onClose, onSubmitClose }) => {
  const {
    errorMessages,
    button,
    isAllSettlement,
    rangeSpread,
    itemsCount,
    quantity,
    profitMargin,
    stopLoss,
    follow,
    counter,
    counterFixed,
  } = useTechConfigChangeLogic({ technicalBuilderId, serviceId, name, params, isOpen, onSubmitClose });

  return (
    <div>
      <Modal
        contentClassName="popup popup--fit-content"
        show={isOpen}
        onHide={onClose}
        centered
        keyboard={false}
        size="s"
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName={styles.backdrop}
      >
        <Modal.Header className="popup__header" closeButton closeVariant="white">
          <Modal.Title style={{ width: '100%' }}>
            <p style={{ textAlign: 'center', marginTop: 12 }}>設定変更</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="popup__body">
          <div className={styles.paragraph}>
            <p style={{ marginTop: 20, fontSize: 14 }}>テクニカルロジックの条件</p>
          </div>
          <SwitchRow
            label={isAllSettlement.label}
            checkValue={isAllSettlement.check}
            checkOnChange={isAllSettlement.checkSet}
            switchValue={isAllSettlement.get}
            onChange={isAllSettlement.set}
            disabled={isAllSettlement.isDisabled}
          />
          <div className={styles.paragraph}>
            <p style={{ marginTop: 20, fontSize: 14 }}>新規注文の条件</p>
          </div>
          <InputRow
            label={rangeSpread.label}
            checkValue={rangeSpread.check}
            checkOnChange={rangeSpread.checkSet}
            value={rangeSpread.get}
            onChange={rangeSpread.set}
            disabled={rangeSpread.isDisabled}
            step={rangeSpread.step}
            name={rangeSpread.name}
            errorMessages={errorMessages}
            withErrorTooltip
          />
          <InputRow
            label={itemsCount.label}
            checkValue={itemsCount.check}
            checkOnChange={itemsCount.checkSet}
            value={itemsCount.get}
            onChange={itemsCount.set}
            disabled={itemsCount.isDisabled}
            step={itemsCount.step}
            name={itemsCount.name}
            errorMessages={errorMessages}
            withErrorTooltip
          />
          <InputRow
            label={quantity.label}
            checkValue={quantity.check}
            checkOnChange={quantity.checkSet}
            value={quantity.get}
            onChange={quantity.set}
            disabled={quantity.isDisabled}
            step={quantity.step}
            name={quantity.name}
            errorMessages={errorMessages}
            withErrorTooltip
          />

          <div className={styles.paragraph}>
            <p style={{ marginTop: 20, fontSize: 14 }}>決済の条件</p>
          </div>
          <InputRow
            label={profitMargin.label}
            checkValue={profitMargin.check}
            checkOnChange={profitMargin.checkSet}
            value={profitMargin.get}
            onChange={profitMargin.set}
            disabled={profitMargin.isDisabled}
            step={profitMargin.step}
            name={profitMargin.name}
            errorMessages={errorMessages}
            withErrorTooltip
          />
          <InputRow
            label={stopLoss.label}
            checkValue={stopLoss.check}
            checkOnChange={stopLoss.checkSet}
            value={stopLoss.get}
            onChange={stopLoss.set}
            disabled={stopLoss.isDisabled}
            step={stopLoss.step}
            min={stopLoss.min}
            name={stopLoss.name}
            errorMessages={errorMessages}
            withErrorTooltip
          />

          <div className={styles.paragraph}>
            <p style={{ marginTop: 20, fontSize: 14 }}>再エントリーの条件</p>
          </div>
          <InputRow
            label={follow.label}
            checkValue={follow.check}
            checkOnChange={follow.checkSet}
            value={follow.get}
            onChange={follow.set}
            disabled={follow.isDisabled}
            step={follow.step}
            name={follow.name}
            errorMessages={errorMessages}
            withErrorTooltip
          />
          <InputRow
            label={counter.label}
            checkValue={counter.check}
            checkOnChange={counter.checkSet}
            value={counter.get}
            onChange={counter.set}
            disabled={counter.isDisabled}
            step={counter.step}
            name={counter.name}
            min={counter.min}
            errorMessages={errorMessages}
            withErrorTooltip
          />
          <SwitchRow
            label={counterFixed.label}
            checkValue={counterFixed.check}
            checkOnChange={counterFixed.checkSet}
            switchValue={counterFixed.get}
            onChange={counterFixed.set}
            disabled={counterFixed.isDisabled}
          />

          <div>
            <Button className={styles.button} onClick={button.submit} disabled={button.isDisabled} width={200}>
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

TechConfigChange.propTypes = {
  serviceId: PropTypes.oneOf(ALL_SERVICES.concat([''])).isRequired,
  name: PropTypes.string.isRequired,
  technicalBuilderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitClose: PropTypes.func.isRequired,
  params: PropTypes.shape({
    buySellSignType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chartType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fixedCounterPrice: PropTypes.bool,
    follow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    indicatorIdList: PropTypes.arrayOf(PropTypes.number),
    instrumentId: PropTypes.string,
    isAutoSettle: PropTypes.bool,
    orderNums: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    priceRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

TechConfigChange.defaultProps = {
  params: undefined,
};

/**
 * テクニカルビルダーモーダル本体
 */
const TechnicalBuilderGroupInfo = ({ isOpen, portfolioData, onClose, source }) => {
  const dispatch = useDispatch();

  const [modalKey, setModalKey] = useState(TECH_PORTFOLIO_INFO_MODAL_KEY);
  const [isConfigChangeShow, setIsConfigChangeShow] = useState(false);
  const [selectTechApGroup, setSelectTechApGroup] = useState({});

  const [techBuilderParams, setTechBuilderParams] = useState({});

  const {
    apGroupIdList = [],
    parameters = {},
    name = '',
    technicalBuilderId = 0,
    serviceId = '',
    status = 0,
  } = techBuilderParams;

  const ref = useRef([]);

  const serviceIdRef = useRef(source?.serviceId);
  const apGroupIdListRef = useRef(source?.apGroupIdList ?? []);
  const portfolioDataRef = useRef(portfolioData ?? []);

  useEffect(() => {
    serviceIdRef.current = source?.serviceId;
    apGroupIdListRef.current = source?.apGroupIdList ?? [];
  }, [source]);

  useEffect(() => {
    portfolioDataRef.current = portfolioData ?? [];
  }, [portfolioData]);

  useEffect(() => {
    if (isOpen) {
      ref.current = portfolioData;
    } else {
      ref.current = [];
    }
  }, [isOpen, portfolioData]);

  useEffect(() => {
    if (isOpen) {
      if (source) setTechBuilderParams(source);
    } else {
      setTechBuilderParams({});
      setSelectTechApGroup({});
    }
  }, [source, isOpen]);

  /**
   * テクニカルビルダーに含まれるapGroupsIdListを元に表示するポートフォリオをfilter
   */
  const apGroups = useMemo(
    () =>
      portfolioData
        .filter((f) => apGroupIdList?.includes(f?.id) && serviceId === f?.serviceId)
        .map((m) => ({ ...m, technicalBuilderId })),
    [portfolioData, apGroupIdList, serviceId, technicalBuilderId],
  );

  const modalRef = useRef(null);
  const scrollRef = useRef(null);

  // モーダル内容表示切り替えアニメーション用 右方向
  const scrollToRight = useCallback(() => {
    let start; // アニメーション開始時間
    let previousTimeStamp;
    let done = false;
    const targetDistance = scrollRef.current.clientWidth;
    const initScrollLeft = scrollRef.current?.scrollLeft; // スクロール開始前のスクロール位置

    function rightScroll(timestamp) {
      if (!scrollRef.current) return;

      // アニメーション開始時間を記憶
      if (start === undefined) {
        start = timestamp;
      }

      if (previousTimeStamp !== timestamp) {
        if (
          scrollRef.current.scrollLeft > scrollRef.current.clientWidth * 0.2 &&
          scrollRef.current.scrollLeft < scrollRef.current.clientWidth * 0.8
        ) {
          scrollRef.current.scrollLeft += 80; // 右方向にスクロール
        } else {
          scrollRef.current.scrollLeft += 40; // 右方向に微量スクロール
        }
        if (scrollRef.current.scrollLeft - initScrollLeft >= targetDistance) done = true;
      }

      if (!done) {
        window.requestAnimationFrame(rightScroll);
      }
    }

    window.requestAnimationFrame(rightScroll);
  }, []);

  // モーダル内容表示切り替えアニメーション用 左方向
  const scrollToLeft = useCallback(() => {
    let start; // アニメーション開始時間
    let previousTimeStamp;
    let done = false;
    const targetDistance = scrollRef.current.clientWidth;
    const initScrollLeft = scrollRef.current?.scrollLeft; // スクロール開始前のスクロール位置

    function leftScroll(timestamp) {
      if (!scrollRef.current) return;

      // アニメーション開始時間を記憶
      if (start === undefined) {
        start = timestamp;
      }

      if (previousTimeStamp !== timestamp) {
        if (
          scrollRef.current.scrollLeft > scrollRef.current.clientWidth * 0.2 &&
          scrollRef.current.scrollLeft < scrollRef.current.clientWidth * 0.8
        ) {
          scrollRef.current.scrollLeft -= 80; // 左方向にスクロール
        } else {
          scrollRef.current.scrollLeft -= 40; // 左方向に微量スクロール
        }
        if (scrollRef.current.scrollLeft - initScrollLeft >= targetDistance) done = true;
      }

      if (timestamp - start < 600) {
        previousTimeStamp = timestamp;

        if (!done) {
          window.requestAnimationFrame(leftScroll);
        }
      }
    }

    window.requestAnimationFrame(leftScroll);
  }, []);

  /**
   * ポートフォリオ詳細遷移処理
   */
  const openTechPortfolioDetail = useCallback(
    (apGroup) => {
      dispatch(openPortfolioAutoTradeDetailModal({ data: apGroup, withoutModal: true }));
      setSelectTechApGroup(apGroup);
      setModalKey(TECH_PORTFOLIO_DETAIL_MODAL_KEY);

      setTimeout(() => {
        scrollToRight();
      }, 200);
    },
    [dispatch, setModalKey, setSelectTechApGroup, scrollToRight],
  );

  // ウィンドウを拡大した際にモーダルのスクロールが半端な位置になるため下記処理を行う
  useEffect(() => {
    if (!modalRef.current || !modalRef.current) return;

    const observer = new ResizeObserver(() => {
      if (scrollRef.current?.scrollLeft) {
        scrollRef.current.scrollLeft += 7680; // 強制的に右端まで表示させる 4Kの2倍の値
      }
    });

    const refCopy = modalRef.current?.dialog; // モーダルを閉じた時にunobserveするためにとっておく

    if (isOpen) {
      observer.observe(modalRef.current.dialog);
    } else if (!isOpen && refCopy) {
      observer.unobserve(refCopy);
    }
  }, [isOpen]);

  /**
   * テクニカルビルダー詳細遷移処理
   */
  const openConfigDetail = useCallback(() => {
    setModalKey(CONFIG_DETAIL_MODAL_KEY);

    setTimeout(() => {
      scrollToRight();
    }, 200);
  }, [scrollToRight]);

  const { statusFilter } = useSelector((state) => state.portfolio.listSettings[MIXED]);

  const statusUpdateSuccess = useCallback(
    (updateStatus) => {
      dispatch(getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[AP_GROUP_STATUSES.ALL_STATUSES] }));
      dispatch(getApGroupRequest({ serviceId }));

      setTechBuilderParams({
        ...techBuilderParams,
        status: updateStatus,
      });
    },
    [dispatch, techBuilderParams, serviceId],
  );

  /**
   * 稼働ステータス更新用ハンドラ
   */
  const onStatusUpdate = useCallback(
    (updateStatus) =>
      dispatch(
        updateTechnicalBuilderStatusRequest({
          status: updateStatus,
          technicalBuilderId,
          serviceId,
          callback: statusUpdateSuccess,
        }),
      ),
    [dispatch, technicalBuilderId, statusUpdateSuccess, serviceId],
  );
  /**
   * テクニカルビルダー稼働処理
   */
  const execTech = useCallback(
    () =>
      dispatch(
        openConfirmationModal({
          title: 'テクニカルビルダー再稼働',
          bodyText: '当該テクニカルビルダーを再稼働します。よろしいですか？',
          callback: () => onStatusUpdate(1),
          buttonBackText: '戻る',
          buttonNextText: '再稼働する',
          isOverlap: true,
          isLoading: false,
        }),
      ),
    [dispatch, onStatusUpdate],
  );

  /**
   * テクニカルビルダー停止処理
   */
  const stopTech = useCallback(
    () =>
      dispatch(
        openConfirmationModal({
          title: 'テクニカルロジックを停止する',
          bodyText: `テクニカルロジックと紐づく全ての自動売買を停止します。
            既に発注されている新規注文は取り消されます。
            既に保有されている建玉や発注されている決済注文は有効です。
            稼働停止後、次の新規注文は発注されません。
            よろしいですか？`,
          callback: () => onStatusUpdate(0),
          buttonBackText: '戻る',
          buttonNextText: '停止する',
          isOverlap: true,
          isLoading: false,
        }),
      ),
    [dispatch, onStatusUpdate],
  );

  const changingApNameIsLoading = useSelector((state) => state.portfolio.changingApNameIsLoading);
  const deleteCardIsLoading = useSelector((state) => state.portfolio.deleteApGroupIsLoading);
  const apListsLoading = useSelector((state) => state.portfolio.selectedApGroupMetaInfo.loading);

  // ロジック削除成功
  const onDeleteSuccess = useCallback(() => {
    dispatch(getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[statusFilter.value] }));

    onClose();
  }, [dispatch, onClose, statusFilter.value]);

  const onCloseConfirmationModal = useCallback(() => dispatch(closeConfirmationModal()), [dispatch]);

  // ロジック削除実行
  const onDeleteAccept = useCallback(() => {
    dispatch(
      deleteTechnicalBuilderDataRequest({
        technicalBuilderId,
        callback: onDeleteSuccess,
        successMessage: `${name} が削除されました。`,
      }),
    );
  }, [dispatch, onDeleteSuccess, technicalBuilderId, name]);

  const confirmDelete = useCallback(
    (errorMessage) => {
      if (errorMessage) {
        dispatch(
          openConfirmationModal({
            title: 'ロジック削除',
            bodyText: errorMessage,
            callback: onCloseConfirmationModal,
            buttonNextText: '閉じる',
            isOverlap: true,
            isLoading: false,
          }),
        );
      } else {
        dispatch(
          openConfirmationModal({
            title: 'ロジック削除',
            bodyText: `全稼働停止しているロジックを削除します。
          削除するとホーム画面から消え、戻すことができなくなります。
          過去に稼働したテクニカルロジックは照会の稼働履歴より再度カートに追加できます。
          削除してよろしいですか？`,
            callback: onDeleteAccept,
            buttonBackText: '戻る',
            buttonNextText: '削除',
            isOverlap: true,
            isLoading: false,
          }),
        );
      }
    },
    [dispatch, onDeleteAccept, onCloseConfirmationModal],
  );

  // ロジック削除処理
  const onDelete = useCallback(() => {
    if ((changingApNameIsLoading && deleteCardIsLoading) || apListsLoading) {
      return;
    }

    const apGroupIds = portfolioDataRef.current
      .filter((pd) => apGroupIdListRef.current.includes(pd.id))
      .map((m) => m.id);

    if (serviceIdRef.current == null || serviceIdRef.current === '' || apGroupIds.length === 0) {
      // API コールできないのでステータスのみで判断
      const apTechDeleteMessage = makeTechDeleteAbility([], status);
      confirmDelete(apTechDeleteMessage);
      return;
    }

    const callback = (result) => {
      const apLists = result.reduce(
        (acc, cur) => {
          acc.apList = acc.apList.concat(cur.apList);
          acc.positionQuantity += cur.positionQuantity;
          acc.activeApCount += cur.activeApCount;
          return acc;
        },
        {
          apList: [],
          positionQuantity: 0,
          activeApCount: 0,
        },
      );
      const apTechDeleteMessage = makeTechDeleteAbility(apLists, status);
      confirmDelete(apTechDeleteMessage);
    };
    dispatch(
      getApGroupByIdMultiRequest({
        apGroupIds,
        serviceId: serviceIdRef.current,
        callback,
      }),
    );
  }, [dispatch, apListsLoading, changingApNameIsLoading, deleteCardIsLoading, status, confirmDelete]);

  const nameRef = useRef(null);
  const onRenameSuccess = useCallback(() => {
    // ポートフォリオに表示のテクビ名を更新するためにGETを使用する
    dispatch(getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[statusFilter.value] }));

    setTechBuilderParams({
      ...techBuilderParams,
      name: nameRef.current,
    });
  }, [dispatch, statusFilter.value, techBuilderParams]);

  const submit = useTechNameSubmitLogic({
    technicalBuilderId,
    serviceId,
    params: parameters,
    onClose: onRenameSuccess,
  });

  // ロジック名変更実行
  const onRename = useCallback(
    (newName) => {
      if (!newName) {
        dispatch(sendNotificationError({ message: 'ロジック名は必須です。' }));
        return;
      }

      if (newName.length > 50) {
        dispatch(sendNotificationError({ message: '50文字以下でご入力ください。' }));
        return;
      }

      nameRef.current = newName;
      submit(newName);
    },
    [dispatch, submit],
  );

  // ロジック名変更処理
  const openRenameModal = useCallback(() => {
    if (status === TECH_STATUS.INACTIVE) {
      dispatch(sendNotificationError({ message: '停止されているテクニカルビルダーのロジック名は変更できません。' }));
      return;
    }

    dispatch(
      openInputConfirmationModal({
        title: 'ロジック名を変更する',
        bodyText: 'ロジック名',
        callback: onRename,
        buttonBackText: '戻る',
        buttonNextText: '変更',
        value: name,
        isOverlap: true,
      }),
    );
  }, [dispatch, onRename, name, status]);

  // ドロップダウンメニュー押下処理
  const onUpdateGroupClick = useCallback(
    (value) => {
      if (value === LOGIC_NAME_CHANGE_KEY) {
        openRenameModal();
      } else if (value === LOGIC_DELETE_KEY) {
        onDelete();
      }
    },
    [onDelete, openRenameModal],
  );

  const serviceName = useServiceName(serviceId);
  // config用のチャートデータ取得及び設定変更に必要な各値をセット。モーダルを閉じた場合はreset
  useEffect(() => {
    if (isOpen && parameters?.instrumentId) {
      batch(() => {
        dispatch(changeBuilderActiveCurrency({ activeCurrency: parameters?.instrumentId }));
        dispatch(changeBuilderExchangeType({ exchangeType: BUILDER_EXCHANGE_TYPES[serviceName].ID }));
        dispatch(changeBuilderOrderType({ orderType: BUILDER_ORDER_TYPES.TECH.ID }));
        dispatch(getBuilderChartDataRequest());
      });
    } else {
      batch(() => {
        dispatch(resetBuilderChartData());
        dispatch(resetBuilderActiveCurrency());
        dispatch(resetBuilderExchangeType());
        dispatch(resetBuilderOrderType());
      });
    }
  }, [dispatch, isOpen, parameters?.instrumentId, serviceName]);

  const onHide = useCallback(() => {
    dispatch(closePortfolioAutoTradeDetailModal());
    dispatch(clearSelectedApGroupData());

    if (modalKey === TECH_PORTFOLIO_INFO_MODAL_KEY) {
      onClose();
    } else {
      scrollToLeft();

      setTimeout(() => {
        setModalKey(TECH_PORTFOLIO_INFO_MODAL_KEY);
      }, 300);
    }
  }, [dispatch, onClose, modalKey, scrollToLeft]);

  const closeConfigChange = useCallback(
    (afterParams) => {
      setIsConfigChangeShow(false);

      const beforeParams = techBuilderParams.parameters;

      const setParams = {};
      Object.keys(afterParams).forEach((key) => {
        if (key !== 'isCounterFixed' && afterParams[key] !== undefined) {
          setParams[key] = afterParams[key];
        } else if (key === 'isCounterFixed') {
          setParams.fixedCounterPrice = afterParams.isCounterFixed;
        }
      });

      setTechBuilderParams({
        ...techBuilderParams,
        parameters: {
          ...beforeParams,
          ...setParams,
        },
      });
      dispatch(getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[statusFilter.value] }));
    },
    [dispatch, statusFilter.value, techBuilderParams],
  );

  const openStatusChangeModal = useCallback(() => {
    if (status === TECH_STATUS.INACTIVE) {
      dispatch(sendNotificationError({ message: '停止されているテクニカルビルダーの設定変更は行えません。' }));
      return;
    }

    if (apGroups.filter((a) => Number(a.status))?.length) {
      dispatch(sendNotificationError({ message: '稼働中の自動売買があるため、設定を変更できません。' }));
      return;
    }
    setIsConfigChangeShow(true);
  }, [apGroups, dispatch, status]);

  /**
   * モーダル内容定義
   */
  const left = useMemo(
    () => (
      <TechPortfolioInfo
        params={parameters}
        apGroups={apGroups}
        name={name}
        status={status}
        openTechPortfolioDetail={openTechPortfolioDetail}
        openConfigDetail={openConfigDetail}
        stopTech={stopTech}
        execTech={execTech}
        onUpdateGroupClick={onUpdateGroupClick}
      />
    ),
    [
      parameters,
      apGroups,
      name,
      status,
      openTechPortfolioDetail,
      openConfigDetail,
      stopTech,
      execTech,
      onUpdateGroupClick,
    ],
  );

  const right = useMemo(() => {
    const body = {
      [CONFIG_DETAIL_MODAL_KEY]: (
        <TechConfigDetail params={parameters} serviceId={serviceId} openConfigChange={() => openStatusChangeModal()} />
      ),
      [TECH_PORTFOLIO_DETAIL_MODAL_KEY]: (
        <TechPortfolioDetail modalKey={modalKey} data={selectTechApGroup} serviceId={serviceId} onClose={onHide} />
      ),
      [TECH_PORTFOLIO_INFO_MODAL_KEY]: <></>,
    };

    return body[modalKey];
  }, [parameters, serviceId, modalKey, selectTechApGroup, onHide, openStatusChangeModal]);

  // 自動売買名変更に対応するためstoreから直接nameを呼び出す
  const selectedApGroup = useSelector((state) => state.portfolio.selectedApGroupData);

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="popup"
        ref={modalRef}
        show={isOpen}
        onHide={onHide}
        scrollable={false}
        backdrop
        keyboard={false}
        size={MODAL_SIZES.EXTRA_LARGE}
        centered
      >
        <Modal.Header className={styles.modalHeader} closeButton closeVariant="white">
          <div className={styles.wrap}>
            {modalKey !== TECH_PORTFOLIO_INFO_MODAL_KEY && modalKey !== CONFIG_DETAIL_MODAL_KEY ? (
              <span style={{ paddingLeft: 10, fontSize: '1.7rem', fontWeight: '600' }}>{selectedApGroup?.name}</span>
            ) : (
              <span style={{ paddingLeft: 10, paddingTop: 30, fontSize: '1.7rem', fontWeight: '600' }}>{name}</span>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div ref={scrollRef} className={styles.modalBody}>
            {left}
            {right}
          </div>
        </Modal.Body>
      </Modal>
      <TechConfigChange
        technicalBuilderId={technicalBuilderId}
        params={parameters}
        serviceId={serviceId}
        name={name}
        isOpen={isConfigChangeShow}
        onSubmitClose={closeConfigChange}
        onClose={() => setIsConfigChangeShow(false)}
      />
    </>
  );
};

TechnicalBuilderGroupInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  source: PropTypes.shape({
    apGroupIdList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    technicalBuilderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    serviceId: PropTypes.oneOf(ALL_SERVICES),
    name: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    parameters: PropTypes.shape({
      buySellSignType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      chartType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fixedCounterPrice: PropTypes.bool,
      follow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      indicatorIdList: PropTypes.arrayOf(PropTypes.number),
      instrumentId: PropTypes.string,
      isAutoSettle: PropTypes.bool,
      orderNums: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      priceRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      sl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      tp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  portfolioData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

TechnicalBuilderGroupInfo.defaultProps = {
  source: undefined,
};

export default memo(TechnicalBuilderGroupInfo);
