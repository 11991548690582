import { call, put, select } from 'redux-saga/effects';
import { deleteOrder } from '../../../api/manualTradeApi';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { errorHandler } from '../errorSaga';

function* deleteOrderRequestHandler(action) {
  try {
    const {
      payload: { orderId, callback },
    } = action;

    const serviceId = yield select((state) => state.auth.serviceId);
    yield call(deleteOrder, { orderId, serviceId });

    yield put(sendNotificationSuccess({ message: '注文が取消されました。' }));

    if (callback) callback();
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

export default deleteOrderRequestHandler;
