import { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  TRADE_TABLES,
  CLOSE_POSITIONS_MAX_AMOUNT,
  SWAP_LABEL_MAP,
  SWAP_TRANSFER_LABEL,
} from 'shared-modules/constants';
import {
  OPTIONS_MANUAL_TRADE_TABLES,
  SORT_DESCENDING,
  DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
  DEFAULT_POSITIONS_TABLE_SORT_BY,
  DEFAULT_POSITIONS_TABLE_SORT_DIR,
  DEFAULT_ORDERS_TABLE_SORT_BY,
  DEFAULT_ORDERS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR,
} from 'shared-modules/constants/manualTrade';
import {
  changeExecutionsTableMetaInfo,
  getExecutionsTableDataRequest,
  getOrdersTableDataRequest,
  resetExecutionsTableMetaInfo,
  resetOrderTableMetaInfo,
  resetPositionTableMetaInfo,
  swapTransferRequest,
} from 'shared-modules/redux/actions/manualTradeActions';
import { removeKeyFromLocalStorage, saveJsonStyleDefaultValuesToLocalStorage } from 'shared-modules/services';
import { SWAP_TRANSFER_POSITIONS_VALIDATION_MESSAGE } from 'shared-modules/constants/errorMesages';
import { changeSelectedTableInManualTrade, openConfirmationModal, openErrorInfoModal } from '../../../../redux/actions';
import DeletePositionsButton from '../../../../components/TablePositions/components/DeletePositionsButton/DeletePositionsButton';
import { LinkButton, Tabs } from '../../../../components';
import TableOrders from '../../../../components/TableOrders';
import TableExecutions from '../../../../components/TableExecutions/TableExecutions';
import TablePositions from '../../../../components/TablePositions/TablePositions';
import { useTableRef } from '../../../../hooks';
import styles from './table.module.scss';
import CustomButton from '../../../../components/CustomButton';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import { DATEPICKER_WIDTH } from '../../constants';

const InnerTable = memo(() => {
  const activeTab = useSelector((state) => state.manualTrade.selectedTable);
  if (activeTab === TRADE_TABLES.EXECUTIONS.ID) {
    return <TableExecutions isManual />;
  }
  if (activeTab === TRADE_TABLES.POSITIONS.ID) {
    return <TablePositions isManual />;
  }
  if (activeTab === TRADE_TABLES.ORDERS.ID) {
    return <TableOrders isManual />;
  }
  return null;
});

const Toolbar = memo(() => {
  const dispatch = useDispatch();
  const serviceId = useSelector((state) => state.auth.serviceId);
  const activeTab = useSelector((state) => state.manualTrade.selectedTable);
  const selectedPositions = useSelector((state) => state.manualTrade.selectedTableRows);
  const isNonePositionsSelected = selectedPositions.length === 0;
  const [buttonStr, setButtonStr] = useState();
  const [localStorageKey, setLocalStorageKey] = useState();
  const [sortByKey, setSortByKey] = useState();
  const [sortDirKey, setSortDirKey] = useState();
  const [transferWidth, setTransferWidth] = useState(120);
  const isManual = true;

  const tableRef = useTableRef();

  useEffect(() => {
    setButtonStr(SWAP_LABEL_MAP[serviceId]);
    setTransferWidth(SWAP_TRANSFER_LABEL[serviceId].width);
    switch (activeTab) {
      case TRADE_TABLES.EXECUTIONS.ID:
        setLocalStorageKey(KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[serviceId]);
        setSortByKey(KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY[serviceId]);
        setSortDirKey(KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR[serviceId]);
        break;
      case TRADE_TABLES.POSITIONS.ID:
        setLocalStorageKey(KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[serviceId]);
        setSortByKey(KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY[serviceId]);
        setSortDirKey(KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR[serviceId]);
        break;
      case TRADE_TABLES.ORDERS.ID:
        setLocalStorageKey(KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[serviceId]);
        setSortByKey(KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY[serviceId]);
        setSortDirKey(KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR[serviceId]);
        break;
      default:
        break;
    }
  }, [activeTab, serviceId, setButtonStr]);
  const filteredTargetPositions = selectedPositions.filter((filteredPositions) => {
    return filteredPositions.unrealizedSwapPl !== 0;
  });

  const selectedPositionsAmount = filteredTargetPositions.length;
  const isMoreThanMax = selectedPositionsAmount > CLOSE_POSITIONS_MAX_AMOUNT;

  const filterClear = useCallback(() => {
    removeKeyFromLocalStorage({ key: localStorageKey });
    removeKeyFromLocalStorage({ key: sortByKey });
    removeKeyFromLocalStorage({ key: sortDirKey });

    if (activeTab === TRADE_TABLES.EXECUTIONS.ID) {
      batch(() => {
        dispatch(resetExecutionsTableMetaInfo({ serviceId }));
        dispatch(getExecutionsTableDataRequest({ isFirstData: true, isManual }));
        tableRef.current?.setSortBy([
          { id: DEFAULT_EXECUTIONS_TABLE_SORT_BY, desc: DEFAULT_EXECUTIONS_TABLE_SORT_DIR === SORT_DESCENDING },
        ]);
      });
    } else if (activeTab === TRADE_TABLES.POSITIONS.ID) {
      dispatch(resetPositionTableMetaInfo({ serviceId }));
      tableRef.current?.setSortBy([
        { id: DEFAULT_POSITIONS_TABLE_SORT_BY, desc: DEFAULT_POSITIONS_TABLE_SORT_DIR === SORT_DESCENDING },
      ]);
    } else if (activeTab === TRADE_TABLES.ORDERS.ID) {
      removeKeyFromLocalStorage({
        key: KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED[serviceId],
      });
      batch(() => {
        dispatch(resetOrderTableMetaInfo({ serviceId }));
        dispatch(getOrdersTableDataRequest({ isFirstData: true, isManual }));
        tableRef.current?.setSortBy([
          { id: DEFAULT_ORDERS_TABLE_SORT_BY, desc: DEFAULT_ORDERS_TABLE_SORT_DIR === SORT_DESCENDING },
        ]);
      });
    }
  }, [dispatch, serviceId, tableRef, localStorageKey, sortByKey, sortDirKey, activeTab, isManual]);

  const swapTransfer = useCallback(() => {
    dispatch(
      swapTransferRequest({
        positionIds: filteredTargetPositions.map((el) => el.positionId),
      }),
    );
  }, [dispatch, filteredTargetPositions]);

  const openSwapTransferModal = useCallback(() => {
    if (isMoreThanMax) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: SWAP_TRANSFER_POSITIONS_VALIDATION_MESSAGE,
        }),
      );
    } else if (filteredTargetPositions.length === 0) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: `選択した建玉に対して未実現${buttonStr}振替処理は行えません。`,
        }),
      );
    } else {
      dispatch(
        openConfirmationModal({
          title: `一括${buttonStr}振替`,
          bodyText: `建玉に紐づく未実現${buttonStr}を全て実現します。よろしいですか？`,
          callback: swapTransfer,
          buttonBackText: '戻る',
          buttonNextText: '確定',
          isOverlap: true,
        }),
      );
    }
  }, [isMoreThanMax, filteredTargetPositions.length, dispatch, buttonStr, swapTransfer]);

  const { fromDate: fromDateInfo, toDate: toDateInfo } = useSelector(
    (state) => state.manualTrade.executionsDataMetaInfo[serviceId],
  );

  const filterOptions = useMemo(() => {
    return {
      fromDateInfo,
      toDateInfo,
      maxDate: new Date(),
    };
  }, [fromDateInfo, toDateInfo]);

  const filterHandler = useCallback(
    ({ value }) => {
      batch(() => {
        dispatch(changeExecutionsTableMetaInfo({ key: 'fromDate', value: value?.fromDate, serviceId }));
        dispatch(changeExecutionsTableMetaInfo({ key: 'toDate', value: value?.toDate, serviceId }));
      });
      dispatch(getExecutionsTableDataRequest({ isFirstData: true, isManual }));

      saveJsonStyleDefaultValuesToLocalStorage({
        key: 'execTime',
        value,
        localStorageKey: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[serviceId],
      });
    },
    [dispatch, isManual, serviceId],
  );

  const filterDatePickerHandler = useCallback(
    ({ fromDate, toDate }) => {
      if (fromDate) {
        if (fromDate.getTime() > toDateInfo.getTime()) {
          filterHandler({ value: { fromDate, toDate: fromDate } });
        } else {
          filterHandler({ value: { fromDate, toDate: toDateInfo } });
        }
      }
      if (toDate) {
        if (toDate.getTime() >= fromDateInfo.getTime()) {
          filterHandler({ value: { fromDate: fromDateInfo, toDate } });
        } else {
          filterHandler({ value: { fromDate: toDate, toDate } });
        }
      }
    },
    [filterHandler, fromDateInfo, toDateInfo],
  );

  const datePicker = useMemo(() => {
    const { maxDate } = filterOptions || {};
    return (
      <div className={styles.datePicker}>
        <CustomDatePicker
          date={fromDateInfo}
          maxDate={maxDate}
          onChange={(fromDate) => filterDatePickerHandler({ fromDate })}
          isLighter
          placeholder="日付を選択"
          width={DATEPICKER_WIDTH}
          isThin
        />
        <div className={styles.datePickerText}>〜</div>
        <CustomDatePicker
          date={toDateInfo}
          maxDate={maxDate}
          onChange={(toDate) => filterDatePickerHandler({ toDate })}
          isLighter
          width={DATEPICKER_WIDTH}
          isThin
        />
      </div>
    );
  }, [filterDatePickerHandler, filterOptions, fromDateInfo, toDateInfo]);

  return (
    <div className={styles.toolbox}>
      {TRADE_TABLES.EXECUTIONS.ID === activeTab && <div>{datePicker}</div>}
      <LinkButton className={styles.linkButton} onClick={filterClear}>
        フィルターをすべてクリア
      </LinkButton>
      {activeTab === TRADE_TABLES.POSITIONS.ID && (
        <div className={styles.buttons}>
          <CustomButton
            className={styles.filterButton}
            onClick={openSwapTransferModal}
            width={transferWidth}
            isDisabled={isNonePositionsSelected}
          >
            {`一括${buttonStr}振替`}
          </CustomButton>
          <DeletePositionsButton />
        </div>
      )}
    </div>
  );
});

const Table = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.manualTrade.selectedTable);

  const changeActiveTab = useCallback(
    (tableId) => {
      dispatch(changeSelectedTableInManualTrade({ tableId }));
    },
    [dispatch],
  );

  const tabItems = useMemo(() => {
    return OPTIONS_MANUAL_TRADE_TABLES.map((option) => ({ ...option, children: <InnerTable /> }));
  }, []);

  return (
    <div className={styles.wrapper}>
      <Tabs items={tabItems} activeKey={activeTab} toolbar={<Toolbar />} onChange={changeActiveTab} />
    </div>
  );
};

export default memo(Table);
