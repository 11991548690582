import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './spin.module.scss';

export const Spin = memo(({ className, children }) => (
  <div className={classNames('spinner-border', className ?? styles.spin)} role="status">
    <span className="sr-only">{children}</span>
  </div>
));

Spin.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Spin.defaultProps = {
  className: undefined,
  children: undefined,
};
