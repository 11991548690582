export const CHANGE_ACTIVE_TABLE = 'WEB_BUILDER/CHANGE_ACTIVE_TABLE';

export const CHANGE_ACTIVE_PAGE = 'WEB_BUILDER/CHANGE_ACTIVE_PAGE';

export const ADD_DRAWING_POINT = 'WEB_BUILDER/ADD_DRAWING_POINT';

export const CHANGE_SELECT_RECOMMEND = 'WEB_BUILDER/CHANGE_SELECT_RECOMMEND';

export const RESET_WEB_BUILDER_OPTIONS = 'WEB_BUILDER/RESET_WEB_BUILDER_OPTIONS';

export const CHANGE_RECOMMEND_LOGIC_LIST = 'WEB_BUILDER/CHANGE_RECOMMEND_LOGIC_LIST';

export const CHANGE_RECOMMEND_MODAL = 'WEB_BUILDER/CHANGE_RECOMMEND_MODAL';

export const CHANGE_ACTIVE_TAB = 'WEB_BUILDER/CHANGE_ACTIVE_TAB';
