import React, { memo } from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import styles from './dropdownIndicator.module.scss';

export const DropdownIndicator = memo((props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.DropdownIndicator {...props}>
    <i className={classNames(styles.icon, 'material-icons')}>arrow_back_ios_new</i>
  </components.DropdownIndicator>
));
