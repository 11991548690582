import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ORDER_HISTORY_EMPTY_MESSAGE } from 'shared-modules/constants';
import { getHistoryCartRequest } from 'shared-modules/redux/actions/cartActions';
import { useHistoryData } from 'shared-modules/hooks/cart';
import { Spin } from '../../../../components';
import { Item } from './Item';
import styles from './orderHistory.module.scss';

export const OrderHistory = memo(({ onMount, onUnmount }) => {
  const dispatch = useDispatch();
  const historyData = useHistoryData();
  const loading = useSelector((state) => state.cart.historyDataIsLoading);

  useEffect(() => {
    dispatch(getHistoryCartRequest());
  }, [dispatch]);

  useEffect(() => {
    onMount?.();
    return () => {
      onUnmount?.();
    };
  }, [onMount, onUnmount]);

  const content = useMemo(() => {
    if (loading) {
      return <Spin className={styles.loader} />;
    }
    if (historyData?.length) {
      return historyData.map(({ logId, createTime, items }) => (
        <div key={logId} className={styles.row}>
          <Item logId={logId} createTime={createTime} items={items} />
        </div>
      ));
    }
    return <div className={styles.emptyText}>{ORDER_HISTORY_EMPTY_MESSAGE}</div>;
  }, [loading, historyData]);

  return (
    <div className={styles.container}>
      <div className={styles.scrollArea}>{content}</div>
    </div>
  );
});

OrderHistory.propTypes = {
  onMount: PropTypes.func,
  onUnmount: PropTypes.func,
};

OrderHistory.defaultProps = {
  onMount: undefined,
  onUnmount: undefined,
};
