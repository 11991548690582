import { memo } from 'react';
import PropTypes from 'prop-types';
import { getSymbolPercent } from 'shared-modules/utils';
import styles from './bar.module.scss';

export const Bar = memo(({ symbolInfo }) => (
  <div className={styles.container}>
    {symbolInfo.map(({ name, percent, color, gradationColor }) => (
      <div
        className={styles.item}
        key={name}
        style={{
          width: getSymbolPercent(percent),
          backgroundImage: `linear-gradient(90deg, ${color} 0%, ${gradationColor} 100%)`,
        }}
      />
    ))}
  </div>
));

Bar.propTypes = {
  symbolInfo: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      percent: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      gradationColor: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
