import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  LAB_INFO_HALL_OF_FAME,
  LAB_INFO_POPULAR,
  LAB_INFO_PROFITABILITY,
  LAB_INFO_WELL,
  MODAL_SIZES,
} from 'shared-modules/constants';
import Modal from '../../../../components/Modal';
import CustomButton from '../../../../components/CustomButton';
import styles from './labCategoryInfo.module.scss';

const BUTTON_WIDTH = 312;

// Text don't have breaks, because React would add spaces in place of breaks, which is undesirable
const LabCategoryInfo = ({ isOpen, closeModal }) => (
  <Modal size={MODAL_SIZES.WIDTH_640} isOpen={isOpen} closeModal={closeModal} isOverlap>
    <div className={styles.bodyWrapper}>
      <div className={styles.title}>収益率</div>
      <div className={styles.text}>{LAB_INFO_PROFITABILITY}</div>
      <hr className={styles.divider} />
      <div className={styles.title}>好調</div>
      <div className={styles.text}>{LAB_INFO_WELL}</div>
      <hr className={styles.divider} />
      <div className={styles.title}>人気</div>
      <div className={styles.text}>{LAB_INFO_POPULAR}</div>
      <hr className={styles.divider} />
      <div className={styles.title}>殿堂入り</div>
      <div className={styles.text}>{LAB_INFO_HALL_OF_FAME}</div>
      <hr className={styles.divider} />
      <CustomButton width={BUTTON_WIDTH} onClick={closeModal} className={styles.button}>
        閉じる
      </CustomButton>
    </div>
  </Modal>
);

LabCategoryInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(LabCategoryInfo);
