import { LOCATION_CHANGE } from '../actionConstants/routerConstants';

const injectQuery = (location) => {
  if (location && location.query) {
    return location;
  }

  const searchQuery = location && location.search;

  if (typeof searchQuery !== 'string' || searchQuery.length === 0) {
    return {
      ...location,
      query: {},
    };
  }

  const search = searchQuery.substring(1);
  const queries = search.split('&');
  const query = queries.reduce((acc, currentQuery) => {
    const [queryKey, queryValue] = currentQuery.split('=');
    return {
      ...acc,
      [queryKey]: queryValue,
    };
  }, {});

  return {
    ...location,
    query,
  };
};

export const createRouterReducer = (history) => {
  const initialState = {
    location: injectQuery(history.location),
    action: history.action,
  };

  return (state = initialState, { type, payload }) => {
    if (type === LOCATION_CHANGE) {
      const { location, action, isFirstRendering } = payload;
      if (isFirstRendering) {
        return state;
      }
      return {
        ...state,
        action,
        location: injectQuery(location),
      };
    }
    return state;
  };
};
