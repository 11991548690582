import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { getServiceName } from 'shared-modules/utils';
import useAutoSelectRunNowConfirmation from 'shared-modules/services/hooks/autoSelectRunNowConfirmation';
import { useChangeService } from 'shared-modules/services/hooks';
import { getMarginRequest } from 'shared-modules/redux/actions/portfolioActions';
import { push } from 'shared-modules/redux/actions/routerActions';
import { changeServiceIdRequest } from 'shared-modules/redux/actions';
import { CASH } from '../../../../constants';
import { tutorialCountInput, tutorialSubmit } from '../../../../constants/tutorial/classNames';
import { Button, InputNumber, Modal } from '../../../../components';
import { AssetDetails } from './AssetDetails';
import styles from './autoSelectRunNowConfirmation.module.scss';

const convertOption = (modalData, closeModal) => (option) => {
  const dispatch = useDispatch();
  const { id, isVisuallyDisabled } = option;
  if (isVisuallyDisabled) {
    return null;
  }
  const found = ALL_SERVICES.find(
    (serviceId) => id === serviceId && modalData?.[`totalMarginRequired${getServiceName(serviceId)}`]?.get != null,
  );
  if (found) {
    const serviceName = getServiceName(found);
    const requiredMargin = modalData?.[`totalMarginRequired${serviceName}`];
    const recommendedMargin = modalData[`totalMarginRecommended${serviceName}`];
    return {
      serviceId: found,
      requiredMargin,
      recommendedMargin,
      onClick: () => {
        closeModal();
        dispatch(push(`/${CASH}`));
        dispatch(changeServiceIdRequest({ serviceId: found }));
      },
    };
  }
  return null;
};

const AutoSelectRunNowConfirmation = ({ isOpen, closeModal, data }) => {
  const dispatch = useDispatch();
  const modalData = useAutoSelectRunNowConfirmation({
    isOpen,
    onClose: closeModal,
    data,
  });
  const { options } = useChangeService();
  const assetData = options.map(convertOption(modalData, closeModal)).filter((x) => x);
  const isMultiAsset = assetData?.length > 1;

  useEffect(() => {
    ALL_SERVICES.forEach((serviceId) => {
      if (!options.filter((x) => x.id === serviceId)[0].isVisuallyDisabled) {
        dispatch(getMarginRequest({ serviceId }));
      }
    });
  }, [dispatch, options]);

  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={modalData.cancelButton.handler}
      size={isMultiAsset ? MODAL_SIZES.WIDTH_640 : MODAL_SIZES.WIDTH_512}
      title={modalData.modalTitle}
      isOverlap
    >
      <div className={styles.body}>
        <div className={classNames(styles.row, tutorialCountInput)}>
          <div className={styles.label}>{modalData.countInput.label}</div>
          <InputNumber
            name={modalData.countInput.name}
            errorMessages={modalData.errorMessages}
            validateFunction={modalData.countInput.validateFunction}
            value={modalData.countInput.get}
            onChange={modalData.countInput.set}
            withErrorTooltip
            onlyIntegerAllowed
            disabledInput={tutorialMode}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.runNote}>{modalData.runNote}</div>
        </div>
        <div className={styles.headline}>
          <div className={styles.label}>稼働後のバランスメーター</div>
        </div>
        <AssetDetails
          data={assetData}
          totalMargin={modalData.totalMarginRecommended}
          totalMarginRequired={modalData.totalMarginRequired}
          closeModal={modalData.cancelButton.handler}
        />
        <div className={styles.buttonArea}>
          <Button
            className={tutorialSubmit}
            onClick={tutorialMode ? undefined : modalData.submitButton.handler}
            disabled={modalData.submitButton.isDisabled}
          >
            {modalData.submitButton.label}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AutoSelectRunNowConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    marginRecommended: PropTypes.number.isRequired,
    marginRequired: PropTypes.number.isRequired,
    sets: PropTypes.number.isRequired,
    callback: PropTypes.func,
    isRunNowType: PropTypes.bool,
  }).isRequired,
};

export default memo(AutoSelectRunNowConfirmation);
