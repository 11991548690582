import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useKeyDownHandler } from '../../services/hooks';
import { ControlDialog } from './ControlDialog';
import styles from './popControl.module.scss';

export const PopControl = memo(({ header, filterOption, sortOptions, applyCallback, applyFilteringViewCondition }) => {
  const dialogRef = useRef(null);
  const containerRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [applyFilteringView, setApplyFilteringView] = useState(false);
  const errorInfo = useSelector((state) => state.error.errorInfoModal);

  const handleClick = useCallback(() => {
    setVisible((prevState) => !prevState);
  }, []);

  const handleKeyDown = useKeyDownHandler(handleClick);

  const handleClickOutside = useCallback(
    (e) => {
      if (errorInfo.isOpen) return;
      if (
        dialogRef.current &&
        !dialogRef.current.contains(e.target) &&
        containerRef.current &&
        !containerRef.current.contains(e.target)
      ) {
        setVisible(false);
      }
    },
    [errorInfo],
  );

  const handleClickApply = useCallback(
    (params) => {
      applyCallback(params);
      setVisible(false);
    },
    [applyCallback],
  );

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    if (applyFilteringViewCondition) {
      setApplyFilteringView(
        typeof applyFilteringViewCondition === 'boolean' ? applyFilteringViewCondition : applyFilteringViewCondition(),
      );
    }
  }, [applyFilteringViewCondition]);

  return (
    <>
      <div
        ref={containerRef}
        className={styles.container}
        role="button"
        tabIndex={0}
        aria-hidden
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <div className={styles.header}>{header}</div>
        <span
          className={classNames('material-icons-outlined', styles.icon, {
            [styles.visible]: visible,
            [styles.applyFilteringView]: applyFilteringView,
          })}
        >
          arrow_drop_down
        </span>
      </div>
      {visible && (
        <ControlDialog
          ref={dialogRef}
          parentRef={containerRef}
          filterOption={filterOption}
          sortOptions={sortOptions}
          onClick={handleClickApply}
          onClose={handleClose}
        />
      )}
    </>
  );
});

PopControl.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  filterOption: PropTypes.shape({
    type: PropTypes.oneOf(['radio', 'checkbox', 'datepicker']).isRequired,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.arrayOf(
              PropTypes.shape({
                label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                selected: PropTypes.bool,
              }),
            ),
          ]),
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          selected: PropTypes.bool,
        }),
      ),
      PropTypes.shape({
        fromDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        toDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      }),
    ]).isRequired,
  }),
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      selected: PropTypes.bool,
    }).isRequired,
  ),
  applyCallback: PropTypes.func.isRequired,
  applyFilteringViewCondition: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

PopControl.defaultProps = {
  header: undefined,
  filterOption: undefined,
  sortOptions: undefined,
  applyFilteringViewCondition: undefined,
};
