import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { AccountDetailHeader } from '../AccountDetailHeader';
import { MeterDetails } from '../MeterDetails';
import { SymbolDistribution } from '../SymbolDistribution';
import styles from './accountStatusDetail.module.scss';

export const AccountStatusDetail = memo(({ serviceId, className }) => (
  <div className={classNames(styles.container, className)}>
    <div className={styles.header}>
      <AccountDetailHeader serviceId={serviceId} />
    </div>
    <div className={styles.content}>
      <div className={styles.meter}>
        <MeterDetails serviceId={serviceId} />
      </div>
      <div className={styles.graph}>
        <div className={styles.title}>内訳</div>
        <SymbolDistribution serviceId={serviceId} />
      </div>
    </div>
  </div>
));

AccountStatusDetail.propTypes = {
  serviceId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

AccountStatusDetail.defaultProps = {
  className: undefined,
};
