import { CLEAR_SCREEN, UPDATE_SCREEN } from '../actionConstants/screenConstants';

export const createScreenReducer = (initialStateFactory) => {
  const defaultInitialState = { screen: null };
  const initialState = initialStateFactory?.() ?? defaultInitialState;
  return (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case UPDATE_SCREEN: {
        return {
          ...state,
          screen: payload.screenId,
        };
      }
      case CLEAR_SCREEN: {
        return {
          ...state,
          screen: null,
        };
      }
      default: {
        return state;
      }
    }
  };
};
