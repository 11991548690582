import React, { memo } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { FX } from 'shared-modules/constants';
import styles from '../steps.module.scss';

const Step8 = ({ serviceId }) => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <p>
          フォロー値は、買いの場合は決済価格からどのくらい上がったら、次新たに買うかを値幅
          {serviceId === FX && '（pips）'}
          で設定する、再エントリーの条件になります。売りの場合は反対で、決済価格からどのくらい下がったらまた売るかを設定します。
        </p>
        {serviceId === FX && (
          <p className={styles.subContent}>
            <br />※ pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）
          </p>
        )}
      </div>
    </div>
  );
};

Step8.propTypes = {
  serviceId: propTypes.string,
};

Step8.defaultProps = {
  serviceId: '',
};

export default memo(Step8);
