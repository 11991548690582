/* eslint-disable-next-line import/no-unresolved */
import { createContext, createElement, memo, useEffect, useRef, useState } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import PropTypes from 'prop-types';
import { KEY_SYSTEM_RELOADED } from '../constants/core';
import { nop } from '../utils';
import Logger from '../services/Logger';

export const ReloadingAppContext = createContext({
  reloading: false,
  setReloading: nop,
  reloaded: false,
  setReloaded: nop,
});

export const ReloadingAppProvider = memo(({ storage, reload, children }) => {
  const storageRef = useRef(storage);
  const reloadRef = useRef(reload);
  const [reloading, setReloading] = useState(false);
  const [reloaded, setReloaded] = useState(false);

  useEffect(() => {
    if (storageRef.current) {
      const loadStorage = async () => {
        try {
          const storedReloaded = await storageRef.current.getItem(KEY_SYSTEM_RELOADED);
          setReloaded(storedReloaded ? JSON.parse(storedReloaded) : false);
          await storageRef.current.removeItem(KEY_SYSTEM_RELOADED);
        } catch (error) {
          setReloaded(false);
          Logger.error(error);
        }
      };
      loadStorage();
    }
  }, []);

  return createElement(
    ReloadingAppContext.Provider,
    {
      value: { reloading, setReloading, reloaded, setReloaded, storage: storageRef.current, reload: reloadRef.current },
    },
    children,
  );
});

ReloadingAppProvider.propTypes = {
  storage: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
  }),
  reload: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

ReloadingAppProvider.defaultProps = {
  storage: undefined,
};
