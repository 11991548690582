import React, { memo, useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import classNames from 'classnames';
import {
  COLORS,
  WIDTH,
  BUY_SELL_MAIN,
  OPTIONS_ORDER_TYPES,
  ALLOWED_TRADE_TYPES,
  MARKET_STATUSES,
  BUY_SELL_CHART_ID_BY_ID,
} from 'shared-modules/constants';
import {
  BUY_SELL_INPUT,
  EXPIRATION_TYPE_INPUT,
  KEY_FOR_DEFAULT_SELECT_SIDE,
  ORDER_TYPES,
  ORDER_TYPES_BY_ID,
  SETTLEMENT_EXPIRATION_TYPE_INPUT,
  COUNT_INPUT,
} from 'shared-modules/constants/manualTrade';
import {
  getRefreshNonCommonStateActionsArrayForManualTrade,
  saveDefaultValuesFromLocalStorage,
} from 'shared-modules/services';
import {
  useManualTradeSelectedInstrumentSettings,
  useSellForbiddenCurrencyPairSupportAllowSellFlgCalc,
  useOppositeSideTotalQuantityInfo,
  usePricesForBuySell,
  useSelectedOrderType,
  useValidateManualTradeInput,
  useValidateSelectedManualTradeOrder,
  useManualTradeChangeActiveCurrency,
  useResetManualTradeQuantity,
  useChangeService,
} from 'shared-modules/services/hooks';
import { openErrorInfoModal } from 'shared-modules/redux/actions/errorActions';
import { useManualTradeCommonFifoLogic } from 'shared-modules/services/hooks/manualTradeFifoTableLogic';
import { useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import {
  changeCreateOrderValues,
  changeSelectedSide,
  changeChartSide,
  openManualTradeConfirmation,
  createMarkerOrderRequest,
  createFIFOOrderRequest,
  createStandardOrderRequest,
  createOcoOrderRequest,
  createIFDOrderRequest,
  createIFOOrderRequest,
} from '../../../../redux/actions';
import CustomSelect from '../../../../components/CustomSelect';
import CustomButton from '../../../../components/CustomButton';
import BuySellPrices from '../../../../components/BuySellPrices';
import InstrumentDropdownIcon from '../../../../components/InstrumentDropdownIcon';
import SideMenuOptionsMarkerOrder from '../SideMenuOptionsMarkerOrder';
import SideMenuOptionsStandard from '../SideMenuOptionsStandard';
import SideMenuOptionsOco from '../SideMenuOptionsOco';
import SideMenuOptionsIfd from '../SideMenuOptionsIfd';
import SideMenuOptionsIfo from '../SideMenuOptionsIfo';
import styles from './sideMenu.module.scss';
import { Tabs } from '../../../../components';

const SELECT_FULL_SIZE_WIDTH = 312;

const SideMenu = () => {
  const dispatch = useDispatch();
  const serviceId = useSelector((state) => state.auth.serviceId);
  const isManual = true;
  const instrumentOptions = useApOrManualInstrumentOptions(isManual, serviceId)[serviceId];
  // eslint-disable-next-line
  const renderDropdownIcon = useCallback(({ value, isClearOption }) => {
    if (isClearOption) {
      return null;
    }
    return <InstrumentDropdownIcon instrumentId={value} serviceId={serviceId} />;
  });

  const withoutConfirmation = useSelector((state) => state.settings[serviceId].skipNewOrderConfirmation);
  const isCrossOrder = useSelector((state) => state.settings[serviceId].isCrossOrder);
  const allowedTradeType = useSelector((state) => state.settings.accountInfo[serviceId]?.canTradeType);
  const selectedSide = useSelector((state) => state.manualTrade.selectedSide);
  const createOrdersValidationErrors = useSelector((state) => state.manualTrade.createOrdersValidationErrors);
  const ordersSettings = useSelector((state) => state.manualTrade.createOrders);
  const selectedCurrencyId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  const isCreateOrderLoading = useSelector((state) => state.manualTrade.createOrderLoading);

  const tradeServiceData = useChangeService();

  const [middleHeight, setMiddleHeight] = useState('max-content');
  const [bottomHeight, setBottomHeight] = useState('1fr');

  const [innerIsModalOpen, setInnerIsModalOpen] = useState(false);

  const changeActiveCurrency = useManualTradeChangeActiveCurrency();

  const validateSelectedManualTradeOrderRef = useRef({});
  const validateSelectedManualTradeOrder = useValidateSelectedManualTradeOrder();
  useEffect(() => {
    validateSelectedManualTradeOrderRef.current = { validateSelectedManualTradeOrder };
  }, [validateSelectedManualTradeOrder]);

  const { marketStatus, allowBuyFlg, allowSellFlg } = useManualTradeSelectedInstrumentSettings();

  const { buyPositionCount, sellPositionCount } = useManualTradeCommonFifoLogic();

  const oppositeSideTotalQuantity = useOppositeSideTotalQuantityInfo();

  const calcedAllowSellFlg = useSellForbiddenCurrencyPairSupportAllowSellFlgCalc(allowSellFlg, buyPositionCount);

  const pricesRef = useRef({});
  const { sellPrice, previousSellPrice, buyPrice, previousBuyPrice } = usePricesForBuySell({
    currencyPair: selectedCurrencyId,
  });
  const [priceIsNull, changePriceStatus] = useState(true);
  useEffect(() => {
    pricesRef.current = { sellPrice, previousSellPrice, buyPrice, previousBuyPrice };
    if (sellPrice !== 0 && buyPrice !== 0 && priceIsNull) {
      changePriceStatus(false);
    }
  }, [sellPrice, previousSellPrice, buyPrice, previousBuyPrice, priceIsNull]);

  const [selectedOrderType, changeSelectedOrderType] = useSelectedOrderType();

  const validateManualTradeInput = useValidateManualTradeInput();
  const validateManualTradeInputRef = useRef({});
  useEffect(() => {
    validateManualTradeInputRef.current = { validateManualTradeInput };
  }, [validateManualTradeInput]);

  const orderSettings = useSelector((state) => state.settings[serviceId].orderSettings);
  useEffect(() => {
    changeSelectedOrderType(Number(orderSettings.orderType));
  }, [orderSettings, dispatch, changeSelectedOrderType]);

  const currentSide = useMemo(
    () => ordersSettings[ORDER_TYPES_BY_ID[selectedOrderType]]?.buySell,
    [ordersSettings, selectedOrderType],
  );

  const currentSideRef = useRef({});
  useEffect(() => {
    if (currentSideRef?.current) currentSideRef.current = { currentSide };
  }, [currentSide]);

  useEffect(() => {
    const {
      current: { sellPrice: refSellPrice, buyPrice: refBuyPrice },
    } = pricesRef;
    const {
      current: { currentSide: sideId },
    } = currentSideRef;
    const refreshTabOrderActions = getRefreshNonCommonStateActionsArrayForManualTrade({
      orderType: ORDER_TYPES_BY_ID[selectedOrderType],
      sellPrice: refSellPrice,
      buyPrice: refBuyPrice,
      orderSettings,
      sideId,
    });
    batch(() => {
      refreshTabOrderActions.forEach((action) => dispatch(action));
    });
    if (
      (selectedOrderType === ORDER_TYPES.IFD.id || selectedOrderType === ORDER_TYPES.IFO.id) &&
      validateManualTradeInputRef?.current
    ) {
      setTimeout(
        () =>
          validateManualTradeInputRef.current?.validateManualTradeInput({
            inputName: SETTLEMENT_EXPIRATION_TYPE_INPUT,
            value: Number(orderSettings.expirationType),
          }),
        0,
      );
    }
  }, [dispatch, orderSettings, selectedOrderType, priceIsNull]);

  useResetManualTradeQuantity(orderSettings.quantity, selectedCurrencyId, validateManualTradeInputRef);

  useEffect(() => {
    batch(() => {
      Object.values(ORDER_TYPES).forEach((type) => {
        if (Object.values(type.inputs).includes(EXPIRATION_TYPE_INPUT)) {
          dispatch(
            changeCreateOrderValues({
              orderType: type.name,
              inputName: EXPIRATION_TYPE_INPUT,
              value: Number(orderSettings.expirationType),
            }),
          );
        }
      });
    });
  }, [orderSettings, dispatch]);

  const confirmCallback = useCallback(
    ({ x, y }) => {
      switch (selectedOrderType) {
        case ORDER_TYPES.MARKET_ORDER.id: {
          if (isCrossOrder) dispatch(createMarkerOrderRequest({ x, y }));
          else dispatch(createFIFOOrderRequest());
          break;
        }
        case ORDER_TYPES.STANDARD.id: {
          dispatch(createStandardOrderRequest());
          break;
        }
        case ORDER_TYPES.OCO.id: {
          dispatch(createOcoOrderRequest());
          break;
        }
        case ORDER_TYPES.IFD.id: {
          dispatch(createIFDOrderRequest());
          break;
        }
        case ORDER_TYPES.IFO.id: {
          dispatch(createIFOOrderRequest());
          break;
        }
        default: {
          // empty
        }
      }
    },
    [selectedOrderType, dispatch, isCrossOrder],
  );

  const handleExecuteOrder = useCallback(
    ({ clientX, clientY }) => {
      const orderIsValid = validateSelectedManualTradeOrder();
      if (isCreateOrderLoading || !orderIsValid) {
        return;
      }
      if (withoutConfirmation) {
        confirmCallback({ x: Math.round(clientX), y: Math.round(clientY) });
        return;
      }
      dispatch(openManualTradeConfirmation({ callback: confirmCallback, orderType: selectedOrderType }));
    },
    [
      dispatch,
      withoutConfirmation,
      confirmCallback,
      selectedOrderType,
      isCreateOrderLoading,
      validateSelectedManualTradeOrder,
    ],
  );

  useEffect(() => {
    if (!innerIsModalOpen || !selectedOrderType === ORDER_TYPES.MARKET_ORDER.id) return;

    const errorMessage = createOrdersValidationErrors[ORDER_TYPES_BY_ID[selectedOrderType]][COUNT_INPUT]?.errorMessage;

    if (errorMessage !== '') {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: errorMessage,
        }),
      );
    }
    setInnerIsModalOpen(false);
  }, [dispatch, innerIsModalOpen, selectedOrderType, createOrdersValidationErrors]);

  const handleExecuteMarketOrder = useCallback(
    (side, { clientX, clientY }) => {
      if (isCreateOrderLoading) return;

      // Because it is unknown whether to sell or buy until the order button is clicked,
      // At this timing, the sell or buy information is dispatched to the store.
      dispatch(
        changeCreateOrderValues({
          orderType: ORDER_TYPES.MARKET_ORDER.name,
          inputName: BUY_SELL_INPUT,
          value: side,
        }),
      );

      // Verify if there are any errors in the validation that is running every time
      const innerTotalQuantity = oppositeSideTotalQuantity[side];
      const orderIsValid = validateSelectedManualTradeOrder({
        marketOrderSelectedSide: side,
        oppositeSideTotalQuantity: innerTotalQuantity,
      });

      if (!orderIsValid) {
        setInnerIsModalOpen(true);
        return;
      }

      if (withoutConfirmation) {
        confirmCallback({ x: Math.round(clientX), y: Math.round(clientY) });
        return;
      }
      dispatch(openManualTradeConfirmation({ callback: confirmCallback, orderType: selectedOrderType }));
    },
    [
      dispatch,
      withoutConfirmation,
      oppositeSideTotalQuantity,
      confirmCallback,
      selectedOrderType,
      isCreateOrderLoading,
      validateSelectedManualTradeOrder,
    ],
  );

  const handleFillOrderOptions = useCallback(
    (type) => {
      const chartType = BUY_SELL_CHART_ID_BY_ID[type];
      const OrderObject = Object.values(ORDER_TYPES).find((item) => item.id === selectedOrderType);
      saveDefaultValuesFromLocalStorage({ key: KEY_FOR_DEFAULT_SELECT_SIDE, value: chartType });

      batch(() => {
        dispatch(
          changeSelectedSide({
            id: chartType,
          }),
        );
        dispatch(
          changeChartSide({
            id: chartType,
          }),
        );
        Object.values(ORDER_TYPES).forEach(({ name }) =>
          dispatch(
            changeCreateOrderValues({
              orderType: name,
              inputName: BUY_SELL_INPUT,
              value: type,
            }),
          ),
        );
      });

      switch (selectedOrderType) {
        case ORDER_TYPES.STANDARD.id: {
          dispatch(
            changeCreateOrderValues({
              orderType: OrderObject.name,
              inputName: OrderObject.inputs.PRICE,
              value: type === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice,
            }),
          );
          break;
        }
        case ORDER_TYPES.OCO.id: {
          batch(() => {
            dispatch(
              changeCreateOrderValues({
                orderType: OrderObject.name,
                inputName: OrderObject.inputs.LIMIT_PRICE,
                value: type === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice,
              }),
            );
            dispatch(
              changeCreateOrderValues({
                orderType: OrderObject.name,
                inputName: OrderObject.inputs.STOP_PRICE,
                value: type === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice,
              }),
            );
          });
          break;
        }
        case ORDER_TYPES.IFD.id: {
          batch(() => {
            dispatch(
              changeCreateOrderValues({
                orderType: OrderObject.name,
                inputName: OrderObject.inputs.PRICE,
                value: type === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice,
              }),
            );
            dispatch(
              changeCreateOrderValues({
                orderType: OrderObject.name,
                inputName: OrderObject.inputs.SETTLEMENT_PRICE,
                value: type === BUY_SELL_MAIN.SELL.ID ? buyPrice : sellPrice,
              }),
            );
          });
          break;
        }
        case ORDER_TYPES.IFO.id: {
          batch(() => {
            dispatch(
              changeCreateOrderValues({
                orderType: OrderObject.name,
                inputName: OrderObject.inputs.SETTLEMENT_LIMIT_PRICE,
                value: type === BUY_SELL_MAIN.SELL.ID ? buyPrice : sellPrice,
              }),
            );
            dispatch(
              changeCreateOrderValues({
                orderType: OrderObject.name,
                inputName: OrderObject.inputs.SETTLEMENT_STOP_PRICE,
                value: type === BUY_SELL_MAIN.SELL.ID ? buyPrice : sellPrice,
              }),
            );
            dispatch(
              changeCreateOrderValues({
                orderType: OrderObject.name,
                inputName: OrderObject.inputs.PRICE,
                value: type === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice,
              }),
            );
          });
          break;
        }
        default: {
          // empty
        }
      }
    },
    [selectedOrderType, dispatch, sellPrice, buyPrice],
  );

  const optionsRef = useRef(null);
  useEffect(() => {
    // clear order options and scroll to top
    if (!optionsRef) {
      return;
    }
    if (optionsRef.current && optionsRef.current.scrollTo && window.navigator.userAgent.indexOf('Edge') < 0) {
      optionsRef.current.scrollTo(0, 0);
    }
  }, [optionsRef, selectedOrderType]);

  // validate all values on selectedSide change
  useEffect(() => {
    const { validateSelectedManualTradeOrder: validate } = validateSelectedManualTradeOrderRef.current;
    if (validate) {
      validate();
    }
  }, [selectedSide]);

  const SideMenuOption = useMemo(() => {
    switch (selectedOrderType) {
      case ORDER_TYPES.MARKET_ORDER.id: {
        setMiddleHeight('max-content');
        return <SideMenuOptionsMarkerOrder />;
      }
      case ORDER_TYPES.STANDARD.id: {
        setMiddleHeight('auto');
        setBottomHeight('minmax(72px, 1fr)');
        return <SideMenuOptionsStandard />;
      }
      case ORDER_TYPES.OCO.id: {
        setMiddleHeight('auto');
        setBottomHeight('minmax(72px, 1fr)');
        return <SideMenuOptionsOco />;
      }
      case ORDER_TYPES.IFD.id: {
        setMiddleHeight('auto');
        setBottomHeight('minmax(72px, 1fr)');
        return <SideMenuOptionsIfd />;
      }
      case ORDER_TYPES.IFO.id: {
        setMiddleHeight('auto');
        setBottomHeight('minmax(72px, 1fr)');
        return <SideMenuOptionsIfo />;
      }
      default: {
        return null;
      }
    }
  }, [selectedOrderType]);
  const hasValidationErrors = useMemo(
    () =>
      Object.values(createOrdersValidationErrors[ORDER_TYPES_BY_ID[selectedOrderType]]).some(
        ({ hasValidationError }) => hasValidationError,
      ),
    [createOrdersValidationErrors, selectedOrderType],
  );

  const doNotAllowTrade = useMemo(
    () => Number(allowedTradeType) !== ALLOWED_TRADE_TYPES.ALLOWED.ID,
    [allowedTradeType],
  );

  const makeOrderIsDisabled = useMemo(() => {
    const doNotAllowBuySell =
      ordersSettings[ORDER_TYPES_BY_ID[selectedOrderType]][BUY_SELL_INPUT] === BUY_SELL_MAIN.BUY.ID
        ? !allowBuyFlg
        : !allowSellFlg;
    return isCreateOrderLoading || hasValidationErrors || doNotAllowTrade || doNotAllowBuySell;
  }, [
    isCreateOrderLoading,
    hasValidationErrors,
    doNotAllowTrade,
    allowBuyFlg,
    allowSellFlg,
    ordersSettings,
    selectedOrderType,
  ]);

  const notAllowFlg = Number(allowedTradeType) === ALLOWED_TRADE_TYPES.NEW_ORDERS_NOT_ALLOWED.ID;
  const makeOrderIsDisabledForMarketOrder = useMemo(() => {
    const marketIsClosedOnMarketOrder =
      selectedOrderType === ORDER_TYPES.MARKET_ORDER.id && Number(marketStatus) === MARKET_STATUSES.CLOSED.ID;
    return isCreateOrderLoading || doNotAllowTrade || marketIsClosedOnMarketOrder;
  }, [isCreateOrderLoading, doNotAllowTrade, marketStatus, selectedOrderType]);

  const makeOrderIsDisabledForMarketOrderSell = useMemo(() => {
    const marketIsClosedOnMarketOrderSell =
      (notAllowFlg && isCrossOrder) || (notAllowFlg && !isCrossOrder && buyPositionCount === 0);
    return marketIsClosedOnMarketOrderSell || isCreateOrderLoading;
  }, [buyPositionCount, isCreateOrderLoading, notAllowFlg, isCrossOrder]);

  const makeOrderIsDisabledForMarketOrderBuy = useMemo(() => {
    const marketIsClosedOnMarketOrderBuy =
      (notAllowFlg && isCrossOrder) || (notAllowFlg && !isCrossOrder && sellPositionCount === 0);
    return marketIsClosedOnMarketOrderBuy || isCreateOrderLoading;
  }, [sellPositionCount, isCreateOrderLoading, notAllowFlg, isCrossOrder]);

  const buySellPrices = useMemo(() => {
    return (
      <BuySellPrices
        instrumentId={selectedCurrencyId}
        sellPrice={sellPrice}
        buyPrice={buyPrice}
        previousSellPrice={previousSellPrice}
        previousBuyPrice={previousBuyPrice}
        onClick={handleFillOrderOptions}
        isBuyDisabled={!allowBuyFlg}
        isSellDisabled={!allowSellFlg}
      />
    );
  }, [
    selectedCurrencyId,
    sellPrice,
    buyPrice,
    previousSellPrice,
    previousBuyPrice,
    handleFillOrderOptions,
    allowBuyFlg,
    allowSellFlg,
  ]);

  const middleBuySellPrices = useMemo(() => {
    if (selectedOrderType === ORDER_TYPES.MARKET_ORDER.id) {
      return null;
    }
    return <div className={styles.sellBuyPrices}>{buySellPrices}</div>;
  }, [selectedOrderType, buySellPrices]);

  const [tabWidth, setTabWidth] = useState(344 / ((tradeServiceData.options?.length ?? 0) + 1));
  const handleResize = useCallback(
    ({ width }) => {
      const length = tradeServiceData.options?.length ?? 0;
      if (length > 0) {
        setTabWidth((width ?? 0) / length);
      }
    },
    [tradeServiceData.options],
  );

  return (
    <div
      className={classNames('default-bg', styles.sideMenuWrapper)}
      style={{ gridTemplateRows: `max-content max-content ${middleHeight} ${bottomHeight}` }}
    >
      <Tabs
        type="line"
        tabMinWidth={tabWidth}
        scrollWidth={tabWidth}
        items={tradeServiceData.options}
        activeKey={tradeServiceData.activeServiceId}
        onChange={tradeServiceData.onChange}
        onReady={handleResize}
        onResize={handleResize}
      />
      <div className={styles.topWrapper}>
        <div className={styles.currencyWrapper}>
          <CustomSelect
            isLighter
            instanceId="side-menu-currency-pair"
            selectItemId={selectedCurrencyId}
            renderBeforeValue={renderDropdownIcon}
            renderBeforeOption={renderDropdownIcon}
            onChange={changeActiveCurrency}
            options={instrumentOptions}
            width={SELECT_FULL_SIZE_WIDTH}
            menuMinWidth="200px"
            isDarker
          />
        </div>

        <div className={styles.tabContainer}>
          <Tabs
            type="line"
            tabMinWidth={62.39}
            scrollWidth={0}
            items={OPTIONS_ORDER_TYPES}
            activeKey={selectedOrderType}
            onChange={changeSelectedOrderType}
          />
        </div>
        {middleBuySellPrices}
      </div>
      <div className={styles.middleBlockWrapper} ref={optionsRef}>
        {SideMenuOption}
        {selectedOrderType === ORDER_TYPES.MARKET_ORDER.id && (
          <div className={styles.markerOrderPricePanel}>
            <BuySellPrices
              instrumentId={selectedCurrencyId}
              sellPrice={sellPrice}
              buyPrice={buyPrice}
              previousSellPrice={previousSellPrice}
              previousBuyPrice={previousBuyPrice}
              onClick={handleExecuteMarketOrder}
              isNotActiveSell={notAllowFlg ? makeOrderIsDisabledForMarketOrderSell : makeOrderIsDisabledForMarketOrder}
              isNotActiveBuy={notAllowFlg ? makeOrderIsDisabledForMarketOrderBuy : makeOrderIsDisabledForMarketOrder}
              isBuyDisabled={!allowBuyFlg}
              isSellDisabled={!calcedAllowSellFlg}
            />
          </div>
        )}
      </div>
      {selectedOrderType !== ORDER_TYPES.MARKET_ORDER.id && (
        <div className={styles.bottomWrapper}>
          <CustomButton
            color={COLORS.RED}
            width={WIDTH.PERCENTAGE_100}
            onClick={handleExecuteOrder}
            isDisabled={makeOrderIsDisabled}
            isLoading={isCreateOrderLoading}
          >
            {withoutConfirmation ? '注文確定' : '注文確認'}
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default memo(SideMenu);
