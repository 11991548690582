import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { tutorialDetailChartArea } from '../../../../../../constants/tutorial/classNames';
import ProfitLossChart from '../../../../../../components/ProfitLossChart';
import { Spin } from '../../../../../../components';
import styles from '../../../PortfolioAutoTradeDetail/components/DetailChart/detailChart.module.scss';

const chartMargin = {
  top: 0,
  left: 0,
  right: 20,
  bottom: 0,
};

const TutorialProfitLossChart = () => {
  const profitLossExecutionsDataIsLoading = useSelector((state) => state.portfolio.profitLossExecutionsDataIsLoading);

  const chartData = useSelector((state) => state.webTutorial.profitLossChartData);

  return (
    <div className={classNames(styles.chart, tutorialDetailChartArea)}>
      {profitLossExecutionsDataIsLoading ? (
        <Spin className={styles.loader} />
      ) : (
        <ProfitLossChart chartMargin={chartMargin} data={chartData} withTimeLabels useDefaultYAxisWidth />
      )}
    </div>
  );
};

export default memo(TutorialProfitLossChart);
