// eslint-disable-next-line import/no-unresolved
import { useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { publishedLabSimulationSelector } from '../redux/labs';
import { normalizeLabStrategy, normalizeSelectionStrategy } from '../services/select';
import { useSelectedStrategyGroup } from './useSelectedStrategyGroup';
import { useSelectedShareItem } from './useSelectedShareItem';

export const useSelectedStrategyInfoList = (termId) => {
  const selectedShareItem = useSelectedShareItem();
  const [selectedStrategyGroup] = useSelectedStrategyGroup();
  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const selectedTermId = termId ?? defaultSelectionTermId;
  const { [selectedTermId]: { strategyList: strategyListSelection } = {} } = useSelector(
    (state) => state.autoSelect.selectionModalInfo,
  );
  const { result } = useSelector(publishedLabSimulationSelector);
  const { strategyList: strategyListLab } = result || {};
  const { serviceId } = selectedStrategyGroup || {};
  return useMemo(() => {
    if (selectedShareItem) {
      return [(strategyListLab ?? []).map((strategy) => normalizeLabStrategy(strategy, serviceId))];
    }
    return [(strategyListSelection ?? []).map((strategy) => normalizeSelectionStrategy(strategy)), selectedTermId];
  }, [selectedShareItem, serviceId, strategyListSelection, strategyListLab, selectedTermId]);
};
