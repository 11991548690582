/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useContext, useRef } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useDispatch } from 'react-redux';
import { isEqual } from '../utils';
import { SelectedStrategyContext } from '../contexts';
import { changeSelectedInstrumentIdAutoTrade } from '../redux/actions/autoSelectActions';
import { useSelectedStrategyGroup } from './useSelectedStrategyGroup';

export const useSelectedStrategy = () => {
  const dispatch = useDispatch();
  const [strategy, setData] = useContext(SelectedStrategyContext);
  const prevRef = useRef(strategy);
  const [strategyGroup] = useSelectedStrategyGroup();
  const setStrategy = useCallback(
    (param) => {
      const { serviceId, instrumentId, strategyId } = param || {};
      dispatch(changeSelectedInstrumentIdAutoTrade({ serviceId, instrumentId, strategyId }));
      if (isEqual(param, prevRef.current)) {
        return;
      }
      if (param == null) {
        setData(null);
      } else {
        const { selectionId, selectionVersion, labId } = strategyGroup || {};
        const newStrategy = { serviceId, instrumentId, strategyId, selectionId, selectionVersion, labId };
        setData(newStrategy);
      }
      prevRef.current = param;
    },
    [dispatch, strategyGroup, setData],
  );
  return [strategy, setStrategy];
};
