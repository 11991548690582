import {
  SOCKET_CONNECTION_REQUEST,
  SOCKET_DISCONNECT_REQUEST,
  HANDLE_ORDER_SOCKET_MESSAGE,
  HANDLE_ACCOUNT_SOCKET_MESSAGE,
  HANDLE_SETTINGS_SOCKET_MESSAGE,
  HANDLE_AP_SOCKET_MESSAGE,
  OPEN_SOCKET_ERROR_MODAL,
  CLOSE_SOCKET_ERROR_MODAL,
  RECONNECT_SOCKET_REQUEST,
  HANDLE_CART_ITEMS_SOCKET_MESSAGE,
  HANDLE_TECHNICAL_BUILDER_SOCKET_MESSAGE,
} from '../actionConstants/socketConstants';

export const socketConnectionRequest = ({ isPublic = false } = {}) => ({
  type: SOCKET_CONNECTION_REQUEST,
  payload: { isPublic },
});
export const socketDisconnectRequest = () => ({ type: SOCKET_DISCONNECT_REQUEST });

export const handleOrderSocketMessage = ({ message }) => ({
  type: HANDLE_ORDER_SOCKET_MESSAGE,
  payload: { message },
});

export const handleAccountSocketMessage = ({ event, serviceId }) => ({
  type: HANDLE_ACCOUNT_SOCKET_MESSAGE,
  payload: { event, serviceId },
});

export const handleSettingsSocketMessage = ({ type, status, serviceId, instrumentId }) => ({
  type: HANDLE_SETTINGS_SOCKET_MESSAGE,
  payload: { type, status, serviceId, instrumentId },
});

export const handleAPSocketMessage = ({ apGroupId, serviceId, technicalBuilderId, event }) => ({
  type: HANDLE_AP_SOCKET_MESSAGE,
  payload: { apGroupId, serviceId, technicalBuilderId, event },
});

export const handleTechnicalBuilderSocketMessage = ({ apGroupId, serviceId, technicalBuilderId, status }) => ({
  type: HANDLE_TECHNICAL_BUILDER_SOCKET_MESSAGE,
  payload: { apGroupId, serviceId, technicalBuilderId, status },
});

export const handleCartItemsSocketMessage = ({ itemCount }) => ({
  type: HANDLE_CART_ITEMS_SOCKET_MESSAGE,
  payload: { itemCount },
});

export const openSocketErrorModal = ({ isPublic = false }) => ({
  type: OPEN_SOCKET_ERROR_MODAL,
  payload: { isPublic },
});
export const closeSocketErrorModal = () => ({ type: CLOSE_SOCKET_ERROR_MODAL });

export const reconnectSocketRequest = ({ isPublic }) => ({ type: RECONNECT_SOCKET_REQUEST, payload: { isPublic } });
