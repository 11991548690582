import { memo, useCallback } from 'react';
import { SSO_INVAST_AFFILIATE_REDIRECT_URL } from 'shared-modules/constants';
import { openNewSameTab } from '../../../../services';
import styles from './invastAffiliate.module.scss';

export const InvastAffiliate = memo(() => {
  const handleClick = useCallback(() => {
    openNewSameTab(`/${SSO_INVAST_AFFILIATE_REDIRECT_URL}`);
  }, []);
  return (
    <div className={styles.container}>
      <button className={styles.link} type="button" onClick={handleClick}>
        <div className={styles.text}>🎉お友達紹介 3,000円プレゼント!</div>
      </button>
    </div>
  );
});
