import { memo } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import { SYSTEM_NOTIFICATION_TYPES } from 'shared-modules/constants/core';
import { useSystemNotificationModalInfo } from 'shared-modules/hooks/system';
import { useLossCutMessages } from 'shared-modules/hooks/service';
import { useSemiBlockingModal } from 'shared-modules/hooks/modal';
import { Button, Modal } from '../../../../components';
import styles from './lossCutModal.module.scss';

export const LossCutModal = memo(({ visible, isBlock, isBlocked, timestamp }) => {
  const { title, line1, line2, buttonLabel } = useLossCutMessages();
  const { isOpen, onClose } = useSemiBlockingModal({
    visible,
    isBlock,
    isBlocked,
    timestamp,
    ready: true,
    disabled: false,
  });
  useSystemNotificationModalInfo({
    notificationType: SYSTEM_NOTIFICATION_TYPES.LOSS_CUT,
    visible: isOpen,
  });
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      size={MODAL_SIZES.WIDTH_400_600}
      isOutsideClickDontClose
      withoutCloseButton
      isOverlap
      isOverlayModal
    >
      <div className={styles.wrapper}>
        <div className={styles.errorMessage}>
          {line1}
          <br />
          {line2}
        </div>
        <div className={styles.buttonsContainer}>
          {!isBlock && (
            <Button onClick={onClose} secondary>
              {buttonLabel}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
});

LossCutModal.propTypes = {
  visible: PropTypes.bool,
  isBlock: PropTypes.bool,
  isBlocked: PropTypes.bool,
  timestamp: PropTypes.number,
};

LossCutModal.defaultProps = {
  visible: false,
  isBlock: false,
  isBlocked: false,
  timestamp: undefined,
};
