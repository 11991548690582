import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './helpHalfModal.module.scss';

const HalfModal = ({ show, onClick, onKeyDown, children }) => {
  const dialogRef = useRef(null);
  const closeButtonRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 27) {
        onClick();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClick]);

  useEffect(() => {
    const handleFocus = () => {
      if (dialogRef.current && !dialogRef.current.contains(document.activeElement)) {
        closeButtonRef.current?.focus();
      }
    };
    document.addEventListener('focus', handleFocus, true);
    return () => {
      document.removeEventListener('focus', handleFocus, true);
    };
  }, []);

  useEffect(() => {
    if (show) {
      setTimeout(() => setShowModal(true));
    } else {
      setShowModal(false);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const content = (
    <div className={styles.overlayWrapper}>
      <div
        role="presentation"
        className={classNames(styles.overlay, { [styles.visible]: showModal })}
        onClick={onClick}
        onKeyDown={onKeyDown}
      />
      <div
        ref={dialogRef}
        role="dialog"
        aria-modal
        className={classNames(styles.halfModal, { [styles.hide]: !showModal })}
      >
        <div className={styles.header}>
          <i
            ref={closeButtonRef}
            role="button"
            tabIndex={0}
            className={classNames('material-icons-outlined', styles.close)}
            onClick={onClick}
            onKeyDown={onKeyDown}
          >
            clear
          </i>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
  return createPortal(content, document.body);
};

HalfModal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

export const HelpHalfModal = memo(({ className, content, logicImageSrc, logicName }) => {
  const [show, setShow] = useState(false);
  const handleClick = useCallback(() => setShow((prevShow) => !prevShow), []);
  const handleKeyDown = useKeyDownHandler(handleClick);
  const handleClickClose = useCallback(() => setShow(false), []);
  const handleKeyDownClose = useKeyDownHandler(handleClickClose);

  const isTextContent = typeof content === 'string';
  const textContent = isTextContent ? (
    <span className={styles.imgInfoDesc} style={{ whiteSpace: 'pre-line' }}>
      {content?.replace(/<br ?\/?>/g, '\n')}
    </span>
  ) : undefined;

  return (
    <div className={classNames(styles.container, className)}>
      <i
        aria-hidden
        className={classNames('material-icons-outlined', styles.icon)}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        help_outline
      </i>
      {show && (
        <HalfModal show={show} onClick={handleClickClose} onKeyDown={handleKeyDownClose}>
          <div className={styles.imgInfoWrapper}>
            {logicImageSrc && <img src={logicImageSrc} alt="" className={styles.imgInfoImage} />}
            <div className={styles.imgInfoTextWrapper}>
              <span className={styles.imgInfoTitle}>{`${logicName}`}</span>

              {isTextContent ? textContent : content}
            </div>
          </div>
        </HalfModal>
      )}
    </div>
  );
});

HelpHalfModal.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  logicImageSrc: PropTypes.string,
  logicName: PropTypes.string.isRequired,
};

HelpHalfModal.defaultProps = {
  className: undefined,
  logicImageSrc: undefined,
};
