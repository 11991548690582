/* eslint-disable */
module.exports = {
  REST_API_URL: 'https://prd.api.ta23.invast.jp/',
  WS_API_URL: 'https://prd.ws.ta23.invast.jp/socket/',
  AMPLIFY_AUTH_USER_POOL_ID: 'ap-northeast-1_1WafKtVYm',
  AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID: '554a450h1200adjoq50gd9veo8',
  MY_PAGE_HOME_URL: 'https://mypage.invast.jp/portal/home/',
  AGREEMENT_URL: 'https://mypage.invast.jp/cd/tool/triauto/index.html',
  FORGOT_PASSWORD_URL: 'https://mypage.invast.jp/portal/login/',
  BUGSNAG_MOBILE_RELEASE_STAGE: 'Production',
  BUGSNAG_WEB_RELEASE_STAGE: 'Production',
  MOBILE_COMMON_PREFIX_URL: 'https://web.triauto.invast.jp/mobile',
  MOBILE_CHART_URL: 'https://web.triauto.invast.jp/mobile-chart',
  MY_PAGE_REDIRECT_URL: 'https://web.triauto.invast.jp/sso-my-page',
  INVAST_AFFILIATE_REDIRECT_URL: 'https://web.triauto.invast.jp/sso-invast-affiliate',
  PAYMENT_REDIRECT_URL: 'https://web.triauto.invast.jp/sso-payment',
  PAYMENT_DEPOSIT_REDIRECT_URL: 'https://web.triauto.invast.jp/sso-payment-deposit',
  PAYMENT_TRANSFER_REDIRECT_URL: 'https://web.triauto.invast.jp/sso-payment-transfer',
  PAYMENT_WITHDRAWAL_REDIRECT_URL: 'https://web.triauto.invast.jp/sso-payment-withdrawal',
  PAYMENT_DETAIL_REDIRECT_URL: 'https://web.triauto.invast.jp/sso-payment-detail',
  QUICK_DEPOSIT_REDIRECT_URL: 'https://web.triauto.invast.jp/sso-quick-deposit',
  QUICK_DEPOSIT_SUCCESS_URL: 'https://web.triauto.invast.jp/cash-deposit-success',
  QUICK_DEPOSIT_FAILED_URL: 'https://web.triauto.invast.jp/cash-deposit-failed',
  MONEY_HATCH_REDIRECT_URL: 'https://web.triauto.invast.jp/sso-money-hatch',
  MOBILE_BUILDER_CHART_URL: 'https://web.triauto.invast.jp/builder-chart',
  MOBILE_TECH_BUILDER_CHART_URL: 'https://web.triauto.invast.jp/mobile/tech-builder-chart',
  MOBILE_AUTO_SELECT_PRICE_CHART_URL: 'https://web.triauto.invast.jp/mobile/auto-select-price-chart',
  OTHER_WINDOW_AUTO_SELECT_DETAIL_CHART_URL: 'https://web.triauto.invast.jp/other-window-auto-select-detail-chart',
  MOBILE_MARKET_ORDER_CHART_URL: 'https://web.triauto.invast.jp/mobile/market-order-chart',
  MOBILE_LAB_PRICE_CHART_URL: 'https://web.triauto.invast.jp/mobile/lab-price-chart',
  URL_SSO_DEPOSIT_RT: 'URL_SSO_DEPOSIT_RT',
  URL_SSO_PAYMENT_DEPOSIT: 'URL_SSO_PAYMENT_DEPOSIT',
  URL_SSO_TRANSFER_WITHDRAWAL: 'URL_SSO_TRANSFER_WITHDRAWAL',
  URL_SSO_WITHDRAWAL: 'URL_SSO_WITHDRAWAL',
  URL_SSO_PAYMENT_HISTORY: 'URL_SSO_PAYMENT_HISTORY',
  URL_SSO_PAYMENT_VIRTUAL: 'URL_SSO_PAYMENT_VIRTUAL',
  FORGOT_EMERGENCY_ID_URL: 'https://mypage.invast.jp/portal/confirm/id',
  FORGOT_EMERGENCY_PASSWORD_URL: 'https://mypage.invast.jp/portal/password/unlock',
  LOGIN_QUESTION_URL: 'https://www.invast.jp/blogs/faqs/account/login/',
  OPEN_ACCOUNT: 'https://www.invast.jp/open-account/',
  TWITTER_URL: 'https://www.twitter.com/triauto_invast',
  FACEBOOK_URL: 'https://ja-jp.facebook.com/invastsecurities',
  YOUTUBE_URL: 'https://www.youtube.com/user/INVASTSecurities',
  LINE_URL: 'https://line.me/R/ti/p/@816mszlr',
  MAINTENANCE_PAGE_URL: 'https://www.invast.jp/cd/triauto/system/trade_tool/maintenance/',
  MOBILE_FORGET_PASSWORD_URL: 'https://mypage.invast.jp/portal/login/',
  MY_PAGE_BASE_URL: 'https://mypage.invast.jp/',
  LAB_POST_CONDITION_LINK: 'https://www.invast.jp/blogs/202112_builder_1',
  LAB_EXTERNAL_LINK_URL: 'https://www.invast.jp/blogs/202112_builder_2',
  MAINTENANCE_INFOPAGE_URL: 'https://www.invast.jp/maintenance/',
  AMPLIFY_AUTH_REGION: 'ap-northeast-1',
  AMPLIFY_AUTH_AUTHENTICATION_FLOW_TYPE: 'CUSTOM_AUTH',
  BUGSNAG_WEB_API_KEY: '93e008b261549b4dc1948372579b4083',
  BUGSNAG_MOBILE_API_KEY: '9119b6e35f440f9a2eca219994b52fd8',
  DEBUG: 'false',
  MANUAL_PC_LINK: 'https://www.invast.jp/triauto/rules/pdf/manual_pc.pdf',
  MANUAL_SP_LINK: 'https://www.invast.jp/triauto/rules/pdf/manual_sp.pdf',
  MANUAL_FX_LINK: 'https://www.invast.jp/blogs/tafx/manual/',
  MANUAL_ETF_LINK: 'https://www.invast.jp/blogs/etf/manual/',
  MANUAL_FX_SP_LINK: 'https://www.invast.jp/blogs/tafx/manual_sp/',
  MANUAL_ETF_SP_LINK: 'https://www.invast.jp/blogs/etf/manual_sp/',
  BLOG_LINK: 'https://www.invast.jp/triauto/blogs/',
  FAQ_LINK: 'https://www.invast.jp/triauto/blogs/faqs/',
  INQUIRY_LINK: 'https://www.invast.jp/contact/',
  NOTICE_LINK: 'https://www.invast.jp/triauto/blogs/news/',
  INVAST_HOME_LINK: 'https://www.invast.jp/triauto/',
  FX_SWAP_SUMMARY_LINK: 'https://www.invast.jp/triauto/market/?disp=swap',
  ETF_INTEREST_RATE_TABLE_LINK: 'https://www.invast.jp/triauto/market/?disp=interest',
  CFD_INTEREST_RATE_TABLE_LINK: 'https://www.invast.jp/triauto/market/?disp=cfd-interest',
  IOS_STORE_LINK: 'https://apps.apple.com/jp/app/toraiotofx/id6467229971',
  ANDROID_STORE_LINK: 'https://play.google.com/store/apps/details?id=com.invast.triauto23.android.tradechannel',
  QUICK_DEPOSIT_INFO_FX_LINK: 'https://www.invast.jp/triauto/rules/#fx-instant_deposit',
  QUICK_DEPOSIT_INFO_ETF_LINK: 'https://www.invast.jp/triauto/rules/#etf-instant_deposit',
  QUICK_DEPOSIT_INFO_CFD_LINK: 'https://www.invast.jp/triauto/rules/#cfd-instant_deposit',
  STAGE: 'PRODUCTION',
  };
