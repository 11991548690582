/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import {
  ALLOWED_STATUS_FOR_CHANGES,
  BUY_SELL_MAIN,
  BUY_SELL_VALUE,
  EXPIRATION_TYPE_MAIN,
  FULL_TIME_REG,
  FX,
  INTEGER_VALIDATION_REG,
  NEW_ORDER_TYPES,
  NUMBER_VALIDATION_REG,
  ORDER_CHANGING_MANUAL_TRADE,
  ORDER_METHOD_MAIN,
  ORDER_SETTLEMENT_TYPE_NAMES,
  ORDERS_COMPOSITE_TYPES_MAIN,
  TRADE_METHODS,
  VALIDATION_ERROR_EMPTY_FIELD,
  VALIDATION_SETTLEMENT_TIME_ERROR_MESSAGE,
  VALIDATION_TIME_ERROR_MESSAGE,
} from '../../constants';
import {
  CHANGE_DATE_INPUT,
  CHANGE_ORDER_OCO2_PRICE_INPUT,
  CHANGE_ORDER_PRICE_INPUT,
  CHANGE_ORDER_THEN_OCO2_PRICE_INPUT,
  CHANGE_ORDER_THEN_PRICE_INPUT,
  CHANGE_SETTLEMENT_DATE_INPUT,
  CHANGE_SETTLEMENT_TIME_INPUT,
  CHANGE_TIME_INPUT,
} from '../../constants/manualTrade';
import { resetChangeOrderErrors, updateChangeOrderValidationErrors } from '../../redux/actions/manualTradeActions';
import { useGetInstrumentDisplayName } from '../../hooks';
import {
  checkIsWebApp,
  createDateStringByDateAndTime,
  expireTimeFunc,
  getPriceStep,
  getRoundedPlusOneHourCurrentTime,
  getServiceQuantityUnit,
  roundExactlyOnPrecisionMatching,
  validateSelectedTime,
  validateSettlementSelectedTime,
} from '../index';
import {
  useInstrumentSettings,
  usePricesForBuySell,
  useTimeChangingHandler,
  useValidateCommonInputByRules,
} from './index';

const {
  SETTLEMENT_COMPOSITE_TYPES,
  SETTLEMENT_PRICE_INPUTS: SETTLEMENT_PRICE_INPUTS_ORDER,
  PRICE_INPUTS: PRICE_INPUTS_ORDER,
  OCO_PRICE_INPUTS,
  PRICE_VALUES,
} = ORDER_CHANGING_MANUAL_TRADE;

const createDataObj = (label, value, formattedValue) => ({
  label,
  value,
  formattedValue,
});

export const usePreparedOrderInfoForEdit = (isOpen) => {
  const orderInfo = useSelector((state) => state.manualTrade.selectedOrderInfo, isEqual);
  const serviceId = useSelector((state) => state.auth.serviceId);
  const getInstrumentDisplayName = useGetInstrumentDisplayName();

  return useMemo(() => {
    if (!orderInfo.length || (!isOpen && checkIsWebApp())) return {};

    return orderInfo.reduce((acc, item) => {
      const compositeTypeNum = Number(item.compositeType);
      const isNotManual = TRADE_METHODS.MANUAL.ID !== Number(item.tradeMethod);

      if (
        [
          ORDERS_COMPOSITE_TYPES_MAIN.SINGLE.ID,
          ORDERS_COMPOSITE_TYPES_MAIN.IFD.ID,
          ORDERS_COMPOSITE_TYPES_MAIN.OCO1.ID,
          ORDERS_COMPOSITE_TYPES_MAIN.IFO.ID,
        ].includes(compositeTypeNum)
      ) {
        const uiCompositeTypeName =
          compositeTypeNum === ORDERS_COMPOSITE_TYPES_MAIN.OCO1.ID ? 'OCO' : item.compositeTypeName;

        const quantityUnits = getServiceQuantityUnit(serviceId);
        const instrumentDisplayName = getInstrumentDisplayName(item.instrumentId, serviceId);

        return {
          ...acc,
          apName: createDataObj('注文名', item.apName),
          isCloseOrder: item.isClose,
          closeOrderLabel: item.isClose ? '決済' : '新規',
          instrumentId: createDataObj('銘柄', item.instrumentId, instrumentDisplayName ?? '-'),
          side: createDataObj('売買', item.side, BUY_SELL_VALUE[item.side] ?? '-'),
          price: item.price,
          type: item.type,
          typeName: createDataObj('注文条件', '', NEW_ORDER_TYPES[item.type] || '-'),
          quantity: createDataObj(`数量(${quantityUnits})`, item.quantity, item.quantity || '-'),
          compositeType: compositeTypeNum,
          compositeTypeName: createDataObj('注文種別', '', uiCompositeTypeName ?? '-'),
          expireType: isNotManual ? EXPIRATION_TYPE_MAIN.INFINITY.ID : item.expireType,
          isDisabledExpireType: isNotManual,
          expireTime: item.expireTime,
          tradeMethod: Number(item.tradeMethod),
          newOrderReadonly: !ALLOWED_STATUS_FOR_CHANGES.includes(Number(item.status)),
        };
      }

      if (compositeTypeNum === ORDERS_COMPOSITE_TYPES_MAIN.OCO2.ID) {
        const apOrderName = item.apGroupId ? acc.apName.value : `${acc.apName.value}\n${item.apName}`;

        return {
          ...acc,
          apName: createDataObj('注文名', apOrderName),
          typeName: createDataObj(
            '注文条件',
            '',
            `${acc.typeName.formattedValue}・${NEW_ORDER_TYPES[item.type] || '-'}`,
          ),
          oco2Price: item.price,
          oco2Type: item.type,
        };
      }

      if ([ORDERS_COMPOSITE_TYPES_MAIN.THEN.ID, ORDERS_COMPOSITE_TYPES_MAIN.THEN_OCO.ID].includes(compositeTypeNum)) {
        return {
          ...acc,
          thenPrice: item.price,
          thenType: item.type,
          thenTypeName: NEW_ORDER_TYPES[item.type] || '-',
          thenSide: item.side,
          thenExpireType: isNotManual ? EXPIRATION_TYPE_MAIN.INFINITY.ID : item.expireType,
          thenExpireTime: item.expireTime,
        };
      }

      if (compositeTypeNum === ORDERS_COMPOSITE_TYPES_MAIN.THEN_OCO2.ID) {
        return {
          ...acc,
          thenTypeName: `${acc.thenTypeName}・${NEW_ORDER_TYPES[item.type] || '-'}`,
          thenOco2Price: item.price,
          thenOco2Type: item.type,
        };
      }

      return acc;
    }, {});
  }, [orderInfo, isOpen, serviceId, getInstrumentDisplayName]);
};

export const useDynamicOrderInfo = (isOpen) => {
  const dispatch = useDispatch();

  /**
   * local state
   */
  const [innerPrice, setInnerPrice] = useState(0);
  const [innerExpireType, changeInnerExpireType] = useState(0);
  const [innerSelectedDate, setInnerSelectedDate] = useState(new Date());
  const [innerSelectedTime, changeInnerSelectedTime] = useState(getRoundedPlusOneHourCurrentTime());
  const onInnerTimeChange = useTimeChangingHandler(changeInnerSelectedTime);
  const [innerOco2Price, setInnerOco2Price] = useState(0);
  const [innerThenPrice, setInnerThenPrice] = useState(0);
  const [innerThenExpireType, changeInnerThenExpireType] = useState(0);
  const [innerThenSelectedDate, changeInnerThenSelectedDate] = useState(new Date());
  const [innerThenSelectedTime, changeInnerThenSelectedTime] = useState(getRoundedPlusOneHourCurrentTime());
  const onInnerThenTimeChange = useTimeChangingHandler(changeInnerThenSelectedTime);
  const [innerThenOco2Price, setInnerThenOco2Price] = useState(0);
  const [initialChangingValues, setInitialChangingValues] = useState({});

  /**
   * store selectors
   */
  const validationMessages = useSelector((state) => state.manualTrade.changeOrdersValidationErrors);
  const loadingOrderInfo = useSelector((state) => state.manualTrade.loadingSelectedOrderInfo);
  const closedPositionInfo = useSelector((state) => state.manualTrade.selectedOrderPositionInfo);
  const serviceId = useSelector((state) => state.auth.serviceId);

  /**
   * custom hooks
   */
  const {
    instrumentId = {},
    tradeMethod,
    price,
    side = {},
    type,
    compositeType,
    expireType,
    isDisabledExpireType,
    expireTime,
    oco2Price,
    oco2Type,
    newOrderReadonly,
    thenPrice,
    thenType,
    thenSide,
    thenExpireType,
    thenExpireTime,
    thenOco2Price,
    thenOco2Type,
  } = usePreparedOrderInfoForEdit(isOpen);

  const {
    validateAskLimitPrice,
    validateAskStopPrice,
    validateBidLimitPrice,
    validateBidStopPrice,
    validateIFDBuyLimitSellLimitPrice,
    validateIFDBuyLimitSellStopPrice,
    validateIFDBuyStopSellLimitPrice,
    validateIFDBuyStopSellStopPrice,
    validateIFDSellLimitBuyLimitPrice,
    validateIFDSellLimitBuyStopPrice,
    validateIFDSellStopBuyLimitPrice,
    validateIFDSellStopBuyStopPrice,
    validateIFOBuyLimitSellLimitSellStop,
    validateIFOBuyStopSellLimitSellStop,
    validateIFOSellLimitBuyLimitBuyStop,
    validateIFOSellStopBuyLimitBuyStop,
  } = useValidateCommonInputByRules(instrumentId.value);

  const { sellPrice, buyPrice } = usePricesForBuySell({
    currencyPair: instrumentId.value,
  });

  /**
   * refs
   */
  const priceInputsChangeRef = useRef({});
  const validateAllInputsRef = useRef({});

  /**
   * calculated memo data
   */
  const orderData = useMemo(
    () => ({
      innerPrice,
      innerExpireType,
      innerSelectedDate,
      innerSelectedTime,
      innerOco2Price,
      innerThenPrice,
      innerThenExpireType,
      innerThenSelectedDate,
      innerThenSelectedTime,
      innerThenOco2Price,
    }),
    [
      innerPrice,
      innerExpireType,
      innerSelectedDate,
      innerSelectedTime,
      innerOco2Price,
      innerThenPrice,
      innerThenExpireType,
      innerThenSelectedDate,
      innerThenSelectedTime,
      innerThenOco2Price,
    ],
  );

  const { pricePrecision } = useInstrumentSettings(instrumentId.value, tradeMethod);

  // TODO CFD FXかそうでないかの判定で問題ないか要確認
  const priceStep = useMemo(() => {
    if (serviceId === FX) {
      return getPriceStep(instrumentId.value);
    }
    return pricePrecision;
  }, [instrumentId.value, serviceId, pricePrecision]);
  const currentDate = useMemo(() => new Date(), []);

  const validationErrors = useMemo(
    () =>
      Object.entries(validationMessages).reduce((messages, [inputName, info]) => {
        if (info.hasValidationError) {
          messages.push({ inputName, errorMessage: info.errorMessage });
        }
        return messages;
      }, []),
    [validationMessages],
  );

  const isDisabledSubmit = useMemo(() => {
    const hasNoChanges = Object.entries(initialChangingValues).every(([fieldName, value]) => {
      if (
        (fieldName === 'innerSelectedDate' || fieldName === 'innerSelectedTime') &&
        initialChangingValues.innerExpireType !== EXPIRATION_TYPE_MAIN.CUSTOM.ID
      ) {
        return true;
      }
      if (
        (fieldName === 'innerThenSelectedDate' || fieldName === 'innerThenSelectedTime') &&
        initialChangingValues.innerThenExpireType !== EXPIRATION_TYPE_MAIN.CUSTOM.ID
      ) {
        return true;
      }

      const compareInputValue = ['innerPrice', 'innerThenPrice', 'innerOco2Price', 'innerThenOco2Price'].includes(
        fieldName,
      )
        ? Number(orderData[fieldName])
        : orderData[fieldName];

      return value === compareInputValue;
    });
    return loadingOrderInfo || hasNoChanges || Boolean(validationErrors.length);
  }, [orderData, loadingOrderInfo, validationErrors, initialChangingValues]);

  const settlementCondition = useMemo(
    () => SETTLEMENT_COMPOSITE_TYPES.includes(Number(compositeType)),
    [compositeType],
  );

  const isNotAP = useMemo(() => tradeMethod !== TRADE_METHODS.AP.ID, [tradeMethod]);

  /**
   * validation handlers
   */
  const priceInputsRequiresValidation = useMemo(
    () => ({
      [CHANGE_ORDER_PRICE_INPUT]: !newOrderReadonly,
      [CHANGE_ORDER_THEN_PRICE_INPUT]: SETTLEMENT_COMPOSITE_TYPES.includes(Number(compositeType)),
      [CHANGE_ORDER_OCO2_PRICE_INPUT]: Number(compositeType) === ORDERS_COMPOSITE_TYPES_MAIN.OCO1.ID,
      [CHANGE_ORDER_THEN_OCO2_PRICE_INPUT]:
        SETTLEMENT_COMPOSITE_TYPES.includes(Number(compositeType)) && Boolean(thenOco2Price),
    }),
    [newOrderReadonly, compositeType, thenOco2Price],
  );

  const validateDate = useCallback(
    (value) => {
      let result;
      if (!value) {
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_DATE_INPUT,
            errorMessage: VALIDATION_ERROR_EMPTY_FIELD,
            hasValidationError: true,
          }),
        );
        result = false;
      } else {
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_DATE_INPUT,
            errorMessage: '',
            hasValidationError: false,
          }),
        );
        result = true;
      }
      return result;
    },
    [dispatch],
  );

  const validateTime = useCallback(
    (time) => {
      if (innerExpireType !== EXPIRATION_TYPE_MAIN.CUSTOM.ID) {
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_TIME_INPUT,
            errorMessage: '',
            hasValidationError: false,
          }),
        );
        return true;
      }

      let result;
      if (!String(time).match(FULL_TIME_REG)) {
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_TIME_INPUT,
            errorMessage: VALIDATION_ERROR_EMPTY_FIELD,
            hasValidationError: true,
          }),
        );
        result = false;
      } else if (!innerSelectedDate || !validateSelectedTime({ date: innerSelectedDate, time })) {
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_TIME_INPUT,
            errorMessage: VALIDATION_TIME_ERROR_MESSAGE,
            hasValidationError: true,
          }),
        );
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_DATE_INPUT,
            errorMessage: VALIDATION_TIME_ERROR_MESSAGE,
            hasValidationError: true,
          }),
        );
        result = false;
      } else {
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_TIME_INPUT,
            errorMessage: '',
            hasValidationError: false,
          }),
        );
        result = true;
      }
      return result;
    },
    [dispatch, innerSelectedDate, innerExpireType],
  );

  useEffect(() => {
    if (
      isNotAP &&
      innerExpireType !== EXPIRATION_TYPE_MAIN.CUSTOM.ID &&
      initialChangingValues.innerExpireType !== EXPIRATION_TYPE_MAIN.CUSTOM.ID
    ) {
      setInnerSelectedDate(new Date());
      changeInnerSelectedTime(getRoundedPlusOneHourCurrentTime());
    }
  }, [innerExpireType, isNotAP, initialChangingValues]);

  useEffect(() => {
    if (
      isNotAP &&
      innerThenExpireType !== EXPIRATION_TYPE_MAIN.CUSTOM.ID &&
      initialChangingValues.innerExpireType !== EXPIRATION_TYPE_MAIN.CUSTOM.ID
    ) {
      changeInnerThenSelectedDate(new Date());
      changeInnerThenSelectedTime(getRoundedPlusOneHourCurrentTime());
    }
  }, [innerThenExpireType, isNotAP, initialChangingValues]);

  useEffect(() => {
    if (isNotAP && innerSelectedDate && validateTime(innerSelectedTime)) {
      dispatch(
        updateChangeOrderValidationErrors({
          inputName: CHANGE_DATE_INPUT,
          errorMessage: '',
          hasValidationError: false,
        }),
      );
    }
  }, [validateTime, innerSelectedDate, innerSelectedTime, isNotAP, dispatch]);

  const changeInnerSelectedDate = useCallback(
    (value) => {
      validateDate(value);

      setInnerSelectedDate(value);
    },
    [validateDate],
  );

  useEffect(() => {
    if (isNotAP) {
      validateTime(innerSelectedTime);
    }
  }, [innerSelectedTime, validateTime, isNotAP]);

  const validateSettlementExpireTime = useCallback(
    ({ expirationType, date, time, settlementType, settlementDate, settlementTime }) => {
      let result;

      let settlementExpireDateErrorMessage;
      let settlementExpireTimeErrorMessage;

      const settlementExpireDateIsExist = settlementDate;
      const settlementExpireTimeIsExist = String(settlementTime).match(FULL_TIME_REG);

      if (!settlementExpireDateIsExist) {
        settlementExpireDateErrorMessage = VALIDATION_ERROR_EMPTY_FIELD;
        result = false;
      }

      if (!settlementExpireTimeIsExist) {
        settlementExpireTimeErrorMessage = VALIDATION_ERROR_EMPTY_FIELD;
        result = false;
      }

      if (settlementExpireDateIsExist && settlementExpireTimeIsExist) {
        result = validateSettlementSelectedTime({
          expirationType,
          expirationDateWithTime: createDateStringByDateAndTime(date, time),
          settlementType,
          settlementTypeWithDate: createDateStringByDateAndTime(settlementDate, settlementTime),
          serviceId,
        });
      }

      if (!result) {
        settlementExpireDateErrorMessage = settlementExpireDateErrorMessage || VALIDATION_SETTLEMENT_TIME_ERROR_MESSAGE;
        settlementExpireTimeErrorMessage = settlementExpireTimeErrorMessage || VALIDATION_SETTLEMENT_TIME_ERROR_MESSAGE;
      }

      dispatch(
        updateChangeOrderValidationErrors({
          inputName: CHANGE_SETTLEMENT_DATE_INPUT,
          errorMessage: settlementExpireDateErrorMessage || '',
          hasValidationError: !result,
        }),
      );
      dispatch(
        updateChangeOrderValidationErrors({
          inputName: CHANGE_SETTLEMENT_TIME_INPUT,
          errorMessage: settlementExpireTimeErrorMessage || '',
          hasValidationError: !result,
        }),
      );

      return result;
    },
    [dispatch, serviceId],
  );

  useEffect(() => {
    if (settlementCondition && isNotAP) {
      validateSettlementExpireTime({
        expirationType: innerExpireType,
        date: innerSelectedDate,
        time: innerSelectedTime,
        settlementType: innerThenExpireType,
        settlementDate: innerThenSelectedDate,
        settlementTime: innerThenSelectedTime,
      });
    }
  }, [
    innerExpireType,
    innerSelectedDate,
    innerSelectedTime,
    innerThenExpireType,
    innerThenSelectedDate,
    innerThenSelectedTime,
    settlementCondition,
    isNotAP,
    validateSettlementExpireTime,
  ]);

  const selectorHasError = useMemo(
    () =>
      validationErrors.some(
        (item) => item.inputName === CHANGE_SETTLEMENT_DATE_INPUT || item.inputName === CHANGE_SETTLEMENT_TIME_INPUT,
      ),
    [validationErrors],
  );

  const validateInput = useCallback(
    (inputName, inputValue) => {
      const isSettlementInput = SETTLEMENT_PRICE_INPUTS_ORDER.includes(inputName);
      const isOcoInput = OCO_PRICE_INPUTS.includes(inputName);
      const currentSide = isSettlementInput ? thenSide : side.value;
      const commonType = isSettlementInput ? thenType : type;
      const ocoType = isSettlementInput ? thenOco2Type : oco2Type;
      const currentType = isOcoInput ? ocoType : commonType;
      const isSell = Number(currentSide) === BUY_SELL_MAIN.SELL.ID;
      const isBuy = Number(currentSide) === BUY_SELL_MAIN.BUY.ID;
      const isIfPriceSell = Number(side.value) === BUY_SELL_MAIN.SELL.ID;
      const isIfPriceBuy = Number(side.value) === BUY_SELL_MAIN.BUY.ID;
      const isLimitPrice = PRICE_INPUTS_ORDER.includes(inputName) && Number(currentType) === ORDER_METHOD_MAIN.LIMIT.ID;
      const isStopPrice =
        PRICE_INPUTS_ORDER.includes(inputName) && Number(currentType) === ORDER_METHOD_MAIN.STOP_LIMIT.ID;
      const isIFD = Number(compositeType) === ORDERS_COMPOSITE_TYPES_MAIN.IFD.ID;
      const isIFO = Number(compositeType) === ORDERS_COMPOSITE_TYPES_MAIN.IFO.ID;
      const isIfPrice = inputName === CHANGE_ORDER_PRICE_INPUT;
      const isDonePrice = isIFD && inputName === CHANGE_ORDER_THEN_PRICE_INPUT;
      const isOco1Price = isIFO && inputName === CHANGE_ORDER_THEN_PRICE_INPUT;
      const isOco2Price = isIFO && inputName === CHANGE_ORDER_THEN_OCO2_PRICE_INPUT;

      if (!String(inputValue).match(NUMBER_VALIDATION_REG)) {
        dispatch(
          updateChangeOrderValidationErrors({
            inputName,
            errorMessage: VALIDATION_ERROR_EMPTY_FIELD,
            hasValidationError: true,
          }),
        );
        return false;
      }
      if (isIFD) {
        let messages = {
          ifPriceMessage: { errorMessage: '', isValid: true },
          donePriceMessage: { errorMessage: '', isValid: true },
        };
        if (
          isIfPriceBuy &&
          Number(type) === ORDER_METHOD_MAIN.LIMIT.ID &&
          Number(thenType) === ORDER_METHOD_MAIN.LIMIT.ID
        ) {
          messages = validateIFDBuyLimitSellLimitPrice(
            isIfPrice ? inputValue : innerPrice,
            isDonePrice ? inputValue : innerThenPrice,
            newOrderReadonly,
          );
        }
        if (
          isIfPriceBuy &&
          Number(type) === ORDER_METHOD_MAIN.LIMIT.ID &&
          Number(thenType) === ORDER_METHOD_MAIN.STOP_LIMIT.ID
        ) {
          messages = validateIFDBuyLimitSellStopPrice(
            isIfPrice ? inputValue : innerPrice,
            isDonePrice ? inputValue : innerThenPrice,
            newOrderReadonly,
          );
        }
        if (
          isIfPriceBuy &&
          Number(type) === ORDER_METHOD_MAIN.STOP_LIMIT.ID &&
          Number(thenType) === ORDER_METHOD_MAIN.LIMIT.ID
        ) {
          messages = validateIFDBuyStopSellLimitPrice(
            isIfPrice ? inputValue : innerPrice,
            isDonePrice ? inputValue : innerThenPrice,
            newOrderReadonly,
          );
        }
        if (
          isIfPriceBuy &&
          Number(type) === ORDER_METHOD_MAIN.STOP_LIMIT.ID &&
          Number(thenType) === ORDER_METHOD_MAIN.STOP_LIMIT.ID
        ) {
          messages = validateIFDBuyStopSellStopPrice(
            isIfPrice ? inputValue : innerPrice,
            isDonePrice ? inputValue : innerThenPrice,
            newOrderReadonly,
          );
        }
        if (
          isIfPriceSell &&
          Number(type) === ORDER_METHOD_MAIN.LIMIT.ID &&
          Number(thenType) === ORDER_METHOD_MAIN.LIMIT.ID
        ) {
          messages = validateIFDSellLimitBuyLimitPrice(
            isIfPrice ? inputValue : innerPrice,
            isDonePrice ? inputValue : innerThenPrice,
            newOrderReadonly,
          );
        }
        if (
          isIfPriceSell &&
          Number(type) === ORDER_METHOD_MAIN.LIMIT.ID &&
          Number(thenType) === ORDER_METHOD_MAIN.STOP_LIMIT.ID
        ) {
          messages = validateIFDSellLimitBuyStopPrice(
            isIfPrice ? inputValue : innerPrice,
            isDonePrice ? inputValue : innerThenPrice,
            newOrderReadonly,
          );
        }
        if (
          isIfPriceSell &&
          Number(type) === ORDER_METHOD_MAIN.STOP_LIMIT.ID &&
          Number(thenType) === ORDER_METHOD_MAIN.LIMIT.ID
        ) {
          messages = validateIFDSellStopBuyLimitPrice(
            isIfPrice ? inputValue : innerPrice,
            isDonePrice ? inputValue : innerThenPrice,
            newOrderReadonly,
          );
        }
        if (
          isIfPriceSell &&
          Number(type) === ORDER_METHOD_MAIN.STOP_LIMIT.ID &&
          Number(thenType) === ORDER_METHOD_MAIN.STOP_LIMIT.ID
        ) {
          messages = validateIFDSellStopBuyStopPrice(
            isIfPrice ? inputValue : innerPrice,
            isDonePrice ? inputValue : innerThenPrice,
            newOrderReadonly,
          );
        }
        const { ifPriceMessage, donePriceMessage } = messages;
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_ORDER_PRICE_INPUT,
            errorMessage: ifPriceMessage.errorMessage,
            hasValidationError: !ifPriceMessage.isValid,
          }),
        );
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_ORDER_THEN_PRICE_INPUT,
            errorMessage: donePriceMessage.errorMessage,
            hasValidationError: !donePriceMessage.isValid,
          }),
        );
        return ifPriceMessage.isValid && donePriceMessage.isValid;
      }
      if (isIFO) {
        let messages = {
          ifPriceMessage: { isValid: true, errorMessage: '' },
          oco1PriceMessage: { isValid: true, errorMessage: '' },
          oco2PriceMessage: { isValid: true, errorMessage: '' },
        };
        if (isIfPriceBuy && Number(type) === ORDER_METHOD_MAIN.LIMIT.ID) {
          messages = validateIFOBuyLimitSellLimitSellStop(
            isIfPrice ? inputValue : innerPrice,
            isOco1Price ? inputValue : innerThenPrice,
            isOco2Price ? inputValue : innerThenOco2Price,
            newOrderReadonly,
          );
        }
        if (isIfPriceBuy && Number(type) === ORDER_METHOD_MAIN.STOP_LIMIT.ID) {
          messages = validateIFOBuyStopSellLimitSellStop(
            isIfPrice ? inputValue : innerPrice,
            isOco1Price ? inputValue : innerThenPrice,
            isOco2Price ? inputValue : innerThenOco2Price,
            newOrderReadonly,
          );
        }
        if (isIfPriceSell && Number(type) === ORDER_METHOD_MAIN.LIMIT.ID) {
          messages = validateIFOSellLimitBuyLimitBuyStop(
            isIfPrice ? inputValue : innerPrice,
            isOco1Price ? inputValue : innerThenPrice,
            isOco2Price ? inputValue : innerThenOco2Price,
            newOrderReadonly,
          );
        }
        if (isIfPriceSell && Number(type) === ORDER_METHOD_MAIN.STOP_LIMIT.ID) {
          messages = validateIFOSellStopBuyLimitBuyStop(
            isIfPrice ? inputValue : innerPrice,
            isOco1Price ? inputValue : innerThenPrice,
            isOco2Price ? inputValue : innerThenOco2Price,
            newOrderReadonly,
          );
        }
        const { ifPriceMessage, oco1PriceMessage, oco2PriceMessage } = messages;
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_ORDER_PRICE_INPUT,
            errorMessage: ifPriceMessage.errorMessage,
            hasValidationError: !ifPriceMessage.isValid,
          }),
        );
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_ORDER_THEN_PRICE_INPUT,
            errorMessage: oco1PriceMessage.errorMessage,
            hasValidationError: !oco1PriceMessage.isValid,
          }),
        );
        dispatch(
          updateChangeOrderValidationErrors({
            inputName: CHANGE_ORDER_THEN_OCO2_PRICE_INPUT,
            errorMessage: oco2PriceMessage.errorMessage,
            hasValidationError: !oco2PriceMessage.isValid,
          }),
        );
        return ifPriceMessage.isValid && oco1PriceMessage.isValid && oco2PriceMessage.isValid;
      }
      if (isLimitPrice && isSell) {
        const { isValid, errorMessage } = validateBidLimitPrice(inputValue);
        dispatch(updateChangeOrderValidationErrors({ inputName, errorMessage, hasValidationError: !isValid }));
        return isValid;
      }
      if (isLimitPrice && isBuy) {
        const { isValid, errorMessage } = validateAskLimitPrice(inputValue);
        dispatch(updateChangeOrderValidationErrors({ inputName, errorMessage, hasValidationError: !isValid }));
        return isValid;
      }
      if (isStopPrice && isSell) {
        const { isValid, errorMessage } = validateBidStopPrice(inputValue);
        dispatch(updateChangeOrderValidationErrors({ inputName, errorMessage, hasValidationError: !isValid }));
        return isValid;
      }
      if (isStopPrice && isBuy) {
        const { isValid, errorMessage } = validateAskStopPrice(inputValue);
        dispatch(updateChangeOrderValidationErrors({ inputName, errorMessage, hasValidationError: !isValid }));
        return isValid;
      }
      dispatch(updateChangeOrderValidationErrors({ inputName, errorMessage: '', hasValidationError: false }));
      return true;
    },
    [
      thenSide,
      side.value,
      thenType,
      type,
      thenOco2Type,
      oco2Type,
      compositeType,
      dispatch,
      validateIFDBuyLimitSellLimitPrice,
      innerPrice,
      innerThenPrice,
      validateIFDBuyLimitSellStopPrice,
      validateIFDBuyStopSellLimitPrice,
      validateIFDBuyStopSellStopPrice,
      validateIFDSellLimitBuyLimitPrice,
      validateIFDSellLimitBuyStopPrice,
      validateIFDSellStopBuyLimitPrice,
      validateIFDSellStopBuyStopPrice,
      validateIFOBuyLimitSellLimitSellStop,
      innerThenOco2Price,
      validateIFOBuyStopSellLimitSellStop,
      validateIFOSellLimitBuyLimitBuyStop,
      validateIFOSellStopBuyLimitBuyStop,
      validateBidLimitPrice,
      validateAskLimitPrice,
      validateBidStopPrice,
      validateAskStopPrice,
      newOrderReadonly,
    ],
  );

  const validateAllInputs = useCallback(() => {
    const priceInputsIsValid = Object.entries(orderData).every(([inputName, inputValue]) => {
      if (priceInputsRequiresValidation[inputName]) {
        return validateInput(inputName, inputValue);
      }
      return true;
    });

    let dateInputIsValid = true;
    let timeInputIsValid = true;

    if (isNotAP && innerExpireType === EXPIRATION_TYPE_MAIN.CUSTOM.ID && !newOrderReadonly) {
      dateInputIsValid = validateDate(innerSelectedDate);
      timeInputIsValid = validateTime(innerSelectedTime);
    }

    let settlementDateWithTimeIsValid = true;
    if (settlementCondition && isNotAP) {
      settlementDateWithTimeIsValid = validateSettlementExpireTime({
        expirationType: innerExpireType,
        date: innerSelectedDate,
        time: innerSelectedTime,
        settlementType: innerThenExpireType,
        settlementDate: innerThenSelectedDate,
        settlementTime: innerThenSelectedTime,
      });
    }

    return priceInputsIsValid && dateInputIsValid && timeInputIsValid && settlementDateWithTimeIsValid;
  }, [
    orderData,
    priceInputsRequiresValidation,
    validateInput,
    validateDate,
    validateTime,
    innerSelectedTime,
    innerSelectedDate,
    newOrderReadonly,
    innerExpireType,
    isNotAP,
    settlementCondition,
    innerThenExpireType,
    innerThenSelectedDate,
    innerThenSelectedTime,
    validateSettlementExpireTime,
  ]);

  /**
   * input handlers
   */
  const changePriceValue = useCallback(
    (inputName, inputValue, changeInputValue) => {
      if (priceInputsRequiresValidation[inputName]) {
        validateInput(inputName, inputValue);
      }
      changeInputValue(
        String(inputValue).match(pricePrecision >= 1 ? INTEGER_VALIDATION_REG : NUMBER_VALIDATION_REG)
          ? roundExactlyOnPrecisionMatching(inputValue, Math.round(1 / pricePrecision), true)
          : inputValue,
      );
    },
    [pricePrecision, validateInput, priceInputsRequiresValidation],
  );

  const changeInnerPrice = useCallback(
    (inputValue) => changePriceValue(CHANGE_ORDER_PRICE_INPUT, inputValue, setInnerPrice),
    [changePriceValue],
  );

  const changeInnerThenPrice = useCallback(
    (inputValue) => changePriceValue(CHANGE_ORDER_THEN_PRICE_INPUT, inputValue, setInnerThenPrice),
    [changePriceValue],
  );

  const changeInnerOco2Price = useCallback(
    (inputValue) => changePriceValue(CHANGE_ORDER_OCO2_PRICE_INPUT, inputValue, setInnerOco2Price),
    [changePriceValue],
  );

  const changeInnerThenOco2Price = useCallback(
    (inputValue) => changePriceValue(CHANGE_ORDER_THEN_OCO2_PRICE_INPUT, inputValue, setInnerThenOco2Price),
    [changePriceValue],
  );

  /**
   * effects
   */
  useEffect(() => {
    validateAllInputsRef.current = { validateAllInputs };
  }, [validateAllInputs]);

  useEffect(() => {
    const { validateAllInputs: validate } = validateAllInputsRef.current;
    if (validate) validate();
  }, [sellPrice, buyPrice]);

  useEffect(() => {
    if (priceInputsChangeRef.current) {
      priceInputsChangeRef.current = {
        changeInnerPrice,
        changeInnerThenPrice,
        changeInnerOco2Price,
        changeInnerThenOco2Price,
      };
    }
  }, [changeInnerPrice, changeInnerThenPrice, changeInnerOco2Price, changeInnerThenOco2Price]);

  useEffect(() => {
    if (!price) {
      return;
    }
    const {
      current: {
        changeInnerPrice: changePrice,
        changeInnerThenPrice: changeThenPrice,
        changeInnerOco2Price: changeOco2Price,
        changeInnerThenOco2Price: changeThenOco2Price,
      },
    } = priceInputsChangeRef;
    changePrice(price);
    changeInnerExpireType(Number(expireType));
    changeOco2Price(oco2Price || 0);
    changeThenPrice(thenPrice || 0);
    changeInnerThenExpireType(Number(thenExpireType) || 0);
    changeThenOco2Price(thenOco2Price || 0);

    const initialValues = {
      innerPrice: Number(price),
      innerThenPrice: thenPrice || 0,
      innerOco2Price: oco2Price || 0,
      innerThenOco2Price: thenOco2Price || 0,
      innerExpireType: Number(expireType),
      innerThenExpireType: Number(thenExpireType) || 0,
      innerSelectedDate: null,
      innerSelectedTime: null,
      innerThenSelectedDate: null,
      innerThenSelectedTime: null,
    };

    if (Number(expireType) !== EXPIRATION_TYPE_MAIN.CUSTOM.ID) {
      const selectedDate = new Date();
      const selectedTime = getRoundedPlusOneHourCurrentTime();
      changeInnerSelectedDate(selectedDate);
      initialValues.innerSelectedDate = selectedDate;
      changeInnerSelectedTime(selectedTime);
      initialValues.innerSelectedTime = selectedTime;
    } else {
      const [date, timeWithSeconds] = expireTime.split('T');
      const selectedDate = new Date(date);
      const time = timeWithSeconds.slice(0, -3);
      changeInnerSelectedDate(selectedDate);
      initialValues.innerSelectedDate = selectedDate;
      changeInnerSelectedTime(time);
      initialValues.innerSelectedTime = time;
    }
    if (Number(thenExpireType) !== EXPIRATION_TYPE_MAIN.CUSTOM.ID) {
      const thenSelectedDate = new Date();
      const thenSelectedTime = getRoundedPlusOneHourCurrentTime();
      changeInnerThenSelectedDate(thenSelectedDate);
      initialValues.innerThenSelectedDate = thenSelectedDate;
      changeInnerThenSelectedTime(thenSelectedTime);
      initialValues.innerThenSelectedTime = thenSelectedTime;
    } else {
      const [date, timeWithSeconds] = thenExpireTime.split('T');
      const thenSelectedDate = new Date(date);
      const time = timeWithSeconds.slice(0, -3);
      changeInnerThenSelectedDate(thenSelectedDate);
      initialValues.innerThenSelectedDate = thenSelectedDate;
      changeInnerThenSelectedTime(time);
      initialValues.innerThenSelectedTime = time;
    }
    setInitialChangingValues(initialValues);
    dispatch(resetChangeOrderErrors());
  }, [
    price,
    expireType,
    expireTime,
    oco2Price,
    thenPrice,
    thenExpireType,
    thenExpireTime,
    thenOco2Price,
    dispatch,
    changeInnerSelectedTime,
    changeInnerSelectedDate,
  ]);

  return {
    innerPrice: {
      get: innerPrice,
      set: changeInnerPrice,
      label: PRICE_VALUES[type] || '-',
    },
    innerOco2Price: {
      get: innerOco2Price,
      set: changeInnerOco2Price,
      label: PRICE_VALUES[oco2Type],
      condition: Number(compositeType) === ORDERS_COMPOSITE_TYPES_MAIN.OCO1.ID,
    },
    innerExpireType: {
      get: innerExpireType,
      readonlyValue: expireTimeFunc(expireType, expireTime),
      set: changeInnerExpireType,
      label: '有効期限',
      isDisabled: isDisabledExpireType,
    },
    innerSelectedDate: {
      get: innerSelectedDate,
      set: changeInnerSelectedDate,
      label: '日付',
      condition: innerExpireType === EXPIRATION_TYPE_MAIN.CUSTOM.ID && !newOrderReadonly,
    },
    innerSelectedTime: {
      get: innerSelectedTime,
      set: onInnerTimeChange,
      label: '時刻',
      placeholder: '00:00',
    },
    innerThenPrice: {
      get: innerThenPrice,
      set: changeInnerThenPrice,
      label: PRICE_VALUES[thenType],
    },
    innerThenOco2Price: {
      get: innerThenOco2Price,
      set: changeInnerThenOco2Price,
      label: PRICE_VALUES[thenOco2Type],
      condition: Boolean(initialChangingValues.innerThenOco2Price),
    },
    innerThenExpireType: {
      get: innerThenExpireType,
      set: changeInnerThenExpireType,
      label: '有効期限',
      hasError: selectorHasError,
      errorMessage: selectorHasError ? VALIDATION_SETTLEMENT_TIME_ERROR_MESSAGE : '',
      isDisabled: isDisabledExpireType,
    },
    innerThenSelectedDate: {
      get: innerThenSelectedDate,
      set: changeInnerThenSelectedDate,
      label: '日付',
      condition: innerThenExpireType === EXPIRATION_TYPE_MAIN.CUSTOM.ID,
    },
    innerThenSelectedTime: {
      get: innerThenSelectedTime,
      set: onInnerThenTimeChange,
      label: '時刻',
      placeholder: '00:00',
    },
    settlement: {
      title: '決済',
      sideLabel: '売買',
      sideValue: BUY_SELL_VALUE[thenSide],
      typeLabel: '注文種別',
      typeValue: ORDER_SETTLEMENT_TYPE_NAMES[compositeType],
      thenTypeLabel: '注文条件',
      condition: settlementCondition,
    },
    closeOrder: {
      title: '決済対象',
      positionPriceTitle: '新規取引価格',
      positionPriceValue: closedPositionInfo.tradePrice || '-',
      positionName: '注文名',
      positionInfo: closedPositionInfo.apName,
    },
    priceStep,
    currentDate,
    isDisabledSubmit,
    isNotAP,
    orderData,
    validateAllInputs,
    validationErrors,
  };
};
