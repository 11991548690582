export const GET_CURRENT_CART_ITEMS_COUNT_REQUEST = 'CART/GET_CURRENT_CART_ITEMS_COUNT_REQUEST';
export const GET_CURRENT_CART_ITEMS_COUNT_SUCCESS = 'CART/GET_CURRENT_CART_ITEMS_COUNT_SUCCESS';

export const GET_CURRENT_CART_START_LOADING = 'CART/GET_CURRENT_CART_START_LOADING';
export const GET_CURRENT_CART_STOP_LOADING = 'CART/GET_CURRENT_CART_STOP_LOADING';
export const CHANGE_CURRENT_CART_TERM_START_LOADING = 'CART/CHANGE_CURRENT_CART_TERM_START_LOADING';
export const CHANGE_CURRENT_CART_TERM_STOP_LOADING = 'CART/CHANGE_CURRENT_CART_TERM_STOP_LOADING';
export const GET_CURRENT_CART_REQUEST = 'CART/GET_CURRENT_CART_REQUEST';
export const GET_CURRENT_CART_SUCCESS = 'CART/GET_CURRENT_CART_SUCCESS';
export const CHANGE_SELECTED_TERM_ID = 'CART/CHANGE_SELECTED_TERM_ID';

export const UPDATE_CURRENT_CART_STRATEGY_SETS_LOCALLY = 'CART/UPDATE_CURRENT_CART_STRATEGY_SETS_LOCALLY';
export const UPDATE_CURRENT_CART_STRATEGY_SETS_REQUEST = 'CART/UPDATE_CURRENT_CART_STRATEGY_SETS_REQUEST';

export const DELETE_CURRENT_CART_ITEM_START_LOADING = 'CART/DELETE_CURRENT_CART_ITEM_START_LOADING';
export const DELETE_CURRENT_CART_ITEM_STOP_LOADING = 'CART/DELETE_CURRENT_CART_ITEM_STOP_LOADING';
export const DELETE_CURRENT_CART_ITEM_REQUEST = 'CART/DELETE_CURRENT_CART_ITEM_REQUEST';

export const EXECUTE_CART_ITEMS_START_LOADING = 'CART/EXECUTE_CART_ITEMS_START_LOADING';
export const EXECUTE_CART_ITEMS_STOP_LOADING = 'CART/EXECUTE_CART_ITEMS_STOP_LOADING';
export const EXECUTE_CART_ITEMS_REQUEST = 'CART/EXECUTE_CART_ITEMS_REQUEST';

export const SAVE_CURRENT_CART_START_LOADING = 'CART/SAVE_CURRENT_CART_START_LOADING';
export const SAVE_CURRENT_CART_STOP_LOADING = 'CART/SAVE_CURRENT_CART_STOP_LOADING';
export const SAVE_CURRENT_CART_REQUEST = 'CART/SAVE_CURRENT_CART_REQUEST';

export const DELETE_CURRENT_CART_START_LOADING = 'CART/DELETE_CURRENT_CART_START_LOADING';
export const DELETE_CURRENT_CART_STOP_LOADING = 'CART/DELETE_CURRENT_CART_STOP_LOADING';
export const DELETE_CURRENT_CART_REQUEST = 'CART/DELETE_CURRENT_CART_REQUEST';

export const GET_HISTORY_CART_START_LOADING = 'CART/GET_HISTORY_CART_START_LOADING';
export const GET_HISTORY_CART_STOP_LOADING = 'CART/GET_HISTORY_CART_STOP_LOADING';
export const GET_HISTORY_CART_REQUEST = 'CART/GET_HISTORY_CART_REQUEST';
export const GET_HISTORY_CART_SUCCESS = 'CART/GET_HISTORY_CART_SUCCESS';

export const ADD_FROM_HISTORY_OR_SAVED_TO_CART_REQUEST = 'CART/ADD_FROM_HISTORY_OR_SAVED_TO_CART_REQUEST';

export const GET_SAVED_CART_START_LOADING = 'CART/GET_SAVED_CART_START_LOADING';
export const GET_SAVED_CART_STOP_LOADING = 'CART/GET_SAVED_CART_STOP_LOADING';
export const GET_SAVED_CART_REQUEST = 'CART/GET_SAVED_CART_REQUEST';
export const GET_SAVED_CART_SUCCESS = 'CART/GET_SAVED_CART_SUCCESS';

export const DELETE_SAVED_CART_ITEM_REQUEST = 'CART/DELETE_SAVED_CART_ITEM_REQUEST';

export const SET_CART_DATA_SERVICE_ID = 'CART/SET_CART_DATA_SERVICE_ID';
