/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BUY_SELL_MAIN } from '../../constants';
import { QUANTITY_PRECISION_KEEP } from '../../constants/manualTrade';
import { getServiceQuantityUnit, toCommaSeparatedNumber, truncToPrecision } from '..';

const POSITION_INCREMENT_NUM = 1;

export const useManualTradeCommonFifoLogic = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const instrumentId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  const pricePrecision = useSelector((state) => state.settings.instrumentList[instrumentId]?.pricePrecision);
  const positionsUnrealizedProfitLoss = useSelector(
    (state) => state.currencies.positionsUnrealizedProfitLoss[instrumentId],
  );

  return useMemo(() => {
    const values = Object.values(positionsUnrealizedProfitLoss || [])
      .filter((data) => data.apGroupId === null)
      .reduce(
        (acc, item) => {
          if (Number(item.side) === BUY_SELL_MAIN.BUY.ID) {
            acc.buyTotalQuantity += item.quantity * QUANTITY_PRECISION_KEEP;
            acc.buyTotalTradePrice += item.quantity * item.tradePrice;
            acc.buyTotalValuationGainLoss += item.unrealizedProfitLoss;
            acc.buyPositionCount += POSITION_INCREMENT_NUM;
          }
          if (Number(item.side) === BUY_SELL_MAIN.SELL.ID) {
            acc.sellTotalQuantity += item.quantity * QUANTITY_PRECISION_KEEP;
            acc.sellTotalTradePrice += item.quantity * item.tradePrice;
            acc.sellTotalValuationGainLoss += item.unrealizedProfitLoss;
            acc.sellPositionCount += POSITION_INCREMENT_NUM;
          }
          return acc;
        },
        {
          buyTotalQuantity: 0,
          buyTotalTradePrice: 0,
          buyTotalValuationGainLoss: 0,
          buyPositionCount: 0,
          sellTotalQuantity: 0,
          sellTotalTradePrice: 0,
          sellTotalValuationGainLoss: 0,
          sellPositionCount: 0,
        },
      );

    const sellAverageTradePrice = Number(
      truncToPrecision(
        values.sellTotalTradePrice / (values.sellTotalQuantity / QUANTITY_PRECISION_KEEP),
        pricePrecision,
      ),
    );
    const buyAverageTradePrice = Number(
      truncToPrecision(values.buyTotalTradePrice / (values.buyTotalQuantity / QUANTITY_PRECISION_KEEP), pricePrecision),
    );

    return { ...values, sellAverageTradePrice, buyAverageTradePrice };
  }, [positionsUnrealizedProfitLoss, pricePrecision]);
};

export const useManualTradeFifoTableLogic = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);

  const {
    buyTotalQuantity,
    buyAverageTradePrice,
    buyTotalValuationGainLoss,
    sellTotalQuantity,
    sellAverageTradePrice,
    sellTotalValuationGainLoss,
  } = useManualTradeCommonFifoLogic();

  return useMemo(() => {
    const putInitialHypen = (value) => {
      return value || '-';
    };

    const setQuantity = (quantity) => {
      const reaplaceValueForDisplay = toCommaSeparatedNumber(putInitialHypen(quantity));
      return quantity > 0 ? `${reaplaceValueForDisplay}` : reaplaceValueForDisplay;
    };

    const setAverageTradePrice = (averageTradePrice) => {
      const reaplaceValueForDisplay = toCommaSeparatedNumber(putInitialHypen(averageTradePrice));
      return averageTradePrice > 0 ? `${reaplaceValueForDisplay}` : reaplaceValueForDisplay;
    };

    const setValuationGainLoss = (totalValuationGainLoss) => {
      const reaplaceValueForDisplay = toCommaSeparatedNumber(putInitialHypen(totalValuationGainLoss));
      return totalValuationGainLoss > 0 ? `+${reaplaceValueForDisplay}` : reaplaceValueForDisplay;
    };

    const setValuationGainLossStyle = (totalValuationGainLoss) => {
      if (totalValuationGainLoss !== 0 && totalValuationGainLoss !== '-')
        return totalValuationGainLoss > 0 ? '#42DD96' : '#DF5656';
      return '';
    };

    return {
      sell: {
        title: '売建玉',
        quantity: setQuantity(sellTotalQuantity / QUANTITY_PRECISION_KEEP),
        averageTradePrice: setAverageTradePrice(sellAverageTradePrice),
        valuationGainLoss: setValuationGainLoss(sellTotalValuationGainLoss),
        style: setValuationGainLossStyle(sellTotalValuationGainLoss),
      },
      buy: {
        title: '買建玉',
        quantity: setQuantity(buyTotalQuantity / QUANTITY_PRECISION_KEEP),
        averageTradePrice: setAverageTradePrice(buyAverageTradePrice),
        valuationGainLoss: setValuationGainLoss(buyTotalValuationGainLoss),
        style: setValuationGainLossStyle(buyTotalValuationGainLoss),
      },
      classification: {
        quantity: `数量(${getServiceQuantityUnit(serviceId)})`,
        averageTradePrice: '平均取引価格',
        valuationGainLoss: '評価損益(円)',
      },
    };
  }, [
    buyTotalQuantity,
    buyAverageTradePrice,
    buyTotalValuationGainLoss,
    sellTotalQuantity,
    sellAverageTradePrice,
    sellTotalValuationGainLoss,
    serviceId,
  ]);
};
