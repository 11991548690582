import { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useCartTermEndDate } from 'shared-modules/hooks';
import { MODAL_SIZES } from 'shared-modules/constants';
import { Button, Modal } from '../../../../components';
import styles from './cartWarningInfo.module.scss';

const BUTTON_WIDTH = 312;

export const CartWarningInfo = memo(({ isOpen, closeModal }) => {
  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const currentData = useSelector((state) => state.cart.currentData[defaultSelectionTermId]);
  const termEndDate = useCartTermEndDate(currentData);

  return (
    <Modal size={MODAL_SIZES.WIDTH_640} isOpen={isOpen} closeModal={closeModal} title="注意事項">
      <div className={styles.bodyWrapper}>
        <div className={styles.text}>
          期間収益率､期間損益等はシミュレーションの結果であり､将来の利益を保証するものではありません｡
        </div>
        <div className={styles.header}>シミュレーションデータについて</div>
        <div className={styles.text}>
          本機能は､過去のFX・CFD・ETFの1分足レートをもとにスプレッド､スワップポイント(FX)
          ､手数料(FX)､金利・配当相当額(CFD)､金利・分配相当額(ETF)を加味した数値でバックテストを行った結果を表示しています｡
          将来の結果を保証するものではありませんのでご注意ください｡また､バックテストは実際の取引と異なります｡
          <br />
          更新日：
          {termEndDate}
        </div>
        <div className={styles.header}>自動売買ルールについて</div>
        <div className={styles.text}>
          各自動売買ルールは不定期に入れ替えやロジック設定値の更新をおこないます｡セレクト一覧には､常に最新パラメーターの自動売買セレクトが表示されます｡
          <br />
          一部の自動売買ルールは､両建てとなるタイミングがあるため
          ､スプレッドや金利(CFD･ETF)が二重にかかる､スワップポイント(FX)で支払いが生じるなどのデメリットがございます｡
          また､一部のルールを除いて､それぞれの自動売買注文に損切りの設定はされていません｡注文内容の詳細は各ルールの注文設定からご確認ください｡
        </div>
        <div className={styles.text}>
          「ビルダー」のシミュレーションにおいて､エントリー価格を「前日終値」からの値幅で作成された場合､エントリー価格は「指定したシミュレーション期間の始値」を使用して計算します｡
          そのため､実際に発注する価格はシミュレーションとは異なりますので､ご注意ください｡
        </div>
        <div className={styles.text}>
          エントリー価格を「価格指定」で作成された場合､実際に発注する注文タイプは､その時のレートをもとに指値・逆指値を判断します｡
          そのため､実際に発注する価格はシミュレーションとは異なりますので､ご注意ください｡
        </div>
        <div className={styles.text}>
          「テクニカルビルダー」のシミュレーションにおいて、エントリー価格は「指定した期間においてのシグナル確定したときの終値」をスタート価格として計算します。
          そのため、実際に発注する価格はシミュレーションとは異なりますので、ご注意ください。
        </div>
        <div className={styles.text}>上記をご理解いただき､ご自身の責任と判断でご利用ください｡</div>
        <Button width={BUTTON_WIDTH} onClick={closeModal} className={styles.button} secondary>
          閉じる
        </Button>
      </div>
    </Modal>
  );
});

CartWarningInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
