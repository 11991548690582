import React, { memo, useCallback, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatNumberToDisplayedString,
  getFxCfdQuantityStep,
  getPriceStepByServiceId,
  getServiceQuantityUnit,
} from 'shared-modules/services';
import {
  ORDER_TYPES,
  COUNT_INPUT,
  STOP_PRICE_INPUT,
  LIMIT_PRICE_INPUT,
  TIME_INPUT,
  DATE_INPUT,
} from 'shared-modules/constants/manualTrade';
import {
  EXPIRATION_TYPE_MAIN,
  OPTIONS_EXPIRATION_TYPE,
  OCO_EXPIRATION_DATE_SELECTOR,
  ETF,
} from 'shared-modules/constants';
import {
  useTimeChangingHandler,
  useInputCreateOrder,
  useBuySellInputCreateOrder,
  useManualTradeSelectedInstrumentSettings,
  useOrderValidationErrors,
  useValidateSelectedManualTradeOrder,
  useValidateManualTradeInput,
  useBuySellOptionRestrictions,
  useCalculateManualMargin,
} from 'shared-modules/services/hooks';
import { SELECT_WIDTH, TIME_INPUT_WIDTH, DATEPICKER_WIDTH } from '../../constants';
import { toggleUserSettingNewOrderSkipConfirmationRequest } from '../../../../redux/actions';
import CustomSelect from '../../../../components/CustomSelect';
import CustomInput from '../../../../components/CustomInput';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import styles from './sideMenuOcoOptions.module.scss';
import { InputNumber, BuySellGroupButton, Switch } from '../../../../components';
import { QuantityLabel } from '../../../../components/QuantityLabel';

const SideMenuOcoOptions = () => {
  const dispatch = useDispatch();

  const serviceId = useSelector((state) => state.auth.serviceId);
  const quantityUnit = getServiceQuantityUnit(serviceId);
  const withoutConfirmation = useSelector((state) => state.settings[serviceId].skipNewOrderConfirmation);

  const instrumentId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);

  const currentDate = useMemo(() => new Date(), []);

  const validateManualTradeInput = useValidateManualTradeInput();
  const validateManualTradeInputRef = useRef({});
  useEffect(() => {
    validateManualTradeInputRef.current = { validateManualTradeInput };
  }, [validateManualTradeInput]);

  const validationErrors = useOrderValidationErrors(ORDER_TYPES.OCO.name);

  const { quantityPrecision, pricePrecision, allowSellFlg, allowBuyFlg, quantityUnitConvFactor } =
    useManualTradeSelectedInstrumentSettings();
  const isETF = serviceId === ETF;

  const quantityIsInteger = isETF && quantityPrecision >= 1;

  const priceStep = useMemo(
    () => getPriceStepByServiceId(serviceId, instrumentId, pricePrecision),
    [pricePrecision, instrumentId, serviceId],
  );
  const priceIsInteger = useMemo(() => isETF && priceStep >= 1, [isETF, priceStep]);

  const handleChangeWithoutConfirmation = useCallback(() => {
    dispatch(toggleUserSettingNewOrderSkipConfirmationRequest());
  }, [dispatch]);

  const [buySell, changeBuySell] = useBuySellInputCreateOrder(ORDER_TYPES.OCO.name);
  useBuySellOptionRestrictions(allowBuyFlg, allowSellFlg, buySell, changeBuySell);
  const [count, changeCount] = useInputCreateOrder(
    ORDER_TYPES.OCO.name,
    ORDER_TYPES.OCO.inputs.COUNT,
    quantityPrecision,
    true,
  );

  const [limitPrice, changeLimitPrice] = useInputCreateOrder(
    ORDER_TYPES.OCO.name,
    ORDER_TYPES.OCO.inputs.LIMIT_PRICE,
    pricePrecision,
  );
  const [stopPrice, changeStopPrice] = useInputCreateOrder(
    ORDER_TYPES.OCO.name,
    ORDER_TYPES.OCO.inputs.STOP_PRICE,
    pricePrecision,
  );
  const priceRef = useRef({});
  useEffect(() => {
    priceRef.current = { limitPrice, stopPrice };
  }, [limitPrice, stopPrice]);

  const [expirationType, changeExpirationType] = useInputCreateOrder(
    ORDER_TYPES.OCO.name,
    ORDER_TYPES.OCO.inputs.EXPIRATION_TYPE,
    null,
    true,
  );
  const [selectedDate, setSelectedDate] = useInputCreateOrder(
    ORDER_TYPES.OCO.name,
    ORDER_TYPES.OCO.inputs.SELECTED_DATE,
    null,
    true,
  );
  const [selectedTime, setSelectedTime] = useInputCreateOrder(
    ORDER_TYPES.OCO.name,
    ORDER_TYPES.OCO.inputs.SELECTED_TIME,
    null,
    true,
  );

  const onTimeChange = useTimeChangingHandler(setSelectedTime);

  const price = useMemo(() => (limitPrice >= stopPrice ? limitPrice : stopPrice), [limitPrice, stopPrice]);

  const calculatedMargin = useCalculateManualMargin(count, price);

  const validateSelectedManualTradeOrder = useValidateSelectedManualTradeOrder();
  const validateSelectedManualTradeOrderRef = useRef({});
  useEffect(() => {
    validateSelectedManualTradeOrderRef.current = { validateSelectedManualTradeOrder };
  }, [validateSelectedManualTradeOrder]);

  // validate all values on mount
  useEffect(() => {
    const { validateSelectedManualTradeOrder: validate } = validateSelectedManualTradeOrderRef.current;
    if (validate) {
      validate();
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>新規</div>
      <div className={styles.buySellRow}>
        売買
        <BuySellGroupButton
          instrumentId={instrumentId}
          buySell={buySell}
          onChange={changeBuySell}
          disabledSell={!allowSellFlg}
          disabledBuy={!allowBuyFlg}
        />
      </div>
      <div className={styles.countRow}>
        <QuantityLabel
          serviceId={serviceId}
          quantityUnit={quantityUnit}
          quantityUnitConvFactor={quantityUnitConvFactor}
        />
        <InputNumber
          value={count}
          name={COUNT_INPUT}
          errorMessages={validationErrors}
          onChange={changeCount}
          type="number"
          step={isETF ? quantityPrecision : getFxCfdQuantityStep}
          onlyIntegerAllowed={quantityIsInteger}
          withErrorTooltip
        />
      </div>
      <div className={styles.necessaryMarginRow}>
        発注証拠金目安
        <div className={styles.necessaryMargin}>
          {formatNumberToDisplayedString({ value: calculatedMargin, withoutPlus: true })}
        </div>
      </div>
      <div className={styles.limitPriceRow}>
        指値価格
        <InputNumber
          value={limitPrice}
          name={LIMIT_PRICE_INPUT}
          errorMessages={validationErrors}
          onChange={changeLimitPrice}
          type="number"
          step={priceStep}
          onlyIntegerAllowed={priceIsInteger}
          withErrorTooltip
        />
      </div>
      <div className={styles.stopPriceRow}>
        逆指値価格
        <InputNumber
          value={stopPrice}
          name={STOP_PRICE_INPUT}
          errorMessages={validationErrors}
          onChange={changeStopPrice}
          type="number"
          step={priceStep}
          onlyIntegerAllowed={priceIsInteger}
          withErrorTooltip
        />
      </div>
      <div className={styles.expirationTypeRow}>
        有効期限
        <CustomSelect
          instanceId="side-menu-oco-expiration-date"
          selectItemId={expirationType}
          onChange={changeExpirationType}
          options={OPTIONS_EXPIRATION_TYPE}
          width={SELECT_WIDTH}
          dataCustomSelector={OCO_EXPIRATION_DATE_SELECTOR}
          menuPlacement="top"
          isDarker
        />
      </div>
      {expirationType === EXPIRATION_TYPE_MAIN.CUSTOM.ID && (
        <>
          <div className={styles.datePickerRow}>
            日付
            <CustomDatePicker
              width={DATEPICKER_WIDTH}
              date={selectedDate}
              onChange={setSelectedDate}
              minDate={currentDate}
              name={DATE_INPUT}
              errorMessages={validationErrors}
              popperPlacement="auto"
              isTaller
              disableSundays
            />
          </div>
          <div className={styles.timePickerRow}>
            時刻
            <CustomInput
              value={selectedTime}
              onChange={onTimeChange}
              width={TIME_INPUT_WIDTH}
              name={TIME_INPUT}
              errorMessages={validationErrors}
              withErrorTooltip
              placeholder="00:00"
              isEndAligned
            />
          </div>
        </>
      )}
      <div className={styles.countRow}>
        確認省略
        <Switch checked={withoutConfirmation} onChange={handleChangeWithoutConfirmation} />
      </div>
    </div>
  );
};

export default memo(SideMenuOcoOptions);
