import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MODAL_SIZES, STRATEGY_SETS_RESULTS } from 'shared-modules/constants';
import { useStrategiesSuccess } from 'shared-modules/services/hooks/cartLogic';
import { tutorialOpenHome } from '../../../../constants/tutorial/classNames';
import { push } from '../../../../redux/actions';
import { Button, Modal } from '../../../../components';
import icon from '../../../../assets/completion.png';
import styles from './strategiesSuccess.module.scss';
import { HOME } from '../../../../constants';

const StrategiesSuccess = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();

  const { title, info, innerStrategyResults } = useStrategiesSuccess(isOpen);

  const handleRedirectToPortfolio = useCallback(() => {
    closeModal();
    // for waiting close animation
    setTimeout(() => {
      dispatch(push(`/${HOME}`));
    }, 300);
  }, [dispatch, closeModal]);

  const hasSuccess = innerStrategyResults.some(
    (strategyResult) => strategyResult.status === STRATEGY_SETS_RESULTS.SUCCESS,
  );

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title={title} size={MODAL_SIZES.WIDTH_400}>
      <div className={styles.wrapper}>
        <div className={classNames(styles.infoRow, styles.text)}>{info}</div>
        {innerStrategyResults.map((strategyResult) => (
          <div className={styles.setsRow} key={strategyResult.strategyId}>
            <div className={styles.dot}>・</div>
            <div>
              <div className={classNames(styles.strategiesName, styles.text)}>
                {strategyResult.status === STRATEGY_SETS_RESULTS.FAIL && (
                  <span className={styles.errorMessage}>[失敗]</span>
                )}
                {strategyResult.strategyName}
              </div>
              <div className={classNames(styles.resultMessage, styles.text)}>{strategyResult.message}</div>
            </div>
          </div>
        ))}
        {hasSuccess && (
          <div className={styles.iconContainer}>
            <img src={icon} className={styles.icon} alt="完了" />
          </div>
        )}
        <div className={classNames(styles.buttonBlock, { [styles.marginTop]: !hasSuccess })}>
          <Button className={styles.firstButton} onClick={closeModal} secondary>
            閉じる
          </Button>
          <Button className={tutorialOpenHome} onClick={handleRedirectToPortfolio}>
            ホーム画面を開く
          </Button>
        </div>
      </div>
    </Modal>
  );
};

StrategiesSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(StrategiesSuccess);
