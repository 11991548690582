import { takeEvery, takeLatest, debounce } from 'redux-saga/effects';
import { DEBOUNCE_DELAY } from '../../../constants/apiConstant';
import {
  CREATE_MARKET_ORDER_REQUEST,
  CREATE_FIFO_ORDER_REQUEST,
  CREATE_STANDARD_ORDER_REQUEST,
  CREATE_OCO_ORDER_REQUEST,
  CREATE_IFD_ORDER_REQUEST,
  CREATE_IFO_ORDER_REQUEST,
  GET_EXECUTIONS_TABLE_DATA_REQUEST,
  GET_ORDERS_TABLE_DATA_REQUEST,
  GET_NEWS_REQUEST,
  GET_ORDER_INFO_REQUEST,
  CHANGE_ORDER_REQUEST,
  DELETE_ORDER_REQUEST,
  CREATE_CLOSE_ORDER_REQUEST,
  DELETE_POSITIONS_ORDER_REQUEST,
  GET_NEWS_PERIODICALLY,
  CLOSE_MULTIPLE_POSITIONS_REQUEST,
  CLOSE_FIFO_SIDE_POSITIONS_REQUEST,
  SWAP_TRANSFER_REQUEST,
  RESET_EXECUTIONS_TABLE_META_INFO_PARTIAL,
  RESET_EXECUTIONS_TABLE_META_INFO_DATE,
} from '../../actionConstants/manualTradeConstants';
import closeMultiplePositionsRequestHandler from './closeMultiplePositionsRequestHandler';
import closeFifoSidePositionsRequestHandler from './closeFifoSidePositionsRequestHandler';
import createMarketOrderRequestHandler from './createMarketOrderRequestHandler';
import createFIFOOrderRequestHandler from './createFIFOOrderRequestHandler';
import createStandardOrderRequestHandler from './createStandardOrderRequestHandler';
import createOcoOrderRequestHandler from './createOcoOrderRequestHandler';
import createIFDOrderRequestHandler from './createIFDOrderRequestHandler';
import creteIFOOrderRequestHandler from './creteIFOOrderRequestHandler';
import getNewsRequestHandler from './getNewsRequestHandler';
import getExecutionsTableDataRequestHandler from './executionsDataHandlers';
import getOrdersTableDataRequestHandler from './ordersDataHandlers';
import getOrderInfoRequestHandler from './getOrderInfoRequestHandler';
import changeOrderRequestHandler from './changeOrderRequestHandler';
import deleteOrderRequestHandler from './deleteOrderRequestHandler';
import createCloseOrderRequestHandler from './createCloseOrderRequestHandler';
import deletePositionsOrderRequestHandler from './deletePositionsOrderRequestHandler';
import getNewsPeriodicallyHandler from './getNewsPeriodicallyHandler';
import swapTransferRequestHandler from './swapTransferRequestHandler';
import resetExecutionsTableMetaInfoDateHandler from './resetExecutionsTableMetaInfoDateHandler';
import resetExecutionsTableMetaInfoPartialHandler from './resetExecutionsTableMetaInfoPartialHandler';

export default function* manualTradeSagaHandler() {
  yield takeEvery(CREATE_MARKET_ORDER_REQUEST, createMarketOrderRequestHandler);
  yield takeEvery(CREATE_FIFO_ORDER_REQUEST, createFIFOOrderRequestHandler);
  yield takeEvery(CREATE_STANDARD_ORDER_REQUEST, createStandardOrderRequestHandler);
  yield takeEvery(CREATE_OCO_ORDER_REQUEST, createOcoOrderRequestHandler);
  yield takeEvery(CREATE_IFD_ORDER_REQUEST, createIFDOrderRequestHandler);
  yield takeEvery(CREATE_IFO_ORDER_REQUEST, creteIFOOrderRequestHandler);
  yield debounce(DEBOUNCE_DELAY, GET_EXECUTIONS_TABLE_DATA_REQUEST, getExecutionsTableDataRequestHandler);
  yield debounce(DEBOUNCE_DELAY, GET_ORDERS_TABLE_DATA_REQUEST, getOrdersTableDataRequestHandler);
  yield takeLatest(GET_NEWS_REQUEST, getNewsRequestHandler);
  yield takeEvery(GET_ORDER_INFO_REQUEST, getOrderInfoRequestHandler);
  yield takeEvery(CHANGE_ORDER_REQUEST, changeOrderRequestHandler);
  yield takeEvery(DELETE_ORDER_REQUEST, deleteOrderRequestHandler);
  yield takeEvery(CREATE_CLOSE_ORDER_REQUEST, createCloseOrderRequestHandler);
  yield takeEvery(DELETE_POSITIONS_ORDER_REQUEST, deletePositionsOrderRequestHandler);
  yield takeLatest(GET_NEWS_PERIODICALLY, getNewsPeriodicallyHandler);
  yield takeEvery(CLOSE_MULTIPLE_POSITIONS_REQUEST, closeMultiplePositionsRequestHandler);
  yield takeEvery(CLOSE_FIFO_SIDE_POSITIONS_REQUEST, closeFifoSidePositionsRequestHandler);
  yield takeEvery(SWAP_TRANSFER_REQUEST, swapTransferRequestHandler);
  yield takeEvery(RESET_EXECUTIONS_TABLE_META_INFO_DATE, resetExecutionsTableMetaInfoDateHandler);
  yield takeEvery(RESET_EXECUTIONS_TABLE_META_INFO_PARTIAL, resetExecutionsTableMetaInfoPartialHandler);
}
