import { CFD, ETF, FX } from 'shared-modules/constants';

export const UNREALIZED_PNL = 4950;

export const mockSummaryInfoHasApGroup = {
  [FX]: {
    bindingMoney: undefined,
    cpAddMarginAmount: 0,
    depositeBalance: 0,
    effectiveMargin: 2038918,
    effectiveMarginRate: 3310.03,
    instrumentGroups: undefined,
    isLoading: false,
    orderableMargin: 1444121,
    orderingRequiredMargin: 533199,
    positionRequiredMargin: 61598,
    receivedMargin: 2033968,
    registeredCashOut: 0,
    unsettledPl: 4950,
  },
  [ETF]: {
    bindingMoney: undefined,
    cpAddMarginAmount: 0,
    depositeBalance: 0,
    effectiveMargin: 2000000,
    effectiveMarginRate: '-',
    instrumentGroups: undefined,
    isLoading: false,
    orderableMargin: 2000000,
    orderingRequiredMargin: 0,
    positionRequiredMargin: 0,
    receivedMargin: 2000000,
    registeredCashOut: 0,
    unsettledPl: null,
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    bindingMoney: undefined,
    cpAddMarginAmount: 0,
    depositeBalance: 0,
    effectiveMargin: 2000000,
    effectiveMarginRate: '-',
    instrumentGroups: undefined,
    isLoading: false,
    orderableMargin: 2000000,
    orderingRequiredMargin: 0,
    positionRequiredMargin: 0,
    receivedMargin: 2000000,
    registeredCashOut: 0,
    unsettledPl: null,
  },
};

export const mockSummaryInfoNoApGroup = {
  [FX]: {
    bindingMoney: undefined,
    cpAddMarginAmount: 0,
    depositeBalance: 0,
    effectiveMargin: 2000000,
    effectiveMarginRate: '-',
    instrumentGroups: undefined,
    isLoading: false,
    orderableMargin: 2000000,
    orderingRequiredMargin: 0,
    positionRequiredMargin: 0,
    receivedMargin: 2000000,
    registeredCashOut: 0,
    unsettledPl: null,
  },
  [ETF]: {
    bindingMoney: undefined,
    cpAddMarginAmount: 0,
    depositeBalance: 0,
    effectiveMargin: 2000000,
    effectiveMarginRate: '-',
    instrumentGroups: undefined,
    isLoading: false,
    orderableMargin: 2000000,
    orderingRequiredMargin: 0,
    positionRequiredMargin: 0,
    receivedMargin: 2000000,
    registeredCashOut: 0,
    unsettledPl: null,
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    bindingMoney: undefined,
    cpAddMarginAmount: 0,
    depositeBalance: 0,
    effectiveMargin: 2000000,
    effectiveMarginRate: '-',
    instrumentGroups: undefined,
    isLoading: false,
    orderableMargin: 2000000,
    orderingRequiredMargin: 0,
    positionRequiredMargin: 0,
    receivedMargin: 2000000,
    registeredCashOut: 0,
    unsettledPl: null,
  },
};
