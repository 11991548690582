import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAccountInfo } from 'shared-modules/hooks';
import { compareServiceId } from 'shared-modules/utils';
import { useAddToCartPayload, useRunNowPayload } from 'shared-modules/hooks/select';
import { useCheckAndNotifyAvailableService } from 'shared-modules/hooks/service';
import {
  openAutoSelectAddToCartConfirmation,
  openAutoSelectRunNowConfirmation,
  openStrategiesSuccessModal,
} from '../../redux/actions';

export const useAddToCart = () => {
  const dispatch = useDispatch();
  const { payload } = useAddToCartPayload();
  return useCallback(() => {
    dispatch(openAutoSelectAddToCartConfirmation(payload));
  }, [dispatch, payload]);
};

export const useRunNow = () => {
  const dispatch = useDispatch();
  const { payload, serviceIds } = useRunNowPayload({ openStrategiesSuccessModal });
  const accountInfo = useAccountInfo();
  const checkAndNotify = useCheckAndNotifyAvailableService();
  return useCallback(() => {
    if (!serviceIds) {
      return;
    }
    serviceIds.sort(compareServiceId);
    // ↓繰り返しの書き方の統一を促しているだけで、実質無意味なルール
    // eslint-disable-next-line no-restricted-syntax
    for (const sid of serviceIds) {
      const sent = checkAndNotify(accountInfo, sid);
      if (sent) {
        return;
      }
    }
    dispatch(openAutoSelectRunNowConfirmation(payload));
  }, [dispatch, checkAndNotify, accountInfo, serviceIds, payload]);
};
