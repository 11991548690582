import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { MY_PAGE_HOME_URL } from 'shared-modules/config';
import PropTypes from 'prop-types';
import { SSO_LOGIN_ACCOUNT_TYPE } from 'shared-modules/constants/apiConstant';
import {
  clearAutoSelectFilterCondition,
  clearAutoSelectSortOrder,
  removeFilterInitKeyword,
} from 'shared-modules/redux/actions/autoSelectActions';
import { openNewSameTab } from '../../../../services';
import {
  push,
  openConfirmationModal,
  openHelpInfo,
  openNotificationSettings,
  openReportsDetails,
  handleRedirect,
  logoutUser,
  openTutorialModal,
} from '../../../../redux/actions';
import { HOME, SSO_MY_PAGE_URL } from '../../../../constants';
import { useKeyPressHandler } from '../../../../services/hooks';
import Logo from '../Logo';
import IconButton from '../../../../components/IconButton';
import styles from './sideBarExpanded.module.scss';

const SideBarOptionItem = ({ item }) => {
  const handleClick = useCallback(
    (e) => {
      item.callback(e);
      e.currentTarget.blur();
    },
    [item],
  );
  const handleKeyPress = useKeyPressHandler(handleClick);

  return (
    <div tabIndex={0} role="button" className={styles.item} onClick={handleClick} onKeyPress={handleKeyPress}>
      <div>{item.value}</div>
      {item.withIcon && <i className={classNames('material-icons', styles.icon)}>open_in_new</i>}
    </div>
  );
};

const goToProcessSSOMyPage = () => {
  openNewSameTab(`/${SSO_MY_PAGE_URL}`);
};

SideBarOptionItem.propTypes = {
  item: PropTypes.shape({
    callback: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    withIcon: PropTypes.bool,
  }).isRequired,
};

const SideBarExpanded = memo(({ handleCloseSideBar }) => {
  const dispatch = useDispatch();
  useSelector((state) => state.settings.learnTriautoStatus);
  const accountType = useSelector((state) => state.settings.loginMethod);

  const handleLogoClick = useCallback(() => {
    handleCloseSideBar();
    dispatch(push(`/${HOME}`));
  }, [dispatch, handleCloseSideBar]);

  const handleLogout = useCallback(() => {
    const url = accountType === SSO_LOGIN_ACCOUNT_TYPE ? MY_PAGE_HOME_URL : '/login';
    dispatch(logoutUser({ successAction: handleRedirect({ url, isInner: false }) }));
  }, [dispatch, accountType]);

  const handleOpenConfirmationLogout = useCallback(
    ({ currentTarget }) => {
      dispatch(
        openConfirmationModal({
          title: 'ログアウト',
          callback: handleLogout,
          bodyText: 'ログアウトします。\n よろしいですか?',
          buttonBackText: 'キャンセル',
          buttonNextText: 'ログアウトする',
          successButtonIsGreen: true,
        }),
      );
      currentTarget.blur();
    },
    [dispatch, handleLogout],
  );

  const handleKeyPressOpenConfirmationLogout = useKeyPressHandler(handleOpenConfirmationLogout);

  const handleStopPropagation = useCallback((e) => e.stopPropagation(), []);

  const handleOpenNotificationSettings = useCallback(() => {
    dispatch(openNotificationSettings());
  }, [dispatch]);

  const handleOpenReports = useCallback(() => {
    dispatch(openReportsDetails());
  }, [dispatch]);

  const handleOpenHelpInfo = useCallback(() => {
    dispatch(openHelpInfo());
  }, [dispatch]);

  const handleOpenTutorialModal = useCallback(() => {
    dispatch(openTutorialModal());
    dispatch(push(`/${HOME}`));
    handleCloseSideBar();
    // チュートリアル中、チュートリアル終了後に、セレクト開くので条件をクリアしておく
    dispatch(clearAutoSelectFilterCondition());
    dispatch(clearAutoSelectSortOrder());
    dispatch(removeFilterInitKeyword());
  }, [dispatch, handleCloseSideBar]);

  const optionsList = useMemo(
    () => [
      { id: 0, value: 'Myページ', callback: goToProcessSSOMyPage, withIcon: true },
      // { id: 1, value: '入出金・振替', callback: goToProcessDepositWithdrawal, withIcon: true },
      // { id: 2, value: 'マネーハッチ', callback: goToMoneyHatch, withIcon: true },
      { id: 3, value: '報告書', callback: handleOpenReports },
      {
        id: 4,
        value: '通知設定',
        callback: handleOpenNotificationSettings,
      },
      { id: 5, value: 'お客様サポート', callback: handleOpenHelpInfo },
      { id: 6, value: 'チュートリアル', callback: handleOpenTutorialModal },
    ],
    [handleOpenReports, handleOpenNotificationSettings, handleOpenHelpInfo, handleOpenTutorialModal],
  );

  return (
    <div aria-hidden className={styles.wrapper} onClick={handleStopPropagation}>
      <div className={styles.logoContainer}>
        <IconButton iconName="menu" onClick={handleCloseSideBar} className={styles.menuIcon} />
        <Logo onClick={handleLogoClick} />
      </div>
      <div className={styles.itemsContainer}>
        <div className={styles.settingsContainer}>
          {optionsList.map((item) => (
            <SideBarOptionItem key={item.id} item={item} />
          ))}
        </div>
        <div
          tabIndex={0}
          role="button"
          className={styles.item}
          onClick={handleOpenConfirmationLogout}
          onKeyPress={handleKeyPressOpenConfirmationLogout}
        >
          ログアウト
        </div>
      </div>
    </div>
  );
});
SideBarExpanded.propTypes = {
  handleCloseSideBar: PropTypes.func.isRequired,
};

export default memo(SideBarExpanded);
