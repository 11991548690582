import multiFXBuySell from './multiFX/multiFxBuySell.png';
import multiFXEntry from './multiFX/multiFxEntry.png';
import multiFXEntryOco from './multiFX/multiFxEntryOco.png';
import multiFXCounter from './multiFX/multiFxCounter.png';
import multiFXFollow from './multiFX/multiFxFollow.png';
import multiFXRangeSpread from './multiFX/multiFxRangeSpread.png';
import multiFXQuantity from './multiFX/multiFxQuantity.png';
import multiFXStopLoss from './multiFX/multiFxStopLoss.png';
import multiFXProfitMargin from './multiFX/multiFxProfitMargin.png';
import multiFXCounterFix from './multiFX/multiFxCounterFix.png';
import multiFXItemsCount from './multiFX/multiFxItemsCount.png';
import multiETFBuySell from './multiETF/multiEtfBuySell.png';
import multiETFEntry from './multiETF/multiEtfEntry.png';
import multiETFEntryOco from './multiETF/multiEtfEntryOco.png';
import multiETFCounter from './multiETF/multiEtfCounter.png';
import multiETFFollow from './multiETF/multiEtfFollow.png';
import multiETFRangeSpread from './multiETF/multiEtfRangeSpread.png';
import multiETFQuantity from './multiETF/multiEtfQuantity.png';
import multiETFStopLoss from './multiETF/multiEtfStopLoss.png';
import multiETFProfitMargin from './multiETF/multiEtfProfitMargin.png';
import multiETFCounterFix from './multiETF/multiEtfCounterFix.png';
import multiETFItemsCount from './multiETF/multiEtfItemsCount.png';
import multiCFDBuySell from './multiCFD/multiCfdBuySell.png';
import multiCFDEntry from './multiCFD/multiCfdEntry.png';
import multiCFDEntryOco from './multiCFD/multiCfdEntryOco.png';
import multiCFDCounter from './multiCFD/multiCfdCounter.png';
import multiCFDFollow from './multiCFD/multiCfdFollow.png';
import multiCFDRangeSpread from './multiCFD/multiCfdRangeSpread.png';
import multiCFDQuantity from './multiCFD/multiCfdQuantity.png';
import multiCFDStopLoss from './multiCFD/multiCfdStopLoss.png';
import multiCFDProfitMargin from './multiCFD/multiCfdProfitMargin.png';
import multiCFDCounterFix from './multiCFD/multiCfdCounterFix.png';
import multiCFDItemsCount from './multiCFD/multiCfdItemsCount.png';
import techFXBarType from './techFX/techFxBarType.png';
import techFXIndicator1 from './techFX/techFxIndicator1.png';
import techFXIndicator2 from './techFX/techFxIndicator2.png';
import techFXAllSettle from './techFX/techFxAllSettle.png';
import techFXRangeSpread from './techFX/techFxRangeSpread.png';
import techFXItemsCount from './techFX/techFxItemsCount.png';
import techETFBarType from './techETF/techEtfBarType.png';
import techETFIndicator1 from './techETF/techEtfIndicator1.png';
import techETFIndicator2 from './techETF/techEtfIndicator2.png';
import techETFAllSettle from './techETF/techEtfAllSettle.png';
import techETFRangeSpread from './techETF/techEtfRangeSpread.png';
import techETFItemsCount from './techETF/techEtfItemsCount.png';
import techCFDBarType from './techCFD/techCfdBarType.png';
import techCFDIndicator1 from './techCFD/techCfdIndicator1.png';
import techCFDIndicator2 from './techCFD/techCfdIndicator2.png';
import techCFDAllSettle from './techCFD/techCfdAllSettle.png';
import techCFDRangeSpread from './techCFD/techCfdRangeSpread.png';
import techCFDItemsCount from './techCFD/techCfdItemsCount.png';

const infoImagesHelper = {
  'multiFX_buySell.png': multiFXBuySell,
  'multiFX_entry.png': multiFXEntry,
  'multiFX_entry_oco.png': multiFXEntryOco,
  'multiFX_counter.png': multiFXCounter,
  'multiFX_follow.png': multiFXFollow,
  'multiFX_rangeSpread.png': multiFXRangeSpread,
  'multiFX_quantity.png': multiFXQuantity,
  'multiFX_stopLoss.png': multiFXStopLoss,
  'multiFX_profitMargin.png': multiFXProfitMargin,
  'multiFX_counter_fix.png': multiFXCounterFix,
  'multiFX_items_count.png': multiFXItemsCount,
  'multiETF_buySell.png': multiETFBuySell,
  'multiETF_entry.png': multiETFEntry,
  'multiETF_entry_oco.png': multiETFEntryOco,
  'multiETF_counter.png': multiETFCounter,
  'multiETF_follow.png': multiETFFollow,
  'multiETF_rangeSpread.png': multiETFRangeSpread,
  'multiETF_quantity.png': multiETFQuantity,
  'multiETF_stopLoss.png': multiETFStopLoss,
  'multiETF_profitMargin.png': multiETFProfitMargin,
  'multiETF_counter_fix.png': multiETFCounterFix,
  'multiETF_items_count.png': multiETFItemsCount,
  'multiCFD_buySell.png': multiCFDBuySell,
  'multiCFD_entry.png': multiCFDEntry,
  'multiCFD_entry_oco.png': multiCFDEntryOco,
  'multiCFD_counter.png': multiCFDCounter,
  'multiCFD_follow.png': multiCFDFollow,
  'multiCFD_rangeSpread.png': multiCFDRangeSpread,
  'multiCFD_quantity.png': multiCFDQuantity,
  'multiCFD_stopLoss.png': multiCFDStopLoss,
  'multiCFD_profitMargin.png': multiCFDProfitMargin,
  'multiCFD_counter_fix.png': multiCFDCounterFix,
  'multiCFD_items_count.png': multiCFDItemsCount,
  'techFX_barType.png': techFXBarType,
  'techFX_indicator1.png': techFXIndicator1,
  'techFX_indicator2.png': techFXIndicator2,
  'techFX_allSettle.png': techFXAllSettle,
  'techFX_rangeSpread.png': techFXRangeSpread,
  'techFX_items_count.png': techFXItemsCount,
  'techETF_barType.png': techETFBarType,
  'techETF_indicator1.png': techETFIndicator1,
  'techETF_indicator2.png': techETFIndicator2,
  'techETF_allSettle.png': techETFAllSettle,
  'techETF_rangeSpread.png': techETFRangeSpread,
  'techETF_items_count.png': techETFItemsCount,
  'techCFD_barType.png': techCFDBarType,
  'techCFD_indicator1.png': techCFDIndicator1,
  'techCFD_indicator2.png': techCFDIndicator2,
  'techCFD_allSettle.png': techCFDAllSettle,
  'techCFD_rangeSpread.png': techCFDRangeSpread,
  'techCFD_items_count.png': techCFDItemsCount,
};

export default infoImagesHelper;
