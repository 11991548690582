import { TRADE_METHODS, TRADE_METHOD_FILTER_INITIAL_STATES } from '../../../constants';

// eslint-disable-next-line import/prefer-default-export
export const toTradeMethodsParameter = (tradeMethods, serviceId, isManual) => {
  if (isManual) {
    return [TRADE_METHODS.MANUAL.ID];
  }
  if (!tradeMethods.length || tradeMethods.length === TRADE_METHOD_FILTER_INITIAL_STATES[serviceId].length) {
    return [];
  }

  return tradeMethods;
};
