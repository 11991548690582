import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BUILDER_MENU,
  BUILDER_PAGES,
  BUILDER_TYPE_TAB_CREATE,
  BUILDER_TYPE_TAB_RELEASE,
} from 'shared-modules/constants/builder';
import { changeActiveTab, changeBuilderActivePage, resetBuilderOptions } from '../../../../redux/actions';
import builderIcon from '../../../../assets/builder/icon_builder.png';
import chartMakeIcon from '../../../../assets/chartMake/icon_chartmake_2.png';
import technicalBuilderIcon from '../../../../assets/technicalBuilder/icon_technicalbuilder.png';
import { useScrollOrigin } from '../../../../hooks';
import { Tabs } from '../../../../components';
import { BuildersSharePage } from '../BuildersSharePage';
import { Card } from './Card';
import styles from './builderTypePage.module.scss';

export const BuilderTypePage = memo(() => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.webBuilder.activeTab);

  useScrollOrigin();

  useEffect(() => {
    dispatch(resetBuilderOptions());
  }, [dispatch]);

  const handleChangeActiveTab = useCallback(
    (id) => {
      dispatch(changeActiveTab({ activeTab: id }));
    },
    [dispatch],
  );

  const handleClickBuilder = useCallback(() => {
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.CURRENCY_TYPE_PAGE.ID }));
  }, [dispatch]);

  const handleClickChartMaker = useCallback(() => {
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.CHART_MAKE_INSTRUMENT_CHOICE_PAGE.ID }));
  }, [dispatch]);

  const handleClickTechBuilder = useCallback(() => {
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.TECH_CURRENCY_TYPE_PAGE.ID }));
  }, [dispatch]);

  const items = useMemo(
    () => [
      {
        id: BUILDER_TYPE_TAB_CREATE,
        label: '作成',
        children: (
          <div className={styles.container}>
            <Card
              icon={builderIcon}
              alt={BUILDER_MENU.BUILDER.MAIN_TITLE}
              title={BUILDER_MENU.BUILDER.MAIN_TITLE}
              summary={BUILDER_MENU.BUILDER.SUB_TITLE}
              detail={BUILDER_MENU.BUILDER.DETAIL}
              onClick={handleClickBuilder}
            />
            <Card
              icon={chartMakeIcon}
              alt={BUILDER_MENU.CHART_MAKE.MAIN_TITLE}
              title={BUILDER_MENU.CHART_MAKE.MAIN_TITLE}
              summary={BUILDER_MENU.CHART_MAKE.SUB_TITLE}
              detail={BUILDER_MENU.CHART_MAKE.DETAIL}
              onClick={handleClickChartMaker}
            />
            <Card
              icon={technicalBuilderIcon}
              alt={BUILDER_MENU.TECH_BUILDER.MAIN_TITLE}
              title={BUILDER_MENU.TECH_BUILDER.MAIN_TITLE}
              summary={BUILDER_MENU.TECH_BUILDER.SUB_TITLE}
              detail={BUILDER_MENU.TECH_BUILDER.DETAIL}
              onClick={handleClickTechBuilder}
            />
          </div>
        ),
      },
      {
        id: BUILDER_TYPE_TAB_RELEASE,
        label: '公開',
        children: <BuildersSharePage />,
      },
    ],
    [handleClickBuilder, handleClickChartMaker, handleClickTechBuilder],
  );
  return <Tabs items={items} activeKey={activeTab} onChange={handleChangeActiveTab} />;
});
