import { put, call, select } from 'redux-saga/effects';
import { SWAP_LABEL_MAP } from '../../../constants';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { swapTransfer } from '../../../api/manualTradeApi';
import { openErrorInfoModal } from '../../actions/errorActions';
import { getPositionsRequest } from '../../actions/currenciesActions';
import { getMarginRequest } from '../../actions/portfolioActions';

function* swapTransferRequestHandler(action) {
  const serviceId = yield select((state) => state.auth.serviceId);

  const {
    payload: { positionIds },
  } = action;

  try {
    yield call(swapTransfer, { serviceId, requestBody: { positionIds } });
    yield put(sendNotificationSuccess({ message: '正常に完了しました。' }));
  } catch (e) {
    yield put(
      openErrorInfoModal({
        message: `全ての建玉の${SWAP_LABEL_MAP[serviceId]}の振替に失敗しました。`,
        title: 'エラー',
        withRefreshButton: false,
      }),
    );
  } finally {
    yield put(getPositionsRequest({ serviceId }));
    yield put(getMarginRequest({ serviceId }));
  }
}

export default swapTransferRequestHandler;
