import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { MODAL_SIZES, BULK_CHANGE_MESSAGE, BULK_CHANGE_TITLE } from 'shared-modules/constants';
import { Modal, Spin } from '../../../../components';
import styles from './multiEditNotification.module.scss';

const MultiEditNotification = () => {
  const isOpen = useSelector((state) => state.multiEdit.isLoading);

  return (
    <Modal isOpen={isOpen} title={BULK_CHANGE_TITLE} isOverlap size={MODAL_SIZES.MAX_CONTENT} withoutCloseButton>
      <div className={styles.wrapper}>{BULK_CHANGE_MESSAGE}</div>
      <Spin className={styles.loader} />
    </Modal>
  );
};

export default memo(MultiEditNotification);
