import React, { memo, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  COLORS,
  MODAL_SIZES,
  WIDTH,
  ORDER_TYPES_MAIN,
  ORDER_TYPES_VALUES,
  BUY_SELL_VALUE,
  ORDER_METHOD_VALUE,
  EXPIRATION_TYPE_MAIN,
  EXPIRATION_TYPE_VALUES,
  CFD,
  ETF,
  FX,
} from 'shared-modules/constants';
import {
  createDateString,
  formatNumberToDisplayedString,
  getServiceQuantityUnit,
  trimFrontZeros,
} from 'shared-modules/services';
import { v4 as uuid } from 'uuid';
import ReactTooltip from 'react-tooltip';
import { getCurrencyUnit, removeInstrumentIdSuffix } from 'shared-modules/utils';
import { toggleUserSettingCloseOrderSkipConfirmationRequest } from '../../../../redux/actions';
import Modal from '../../../../components/Modal';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import CustomButton from '../../../../components/CustomButton';
import styles from './manualTradeTableCloseOrderConfirmation.module.scss';

const ManualTradeTableCloseOrderConfirmation = ({ isOpen, closeModal, callback, data }) => {
  const {
    positionName,
    paymentMethod,
    closeOrderSide,
    orderMethod,
    count,
    closePrice,
    closeLimitPrice,
    closeStopPrice,
    settlementExpirationType,
    selectedDate,
    selectedTime,
    displayedInstrumentId,
    instrumentId,
  } = useMemo(() => data || {}, [data]);

  const dispatch = useDispatch();
  const serviceId = useSelector((state) => state.auth.serviceId);
  const { quantityUnitConvFactor } = useSelector((state) => state.settings.instrumentList[instrumentId]);
  const isFX = useMemo(() => serviceId === FX, [serviceId]);
  const isCFD = useMemo(() => serviceId === CFD, [serviceId]);
  const isETF = useMemo(() => serviceId === ETF, [serviceId]);
  const instrumentIdSymbol = isFX ? removeInstrumentIdSuffix(displayedInstrumentId) : displayedInstrumentId;

  const quantityUnit = getServiceQuantityUnit(serviceId);
  const currencyUnit = getCurrencyUnit(instrumentId);
  const formatPrice = (value) =>
    formatNumberToDisplayedString({
      value: trimFrontZeros(value),
      symbol: !isETF ? '' : currencyUnit,
      withoutPlus: true,
      withYenIcon: false,
    });

  const withoutConfirmation = useSelector((state) => state.settings[serviceId].skipCloseOrderConfirmation);
  const handleChangeWithoutConfirmation = useCallback(() => {
    dispatch(toggleUserSettingCloseOrderSkipConfirmationRequest());
  }, [dispatch]);

  const handleConfirmOrderClose = useCallback(() => {
    closeModal();
    callback();
  }, [closeModal, callback]);

  const tooltipId = useRef(uuid()).current;

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title="決済注文"
      size={MODAL_SIZES.WIDTH_360}
      isOverlap
      isOverlayModal
    >
      <div className={styles.nameRow}>
        決済対象
        <span className={styles.nameValue} data-for={tooltipId} data-tip={positionName}>
          {positionName}
        </span>
        <ReactTooltip id={tooltipId} />
      </div>
      <div className={styles.currencyRow}>
        銘柄
        <span>{instrumentIdSymbol}</span>
      </div>
      <div className={styles.paymentMethodRow}>
        注文方式
        <span>{ORDER_TYPES_VALUES[paymentMethod]}</span>
      </div>
      <div className={styles.buySellRow}>
        決済売買
        <span>{BUY_SELL_VALUE[closeOrderSide]}</span>
      </div>
      {paymentMethod === ORDER_TYPES_MAIN.STANDARD.ID && (
        <div className={styles.orderTermsRow}>
          注文条件
          <span>{ORDER_METHOD_VALUE[orderMethod]}</span>
        </div>
      )}
      <div className={styles.countRow}>
        <div>
          数量
          {isCFD && <div>1Lot={quantityUnitConvFactor}</div>}
        </div>
        <span className={styles.countValue}>{`${trimFrontZeros(count)}${quantityUnit}`}</span>
      </div>
      {paymentMethod === ORDER_TYPES_MAIN.STANDARD.ID && (
        <div className={styles.priceRow}>
          価格
          <span className={styles.number}>{formatPrice(closePrice)}</span>
        </div>
      )}
      {paymentMethod === ORDER_TYPES_MAIN.OCO.ID && (
        <>
          <div className={styles.designatedPriceRow}>
            指値価格
            <span className={styles.number}>{formatPrice(closeLimitPrice)}</span>
          </div>
          <div className={styles.reverseDesignatedPriceRow}>
            逆指値価格
            <span className={styles.number}>{formatPrice(closeStopPrice)}</span>
          </div>
        </>
      )}
      {paymentMethod !== ORDER_TYPES_MAIN.MARKET_ORDER.ID && (
        <div className={styles.expirationTypeRow}>
          有効期限
          <span>
            {settlementExpirationType === EXPIRATION_TYPE_MAIN.CUSTOM.ID
              ? createDateString(selectedDate, selectedTime, true)
              : EXPIRATION_TYPE_VALUES[settlementExpirationType]}
          </span>
        </div>
      )}
      <CustomCheckbox
        isChecked={withoutConfirmation}
        onChange={handleChangeWithoutConfirmation}
        label="次回以降は確認を省略する"
        isReversed
        className={styles.checkboxRow}
        labelClassName={styles.checkboxLabel}
      />
      <CustomButton
        color={COLORS.RED}
        width={WIDTH.PERCENTAGE_100}
        className={styles.button}
        onClick={handleConfirmOrderClose}
      >
        決済注文確定
      </CustomButton>
    </Modal>
  );
};
ManualTradeTableCloseOrderConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  callback: PropTypes.func,
  data: PropTypes.shape({}),
};
ManualTradeTableCloseOrderConfirmation.defaultProps = {
  callback: () => {},
  data: {},
};

export default memo(ManualTradeTableCloseOrderConfirmation);
