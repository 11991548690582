/* eslint-disable no-console */

export default class Logger {
  static sendErrorReport() {}

  static onErrorReport(callback) {
    this.sendErrorReport = callback;
  }

  static log(...args) {
    if (process.env.NODE_ENV !== 'development') return;
    console.log(args);
  }

  static error(error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    } else {
      this.sendErrorReport(error);
    }
  }
}
