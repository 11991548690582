import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';

// @deprecated
const Select = ({ items, label, onItemSelect, selectedItem, byValue, toggleClassName }) => {
  return (
    <div className="me-4">
      {label && <p>{label}</p>}
      <Dropdown>
        {items ? (
          <>
            <Dropdown.Toggle variant="dark" size="lg" bsPrefix={toggleClassName}>
              {selectedItem ? selectedItem.label : items[0].label}
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
              {items.map((item) => (
                <Dropdown.Item
                  key={item.value}
                  active={item === selectedItem}
                  onClick={() => onItemSelect(byValue ? item.value : item)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{item.label} </div>
                    {item === selectedItem && (
                      <div className="material-icons-outlined" style={{ fontSize: 13 }}>
                        check
                      </div>
                    )}
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </>
        ) : (
          <Dropdown.Toggle variant="dark" size="lg" onClick={onItemSelect} bsPrefix={toggleClassName}>
            {selectedItem}
          </Dropdown.Toggle>
        )}
      </Dropdown>
    </div>
  );
};

Select.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired })),
  label: PropTypes.string,
  onItemSelect: PropTypes.func.isRequired,
  toggleClassName: PropTypes.string,
  byValue: PropTypes.bool,
  selectedItem: PropTypes.oneOfType([
    PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
    PropTypes.string,
  ]),
};

Select.defaultProps = {
  items: null,
  label: '',
  byValue: false,
  toggleClassName: 'dropdown-toggle dropdown-chevron',
  selectedItem: null,
};

export default Select;
