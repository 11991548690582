/* eslint-disable global-require */
const logicImages = {
  'bear_gurutore.gif': require('./bear_gurutore.gif'),
  'bull_gurutore.gif': require('./bull_gurutore.gif'),
  'core_ranger.gif': require('./core_ranger.gif'),
  'core_ranger_bull.gif': require('./core_ranger_bull.gif'),
  'core_ranger_bear.gif': require('./core_ranger_bear.gif'),
  'half.gif': require('./half.gif'),
  'swapper.gif': require('./swapper.gif'),
  'logic7.gif': require('./logic7.gif'),
  'counter.gif': require('./counter.gif'),
  'follow.gif': require('./follow.gif'),
  'hedger.gif': require('./hedger.gif'),
  'chartmake_hedger.gif': require('./chartmake_hedger.gif'),
  'rising.gif': require('./rising.gif'),
  'three_cards.gif': require('./three_cards.gif'),
  'protector.gif': require('./protector.gif'),
  'short.gif': require('./short.gif'),
  'strategist.png': require('./strategist.png'),
  'cover_core_ranger.gif': require('./chartmakeCover/core_ranger.gif'),
  'cover_core_ranger_bull.gif': require('./chartmakeCover/core_ranger_bull.gif'),
  'cover_core_ranger_bear.gif': require('./chartmakeCover/core_ranger_bear.gif'),
  'cover_half.gif': require('./chartmakeCover/half.gif'),
  'cover_swapper.gif': require('./chartmakeCover/swapper.gif'),
  'cover_protector.gif': require('./chartmakeCover/protector.gif'),
  'cover_hedger.gif': require('./chartmakeCover/hedger.gif'),
};

export default logicImages;
