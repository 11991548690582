import { memo } from 'react';
import { SplitPane } from '../../components';
import { ChartArea } from './components/ChartArea';
import { CartTable } from './components/CartTable';
import { SideMenu } from './components/SideMenu';
import styles from './cart.module.scss';

export const Cart = memo(() => (
  <div className={styles.container}>
    <div className={styles.sideMenu}>
      <SideMenu />
    </div>
    <div className={styles.content}>
      <SplitPane split="horizontal" defaultSize="60%" minSize={200}>
        <ChartArea />
        <CartTable />
      </SplitPane>
    </div>
  </div>
));
