import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useValueToColor } from '../../hooks';
import { PercentageText } from './PercentageText';

export const ColoredRateText = memo(({ value, className, symbolClassName }) => {
  const { getNumericalValueColor } = useValueToColor();
  const color = useMemo(() => getNumericalValueColor(value), [value, getNumericalValueColor]);
  return <PercentageText className={className} symbolClassName={symbolClassName} color={color} value={value} />;
});

ColoredRateText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  symbolClassName: PropTypes.string,
};

ColoredRateText.defaultProps = {
  value: undefined,
  className: undefined,
  symbolClassName: undefined,
};
