import { memo } from 'react';
import { PriceValue } from '../PriceValue';

const PriceValueETF = ({ priceValue, instrumentId, previousValue, label, isReversed }) => (
  <PriceValue
    priceValue={priceValue}
    instrumentId={instrumentId}
    previousValue={previousValue}
    label={label}
    isReversed={isReversed}
  />
);

PriceValueETF.propTypes = PriceValue.propTypes;

PriceValueETF.defaultProps = { ...PriceValue.defaultProps };

export default memo(PriceValueETF);
