import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MultiEditTableHeader from './MultiEditTableHeader';
import MultiEditTableBody from './MultiEditTableBody';
import styles from './multiEditTable.module.scss';
import { SPACER } from '../../constants';

const MultiEditTable = ({ useNewOrderLineUpdate, bodyHeight, chartHeight, setChartHeight, propTableRowsRef }) => (
  <div className={styles.wrapper} style={{ height: bodyHeight - chartHeight - SPACER }}>
    <MultiEditTableHeader
      bodyHeight={bodyHeight}
      chartHeight={chartHeight}
      setChartHeight={setChartHeight}
      propTableRowsRef={propTableRowsRef}
    />
    <MultiEditTableBody
      useNewOrderLineUpdate={useNewOrderLineUpdate}
      propTableRowsRef={propTableRowsRef}
      // useClosedOrderLineUpdate={useClosedOrderLineUpdate}
    />
  </div>
);

MultiEditTable.propTypes = {
  useNewOrderLineUpdate: PropTypes.func.isRequired,
  // useClosedOrderLineUpdate: PropTypes.func.isRequired,
  bodyHeight: PropTypes.number,
  chartHeight: PropTypes.number,
  setChartHeight: PropTypes.func,
  propTableRowsRef: PropTypes.shape({ current: PropTypes.shape({}).isRequired }).isRequired,
};

MultiEditTable.defaultProps = {
  bodyHeight: 0,
  chartHeight: 0,
  setChartHeight() {},
};

export default memo(MultiEditTable);
