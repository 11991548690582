import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DEFAULT_POSITIONS_TABLE_SORT_BY,
  DEFAULT_POSITIONS_TABLE_SORT_DIR,
  SORT_DESCENDING,
} from 'shared-modules/constants/manualTrade';
import { CFD, ETF, FX, POSITIONS_EMPTY_MESSAGE } from 'shared-modules/constants';
import { Table } from '../../../../../../../../components';
import CellPortfolioPositionTableActions from '../../../../../CellPortfolioPositionTableActions';
import styles from '../../../../../PortfolioAutoTradeDetail/components/DetailTable/detailTable.module.scss';
import { getModalPositionsTableTemplate } from '../../../../../../../../services/tableTemplate';
import { tutorialPositionsTable } from '../../../../../../../../constants/tutorial/classNames';

const DEFAULT_SORTING = [
  {
    id: DEFAULT_POSITIONS_TABLE_SORT_BY,
    desc: DEFAULT_POSITIONS_TABLE_SORT_DIR === SORT_DESCENDING,
  },
];

const RESIZED = {
  [FX]: {
    KEY: 'portfolioFXAutoTradePositionsTable',
    COLUMNS: [78, 68, 40, 78, 78, 78, 74, 74, 84, 88, 80, 74],
  },
  [ETF]: {
    KEY: 'portfolioETFAutoTradePositionsTable',
    COLUMNS: [80, 68, 40, 74, 78, 74, 74, 74, 74, 95, 87, 74, 74],
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    KEY: 'portfolioCFDAutoTradePositionsTable',
    COLUMNS: [80, 68, 40, 74, 78, 74, 74, 74, 74, 95, 87, 74, 74],
  },
};

const TutorialTablePositions = ({ serviceId }) => {
  const selectedPositionsMetaInfo = useSelector((state) => state.portfolio.selectedPositionsMetaInfo[serviceId]);

  const tableData = useSelector((state) => state.webTutorial.positionsData);
  const tableMetaInfo = useMemo(
    () => ({
      loading: false,
      sortBy: DEFAULT_POSITIONS_TABLE_SORT_BY,
      sortDir: DEFAULT_POSITIONS_TABLE_SORT_DIR,
      ...selectedPositionsMetaInfo,
    }),
    [selectedPositionsMetaInfo],
  );

  const resized = useMemo(() => {
    const { KEY, COLUMNS } = RESIZED[serviceId];
    return {
      key: KEY,
      default: [...COLUMNS],
    };
  }, [serviceId]);

  const columns = useMemo(
    () => [
      ...getModalPositionsTableTemplate(serviceId),
      {
        Header: '',
        accessor: 'tableButton',
        disableSortBy: true,
        sticky: 'right',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <CellPortfolioPositionTableActions isForInstrument serviceId={serviceId} row={row} />,
      },
    ],
    [serviceId],
  );

  return (
    <span className={tutorialPositionsTable}>
      <Table
        key={serviceId}
        tableData={tableData}
        columns={columns}
        resized={resized}
        emptyText={POSITIONS_EMPTY_MESSAGE}
        className={styles.table}
        tableMetaInfo={tableMetaInfo}
        defaultSorting={DEFAULT_SORTING}
        useServerSorting
      />
    </span>
  );
};

TutorialTablePositions.propTypes = {
  serviceId: PropTypes.string.isRequired,
};

export default memo(TutorialTablePositions);
