import React, { memo, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ItemWrapper } from './ItemWrapper';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './scrollableItems.module.scss';

const calcScrollWidth = (difference, scrollWidth) => Math.ceil(difference / scrollWidth) * scrollWidth;

export const ScrollableItems = memo(
  ({
    activeElement,
    items,
    scrollWidth,
    className,
    containerClassName,
    noScrollContainerClassName,
    disabledScroll,
  }) => {
    const containerRef = useRef(null);
    const scrollElementRef = useRef(null);
    const [enabledScroll, setEnabledScroll] = useState(false);
    const [isScrollMin, setIsScrollMin] = useState(true);
    const [isScrollMax, setIsScrollMax] = useState(false);

    const containerStyle = useMemo(() => {
      if (!enabledScroll) {
        return { gridTemplateColumns: 'max-content' };
      }
      return undefined;
    }, [enabledScroll]);

    const refreshScrollButtons = useCallback(() => {
      const container = containerRef.current;
      const scrollElement = scrollElementRef.current;
      if (!container || !scrollElement || disabledScroll) {
        return;
      }
      setEnabledScroll(container.clientWidth - scrollElement.scrollWidth < 0);
      const justLeft = scrollElement.scrollLeft === 0;
      setIsScrollMin(justLeft);
      setIsScrollMax(
        justLeft ? false : scrollElement.scrollLeft + scrollElement.clientWidth === scrollElement.scrollWidth,
      );
    }, [disabledScroll]);

    const adjustScroll = useCallback(
      (target) => {
        if (!enabledScroll) {
          return;
        }
        const scrollElement = scrollElementRef.current;
        const x1 = scrollElement.scrollLeft;
        const x2 = x1 + scrollElement.clientWidth;
        const targetX1 = target.offsetLeft + target.clientLeft;
        const targetX2 = targetX1 + target.clientWidth;
        if (x1 > targetX1) {
          const calculatedScrollWidth = calcScrollWidth(x1 - targetX1, scrollWidth);
          setIsScrollMin(scrollElement.scrollLeft - calculatedScrollWidth <= 0);
          setIsScrollMax(false);
          scrollElement.scrollTo({
            left: scrollElement.scrollLeft - calculatedScrollWidth,
            behavior: 'smooth',
          });
          // scrollElement.scrollLeft -= calculatedScrollWidth;
        } else if (x2 < targetX2) {
          const calculatedScrollWidth = calcScrollWidth(targetX2 - x2, scrollWidth);
          setIsScrollMin(false);
          setIsScrollMax(x1 + scrollElement.clientWidth + calculatedScrollWidth >= scrollElement.scrollWidth);
          scrollElement.scrollTo({
            left: scrollElement.scrollLeft + calculatedScrollWidth,
            behavior: 'smooth',
          });
          // scrollElement.scrollLeft += calculatedScrollWidth;
        }
      },
      [enabledScroll, scrollWidth],
    );

    const handleClickItem = useCallback((event) => adjustScroll(event.target), [adjustScroll]);

    const handleClickBack = useCallback(() => {
      if (isScrollMin) {
        return;
      }
      const scrollElement = scrollElementRef.current;
      setIsScrollMin(scrollElement.scrollLeft - scrollWidth <= 0);
      setIsScrollMax(false);
      scrollElement.scrollTo({
        left: scrollElement.scrollLeft - scrollWidth,
        behavior: 'smooth',
      });
      // scrollElement.scrollLeft -= scrollWidth;
    }, [isScrollMin, scrollWidth]);
    const handleKeyDownBack = useKeyDownHandler(handleClickBack);

    const handleClickForward = useCallback(() => {
      if (isScrollMax) {
        return;
      }
      const scrollElement = scrollElementRef.current;
      setIsScrollMin(false);
      const { scrollLeft, clientWidth } = scrollElement;
      setIsScrollMax(scrollLeft + clientWidth + scrollWidth >= scrollElement.scrollWidth);
      scrollElement.scrollTo({
        left: scrollElement.scrollLeft + scrollWidth,
        behavior: 'smooth',
      });
      // scrollElement.scrollLeft += scrollWidth;
    }, [isScrollMax, scrollWidth]);
    const handleKeyDownForward = useKeyDownHandler(handleClickForward);

    useEffect(() => {
      refreshScrollButtons();
    }, [items, refreshScrollButtons]);

    useEffect(() => {
      if (activeElement) {
        adjustScroll(activeElement);
      }
    }, [activeElement, adjustScroll]);

    useEffect(() => {
      if (!window.ResizeObserver) {
        return () => {};
      }
      const container = containerRef.current;
      const observer = new window.ResizeObserver(() => {
        refreshScrollButtons();
      });
      observer.observe(container);
      return () => {
        observer.unobserve(container);
      };
    }, [refreshScrollButtons]);

    return (
      <div
        style={containerStyle}
        className={classNames(
          styles.container,
          containerClassName,
          noScrollContainerClassName && { [noScrollContainerClassName]: !enabledScroll },
        )}
        ref={containerRef}
      >
        {enabledScroll && (
          <i
            style={{ paddingLeft: 8 }}
            className={classNames('material-icons', styles.arrow, { [styles.disabled]: isScrollMin })}
            tabIndex={isScrollMin ? -1 : 0}
            role="button"
            onClick={handleClickBack}
            onKeyDown={handleKeyDownBack}
          >
            arrow_back_ios
          </i>
        )}
        <div className={classNames(styles.scrollArea, className)} ref={scrollElementRef}>
          {items.map((item) => (
            <ItemWrapper key={item.key} disabled={item.props?.disabled} onClick={handleClickItem}>
              {item}
            </ItemWrapper>
          ))}
        </div>
        {enabledScroll && (
          <i
            className={classNames('material-icons', styles.arrow, { [styles.disabled]: isScrollMax })}
            tabIndex={isScrollMax ? -1 : 0}
            role="button"
            onClick={handleClickForward}
            onKeyDown={handleKeyDownForward}
          >
            arrow_forward_ios
          </i>
        )}
      </div>
    );
  },
);

ScrollableItems.propTypes = {
  activeElement: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.shape({
      offsetLeft: PropTypes.number.isRequired,
      clientLeft: PropTypes.number.isRequired,
      clientWidth: PropTypes.number.isRequired,
    }),
  ]),
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  scrollWidth: PropTypes.number,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  noScrollContainerClassName: PropTypes.string,
  disabledScroll: PropTypes.bool,
};

ScrollableItems.defaultProps = {
  activeElement: undefined,
  scrollWidth: 120,
  className: undefined,
  containerClassName: undefined,
  noScrollContainerClassName: undefined,
  disabledScroll: false,
};
