import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as DownArrow } from '../../../../../../assets/downArrow.svg';
import { ReactComponent as UpArrow } from '../../../../../../assets/upArrow.svg';
import { highlightCurrencyPairRate } from '../../../../../../services';
import styles from './valueCompared.module.scss';

const ARROW_SIZE = 17;

const ValueCompared = ({ prevValue, value, instrumentId, isClosed }) => {
  const formattedValue = useMemo(() => highlightCurrencyPairRate(instrumentId, value), [instrumentId, value]);

  return (
    <div className={styles.wrapper}>
      {prevValue && value < prevValue && (
        <DownArrow width={ARROW_SIZE} height={ARROW_SIZE} className={styles.arrowIcon} key={value} />
      )}

      {prevValue && value > prevValue && (
        <UpArrow
          width={ARROW_SIZE}
          height={ARROW_SIZE}
          className={classNames(styles.arrowIcon, styles.isRed)}
          key={value}
        />
      )}

      <div className={classNames({ [styles.isClosed]: isClosed })}>{value && formattedValue}</div>
    </div>
  );
};

ValueCompared.propTypes = {
  prevValue: PropTypes.number,
  value: PropTypes.number,
  isClosed: PropTypes.bool,
  instrumentId: PropTypes.string,
};

ValueCompared.defaultProps = {
  prevValue: null,
  value: null,
  isClosed: false,
  instrumentId: '',
};

export default memo(ValueCompared);
