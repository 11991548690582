import { compareServiceId } from '.';

export const fromCommaSeparatedServiceIdToServiceIds = (commaSeparatedServiceId) => {
  if (!commaSeparatedServiceId) {
    return [];
  }
  const serviceIds = commaSeparatedServiceId.split(',').filter((serviceId) => serviceId);
  serviceIds.sort(compareServiceId);
  return serviceIds;
};
