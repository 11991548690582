import {
  GET_USER_SSO_MONEY_HATCH_SUCCESS,
  GET_USER_SSO_MY_PAGE_SUCCESS,
  GET_USER_SSO_PAYMENT_SUCCESS,
  GET_USER_SSO_CASH_FLOW_SUCCESS,
  RESET_SSO_DATA,
  GET_USER_SSO_INVAST_AFFILIATE_SUCCESS,
} from '../actionConstants/userSSOConstants';

export const initialState = {
  userSSOMyPage: {
    url: '',
    token: '',
    hash: '',
  },
  userSSOPayment: {
    url: '',
    token: '',
    hash: '',
  },
  moneyHatch: {
    url: '',
    token: '',
    hash: '',
  },
  userSSOCashflow: {
    url: '',
    token: '',
    hash: '',
  },
  userSSOInvastAffiliate: {
    url: '',
    token: '',
    hash: '',
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_SSO_MY_PAGE_SUCCESS: {
      const { url, token, hash } = payload;
      return {
        ...state,
        userSSOMyPage: { url, token, hash },
      };
    }
    case GET_USER_SSO_PAYMENT_SUCCESS: {
      const { url, token, hash } = payload;
      return {
        ...state,
        userSSOPayment: { url, token, hash },
      };
    }
    case GET_USER_SSO_MONEY_HATCH_SUCCESS: {
      const { url, token, hash } = payload;
      return {
        ...state,
        moneyHatch: { url, token, hash },
      };
    }
    case GET_USER_SSO_CASH_FLOW_SUCCESS: {
      const { url, token, hash } = payload;
      return {
        ...state,
        userSSOCashflow: { url, token, hash },
      };
    }
    case GET_USER_SSO_INVAST_AFFILIATE_SUCCESS: {
      const { url, token, hash } = payload;
      return {
        ...state,
        userSSOInvastAffiliate: { url, token, hash },
      };
    }
    case RESET_SSO_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
