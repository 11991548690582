import { memo, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useBalanceMeter, useBalanceMeterTooltip } from 'shared-modules/hooks/balanceMeter';
import { CircularMeter, MAX_STROKE_WIDTH } from '../CircularMeter';
import { Tooltip } from '../Tooltip';
import { Dot } from './Dot';
import styles from './balanceMeter.module.scss';
import { Button } from '../Button';

const min = 0;
const max = 1;

export const BalanceMeter = memo(
  ({
    enabledFuture,
    meterData,
    size,
    className,
    badgeClassName,
    overviewClassName,
    onChangeAttention,
    isDisabled,
    isMarginZero,
    onClick,
  }) => {
    const [ready, setReady] = useState(false);
    const { value, value2, attention } = useBalanceMeter({
      meterData,
      enabledFuture,
      onChangeAttention,
      min,
      max,
    });

    // 建玉必要証拠金が0かつ注文中必要証拠金が0の場合はバッジ等を非表示
    const isBalanceMeterVisible = useMemo(
      () =>
        meterData?.positionRequiredMargin === 0 &&
        meterData?.orderingRequiredMargin === 0 &&
        !meterData?.consumedMargin,
      [meterData?.positionRequiredMargin, meterData?.orderingRequiredMargin, meterData?.consumedMargin],
    );

    const { badge, overview, color: textColor } = attention;

    const tooltip = useBalanceMeterTooltip({ isTrading: value || value2, attention });

    const handleReady = useCallback(() => {
      setReady(true);
    }, []);

    return (
      <div className={styles.containerWrapper}>
        <div className={classNames(styles.container, className, { [styles.isMarginZero]: isMarginZero })}>
          <div className={styles.meter}>
            <CircularMeter
              size={size}
              value={!isMarginZero && ready ? value : null}
              value2={!isMarginZero && ready ? value2 : null}
              min={min}
              max={max}
              onReady={handleReady}
            >
              {!isDisabled && !isMarginZero && ready && isBalanceMeterVisible && (
                <Tooltip content={tooltip && <div className={styles.tooltip}>{tooltip}</div>} cover>
                  <div className={styles.attention} style={{ height: size, width: size }} />
                </Tooltip>
              )}
              {!isDisabled && !isMarginZero && ready && !isBalanceMeterVisible && (
                <Tooltip content={tooltip && <div className={styles.tooltip}>{tooltip}</div>} cover>
                  <div className={styles.attention} style={{ height: size, width: size }}>
                    <div className={classNames(styles.overview, overviewClassName)} style={{ color: textColor }}>
                      {overview}
                    </div>
                    <div className={classNames(styles.badge, badgeClassName)} style={{ color: textColor }}>
                      {badge}
                    </div>
                  </div>
                </Tooltip>
              )}
            </CircularMeter>
          </div>
          {enabledFuture && !isMarginZero && ready && !isBalanceMeterVisible && (
            <div className={styles.legend}>
              <div className={styles.legendRow}>
                <Dot className={styles.nowFill} size={MAX_STROKE_WIDTH} adjustedValue={1} />
                <div className={classNames(styles.legendText, styles.nowColor)}>現在</div>
              </div>
              <div className={styles.legendRow}>
                <Dot className={styles.futureFill} size={MAX_STROKE_WIDTH} />
                <div className={classNames(styles.legendText, styles.futureColor)}>稼働後</div>
              </div>
            </div>
          )}
        </div>
        {isMarginZero && (
          <div className={styles.marginZeroContainer}>
            <div className={styles.marginZero}>
              <p>
                口座に入金すると
                <br />
                バランスメーターが表示されます
                <Button className={styles.cashButton} onClick={onClick}>
                  入金する
                </Button>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  },
);

BalanceMeter.propTypes = {
  enabledFuture: PropTypes.bool,
  meterData: PropTypes.shape({
    effectiveMargin: PropTypes.number,
    orderableMargin: PropTypes.number,
    positionRequiredMargin: PropTypes.number,
    orderingRequiredMargin: PropTypes.number,
    consumedMargin: PropTypes.number,
  }),
  size: PropTypes.number,
  className: PropTypes.string,
  badgeClassName: PropTypes.string,
  overviewClassName: PropTypes.string,
  onChangeAttention: PropTypes.func,
  isDisabled: PropTypes.bool,
  isMarginZero: PropTypes.bool,
  onClick: PropTypes.func,
};

BalanceMeter.defaultProps = {
  enabledFuture: false,
  meterData: undefined,
  size: 190,
  className: undefined,
  badgeClassName: undefined,
  overviewClassName: undefined,
  onChangeAttention: undefined,
  isDisabled: false,
  isMarginZero: false,
  onClick: undefined,
};
