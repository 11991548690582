import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { CFD, ETF, FX } from 'shared-modules/constants';
import { getServiceName } from 'shared-modules/utils';
import { useChangeService, useUuid } from 'shared-modules/services/hooks';
import { BUILDER_PAGES } from 'shared-modules/constants/builder';
import { setFormValue } from 'shared-modules/redux/labs';
import { useAccountInfo } from 'shared-modules/hooks/useAccountInfo';
import { changeBuilderActivePage } from '../../../../redux/actions';
import { Button } from '../../../../components';

const useStartButtonCallback = (serviceId) => {
  const dispatch = useDispatch();
  const { onChange, options } = useChangeService();

  const nextPage = useCallback(() => {
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.BUILDERS_SHARE_SELECT_PAGE.ID }));
  }, [dispatch]);

  return useCallback(() => {
    onChange(serviceId);
    dispatch(setFormValue({ key: 'serviceId', value: serviceId }));
    if (!options.find((item) => item.id === serviceId).isVisuallyDisabled) {
      nextPage();
    }
  }, [dispatch, nextPage, onChange, options, serviceId]);
};

const makeButtonCaption = (serviceId, caption) => {
  if (caption == null) {
    return `${getServiceName(serviceId)}ではじめる`;
  }
  return caption;
};

export const useStartShareButton = (serviceId, buttonCaption) => {
  const tooltipId = useUuid();
  const accountInfo = useAccountInfo()[serviceId];
  const buttonCaptionRef = useRef(makeButtonCaption(serviceId, buttonCaption));
  const handleClick = useStartButtonCallback(serviceId);
  const tooltipMessage = useMemo(() => {
    if (accountInfo.notExist) return '口座未開設';
    if (accountInfo.isMaintenance) return 'メンテナンス中';
    if (accountInfo.isLossCut) return 'ロスカット中';
    return '';
  }, [accountInfo.isLossCut, accountInfo.isMaintenance, accountInfo.notExist]);

  useEffect(() => {
    buttonCaptionRef.current = makeButtonCaption(serviceId, buttonCaption);
  }, [serviceId, buttonCaption]);

  return useMemo(() => {
    const button = (
      <Button visuallyDisabled={accountInfo.isNotAvailable} onClick={handleClick}>
        {buttonCaptionRef.current}
      </Button>
    );
    if (accountInfo.isNotAvailable) {
      return (
        <>
          <div data-for={tooltipId} data-tip={tooltipMessage}>
            {button}
          </div>
          <ReactTooltip id={tooltipId} />
        </>
      );
    }
    return button;
  }, [accountInfo.isNotAvailable, handleClick, tooltipId, tooltipMessage]);
};

export const useStartShareButtons = () => {
  const fxButton = useStartShareButton(FX);
  const etfButton = useStartShareButton(ETF);
  const cfdButton = useStartShareButton(CFD);
  return useMemo(
    () => [
      { key: FX, value: fxButton },
      { key: CFD, value: cfdButton },
      { key: ETF, value: etfButton },
    ],
    [fxButton, etfButton, cfdButton],
  );
};
