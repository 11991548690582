import React, { memo } from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './option.module.scss';

export const Option = memo(({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.Option {...props}>
    <>
      {children}
      {props.isSelected && <i className={classNames(styles.icon, 'material-icons')}>check</i>}
    </>
  </components.Option>
));

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
