import styleConstants from 'shared-modules/styles/_constants.scss';

export const CLEAR = 'クリア';
export const DEFAULT_COLORS = {
  mainThemeColor: styleConstants.WEB_BACKGROUND_GREY,
  mainThemeHoverColor: styleConstants.WEB_BACKGROUND_GREY_HOVER,
};

export const LIGHTER_COLORS = {
  mainThemeColor: styleConstants.WEB_GREY,
  mainThemeHoverColor: styleConstants.WEB_GREY_HOVER,
};
