import { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMeter } from 'shared-modules/hooks/circularMeter';
import { HandleCircle } from './HandleCircle';
import { BackCircle } from './BackCircle';
import { PrimaryCircle } from './PrimaryCircle';
import { SecondaryCircle } from './SecondaryCircle';
import styles from './circularMeter.module.scss';

export const MAX_STROKE_WIDTH = 10;
// see circularMeter.module.scss
const TRANSITION_DURATION = 400;

// value1 > value2 の場合、value2 は value1 と同じ値として扱われる
export const CircularMeter = memo(({ size, max, min, value, value2, children, onReady }) => {
  const circleInfo = useMemo(() => {
    const strokeWidth = 5;
    const offset = Math.max(strokeWidth, MAX_STROKE_WIDTH);
    const hs = size / 2;
    const cx = hs;
    const cy = offset;
    const r = hs - offset;
    const circumference = 2 * r * Math.PI;
    const effectiveRange = circumference / 1.252;
    return { cx, cy, r, circumference, effectiveRange, strokeWidth };
  }, [size]);

  const { empty, position1, position2 } = useMeter(
    value,
    value2,
    min,
    max,
    circleInfo.effectiveRange,
    TRANSITION_DURATION,
  );

  useEffect(() => {
    onReady?.();
  }, [onReady]);

  return (
    <div className={styles.container}>
      <svg className={styles.meter} width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <BackCircle
          className={styles.meterAnimation}
          size={size}
          circleInfo={circleInfo}
          position={Math.max(position1, position2 ?? 0)}
        />
        {!empty && (
          <>
            {position2 != null && (
              <SecondaryCircle
                className={styles.meterAnimation}
                position1={position1}
                position2={position2}
                size={size}
                circleInfo={circleInfo}
              />
            )}
            <PrimaryCircle className={styles.meterAnimation} position={position1} size={size} circleInfo={circleInfo} />
            {position2 != null && (
              <HandleCircle
                className={styles.handleAnimation}
                position={position2}
                color="#fffe48"
                width={MAX_STROKE_WIDTH}
                circleInfo={circleInfo}
              />
            )}
            <HandleCircle
              className={styles.handleAnimation}
              position={position1}
              color="#dcdcdc"
              width={MAX_STROKE_WIDTH - 2}
              circleInfo={circleInfo}
            />
          </>
        )}
      </svg>
      {children && (
        <div style={{ width: size, height: size }} className={styles.wrapper}>
          {children}
        </div>
      )}
    </div>
  );
});

CircularMeter.propTypes = {
  size: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.number,
  value2: PropTypes.number,
  children: PropTypes.node,
  onReady: PropTypes.func,
};

CircularMeter.defaultProps = {
  size: 200,
  max: 100,
  min: 0,
  value: undefined,
  value2: undefined,
  children: undefined,
  onReady: undefined,
};
