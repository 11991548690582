import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { fromCommaSeparatedServiceIdToServiceIds } from 'shared-modules/utils/guide';
import { GUIDE_LINK_TYPE_VIDEO, YOUTUBE_REG } from 'shared-modules/constants';
import classNames from 'classnames';
import { ReactComponent as Youtube } from '../../../assets/youtube.svg';
import { Tag } from '../../../components/Tag/index';
import { openYoutubeModal } from '../../../redux/actions';
import styles from '../media.module.scss';
import HorizontalScroll from './HorizontalScroll';
import filmStripStyles from './filmStripItems.module.scss';

const FlimStripItems = ({ items, isHome }) => {
  const dispatch = useDispatch();

  const openLink = useCallback(
    (externalLinkUrl, externalLinkName) => {
      if (!externalLinkUrl || !externalLinkName) {
        return;
      }

      const isYoutubeLink = YOUTUBE_REG.test(externalLinkUrl);

      if (isYoutubeLink) {
        dispatch(openYoutubeModal({ url: externalLinkUrl }));
        return;
      }

      window.open(externalLinkUrl, externalLinkName);
    },
    [dispatch],
  );

  const articleList = useMemo(() => orderBy(items, (i) => i.publishedTime, 'desc'), [items]);
  if (isHome)
    return (
      <>
        <div style={{ marginLeft: 10, width: '100%' }}>
          <div className={styles.home}>
            {articleList.map((item) => (
              <article
                aria-hidden="true"
                key={item.itemId}
                className={classNames(filmStripStyles.card)}
                onClick={() => openLink(item.linkUrl, 'linkName')}
              >
                <div className={styles.imageContainer}>
                  <img src={item.thumbnailUrl} loading="lazy" className={styles.thumbnail} alt="" />
                  {item?.type === GUIDE_LINK_TYPE_VIDEO && (
                    <button
                      className={classNames(styles.youtubeButtonHome, 'ytp-large-play-button ytp-button')}
                      type="button"
                      aria-label="Play"
                    >
                      <Youtube className={filmStripStyles.path} />
                    </button>
                  )}
                </div>
                <p className={styles.articleTitle}>{item.title}</p>
              </article>
            ))}
          </div>
        </div>
      </>
    );
  return (
    <HorizontalScroll contentClassName={filmStripStyles.cardContainer}>
      {articleList.map((item) => (
        <article
          aria-hidden="true"
          key={item.itemId}
          className={filmStripStyles.card}
          onClick={() => openLink(item.linkUrl, 'linkName')}
        >
          <div className={styles.imageContainer}>
            <img src={item.thumbnailUrl} loading="lazy" className={styles.thumbnail} alt="" />
            {item?.type === GUIDE_LINK_TYPE_VIDEO && (
              <button className="ytp-large-play-button ytp-button" type="button" aria-label="Play">
                <Youtube className={filmStripStyles.path} />
              </button>
            )}
          </div>
          <div className={styles.postTime}>
            <span className={styles.triauto}>
              <div className={styles.tags}>
                {fromCommaSeparatedServiceIdToServiceIds(item?.serviceId).map((serviceId) => (
                  <Tag key={serviceId} className={styles.serviceTag} text={serviceId} />
                ))}
              </div>
              &nbsp;&nbsp;
            </span>
          </div>
          <p className={styles.articleTitle}>{item.title}</p>
        </article>
      ))}
    </HorizontalScroll>
  );
};

FlimStripItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isHome: PropTypes.bool,
};

FlimStripItems.defaultProps = {
  isHome: false,
};

export default FlimStripItems;
