import { ALL_SERVICES } from './core';
import {
  FX,
  ETF,
  SSO_PAYMENT_TRANSFER_REDIRECT_URL,
  SSO_PAYMENT_WITHDRAWAL_REDIRECT_URL,
  SSO_PAYMENT_DETAIL_REDIRECT_URL,
  CFD,
} from './index';

export const CASH_PAGES = {
  CASH_MENU: {
    ID: 1,
    NAME: 'cashMenu',
  },
  CASH_QUICK_DEPOSIT_SELECT: {
    ID: 10,
    NAME: 'cashQuickDepositSelect',
  },
  CASH_QUICK_DEPOSIT_CONFIRM: {
    ID: 11,
    NAME: 'cashQuickDepositConfirm',
  },
  CASH_QUICK_DEPOSIT_SUCCESS: {
    ID: 12,
    NAME: 'cashQuickDepositSuccess',
  },
  CASH_QUICK_DEPOSIT_FAILED: {
    ID: 13,
    NAME: 'cashQuickDepositFailed',
  },
  CASH_DEPOSIT_VIEW: {
    ID: 20,
    NAME: 'cashDepositView',
  },
  CASH_TRANSFER: {
    ID: 30,
    NAME: 'cashTransfer',
  },
  CASH_WITHDRAWAL: {
    ID: 40,
    NAME: 'cashWithdrawal',
  },
  CASH_DETAILS: {
    ID: 50,
    NAME: 'cashDetails',
  },
};

export const CASH_PAGE_TITLE = {
  CASH: '入出金',
  QUICK_DEPOSIT: '即時入金',
  DEPOSIT: '振込入金',
  TRANSFER: '資金振替',
  WITHDRAWAL: '出金',
  DETAILS: '入出金・振替履歴',
};

export const CASH_MENU = [
  {
    title: CASH_PAGE_TITLE.QUICK_DEPOSIT,
    description: 'インターネットでの入金',
    remarks: '（原則24時間可）',
    pageId: CASH_PAGES.CASH_QUICK_DEPOSIT_SELECT.ID,
    pageName: CASH_PAGES.CASH_QUICK_DEPOSIT_SELECT.NAME,
    key: 1,
  },
  {
    title: CASH_PAGE_TITLE.DEPOSIT,
    description: '銀行窓口やATMなどから入金',
    pageId: CASH_PAGES.CASH_DEPOSIT_VIEW.ID,
    pageName: CASH_PAGES.CASH_DEPOSIT_VIEW.NAME,
    key: 2,
  },
  {
    title: CASH_PAGE_TITLE.TRANSFER,
    description: '他サービスへの資金振替',
    pageUrl: SSO_PAYMENT_TRANSFER_REDIRECT_URL,
    pageId: CASH_PAGES.CASH_TRANSFER.ID,
    pageName: CASH_PAGES.CASH_TRANSFER.NAME,
    useUrl: true,
    key: 3,
  },
  {
    title: CASH_PAGE_TITLE.WITHDRAWAL,
    description: '取引口座から指定先金融口座に出金',
    pageUrl: SSO_PAYMENT_WITHDRAWAL_REDIRECT_URL,
    pageId: CASH_PAGES.CASH_WITHDRAWAL.ID,
    pageName: CASH_PAGES.CASH_WITHDRAWAL.NAME,
    useUrl: true,
    key: 4,
  },
  {
    title: CASH_PAGE_TITLE.DETAILS,
    description: '入出金・振替履歴を表示',
    pageUrl: SSO_PAYMENT_DETAIL_REDIRECT_URL,
    pageId: CASH_PAGES.CASH_DETAILS.ID,
    pageName: CASH_PAGES.CASH_DETAILS.NAME,
    useUrl: true,
    key: 5,
  },
];

const DEPOSIT_TARGET_MAP = Object.freeze({
  [FX]: Object.freeze({
    id: 1,
    serviceId: FX,
    alt: 'トライオートFX',
  }),
  [ETF]: Object.freeze({
    id: 2,
    serviceId: ETF,
    alt: 'トライオートETF',
  }),
  // TODO CFD 暫定
  [CFD]: Object.freeze({
    id: 3,
    serviceId: CFD,
    alt: 'トライオートCFD',
  }),
});

export const DEPOSIT_TARGET_LIST = Object.freeze(ALL_SERVICES.map((serviceId) => DEPOSIT_TARGET_MAP[serviceId]));

export const BANK_ACCOUNT_TYPE = {
  1: '普通',
};

export const KEY_FOR_DEPOSIT_SOURCE_ID = `depositSourceId`;
export const KEY_FOR_DEPOSIT_TARGET_ID = `depositTargetId`;
export const KEY_FOR_PAYMENT_AMOUNT = `paymentAmount`;
export const KEY_FOR_BANK_LIST = `bankList`;

export const CASH_CONSIDERATION_TITLE = `振込入金のご利用に関する注意事項`;

export const WEB_CASH_CONSIDERATION_1 = `左記、振込専用口座にお振込みください。`;
export const MOBILE_CASH_CONSIDERATION_1 = `上記、振込専用口座にお振込ください。`;

export const CASH_CONSIDERATION_2_1 = `当社にて入金確認後、お取引ツールへの反映を行います。`;
export const CASH_CONSIDERATION_2_2 = `（原則5〜65分でお客様のお取引口座へ反映します。ただし、着金までの所要時間は銀行によって異なります。）`;

export const CASH_CONSIDERATION_3 = `お取引ツールにて入金額をご確認ください。`;

export const CASH_CONSIDERATION_4 = `お取引を開始いただけます。`;

export const CASH_DEPOSIT_CONSIDERATION_TITLE = `【入金時の注意事項】`;

export const CASH_DEPOSIT_CONSIDERATION_1 = `左記の口座へ振り込まれると、振込入金先に入金されます。`;
export const CASH_DEPOSIT_CONSIDERATION_1_MOBILE = `上記の口座へ振り込まれると、振込入金先に入金されます。`;

export const CASH_DEPOSIT_CONSIDERATION_2 = `振込手数料はお客様のご負担となります。ただし、即時入金サービス利用時の振込手数料は無料です。`;

// eslint-disable-next-line
export const CASH_DEPOSIT_CONSIDERATION_3_1 = `原則24時間ご入金可能(メンテナンス、障害時を除く)ですが、平日15時以降のご入金で、名義相違等によりお取引口座への反映が不可能な際は、翌営業日の扱いとなる等お時間を頂戴いたします。`;

export const CASH_DEPOSIT_CONSIDERATION_4 = `振込人名義が当社の登録名義と完全に一致しない場合、ご入金をお受けできません。`;

export const CASH_DEPOSIT_CONSIDERATION_5 = `振込人名義がご家族でもお受けできません。`;

// eslint-disable-next-line
export const CASH_DEPOSIT_CONSIDERATION_6_1 = `ご本人名義でも、名義(カナ)が一部異なっている、MyページIDや会社名等、登録名義以外の情報が入っている等、登録名義と一致しない場合はお受けできません。`;

export const CASH_DEPOSIT_CONSIDERATION_7 = `振込名義相違等が発生した際は、お客様ご自身で組戻しの手続きを行っていただきます。
(銀行窓口で行う必要がございます。)`;

export const CASH_DEPOSIT_CONSIDERATION_8 = `振込人名義の相違等の入金手続きの間違いや、銀行の事務処理等の遅れにより発生するリスクは、すべてお客様が負うものといたします。ご了承ください。`;

export const CASH_QUICK_DEPOSIT_INFO_LINK = 'https://www.invast.jp/triauto/service/summary/deposit.html';
export const CASH_QUICK_DEPOSIT_RULE_PDF_LINK = 'https://www.invast.jp/account/pdf/rule_01.pdf';
