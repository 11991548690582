import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './statusIcon.module.scss';

export const StatusIcon = memo(({ className, active }) => {
  if (active) {
    return <div className={classNames(styles.container, styles.active, className)}>稼働中</div>;
  }
  return <div className={classNames(styles.container, styles.inactive, className)}>稼働停止</div>;
});

StatusIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
};

StatusIcon.defaultProps = {
  active: false,
  className: undefined,
};
