import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef } from 'react';

import { ReactComponent as ChevronLeft } from '../../../assets/chevronLeft.svg';
import { ReactComponent as ChevronRight } from '../../../assets/chevronRight.svg';
import styles from '../media.module.scss';
import filmStripStyles from './filmStripItems.module.scss';

const HorizontalScroll = memo(({ children, topOffset, contentClassName }) => {
  const scrollRef = useRef();
  const leftButtonRef = useRef();
  const rightButtonRef = useRef();

  const updateScrollButtons = () => {
    if (!scrollRef.current) return;

    if (leftButtonRef.current)
      leftButtonRef.current.style.display = scrollRef.current.scrollLeft > 0 ? 'block' : 'none';

    if (rightButtonRef.current)
      rightButtonRef.current.style.display =
        scrollRef.current.clientWidth + scrollRef.current.scrollLeft < scrollRef.current.scrollWidth ? 'block' : 'none';
  };

  useEffect(() => {
    const unsubscribeScroll = () => {
      window.removeEventListener('resize', updateScrollButtons);
    };

    window.addEventListener('resize', updateScrollButtons);

    updateScrollButtons();

    if (!scrollRef.current || !rightButtonRef.current || !leftButtonRef.current) return unsubscribeScroll;

    if (scrollRef.current.clientWidth >= scrollRef.current.scrollWidth) {
      leftButtonRef.current.style.display = 'none';
      rightButtonRef.current.style.display = 'none';
      return unsubscribeScroll;
    }

    leftButtonRef.current.style.display = scrollRef.current.scrollLeft > 0 ? 'block' : 'none';
    rightButtonRef.current.style.display =
      scrollRef.current.clientWidth + scrollRef.current.scrollLeft < scrollRef.current.scrollWidth ? 'block' : 'none';

    return unsubscribeScroll;
  }, []);

  const scrollToLeft = () => {
    let start;
    let previousTimeStamp;
    let done = false;
    const targetDistance = scrollRef.current.clientWidth * 0.6;
    const initScrollLeft = scrollRef.current.scrollLeft;

    function leftScroll(timestamp) {
      if (start === undefined) {
        start = timestamp;
      }

      if (previousTimeStamp !== timestamp) {
        scrollRef.current.scrollLeft -= 20;
        if (scrollRef.current.scrollLeft === 0 || initScrollLeft - scrollRef.current.scrollLeft >= targetDistance)
          done = true;
      }

      if (timestamp - start < 600) {
        previousTimeStamp = timestamp;
        if (done) updateScrollButtons();
        else window.requestAnimationFrame(leftScroll);
      } else updateScrollButtons();
    }

    window.requestAnimationFrame(leftScroll);
  };

  const scrollToRight = () => {
    let start;
    let previousTimeStamp;
    let done = false;
    const targetDistance = scrollRef.current.clientWidth * 0.6;
    const initScrollLeft = scrollRef.current.scrollLeft;

    function rightScroll(timestamp) {
      if (start === undefined) {
        start = timestamp;
      }

      if (previousTimeStamp !== timestamp) {
        scrollRef.current.scrollLeft += 20;
        if (
          scrollRef.current.scrollLeft === scrollRef.current.scrollWidth ||
          scrollRef.current.scrollLeft - initScrollLeft >= targetDistance
        )
          done = true;
      }

      if (timestamp - start < 600) {
        previousTimeStamp = timestamp;
        if (done) updateScrollButtons();
        else window.requestAnimationFrame(rightScroll);
      } else updateScrollButtons();
    }

    window.requestAnimationFrame(rightScroll);
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('wheel', updateScrollButtons, { passive: true });
    return () => {
      container?.removeEventListener('wheel', updateScrollButtons, { passive: true });
    };
  }, []);

  return (
    <div style={{ marginLeft: 10 }}>
      <div className={filmStripStyles.container}>
        <div className={filmStripStyles.filmStripItems} ref={scrollRef}>
          <div className={filmStripStyles.offsetZone}>
            <div
              style={{
                position: 'relative',
                width: 0,
              }}
            >
              <span style={{ width: 5, left: 0, height: '100%', position: 'absolute' }} />
            </div>
          </div>
          <div className={styles.scrollBtnWrapper} role="button" ref={leftButtonRef}>
            <div
              aria-hidden="true"
              className={filmStripStyles.scrollButtonCircleLeft}
              onClick={scrollToLeft}
              style={topOffset ? { top: topOffset } : {}}
            >
              <ChevronLeft />
            </div>
          </div>
          <div className={contentClassName} ref={containerRef}>
            {children}
          </div>
          <div className={filmStripStyles.scrollBtnWrapper} ref={rightButtonRef}>
            <div
              aria-hidden="true"
              className={filmStripStyles.scrollButtonCircleRight}
              onClick={scrollToRight}
              style={topOffset ? { top: topOffset } : {}}
            >
              <ChevronRight />
            </div>
          </div>
          <div className={filmStripStyles.offsetZone}>
            <div
              style={{
                position: 'relative',
                width: 0,
              }}
            >
              <span style={{ width: 5, right: 0, height: '100%', position: 'absolute' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

HorizontalScroll.propTypes = {
  children: PropTypes.node.isRequired,
  topOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentClassName: PropTypes.string,
};

HorizontalScroll.defaultProps = {
  topOffset: undefined,
  contentClassName: undefined,
};

export default HorizontalScroll;
