import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { cashChangeActivePage } from '../redux/actions/cashActions';

function callOnEnter(event, callback) {
  event.preventDefault();
  if (event.key === 'Enter') callback(event);
}

const callOnEnterOrSpace = (event, callback) => {
  const { key } = event;
  if (key === 'Enter' || key === ' ') {
    event.preventDefault();
    callback(event);
  }
};

export const useKeyPressHandler = (callback) => useCallback((event) => callOnEnter(event, callback), [callback]);

export const useKeyDownHandler = (callback) => useCallback((event) => callOnEnterOrSpace(event, callback), [callback]);

export const useOptionalKeyPressHandler = (callback) =>
  useMemo(() => (callback ? (event) => callOnEnter(event, callback) : null), [callback]);

export const useArrowKeyNavigation = (elementsLength, defaultIndex = 0) => {
  const [currentFocus, setCurrentFocus] = useState(defaultIndex);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 40) {
        // Down arrow
        e.preventDefault();
        setCurrentFocus(currentFocus === elementsLength - 1 ? 0 : currentFocus + 1);
      } else if (e.keyCode === 38) {
        // Up arrow
        e.preventDefault();
        setCurrentFocus(currentFocus === 0 ? elementsLength - 1 : currentFocus - 1);
      }
    },
    [elementsLength, currentFocus, setCurrentFocus],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return [currentFocus, setCurrentFocus];
};

export function useEffectSetChildTabIndex(tabIndex) {
  const parentRef = useRef(null);

  useEffect(() => {
    const child = parentRef.current?.firstChild;
    if (child) child.setAttribute('tabindex', tabIndex);
  });

  return parentRef;
}

export const useCashPageTransition = () => {
  const dispatch = useDispatch();
  return useMemo(() => (activePage) => dispatch(cashChangeActivePage({ activePage })), [dispatch]);
};
