import { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';

export const Pane = memo(
  forwardRef(({ className, split, size, children }, ref) => {
    const style = useMemo(() => {
      const innerStyle = {
        flex: 1,
        position: 'relative',
        outline: 'none',
      };
      if (size != null) {
        innerStyle.flex = 'none';
        if (split === 'vertical') {
          innerStyle.width = size;
        } else {
          innerStyle.display = 'flex';
          innerStyle.height = size;
        }
      }
      return innerStyle;
    }, [split, size]);

    return (
      <div ref={ref} style={style} className={className}>
        {children}
      </div>
    );
  }),
);

Pane.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  split: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
  children: PropTypes.node.isRequired,
};

Pane.defaultProps = {
  className: undefined,
  size: undefined,
};
