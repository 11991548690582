import { memo, useMemo, useCallback } from 'react';
import { batch, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { AP_GROUP_ORDER, TRADE_TABLES } from 'shared-modules/constants';
import { SORT_DESCENDING } from 'shared-modules/constants/manualTrade';
import {
  DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY,
  DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR,
  DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_BY,
  DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_DIR,
} from 'shared-modules/constants/portfolio';
import { changeBeforePortfolioDataSave } from 'shared-modules/redux/multiEdit';
import {
  changeSelectedExecutionsMetaInfo,
  getExecutionsInfoRequest,
  resetSelectedApGroupMetaInfo,
  resetSelectedExecutionsMetaInfo,
  resetSelectedPositionsMetaInfo,
} from 'shared-modules/redux/actions/portfolioActions';
import {
  closePortfolioAutoTradeDetailModal,
  openMultiEdit,
  openErrorInfoModal,
} from '../../../../../../../../../redux/actions';
import { Button, LinkButton } from '../../../../../../../../../components';
import { useTableRef } from '../../../../../../../../../hooks';
import styles from './tableWrapper.module.scss';
import CustomDatePicker from '../../../../../../../../../components/CustomDatePicker';
import { DATEPICKER_WIDTH } from '../../../../../../../../../screens/ManualTrade/constants';

export const TableWrapper = memo(({ serviceId, children }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.webPortfolio.selectedTable);
  const selectedApGroupData = useSelector((state) => state.portfolio.selectedApGroupData);
  const { status, id: selectedApGroupId } = selectedApGroupData;
  const isActive = status === AP_GROUP_ORDER.ACTIVITY.ACTIVE.ID;
  const deleteApGroupItemIsLoading = useSelector((state) => state.portfolio.deletingApGroupItemIsLoading);
  const isLoading = useMemo(() => {
    const statusNotExists = status === undefined;
    return deleteApGroupItemIsLoading || statusNotExists;
  }, [deleteApGroupItemIsLoading, status]);
  const { data: portfolioAutoTradeCardData } = useSelector((state) => state.modals.portfolioAutoTradeDetail);

  const tableRef = useTableRef();

  const multiEditButtonClick = useCallback(() => {
    if (isActive) {
      batch(() => {
        dispatch(changeBeforePortfolioDataSave({ cardData: portfolioAutoTradeCardData, selectedApGroupData }));
        dispatch(closePortfolioAutoTradeDetailModal());
        dispatch(openMultiEdit());
      });
    } else {
      dispatch(
        openErrorInfoModal({
          title: '注文設定変更',
          message: '全ての自動売買が停止されている状態の自動売買グループの注文設定変更は行えません。',
        }),
      );
    }
  }, [dispatch, portfolioAutoTradeCardData, selectedApGroupData, isActive]);

  const filterClear = useCallback(() => {
    switch (activeTab) {
      case TRADE_TABLES.EXECUTIONS.ID:
        batch(() => {
          dispatch(resetSelectedExecutionsMetaInfo({ serviceId }));
          dispatch(getExecutionsInfoRequest({ apGroupId: selectedApGroupId, isFirstData: true, serviceId }));
          tableRef.current?.setSortBy([
            {
              id: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY,
              desc: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR === SORT_DESCENDING,
            },
          ]);
        });
        break;
      case TRADE_TABLES.ORDERS.ID:
        dispatch(resetSelectedApGroupMetaInfo({ serviceId }));
        break;
      case TRADE_TABLES.POSITIONS.ID:
        batch(() => {
          dispatch(resetSelectedPositionsMetaInfo({ serviceId }));
          tableRef.current?.setSortBy([
            {
              id: DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_BY,
              desc: DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_DIR === SORT_DESCENDING,
            },
          ]);
        });
        break;
      default:
        break;
    }
  }, [dispatch, serviceId, selectedApGroupId, activeTab, tableRef]);

  const { fromDate: fromDateInfo, toDate: toDateInfo } = useSelector(
    (state) => state.portfolio.selectedApGroupExecutionsMetaInfo[serviceId],
  );
  const filterOptions = useMemo(() => {
    return {
      fromDateInfo,
      toDateInfo,
      maxDate: new Date(),
    };
  }, [fromDateInfo, toDateInfo]);

  const filterHandler = useCallback(
    ({ value }) => {
      batch(() => {
        dispatch(changeSelectedExecutionsMetaInfo({ key: 'fromDate', value: value?.fromDate, serviceId }));
        dispatch(changeSelectedExecutionsMetaInfo({ key: 'toDate', value: value?.toDate, serviceId }));
      });
      dispatch(getExecutionsInfoRequest({ apGroupId: selectedApGroupId, isFirstData: true, serviceId }));
    },
    [dispatch, selectedApGroupId, serviceId],
  );

  const filterDatePickerHandler = useCallback(
    ({ fromDate, toDate }) => {
      if (fromDate) {
        if (fromDate.getTime() > toDateInfo.getTime()) {
          filterHandler({ value: { fromDate, toDate: fromDate } });
        } else {
          filterHandler({ value: { fromDate, toDate: toDateInfo } });
        }
      }
      if (toDate) {
        if (toDate.getTime() >= fromDateInfo.getTime()) {
          filterHandler({ value: { fromDate: fromDateInfo, toDate } });
        } else {
          filterHandler({ value: { fromDate: toDate, toDate } });
        }
      }
    },
    [filterHandler, fromDateInfo, toDateInfo],
  );

  const datePicker = useMemo(() => {
    const { maxDate } = filterOptions || {};
    return (
      <div className={styles.datePicker}>
        <CustomDatePicker
          date={fromDateInfo}
          maxDate={maxDate}
          onChange={(fromDate) => filterDatePickerHandler({ fromDate })}
          isLighter
          width={DATEPICKER_WIDTH}
          isThin
        />
        <div className={styles.dateTickerText}>〜</div>
        <CustomDatePicker
          date={toDateInfo}
          maxDate={maxDate}
          onChange={(toDate) => filterDatePickerHandler({ toDate })}
          isLighter
          width={DATEPICKER_WIDTH}
          isThin
        />
      </div>
    );
  }, [filterDatePickerHandler, filterOptions, fromDateInfo, toDateInfo]);

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        {activeTab === TRADE_TABLES.EXECUTIONS.ID && <div>{datePicker}</div>}
        <LinkButton className={styles.linkButton} onClick={filterClear}>
          フィルターをすべてクリア
        </LinkButton>
        {activeTab === TRADE_TABLES.ORDERS.ID && (
          <Button onClick={multiEditButtonClick} loading={isLoading} className={styles.button} secondary>
            一括変更
          </Button>
        )}
      </div>
      <div style={{ width: '100%', height: '100%' }}>{children}</div>
    </div>
  );
});

TableWrapper.propTypes = {
  serviceId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
