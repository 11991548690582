/* eslint-disable-next-line import/no-unresolved */
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector, useDispatch } from 'react-redux';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import {
  addFromHistoryOrSavedToCartRequest,
  deleteCurrentCartItemRequest,
  deleteCurrentCartRequest,
  deleteSavedCartItemRequest,
  getCurrentCartRequest,
  getHistoryCartRequest,
  getSavedCartRequest,
  saveCurrentCartRequest,
  updateCurrentCartStrategySetsLocally,
  updateCurrentCartStrategySetsRequest,
} from '../../redux/actions/cartActions';
import { CART_TABS, MIN_STRATEGY_SETS, TABLE_DEBOUNCE_VALUE } from '../../constants/cart';
import { CART_DEFAULT_SETS, STRATEGY_SETS_RESULTS } from '../../constants';
import { useCalculatingChartData, useGetInstrumentDisplayName } from '../../hooks';
import { calculateMaxItemForCart } from '../index';
import { useCurrentData } from '../../hooks/cart';
import { compareServiceId } from '../../utils';
import { transformCurrentItems } from '../../utils/cart';

export const useCurrentCart = (activeTab) => {
  const dispatch = useDispatch();
  const isTabCurrentCart = useMemo(() => activeTab === CART_TABS.CURRENT.ID, [activeTab]);

  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const currentData = useSelector((state) => state.cart.currentData[defaultSelectionTermId]);

  const currentCartData = useMemo(() => transformCurrentItems(currentData), [currentData]);

  const isLoadingSaveCurrentCart = useSelector((state) => state.cart.currentDataSaveIsLoading);
  const isLoadingDeleteCurrentCart = useSelector((state) => state.cart.currentDataDeleteIsLoading);

  const saveCurrentCart = useCallback(() => {
    dispatch(saveCurrentCartRequest({}));
  }, [dispatch]);

  const deleteCurrentCart = useCallback(() => {
    dispatch(deleteCurrentCartRequest());
  }, [dispatch]);

  return {
    isTabCurrentCart,
    currentCartData,
    isLoadingSaveCurrentCart,
    isLoadingDeleteCurrentCart,
    saveCurrentCart,
    deleteCurrentCart,
  };
};

export const useChangeCartType = () => {
  const [activeTab, changeActiveTab] = useState(CART_TABS.CURRENT.ID);

  const [errorMessages, changeErrorMessages] = useState([]);
  useEffect(() => {
    if (activeTab === CART_TABS.CURRENT.ID) {
      changeErrorMessages([]);
    }
  }, [activeTab]);

  return {
    activeTab,
    changeActiveTab,
    errorMessages,
    changeErrorMessages,
  };
};

const loadDataFunctions = {
  [CART_TABS.CURRENT.ID]: getCurrentCartRequest,
  [CART_TABS.HISTORY.ID]: getHistoryCartRequest,
  [CART_TABS.SAVED.ID]: getSavedCartRequest,
};

export const useLoadCartData = (activeTab) => {
  const dispatch = useDispatch();

  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);

  useEffect(() => {
    if (activeTab === CART_TABS.CURRENT.ID) {
      dispatch(loadDataFunctions[activeTab]({ termId: defaultSelectionTermId }));
    } else {
      dispatch(loadDataFunctions[activeTab]());
    }
  }, [activeTab, dispatch, defaultSelectionTermId]);

  const currentData = useSelector((state) => state.cart.currentData[defaultSelectionTermId] ?? []);
  const historyData = useSelector((state) => state.cart.historyData);
  const savedData = useSelector((state) => state.cart.savedData);

  const currentDataIsLoading = useSelector((state) => state.cart.currentDataIsLoading);
  const historyDataIsLoading = useSelector((state) => state.cart.historyDataIsLoading);
  const savedDataIsLoading = useSelector((state) => state.cart.savedDataIsLoading);

  const isLoading = useMemo(() => {
    const loadingData = {
      [CART_TABS.CURRENT.ID]: currentDataIsLoading,
      [CART_TABS.HISTORY.ID]: historyDataIsLoading,
      [CART_TABS.SAVED.ID]: savedDataIsLoading,
    };
    if (loadingData[activeTab]) return loadingData[activeTab];
    return false;
  }, [activeTab, currentDataIsLoading, historyDataIsLoading, savedDataIsLoading]);

  return {
    currentData,
    historyData,
    savedData,
    isLoading,
  };
};

const withoutInputError = (errors, inputNameRef) => errors.filter((error) => error.inputName !== inputNameRef.current);

export const useCurrentCartLogic = ({ item, resetErrorFunction }) => {
  const {
    strategyDetail: { instrumentId, name, serviceId },
    cartItems,
  } = item;

  const dispatch = useDispatch();

  const [count, changeCount] = useState(item.strategySets);
  const prevCountRef = useRef(count);
  useEffect(() => {
    if (prevCountRef.current === count) {
      return;
    }
    prevCountRef.current = count;

    if (item.strategySets === count) {
      return;
    }
    cartItems.forEach((cartItem) => {
      dispatch(
        updateCurrentCartStrategySetsLocally({
          value: Number(count),
          itemId: cartItem.itemId,
        }),
      );
    });
  }, [dispatch, item, count, cartItems]);

  const inputNameRefId = useRef(uuid());

  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const deleteItemOptions = useSelector((state) => state.cart.currentDataDeleteItem);
  const isLoadingDelete = useMemo(() => {
    const idSet = new Set((cartItems ?? []).map((cartItem) => cartItem.itemId));
    if (idSet.has(deleteItemOptions.itemId)) {
      return deleteItemOptions.isLoading;
    }
    return false;
  }, [cartItems, deleteItemOptions]);

  const calculatedMaxStrategySets = useMemo(() => {
    return calculateMaxItemForCart({ strategyList: cartItems, instrumentList, useDefaultSets: true });
  }, [instrumentList, cartItems]);

  const validateInput = useCallback(
    (value) => {
      if (MIN_STRATEGY_SETS <= value && value <= calculatedMaxStrategySets) {
        resetErrorFunction((prevVal) => withoutInputError(prevVal, inputNameRefId));
        return true;
      }

      resetErrorFunction((prevVal) => [
        ...withoutInputError(prevVal, inputNameRefId),
        {
          inputName: inputNameRefId.current,
          errorMessage: `セット数は1以上${calculatedMaxStrategySets}までご設定ください。`,
        },
      ]);
      return false;
    },
    [resetErrorFunction, calculatedMaxStrategySets],
  );

  const handleDeleteItem = useCallback(() => {
    cartItems.forEach((cartItem) => {
      dispatch(deleteCurrentCartItemRequest({ itemId: cartItem.itemId }));
    });
    resetErrorFunction((prevVal) => withoutInputError(prevVal, inputNameRefId));
  }, [dispatch, resetErrorFunction, cartItems]);

  const itemNameRefId = useRef(uuid());

  const handleUpdateStrategySetsRequestDebounce = useDebouncedCallback((e) => {
    cartItems.forEach((cartItem) => {
      dispatch(updateCurrentCartStrategySetsRequest({ value: Number(e), itemId: cartItem.itemId }));
    });
  }, 500);

  const handleUpdateStrategySetsRequest = useCallback(
    (e) => {
      cartItems.forEach((cartItem) => {
        dispatch(updateCurrentCartStrategySetsRequest({ value: Number(e), itemId: cartItem.itemId }));
      });
    },
    [dispatch, cartItems],
  );

  const serviceIds = useMemo(() => {
    if (serviceId) {
      return [serviceId];
    }
    const serviceIdSet = new Set(cartItems.map(({ strategyDetail }) => strategyDetail?.serviceId));
    const ids = [...serviceIdSet];
    ids.sort(compareServiceId);
    return ids;
  }, [serviceId, cartItems]);

  const handleSaveItem = useCallback(() => {
    dispatch(saveCurrentCartRequest({ strategyIds: cartItems.map(({ strategyId }) => strategyId) }));
  }, [dispatch, cartItems]);

  return {
    instrumentId,
    name,
    serviceIds,
    count,
    changeCount,
    validateInput,
    isLoadingDelete,
    handleDeleteItem,
    handleUpdateStrategySetsRequest,
    handleUpdateStrategySetsRequestDebounce,
    itemNameRefId,
    inputNameRefId,
    calculatedMaxStrategySets,
    handleSaveItem,
  };
};

export const useCurrentCartConfirmation = (errorMessages) => {
  const termId = useSelector((state) => state.cart.selectedTermId);
  const strategyList = useCurrentData(termId);

  const isDisabled = useMemo(
    () => strategyList.length === 0 || Boolean(errorMessages.length),
    [strategyList, errorMessages.length],
  );
  const isLoading = useSelector((state) => state.cart.currentDataIsLoading);

  const {
    chartStats: { marginRequired },
  } = useCalculatingChartData({ strategyList, defaultSets: CART_DEFAULT_SETS });

  return {
    data: strategyList,
    isDisabled,
    isLoading,
    marginRequired,
  };
};

export const useCartTableData = () => {
  const defaultSelectedTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const currentData = useSelector((state) => state.cart.currentData[defaultSelectedTermId] || []);
  const currentDataIsLoading = useSelector((state) => state.cart.currentDataIsLoading);

  const getInstrumentDisplayName = useGetInstrumentDisplayName();

  const [currentDataDebounced] = useDebounce(currentData, TABLE_DEBOUNCE_VALUE);

  const settingsTableData = useMemo(() => {
    if (currentDataIsLoading || !currentDataDebounced.length) {
      return [];
    }

    return currentDataDebounced.reduce(
      (totalData, { itemId, strategySets, strategyDetail: { itemList, serviceId } }) => [
        ...totalData,
        ..._.sortBy(
          itemList.map((item) => ({
            ...item,
            strategySets,
            instrumentDisplayName: getInstrumentDisplayName(item.instrumentId, serviceId),
            serviceId,
            cartItemId: itemId,
          })),
          ['name'],
        ),
      ],
      [],
    );
  }, [currentDataDebounced, currentDataIsLoading, getInstrumentDisplayName]);

  return {
    currentDataIsLoading,
    settingsTableData,
  };
};

export const useStrategiesSuccess = (isOpen) => {
  const strategyResults = useSelector((state) => state.modals.strategiesSuccess.resultArray);

  const [innerApStrategyResults, changeInnerApStrategyResult] = useState([]);
  const [innerTechStrategyResults, changeInnerTechStrategyResult] = useState([]);
  const [innerStrategyResults, changeInnerStrategyResult] = useState([]);
  useEffect(() => {
    if (isOpen) {
      changeInnerApStrategyResult(strategyResults.filter((s) => s.technicalBuilderId == null));
      changeInnerTechStrategyResult(strategyResults.filter((s) => s.technicalBuilderId != null));
      changeInnerStrategyResult(strategyResults);
    }
  }, [strategyResults, isOpen]);

  const isTech = innerTechStrategyResults.length > 0;
  const isNoTech = innerApStrategyResults.length > 0;
  const isFailed = innerStrategyResults.some((r) => r.status === STRATEGY_SETS_RESULTS.FAIL);

  const getTitleInfo = (tech, ap, fail) => {
    if (tech && ap) {
      if (!fail) {
        return {
          title: '自動売買・テクニカルロジックが稼働しました。',
          info: '自動売買注文の状況、テクニカルロジックの設定条件はホーム画面のポートフォリオ内にて確認できます。',
          strategyResults: innerStrategyResults,
        };
      }
      return {
        title: '稼働できなかった自動売買・テクニカルロジックがあります。',
        info: '稼働した注文についてはホーム画面のポートフォリオ内にて確認できます。\n失敗した注文・テクニカルロジックについては証拠金状況や注文状況等を今一度ご確認ください。',
        strategyResults: innerStrategyResults,
      };
    }
    if (tech) {
      if (!fail) {
        return {
          title: 'テクニカルロジックが稼働しました。',
          info: 'テクニカルロジックの設定条件はホーム画面のポートフォリオ内にて確認できます。',
          strategyResults: innerTechStrategyResults,
        };
      }
      return {
        title: 'テクニカルロジックの稼働に失敗しました。',
        info: '証拠金状況や注文状況等を今一度ご確認ください。',
        strategyResults: innerTechStrategyResults,
      };
    }
    if (!fail) {
      return {
        title: '自動売買が稼働しました。',
        info: '注文の状況はホーム画面のポートフォリオ内にて確認できます。',
        strategyResults: innerApStrategyResults,
      };
    }
    return {
      title: '稼働できなかった自動売買があります。',
      info: '稼働した注文についてはホーム画面のポートフォリオ内にて確認できます。\n失敗した注文については証拠金状況や注文状況等を今一度ご確認ください。',
      strategyResults: innerApStrategyResults,
    };
  };

  const titleInfoObj = getTitleInfo(isTech, isNoTech, isFailed);

  return useMemo(
    () => ({
      title: titleInfoObj.title,
      info: titleInfoObj.info,
      innerStrategyResults: titleInfoObj.strategyResults,
    }),
    [titleInfoObj.title, titleInfoObj.info, titleInfoObj.strategyResults],
  );
};

export const useCartHistory = (logId, strategyIds) => {
  const [isLoading, changeIsLoading] = useState(false);

  const dispatch = useDispatch();
  const handleAddToCurrentCart = useCallback(() => {
    changeIsLoading(true);
    dispatch(addFromHistoryOrSavedToCartRequest({ logId, strategyIds, callback: () => changeIsLoading(false) }));
  }, [dispatch, logId, strategyIds]);

  return useMemo(
    () => ({
      isLoading,
      handleAddToCurrentCart,
    }),
    [isLoading, handleAddToCurrentCart],
  );
};

export const useSavedCart = (logId, strategyIds) => {
  const dispatch = useDispatch();

  const [isLoadingAddToCart, changeIsLoadingAddToCart] = useState(false);
  const handleAddToCurrentCart = useCallback(() => {
    changeIsLoadingAddToCart(true);
    dispatch(
      addFromHistoryOrSavedToCartRequest({ logId, strategyIds, callback: () => changeIsLoadingAddToCart(false) }),
    );
  }, [dispatch, logId, strategyIds]);

  const [isLoadingDeleteItem, changeIsLoadingDeleteItem] = useState(false);
  const handleDeleteItem = useCallback(() => {
    changeIsLoadingDeleteItem(true);
    dispatch(deleteSavedCartItemRequest({ logId, callback: () => changeIsLoadingDeleteItem(false) }));
  }, [dispatch, logId]);

  return useMemo(
    () => ({
      isLoadingAddToCart,
      isLoadingDeleteItem,
      handleDeleteItem,
      handleAddToCurrentCart,
    }),
    [isLoadingAddToCart, isLoadingDeleteItem, handleDeleteItem, handleAddToCurrentCart],
  );
};
