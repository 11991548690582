import pick from 'lodash/pick';
import {
  CHANGE_AP_GROUP_ITEM_REQUEST,
  CHANGE_AP_GROUP_ITEM_REQUEST_END_LOADING,
  CHANGE_AP_GROUP_ITEM_REQUEST_START_LOADING,
  CHANGE_AP_GROUP_ITEM_STATUS,
  CHANGE_SELECTED_EXECUTIONS_META_INFO,
  CLEAR_AP_GROUP_DATA,
  CLEAR_MANUAL_POSITIONS_DATA,
  CLEAR_SELECTED_AP_GROUP_DATA,
  DELETE_AP_GROUP_ITEM_REQUEST,
  DELETE_AP_GROUP_ITEM_REQUEST_END_LOADING,
  DELETE_AP_GROUP_ITEM_REQUEST_START_LOADING,
  DELETE_AP_GROUP_REQUEST,
  DELETE_AP_GROUP_REQUEST_END_LOADING,
  DELETE_AP_GROUP_REQUEST_START_LOADING,
  GET_AP_GROUP_BY_ID_REQUEST,
  GET_AP_GROUP_BY_ID_REQUEST_END_LOADING,
  GET_AP_GROUP_BY_ID_REQUEST_START_LOADING,
  GET_AP_GROUP_BY_ID_SUCCESS,
  CLEAR_AP_GROUP_BY_SERVICE,
  GET_AP_GROUP_BY_ID_MULTI_REQUEST,
  GET_AP_GROUPS_REQUEST,
  GET_AP_GROUPS_REQUEST_END_LOADING,
  GET_AP_GROUPS_REQUEST_START_LOADING,
  GET_AP_GROUPS_SUCCESS,
  REMOVE_AP_GROUP,
  GET_EXECUTIONS_INFO_REQUEST,
  GET_EXECUTIONS_INFO_REQUEST_END_LOADING,
  GET_EXECUTIONS_INFO_REQUEST_START_LOADING,
  GET_EXECUTIONS_INFO_SUCCESS,
  SET_EXECUTIONS_SUMMARY_INFO_FOR_TUTORIAL,
  GET_MARGIN_PERIODICALLY,
  GET_MARGIN_REQUEST,
  GET_MARGIN_REQUEST_IMMEDIATELY,
  GET_MARGIN_REQUEST_END_LOADING,
  GET_MARGIN_REQUEST_START_LOADING,
  GET_MARGIN_SUCCESS,
  GET_PL_EXECUTIONS_INFO_REQUEST,
  GET_PL_EXECUTIONS_INFO_REQUEST_END_LOADING,
  GET_PL_EXECUTIONS_INFO_REQUEST_START_LOADING,
  GET_PL_EXECUTIONS_INFO_SUCCESS,
  GET_POSITIONS_INFO_REQUEST,
  GET_POSITIONS_INFO_REQUEST_END_LOADING,
  GET_POSITIONS_INFO_REQUEST_START_LOADING,
  GET_POSITIONS_INFO_SUCCESS,
  SET_LIST_SETTINGS,
  UPDATE_AP_GROUP_REQUEST,
  UPDATE_AP_GROUP_REQUEST_END_LOADING,
  UPDATE_AP_GROUP_REQUEST_START_LOADING,
  UPDATE_MARGIN_BY_SOCKET,
  RESET_MARGIN_DATA,
  TOGGLE_CHANGE_AP_GROUP_ABILITY,
  DELETE_ALL_MANUAL_TRADE_POSITIONS_REQUEST,
  RESET_SELECTED_EXECUTIONS_META_INFO,
  RESET_SELECTED_EXECUTIONS_META_INFO_DATE,
  RESET_SELECTED_EXECUTIONS_META_INFO_PARTIAL,
  CHANGE_SELECTED_AP_GROUP_META_INFO,
  RESET_SELECTED_AP_GROUP_META_INFO,
  CHANGE_SELECTED_POSITIONS_META_INFO,
  RESET_SELECTED_POSITIONS_META_INFO,
  CHANGE_CARD_DATA,
  RESET_CARD_DATA,
  CHANGE_SELECTED_AP_GROUP_STATUS,
  GET_AP_GROUP_BY_ID_SUCCESS_DO_NOT_UPDATE_SELECTED_DATA,
  GET_PORTFOLIO_DATA_REQUEST,
  GET_PORTFOLIO_DATA_REQUEST_START_LOADING,
  GET_PORTFOLIO_DATA_REQUEST_END_LOADING,
} from '../actionConstants/portfolioConstants';

export const getMarginPeriodically = () => ({
  type: GET_MARGIN_PERIODICALLY,
});

export const getMarginRequestImmediately = ({ serviceId }) => ({
  type: GET_MARGIN_REQUEST_IMMEDIATELY,
  payload: { serviceId },
});

export const getMarginRequest = ({ serviceId }) => ({
  type: GET_MARGIN_REQUEST,
  payload: { serviceId },
});
export const getMarginSuccess = ({ marginData, serviceId }) => ({
  type: GET_MARGIN_SUCCESS,
  payload: { marginData, serviceId },
});

export const getMarginRequestStartLoading = () => ({ type: GET_MARGIN_REQUEST_START_LOADING });
export const getMarginRequestEndLoading = () => ({ type: GET_MARGIN_REQUEST_END_LOADING });

export const updateMarginBySocket = ({ unrealizedProfitLoss, serviceId }) => ({
  type: UPDATE_MARGIN_BY_SOCKET,
  payload: { unrealizedProfitLoss, serviceId },
});
export const resetMarginData = ({ serviceId }) => ({
  type: RESET_MARGIN_DATA,
  payload: { serviceId },
});

export const getApGroupRequest = ({ groupId, serviceId, status } = {}) => ({
  type: GET_AP_GROUPS_REQUEST,
  payload: { groupId, serviceId, status },
});
export const getApGroupSuccess = ({ serviceId, apGroupsData, groupId }) => ({
  type: GET_AP_GROUPS_SUCCESS,
  payload: { serviceId, apGroupsData, groupId },
});

export const getApGroupRequestStartLoading = () => ({ type: GET_AP_GROUPS_REQUEST_START_LOADING });
export const getApGroupRequestEndLoading = () => ({ type: GET_AP_GROUPS_REQUEST_END_LOADING });

export const getApGroupByIdRequest = ({ id, doNotReload, serviceId, status, doNotUpdateSelectedData }) => ({
  type: GET_AP_GROUP_BY_ID_REQUEST,
  payload: { id, doNotReload, serviceId, status, doNotUpdateSelectedData },
});
export const getApGroupByIdSuccess = ({ data, serviceId }) => ({
  type: GET_AP_GROUP_BY_ID_SUCCESS,
  payload: { data, serviceId },
});
export const getApGroupByIdSuccessDoNotUpdateSelectedData = ({ data, serviceId }) => ({
  type: GET_AP_GROUP_BY_ID_SUCCESS_DO_NOT_UPDATE_SELECTED_DATA,
  payload: { data, serviceId },
});

export const clearApGroupByService = ({ serviceId }) => ({
  type: CLEAR_AP_GROUP_BY_SERVICE,
  payload: { serviceId },
});

export const getApGroupByIdRequestStartLoading = () => ({ type: GET_AP_GROUP_BY_ID_REQUEST_START_LOADING });
export const getApGroupByIdRequestEndLoading = () => ({ type: GET_AP_GROUP_BY_ID_REQUEST_END_LOADING });

export const getApGroupByIdMultiRequest = ({ apGroupIds, serviceId, callback }) => ({
  type: GET_AP_GROUP_BY_ID_MULTI_REQUEST,
  payload: { apGroupIds, serviceId, callback },
});

export const updateApGroupRequest = ({ groupId, apGroupName, status, serviceId, allStop = false }) => ({
  type: UPDATE_AP_GROUP_REQUEST,
  payload: { groupId, apGroupName, status, serviceId, allStop },
});
export const updateApGroupRequestStartLoading = () => ({
  type: UPDATE_AP_GROUP_REQUEST_START_LOADING,
});
export const updateApGroupRequestEndLoading = () => ({
  type: UPDATE_AP_GROUP_REQUEST_END_LOADING,
});

export const deleteApGroupRequest = ({ groupId, callback, successMessage, notificationDelay, serviceId }) => ({
  type: DELETE_AP_GROUP_REQUEST,
  payload: { groupId, callback, successMessage, notificationDelay, serviceId },
});
export const deleteApGroupRequestStartLoading = () => ({
  type: DELETE_AP_GROUP_REQUEST_START_LOADING,
});
export const deleteApGroupRequestEndLoading = () => ({
  type: DELETE_AP_GROUP_REQUEST_END_LOADING,
});
export const changeApGroupItemStatus = ({ groupId, apId, status }) => ({
  type: CHANGE_AP_GROUP_ITEM_STATUS,
  payload: { groupId, apId, status },
});
export const changeApGroupItemRequest = ({
  groupId,
  apId,
  data,
  callback,
  serviceId,
  status,
  nextStatus = status,
}) => ({
  type: CHANGE_AP_GROUP_ITEM_REQUEST,
  payload: { groupId, apId, data, callback, serviceId, status, nextStatus },
});
export const changeApGroupItemRequestStartLoading = () => ({ type: CHANGE_AP_GROUP_ITEM_REQUEST_START_LOADING });
export const changeApGroupItemRequestEndLoading = () => ({ type: CHANGE_AP_GROUP_ITEM_REQUEST_END_LOADING });
export const changeSelectedApGroupStatus = ({ status }) => ({
  type: CHANGE_SELECTED_AP_GROUP_STATUS,
  payload: { status },
});

export const toggleChangeApGroupAbility = () => ({ type: TOGGLE_CHANGE_AP_GROUP_ABILITY });

export const deleteApGroupItemRequest = ({
  groupId,
  apId,
  callback,
  successMessage,
  notificationDelay,
  status,
  serviceId,
}) => ({
  type: DELETE_AP_GROUP_ITEM_REQUEST,
  payload: { groupId, apId, callback, successMessage, notificationDelay, status, serviceId },
});
export const deleteApGroupItemRequestStartLoading = () => ({ type: DELETE_AP_GROUP_ITEM_REQUEST_START_LOADING });
export const deleteApGroupItemRequestEndLoading = () => ({ type: DELETE_AP_GROUP_ITEM_REQUEST_END_LOADING });

export const clearSelectedApGroupData = () => ({ type: CLEAR_SELECTED_AP_GROUP_DATA });
export const clearApGroupData = () => ({ type: CLEAR_AP_GROUP_DATA });
export const clearManualPositionsData = () => ({ type: CLEAR_MANUAL_POSITIONS_DATA });

export const getExecutionsInfoRequest = ({ apGroupId, isFirstData, serviceId }) => ({
  type: GET_EXECUTIONS_INFO_REQUEST,
  payload: { apGroupId, isFirstData, serviceId },
});
export const getExecutionsInfoRequestStartLoading = ({ serviceId }) => ({
  type: GET_EXECUTIONS_INFO_REQUEST_START_LOADING,
  payload: { serviceId },
});
export const getExecutionsInfoRequestEndLoading = ({ serviceId }) => ({
  type: GET_EXECUTIONS_INFO_REQUEST_END_LOADING,
  payload: { serviceId },
});
export const getExecutionsInfoSuccess = ({ data, totalPages, pageNumber, isFirstData, serviceId, summary }) => ({
  type: GET_EXECUTIONS_INFO_SUCCESS,
  payload: { data, totalPages, pageNumber, isFirstData, serviceId, summary },
});
export const setExecutionsSummaryInfoForTutorial = ({ serviceId, summary }) => ({
  type: SET_EXECUTIONS_SUMMARY_INFO_FOR_TUTORIAL,
  payload: { serviceId, summary },
});

export const getPlExecutionsInfoRequest = ({ apGroupId, fromDate, toDate, serviceId }) => ({
  type: GET_PL_EXECUTIONS_INFO_REQUEST,
  payload: { apGroupId, fromDate, toDate, serviceId },
});
export const getPlExecutionsInfoRequestStartLoading = () => ({
  type: GET_PL_EXECUTIONS_INFO_REQUEST_START_LOADING,
});
export const getPlExecutionsInfoRequestEndLoading = () => ({
  type: GET_PL_EXECUTIONS_INFO_REQUEST_END_LOADING,
});
export const getPlExecutionsInfoSuccess = (plExecutionsInfo) => ({
  type: GET_PL_EXECUTIONS_INFO_SUCCESS,
  payload: plExecutionsInfo,
});

export const changeSelectedExecutionsMetaInfo = ({ key, value, serviceId }) => ({
  type: CHANGE_SELECTED_EXECUTIONS_META_INFO,
  payload: { key, value, serviceId },
});

export const resetSelectedExecutionsMetaInfo = ({ serviceId }) => ({
  type: RESET_SELECTED_EXECUTIONS_META_INFO,
  payload: { serviceId },
});

export const resetSelectedExecutionsMetaInfoDate = () => ({
  type: RESET_SELECTED_EXECUTIONS_META_INFO_DATE,
});
export const resetSelectedExecutionsMetaInfoPartial = () => ({
  type: RESET_SELECTED_EXECUTIONS_META_INFO_PARTIAL,
});

export const getPositionsInfoRequest = (params) => ({
  type: GET_POSITIONS_INFO_REQUEST,
  payload: params,
});

export const getPositionsInfoRequestStartLoading = () => ({
  type: GET_POSITIONS_INFO_REQUEST_START_LOADING,
});
export const getPositionsInfoRequestEndLoading = () => ({
  type: GET_POSITIONS_INFO_REQUEST_END_LOADING,
});
export const getPositionsInfoSuccess = (openPositionsInstrumentData) => ({
  type: GET_POSITIONS_INFO_SUCCESS,
  payload: openPositionsInstrumentData,
});

export const changeCardData = (cardData) => ({
  type: CHANGE_CARD_DATA,
  payload: cardData,
});
export const resetCardData = () => ({
  type: RESET_CARD_DATA,
});

export const setListSettings = (serviceId, settings) => ({
  type: SET_LIST_SETTINGS,
  payload: {
    serviceId,
    settings: pick(settings, ['sortBy', 'statusFilter', 'tradeTypeFilter', 'assetTypeFilter']),
  },
});

export const deleteAllManualTradePositionsRequest = ({
  instrumentIdAndSide,
  callback,
  successMessage,
  notificationDelay,
  serviceId,
}) => ({
  type: DELETE_ALL_MANUAL_TRADE_POSITIONS_REQUEST,
  payload: { instrumentIdAndSide, callback, successMessage, notificationDelay, serviceId },
});

export const removeApGroup = ({ serviceId, groupId }) => ({
  type: REMOVE_AP_GROUP,
  payload: {
    serviceId,
    groupId,
  },
});

export const changeSelectedApGroupMetaInfo = ({ key, value, serviceId }) => ({
  type: CHANGE_SELECTED_AP_GROUP_META_INFO,
  payload: { key, value, serviceId },
});

export const resetSelectedApGroupMetaInfo = ({ serviceId }) => ({
  type: RESET_SELECTED_AP_GROUP_META_INFO,
  payload: { serviceId },
});

export const changeSelectedPositionsMetaInfo = ({ key, value, serviceId }) => ({
  type: CHANGE_SELECTED_POSITIONS_META_INFO,
  payload: { key, value, serviceId },
});

export const resetSelectedPositionsMetaInfo = ({ serviceId }) => ({
  type: RESET_SELECTED_POSITIONS_META_INFO,
  payload: { serviceId },
});

export const getPortfolioDataRequest = () => ({ type: GET_PORTFOLIO_DATA_REQUEST });
export const getPortfolioDataRequestStartLoading = () => ({ type: GET_PORTFOLIO_DATA_REQUEST_START_LOADING });
export const getPortfolioDataRequestEndLoading = () => ({ type: GET_PORTFOLIO_DATA_REQUEST_END_LOADING });
