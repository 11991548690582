import React, { memo, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { BUY_SELL_MAIN, FX } from 'shared-modules/constants';
import { DEFAULT_INSTRUMENT_ID } from 'shared-modules/constants/manualTrade';
import { getOrdersRequest } from 'shared-modules/redux/orders';
import { tutorialTradingView } from '../../../../../../constants/tutorial/classNames';
import Chart from '../../../../../../components/Chart';
import styles from './tradingViewChart.module.scss';

const TradingViewChart = ({ selectedInstrumentId, apGroupId, serviceId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!serviceId) return;
    const payload = { serviceId, params: { instrumentId: selectedInstrumentId, apGroupId } };
    dispatch(getOrdersRequest(payload));
  }, [dispatch, serviceId, selectedInstrumentId, apGroupId]);

  return (
    <div className={classNames(styles.chart, tutorialTradingView)}>
      <Chart
        serviceId={serviceId}
        selectedInstrumentId={selectedInstrumentId}
        selectedSide={BUY_SELL_MAIN.SELL.CHART_ID}
        withPositions
        onlyApGroupPositions={apGroupId}
      />
    </div>
  );
};

TradingViewChart.propTypes = {
  serviceId: PropTypes.string.isRequired,
  selectedInstrumentId: PropTypes.string,
  apGroupId: PropTypes.string,
};

TradingViewChart.defaultProps = {
  selectedInstrumentId: DEFAULT_INSTRUMENT_ID[FX],
  apGroupId: '',
};

export default memo(TradingViewChart);
