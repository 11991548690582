import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MODAL_SIZES } from 'shared-modules/constants';
import {
  ANDROID_STORE_LINK,
  BLOG_LINK,
  ETF_INTEREST_RATE_TABLE_LINK,
  CFD_INTEREST_RATE_TABLE_LINK,
  FAQ_LINK,
  FX_SWAP_SUMMARY_LINK,
  INQUIRY_LINK,
  INVAST_HOME_LINK,
  IOS_STORE_LINK,
  MANUAL_PC_LINK,
  NOTICE_LINK,
} from 'shared-modules/config';
import Modal from '../../../../components/Modal';
import { ReactComponent as AppStoreLogo } from '../../../../assets/logoAppStore.svg';
import { ReactComponent as GooglePlayLogo } from '../../../../assets/logoGooglePlay.svg';
import styles from './helpInfo.module.scss';
import LinkRow from './components/Link';

const LOGO_SIZES = {
  WIDTH: 140,
  HEIGHT: 42,
};

const HelpInfo = ({ isOpen, closeModal }) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="お客様サポート" size={MODAL_SIZES.WIDTH_710}>
      <div className={styles.wrapper}>
        <div className={styles.helpBlock}>
          <div className={styles.title}>ヘルプ</div>
          <LinkRow target="manual" href={MANUAL_PC_LINK}>
            操作マニュアル
          </LinkRow>
          <LinkRow target="blog" href={BLOG_LINK}>
            トライオートブログ
          </LinkRow>
          <LinkRow target="faq" href={FAQ_LINK}>
            FAQ
          </LinkRow>
          <LinkRow target="inquiry" href={INQUIRY_LINK}>
            お問合せ
          </LinkRow>
          <LinkRow target="notice" href={NOTICE_LINK}>
            お知らせ
          </LinkRow>
          <LinkRow target="inquiry" href={INVAST_HOME_LINK}>
            トライオートホームページ
          </LinkRow>
        </div>
        <div className={styles.appBlock}>
          <div className={styles.title}>スマートフォンアプリ</div>
          <div className={styles.logoContainer}>
            <AppStoreLogo
              width={LOGO_SIZES.WIDTH}
              height={LOGO_SIZES.HEIGHT}
              className={classNames(styles.logo, styles.isFirst)}
              onClick={() => {
                window.open(IOS_STORE_LINK);
              }}
            />
            <GooglePlayLogo
              width={LOGO_SIZES.WIDTH}
              height={LOGO_SIZES.HEIGHT}
              className={classNames(styles.logo, styles.isFirst)}
              onClick={() => {
                window.open(ANDROID_STORE_LINK);
              }}
            />
          </div>
        </div>
        <div className={styles.swapBlock}>
          <LinkRow href={FX_SWAP_SUMMARY_LINK} target="fx-swap-summary">
            FXスワップポイント
          </LinkRow>
          <LinkRow href={CFD_INTEREST_RATE_TABLE_LINK} target="cfd-interest-rate-table">
            CFD金利配当相当額
          </LinkRow>
          <LinkRow href={ETF_INTEREST_RATE_TABLE_LINK} target="etf-interest-rate-table">
            ETF金利調整額
          </LinkRow>
        </div>
      </div>
    </Modal>
  );
};
HelpInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(HelpInfo);
