import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as ChinaFlag } from '../../assets/flagsETF/China.svg';
import { ReactComponent as USlag } from '../../assets/flagsETF/US.svg';
import { ReactComponent as JPFlag } from '../../assets/flagsETF/JP.svg';
import { ReactComponent as EUFlag } from '../../assets/flagsETF/EU.svg';
import { ReactComponent as OilFlag } from '../../assets/flagsETF/Oil.svg';
import { ReactComponent as GoldFlag } from '../../assets/flagsETF/Gold.svg';
import { ReactComponent as WORLDFlag } from '../../assets/flagsETF/World.svg';
import { ReactComponent as InvastFlag } from '../../assets/flagsETF/invast-circle.svg';
import { ReactComponent as Gold2Flag } from '../../assets/flagsETF/Gold_2.svg';
import { ReactComponent as SilverFlag } from '../../assets/flagsETF/Silver.svg';
import { ReactComponent as TOPIX } from '../../assets/flagsETF/1306.svg';
import { ReactComponent as NIKKEI225 } from '../../assets/flagsETF/1321.svg';
import { ReactComponent as TSE1357 } from '../../assets/flagsETF/1357.svg';
import { ReactComponent as TSE1570 } from '../../assets/flagsETF/1570.svg';
import { ReactComponent as ACWI } from '../../assets/flagsETF/ACWI.svg';
import { ReactComponent as FAS } from '../../assets/flagsETF/FAS.svg';
import { ReactComponent as HYG } from '../../assets/flagsETF/HYG.svg';
import { ReactComponent as IWD } from '../../assets/flagsETF/IWD.svg';
import { ReactComponent as IWM } from '../../assets/flagsETF/IWM.svg';
import { ReactComponent as LQD } from '../../assets/flagsETF/LQD.svg';
import { ReactComponent as QQQ } from '../../assets/flagsETF/QQQ.svg';
import { ReactComponent as SPY } from '../../assets/flagsETF/SPY.svg';
import { ReactComponent as SSO } from '../../assets/flagsETF/SSO.svg';
import { ReactComponent as TQQQ } from '../../assets/flagsETF/TQQQ.svg';
import { ReactComponent as VWO } from '../../assets/flagsETF/VWO.svg';
import { ReactComponent as XLE } from '../../assets/flagsETF/XLE.svg';
import { ReactComponent as XLF } from '../../assets/flagsETF/XLF.svg';
import { ReactComponent as XLI } from '../../assets/flagsETF/XLI.svg';
import { ReactComponent as ARKK } from '../../assets/flagsETF/ARKK.svg';
import { ReactComponent as VXX } from '../../assets/flagsETF/VXX.svg';
import { ReactComponent as XLY } from '../../assets/flagsETF/XLY.svg';
import { ReactComponent as XLK } from '../../assets/flagsETF/XLK.svg';
import { ReactComponent as XLP } from '../../assets/flagsETF/XLP.svg';
import { ReactComponent as XLU } from '../../assets/flagsETF/XLU.svg';

import styles from './customFlagETF.module.scss';

const ETF_FLAG_TYPE = {
  China: ChinaFlag,
  US: USlag,
  JP: JPFlag,
  EU: EUFlag,
  Oil: OilFlag,
  Gold: GoldFlag,
  World: WORLDFlag,
  Invast: InvastFlag,
  Gold_2: Gold2Flag,
  Silver: SilverFlag,
  1306: TOPIX,
  1321: NIKKEI225,
  1357: TSE1357,
  1570: TSE1570,
  ACWI,
  FAS,
  HYG,
  IWD,
  IWM,
  LQD,
  QQQ,
  SPY,
  SSO,
  TQQQ,
  VWO,
  XLE,
  XLF,
  XLI,
  ARKK,
  VXX,
  XLY,
  XLP,
  XLU,
  XLK,
};

const CustomFlagETF = ({ flagType, className }) => {
  const currencyFlagType = flagType.split('.')[0];
  const Tag = useMemo(() => ETF_FLAG_TYPE[currencyFlagType], [currencyFlagType]);
  if (!Tag) return null;
  return <Tag className={classNames(styles.flagImage, { [className]: className })} />;
};

CustomFlagETF.propTypes = {
  flagType: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CustomFlagETF.defaultProps = {
  className: '',
};

export default memo(CustomFlagETF);
