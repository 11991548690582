import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useKeyPressHandler } from '../../services/hooks';
import styles from './customRadios.module.scss';

const Radio = ({ label, value, activeValue, onChange, className }) => {
  const isChecked = useMemo(() => activeValue === value, [activeValue, value]);
  const handleClickRadio = useCallback(
    (e) => {
      onChange(value);
      e.currentTarget.blur();
    },
    [onChange, value],
  );
  const handlePressRadio = useKeyPressHandler(handleClickRadio);
  return (
    <div
      role="button"
      className={classNames(styles.radioElement, className)}
      onClick={handleClickRadio}
      onKeyPress={handlePressRadio}
      tabIndex={0}
    >
      <i className={classNames(styles.radioImage, { [styles.isChecked]: isChecked }, 'material-icons')}>
        {isChecked ? 'radio_button_checked' : 'radio_button_unchecked'}
      </i>
      {label && <div className={styles.radioLabel}>{label}</div>}
    </div>
  );
};

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

Radio.defaultProps = {
  value: null,
  activeValue: null,
};

const CustomRadios = ({ onChange, options, activeValue, className, rowClassName }) => {
  return (
    <div className={className}>
      {options.map(({ label, value }) => (
        <Radio
          key={value}
          label={label}
          value={value}
          activeValue={activeValue}
          onChange={onChange}
          className={rowClassName}
        />
      ))}
    </div>
  );
};

CustomRadios.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  rowClassName: PropTypes.string,
};

CustomRadios.defaultProps = {
  className: '',
  rowClassName: '',
  activeValue: null,
};

export default memo(CustomRadios);
