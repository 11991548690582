// eslint-disable-next-line import/no-unresolved
import { useEffect, useMemo } from 'react';
import { getBalanceMeterAttention } from '../services';
import { isShowDepositTransferLink } from '../utils';
import { BALANCE_METER_NO_TRADE_TOOLTIP } from '../constants';

export const useMeterAttention = ({
  effectiveMargin,
  positionRequiredMargin,
  orderingRequiredMargin,
  consumedMargin,
}) => {
  const attention = useMemo(() => {
    return getBalanceMeterAttention({
      effectiveMargin,
      positionRequiredMargin,
      orderingRequiredMargin,
      consumedMargin,
    });
  }, [effectiveMargin, positionRequiredMargin, orderingRequiredMargin, consumedMargin]);
  return useMemo(
    () => ({
      attention,
      needWarningIcon: isShowDepositTransferLink(attention?.level),
    }),
    [attention],
  );
};

export const useBalanceMeter = ({ meterData, enabledFuture, onChangeAttention, min, max }) => {
  const { effectiveMargin, orderableMargin, positionRequiredMargin, orderingRequiredMargin, consumedMargin } =
    meterData || {};

  let now;
  let future;
  if (effectiveMargin == null) {
    now = min;
    if (enabledFuture) {
      future = min;
    }
  } else {
    now = ((positionRequiredMargin ?? 0) + (orderingRequiredMargin ?? 0)) / effectiveMargin;
    if (consumedMargin != null) {
      if (enabledFuture) {
        if (consumedMargin === 0) {
          // 追加分なしの場合は計算しない
          future = now;
        } else {
          future = 1 - (orderableMargin - consumedMargin) / effectiveMargin;
          if (future < now) {
            // 追加分を加算しても現在値を超えない場合は誤差が発生していると思われるため補正
            future = now;
          }
        }
      }
    }
  }
  // future が有効な場合は circularMeter コンポーネント内で補正が行われるのでここでは無視する
  if (future == null) {
    if (now < min) {
      now = min;
    } else if (now > max) {
      now = max;
    }
    if (!Number.isFinite(now)) {
      now = 0;
    }
  } else if (!Number.isFinite(future) || !Number.isFinite(now)) {
    future = 0;
    now = 0;
  }

  const { attention } = useMeterAttention(meterData || {});

  useEffect(() => {
    onChangeAttention?.(attention);
  }, [attention, onChangeAttention]);

  return useMemo(
    () => ({
      value: now,
      value2: future,
      attention,
    }),
    [now, future, attention],
  );
};

export const useBalanceMeterTooltip = ({ isTrading, attention }) => {
  return useMemo(() => {
    if (!isTrading) {
      return BALANCE_METER_NO_TRADE_TOOLTIP;
    }
    return attention?.tooltip;
  }, [isTrading, attention?.tooltip]);
};
