import React, { memo } from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import styles from './dropdownIndicator.module.scss';

const DropdownIndicator = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <i className={classNames(styles.indicator, 'material-icons')}>keyboard_arrow_down</i>
    </components.DropdownIndicator>
  );
};

export default memo(DropdownIndicator);
