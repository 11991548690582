import { select } from 'redux-saga/effects';
import { makeAccountInfo } from '../../utils';

export function* getAccountInfo(withMarginGroupId) {
  const settings = yield select((state) => state.settings);
  const loginValues = yield select((state) => state.auth.loginValues);
  return makeAccountInfo({
    maintenanceState: settings.maintenanceInfo,
    accountState: settings.accountInfo,
    loginValues,
    withMarginGroupId: withMarginGroupId === true,
  });
}
