import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSummaryInfoByServiceId } from 'shared-modules/services/hooks';
import { useDispatch } from 'react-redux';
import { push } from 'shared-modules/redux/actions/routerActions';
import { useDepositTransfer } from 'shared-modules/hooks/useDepositTransfer';
import { changeServiceIdRequest } from 'shared-modules/redux/actions';
import { useBalanceMeterTooltip } from 'shared-modules/hooks/balanceMeter';
import { BalanceMeter, Button } from '../../../../components';
import { CASH } from '../../../../constants';
import styles from './meterDetails.module.scss';

export const MeterDetails = memo(({ serviceId }) => {
  const dispatch = useDispatch();
  const summaryInfo = useSummaryInfoByServiceId(serviceId);
  const handleClick = useCallback(() => {
    dispatch(push(`/${CASH}`));
    dispatch(changeServiceIdRequest({ serviceId }));
  }, [dispatch, serviceId]);
  const { label, showLink, handleChangeAttention, attention } = useDepositTransfer();
  const isBalanceMeterVisible = useMemo(
    () => summaryInfo?.positionRequiredMargin === 0 && summaryInfo?.orderingRequiredMargin === 0,
    [summaryInfo?.positionRequiredMargin, summaryInfo?.orderingRequiredMargin],
  );
  const tooltip = useBalanceMeterTooltip({ isTrading: !isBalanceMeterVisible, attention });
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <BalanceMeter meterData={summaryInfo} onClick={handleClick} onChangeAttention={handleChangeAttention} />
        <Button width={120} secondary={!showLink} onClick={handleClick}>
          {label}
        </Button>
      </div>
      <div className={styles.description}>{tooltip}</div>
    </div>
  );
});

MeterDetails.propTypes = {
  serviceId: PropTypes.string.isRequired,
};
