import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useAccountInfo } from 'shared-modules/hooks';
import { useDisabledInfo } from 'shared-modules/hooks/service';
import { DRAGGABLE_CANCEL_SELECTOR_NAME } from '../../../constants';
import styles from './serviceDisabledInfo.module.scss';

export const ServiceDisabledInfo = memo(({ serviceId, children }) => {
  const accountInfo = useAccountInfo()[serviceId];
  const disabledInfo = useDisabledInfo(accountInfo, serviceId);
  if (accountInfo.isNotAvailable) {
    return (
      <div className={styles.container}>
        {disabledInfo.linkURL ? (
          <p>
            {disabledInfo.message[0]}
            <a href={disabledInfo.linkURL} className={classNames(DRAGGABLE_CANCEL_SELECTOR_NAME, styles.link)}>
              {disabledInfo.message[1]}
            </a>
            {disabledInfo.message[2]}
          </p>
        ) : (
          <p>{disabledInfo.message}</p>
        )}
      </div>
    );
  }
  return children;
});

ServiceDisabledInfo.propTypes = {
  serviceId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
