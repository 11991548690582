import { put, takeEvery, takeLatest, retry, call, select } from 'redux-saga/effects';
import {
  GET_CURRENT_CART_REQUEST,
  UPDATE_CURRENT_CART_STRATEGY_SETS_REQUEST,
  DELETE_CURRENT_CART_ITEM_REQUEST,
  GET_CURRENT_CART_ITEMS_COUNT_REQUEST,
  EXECUTE_CART_ITEMS_REQUEST,
  SAVE_CURRENT_CART_REQUEST,
  GET_HISTORY_CART_REQUEST,
  ADD_FROM_HISTORY_OR_SAVED_TO_CART_REQUEST,
  GET_SAVED_CART_REQUEST,
  DELETE_SAVED_CART_ITEM_REQUEST,
  DELETE_CURRENT_CART_REQUEST,
} from '../actionConstants/cartConstants';
import { RETRY_DELAY, RETRY_MAX_TRIES } from '../../constants/apiConstant';
import { executeCurrentCartItems } from '../../api/tradingApi';
import {
  getCurrentCart,
  updateCurrencyCartItemStrategySets,
  deleteCurrentCartItem,
  getCurrencyCartItemsCount,
  saveCurrentCart,
  getHistoryCart,
  addHistoryOrSavedToCart,
  getSavedCart,
  deleteSavedCartItem,
  deleteCurrentCart,
} from '../../api/cartApi';
import {
  getCurrentCartStartLoading,
  getCurrentCartStopLoading,
  getCurrentCartSuccess,
  getCurrentCartRequest,
  getCurrentCartItemsCountSuccess,
  saveCurrentCartStartLoading,
  saveCurrentCartStopLoading,
  executeCartItemsStartLoading,
  executeCartItemsStopLoading,
  getHistoryCartStartLoading,
  getHistoryCartStopLoading,
  getHistoryCartSuccess,
  getSavedCartStartLoading,
  getSavedCartStopLoading,
  getSavedCartSuccess,
  getSavedCartRequest,
  deleteCurrentCartStartLoading,
  deleteCurrentCartStopLoading,
  deleteCurrentCartItemStartLoading,
  deleteCurrentCartItemStopLoading,
  getCurrentCartItemsCountRequest,
  changeCurrentCartTermStartLoading,
  changeCurrentCartTermStopLoading,
} from '../actions/cartActions';
import { sendNotificationSuccess } from '../actions/notificationActions';
import { errorHandler } from './errorSaga';
import { checkIsWebApp } from '../../services';

const DELETE_SUCCESSFUL_MESSAGE = '削除されました';
const SAVE_SUCCESSFUL_MESSAGE = 'カートが保存されました。';
const ADD_FROM_HISTORY_OR_SAVER_TO_CURRENT_CART_SUCCESSFUL_MESSAGE = 'カートに追加されました。';
const DELETE_SAVED_CART_ITEM_SUCCESSFUL_MESSAGE = '削除されました';
const WEB_CART_LOCATION = '/cart';
const MOBILE_CART_LOCATION = '/cartStack';

const sortByCreateTime = (a, b) => {
  if (a.createTime < b.createTime) return 1;
  if (a.createTime > b.createTime) return -1;
  return 0;
};

function* getCurrentCartItemsCountRequestHandler() {
  try {
    const { data: itemsCount } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getCurrencyCartItemsCount);

    yield put(getCurrentCartItemsCountSuccess({ itemsCount }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

function* getCurrentCartRequestHandler(action) {
  const {
    payload: { termId, changeTermId },
  } = action;

  try {
    if (changeTermId) {
      yield put(changeCurrentCartTermStartLoading());
    } else {
      yield put(getCurrentCartStartLoading());
    }

    const defaultTermId = yield select((state) => state.constants.defaultSelectionTermId);

    const {
      data: { itemList },
    } = yield call(getCurrentCart, { termId });

    yield put(getCurrentCartSuccess({ currentData: itemList, termId }));

    const isWebApp = checkIsWebApp();
    const currentPage = isWebApp
      ? yield select((state) => state.router.location.pathname)
      : yield select((state) => state.mobileServices.activeMobileScreen);

    const isCartPage = isWebApp ? currentPage === WEB_CART_LOCATION : currentPage === MOBILE_CART_LOCATION;

    if (!isCartPage) {
      return;
    }

    const selectedTermId = yield select((state) => state.cart.selectedTermId);

    if (changeTermId || defaultTermId === selectedTermId) {
      return;
    }

    const {
      data: { itemList: itemListSelected },
    } = yield call(getCurrentCart, { termId: selectedTermId });

    yield put(getCurrentCartSuccess({ currentData: itemListSelected, termId: selectedTermId }));
  } catch (e) {
    yield call(errorHandler, { error: e });
    yield put(getCurrentCartSuccess({ currentData: [], termId }));
  } finally {
    if (changeTermId) {
      yield put(changeCurrentCartTermStopLoading());
    } else {
      yield put(getCurrentCartStopLoading());
    }
  }
}

function* updateCurrentCartStrategySetsRequestHandler(action) {
  try {
    const {
      payload: { itemId, value },
    } = action;

    const requestBody = {
      strategySets: value,
    };
    yield call(updateCurrencyCartItemStrategySets, { itemId, requestBody });
  } catch (e) {
    yield call(errorHandler, { error: e });
    const defaultSelectionTermId = yield select((state) => state.constants.defaultSelectionTermId);
    yield put(getCurrentCartRequest({ termId: defaultSelectionTermId }));
  }
}

function* deleteCurrentCartItemRequestHandler(action) {
  try {
    const {
      payload: { itemId },
    } = action;

    yield put(deleteCurrentCartItemStartLoading({ itemId }));

    yield call(deleteCurrentCartItem, { itemId });

    const defaultTermId = yield select((state) => state.constants.defaultSelectionTermId);
    const selectedTermId = yield select((state) => state.cart.selectedTermId);

    let currentData = yield select((state) => state.cart.currentData[defaultTermId] ?? []);
    currentData = currentData.filter((el) => el.itemId !== itemId);

    yield put(getCurrentCartSuccess({ currentData, termId: defaultTermId }));

    if (selectedTermId !== defaultTermId) {
      let currentDataSelected = yield select((state) => state.cart.currentData[selectedTermId] ?? []);
      currentDataSelected = currentDataSelected.filter((el) => el.itemId !== itemId);

      yield put(getCurrentCartSuccess({ currentData: currentDataSelected, termId: selectedTermId }));
    }

    yield put(sendNotificationSuccess({ message: DELETE_SUCCESSFUL_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(deleteCurrentCartItemStopLoading());
  }
}

function* saveCurrentCartRequestHandler(action) {
  try {
    const {
      payload: { strategyIds },
    } = action;

    yield put(saveCurrentCartStartLoading());
    // strategyIdsが存在している場合、対象strategyのみ保存されます
    // strategyIdsがNullの場合、全カードが保存されます
    yield call(saveCurrentCart, { requestBody: { strategyIds } });

    yield put(sendNotificationSuccess({ message: SAVE_SUCCESSFUL_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(saveCurrentCartStopLoading());
  }
}

function* deleteCurrentCartRequestHandler() {
  try {
    yield put(deleteCurrentCartStartLoading());

    yield call(deleteCurrentCart);

    yield put(getCurrentCartItemsCountRequest());
    yield put(sendNotificationSuccess({ message: DELETE_SUCCESSFUL_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(deleteCurrentCartStopLoading());
    const defaultSelectionTermId = yield select((state) => state.constants.defaultSelectionTermId);
    yield put(getCurrentCartRequest({ termId: defaultSelectionTermId }));
  }
}

function* executeCartItemsRequestHandler(action) {
  try {
    const {
      payload: { callback },
    } = action;
    yield put(executeCartItemsStartLoading());

    const serviceId = yield select((state) => state.auth.serviceId);

    const { data } = yield call(executeCurrentCartItems, { serviceId });

    callback(data);
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(executeCartItemsStopLoading());
  }
}

function* getHistoryCartRequestHandler() {
  try {
    yield put(getHistoryCartStartLoading());

    const { data } = yield call(getHistoryCart);

    const sortedData = data.sort(sortByCreateTime);

    yield put(getHistoryCartSuccess({ historyData: sortedData }));
  } catch (e) {
    yield call(errorHandler, { error: e });
    yield put(getHistoryCartSuccess({ historyData: [] }));
  } finally {
    yield put(getHistoryCartStopLoading());
  }
}

function* addFromHistoryOrSavedToCartRequestHandle(action) {
  const {
    payload: { logId, strategyIds, callback },
  } = action;
  try {
    yield call(addHistoryOrSavedToCart, { requestBody: { cartLogId: logId, strategyIds } });

    const termId = yield select((state) => state.constants.defaultSelectionTermId);

    yield put(getCurrentCartRequest({ termId }));
    yield put(sendNotificationSuccess({ message: ADD_FROM_HISTORY_OR_SAVER_TO_CURRENT_CART_SUCCESSFUL_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    callback();
  }
}

function* getSavedCartRequestHandler() {
  try {
    yield put(getSavedCartStartLoading());

    const { data } = yield call(getSavedCart);
    const sortedData = data.sort(sortByCreateTime);

    yield put(getSavedCartSuccess({ savedData: sortedData }));
  } catch (e) {
    yield call(errorHandler, { error: e });

    yield put(getSavedCartSuccess({ savedData: [] }));
  } finally {
    yield put(getSavedCartStopLoading());
  }
}

function* deleteSavedCartItemRequestHandler(action) {
  const {
    payload: { logId, callback },
  } = action;
  try {
    yield call(deleteSavedCartItem, { logId });

    yield put(getSavedCartRequest());
    yield put(sendNotificationSuccess({ message: DELETE_SAVED_CART_ITEM_SUCCESSFUL_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    callback();
  }
}

export default function* advertisementSaga() {
  yield takeEvery(GET_CURRENT_CART_ITEMS_COUNT_REQUEST, getCurrentCartItemsCountRequestHandler);
  yield takeLatest(GET_CURRENT_CART_REQUEST, getCurrentCartRequestHandler);
  yield takeLatest(UPDATE_CURRENT_CART_STRATEGY_SETS_REQUEST, updateCurrentCartStrategySetsRequestHandler);
  yield takeEvery(DELETE_CURRENT_CART_ITEM_REQUEST, deleteCurrentCartItemRequestHandler);
  yield takeEvery(SAVE_CURRENT_CART_REQUEST, saveCurrentCartRequestHandler);
  yield takeEvery(EXECUTE_CART_ITEMS_REQUEST, executeCartItemsRequestHandler);
  yield takeEvery(DELETE_CURRENT_CART_REQUEST, deleteCurrentCartRequestHandler);
  yield takeLatest(GET_HISTORY_CART_REQUEST, getHistoryCartRequestHandler);
  yield takeEvery(ADD_FROM_HISTORY_OR_SAVED_TO_CART_REQUEST, addFromHistoryOrSavedToCartRequestHandle);
  yield takeLatest(GET_SAVED_CART_REQUEST, getSavedCartRequestHandler);
  yield takeEvery(DELETE_SAVED_CART_ITEM_REQUEST, deleteSavedCartItemRequestHandler);
}
