import React from 'react';
import PropTypes from 'prop-types';
import { FX, CFD } from 'shared-modules/constants';
import CustomFlag from '../CustomFlag';
import EtfInstrumentIcon from '../EtfInstrumentIcon';
import CfdInstrumentIcon from '../CfdInstrumentIcon';
import styles from './instrumentDropdownIcon.module.scss';

const InstrumentDropdownIcon = ({ instrumentId, serviceId }) => {
  if (!instrumentId) return null;
  if (serviceId === FX) {
    const [firstFlag, secondFlag] = instrumentId.split('/');
    return (
      <div className={styles.wrapper}>
        <CustomFlag country={firstFlag} className={styles.flag} />
        <CustomFlag country={secondFlag} className={styles.flag} />
      </div>
    );
  }
  if (serviceId === CFD) {
    return <CfdInstrumentIcon className={styles.wrapper} instrumentId={instrumentId} size="small" noFullSize />;
  }
  return <EtfInstrumentIcon className={styles.wrapper} instrumentId={instrumentId} size="small" noFullSize />;
};

InstrumentDropdownIcon.propTypes = {
  instrumentId: PropTypes.string.isRequired,
  serviceId: PropTypes.string,
};

InstrumentDropdownIcon.defaultProps = {
  serviceId: FX,
};

export default InstrumentDropdownIcon;
