import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import Select, { components as defaultComponents } from 'react-select';
import PropTypes from 'prop-types';
import { EditableInput } from '../EditableInput';
import { DropdownIndicator } from './DropdownIndicator';
import { Option } from './Option';
import styles from './editableSelect.module.scss';

const { ValueContainer } = defaultComponents;
const components = { DropdownIndicator, Option, ValueContainer };

const indicatorSeparator = () => {};
const noOptionsMessage = () => '該当するデータがありません';

export const EditableSelect = memo(
  ({
    label,
    value,
    disabled,
    editable,
    placeholder,
    width,
    className,
    options,
    focusFirstTime,
    editOnly,
    onChange,
    onSwitch,
  }) => {
    const editRef = useRef(null);
    const selectRef = useRef(null);
    const focusFirstTimeRef = useRef(focusFirstTime);

    useEffect(() => {
      if (focusFirstTimeRef.current) {
        selectRef.current?.focus();
      }
    }, []);

    const handleChange = useCallback(
      (option) => {
        if (disabled) {
          return;
        }
        if (onChange) {
          onChange(option.value);
        }
        setTimeout(() => selectRef.current?.focus());
      },
      [disabled, onChange],
    );

    const handleBlur = useCallback(
      (e) => {
        if (disabled) {
          return;
        }
        if (onSwitch) {
          onSwitch(e);
        }
        if (!editOnly) {
          setTimeout(() => editRef.current?.focus());
        }
      },
      [disabled, editOnly, onSwitch],
    );

    const handleKeyDown = useCallback(
      (e) => {
        if (disabled) {
          return;
        }
        if (!editOnly) {
          // if (e.key === 'Enter') {
          //   selectRef.current?.blur();
          // }
          if (e.key === 'Tab' || e.key === 'Escape') {
            e.preventDefault();
            selectRef.current?.blur();
          }
        }
      },
      [disabled, editOnly, selectRef],
    );

    const displayedValue = useMemo(() => options.find((option) => option.value === value), [value, options]);

    const dynamicStyles = useMemo(() => {
      return {
        indicatorSeparator,
        control: (controlStyles) => ({ ...controlStyles, width }),
      };
    }, [width]);

    return (
      <EditableInput
        ref={editRef}
        inputRef={selectRef}
        label={label}
        value={displayedValue?.label}
        disabled={disabled}
        editable={editable}
        editOnly={editOnly}
        width={width}
        className={className}
        textClassName={styles.text}
        onClick={onSwitch}
      >
        <div className={styles.container}>
          <Select
            ref={selectRef}
            classNamePrefix="editable-select"
            className={styles.select}
            components={components}
            styles={dynamicStyles}
            options={options}
            placeholder={placeholder}
            value={displayedValue}
            isDisabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            menuPlacement="auto"
            noOptionsMessage={noOptionsMessage}
            blurInputOnSelect
          />
        </div>
      </EditableInput>
    );
  },
);

EditableSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  focusFirstTime: PropTypes.bool,
  editOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onSwitch: PropTypes.func,
};

EditableSelect.defaultProps = {
  label: undefined,
  value: undefined,
  disabled: false,
  editable: undefined,
  placeholder: undefined,
  width: 'auto',
  className: undefined,
  options: [],
  focusFirstTime: false,
  editOnly: false,
  onChange: undefined,
  onSwitch: undefined,
};
