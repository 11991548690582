import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { BUY_SELL_VALUE, BUY_SELL_AVAILABLE_VALUES, BUY_SELL_MAIN } from 'shared-modules/constants';
import styles from './buySellLabel.module.scss';

export const BuySellLabel = memo(({ type, className }) => {
  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.buy]: type === BUY_SELL_MAIN.BUY.ID,
          [styles.sell]: type === BUY_SELL_MAIN.SELL.ID,
        },
        className,
      )}
    >
      {BUY_SELL_VALUE[type]}
    </div>
  );
});

BuySellLabel.propTypes = {
  type: PropTypes.oneOf(BUY_SELL_AVAILABLE_VALUES).isRequired,
  className: PropTypes.string,
};

BuySellLabel.defaultProps = {
  className: undefined,
};
