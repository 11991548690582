import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { toFullwidthKana } from 'japanese-string-utils';

import { COLORS, SSO_PAYMENT_DEPOSIT_REDIRECT_URL } from 'shared-modules/constants';
import {
  CASH_CONSIDERATION_TITLE,
  WEB_CASH_CONSIDERATION_1,
  CASH_CONSIDERATION_2_1,
  CASH_CONSIDERATION_2_2,
  CASH_CONSIDERATION_3,
  CASH_CONSIDERATION_4,
  CASH_DEPOSIT_CONSIDERATION_TITLE,
  CASH_DEPOSIT_CONSIDERATION_1,
  CASH_DEPOSIT_CONSIDERATION_2,
  CASH_DEPOSIT_CONSIDERATION_3_1,
  CASH_DEPOSIT_CONSIDERATION_4,
  CASH_DEPOSIT_CONSIDERATION_5,
  CASH_DEPOSIT_CONSIDERATION_6_1,
  CASH_DEPOSIT_CONSIDERATION_7,
  CASH_DEPOSIT_CONSIDERATION_8,
  BANK_ACCOUNT_TYPE,
} from 'shared-modules/constants/cash';
import { SYSTEM_KBN_NAMES } from 'shared-modules/constants/apiConstant';

import { openNewSameTab } from '../../../../services';
import CustomButton from '../../../../components/CustomButton';
import commonStyles from '../../common/cashCommon.module.scss';
import styles from './depositView.module.scss';

const DepositView = () => {
  const goToProcessSSODeposit = useCallback(() => {
    openNewSameTab(`/${SSO_PAYMENT_DEPOSIT_REDIRECT_URL}`);
  }, []);

  const {
    bankName,
    bankCd,
    branchName,
    branchCd,
    accountType,
    bankAccountNumber,
    accountHolderKana,
    priorityServiceCd,
  } = useSelector((state) => state.cash.depositOnlyBankInfo);

  const depositTargetService = SYSTEM_KBN_NAMES?.[priorityServiceCd];

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={commonStyles.title}>振込入金先情報</div>

        <div className={commonStyles.textRow}>
          以下の口座までご希望の入金額をお振り込みください。ATMや銀行窓口からご入金いただけます。
        </div>

        <table className={styles.table}>
          <tbody>
            <tr>
              <td className={styles.colTitle}>金融機関</td>
              <td className={styles.colData}>{`${bankName}（${bankCd}）`}</td>
            </tr>
            <tr>
              <td className={styles.colTitle}>支店名</td>
              <td className={styles.colData}>{`${branchName}（${branchCd}）`}</td>
            </tr>
            <tr>
              <td className={styles.colTitle}>口座種別</td>
              <td className={styles.colData}>{BANK_ACCOUNT_TYPE[accountType]}</td>
            </tr>
            <tr>
              <td className={styles.colTitle}>口座番号</td>
              <td className={styles.colData}>{bankAccountNumber}</td>
            </tr>
            <tr>
              <td className={styles.colTitle}>口座名義</td>
              <td className={styles.colData}>{toFullwidthKana(accountHolderKana)}</td>
            </tr>
            <tr>
              <td className={styles.colTitle}>振込入金先</td>
              <td className={styles.colData}>{depositTargetService}</td>
            </tr>
          </tbody>
        </table>

        <div className={styles.buttonRow}>
          <div className={styles.description}>振込入金先を変更する</div>
          <CustomButton color={COLORS.GREY} className={styles.myPage} onClick={goToProcessSSODeposit} width={180}>
            Myページ
            <i className={classNames('material-icons', styles.buttonIcon)}>open_in_new</i>
          </CustomButton>
        </div>
      </div>

      <div className={styles.rules}>
        <div className={commonStyles.title}>{CASH_CONSIDERATION_TITLE}</div>

        <div className={styles.textRow}>
          <span className={commonStyles.asterisk}>1.</span>
          {WEB_CASH_CONSIDERATION_1}
        </div>

        <div className={styles.textRow}>
          <span className={commonStyles.asterisk}>2.</span>
          <div>
            <div>{CASH_CONSIDERATION_2_1}</div>
            <div>{CASH_CONSIDERATION_2_2}</div>
          </div>
        </div>

        <div className={styles.textRow}>
          <span className={commonStyles.asterisk}>3.</span>
          {CASH_CONSIDERATION_3}
        </div>

        <div className={styles.textRow}>
          <span className={commonStyles.asterisk}>4.</span>
          {CASH_CONSIDERATION_4}
        </div>

        <div className={classNames(styles.textRow, styles.consideration)}>{CASH_DEPOSIT_CONSIDERATION_TITLE}</div>

        <div className={styles.attentionRow}>
          <span className={commonStyles.asterisk}>※</span>
          {CASH_DEPOSIT_CONSIDERATION_1}
        </div>
        <div className={styles.attentionRow}>
          <span className={commonStyles.asterisk}>※</span>
          {CASH_DEPOSIT_CONSIDERATION_2}
        </div>
        <div className={styles.attentionRow}>
          <span className={commonStyles.asterisk}>※</span>
          {CASH_DEPOSIT_CONSIDERATION_3_1}
        </div>
        <div className={styles.attentionRow}>
          <span className={commonStyles.asterisk}>※</span>
          {CASH_DEPOSIT_CONSIDERATION_4}
        </div>
        <div className={styles.attentionRow}>
          <span className={commonStyles.asterisk}>・</span>
          {CASH_DEPOSIT_CONSIDERATION_5}
        </div>
        <div className={styles.attentionRow}>
          <span className={commonStyles.asterisk}>・</span>
          {CASH_DEPOSIT_CONSIDERATION_6_1}
        </div>
        <div className={styles.attentionRow}>
          <span className={commonStyles.asterisk}>※</span>
          {CASH_DEPOSIT_CONSIDERATION_7}
        </div>
        <div className={styles.attentionRow}>
          <span className={commonStyles.asterisk}>※</span>
          {CASH_DEPOSIT_CONSIDERATION_8}
        </div>
      </div>
    </div>
  );
};

export default memo(DepositView);
