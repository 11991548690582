import { memo } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import { Button, Modal } from '../../../../components';
import styles from './confirmation.module.scss';

const Confirmation = ({
  isOpen,
  closeModal,
  title,
  callback,
  bodyText,
  buttonBackText,
  buttonNextText,
  isOverlap,
  successButtonIsGreen,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      isOverlap={isOverlap}
      isOverlayModal
      title={title}
      size={MODAL_SIZES.WIDTH_400}
    >
      <div className={styles.bodyText}>{bodyText}</div>
      <div className={styles.buttonRow}>
        {buttonBackText && (
          <Button width={168} onClick={closeModal} className={styles.backButton} secondary>
            {buttonBackText}
          </Button>
        )}
        <Button width={168} onClick={callback} secondary={!successButtonIsGreen}>
          {buttonNextText}
        </Button>
      </div>
    </Modal>
  );
};
Confirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  callback: PropTypes.func,
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonBackText: PropTypes.string,
  buttonNextText: PropTypes.string,
  isOverlap: PropTypes.bool,
  successButtonIsGreen: PropTypes.bool,
};
Confirmation.defaultProps = {
  title: '',
  callback: null,
  bodyText: '',
  buttonBackText: '',
  buttonNextText: '',
  isOverlap: false,
  successButtonIsGreen: false,
};

export default memo(Confirmation);
