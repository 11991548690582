import React from 'react';
import PropTypes from 'prop-types';
import { BuySellLabel } from '../../../../../../components';

export const BuySellCell = ({ cell: { value } }) => <BuySellLabel type={value} />;

BuySellCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }),
};

BuySellCell.defaultProps = {
  cell: {},
};
