import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { RiskAssessmentIcon } from '../Icons';
import { RankText } from '../Texts';
import styles from './riskAssessment.module.scss';

export const RiskAssessment = memo(({ value, className }) => (
  <div className={classNames(styles.container, className)}>
    <RiskAssessmentIcon value={value} />
    <RankText className={styles.text} value={value} />
  </div>
));

RiskAssessment.propTypes = {
  value: PropTypes.oneOf([0, 1, 2, 3, 4, 5]).isRequired,
  className: PropTypes.string,
};

RiskAssessment.defaultProps = {
  className: undefined,
};
