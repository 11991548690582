import PropTypes from 'prop-types';
import { memo } from 'react';

const Nav = ({ items, onItemSelect, selectedItem, containerClassName, itemClassName, activeItemClassName }) => {
  return (
    <div className={containerClassName}>
      {items.map((item) => (
        <div
          aria-hidden
          key={item.value}
          className={item === selectedItem ? activeItemClassName : itemClassName}
          onClick={() => onItemSelect(item)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

Nav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  onItemSelect: PropTypes.func.isRequired,
  selectedItem: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
    PropTypes.string,
  ]),
  containerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  activeItemClassName: PropTypes.string,
};

Nav.defaultProps = {
  items: null,
  selectedItem: null,
  containerClassName: 'd-flex justify-content-around',
  itemClassName: 'nav__item',
  activeItemClassName: 'nav__item nav__item--active rounded-pill',
};

export default memo(Nav);
