import {
  GET_CURRENT_CART_ITEMS_COUNT_REQUEST,
  GET_CURRENT_CART_ITEMS_COUNT_SUCCESS,
  GET_CURRENT_CART_START_LOADING,
  GET_CURRENT_CART_STOP_LOADING,
  GET_CURRENT_CART_REQUEST,
  GET_CURRENT_CART_SUCCESS,
  UPDATE_CURRENT_CART_STRATEGY_SETS_LOCALLY,
  UPDATE_CURRENT_CART_STRATEGY_SETS_REQUEST,
  DELETE_CURRENT_CART_ITEM_REQUEST,
  EXECUTE_CART_ITEMS_START_LOADING,
  EXECUTE_CART_ITEMS_STOP_LOADING,
  EXECUTE_CART_ITEMS_REQUEST,
  SAVE_CURRENT_CART_START_LOADING,
  SAVE_CURRENT_CART_STOP_LOADING,
  SAVE_CURRENT_CART_REQUEST,
  DELETE_CURRENT_CART_START_LOADING,
  DELETE_CURRENT_CART_STOP_LOADING,
  DELETE_CURRENT_CART_REQUEST,
  GET_HISTORY_CART_START_LOADING,
  GET_HISTORY_CART_STOP_LOADING,
  GET_HISTORY_CART_REQUEST,
  GET_HISTORY_CART_SUCCESS,
  ADD_FROM_HISTORY_OR_SAVED_TO_CART_REQUEST,
  GET_SAVED_CART_START_LOADING,
  GET_SAVED_CART_STOP_LOADING,
  GET_SAVED_CART_REQUEST,
  GET_SAVED_CART_SUCCESS,
  DELETE_SAVED_CART_ITEM_REQUEST,
  DELETE_CURRENT_CART_ITEM_START_LOADING,
  DELETE_CURRENT_CART_ITEM_STOP_LOADING,
  SET_CART_DATA_SERVICE_ID,
  CHANGE_CURRENT_CART_TERM_START_LOADING,
  CHANGE_CURRENT_CART_TERM_STOP_LOADING,
  CHANGE_SELECTED_TERM_ID,
} from '../actionConstants/cartConstants';

export const getCurrentCartItemsCountRequest = () => ({ type: GET_CURRENT_CART_ITEMS_COUNT_REQUEST });
export const getCurrentCartItemsCountSuccess = ({ itemsCount }) => ({
  type: GET_CURRENT_CART_ITEMS_COUNT_SUCCESS,
  payload: { itemsCount },
});

export const getCurrentCartStartLoading = () => ({ type: GET_CURRENT_CART_START_LOADING });
export const getCurrentCartStopLoading = () => ({ type: GET_CURRENT_CART_STOP_LOADING });
export const changeCurrentCartTermStartLoading = () => ({ type: CHANGE_CURRENT_CART_TERM_START_LOADING });
export const changeCurrentCartTermStopLoading = () => ({ type: CHANGE_CURRENT_CART_TERM_STOP_LOADING });
export const getCurrentCartRequest = ({ termId, changeTermId }) => ({
  type: GET_CURRENT_CART_REQUEST,
  payload: { termId, changeTermId },
});
export const getCurrentCartSuccess = ({ currentData, termId }) => ({
  type: GET_CURRENT_CART_SUCCESS,
  payload: { currentData, termId },
});
export const changeSelectedTermId = ({ termId }) => ({ type: CHANGE_SELECTED_TERM_ID, payload: { termId } });

export const updateCurrentCartStrategySetsLocally = ({ value, itemId }) => ({
  type: UPDATE_CURRENT_CART_STRATEGY_SETS_LOCALLY,
  payload: { value, itemId },
});
export const updateCurrentCartStrategySetsRequest = ({ value, itemId }) => ({
  type: UPDATE_CURRENT_CART_STRATEGY_SETS_REQUEST,
  payload: { value, itemId },
});

export const deleteCurrentCartItemStartLoading = ({ itemId }) => ({
  type: DELETE_CURRENT_CART_ITEM_START_LOADING,
  payload: { itemId },
});
export const deleteCurrentCartItemStopLoading = () => ({ type: DELETE_CURRENT_CART_ITEM_STOP_LOADING });
export const deleteCurrentCartItemRequest = ({ itemId }) => ({
  type: DELETE_CURRENT_CART_ITEM_REQUEST,
  payload: { itemId },
});

export const executeCartItemsStartLoading = () => ({ type: EXECUTE_CART_ITEMS_START_LOADING });
export const executeCartItemsStopLoading = () => ({ type: EXECUTE_CART_ITEMS_STOP_LOADING });
export const executeCartItemsRequest = ({ callback }) => ({ type: EXECUTE_CART_ITEMS_REQUEST, payload: { callback } });

export const saveCurrentCartStartLoading = () => ({ type: SAVE_CURRENT_CART_START_LOADING });
export const saveCurrentCartStopLoading = () => ({ type: SAVE_CURRENT_CART_STOP_LOADING });
export const saveCurrentCartRequest = ({ strategyIds }) => ({
  type: SAVE_CURRENT_CART_REQUEST,
  payload: { strategyIds },
});

export const deleteCurrentCartStartLoading = () => ({ type: DELETE_CURRENT_CART_START_LOADING });
export const deleteCurrentCartStopLoading = () => ({ type: DELETE_CURRENT_CART_STOP_LOADING });
export const deleteCurrentCartRequest = () => ({ type: DELETE_CURRENT_CART_REQUEST });

export const getHistoryCartStartLoading = () => ({ type: GET_HISTORY_CART_START_LOADING });
export const getHistoryCartStopLoading = () => ({ type: GET_HISTORY_CART_STOP_LOADING });
export const getHistoryCartRequest = () => ({ type: GET_HISTORY_CART_REQUEST });
export const getHistoryCartSuccess = ({ historyData }) => ({
  type: GET_HISTORY_CART_SUCCESS,
  payload: { historyData },
});

export const addFromHistoryOrSavedToCartRequest = ({ logId, strategyIds, callback }) => ({
  type: ADD_FROM_HISTORY_OR_SAVED_TO_CART_REQUEST,
  payload: { logId, strategyIds, callback },
});

export const getSavedCartStartLoading = () => ({ type: GET_SAVED_CART_START_LOADING });
export const getSavedCartStopLoading = () => ({ type: GET_SAVED_CART_STOP_LOADING });
export const getSavedCartRequest = () => ({ type: GET_SAVED_CART_REQUEST });
export const getSavedCartSuccess = ({ savedData }) => ({ type: GET_SAVED_CART_SUCCESS, payload: { savedData } });

export const deleteSavedCartItemRequest = ({ logId, callback }) => ({
  type: DELETE_SAVED_CART_ITEM_REQUEST,
  payload: { logId, callback },
});

export const setCartDataServiceId = ({ serviceId }) => ({ type: SET_CART_DATA_SERVICE_ID, payload: { serviceId } });
