import React, { memo } from 'react';
import styles from '../steps.module.scss';

const Step2 = () => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <p>
          テクニカル指標を設定するチャートは3つの足種から選択できます。
          <br />
          「4時間足」なら4時間ごと、「8時間足」なら8時間ごと、「日足」なら1日ごとの値動きを表したチャートになります。時間足が長いほど長期的なトレンドを判断しやすい傾向があります。
        </p>
        <p>
          ・4時間足
          <br />
          ・8時間足
          <br />
          ・日足
        </p>
      </div>
    </div>
  );
};

export default memo(Step2);
