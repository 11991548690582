import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { DEBUG } from 'shared-modules/config';
import { routerMiddleware } from 'shared-modules/redux/middleware';
import { clearScreen, updateScreen } from 'shared-modules/redux/actions/screenActions';
import { pathnameToScreenId } from '../../services';
import mainReducer from '../reducers';
import mySaga from '../sagas';
import { onLocationChanged } from '../actions';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: mainReducer(history),
  middleware: [routerMiddleware(history), sagaMiddleware],
  devTools: DEBUG === 'true',
});

store.dispatch(clearScreen());

const handleLocationChange = (location, action, isFirstRendering = false) => {
  store.dispatch(onLocationChanged(location, action, isFirstRendering));
  const screenId = pathnameToScreenId(location?.pathname);
  store.dispatch(updateScreen({ screenId }));
};
history.listen(handleLocationChange);
handleLocationChange(history.location, history.action, true);

sagaMiddleware.run(mySaga);
