import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LEARN_TRIAUTO_MENU } from 'shared-modules/constants';
import { batch, useDispatch } from 'react-redux';
import { useKeyPressHandler } from '../../services/hooks';
import styles from './LearnTriautoTabs.module.scss';
import { CircleCheckbox } from '../CircleCheckbox';
import {
  openTutorialModal,
  openBeginDepositModal,
  openBeginRunAutoModal,
  clearAutoSelectFilterCondition,
  clearAutoSelectSortOrder,
  removeFilterInitKeyword,
} from '../../redux/actions';

const TabItem = memo(({ item, isDisabled, itemClassName, isChecked }) => {
  const dispatch = useDispatch();
  const key = item.id;
  const clickCard = useCallback(() => {
    batch(() => {
      if (key === LEARN_TRIAUTO_MENU.BEGINNER.STEPS[0].key) {
        dispatch(clearAutoSelectFilterCondition());
        dispatch(clearAutoSelectSortOrder());
        dispatch(removeFilterInitKeyword());
        dispatch(openTutorialModal());
      }
      if (key === LEARN_TRIAUTO_MENU.BEGINNER.STEPS[1].key) dispatch(openBeginDepositModal());
      if (key === LEARN_TRIAUTO_MENU.BEGINNER.STEPS[2].key) dispatch(openBeginRunAutoModal());
    });
  }, [dispatch, key]);

  const handleKeyPress = useKeyPressHandler(clickCard);

  return (
    <div
      role="button"
      tabIndex={isDisabled || item.isDisabled ? -1 : 0}
      className={classNames(itemClassName, 'card', 'card--clickable', styles.stepsContainer)}
      onClick={clickCard}
      onKeyPress={handleKeyPress}
    >
      <div className={styles.textBox}>
        <div className={styles.stepsText}>{item.value || item.label}</div>
        {isChecked && (
          <div className={styles.checkBox}>
            <CircleCheckbox checked />
          </div>
        )}
      </div>
    </div>
  );
});

TabItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    callback: PropTypes.func,
    isDisabled: PropTypes.bool,
  }).isRequired,
  isDisabled: PropTypes.bool,
  itemClassName: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
};

TabItem.defaultProps = {
  isDisabled: false,
  isChecked: false,
};

const LearnTriautoTabs = ({ options, isDisabled, itemClassName }) => {
  const title = LEARN_TRIAUTO_MENU.BEGINNER.MAIN_TITLE;
  return (
    <section className={styles.learnTriauto}>
      <div className={styles.learnTriautoSection}>
        <div className={styles.learnTriautoTitle}>
          <h2>{title}</h2>
        </div>
      </div>
      <div className={styles.overflowContainer}>
        {options.map((item) => (
          <TabItem
            key={item.value}
            item={item}
            isDisabled={isDisabled}
            itemClassName={itemClassName}
            isChecked={item.isChecked}
          />
        ))}
      </div>
    </section>
  );
};

LearnTriautoTabs.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
      label: PropTypes.string,
      callback: PropTypes.func,
      isDisabled: PropTypes.bool,
    }),
  ).isRequired,
  isDisabled: PropTypes.bool,
  itemClassName: PropTypes.string,
};

LearnTriautoTabs.defaultProps = {
  isDisabled: false,
  itemClassName: '',
};

export default memo(LearnTriautoTabs);
