import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useMessage } from 'shared-modules/hooks/message';
import { CHANNEL_BROWSER } from 'shared-modules/constants';
import styles from './card.module.scss';
import UnreadLabel from '../UnreadLabel';

const browse = ({ linkUrl, messageId }) => window.open(linkUrl, messageId);

export const Card = memo(({ message }) => {
  const { showMessage, endDateDisplay } = useMessage({ message, browse, channel: CHANNEL_BROWSER });
  const { messageId, thumbnailUrl, title, status } = message;
  return (
    <div key={messageId} role="button" className={styles.container} onClick={showMessage} aria-hidden>
      <div className={styles.thumbnailContainer}>
        <img src={thumbnailUrl} className={styles.thumbnail} alt="" />
      </div>
      <div className={styles.info}>
        <div className={styles.titleArea}>
          <div className={styles.dateArea}>
            <div className={styles.date}>{endDateDisplay}</div>
            <div className={styles.unreadArea}>
              <UnreadLabel readFlg={!!status?.readFlg} />
            </div>
          </div>
          <div className={styles.title}>{title}</div>
        </div>
      </div>
    </div>
  );
});

Card.propTypes = {
  message: PropTypes.shape({
    messageId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    action: PropTypes.string,
    linkUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    status: PropTypes.shape({
      startTime: PropTypes.string,
      readFlg: PropTypes.bool,
    }),
  }).isRequired,
};
