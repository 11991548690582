import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from '../../../../../../components/CustomCheckbox';
import styles from './checkboxWrapper.module.scss';

const CheckboxWrapper = ({ item, onChangeHandler, hasError, resetError }) => {
  const handleToggleCheckbox = useCallback(() => {
    onChangeHandler((prevValue) =>
      prevValue.map((checkboxObject) => {
        if (checkboxObject.value === item.value) {
          return {
            ...checkboxObject,
            checked: !checkboxObject.checked,
          };
        }
        return checkboxObject;
      }),
    );
    resetError(false);
  }, [onChangeHandler, item.value, resetError]);

  return (
    <CustomCheckbox
      isChecked={item.checked}
      onChange={handleToggleCheckbox}
      label={item.label}
      className={styles.checkbox}
      labelClassName={styles.labelClassName}
      hasError={hasError}
    />
  );
};
CheckboxWrapper.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  item: PropTypes.shape({
    checked: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  hasError: PropTypes.bool.isRequired,
  resetError: PropTypes.func.isRequired,
};

export default CheckboxWrapper;
