import { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DRAGGABLE_CANCEL_SELECTOR_NAME } from '../../constants';
import { Spinner } from '../Spinner';
import styles from './button.module.scss';

export const Button = memo(
  ({
    submit,
    children,
    secondary,
    width,
    className,
    disabled,
    visuallyDisabled,
    loading,
    onClick,
    operateDisabled,
  }) => {
    const handleClick = useCallback(
      (e) => {
        if (onClick) {
          onClick(e);
        }
      },
      [onClick],
    );

    const style = useMemo(() => (width == null ? undefined : { width }), [width]);

    return (
      <button
        type={submit ? 'submit' : 'button'}
        style={style}
        className={classNames(
          className,
          styles.button,
          {
            [styles.secondary]: secondary,
            [styles.disabled]: disabled || visuallyDisabled || loading,
          },
          DRAGGABLE_CANCEL_SELECTOR_NAME,
        )}
        onClick={handleClick}
        disabled={disabled || operateDisabled || loading}
      >
        {loading ? <Spinner /> : children}
      </button>
    );
  },
);

Button.propTypes = {
  submit: PropTypes.bool,
  secondary: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  disabled: PropTypes.bool,
  visuallyDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  operateDisabled: PropTypes.bool,
};

Button.defaultProps = {
  submit: false,
  secondary: false,
  width: undefined,
  className: '',
  onClick: undefined,
  children: undefined,
  disabled: false,
  visuallyDisabled: false,
  loading: false,
  operateDisabled: false,
};
