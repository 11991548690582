import { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './notificationCard.module.scss';

export const NotificationCard = memo(({ title, src, link }) => {
  return (
    <div className={styles.leftSmallItem}>
      <a href={link} target="_blank" rel="noreferrer">
        <img className={styles.leftSmallImg} alt={title} src={src} />
      </a>
    </div>
  );
});

NotificationCard.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
