/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import { useMemo, useCallback, useEffect, useRef } from 'react';
import Decimal from 'decimal.js';
import { useSelector, useDispatch } from 'react-redux';
import {
  BUY_SELL_MAIN,
  BUY_SELL_OPPOSITE_ID,
  EXPIRATION_TYPE_MAIN,
  MARKET_STATUSES,
  NUMBER_VALIDATION_REG,
  FULL_TIME_REG,
  ORDER_METHOD_MAIN,
  ORDER_TYPES_MAIN,
  QUANTITY_STEP,
  VALIDATION_ERROR_EMPTY_FIELD,
  VALIDATION_TIME_ERROR_MESSAGE,
  TRADE_METHODS,
  INTEGER_VALIDATION_REG,
  FX,
  ETF,
} from '../../constants';
import {
  CLOSE_LIMIT_PRICE_INPUT,
  CLOSE_PRICE_INPUT,
  CLOSE_STOP_PRICE_INPUT,
  CLOSE_DATE_INPUT,
  CLOSE_TIME_INPUT,
  COUNT_INPUT,
  ORDER_TYPES,
  CLOSE_ORDER_LOGIC_VALUES,
} from '../../constants/manualTrade';
import {
  getPriceStep,
  roundExactlyOnPrecisionMatching,
  getRoundedPlusOneHourCurrentTime,
  validateSelectedTime,
  roundToFixedPrecision,
} from '../index';
import {
  useInstrumentSettings,
  usePricesForBuySell,
  useTimeChangingHandler,
  useValidateCommonInputByRules,
} from './index';
import {
  createCloseOrderRequest,
  updateCloseOrderValidationErrors,
  changePositionDetailsOrderLogic,
} from '../../redux/actions/manualTradeActions';
import { toggleUserSettingCloseOrderSkipConfirmationRequest } from '../../redux/actions/settingsActions';
import { CFD_MIN_VALUE } from '../../constants/builder';

const VALIDATING_INPUTS = {
  [ORDER_TYPES_MAIN.MARKET_ORDER.ID]: [COUNT_INPUT],
  [ORDER_TYPES_MAIN.STANDARD.ID]: [COUNT_INPUT, CLOSE_PRICE_INPUT],
  [ORDER_TYPES_MAIN.OCO.ID]: [COUNT_INPUT, CLOSE_LIMIT_PRICE_INPUT, CLOSE_STOP_PRICE_INPUT],
};

const PRICE_INPUTS = {
  [ORDER_TYPES_MAIN.MARKET_ORDER.ID]: [],
  [ORDER_TYPES_MAIN.STANDARD.ID]: [CLOSE_PRICE_INPUT],
  [ORDER_TYPES_MAIN.OCO.ID]: [CLOSE_LIMIT_PRICE_INPUT, CLOSE_STOP_PRICE_INPUT],
};

const useDynamicClosePosition = ({
  isOpen = false,
  closeModal,
  openConfirmModal,
  doNotRefresh = false,
  allowUserDecimals = false,
}) => {
  const dispatch = useDispatch();

  const serviceId = useSelector((state) => state.auth.serviceId);
  const positionId = useSelector((state) => state.modals.manualTradeTableCloseOrder.positionInfo.positionId);
  const instrumentId = useSelector((state) => state.modals.manualTradeTableCloseOrder.positionInfo.instrumentId);
  const side = useSelector((state) => state.modals.manualTradeTableCloseOrder.positionInfo.side);
  const positionName = useSelector((state) => state.modals.manualTradeTableCloseOrder.positionInfo.apName);
  const tradePriceRaw = useSelector((state) => state.modals.manualTradeTableCloseOrder.positionInfo.tradePrice);
  const tradeMethodRaw = useSelector((state) => state.modals.manualTradeTableCloseOrder.positionInfo.tradeMethod);
  const tradeMethod = useMemo(
    () => (Number(tradeMethodRaw) === TRADE_METHODS.MH_MANUAL.ID ? TRADE_METHODS.MH_AP.ID : tradeMethodRaw),
    [tradeMethodRaw],
  );
  const { quantity, settlingQuantity } = useSelector((state) => state.modals.manualTradeTableCloseOrder.positionInfo);
  const validationMessages = useSelector((state) => state.manualTrade.closeOrdersValidationErrors);

  const closeOrderSide = useMemo(() => BUY_SELL_OPPOSITE_ID[Number(side)], [side]);

  const {
    quantityPrecision: quantityPrecisionRaw,
    pricePrecision,
    marketStatus,
    shortName,
  } = useInstrumentSettings(instrumentId, tradeMethod);

  // TODO CFD FXかそうでないかの判定で問題ないか要確認
  const quantityPrecision = useMemo(() => {
    if (serviceId === FX) {
      return quantityPrecisionRaw;
    }

    if (!Number.isInteger(Number(quantity)) || !Number.isInteger(Number(settlingQuantity))) {
      return 0.1;
    }

    return quantityPrecisionRaw;
  }, [serviceId, quantityPrecisionRaw, quantity, settlingQuantity]);

  // TODO CFD FXかそうでないかの判定で問題ないか要確認
  const tradePrice = useMemo(
    () => (serviceId === FX ? tradePriceRaw : roundToFixedPrecision(tradePriceRaw, pricePrecision || 1)),
    [tradePriceRaw, pricePrecision, serviceId],
  );

  // TODO CFD FXかそうでないかの判定で問題ないか要確認
  const displayedInstrumentId = useMemo(
    () => (serviceId === FX ? instrumentId : shortName),
    [serviceId, instrumentId, shortName],
  );

  const priceStep = useMemo(() => {
    if (serviceId === FX) {
      return getPriceStep(instrumentId);
    }
    if (serviceId === ETF) {
      return pricePrecision;
    }
    return CFD_MIN_VALUE;
  }, [serviceId, instrumentId, pricePrecision]);

  const isLoadingCreateCloseOrder = useSelector((state) => state.manualTrade.loadingCreateCloseOrder);

  const maxQuantity = useMemo(() => {
    return Number(Decimal.sub(quantity || 0, settlingQuantity || 0).toFixed(3));
  }, [quantity, settlingQuantity]);

  const { validateQuantity, validateAskLimitPrice, validateAskStopPrice, validateBidLimitPrice, validateBidStopPrice } =
    useValidateCommonInputByRules(instrumentId);

  const { sellPrice, buyPrice, previousSellPrice, previousBuyPrice } = usePricesForBuySell({
    currencyPair: instrumentId,
  });

  const {
    paymentMethod,
    orderMethod,
    count,
    closePrice,
    closeLimitPrice,
    closeStopPrice,
    settlementExpirationType,
    dynamicQuantityStep,
    selectedDate,
    selectedTime,
  } = useSelector((state) => state.manualTrade.closeOrderLogicValues);

  const changeOrderMethod = useCallback(
    (value) => {
      dispatch(changePositionDetailsOrderLogic(CLOSE_ORDER_LOGIC_VALUES.ORDER_METHOD, value));
    },
    [dispatch],
  );
  const setCount = useCallback(
    (value) => {
      dispatch(changePositionDetailsOrderLogic(CLOSE_ORDER_LOGIC_VALUES.COUNT, value));
    },
    [dispatch],
  );
  const setClosePrice = useCallback(
    (value) => {
      dispatch(changePositionDetailsOrderLogic(CLOSE_ORDER_LOGIC_VALUES.CLOSE_PRICE, value));
    },
    [dispatch],
  );
  const setCloseLimitPrice = useCallback(
    (value) => {
      dispatch(changePositionDetailsOrderLogic(CLOSE_ORDER_LOGIC_VALUES.CLOSE_LIMIT_PRICE, value));
    },
    [dispatch],
  );
  const setCloseStopPrice = useCallback(
    (value) => {
      dispatch(changePositionDetailsOrderLogic(CLOSE_ORDER_LOGIC_VALUES.CLOSE_STOP_PRICE, value));
    },
    [dispatch],
  );
  const changeSettlementExpirationType = useCallback(
    (value) => {
      dispatch(changePositionDetailsOrderLogic(CLOSE_ORDER_LOGIC_VALUES.SETTLEMENT_EXPIRATION_TYPE, value));
    },
    [dispatch],
  );
  const setDynamicQuantityStep = useCallback(
    (value) => {
      dispatch(changePositionDetailsOrderLogic(CLOSE_ORDER_LOGIC_VALUES.DYNAMIC_QUANTITY_STEP, value));
    },
    [dispatch],
  );
  const changeSelectedDate = useCallback(
    (value) => {
      dispatch(changePositionDetailsOrderLogic(CLOSE_ORDER_LOGIC_VALUES.SELECTED_DATE, value));
    },
    [dispatch],
  );
  const setSelectedTime = useCallback(
    (value) => {
      dispatch(changePositionDetailsOrderLogic(CLOSE_ORDER_LOGIC_VALUES.SELECTED_TIME, value));
    },
    [dispatch],
  );
  const onTimeChange = useTimeChangingHandler(setSelectedTime);

  const currentDate = useMemo(() => new Date(), []);

  const closeOrderData = useMemo(
    () => ({
      positionId,
      instrumentId,
      side: closeOrderSide,
      quantity: count,
      paymentMethod,
      orderMethod,
      closePrice,
      closeLimitPrice,
      closeStopPrice,
      settlementExpirationType,
      selectedDate,
      selectedTime,
    }),
    [
      positionId,
      instrumentId,
      closeOrderSide,
      count,
      paymentMethod,
      orderMethod,
      closePrice,
      closeLimitPrice,
      closeStopPrice,
      settlementExpirationType,
      selectedDate,
      selectedTime,
    ],
  );

  const confirmationData = useMemo(
    () => ({
      positionName,
      instrumentId,
      paymentMethod,
      closeOrderSide,
      orderMethod,
      count,
      closePrice,
      closeLimitPrice,
      closeStopPrice,
      settlementExpirationType,
      selectedDate,
      selectedTime,
      displayedInstrumentId,
    }),
    [
      positionName,
      instrumentId,
      paymentMethod,
      closeOrderSide,
      orderMethod,
      count,
      closePrice,
      closeLimitPrice,
      closeStopPrice,
      settlementExpirationType,
      selectedDate,
      selectedTime,
      displayedInstrumentId,
    ],
  );

  const currentPrice = useMemo(
    () => (closeOrderSide === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice),
    [closeOrderSide, sellPrice, buyPrice],
  );

  const validateInput = useCallback(
    (inputName, inputValue) => {
      const isSell = closeOrderSide === BUY_SELL_MAIN.SELL.ID;
      const isBuy = closeOrderSide === BUY_SELL_MAIN.BUY.ID;
      const isQuantity = inputName === COUNT_INPUT;
      const isLimitPrice =
        inputName === CLOSE_LIMIT_PRICE_INPUT ||
        (inputName === CLOSE_PRICE_INPUT && Number(orderMethod) === ORDER_METHOD_MAIN.LIMIT.ID);
      const isStopPrice =
        inputName === CLOSE_STOP_PRICE_INPUT ||
        (inputName === CLOSE_PRICE_INPUT && Number(orderMethod) === ORDER_METHOD_MAIN.STOP_LIMIT.ID);

      if (!String(inputValue).match(NUMBER_VALIDATION_REG)) {
        dispatch(
          updateCloseOrderValidationErrors({
            inputName,
            errorMessage: VALIDATION_ERROR_EMPTY_FIELD,
            hasValidationError: true,
          }),
        );
        return false;
      }
      if (isQuantity) {
        const { isValid, errorMessage } = validateQuantity(inputValue, closeOrderSide, true, maxQuantity);
        dispatch(updateCloseOrderValidationErrors({ inputName, errorMessage, hasValidationError: !isValid }));
        return isValid;
      }
      if (isLimitPrice && isSell) {
        const { isValid, errorMessage } = validateBidLimitPrice(inputValue);
        dispatch(updateCloseOrderValidationErrors({ inputName, errorMessage, hasValidationError: !isValid }));
        return isValid;
      }
      if (isLimitPrice && isBuy) {
        const { isValid, errorMessage } = validateAskLimitPrice(inputValue);
        dispatch(updateCloseOrderValidationErrors({ inputName, errorMessage, hasValidationError: !isValid }));
        return isValid;
      }
      if (isStopPrice && isSell) {
        const { isValid, errorMessage } = validateBidStopPrice(inputValue);
        dispatch(updateCloseOrderValidationErrors({ inputName, errorMessage, hasValidationError: !isValid }));
        return isValid;
      }
      if (isStopPrice && isBuy) {
        const { isValid, errorMessage } = validateAskStopPrice(inputValue);
        dispatch(updateCloseOrderValidationErrors({ inputName, errorMessage, hasValidationError: !isValid }));
        return isValid;
      }
      dispatch(updateCloseOrderValidationErrors({ inputName, errorMessage: '', hasValidationError: false }));
      return true;
    },
    [
      dispatch,
      orderMethod,
      closeOrderSide,
      validateQuantity,
      validateAskLimitPrice,
      validateAskStopPrice,
      validateBidLimitPrice,
      validateBidStopPrice,
      maxQuantity,
    ],
  );

  const changeNumberValue = useCallback(
    (inputName, inputValue, changeInputValue) => {
      validateInput(inputName, inputValue);
      const precision = inputName === COUNT_INPUT ? quantityPrecision : pricePrecision;
      changeInputValue(
        String(inputValue).match(precision >= 1 ? INTEGER_VALIDATION_REG : NUMBER_VALIDATION_REG) && !allowUserDecimals
          ? roundExactlyOnPrecisionMatching(inputValue, Math.round(1 / precision), true)
          : inputValue,
      );
    },
    [pricePrecision, quantityPrecision, validateInput, allowUserDecimals],
  );

  const changeCount = useCallback(
    (inputValue) => changeNumberValue(COUNT_INPUT, inputValue, setCount),
    [changeNumberValue, setCount],
  );
  const changeClosePrice = useCallback(
    (inputValue) => changeNumberValue(CLOSE_PRICE_INPUT, inputValue, setClosePrice),
    [changeNumberValue, setClosePrice],
  );
  const changeCloseLimitPrice = useCallback(
    (inputValue) => changeNumberValue(CLOSE_LIMIT_PRICE_INPUT, inputValue, setCloseLimitPrice),
    [setCloseLimitPrice, changeNumberValue],
  );
  const changeCloseStopPrice = useCallback(
    (inputValue) => changeNumberValue(CLOSE_STOP_PRICE_INPUT, inputValue, setCloseStopPrice),
    [setCloseStopPrice, changeNumberValue],
  );

  useEffect(() => {
    if (settlementExpirationType !== EXPIRATION_TYPE_MAIN.CUSTOM.ID) {
      changeSelectedDate(new Date());
      setSelectedTime(getRoundedPlusOneHourCurrentTime());
    }
  }, [setSelectedTime, changeSelectedDate, settlementExpirationType]);

  const validateDate = useCallback(
    (value) => {
      let result;
      if (!value) {
        dispatch(
          updateCloseOrderValidationErrors({
            inputName: CLOSE_DATE_INPUT,
            errorMessage: VALIDATION_ERROR_EMPTY_FIELD,
            hasValidationError: true,
          }),
        );
        result = false;
      } else {
        dispatch(
          updateCloseOrderValidationErrors({
            inputName: CLOSE_DATE_INPUT,
            errorMessage: '',
            hasValidationError: false,
          }),
        );
        result = true;
      }
      return result;
    },
    [dispatch],
  );

  const validateTime = useCallback(
    (time) => {
      if (settlementExpirationType !== EXPIRATION_TYPE_MAIN.CUSTOM.ID) {
        dispatch(
          updateCloseOrderValidationErrors({
            inputName: CLOSE_TIME_INPUT,
            errorMessage: '',
            hasValidationError: false,
          }),
        );
        return true;
      }

      let result;
      if (!String(time).match(FULL_TIME_REG)) {
        dispatch(
          updateCloseOrderValidationErrors({
            inputName: CLOSE_TIME_INPUT,
            errorMessage: VALIDATION_ERROR_EMPTY_FIELD,
            hasValidationError: true,
          }),
        );
        result = false;
      } else if (!selectedDate || !validateSelectedTime({ date: selectedDate, time })) {
        dispatch(
          updateCloseOrderValidationErrors({
            inputName: CLOSE_TIME_INPUT,
            errorMessage: VALIDATION_TIME_ERROR_MESSAGE,
            hasValidationError: true,
          }),
        );
        dispatch(
          updateCloseOrderValidationErrors({
            inputName: CLOSE_DATE_INPUT,
            errorMessage: VALIDATION_TIME_ERROR_MESSAGE,
            hasValidationError: true,
          }),
        );
        result = false;
      } else {
        dispatch(
          updateCloseOrderValidationErrors({
            inputName: CLOSE_TIME_INPUT,
            errorMessage: '',
            hasValidationError: false,
          }),
        );
        result = true;
      }
      return result;
    },
    [dispatch, selectedDate, settlementExpirationType],
  );

  useEffect(() => {
    if (selectedDate && validateTime(selectedTime)) {
      dispatch(
        updateCloseOrderValidationErrors({
          inputName: CLOSE_DATE_INPUT,
          errorMessage: '',
          hasValidationError: false,
        }),
      );
    }
  }, [validateTime, selectedDate, selectedTime, dispatch]);

  const setSelectedDate = useCallback(
    (inputValue) => {
      validateDate(inputValue);

      changeSelectedDate(inputValue);
    },
    [changeSelectedDate, validateDate],
  );

  useEffect(() => {
    validateTime(selectedTime);
  }, [selectedTime, validateTime]);

  const refreshAllFieldExceptPaymentMethod = useCallback(() => {
    changeOrderMethod(ORDER_METHOD_MAIN.LIMIT.ID);
    changeCount(maxQuantity);
    changeClosePrice(currentPrice);
    changeCloseLimitPrice(currentPrice);
    changeCloseStopPrice(currentPrice);
    changeSettlementExpirationType(EXPIRATION_TYPE_MAIN.INFINITY.ID);
    setSelectedDate(new Date());
    setSelectedTime(getRoundedPlusOneHourCurrentTime());
  }, [
    changeOrderMethod,
    changeSettlementExpirationType,
    setSelectedDate,
    setSelectedTime,
    maxQuantity,
    currentPrice,
    changeCloseLimitPrice,
    changeClosePrice,
    changeCloseStopPrice,
    changeCount,
  ]);
  const refreshAllFieldExceptPaymentMethodRef = useRef({});
  useEffect(() => {
    if (refreshAllFieldExceptPaymentMethodRef?.current) {
      refreshAllFieldExceptPaymentMethodRef.current = { refreshAllFieldExceptPaymentMethod };
    }
  }, [refreshAllFieldExceptPaymentMethod]);

  const changePaymentMethod = useCallback(
    (value) => {
      const { refreshAllFieldExceptPaymentMethod: refresh } = refreshAllFieldExceptPaymentMethodRef.current;
      dispatch(changePositionDetailsOrderLogic(CLOSE_ORDER_LOGIC_VALUES.PAYMENT_METHOD, value));
      if (refresh) {
        refresh();
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const { refreshAllFieldExceptPaymentMethod: refresh } = refreshAllFieldExceptPaymentMethodRef.current;
    if (isOpen && !doNotRefresh) {
      changePaymentMethod(0);
      if (refresh) {
        refresh();
      }
    }
  }, [isOpen, doNotRefresh, changePaymentMethod]);

  // TODO CFD FXかそうでないかの判定で問題ないか要確認
  useEffect(() => {
    if (serviceId === FX) {
      const upperLimit = maxQuantity <= 1 ? QUANTITY_STEP.ZERO_POINT_ONE : QUANTITY_STEP.ONE;
      const lowerLimit = QUANTITY_STEP.ZERO_POINT_ONE;

      if (count <= 1) {
        setDynamicQuantityStep(lowerLimit);
      } else {
        setDynamicQuantityStep(upperLimit);
      }
    } else {
      setDynamicQuantityStep(quantityPrecision);
    }
  }, [maxQuantity, count, setDynamicQuantityStep, serviceId, quantityPrecision]);

  const validationErrors = useMemo(
    () =>
      Object.entries(validationMessages).reduce((messages, [inputName, info]) => {
        if (info.hasValidationError) {
          messages.push({ inputName, errorMessage: info.errorMessage });
        }
        return messages;
      }, []),
    [validationMessages],
  );

  const buttonIsDisabled = useMemo(() => {
    const hasError = Boolean(validationErrors.length);
    const marketIsClosedOnMarketOrder =
      paymentMethod === ORDER_TYPES.MARKET_ORDER.id && Number(marketStatus) === MARKET_STATUSES.CLOSED.ID;

    return hasError || marketIsClosedOnMarketOrder;
  }, [validationErrors, paymentMethod, marketStatus]);

  const withoutConfirmation = useSelector((state) => state.settings[serviceId].skipCloseOrderConfirmation);
  const handleChangeWithoutConfirmation = useCallback(() => {
    dispatch(toggleUserSettingCloseOrderSkipConfirmationRequest());
  }, [dispatch]);

  const createCloseOrder = useCallback(() => {
    dispatch(createCloseOrderRequest({ callback: closeModal, data: closeOrderData }));
  }, [dispatch, closeModal, closeOrderData]);

  const validateAllInputs = useCallback(() => {
    const priceInputsIsValid = Object.entries(closeOrderData).every(([inputName, inputValue]) => {
      if (VALIDATING_INPUTS[paymentMethod].includes(inputName)) {
        return validateInput(inputName, inputValue);
      }
      return true;
    });
    let dateInputIsValid = true;
    let timeInputIsValid = true;

    if (paymentMethod !== ORDER_TYPES.MARKET_ORDER.id) {
      dateInputIsValid = validateDate(selectedDate);
      timeInputIsValid = validateTime(selectedTime);
    }

    return priceInputsIsValid && dateInputIsValid && timeInputIsValid;
  }, [closeOrderData, validateInput, paymentMethod, validateDate, validateTime, selectedDate, selectedTime]);
  const validateAllInputsRef = useRef({});
  useEffect(() => {
    if (validateAllInputsRef?.current) {
      validateAllInputsRef.current = { validateAllInputs };
    }
  }, [validateAllInputs]);

  const validateAllPrices = useCallback(() => {
    Object.entries(closeOrderData).forEach(([inputName, inputValue]) => {
      if (PRICE_INPUTS[paymentMethod].includes(inputName)) {
        validateInput(inputName, inputValue);
      }
    });
  }, [closeOrderData, validateInput, paymentMethod]);
  const validateAllPricesRef = useRef({});
  useEffect(() => {
    if (validateAllPricesRef?.current) {
      validateAllPricesRef.current = { validateAllPrices };
    }
  }, [validateAllPrices]);

  const handleCloseOrder = useCallback(() => {
    const orderValuesAreValid = validateAllInputs();
    if (buttonIsDisabled || !orderValuesAreValid) {
      return;
    }
    if (withoutConfirmation) {
      createCloseOrder();
    } else {
      dispatch(openConfirmModal({ callback: createCloseOrder, data: confirmationData }));
    }
  }, [
    validateAllInputs,
    buttonIsDisabled,
    withoutConfirmation,
    createCloseOrder,
    dispatch,
    openConfirmModal,
    confirmationData,
  ]);

  useEffect(() => {
    const { validateAllPrices: validatePrices } = validateAllPricesRef.current;
    if (validatePrices) validatePrices();
  }, [sellPrice, buyPrice]);

  useEffect(() => {
    if (validateAllInputsRef?.current?.validateAllInputs) {
      validateAllInputsRef.current.validateAllInputs();
    }
  }, [orderMethod]);

  return {
    positionName,
    positionId,
    instrumentId,
    tradePrice,
    maxQuantity,
    buyPrice,
    previousBuyPrice,
    sellPrice,
    previousSellPrice,
    paymentMethod,
    changePaymentMethod,
    closeOrderSide,
    orderMethod,
    changeOrderMethod,
    count,
    changeCount,
    dynamicQuantityStep,
    validationErrors,
    priceStep,
    closePrice,
    changeClosePrice,
    closeLimitPrice,
    changeCloseLimitPrice,
    closeStopPrice,
    changeCloseStopPrice,
    settlementExpirationType,
    changeSettlementExpirationType,
    currentDate,
    selectedDate,
    setSelectedDate,
    selectedTime,
    onTimeChange,
    withoutConfirmation,
    handleChangeWithoutConfirmation,
    handleCloseOrder,
    buttonIsDisabled,
    isLoadingCreateCloseOrder,
    tradeMethod,
    displayedInstrumentId,
  };
};

export default useDynamicClosePosition;
