import classNames from 'classnames';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Spin } from '../../Spin';
import styles from '../multiEditCustomTable.module.scss';

const TableLoader = ({ isLoading, style }) => {
  return (
    isLoading && (
      <div className={classNames(styles.loaderContainer, style)}>
        <Spin className={styles.loader} />
      </div>
    )
  );
};

TableLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  style: PropTypes.string,
};

TableLoader.defaultProps = {
  style: '',
};

export default memo(TableLoader);
