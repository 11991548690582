import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCalculatingChartDataWithLabels, useChangeService, useDebounce } from 'shared-modules/services/hooks';
import { useConvertStrategyList, useCurrentData } from 'shared-modules/hooks/cart';
import { CART_DEFAULT_SETS, CHART_DEBOUNCE_TIME, DEFAULT_SERVICE_ID } from 'shared-modules/constants';
import { CART_EMPTY_CHART_MESSAGE } from 'shared-modules/constants/cart';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { useAccountInfo } from 'shared-modules/hooks';
import { getMarginRequest } from 'shared-modules/redux/actions/portfolioActions';
import { getServiceName, roundRoi } from 'shared-modules/utils';
import { useCheckAndNotifyAvailableService } from 'shared-modules/hooks/service';
import { changeSelectedTermId, getCurrentCartRequest } from '../../../../redux/actions';
import ProfitLossChart from '../../../../components/ProfitLossChart';
import { InfoTooltip, LabelWithHelp, Spin, Tabs, TermSelect } from '../../../../components';
import { ColoredRateText, MarginText, PriceText } from '../../../../components/Texts';
import { CartAssetDetail } from '../CartAssetDetail';
import { CartWarningInfo } from '../CartWarningInfo';
import styles from './chartArea.module.scss';

const chartMargin = {
  top: 20,
  left: 20,
  right: 20,
  bottom: 10,
};

const isNotExistsOption = (optionList, id) => {
  return optionList.filter((x) => x.id === id).length === 0;
};

const isVisuallyDisabledOption = (optionList, id) => {
  return optionList.filter((x) => x.id === id)[0].isVisuallyDisabled;
};

export const ChartArea = memo(() => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [tabWidth, setTabWidth] = useState(115);

  const selectionTermOptions = useSelector((state) => state.constants.selectionTermOptions);
  const defaultSelectedTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const rates = useSelector((state) => state.currencies.eodRates);
  useEffect(() => {
    dispatch(changeSelectedTermId({ termId: defaultSelectedTermId }));
  }, [dispatch, defaultSelectedTermId]);

  const termId = useSelector((state) => state.cart.selectedTermId);
  const handleChangeTermId = useCallback(
    (e) => {
      dispatch(changeSelectedTermId({ termId: e }));

      if (e !== defaultSelectedTermId) {
        dispatch(getCurrentCartRequest({ termId: e, changeTermId: true }));
      }
    },
    [dispatch, defaultSelectedTermId],
  );

  const strategyList = useCurrentData(termId);

  const isLoadingMain = useSelector((state) => state.cart.currentDataIsLoading);
  const isLoadingTermId = useSelector((state) => state.cart.currentDataChangeTermIdIsLoading);
  const isLoadingMeterData = useSelector((state) => state.cart.changingSummaryInfoLoading);
  const [balanceMeterServiceId, setBalanceMeterServiceId] = useState(DEFAULT_SERVICE_ID);

  const isLoading = useMemo(
    () => isLoadingMain || isLoadingTermId || isLoadingMeterData,
    [isLoadingMain, isLoadingTermId, isLoadingMeterData],
  );

  const [strategyListDebounced] = useDebounce(strategyList, CHART_DEBOUNCE_TIME);
  const [isLoadingDebounced] = useDebounce(isLoading, CHART_DEBOUNCE_TIME);

  const convertedStrategyList = useConvertStrategyList({ strategyList: strategyListDebounced, rates });
  const calculatingChartData = useCalculatingChartDataWithLabels({
    strategyList: convertedStrategyList,
    defaultSets: CART_DEFAULT_SETS,
  });
  const {
    chartRoi,
    totalPl,
    roundedMarginRecommended,
    roundedMarginRecommendedFX,
    roundedMarginRecommendedETF,
    roundedMarginRecommendedCFD,
    marginRequired,
    warningButton,
    marginRequiredFX,
    marginRequiredETF,
    marginRequiredCFD,
    chartData,
  } = calculatingChartData;

  const calculatedRoi = useMemo(() => roundRoi(chartRoi.value), [chartRoi.value]);

  const { options } = useChangeService();
  const cartItemServiceId = useMemo(
    () => strategyListDebounced?.map((s) => s.strategyDetail?.serviceId),
    [strategyListDebounced],
  );

  // TODO CFD 要リファクタリング
  const tagOptions = useMemo(() => {
    const data = {
      marginRequiredFX,
      marginRequiredETF,
      marginRequiredCFD,
      roundedMarginRecommendedFX,
      roundedMarginRecommendedETF,
      roundedMarginRecommendedCFD,
    };
    return options
      .filter((x) => cartItemServiceId?.includes(x.id))
      .map((x) => {
        const serviceName = getServiceName(x.id);
        return {
          children: (
            <CartAssetDetail
              marginRequired={data[`marginRequired${serviceName}`]}
              marginRecommended={data[`roundedMarginRecommended${serviceName}`]}
              serviceId={x.id}
            />
          ),
          ...x,
        };
      });
  }, [
    options,
    cartItemServiceId,
    marginRequiredFX,
    marginRequiredETF,
    marginRequiredCFD,
    roundedMarginRecommendedFX,
    roundedMarginRecommendedETF,
    roundedMarginRecommendedCFD,
  ]);

  useEffect(() => {
    ALL_SERVICES.forEach((serviceId) => {
      if (!options.filter((x) => x.id === serviceId)[0].isVisuallyDisabled) {
        dispatch(getMarginRequest({ serviceId }));
      }
    });
  }, [dispatch, options]);

  const accountInfo = useAccountInfo();
  const checkAndNotify = useCheckAndNotifyAvailableService();
  const handleChangeTab = useCallback(
    (id) => {
      if (checkAndNotify(accountInfo, id)) {
        return;
      }
      setBalanceMeterServiceId(id);
    },
    [accountInfo, checkAndNotify],
  );

  useEffect(() => {
    if (
      tagOptions.length > 0 &&
      (isNotExistsOption(tagOptions, balanceMeterServiceId) ||
        isVisuallyDisabledOption(tagOptions, balanceMeterServiceId))
    ) {
      setBalanceMeterServiceId(tagOptions.filter((x) => !x.isVisuallyDisabled)[0]?.id || tagOptions[0].id);
    }
  }, [dispatch, tagOptions, balanceMeterServiceId]);

  const handleOpenWarning = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCloseWarning = useCallback(() => {
    setVisible(false);
  }, []);

  const handleResize = useCallback(
    ({ width }) => {
      const length = tagOptions?.length ?? 0;
      if (length === 1) {
        setTabWidth((width ?? 0) / 2);
      } else if (length > 1) {
        setTabWidth((width ?? 0) / length);
      }
    },
    [tagOptions],
  );

  return (
    <>
      <div className={styles.container}>
        {!isLoadingDebounced && termId && !chartData.length && (
          <div className={styles.emptyValues}>{CART_EMPTY_CHART_MESSAGE}</div>
        )}
        {!isLoadingDebounced && termId && chartData.length > 0 && (
          <>
            <div className={styles.header}>
              <div className={styles.headerRowGroup}>
                <div className={styles.headerRow}>
                  <div className={styles.minItem}>
                    <div className={styles.label}>{chartRoi.label}</div>
                    <ColoredRateText className={styles.value} symbolClassName={styles.value} value={calculatedRoi} />
                  </div>
                  <div className={styles.item}>
                    <div className={styles.label}>{totalPl.label}</div>
                    <PriceText className={styles.value} symbolClassName={styles.value} value={totalPl.value} />
                  </div>
                  {/* TODO 総合評価対応 */}
                  {/* <div className={styles.minItem}>
                    <div className={styles.label}>{RISK_ASSESSMENT_LABEL}</div>
                    <Evaluation value={riskAssessment} />
                  </div> */}
                </div>
                <div className={styles.headerRow}>
                  <div className={styles.item}>
                    <div className={styles.labelContainer}>
                      {roundedMarginRecommended.label}
                      <InfoTooltip
                        className={styles.tooltip}
                        message={roundedMarginRecommended.tooltip}
                        placement="bottom"
                      />
                    </div>
                    <MarginText
                      className={styles.value}
                      symbolClassName={styles.value}
                      value={roundedMarginRecommended.value}
                    />
                  </div>
                  <div className={styles.item}>
                    <div className={styles.labelContainer}>
                      {marginRequired.label}
                      <InfoTooltip className={styles.tooltip} message={marginRequired.tooltip} placement="bottom" />
                    </div>
                    <MarginText className={styles.value} symbolClassName={styles.value} value={marginRequired.value} />
                  </div>
                </div>
              </div>
              <div className={styles.headerRow}>
                <div className={styles.notes}>
                  <LabelWithHelp label={warningButton.label} onClick={handleOpenWarning} />
                </div>
              </div>
            </div>
            <div className={styles.termSelectArea}>
              <TermSelect
                titleClassName={styles.termSelectTitle}
                title="シミュレーション期間"
                options={selectionTermOptions}
                termId={termId}
                onChange={handleChangeTermId}
              />
            </div>
            <div className={styles.content}>
              <div className={styles.chart}>
                {isLoadingDebounced ? (
                  <Spin className={styles.loader} />
                ) : (
                  <ProfitLossChart chartMargin={chartMargin} data={chartData} useDefaultYAxisWidth />
                )}
              </div>
              <div className={styles.meter}>
                <div className={styles.meterTitle}>稼働後バランスメーター</div>
                <Tabs
                  type="line"
                  items={tagOptions}
                  activeKey={balanceMeterServiceId}
                  containerClassName={styles.tabs}
                  tabMinWidth={tabWidth}
                  scrollWidth={tabWidth}
                  onReady={handleResize}
                  onResize={handleResize}
                  onChange={handleChangeTab}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <CartWarningInfo isOpen={visible} closeModal={handleCloseWarning} />
    </>
  );
});
