import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import { BUY_SELL_MAIN, CHART_RESOLUTION_MAIN } from 'shared-modules/constants';
import { useCalculatingSimulationChartData } from 'shared-modules/services/hooks';
import {
  useBuilderMarginRequirement,
  useBuilderMultiBasePriceForSide,
  useBuilderMultiEntryPriceParsedValues,
  useGetBuilderOrderRequestData,
  useGetBuilderServiceId,
} from 'shared-modules/services/hooks/builder';
import {
  useComprehensiveEvaluation,
  useComprehensiveEvaluationInfoBuilder,
} from 'shared-modules/hooks/comprehensiveEvaluation';
import { numberExists } from 'shared-modules/utils';
import { useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import {
  changeSelectedTermIdBuilder,
  getBuilderSimulationDataRequest,
  openBuilderWarningInfo,
} from '../../../../../redux/actions';
import {
  LabelWithHelp,
  MultiPriceChart,
  ProfitLossChart,
  SimulationInfo,
  Spin,
  TermSelect,
} from '../../../../../components';
import { AssetLabel } from './AssetLabel';
import BuySellSwitch from '../../../../../components/BuySellSwitch';
import styles from './simulatingChart.module.scss';

// const dropdownWidth = 130;

const ResolutionButton = ({ barType, onChange, id, label }) => (
  <div>
    <div
      role="button"
      aria-hidden
      className={classNames({
        [styles.active]: id === barType,
        [styles.nonActive]: id !== barType,
      })}
      onClick={() => onChange(id)}
    >
      {label}
    </div>
  </div>
);

ResolutionButton.propTypes = {
  barType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export const SimulatingChart = memo(() => {
  const dispatch = useDispatch();
  const activeCurrency = useSelector((state) => state.builder.activeCurrency);
  const allowOrderEdit = useSelector((state) => state.builder.allowOrderEdit);

  const selectionTermOptions = useSelector((state) => state.constants.selectionTermOptions);

  const defaultSelectedTermId = useSelector((state) => state.constants.defaultSelectionTermId);

  const getOrderRequestData = useGetBuilderOrderRequestData();

  const [termId, changeTermId] = useState(defaultSelectedTermId);
  const simulationData = useSelector((state) => state.builder.simulationData[termId]);
  const [isLoading, changeIsLoading] = useState(false);

  useEffect(() => {
    if (allowOrderEdit) {
      changeTermId(defaultSelectedTermId);
      dispatch(changeSelectedTermIdBuilder({ termId: defaultSelectedTermId }));
      changeIsLoading(false);
    }
  }, [dispatch, allowOrderEdit, defaultSelectedTermId]);

  const handleChangeTermId = useCallback(
    (newTermId) => {
      changeTermId(newTermId);
      dispatch(changeSelectedTermIdBuilder({ termId: newTermId }));
      changeIsLoading(true);

      const orderRequestData = getOrderRequestData();
      dispatch(
        getBuilderSimulationDataRequest({
          orderRequestData,
          callback: () => changeIsLoading(false),
          termId: newTermId,
        }),
      );
    },
    [dispatch, getOrderRequestData],
  );

  const simulationStats = useSelector((state) => state.builder.simulationData[termId]?.simulationStats);
  const { realizedPl, unrealizedPl, marginRecommended } = simulationStats ?? {};
  const totalPl = useMemo(
    () =>
      numberExists(realizedPl) && numberExists(unrealizedPl) ? new Decimal(realizedPl).add(unrealizedPl).toNumber() : 0,
    [realizedPl, unrealizedPl],
  );
  const chartTitle = useMemo(() => (allowOrderEdit ? 'プライスチャート' : '損益シミュレーション'), [allowOrderEdit]);
  const chartData = useCalculatingSimulationChartData({
    strategyList: [simulationData ?? {}],
  });
  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);
  const logicGroupsList = useSelector((state) => state.builder.logicGroupsList);
  const itemsCount = logicGroupsList.reduce((prev, curr) => prev + curr.itemsCount, 0);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const serviceId = useGetBuilderServiceId();
  const instrumentOptions = useApOrManualInstrumentOptions(false, serviceId);
  const { basePriceAsk, basePriceBid } = useBuilderMultiBasePriceForSide();

  const [buySell, changeBuySell] = useState(BUY_SELL_MAIN.BUY.CHART_ID);
  const multiOrderSelectedSellBuyId = useSelector((state) => state.builder.multiOrder.selectedSellBuyId);
  const chartBuySell =
    Number(multiOrderSelectedSellBuyId) === BUY_SELL_MAIN.BUY.ID
      ? BUY_SELL_MAIN.BUY.CHART_ID
      : BUY_SELL_MAIN.SELL.CHART_ID;
  useEffect(() => {
    changeBuySell(chartBuySell);
  }, [chartBuySell]);
  const multiOrderOcoIsChecked = useSelector((state) => state.builder.multiOrder.ocoIsChecked);
  const multiOrderIsBuy = useMemo(() => Number(chartBuySell) === BUY_SELL_MAIN.BUY.CHART_ID, [chartBuySell]);
  const multiOrderBasePrice = useMemo(
    () => (multiOrderIsBuy ? basePriceAsk : basePriceBid),
    [multiOrderIsBuy, basePriceAsk, basePriceBid],
  );
  const [multiOrderBasePriceEntryPriceValue, multiOrderRangeSpreadPriceValue] = useBuilderMultiEntryPriceParsedValues();

  const onWarningButtonClick = useCallback(() => {
    dispatch(openBuilderWarningInfo());
  }, [dispatch]);

  const marginRequired = useBuilderMarginRequirement();

  const comprehensiveEvaluationInfo = useComprehensiveEvaluationInfoBuilder();
  const comprehensiveEvaluation = useComprehensiveEvaluation(comprehensiveEvaluationInfo);

  const [barType, setBarType] = useState(CHART_RESOLUTION_MAIN.DAYS_1.TV_ID);

  const toolBox = useMemo(() => {
    if (allowOrderEdit) {
      return (
        <div className={styles.toolBox}>
          <div className={styles.label}>足種</div>
          <ResolutionButton
            id={CHART_RESOLUTION_MAIN.HOURS_1.TV_ID}
            barType={barType}
            onChange={setBarType}
            label="1時間"
          />

          <ResolutionButton
            id={CHART_RESOLUTION_MAIN.HOURS_4.TV_ID}
            barType={barType}
            onChange={setBarType}
            label="4時間"
          />

          <ResolutionButton
            id={CHART_RESOLUTION_MAIN.HOURS_8.TV_ID}
            barType={barType}
            onChange={setBarType}
            label="8時間"
          />

          <ResolutionButton
            id={CHART_RESOLUTION_MAIN.DAYS_1.TV_ID}
            barType={barType}
            onChange={setBarType}
            label="日"
          />

          <ResolutionButton
            id={CHART_RESOLUTION_MAIN.WEEKS_1.TV_ID}
            barType={barType}
            onChange={setBarType}
            label="週"
          />

          <ResolutionButton
            id={CHART_RESOLUTION_MAIN.MONTH_1.TV_ID}
            barType={barType}
            onChange={setBarType}
            label="月"
          />
          <div className={styles.chartHeader}>
            <BuySellSwitch activeItemId={buySell} onChange={changeBuySell} />
          </div>
        </div>
      );
    }
    return (
      <TermSelect
        className={styles.termSelect}
        titleClassName={styles.termSelectTitle}
        title="シミュレーション期間"
        options={selectionTermOptions}
        termId={termId}
        onChange={handleChangeTermId}
      />
    );
  }, [allowOrderEdit, selectionTermOptions, termId, handleChangeTermId, barType, buySell]);

  return (
    <div className={styles.simulatingChart}>
      <div className={styles.header}>
        <div className={styles.title}>{chartTitle}</div>
        <AssetLabel />
        {!allowOrderEdit && (
          <div className="ms-auto me-3">
            <LabelWithHelp label="注意事項" onClick={onWarningButtonClick} />
          </div>
        )}
      </div>
      {toolBox}
      <div className={styles.content}>
        <div className={classNames(styles.chartWrapper, { [styles.existsInfo]: !allowOrderEdit && !isLoading })}>
          {allowOrderEdit && (
            <MultiPriceChart
              selectedCurrencyPairId={activeCurrency}
              resolution={barType}
              orderSettingsList={orderSettingsList}
              instrumentOptions={instrumentOptions}
              instrumentList={instrumentList}
              serviceId={serviceId}
              logicGroupsList={logicGroupsList}
              ocoIsChecked={multiOrderOcoIsChecked}
              buySell={buySell}
              chartBuySell={chartBuySell}
              basePrice={multiOrderBasePrice}
              entryPriceValue={multiOrderBasePriceEntryPriceValue}
              rangeSpreadPriceValue={multiOrderRangeSpreadPriceValue}
            />
          )}
          {!allowOrderEdit && !isLoading && <ProfitLossChart data={chartData} useDefaultYAxisWidth />}
        </div>
        {!allowOrderEdit && !isLoading && (
          <div className={styles.infoWrapper}>
            <SimulationInfo
              serviceId={serviceId}
              totalPl={totalPl}
              marginRecommended={marginRecommended}
              marginRequired={marginRequired}
              count={itemsCount}
              comprehensiveEvaluation={comprehensiveEvaluation}
            />
          </div>
        )}
        {!allowOrderEdit && isLoading && (
          <div className={styles.loaderWrapper}>
            <Spin className={styles.isLoading} />
          </div>
        )}
      </div>
    </div>
  );
});
