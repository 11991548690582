import { getJapaneseFormatDate, getMaxDate } from '../services';

export const useTermEndDate = (strategies) => {
  if (strategies?.length) {
    return getJapaneseFormatDate(getMaxDate(strategies.map((strategy) => strategy.simulationStats?.termEnd)));
  }
  return '';
};

// termEnd access path: strategyList.strategyDetail.simulationStats.termEnd
// delegate to useTermEndDate
export const useCartTermEndDate = (strategyList) => {
  return useTermEndDate(strategyList?.map(({ strategyDetail }) => strategyDetail));
};
