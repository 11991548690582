import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './termSelect.module.scss';

export const TermSelect = memo(({ className, titleClassName, title, options, termId, onChange }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.title, titleClassName)}>{title ?? ''}</div>
      <div className={styles.buttons}>
        {options.map(({ label, value }) => (
          <div key={value}>
            <div
              role="button"
              aria-hidden
              onClick={() => onChange(value)}
              className={classNames(styles.button, { [styles.active]: value === termId })}
            >
              {label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

TermSelect.propTypes = {
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  title: PropTypes.string,
  termId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func,
};

TermSelect.defaultProps = {
  className: undefined,
  titleClassName: undefined,
  title: undefined,
  termId: undefined,
  options: undefined,
  onChange: undefined,
};
