import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_SIZES, COLORS, WIDTH, OPTIONS_EXPIRATION_TYPE, CFD } from 'shared-modules/constants';
import {
  CHANGE_ORDER_PRICE_INPUT,
  CHANGE_ORDER_OCO2_PRICE_INPUT,
  CHANGE_ORDER_THEN_PRICE_INPUT,
  CHANGE_ORDER_THEN_OCO2_PRICE_INPUT,
  CHANGE_DATE_INPUT,
  CHANGE_TIME_INPUT,
  CHANGE_SETTLEMENT_DATE_INPUT,
  CHANGE_SETTLEMENT_TIME_INPUT,
} from 'shared-modules/constants/manualTrade';
import { usePreparedOrderInfoForEdit, useDynamicOrderInfo } from 'shared-modules/services/hooks/orderChangeLogic';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { openConfirmationModal, getOrderInfoRequest, changeOrderRequest } from '../../../../redux/actions';
import { InputNumber, Modal, Spin } from '../../../../components';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import CustomSelect from '../../../../components/CustomSelect/CustomSelect';
import CustomInput from '../../../../components/CustomInput';
import styles from './manualTradeTableChangeOrder.module.scss';

const DROPDOWN_WIDTH = 123;
const TIME_INPUT_WIDTH = 122;
const DATEPICKER_WIDTH = 131;

const ManualTradeTableChangeOrder = ({ isOpen, closeModal, orderId, isManual }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(getOrderInfoRequest({ orderId }));
    }
  }, [dispatch, isOpen, orderId]);

  const loadingOrderInfo = useSelector((state) => state.manualTrade.loadingSelectedOrderInfo);
  const loadingChangeOrder = useSelector((state) => state.manualTrade.loadingChangeOrder);
  const serviceId = useSelector((state) => state.auth.serviceId);

  const {
    apName,
    closeOrderLabel,
    instrumentId = {},
    price,
    side = {},
    typeName = {},
    quantity = {},
    compositeType,
    compositeTypeName = {},
    newOrderReadonly,
    thenTypeName,
    isCloseOrder,
  } = usePreparedOrderInfoForEdit(isOpen);
  const {
    innerPrice,
    innerOco2Price,
    innerExpireType,
    innerSelectedDate,
    innerSelectedTime,
    innerThenPrice,
    innerThenOco2Price,
    innerThenExpireType,
    innerThenSelectedDate,
    innerThenSelectedTime,
    priceStep,
    currentDate,
    isDisabledSubmit,
    isNotAP,
    settlement,
    closeOrder,
    orderData,
    validateAllInputs,
    validationErrors,
  } = useDynamicOrderInfo(isOpen);

  const confirmChangeOrder = useCallback(() => {
    dispatch(
      changeOrderRequest({
        orderType: compositeType,
        callback: closeModal,
        orderData,
        isManual,
      }),
    );
  }, [dispatch, compositeType, orderData, closeModal, isManual]);

  const handleChangeOrder = useCallback(() => {
    const orderValuesAreValid = validateAllInputs();
    if (orderValuesAreValid) {
      dispatch(
        openConfirmationModal({
          title: '注文変更',
          bodyText:
            '注文を変更します。変更する注文が自動売買注文の場合、当該自動売買は稼働停止となります。よろしいですか。',
          buttonBackText: '戻る',
          buttonNextText: '変更確定',
          callback: confirmChangeOrder,
          isOverlap: true,
        }),
      );
    }
  }, [dispatch, validateAllInputs, confirmChangeOrder]);

  const targetPositionNameRef = useRef(uuid());

  const tooltipId = useRef(uuid()).current;

  const settings = useSelector((state) => state.settings.instrumentList?.[instrumentId?.value]);

  const isCFD = useMemo(() => serviceId === CFD, [serviceId]);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="注文変更" size={MODAL_SIZES.WIDTH_360}>
      <div className={styles.wrapper}>
        {loadingOrderInfo ? (
          <div className={styles.emptyBlock}>
            <div className={styles.loaderWrapper}>
              <Spin className={styles.loader} />
            </div>
          </div>
        ) : (
          <>
            {/* STATIC INFO */}
            <div className={styles.titleRow}>{closeOrderLabel}</div>

            {apName && (
              <div className={styles.nameRow}>
                {apName.label}
                <span className={styles.nameSpan} data-for={tooltipId} data-tip={apName.value}>
                  {apName.value}
                </span>
                <ReactTooltip id={tooltipId} />
              </div>
            )}

            <div className={styles.brandRow}>
              {instrumentId.label}
              <span>{instrumentId.formattedValue}</span>
            </div>

            <div className={styles.buySellRow}>
              {side.label}
              <span>{side.formattedValue}</span>
            </div>

            {compositeType !== 0 && (
              <div className={styles.orderTypeRow}>
                {compositeTypeName.label}
                <span>{compositeTypeName.formattedValue}</span>
              </div>
            )}

            <div className={styles.orderTermsRow}>
              {typeName.label}
              <span>{typeName.formattedValue}</span>
            </div>

            <div className={styles.countRow}>
              <div>
                {quantity.label}
                {isCFD && <div>1Lot={settings?.quantityUnitConvFactor}</div>}
              </div>
              <span className={styles.number}>{quantity.formattedValue}</span>
            </div>

            {/* DYNAMIC INFO */}
            <div className={styles.priceRow}>
              {innerPrice.label}

              {newOrderReadonly ? (
                <span className={styles.number}>{price}</span>
              ) : (
                <InputNumber
                  value={innerPrice.get}
                  onChange={innerPrice.set}
                  type="number"
                  name={CHANGE_ORDER_PRICE_INPUT}
                  step={priceStep}
                  errorMessages={validationErrors}
                  withErrorTooltip
                />
              )}
            </div>

            {innerOco2Price.condition && (
              <div className={styles.ocoSecondValueRow}>
                {innerOco2Price.label}
                <InputNumber
                  value={innerOco2Price.get}
                  onChange={innerOco2Price.set}
                  name={CHANGE_ORDER_OCO2_PRICE_INPUT}
                  type="number"
                  step={priceStep}
                  errorMessages={validationErrors}
                  withErrorTooltip
                />
              </div>
            )}

            <div className={styles.expirationTypeRow}>
              {innerExpireType.label}

              {newOrderReadonly ? (
                <span>{innerExpireType.readonlyValue}</span>
              ) : (
                <CustomSelect
                  width={DROPDOWN_WIDTH}
                  selectItemId={innerExpireType.get}
                  onChange={innerExpireType.set}
                  options={OPTIONS_EXPIRATION_TYPE}
                  isDisabled={innerExpireType.isDisabled}
                />
              )}
            </div>

            {isNotAP && innerSelectedDate.condition && (
              <>
                <div className={styles.datePickerRow}>
                  {innerSelectedDate.label}

                  <CustomDatePicker
                    width={DATEPICKER_WIDTH}
                    date={innerSelectedDate.get}
                    onChange={innerSelectedDate.set}
                    minDate={currentDate}
                    popperPlacement="auto"
                    isTaller
                    disableSundays
                    name={CHANGE_DATE_INPUT}
                    errorMessages={validationErrors}
                    withErrorTooltip
                  />
                </div>
                <div className={styles.timePickerRow}>
                  {innerSelectedTime.label}

                  <CustomInput
                    value={innerSelectedTime.get}
                    onChange={innerSelectedTime.set}
                    width={TIME_INPUT_WIDTH}
                    placeholder={innerSelectedTime.placeholder}
                    isEndAligned
                    name={CHANGE_TIME_INPUT}
                    errorMessages={validationErrors}
                    withErrorTooltip
                  />
                </div>
              </>
            )}

            {settlement.condition && (
              <>
                <div className={styles.settlementTitleRow}>{settlement.title}</div>

                <div className={styles.settlementBuySellRow}>
                  {settlement.sideLabel}
                  <span>{settlement.sideValue}</span>
                </div>

                <div className={styles.settlementOrderTerms}>
                  {settlement.typeLabel}
                  <span>{settlement.typeValue}</span>
                </div>

                <div className={styles.settlementOrderTerms}>
                  {settlement.thenTypeLabel}
                  <span>{thenTypeName}</span>
                </div>

                <div className={styles.settlementCount}>
                  {innerThenPrice.label}
                  <InputNumber
                    value={innerThenPrice.get}
                    onChange={innerThenPrice.set}
                    name={CHANGE_ORDER_THEN_PRICE_INPUT}
                    type="number"
                    step={priceStep}
                    errorMessages={validationErrors}
                    withErrorTooltip
                  />
                </div>

                {innerThenOco2Price.condition && (
                  <div className={styles.settlementCount}>
                    {innerThenOco2Price.label}
                    <InputNumber
                      value={innerThenOco2Price.get}
                      onChange={innerThenOco2Price.set}
                      name={CHANGE_ORDER_THEN_OCO2_PRICE_INPUT}
                      type="number"
                      step={priceStep}
                      errorMessages={validationErrors}
                      withErrorTooltip
                    />
                  </div>
                )}

                <div className={styles.settlementExpirationTypeRow}>
                  {innerThenExpireType.label}

                  <CustomSelect
                    width={DROPDOWN_WIDTH}
                    selectItemId={innerThenExpireType.get}
                    onChange={innerThenExpireType.set}
                    options={OPTIONS_EXPIRATION_TYPE}
                    hasError={innerThenExpireType.hasError}
                    errorMessage={innerThenExpireType.errorMessage}
                    isDisabled={innerThenExpireType.isDisabled}
                    withErrorTooltip
                  />
                </div>

                {isNotAP && innerThenSelectedDate.condition && (
                  <>
                    <div className={styles.datePickerRow}>
                      {innerThenSelectedDate.label}

                      <CustomDatePicker
                        width={DATEPICKER_WIDTH}
                        date={innerThenSelectedDate.get}
                        onChange={innerThenSelectedDate.set}
                        minDate={currentDate}
                        popperPlacement="auto"
                        isTaller
                        disableSundays
                        name={CHANGE_SETTLEMENT_DATE_INPUT}
                        errorMessages={validationErrors}
                        withErrorTooltip
                      />
                    </div>

                    <div className={styles.timePickerRow}>
                      {innerThenSelectedTime.label}

                      <CustomInput
                        value={innerThenSelectedTime.get}
                        onChange={innerThenSelectedTime.set}
                        width={TIME_INPUT_WIDTH}
                        placeholder={innerThenSelectedTime.placeholder}
                        isEndAligned
                        name={CHANGE_SETTLEMENT_TIME_INPUT}
                        errorMessages={validationErrors}
                        withErrorTooltip
                      />
                    </div>
                  </>
                )}
              </>
            )}

            {isCloseOrder && (
              <>
                <div className={styles.targetPositionHeader}>{closeOrder.title}</div>

                <div className={styles.targetPositionPriceRow}>
                  {closeOrder.positionPriceTitle}
                  <span className={styles.number}>{closeOrder.positionPriceValue}</span>
                </div>

                <div className={styles.targetPositionNameRow}>
                  {closeOrder.positionName}
                  <span
                    className={styles.targetPositionName}
                    data-for={targetPositionNameRef.current}
                    data-tip={closeOrder.positionInfo}
                  >
                    {closeOrder.positionInfo}
                  </span>
                </div>

                <ReactTooltip id={targetPositionNameRef.current} />
              </>
            )}
          </>
        )}

        <CustomButton
          className={styles.button}
          onClick={handleChangeOrder}
          width={WIDTH.PERCENTAGE_100}
          color={COLORS.RED}
          isDisabled={isDisabledSubmit}
          isLoading={loadingChangeOrder}
        >
          変更する
        </CustomButton>
      </div>
    </Modal>
  );
};

ManualTradeTableChangeOrder.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  orderId: PropTypes.string,
  isManual: PropTypes.bool,
};

ManualTradeTableChangeOrder.defaultProps = {
  orderId: '',
  isManual: false,
};

export default memo(ManualTradeTableChangeOrder);
