import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { sendNotificationError } from 'shared-modules/redux/actions/notificationActions';
import { nop } from 'shared-modules/utils';
import { Button } from '../../Button';
import CustomRadios from '../../CustomRadios';
import CustomCheckbox from '../../CustomCheckbox';
import CustomDatePicker from '../../CustomDatePicker';
import styles from './controlDialog.module.scss';

const DATEPICKER_WIDTH = 128;

const makeOptions = (options) =>
  options.map(({ label, value }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ));

export const ControlDialog = memo(
  forwardRef(({ parentRef, filterOption, sortOptions, onClick, onClose }, ref) => {
    const dispatch = useDispatch();
    const filterOptionType = filterOption?.type;
    const filterOptions = filterOption?.options;
    const [hasError, setHasError] = useState(false);
    const [toolTipStyle, setToolTipStyle] = useState(null);
    const [filterSelected, setFilterSelected] = useState(null);
    const [sortSelected, setSortSelected] = useState(sortOptions?.find(({ selected }) => selected)?.value);
    const [filterInnerSelected, setFilterInnerSelected] = useState(null);

    const calculateStyles = useCallback(() => {
      if (parentRef?.current && ref?.current) {
        const pos = parentRef.current.getBoundingClientRect();
        const { height: contentHeight } = ref.current.getBoundingClientRect();
        let top = pos.height;
        const maxHeight = document.body.clientHeight - pos.top - top;
        const upperEffectiveHeight = pos.top;
        if (maxHeight < contentHeight && upperEffectiveHeight >= contentHeight) {
          top = -contentHeight;
        }
        setToolTipStyle({ top: pos.top + top, left: pos.left });
      }
    }, [ref, parentRef]);

    const filterCheckboxHandler = useCallback(
      (checkboxSelected, selectedLabel) => {
        setHasError(false);
        const selectedCheckboxValue = filterOptions?.find(({ label }) => label === selectedLabel)?.value;
        if (checkboxSelected) {
          setFilterSelected((filterSelected ?? []).concat(selectedCheckboxValue));
        } else {
          setFilterSelected(filterSelected.filter((selected) => selected !== selectedCheckboxValue));
        }
      },
      [filterOptions, filterSelected],
    );

    const checkbox = useMemo(() => {
      if (filterOptionType === 'checkbox') {
        return filterOptions.map(({ label, value }) => (
          <CustomCheckbox
            key={value}
            isChecked={Boolean(filterSelected?.includes(value))}
            onChange={filterCheckboxHandler}
            label={label}
            checkboxClassName={styles.checkboxIcon}
          />
        ));
      }
      return null;
    }, [filterOptionType, filterOptions, filterSelected, filterCheckboxHandler]);

    const filterDatePickerHandler = useCallback(
      ({ fromDate, toDate }) => {
        if (fromDate) {
          if (fromDate.getTime() > filterSelected.toDate.getTime()) {
            setFilterSelected({ ...filterSelected, fromDate, toDate: fromDate });
          } else {
            setFilterSelected({ ...filterSelected, fromDate });
          }
        }
        if (toDate) {
          if (toDate.getTime() >= filterSelected.fromDate.getTime()) {
            setFilterSelected({ ...filterSelected, toDate });
          } else {
            setFilterSelected({ ...filterSelected, fromDate: toDate, toDate });
          }
        }
      },
      [filterSelected],
    );

    const datePicker = useMemo(() => {
      if (filterOptionType === 'datepicker') {
        const { maxDate } = filterOptions || {};
        return (
          <div className={styles.datePickerContainer}>
            <CustomDatePicker
              date={filterSelected?.fromDate}
              maxDate={maxDate}
              onChange={(fromDate) => filterDatePickerHandler({ fromDate })}
              isLighter
              placeholder="日付を選択"
              width={DATEPICKER_WIDTH}
            />
            <div className={styles.datePickerSeparator}>~</div>
            <CustomDatePicker
              date={filterSelected?.toDate}
              maxDate={maxDate}
              onChange={(toDate) => filterDatePickerHandler({ toDate })}
              isLighter
              width={DATEPICKER_WIDTH}
            />
          </div>
        );
      }
      return null;
    }, [filterOptionType, filterOptions, filterSelected, filterDatePickerHandler]);

    const handleClickButton = useCallback(() => {
      if (filterOptionType === 'checkbox' && filterSelected.length === 0) {
        setHasError(true);
        dispatch(sendNotificationError({ message: '最低1つを選択してください。' }));
        return;
      }
      onClick({
        filterSelected: filterSelected === 'select' ? filterInnerSelected : filterSelected,
        sortSelected,
      });
    }, [dispatch, onClick, filterOptionType, filterSelected, sortSelected, filterInnerSelected]);

    const handleSelect = useCallback((event) => {
      setFilterInnerSelected(event.target.value);
    }, []);

    const radio = useMemo(() => {
      if (filterOptionType === 'radio') {
        // filterInnerSelectedのデフォルト値設定
        if (!filterInnerSelected) setFilterInnerSelected(filterOptions[1].label[0].value);

        const options = filterOptions?.map((option) => {
          const { label } = option;
          // 個別選択を想定（なので filterOptions の要素数は 1 〜 2 を想定）
          if (Array.isArray(label)) {
            return {
              label: (
                <select
                  className={styles.select}
                  value={filterInnerSelected ?? ''}
                  onMouseDown={() => setFilterSelected('select')}
                  onChange={handleSelect}
                >
                  {makeOptions(label)}
                </select>
              ),
              value: 'select',
            };
          }
          // 全てを想定
          return option;
        });
        return (
          <CustomRadios
            rowClassName={styles.radios}
            options={options}
            onChange={setFilterSelected}
            activeValue={filterSelected}
          />
        );
      }
      return null;
    }, [filterOptionType, filterOptions, filterSelected, filterInnerSelected, handleSelect]);

    const filterInput = useMemo(() => {
      if (radio) {
        return radio;
      }
      if (checkbox) {
        return checkbox;
      }
      if (datePicker) {
        return datePicker;
      }
      return null;
    }, [radio, checkbox, datePicker]);

    useEffect(() => {
      calculateStyles();
    }, [calculateStyles]);

    useEffect(() => {
      if (!onClose) {
        return nop;
      }
      document.addEventListener('wheel', onClose);
      return () => {
        document.removeEventListener('wheel', onClose);
      };
    }, [onClose]);

    // フィルタ選択値の初期化
    useEffect(() => {
      const { type, options } = filterOption || {};
      if (type === 'datepicker') {
        setFilterSelected({ fromDate: options?.fromDate, toDate: options?.toDate });
      }
      if (type === 'checkbox') {
        setFilterSelected(options?.filter(({ selected }) => selected)?.map(({ value }) => value));
      }
      if (type === 'radio') {
        const found = options?.find(({ selected }) => selected);
        if (found) {
          const { label, value } = found;
          setFilterSelected(found.value);
          if (value === 'select') {
            setFilterInnerSelected(label?.find(({ selected }) => selected)?.value ?? label?.[0]?.value);
          }
        }
      }
    }, [filterOption]);

    // https://github.com/facebook/prop-types/issues/167
    return (
      <>
        {createPortal(
          <div className={classNames(styles.container, { [styles.inShadow]: hasError })} style={toolTipStyle} ref={ref}>
            {filterInput && (
              <div className={styles.filterWrapper}>
                <div className={styles.filterLabel}>絞り込み</div>
                {filterInput}
              </div>
            )}
            {filterInput && sortOptions && <div className={styles.separator} />}
            {sortOptions && (
              <div className={styles.sortWrapper}>
                <div className={styles.filterLabel}>並べ替え</div>
                <CustomRadios
                  rowClassName={styles.radios}
                  options={sortOptions}
                  onChange={setSortSelected}
                  activeValue={sortSelected}
                />
              </div>
            )}
            <Button className={styles.button} width={160} onClick={handleClickButton} secondary>
              適用
            </Button>
          </div>,
          document.body,
        )}
      </>
    );
  }),
);

ControlDialog.propTypes = {
  parentRef: PropTypes.shape({
    current: PropTypes.shape({
      getBoundingClientRect: PropTypes.func,
    }),
  }),
  filterOption: PropTypes.shape({
    type: PropTypes.oneOf(['radio', 'checkbox', 'datepicker']).isRequired,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.arrayOf(
              PropTypes.shape({
                label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                selected: PropTypes.bool,
              }),
            ),
          ]),
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          selected: PropTypes.bool,
        }),
      ),
      PropTypes.shape({
        fromDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        toDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      }),
    ]).isRequired,
  }),
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      selected: PropTypes.bool,
    }).isRequired,
  ),
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

ControlDialog.defaultProps = {
  parentRef: undefined,
  filterOption: undefined,
  sortOptions: undefined,
  onClose: undefined,
};
