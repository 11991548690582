import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useIsReloadingApp } from 'shared-modules/hooks';
import { useSemiBlockingModalDisplayControl } from 'shared-modules/hooks/modal';
import { isTrue } from 'shared-modules/utils';
import { isTargetAllSemiBlockingModal } from '../../../services';
import { LossCutModal } from './LossCutModal';
import { MaintenanceModal } from './MaintenanceModal';

export const BlockingModal = memo(() => {
  const isMobile = useSelector((state) => state.router.location.query.isMobile);
  const {
    visibleBlockingMaintenance,
    visibleBlockingLossCut,
    visibleMaintenance,
    visibleLossCut,
    isBlockedMaintenance,
    isBlockedLossCut,
    changeMaintenanceTimestamp,
    changeLossCutTimestamp,
  } = useSemiBlockingModalDisplayControl(isTargetAllSemiBlockingModal);
  const isReloadingApp = useIsReloadingApp();
  if (isReloadingApp) {
    // TODO リロード中モーダルを表示させるのが良さそう(余力があれば)
    return null;
  }
  if (isTrue(isMobile)) {
    // mobile の webview 上にはメンテ・ロスカットモーダルを表示させない
    return null;
  }
  return (
    <>
      <MaintenanceModal visible={visibleBlockingMaintenance} isBlock />
      <LossCutModal visible={visibleBlockingLossCut} isBlock />
      <MaintenanceModal
        visible={visibleMaintenance}
        isBlocked={isBlockedMaintenance}
        timestamp={changeMaintenanceTimestamp}
      />
      <LossCutModal visible={visibleLossCut} isBlocked={isBlockedLossCut} timestamp={changeLossCutTimestamp} />
    </>
  );
});
