import { all, put } from 'redux-saga/effects';
import { changeExecutionsTableMetaInfo } from '../../actions/manualTradeActions';
import Logger from '../../../services/Logger';
import { ALL_SERVICES } from '../../../constants/core';

function* resetExecutionsTableMetaInfoPartialHandler() {
  try {
    yield all(
      ALL_SERVICES.flatMap((serviceId) => [
        put(changeExecutionsTableMetaInfo({ key: 'fromDate', value: null, serviceId })),
        put(changeExecutionsTableMetaInfo({ key: 'toDate', value: null, serviceId })),
        put(changeExecutionsTableMetaInfo({ key: 'searchedFromDate', value: null, serviceId })),
        put(changeExecutionsTableMetaInfo({ key: 'searchedToDate', value: null, serviceId })),
      ]),
    );
  } catch (e) {
    Logger.error(e);
  }
}

export default resetExecutionsTableMetaInfoPartialHandler;
