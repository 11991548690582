import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { BUY_SELL_MAIN, FX } from 'shared-modules/constants';
import { calculateSpread, toCommaSeparatedFixedValue } from 'shared-modules/services';
import { highlightCurrencyPairRate } from '../../services';
import PriceForManual from './components/PriceForManual';
import styles from './buySellPricesForManual.module.scss';

const HIGHLIGHT_SIZE = 26;

const BuySellPricesForManual = ({
  instrumentId,
  sellPrice,
  buyPrice,
  className,
  onClick,
  isNotActive,
  isNotActiveBuy,
  isNotActiveSell,
  isBuyDisabled,
  isSellDisabled,
  previousSellPrice,
  previousBuyPrice,
}) => {
  const serviceId = useSelector((state) => state.auth.serviceId);

  const handleClickSell = useCallback(
    (e) => {
      if (isNotActive) {
        return;
      }
      onClick(BUY_SELL_MAIN.SELL.ID, e);
      e.currentTarget.blur();
    },
    [onClick, isNotActive],
  );

  const handleClickBuy = useCallback(
    (e) => {
      onClick(BUY_SELL_MAIN.BUY.ID, e);
      e.currentTarget.blur();
    },
    [onClick],
  );

  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const instrument = instrumentList?.[instrumentId];

  // TODO CFD FXかそうでないかの判定で問題ないか要確認
  const formatValue = useCallback(
    (value) => {
      if (serviceId === FX) return highlightCurrencyPairRate(instrument.instrumentId, value, HIGHLIGHT_SIZE);
      return (
        <div className={styles.largePrice}>{toCommaSeparatedFixedValue(value, instrument.pricePrecision, '-')}</div>
      );
    },
    [instrument, serviceId],
  );

  return (
    <div className={classNames(styles.wrapper, className)}>
      <PriceForManual
        instrumentId={instrumentId}
        label="BID 売"
        value={sellPrice}
        prevValue={previousSellPrice}
        formatValue={formatValue}
        isDisabled={isNotActive || isSellDisabled || isNotActiveSell}
        isGrey={isSellDisabled}
        onPress={handleClickSell}
      />
      <PriceForManual
        isBuy
        instrumentId={instrumentId}
        alignedTo="right"
        label="ASK 買"
        value={buyPrice}
        formatValue={formatValue}
        prevValue={previousBuyPrice}
        isDisabled={isNotActive || isBuyDisabled || isNotActiveBuy}
        isGrey={isBuyDisabled}
        onPress={handleClickBuy}
      />
      <div className={styles.spread}>
        {calculateSpread({
          buyPrice,
          sellPrice,
          instrumentId,
          instrumentList,
        })}
      </div>
    </div>
  );
};
BuySellPricesForManual.propTypes = {
  instrumentId: PropTypes.string,
  sellPrice: PropTypes.number.isRequired,
  buyPrice: PropTypes.number.isRequired,
  previousSellPrice: PropTypes.number,
  previousBuyPrice: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isNotActive: PropTypes.bool,
  isNotActiveBuy: PropTypes.bool,
  isNotActiveSell: PropTypes.bool,
  isBuyDisabled: PropTypes.bool,
  isSellDisabled: PropTypes.bool,
};
BuySellPricesForManual.defaultProps = {
  className: '',
  instrumentId: '',
  onClick: () => {},
  isNotActive: false,
  isNotActiveBuy: false,
  isNotActiveSell: false,
  previousSellPrice: null,
  previousBuyPrice: null,
  isBuyDisabled: false,
  isSellDisabled: false,
};

export default memo(BuySellPricesForManual);
