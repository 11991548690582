import intersectionBy from 'lodash/intersectionBy';
import uniq from 'lodash/uniq';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_POSITIONS_MAX_AMOUNT, ETF } from 'shared-modules/constants';
import { useGetStillDeletingPositions } from 'shared-modules/services/hooks';
import {
  openConfirmationModal,
  openErrorInfoModal,
  saveDeletingPositions,
  closeMultiplePositionsRequest,
} from '../../../../redux/actions';
import CustomButton from '../../../CustomButton';
import styles from './deletePositionsButton.module.scss';

const DeletePositionsButton = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const selectedPositions = useSelector((state) => state.manualTrade.selectedTableRows);
  const tableMetaInfo = useSelector((state) => state.manualTrade.positionsDataMetaInfo[serviceId]);
  const tableDataRaw = useSelector((state) => state.currencies.positions[serviceId]);

  const dispatch = useDispatch();

  const getStillDeletingPositions = useGetStillDeletingPositions();

  const isNonePositionsSelected = selectedPositions.length === 0;

  const closeMultiPositions = useCallback(() => {
    const successDeletionCallback = () => {
      // filter all position that are already expired
      const stillDeletingPositions = getStillDeletingPositions();

      // save position IDs with timestamps in order to prevent clicking its checkboxes for two minutes
      const positions = selectedPositions
        .map(({ positionId }) => ({ positionId, deletionStartedAt: Date.now() }))
        .concat(stillDeletingPositions);

      dispatch(saveDeletingPositions({ positions }));
    };

    dispatch(
      closeMultiplePositionsRequest({
        positionIds: selectedPositions.map((el) => el.positionId),
        callback: successDeletionCallback,
      }),
    );
  }, [dispatch, getStillDeletingPositions, selectedPositions]);

  const openCloseMultiPositionsModal = useCallback(() => {
    // TODO CFD おそらく、ETFかそれ以外かの判定で問題ないはず
    const isETF = serviceId === ETF;
    let currenciesTypes = [];
    if (isETF) {
      currenciesTypes = uniq(
        intersectionBy(tableDataRaw, selectedPositions, 'positionId').map(
          (el) => el.instrumentId.match(/USD|JPY/)?.[0],
        ),
      );
    }
    const isBothUSDAndJPY = currenciesTypes.length === 2;

    if (selectedPositions.length > CLOSE_POSITIONS_MAX_AMOUNT) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: '建玉が100件を超えています。建玉を100件以内にしてから発注してください。',
        }),
      );
    } else if (isETF && isBothUSDAndJPY) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: '日本市場銘柄と米国市場銘柄を同時に選択することはできません',
        }),
      );
    } else {
      dispatch(
        openConfirmationModal({
          title: '一括決済注文',
          bodyText: `選択した建玉の注文中の決済注文を取消し、全建玉数量を成行決済します。また稼働中の自動売買は停止いたします。よろしいですか？
						大量の決済を行う場合は画面上の建玉が消えるまでに時間がかかる場合がございます。`,
          callback: closeMultiPositions,
          buttonBackText: '戻る',
          buttonNextText: '決済',
          isOverlap: true,
        }),
      );
    }
  }, [closeMultiPositions, dispatch, selectedPositions, serviceId, tableDataRaw]);

  return (
    <CustomButton
      isSmall
      isDisabled={isNonePositionsSelected || tableMetaInfo.isLoading}
      onClick={openCloseMultiPositionsModal}
      className={styles.button}
      isBlack
    >
      一括決済
    </CustomButton>
  );
};

DeletePositionsButton.propTypes = {};

export default memo(DeletePositionsButton);
