import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OK_BUTTON_TEXT } from 'shared-modules/constants';
import { MY_PAGE_HOME_URL } from 'shared-modules/config';
import { replace } from '../../redux/actions';
import { Button } from '../../components';
import styles from './unsupportedBrowserPage.module.scss';

const UnsupportedBrowserPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(replace('/'));
  }, [dispatch]);

  const goToMyPage = useCallback(() => {
    window.close();
    window.location.replace(MY_PAGE_HOME_URL);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        ご利用のブラウザは動作サポート対象外のため、Microsoft EdgeまたはGoogle Chromeをご利用ください。
      </div>
      <Button className={styles.button} onClick={goToMyPage} secondary>
        {OK_BUTTON_TEXT}
      </Button>
    </div>
  );
};

export default memo(UnsupportedBrowserPage);
