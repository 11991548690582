import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ORDER_TYPES } from 'shared-modules/constants/manualTrade';
import { BUY_SELL_MAIN, BUY_SELL_VALUE, CFD } from 'shared-modules/constants';
import { formatNumberToDisplayedString, getServiceQuantityUnit, trimFrontZeros } from 'shared-modules/services';
import { useGetInstrumentDisplayName } from 'shared-modules/hooks';
import { useCalculateManualMargin } from 'shared-modules/services/hooks';
import styles from './marketOrder.module.scss';

const MarketOrder = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const quantityUnit = getServiceQuantityUnit(serviceId);
  const instrumentId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  const getInstrumentDisplayName = useGetInstrumentDisplayName();
  const isCrossOrder = useSelector((state) => state.settings[serviceId].isCrossOrder);
  const { ask, bid } = useSelector((state) => state.currencies.rates[instrumentId]);
  const { quantityUnitConvFactor } = useSelector((state) => state.settings.instrumentList[instrumentId]);

  const buySell = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.MARKET_ORDER.name][ORDER_TYPES.MARKET_ORDER.inputs.BUY_SELL],
  );
  const count = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.MARKET_ORDER.name][ORDER_TYPES.MARKET_ORDER.inputs.COUNT],
  );

  const price = useMemo(() => (buySell === BUY_SELL_MAIN.BUY.ID ? ask : bid), [buySell, bid, ask]);

  const calculatedMargin = useCalculateManualMargin(count, price);

  const isCFD = useMemo(() => serviceId === CFD, [serviceId]);

  return (
    <>
      {isCrossOrder && <div className={styles.headerRow}>新規</div>}
      <div className={styles.brandRow}>
        銘柄
        <span>{getInstrumentDisplayName(instrumentId, serviceId)}</span>
      </div>
      <div className={styles.orderTypeRow}>
        注文条件
        <span>{isCrossOrder ? '成行' : '成行(FIFO)'}</span>
      </div>
      <div className={styles.buySellRow}>
        売買
        <span>{BUY_SELL_VALUE[buySell]}</span>
      </div>
      <div className={styles.countRow}>
        <div>
          数量
          {isCFD && <div>1Lot={quantityUnitConvFactor}</div>}
        </div>
        <span className={styles.boldText}>{`${trimFrontZeros(count)}${quantityUnit}`}</span>
      </div>
      <div className={styles.calculatingMarginRow}>
        発注証拠金目安
        <div className={styles.boldText}>
          {formatNumberToDisplayedString({ value: calculatedMargin, withoutPlus: true })}
        </div>
      </div>
    </>
  );
};

export default memo(MarketOrder);
