import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BUILDER_PAGES } from 'shared-modules/constants/builder';
import { changeBuilderActivePage } from '../../../../redux/actions';
import { Progress } from '../../../../components';
import {
  BUILDERS_SHARE_PROGRESS_TITLE_STEP1,
  BUILDERS_SHARE_PROGRESS_TITLE_STEP2,
  BUILDERS_SHARE_PROGRESS_TITLE_STEP3,
} from '../../constants';
import { BuilderSelectPage } from './BuilderSelectPage';
import { ShareInputPage } from './ShareInputPage';
import { ShareConfirmPage } from './ShareConfirmPage';

const PAGES = [
  {
    id: BUILDER_PAGES.BUILDERS_SHARE_SELECT_PAGE.ID,
    title: BUILDERS_SHARE_PROGRESS_TITLE_STEP1,
    component: BuilderSelectPage,
  },
  {
    id: BUILDER_PAGES.BUILDERS_SHARE_INPUT_PAGE.ID,
    title: BUILDERS_SHARE_PROGRESS_TITLE_STEP2,
    component: ShareInputPage,
  },
  {
    id: BUILDER_PAGES.BUILDERS_SHARE_CONFIRM_PAGE.ID,
    title: BUILDERS_SHARE_PROGRESS_TITLE_STEP3,
    component: ShareConfirmPage,
  },
];

export const BuildersShareConfigPage = memo(() => {
  const dispatch = useDispatch();
  const activePage = useSelector((state) => state.webBuilder.activePage);
  const handleNotFound = useCallback(() => {
    dispatch(changeBuilderActivePage(BUILDER_PAGES.BUILDER_TYPE_PAGE.ID));
  }, [dispatch]);
  return <Progress pages={PAGES} currentPage={activePage} onNotFound={handleNotFound} />;
});
