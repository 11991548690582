import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CFD, ETF, FX } from 'shared-modules/constants';
import { useKeyDownHandler } from '../../../../services/hooks';
import { CfdInstrumentIcon, CustomFlag, EtfInstrumentIcon } from '../../../../components';
import styles from './currencyItem.module.scss';

const useBind = (callback, value) => useCallback(() => callback(value), [callback, value]);

export const CurrencyItem = memo(({ onClick, onFocus, onEnter, active, disabled, currency, hasFocus }) => {
  const { label, value } = currency || {};
  const serviceId = useSelector((state) => state.auth.serviceId);
  const handleClick = useBind(onClick, value);
  const handleFocus = useBind(onFocus, value);
  const handleEnter = useKeyDownHandler(useBind(onEnter, value));
  const [firstFlag, secondFlag] = label?.split('/') || [];

  const innerRef = useRef(null);

  useEffect(() => {
    if (hasFocus) {
      // Move element into view when it is focused
      innerRef.current.focus();
    }
  }, [hasFocus]);

  const content = useMemo(() => {
    if (serviceId === FX) {
      return (
        <>
          <CustomFlag className={styles.firstFlag} country={firstFlag} />
          <CustomFlag className={styles.secondFlag} country={secondFlag} />
        </>
      );
    }
    if (serviceId === ETF) {
      return <EtfInstrumentIcon className={styles.firstFlag} instrumentId={value} size="small" />;
    }
    if (serviceId === CFD) {
      return <CfdInstrumentIcon className={styles.firstFlag} instrumentId={value} size="small" />;
    }
    return null;
  }, [serviceId, firstFlag, secondFlag, value]);

  return (
    <div
      role="button"
      ref={innerRef}
      onClick={disabled ? null : handleClick}
      onFocus={disabled ? null : handleFocus}
      onKeyDown={disabled ? null : handleEnter}
      tabIndex={0}
      className={classNames(styles.container, { [styles.active]: active }, { [styles.disabled]: disabled })}
    >
      {content}
      <span className={styles.currency}>{label}</span>
    </div>
  );
});

CurrencyItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onEnter: PropTypes.func,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  currency: PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired }).isRequired,
  hasFocus: PropTypes.bool,
};

CurrencyItem.defaultProps = {
  hasFocus: false,
  disabled: false,
  onFocus() {},
  onEnter() {},
};
