import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { MODAL_SIZES, TOOLTIP_MESSAGE } from 'shared-modules/constants';
import { Modal } from '../../../../components';
import styles from './tradeInformation.module.scss';

export const TradeInformation = memo(() => {
  const [visible, setVisible] = useState(false);
  const handleOpen = useCallback(() => {
    setVisible(true);
  }, []);
  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.iconButton} role="button" tabIndex={0} onClick={handleOpen} aria-hidden>
        <i className={classNames('material-icons-outlined', styles.icon)}>help_outline</i>
      </div>
      {visible && (
        <Modal isOpen={visible} closeModal={handleClose} title="取引情報" size={MODAL_SIZES.WIDTH_710}>
          <div className={styles.content}>{TOOLTIP_MESSAGE.TRADE_INFORMATION}</div>
        </Modal>
      )}
    </div>
  );
});
