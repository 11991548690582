import Decimal from 'decimal.js';
import {
  FX,
  CHART_MAKE_DEFAULT_WIDTH,
  CHART_MAKE_CHANGE_TARGET_OPTIONS,
  SWAPPER,
  CHART_MAKE_BUY_SELL_MAIN,
} from '../../../../constants/index';
import { Range, RANGE_POSITION } from './Range';
import Ap from '../../Ap';
import { ceil, getBaseQuantity, getSignificantDigits } from '../../constants';
import Logic from './Logic';

const SwapperRange = (instrumentId, instrumentSetting, side, min, max, quantity, rangePosition) => {
  const range = Range(instrumentId, instrumentSetting, side, min, max, quantity, rangePosition);
  const digits = getSignificantDigits(FX, instrumentId, instrumentSetting?.pricePrecision);

  return {
    ...range,
    // override
    toAp(num, inputSide) {
      const entryPrice1 = ceil(Decimal.sub(range.getMax(), Decimal.mul(num, this.getWidthPrice())), digits);
      const counterPips = ceil(Decimal.mul(-1, Decimal.mul(range.getTp(), 1.5)), 1);
      return Ap(
        inputSide.ID,
        entryPrice1,
        null,
        range.getDispQuantity(),
        range.getSl(),
        range.getTp(),
        null,
        counterPips,
        null,
      );
    },
    ajustMin() {
      this.setMin(
        ceil(
          Decimal.sub(this.getMax(), Decimal.mul(Decimal.sub(this.getNumOfOrders(), 1), this.getWidthPrice())),
          digits,
        ),
      );
    },
    validateRangeWidth(rangeWidth, numOfOrders) {
      if (
        (numOfOrders === 1 && ceil(rangeWidth, 1) < CHART_MAKE_DEFAULT_WIDTH) ||
        ceil(Decimal.div(rangeWidth, Decimal.sub(numOfOrders, 1)), 1) < CHART_MAKE_DEFAULT_WIDTH
      ) {
        return {
          isValid: false,
          errorMessage: 'この設定では注文幅が狭くなり過ぎます。レンジ幅を広くするか本数を少なくして下さい。',
        };
      }
      return { isValid: true };
    },
    setNumOfOrdersAjustWidth(inputNumOfOrders) {
      const validateResult = this.validateRangeWidth(this.getRangeWidth(), inputNumOfOrders);
      if (!validateResult.isValid) {
        return validateResult;
      }
      this.setNumOfOrders(inputNumOfOrders);
      this.setWidth(
        this.getNumOfOrders() === 1
          ? this.getRangeWidth()
          : ceil(Decimal.div(this.getRangeWidth(), Decimal.sub(this.getNumOfOrders(), 1)), 1),
      );
      return { isValid: true };
    },
    calcAndSetNumOfOrders() {
      this.setNumOfOrders(
        ceil(Decimal.add(Decimal.div(Decimal.sub(this.getMax(), this.getMin()), this.getWidthPrice()), 1), 0),
      );
    },
    getMinRangeWidth(inputNumOfOrders) {
      return ceil(Decimal.mul(CHART_MAKE_DEFAULT_WIDTH, Decimal.sub(inputNumOfOrders, 1)), 1);
    },
  };
};

const Swapper = (instrumentId, inputHigh, inputLow, instrumentSetting) => {
  const digits = getSignificantDigits(FX, instrumentId, instrumentSetting?.pricePrecision);
  const high = ceil(inputHigh, digits);
  const low = ceil(inputLow, digits);
  const logic = Logic(SWAPPER, high, low);
  let width = 0;
  const range = SwapperRange(
    instrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.BUY,
    high,
    low,
    getBaseQuantity(FX, instrumentId),
    RANGE_POSITION.ISOLATION_BOTTOM,
  );
  const currentSettings = {
    width: null,
  };
  return {
    getNumOfOrders() {
      return range.getNumOfOrders();
    },
    getAllRanges() {
      return [range];
    },
    getRecalcResource(settings) {
      let targetRange; // 注文変更ターゲットレンジ
      const upperRanges = []; // ターゲットレンジより上のレンジ
      const lowerRanges = []; // ターゲットレンジより下のレンジ
      let newNumOfOrders; // 合計本数バリデーション用 ターゲットレンジの注文本数
      let otherNumOfOrders; // 合計本数バリデーション用 ターゲットレンジ以外の注文本数
      // ターゲットのレンジ別の処理
      switch (settings.target) {
        case CHART_MAKE_CHANGE_TARGET_OPTIONS[FX][SWAPPER][0].id:
          otherNumOfOrders = 0;
          newNumOfOrders = settings.recalc.numOfOrders;
          targetRange = range;
          break;
        default:
          return null;
      }
      return {
        isValid: true,
        targetRange,
        upperRanges,
        lowerRanges,
        newNumOfOrders,
        otherNumOfOrders,
      };
    },
    backupCurrentSettings() {
      currentSettings.width = width;
      range.backupCurrentSettings();
    },
    rollbackSettings() {
      width = currentSettings.width;
      range.rollbackSettings();
    },
    backupDefaultSettings() {
      range.backupDefaultSettings();
    },
    calcAndSetRangeSettings(hendou, step) {
      // 近似1日変動値幅とstepでレンジの幅、その幅から本数を計算
      this.calcAndSetWidth(hendou, step);
      this.calcAndSetNumOfOrders();
    },
    calcAndSetWidth(hendouPips, step) {
      width = Math.max(ceil(Decimal.div(hendouPips, Decimal.sub(2, step)), 1), CHART_MAKE_DEFAULT_WIDTH);
      range.setWidth(width);
    },
    calcAndSetNumOfOrders() {
      range.calcAndSetNumOfOrders();
    },
    calcAndSetMaxMin() {
      range.setMax(ceil(high, digits));
      range.ajustMin();
    },
    calcAfterFixedWidthProcess() {
      this.calcAndSetMaxMin();
    },
    ...logic,
    createUnsortedAps() {
      const area1 = {
        max: range.getMax(),
        min: ceil(
          Decimal.sub(range.getMax(), Decimal.mul(Decimal.sub(range.getMax(), range.getMin()), Decimal.div(1, 3))),
          digits,
        ),
      };
      const area2 = {
        max: area1.min,
        min: ceil(
          Decimal.sub(range.getMax(), Decimal.mul(Decimal.sub(range.getMax(), range.getMin()), Decimal.div(2, 3))),
          digits,
        ),
      };
      const area3 = {
        max: area2.min,
        min: ceil(Decimal.sub(range.getMax(), Decimal.sub(range.getMax(), range.getMin())), digits),
      };
      const zoneAps = range.createAps();
      const result = zoneAps.map((x) => {
        const item = { ...x };
        if (area2.max >= item.entryPrice1 && area2.min < item.entryPrice1) {
          item.quantity = Decimal.mul(item.quantity, 2).toNumber();
        } else if (area3.max >= item.entryPrice1) {
          item.quantity = Decimal.mul(item.quantity, 3).toNumber();
        }
        return item;
      });
      return result;
    },
  };
};

export default Swapper;
