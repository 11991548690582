import { get, invokeApi, createAdditionalParams } from './index';

export const getParamList = (params) => {
  const paramList = [];

  // eslint-disable-next-line
  for (const [key, value] of Object.entries(params)) {
    paramList.push(`${key}=${value}`);
  }

  return paramList;
};

export const getOrder = async (serviceId, orderId) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders/${orderId}`, get, additionalParams);
};

export const getOrders = async (serviceId, params) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/orders?${getParamList(params).join('&')}`, get, additionalParams);
};
