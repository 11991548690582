import { memo } from 'react';
import { useSelector } from 'react-redux';
import { BUY_SELL_MAIN } from 'shared-modules/constants';
import { useSelectedStrategy } from 'shared-modules/hooks';
import AutoSelectDetailChart from '../../../../appWrappers/ModalContainer/components/AutoSelectDetails/components/AutoSelectDetailChart';
import { useErrorText } from '../../../../hooks';

export const SelectionChart = memo(() => {
  const termId = useSelector((state) => state.autoSelect.selectedTermId);
  const [strategy] = useSelectedStrategy();
  const { selectionId, selectionVersion, serviceId, instrumentId, strategyId } = strategy || {};
  const errorText = useErrorText(serviceId);
  if (!termId || !serviceId || !instrumentId || !strategyId || !selectionId || !selectionVersion) {
    return null;
  }
  if (errorText) {
    return errorText;
  }
  return (
    <AutoSelectDetailChart
      key={serviceId}
      termId={termId}
      selectionId={selectionId}
      selectionVersion={selectionVersion}
      serviceId={serviceId}
      selectedInstrumentId={instrumentId}
      selectedStrategyId={strategyId}
      selectedSide={BUY_SELL_MAIN.BUY.CHART_ID}
      autoSelectOrderPositions
    />
  );
});
