/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Decimal from 'decimal.js';
import { toHalfwidthKana } from 'japanese-string-utils';

import { sendErrorToBugsnag } from '../../redux/actions/errorActions';
import {
  BUY_RANGE,
  COUNT_INPUT_NAME,
  COUNTRY_TYPE,
  CORE_RANGE,
  CORE_RANGER,
  CORE_RANGER_BEAR,
  CORE_RANGER_BULL,
  CHART_MAKE_CHANGE_TARGET_OPTIONS,
  FX,
  ETF,
  CFD,
  HALF,
  HEDGER,
  LOSS_CUT_WIDTH_NAME,
  NUMBER_VALIDATION_REG_ALLOW_NEGATIVE,
  PROFIT_MARGIN_NAME,
  PROTECTOR,
  RANGE,
  SWAPPER,
  TRADE_METHODS,
  VALIDATION_ERROR_INVALID_VALUE,
  VALIDATION_ERROR_EMPTY_FIELD,
  ZONE_PROTECTOR,
  ZONE,
  CHART_MAKE_RANGE_ID,
  SELL_RANGE,
  SELL_HEDGE,
  BUY_ZONE,
  ITEMS_COUNT,
  PRICE_RANGE,
  RANGE_WIDTH,
  CROSS_ORDER_ITEMS_COUNT_MIN,
  ONE_SIDE_ORDER_ITEMS_COUNT_MIN,
  CHART_MAKE_BUY_SELL_MAIN,
  CHART_MAKE_FUND_INDICATION_TOOLTIP,
  RANGE_WIDTH_INFO,
  REQUIRED_MARGIN_TOOLTIP,
  REQUIRED_MARGIN_LABEL,
} from '../../constants';
import {
  BASE_PRICE_MAX_MULTIPLIER,
  BASE_PRICE_MIN_MULTIPLIER,
  COMMON_PIPS_MIN_VALUE,
  ETF_JPY_PRICE_MIN,
  ETF_JPY_PRICE_STEP,
  ETF_NOT_JPY_PRICE_MIN,
  ETF_NOT_JPY_PRICE_STEP,
  PROFIT_MARGIN_STEP,
  QUANTITY_MAX_VALUE,
  RANGE_ETF_JPY_SPREAD_STEP,
  RANGE_ETF_NOT_JPY_SPREAD_STEP,
  RANGE_SPREAD_STEP,
  STOP_LOSS_MAX_VALUE,
  STOP_LOSS_STEP,
  RANGE_CFD_SPREAD_STEP,
  CFD_STOP_LOSS_MAX_VALUE,
  CFD_PRICE_STEP,
  CFD_PRICE_MIN,
} from '../../constants/builder';
import {
  getAPQuantityStep,
  getBuilderPriceStep,
  getServiceQuantityUnit,
  roundToFixedPrecision,
  getBuilderRoundedOptionValue,
} from '../index';
import { decimalRounding, PRICE_CHART_COLORS } from '../builder';
import { useInstrumentSettings } from './index';
import { useBuilderBasePrice, useBuilderPricePrecision, useBuilderFluctuationRange, usePipConversion } from './builder';
import { useGetChartMakeOrderSettingData } from './chartMake';
import { addBuilderOrderSettingsList } from '../../redux/actions/builderActions';
import { getCurrencyUnit, getCurrencyUnitByServiceId } from '../../utils';
import {
  createCfdLogic,
  createEtfLogic,
  createFxLogic,
  summarizeDrawingData,
} from '../chartMakeLogic/LogicRecommender';

const SIDE = 'side';
const VALID = 'valid';
const LOSS_CUT_WIDTH_CHECK = 'lossCutWidthCheck';

export const getPriceStep = (instrumentId, serviceId) => {
  if (serviceId === FX) {
    return getBuilderPriceStep(instrumentId);
  }
  if (serviceId === ETF) {
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return ETF_JPY_PRICE_STEP;
    }
    return ETF_NOT_JPY_PRICE_STEP;
  }
  if (serviceId === CFD) {
    return CFD_PRICE_STEP;
  }
  return 0.01;
};

export const getRangeStep = (instrumentId, serviceId) => {
  if (serviceId === FX) {
    return RANGE_SPREAD_STEP;
  }
  if (serviceId === ETF) {
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return RANGE_ETF_JPY_SPREAD_STEP;
    }
    return RANGE_ETF_NOT_JPY_SPREAD_STEP;
  }
  if (serviceId === CFD) {
    return RANGE_CFD_SPREAD_STEP;
  }
  return 1;
};

export const getProfitMarginStep = (serviceId, priceStep) => {
  return serviceId === FX ? PROFIT_MARGIN_STEP : priceStep;
};

export const getStopLossStep = (serviceId, priceStep) => {
  return serviceId === FX ? STOP_LOSS_STEP : priceStep;
};

export const getRangeSpreadMaxValue = ({ fluctuationRange, pipsConversion, pipsPrecision }) => {
  return decimalRounding(Decimal.mul(fluctuationRange, 3).div(pipsConversion), pipsPrecision);
};

const getStopLossMax = (instrumentId) => {
  if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
    return -30; // quote currency JPY
  }

  return -3.5; // quote currency USD
};

export const getStopLossRange = ({
  basePrice,
  pipsPrecision,
  pricePrecision,
  pipsConversion,
  serviceId,
  instrumentId,
}) => {
  const multipliedBasePrice = decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER), pricePrecision);
  const min = decimalRounding(Decimal.sub(0, multipliedBasePrice).div(pipsConversion), pipsPrecision);

  let max = STOP_LOSS_MAX_VALUE;
  if (serviceId === ETF) {
    max = getStopLossMax(instrumentId);
  }
  if (serviceId === CFD) {
    max = CFD_STOP_LOSS_MAX_VALUE;
  }

  return [min, max];
};

export const getStartPriceRange = ({ basePrice, pricePrecision, serviceId, rangeSpread }) => {
  const min = decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER), pricePrecision);

  let max = decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MAX_MULTIPLIER), pricePrecision);
  if (serviceId !== FX) {
    max = decimalRounding(Decimal.add(basePrice, Number(rangeSpread)), pricePrecision);
  }

  return [min, max];
};

export const getChangeTargetId = (serviceId, selectLogicName, changeTargetActiveValue) => {
  const changeTargets = CHART_MAKE_CHANGE_TARGET_OPTIONS[serviceId]?.[selectLogicName];
  return changeTargets.find((option) => option.value === changeTargetActiveValue)?.id;
};

export const getMaxQuantityForValidate = (logicType, baseQuantity, quantityPrecision) => {
  return logicType === SWAPPER || logicType === ZONE_PROTECTOR
    ? Math.floor(Decimal.div(baseQuantity, 3).toNumber() * (1 / quantityPrecision)) / (1 / quantityPrecision)
    : baseQuantity;
};

const getLogic = ({ serviceId, selectLogicName, drawingDataSummary, instrumentId, instrumentSetting }) => {
  let logicClass = null;
  let ranges = {};

  if (serviceId === FX) {
    logicClass = createFxLogic(selectLogicName, drawingDataSummary, instrumentId, instrumentSetting);
  } else if (serviceId === ETF) {
    logicClass = createEtfLogic(selectLogicName, drawingDataSummary, instrumentId, instrumentSetting);
  } else if (serviceId === CFD) {
    logicClass = createCfdLogic(selectLogicName, drawingDataSummary, instrumentId, instrumentSetting);
  }

  const allRange = logicClass?.getAllRanges();

  if (!allRange) {
    return { logicClass, ranges };
  }

  if (selectLogicName === CORE_RANGER) {
    ranges = {
      [CHART_MAKE_RANGE_ID[CORE_RANGER][SELL_RANGE]]: allRange[0],
      [CHART_MAKE_RANGE_ID[CORE_RANGER][CORE_RANGE]]: allRange[1],
      [CHART_MAKE_RANGE_ID[CORE_RANGER][BUY_RANGE]]: allRange[2],
    };
  } else if (selectLogicName === HALF) {
    ranges = {
      [CHART_MAKE_RANGE_ID[HALF][SELL_RANGE]]: allRange[0],
      [CHART_MAKE_RANGE_ID[HALF][BUY_RANGE]]: allRange[1],
    };
  } else if (selectLogicName === CORE_RANGER_BEAR) {
    ranges = {
      [CHART_MAKE_RANGE_ID[CORE_RANGER_BEAR][SELL_RANGE]]: allRange[0],
      [CHART_MAKE_RANGE_ID[CORE_RANGER_BEAR][CORE_RANGE]]: allRange[1],
    };
  } else if (selectLogicName === CORE_RANGER_BULL) {
    ranges = {
      [CHART_MAKE_RANGE_ID[CORE_RANGER_BULL][BUY_RANGE]]: allRange[0],
      [CHART_MAKE_RANGE_ID[CORE_RANGER_BULL][CORE_RANGE]]: allRange[1],
    };
  } else if (selectLogicName === SWAPPER) {
    ranges = {
      [CHART_MAKE_RANGE_ID[SWAPPER][RANGE]]: allRange[0],
    };
  } else if (selectLogicName === PROTECTOR) {
    ranges = {
      [CHART_MAKE_RANGE_ID[PROTECTOR][ZONE]]: allRange[0],
    };
  } else if (selectLogicName === ZONE_PROTECTOR) {
    ranges = {
      [CHART_MAKE_RANGE_ID[ZONE_PROTECTOR][ZONE]]: allRange[0],
    };
  } else if (selectLogicName === HEDGER) {
    ranges = {
      [CHART_MAKE_RANGE_ID[HEDGER][BUY_ZONE]]: allRange[0],
      [CHART_MAKE_RANGE_ID[HEDGER][SELL_HEDGE]]: allRange[1],
    };
  } else {
    throw new Error('ロジックが見つかりませんでした。');
  }

  return { logicClass, ranges };
};

const getRecalcSettings = ({ logicClass, changeTargetRangeId, startPrice, rangeWidth, numOfOrders }) => {
  const recalcSettings = {
    target: changeTargetRangeId,
    recalc: {
      startPrice: Number(startPrice),
      rangeWidth: Number(rangeWidth),
      numOfOrders: Number(numOfOrders),
    },
    createAps: null,
  };
  return logicClass.recalc(recalcSettings);
};

const getColor = (side) => {
  if (side === CHART_MAKE_BUY_SELL_MAIN.BUY.ID) return PRICE_CHART_COLORS.MAGENTA;
  if (side === CHART_MAKE_BUY_SELL_MAIN.SELL.ID) return PRICE_CHART_COLORS.SUMMER_SKY;
  if (side === CHART_MAKE_BUY_SELL_MAIN.STRADDLE.ID) return '#A2E7A7';
  return PRICE_CHART_COLORS.TRANSPARENT;
};

export const useDynamicReadOnlyAmountSettings = ({
  itemsCount,
  amount,
  logicType,
  setReadOnlyAmountSettings,
  getNewReadOnlyAmountSettings,
}) => {
  const prevItemsCountRef = useRef(itemsCount);
  useEffect(() => {
    if (![SWAPPER, ZONE_PROTECTOR].includes(logicType)) {
      return;
    }
    if (itemsCount !== prevItemsCountRef.current) {
      if (itemsCount <= 1) {
        setReadOnlyAmountSettings(null);
      } else if (itemsCount < 4) {
        const newReadOnlyAmountSettings = getNewReadOnlyAmountSettings([...Array(itemsCount - 1)], amount);
        setReadOnlyAmountSettings(newReadOnlyAmountSettings);
      }
      prevItemsCountRef.current = itemsCount;
    }
  }, [logicType, itemsCount, amount, setReadOnlyAmountSettings, getNewReadOnlyAmountSettings]);
};

const useChartMakeInfo = ({ store, sketchData, selectLogicName }) => {
  const dispatch = useDispatch();
  // Define prerequisite variables
  const { activeCurrency: instrumentId } = store.getState().builder;

  // TODO deprecated use useSelector((state) => state.settings)
  const parentSettings = store.getState().settings;
  const { instrumentList } = parentSettings;
  // TODO CFD この時点で instrumentList が存在しないことがあるかどうか要確認
  const serviceId = instrumentList?.[instrumentId]?.serviceId;

  // Define values for internal processing

  const { quantityPrecision, pricePrecision, buyQuantityMin, buyQuantityMax, sellQuantityMin, sellQuantityMax } =
    useInstrumentSettings(instrumentId, TRADE_METHODS.AP.ID);

  const {
    pipsPrecision,
    pricePrecision: builderPricePrecision,
    initialPrecision,
    initialPipsPrecision,
  } = useBuilderPricePrecision();

  const currencyName = useMemo(
    () => (serviceId === FX ? instrumentId : toHalfwidthKana(instrumentList[instrumentId]?.shortName)),
    [serviceId, instrumentId, instrumentList],
  );

  // Define value for logic

  const priceArr = useMemo(
    () => sketchData.map((bar) => Number(roundToFixedPrecision(bar.value, pricePrecision))),
    [sketchData, pricePrecision],
  );

  const drawingDataSummary = useMemo(
    () =>
      summarizeDrawingData(
        priceArr.map((price) => ({ value: price })),
        instrumentId,
        instrumentList[instrumentId],
        serviceId,
      ),
    [priceArr, instrumentId, instrumentList, serviceId],
  );
  const { high: priceHigh, low: priceLow, modePrice } = drawingDataSummary;

  //  Define other variables
  const logicRef = useRef(
    getLogic({
      serviceId,
      selectLogicName,
      drawingDataSummary,
      instrumentId,
      instrumentSetting: instrumentList[instrumentId],
    }),
  );

  const getSettingsValue = useCallback(
    (settings) => {
      if (!settings) return {};

      return {
        priceRange: Number(roundToFixedPrecision(settings.startPrice, pricePrecision)),
        rangeWidth: Number(getBuilderRoundedOptionValue(settings.rangeWidth, pipsPrecision)),
        itemsCount: settings.numOfOrders,
        quantity: settings.quantity,
        profitMarginValue: typeof settings.tp === 'number' ? [settings.tp] : settings.tp,
        lossCutWidthValue: settings.sl || 0,
        max: settings.max,
        min: settings.min,
      };
    },
    [pricePrecision, pipsPrecision],
  );

  // Definition of the value to be displayed on the screen
  const changeTargetOptions = CHART_MAKE_CHANGE_TARGET_OPTIONS[serviceId]?.[selectLogicName];
  const [changeTarget, setChangeTarget] = useState(changeTargetOptions[0].value);
  const changeTargetIdRef = useRef(changeTargetOptions[0].id);
  const [priceRange, setPriceRange] = useState(0);
  const [priceRangeInfoMessage, setPriceRangeInfoMessage] = useState('');
  const [rangeWidth, setRangeWidth] = useState(() => {
    const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
    return getSettingsValue(logicRef.current.logicClass.getSettings(changeTargetRangeId)).rangeWidth;
  });
  const [itemsCount, setItemsCount] = useState(0);
  const [itemsCountInfoMessage, setItemsCountInfoMessage] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [readOnlyAmountSettings, setReadOnlyAmountSettings] = useState(null);
  const [profitMarginValue, setProfitMarginValue] = useState([0]);
  const [lossCutWidthValue, setLossCutWidthValue] = useState(0);
  const [lossCutWidthCheck, setLossCutWidthCheck] = useState(false);
  const [rangeAreaAllPrice, setRangeAreaAllPrice] = useState([]);
  const orderSettings = useSelector((state) => state.builder.orderSettingsList);
  const [errorsArray, changeErrorsArray] = useState([]);
  const [recalcErrorMessage, setRecalcErrorMessage] = useState(null);

  const [isAddCartAndRunNowDisabled, setIsAddCartAndRunNowDisabled] = useState(false);
  const [isReCalcDisabled, setIsReCalcDisabled] = useState(true);
  const [isInitValidation, setIsInitValidation] = useState(false);

  // Define the value to be used when changing the value displayed on the screen

  const isChartDataLoading = false;

  // price
  const priceStep = useMemo(() => {
    if (serviceId === FX) {
      return getBuilderPriceStep(instrumentId);
    }
    if (serviceId === ETF) {
      if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
        return ETF_JPY_PRICE_STEP;
      }
      return ETF_NOT_JPY_PRICE_STEP;
    }
    if (serviceId === CFD) {
      return CFD_PRICE_STEP;
    }
    return 0.01;
  }, [serviceId, instrumentId]);

  const rangeSide = useMemo(() => {
    if (logicRef.current) {
      const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
      if (logicRef.current.ranges[changeTargetRangeId]?.getSide) {
        return logicRef.current.ranges[changeTargetRangeId]?.getSide().ID;
      }
    }
    return -1;
  }, [selectLogicName, changeTarget, serviceId]);

  const basePrice = useBuilderBasePrice();

  const entryPriceMinValue = useMemo(
    () => decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER), builderPricePrecision),
    [basePrice, builderPricePrecision],
  );
  const entryPriceMaxValue = useMemo(() => {
    if (serviceId !== FX) {
      return decimalRounding(Decimal.add(basePrice, Number(rangeWidth)), builderPricePrecision);
    }
    return decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MAX_MULTIPLIER), builderPricePrecision);
  }, [basePrice, builderPricePrecision, rangeWidth, serviceId]);

  const priceUnit = useMemo(() => (serviceId !== ETF ? '' : getCurrencyUnit(instrumentId)), [serviceId, instrumentId]);

  const priceStepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? builderPricePrecision : initialPipsPrecision;
    const innerCurrencyUnit = serviceId === FX ? '' : priceUnit;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${innerCurrencyUnit}${unitText}`;
  }, [serviceId, priceUnit, builderPricePrecision, initialPipsPrecision]);

  // range
  const rangeStep = useMemo(() => {
    if (serviceId === FX) {
      return RANGE_SPREAD_STEP;
    }
    if (serviceId === ETF) {
      if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
        return RANGE_ETF_JPY_SPREAD_STEP;
      }
      return RANGE_ETF_NOT_JPY_SPREAD_STEP;
    }
    if (serviceId === CFD) {
      return RANGE_CFD_SPREAD_STEP;
    }
    return 1;
  }, [serviceId, instrumentId]);

  const quantityStep = useMemo(() => {
    if (serviceId === FX) return getAPQuantityStep(instrumentId);
    return quantityPrecision;
  }, [instrumentId, serviceId, quantityPrecision]);

  const pipsConversion = usePipConversion(instrumentId);
  const fluctuationRange = useBuilderFluctuationRange();

  const rangeSpreadMaxValue = useMemo(
    () => decimalRounding(Decimal.mul(fluctuationRange, 3).div(pipsConversion), pipsPrecision),
    [fluctuationRange, pipsConversion, pipsPrecision],
  );

  const quantityUnit = getServiceQuantityUnit(serviceId);
  const currencyUnit = getCurrencyUnitByServiceId(serviceId, instrumentId);

  const stepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? pipsPrecision : initialPipsPrecision;
    const innerCurrencyUnit = serviceId === FX ? '' : currencyUnit;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${innerCurrencyUnit}${unitText}`;
  }, [serviceId, currencyUnit, pipsPrecision, initialPipsPrecision]);

  // quantity
  const [quantityMinValue, quantityMaxValue, step] = useMemo(() => {
    if (serviceId === FX) {
      return [
        quantityStep,
        getMaxQuantityForValidate(selectLogicName, QUANTITY_MAX_VALUE, quantityPrecision),
        quantityStep,
      ];
    }
    // TODO CFD ETF と同じで良いか要確認
    switch (rangeSide) {
      case CHART_MAKE_BUY_SELL_MAIN.SELL.ID:
        return [
          sellQuantityMin,
          getMaxQuantityForValidate(selectLogicName, sellQuantityMax, quantityPrecision),
          quantityPrecision,
        ];
      case CHART_MAKE_BUY_SELL_MAIN.BUY.ID:
        return [
          buyQuantityMin,
          getMaxQuantityForValidate(selectLogicName, buyQuantityMax, quantityPrecision),
          quantityPrecision,
        ];
      case CHART_MAKE_BUY_SELL_MAIN.STRADDLE.ID:
        return [
          Math.max(buyQuantityMin, sellQuantityMin),
          getMaxQuantityForValidate(selectLogicName, Math.min(buyQuantityMax, sellQuantityMax), quantityPrecision),
          quantityPrecision,
        ];
      default:
        return [
          buyQuantityMin,
          getMaxQuantityForValidate(selectLogicName, buyQuantityMax, quantityPrecision),
          quantityPrecision,
        ];
    }
  }, [
    serviceId,
    buyQuantityMin,
    buyQuantityMax,
    rangeSide,
    sellQuantityMin,
    sellQuantityMax,
    quantityPrecision,
    quantityStep,
    selectLogicName,
  ]);

  // profitmargin
  const profitMarginMinValue = useMemo(() => {
    if (serviceId === FX) {
      return COMMON_PIPS_MIN_VALUE;
    }
    if (serviceId === ETF) {
      if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
        return ETF_JPY_PRICE_MIN;
      }
      return ETF_NOT_JPY_PRICE_MIN;
    }
    if (serviceId === CFD) {
      return CFD_PRICE_MIN;
    }

    return 0.1;
  }, [serviceId, instrumentId]);

  // TODO 意味ある？
  const profitMarginMaxValue = useMemo(() => {
    return rangeWidth;
  }, [rangeWidth]);

  // losscut
  const [stopLossMinValue, stopLossMaxValue] = useMemo(
    () =>
      getStopLossRange({
        basePrice,
        pipsPrecision,
        pricePrecision: builderPricePrecision,
        pipsConversion,
        serviceId,
        instrumentId,
      }),
    [basePrice, builderPricePrecision, instrumentId, pipsConversion, pipsPrecision, serviceId],
  );

  // Define variables used for screen display shaping process

  const { fundIndication, requiredMargin } = useGetChartMakeOrderSettingData(logicRef?.current?.logicClass);
  const allRangeValidationRef = useRef();

  // Definition of a function that changes a value
  const changeErrorsArrayHandler = useCallback(
    (error, inputName) => {
      const excludeInputErrorsArray = errorsArray.filter((err) => err.inputName !== inputName);

      if (error) {
        changeErrorsArray([...excludeInputErrorsArray, error]);

        const targetRangeValidRef = allRangeValidationRef.current.find((a) => a[SIDE] === changeTargetIdRef.current);
        targetRangeValidRef[VALID] = [...excludeInputErrorsArray, error];
      } else {
        changeErrorsArray(excludeInputErrorsArray);
        const targetRangeValidRef = allRangeValidationRef.current.find((a) => a[SIDE] === changeTargetIdRef.current);
        targetRangeValidRef[VALID] = excludeInputErrorsArray;
        if (isInitValidation) {
          setIsInitValidation(false);
        }
      }
    },
    [errorsArray, isInitValidation],
  );

  // refs
  const allRangeDefaultSettingsRef = useRef({});
  const allRangeSideMenuRef = useRef({});

  const onChangeSideMenuRef = (newVal, inputName) => {
    const targetRangeRef = allRangeSideMenuRef.current.find((r) => r.side === changeTargetIdRef.current);
    targetRangeRef[inputName] = newVal;
  };

  const onChangeTargetHandler = useCallback(
    (newChangeTarget) => {
      const previousTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);

      const recalcSettings = {
        target: previousTargetRangeId, // 変更対象レンジ
        recalc: {
          startPrice: Number(priceRange),
          rangeWidth: Number(rangeWidth),
          numOfOrders: Number(itemsCount),
        },
        createAps: {
          quantity: Number(quantity),
          tp: serviceId === FX ? Number(profitMarginValue) : profitMarginValue.map((tp) => Number(tp)),
          sl: lossCutWidthValue === '' || !lossCutWidthCheck ? null : Number(lossCutWidthValue),
        },
      };

      const { logicClass } = logicRef.current;
      logicClass.recalc(recalcSettings);

      const index = allRangeSideMenuRef.current.findIndex((s) => s.side === previousTargetRangeId);
      allRangeSideMenuRef.current[index][LOSS_CUT_WIDTH_CHECK] = lossCutWidthCheck;

      setChangeTarget(newChangeTarget);
      const selectedSideId = changeTargetOptions.find((o) => o.label === newChangeTarget)?.id;
      const newRangeAreaAllPrice = [...rangeAreaAllPrice].map((range) => {
        if (range.side === selectedSideId) {
          return {
            ...range,
            isActive: true,
          };
        }
        return { ...range, isActive: false };
      });
      setRangeAreaAllPrice(newRangeAreaAllPrice);
    },
    [
      changeTarget,
      itemsCount,
      lossCutWidthValue,
      lossCutWidthCheck,
      priceRange,
      profitMarginValue,
      rangeWidth,
      selectLogicName,
      serviceId,
      quantity,
      changeTargetOptions,
      rangeAreaAllPrice,
    ],
  );

  const onChangePriceRangeHandler = useCallback(
    (newPriceRange) => {
      const roundedNewPriceRange = getBuilderRoundedOptionValue(newPriceRange, builderPricePrecision);

      setPriceRange(roundedNewPriceRange);
      onChangeSideMenuRef(roundedNewPriceRange, PRICE_RANGE);

      const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
      const { logicClass } = logicRef.current;
      const result = getRecalcSettings({
        logicClass,
        changeTargetRangeId,
        startPrice: Number(roundedNewPriceRange),
        rangeWidth,
        numOfOrders: itemsCount,
      });

      if (result?.isValid === false) {
        const error = {
          inputName: PRICE_RANGE,
          errorMessage: result.errorMessage,
        };
        changeErrorsArrayHandler(error, PRICE_RANGE);
      } else {
        const allRangeSettings = logicClass.getAllSettings();
        const selectedSideId = changeTargetOptions.find((o) => o.label === changeTarget)?.id;

        setRangeAreaAllPrice(
          allRangeSettings.map((rangeSet) => ({
            max: Number(roundToFixedPrecision(rangeSet.max, pricePrecision)),
            min: Number(roundToFixedPrecision(rangeSet.min, pricePrecision)),
            side: rangeSet.side,
            color: getColor(rangeSet.side),
            isActive: selectedSideId === rangeSet.side,
          })),
        );
      }
    },
    [
      changeTarget,
      selectLogicName,
      serviceId,
      pricePrecision,
      builderPricePrecision,
      changeErrorsArrayHandler,
      changeTargetOptions,
      rangeWidth,
      itemsCount,
    ],
  );

  const onChangeRangeWidthHandler = useCallback(
    (newRangeWidth) => {
      const roundedNewRangeWidth = getBuilderRoundedOptionValue(newRangeWidth, pipsPrecision);

      setRangeWidth(roundedNewRangeWidth);
      onChangeSideMenuRef(roundedNewRangeWidth, RANGE_WIDTH);

      const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
      const { logicClass } = logicRef.current;

      getRecalcSettings({
        logicClass,
        changeTargetRangeId,
        startPrice: priceRange,
        rangeWidth: Number(roundedNewRangeWidth),
        numOfOrders: itemsCount,
      });

      const allRangeSettings = logicClass.getAllSettings();

      const selectedSideId = changeTargetOptions.find((o) => o.label === changeTarget)?.id;

      setRangeAreaAllPrice(
        allRangeSettings.map((rangeSet) => ({
          max: Number(roundToFixedPrecision(rangeSet.max, pricePrecision)),
          min: Number(roundToFixedPrecision(rangeSet.min, pricePrecision)),
          side: rangeSet.side,
          color: getColor(rangeSet.side),
          isActive: selectedSideId === rangeSet.side,
        })),
      );
    },
    [
      changeTarget,
      selectLogicName,
      serviceId,
      priceRange,
      pipsPrecision,
      pricePrecision,
      itemsCount,
      changeTargetOptions,
    ],
  );

  const onChangeItemsCountHandler = useCallback(
    (newItemsCount) => {
      const roundedNewItemsCount = getBuilderRoundedOptionValue(newItemsCount, 0);
      setItemsCount(roundedNewItemsCount);
      onChangeSideMenuRef(roundedNewItemsCount, ITEMS_COUNT);

      const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
      const { logicClass } = logicRef.current;
      getRecalcSettings({
        logicClass,
        changeTargetRangeId,
        startPrice: priceRange,
        rangeWidth,
        numOfOrders: Number(roundedNewItemsCount),
      });
    },
    [changeTarget, selectLogicName, serviceId, priceRange, rangeWidth],
  );

  const getNewReadOnlyAmountSettings = useCallback(
    (oldReadOnlyAmountSettings, qty) => {
      return oldReadOnlyAmountSettings?.map((_, i) =>
        getBuilderRoundedOptionValue(qty * (i + 2), -Math.log10(quantityPrecision)),
      );
    },
    [quantityPrecision],
  );

  const onChangeQuantityHandler = useCallback(
    (val) => {
      const newValue = getBuilderRoundedOptionValue(val, -Math.log10(quantityPrecision));
      setQuantity(newValue);
      onChangeSideMenuRef(newValue, COUNT_INPUT_NAME);

      const newReadOnlyAmountSettings = getNewReadOnlyAmountSettings(readOnlyAmountSettings, newValue);
      setReadOnlyAmountSettings(newReadOnlyAmountSettings);
    },
    [quantityPrecision, readOnlyAmountSettings, getNewReadOnlyAmountSettings],
  );

  useDynamicReadOnlyAmountSettings({
    itemsCount,
    amount: quantity,
    logicType: selectLogicName,
    setReadOnlyAmountSettings,
    getNewReadOnlyAmountSettings,
  });

  const onChangeProfitMarginHandler = useCallback(
    (newProfitMarginValue, index) => {
      const newProfitMarginValues = [...profitMarginValue];
      newProfitMarginValues.splice(index, 1, getBuilderRoundedOptionValue(newProfitMarginValue, pipsPrecision));

      setProfitMarginValue(newProfitMarginValues);
      onChangeSideMenuRef(newProfitMarginValues, PROFIT_MARGIN_NAME);
    },
    [profitMarginValue, pipsPrecision],
  );

  const onChangeLossCutWidthHandler = useCallback(
    (val) => {
      const newValue = getBuilderRoundedOptionValue(val, pipsPrecision);
      setLossCutWidthValue(newValue);
      onChangeSideMenuRef(newValue, LOSS_CUT_WIDTH_NAME);
    },
    [pipsPrecision],
  );

  // validate function block
  const validatePriceRange = useCallback(
    (value) => {
      let entryPriceError = null;

      if (value === '') {
        entryPriceError = { inputName: PRICE_RANGE, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        entryPriceError = { inputName: PRICE_RANGE, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < entryPriceMinValue ||
        Number(value) > entryPriceMaxValue ||
        (initialPrecision >= 1 && Number(value) % initialPrecision !== 0)
      ) {
        entryPriceError = {
          inputName: PRICE_RANGE,
          errorMessage: `価格は${entryPriceMinValue}${priceUnit}以上、${entryPriceMaxValue}${priceUnit}以下、${priceStepValue}まででご設定ください。`, // eslint-disable-line
        };
      }

      return { error: entryPriceError, inputName: PRICE_RANGE };
    },
    [entryPriceMinValue, entryPriceMaxValue, initialPrecision, priceStepValue, priceUnit],
  );

  const validateRangeWidth = useCallback(
    (value) => {
      const { logicClass } = logicRef.current;

      const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
      const minRangeWidthOrErrorObject = logicClass?.getMinRangeWidth
        ? logicClass?.getMinRangeWidth(changeTargetRangeId, Number(itemsCount))
        : 100; // TODO: ETFの最低レンジはどこから
      let rangeWidthError = null;

      if (typeof minRangeWidthOrErrorObject === 'number') {
        if (value === '') {
          rangeWidthError = { inputName: RANGE_WIDTH, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
        } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
          rangeWidthError = { inputName: RANGE_WIDTH, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
        } else if (
          Number(value) < minRangeWidthOrErrorObject ||
          Number(value) > rangeSpreadMaxValue ||
          (initialPipsPrecision >= 1 && Number(value) % initialPipsPrecision !== 0)
        ) {
          rangeWidthError = {
            inputName: RANGE_WIDTH,
            errorMessage: `レンジ幅は${minRangeWidthOrErrorObject}${currencyUnit}以上、${rangeSpreadMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
          };
        }
      }
      return { error: rangeWidthError, inputName: RANGE_WIDTH };
    },
    [
      rangeSpreadMaxValue,
      initialPipsPrecision,
      itemsCount,
      currencyUnit,
      changeTarget,
      stepValue,
      selectLogicName,
      serviceId,
    ],
  );

  const validateItemsCount = useCallback(
    (value) => {
      const { logicClass, ranges } = logicRef.current;
      let itemsCountError = null;
      const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
      const targetRangeSide = ranges[changeTargetRangeId]?.getSide()?.ID;

      const itemsCountMin =
        targetRangeSide === CHART_MAKE_BUY_SELL_MAIN.STRADDLE.ID
          ? CROSS_ORDER_ITEMS_COUNT_MIN
          : ONE_SIDE_ORDER_ITEMS_COUNT_MIN;

      if (value === '') {
        itemsCountError = { inputName: ITEMS_COUNT, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        itemsCountError = { inputName: ITEMS_COUNT, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (Number(value) < itemsCountMin) {
        itemsCountError = {
          inputName: ITEMS_COUNT,
          errorMessage: `本数は${itemsCountMin}本以上で設定してください`,
        };
      } else {
        const errorObject = logicClass?.getMinRangeWidth
          ? logicClass?.getMinRangeWidth(changeTargetRangeId, Number(value))
          : 100; // TODO: ETFの最低レンジはどこから

        if (typeof errorObject === 'object') {
          itemsCountError = {
            inputName: ITEMS_COUNT,
            errorMessage: errorObject.errorMessage,
          };
        }
      }

      return { error: itemsCountError, inputName: ITEMS_COUNT };
    },
    [serviceId, selectLogicName, changeTarget],
  );

  const validateQuantity = useCallback(
    (value) => {
      let quantityError = null;
      if (value === '') {
        quantityError = { inputName: COUNT_INPUT_NAME, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        quantityError = { inputName: COUNT_INPUT_NAME, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < quantityMinValue ||
        Number(value) > quantityMaxValue ||
        (quantityPrecision >= 1 && Number(value) % quantityPrecision !== 0)
      ) {
        quantityError = {
          inputName: COUNT_INPUT_NAME,
          errorMessage: `数量は${quantityMinValue}${quantityUnit}以上、${quantityMaxValue}${quantityUnit}以下、${step}${quantityUnit}単位でご設定ください。`, // eslint-disable-line
        };
      }

      return { error: quantityError, inputName: COUNT_INPUT_NAME };
    },
    [quantityPrecision, step, quantityMaxValue, quantityMinValue, quantityUnit],
  );

  const validateProfitMargin = useCallback(
    (value, index, outerRangeWidth) => {
      const max = outerRangeWidth || profitMarginMaxValue;
      let profitMarginError = null;
      if (value === '') {
        profitMarginError = { inputName: `${PROFIT_MARGIN_NAME}${index}`, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        profitMarginError = {
          inputName: `${PROFIT_MARGIN_NAME}${index}`,
          errorMessage: VALIDATION_ERROR_INVALID_VALUE,
        };
      } else if (
        Number(value) < profitMarginMinValue ||
        Number(value) > max ||
        (initialPipsPrecision >= 1 && Number(value) % initialPipsPrecision !== 0)
      ) {
        profitMarginError = {
          inputName: `${PROFIT_MARGIN_NAME}${index}`,
          errorMessage: `利確幅は+${profitMarginMinValue}${currencyUnit}以上、+${profitMarginMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }

      return { error: profitMarginError, inputName: `${PROFIT_MARGIN_NAME}${index}` };
    },
    [currencyUnit, initialPipsPrecision, stepValue, profitMarginMinValue, profitMarginMaxValue],
  );

  const validateLossCutWidth = useCallback(
    (value) => {
      let stopLossError = null;
      if (value === '') {
        stopLossError = { inputName: LOSS_CUT_WIDTH_NAME, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        stopLossError = { inputName: LOSS_CUT_WIDTH_NAME, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < stopLossMinValue ||
        Number(value) > stopLossMaxValue ||
        (initialPipsPrecision >= 1 && Number(value) % initialPipsPrecision !== 0)
      ) {
        stopLossError = {
          inputName: LOSS_CUT_WIDTH_NAME,
          errorMessage: `損切幅は${stopLossMinValue}${currencyUnit}以上、${stopLossMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }
      return { error: stopLossError, inputName: LOSS_CUT_WIDTH_NAME };
    },
    [initialPipsPrecision, stepValue, stopLossMaxValue, stopLossMinValue, currencyUnit],
  );

  const validatePriceRangeHandler = useCallback(
    (val) => {
      if (Number(val) !== Number(priceRange)) {
        const { error, inputName } = validatePriceRange(val);
        changeErrorsArrayHandler(error, inputName);
      }
    },
    [validatePriceRange, changeErrorsArrayHandler, priceRange],
  );

  const validateRangeWidthHandler = useCallback(
    (val) => {
      const { error, inputName } = validateRangeWidth(val);
      changeErrorsArrayHandler(error, inputName);
    },
    [validateRangeWidth, changeErrorsArrayHandler],
  );

  const validateItemsCountHandler = useCallback(
    (val) => {
      const { error, inputName } = validateItemsCount(val);
      changeErrorsArrayHandler(error, inputName);
    },
    [validateItemsCount, changeErrorsArrayHandler],
  );

  const validateQuantityHandler = useCallback(
    (val) => {
      const { error, inputName } = validateQuantity(val);
      changeErrorsArrayHandler(error, inputName);
    },
    [validateQuantity, changeErrorsArrayHandler],
  );

  const validateProfitMarginHandler = useCallback(
    (val, index) => {
      const { error, inputName } = validateProfitMargin(val, index);
      changeErrorsArrayHandler(error, inputName);
    },
    [validateProfitMargin, changeErrorsArrayHandler],
  );

  const validateLossCutWidthHandler = useCallback(
    (val) => {
      const { error, inputName } = validateLossCutWidth(val);
      changeErrorsArrayHandler(error, inputName);
    },
    [validateLossCutWidth, changeErrorsArrayHandler],
  );

  const initValidation = useCallback(
    (settings) => {
      const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);

      const error = [];
      error.push(validatePriceRange(settings.priceRange).error);
      error.push(validateRangeWidth(settings.rangeWidth).error);
      error.push(validateItemsCount(settings.itemsCount).error);
      error.push(validateQuantity(settings.quantity).error);

      settings.profitMarginValue.forEach((tp, index) => {
        error.push(validateProfitMargin(tp, index, settings.rangeWidth).error);
      });

      if (allRangeSideMenuRef.current.find((s) => s.side === changeTargetRangeId)?.[LOSS_CUT_WIDTH_CHECK]) {
        error.push(validateLossCutWidth(settings.lossCutWidthValue || '').error);
      }

      const filteredError = error.filter((err) => err);
      setIsInitValidation(Boolean(filteredError.length));
      changeErrorsArray(filteredError);
      const targetRangeValidRef = allRangeValidationRef.current.find((a) => a[SIDE] === changeTargetRangeId);
      targetRangeValidRef[VALID] = filteredError;
    },
    [
      changeErrorsArray,
      validatePriceRange,
      validateRangeWidth,
      validateItemsCount,
      validateQuantity,
      validateProfitMargin,
      validateLossCutWidth,
      changeTarget,
      selectLogicName,
      serviceId,
    ],
  );
  // Definition onChange loss cut width check

  const onChangeLossCutWidthCheckHandler = useCallback(
    (check) => {
      setLossCutWidthCheck(check);
      onChangeSideMenuRef(check, LOSS_CUT_WIDTH_CHECK);
      if (check === false) {
        changeErrorsArrayHandler(null, LOSS_CUT_WIDTH_NAME);
      } else {
        const setValue = Number(lossCutWidthValue) !== 0 ? Number(lossCutWidthValue) : '';
        setLossCutWidthValue(setValue);
        validateLossCutWidthHandler(setValue);
      }
    },
    [changeErrorsArrayHandler, lossCutWidthValue, validateLossCutWidthHandler],
  );

  // Definition of a function to calculate order settings

  const shapeAps = useCallback(
    (aps) =>
      aps.map((ap, index) => ({
        ...ap,
        id: index + 1,
        instrumentId: serviceId === FX ? instrumentId : currencyName,
        entryPrice1: Number(roundToFixedPrecision(ap.entryPrice1, pricePrecision)),
        amount: ap.quantity,
        tp: Number(ap.tp),
        sl: ap.sl || null,
        buySell: ap.side,
        counterPrice: ap.counterPrice == null ? null : Number(roundToFixedPrecision(ap.counterPrice, pricePrecision)),
        profitMargin: ap.tp,
        stopLossRange: ap.sl || null,
      })),
    [currencyName, instrumentId, serviceId, pricePrecision],
  );

  const resetReadonlyAmountSettings = useCallback(
    (aps) => {
      if (![SWAPPER, ZONE_PROTECTOR].includes(selectLogicName)) return;

      const amountSettingGroupBy = aps.reduce((acc, ap) => {
        return acc.includes(ap.amount) ? acc : [...acc, ap.amount];
      }, []);
      if (amountSettingGroupBy.length > 1) {
        setReadOnlyAmountSettings(amountSettingGroupBy.slice(1));
      } else {
        setReadOnlyAmountSettings(null);
      }
    },
    [selectLogicName],
  );

  const setSideMenuDefaultValueToRef = useCallback(
    (allRangeSettings, lossCutWidthChecks) => {
      allRangeDefaultSettingsRef.current = allRangeSettings.map((range) => {
        return {
          [SIDE]: range.side,
          [PRICE_RANGE]: range.startPrice,
          [RANGE_WIDTH]: range.rangeWidth,
          [ITEMS_COUNT]: range.numOfOrders,
          [COUNT_INPUT_NAME]: range.quantity,
          [PROFIT_MARGIN_NAME]: serviceId === FX ? [range.tp] : range.tp,
          [LOSS_CUT_WIDTH_NAME]: range.sl || '',
          [LOSS_CUT_WIDTH_CHECK]: lossCutWidthChecks[range.side],
        };
      });

      allRangeSideMenuRef.current = allRangeSettings.map((range) => {
        return {
          [SIDE]: range.side,
          [PRICE_RANGE]: range.startPrice,
          [RANGE_WIDTH]: range.rangeWidth,
          [ITEMS_COUNT]: range.numOfOrders,
          [COUNT_INPUT_NAME]: range.quantity,
          [PROFIT_MARGIN_NAME]: serviceId === FX ? [range.tp] : range.tp,
          [LOSS_CUT_WIDTH_NAME]: range.sl || '',
          [LOSS_CUT_WIDTH_CHECK]: lossCutWidthChecks[range.side],
        };
      });
    },
    [serviceId],
  );

  const setSideMenuValue = useCallback((settingsValue) => {
    setPriceRange(settingsValue.priceRange);
    setRangeWidth(settingsValue.rangeWidth);
    setItemsCount(settingsValue.itemsCount);
    setQuantity(settingsValue.quantity);
    setProfitMarginValue(settingsValue.profitMarginValue);
    setLossCutWidthValue(settingsValue.lossCutWidthValue || '');
  }, []);

  // change target
  const [isChangeTargetDone, setIsChangeTargetDone] = useState(false);

  useEffect(() => {
    const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
    if (!logicRef.current || changeTargetIdRef.current === changeTargetRangeId) return;

    const { logicClass } = logicRef.current;

    const newSettings = getSettingsValue(logicClass.getSettings(changeTargetRangeId));

    setSideMenuValue(newSettings);
    setLossCutWidthCheck(
      allRangeSideMenuRef.current.find((s) => s.side === changeTargetRangeId)?.[LOSS_CUT_WIDTH_CHECK],
    );

    const allRangeSettings = logicClass.getAllSettings();

    const selectedSideId = changeTargetOptions.find((o) => o.label === changeTarget)?.id;
    setRangeAreaAllPrice(
      allRangeSettings.map((rangeSet) => ({
        max: Number(roundToFixedPrecision(rangeSet.preciseMax, pricePrecision)),
        min: Number(roundToFixedPrecision(rangeSet.preciseMin, pricePrecision)),
        side: rangeSet.side,
        color: getColor(rangeSet.side),
        isActive: selectedSideId === rangeSet.side,
      })),
    );

    setItemsCountInfoMessage(logicClass.getNumOfOrdersHelpText(changeTargetRangeId));
    changeTargetIdRef.current = changeTargetRangeId;
    setIsChangeTargetDone(true);
  }, [
    changeTarget,
    changeTargetOptions,
    getSettingsValue,
    pricePrecision,
    selectLogicName,
    serviceId,
    setSideMenuValue,
  ]);

  // change Target Vlalidation
  useEffect(() => {
    if (!isChangeTargetDone) return;

    const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
    const { logicClass } = logicRef.current;

    const newSettings = getSettingsValue(logicClass.getSettings(changeTargetRangeId));
    initValidation(newSettings);
    setIsChangeTargetDone(false);
  }, [
    changeTarget,
    getSettingsValue,
    selectLogicName,
    serviceId,
    setSideMenuValue,
    initValidation,
    isChangeTargetDone,
  ]);

  // validation after chenge profitMarginMaxValue
  const profitMarginMaxValueRef = useRef(profitMarginMaxValue);

  const changeErrorsArrayHandlerByMaxValue = useCallback(
    (errors, inputErrors) => {
      const excludeInputErrorsArray = errorsArray.filter(
        (err) => !inputErrors.includes(err.inputName) && err.inputName.indexOf('tp') !== 0,
      );

      const targetRangeValidRef = allRangeValidationRef.current.find((a) => a[SIDE] === changeTargetIdRef.current);

      if (errors.length !== 0) {
        changeErrorsArray([...excludeInputErrorsArray, ...errors]);
        targetRangeValidRef[VALID] = [...excludeInputErrorsArray, ...errors];
      } else {
        changeErrorsArray([...excludeInputErrorsArray]);
        targetRangeValidRef[VALID] = [...excludeInputErrorsArray];
      }
    },
    [errorsArray],
  );

  useEffect(() => {
    if (profitMarginMaxValueRef.current !== profitMarginMaxValue) {
      profitMarginMaxValueRef.current = profitMarginMaxValue;
      if (isChangeTargetDone) return;

      const inputErrors = [];
      const errors = [];
      profitMarginValue.forEach((val, index) => {
        const { error, inputName } = validateProfitMargin(val, index, profitMarginMaxValue);
        if (error) {
          inputErrors.push(inputName);
          errors.push(error);
        }
      });

      changeErrorsArrayHandlerByMaxValue(errors, inputErrors);
    }
  }, [
    profitMarginMaxValue,
    profitMarginValue,
    changeErrorsArrayHandlerByMaxValue,
    validateProfitMargin,
    isChangeTargetDone,
  ]);

  // logic class init
  useEffect(() => {
    if (orderSettings.length) return;

    try {
      const { logicClass, ranges } = logicRef.current;
      const lossCutChecks = {};
      changeTargetOptions.forEach((o) => {
        lossCutChecks[o.id] = selectLogicName === HEDGER && o.id === CHART_MAKE_RANGE_ID[HEDGER][SELL_HEDGE];
      });

      const aps = shapeAps(logicClass.createAps());
      dispatch(addBuilderOrderSettingsList({ orderSettings: aps }));
      resetReadonlyAmountSettings(aps);

      const changeTargetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
      const settings = getSettingsValue(logicClass.getSettings(changeTargetRangeId));
      setSideMenuValue(settings);
      setLossCutWidthCheck(lossCutChecks[changeTargetRangeId]);

      const allRangeSettings = logicClass.getAllSettings();
      setSideMenuDefaultValueToRef(allRangeSettings, lossCutChecks);

      allRangeValidationRef.current = changeTargetOptions.map((o) => ({ [SIDE]: o.id, [VALID]: [] }));
      initValidation(settings);

      logicRef.current = { logicClass, ranges };
      logicClass.backupDefaultSettings();

      const selectedSideId = changeTargetOptions.find((o) => o.label === changeTarget)?.id;

      setRangeAreaAllPrice(
        allRangeSettings.map((rangeSet) => ({
          max: Number(roundToFixedPrecision(rangeSet.max, pricePrecision)),
          min: Number(roundToFixedPrecision(rangeSet.min, pricePrecision)),
          side: rangeSet.side,
          color: getColor(rangeSet.side),
          isActive: selectedSideId === rangeSet.side,
        })),
      );
      setPriceRangeInfoMessage(logicClass.getStartPriceHelpText());
      setItemsCountInfoMessage(logicClass.getNumOfOrdersHelpText(changeTargetRangeId));
    } catch (e) {
      dispatch(sendErrorToBugsnag({ error: e }));
    }
  }, [
    changeTarget,
    currencyName,
    dispatch,
    getSettingsValue,
    instrumentId,
    setSideMenuDefaultValueToRef,
    modePrice,
    logicRef,
    orderSettings,
    priceHigh,
    priceLow,
    pricePrecision,
    selectLogicName,
    serviceId,
    setSideMenuValue,
    initValidation,
    shapeAps,
    changeTargetOptions,
    setPriceRangeInfoMessage,
    setItemsCountInfoMessage,
    resetReadonlyAmountSettings,
  ]);

  // Define a function for button control

  // Determine the disabled flag for each button
  useEffect(() => {
    const isSideMenuChange = allRangeDefaultSettingsRef.current
      .map((defSet) => {
        const curSet = allRangeSideMenuRef.current.find((cur) => cur.side === defSet.side);

        const defProfitNumberParse = defSet?.[PROFIT_MARGIN_NAME].map((d) => Number(d));
        const curProfitNumberParse = curSet?.[PROFIT_MARGIN_NAME].map((c) => Number(c));

        return (
          Number(defSet?.[PRICE_RANGE]) !== Number(curSet?.[PRICE_RANGE]) ||
          Number(defSet?.[RANGE_WIDTH]) !== Number(curSet?.[RANGE_WIDTH]) ||
          Number(defSet?.[COUNT_INPUT_NAME]) !== Number(curSet?.[COUNT_INPUT_NAME]) ||
          Number(defSet?.[ITEMS_COUNT]) !== Number(curSet?.[ITEMS_COUNT]) ||
          JSON.stringify(defProfitNumberParse) !== JSON.stringify(curProfitNumberParse) ||
          (curSet?.[LOSS_CUT_WIDTH_CHECK] &&
            Number(curSet?.[LOSS_CUT_WIDTH_NAME]) !== Number(defSet?.[LOSS_CUT_WIDTH_NAME])) ||
          defSet?.[LOSS_CUT_WIDTH_CHECK] !== curSet?.[LOSS_CUT_WIDTH_CHECK]
        );
      })
      .includes(true);

    const allRangeErrors = allRangeValidationRef.current
      .map((range) => {
        const excludeErrorsArray = range[VALID].filter((err) => !err.inputName.indexOf(PROFIT_MARGIN_NAME))
          .filter(
            (err) =>
              Number(err.inputName.slice(2)) <
              Number(allRangeSideMenuRef.current.find((cur) => cur.side === range[SIDE]).itemsCount),
          )
          .concat(range[VALID].filter((err) => err.inputName.indexOf(PROFIT_MARGIN_NAME)));

        return Boolean(excludeErrorsArray.length);
      })
      .includes(true);

    setIsAddCartAndRunNowDisabled(isSideMenuChange);
    setIsReCalcDisabled(!isSideMenuChange || allRangeErrors);
  }, [
    priceRange,
    rangeWidth,
    quantity,
    itemsCount,
    profitMarginValue,
    lossCutWidthValue,
    lossCutWidthCheck,
    serviceId,
  ]);

  const orderSettingsReCalc = useCallback(() => {
    try {
      const targetRangeId = getChangeTargetId(serviceId, selectLogicName, changeTarget);
      const { logicClass } = logicRef.current;
      const settings = {
        target: targetRangeId, // 変更対象レンジ
        recalc: {
          startPrice: Number(priceRange),
          rangeWidth: Number(rangeWidth),
          numOfOrders: Number(itemsCount),
        },
        createAps: {
          quantity: Number(quantity),
          tp: serviceId === FX ? Number(profitMarginValue[0]) : profitMarginValue.map((tp) => Number(tp)),
          sl: lossCutWidthCheck ? Number(lossCutWidthValue) : null,
        },
      };
      const recalcResult = logicClass.recalc(settings);
      if (!recalcResult.isValid) {
        setRecalcErrorMessage(recalcResult?.errorMessage);
        return;
      }
      logicClass.backupDefaultSettings();

      const aps = shapeAps(logicClass.createAps());

      dispatch(addBuilderOrderSettingsList({ orderSettings: aps }));

      resetReadonlyAmountSettings(aps);

      logicRef.current.logicClass = logicClass;

      const allRangeSettings = logicClass.getAllSettings();

      const lossCutChecks = {};
      changeTargetOptions.forEach((o) => {
        lossCutChecks[o.id] = allRangeSideMenuRef.current.find((cur) => cur.side === o.id).lossCutWidthCheck;
      });

      setSideMenuDefaultValueToRef(allRangeSettings, lossCutChecks);

      setIsAddCartAndRunNowDisabled(false);
      setIsReCalcDisabled(true);
    } catch (e) {
      dispatch(sendErrorToBugsnag({ error: e }));
    }
  }, [
    setSideMenuDefaultValueToRef,
    changeTarget,
    dispatch,
    itemsCount,
    lossCutWidthCheck,
    lossCutWidthValue,
    priceRange,
    profitMarginValue,
    rangeWidth,
    serviceId,
    selectLogicName,
    shapeAps,
    quantity,
    resetReadonlyAmountSettings,
    changeTargetOptions,
  ]);

  const deleteOrderSetting = useCallback(
    (id) => {
      const deletedOrderSetting = orderSettings.filter((order) => order.id !== Number(id));

      dispatch(addBuilderOrderSettingsList({ orderSettings: deletedOrderSetting, logicGroup: null }));
    },
    [dispatch, orderSettings],
  );

  const labelUnit = useMemo(() => (serviceId === CFD ? '' : `(${currencyUnit})`), [serviceId, currencyUnit]);

  return useMemo(
    () => ({
      changeTarget: {
        get: changeTarget,
        set: onChangeTargetHandler,
        options: changeTargetOptions,
        label: `変更対象`,
      },
      priceRange: {
        get: priceRange,
        set: onChangePriceRangeHandler,
        label: `レンジ位置`,
        name: PRICE_RANGE,
        infoMessage: priceRangeInfoMessage,
        validate: validatePriceRangeHandler,
        step: priceStep,
        isDisabled: isChartDataLoading,
      },
      rangeWidth: {
        get: rangeWidth,
        set: onChangeRangeWidthHandler,
        label: `レンジ幅${labelUnit}`,
        name: RANGE_WIDTH,
        infoMessage: RANGE_WIDTH_INFO,
        validate: validateRangeWidthHandler,
        step: rangeStep,
        isDisabled: isChartDataLoading,
      },
      itemsCount: {
        get: itemsCount,
        set: onChangeItemsCountHandler,
        label: `本数`,
        name: ITEMS_COUNT,
        infoMessage: itemsCountInfoMessage,
        validate: validateItemsCountHandler,
        step: 1,
      },
      quantity: {
        get: quantity,
        set: onChangeQuantityHandler,
        label: `数量`,
        name: COUNT_INPUT_NAME,
        validate: validateQuantityHandler,
        step: quantityStep,
        readOnlyAmountSettings,
        quantityUnit,
      },
      profitMargin: {
        get: profitMarginValue,
        set: onChangeProfitMarginHandler,
        label: (index) => `利確幅${index}${labelUnit}`,
        step: serviceId === FX ? PROFIT_MARGIN_STEP : priceStep,
        name: PROFIT_MARGIN_NAME,
        validate: validateProfitMarginHandler,
      },
      lossCutWidthCheck: {
        get: lossCutWidthCheck,
        set: onChangeLossCutWidthCheckHandler,
      },
      lossCutWidth: {
        get: lossCutWidthValue,
        set: onChangeLossCutWidthHandler,
        label: `損切幅${labelUnit}`,
        step: serviceId === FX ? STOP_LOSS_STEP : priceStep,
        name: LOSS_CUT_WIDTH_NAME,
        validate: validateLossCutWidthHandler,
        min: null,
        isDisabled: !lossCutWidthCheck,
      },
      fundIndication: {
        label: '運用資金目安',
        value: fundIndication,
        tooltipMessage: CHART_MAKE_FUND_INDICATION_TOOLTIP,
      },
      requiredMargin: {
        label: REQUIRED_MARGIN_LABEL,
        value: requiredMargin,
        tooltipMessage: REQUIRED_MARGIN_TOOLTIP,
      },
      reCalc: orderSettingsReCalc,
      orderSettings,
      deleteOrderSetting,
      priceHigh,
      rangeAreaAllPrice,
      buttonController: {
        isInitValidation,
        isAddCartAndRunNowDisabled,
        isReCalcDisabled,
      },
      errorsArray,
      recalcErrorMessage,
      setRecalcErrorMessage,
    }),
    [
      changeTarget,
      onChangeTargetHandler,
      priceRange,
      onChangePriceRangeHandler,
      validatePriceRangeHandler,
      serviceId,
      priceStep,
      isChartDataLoading,
      rangeWidth,
      onChangeRangeWidthHandler,
      validateRangeWidthHandler,
      rangeStep,
      itemsCount,
      onChangeItemsCountHandler,
      validateItemsCountHandler,
      quantity,
      onChangeQuantityHandler,
      validateQuantityHandler,
      quantityUnit,
      quantityStep,
      profitMarginValue,
      onChangeProfitMarginHandler,
      validateProfitMarginHandler,
      lossCutWidthCheck,
      onChangeLossCutWidthCheckHandler,
      lossCutWidthValue,
      onChangeLossCutWidthHandler,
      validateLossCutWidthHandler,
      fundIndication,
      requiredMargin,
      errorsArray,
      orderSettingsReCalc,
      orderSettings,
      deleteOrderSetting,
      changeTargetOptions,
      isInitValidation,
      isAddCartAndRunNowDisabled,
      isReCalcDisabled,
      rangeAreaAllPrice,
      priceHigh,
      recalcErrorMessage,
      setRecalcErrorMessage,
      priceRangeInfoMessage,
      itemsCountInfoMessage,
      readOnlyAmountSettings,
      labelUnit,
    ],
  );
};

export default useChartMakeInfo;
