import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import { useTechRunNowResultsLogic } from 'shared-modules/services/hooks/builder';
import { handleRedirect } from '../../../../redux/actions';
import { Button, Modal } from '../../../../components';
import styles from './techAddToPortfolioSuccess.module.scss';

const TechAddToPortfolioSuccess = ({ isOpen, closeModal, orderName }) => {
  const dispatch = useDispatch();

  const openHomeScreen = useCallback(() => {
    closeModal();
    dispatch(handleRedirect({ url: '/home', isInner: true }));
  }, [closeModal, dispatch]);

  const runNowResultsLogic = useTechRunNowResultsLogic({ orderName, closeModal });

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title={runNowResultsLogic.success.modalTitle}
      size={MODAL_SIZES.WIDTH_400}
    >
      <div className={styles.row}>{runNowResultsLogic.success.title}</div>
      <div className={styles.row}>{runNowResultsLogic.success.message}</div>
      <div className={styles.buttonWrapper}>
        <Button onClick={runNowResultsLogic.buttons.cancel.handler} className={styles.closeButton} secondary>
          {runNowResultsLogic.buttons.cancel.label}
        </Button>
        <Button onClick={openHomeScreen}>{runNowResultsLogic.buttons.submit.label}</Button>
      </div>
    </Modal>
  );
};

TechAddToPortfolioSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  orderName: PropTypes.string.isRequired,
};

export default memo(TechAddToPortfolioSuccess);
