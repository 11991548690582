import { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCheckOnNegativePriceWhenSimulate,
  useGetTechBuilderOrderRequestData,
} from 'shared-modules/services/hooks/builder';
import { Button } from '../../../../components';
import {
  changeBuilderOrderEditStatus,
  getTechBuilderSimulationDataRequest,
  openTechAddToCartModal,
  openTechAddToPortfolioModal,
} from '../../../../redux/actions';
import styles from './techFooter.module.scss';

export const TechFooter = memo(() => {
  const dispatch = useDispatch();
  const allowOrderEdit = useSelector((state) => state.builder.allowOrderEdit);
  const logicGroupsList = useSelector((state) => state.builder.logicGroupsList);
  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);
  const simulationDataIsLoading = useSelector((state) => state.builder.simulationDataIsLoading);

  const getTechOrderRequestData = useGetTechBuilderOrderRequestData();

  const checkOnNegativePrice = useCheckOnNegativePriceWhenSimulate();

  const [isOrderSetting, setIsOrderSetting] = useState(true);

  const changeEditMode = useCallback(
    () => dispatch(changeBuilderOrderEditStatus({ allowOrderEdit: !allowOrderEdit })),
    [dispatch, allowOrderEdit],
  );

  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);

  const getSimulationData = useCallback(() => {
    const successCallback = () => {
      const orderRequestData = getTechOrderRequestData();

      dispatch(
        getTechBuilderSimulationDataRequest({
          orderRequestData,
          callback: changeEditMode,
          termId: defaultSelectionTermId,
        }),
      );
    };

    checkOnNegativePrice(successCallback);
  }, [changeEditMode, checkOnNegativePrice, dispatch, getTechOrderRequestData, defaultSelectionTermId]);

  const onAddToCartClick = useCallback(() => {
    const successCallback = () => {
      dispatch(openTechAddToCartModal(isOrderSetting));
    };
    checkOnNegativePrice(successCallback);
  }, [dispatch, checkOnNegativePrice, isOrderSetting]);

  const onRunNowClick = useCallback(() => {
    const successCallback = () => {
      dispatch(openTechAddToPortfolioModal(isOrderSetting));
    };
    checkOnNegativePrice(successCallback);
  }, [dispatch, checkOnNegativePrice, isOrderSetting]);

  const isDisabled = useMemo(
    () => !logicGroupsList.length && !orderSettingsList.length,
    [logicGroupsList, orderSettingsList],
  );

  useEffect(() => {
    if (isDisabled) {
      dispatch(changeBuilderOrderEditStatus({ allowOrderEdit: true }));
    }
  }, [isDisabled, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.buttonGroup}>
        {allowOrderEdit ? (
          <Button
            disabled={isDisabled}
            loading={simulationDataIsLoading}
            tabIndex="0"
            onClick={() => {
              setIsOrderSetting(false);
              getSimulationData();
            }}
          >
            シミュレーションを見る
          </Button>
        ) : (
          <Button
            onClick={() => {
              setIsOrderSetting(true);
              changeEditMode();
            }}
            tabIndex="0"
            secondary
          >
            プライスチャートに戻る
          </Button>
        )}

        <Button disabled={isDisabled} width={200} onClick={onAddToCartClick} tabIndex="0" secondary>
          カートに追加
        </Button>

        <Button disabled={isDisabled} width={200} onClick={onRunNowClick} tabIndex="0">
          今すぐ稼働
        </Button>
      </div>
    </div>
  );
});
