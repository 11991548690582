import _ from 'lodash';
import Decimal from 'decimal.js';
/* eslint-disable-next-line import/no-unresolved */
import { createSelector } from '@reduxjs/toolkit';
import { removeSuffix } from '../hooks/symbol';
import { getInstrumentShortName, getServiceId } from '../utils';
import { AP_SUFFIX, M_SUFFIX, rateSeparationedAssets } from '../constants';

// 銘柄定義順の銘柄ID一覧を作成するが、レート分別対応の銘柄IDが初出した際にはその手前にサフィックスを除去した銘柄IDを差し込む
const makeInstrumentDefinitions = (instrumentList) => {
  const cache = new Set();
  return Object.keys(instrumentList).reduce((acc, curr) => {
    if (curr.includes(AP_SUFFIX) || curr.includes(M_SUFFIX)) {
      const shortInstrumentId = removeSuffix(curr);
      if (!cache.has(shortInstrumentId)) {
        cache.add(shortInstrumentId);
        acc.push(shortInstrumentId);
      }
    }
    acc.push(curr);
    return acc;
  }, []);
};

const isRateSeparation = (instrumentId, instrumentList) => {
  return rateSeparationedAssets.includes(getServiceId(instrumentId, instrumentList));
};

const makeGetPrimarySortValueFunc = (instrumentList) => {
  const instrumentDefinitions = makeInstrumentDefinitions(instrumentList);
  return ({ instrumentId }) => {
    const logicalInstrumentId = isRateSeparation(instrumentId, instrumentList)
      ? removeSuffix(instrumentId)
      : instrumentId;
    return instrumentDefinitions.indexOf(logicalInstrumentId);
  };
};

const makeGetSecondarySortValueFunc =
  (instrumentList) =>
  ({ instrumentId, tradeMethod }) =>
    isRateSeparation(instrumentId, instrumentList) ? tradeMethod : null;

export const positionSummarySelector = createSelector(
  (state) => state.constants[state.auth.serviceId].instrumentsOptions,
  (state) => state.currencies.positions[state.auth.serviceId],
  (state) => state.currencies.positionsUnrealizedProfitLoss,
  (state) => state.settings.instrumentList,
  (instrumentsOptions, positions, positionsUnrealizedProfitLoss, instrumentList) => {
    const instrumentIdList = instrumentsOptions.map(({ value }) => value);

    const positionSummary = positions.reduce((acc, position) => {
      const reduceKey = `${position.instrumentId}#${position.side}`;
      const name = getInstrumentShortName(position.instrumentId, instrumentList);
      // group by instrumentId#side
      if (!acc[reduceKey]) {
        acc[reduceKey] = {
          name,
          tradeMethod: position?.tradeMethod,
          totalSettlingQuantity: position?.settlingQuantity,
          totalSwapPl: position?.swapPl,
          totalUnrealizedSwapPl: position?.unrealizedSwapPl,
          totalDividend: position?.dividend,
          positionIds: [position.positionId],
        };
      } else {
        acc[reduceKey].totalSettlingQuantity = Decimal.add(
          acc[reduceKey].totalSettlingQuantity,
          position?.settlingQuantity,
        ).toNumber();
        acc[reduceKey].totalSwapPl += position?.swapPl ?? 0;
        acc[reduceKey].totalUnrealizedSwapPl += position?.unrealizedSwapPl ?? 0;
        acc[reduceKey].totalDividend += position?.dividend ?? 0;
        acc[reduceKey].positionIds = [...acc[reduceKey].positionIds, position.positionId];
      }
      return acc;
    }, {});

    const currentServicePositionsUnrealizedPl = _.pickBy(positionsUnrealizedProfitLoss, (value, key) =>
      instrumentIdList.includes(key),
    );

    const getPrimarySortValueFunc = makeGetPrimarySortValueFunc(instrumentList);
    const getSecondarySortValueFunc = makeGetSecondarySortValueFunc(instrumentList);
    return _.orderBy(
      _.reduce(
        currentServicePositionsUnrealizedPl,
        (acc, positionsValue, instrumentIdKey) => {
          _.each(_.groupBy(Object.values(positionsValue), 'side'), (sideGroup, side) => {
            const reduceKey = `${instrumentIdKey}#${side}`;
            const reduceSideSummary = _.reduce(
              sideGroup,
              (detailsAcc, detail) => {
                return {
                  ...detailsAcc,
                  totalQuantity: Decimal.add(detailsAcc.totalQuantity, detail.quantity).toNumber(),
                  avgTradePrice: Decimal.div(
                    Decimal.add(
                      Decimal.mul(detailsAcc.avgTradePrice, detailsAcc?.totalQuantity),
                      Decimal.mul(detail.tradePrice, detail?.quantity),
                    ),
                    Decimal.add(detailsAcc.totalQuantity, detail.quantity),
                  ).toNumber(),
                  totalUnrealizedProfitLoss: detailsAcc.totalUnrealizedProfitLoss + detail.unrealizedProfitLoss,
                };
              },
              {
                instrumentId: instrumentIdKey,
                name: positionSummary[reduceKey].name,
                tradeMethod: positionSummary[reduceKey].tradeMethod,
                positionIds: positionSummary[reduceKey].positionIds,
                side,
                totalQuantity: 0,
                avgTradePrice: 0,
                totalUnrealizedProfitLoss: 0,
                totalSettlingQuantity: positionSummary[reduceKey]?.totalSettlingQuantity,
                totalUnrealizedSwapPl: positionSummary[reduceKey]?.totalUnrealizedSwapPl,
                totalDividend: positionSummary[reduceKey]?.totalDividend,
              },
            );
            acc.push(reduceSideSummary);
          });
          return acc;
        },
        [],
      ),
      [getPrimarySortValueFunc, getSecondarySortValueFunc, 'side'],
      ['asc', 'asc', 'desc'],
    );
  },
);

export const positionSummaryWithoutDynamicDataSelector = createSelector(
  (state) => state.currencies.positions[state.auth.serviceId],
  (state) => state.settings.instrumentList,
  (positions, instrumentList) => {
    const getPrimarySortValueFunc = makeGetPrimarySortValueFunc(instrumentList);
    const getSecondarySortValueFunc = makeGetSecondarySortValueFunc(instrumentList);
    return _.orderBy(
      positions.reduce((acc, position) => {
        const reduceKey = `${position.instrumentId}#${position.side}`;
        const name = getInstrumentShortName(position.instrumentId, instrumentList);
        // group by instrumentId#side
        if (!acc[reduceKey]) {
          acc[reduceKey] = {
            instrumentId: position?.instrumentId,
            name,
            tradeMethod: position?.tradeMethod,
            side: position?.side,
            totalQuantity: position?.quantity,
            totalSettlingQuantity: position?.settlingQuantity,
            avgTradePrice: position?.tradePrice * position?.quantity,
            totalUnrealizedSwapPl: position?.unrealizedSwapPl ?? 0,
            totalDividend: position?.dividend ?? 0,
            positionIds: [position.positionId],
            count: 1,
          };
        } else {
          acc[reduceKey].totalQuantity = Decimal.add(acc[reduceKey].totalQuantity, position?.quantity).toNumber();
          acc[reduceKey].totalSettlingQuantity = Decimal.add(
            acc[reduceKey].totalSettlingQuantity,
            position?.settlingQuantity,
          ).toNumber();
          acc[reduceKey].avgTradePrice = Decimal.add(
            acc[reduceKey].avgTradePrice,
            Decimal.mul(position?.tradePrice, position?.quantity),
          ).toNumber();
          acc[reduceKey].totalUnrealizedSwapPl = Decimal.add(
            acc[reduceKey].totalUnrealizedSwapPl,
            position?.unrealizedSwapPl ?? 0,
          ).toNumber();
          acc[reduceKey].totalDividend = Decimal.add(acc[reduceKey].totalDividend, position?.dividend ?? 0).toNumber();
          acc[reduceKey].positionIds = [...acc[reduceKey].positionIds, position.positionId];
          acc[reduceKey].count += 1;
        }
        return acc;
      }, {}),
      [getPrimarySortValueFunc, getSecondarySortValueFunc, 'side'],
      ['asc', 'asc', 'desc'],
    ).map((item) => ({
      ...item,
      avgTradePrice: Decimal.div(item.avgTradePrice, item.totalQuantity).toNumber(),
    }));
  },
);
