import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { COLORS } from 'shared-modules/constants';
import { resetData } from 'shared-modules/redux/cash';
import { CASH_PAGE_TITLE, CASH_PAGES } from 'shared-modules/constants/cash';
import { INQUIRY_LINK } from 'shared-modules/config';

import { push } from '../../../../../redux/actions';
import CustomButton from '../../../../../components/CustomButton';

import styles from './quickDepositFailed.module.scss';
import { useCashPageTransition } from '../../../../../services/hooks';
import { HOME } from '../../../../../constants';

const QuickDepositFailed = () => {
  const dispatch = useDispatch();
  const pageTransition = useCashPageTransition();

  useEffect(() => {
    pageTransition(CASH_PAGES.CASH_QUICK_DEPOSIT_FAILED.ID);
  }, [pageTransition]);

  const handleRedirectToPortfolio = useCallback(() => {
    dispatch(resetData());
    setTimeout(() => {
      dispatch(push(`/${HOME}`));
    }, 300);
  }, [dispatch]);

  const openInquiryPage = useCallback(() => {
    window.open(INQUIRY_LINK);
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <span className={styles.pageTitle}>{CASH_PAGE_TITLE.QUICK_DEPOSIT}</span>
      <div className={styles.wrapper}>
        <div className={styles.paragraphNoLine}>即時入金に失敗しました。</div>

        <div className={styles.description}>
          <div>送金元銀行口座をご確認いただき、「インヴァスト証券へ送金完了」かをご確認ください。</div>
        </div>

        <div className={styles.paragraph}>銀行口座上では振込みが完了している場合</div>

        <div className={styles.description}>
          <div>ご入金がお取引口座に反映していない場合は、銀行側で通常の振込みとして扱われている可能性もあり、</div>
          <div>入金の反映までお時間をいただく場合がございます。</div>
          <div>なお、夜間や休日祝日の場合、翌銀行営業日の取扱となります。</div>
        </div>

        <div className={styles.paragraph}>他人名義で即時入金された場合</div>

        <div className={styles.description}>
          <div>送金元銀行へ連絡し「組戻請求」を行っていただきますようお願いいたします。</div>
        </div>

        <div className={styles.buttonRow}>
          <CustomButton color={COLORS.GREY} className={styles.button} onClick={() => openInquiryPage()} width={270}>
            お問い合わせ
          </CustomButton>
          <CustomButton color={COLORS.GREY} className={styles.button} onClick={handleRedirectToPortfolio} width={270}>
            ホームに戻る
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default memo(QuickDepositFailed);
