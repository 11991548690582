import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COUNTRIES_AVAILABLE_VALUES, FX } from 'shared-modules/constants';
import { useServiceId } from 'shared-modules/hooks';
import { useSelector } from 'react-redux';
import { CustomFlags } from '../CustomFlags';
import styles from './customCurrencyIcon.module.scss';

const CustomCurrencyIcon = ({ currencyPair, className, isSmall, isMini, isTiny }) => {
  const instrumentMap = useSelector((state) => state.settings.instrumentList);
  const { image } =
    instrumentMap[currencyPair] ??
    Object.values(instrumentMap).find((instrument) => instrument.symbol === currencyPair);

  const serviceId = useServiceId(currencyPair);

  const icon = useMemo(() => {
    const [firstCountry, secondCountry] = currencyPair.split('/');
    const isFXIcon = COUNTRIES_AVAILABLE_VALUES.some((country) => country === firstCountry);
    if (isFXIcon) {
      const CustomFlagComponent = CustomFlags[FX];
      return (
        <>
          <CustomFlagComponent
            country={firstCountry}
            className={classNames(
              styles.firstFlag,
              { [styles.isSmall]: isSmall },
              { [styles.isMini]: isMini },
              { [styles.isTiny]: isTiny },
            )}
          />
          <CustomFlagComponent
            country={secondCountry}
            className={classNames(
              styles.secondFlag,
              { [styles.isSmall]: isSmall },
              { [styles.isMini]: isMini },
              { [styles.isTiny]: isTiny },
            )}
          />
        </>
      );
    }
    const CustomFlagComponent = CustomFlags[serviceId];
    if (CustomFlagComponent) {
      return (
        <CustomFlagComponent
          flagType={image}
          className={classNames(
            styles[`${serviceId}Flag`],
            { [styles.isSmall]: isSmall },
            { [styles.isMini]: isMini },
            { [styles.isTiny]: isTiny },
          )}
        />
      );
    }
    return null;
  }, [currencyPair, serviceId, isSmall, isMini, isTiny, image]);

  return (
    <div
      className={classNames(
        styles.wrapper,
        className,
        { [styles.isSmall]: isSmall },
        { [styles.isMini]: isMini },
        { [styles.isTiny]: isTiny },
      )}
    >
      {icon}
    </div>
  );
};

CustomCurrencyIcon.propTypes = {
  currencyPair: PropTypes.string,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  isMini: PropTypes.bool,
  isTiny: PropTypes.bool,
};

CustomCurrencyIcon.defaultProps = {
  currencyPair: '',
  className: '',
  isSmall: false,
  isMini: false,
  isTiny: false,
};

export default memo(CustomCurrencyIcon);
