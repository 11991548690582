import { put, call, retry, takeEvery, takeLatest, select } from 'redux-saga/effects';
import {
  getCashInitRequest,
  getCashSessionRequestSuccess,
  getBankListRequest,
  getBankListRequestSuccess,
  getCanDepositRequest,
  getCanDepositRequestSuccess,
  getBankInfoRequest,
  getBankInfoRequestSuccess,
  getDepositOnlyBankInfoRequest,
  getDepositOnlyBankInfoRequestSuccess,
  getCashMenuMarginInfoRequest,
  changeIsMarginRefreshed,
  changeQuickDepositTermsAgreed,
  getQuickDepositTermsAgreeRequest,
  postQuickDepositTermsAgreeRequest,
  getQuickDepositTermsAgreeRequestSuccess,
  cashStartLoading,
  cashEndLoading,
} from '../cash';
import { getMarginSuccess } from '../actions/portfolioActions';
import { openErrorInfoModal } from '../actions/errorActions';
import { getMargin } from '../../api/portfolioApi';
import {
  getCashSession,
  getBankList,
  getCanDeposit,
  getBankInfo,
  getDepositOnlyBankInfo,
  getRulesAgree,
  postRulesAgree,
} from '../../api/cashApi';
import { errorHandler } from './errorSaga';
import { RETRY_MAX_TRIES, RETRY_DELAY, IMS_EXEC_FAILED_MESSAGE } from '../../constants/apiConstant';
import { CFD, ETF, FX } from '../../constants';
import { getAccountInfo } from './common';

function* imsErrorHandler({ message = '', title = '', buttonText = '', buttonCallback = () => {} }) {
  try {
    yield put(
      openErrorInfoModal({
        message,
        title,
        buttonText,
        buttonCallback,
      }),
    );
  } catch (e) {
    console.log('error imsErrorHandler', e); // eslint-disable-line
  }
}

function* getCashInitRequestHandler(action) {
  const { serviceId, failureCallback: buttonCallback, successCallback } = action.payload;
  yield put(cashStartLoading());
  try {
    const {
      data: { status, sessionId },
    } = yield call(getCashSession, { serviceId });

    if (!status && sessionId) {
      yield put(getCashSessionRequestSuccess({ sessionId }));
      if (successCallback) successCallback();
    } else {
      yield call(imsErrorHandler, {
        message: IMS_EXEC_FAILED_MESSAGE,
        buttonText: '戻る',
        buttonCallback,
      });
    }
  } catch (e) {
    yield call(errorHandler, { buttonText: '戻る', error: e, buttonCallback });
  } finally {
    yield put(cashEndLoading());
  }
}

function* getBankListInfoRequestHandler(action) {
  try {
    const { serviceId } = action.payload;

    const marginGroup = yield select((state) => state.settings.accountInfo[serviceId]?.marginGroupId);

    const { data: bankList } = yield call(getBankList, { serviceId, marginGroup });
    yield put(getBankListRequestSuccess({ bankList }));
  } catch (e) {
    yield call(errorHandler, { buttonText: '戻る', error: e });
  }
}

function* getCanDepositRequestHandler(action) {
  const { serviceId, failureCallback } = action.payload;
  try {
    const sessionId = yield select((state) => state.cash.sessionId);

    const {
      data: { success },
    } = yield call(getCanDeposit, { serviceId, sessionId });

    if (!success) {
      yield call(imsErrorHandler, {
        title: 'エラー',
        message: IMS_EXEC_FAILED_MESSAGE,
        buttonText: '戻る',
        buttonCallback: failureCallback,
      });
    } else {
      yield put(getCanDepositRequestSuccess({ isSuccess: success }));
    }
  } catch (e) {
    yield call(errorHandler, { error: e, buttonCallback: failureCallback });
  }
}

function* getDepositOnlyBankInfoRequestHandler(action) {
  const { serviceId, failureCallback } = action.payload;
  const sessionId = yield select((state) => state.cash.sessionId);

  try {
    const { data } = yield call(getDepositOnlyBankInfo, { serviceId, sessionId });
    const { priorityServiceCd, exbankList, status } = data;

    if (!status) {
      yield put(
        getDepositOnlyBankInfoRequestSuccess({
          depositOnlyBankInfo: {
            ...exbankList[0],
            priorityServiceCd,
          },
        }),
      );
    } else {
      yield call(imsErrorHandler, {
        title: 'エラー',
        message: IMS_EXEC_FAILED_MESSAGE,
        buttonText: '戻る',
        buttonCallback: failureCallback,
      });
    }
  } catch (e) {
    yield call(errorHandler, { error: e, buttonCallback: failureCallback });
  }
}

function* getBankInfoRequestHandler(action) {
  const { bankCode } = action.payload;

  try {
    const { serviceId } = action.payload;
    const sessionId = yield select((state) => state.cash.sessionId);
    const { data: bankInfo } = yield call(getBankInfo, { serviceId, bankCode, sessionId });

    yield put(getBankInfoRequestSuccess({ bankInfo }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

function* getQuickDepositTermsAgreeRequestHandler(action) {
  const { serviceId, failureCallback } = action.payload;
  try {
    const sessionId = yield select((state) => state.cash.sessionId);

    const { data } = yield call(getRulesAgree, { serviceId, sessionId });

    const { readFlg, linkUrl, disclaimerCd } = data[0];

    yield put(changeQuickDepositTermsAgreed({ readFlg }));
    yield put(getQuickDepositTermsAgreeRequestSuccess({ linkUrl, disclaimerCd }));

    yield call(getCanDepositRequestHandler, { payload: { serviceId, failureCallback } });
  } catch (e) {
    yield call(errorHandler, { error: e, buttonCallback: failureCallback });
  }
}

function* postQuickDepositTermsAgreeRequestHandler(action) {
  const { serviceId, failureCallback } = action.payload;
  try {
    const sessionId = yield select((state) => state.cash.sessionId);
    const { disclaimerCd, readTs } = yield select((state) => state.cash.disclaimer);

    const {
      data: { status },
    } = yield call(postRulesAgree, { serviceId, sessionId, disclaimerCd, readTs });

    if (!status) {
      yield put(changeQuickDepositTermsAgreed({ readFlg: true }));
    } else {
      yield call(imsErrorHandler, {
        title: 'エラー',
        message: IMS_EXEC_FAILED_MESSAGE,
        buttonText: '戻る',
        buttonCallback: failureCallback,
      });
    }
  } catch (e) {
    yield call(errorHandler, { error: e, buttonCallback: failureCallback });
  }
}

// TODO CFD もしかしたらいらないかも
function* getCashMenuMarginInfoRequestHandler(action) {
  const callback = action?.payload?.callback;

  try {
    const accountInfo = yield* getAccountInfo();
    if (!accountInfo[FX].isNotAvailable) {
      const { data: FXMarginData } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getMargin, FX);
      yield put(getMarginSuccess({ marginData: FXMarginData, serviceId: FX }));
    }

    if (!accountInfo[ETF].isNotAvailable) {
      const { data: ETFMarginData } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getMargin, ETF);
      yield put(getMarginSuccess({ marginData: ETFMarginData, serviceId: ETF }));
    }

    if (!accountInfo[CFD].isNotAvailable) {
      const { data: CFDMarginData } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getMargin, CFD);
      yield put(getMarginSuccess({ marginData: CFDMarginData, serviceId: CFD }));
    }

    yield put(changeIsMarginRefreshed({ isMarginRefreshed: true }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    if (callback) callback();
  }
}

export default function* cashSaga() {
  yield takeLatest(getCashInitRequest.type, getCashInitRequestHandler);
  yield takeLatest(getBankListRequest.type, getBankListInfoRequestHandler);
  yield takeLatest(getCanDepositRequest.type, getCanDepositRequestHandler);
  yield takeLatest(getDepositOnlyBankInfoRequest.type, getDepositOnlyBankInfoRequestHandler);
  yield takeEvery(getBankInfoRequest.type, getBankInfoRequestHandler);
  yield takeLatest(getCashMenuMarginInfoRequest.type, getCashMenuMarginInfoRequestHandler);
  yield takeLatest(getQuickDepositTermsAgreeRequest.type, getQuickDepositTermsAgreeRequestHandler);
  yield takeLatest(postQuickDepositTermsAgreeRequest.type, postQuickDepositTermsAgreeRequestHandler);
}
