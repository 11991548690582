import Decimal from 'decimal.js';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NumberTotal from '../TotalItem/NumberTotal';
import HighlightValue from '../HighlightValue';
import { filterPosition } from './common';

export const TradeInfoTotalPl = memo(({ isManual }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const positions = useSelector((state) => state.currencies.positions[serviceId]);
  const tableMetaInfo = useSelector((state) => state.manualTrade.positionsDataMetaInfo[serviceId]);

  const positionsUnrealizedPL = useSelector((state) => state.currencies.positionsUnrealizedProfitLoss);

  const filteredPositionData = useMemo(() => {
    const { tradeMethods, instrumentId, side } = tableMetaInfo;
    return positions
      .filter((item) => filterPosition({ item, tradeMethods, instrumentId, side, isManual }))
      .map((data) => ({ ...data, totalPl: data.pl + data.unrealizedSwapPl }));
  }, [positions, tableMetaInfo, isManual]);

  if (filteredPositionData.length === 0) return '-';

  const total = filteredPositionData.reduce((sum, item) => {
    const pl = positionsUnrealizedPL?.[item?.instrumentId]?.[item?.positionId]?.unrealizedProfitLoss;
    const totalPl = Decimal.add(Math.floor(pl), Math.floor(item.unrealizedSwapPl));
    return Decimal.add(sum, totalPl ?? 0).toNumber();
  }, 0);

  return <NumberTotal label="計" value={<HighlightValue value={total} isToFormat />} />;
});

TradeInfoTotalPl.propTypes = {
  isManual: PropTypes.bool,
};

TradeInfoTotalPl.defaultProps = {
  isManual: false,
};
