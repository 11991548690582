import { call, put, select } from 'redux-saga/effects';
import { getExecutions } from '../../../api/manualTradeApi';
import {
  changeExecutionsTableMetaInfo,
  executionsTableStartLoading,
  executionsTableStopLoading,
  getExecutionsTableDataSuccess,
} from '../../actions/manualTradeActions';
import { errorHandler } from '../errorSaga';
import { checkIsWebApp, date30DaysBefore, isMoreThanOneYearAgo } from '../../../services';
import { toTradeMethodsParameter } from './utils';
import { removeSuffix } from '../../../hooks/symbol';

export default function* getExecutionsTableDataRequestHandler(action) {
  const serviceId = yield select((state) => state.auth.serviceId);
  try {
    yield put(executionsTableStartLoading({ serviceId }));

    const {
      payload: { isFirstData, isManual },
    } = action;

    let tableMetaInfo = yield select((state) => state.manualTrade.executionsDataMetaInfo[serviceId]);
    if (!checkIsWebApp()) {
      if (isFirstData) {
        if (tableMetaInfo.fromDate == null || tableMetaInfo.toDate == null) {
          const fromDate = date30DaysBefore();
          const toDate = new Date();
          yield put(changeExecutionsTableMetaInfo({ key: 'fromDate', value: fromDate, serviceId }));
          yield put(changeExecutionsTableMetaInfo({ key: 'toDate', value: toDate, serviceId }));
          yield put(changeExecutionsTableMetaInfo({ key: 'searchedFromDate', value: fromDate, serviceId }));
          yield put(changeExecutionsTableMetaInfo({ key: 'searchedToDate', value: toDate, serviceId }));
          tableMetaInfo = yield select((state) => state.manualTrade.executionsDataMetaInfo[serviceId]);
        } else {
          yield put(
            changeExecutionsTableMetaInfo({ key: 'searchedFromDate', value: tableMetaInfo.fromDate, serviceId }),
          );
          yield put(changeExecutionsTableMetaInfo({ key: 'searchedToDate', value: tableMetaInfo.toDate, serviceId }));
        }
      } else if (tableMetaInfo.fromDate == null || tableMetaInfo.toDate == null) {
        // リセット条件にバックグラウンド移行時が追加された場合の措置
        // 上記の場合、resetExecutionsTableMetaInfoDateHandler でリセットが行われ、searchedXXX には値が残っていることを期待する
        yield put(changeExecutionsTableMetaInfo({ key: 'fromDate', value: tableMetaInfo.searchedFromDate, serviceId }));
        yield put(changeExecutionsTableMetaInfo({ key: 'toDate', value: tableMetaInfo.searchedToDate, serviceId }));
        tableMetaInfo = yield select((state) => state.manualTrade.executionsDataMetaInfo[serviceId]);
      } else {
        yield put(changeExecutionsTableMetaInfo({ key: 'searchedFromDate', value: tableMetaInfo.fromDate, serviceId }));
        yield put(changeExecutionsTableMetaInfo({ key: 'searchedToDate', value: tableMetaInfo.toDate, serviceId }));
      }
    }

    // don't search if time is more then 1 year ago
    if (isMoreThanOneYearAgo(tableMetaInfo.fromDate)) {
      yield put(
        getExecutionsTableDataSuccess({
          data: [],
          totalPages: 1,
          pageNumber: 1,
          isFirstData: true,
          serviceId,
        }),
      );
      return;
    }

    if (isFirstData) {
      yield put(
        getExecutionsTableDataSuccess({
          data: [],
          totalPages: 1,
          pageNumber: 1,
          isFirstData,
          serviceId,
        }),
      );
    }

    const newPageNumber = isFirstData ? 1 : tableMetaInfo.pageNumber + 1;
    if (!isFirstData && newPageNumber > tableMetaInfo.totalPages) {
      return;
    }

    const {
      data: {
        list: data,
        pageInfo: { totalPages, pageNumber },
        summary: {
          summarizedPl,
          summarizedSwap,
          summarizedDividend,
          summarizedComm,
          summarizedBuyQuantity,
          summarizedSellQuantity,
        },
      },
    } = yield call(getExecutions, {
      pageNumber: newPageNumber,
      sortBy: tableMetaInfo.sortBy,
      sortDir: tableMetaInfo.sortDir,
      fromDate: tableMetaInfo.fromDate,
      toDate: tableMetaInfo.toDate,
      instrumentId: removeSuffix(tableMetaInfo.instrumentId),
      tradeMethods: toTradeMethodsParameter(tableMetaInfo.tradeMethods, serviceId, isManual),
      serviceId,
      isClose: tableMetaInfo.isClose,
      side: tableMetaInfo.side,
    });

    yield put(
      getExecutionsTableDataSuccess({
        data,
        totalPages,
        pageNumber,
        isFirstData,
        serviceId,
        summarizedPl,
        summarizedSwap,
        summarizedDividend,
        summarizedComm,
        summarizedBuyQuantity,
        summarizedSellQuantity,
      }),
    );
  } catch (e) {
    yield call(errorHandler, { error: e });

    yield put(
      getExecutionsTableDataSuccess({
        data: [],
        totalPages: 1,
        pageNumber: 1,
        isFirstData: true,
        serviceId,
      }),
    );
  } finally {
    yield put(executionsTableStopLoading({ serviceId }));
  }
}
