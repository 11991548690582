import {
  CFD,
  COMPREHENSIVE_EVALUATION_LABEL,
  ETF,
  FX,
  LABEL_DOING_WELL,
  LABEL_LESS_TRADING,
  RECOMMENDED_MARGIN_LABEL,
  NARROWING_DOWN,
  SORTING,
  SEARCHING,
  OPERATION_STYLE,
  ASSUMED_OPERATION_PERIOD,
  COMPREHENSIVE_EVALUATION,
  FUNCTION_DESCRIPTION,
  DISPLAY_DESCRIPTION,
} from '.';

export const RANGE_OUT_ALERT_MESSAGE = 'このルールは現在レンジアウトしています';
export const ADD_TO_CART = 'カートに追加';
export const RUN_NOW = '今すぐ稼働';
export const RECOMMENDATION = 'おすすめ';
export const CONFIRM_MESSAGES = Object.freeze([
  'こちらは一般の投資家が作成したロジックです',
  '注文設定をよく確認いただいた上で運用をご検討ください。',
]);
export const MESSAGE_NO_RESULT = '条件に一致する自動売買ルールが見つかりませんでした。';

export const HOW_TO_READ_CARD_TITLE_LINES = [FUNCTION_DESCRIPTION, DISPLAY_DESCRIPTION];
export const HOW_TO_READ_CARD_BOLD_LINES = [
  NARROWING_DOWN,
  SORTING,
  SEARCHING,
  OPERATION_STYLE,
  ASSUMED_OPERATION_PERIOD,
  LABEL_DOING_WELL,
  LABEL_LESS_TRADING,
  COMPREHENSIVE_EVALUATION,
];
export const HOW_TO_READ_CARD = [
  FUNCTION_DESCRIPTION,
  NARROWING_DOWN,
  '　運用スタイルや運用の目安となる期間や資金を指定してルールを検索できます。',
  SORTING,
  '　総合評価順、収益率順または人気順で並び替えできます。',
  SEARCHING,
  '　選択しているカテゴリに対して、ルール名で検索できます。',
  DISPLAY_DESCRIPTION,
  OPERATION_STYLE,
  '　上昇狙い / 下落狙い / レンジ狙い',
  ASSUMED_OPERATION_PERIOD,
  '　短期（3か月未満） / 中期（3か月以上～6か月未満） / 長期（6か月以上）',
  LABEL_DOING_WELL,
  '　直近30日間のシミュレーションにおいてパフォーマンスが良いルールに付与されます。',
  LABEL_LESS_TRADING,
  '　過去1年間の決済取引回数が60回未満、取引が少ないルールに付与されます。',
  COMPREHENSIVE_EVALUATION,
  '　1～5',
  '　シミュレーションにおける最大ドローダウン、収益率、取引頻度、レンジ位置を総合的に評価し、5段階評価で表しています。',
  '　評価の数字が大きいほどルールの総合力が高いと考えられます。',
];

export const HOW_TO_READ_CARD_TITLE_LINES_MOBILE = [FUNCTION_DESCRIPTION, DISPLAY_DESCRIPTION];
export const HOW_TO_READ_CARD_BOLD_LINES_MOBILE = [
  NARROWING_DOWN,
  SORTING,
  SEARCHING,
  OPERATION_STYLE,
  ASSUMED_OPERATION_PERIOD,
  LABEL_DOING_WELL,
  LABEL_LESS_TRADING,
  COMPREHENSIVE_EVALUATION,
];
export const HOW_TO_READ_CARD_MOBILE = [
  FUNCTION_DESCRIPTION,
  NARROWING_DOWN,
  '運用スタイルや運用の目安となる期間や資金を指定してルールを検索できます。',
  '',
  SORTING,
  '総合評価順、収益率順または人気順で並び替えできます。',
  '',
  SEARCHING,
  '選択しているカテゴリに対して、ルール名で検索できます。',
  '',
  DISPLAY_DESCRIPTION,
  OPERATION_STYLE,
  '上昇狙い / 下落狙い / レンジ狙い',
  '',
  ASSUMED_OPERATION_PERIOD,
  '短期（3か月未満） / 中期（3か月以上～6か月未満） / 長期（6か月以上）',
  '',
  LABEL_DOING_WELL,
  '直近30日間のシミュレーションにおいてパフォーマンスが良いルールに付与されます。',
  '',
  LABEL_LESS_TRADING,
  '過去1年間の決済取引回数が60回未満、取引が少ないルールに付与されます。',
  '',
  COMPREHENSIVE_EVALUATION,
  '1～5',
  'シミュレーションにおける最大ドローダウン、収益率、取引頻度、レンジ位置を総合的に評価し、5段階評価で表しています。',
  '評価の数字が大きいほどルールの総合力が高いと考えられます。',
];

export const MARGINS = Object.freeze([
  Object.freeze({ id: '0', value: '50万円未満' }),
  Object.freeze({ id: '1', value: '50〜100万円未満' }),
  Object.freeze({ id: '2', value: '100〜200万円未満' }),
  Object.freeze({ id: '3', value: '200万円以上' }),
]);

export const PERIODS = Object.freeze([
  Object.freeze({ id: '0', value: '短期' }),
  Object.freeze({ id: '1', value: '中期' }),
  Object.freeze({ id: '2', value: '長期' }),
]);

export const COMPREHENSIVE_EVALUATIONS = Object.freeze([
  Object.freeze({ id: '1', value: '1' }),
  Object.freeze({ id: '2', value: '2' }),
  Object.freeze({ id: '3', value: '3' }),
  Object.freeze({ id: '4', value: '4' }),
  Object.freeze({ id: '5', value: '5' }),
]);

export const ID_RANGE_OUTS_EXCLUDE_RANGE_OUT = 'excludeRangeOut';
export const KEY_ASSETS = 'assets';
export const KEY_MARGINS = 'margins';
export const KEY_STYLES = 'styles';
export const KEY_PERIODS = 'periods';
export const KEY_COMPREHENSIVE_EVALUATIONS = 'comprehensiveEvaluations';
export const KEY_RANGE_OUTS = 'rangeOuts';
export const TITLE_FILTER_CONDITION = '絞込';
export const TITLE_ASSET = 'アセット';
export const TITLE_RECOMMENDED_MARGIN = RECOMMENDED_MARGIN_LABEL;
export const TITLE_STYLE = '運用スタイル';
export const TITLE_PERIOD = '運用期間';
export const TITLE_COMPREHENSIVE_EVALUATION = COMPREHENSIVE_EVALUATION_LABEL;
export const TITLE_RANGE_OUT = 'レンジアウトを除く';
export const DESCRIPTION_RANGE_OUT = 'レンジアウトしていないルールが表示されます';
export const LABEL_CLEAR = '条件をクリア';
export const LABEL_OK = 'OK';

export const MULTI_STRATEGY = 'マルチ戦略';

const SHARE_RETURN_TAG_ID_FX = 1000;
const SHARE_RETURN_TAG_ID_ETF = 2000;
const SHARE_RETURN_TAG_ID_CFD = 3000;
const SHARE_RETURN_TAG_ID_ALL = Object.freeze([
  SHARE_RETURN_TAG_ID_FX,
  SHARE_RETURN_TAG_ID_ETF,
  SHARE_RETURN_TAG_ID_CFD,
]);

export const TAG_ID_FX = 1;
export const TAG_ID_ETF = 2;
export const TAG_ID_CFD = 3;
export const TAG_ID_ALL = 9;

export const SHARE_RETURN_TAG_ID_MAPPING = Object.freeze({
  [TAG_ID_FX]: SHARE_RETURN_TAG_ID_FX,
  [TAG_ID_ETF]: SHARE_RETURN_TAG_ID_ETF,
  [TAG_ID_CFD]: SHARE_RETURN_TAG_ID_CFD,
  [TAG_ID_ALL]: SHARE_RETURN_TAG_ID_ALL,
});

export const SERVICE_TAG_MAPPING = Object.freeze({
  [FX]: TAG_ID_FX,
  [ETF]: TAG_ID_ETF,
  [CFD]: TAG_ID_CFD,
});

export const TAG_SERVICE_MAPPING = Object.freeze({
  [TAG_ID_FX]: FX,
  [TAG_ID_ETF]: ETF,
  [TAG_ID_CFD]: CFD,
});

export const ORDER_RETURN = 0;
export const ORDER_POPULAR = 3;
export const ORDER_EVALUATION = 4;

export const SELECTION_OPTIONS = Object.freeze([
  Object.freeze({ label: '総合評価順', value: ORDER_EVALUATION }),
  Object.freeze({ label: '収益率順', value: ORDER_RETURN }),
  Object.freeze({ label: '人気順', value: ORDER_POPULAR }),
]);

export const SELECTION_OPTIONS_SELECTED_LABEL_MAP = SELECTION_OPTIONS.reduce((acc, cur) => {
  const selectedLabels = { [ORDER_EVALUATION]: '評価順' };
  const { value } = cur;
  const label = selectedLabels[value] ?? cur.label;
  return {
    ...acc,
    [value]: label,
  };
}, {});

export const DEFAULT_TERM_ID = '3';
