import classNames from 'classnames';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { tutorialAssetDetails, tutorialAssetDetailsMargin } from '../../../../../constants/tutorial/classNames';
import { MarginText } from '../../../../../components/Texts';
import { InfoTooltip } from '../../../../../components';
import { AutoSelectAssetDetail } from '../AutoSelectAssetDetail';
import styles from './assetDetails.module.scss';

export const AssetDetails = memo(({ data, totalMargin, totalMarginRequired, closeModal }) => {
  const singleAsset = data.length === 1;
  const isVisibleMarginTextSum = data.length !== 1 ? '合計' : '';
  return (
    <div className={tutorialAssetDetails}>
      {data.map(({ serviceId, recommendedMargin, requiredMargin, onClick }) => (
        <AutoSelectAssetDetail
          key={serviceId}
          serviceId={serviceId}
          recommendedMargin={recommendedMargin}
          requiredMargin={requiredMargin}
          onClick={onClick}
          closeModal={closeModal}
          hideMarginInfo={singleAsset}
        />
      ))}

      <div className={styles.marginArea}>
        <div className={classNames(styles.row, tutorialAssetDetailsMargin)}>
          <div className={styles.labelContainer}>
            {totalMargin.label + isVisibleMarginTextSum}
            {totalMargin.tooltip && <InfoTooltip message={totalMargin.tooltip} />}
          </div>
          <MarginText className={styles.value} value={totalMargin.get} />
        </div>
        <div className={styles.row}>
          <div className={styles.labelContainer}>
            {totalMarginRequired.label + isVisibleMarginTextSum}
            {totalMarginRequired.tooltip && <InfoTooltip message={totalMarginRequired.tooltip} />}
          </div>
          <MarginText className={styles.value} value={totalMarginRequired?.get} />
        </div>
      </div>
    </div>
  );
});

AssetDetails.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      serviceId: PropTypes.string.isRequired,
      requiredMargin: PropTypes.shape({ get: PropTypes.number }),
      recommendedMargin: PropTypes.shape({ get: PropTypes.number }),
    }),
  ).isRequired,
  totalMargin: PropTypes.shape({
    get: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }).isRequired,
  totalMarginRequired: PropTypes.shape({
    get: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func,
};

AssetDetails.defaultProps = {
  closeModal: () => {},
};
