import { memo } from 'react';
import PropTypes from 'prop-types';
import { useInstrumentShortName } from 'shared-modules/hooks';

export const ShortName = memo(({ instrumentId }) => {
  const instrumentShortName = useInstrumentShortName(instrumentId);
  return instrumentShortName;
});

ShortName.propTypes = {
  instrumentId: PropTypes.string.isRequired,
};
