import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PositionQuickCloseButton from './components/PositionQuickCloseButton';

const CellPortfolioPositionTableActions = ({ row, isForInstrument }) => {
  const { positionId, apGroupId, instrumentId } = row.original;
  const usedInstrumentId = isForInstrument ? instrumentId : null;
  return <PositionQuickCloseButton positionId={positionId} apGroupId={apGroupId} instrumentId={usedInstrumentId} />;
};

CellPortfolioPositionTableActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      positionId: PropTypes.string,
      apGroupId: PropTypes.string,
      instrumentId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isForInstrument: PropTypes.bool,
};

CellPortfolioPositionTableActions.defaultProps = {
  isForInstrument: false,
};

export default memo(CellPortfolioPositionTableActions);
