import { getDefaultValuesFromLocalStorage } from '../../services';
import {
  KEY_FOR_DEFAULT_CHART_RESOLUTION,
  KEY_FOR_DISPLAY_POSITIONS,
  DEFAULT_DISPLAY_POSITIONS,
  KEY_FOR_DISPLAY_CLOSED_ORDERS,
  DEFAULT_DISPLAY_CLOSED_ORDERS,
  KEY_FOR_DISPLAY_NEW_ORDERS,
  DEFAULT_DISPLAY_NEW_ORDERS,
} from '../../constants/chart';
import { CHART_RESOLUTION_MAIN } from '../../constants';
import {
  CHANGE_CHART_RESOLUTION,
  TOGGLE_DISPLAY_CLOSED_ORDERS_FLAG,
  TOGGLE_DISPLAY_NEW_ORDERS_FLAG,
  TOGGLE_DISPLAY_POSITIONS_FLAG,
  CHANGE_DISPLAY_CLOSED_ORDERS_FLAG,
  CHANGE_DISPLAY_NEW_ORDERS_FLAG,
  CHANGE_DISPLAY_POSITIONS_FLAG,
  CHANGE_CHART_RESOLUTION_SELECT,
} from '../actionConstants/chartConstants';

const initialState = () => ({
  resolution: getDefaultValuesFromLocalStorage({
    key: KEY_FOR_DEFAULT_CHART_RESOLUTION,
    defaultValue: CHART_RESOLUTION_MAIN.HOURS_1.TV_ID,
  }),
  resolutionSelect: CHART_RESOLUTION_MAIN.DAYS_1.TV_ID,
  displayPositions:
    getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DISPLAY_POSITIONS,
      defaultValue: DEFAULT_DISPLAY_POSITIONS.toString(),
    }) === 'true',
  displayNewOrders:
    getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DISPLAY_NEW_ORDERS,
      defaultValue: DEFAULT_DISPLAY_NEW_ORDERS.toString(),
    }) === 'true',
  displayClosedOrders:
    getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DISPLAY_CLOSED_ORDERS,
      defaultValue: DEFAULT_DISPLAY_CLOSED_ORDERS.toString(),
    }) === 'true',
});

export default (state = initialState(), action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_CHART_RESOLUTION: {
      const { resolution } = payload;
      return {
        ...state,
        resolution,
      };
    }
    case CHANGE_CHART_RESOLUTION_SELECT: {
      const { resolutionSelect } = payload;
      return {
        ...state,
        resolutionSelect,
      };
    }
    case TOGGLE_DISPLAY_CLOSED_ORDERS_FLAG: {
      return {
        ...state,
        displayClosedOrders: !state.displayClosedOrders,
      };
    }
    case TOGGLE_DISPLAY_NEW_ORDERS_FLAG: {
      return {
        ...state,
        displayNewOrders: !state.displayNewOrders,
      };
    }
    case TOGGLE_DISPLAY_POSITIONS_FLAG: {
      return {
        ...state,
        displayPositions: !state.displayPositions,
      };
    }
    case CHANGE_DISPLAY_CLOSED_ORDERS_FLAG: {
      return {
        ...state,
        displayClosedOrders: payload.displayClosedOrders,
      };
    }
    case CHANGE_DISPLAY_NEW_ORDERS_FLAG: {
      return {
        ...state,
        displayNewOrders: payload.displayNewOrders,
      };
    }
    case CHANGE_DISPLAY_POSITIONS_FLAG: {
      return {
        ...state,
        displayPositions: payload.displayPositions,
      };
    }
    default: {
      return state;
    }
  }
};
