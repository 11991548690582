import { memo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './checkButton.module.scss';

const Checked = memo(() => (
  <svg
    className={styles.checkedIcon}
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.88458 10L0.214844 5.23404L1.11127 4.31915L4.88458 8.17021L12.8898 0L13.7863 0.914894L4.88458 10Z" />
  </svg>
));

export const CheckButton = memo(({ checked, label, className, onChange }) => {
  const handleClick = useCallback(() => {
    onChange?.(!checked, label);
  }, [onChange, checked, label]);
  const handleKeyDown = useKeyDownHandler(handleClick);
  return (
    <div
      role="button"
      tabIndex="0"
      className={classNames(styles.container, { [styles.checked]: checked }, className)}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <div className={styles.content}>
        {checked && <Checked />}
        <div className={classNames(styles.label, { [styles.checked]: checked })}>{label}</div>
      </div>
    </div>
  );
});

CheckButton.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

CheckButton.defaultProps = {
  checked: undefined,
  label: undefined,
  className: undefined,
  onChange: undefined,
};
