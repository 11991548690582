import { memo } from 'react';
import classNames from 'classnames';
import ValueWithPrecision from '../../../../../../components/ValueWithPrecision';
import { ReactComponent as DownArrow } from '../../../../../../assets/downArrow.svg';
import { ReactComponent as UpArrow } from '../../../../../../assets/upArrow.svg';
import { PriceValue } from '../PriceValue';
import styles from './priceValueCFD.module.scss';

const PriceValueCFD = ({ priceValue, instrumentId, previousValue, label, isReversed }) => (
  <div className={classNames(styles.container, { [styles.right]: isReversed })}>
    <div>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{label}</div>
      </div>
      <div className={styles.valueContainer}>
        {Boolean(previousValue) && priceValue < previousValue && (
          <DownArrow className={styles.downArrow} key={priceValue} />
        )}
        {Boolean(previousValue) && priceValue > previousValue && (
          <UpArrow className={styles.upArrow} key={priceValue} />
        )}
        <ValueWithPrecision value={priceValue} instrumentId={instrumentId} />
      </div>
    </div>
  </div>
);

PriceValueCFD.propTypes = PriceValue.propTypes;

PriceValueCFD.defaultProps = { ...PriceValue.defaultProps };

export default memo(PriceValueCFD);
