export default (PineJS) => [
  {
    name: 'RCI',
    metainfo: {
      _metainfoVersion: 42,
      id: 'rci@tv-basicstudies-1',
      name: 'RCI',
      description: 'RCI',
      shortDescription: 'RCI',
      scriptIdPart: '',
      is_price_study: false,
      is_hidden_study: false,
      isCustomIndicator: true,
      inputs: [
        {
          id: 'short_interval',
          type: 'integer',
          name: '短期',
          defval: 9,
          min: 1,
          max: 100,
        },
        {
          id: 'middle_interval',
          type: 'integer',
          name: '中期',
          defval: 36,
          min: 1,
          max: 100,
        },
        {
          id: 'long_interval',
          type: 'integer',
          name: '長期',
          defval: 52,
          min: 1,
          max: 100,
        },
        {
          id: 'source',
          type: 'source',
          name: 'Source',
          defval: 'close',
        },
        {
          id: 'upperband',
          type: 'integer',
          name: 'High line[%]',
          defval: 80,
          min: 1,
          max: 100,
        },
        {
          id: 'lowerband',
          type: 'integer',
          name: 'Low line[%]',
          defval: -80,
          min: -100,
          max: -1,
        },
      ],
      plots: [
        {
          id: 'plot_0',
          type: 'line',
        },
        {
          id: 'plot_1',
          type: 'line',
        },
        {
          id: 'plot_2',
          type: 'line',
        },
        {
          id: 'plot_3',
          type: 'line',
        },
        {
          id: 'plot_4',
          type: 'line',
        },
      ],
      styles: {
        plot_0: {
          title: 'Short interval',
          histogramBase: 0,
        },
        plot_1: {
          title: 'Middle interval',
          histogramBase: 0,
        },
        plot_2: {
          title: 'Long interval',
          histogramBase: 0,
        },
        plot_3: {
          title: 'High line',
          histogramBase: 0,
        },
        plot_4: {
          title: 'Low line',
          histogramBase: 0,
        },
      },
      defaults: {
        inputs: {
          short_interval: 9,
          middle_interval: 36,
          long_interval: 52,
          source: 'close',
          upperband: 80,
          lowerband: -80,
        },
        precision: 2,
        styles: {
          plot_0: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: false,
            transparency: 35,
            color: 'red',
          },
          plot_1: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: false,
            transparency: 35,
            color: 'blue',
          },
          plot_2: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: false,
            transparency: 35,
            color: 'green',
          },
          plot_3: {
            linestyle: 1,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: false,
            transparency: 40,
            color: '#FFF',
          },
          plot_4: {
            linestyle: 1,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: false,
            transparency: 40,
            color: '#FFF',
          },
        },
      },
    },
    // eslint-disable-next-line object-shorthand, func-names
    constructor: function () {
      // eslint-disable-next-line func-names
      this.main = function (context, input) {
        /* eslint-disable no-underscore-dangle */
        this._context = context;
        this._input = input;

        const shortInterval = this._input(0);
        const middleInterval = this._input(1);
        const longInterval = this._input(2);
        const sourceName = this._input(3);
        const upperBand = this._input(4);
        const lowerBand = this._input(5);

        const source = this._context.new_var(PineJS.Std[sourceName](this._context));
        /* eslint-enable no-underscore-dangle */

        const ord = (seq, idx, itv) => {
          const p = seq.get(idx);
          let o = 1;
          let s = 0;

          for (let i = 0; i < itv; i += 1) {
            if (p < seq.get(i)) {
              o += 1;
            } else if (p === seq.get(i)) {
              s += 1;
            }
          }
          return o + (s - 1) / 2.0;
        };

        const d = (itv) => {
          let sum = 0;
          for (let i = 0; i < itv; i += 1) {
            sum += PineJS.Std.pow(i + 1 - ord(source, i, itv), 2);
          }
          return sum;
        };

        const rci = (itv) => (1 - (6 * d(itv)) / (itv * (itv * itv - 1))) * 100;

        return [rci(shortInterval), rci(middleInterval), rci(longInterval), upperBand, lowerBand];
      };
    },
  },
  {
    name: '移動平均乖離率',
    metainfo: {
      _metainfoVersion: 42,
      id: 'moving_average_deviation_rate@tv-basicstudies-1',
      name: '移動平均乖離率',
      description: '移動平均乖離率',
      shortDescription: '移動平均乖離率',
      scriptIdPart: '',
      is_price_study: false,
      is_hidden_study: false,
      isCustomIndicator: true,
      inputs: [
        {
          id: 'period',
          type: 'integer',
          name: '期間',
          defval: 25,
          min: 1,
          max: 200,
        },
      ],
      plots: [
        {
          id: 'plot_0',
          type: 'line',
        },
        {
          id: 'plot_1',
          type: 'line',
        },
        {
          id: 'plot_2',
          type: 'line',
        },
        {
          id: 'plot_3',
          type: 'line',
        },
        {
          id: 'plot_4',
          type: 'colorer',
          target: 'filled_area_1',
        },
      ],
      filledAreas: [
        {
          id: 'filled_area_1',
          objAId: 'plot_2',
          objBId: 'plot_3',
          title: '背景',
          type: 'plot_plot',
        },
      ],
      styles: {
        plot_0: {
          title: '期間',
          histogramBase: 0,
        },
        plot_1: {
          title: 'プロット1',
          histogramBase: 0,
        },
        plot_2: {
          title: 'プロット2',
          histogramBase: 0,
        },
        plot_3: {
          title: 'プロット3',
          histogramBase: 0,
        },
      },
      defaults: {
        inputs: {
          period: 25,
        },
        precision: 2,
        styles: {
          plot_0: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: false,
            transparency: 35,
            color: 'red',
          },
          plot_1: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: false,
            transparency: 35,
            color: 'green',
          },
          plot_2: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: false,
            transparency: 35,
            color: 'green',
          },
          plot_3: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: false,
            transparency: 35,
            color: 'green',
          },
        },
        filledAreasStyle: {
          filled_area_1: {
            color: '#0f0',
            transparency: 90,
            visible: false,
          },
        },
      },
    },
    // eslint-disable-next-line object-shorthand, func-names
    constructor: function () {
      // eslint-disable-next-line func-names
      this.main = function (context, input) {
        /* eslint-disable no-underscore-dangle */
        this._context = context;
        this._input = input;

        const period = this._input(0);
        const close = this._context.new_var(PineJS.Std.close(this._context));
        const sma = PineJS.Std.sma(close, period, this._context);
        const rate = this._context.new_var((close / sma) * 100 - 100);

        const stdCenter = PineJS.Std.sma(rate, period * 2, this._context);
        const std = PineJS.Std.stdev(rate, period * 2, this._context);
        /* eslint-enable no-underscore-dangle */
        const plusDev = stdCenter + std * 2;
        const minusDev = stdCenter - std * 2;

        return [std, plusDev, stdCenter, minusDev];
      };
    },
  },
];
