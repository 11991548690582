import { SORT_DESCENDING } from './manualTrade';
import { CFD, ETF, FX, TRADE_TABLES } from './index';

export const DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY = 'execTime';
export const DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR = SORT_DESCENDING;

export const DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_BY = 'execTime';
export const DEFAULT_PORTFOLIO_POSITIONS_TABLE_SORT_DIR = SORT_DESCENDING;

export const KEY_FOR_SELECTED_PORTFOLIO_INSTRUMENTS = 'KEY_FOR_SELECTED_PORTFOLIO_INSTRUMENTS';

export const KEY_FOR_DEFAULT_SELECTED_FX_INSTRUMENTS = 'KEY_FOR_DEFAULT_SELECTED_FX_INSTRUMENTS';
export const KEY_FOR_DEFAULT_SELECTED_ETF_INSTRUMENTS = 'KEY_FOR_DEFAULT_SELECTED_ETF_INSTRUMENTS';
export const KEY_FOR_DEFAULT_SELECTED_CFD_INSTRUMENTS = 'KEY_FOR_DEFAULT_SELECTED_CFD_INSTRUMENTS';
export const KEY_FOR_DEFAULT_SELECTED_PORTFOLIO_INSTRUMENTS = {
  [FX]: KEY_FOR_DEFAULT_SELECTED_FX_INSTRUMENTS,
  [ETF]: KEY_FOR_DEFAULT_SELECTED_ETF_INSTRUMENTS,
  [CFD]: KEY_FOR_DEFAULT_SELECTED_CFD_INSTRUMENTS,
};

export const DEFAULT_TABLE_ID = TRADE_TABLES.ORDERS.ID;

export const TAB_DETAIL = 'DETAIL';
export const TAB_PL_TREND = 'PL_TREND';

export const ACCOUNT_INFORMATION = '詳細';
export const TRADE_PERFORMANCE = '運用成績';

export const KEY_FOR_PORTFOLIO_SUMMARY_CARD_ORDER = 'portfolio.summaryCardOrder';
