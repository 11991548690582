import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { openManualTradeConfig, openManualTradeNews } from '../../../../redux/actions';
import IconButton from '../../../../components/IconButton/IconButton';
import SettingsIcon from '../../../../components/SettingsIcon/SettingsIcon';
import styles from './newsRow.module.scss';

const NewsRow = ({ withTradeSettings, isHeader }) => {
  const dispatch = useDispatch();

  const newsData = useSelector((state) => state.manualTrade.newsData);

  const handleNewsClick = useCallback(() => {
    dispatch(openManualTradeNews());
  }, [dispatch]);

  const handleSettingsClick = useCallback(() => {
    dispatch(openManualTradeConfig());
  }, [dispatch]);

  const firstNewsTitle = useMemo(() => {
    if (newsData?.[0]?.title) return newsData[0].title;
    return '';
  }, [newsData]);

  return (
    <div className={classNames(styles.wrapper, { [styles.header]: isHeader })}>
      <div className={styles.newsRow} onClick={handleNewsClick} role="button" aria-hidden>
        <IconButton onClick={handleNewsClick} iconName="zoom_out_map" className={styles.icon} />
        <div className={styles.label}>NEWS</div>
        <div className={styles.title}>{firstNewsTitle}</div>
      </div>
      {withTradeSettings && <SettingsIcon onClick={handleSettingsClick} className={styles.settings} />}
    </div>
  );
};

NewsRow.propTypes = {
  withTradeSettings: PropTypes.bool,
  isHeader: PropTypes.bool,
};

NewsRow.defaultProps = {
  withTradeSettings: true,
  isHeader: false,
};

export default memo(NewsRow);
