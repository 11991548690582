import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import JPCJPY from '../../assets/flagsCFD/JPCJPY.svg';
import DJCUSD from '../../assets/flagsCFD/DJCUSD.svg';
import NACUSD from '../../assets/flagsCFD/NACUSD.svg';
import SPCUSD from '../../assets/flagsCFD/SPCUSD.svg';
import RTCUSD from '../../assets/flagsCFD/RTCUSD.svg';
import GECEUR from '../../assets/flagsCFD/GECEUR.svg';
import UKCGBP from '../../assets/flagsCFD/UKCGBP.svg';
import Invast from '../../assets/flagsCFD/invast-circle.svg';
import styles from './cfdInstrumentIcon.module.scss';

const ICONS = {
  'JPCJPY.svg': JPCJPY,
  'DJCUSD.svg': DJCUSD,
  'NACUSD.svg': NACUSD,
  'SPCUSD.svg': SPCUSD,
  'RTCUSD.svg': RTCUSD,
  'GECEUR.svg': GECEUR,
  'UKCGBP.svg': UKCGBP,
  'invast-circle.svg': Invast,
};

const FULL_SIZE_ICON = Invast;

const CfdInstrumentIcon = ({ instrumentId, className, size, noFullSize }) => {
  const instrumentMap = useSelector((state) => state.settings.instrumentList);

  const src = useMemo(
    () => _.get(ICONS, _.get(instrumentMap, [instrumentId, 'image']), null),
    [instrumentId, instrumentMap],
  );

  const innerClassName = useMemo(() => {
    return classNames(className, styles[size], { [styles.fullSize]: FULL_SIZE_ICON === src && !noFullSize });
  }, [className, size, src, noFullSize]);

  if (src) {
    return <img className={innerClassName} src={src} alt="" />;
  }
  return <div className={innerClassName} />;
};

CfdInstrumentIcon.propTypes = {
  instrumentId: PropTypes.string.isRequired,
  className: PropTypes.string,
  noFullSize: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small', 'littleTiny', 'tiny']),
};

CfdInstrumentIcon.defaultProps = {
  className: '',
  size: 'medium',
  noFullSize: false,
};

export default memo(CfdInstrumentIcon);
