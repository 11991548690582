import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FX, LAB_SIMULATION_EMPTY_MESSAGE } from 'shared-modules/constants';
import { formatDateTimeWithoutDivider } from 'shared-modules/services';
import { useLabTableData } from 'shared-modules/services/hooks/autoSelectDetails';
import { Table } from '../../../../../../components';
import BuySellItem from '../../../../../../components/BuySellItem/BuySellItem';
import HighlightValue from '../../../../../../components/HighlightValue';
import InstrumentPrecisionTableCell from '../../../../../../components/InstrumentPrecisionTableCell';
import styles from './fxTable.module.scss';
import { getSettingsTableTemplate, orderNameWithToolTips } from '../../../../../../services/tableTemplate';

const DEFAULT_SIMULATION_SORTING = [{ id: 'closeTime', desc: true }];
const DEFAULT_TRADE_SETTINGS_SORTING = [{ id: 'name', desc: false }];

const settingsResized = {
  key: 'autoSelectDetailsFxSettingsTable',
  default: [82, 107, 70, 70, 82, 82, 70, 82, 82, 82, 70, 97],
};
const inquiryResized = {
  key: 'autoSelectDetailsFxInquiryTable',
  default: [70, 119, 70, 83, 116, 108, 103, 70, 90, 78, 70],
};
const orderGroupsResized = {
  key: 'autoSelectOrderGroupsTable',
  default: [1, 1, 1],
};

const FxTable = ({ isLoading, simulationData, defaultSets, selectedTableTypeId }) => {
  const { simulationTradesData, autoTradeSettingsData, orderGroupsData, helpers } = useLabTableData(
    simulationData.strategyList ?? [],
    defaultSets,
    FX,
  );

  const isSimulationStats = simulationData.simulationStats !== null;

  const tableMetaInfo = useMemo(
    () => ({
      loading: isLoading,
      pageNumber: 1,
      totalPages: 1,
    }),
    [isLoading],
  );

  const getOrderName = useCallback(
    ({ itemId, id }) => {
      const itemListValue = autoTradeSettingsData.find(
        (value) => value.itemId === itemId && value.strategyId === Number.parseInt(id, 10),
      );
      if (itemListValue && itemListValue.name) {
        return orderNameWithToolTips(itemListValue.name);
      }
      return '-';
    },
    [autoTradeSettingsData],
  );

  const inquiryColumns = useMemo(
    () => [
      {
        Header: '銘柄',
        accessor: 'instrumentDisplayName',
        Cell: ({ cell: { value } }) => value || '-',
        disableSortBy: true,
      },
      {
        Header: '注文名',
        accessor: 'orderName',
        Cell: ({
          row: {
            original: { itemId, id },
          },
        }) => getOrderName({ itemId, id }),
        disableSortBy: true,
      },
      {
        Header: '売買',
        accessor: 'side',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (value ? <BuySellItem type={Number(value)} /> : '-'),
        disableSortBy: true,
      },
      {
        Header: '数量(万)',
        accessor: 'quantity',
        Cell: ({
          row: {
            original: { quantity, strategySets },
          },
        }) => helpers.getQuantity(quantity, strategySets),
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '新規約定日時',
        accessor: 'openTime',
        Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '新規約定価格',
        accessor: 'openPrice',
        Cell: InstrumentPrecisionTableCell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '決済約定日時',
        accessor: 'closeTime',
        Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '決済約定価格',
        accessor: 'closePrice',
        Cell: InstrumentPrecisionTableCell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '累計スワップ(円)',
        accessor: 'swapInterest',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { swapInterest, strategySets },
          },
        }) =>
          swapInterest && strategySets ? (
            <HighlightValue
              value={helpers.getSwapInterest(swapInterest, strategySets)}
              isToFormat
              withoutPlus
              withYenIcon={false}
            />
          ) : (
            '-'
          ),
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '実現損益(円)',
        accessor: 'realizedPl',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { realizedPl, strategySets },
          },
        }) =>
          realizedPl && strategySets ? (
            <HighlightValue
              value={helpers.getRealizedProfitLoss(realizedPl, strategySets)}
              isToFormat
              withoutPlus
              withYenIcon={false}
            />
          ) : (
            '-'
          ),
        isNumber: true,
        disableSortBy: true,
      },
    ],
    [getOrderName, helpers],
  );

  const settingsColumns = useMemo(
    () =>
      getSettingsTableTemplate().map((row) => {
        if (row.accessor === 'quantity') {
          return {
            ...row,
            disableSortBy: true,
            Cell: ({
              row: {
                original: { quantity, strategySets },
              },
            }) => helpers.getQuantity(quantity, strategySets),
          };
        }
        return {
          ...row,
          disableSortBy: true,
        };
      }),
    [helpers],
  );

  const orderGroupColumns = useMemo(
    () => [
      { Header: '注文グループ名', accessor: 'name', disableSortBy: true },
      { Header: '銘柄', accessor: 'instrumentDisplayName', disableSortBy: true },
      {
        Header: 'セット数',
        accessor: 'strategySets',
        isNumber: false,
        Cell: ({ cell: { value } }) => helpers.getStrategySets(value),
        disableSortBy: true,
      },
    ],
    [helpers],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableWrapper}>
        {selectedTableTypeId === 0 && (
          <>
            {isSimulationStats ? (
              <Table
                tableData={simulationTradesData}
                columns={inquiryColumns}
                resized={inquiryResized}
                className={styles.table}
                defaultSorting={DEFAULT_SIMULATION_SORTING}
                tableMetaInfo={tableMetaInfo}
                key={0}
              />
            ) : (
              <div className={styles.tradesDataEmptyMessageWrapper}>
                <span>{LAB_SIMULATION_EMPTY_MESSAGE}</span>
              </div>
            )}
          </>
        )}
        {selectedTableTypeId === 1 && (
          <Table
            tableData={autoTradeSettingsData}
            columns={settingsColumns}
            defaultSorting={DEFAULT_TRADE_SETTINGS_SORTING}
            resized={settingsResized}
            className={styles.table}
            tableMetaInfo={tableMetaInfo}
            key={1}
          />
        )}
        {selectedTableTypeId === 2 && (
          <Table
            tableData={orderGroupsData}
            columns={orderGroupColumns}
            resized={orderGroupsResized}
            className={styles.table}
            tableMetaInfo={tableMetaInfo}
            maxColumnWidth={490}
            key={2}
          />
        )}
      </div>
      <div className={styles.divider} />
    </div>
  );
};

FxTable.propTypes = {
  selectedTableTypeId: PropTypes.number.isRequired,
  simulationData: PropTypes.shape({
    strategyList: PropTypes.arrayOf(PropTypes.shape({})),
    simulationStats: PropTypes.shape({}),
  }),
  isLoading: PropTypes.bool,
  defaultSets: PropTypes.number,
};
FxTable.defaultProps = {
  simulationData: {},
  isLoading: false,
  defaultSets: 1,
};

export default memo(FxTable);
