import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { BAR_TYPES, BUY_SELL_MAIN, CFD, DISABLE_INSTRUMENTS, INDICATORS } from 'shared-modules/constants';
import { BUILDER_PAGES, INDICATOR1_TOOLTIP_BOLD_LINES } from 'shared-modules/constants/builder';
import useTechBuilderMultiFormLogic from 'shared-modules/services/hooks/techBuilderMultiFormLogic';
import { getServiceId } from 'shared-modules/utils';
import { BackLink, Button, BuySellGroupButton, HelpHalfModal, InputNumber, Switch } from '../../../../components';
import {
  changeBuilderActivePage,
  resetBuilderChartData,
  resetBuilderMultiOrderOptions,
  resetBuilderOrdersTables,
  openTechBuilderConfigurationFlowModal,
} from '../../../../redux/actions';
import { TagButton } from '../../../Builder/components/ConfigurationPage/SideMenu/TagButton';
import styles from './techSideMenu.module.scss';
import CustomSelect from '../../../../components/CustomSelect';
import infoImagesHelper from '../../../../assets/builderInfo/infoImagesHelper';

const shortNumberInputWidth = 80;

const Indicator1Content = memo(({ text }) => {
  return (
    <div className={styles.helpIndicator1}>
      {text
        .replace(/<br ?\/?>/g, '\n')
        .split('\n')
        .map((line, index) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classNames(styles.text, {
              [styles.boldLine]: INDICATOR1_TOOLTIP_BOLD_LINES.includes(line),
            })}
          >
            {line}
            <br />
          </span>
        ))}
    </div>
  );
});

Indicator1Content.propTypes = {
  text: PropTypes.string.isRequired,
};

export const TechSideMenu = memo(() => {
  const dispatch = useDispatch();

  const {
    errorMessages,
    reset,
    indicator1,
    indicator2,
    barType,
    isAllSettlement,
    buySell,
    rangeSpread,
    itemsCount,
    quantity: quantityLogic,
    profitMargin,
    stopLossCheckbox,
    stopLossInput,
    followCheckbox,
    followInput,
    counterCheckbox,
    counterInput,
    counterFixedCheckbox,
    invalidCounter,
    submit,
  } = useTechBuilderMultiFormLogic(() => {}, true);

  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const serviceId = getServiceId(instrumentId, instrumentList);
  const isCFD = useMemo(() => serviceId === CFD, [serviceId]);
  const { quantityUnitConvFactor } = useSelector((state) => state.settings.instrumentList[instrumentId]);

  const handleClick = useCallback(() => {
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.TECH_CURRENCY_TYPE_PAGE.ID }));
    dispatch(resetBuilderMultiOrderOptions());
    dispatch(resetBuilderOrdersTables());
    dispatch(resetBuilderChartData());
  }, [dispatch]);

  const onCheckConfigurationFlow = useCallback(() => dispatch(openTechBuilderConfigurationFlowModal()), [dispatch]);

  const isTQQQ = DISABLE_INSTRUMENTS.some((ins) => ins === instrumentId);

  const indicatorOptions1 = useMemo(() => INDICATORS.filter((i) => i.value !== indicator2.get), [indicator2]);

  const indicatorOptions2 = useMemo(
    () => [{ label: '未選択', value: '' }, ...INDICATORS].filter((i) => i.value !== indicator1.get),
    [indicator1],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.row}>
          <BackLink onClick={handleClick}>選択に戻る</BackLink>
          <TagButton onClick={onCheckConfigurationFlow} disabled={reset.isDisabled}>
            設定の流れ
          </TagButton>
        </div>
        <div className={classNames(styles.row, styles.right)}>
          <TagButton onClick={reset.handler} disabled={reset.isDisabled}>
            初期設定に戻る
          </TagButton>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>テクニカルロジックの条件設定</div>
        <div className={styles.settings}>
          {/* 売買シグナル */}
          <div className={styles.item}>
            <div className={styles.label}>
              {buySell.label}
              <HelpHalfModal
                className={styles.help}
                logicName={buySell.label}
                logicImageSrc={infoImagesHelper[buySell.image]}
                content={buySell.tooltip}
              />
            </div>
            <BuySellGroupButton
              buySell={isTQQQ ? BUY_SELL_MAIN.SELL.ID : buySell.get}
              onChange={buySell.set}
              disabledSell={buySell.isSellDisabled}
              disabledBuy={buySell.isBuyDisabled || isTQQQ}
            />
          </div>

          {/* 足種 */}
          <div className={styles.item}>
            <div className={styles.label}>
              {barType.label}
              <HelpHalfModal
                className={styles.help}
                logicName={barType.label}
                logicImageSrc={infoImagesHelper[barType.image]}
                content={barType.tooltip}
              />
            </div>
            <div>
              <CustomSelect
                options={BAR_TYPES}
                selectItemId={barType.get}
                onChange={barType.set}
                isDisabled={barType.isDisabled}
              />
            </div>
          </div>

          {/* インジケーター1 */}
          <div className={styles.item}>
            <div className={styles.label}>
              {indicator1.label}
              <HelpHalfModal
                className={styles.help}
                logicName={indicator1.label}
                logicImageSrc={infoImagesHelper[indicator1.image]}
                content={<Indicator1Content text={indicator1.tooltip} />}
              />
            </div>
            <CustomSelect
              options={indicatorOptions1}
              selectItemId={indicator1.get}
              onChange={indicator1.set}
              isDisabled={indicator1.isDisabled}
              width={180}
              maxHeight={140}
            />
          </div>

          {/* インジケーター2 */}
          <div className={styles.item}>
            <div className={styles.label}>
              {indicator2.label}
              <HelpHalfModal
                className={styles.help}
                logicName={indicator2.label}
                logicImageSrc={infoImagesHelper[indicator2.image]}
                content={indicator2.tooltip}
              />
            </div>
            <div>
              <CustomSelect
                options={indicatorOptions2}
                selectItemId={indicator2.get}
                onChange={indicator2.set}
                isDisabled={indicator2.isDisabled}
                width={180}
                maxHeight={140}
              />
            </div>
          </div>

          {/* 反対シグナルが出たときに決済するか */}
          <div className={styles.item}>
            <div className={styles.label}>
              {isAllSettlement.label}
              <HelpHalfModal
                className={styles.help}
                logicName={isAllSettlement.label}
                logicImageSrc={infoImagesHelper[isAllSettlement.image]}
                content={isAllSettlement.tooltip}
              />
            </div>
            <div>
              <Switch
                checked={isAllSettlement.get}
                onChange={isAllSettlement.set}
                disabled={isAllSettlement.isDisabled}
              />
            </div>
          </div>
        </div>

        <div className={styles.title}>新規注文の条件設定</div>
        <div className={styles.settings}>
          {/* レンジ幅 */}
          <div className={styles.item}>
            <div className={styles.label}>
              {rangeSpread.label}
              <HelpHalfModal
                className={styles.help}
                logicName={rangeSpread.label}
                logicImageSrc={infoImagesHelper[rangeSpread.image]}
                content={rangeSpread.tooltip}
              />
            </div>
            <InputNumber
              value={rangeSpread.get}
              onChange={rangeSpread.set}
              disabled={rangeSpread.isDisabled}
              step={rangeSpread.step}
              name={rangeSpread.name}
              errorMessages={errorMessages}
              withErrorTooltip
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              {itemsCount.label}
              <HelpHalfModal
                className={styles.help}
                logicName={itemsCount.label}
                logicImageSrc={infoImagesHelper[itemsCount.image]}
                content={itemsCount.tooltip}
              />
            </div>
            <InputNumber
              width={shortNumberInputWidth}
              value={itemsCount.get}
              onChange={itemsCount.set}
              disabled={itemsCount.isDisabled}
              step={itemsCount.step}
              name={itemsCount.name}
              errorMessages={errorMessages}
              withErrorTooltip
              onlyIntegerAllowed
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              <div>
                {quantityLogic.label}
                {isCFD && <div>1Lot={quantityUnitConvFactor}</div>}
              </div>
              <HelpHalfModal
                className={styles.help}
                logicName={quantityLogic.label}
                logicImageSrc={infoImagesHelper[quantityLogic.image]}
                content={quantityLogic.tooltip}
              />
            </div>
            <InputNumber
              width={shortNumberInputWidth}
              value={quantityLogic.get}
              onChange={quantityLogic.set}
              disabled={quantityLogic.isDisabled}
              step={quantityLogic.step}
              name={quantityLogic.name}
              errorMessages={errorMessages}
              withErrorTooltip
            />
          </div>
        </div>

        <div className={styles.title}>決済注文の条件設定</div>
        <div className={styles.settings}>
          <div className={styles.item}>
            <div className={styles.label}>
              {profitMargin.label}
              <HelpHalfModal
                className={styles.help}
                logicName={profitMargin.label}
                logicImageSrc={infoImagesHelper[profitMargin.image]}
                content={profitMargin.tooltip}
              />
            </div>
            <InputNumber
              value={profitMargin.get}
              onChange={profitMargin.set}
              disabled={profitMargin.isDisabled}
              step={profitMargin.step}
              name={profitMargin.name}
              errorMessages={errorMessages}
              withErrorTooltip
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              {stopLossCheckbox.label}
              <HelpHalfModal
                className={styles.help}
                logicName={stopLossCheckbox.label}
                logicImageSrc={infoImagesHelper[stopLossCheckbox.image]}
                content={stopLossCheckbox.tooltip}
              />
            </div>
            <Switch
              checked={stopLossCheckbox.get}
              onChange={stopLossCheckbox.set}
              disabled={stopLossCheckbox.isDisabled}
            />
          </div>

          {stopLossCheckbox.get && (
            <div className={classNames(styles.item, styles.right)}>
              <InputNumber
                width={shortNumberInputWidth}
                value={stopLossInput.get}
                onChange={stopLossInput.set}
                disabled={stopLossInput.isDisabled}
                step={stopLossInput.step}
                min={stopLossInput.min}
                name={stopLossInput.name}
                errorMessages={errorMessages}
                withErrorTooltip
                validateNegativeValues
              />
            </div>
          )}
        </div>
        <div className={styles.title}>再エントリーの条件設定</div>
        <div className={styles.settings}>
          {invalidCounter.condition && (
            <div className={classNames(styles.item, styles.validationErrorMessage)}>{invalidCounter.message}</div>
          )}
          <div className={styles.item}>
            <div className={styles.label}>
              {followCheckbox.label}
              <HelpHalfModal
                className={styles.help}
                logicName={followCheckbox.label}
                logicImageSrc={infoImagesHelper[followCheckbox.image]}
                content={followCheckbox.tooltip}
              />
            </div>
            <Switch checked={followCheckbox.get} onChange={followCheckbox.set} disabled={followCheckbox.isDisabled} />
          </div>
          {followCheckbox.get && (
            <div className={classNames(styles.item, styles.right)}>
              <InputNumber
                width={shortNumberInputWidth}
                value={followInput.get}
                onChange={followInput.set}
                disabled={followInput.isDisabled}
                step={followInput.step}
                min={followInput.min}
                name={followInput.name}
                errorMessages={errorMessages}
                validateNegativeValues
                withErrorTooltip
              />
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.label}>
              {counterCheckbox.label}
              <HelpHalfModal
                className={styles.help}
                logicName={counterCheckbox.label}
                logicImageSrc={infoImagesHelper[counterCheckbox.image]}
                content={counterCheckbox.tooltip}
              />
            </div>
            <Switch
              checked={counterCheckbox.get}
              onChange={counterCheckbox.set}
              disabled={counterCheckbox.isDisabled}
            />
          </div>
          {counterCheckbox.get && (
            <div className={classNames(styles.item, styles.right)}>
              <InputNumber
                width={shortNumberInputWidth}
                value={counterInput.get}
                onChange={counterInput.set}
                disabled={counterInput.isDisabled}
                step={counterInput.step}
                min={counterInput.min}
                name={counterInput.name}
                errorMessages={errorMessages}
                validateNegativeValues
                withErrorTooltip
              />
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.label}>
              {counterFixedCheckbox.label}
              <HelpHalfModal
                className={styles.help}
                logicName={counterFixedCheckbox.label}
                logicImageSrc={infoImagesHelper[counterFixedCheckbox.image]}
                content={counterFixedCheckbox.tooltip}
              />
            </div>
            <Switch
              checked={counterFixedCheckbox.get}
              onChange={counterFixedCheckbox.set}
              disabled={counterFixedCheckbox.isDisabled}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.buttonGroup}>
          <Button onClick={submit.handler} disabled={submit.isDisabled}>
            {submit.label}
          </Button>
        </div>
      </div>
    </div>
  );
});
