/* eslint-disable import/no-unresolved */
import { useSelector } from 'react-redux';
import { labApGroupsSelector, SelectedApGroupIdsSelector } from '../../redux/labs';

function useLabCreationTime() {
  const apGroups = useSelector(labApGroupsSelector);
  const selectedIds = useSelector(SelectedApGroupIdsSelector);
  const selectedApGroups = apGroups
    .filter((a) => selectedIds.includes(a.id))
    .sort((a, b) => new Date(b.entryDateTime) - new Date(a.entryDateTime));

  return selectedApGroups.length > 0 ? selectedApGroups[0]?.entryDate : null;
}

export default useLabCreationTime;
