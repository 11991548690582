/* eslint-disable */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { BUILDER_ORDER_CONFIGURATION_OPTIONS as TECH_STORE_KEY } from '../constants/builder';

export const techStartLoading = createAction('TECH/START_LOADING');
export const techEndLoading = createAction('TECH/END_LOADING');

export const changeConfigOption = createAction('TECH/CHANGE_CONFIG_OPTION');
export const resetConfigOption = createAction('TECH/RESET_CONFIG_OPTION');

export const getTechnicalBuilderDataRequest = createAction('TECH/GET_TECHNICAL_BUILDER_DATA_REQUEST');
export const getTechnicalBuilderDataSuccess = createAction('TECH/GET_TECHNICAL_BUILDER_DATA_SUCCESS');

export const updateTechnicalBuilderDataRequest = createAction('TECH/UPDATE_TECHNICAL_BUILDER_DATA_REQUEST');

export const updateTechnicalBuilderNameRequest = createAction('TECH/UPDATE_TECHNICAL_BUILDER_NAME_REQUEST');

export const deleteTechnicalBuilderDataRequest = createAction('TECH/DELETE_TECHNICAL_BUILDER_DATA_REQUEST');
export const deleteTechnicalBuilderDataSuccess = createAction('TECH/DELETE_TECHNICAL_BUILDER_DATA_SUCCESS');

export const updateTechnicalBuilderStatusRequest = createAction('TECH/UPDATE_TECHNICAL_BUILDER_STATUS_REQUEST');
export const updateTechnicalBuilderStatusSuccess = createAction('TECH/UPDATE_TECHNICAL_BUILDER_STATUS_SUCCESS');

export const changeLogicName = createAction('TECH/CHANGE_TECHNICAL_BUILDER_NAME');
export const resetLogicName = createAction('TECH/RESET_TECHNICAL_BUILDER_NAME');

export const changeStatus = createAction('TECH/CHANGE_TECHNICAL_BUILDER_STATUS');

const initConfig = {
  [TECH_STORE_KEY.TECH.IS_ALL_SETTLEMENT_IS_CHECKED]: false,
  [TECH_STORE_KEY.TECH.IS_ALL_SETTLEMENT]: false,
  [TECH_STORE_KEY.TECH.RANGE_SPREAD_IS_CHECKED]: false,
  [TECH_STORE_KEY.TECH.RANGE_SPREAD]: 0,
  [TECH_STORE_KEY.TECH.ITEMS_COUNT_IS_CHECKED]: false,
  [TECH_STORE_KEY.TECH.ITEMS_COUNT]: 0,
  [TECH_STORE_KEY.TECH.AMOUNT_IS_CHECKED]: false,
  [TECH_STORE_KEY.TECH.AMOUNT]: 0,
  [TECH_STORE_KEY.TECH.PROFIT_MARGIN_IS_CHECKED]: false,
  [TECH_STORE_KEY.TECH.PROFIT_MARGIN]: 0,
  [TECH_STORE_KEY.TECH.STOP_LOSS_SPREAD_IS_CHECKED]: false,
  [TECH_STORE_KEY.TECH.STOP_LOSS_SPREAD]: 0,
  [TECH_STORE_KEY.TECH.FOLLOW_VALUE_IS_CHECKED]: false,
  [TECH_STORE_KEY.TECH.FOLLOW_VALUE]: 0,
  [TECH_STORE_KEY.TECH.COUNTER_VALUE_IS_CHECKED]: false,
  [TECH_STORE_KEY.TECH.COUNTER_VALUE]: 0,
  [TECH_STORE_KEY.TECH.COUNTER_IS_FIXED_CHECKED]: false,
  [TECH_STORE_KEY.TECH.COUNTER_IS_FIXED]: false,
};

Object.freeze(initConfig);

export default createReducer(
  {
    techGroupsData: [],
    config: {
      ...initConfig,
    },
    isLoading: false,
    logicName: '',
    status: null,
  },
  {
    [getTechnicalBuilderDataSuccess.type]: (tech, action) => {
      tech.techGroupsData = action?.payload?.data;
    },
    [changeConfigOption.type]: (tech, action) => {
      const { key, value } = action?.payload;
      tech.config[key] = value;
    },
    [changeLogicName.type]: (tech, action) => {
      const { name } = action?.payload;
      tech.logicName = name;
    },
    [resetLogicName.type]: (tech) => {
      tech.logicName = '';
    },
    [changeStatus.type]: (tech, action) => {
      const { status } = action?.payload;
      tech.status = status;
    },
    [resetConfigOption.type]: (tech) => {
      tech.config = initConfig;
    },
    [techStartLoading.type]: (tech) => {
      tech.isLoading = true;
    },
    [techEndLoading.type]: (tech) => {
      tech.isLoading = false;
    },
  },
);
