// eslint-disable-next-line import/no-unresolved
import { useEffect, useMemo, useRef, useState } from 'react';
import { valueToPercent } from '../utils';
import { useThrottle } from './useThrottle';

const toPercent = (value, min, max) => {
  if (value == null) {
    return null;
  }
  return valueToPercent(value, min, max);
};

const percentToValue = (percent, effectiveRange) => {
  if (percent == null) {
    return null;
  }
  return (percent * effectiveRange) / 100;
};

const getPosition = (value, min, max, effectiveRange) => {
  const percent = toPercent(value, min, max);
  return percentToValue(percent, effectiveRange);
};

const usePositions = (value1, value2, min, max, effectiveRange) => {
  return useMemo(() => {
    const values = [value1, value2];
    const newMax = Math.max(...values);
    if (newMax > max) {
      return values.map((v) => getPosition(v, min, newMax, effectiveRange));
    }
    return values.map((v) => getPosition(v, min, max, effectiveRange));
  }, [value1, value2, min, max, effectiveRange]);
};

export const useMeter = (value1, value2, min, max, effectiveRange, duration) => {
  const v1 = value1 ?? 0;
  const v2 = value2 != null && v1 > value2 ? v1 : value2;
  const timerRef = useRef(null);
  const [empty, setEmpty] = useState(true);
  const emptyRef = useRef(empty);
  const [position1, setPosition1] = useState(null);
  const [position2, setPosition2] = useState(null);
  const positions = usePositions(v1, v2, min, max, effectiveRange);
  const [pos1, pos2] = useThrottle(positions, duration);
  const pos2Ref = useRef(pos2);

  useEffect(() => {
    if ((pos2Ref.current == null && pos2 != null) || (pos2Ref.current != null && pos2 == null)) {
      // position2 が有効から無効、無効から有効に切り替わった場合、一旦原点復帰させる
      emptyRef.current = false;
      setEmpty(emptyRef.current);
      setPosition1(0);
      setPosition2(pos2Ref.current == null && pos2 == null ? null : 0);
      pos2Ref.current = pos2;
      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        if (!pos1 && !pos2) {
          emptyRef.current = true;
          setEmpty(emptyRef.current);
        } else {
          setPosition1(pos1);
          setPosition2(pos2);
        }
        timerRef.current = null;
      }, duration);
      return;
    }
    if (pos2Ref.current !== pos2) {
      pos2Ref.current = pos2;
    }
    if (emptyRef.current) {
      if (pos1 || pos2) {
        // 無効から有効に切り替わった場合、一旦原点復帰させる
        setPosition1(0);
        setPosition2(pos2 == null ? null : 0);
        emptyRef.current = false;
        setEmpty(emptyRef.current);
        if (timerRef.current != null) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          setPosition1(pos1);
          setPosition2(pos2);
          timerRef.current = null;
        }, duration);
      }
    } else if (!pos1 && !pos2) {
      setPosition1(0);
      setPosition2(pos2 == null ? null : 0);
      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        emptyRef.current = true;
        setEmpty(emptyRef.current);
        timerRef.current = null;
      }, duration);
    } else {
      emptyRef.current = false;
      setEmpty(emptyRef.current);
      setPosition1(pos1);
      setPosition2(pos2);
    }
  }, [pos1, pos2, duration]);

  useEffect(
    () => () => {
      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    },
    [],
  );

  return useMemo(() => ({ empty, position1, position2 }), [empty, position1, position2]);
};
