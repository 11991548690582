import { put, delay, select } from 'redux-saga/effects';
import { getNewsRequest } from '../../actions/manualTradeActions';
import { DELAY_FOR_UPDATE_NEWS } from '../../../constants/apiConstant';

function* getNewsPeriodicallyHandler() {
  try {
    yield put(getNewsRequest());
    while (true) {
      yield delay(DELAY_FOR_UPDATE_NEWS);
      const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
      const hasError = yield select((state) => state.socket.hasError);
      if (!isAuthenticated || hasError) {
        break;
      }
      yield put(getNewsRequest());
    }
  } catch (e) {
    // todo: add error handling
    console.log('getNewsPeriodicallyHandler error', e); // eslint-disable-line
  }
}

export default getNewsPeriodicallyHandler;
