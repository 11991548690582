import { Spin } from '../../../../../../../../components';
import styles from './loader.module.scss';

const Loader = () => (
  <div className={styles.loaderContainer}>
    <Spin className={styles.loader} />
  </div>
);

export default Loader;
