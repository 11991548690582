import { memo } from 'react';
import PropTypes from 'prop-types';
import InnerLabChart from '../../../../appWrappers/ModalContainer/components/LabDetailModal/components/LabChart/LabChart';
import { useErrorText } from '../../../../hooks';

export const LabChart = memo(({ strategy }) => {
  const errorText = useErrorText(strategy?.serviceId);
  if (strategy == null) {
    return null;
  }
  if (errorText) {
    return errorText;
  }
  return <InnerLabChart strategy={strategy} disableLeftToolbar isUseLabServiceId={false} />;
});

LabChart.propTypes = {
  strategy: PropTypes.shape({
    strategyId: PropTypes.number,
    instrumentId: PropTypes.string,
    serviceId: PropTypes.string,
    name: PropTypes.string,
    itemList: PropTypes.arrayOf(PropTypes.shape({})),
    strategySets: PropTypes.number,
    simulationTradeList: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

LabChart.defaultProps = {
  strategy: undefined,
};
