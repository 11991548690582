import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BUILDER_PAGES } from 'shared-modules/constants/builder';
import { resetBuilderOptions, resetWebBuilderOptions } from '../../redux/actions';
import { Spin } from '../../components';
import ChartMakeDrawPage from '../ChartMake/ChartMakeDrawPage';
import ChartMakeSummaryPage from '../ChartMake/ChartMakeSummaryPage';
import { BuildersShareConfigPage, BuilderTypePage, ConfigurationPage } from './components';
import TechBuilder from '../TechBuilder';
import { CurrencyTypePage } from '../common';
import styles from './builder.module.scss';

export const Builder = memo(() => {
  const dispatch = useDispatch();
  const [drawingPoints, setDrawingPoints] = useState([]);

  const isLoading = useSelector((state) => state.currencies.ratesIsLoading);
  const key = useSelector((state) => state.router.location.key);
  const serviceId = useSelector((state) => state.auth.serviceId);
  const activePage = useSelector((state) => state.webBuilder.activePage);
  const activePageRef = useRef(null);

  useEffect(() => {
    activePageRef.current = activePage;
  }, [activePage]);

  useEffect(() => {
    setDrawingPoints([]);
    if (activePageRef.current === BUILDER_PAGES.CHART_MAKE_INSTRUMENT_CHOICE_PAGE.ID) {
      return;
    }
    if (
      activePageRef.current === BUILDER_PAGES.CHART_MAKE_DRAW_PAGE.ID ||
      activePageRef.current === BUILDER_PAGES.CHART_MAKE_SUMMARY_PAGE
    ) {
      dispatch(resetBuilderOptions());
    }
  }, [key, dispatch, serviceId]);

  useEffect(() => {
    return () => {
      dispatch(resetWebBuilderOptions());
    };
  }, [dispatch, key]);

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Spin className={styles.loader} />
      </div>
    );
  }

  switch (activePage) {
    case BUILDER_PAGES.BUILDER_TYPE_PAGE.ID: {
      return <BuilderTypePage />;
    }
    case BUILDER_PAGES.CURRENCY_TYPE_PAGE.ID: {
      return <CurrencyTypePage />;
    }
    case BUILDER_PAGES.CONFIGURATION_PAGE.ID: {
      return <ConfigurationPage />;
    }
    case BUILDER_PAGES.CHART_MAKE_INSTRUMENT_CHOICE_PAGE.ID: {
      return <CurrencyTypePage />;
    }
    case BUILDER_PAGES.CHART_MAKE_DRAW_PAGE.ID: {
      return <ChartMakeDrawPage drawingPoints={drawingPoints} setDrawingPoints={setDrawingPoints} />;
    }
    case BUILDER_PAGES.CHART_MAKE_SUMMARY_PAGE.ID: {
      return <ChartMakeSummaryPage />;
    }
    case BUILDER_PAGES.BUILDERS_SHARE_SELECT_PAGE.ID:
    case BUILDER_PAGES.BUILDERS_SHARE_INPUT_PAGE.ID:
    case BUILDER_PAGES.BUILDERS_SHARE_CONFIRM_PAGE.ID:
      return <BuildersShareConfigPage />;
    case BUILDER_PAGES.TECH_CURRENCY_TYPE_PAGE.ID: {
      return <CurrencyTypePage />;
    }
    case BUILDER_PAGES.TECH_BUILDER_PAGE.ID:
      return <TechBuilder />;
    default: {
      return <div />;
    }
  }
});
