import React, { memo } from 'react';
import {
  BUILDER_CONFIGRATION_FLOW_MAIN_TITLE,
  TECH_BUILDER_CONFIGURATION_MENU1,
  TECH_BUILDER_CONFIGURATION_MENU2,
  TECH_BUILDER_CONFIGURATION_MENU3,
  TECH_BUILDER_CONFIGURATION_MENU4,
} from 'shared-modules/constants/builder';
import styles from '../steps.module.scss';

const titleArea = (step) => {
  return (
    <div key={step?.[0]}>
      <span className={styles.step}>{step?.[0]}</span>
      <span className={styles.subTitle}>{step?.[1]}</span>
    </div>
  );
};

const Top = () => {
  return (
    <div className={styles.topContent}>
      <div className={styles.innerContent}>
        <div className={styles.topTitle}>
          <p>{BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.TECH}</p>
        </div>
        <div className={styles.stepContainer}>{TECH_BUILDER_CONFIGURATION_MENU1.map((step) => titleArea(step))}</div>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.topTitle}>
          <p>{BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.ORDER}</p>
        </div>
        <div className={styles.stepContainer}>{TECH_BUILDER_CONFIGURATION_MENU2.map((step) => titleArea(step))}</div>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.topTitle}>
          <p>{BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.SETTLEMENT}</p>
        </div>
        <div className={styles.stepContainer}>{TECH_BUILDER_CONFIGURATION_MENU3.map((step) => titleArea(step))}</div>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.topTitle}>
          <p>{BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.REENTRY}</p>
        </div>
        <div className={styles.stepContainer}>{TECH_BUILDER_CONFIGURATION_MENU4.map((step) => titleArea(step))}</div>
      </div>
    </div>
  );
};

export default memo(Top);
