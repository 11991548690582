import { all, takeLatest, call, delay, put, select } from 'redux-saga/effects';
import {
  CARD_STATUS_IDS,
  MIXED,
  STRATEGY_SETS_RESULTS,
  LEARN_TRIAUTO_MENU,
  LEARN_TRIAUTO_STATUS,
  BUY_SELL_MAIN,
  DEFAULT_SERVICE_ID,
} from 'shared-modules/constants';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { getAccountInfo } from 'shared-modules/redux/sagas/common';
import {
  getApGroupRequest,
  getApGroupSuccess,
  setExecutionsSummaryInfoForTutorial,
} from 'shared-modules/redux/actions/portfolioActions';
import { getTechnicalBuilderDataRequest, getTechnicalBuilderDataSuccess } from 'shared-modules/redux/tech';
import {
  createCurrentDateString,
  createCurrentDateAddSecondString,
  createDateStringWithoutTime,
} from 'shared-modules/services';
import { errorHandler } from 'shared-modules/redux/sagas/errorSaga';
import {
  updateUserLearnTriautoStatus,
  updateUserLearnTriautoStatusRequest,
} from 'shared-modules/redux/actions/settingsActions';
import Decimal from 'decimal.js';
import {
  WEB_TUTORIAL_OPEN_SELECT_PAGE,
  WEB_TUTORIAL_OPEN_STRATEGIES_SUCCESS,
  WEB_TUTORIAL_OPEN_PORTFOLIO,
  WEB_TUTORIAL_OPEN_PORTFOLIO_DETAIL,
  WEB_TUTORIAL_OPEN_HOME,
} from '../actionConstants/tutorialConstants';
import {
  closeAutoSelectRunNowConfirmation,
  openStrategiesSuccessModal,
  closeStrategiesSuccessModal,
  push,
  openTutorialPortfolioDetailModal,
  getPositionsRequest,
  getPositionsSuccess,
  closeYoutubeModal,
  closeTutorialPortfolioDetailModal,
} from '../actions';
import { CLOSE_TUTORIAL_MODAL, OPEN_TUTORIAL_MODAL } from '../actionConstants/modalConstants';
import {
  disableTutorialMode,
  enableTutorialMode,
  removeTutorialTablesData,
  removeTutorialCardData,
  setTutorialTablesData,
  setTutorialCardData,
  setTutorialProfitLossChartData,
  removeTutorialProfitLossChartData,
  setTutorialIsReadyAutoSelect,
} from '../actions/tutorialActions';
import { AUTO_SELECT, HOME } from '../../constants';

function* tutorialOpenSelectPage4Handler() {
  yield put(setTutorialIsReadyAutoSelect({ isReadyAutoSelect: false }));
  yield put(push(`/${AUTO_SELECT}`));
}

function* tutorialOpenHomeHandler() {
  yield put(push(`/${HOME}`));
}

function* tutorialOpenStrategiesSuccessHandler() {
  yield put(closeAutoSelectRunNowConfirmation());

  const defaultSelectionTermId = yield select((state) => state.constants.defaultSelectionTermId);
  const { strategyList } = yield select((state) => state.autoSelect.selectionModalInfo[defaultSelectionTermId]);

  const resultArray = strategyList.map(({ strategyId, strategyDetail }) => {
    const message = `${strategyDetail.itemList.length}件中${strategyDetail.itemList.length}件稼働しました。`;

    return {
      status: STRATEGY_SETS_RESULTS.SUCCESS,
      strategyName: strategyDetail.name,
      message,
      strategyId,
    };
  });

  yield put(openStrategiesSuccessModal({ resultArray }));
}

function* tutorialOpenPortfolioHandler() {
  const defaultSelectionTermId = yield select((state) => state.constants.defaultSelectionTermId);
  const { strategyList } = yield select((state) => state.autoSelect.selectionModalInfo[defaultSelectionTermId]);

  const [destruct] = strategyList;
  const { strategyDetail } = destruct;

  const currentDate = createCurrentDateString();

  // tutorial用の偽のapGroupDataを追加する
  const tutorialCardData = {
    activeApCount: strategyDetail.itemsCount,
    entryDate: createDateStringWithoutTime(),
    entryDateTime: currentDate,
    id: '999999999',
    instrumentId: strategyDetail.instrumentId,
    name: strategyDetail.name,
    positionQuantity: 2,
    sourceId: '000000',
    sourceType: 'select',
    status: '1',
    totalApCount: strategyDetail.itemsCount,
    totalRealizedPnl: 33968,
    tradeServiceType: null,
    unrealizedPnl: 4950,
  };

  yield put(setTutorialCardData({ cardData: tutorialCardData }));
  yield put(getApGroupSuccess({ serviceId: DEFAULT_SERVICE_ID, apGroupsData: [tutorialCardData], groupId: null }));

  const positionsData = [];
  const executionData = [];

  const getPlForPosition = (i) => {
    if (i === 1) {
      return 1605;
    }
    if (i === 2) {
      return 3286;
    }
    return 0;
  };

  const getTradePriceForPosition = (i) => {
    if (i === 1) {
      return 0.9031;
    }
    if (i === 2) {
      return 0.9011;
    }
    return 0;
  };

  for (let i = 1; i < 3; i += 1) {
    const position = {
      apGroupId: '9999900000',
      apId: `999990000${i}`,
      id: `0${i}`,
      apName: `${strategyDetail.name}_0${i}`,
      dividend: 0,
      execTime: currentDate,
      instrumentId: strategyDetail.instrumentId,
      pl: -getPlForPosition(i),
      positionId: `9999999990${i}`,
      quantity: 1,
      settlingQuantity: 0,
      side: BUY_SELL_MAIN.BUY.ID.toString(),
      swapPl: 0,
      totalPl: -getPlForPosition(i),
      tradeMethod: '0',
      tradePrice: getTradePriceForPosition(i),
      currentPrice: 0.90501,
      unrealizedSwapPl: 0,
    };
    positionsData.push(position);
  }

  const getExecPriceForExecution = (i) => {
    if (i === 1) {
      return '0.09041';
    }
    if (i === 2) {
      return '0.09001';
    }
    if (i === 3) {
      return '0.09011';
    }
    return `0.0904${i}`;
  };

  const getPlForExecution = (i) => {
    if (i === 1) {
      return 3286;
    }
    if (i === 22) {
      return 30682;
    }
    return 0;
  };

  const getSideForExecution = (i) => {
    if (i === 1 || (i >= 5 && i % 2 === 0)) {
      return BUY_SELL_MAIN.SELL.ID.toString();
    }
    if (i === 2 || i === 3 || i === 4 || (i >= 5 && i % 2 === 1)) {
      return BUY_SELL_MAIN.BUY.ID.toString();
    }
    return 0;
  };

  for (let i = 1; i < 23; i += 1) {
    const execution = {
      accountId: '89002498',
      apGroupId: '9999900000',
      apId: `999990000${i}`,
      apName: `${strategyDetail.name}_0${i}`,
      commissionEquivalent: null,
      dividend: 0,
      execPrice: getExecPriceForExecution(i),
      execTime: currentDate,
      id: `999999999${i}`,
      instrumentId: strategyDetail.instrumentId,
      isClose: true,
      pl: getPlForExecution(i),
      positionExecPrice: `1.01.024${i}`,
      quantity: 1,
      side: getSideForExecution(i),
      swapPl: 0,
      tradeMethod: '0',
      updateTime: null,
    };
    executionData.push(execution);
  }

  const executionsSummary = executionData.reduce(
    (acc, item) => {
      if (item.side === BUY_SELL_MAIN.BUY.ID) {
        acc.summarizedBuyQuantity = Number(Decimal.add(acc.summarizedBuyQuantity, item.quantity));
      }
      if (item.side === BUY_SELL_MAIN.SELL.ID) {
        acc.summarizedSellQuantity = Number(Decimal.add(acc.summarizedSellQuantity, item.quantity));
      }
      acc.summarizedPl = Number(Decimal.add(acc.summarizedPl, item.pl));
      acc.summarizedSwap = Number(Decimal.add(acc.summarizedSwap, item.swapPl));

      return acc;
    },
    { summarizedBuyQuantity: 0, summarizedSellQuantity: 0, summarizedPl: 0, summarizedSwap: 0 },
  );

  yield put(setTutorialTablesData({ orderData: strategyDetail?.itemList, positionsData, executionData }));
  yield put(setExecutionsSummaryInfoForTutorial({ serviceId: DEFAULT_SERVICE_ID, summary: executionsSummary }));

  const currentDateSecondFormat = currentDate.slice(0, currentDate.length - 2).concat('00');

  const profitLossChartData = [
    { pl: 4000, date: currentDateSecondFormat },
    { pl: 4063, date: createCurrentDateAddSecondString(currentDateSecondFormat, 1) },
    { pl: 4126, date: createCurrentDateAddSecondString(currentDateSecondFormat, 2) },
    { pl: 4189, date: createCurrentDateAddSecondString(currentDateSecondFormat, 3) },
    { pl: 4250, date: createCurrentDateAddSecondString(currentDateSecondFormat, 4) },
    { pl: 4313, date: createCurrentDateAddSecondString(currentDateSecondFormat, 5) },
    { pl: 4379, date: createCurrentDateAddSecondString(currentDateSecondFormat, 6) },
    { pl: 4442, date: createCurrentDateAddSecondString(currentDateSecondFormat, 7) },
    { pl: 4500, date: createCurrentDateAddSecondString(currentDateSecondFormat, 8) },
    { pl: 4563, date: createCurrentDateAddSecondString(currentDateSecondFormat, 9) },
    { pl: 4626, date: createCurrentDateAddSecondString(currentDateSecondFormat, 10) },
    { pl: 4689, date: createCurrentDateAddSecondString(currentDateSecondFormat, 11) },
    { pl: 4750, date: createCurrentDateAddSecondString(currentDateSecondFormat, 12) },
    { pl: 4813, date: createCurrentDateAddSecondString(currentDateSecondFormat, 13) },
    { pl: 4876, date: createCurrentDateAddSecondString(currentDateSecondFormat, 14) },
    { pl: 4949, date: createCurrentDateAddSecondString(currentDateSecondFormat, 15) },
    { pl: 5000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 16) },
    { pl: 5063, date: createCurrentDateAddSecondString(currentDateSecondFormat, 17) },
    { pl: 5126, date: createCurrentDateAddSecondString(currentDateSecondFormat, 18) },
    { pl: 5189, date: createCurrentDateAddSecondString(currentDateSecondFormat, 19) },
    { pl: 5250, date: createCurrentDateAddSecondString(currentDateSecondFormat, 20) },
    { pl: 5313, date: createCurrentDateAddSecondString(currentDateSecondFormat, 21) },
    { pl: 5379, date: createCurrentDateAddSecondString(currentDateSecondFormat, 22) },
    { pl: 5442, date: createCurrentDateAddSecondString(currentDateSecondFormat, 23) },
    { pl: 5500, date: createCurrentDateAddSecondString(currentDateSecondFormat, 24) },
    { pl: 5563, date: createCurrentDateAddSecondString(currentDateSecondFormat, 25) },
    { pl: 5626, date: createCurrentDateAddSecondString(currentDateSecondFormat, 26) },
    { pl: 5689, date: createCurrentDateAddSecondString(currentDateSecondFormat, 27) },
    { pl: 5750, date: createCurrentDateAddSecondString(currentDateSecondFormat, 28) },
    { pl: 5813, date: createCurrentDateAddSecondString(currentDateSecondFormat, 29) },
    { pl: 5876, date: createCurrentDateAddSecondString(currentDateSecondFormat, 30) },
    { pl: 5910, date: createCurrentDateAddSecondString(currentDateSecondFormat, 31) },
    { pl: 5920, date: createCurrentDateAddSecondString(currentDateSecondFormat, 32) },
    { pl: 5930, date: createCurrentDateAddSecondString(currentDateSecondFormat, 33) },
    { pl: 5940, date: createCurrentDateAddSecondString(currentDateSecondFormat, 34) },
    { pl: 5950, date: createCurrentDateAddSecondString(currentDateSecondFormat, 35) },
    { pl: 5960, date: createCurrentDateAddSecondString(currentDateSecondFormat, 36) },
    { pl: 5970, date: createCurrentDateAddSecondString(currentDateSecondFormat, 37) },
    { pl: 5980, date: createCurrentDateAddSecondString(currentDateSecondFormat, 38) },
    { pl: 6000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 39) },
    { pl: 10000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 40) },
    { pl: 10100, date: createCurrentDateAddSecondString(currentDateSecondFormat, 41) },
    { pl: 10200, date: createCurrentDateAddSecondString(currentDateSecondFormat, 42) },
    { pl: 10300, date: createCurrentDateAddSecondString(currentDateSecondFormat, 43) },
    { pl: 10400, date: createCurrentDateAddSecondString(currentDateSecondFormat, 44) },
    { pl: 10400, date: createCurrentDateAddSecondString(currentDateSecondFormat, 44) },
    { pl: 10500, date: createCurrentDateAddSecondString(currentDateSecondFormat, 45) },
    { pl: 10600, date: createCurrentDateAddSecondString(currentDateSecondFormat, 46) },
    { pl: 10700, date: createCurrentDateAddSecondString(currentDateSecondFormat, 47) },
    { pl: 10800, date: createCurrentDateAddSecondString(currentDateSecondFormat, 48) },
    { pl: 10900, date: createCurrentDateAddSecondString(currentDateSecondFormat, 49) },
    { pl: 11000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 50) },
    { pl: 11100, date: createCurrentDateAddSecondString(currentDateSecondFormat, 51) },
    { pl: 11200, date: createCurrentDateAddSecondString(currentDateSecondFormat, 52) },
    { pl: 11300, date: createCurrentDateAddSecondString(currentDateSecondFormat, 53) },
    { pl: 11400, date: createCurrentDateAddSecondString(currentDateSecondFormat, 54) },
    { pl: 11500, date: createCurrentDateAddSecondString(currentDateSecondFormat, 55) },
    { pl: 11600, date: createCurrentDateAddSecondString(currentDateSecondFormat, 56) },
    { pl: 11700, date: createCurrentDateAddSecondString(currentDateSecondFormat, 57) },
    { pl: 11800, date: createCurrentDateAddSecondString(currentDateSecondFormat, 58) },
    { pl: 11900, date: createCurrentDateAddSecondString(currentDateSecondFormat, 59) },
    { pl: 12000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 60) },
    { pl: 12060, date: createCurrentDateAddSecondString(currentDateSecondFormat, 61) },
    { pl: 12120, date: createCurrentDateAddSecondString(currentDateSecondFormat, 62) },
    { pl: 12180, date: createCurrentDateAddSecondString(currentDateSecondFormat, 63) },
    { pl: 12250, date: createCurrentDateAddSecondString(currentDateSecondFormat, 64) },
    { pl: 12310, date: createCurrentDateAddSecondString(currentDateSecondFormat, 65) },
    { pl: 12370, date: createCurrentDateAddSecondString(currentDateSecondFormat, 66) },
    { pl: 12430, date: createCurrentDateAddSecondString(currentDateSecondFormat, 67) },
    { pl: 12500, date: createCurrentDateAddSecondString(currentDateSecondFormat, 68) },
    { pl: 12560, date: createCurrentDateAddSecondString(currentDateSecondFormat, 69) },
    { pl: 12620, date: createCurrentDateAddSecondString(currentDateSecondFormat, 70) },
    { pl: 12680, date: createCurrentDateAddSecondString(currentDateSecondFormat, 71) },
    { pl: 12750, date: createCurrentDateAddSecondString(currentDateSecondFormat, 72) },
    { pl: 12810, date: createCurrentDateAddSecondString(currentDateSecondFormat, 73) },
    { pl: 12870, date: createCurrentDateAddSecondString(currentDateSecondFormat, 74) },
    { pl: 12930, date: createCurrentDateAddSecondString(currentDateSecondFormat, 75) },
    { pl: 13000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 76) },
    { pl: 13060, date: createCurrentDateAddSecondString(currentDateSecondFormat, 77) },
    { pl: 13120, date: createCurrentDateAddSecondString(currentDateSecondFormat, 78) },
    { pl: 13180, date: createCurrentDateAddSecondString(currentDateSecondFormat, 79) },
    { pl: 13250, date: createCurrentDateAddSecondString(currentDateSecondFormat, 80) },
    { pl: 13310, date: createCurrentDateAddSecondString(currentDateSecondFormat, 81) },
    { pl: 13370, date: createCurrentDateAddSecondString(currentDateSecondFormat, 82) },
    { pl: 13430, date: createCurrentDateAddSecondString(currentDateSecondFormat, 83) },
    { pl: 13500, date: createCurrentDateAddSecondString(currentDateSecondFormat, 84) },
    { pl: 13560, date: createCurrentDateAddSecondString(currentDateSecondFormat, 85) },
    { pl: 13620, date: createCurrentDateAddSecondString(currentDateSecondFormat, 86) },
    { pl: 13680, date: createCurrentDateAddSecondString(currentDateSecondFormat, 87) },
    { pl: 13750, date: createCurrentDateAddSecondString(currentDateSecondFormat, 88) },
    { pl: 13810, date: createCurrentDateAddSecondString(currentDateSecondFormat, 89) },
    { pl: 13870, date: createCurrentDateAddSecondString(currentDateSecondFormat, 90) },
    { pl: 13930, date: createCurrentDateAddSecondString(currentDateSecondFormat, 91) },
    { pl: 14000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 92) },
    { pl: 14060, date: createCurrentDateAddSecondString(currentDateSecondFormat, 93) },
    { pl: 14120, date: createCurrentDateAddSecondString(currentDateSecondFormat, 94) },
    { pl: 14180, date: createCurrentDateAddSecondString(currentDateSecondFormat, 95) },
    { pl: 14250, date: createCurrentDateAddSecondString(currentDateSecondFormat, 96) },
    { pl: 14310, date: createCurrentDateAddSecondString(currentDateSecondFormat, 97) },
    { pl: 14370, date: createCurrentDateAddSecondString(currentDateSecondFormat, 98) },
    { pl: 14430, date: createCurrentDateAddSecondString(currentDateSecondFormat, 99) },
    { pl: 14590, date: createCurrentDateAddSecondString(currentDateSecondFormat, 100) },
    { pl: 14650, date: createCurrentDateAddSecondString(currentDateSecondFormat, 101) },
    { pl: 14710, date: createCurrentDateAddSecondString(currentDateSecondFormat, 102) },
    { pl: 14770, date: createCurrentDateAddSecondString(currentDateSecondFormat, 103) },
    { pl: 21250, date: createCurrentDateAddSecondString(currentDateSecondFormat, 104) },
    { pl: 21455, date: createCurrentDateAddSecondString(currentDateSecondFormat, 105) },
    { pl: 21660, date: createCurrentDateAddSecondString(currentDateSecondFormat, 106) },
    { pl: 21865, date: createCurrentDateAddSecondString(currentDateSecondFormat, 107) },
    { pl: 22070, date: createCurrentDateAddSecondString(currentDateSecondFormat, 108) },
    { pl: 22275, date: createCurrentDateAddSecondString(currentDateSecondFormat, 109) },
    { pl: 22480, date: createCurrentDateAddSecondString(currentDateSecondFormat, 110) },
    { pl: 22685, date: createCurrentDateAddSecondString(currentDateSecondFormat, 111) },
    { pl: 22890, date: createCurrentDateAddSecondString(currentDateSecondFormat, 112) },
    { pl: 23095, date: createCurrentDateAddSecondString(currentDateSecondFormat, 113) },
    { pl: 23300, date: createCurrentDateAddSecondString(currentDateSecondFormat, 114) },
    { pl: 23505, date: createCurrentDateAddSecondString(currentDateSecondFormat, 115) },
    { pl: 23710, date: createCurrentDateAddSecondString(currentDateSecondFormat, 116) },
    { pl: 23915, date: createCurrentDateAddSecondString(currentDateSecondFormat, 117) },
    { pl: 24120, date: createCurrentDateAddSecondString(currentDateSecondFormat, 118) },
    { pl: 24325, date: createCurrentDateAddSecondString(currentDateSecondFormat, 119) },
    { pl: 24500, date: createCurrentDateAddSecondString(currentDateSecondFormat, 120) },
    { pl: 24705, date: createCurrentDateAddSecondString(currentDateSecondFormat, 121) },
    { pl: 24910, date: createCurrentDateAddSecondString(currentDateSecondFormat, 122) },
    { pl: 25115, date: createCurrentDateAddSecondString(currentDateSecondFormat, 123) },
    { pl: 25320, date: createCurrentDateAddSecondString(currentDateSecondFormat, 124) },
    { pl: 25420, date: createCurrentDateAddSecondString(currentDateSecondFormat, 125) },
    { pl: 25520, date: createCurrentDateAddSecondString(currentDateSecondFormat, 126) },
    { pl: 25640, date: createCurrentDateAddSecondString(currentDateSecondFormat, 127) },
    { pl: 25740, date: createCurrentDateAddSecondString(currentDateSecondFormat, 128) },
    { pl: 25840, date: createCurrentDateAddSecondString(currentDateSecondFormat, 129) },
    { pl: 26000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 130) },
    { pl: 26140, date: createCurrentDateAddSecondString(currentDateSecondFormat, 131) },
    { pl: 30000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 132) },
    { pl: 30080, date: createCurrentDateAddSecondString(currentDateSecondFormat, 133) },
    { pl: 30160, date: createCurrentDateAddSecondString(currentDateSecondFormat, 134) },
    { pl: 30240, date: createCurrentDateAddSecondString(currentDateSecondFormat, 135) },
    { pl: 30333, date: createCurrentDateAddSecondString(currentDateSecondFormat, 136) },
    { pl: 30413, date: createCurrentDateAddSecondString(currentDateSecondFormat, 137) },
    { pl: 30493, date: createCurrentDateAddSecondString(currentDateSecondFormat, 138) },
    { pl: 30573, date: createCurrentDateAddSecondString(currentDateSecondFormat, 139) },
    { pl: 30666, date: createCurrentDateAddSecondString(currentDateSecondFormat, 140) },
    { pl: 30746, date: createCurrentDateAddSecondString(currentDateSecondFormat, 141) },
    { pl: 30826, date: createCurrentDateAddSecondString(currentDateSecondFormat, 142) },
    { pl: 30906, date: createCurrentDateAddSecondString(currentDateSecondFormat, 143) },
    { pl: 31000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 144) },
    { pl: 31080, date: createCurrentDateAddSecondString(currentDateSecondFormat, 145) },
    { pl: 31160, date: createCurrentDateAddSecondString(currentDateSecondFormat, 146) },
    { pl: 31240, date: createCurrentDateAddSecondString(currentDateSecondFormat, 147) },
    { pl: 31333, date: createCurrentDateAddSecondString(currentDateSecondFormat, 148) },
    { pl: 31413, date: createCurrentDateAddSecondString(currentDateSecondFormat, 149) },
    { pl: 31493, date: createCurrentDateAddSecondString(currentDateSecondFormat, 150) },
    { pl: 31573, date: createCurrentDateAddSecondString(currentDateSecondFormat, 151) },
    { pl: 31666, date: createCurrentDateAddSecondString(currentDateSecondFormat, 152) },
    { pl: 31746, date: createCurrentDateAddSecondString(currentDateSecondFormat, 153) },
    { pl: 31826, date: createCurrentDateAddSecondString(currentDateSecondFormat, 154) },
    { pl: 31906, date: createCurrentDateAddSecondString(currentDateSecondFormat, 155) },
    { pl: 32000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 156) },
    { pl: 32080, date: createCurrentDateAddSecondString(currentDateSecondFormat, 157) },
    { pl: 32160, date: createCurrentDateAddSecondString(currentDateSecondFormat, 158) },
    { pl: 32240, date: createCurrentDateAddSecondString(currentDateSecondFormat, 159) },
    { pl: 32333, date: createCurrentDateAddSecondString(currentDateSecondFormat, 160) },
    { pl: 32413, date: createCurrentDateAddSecondString(currentDateSecondFormat, 161) },
    { pl: 32493, date: createCurrentDateAddSecondString(currentDateSecondFormat, 162) },
    { pl: 32573, date: createCurrentDateAddSecondString(currentDateSecondFormat, 163) },
    { pl: 32666, date: createCurrentDateAddSecondString(currentDateSecondFormat, 164) },
    { pl: 32746, date: createCurrentDateAddSecondString(currentDateSecondFormat, 165) },
    { pl: 32826, date: createCurrentDateAddSecondString(currentDateSecondFormat, 166) },
    { pl: 32906, date: createCurrentDateAddSecondString(currentDateSecondFormat, 167) },
    { pl: 33000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 168) },
    { pl: 33080, date: createCurrentDateAddSecondString(currentDateSecondFormat, 169) },
    { pl: 33160, date: createCurrentDateAddSecondString(currentDateSecondFormat, 170) },
    { pl: 33240, date: createCurrentDateAddSecondString(currentDateSecondFormat, 171) },
    { pl: 33333, date: createCurrentDateAddSecondString(currentDateSecondFormat, 172) },
    { pl: 33416, date: createCurrentDateAddSecondString(currentDateSecondFormat, 173) },
    { pl: 33496, date: createCurrentDateAddSecondString(currentDateSecondFormat, 174) },
    { pl: 33576, date: createCurrentDateAddSecondString(currentDateSecondFormat, 175) },
    { pl: 33666, date: createCurrentDateAddSecondString(currentDateSecondFormat, 176) },
    { pl: 33746, date: createCurrentDateAddSecondString(currentDateSecondFormat, 177) },
    { pl: 33826, date: createCurrentDateAddSecondString(currentDateSecondFormat, 178) },
    { pl: 33906, date: createCurrentDateAddSecondString(currentDateSecondFormat, 179) },
    { pl: 34000, date: createCurrentDateAddSecondString(currentDateSecondFormat, 180) },
  ];

  yield put(setTutorialProfitLossChartData({ profitLossChartData }));

  yield put(closeStrategiesSuccessModal());

  yield put(push(`/${HOME}`));
}

function* tutorialOpenPortfolioDetailHandler() {
  yield put(openTutorialPortfolioDetailModal());
}

function* enableTutorialHandler() {
  const isAuth = yield select((state) => state.auth.isAuthenticated);
  if (isAuth) {
    const { beginnerReadStatusList } = yield select((state) => state.settings.learnTriautoStatus);
    if (beginnerReadStatusList[LEARN_TRIAUTO_MENU.BEGINNER.STEPS[0].key] === LEARN_TRIAUTO_STATUS.READ_STATUS.UNREAD) {
      yield put(updateUserLearnTriautoStatus({ key: LEARN_TRIAUTO_MENU.BEGINNER.STEPS[0].key }));
      yield put(updateUserLearnTriautoStatusRequest());
    }
  }
  yield put(enableTutorialMode());

  yield put(push(`/${HOME}`));

  yield all(ALL_SERVICES.map((serviceId) => put(getApGroupSuccess({ serviceId, apGroupsData: [], groupId: null }))));

  yield put(getTechnicalBuilderDataSuccess({ data: [] }));

  yield all(
    ALL_SERVICES.map((serviceId) =>
      put(getPositionsSuccess({ positions: [], positionsUnrealizedProfitLoss: {}, serviceId })),
    ),
  );
}

// モーダルを閉じると自動で発火
function* disableTutorialHandler() {
  yield put(push(`/${HOME}`));

  yield put(closeStrategiesSuccessModal());
  yield put(closeYoutubeModal());
  yield put(closeTutorialPortfolioDetailModal());
  yield put(closeAutoSelectRunNowConfirmation());

  yield put(removeTutorialTablesData());
  yield put(removeTutorialCardData());
  yield put(removeTutorialProfitLossChartData());
  yield put(getApGroupSuccess({ serviceId: DEFAULT_SERVICE_ID, apGroupsData: [], groupId: null }));

  yield put(disableTutorialMode());
  yield delay(300);

  try {
    const accountInfo = yield* getAccountInfo();

    yield all(
      ALL_SERVICES.filter((serviceId) => !accountInfo[serviceId].isNotAvailable).flatMap((serviceId) => [
        put(getApGroupRequest({ serviceId })),
        put(getPositionsRequest({ serviceId })),
      ]),
    );

    const { statusFilter } = yield select((state) => state.portfolio.listSettings[MIXED]);
    yield put(getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[statusFilter.value] }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

export default function* tutorialSagaHandler() {
  yield takeLatest(WEB_TUTORIAL_OPEN_SELECT_PAGE, tutorialOpenSelectPage4Handler);
  yield takeLatest(WEB_TUTORIAL_OPEN_HOME, tutorialOpenHomeHandler);
  yield takeLatest(WEB_TUTORIAL_OPEN_STRATEGIES_SUCCESS, tutorialOpenStrategiesSuccessHandler);
  yield takeLatest(WEB_TUTORIAL_OPEN_PORTFOLIO, tutorialOpenPortfolioHandler);
  yield takeLatest(WEB_TUTORIAL_OPEN_PORTFOLIO_DETAIL, tutorialOpenPortfolioDetailHandler);
  yield takeLatest(OPEN_TUTORIAL_MODAL, enableTutorialHandler);
  yield takeLatest(CLOSE_TUTORIAL_MODAL, disableTutorialHandler);
}
