// eslint-disable-next-line import/no-unresolved
import { useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { CFD, ETF, FX } from '../constants';

export const useInstrumentOptions = () => {
  const FXOptions = useSelector((state) => state.constants[FX].instrumentsOptions);
  const ETFOptions = useSelector((state) => state.constants[ETF].instrumentsOptions);
  const CFDOptions = useSelector((state) => state.constants[CFD].instrumentsOptions);
  return useMemo(
    () => ({ [FX]: FXOptions, [ETF]: ETFOptions, [CFD]: CFDOptions }),
    [FXOptions, ETFOptions, CFDOptions],
  );
};
