import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import classNames from 'classnames';
import styleConstants from 'shared-modules/styles/_constants.scss';
import { useKeyPressHandler } from '../../services/hooks';
import styles from './customSwitch.module.scss';

const CustomSwitch = ({ isChecked, isDisabled, onChange, className }) => {
  const onKeyPress = useCallback(() => onChange(!isChecked), [onChange, isChecked]);
  const handleKeyPress = useKeyPressHandler(onKeyPress);

  return (
    <div tabIndex={isDisabled ? -1 : 0} role="button" onKeyPress={handleKeyPress} className={styles.switchWrapper}>
      <Switch
        tabIndex={-1}
        width={33}
        height={16}
        checked={isChecked}
        disabled={isDisabled}
        onChange={onChange}
        className={classNames(
          styles.switch,
          { [styles.isDisabled]: isDisabled },
          { [styles.isChecked]: isChecked },
          { [styles.isCheckedAndDisabled]: isDisabled && isChecked },
          className,
        )}
        checkedIcon={false}
        onHandleColor={styleConstants.WEB_WHITE}
        uncheckedIcon={false}
        offHandleColor={styleConstants.WEB_WHITE}
        handleDiameter={10}
      />
    </div>
  );
};

CustomSwitch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

CustomSwitch.defaultProps = {
  className: '',
  isDisabled: false,
};

export default memo(CustomSwitch);
