import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReportsDetailsDailyOptions from '../ReportsDetailsDailyOptions';
import ReportsDetailsQuarterOptions from '../ReportsDetailsQuarterOptions';
import ReportsDetailsMonthOptions from '../ReportsDetailsMonthOptions';
import styles from './reportsDetailsSettings.module.scss';

const ReportsDetailsSettings = ({ serviceId, selectedReportType, booleanTypes, searchReports }) => {
  const { isDaily, isMonthly, isQuarterly } = booleanTypes;

  return (
    <div className={classNames(styles.formatWrapper, { [styles.isOnNextRow]: isMonthly || isQuarterly })}>
      {isDaily && (
        <ReportsDetailsDailyOptions
          selectedReportType={selectedReportType}
          searchReports={searchReports}
          serviceId={serviceId}
        />
      )}
      {isMonthly && <ReportsDetailsMonthOptions serviceId={serviceId} searchReports={searchReports} />}
      {isQuarterly && <ReportsDetailsQuarterOptions serviceId={serviceId} searchReports={searchReports} />}
    </div>
  );
};

ReportsDetailsSettings.propTypes = {
  serviceId: PropTypes.string.isRequired,
  selectedReportType: PropTypes.number.isRequired,
  booleanTypes: PropTypes.shape({
    isDaily: PropTypes.bool,
    isMonthly: PropTypes.bool,
    isQuarterly: PropTypes.bool,
  }).isRequired,
  searchReports: PropTypes.func.isRequired,
};

export default memo(ReportsDetailsSettings);
