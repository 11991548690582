/* eslint-disable react/prop-types */
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  CFD,
  ETF,
  FX,
  POSITIONS_EMPTY_MESSAGE,
  TRADE_METHOD_VALUE,
  rateSeparationedAssets,
} from 'shared-modules/constants';
import { createFlooredAccessor, getServiceQuantityUnit } from 'shared-modules/services';
import { positionSummaryWithoutDynamicDataSelector } from 'shared-modules/redux/tradeInfo';
import { MAPPING_POSITION_SUMMARY_SWAP_PL } from 'shared-modules/constants/manualTrade';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import HighlightValue from '../../../../components/HighlightValue';
import BuySellItem from '../../../../components/BuySellItem';
import CurrentPriceCellValue from '../../../../components/CurrentPriceCellValue';
import { Table } from '../../../../components';
import InstrumentPrecisionTableCell from '../../../../components/InstrumentPrecisionTableCell';
import TotalUnrealizedProfitLossCellValue from './TotalUnrealizedProfitLossCellValue';
import { PositionSummaryCheckbox } from './PositionSummaryCheckbox';

const DEFAULT = [230, 20, 170, 170, 180, 180, 200, 170, 293, 90];
const RATE_SEPARATION_DEFAULT = [230, 120, 20, 170, 170, 180, 180, 200, 170, 293, 90];
const getDefault = (serviceId) => (rateSeparationedAssets.includes(serviceId) ? RATE_SEPARATION_DEFAULT : DEFAULT);
const SIZE_SETTINGS = {
  [FX]: {
    key: 'tradeInfoFxPositionSummary',
    default: getDefault(FX),
  },
  [ETF]: {
    key: 'tradeInfoETFPositionSummary',
    default: getDefault(ETF),
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    key: 'tradeInfoCFDPositionSummary',
    default: getDefault(CFD),
  },
};

const checkboxColumn = {
  id: 'selection',
  // eslint-disable-next-line react/prop-types
  Header: ({ getToggleAllRowsSelectedProps, data }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PositionSummaryCheckbox {...getToggleAllRowsSelectedProps()} tableData={data} isToggleAll />
  ),
  // eslint-disable-next-line react/prop-types
  Cell: ({ row }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PositionSummaryCheckbox {...row.getToggleRowSelectedProps()} positionIds={row.original.positionIds} />
  ),
  sticky: 'right',
  maxWidth: 90,
};

const stickyColumns = [checkboxColumn];

const TablePositionSummary = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const tableData = useSelector(positionSummaryWithoutDynamicDataSelector);
  const resized = SIZE_SETTINGS[serviceId];
  const displayUnit = getServiceQuantityUnit(serviceId);
  // TODO CFD FXとそれ以外の条件で問題ないか要確認
  const totalUnrealizedSwapPlLabel = MAPPING_POSITION_SUMMARY_SWAP_PL[serviceId];
  const columns = useMemo(() => {
    let cols =
      serviceId === FX
        ? [{ Header: '銘柄', accessor: 'name', Cell: ({ cell: { value } }) => removeSuffix(value) }]
        : [{ Header: '銘柄', accessor: 'name' }];
    if (rateSeparationedAssets.includes(serviceId)) {
      cols = cols.concat([
        {
          Header: '種類',
          accessor: 'tradeMethod',
          Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)],
          disableSortBy: true,
          maxWidth: 120,
        },
      ]);
    }
    return cols.concat([
      {
        Header: '売買',
        accessor: 'side',
        Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
        disableSortBy: true,
        maxWidth: 60,
      },
      { Header: `数量(${displayUnit})`, accessor: 'totalQuantity', isNumber: true, maxWidth: 100 },
      {
        Header: `注文中数量(${displayUnit})`,
        accessor: 'totalSettlingQuantity',
        isNumber: true,
        maxWidth: 100,
      },
      {
        Header: '平均取引価格',
        accessor: 'avgTradePrice',
        Cell: InstrumentPrecisionTableCell,
        isNumber: true,
      },
      {
        Header: '現在価格',
        accessor: 'currentPrice',
        Cell: ({
          row: {
            original: { instrumentId, side },
          },
        }) => <CurrentPriceCellValue instrumentId={instrumentId} side={side} />,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '評価損益(円)',
        accessor: 'totalUnrealizedProfitLoss',
        isNumber: true,
        Cell: ({
          row: {
            original: { instrumentId, side },
          },
        }) => <TotalUnrealizedProfitLossCellValue instrumentId={instrumentId} side={side} />,
      },
      {
        Header: totalUnrealizedSwapPlLabel,
        accessor: createFlooredAccessor('totalUnrealizedSwapPl'),
        isNumber: true,
        Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      },
    ]);
  }, [serviceId, displayUnit, totalUnrealizedSwapPlLabel]);

  return (
    <Table
      key={serviceId}
      tableData={tableData}
      columns={columns}
      resized={resized}
      emptyText={POSITIONS_EMPTY_MESSAGE}
      stickyColumns={stickyColumns}
      withCheckboxes
    />
  );
};

export default memo(TablePositionSummary);
