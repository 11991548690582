import { memo } from 'react';
import PropTypes from 'prop-types';
import { useUuid } from 'shared-modules/services/hooks';
import { GradientDefs } from './GradientDefs';
import { Circle } from './Circle';

export const MeterCircle = memo(
  ({ className, size, circleInfo, color1, color2, color3, color4, strokeDasharray, strokeDashoffset }) => {
    const patternId = useUuid();
    const { r, cx, cy, strokeWidth } = circleInfo;
    return (
      <g>
        <GradientDefs
          patternId={patternId}
          color1={color1}
          color2={color2}
          color3={color3}
          color4={color4}
          size={size}
        />
        <Circle
          className={className}
          patternId={patternId}
          r={r}
          cx={cx}
          cy={cy}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
          strokeWidth={strokeWidth}
        />
      </g>
    );
  },
);

MeterCircle.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  strokeDasharray: PropTypes.string.isRequired,
  strokeDashoffset: PropTypes.number,
  circleInfo: PropTypes.shape({
    r: PropTypes.number.isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    circumference: PropTypes.number.isRequired,
    strokeWidth: PropTypes.number.isRequired,
  }).isRequired,
  color1: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired,
  color3: PropTypes.string.isRequired,
  color4: PropTypes.string.isRequired,
};

MeterCircle.defaultProps = {
  className: undefined,
  strokeDashoffset: undefined,
};
