import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { COLORS, dailyValues, datePickerWidth, searchButtonWidth } from 'shared-modules/constants';
import { useReportDailySettings } from 'shared-modules/services/hooks/reportsLogic';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';
import CustomButton from '../../../../../../components/CustomButton';
import styles from './reportsDetailsDailyOptions.module.scss';

const ReportsDetailsDailyOptions = ({ serviceId, searchReports, selectedReportType }) => {
  const { startDate, endDate, submit } = useReportDailySettings({ serviceId, searchReports, selectedReportType });

  return (
    <>
      <CustomDatePicker
        date={startDate.get}
        onChange={startDate.set}
        maxDate={startDate.max}
        minDate={startDate.min}
        isDisabled={startDate.isDisabled}
        width={datePickerWidth}
        isLighter
        isTaller
      />

      <div className={styles.dataSeparator}>〜</div>

      <CustomDatePicker
        date={endDate.get}
        onChange={endDate.set}
        maxDate={endDate.max}
        minDate={endDate.min}
        isDisabled={endDate.isDisabled}
        className={styles.formatItem}
        width={datePickerWidth}
        isLighter
        isTaller
      />

      <CustomButton
        color={COLORS.WHITE}
        width={searchButtonWidth}
        className={styles.searchButton}
        onClick={submit.handler}
        isLoading={submit.isLoading}
      >
        {submit.label}
      </CustomButton>
    </>
  );
};

ReportsDetailsDailyOptions.propTypes = {
  serviceId: PropTypes.string.isRequired,
  selectedReportType: PropTypes.oneOf(dailyValues).isRequired,
  searchReports: PropTypes.func.isRequired,
};

export default memo(ReportsDetailsDailyOptions);
