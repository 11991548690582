import { createAdditionalParams, PAGE_SIZE_WITHOUT_PAGINATION, invokeApi, get, patch } from './index';

// todo: move all general api to this file
export const getNotificationList = async ({ isWeb }) => {
  const additionalParams = await createAdditionalParams();

  const requestQuery = `?pageNumber=1&${PAGE_SIZE_WITHOUT_PAGINATION}&sortDir=asc&sortBy=startTime&type=Popup&channel=${
    isWeb ? 'Browser' : 'App'
  }`;

  return invokeApi({}, `notifications${requestQuery}`, get, additionalParams);
};

export const updateNotificationReadStatus = async ({ notificationId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `notifications/${notificationId}`, patch, additionalParams);
};

export const fetchTABEAppVersion = () => {
  const minSupportVersion = 'min_support_version_ta23';
  return invokeApi({}, `public/check_app_version?minSupportVersion=${minSupportVersion}`, get);
};

export const fetchNotification = () => {
  return invokeApi({}, `public/login_banner_notifications`, get);
};
