import { memo } from 'react';
import PropTypes from 'prop-types';
import { PercentageText } from './PercentageText';

export const RateText = memo(({ value, className, symbolClassName }) => (
  <PercentageText className={className} symbolClassName={symbolClassName} value={value} />
));

RateText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  symbolClassName: PropTypes.string,
};

RateText.defaultProps = {
  value: undefined,
  className: undefined,
  symbolClassName: undefined,
};
