import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';

const InfoTooltip = ({ content }) => {
  const renderTooltip = (props) => (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Tooltip {...props} arrowProps={{ style: { display: 'none' } }}>
      <div className="tooltip-content info-tooltip">{content}</div>
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      <span className="material-icons-outlined tooltip--info">info</span>
    </OverlayTrigger>
  );
};

InfoTooltip.propTypes = {
  content: PropTypes.string.isRequired,
};

export default InfoTooltip;
