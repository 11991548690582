import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { RateOfReturnIcon } from '../Icons';
import { ColoredRateText } from '../Texts';
import styles from './rateOfReturn.module.scss';

export const RateOfReturn = memo(({ value, className }) => (
  <div className={classNames(styles.container, className)}>
    <RateOfReturnIcon value={value} />
    <ColoredRateText className={styles.text} value={value} />
  </div>
));

RateOfReturn.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string,
};

RateOfReturn.defaultProps = {
  value: undefined,
  className: undefined,
};
