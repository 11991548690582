import React, { useCallback, useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import * as LightweightCharts from 'lightweight-charts';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { getChartData } from 'shared-modules/api/chartApi';
import { BUY_SELL_MAIN, CFD, CHART_RESOLUTION_MAIN, COLORS, ETF, FX } from 'shared-modules/constants';
import { getInstruments, getSettings } from 'shared-modules/api/settingsApi';
import { getLastYearRange } from 'shared-modules/services';
import Logger from 'shared-modules/services/Logger';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import {
  addDays,
  containsTime,
  createPointer,
  getDates,
  getPrice,
  shadowAreaSeriesOptions,
  toYYYYmmDD,
  rightBoundAreaSeriesOptions,
  getChartSettingPriceRange,
  formatDate,
} from '../ChartMake/ChartMakeDrawPage/LightSketchboard';
import CustomButton from '../../components/CustomButton';
import styles from './mobileDrawOrder.module.scss';

let historyPoints = [];
let drawingPoints = [];
let invisiblePoints = [];

const bottomBarHeight = 60;

const dateEq = (time, dest) => {
  const { year, day, month } = time;
  const formattedDateStr = year ? toYYYYmmDD(new Date(year, month - 1, day)) : toYYYYmmDD(new Date(time * 1000));
  return formattedDateStr === toYYYYmmDD(dest);
};

const { startTime, endTime } = getLastYearRange();

function createChart(serviceId, instrument, onDrawingEnd, precision, chartMakeSetting) {
  const instrumentIdSymbol = serviceId === FX ? removeSuffix(instrument) : instrument;
  const currentDate = new Date();
  const rightTimeBound = addDays(currentDate, 365).getTime() / 1000;
  const historyPrices = historyPoints.map((point) => point.value);

  const prevDayEndPrice = historyPoints[historyPoints.length - 1].value;
  const { settingMaxPrice, settingMinPrice } = getChartSettingPriceRange(
    serviceId,
    chartMakeSetting.instrumentId,
    prevDayEndPrice,
    chartMakeSetting,
  );
  const maxPrice = Math.max(...historyPrices, settingMaxPrice);
  const minPrice = Math.min(...historyPrices, settingMinPrice);

  const marginVerticalRatioCalc = (maxPrice / minPrice) * 0.0045;
  const marginVerticalRatio = Number.isFinite(marginVerticalRatioCalc) ? marginVerticalRatioCalc : 0;

  const dateArray = getDates(currentDate, addDays(currentDate, 395));
  drawingPoints = [historyPoints[historyPoints.length - 1]];
  invisiblePoints = dateArray.map((date) => ({ time: date.getTime() / 1000, value: drawingPoints[0].value }));

  const chartElement = document.getElementById('chart');

  const pointer = createPointer();
  if (chartElement) chartElement.append(pointer);
  const offsetX = 0;
  const offsetY = 0;

  const numberOfBars = historyPoints.length + invisiblePoints.length;
  const chartWidth = window.innerWidth - offsetX;
  const chartHeight = window.innerHeight - offsetY - bottomBarHeight;
  const priceScaleWidth = 46;
  const barSpacing = (chartWidth - priceScaleWidth) / numberOfBars;

  const chart = LightweightCharts.createChart(chartElement, {
    width: chartWidth,
    height: chartHeight,
    crosshair: {
      mode: LightweightCharts.CrosshairMode.Normal,
    },
    layout: {
      backgroundColor: 'rgba(54, 54, 54, 1)',
      textColor: 'rgba(255, 255, 255, 0.8)',
    },
    grid: {
      vertLines: {
        color: 'rgba(255, 255, 255, 0.2)',
      },
      horzLines: {
        color: 'rgba(255, 255, 255, 0.2)',
      },
    },
    rightPriceScale: {
      borderColor: 'rgba(255, 255, 255, 0.8)',
      scaleMargins: {
        top: 0,
        bottom: 0,
      },
    },
    timeScale: {
      borderColor: 'rgba(255, 255, 255, 0.8)',
      barSpacing,
    },
    autoScale: true,
    localization: {
      locale: 'ja-JP',
      dateFormat: 'yyyy-MM-dd',
      priceFormatter: (price) => price.toFixed(precision),
    },
    handleScroll: {
      mouseWheel: false,
      pressedMouseMove: false,
      horzTouchDrag: false,
      vertTouchDrag: false,
    },
    handleScale: {
      axisPressedMouseMove: false,
      mouseWheel: false,
      pinch: false,
    },
  });

  const historicalBarsLineSeries = chart.addLineSeries({
    color: '#4094E8',
    priceScaleId: 'right',
    scaleMargins: {
      top: 0,
      bottom: 0,
    },
    priceLineStyle: LightweightCharts.LineStyle.Solid,
  });

  historicalBarsLineSeries.setData(historyPoints);

  const invisibleLineSeries = chart.addLineSeries({
    visible: false,
    priceScaleId: 'right',
    scaleMargins: {
      top: 0,
      bottom: 0,
    },
  });

  invisibleLineSeries.setData(invisiblePoints);

  const mainLineSeries = chart.addLineSeries({
    color: '#fff',
    priceScaleId: 'right',
    scaleMargins: {
      top: 0,
      bottom: 0,
    },
    lineWidth: 2,
  });
  mainLineSeries.setData(drawingPoints);

  const shadowAreaSeries = chart.addAreaSeries(shadowAreaSeriesOptions);

  const upperBound = maxPrice * (1 + marginVerticalRatio);

  shadowAreaSeries.setData([
    { time: formatDate(startTime), value: upperBound },
    { time: formatDate(endTime), value: upperBound },
  ]);

  const rightBoundAreaSeries = chart.addAreaSeries({
    ...rightBoundAreaSeriesOptions,
    autoscaleInfoProvider: () => ({
      priceRange: {
        maxValue: upperBound,
        minValue: minPrice * (1 - marginVerticalRatio),
      },
    }),
  });
  const rightBoundPoint = { time: rightTimeBound, value: upperBound };
  rightBoundAreaSeries.setData([rightBoundPoint]);
  const lastDay = toYYYYmmDD(rightTimeBound);

  const maxY = chartHeight - chart.timeScale().height();

  const canvas = document.getElementsByTagName('canvas')[1];
  canvas.addEventListener(
    'touchstart',
    function touchstart() {
      this.down = true;
    },
    0,
  );
  canvas.addEventListener(
    'touchend',
    function touchend() {
      this.down = false;
      onDrawingEnd(
        drawingPoints.length >= 2 ? dateEq(drawingPoints[drawingPoints.length - 1].time, rightTimeBound) : false,
      );
    },
    0,
  );

  canvas.addEventListener(
    'touchmove',
    function touchmove(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.style.cursor = 'pointer';
      let x;
      let y;

      if (this.down) {
        if (!e.pageX) {
          x = e.targetTouches[0].clientX - offsetX;
          y = e.targetTouches[0].clientY - offsetY;
        } else {
          x = e.pageX - offsetX;
          y = e.pageY - offsetY;
        }

        if (y < 0 || y >= maxY) return;

        try {
          const time = chart.timeScale().coordinateToTime(x);
          const value = mainLineSeries.coordinateToPrice(y);

          if (x && y && time && value) {
            const point = {
              time,
              value,
            };
            const { year, day, month } = point.time;
            const timestamp = year
              ? toYYYYmmDD(new Date(year, month - 1, day))
              : toYYYYmmDD(new Date(point.time * 1000));

            if (timestamp > lastDay) {
              if (drawingPoints.length < 2 || containsTime(drawingPoints, rightBoundPoint)) return;

              const lastPoint = drawingPoints[drawingPoints.length - 1];

              const k = (value * 100000 - lastPoint.value * 100000) / (time - lastPoint.time);
              const b = value * 100000 - k * time;

              const price = (k * time + b) / 100000;
              point.time = rightTimeBound;
              point.value = price;
            }

            if (containsTime(drawingPoints, point) || Number.isNaN(point.value)) return;
            if (point.value < minPrice) point.value = minPrice;
            if (point.value > maxPrice) point.value = maxPrice;
            mainLineSeries.update(point);
            drawingPoints.push(point);
            this.X = x;
            this.Y = y;
            pointer.style.left = `${chart.timeScale().timeToCoordinate(point.time) + offsetX - 16}px`;
            pointer.style.top = `${mainLineSeries.priceToCoordinate(point.value) + offsetY - 16}px`;
          }
        } catch (ex) {
          Logger.error(ex);
        }
      }
    },
    0,
  );

  const legend = document.createElement('div');
  legend.className = 'three-line-legend';
  chartElement.appendChild(legend);
  legend.style.display = 'block';
  legend.style.left = `${offsetX + 3}px`;
  legend.style.top = `${offsetY + 3}px`;
  legend.innerHTML = `<div style="font-size: 12px; margin: 4px 0px; color: white"> ${instrumentIdSymbol}</div>`;
  mainLineSeries.resetPointer = () => {
    const [lastPoint] = drawingPoints.slice(-1);
    const startingX = chart.timeScale().timeToCoordinate(lastPoint.time);
    const startingY = historicalBarsLineSeries.priceToCoordinate(lastPoint.value);
    pointer.style.left = `${startingX + offsetX - 16}px`;
    pointer.style.top = `${startingY + offsetY - 16}px`;
  };

  chart
    .timeScale()
    .setVisibleRange({ from: historyPoints[0].time, to: invisiblePoints[invisiblePoints.length - 1].time });

  mainLineSeries.chart = chart;
  return mainLineSeries;
}

const MobileDrawOrder = ({ location }) => {
  const [drawingCompleted, setDrawingCompleted] = useState(false);
  const { instrumentId, serviceId, precision } = queryString.parse(location.search);
  const lineSeries = useRef();

  const handleDrawingEnd = useCallback(
    (isDone) => {
      if (drawingCompleted !== isDone) setDrawingCompleted(isDone);
    },
    [drawingCompleted, setDrawingCompleted],
  );

  const handleSubmit = () => {
    if (window.ReactNativeWebView) {
      const drawingData = invisiblePoints
        .map((x) => ({ time: x.time, value: getPrice(x.time, drawingPoints, lineSeries.current.chart) }))
        .filter((p) => p.value !== undefined);
      window.ReactNativeWebView.postMessage(JSON.stringify({ chartData: lineSeries.current.chartData, drawingData }));
    }
  };

  const onCancel = useCallback(() => {
    drawingPoints.length = 0;
    drawingPoints.push(historyPoints[historyPoints.length - 1]);
    lineSeries.current.setData(drawingPoints);

    if (lineSeries.current) setTimeout(lineSeries.current.resetPointer, 200);
    handleDrawingEnd(false);
  }, [lineSeries, handleDrawingEnd]);

  const getData = useCallback(async () => {
    const { data } = await getChartData({
      instrumentId,
      resolution: CHART_RESOLUTION_MAIN.DAYS_1.ID,
      askBid: BUY_SELL_MAIN.BUY.CHART_ID,
      startTime,
      endTime,
      isMobile: true,
      serviceId,
    });

    let instrument = instrumentId;

    if (serviceId === ETF) {
      const { data: ETFInstruments } = await getInstruments({ isPublic: true, serviceId: ETF });
      instrument = ETFInstruments.find((item) => item.instrumentId === instrumentId)?.shortName;
    }
    if (serviceId === CFD) {
      const { data: CFDInstruments } = await getInstruments({ isPublic: true, serviceId: CFD });
      instrument = CFDInstruments.find((item) => item.instrumentId === instrumentId)?.shortName;
    }
    const {
      data: {
        [serviceId]: { chart_make_settings: chartMakeSettings },
      },
    } = await getSettings({ isPublic: true });
    const chartMakeSetting = chartMakeSettings.find((s) => s.instrumentId === instrumentId);

    historyPoints = _.uniqBy(
      data.map((bar) => ({ time: formatDate(new Date(bar.startTime)), value: bar.close })),
      'time',
    );
    if (moment(endTime).isSame(historyPoints[historyPoints.length - 1].time, 'day'))
      historyPoints = historyPoints.slice(0, -1);

    lineSeries.current = createChart(serviceId, instrument, handleDrawingEnd, precision, chartMakeSetting);
    lineSeries.current.chartData = data;
    onCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentId, serviceId]);

  useEffect(() => {
    getData();

    let timeout;
    const handleResize = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        const offsetX = 0;
        const offsetY = 0;

        const numberOfBars = historyPoints.length + invisiblePoints.length;
        const chartWidth = window.innerWidth - offsetX;
        const chartHeight = window.innerHeight - offsetY - bottomBarHeight;
        const priceScaleWidth = 56;
        const barSpacing = (chartWidth - priceScaleWidth) / numberOfBars;
        lineSeries.current.chart.applyOptions({
          width: chartWidth,
          height: chartHeight,
          timeScale: {
            borderColor: 'rgba(255, 255, 255, 0.8)',
            barSpacing,
          },
        });
        setTimeout(lineSeries.current.resetPointer, 200);
      }, 200);
    };
    window.addEventListener('resize', handleResize);
  }, [getData, instrumentId]);

  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i += 1) {
    if (metas[i].getAttribute('name') === 'viewport') {
      metas[i].content = 'initial-scale=1.0, maximum-scale=1.0';
    }
  }

  return (
    <div
      style={{
        backgroundColor: 'rgba(54, 54, 54, 1)',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        style={{
          height: 'calc(100vh)',
          backgroundColor: 'rgba(54, 54, 54, 1)',
          display: 'inline',
          marginRight: 'auto',
        }}
        id="chart"
      />
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: bottomBarHeight, backgroundColor: 'rgba(60, 60, 60, 1)' }}
      >
        <CustomButton onClick={onCancel} width={200} className={styles.buttonInnerStyle}>
          やり直す
        </CustomButton>

        <CustomButton
          onClick={handleSubmit}
          color={COLORS.GREEN}
          width={200}
          isDisabled={!drawingCompleted}
          className={styles.buttonInnerStyle}
        >
          次のステップへ
        </CustomButton>
      </div>
    </div>
  );
};

MobileDrawOrder.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.shape({
      instrumentId: PropTypes.string.isRequired,
      serviceId: PropTypes.string.isRequired,
      precision: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default MobileDrawOrder;
