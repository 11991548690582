import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import PropTypes from 'prop-types';
import { MODAL_SIZES, COLORS, WIDTH } from 'shared-modules/constants';
import { useInstrumentShortName } from 'shared-modules/hooks';
import { addSuffixInstrumentId, removeSuffix } from 'shared-modules/hooks/symbol';
import { isYenConversionInstrument } from 'shared-modules/utils';
import {
  sendNotificationError,
  sendNotificationSuccess,
  updateDisplayingTradeCurrencyPair,
} from '../../../../redux/actions';
import Modal from '../../../../components/Modal';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import CustomButton from '../../../../components/CustomButton';
import styles from './manualTradeCurrencySettings.module.scss';

const CheckboxWrapper = ({ item, onChangeHandler, hasError, resetError }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const isYenConversion = isYenConversionInstrument(item.instrumentId, serviceId);
  const getInstrumentIdForYenConversion = () => {
    if (isYenConversion) {
      return addSuffixInstrumentId(item.instrumentId);
    }
    return item.instrumentId;
  };
  const label = removeSuffix(useInstrumentShortName(getInstrumentIdForYenConversion()));

  const handleToggleCheckbox = useCallback(() => {
    onChangeHandler((prevValue) =>
      prevValue.map((checkboxObject) => {
        if (checkboxObject.instrumentId === item.instrumentId) {
          return {
            ...checkboxObject,
            displayed: !checkboxObject.displayed,
          };
        }
        return checkboxObject;
      }),
    );
    resetError(false);
  }, [onChangeHandler, item.instrumentId, resetError]);

  return (
    <CustomCheckbox
      isChecked={item.displayed}
      onChange={handleToggleCheckbox}
      label={label}
      className={styles.checkbox}
      labelClassName={styles.labelClassName}
      hasError={hasError}
    />
  );
};

CheckboxWrapper.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  item: PropTypes.shape({
    displayed: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    instrumentId: PropTypes.string.isRequired,
  }).isRequired,
  hasError: PropTypes.bool.isRequired,
  resetError: PropTypes.func.isRequired,
};

const ManualTradeCurrencySettings = ({ isOpen, closeModal }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const selectedValues = useSelector((state) => state.settings[serviceId].displayedInstruments);

  const [hasError, changeHasError] = useState(false);

  const [locallySelectedCurrencyPairs, changeLocallySelectedCurrencyPairs] = useState([]);
  useEffect(() => {
    if (isOpen) {
      changeLocallySelectedCurrencyPairs(selectedValues);
      changeHasError(false);
    }
  }, [selectedValues, isOpen]);

  const isAllSelected = useMemo(
    () => locallySelectedCurrencyPairs.every(({ displayed }) => displayed),
    [locallySelectedCurrencyPairs],
  );

  const handleToggleSelectAllCurrencyPairs = useCallback(() => {
    if (isAllSelected) {
      changeLocallySelectedCurrencyPairs((prevVal) => prevVal.map((item) => ({ ...item, displayed: false })));
    } else {
      changeLocallySelectedCurrencyPairs((prevVal) => prevVal.map((item) => ({ ...item, displayed: true })));
      changeHasError(false);
    }
  }, [isAllSelected, changeLocallySelectedCurrencyPairs]);

  const dispatch = useDispatch();

  const handleUpdateCurrencyPair = useCallback(() => {
    const validateError = locallySelectedCurrencyPairs.reduce(
      (errorFlag, item) => (item.displayed ? false : errorFlag),
      true,
    );
    if (validateError) {
      changeHasError(true);
      dispatch(sendNotificationError({ message: '1以上の銘柄を選択してください。' }));
      return;
    }

    batch(() => {
      dispatch(updateDisplayingTradeCurrencyPair({ array: locallySelectedCurrencyPairs, serviceId }));
      dispatch(sendNotificationSuccess({ message: '保存しました。' }));
      closeModal();
    });
  }, [dispatch, locallySelectedCurrencyPairs, closeModal, serviceId]);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="銘柄表示設定" size={MODAL_SIZES.WIDTH_360}>
      <div className={styles.wrapper}>
        <div className={styles.tittleRow}>
          銘柄
          <CustomButton onClick={handleToggleSelectAllCurrencyPairs} isSmall>
            {isAllSelected ? '全てを非選択' : '全てを選択'}
          </CustomButton>
        </div>
        <div className={styles.checkboxContainer}>
          {locallySelectedCurrencyPairs.map((item) => (
            <CheckboxWrapper
              key={item.id}
              onChangeHandler={changeLocallySelectedCurrencyPairs}
              item={item}
              hasError={hasError}
              resetError={changeHasError}
            />
          ))}
        </div>
        <CustomButton
          color={COLORS.RED}
          width={WIDTH.PERCENTAGE_100}
          className={styles.button}
          onClick={handleUpdateCurrencyPair}
          isDisabled={hasError}
        >
          保存
        </CustomButton>
      </div>
    </Modal>
  );
};

ManualTradeCurrencySettings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(ManualTradeCurrencySettings);
