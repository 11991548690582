import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LEARN_TRIAUTO_MENU, LEARN_TRIAUTO_STATUS } from 'shared-modules/constants';
import LearnTriautoTabs from '../LearnTriautoTabs';
import styles from './SelectionLearnTriautoContent.module.scss';

const SelectionLearnTriautoContent = () => {
  const { beginnerReadStatusList } = useSelector((state) => state.settings.learnTriautoStatus);

  const options = useMemo(() => {
    return LEARN_TRIAUTO_MENU.BEGINNER.STEPS.map(({ key, title }) => ({
      id: key,
      value: title,
      isChecked: beginnerReadStatusList[key] === LEARN_TRIAUTO_STATUS.READ_STATUS.READ,
    }));
  }, [beginnerReadStatusList]);
  return (
    <LearnTriautoTabs type={LEARN_TRIAUTO_MENU.BEGINNER.ID} options={options} itemClassName={styles.itemClassName} />
  );
};

export default memo(SelectionLearnTriautoContent);
