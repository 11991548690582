import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'shared-modules/redux/actions/routerActions';
import {
  updateUserLearnTriautoStatus,
  updateUserLearnTriautoStatusRequest,
} from 'shared-modules/redux/actions/settingsActions';
import { MODAL_SIZES, LEARN_TRIAUTO_MENU, LEARN_TRIAUTO_STATUS } from 'shared-modules/constants';
import { Button, Modal } from '../../../../components';
import { AUTO_SELECT } from '../../../../constants';
import styles from './BeginRunAuto.module.scss';
import beginRunAuto from '../../../../assets/learnTriauto/beginRunAuto.png';

const BeginRunAuto = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { beginnerReadStatusList } = useSelector((state) => state.settings.learnTriautoStatus);

  const openAutoSelect = useCallback(() => {
    if (beginnerReadStatusList[LEARN_TRIAUTO_MENU.BEGINNER.STEPS[2].key] === LEARN_TRIAUTO_STATUS.READ_STATUS.UNREAD) {
      dispatch(updateUserLearnTriautoStatus({ key: LEARN_TRIAUTO_MENU.BEGINNER.STEPS[2].key }));
      dispatch(updateUserLearnTriautoStatusRequest());
    }
    closeModal();
    dispatch(push(`/${AUTO_SELECT}`));
  }, [dispatch, beginnerReadStatusList, closeModal]);

  const closePanel = useCallback(() => {
    if (beginnerReadStatusList[LEARN_TRIAUTO_MENU.BEGINNER.STEPS[2].key] === LEARN_TRIAUTO_STATUS.READ_STATUS.UNREAD) {
      dispatch(updateUserLearnTriautoStatus({ key: LEARN_TRIAUTO_MENU.BEGINNER.STEPS[2].key }));
      dispatch(updateUserLearnTriautoStatusRequest());
    }
    closeModal();
  }, [dispatch, beginnerReadStatusList, closeModal]);

  const leftContents = () => {
    return (
      <div>
        <img src={beginRunAuto} className={styles.img} alt="口座に入金する" />
      </div>
    );
  };

  const rightContents = () => {
    return (
      <div>
        <div className={styles.topTitle}>
          <p>自動売買ルールを稼働する</p>
        </div>
        <div className={styles.sentenceBlock}>
          <span>トライオートで人気の取引方法は、</span>
          <span className={styles.bold}>
            レンジ相場を形成しやすい
            <br />
            銘柄で、売買を繰り返す
          </span>
          <span>自動売買です。</span>
          <br />
          <br />
          <span>
            豪ドル/NZドル・ユーロ/英ポンド・米ドル/カナダドルを組み
            <br />
            合わせた自動売買向けのルール「三大陸」で利益と安定運用を
            <br />
            目指しましょう。
          </span>
        </div>
        <div className={styles.footer}>
          <Button className={styles.button} onClick={openAutoSelect} primary>
            セレクトでえらぶ
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {isOpen && (
        <Modal
          closeModal={closePanel}
          isOpen={isOpen}
          centered
          size={MODAL_SIZES.WIDTH_1024}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className={styles.modalComponent}>
            <div className={styles.leftComponent}>{leftContents()}</div>
            <div className={styles.rightComponent}>{rightContents()}</div>
          </div>
        </Modal>
      )}
    </>
  );
};

BeginRunAuto.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(BeginRunAuto);
