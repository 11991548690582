import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './StartUpGuide.module.scss';
import { MEDIA, WEB_GUIDE_CATEGORY_LIST_ARTICLE_DISPLAY_NUM } from '../../constants';
import FlimStripItems from '../../screens/Media/components/FilmStripItems';

const StartUpGuide = memo(() => {
  const { startUpGuide } = useSelector((state) => state.guide);

  if (startUpGuide?.items) {
    return (
      <>
        <div className={styles.startUpGuideSection}>
          <div className={styles.startUpGuideTitle}>
            <h2>
              {startUpGuide.categoryName}
              <Link to={`/${MEDIA}`} className={styles.linkToMedia} style={{ textDecoration: 'none' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  すべて見る
                  <i className="material-icons" style={{ fontSize: 15, display: 'inline' }}>
                    chevron_right
                  </i>
                </div>
              </Link>
            </h2>
          </div>
        </div>
        <FlimStripItems items={startUpGuide.items.slice(0, WEB_GUIDE_CATEGORY_LIST_ARTICLE_DISPLAY_NUM)} isHome />
      </>
    );
  }
  return <></>;
});

export default memo(StartUpGuide);
