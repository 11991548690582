import { memo } from 'react';
import PropTypes from 'prop-types';
import { Tag } from '../../components';
import styles from './unreadLabel.module.scss';

const UnreadLabel = ({ readFlg }) => (readFlg ? null : <Tag className={styles.unread} text="未読" />);

UnreadLabel.propTypes = {
  readFlg: PropTypes.bool,
};

UnreadLabel.defaultProps = {
  readFlg: false,
};

export default memo(UnreadLabel);
