import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ORDER_TYPES } from 'shared-modules/constants/manualTrade';
import {
  BUY_SELL_MAIN,
  BUY_SELL_VALUE,
  CFD,
  EXPIRATION_TYPE_MAIN,
  EXPIRATION_TYPE_VALUES,
  ORDER_METHOD_VALUE,
} from 'shared-modules/constants';
import {
  createDateString,
  formatNumberToDisplayedString,
  getServiceQuantityUnit,
  trimFrontZeros,
} from 'shared-modules/services';
import { useGetInstrumentDisplayName } from 'shared-modules/hooks';
import { useCalculateManualMargin } from 'shared-modules/services/hooks';
import useFormatPrice from '../../hooks/useFormatPrice';
import styles from './ifoOrder.module.scss';

const IFOOrder = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const quantityUnit = getServiceQuantityUnit(serviceId);
  const instrumentId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  const formatPrice = useFormatPrice(instrumentId);
  const getInstrumentDisplayName = useGetInstrumentDisplayName();
  const { quantityUnitConvFactor } = useSelector((state) => state.settings.instrumentList[instrumentId]);

  const orderMethod = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.ORDER_METHOD],
  );
  const buySell = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.BUY_SELL],
  );
  const count = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.COUNT],
  );
  const price = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.PRICE],
  );
  const expirationType = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.EXPIRATION_TYPE],
  );
  const selectedDate = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SELECTED_DATE],
  );
  const selectedTime = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SELECTED_TIME],
  );
  const settlementLimitPrice = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SETTLEMENT_LIMIT_PRICE],
  );
  const settlementStopPrice = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SETTLEMENT_STOP_PRICE],
  );
  const settlementExpirationType = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SETTLEMENT_EXPIRATION_TYPE],
  );
  const settlementSelectedDate = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SETTLEMENT_SELECTED_DATE],
  );
  const settlementSelectedTime = useSelector(
    (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SETTLEMENT_SELECTED_TIME],
  );

  const calculatedMargin = useCalculateManualMargin(count, price);

  const isCFD = useMemo(() => serviceId === CFD, [serviceId]);

  return (
    <>
      <div className={styles.headerRow}>新規</div>
      <div className={styles.brandRow}>
        銘柄
        <span>{getInstrumentDisplayName(instrumentId, serviceId)}</span>
      </div>
      <div className={styles.orderTypeRow}>
        注文条件
        <span>{ORDER_METHOD_VALUE[orderMethod]}</span>
      </div>
      <div className={styles.buySellRow}>
        売買
        <span>{BUY_SELL_VALUE[buySell]}</span>
      </div>
      <div className={styles.countRow}>
        <div>
          数量
          {isCFD && <div>1Lot={quantityUnitConvFactor}</div>}
        </div>
        <span className={styles.boldText}>{`${count}${quantityUnit}`}</span>
      </div>
      <div className={styles.calculatingMarginRow}>
        発注証拠金目安
        <div className={styles.boldText}>
          {formatNumberToDisplayedString({ value: calculatedMargin, withoutPlus: true })}
        </div>
      </div>
      <div className={styles.priceRow}>
        価格
        <span>{formatPrice(price)}</span>
      </div>
      <div className={styles.expirationTypeRow}>
        有効期限
        <span>
          {expirationType === EXPIRATION_TYPE_MAIN.CUSTOM.ID
            ? createDateString(selectedDate, selectedTime, true)
            : EXPIRATION_TYPE_VALUES[expirationType]}
        </span>
      </div>
      <div className={styles.settlementHeaderRow}>決済</div>
      <div className={styles.settlementOrderType}>
        注文条件
        <span>OCO</span>
      </div>
      <div className={styles.settlementBuySell}>
        売買
        <span>{BUY_SELL_VALUE[buySell === BUY_SELL_MAIN.SELL.ID ? BUY_SELL_MAIN.BUY.ID : BUY_SELL_MAIN.SELL.ID]}</span>
      </div>
      <div className={styles.settlementCount}>
        <div>
          数量
          {isCFD && <div>1Lot={quantityUnitConvFactor}</div>}
        </div>
        <span>{`${trimFrontZeros(count)}${quantityUnit}`}</span>
      </div>
      <div className={styles.settlementLimitPrice}>
        指値価格
        <span>{formatPrice(settlementLimitPrice)}</span>
      </div>
      <div className={styles.settlementStopPrice}>
        逆指値価格
        <span>{formatPrice(settlementStopPrice)}</span>
      </div>
      <div className={styles.settlementExpirationType}>
        有効期限
        <span>
          {settlementExpirationType === EXPIRATION_TYPE_MAIN.CUSTOM.ID
            ? createDateString(settlementSelectedDate, settlementSelectedTime, true)
            : EXPIRATION_TYPE_VALUES[settlementExpirationType]}
        </span>
      </div>
    </>
  );
};

export default memo(IFOOrder);
