import { INNER_ERROR_VALIDATION_ERRORS } from '../constants/errorMesages';

const NORMAL_LOGIN_MIN_LENGTH = 6;
const NORMAL_LOGIN_MAX_LENGTH = 20;

export const getValidationResult = (validationResults) => {
  const errorMessages = validationResults.reduce((arr, error) => {
    if (!error.isValid) {
      arr.push({ inputName: error.inputName, errorMessage: error.errorMessage });
    }
    return arr;
  }, []);

  if (!errorMessages.length) {
    return;
  }

  const errorObject = new Error(INNER_ERROR_VALIDATION_ERRORS);
  errorObject.errorMessages = errorMessages;

  throw errorObject;
};

export const validateName = (validateObject, errorMessage) => {
  const [inputName, inputValue] = Object.entries(validateObject)[0];

  const result = {
    isValid: true,
    inputName,
    errorMessage: null,
  };
  if (inputValue.trim().length < 8) {
    result.isValid = false;
    result.errorMessage = errorMessage;
  }
  return result;
};

export const validateLength = (validateObject, errorMessage) => {
  const [inputName, inputValue] = Object.entries(validateObject)[0];
  const trimmedValue = inputValue.trim();

  const result = {
    isValid: true,
    inputName,
    errorMessage: null,
  };
  if (trimmedValue.length < NORMAL_LOGIN_MIN_LENGTH) {
    result.isValid = false;
    result.errorMessage = errorMessage;
  }
  if (trimmedValue.length > NORMAL_LOGIN_MAX_LENGTH) {
    result.isValid = false;
    result.errorMessage = errorMessage;
  }
  return result;
};
