import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CFD, ETF } from 'shared-modules/constants';
import { getLabImageSrc } from '../../services';
import styles from './selectionImage.module.scss';

const NON_CIRCLE_REPLACEMENTS_FOR_IMAGES = {
  'invast-circle.svg': 'invast.png',
};

const ETF_FULL_SIZE_ICONS = [
  'invast-circle.svg',
  '1321_gold.svg',
  'qqq_gold.svg',
  'spy_gold.svg',
  'tqqq_gold.svg',
  'xlk_gold.svg',
  'xly_gold.svg',
  'World.svg',
];
const CFD_FULL_SIZE_ICONS = ['invast-circle.svg'];

const getPathFolder = (serviceId) => {
  if (serviceId === ETF) {
    return 'flagsETF';
  }
  if (serviceId === CFD) {
    return 'flagsCFD';
  }
  return 'selectionImages';
};

function resolveLocalSource(name, localFolder) {
  try {
    // eslint-disable-next-line
    return require(`../../assets/${localFolder}/${name}`);
  } catch {
    return null;
  }
}

function resolveSource(source, localFolder) {
  if (!source) return null;
  if (source.startsWith('http')) return source;

  let name = source;
  if (localFolder !== 'selectionImages') {
    const replacementName = NON_CIRCLE_REPLACEMENTS_FOR_IMAGES[source];
    name = replacementName ?? name;
  }

  return resolveLocalSource(name, localFolder);
}

function isFullSize(image, serviceId) {
  if (!image.toLowerCase().endsWith('.svg')) {
    return true;
  }
  if (serviceId === ETF) {
    return ETF_FULL_SIZE_ICONS.includes(image);
  }
  if (serviceId === CFD) {
    return CFD_FULL_SIZE_ICONS.includes(image);
  }
  return true;
}

const SelectionImage = ({ image, className, serviceId, hasFrame, width, isShare }) => {
  if (isShare) {
    return (
      <div
        className={classNames(styles.imageWrapper, {
          [className]: className,
        })}
        style={width ? { width } : {}}
      >
        <div className={styles.circleFrameShare} style={{ width, height: width }}>
          <img src={getLabImageSrc(image)} className={styles.imageStyle} alt="" />
        </div>
      </div>
    );
  }

  const pathFolder = getPathFolder(serviceId);
  const source = resolveSource(image, pathFolder);

  if (!source) {
    return (
      <div
        className={classNames(className, styles.emptyImage, {
          [styles.circleFrame]: hasFrame,
        })}
        style={{ width, height: width }}
      />
    );
  }

  const imageStyle = !isFullSize(image, serviceId) ? { width: '68%', height: '68%' } : {};
  return (
    <div
      className={classNames(styles.imageWrapper, {
        [className]: className,
      })}
      style={width ? { width } : {}}
    >
      <div className={styles.circleFrame} style={{ width, height: width }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <img alt="" src={source} className={styles.imageStyle} style={imageStyle} />
      </div>
    </div>
  );
};

SelectionImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  className: PropTypes.string,
  serviceId: PropTypes.string,
  hasFrame: PropTypes.bool,
  width: PropTypes.string,
  isShare: PropTypes.bool,
};

SelectionImage.defaultProps = {
  image: undefined,
  className: '',
  serviceId: null,
  hasFrame: false,
  width: '63px',
  isShare: false,
};

export default memo(SelectionImage);
