import React, { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RangeSlider from 'react-bootstrap-range-slider';
import Cropper from 'react-easy-crop';
import { Modal } from 'react-bootstrap';
import { LAB_STEP3_ICON_CROP_TITLE, MODAL_SIZES } from 'shared-modules/constants';
import { sendErrorToBugsnag } from 'shared-modules/redux/actions/errorActions';
import { truncToFixedLength } from 'shared-modules/services';
import { getCroppedImg } from '../../../../services';
import { Button } from '../../../../components';
import styles from './iconCropModal.module.scss';

const CROP_SIZE = 256;

export const IconCropModal = memo(({ sourceBlobUrl, isOpen, closeModal, finishCallback }) => {
  const dispatch = useDispatch();
  const handleStopPropagation = useCallback((e) => e.stopPropagation(), []);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [baseZoom, setBaseZoom] = useState(1);
  const [zoom, setZoom] = useState(baseZoom);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, completeCroppedAreaPixels) => {
    setCroppedAreaPixels(completeCroppedAreaPixels);
  }, []);

  const finishHandle = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(sourceBlobUrl, croppedAreaPixels);
      if (finishCallback) {
        finishCallback(croppedImage);
      }
      closeModal();
    } catch (e) {
      dispatch(sendErrorToBugsnag({ error: e }));
    }
  }, [dispatch, finishCallback, closeModal, sourceBlobUrl, croppedAreaPixels]);

  const onImageLoaded = useCallback(({ width, height, naturalWidth, naturalHeight }) => {
    const maxBorder = Math.max(naturalHeight, naturalWidth);
    // auto zoom in by CROP_SIZE
    if (maxBorder < CROP_SIZE) {
      const ratio = Number(truncToFixedLength(CROP_SIZE / maxBorder, 3));
      setBaseZoom(ratio);
      setZoom(ratio);
    }
    if (width !== height) {
      const minBorder = Math.min(width, height);
      const ratio = Number(truncToFixedLength(CROP_SIZE / minBorder, 3));
      setBaseZoom(ratio);
      setZoom(ratio);
    }
  }, []);
  return (
    <Modal
      show={isOpen}
      onHide={closeModal}
      centered
      onClick={handleStopPropagation}
      size={MODAL_SIZES.LARGE}
      dialogClassName={styles.modal}
      backdropClassName={styles.backdrop}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton={false} bsPrefix={styles.header}>
        <i aria-hidden className={classNames('material-icons-outlined', styles.close)} onClick={closeModal}>
          clear
        </i>
      </Modal.Header>
      <Modal.Body bsPrefix={styles.body}>
        <div className={styles.title}>{LAB_STEP3_ICON_CROP_TITLE}</div>
        <div className={styles.content}>
          <div className={styles.cropContainer}>
            <Cropper
              image={sourceBlobUrl}
              crop={crop}
              zoom={zoom}
              minZoom={baseZoom}
              maxZoom={5 * baseZoom}
              aspect={1}
              onCropChange={setCrop}
              cropSize={{ height: CROP_SIZE, width: CROP_SIZE }}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="round"
              showGrid={false}
              zoomWithScroll={false}
              onMediaLoaded={onImageLoaded}
            />
          </div>
          <div className={styles.zoomContainer}>
            <RangeSlider
              value={zoom}
              min={baseZoom}
              step={0.001}
              max={5 * baseZoom}
              onChange={(changeEvent) => setZoom(changeEvent.target.value)}
              size="sm"
              tooltip="off"
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button onClick={closeModal} secondary>
            キャンセル
          </Button>
          <Button onClick={finishHandle}>決定</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});

IconCropModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  sourceBlobUrl: PropTypes.string.isRequired,
  finishCallback: PropTypes.func.isRequired,
};
