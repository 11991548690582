import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Spin } from '../Spin';
import styles from './ssoSubmissionPage.module.scss';

const SSOSubmissionPage = ({ ssoState, refreshSsoState, formId }) => {
  const location = useLocation();

  const { url, token, hash } = ssoState;
  const [mobileData, setMobileData] = useState(null);

  const formRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search.substr(1));
    const isMobile = urlParams.get('isMobile');

    if (isMobile) {
      setMobileData({
        url: urlParams.get('url'),
        token: urlParams.get('token'),
        hash: urlParams.get('hash'),
      });
    } else refreshSsoState();
  }, [refreshSsoState, location.search]);

  useEffect(() => {
    if ((url && token && hash) || mobileData) {
      formRef.current.submit();
    }
  }, [url, token, hash, mobileData]);

  return (
    <div className={styles.wrapper}>
      <Spin className={styles.loader} />
      {/* Hidden form for submitting SSO */}
      <form id={formId} action={mobileData?.url ?? url} method="post" ref={formRef} style={{ visibility: 'hidden' }}>
        <input type="hidden" name="data" value={mobileData?.token ?? token} />
        <input type="hidden" name="messageDigest" value={mobileData?.hash ?? hash} />
      </form>
    </div>
  );
};

SSOSubmissionPage.propTypes = {
  formId: PropTypes.string.isRequired,
  ssoState: PropTypes.shape({
    url: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
  }).isRequired,
  refreshSsoState: PropTypes.func.isRequired,
};

export default memo(SSOSubmissionPage);
