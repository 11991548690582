import { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './table.module.scss';

export const EmptyText = memo(({ isEmptyTextShown, emptyText }) => {
  return isEmptyTextShown && <div className={styles.emptyText}>{emptyText}</div>;
});

EmptyText.propTypes = {
  isEmptyTextShown: PropTypes.bool.isRequired,
  emptyText: PropTypes.string.isRequired,
};
