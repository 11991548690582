import Decimal from 'decimal.js';
import { CHART_MAKE_BUY_SELL_MAIN, CFD, HEDGER, CHART_MAKE_CFD_ROUND_DIGIT } from '../../../../constants';
import { ceil, getBaseQuantity, getSignificantDigits } from '../../constants';
import Logic from './Logic';
import { Range, RANGE_POSITION } from './Range';

const CfdHedger = (instrumentId, inputHigh, inputLow, instrumentSetting) => {
  const digits = getSignificantDigits(CFD, instrumentId, CHART_MAKE_CFD_ROUND_DIGIT);
  const high = ceil(inputHigh, digits);
  const low = ceil(inputLow, digits);
  const SELL_HEDGER_DEFAULT_NUM_OF_ORDERS = 9;
  const RANGE_BETWEEN_SPACE_RATIO = 0.1;
  const logic = Logic(HEDGER, high, low);
  const quantity = getBaseQuantity(CFD, instrumentId, instrumentSetting);
  let width = 0;
  const buyZone = Range(
    instrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.BUY,
    high,
    low,
    quantity,
    RANGE_POSITION.ISOLATION_TOP,
  );
  const sellMax = ceil(Decimal.mul(low, Decimal.sub(1.0, RANGE_BETWEEN_SPACE_RATIO)), digits);
  const sellHedge = Range(
    instrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.SELL,
    sellMax,
    ceil(Decimal.sub(sellMax, Decimal.mul(Decimal.sub(high, low), 0.3)), digits),
    Decimal.mul(quantity, 2).toNumber(),
    RANGE_POSITION.ISOLATION_BOTTOM,
  );
  const currentSettings = {
    width: null,
  };

  return {
    backupDefaultSettings() {
      buyZone.backupDefaultSettings();
      sellHedge.backupDefaultSettings();
    },
    calcAndSetRangeSettings(step) {
      // Stepでレンジの幅、その幅から本数を計算
      this.calcAndSetWidth(step);
      this.calcAndSetNumOfOrders();
      this.ajustMaxMin(high);
    },
    ajustMaxMin(max) {
      buyZone.setMax(ceil(max, digits));
      buyZone.ajustMin();
      sellHedge.setMax(ceil(Decimal.mul(buyZone.getMin(), Decimal.sub(1, RANGE_BETWEEN_SPACE_RATIO)), digits));
      sellHedge.ajustMin();
    },
    calcAndSetWidth(step) {
      width = ceil(Decimal.mul(high, Decimal.add(0.005, step)), digits);
      buyZone.setWidth(width);
      sellHedge.setNumOfOrdersAjustWidth(SELL_HEDGER_DEFAULT_NUM_OF_ORDERS);
    },
    calcAndSetNumOfOrders() {
      buyZone.calcAndSetNumOfOrders();
    },
    getNumOfOrders() {
      return buyZone.getNumOfOrders() + sellHedge.getNumOfOrders();
    },
    backupCurrentSettings() {
      currentSettings.width = width;
      buyZone.backupCurrentSettings();
      sellHedge.backupCurrentSettings();
    },
    rollbackSettings() {
      width = currentSettings.width;
      buyZone.rollbackSettings();
      sellHedge.rollbackSettings();
    },
    calcAndSetTp() {
      const sp = [10, 0.1];
      const tp = [];
      for (let i = 0; i < 7; i += 1) {
        const tmpTp = Math.min(
          ceil(
            Math.max(Decimal.mul(high, Decimal.div(0.042, Decimal.sub(7, i))), Decimal.mul(sp[0], Decimal.add(i, 2))),
            digits,
          ),
          buyZone.getRangeWidth(),
        );
        tp.push(tmpTp);
      }
      tp.push(Math.min(ceil(Decimal.mul(tp[6], 2), digits), buyZone.getRangeWidth()));
      tp.push(Math.min(ceil(Decimal.mul(tp[6], 3), digits), buyZone.getRangeWidth()));
      buyZone.setTp(tp);
      sellHedge.setTp([Math.max(ceil(Decimal.mul(Decimal.sub(high, low), 0.1), digits), sp[1])]);
    },
    getAllRanges() {
      return [buyZone, sellHedge];
    },
    ...logic,
    calcAndSetSl() {
      const sp = -10;
      sellHedge.setSl(Math.min(ceil(Decimal.mul(-1, Decimal.mul(sellHedge.getTp()[0], 0.8)), digits), sp));
    },
    createUnsortedAps() {
      return buyZone.createAps().concat(sellHedge.createAps());
    },
    validateRange() {
      let result = { isValid: true };
      if (this.getAllRanges().some((x) => x.getMax() <= 0 || x.getMin() <= 0)) {
        result = {
          isValid: false,
          errorMessage: 'この設定では価格が範囲外となるレンジが存在するため注文変更できません。',
        };
      }
      if (this.getAllRanges().some((x) => x.getNumOfOrders() === 0)) {
        result = {
          isValid: false,
          errorMessage: 'この設定では本数が0となるレンジが存在するため注文変更できません。',
        };
      }
      if (buyZone.getMin() < sellHedge.getMax()) {
        result = {
          isValid: false,
          errorMessage: 'この設定ではレンジが他のレンジの範囲と重複するため注文変更できません。',
        };
      }
      return result;
    },
  };
};

export default CfdHedger;
