import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  COLORS,
  COMPREHENSIVE_EVALUATION_LABEL,
  LAB_POST_SUMMARY_SIMULATION_EMPTY_MESSAGE,
  LAB_SIMULATION_EMPTY_MESSAGE,
  RECOMMENDED_MARGIN_LABEL,
  REQUIRED_MARGIN_LABEL,
  REQUIRED_MARGIN_TOOLTIP,
} from 'shared-modules/constants';
import {
  formatNumberToDisplayedString,
  formatNumberToPercentageString,
  getJapaneseFormatDate,
} from 'shared-modules/services';
import { recommendedMarginTooltips } from 'shared-modules/services/common';
import { openAutoSelectWarningInfo } from '../../../../../../redux/actions';
import { Evaluation, Spin } from '../../../../../../components';
import ProfitLossChart from '../../../../../../components/ProfitLossChart';
import CustomButton from '../../../../../../components/CustomButton';
import CustomTooltip from '../../../../../../components/CustomTooltip/CustomTooltip';
import styles from './profitLossChartContainer.module.scss';

const chartMargin = {
  top: 10,
  left: 0,
  right: 5,
  bottom: 0,
};

const ProfitLossChartContainer = ({
  chartData,
  isLoading,
  simulationStats,
  isPreview,
  isPost,
  defaultSets,
  comprehensiveEvaluation,
}) => {
  const dispatch = useDispatch();
  const serviceId = useSelector((state) => state.auth.serviceId);
  const getColorClass = (number) => (number >= 0 ? 'plus' : 'minus');

  const handleOpenWarning = useCallback(
    () =>
      dispatch(
        openAutoSelectWarningInfo({
          termEndDate: getJapaneseFormatDate(simulationStats.termEnd),
          isLabPreview: isPreview,
        }),
      ),
    [dispatch, simulationStats.termEnd, isPreview],
  );

  return (
    <div className={styles.wrapper}>
      {isLoading && <Spin className={styles.loader} />}

      {!isLoading && (
        <>
          {chartData.length !== 0 ? (
            <>
              <div className={styles.firstRow}>
                <div className={styles.termContainer}>
                  期間
                  <span className={styles.value}>稼働日～</span>
                </div>
                <div className={styles.plRateContainer}>
                  期間収益率
                  <span className={classNames(styles.value, getColorClass(simulationStats?.roi))}>
                    {formatNumberToPercentageString(simulationStats?.roi)}
                  </span>
                </div>
                <div className={styles.plContainer}>
                  期間損益
                  {/* eslint-disable-next-line */}
                  <span className={classNames(styles.value, getColorClass(simulationStats?.roi))}>
                    {formatNumberToDisplayedString({ value: simulationStats?.totalPl * defaultSets })}
                  </span>
                </div>
              </div>
              <div className={styles.secondRow}>
                <div className={styles.detailsRow}>
                  <div className={styles.recommendedMargin}>
                    {RECOMMENDED_MARGIN_LABEL}
                    <div className={styles.value}>
                      {formatNumberToDisplayedString({
                        value: simulationStats.marginRecommended,
                        withoutPlus: true,
                      })}
                    </div>
                    <CustomTooltip
                      message={recommendedMarginTooltips(serviceId)}
                      className={styles.infoWrapper}
                      placement="bottom"
                    >
                      <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
                    </CustomTooltip>
                  </div>
                  <div className={styles.recommendedMargin}>
                    {REQUIRED_MARGIN_LABEL}
                    <div className={styles.value}>
                      {formatNumberToDisplayedString({
                        value: simulationStats.marginRequired,
                        withoutPlus: true,
                      })}
                    </div>
                    <CustomTooltip message={REQUIRED_MARGIN_TOOLTIP} className={styles.infoWrapper} placement="bottom">
                      <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
                    </CustomTooltip>
                  </div>
                  <div className={styles.comprehensiveEvaluation}>{COMPREHENSIVE_EVALUATION_LABEL}</div>
                  <div className={styles.comprehensiveEvaluation}>
                    <Evaluation value={comprehensiveEvaluation} />
                  </div>
                </div>
                <CustomButton color={COLORS.TRANSPARENT} onClick={handleOpenWarning} className={styles.warningButton}>
                  注意事項
                </CustomButton>
              </div>
              <div className={styles.chartWrapper}>
                <ProfitLossChart chartMargin={chartMargin} data={chartData} useDefaultYAxisWidth scale="point" />
              </div>
            </>
          ) : (
            <span className={styles.simulationDataEmptyText}>
              {isPost ? LAB_POST_SUMMARY_SIMULATION_EMPTY_MESSAGE : LAB_SIMULATION_EMPTY_MESSAGE}
            </span>
          )}
        </>
      )}
    </div>
  );
};

ProfitLossChartContainer.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  simulationStats: PropTypes.shape({
    termEnd: PropTypes.string,
    realizedPl: PropTypes.number,
    unrealizedPl: PropTypes.number,
    marginRecommended: PropTypes.number,
    marginRequired: PropTypes.number,
    roi: PropTypes.number,
    maxDd: PropTypes.number,
    totalPl: PropTypes.number,
  }),
  isPreview: PropTypes.bool,
  isPost: PropTypes.bool,
  defaultSets: PropTypes.number,
  comprehensiveEvaluation: PropTypes.number,
};
ProfitLossChartContainer.defaultProps = {
  chartData: [],
  simulationStats: {},
  isPreview: false,
  isPost: false,
  defaultSets: 1,
  comprehensiveEvaluation: undefined,
};

export default memo(ProfitLossChartContainer);
