import { get, post, invokeApi, createAdditionalParams } from './index';

export const getSelectionTags = async () => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `selection_tags`, get, additionalParams);
};

export const getSelectionsByParams = async ({ tagId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `selections?tagId=${tagId}`, get, additionalParams);
};

export const addToCart = async ({ requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, 'cart', post, additionalParams, { ...(requestBody || {}), considerParent: true });
};

export const createApGroup = async ({ serviceId, requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/ap_groups`, post, additionalParams, requestBody);
};
