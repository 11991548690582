import { memo, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { formatPrice } from 'shared-modules/services';
import { isDisplayedNumber } from 'shared-modules/utils';
import styles from './percentageText.module.scss';

export const PercentageText = memo(({ value, className, symbolClassName, color, withoutPlus }) => {
  const [formattedValue, isEmpty] = useMemo(() => {
    if (!isDisplayedNumber(value)) {
      return ['-', true];
    }
    return formatPrice({ value, withoutPlus, allowZero: true });
  }, [value, withoutPlus]);
  return (
    <div style={color && { color }} className={classNames(styles.text, className)}>
      {formattedValue}
      {!isEmpty && (
        <span style={color && { color }} className={classNames(styles.symbol, symbolClassName)}>
          %
        </span>
      )}
    </div>
  );
});

PercentageText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  className: PropTypes.string,
  symbolClassName: PropTypes.string,
  withoutPlus: PropTypes.bool,
};

PercentageText.defaultProps = {
  value: undefined,
  color: undefined,
  className: undefined,
  symbolClassName: undefined,
  withoutPlus: false,
};
