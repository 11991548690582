import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { useCurrentCartConfirmation } from 'shared-modules/services/hooks/cartLogic';
import { useAccountInfo } from 'shared-modules/hooks/useAccountInfo';
import { checkCartStrategiesType } from 'shared-modules/utils';
import { makeNotificationErrorParams } from 'shared-modules/utils/service';
import { useSummaryInfoByServiceId } from 'shared-modules/services/hooks';
import { BUILDER_REQUIRED_MARGIN_VALIDATION_MESSAGE, TECHNICAL_BUILDER } from 'shared-modules/constants/builder';
import {
  executeCartItemsRequest,
  openConfirmationModal,
  openStrategiesSuccessModal,
  closeConfirmationModal,
  sendNotificationError,
  setCartDataServiceId,
  openErrorInfoModal,
} from '../../../../../redux/actions';
import { Button } from '../../../../../components';
import styles from './footer.module.scss';

const BUTTON_SIZE = 140;

export const Footer = memo(({ errorMessages, onSave, disabledAllSave, disabledAllRun }) => {
  const dispatch = useDispatch();
  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const storedCartItems = useSelector((state) => state.cart.currentData[defaultSelectionTermId]);
  const cartItems = useMemo(() => storedCartItems ?? [], [storedCartItems]);
  const serviceId = useSelector((state) => state.auth.serviceId);
  const accountInfo = useAccountInfo();
  const { isDisabled, isLoading, marginRequired } = useCurrentCartConfirmation(errorMessages);

  const hasServiceItem = useCallback(
    (service) => cartItems.some(({ strategyDetail }) => strategyDetail.serviceId === service),
    [cartItems],
  );

  const summaryInfo = useSummaryInfoByServiceId(serviceId);
  const includeTechBuilder = cartItems
    .map((item) => item.strategyDetail.logic.name === TECHNICAL_BUILDER)
    .filter((el) => el);

  const handleExecuteOrder = useCallback(() => {
    if (includeTechBuilder.length > 0 && marginRequired > summaryInfo.orderableMargin) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: BUILDER_REQUIRED_MARGIN_VALIDATION_MESSAGE,
          buttonText: '閉じる',
        }),
      );
      return false;
    }

    const notAvailableService = ALL_SERVICES.find(
      (service) => hasServiceItem(service) && accountInfo[service].isNotAvailable,
    );
    if (notAvailableService) {
      const errorParams = makeNotificationErrorParams({ accountInfo, serviceId: notAvailableService });
      dispatch(closeConfirmationModal());
      dispatch(sendNotificationError(errorParams));
      return false;
    }

    const cartStrategiesServiceId = checkCartStrategiesType(cartItems, serviceId);

    const callback = (resultArray) => {
      dispatch(openStrategiesSuccessModal({ resultArray }));
      dispatch(setCartDataServiceId({ serviceId: cartStrategiesServiceId }));
    };

    return dispatch(executeCartItemsRequest({ callback }));
  }, [
    dispatch,
    hasServiceItem,
    accountInfo,
    cartItems,
    includeTechBuilder.length,
    marginRequired,
    serviceId,
    summaryInfo.orderableMargin,
  ]);

  const handleOpenConfirmationModal = useCallback(() => {
    if (isDisabled || disabledAllRun) {
      return;
    }
    dispatch(
      openConfirmationModal({
        title: 'カート確定：確認',
        callback: handleExecuteOrder,
        bodyText: `注文確定を行います。よろしいでしょうか。
          ※注文本数が多い場合は、注文完了までに時間がかかる場合があります。`,
        buttonBackText: '戻る',
        buttonNextText: '確定',
        successButtonIsGreen: true,
      }),
    );
  }, [handleExecuteOrder, dispatch, isDisabled, disabledAllRun]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonArea}>
        <Button width={BUTTON_SIZE} loading={isLoading} disabled={disabledAllSave} onClick={onSave} secondary>
          一括保存
        </Button>
        <Button
          width={BUTTON_SIZE}
          loading={isLoading}
          disabled={isDisabled || disabledAllRun}
          onClick={handleOpenConfirmationModal}
        >
          稼働する
        </Button>
      </div>
    </div>
  );
});

Footer.propTypes = {
  onSave: PropTypes.func.isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabledAllSave: PropTypes.bool.isRequired,
  disabledAllRun: PropTypes.bool,
};

Footer.defaultProps = {
  disabledAllRun: false,
};
