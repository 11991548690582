import { memo } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useUuid } from 'shared-modules/services/hooks';
import styles from './rangeOutIcon.module.scss';

const TIP = `稼働中自動売買のエントリー価格が前日終値から外れています。
レンジアウトになると、新規取引が行われないことや、予想と逆方向に
相場が動いてしまって未決済建玉の評価損失が拡大する可能性があります。`;

export const RangeOutIcon = memo(({ className }) => {
  const uuid = useUuid();
  return (
    <>
      <div className={classNames(styles.container, className)}>
        レンジアウト
        <div data-for={uuid} data-tip={TIP}>
          <i className="material-icons-outlined">info</i>
        </div>
      </div>
      <ReactTooltip id={uuid} className={styles.tip} />
    </>
  );
});

RangeOutIcon.propTypes = {
  className: PropTypes.string,
};

RangeOutIcon.defaultProps = {
  className: undefined,
};
