export const GET_USER_SSO_MY_PAGE_REQUEST = 'USER_SSO/GET_USER_SSO_MY_PAGE_REQUEST';
export const GET_USER_SSO_MY_PAGE_SUCCESS = 'USER_SSO/GET_USER_SSO_MY_PAGE_SUCCESS';
export const GET_USER_SSO_PAYMENT_REQUEST = 'USER_SSO/GET_USER_SSO_PAYMENT_REQUEST';
export const GET_USER_SSO_PAYMENT_SUCCESS = 'USER_SSO/GET_USER_SSO_PAYMENT_SUCCESS';
export const GET_USER_SSO_MONEY_HATCH_REQUEST = 'USER_SSO/GET_USER_SSO_MONEY_HATCH_REQUEST';
export const GET_USER_SSO_MONEY_HATCH_SUCCESS = 'USER_SSO/GET_USER_SSO_MONEY_HATCH_SUCCESS';
export const GET_USER_SSO_CASH_FLOW_REQUEST = 'USER_SSO/GET_USER_SSO_CASH_FLOW_REQUEST';
export const GET_USER_SSO_CASH_FLOW_SUCCESS = 'USER_SSO/GET_USER_SSO_CASH_FLOW_SUCCESS';
export const GET_USER_SSO_INVAST_AFFILIATE_REQUEST = 'USER_SSO/GET_USER_SSO_INVAST_AFFILIATE_REQUEST';
export const GET_USER_SSO_INVAST_AFFILIATE_SUCCESS = 'USER_SSO/GET_USER_SSO_INVAST_AFFILIATE_SUCCESS';

export const RESET_SSO_DATA = 'USER_SSO/RESET_SSO_DATA';
