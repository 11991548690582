import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './tagButton.module.scss';

export const TagButton = memo(({ disabled, children, onClick }) => {
  return (
    <button
      type="button"
      className={classNames(styles.tagButton, { [styles.disabled]: disabled })}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
});

TagButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

TagButton.defaultProps = {
  disabled: false,
  onClick: undefined,
};
