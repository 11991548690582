import { CFD, ETF, FX } from '../constants';

export const recommendedMarginTooltips = (serviceId) => {
  let message = '相場変動リスクを考慮した運用資金の目安。';
  if (!serviceId) return message;
  const serviceIdSet = Array.isArray(serviceId) ? serviceId : [serviceId];
  if (serviceIdSet.includes(FX)) {
    message +=
      'FX自動売買の推奨証拠金は「必要証拠金(レバレッジ25倍)+シミュレーション期間の最大ドローダウン」より算出。';
  }
  if (serviceIdSet.includes(CFD)) {
    message +=
      'CFD自動売買の推奨証拠金は「必要証拠金(レバレッジ10倍)+シミュレーション期間の最大ドローダウン」より算出。';
  }
  if (serviceIdSet.includes(ETF)) {
    message +=
      'ETF自動売買の推奨証拠金は「{必要証拠金(レバレッジ5倍)*銘柄毎に算出した倍率+シミュレーション期間の最大ドローダウン}」より算出。';
  }
  return message;
};
