import { CFD, ETF, FX } from 'shared-modules/constants';
import CfdTable from './CfdTable';
import EtfTable from './EtfTable';
import FxTable from './FxTable';

export const Tables = Object.freeze({
  [FX]: FxTable,
  [ETF]: EtfTable,
  [CFD]: CfdTable,
});
