// eslint-disable-next-line import/no-unresolved
import { createSelector } from '@reduxjs/toolkit';
import { CFD, ETF, FX } from '../constants';

export const currencyPairsOptionsSelector = createSelector(
  (state) => state.constants[FX].instrumentsOptions,
  (state) => state.constants[ETF].instrumentsOptions,
  (state) => state.constants[CFD].instrumentsOptions,
  (fxCurrencyPairsOptions, etfCurrencyPairsOptions, cfdCurrencyPairsOptions) => {
    return { ...fxCurrencyPairsOptions, ...etfCurrencyPairsOptions, ...cfdCurrencyPairsOptions };
  },
);

export const apGroupsSelector = createSelector(
  (state) => state.portfolio.apGroupsData[FX],
  (state) => state.portfolio.apGroupsData[ETF],
  (state) => state.portfolio.apGroupsData[CFD],
  (fxApGroups, etfApGroups, cfdApGroups) => [...fxApGroups, ...etfApGroups, ...cfdApGroups],
);

export const positionsSelector = createSelector(
  (state) => state.currencies.positions[FX],
  (state) => state.currencies.positions[ETF],
  (state) => state.currencies.positions[CFD],
  (fxPositions, etfPositions, cfdPositions) => [...fxPositions, ...etfPositions, ...cfdPositions],
);
