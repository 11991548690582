import {
  CHANGE_SELECTED_INSTRUMENT_ID,
  CHANGE_SELECTED_SIDE,
  CHANGE_CHART_SIDE,
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  CHANGE_SELECTED_TABLE,
  EXECUTIONS_TABLE_START_LOADING,
  EXECUTIONS_TABLE_STOP_LOADING,
  GET_EXECUTIONS_TABLE_DATA_REQUEST,
  GET_EXECUTIONS_TABLE_DATA_SUCCESS,
  CHANGE_EXECUTIONS_TABLE_META_INFO,
  GET_POSITIONS_START_LOADING,
  GET_POSITIONS_STOP_LOADING,
  CHANGE_POSITIONS_TABLE_META_INFO,
  CREATE_CLOSE_ORDER_START_LOADING,
  CREATE_CLOSE_ORDER_STOP_LOADING,
  CREATE_CLOSE_ORDER_REQUEST,
  DELETE_POSITIONS_ORDER_REQUEST,
  ORDERS_TABLE_START_LOADING,
  ORDERS_TABLE_STOP_LOADING,
  GET_ORDERS_TABLE_DATA_REQUEST,
  GET_ORDERS_TABLE_DATA_SUCCESS,
  CHANGE_ORDERS_TABLE_META_INFO,
  CHANGE_CREATE_ORDER_VALUES,
  RESET_CREATE_ORDER_VALUES,
  CHANGE_CREATE_ORDER_VALIDATION_ERRORS,
  ORDER_INFO_REQUEST_START_LOADING,
  ORDER_INFO_REQUEST_STOP_LOADING,
  GET_ORDER_INFO_REQUEST,
  GET_ORDER_INFO_SUCCESS,
  ORDER_CHANGE_START_LOADING,
  ORDER_CHANGE_STOP_LOADING,
  CHANGE_ORDER_REQUEST,
  DELETE_ORDER_REQUEST,
  CHANGE_SHARED_META_INFO,
  CREATE_MARKET_ORDER_REQUEST,
  CREATE_FIFO_ORDER_REQUEST,
  CREATE_STANDARD_ORDER_REQUEST,
  CREATE_OCO_ORDER_REQUEST,
  CREATE_IFD_ORDER_REQUEST,
  CREATE_IFO_ORDER_REQUEST,
  CREATE_ORDER_START_LOADING,
  CREATE_ORDER_STOP_LOADING,
  CHANGE_SELECTED_ORDER_TYPE,
  GET_NEWS_REQUEST_START_LOADING,
  GET_NEWS_REQUEST_STOP_LOADING,
  GET_NEWS_PERIODICALLY,
  UPDATE_CHANGE_ORDER_VALIDATION_ERRORS,
  UPDATE_CLOSE_ORDER_VALIDATION_ERRORS,
  RESET_CREATE_ORDERS_NON_COMMON_VALUES,
  RESET_CREATE_ORDER_ERRORS,
  RESET_CHANGE_ORDER_VALIDATION_ERRORS,
  CLEAR_STATUS_AFTER_CLOSING,
  CHANGE_POSITION_DETAILS_ORDER_LOGIC,
  CLOSE_MULTIPLE_POSITIONS_REQUEST,
  CLOSE_FIFO_SIDE_POSITIONS_REQUEST,
  SAVE_SELECTED_TABLE_ROWS,
  SAVE_DELETING_POSITIONS,
  CLOSE_FIFO_SIDE_POSITIONS_START_LOADING,
  CLOSE_FIFO_SIDE_POSITIONS_STOP_LOADING,
  RESET_POSITION_TABLE_META_INFO,
  RESET_EXECUTIONS_TABLE_META_INFO,
  RESET_ORDER_TABLE_META_INFO,
  CHANGE_SELECTED_INFO_TABLE,
  SWAP_TRANSFER_REQUEST,
  RESET_EXECUTIONS_TABLE_META_INFO_PARTIAL,
  RESET_EXECUTIONS_TABLE_META_INFO_DATE,
  CHANGE_SELECTED_TRADE_METHOD,
  CHANGE_TRADE_METHOD_TYPE,
} from '../actionConstants/manualTradeConstants';

export const changeTradeSelectedInstrumentId = ({ id, serviceId }) => ({
  type: CHANGE_SELECTED_INSTRUMENT_ID,
  payload: { id, serviceId },
});

export const changeSelectedSide = ({ id }) => ({
  type: CHANGE_SELECTED_SIDE,
  payload: { id },
});
export const changeChartSide = ({ id }) => ({
  type: CHANGE_CHART_SIDE,
  payload: { id },
});

export const changeSelectedTradeMeThod = ({ id }) => ({
  type: CHANGE_SELECTED_TRADE_METHOD,
  payload: { id },
});

export const getNewsPeriodically = () => ({ type: GET_NEWS_PERIODICALLY });

export const getNewsRequest = () => ({ type: GET_NEWS_REQUEST });
export const getNewsRequestStartLoading = () => ({ type: GET_NEWS_REQUEST_START_LOADING });
export const getNewsRequestStopLoading = () => ({ type: GET_NEWS_REQUEST_STOP_LOADING });
export const getNewsSuccess = (newsData) => ({ type: GET_NEWS_SUCCESS, payload: newsData });

export const changeSelectedTableInManualTrade = ({ tableId }) => ({
  type: CHANGE_SELECTED_TABLE,
  payload: { tableId },
});

export const changeSelectedTableInInfoTable = ({ tableId }) => ({
  type: CHANGE_SELECTED_INFO_TABLE,
  payload: { tableId },
});

export const executionsTableStartLoading = ({ serviceId }) => ({
  type: EXECUTIONS_TABLE_START_LOADING,
  payload: { serviceId },
});
export const executionsTableStopLoading = ({ serviceId }) => ({
  type: EXECUTIONS_TABLE_STOP_LOADING,
  payload: { serviceId },
});
export const getExecutionsTableDataRequest = ({ isFirstData, isManual }) => ({
  type: GET_EXECUTIONS_TABLE_DATA_REQUEST,
  payload: { isFirstData, isManual },
});
export const getExecutionsTableDataSuccess = ({
  data,
  totalPages,
  pageNumber,
  isFirstData,
  serviceId,
  summarizedPl,
  summarizedSwap,
  summarizedDividend,
  summarizedComm,
  summarizedBuyQuantity,
  summarizedSellQuantity,
}) => ({
  type: GET_EXECUTIONS_TABLE_DATA_SUCCESS,
  payload: {
    data,
    totalPages,
    pageNumber,
    isFirstData,
    serviceId,
    summarizedPl,
    summarizedSwap,
    summarizedDividend,
    summarizedComm,
    summarizedBuyQuantity,
    summarizedSellQuantity,
  },
});

export const changeExecutionsTableMetaInfo = ({ key, value, serviceId }) => ({
  type: CHANGE_EXECUTIONS_TABLE_META_INFO,
  payload: { key, value, serviceId },
});
export const resetExecutionsTableMetaInfo = ({ serviceId }) => ({
  type: RESET_EXECUTIONS_TABLE_META_INFO,
  payload: { serviceId },
});
export const resetExecutionsTableMetaInfoDate = () => ({
  type: RESET_EXECUTIONS_TABLE_META_INFO_DATE,
});
export const resetExecutionsTableMetaInfoPartial = () => ({
  type: RESET_EXECUTIONS_TABLE_META_INFO_PARTIAL,
});

export const getPositionsStartLoading = ({ serviceId }) => ({
  type: GET_POSITIONS_START_LOADING,
  payload: { serviceId },
});
export const getPositionsStopLoading = ({ serviceId }) => ({
  type: GET_POSITIONS_STOP_LOADING,
  payload: { serviceId },
});

export const closeFifoSidePositionsStartLoading = ({ serviceId, side }) => ({
  type: CLOSE_FIFO_SIDE_POSITIONS_START_LOADING,
  payload: { serviceId, side },
});

export const closeFifoSidePositionsStopLoading = ({ serviceId, side }) => ({
  type: CLOSE_FIFO_SIDE_POSITIONS_STOP_LOADING,
  payload: { serviceId, side },
});

export const changePositionsTableMetaInfo = ({ key, value, serviceId }) => ({
  type: CHANGE_POSITIONS_TABLE_META_INFO,
  payload: { key, value, serviceId },
});
export const resetPositionTableMetaInfo = ({ serviceId }) => ({
  type: RESET_POSITION_TABLE_META_INFO,
  payload: { serviceId },
});

export const createCloseOrderStartLoading = () => ({ type: CREATE_CLOSE_ORDER_START_LOADING });
export const createCloseOrderStopLoading = () => ({ type: CREATE_CLOSE_ORDER_STOP_LOADING });
export const clearStatusAfterClosing = () => ({ type: CLEAR_STATUS_AFTER_CLOSING });
export const createCloseOrderRequest = ({ callback, data }) => ({
  type: CREATE_CLOSE_ORDER_REQUEST,
  payload: { callback, data },
});

export const deletePositionsOrderById = ({ orderId, side, instrumentId, apGroupId, callback }) => ({
  type: DELETE_POSITIONS_ORDER_REQUEST,
  payload: { orderId, side, instrumentId, apGroupId, callback },
});

export const ordersTableStartLoading = ({ serviceId }) => ({
  type: ORDERS_TABLE_START_LOADING,
  payload: { serviceId },
});
export const ordersTableStopLoading = ({ serviceId }) => ({ type: ORDERS_TABLE_STOP_LOADING, payload: { serviceId } });
export const getOrdersTableDataRequest = ({ isFirstData, isManual }) => ({
  type: GET_ORDERS_TABLE_DATA_REQUEST,
  payload: { isFirstData, isManual },
});
export const getOrdersTableDataSuccess = ({ data, totalPages, pageNumber, isFirstData, serviceId }) => ({
  type: GET_ORDERS_TABLE_DATA_SUCCESS,
  payload: { data, totalPages, pageNumber, isFirstData, serviceId },
});

export const changeOrdersTableMetaInfo = ({ key, value, serviceId }) => ({
  type: CHANGE_ORDERS_TABLE_META_INFO,
  payload: { key, value, serviceId },
});

export const changeCreateOrderValues = ({ orderType, inputName, value }) => ({
  type: CHANGE_CREATE_ORDER_VALUES,
  payload: { orderType, inputName, value },
});
export const resetCreateOrderValues = () => ({ type: RESET_CREATE_ORDER_VALUES });
export const resetCreateOrderNonCommonValues = () => ({ type: RESET_CREATE_ORDERS_NON_COMMON_VALUES });
export const resetCreateOrderErrors = () => ({ type: RESET_CREATE_ORDER_ERRORS });
export const resetChangeOrderErrors = () => ({ type: RESET_CHANGE_ORDER_VALIDATION_ERRORS });
export const changeCreateOrderValidationErrors = ({
  orderType,
  inputName,
  errorMessage,
  hasValidationError = true,
}) => ({
  type: CHANGE_CREATE_ORDER_VALIDATION_ERRORS,
  payload: { orderType, inputName, errorMessage, hasValidationError },
});

export const changeSelectedOrderType = ({ orderType }) => ({
  type: CHANGE_SELECTED_ORDER_TYPE,
  payload: { orderType },
});

export const changeTradeMethodType = ({ tradeMethodType }) => ({
  type: CHANGE_TRADE_METHOD_TYPE,
  payload: { tradeMethodType },
});

export const resetOrderTableMetaInfo = ({ serviceId }) => ({
  type: RESET_ORDER_TABLE_META_INFO,
  payload: { serviceId },
});

export const orderInfoRequestStartLoading = () => ({ type: ORDER_INFO_REQUEST_START_LOADING });
export const orderInfoRequestStopLoading = () => ({ type: ORDER_INFO_REQUEST_STOP_LOADING });
export const getOrderInfoRequest = ({ orderId, callback }) => ({
  type: GET_ORDER_INFO_REQUEST,
  payload: { orderId, callback },
});
export const getOrderInfoSuccess = ({ array, positionInfo }) => ({
  type: GET_ORDER_INFO_SUCCESS,
  payload: { array, positionInfo },
});

export const orderChangeStartLoading = () => ({ type: ORDER_CHANGE_START_LOADING });
export const orderChangeStopLoading = () => ({ type: ORDER_CHANGE_STOP_LOADING });
export const changeOrderRequest = ({ orderType, orderData, callback, isManual }) => ({
  type: CHANGE_ORDER_REQUEST,
  payload: { orderType, orderData, callback, isManual },
});

export const deleteOrderRequest = ({ orderId, callback }) => ({
  type: DELETE_ORDER_REQUEST,
  payload: { orderId, callback },
});

export const changeSharedMetaInfo = ({ key, value, serviceId }) => ({
  type: CHANGE_SHARED_META_INFO,
  payload: { key, value, serviceId },
});

export const createOrderStartLoading = () => ({ type: CREATE_ORDER_START_LOADING });
export const createOrderStopLoading = () => ({ type: CREATE_ORDER_STOP_LOADING });
export const createMarkerOrderRequest = ({ x, y } = {}) => ({ type: CREATE_MARKET_ORDER_REQUEST, payload: { x, y } });
export const createFIFOOrderRequest = () => ({ type: CREATE_FIFO_ORDER_REQUEST });
export const createStandardOrderRequest = () => ({ type: CREATE_STANDARD_ORDER_REQUEST });
export const createOcoOrderRequest = () => ({ type: CREATE_OCO_ORDER_REQUEST });
export const createIFDOrderRequest = () => ({ type: CREATE_IFD_ORDER_REQUEST });
export const createIFOOrderRequest = () => ({ type: CREATE_IFO_ORDER_REQUEST });

export const updateChangeOrderValidationErrors = ({ inputName, errorMessage, hasValidationError }) => ({
  type: UPDATE_CHANGE_ORDER_VALIDATION_ERRORS,
  payload: { inputName, errorMessage, hasValidationError },
});

export const updateCloseOrderValidationErrors = ({ inputName, errorMessage, hasValidationError }) => ({
  type: UPDATE_CLOSE_ORDER_VALIDATION_ERRORS,
  payload: { inputName, errorMessage, hasValidationError },
});

export const changePositionDetailsOrderLogic = (inputName, inputValue) => ({
  type: CHANGE_POSITION_DETAILS_ORDER_LOGIC,
  payload: { inputName, inputValue },
});

export const closeMultiplePositionsRequest = ({ positionIds, callback }) => ({
  type: CLOSE_MULTIPLE_POSITIONS_REQUEST,
  payload: { positionIds, callback },
});
export const closeFifoSidePositionsRequest = ({ positionIds, side, callback }) => ({
  type: CLOSE_FIFO_SIDE_POSITIONS_REQUEST,
  payload: { positionIds, side, callback },
});

export const saveDeletingPositions = ({ positions }) => ({
  type: SAVE_DELETING_POSITIONS,
  payload: { positions },
});

export const saveSelectedTableRows = ({ rows }) => ({
  type: SAVE_SELECTED_TABLE_ROWS,
  payload: { rows },
});

export const swapTransferRequest = ({ positionIds, callback }) => ({
  type: SWAP_TRANSFER_REQUEST,
  payload: { positionIds, callback },
});
