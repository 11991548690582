import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { CFD, ETF, FX } from 'shared-modules/constants';
import { KEY_FOR_DEPOSIT_SOURCE_ID, KEY_FOR_PAYMENT_AMOUNT, KEY_FOR_BANK_LIST } from 'shared-modules/constants/cash';
import { useAccountInfo } from 'shared-modules/hooks';
import { formatNumberZeroEn, removeKeyFromSessionStorage } from 'shared-modules/services';
import { getCashMenuMarginInfoRequest } from 'shared-modules/redux/cash';
import LogoTriautoFX from '../../assets/logoTriautoFX.png';
import LogoTriautoETF from '../../assets/logoTriautoETF.png';
import LogoTriautoCFD from '../../assets/logoTriautoCFD.png';
import { useCashMenu } from './hooks';
import styles from './cashMenu.module.scss';

const LOGOS = Object.freeze({
  [FX]: LogoTriautoFX,
  [ETF]: LogoTriautoETF,
  [CFD]: LogoTriautoCFD,
});

export const CashMenu = memo(() => {
  const dispatch = useDispatch();
  const cashMenuList = useCashMenu();
  const accountInfo = useAccountInfo();
  const serviceId = useSelector((state) => state.auth.serviceId);
  const marginData = useSelector((state) => state.portfolio.marginData);

  useEffect(() => {
    dispatch(getCashMenuMarginInfoRequest());
    removeKeyFromSessionStorage({ key: KEY_FOR_DEPOSIT_SOURCE_ID });
    removeKeyFromSessionStorage({ key: KEY_FOR_PAYMENT_AMOUNT });
    removeKeyFromSessionStorage({ key: KEY_FOR_BANK_LIST });
  }, [dispatch, serviceId]);

  return (
    <div>
      <div className={styles.infoRow}>
        {ALL_SERVICES.filter((service) => !accountInfo[service].notExist).map((service) => {
          const { isLossCut, isMaintenance } = accountInfo[service];
          const logo = LOGOS[service];
          const {
            effectiveMargin,
            summary: { withdrawalLimit },
          } = marginData[service];
          const disabled = isLossCut || isMaintenance;
          return (
            <div key={service} className={styles.infoWrapper}>
              <img className={styles.logo} src={logo} width="233" height="30" alt="アイコン" />
              <div className={styles.priceInfo}>
                <div className={styles.priceBlock}>
                  <div className={styles.priceLabel}>有効証拠金</div>
                  <div className={styles.price}>
                    {disabled ? '-' : formatNumberZeroEn({ value: effectiveMargin, withoutPlus: true })}
                  </div>
                </div>
                <div className={styles.priceBlock}>
                  <div className={styles.priceLabel}>出金可能額</div>
                  <div className={styles.price}>
                    {disabled ? '-' : formatNumberZeroEn({ value: withdrawalLimit, withoutPlus: true })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.mainMenuRow}>
        {cashMenuList.map((b) => (
          <div
            className={styles.buttonWrapper}
            onClick={() => b.callBack(serviceId)}
            role="button"
            aria-hidden
            key={b.key}
          >
            {b.icon}
            <div className={styles.buttonText}>
              <div className={styles.buttonTitle}>{b.title}</div>
              <div>
                <span className={styles.buttonDescription}>{b.description}</span>
                {Boolean(b?.remarks?.length) && <span className={styles.buttonRemarks}>{b.remarks}</span>}
              </div>
            </div>
            {b.subIcon}
          </div>
        ))}
      </div>
    </div>
  );
});
