import { call, put, select } from 'redux-saga/effects';
import { changeOrder } from '../../../api/manualTradeApi';
import {
  orderChangeStartLoading,
  orderChangeStopLoading,
  getOrdersTableDataRequest,
} from '../../actions/manualTradeActions';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { errorHandler } from '../errorSaga';
import { createDateString } from '../../../services';
import { ORDERS_COMPOSITE_TYPES_MAIN } from '../../../constants';
import { CHANGE_ORDER_FORM } from '../../../constants/manualTrade';

const ROUTE_VALUES = {
  [ORDERS_COMPOSITE_TYPES_MAIN.SINGLE.ID]: 'single',
  [ORDERS_COMPOSITE_TYPES_MAIN.IFD.ID]: 'ifd',
  [ORDERS_COMPOSITE_TYPES_MAIN.OCO1.ID]: 'oco',
  [ORDERS_COMPOSITE_TYPES_MAIN.IFO.ID]: 'ifo',
};

function* changeOrderRequestHandler(action) {
  try {
    yield put(orderChangeStartLoading());
    const {
      payload: { orderType, orderData, callback, isManual },
    } = action;

    const serviceId = yield select((state) => state.auth.serviceId);
    const previousValue = yield select((state) => state.manualTrade.selectedOrderInfo);

    const routePath = ROUTE_VALUES[orderType];
    let requestBody;

    const expireTime = createDateString(orderData.innerSelectedDate, orderData.innerSelectedTime);
    const thenExpireTime = createDateString(orderData.innerThenSelectedDate, orderData.innerThenSelectedTime);

    switch (Number(orderType)) {
      case ORDERS_COMPOSITE_TYPES_MAIN.SINGLE.ID: {
        requestBody = {
          orderId: previousValue[0].orderId,
          price: orderData.innerPrice,
          expireType: orderData.innerExpireType,
          expireTime,
        };
        break;
      }
      case ORDERS_COMPOSITE_TYPES_MAIN.IFD.ID: {
        requestBody = previousValue.reduce((acc, item) => {
          if (Number(item.compositeType) === ORDERS_COMPOSITE_TYPES_MAIN.IFD.ID) {
            acc.newOrder = {
              orderId: item.orderId,
              price: orderData.innerPrice,
              expireType: orderData.innerExpireType,
              expireTime,
            };
          } else {
            acc.closeOrder = {
              orderId: item.orderId,
              price: orderData.innerThenPrice,
              expireType: orderData.innerThenExpireType,
              expireTime: thenExpireTime,
            };
          }
          return acc;
        }, {});
        break;
      }
      case ORDERS_COMPOSITE_TYPES_MAIN.OCO1.ID: {
        requestBody = previousValue.reduce((acc, item) => {
          if (Number(item.compositeType) === ORDERS_COMPOSITE_TYPES_MAIN.OCO1.ID) {
            acc.order1 = {
              orderId: item.orderId,
              price: orderData.innerPrice,
              expireType: orderData.innerExpireType,
              expireTime,
            };
          } else {
            acc.order2 = {
              orderId: item.orderId,
              price: orderData.innerOco2Price,
              expireType: orderData.innerExpireType,
              expireTime,
            };
          }
          return acc;
        }, {});
        break;
      }
      case ORDERS_COMPOSITE_TYPES_MAIN.IFO.ID: {
        requestBody = previousValue.reduce((acc, item) => {
          if (Number(item.compositeType) === ORDERS_COMPOSITE_TYPES_MAIN.IFO.ID) {
            acc.newOrder = {
              orderId: item.orderId,
              price: orderData.innerPrice,
              expireType: orderData.innerExpireType,
              expireTime,
            };
          } else if (Number(item.compositeType) === ORDERS_COMPOSITE_TYPES_MAIN.THEN_OCO.ID) {
            acc.closeOrder1 = {
              orderId: item.orderId,
              price: orderData.innerThenPrice,
              expireType: orderData.innerThenExpireType,
              expireTime: thenExpireTime,
            };
          } else {
            acc.closeOrder2 = {
              orderId: item.orderId,
              price: orderData.innerThenOco2Price,
              expireType: orderData.innerThenExpireType,
              expireTime: thenExpireTime,
            };
          }
          return acc;
        }, {});
        break;
      }
      default: {
        requestBody = {};
      }
    }
    yield call(changeOrder, { routePath, requestBody, serviceId });
    callback();
    yield put(sendNotificationSuccess({ message: '注文が変更されました。' }));
    // ソケット通知が生きている場合、通知によって同じAPIが叩かれるが、高い確率でこちらが先に処理される
    yield put(getOrdersTableDataRequest({ isFirstData: true, isManual }));
  } catch (e) {
    yield call(errorHandler, { error: e, form: CHANGE_ORDER_FORM });
  } finally {
    yield put(orderChangeStopLoading());
  }
}

export default changeOrderRequestHandler;
