import { call, put, select } from 'redux-saga/effects';
import { getUnreadCount } from '../../../api/messageApi';
import { getUnreadCountSuccess } from '../../actions/messageActions';
import { errorHandler } from '../errorSaga';
import { MESSAGE_TYPE } from '../../../constants';

export function* getUnreadCountRequestHandler({ payload }) {
  try {
    const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
    if (!isAuthenticated) {
      // 未ログイン時はスキップ
      return;
    }
    const { channel } = payload;
    const { data } = yield call(getUnreadCount, { channel });
    const sum = data.reduce(
      (acc, { messageType, unreadCount }) => {
        if (messageType === MESSAGE_TYPE.EVENT) {
          acc.unreadCountEvent += unreadCount;
        } else {
          acc.unreadCountIndividual += unreadCount;
        }
        return acc;
      },
      { unreadCountEvent: 0, unreadCountIndividual: 0 },
    );

    yield put(getUnreadCountSuccess(sum));
  } catch (error) {
    // TODO 暗黙の更新で画面側にエラーを通知してしまって良いのかどうか
    yield call(errorHandler, { error });
  }
}
