import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MODAL_SIZES, COLORS, BUY_SELL_MAIN } from 'shared-modules/constants';
import { switchSelectedTableRows } from 'shared-modules/redux/multiEdit';
import { store } from '../../../../redux/store';
import Modal from '../../../../components/Modal';
import CustomButton from '../../../../components/CustomButton';
import styles from './multiEditCheckComfirm.module.scss';

const setParams = (key, rowNum) => {
  let description;
  let button1Text;
  let button1Value;
  let button2Text;
  let button2Value;

  if (key === 'side') {
    description = '売り注文と買い注文は同時に選択一括変更することができません。\nどちらかの注文を選択してください。';
    button1Value = String(BUY_SELL_MAIN.SELL.ID);
    button2Value = String(BUY_SELL_MAIN.BUY.ID);
    if (rowNum === 0) {
      button1Text = '売り注文全選択';
      button2Text = '買い注文全選択';
    } else {
      button1Text = '売り注文を選択';
      button2Text = '買い注文を選択';
    }
  } else {
    description =
      'マネーハッチ自動売買注文の初回注文・稼働停止注文と2回目以降発注中注文は同時に選択一括変更することができません。\nどちらかの注文を選択してください。';
    button1Value = true;
    button2Value = false;
    if (rowNum === 0) {
      button1Text = '初回注文・稼働停止注文を全選択';
      button2Text = '2回目以降発注中注文を全選択';
    } else {
      button1Text = '初回注文・稼働停止注文を選択';
      button2Text = '2回目以降発注中注文を選択';
    }
  }

  return { description, button1Text, button1Value, button2Text, button2Value };
};

const MultiEditCheckComfirm = ({ isOpen, closeModal, rowNum }) => {
  const dispatch = useDispatch();
  const key = store.getState().multiEdit.selectedTableRows[0].checkKey;
  const params = setParams(key, rowNum);

  const onClickButton = useCallback(
    (value) => {
      dispatch(switchSelectedTableRows({ rowNum, value }));
      closeModal();
    },
    [rowNum, closeModal, dispatch],
  );

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="選択注文確認" size={MODAL_SIZES.WIDTH_400_600} isOverlap>
      <div className={styles.text}>{params.description}</div>

      <div className={styles.buttonWrapper}>
        <CustomButton onClick={() => onClickButton(params.button1Value)} color={COLORS.LIGHT_GREY} width={270}>
          {params.button1Text}
        </CustomButton>
        <CustomButton onClick={() => onClickButton(params.button2Value)} color={COLORS.LIGHT_GREY} width={270}>
          {params.button2Text}
        </CustomButton>
      </div>
    </Modal>
  );
};

MultiEditCheckComfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  rowNum: PropTypes.number.isRequired,
};

export default memo(MultiEditCheckComfirm);
