/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useContext } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TERM_ID } from '../constants/select';
import { SelectedStrategyGroupContext } from '../contexts';
import {
  changeSelectedTermIdAutoTrade,
  clearSelectionModalInfo,
  getSelectionModalInfoRequest,
} from '../redux/actions/autoSelectActions';
import {
  changePublishedLabSelectedTermId,
  getPublishedLabDetailsRequest,
  resetPublishedLabDetails,
} from '../redux/labs';

export const useSelectedStrategyGroup = () => {
  const dispatch = useDispatch();
  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const [strategyGroup, setData] = useContext(SelectedStrategyGroupContext);
  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);
  const setStrategyGroup = useCallback(
    (newData) => {
      setData(newData);
      if (!tutorialMode) {
        dispatch(clearSelectionModalInfo());
      }
      if (newData != null) {
        const { selectionId, selectionVersion, labId } = newData;
        if (labId == null) {
          if (selectionId != null && selectionVersion != null) {
            dispatch(changeSelectedTermIdAutoTrade({ termId: defaultSelectionTermId }));
            dispatch(
              getSelectionModalInfoRequest({
                selectionId,
                selectionVersion,
                termId: defaultSelectionTermId,
              }),
            );
          }
        } else {
          dispatch(resetPublishedLabDetails());
          dispatch(changePublishedLabSelectedTermId({ termId: DEFAULT_TERM_ID }));
          dispatch(getPublishedLabDetailsRequest({ labId, termId: DEFAULT_TERM_ID, tradesFlg: true }));
        }
      }
    },
    [setData, tutorialMode, dispatch, defaultSelectionTermId],
  );
  return [strategyGroup, setStrategyGroup];
};
