import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import HighlightValue from '../HighlightValue';

const UnrealizedProfitLossCellValue = memo(({ instrumentId, positionId, value, isToFormat }) => {
  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);

  const fixedValue = useSelector(
    (state) => state.currencies.positionsUnrealizedProfitLoss?.[instrumentId]?.[positionId]?.unrealizedProfitLoss,
  );

  if (tutorialMode && value) {
    return <HighlightValue value={Math.floor(Math.abs(value))} isToFormat={isToFormat} />;
  }
  return <HighlightValue value={Math.floor(fixedValue)} isToFormat={isToFormat} />;
});

UnrealizedProfitLossCellValue.propTypes = {
  instrumentId: PropTypes.string,
  positionId: PropTypes.string,
  value: PropTypes.string,
  isToFormat: PropTypes.bool,
};

UnrealizedProfitLossCellValue.defaultProps = {
  instrumentId: '',
  positionId: '',
  value: '',
  isToFormat: false,
};

export default memo(UnrealizedProfitLossCellValue);
