import {
  SCREEN_ID_BUILDER,
  SCREEN_ID_CART,
  SCREEN_ID_CASH,
  SCREEN_ID_GUIDE,
  SCREEN_ID_INQUIRY,
  SCREEN_ID_MESSAGE,
  SCREEN_ID_PORTFOLIO,
  SCREEN_ID_SELECT,
  SCREEN_ID_TRADE,
} from 'shared-modules/constants/screen';

export const HOME = 'home';
export const LOGIN = 'login';
export const AUTO_SELECT = 'auto-select';
export const BUILDER = 'builder';
export const BUILDERS_SHARE = 'builders-share';
export const MANUAL_TRADE = 'manual-trade';
export const TRADE_INFO = 'trade-info';
export const MESSAGE = 'message';
export const CART = 'cart';
export const CASH = 'cash';
export const CASH_DEPOSIT_SUCCESS = 'cash-deposit-success';
export const CASH_DEPOSIT_FAILED = 'cash-deposit-failed';
export const MEDIA = 'media';
export const MANUAL_TRADE_CHART = 'manual-trade-chart';
export const OTHER_WINDOW_AUTO_SELECT_DETAIL_CHART = 'other-window-auto-select-detail-chart';
export const CURRENCY_PAIRS = 'currency-pairs';
export const SSO_AUTH_URL = 'sso';
export const IMS_AUTH_URL = 'ims';
export const MOBILE_CHART = 'mobile-chart';
export const MOBILE_DRAW_CHART = 'mobile/draw-chart';
export const BUILDER_CHART = 'builder-chart';
export const MOBILE_TECH_BUILDER_CHART = 'mobile/tech-builder-chart';
export const MOBILE_AUTO_SELECT_PRICE_CHART = 'mobile/auto-select-price-chart';
export const MOBILE_MARKET_ORDER_CHART = 'mobile/market-order-chart';
export const MOBILE_LAB_PRICE_CHART = 'mobile/lab-price-chart';
export { SSO_MY_PAGE_URL, SSO_PAYMENT_URL, SSO_QUICK_DEPOSIT_URL } from 'shared-modules/constants';

export const MANUAL_TRADE_CURRENCY_PAIRS_POPUP_PARAMS =
  'status=0,location=0,toolbar=0,menubar=0,width=760,height=360,left=400,top=150';

export const POPUP_MESSAGES = {
  GET_DISPLAYED_CURRENCY_LIST_REQUEST: 'GET_DISPLAYED_CURRENCY_LIST_REQUEST',
  GET_DISPLAYED_CURRENCY_LIST_SUCCESS: 'GET_DISPLAYED_CURRENCY_LIST_SUCCESS',
  CHANGE_DISPLAYED_CURRENCY: 'CHANGE_DISPLAYED_CURRENCY',
  GET_CHART_TYPE_REQUEST: 'GET_CHART_TYPE_REQUEST',
  GET_CHART_TYPE_SUCCESS: 'GET_CHART_TYPE_SUCCESS',
  CLOSE_POPUP: 'CLOSE_POPUP',
};

export const BROWSERS = {
  INTERNET_EXPLORER: {
    NAME: 'Internet Explorer',
  },
  CHROME: {
    NAME: 'Chrome',
  },
  EDGE: {
    NAME: 'Microsoft Edge',
  },
  FIREFOX: {
    NAME: 'Firefox',
  },
  OPERA: {
    NAME: 'Opera',
  },
  SAFARI: {
    NAME: 'Safari',
  },
};

export const UNSUPPORTED_BROWSERS = [BROWSERS.INTERNET_EXPLORER.NAME];

export const BUILDER_TYPE_TAB_CREATE = 'CREATE';
export const BUILDER_TYPE_TAB_RELEASE = 'RELEASE';

export const AUTO_SELECT_TAB_LOGIC_SETTING = 'LOGIC_SETTING';
export const AUTO_SELECT_TAB_SIMULATION = 'SIMULATION';

export const WEB_GUIDE_ALL_SEE_BUTTON_DISPLAY_ARTICLE_NUM = 6;
export const WEB_GUIDE_ARTICLE_LIST_PAGE_SIZE = 15;
export const WEB_GUIDE_CATEGORY_LIST_ARTICLE_DISPLAY_NUM = 12;

export const ROUTE_TO_SCREEN_ID = Object.freeze({
  [HOME.toLowerCase()]: SCREEN_ID_PORTFOLIO,
  [BUILDER.toLowerCase()]: SCREEN_ID_BUILDER,
  [AUTO_SELECT.toLowerCase()]: SCREEN_ID_SELECT,
  [MANUAL_TRADE.toLowerCase()]: SCREEN_ID_TRADE,
  [TRADE_INFO.toLowerCase()]: SCREEN_ID_INQUIRY,
  [CART.toLowerCase()]: SCREEN_ID_CART,
  [CASH.toLowerCase()]: SCREEN_ID_CASH,
  [CASH_DEPOSIT_SUCCESS.toLowerCase()]: SCREEN_ID_CASH,
  [CASH_DEPOSIT_FAILED.toLowerCase()]: SCREEN_ID_CASH,
  [MEDIA.toLowerCase()]: SCREEN_ID_GUIDE,
  [MESSAGE.toLowerCase()]: SCREEN_ID_MESSAGE,
});

export const DRAGGABLE_CANCEL_SELECTOR_NAME = 'cancelDrag';
