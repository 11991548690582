export const GET_RATES_START_LOADING = 'CURRENCIES/GET_RATES_START_LOADING';
export const GET_RATES_STOP_LOADING = 'CURRENCIES/GET_RATES_STOP_LOADING';
export const GET_RATES_REQUEST = 'CURRENCIES/GET_RATES_REQUEST';
export const GET_RATES_SUCCESS = 'CURRENCIES/GET_RATES_SUCCESS';
export const GET_EOD_RATES_SUCCESS = 'CURRENCIES/GET_EOD_RATES_SUCCESS';

export const UPDATE_RATES_VALUES = 'CURRENCIES/UPDATE_RATES_VALUES';

export const GET_POSITIONS_REQUEST = 'CURRENCIES/GET_POSITIONS_REQUEST';
export const GET_POSITIONS_SUCCESS = 'CURRENCIES/GET_POSITIONS_SUCCESS';

export const GET_POSITION_BY_ID_REQUEST = 'CURRENCIES/GET_POSITION_BY_ID_REQUEST';
export const GET_POSITION_BY_ID_SUCCESS = 'CURRENCIES/GET_POSITION_BY_ID_SUCCESS';

export const UPDATE_UNREALIZED_PROFIT_LOSS_VALUES = 'CURRENCIES/UPDATE_UNREALIZED_PROFIT_LOSS_VALUES';

export const CALC_INSTRUMENT_MARGIN_PERIODICALLY = 'CURRENCIES/CALC_INSTRUMENT_MARGIN_PERIODICALLY';
export const CALC_PUBLIC_INSTRUMENT_MARGIN_PERIODICALLY = 'CURRENCIES/CALC_PUBLIC_INSTRUMENT_MARGIN_PERIODICALLY';

export const REPLACE_MARGIN_RATES = 'CURRENCIES/REPLACE_MARGIN_RATES';
