import moment from 'moment';

import { put, call, takeEvery, select } from 'redux-saga/effects';
import { getOrdersRequest, getOrdersSuccess, removeAllOrders, stopLoading } from '../orders';
import { errorHandler } from './errorSaga';
import { getOrders, getParamList } from '../../api/orderApi';

const lastFecthWithin = (lastFetch, seconds) => {
  if (!lastFetch) return false;

  const diffInSeconds = moment().diff(moment(lastFetch), 'seconds');

  return diffInSeconds < seconds;
};

function* getOrdersRequestHandler(action) {
  const { serviceId } = action.payload;
  const lastQuery = yield select((state) => state.orders.lastQuery);
  const lastFetch = yield select((state) => state.orders.lastFetch);
  const params = {
    ...action.payload.params,
    status: 1, // order status: valid
    pageSize: 3000,
    pageNumber: 1,
  };
  const currentQuery = `${serviceId}/orders?${getParamList(params).join('&')}`;

  if (currentQuery === lastQuery && lastFecthWithin(lastFetch, 300)) {
    yield put(stopLoading());
    return;
  }

  yield put(removeAllOrders());

  try {
    const {
      data: {
        list: data,
        pageInfo: { totalPages, pageNumber },
      },
    } = yield call(getOrders, serviceId, params);

    yield put(
      getOrdersSuccess({
        data,
        totalPages,
        pageNumber,
        serviceId,
        lastQuery: currentQuery,
        filter: {
          ...action.payload.params,
        },
      }),
    );
  } catch (e) {
    yield call(errorHandler, { error: e });

    yield put(getOrdersSuccess({ data: [], totalPages: 1, pageNumber: 1, serviceId, lastQuery }));
  }
}

export default function* orderSaga() {
  yield takeEvery(getOrdersRequest.type, getOrdersRequestHandler);
}
