import { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TRADE_TABLES } from 'shared-modules/constants';
import { Button, LinkButton } from '../../../../../../../../components';
import styles from '../../../../../PortfolioAutoTradeDetail/components/DetailTable/components/TableHeader/TableWrapper/tableWrapper.module.scss';

export const TutorialTableWrapper = memo(({ children }) => {
  const activeTab = useSelector((state) => state.webPortfolio.selectedTable);

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <LinkButton className={styles.linkButton} onClick={() => {}}>
          フィルターをすべてクリア
        </LinkButton>
        {activeTab === TRADE_TABLES.ORDERS.ID && (
          <Button onClick={() => {}} loading={false} className={styles.button} secondary>
            一括変更
          </Button>
        )}
      </div>
      <div style={{ width: '100%', height: '100%' }}>{children}</div>
    </div>
  );
});

TutorialTableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
