import Decimal from 'decimal.js';
import {
  ETF,
  CHART_MAKE_CHANGE_TARGET_OPTIONS,
  CORE_RANGER,
  CHART_MAKE_BUY_SELL_MAIN,
} from '../../../../constants/index';
import { ceil, getBaseQuantity, getSignificantDigits } from '../../constants';
import Logic from './Logic';
import { Range, RANGE_POSITION } from './Range';

const CoreRange = (instrumentId, instrumentSetting, modePrice, high, low, quantity) => {
  const MIN_TP_WIDTH = 0.2;
  const MIN_RANGEWIDTH = 1;
  const range = Range(
    instrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.STRADDLE,
    0,
    0,
    quantity,
    RANGE_POSITION.MIDDLE,
  );
  let isMinFixed = false;
  let isMaxFixed = true;
  const digits = getSignificantDigits(ETF, instrumentId, instrumentSetting?.pricePrecision);

  // ±30%のコアレンジで最大最小を設定
  range.setMax(ceil(Decimal.add(modePrice, Decimal.mul(Decimal.sub(high, low), 0.3)), digits));
  range.setMin(ceil(Decimal.sub(modePrice, Decimal.mul(Decimal.sub(high, low), 0.3)), digits));

  // 設定したレンジの最大がhighの範囲を超える場合はhighを超えないように最大を設定し、最小は「最大-high-lowの60%」にする。
  // 最小についても同様にlowを超えないように制御
  if (range.getMax() > ceil(high, digits)) {
    range.setMax(ceil(high, digits));
    range.setMin(ceil(Decimal.sub(range.getMax(), Decimal.mul(Decimal.sub(high, low), 0.6)), digits));
    isMaxFixed = true;
    isMinFixed = false;
  } else if (range.getMin() < ceil(low, digits)) {
    range.setMin(ceil(low, digits));
    range.setMax(ceil(Decimal.add(range.getMin(), Decimal.mul(Decimal.sub(high, low), 0.6)), digits));
    isMinFixed = true;
    isMaxFixed = false;
  }
  range.expandUpToMinRangeWidth();

  return {
    isMinFixed() {
      return isMinFixed;
    },
    isMaxFixed() {
      return isMaxFixed;
    },
    calcAndSetWidth(hendouPips, step) {
      // 幅は近似1日変動値幅から計算した値と規定値のMAXを採用
      range.setWidth(Math.max(ceil(Decimal.div(hendouPips, Decimal.sub(2, step)), 1), MIN_TP_WIDTH));
    },
    ...range,
    // overrides
    ajustMin() {
      this.setMin(
        ceil(
          Decimal.sub(this.getMax(), Decimal.mul(Decimal.sub(this.getNumOfOrderPoints(), 1), this.getWidth())),
          digits,
        ),
      );
    },
    ajustMax() {
      this.setMax(
        ceil(
          Decimal.add(this.getMin(), Decimal.mul(Decimal.sub(this.getNumOfOrderPoints(), 1), this.getWidth())),
          digits,
        ),
      );
    },
    setNumOfOrdersAjustWidth(inputNumOfOrders) {
      const validateResult = this.validateRangeWidth(this.getRangeWidth(), inputNumOfOrders);
      if (!validateResult.isValid) {
        return validateResult;
      }
      this.setNumOfOrders(inputNumOfOrders);
      this.setWidth(
        this.getNumOfOrderPoints() === 1
          ? this.getRangeWidth()
          : ceil(Decimal.div(this.getRangeWidth(), Decimal.sub(this.getNumOfOrderPoints(), 1)), 1),
      );
      return { isValid: true };
    },
    calcAndSetNumOfOrders() {
      this.setNumOfOrders(
        ceil(Decimal.add(Decimal.div(Decimal.sub(this.getMax(), this.getMin()), this.getWidth()), 1), 0),
      );
    },
    calcAndSetTp() {
      range.setTp([range.getWidth()]);
    },
    recalcWithStartPrice(startPrice, rangeWidth, inputNumOfOrders) {
      const newNumOfOrders = inputNumOfOrders ? Decimal.div(inputNumOfOrders, 2).toNumber() : null;
      const newRangeWidth = rangeWidth || this.getRangeWidth();
      const halfRangeWidth = Decimal.div(newRangeWidth, 2);
      const basePrice = startPrice ? ceil(Decimal.add(startPrice, halfRangeWidth), digits) : this.getMax();
      this.setMin(ceil(Decimal.sub(basePrice, newRangeWidth), digits));
      this.setMaxForDisp(ceil(basePrice, digits));
      this.setMinForDisp(this.getMin());
      return this.recalc(null, basePrice, newNumOfOrders, rangeWidth);
    },
    createAps() {
      const result = [];
      for (let i = 0; i < range.getNumOfOrders(); i += 1) {
        result.push(this.toAp(i, CHART_MAKE_BUY_SELL_MAIN.SELL));
        result.push(this.toAp(i, CHART_MAKE_BUY_SELL_MAIN.BUY));
      }
      return result;
    },
    validateRangeWidth(rangeWidth, numOfOrders) {
      if (
        (numOfOrders === 1 && ceil(rangeWidth, 1) < this.getMinWidth()) ||
        ceil(Decimal.div(rangeWidth, Decimal.sub(numOfOrders, 1)), 1) < this.getMinWidth()
      ) {
        return {
          isValid: false,
          errorMessage: 'この設定では注文幅が狭くなり過ぎます。レンジ幅を広くするか本数を少なくして下さい。',
        };
      }
      return { isValid: true };
    },
    getNumOfOrders() {
      return ceil(Decimal.mul(range.getNumOfOrders(), 2), 0);
    },
    getNumOfOrderPoints() {
      return range.getNumOfOrders();
    },
    getMinRangeWidth(inputNumOfOrders) {
      return Math.max(
        ceil(Decimal.mul(this.getMinWidth(), Decimal.sub(Decimal.div(inputNumOfOrders, 2), 1)), digits),
        MIN_RANGEWIDTH,
      );
    },
    validateNumOfOrders(inputNumOfOrders) {
      if (inputNumOfOrders % 2 !== 0) {
        return {
          isValid: false,
          errorMessage: 'コアレンジの本数は偶数を指定してください。売注文と買注文が半分ずつ同じ本数設定されます。',
        };
      }
      return { isValid: true };
    },
  };
};

const SubRanges = (inputInstrumentId, instrumentSetting, inputQuantity) => {
  const instrumentId = inputInstrumentId;
  const digits = getSignificantDigits(ETF, instrumentId, instrumentSetting?.pricePrecision);
  const buyRange = Range(
    inputInstrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.BUY,
    0,
    0,
    inputQuantity,
    RANGE_POSITION.BOTTOM,
  );
  const sellRange = Range(
    inputInstrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.SELL,
    0,
    0,
    inputQuantity,
    RANGE_POSITION.TOP,
  );
  return {
    calcAndSetMaxMin(coreRange, high, low) {
      // サブレンジ(売)最小はコアレンジの最大からサブレンジ幅を空けた値に設定
      sellRange.setMin(coreRange.getMax());

      //  サブレンジ(売)最大は「サブレンジ(売)最小 + high-lowの20%」か「high」のうち、よりサブレンジが広くなる設定値を使用
      const minRange = Decimal.mul(Decimal.sub(high, low), 0.2);
      if (ceil(Decimal.sub(high, sellRange.getMin()), digits) > ceil(minRange, digits)) {
        sellRange.setMax(ceil(high, digits));
      } else {
        sellRange.setMax(ceil(Decimal.add(sellRange.getMin(), ceil(minRange, digits)), digits));
      }

      // サブレンジ(買)最大はコアレンジの最小注文価を設定
      buyRange.setMax(
        ceil(
          Decimal.sub(
            coreRange.getMax(),
            Decimal.mul(Decimal.sub(coreRange.getNumOfOrderPoints(), 1), coreRange.getWidth()),
          ),
          digits,
        ),
      );

      //  サブレンジ(買)最小は「サブレンジ(売)最大 - high-lowの20%」か「low」の範囲が広いほうを設定
      if (ceil(Decimal.sub(buyRange.getMax(), low), digits) > ceil(minRange, digits)) {
        buyRange.setMin(ceil(low, digits));
      } else {
        buyRange.setMin(ceil(Decimal.sub(buyRange.getMax(), ceil(minRange, digits)), digits));
      }
    },
    calcAndSetNumOfOrders() {
      buyRange.calcAndSetNumOfOrders();
      sellRange.calcAndSetNumOfOrders();
    },
    calcAndSetWidth(coreRangeWidth) {
      // サブレンジの幅はコアレンジの4倍
      const width = ceil(Decimal.mul(coreRangeWidth, 4), 1);
      buyRange.setWidth(width);
      sellRange.setWidth(width);
    },
    calcAndSetTp() {
      buyRange.setTp([buyRange.getWidth()]);
      sellRange.setTp([sellRange.getWidth()]);
    },
    ajustMaxMin() {
      buyRange.ajustMin();
      sellRange.ajustMax();
    },
    getBuyNumOfOrders() {
      return buyRange.getNumOfOrders();
    },
    getSellNumOfOrders() {
      return sellRange.getNumOfOrders();
    },
    getNumOfOrders() {
      return this.getBuyNumOfOrders() + this.getSellNumOfOrders();
    },
    getSellRange() {
      return sellRange;
    },
    getBuyRange() {
      return buyRange;
    },
    createSellRangeAps() {
      const result = sellRange.createAps();
      return result;
    },
    createBuyRangeAps() {
      const result = buyRange.createAps();
      return result;
    },
    backupDefaultSettings() {
      sellRange.backupDefaultSettings();
      buyRange.backupDefaultSettings();
    },
    backupCurrentSettings() {
      sellRange.backupCurrentSettings();
      buyRange.backupCurrentSettings();
    },
    rollbackSettings() {
      sellRange.rollbackSettings();
      buyRange.rollbackSettings();
    },
  };
};

const EtfCoreRanger = (instrumentId, modePrice, inputHigh, inputLow, instrumentSetting) => {
  const digits = getSignificantDigits(ETF, instrumentId, instrumentSetting?.pricePrecision);
  const high = ceil(inputHigh, digits);
  const low = ceil(inputLow, digits);
  const logic = Logic(CORE_RANGER, high, low);
  const coreRange = CoreRange(
    instrumentId,
    instrumentSetting,
    ceil(modePrice, digits),
    high,
    low,
    Decimal.mul(getBaseQuantity(ETF, instrumentId), 2).toNumber(),
  );
  const subRanges = SubRanges(instrumentId, instrumentSetting, getBaseQuantity(ETF, instrumentId));
  return {
    getNumOfOrders() {
      return coreRange.getNumOfOrders() + subRanges.getNumOfOrders();
    },
    createUnsortedAps() {
      const coreRangeAps = this.createCoreRangeAps();
      const sellSubRangeAps = this.createSellSubRangeAps();
      const buySubRangeAps = this.createBuySubRangeAps();
      return sellSubRangeAps.concat(coreRangeAps).concat(buySubRangeAps);
    },
    createCoreRangeAps() {
      const coreRangeAps = coreRange.createAps();
      return coreRangeAps;
    },
    createSellSubRangeAps() {
      const result = subRanges.createSellRangeAps();
      return result;
    },
    createBuySubRangeAps() {
      const result = subRanges.createBuyRangeAps();
      return result;
    },
    getAllRanges() {
      return [subRanges.getSellRange(), coreRange, subRanges.getBuyRange()];
    },
    getRecalcResource(settings) {
      let targetRange; // 注文変更ターゲットレンジ
      const upperRanges = []; // ターゲットレンジより上のレンジ
      const lowerRanges = []; // ターゲットレンジより下のレンジ
      let newNumOfOrders; // 合計本数バリデーション用 ターゲットレンジの注文本数
      let otherNumOfOrders; // 合計本数バリデーション用 ターゲットレンジ以外の注文本数
      // ターゲットのレンジ別の処理
      switch (settings.target) {
        case CHART_MAKE_CHANGE_TARGET_OPTIONS[ETF][CORE_RANGER][0].id:
          otherNumOfOrders = coreRange.getNumOfOrders() + subRanges.getBuyRange().getNumOfOrders();
          newNumOfOrders = settings.recalc.numOfOrders;
          targetRange = subRanges.getSellRange();
          lowerRanges.push(coreRange);
          lowerRanges.push(subRanges.getBuyRange());
          break;
        case CHART_MAKE_CHANGE_TARGET_OPTIONS[ETF][CORE_RANGER][1].id:
          targetRange = coreRange;
          if (settings.recalc.numOfOrders) {
            newNumOfOrders = settings.recalc.numOfOrders;
          }
          otherNumOfOrders = subRanges.getNumOfOrders();

          upperRanges.push(subRanges.getSellRange());
          lowerRanges.push(subRanges.getBuyRange());
          break;
        case CHART_MAKE_CHANGE_TARGET_OPTIONS[ETF][CORE_RANGER][2].id:
          otherNumOfOrders = coreRange.getNumOfOrders() + subRanges.getSellRange().getNumOfOrders();
          newNumOfOrders = settings.recalc.numOfOrders;
          targetRange = subRanges.getBuyRange();
          upperRanges.push(coreRange);
          upperRanges.push(subRanges.getSellRange());
          break;
        default:
          return null;
      }
      return {
        isValid: true,
        targetRange,
        upperRanges,
        lowerRanges,
        newNumOfOrders,
        otherNumOfOrders,
      };
    },
    backupCurrentSettings() {
      coreRange.backupCurrentSettings();
      subRanges.backupCurrentSettings();
    },
    rollbackSettings() {
      coreRange.rollbackSettings();
      subRanges.rollbackSettings();
    },
    backupDefaultSettings() {
      coreRange.backupDefaultSettings();
      subRanges.backupDefaultSettings();
    },
    calcAndSetRangeSettings(step, hendou) {
      this.calcAndSetCoreRangeMaxMin(hendou, step);
      this.calcAndSetSubRangeMaxMin();
    },
    calcAndSetCoreRangeMaxMin(hendou, step) {
      coreRange.calcAndSetWidth(hendou, step);
      coreRange.calcAndSetNumOfOrders();
    },
    calcAndSetSubRangeMaxMin() {
      // コアレンジの幅からサブレンジの幅を計算
      subRanges.calcAndSetWidth(coreRange.getWidth());
      // 各サブレンジの最大最小をコアレンジ, High, Lowから計算
      subRanges.calcAndSetMaxMin(coreRange, this.getHigh(), this.getLow());
      // 求めた幅、最大最小から本数を計算
      subRanges.calcAndSetNumOfOrders();
      subRanges.ajustMaxMin();
    },
    calcAndSetTp() {
      coreRange.calcAndSetTp();
      subRanges.calcAndSetTp();
    },
    ...logic,
    getStep() {
      return 0.1;
    },
    calcAfterFixedWidthProcess() {
      if (coreRange.isMinFixed()) {
        coreRange.ajustMax();
      } else {
        coreRange.ajustMin();
      }
      this.calcAndSetSubRangeMaxMin();
    },
  };
};

export default EtfCoreRanger;
