/* eslint-disable-next-line import/no-unresolved */
import { useCallback } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector } from 'react-redux';
import { FX } from '../constants';
import { removeSuffix } from './symbol';

export const useGetInstrumentDisplayName = () => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  return useCallback(
    (instrumentId, serviceId) =>
      serviceId === FX ? removeSuffix(instrumentId) : instrumentList?.[instrumentId]?.shortName ?? '-',
    [instrumentList],
  );
};
