import React, { memo, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ORDER_SETTINGS_TABLE_TAB, LOGIC_GROUP_TABLE_TAB } from 'shared-modules/constants/builder';
import { Tabs } from '../../../../../components';
import { changeBuilderActiveTable } from '../../../../../redux/actions';
import { LogicGroupTable } from './LogicGroupTable';
import { OrderSettingsTable } from './OrderSettingsTable';
import styles from './chartSettings.module.scss';

export const ChartSettings = memo(({ tableType }) => {
  const dispatch = useDispatch();
  const activeTable = useSelector((state) => state.webBuilder.activeTable);

  const setActiveTable = useCallback(
    (tableId) => {
      dispatch(changeBuilderActiveTable({ tableId }));
    },
    [dispatch],
  );

  useEffect(() => setActiveTable(ORDER_SETTINGS_TABLE_TAB.id), [setActiveTable]);

  const items = useMemo(
    () => [
      {
        id: ORDER_SETTINGS_TABLE_TAB.id,
        label: ORDER_SETTINGS_TABLE_TAB.value,
        children: <OrderSettingsTable tableType={tableType} />,
      },
      {
        id: LOGIC_GROUP_TABLE_TAB.id,
        label: LOGIC_GROUP_TABLE_TAB.value,
        children: <LogicGroupTable tableType={tableType} />,
      },
    ],
    [tableType],
  );

  return (
    <div className={styles.chartSettings}>
      <Tabs className={styles.tabContent} items={items} activeKey={activeTable} onChange={setActiveTable} />
    </div>
  );
});

ChartSettings.propTypes = {
  tableType: PropTypes.string,
};

ChartSettings.defaultProps = {
  tableType: 'builder',
};
