import { takeEvery } from 'redux-saga/effects';
import { SEND_NOTIFICATION_SUCCESS } from 'shared-modules/redux/actionConstants/notificationConstants';
import { openNotification } from '../../services';

function createNotificationSuccess(action) {
  const {
    payload: { message },
  } = action;
  openNotification({ message });
}

export default function* manualTradeSagaHandler() {
  yield takeEvery(SEND_NOTIFICATION_SUCCESS, createNotificationSuccess);
}
