const Ap = (
  inputSide,
  inputEntryPrice1,
  inputEntryPrice2,
  inputQuantity,
  inputSl,
  inputTp,
  inputFollow,
  inputCounter,
  inputCounterPrice,
) => {
  const side = inputSide;
  const entryPrice1 = inputEntryPrice1;
  const entryPrice2 = inputEntryPrice2;
  const quantity = inputQuantity;
  const sl = inputSl;
  const tp = inputTp;
  const follow = inputFollow;
  const counter = inputCounter;
  const counterPrice = inputCounterPrice;
  return {
    side,
    entryPrice1,
    entryPrice2,
    quantity,
    sl,
    tp,
    follow,
    counter,
    counterPrice,
  };
};

export default Ap;
