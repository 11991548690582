import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import Decimal from 'decimal.js';
import PropTypes from 'prop-types';
import { WIDTH } from 'shared-modules/constants';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { numberExists } from 'shared-modules/utils';
import { setNumberInputValue } from 'shared-modules/services';
import ReactTooltip from 'react-tooltip';
import styles from './customInput.module.scss';

const DEFAULT_TEXT_INPUT_WIDTH = WIDTH.PERCENTAGE_100;
const DEFAULT_NUMBER_INPUT_WIDTH = 123;
const ONLY_INTEGER_REG = /^\d$/;

const resolveStep = (step, inputValue, isIncrement = true) =>
  typeof step === 'function' ? step(inputValue, isIncrement) : step;

const CustomInput = ({
  className: outerClassName,
  label,
  placeholder,
  value: inputValue,
  width,
  onChange,
  type,
  isDisabled,
  name,
  errorMessages,
  validateFunction,
  min,
  max,
  step,
  isLighter,
  isSearch,
  withPips,
  pipsLabel,
  isEndAligned,
  withErrorTooltip,
  onBlurSendRequest,
  onlyIntegerAllowed,
  dataCustomSelector,
  validateNegativeValues,
  errorClassName: outerErrorClassName,
  inputClassName: outerInputClassName,
  controlBlockClassName: outerControlBlockClassName,
  controlBlockDecrementClassName: outerControlBlockDecrementClassName,
  iconAllowClassName: outerIconArrowClassName,
  iconAllowIncrementClassName: outerIconArrowIncrementClassName,
  iconAllowDecrementClassName: outerIconArrowDecrementClassName,
  pipsClassName: outerPipsClassName,
  errorTooltipClassName: outerErrorTooltipClassName,
}) => {
  const [error, changeError] = useState(null);
  const id = useRef(uuid()).current;
  const localValueRef = useRef(inputValue);
  const intervalIdRef = useRef(null);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    changeError(errorMessages.find((errorItem) => errorItem.inputName === name));
  }, [errorMessages, changeError, name]);

  const isNumberInput = type === 'number';
  const defaultWidth = isNumberInput ? DEFAULT_NUMBER_INPUT_WIDTH : DEFAULT_TEXT_INPUT_WIDTH;
  const elementWidth = width || defaultWidth;
  const inputType = isNumberInput ? 'text' : type;

  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;

      if (isNumberInput) {
        setNumberInputValue({
          value,
          min,
          max,
          validate: validateFunction,
          allowDecimal: !onlyIntegerAllowed,
          allowNegative: validateNegativeValues,
          setValue: onChange,
        });
        return;
      }

      onChange(value);
    },
    [onChange, validateFunction, max, min, validateNegativeValues, isNumberInput, onlyIntegerAllowed],
  );

  const incrementValue = useCallback(() => {
    const localValue = localValueRef.current;

    const isNumberExist = numberExists(localValue);
    const prevValue = isNumberExist ? localValue : 0;
    const resolvedStep = resolveStep(step, localValue, true);

    const newValue = Decimal.add(Number(prevValue), resolvedStep).toNumber();

    const result = numberExists(max) && newValue > max ? max : newValue;

    const validateResult = validateFunction(result);
    localValueRef.current = result;
    onChange(result);
    if (validateResult) {
      onBlurSendRequest(result);
    }
  }, [onChange, validateFunction, max, step, onBlurSendRequest]);

  const decrementValue = useCallback(() => {
    const localValue = localValueRef.current;

    const isNumberExist = numberExists(localValue);
    const prevValue = isNumberExist ? localValue : 0;
    const resolvedStep = resolveStep(step, localValue, false);

    const newValue = Decimal.sub(Number(prevValue), resolvedStep).toNumber();

    const result = numberExists(min) && newValue < min ? min : newValue;

    const validateResult = validateFunction(result);
    localValueRef.current = result;
    onChange(result);
    if (validateResult) {
      onBlurSendRequest(result);
    }
  }, [onChange, validateFunction, min, step, onBlurSendRequest]);

  const mouseDownHandler = useCallback(
    ({ isIncrement = true } = {}) => {
      localValueRef.current = inputValue;

      // wait 300 milliseconds before entering a long-press counting loop
      timeoutIdRef.current = setTimeout(() => {
        // start counting
        intervalIdRef.current = setInterval(() => {
          if (isIncrement) incrementValue();
          else decrementValue();
        }, 200);
      }, 300);
    },
    [decrementValue, incrementValue, inputValue],
  );

  const stopCount = () => {
    // clear the initial timer to prevent counting triggered by short time press
    clearTimeout(timeoutIdRef.current);

    // clear the loop timer to stop counting
    clearInterval(intervalIdRef.current);
  };

  const keyPressHandler = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        const validateResult = validateFunction(inputValue);

        if (validateResult) {
          onBlurSendRequest(inputValue);
        }
      }
      if (isNumberInput) {
        localValueRef.current = inputValue;

        if (e.key === 'ArrowUp') {
          e.preventDefault();
          incrementValue();
        }
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          decrementValue();
        }
      }
      // Necessary for manual input, otherwise caret will move to the end after change fails
      if (onlyIntegerAllowed && e.key.length === 1 && !ONLY_INTEGER_REG.test(e.key)) {
        e.preventDefault();
      }
    },
    [
      isNumberInput,
      incrementValue,
      decrementValue,
      validateFunction,
      onBlurSendRequest,
      inputValue,
      onlyIntegerAllowed,
    ],
  );

  const handleSendRequest = useCallback(
    ({ target: { value } }) => {
      const validateResult = validateFunction(value);

      if (validateResult) {
        onBlurSendRequest(value);
      }
    },
    [onBlurSendRequest, validateFunction],
  );

  return (
    <div className={classNames(styles.outerWrapper, { [styles.withPips]: withPips }, outerClassName)}>
      <div className={styles.wrapper} style={{ width: elementWidth }}>
        {label && <div className={classNames(styles.label, { [styles.isDisabled]: isDisabled })}>{label}</div>}
        <input
          title=""
          type={inputType}
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
          className={classNames(
            styles.input,
            {
              [styles.isLighter]: isLighter,
              [styles.isNumberInput]: isNumberInput,
              [styles.isSearchInput]: isSearch,
              [styles.isDisabled]: isDisabled,
              [styles.isError]: error,
              [styles.withPips]: withPips,
              [styles.isEndAligned]: isEndAligned,
            },
            outerInputClassName,
          )}
          autoComplete="new-password"
          disabled={isDisabled}
          style={{ width: elementWidth }}
          min={min}
          max={max}
          step={resolveStep(step)}
          data-for={id}
          data-tip={error && error.errorMessage}
          onKeyDown={keyPressHandler}
          onBlur={handleSendRequest}
          data-custom-selector={dataCustomSelector}
        />
        {isSearch && (
          <i
            className={classNames('material-icons', styles.searchIcon, {
              [styles.isDisabled]: isDisabled,
              [styles.withLabel]: label,
            })}
          >
            search
          </i>
        )}
        {isNumberInput && !isDisabled && (
          <>
            <div
              aria-hidden
              className={classNames(
                styles.controlBlock,
                styles.increment,
                {
                  [styles.withLabel]: label,
                },
                outerControlBlockClassName,
              )}
              onClick={incrementValue}
              onContextMenu={stopCount}
              onMouseDown={mouseDownHandler}
              onMouseUp={stopCount}
              onMouseOut={stopCount}
              onBlur={stopCount}
              data-custom-selector={`${dataCustomSelector}_arrow_up`}
            >
              <i
                className={classNames(
                  'material-icons',
                  styles.iconArrow,
                  styles.increment,
                  outerIconArrowClassName,
                  outerIconArrowIncrementClassName,
                )}
              >
                arrow_drop_up
              </i>
            </div>
            <div
              aria-hidden
              className={classNames(
                styles.controlBlock,
                styles.decrement,
                {
                  [styles.withLabel]: label,
                },
                outerControlBlockClassName,
                outerControlBlockDecrementClassName,
              )}
              onClick={decrementValue}
              onContextMenu={stopCount}
              onMouseDown={() => mouseDownHandler({ isIncrement: false })}
              onMouseUp={stopCount}
              onMouseOut={stopCount}
              onBlur={stopCount}
              data-custom-selector={`${dataCustomSelector}_arrow_down`}
            >
              <i
                className={classNames(
                  'material-icons',
                  styles.iconArrow,
                  styles.decrement,
                  outerIconArrowClassName,
                  outerIconArrowDecrementClassName,
                )}
              >
                arrow_drop_down
              </i>
            </div>
          </>
        )}
        {error && error.errorMessage && !withErrorTooltip && (
          <div className={classNames(styles.error, outerErrorClassName)}>{error.errorMessage}</div>
        )}
      </div>
      {withPips && (
        <div
          className={classNames(
            styles.pipsWrapper,
            {
              [styles.isLighter]: isLighter,
              [styles.isDisabled]: isDisabled,
            },
            outerPipsClassName,
          )}
        >
          {pipsLabel}
        </div>
      )}
      {withErrorTooltip && error && error.errorMessage && (
        <ReactTooltip
          id={id}
          place="bottom"
          type="dark"
          effect="solid"
          multiline
          data-html
          className={classNames(styles.tooltip, outerErrorTooltipClassName)}
        />
      )}
    </div>
  );
};
CustomInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  errorMessages: PropTypes.arrayOf(PropTypes.shape({})),
  validateFunction: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([WIDTH.PERCENTAGE_100, ''])]),
  isDisabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.func, // (value: number, isIncrement: bool) => number
  ]),
  isLighter: PropTypes.bool,
  isSearch: PropTypes.bool,
  withPips: PropTypes.bool,
  pipsLabel: PropTypes.string,
  isEndAligned: PropTypes.bool,
  withErrorTooltip: PropTypes.bool,
  onBlurSendRequest: PropTypes.func,
  onlyIntegerAllowed: PropTypes.bool,
  dataCustomSelector: PropTypes.string,
  validateNegativeValues: PropTypes.bool,
  errorClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  controlBlockClassName: PropTypes.string,
  controlBlockDecrementClassName: PropTypes.string,
  iconAllowClassName: PropTypes.string,
  iconAllowIncrementClassName: PropTypes.string,
  iconAllowDecrementClassName: PropTypes.string,
  pipsClassName: PropTypes.string,
  errorTooltipClassName: PropTypes.string,
};
CustomInput.defaultProps = {
  className: '',
  label: '',
  onChange: () => {},
  name: null,
  errorMessages: [],
  validateFunction: () => {},
  type: '',
  placeholder: '',
  width: '',
  isDisabled: false,
  min: 0,
  max: null,
  step: 1,
  isLighter: false,
  isSearch: false,
  withPips: false,
  pipsLabel: 'pips',
  isEndAligned: false,
  withErrorTooltip: false,
  onBlurSendRequest: () => {},
  onlyIntegerAllowed: false,
  dataCustomSelector: '',
  validateNegativeValues: false,
  errorClassName: '',
  inputClassName: '',
  controlBlockClassName: '',
  controlBlockDecrementClassName: '',
  iconAllowClassName: '',
  iconAllowIncrementClassName: '',
  iconAllowDecrementClassName: '',
  pipsClassName: '',
  errorTooltipClassName: '',
};

export default memo(CustomInput);
