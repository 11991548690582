import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MARKET_STATUSES, TRADE_METHODS, FX, ADDITIONAL_INSTRUMENT_IDS } from 'shared-modules/constants';
import { FULL_SCREEN_COLUMN_NAMES } from 'shared-modules/constants/manualTrade';
import { useInstrumentSettings } from 'shared-modules/services/hooks';
import { calculateSpread, roundToFixedPrecision } from 'shared-modules/services';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import FlagElement from '../FlagElement';
import styles from './rowElement.module.scss';

const RowElement = memo(({ item }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const isFX = serviceId === FX;
  const additionalInstrumentId = ADDITIONAL_INSTRUMENT_IDS[serviceId]?.[removeSuffix(item.instrumentId)];
  const isAdditionalInstrument = additionalInstrumentId != null;
  const instrumentId = useMemo(() => {
    if (serviceId !== FX && isAdditionalInstrument) {
      return `${serviceId}.${removeSuffix(item.instrumentId)}`;
    }
    return item.instrumentId;
  }, [isAdditionalInstrument, serviceId, item.instrumentId]);

  const itemPrices = useSelector((state) => state.currencies.rates[instrumentId]) || {};
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const { marketStatus, pricePrecision: instrumentPrecision } = useInstrumentSettings(
    instrumentId,
    TRADE_METHODS.MANUAL.ID,
  );
  const isClosed = useMemo(() => Number(marketStatus) === MARKET_STATUSES.CLOSED.ID, [marketStatus]);

  const cellArray = [
    {
      id: 0,
      render: () => (
        <FlagElement instrumentId={instrumentId} isFX={isFX} isAdditionalInstrument={isAdditionalInstrument} />
      ),
    },
    {
      id: 1,
      render: () =>
        itemPrices[FULL_SCREEN_COLUMN_NAMES.BID]
          ? roundToFixedPrecision(itemPrices[FULL_SCREEN_COLUMN_NAMES.BID], instrumentPrecision)
          : '-',
    },
    {
      id: 2,
      render: () =>
        itemPrices[FULL_SCREEN_COLUMN_NAMES.ASK]
          ? roundToFixedPrecision(itemPrices[FULL_SCREEN_COLUMN_NAMES.ASK], instrumentPrecision)
          : '-',
    },
    {
      id: 3,
      render: () =>
        itemPrices[FULL_SCREEN_COLUMN_NAMES.BID]
          ? calculateSpread({
              buyPrice: itemPrices[FULL_SCREEN_COLUMN_NAMES.ASK],
              sellPrice: itemPrices[FULL_SCREEN_COLUMN_NAMES.BID],
              instrumentId,
              instrumentList,
              alwaysReturnValue: true,
            })
          : '-',
    },
    {
      id: 4,
      render: () =>
        itemPrices[FULL_SCREEN_COLUMN_NAMES.HIGH]
          ? roundToFixedPrecision(itemPrices[FULL_SCREEN_COLUMN_NAMES.HIGH], instrumentPrecision)
          : '-',
    },
    {
      id: 5,
      render: () =>
        itemPrices[FULL_SCREEN_COLUMN_NAMES.LOW]
          ? roundToFixedPrecision(itemPrices[FULL_SCREEN_COLUMN_NAMES.LOW], instrumentPrecision)
          : '-',
    },
    {
      id: 6,
      render: () =>
        itemPrices[FULL_SCREEN_COLUMN_NAMES.BID]
          ? roundToFixedPrecision(
              itemPrices[FULL_SCREEN_COLUMN_NAMES.BID] - itemPrices[FULL_SCREEN_COLUMN_NAMES.CLOSE_BID],
              instrumentPrecision,
            )
          : '-',
    },
  ];

  return (
    <div className={styles.row}>
      {cellArray.map((cellItem) => (
        <div
          key={cellItem.id}
          className={classNames(cellItem.id !== 0 ? styles.cellItem : styles.brandCell, {
            [styles.isClosed]: isClosed || isAdditionalInstrument,
          })}
        >
          {cellItem.render()}
        </div>
      ))}
    </div>
  );
});

RowElement.propTypes = {
  item: PropTypes.shape({
    instrumentId: PropTypes.string.isRequired,
  }).isRequired,
};

export default RowElement;
