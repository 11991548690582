import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { roundToFixedPrecision, toCommaSeparatedNumber } from 'shared-modules/services';
import { ReactComponent as DownArrow } from '../../../../../../assets/downArrow.svg';
import { ReactComponent as UpArrow } from '../../../../../../assets/upArrow.svg';
import styles from './valueCompared.module.scss';

const ARROW_SIZE = 17;

const ValueCompared = ({ prevValue, value, precision, isClosed }) => {
  return (
    <div className={styles.wrapper}>
      {prevValue && value < prevValue && (
        <DownArrow width={ARROW_SIZE} height={ARROW_SIZE} className={styles.arrowIcon} key={value} />
      )}
      {prevValue && value > prevValue && (
        <UpArrow
          width={ARROW_SIZE}
          height={ARROW_SIZE}
          className={classNames(styles.arrowIcon, styles.red)}
          key={value}
        />
      )}
      <div className={classNames({ [styles.isClosed]: isClosed })}>
        {value && toCommaSeparatedNumber(roundToFixedPrecision(value, precision))}
      </div>
    </div>
  );
};

ValueCompared.propTypes = {
  prevValue: PropTypes.number,
  value: PropTypes.number,
  isClosed: PropTypes.bool,
  precision: PropTypes.number.isRequired,
};

ValueCompared.defaultProps = {
  prevValue: null,
  value: null,
  isClosed: false,
};

export default ValueCompared;
