/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_ORDER_SETS_VALUE,
  ORDER_NAME_MAX_LENGTH,
  STRATEGY_SETS_MIN_VALUE,
  BUILDER_ORDER_TYPES,
} from '../../constants/builder';
import {
  addToCartBuilderRequest,
  addToPortfolioBuilderRequest,
  getBuilderChartDataRequest,
  resetBuilderOrdersTables,
} from '../../redux/actions/builderActions';
import { sendNotificationSuccess } from '../../redux/actions/notificationActions';
import { formatNumberToDisplayedString, getDateSolidString } from '../index';
import {
  STRATEGY_SETS_INPUT_NAME,
  STRATEGY_NAME_INPUT_NAME,
  NAME_VALIDATION_ERROR,
  useBuilderMaxStrategySetsValue,
  useBuilderMarginRequirement,
} from './builder';
import {
  CFD,
  CFD_EUR_JPY_INSTRUMENT_ID,
  CFD_GBP_JPY_INSTRUMENT_ID,
  CFD_USD_JPY_INSTRUMENT_ID,
  CHART_MAKE_FUND_INDICATION_TOOLTIP,
  CHART_MAKE_REQUEST_LOGIC_ID,
  COUNTRY_TYPE,
  ETF,
  ETF_USD_JPY_INSTRUMENT_ID,
  FX,
  PROTECTOR,
  REQUIRED_MARGIN_LABEL,
  REQUIRED_MARGIN_TOOLTIP,
  ZONE_PROTECTOR,
} from '../../constants';
import { getApGroupRequest } from '../../redux/actions/portfolioActions';
import { useAccountInfo } from '../../hooks';

export const useChartMakeLogicName = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const instrumentShortName = useMemo(() => {
    if (instrumentId && instrumentList?.[instrumentId]) return instrumentList[instrumentId].shortName;
    return '';
  }, [instrumentId, instrumentList]);

  return useMemo(() => `${instrumentShortName}_${getDateSolidString()}`, [instrumentShortName]);
};

export const useGetChartMakeOrderSettingData = (selectedLogic) => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const etfUsdJpyRate = useSelector((state) => state.currencies.rates?.[ETF_USD_JPY_INSTRUMENT_ID]?.bidClose);
  const cfdUsdJpyRate = useSelector((state) => state.currencies.rates?.[CFD_USD_JPY_INSTRUMENT_ID]?.bidClose);
  const cfdEurJpyRate = useSelector((state) => state.currencies.rates?.[CFD_EUR_JPY_INSTRUMENT_ID]?.bidClose);
  const cfdGbpJpyRate = useSelector((state) => state.currencies.rates?.[CFD_GBP_JPY_INSTRUMENT_ID]?.bidClose);
  const serviceId = useSelector((state) => state.auth.serviceId);
  const accountInfo = useAccountInfo()[serviceId];

  const isFX = serviceId === FX;

  const orderTotalRequiredMargin = useBuilderMarginRequirement();

  if (accountInfo[serviceId]?.isNotAvailable) {
    return { requiredMargin: 0 };
  }

  const convertRateMap = {
    [ETF]: {
      [COUNTRY_TYPE.USD]: etfUsdJpyRate,
    },
    [CFD]: {
      [COUNTRY_TYPE.USD]: cfdUsdJpyRate,
      [COUNTRY_TYPE.EUR]: cfdEurJpyRate,
      [COUNTRY_TYPE.GBP]: cfdGbpJpyRate,
    },
  };

  let fundIndication;
  if ((!isFX && selectedLogic.getType() === PROTECTOR) || selectedLogic.getType() === ZONE_PROTECTOR) {
    const instrumentType = Object.values(COUNTRY_TYPE).find((type) => instrumentId.endsWith(type));
    const convertRate = convertRateMap[serviceId][instrumentType] ?? undefined;
    fundIndication = selectedLogic.getEstOperatingFunds(orderTotalRequiredMargin, convertRate);
  }

  return { requiredMargin: orderTotalRequiredMargin, fundIndication };
};

export const useGetChartMakeOrderRequestData = () => {
  const orderSettings = useSelector((state) => state.builder.orderSettingsList);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const orderDefaultName = useChartMakeLogicName();

  return useCallback(
    (name = '') => {
      const orderName = name ? `${name}_${BUILDER_ORDER_TYPES.CHART_MAKE.NAME}` : orderDefaultName;
      const itemList = orderSettings.map((order, index) => {
        const number = index + 1;
        const orderNumber = number < 10 ? `0${number}` : number;

        return {
          id: number,
          name: `${orderName}_${orderNumber}`,
          quantity: order.amount,
          side: order.buySell,
          sl: order.stopLossRange,
          tp: order.profitMargin,
          entryPrice1: order.entryPrice1,
          entryPrice2: order.entryPrice2,
          follow: order.follow,
          counter: order.counter,
          counterPrice: order.counterPrice,
        };
      });

      return {
        name: orderName,
        logicId: CHART_MAKE_REQUEST_LOGIC_ID,
        instrumentId,
        itemList,
      };
    },
    [instrumentId, orderDefaultName, orderSettings],
  );
};

export const useChartMakeAddToCartLogic = ({ isModalOpen: isOpen, closeModal }) => {
  const [errorMessages, changeErrorMessages] = useState([]);

  const dispatch = useDispatch();

  const { fundIndication } = useSelector((state) => state.modals.chartMakeAddToCart.data);
  const requiredMargin = useBuilderMarginRequirement();

  const addToCartIsLoading = useSelector((state) => state.builder.addToCartIsLoading);

  const orderName = useChartMakeLogicName();
  const getChartMakeOrderRequestData = useGetChartMakeOrderRequestData();

  const orderNameRef = useRef({});
  useEffect(() => {
    if (orderNameRef?.current) orderNameRef.current = { orderName };
  }, [orderName]);

  useEffect(() => {
    if (isOpen) changeErrorMessages([]);
  }, [isOpen]);

  const [currentOrderName, setOrderName] = useState(orderName);
  const [currentSets, setSets] = useState(DEFAULT_ORDER_SETS_VALUE);

  const addToCartCallback = useCallback(() => {
    closeModal();
    dispatch(sendNotificationSuccess({ message: 'カートに追加されました。' }));
    dispatch(resetBuilderOrdersTables());
  }, [dispatch, closeModal]);

  const addToCart = useCallback(() => {
    if (errorMessages.length) return;

    const orderRequestData = getChartMakeOrderRequestData(currentOrderName);

    dispatch(
      addToCartBuilderRequest({
        strategySets: currentSets,
        orderRequestData,
        callback: addToCartCallback,
        isChartMake: true,
      }),
    );
  }, [errorMessages.length, getChartMakeOrderRequestData, currentOrderName, dispatch, currentSets, addToCartCallback]);

  const changeOrderName = useCallback((value) => {
    let newOrderName = String(value);
    if (newOrderName.length > ORDER_NAME_MAX_LENGTH) newOrderName = newOrderName.slice(0, ORDER_NAME_MAX_LENGTH);

    setOrderName(newOrderName);
  }, []);

  const multipliedFundIndication = useMemo(() => fundIndication * currentSets, [fundIndication, currentSets]);
  const multipliedRequiredMargin = useMemo(() => requiredMargin * currentSets, [requiredMargin, currentSets]);
  const maxValue = useBuilderMaxStrategySetsValue();

  const validateInput = useCallback(
    (value) => {
      if (STRATEGY_SETS_MIN_VALUE <= value && value <= maxValue) {
        changeErrorMessages((prevVal) => prevVal.filter((i) => i.inputName !== STRATEGY_SETS_INPUT_NAME));
      } else {
        changeErrorMessages((prevVal) => [
          ...prevVal.filter((i) => i.inputName !== STRATEGY_SETS_INPUT_NAME),
          { inputName: STRATEGY_SETS_INPUT_NAME, errorMessage: `セット数は1以上${maxValue}までご設定ください。` },
        ]);
      }
    },
    [maxValue],
  );

  const validateName = useCallback((value) => {
    if (String(value).length > 40) {
      changeErrorMessages((prevVal) => [
        ...prevVal.filter((i) => i.inputName !== STRATEGY_NAME_INPUT_NAME),
        { inputName: STRATEGY_NAME_INPUT_NAME, errorMessage: NAME_VALIDATION_ERROR },
      ]);
    } else {
      changeErrorMessages((prevVal) => prevVal.filter((i) => i.inputName !== STRATEGY_NAME_INPUT_NAME));
    }
  }, []);

  const isDisabledConfirmButton = Boolean(errorMessages.length);

  useEffect(() => {
    const { orderName: orderNameValue } = orderNameRef.current;
    if (isOpen) {
      setOrderName(orderNameValue);
      setSets(DEFAULT_ORDER_SETS_VALUE);
    }
  }, [isOpen]);

  return {
    modalTitle: 'カート追加 確認',
    errorMessages,
    name: {
      label: '自動売買グループ名',
      get: currentOrderName,
      set: changeOrderName,
      name: STRATEGY_NAME_INPUT_NAME,
      validateFunction: validateName,
    },
    sets: {
      label: 'セット数',
      get: currentSets,
      set: setSets,
      name: STRATEGY_SETS_INPUT_NAME,
      validateFunction: validateInput,
    },
    fundIndication: {
      label: '運用資金目安',
      get: formatNumberToDisplayedString({ value: multipliedFundIndication, withoutPlus: true }),
      tooltip: CHART_MAKE_FUND_INDICATION_TOOLTIP,
    },
    requiredMargin: {
      label: REQUIRED_MARGIN_LABEL,
      get: formatNumberToDisplayedString({ value: multipliedRequiredMargin, withoutPlus: true }),
      tooltip: REQUIRED_MARGIN_TOOLTIP,
    },
    submit: {
      label: 'カートに追加する',
      handler: addToCart,
      isLoading: addToCartIsLoading,
      isDisabled: isDisabledConfirmButton,
    },
  };
};

export const useChartMakeRunNowLogic = ({ isModalOpen: isOpen, closeModal, handleSuccess, handleFail }) => {
  const [errorMessages, changeErrorMessages] = useState([]);

  const dispatch = useDispatch();

  const { fundIndication } = useSelector((state) => state.modals.chartMakeRunNowConfirmation.data);
  const requiredMargin = useBuilderMarginRequirement();

  const addToPortfolioIsLoading = useSelector((state) => state.builder.addToPortfolioIsLoading);

  const orderName = useChartMakeLogicName();
  const getChartMakeOrderRequestData = useGetChartMakeOrderRequestData();

  const orderNameRef = useRef({});
  useEffect(() => {
    if (orderNameRef?.current) orderNameRef.current = { orderName };
  }, [orderName]);

  useEffect(() => {
    if (isOpen) changeErrorMessages([]);
  }, [isOpen]);

  const [currentOrderName, setOrderName] = useState(orderName);
  const [currentSets, setSets] = useState(DEFAULT_ORDER_SETS_VALUE);

  const serviceId = useSelector((state) => state.auth.serviceId);

  const successCallback = useCallback(() => {
    closeModal();
    dispatch(getApGroupRequest({ serviceId }));
    if (handleSuccess) handleSuccess(currentOrderName);
  }, [closeModal, dispatch, serviceId, handleSuccess, currentOrderName]);

  const failCallback = useCallback(() => {
    closeModal();
    if (handleFail) handleFail(currentOrderName);
  }, [closeModal, handleFail, currentOrderName]);

  const addToPortfolio = useCallback(() => {
    const orderRequestData = getChartMakeOrderRequestData(currentOrderName);

    dispatch(
      addToPortfolioBuilderRequest({ strategySets: currentSets, orderRequestData, successCallback, failCallback }),
    );
  }, [dispatch, currentOrderName, getChartMakeOrderRequestData, currentSets, successCallback, failCallback]);

  const changeOrderName = useCallback((value) => {
    let newOrderName = String(value);
    if (newOrderName.length > ORDER_NAME_MAX_LENGTH) newOrderName = newOrderName.slice(0, ORDER_NAME_MAX_LENGTH);

    setOrderName(newOrderName);
  }, []);

  const multipliedFundIndication = useMemo(() => fundIndication * currentSets, [fundIndication, currentSets]);
  const multipliedRequiredMargin = useMemo(() => requiredMargin * currentSets, [requiredMargin, currentSets]);
  const maxValue = useBuilderMaxStrategySetsValue();

  const validateInput = useCallback(
    (value) => {
      if (STRATEGY_SETS_MIN_VALUE <= value && value <= maxValue) {
        changeErrorMessages((prevVal) => prevVal.filter((i) => i.inputName !== STRATEGY_SETS_INPUT_NAME));
      } else {
        changeErrorMessages((prevVal) => [
          ...prevVal.filter((i) => i.inputName !== STRATEGY_SETS_INPUT_NAME),
          { inputName: STRATEGY_SETS_INPUT_NAME, errorMessage: `セット数は1以上${maxValue}までご設定ください。` },
        ]);
      }
    },
    [maxValue],
  );

  const validateName = useCallback((value) => {
    if (String(value).length > 40) {
      changeErrorMessages((prevVal) => [
        ...prevVal.filter((i) => i.inputName !== STRATEGY_NAME_INPUT_NAME),
        { inputName: STRATEGY_NAME_INPUT_NAME, errorMessage: NAME_VALIDATION_ERROR },
      ]);
    } else {
      changeErrorMessages((prevVal) => prevVal.filter((i) => i.inputName !== STRATEGY_NAME_INPUT_NAME));
    }
  }, []);

  const isDisabledConfirmButton = Boolean(errorMessages.length);

  useEffect(() => {
    const { orderName: orderNameValue } = orderNameRef.current;
    if (isOpen) setOrderName(orderNameValue);
  }, [isOpen]);

  return {
    modalTitle: '今すぐ稼働 確認',
    errorMessages,
    name: {
      label: '自動売買グループ名',
      get: currentOrderName,
      set: changeOrderName,
      name: STRATEGY_NAME_INPUT_NAME,
      validateFunction: validateName,
    },
    sets: {
      label: 'セット数',
      get: currentSets,
      set: setSets,
      name: STRATEGY_SETS_INPUT_NAME,
      validateFunction: validateInput,
    },
    fundIndication: {
      label: '運用資金目安',
      get: multipliedFundIndication,
      tooltip: CHART_MAKE_FUND_INDICATION_TOOLTIP,
    },
    requiredMargin: {
      label: REQUIRED_MARGIN_LABEL,
      get: multipliedRequiredMargin,
      tooltip: REQUIRED_MARGIN_TOOLTIP,
    },
    submit: {
      label: '稼働開始',
      handler: addToPortfolio,
      isLoading: addToPortfolioIsLoading,
      isDisabled: isDisabledConfirmButton,
    },
    message:
      'セット数を変更すると、取引数量も変更されます。稼働開始後、必ずホーム画面のポートフォリオより取引数量をご確認ください。',
  };
};

export const useChartMakeRunNowResultsLogic = ({ orderName, closeModal }) => {
  const dispatch = useDispatch();

  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);

  const modifiedOrderName = useMemo(() => `${orderName}_${BUILDER_ORDER_TYPES.CHART_MAKE.NAME}`, [orderName]);

  const orderInfo = useMemo(
    () =>
      `・${modifiedOrderName}\n   ${orderSettingsList.length}件中${orderSettingsList.length}件自動売買が稼働しました`,
    [modifiedOrderName, orderSettingsList],
  );

  const closeSuccessModal = useCallback(() => {
    closeModal();
    dispatch(resetBuilderOrdersTables());
    dispatch(getBuilderChartDataRequest());
  }, [closeModal, dispatch]);

  return {
    success: {
      modalTitle: '自動売買が稼働しました。',
      title: '注文の状況はホーム画面のポートフォリオ内にて確認できます。',
      message: orderInfo,
    },
    fail: {
      modalTitle: '稼働できなかった自動売買があります。',
      title1: '稼働した注文についてはホーム画面のポートフォリオ内にて確認できます。',
      title2: '失敗した注文については証拠金状況や注文状況等を今一度ご確認ください。',
      failLabel: '[失敗]',
      orderName: modifiedOrderName,
      message: '稼働ができませんでした',
    },
    buttons: {
      submit: {
        label: 'ホーム画面を開く',
      },
      cancel: {
        label: '閉じる',
        handler: closeSuccessModal,
      },
    },
  };
};
