import {
  CFD,
  ETF,
  FX,
  ORDER_TYPES_MAIN,
  TRADE_INFO_TABLES,
  TRADE_METHOD_FILTER_INITIAL_STATES,
  TRADE_TABLES,
} from './index';
import { getTradeMethodOptionIds } from '../utils/manualTrade';

export const BUY_SELL_INPUT = 'buySell';
export const PRICE_INPUT = 'price';
export const COUNT_INPUT = 'count';
export const ORDER_METHOD_INPUT = 'orderMethod';
export const LIMIT_PRICE_INPUT = 'limitPrice';
export const STOP_PRICE_INPUT = 'stopPrice';
export const DATE_INPUT = 'selectedDate';
export const TIME_INPUT = 'selectedTime';
export const SETTLEMENT_PRICE_INPUT = 'settlementPrice';
export const SETTLEMENT_ORDER_METHOD_INPUT = 'settlementOrderMethod';
export const SETTLEMENT_LIMIT_PRICE_INPUT = 'settlementLimitPrice';
export const SETTLEMENT_STOP_PRICE_INPUT = 'settlementStopPrice';
export const EXPIRATION_TYPE_INPUT = 'expirationType';
export const SETTLEMENT_EXPIRATION_TYPE_INPUT = 'settlementExpirationType';
export const SETTLEMENT_DATE_INPUT = 'settlementSelectedDate';
export const SETTLEMENT_TIME_INPUT = 'settlementSelectedTime';

export const CHANGE_ORDER_PRICE_INPUT = 'innerPrice';
export const CHANGE_ORDER_THEN_PRICE_INPUT = 'innerThenPrice';
export const CHANGE_ORDER_OCO2_PRICE_INPUT = 'innerOco2Price';
export const CHANGE_ORDER_THEN_OCO2_PRICE_INPUT = 'innerThenOco2Price';
export const CHANGE_DATE_INPUT = 'changeSelectedDate';
export const CHANGE_TIME_INPUT = 'changeSelectedTime';
export const CHANGE_SETTLEMENT_DATE_INPUT = 'changeSettlementSelectedDate';
export const CHANGE_SETTLEMENT_TIME_INPUT = 'changeSettlementSelectedTime';

export const CLOSE_PRICE_INPUT = 'closePrice';
export const CLOSE_LIMIT_PRICE_INPUT = 'closeLimitPrice';
export const CLOSE_STOP_PRICE_INPUT = 'closeStopPrice';
export const CLOSE_DATE_INPUT = 'closeSelectedDate';
export const CLOSE_TIME_INPUT = 'closeSelectedTime';

export const CHANGE_ORDER_FORM = 'changeOrderForm';
export const CLOSE_ORDER_FORM = 'closeOrderForm';

export const ORDER_TYPES = {
  MARKET_ORDER: {
    id: ORDER_TYPES_MAIN.MARKET_ORDER.ID,
    name: 'marketOrder',
    inputs: {
      BUY_SELL: BUY_SELL_INPUT,
      COUNT: COUNT_INPUT,
    },
  },
  STANDARD: {
    id: ORDER_TYPES_MAIN.STANDARD.ID,
    name: 'standard',
    inputs: {
      BUY_SELL: BUY_SELL_INPUT,
      COUNT: COUNT_INPUT,
      ORDER_METHOD: ORDER_METHOD_INPUT,
      PRICE: PRICE_INPUT,
      EXPIRATION_TYPE: EXPIRATION_TYPE_INPUT,
      SELECTED_DATE: DATE_INPUT,
      SELECTED_TIME: TIME_INPUT,
    },
  },
  OCO: {
    id: ORDER_TYPES_MAIN.OCO.ID,
    name: 'oco',
    inputs: {
      BUY_SELL: BUY_SELL_INPUT,
      COUNT: COUNT_INPUT,
      LIMIT_PRICE: LIMIT_PRICE_INPUT,
      STOP_PRICE: STOP_PRICE_INPUT,
      EXPIRATION_TYPE: EXPIRATION_TYPE_INPUT,
      SELECTED_DATE: DATE_INPUT,
      SELECTED_TIME: TIME_INPUT,
    },
  },
  IFD: {
    id: ORDER_TYPES_MAIN.IFD.ID,
    name: 'ifd',
    inputs: {
      BUY_SELL: BUY_SELL_INPUT,
      ORDER_METHOD: ORDER_METHOD_INPUT,
      COUNT: COUNT_INPUT,
      PRICE: PRICE_INPUT,
      EXPIRATION_TYPE: EXPIRATION_TYPE_INPUT,
      SELECTED_DATE: DATE_INPUT,
      SELECTED_TIME: TIME_INPUT,
      SETTLEMENT_ORDER_METHOD: SETTLEMENT_ORDER_METHOD_INPUT,
      SETTLEMENT_PRICE: SETTLEMENT_PRICE_INPUT,
      SETTLEMENT_EXPIRATION_TYPE: SETTLEMENT_EXPIRATION_TYPE_INPUT,
      SETTLEMENT_SELECTED_DATE: SETTLEMENT_DATE_INPUT,
      SETTLEMENT_SELECTED_TIME: SETTLEMENT_TIME_INPUT,
    },
  },
  IFO: {
    id: ORDER_TYPES_MAIN.IFO.ID,
    name: 'ifo',
    inputs: {
      BUY_SELL: BUY_SELL_INPUT,
      ORDER_METHOD: ORDER_METHOD_INPUT,
      COUNT: COUNT_INPUT,
      PRICE: PRICE_INPUT,
      EXPIRATION_TYPE: EXPIRATION_TYPE_INPUT,
      SELECTED_DATE: DATE_INPUT,
      SELECTED_TIME: TIME_INPUT,
      SETTLEMENT_LIMIT_PRICE: SETTLEMENT_LIMIT_PRICE_INPUT,
      SETTLEMENT_STOP_PRICE: SETTLEMENT_STOP_PRICE_INPUT,
      SETTLEMENT_EXPIRATION_TYPE: SETTLEMENT_EXPIRATION_TYPE_INPUT,
      SETTLEMENT_SELECTED_DATE: SETTLEMENT_DATE_INPUT,
      SETTLEMENT_SELECTED_TIME: SETTLEMENT_TIME_INPUT,
    },
  },
};

export const ORDER_TYPES_NAMES = Object.values(ORDER_TYPES).map(({ name }) => name);

// TODO: Check all field names after backend will send them
export const ORDER_TYPE_INPUT_NAME_BY_BACKEND_FIELD = {
  [ORDER_TYPES.MARKET_ORDER.name]: {
    quantity: [ORDER_TYPES.MARKET_ORDER.inputs.COUNT],
  },
  [ORDER_TYPES.STANDARD.name]: {
    quantity: [ORDER_TYPES.STANDARD.inputs.COUNT],
    price: [ORDER_TYPES.STANDARD.inputs.PRICE],
    expireTime: [ORDER_TYPES.STANDARD.inputs.SELECTED_TIME, ORDER_TYPES.STANDARD.inputs.SELECTED_DATE],
  },
  [ORDER_TYPES.OCO.name]: {
    quantity: [ORDER_TYPES.OCO.inputs.COUNT],
    'order1.price': [ORDER_TYPES.OCO.inputs.LIMIT_PRICE],
    'order2.price': [ORDER_TYPES.OCO.inputs.STOP_PRICE],
    expireTime: [ORDER_TYPES.OCO.inputs.SELECTED_TIME, ORDER_TYPES.OCO.inputs.SELECTED_DATE],
  },
  [ORDER_TYPES.IFD.name]: {
    quantity: [ORDER_TYPES.IFD.inputs.COUNT],
    'newOrder.price': [ORDER_TYPES.IFD.inputs.PRICE],
    'closeOrder.price': [ORDER_TYPES.IFD.inputs.SETTLEMENT_PRICE],
    'newOrder.expireTime': [ORDER_TYPES.IFD.inputs.SELECTED_TIME, ORDER_TYPES.IFD.inputs.SELECTED_DATE],
    'closeOrder.expireTime': [
      ORDER_TYPES.IFD.inputs.SETTLEMENT_SELECTED_TIME,
      ORDER_TYPES.IFD.inputs.SETTLEMENT_SELECTED_DATE,
    ],
  },
  [ORDER_TYPES.IFO.name]: {
    quantity: [ORDER_TYPES.IFO.inputs.COUNT],
    'newOrder.price': [ORDER_TYPES.IFO.inputs.PRICE],
    'closeOrder1.price': [ORDER_TYPES.IFO.inputs.SETTLEMENT_LIMIT_PRICE],
    'closeOrder2.price': [ORDER_TYPES.IFO.inputs.SETTLEMENT_STOP_PRICE],
    'newOrder.expireTime': [ORDER_TYPES.IFO.inputs.SELECTED_TIME, ORDER_TYPES.IFO.inputs.SELECTED_DATE],
    'closeOrder1.expireTime': [
      ORDER_TYPES.IFO.inputs.SETTLEMENT_SELECTED_TIME,
      ORDER_TYPES.IFO.inputs.SETTLEMENT_SELECTED_DATE,
    ],
    'closeOrder2.expireTime': [
      ORDER_TYPES.IFO.inputs.SETTLEMENT_SELECTED_TIME,
      ORDER_TYPES.IFO.inputs.SETTLEMENT_SELECTED_DATE,
    ],
    'closeOrder.expireTime': [
      ORDER_TYPES.IFO.inputs.SETTLEMENT_SELECTED_TIME,
      ORDER_TYPES.IFO.inputs.SETTLEMENT_SELECTED_DATE,
    ],
  },
};

export const CHANGE_ORDER_TYPE_INPUT_NAME_BY_BACKEND_FIELD = {
  price: CHANGE_ORDER_PRICE_INPUT,
  'newOrder.price': CHANGE_ORDER_PRICE_INPUT,
  'closeOrder.price': CHANGE_ORDER_THEN_PRICE_INPUT,
  'order1.price': CHANGE_ORDER_PRICE_INPUT,
  'order2.price': CHANGE_ORDER_OCO2_PRICE_INPUT,
  'closeOrder1.price': CHANGE_ORDER_THEN_PRICE_INPUT,
  'closeOrder2.price': CHANGE_ORDER_THEN_OCO2_PRICE_INPUT,
};

export const CLOSE_ORDER_TYPE_INPUT_NAME_BY_BACKEND_FIELD = {
  quantity: COUNT_INPUT,
  price: CLOSE_PRICE_INPUT,
  'order1.price': CLOSE_LIMIT_PRICE_INPUT,
  'order2.price': CLOSE_STOP_PRICE_INPUT,
};

export const ORDER_TYPES_BY_ID = {
  [ORDER_TYPES_MAIN.MARKET_ORDER.ID]: ORDER_TYPES.MARKET_ORDER.name,
  [ORDER_TYPES_MAIN.STANDARD.ID]: ORDER_TYPES.STANDARD.name,
  [ORDER_TYPES_MAIN.OCO.ID]: ORDER_TYPES.OCO.name,
  [ORDER_TYPES_MAIN.IFD.ID]: ORDER_TYPES.IFD.name,
  [ORDER_TYPES_MAIN.IFO.ID]: ORDER_TYPES.IFO.name,
};

export const COMMON_INPUTS = [COUNT_INPUT, BUY_SELL_INPUT, EXPIRATION_TYPE_INPUT, DATE_INPUT, TIME_INPUT];
export const COMMON_VALIDATION_INPUTS = [COUNT_INPUT, DATE_INPUT, TIME_INPUT];
export const BUY_SELL_INPUTS = [BUY_SELL_INPUT];
export const DATE_INPUTS = [DATE_INPUT, SETTLEMENT_DATE_INPUT];
export const TIME_INPUTS = [TIME_INPUT, SETTLEMENT_TIME_INPUT];
export const ADDITION_VALIDATION_DATE_INPUTS = {
  [DATE_INPUT]: TIME_INPUT,
  [TIME_INPUT]: DATE_INPUT,
  [SETTLEMENT_DATE_INPUT]: SETTLEMENT_TIME_INPUT,
  [SETTLEMENT_TIME_INPUT]: SETTLEMENT_DATE_INPUT,
};
export const VALIDATION_DATE_INPUTS = [DATE_INPUT, TIME_INPUT, EXPIRATION_TYPE_INPUT];
export const VALIDATION_SETTLEMENT_DATE_INPUTS = [
  SETTLEMENT_DATE_INPUT,
  SETTLEMENT_TIME_INPUT,
  SETTLEMENT_EXPIRATION_TYPE_INPUT,
];
export const SETTLEMENT_PRICE_INPUTS = [
  SETTLEMENT_PRICE_INPUT,
  SETTLEMENT_LIMIT_PRICE_INPUT,
  SETTLEMENT_STOP_PRICE_INPUT,
];
export const CHANGED_DATE_VALUES = {
  [EXPIRATION_TYPE_INPUT]: 'changedExpireType',
  [DATE_INPUT]: 'changedExpireDate',
  [TIME_INPUT]: 'changedExpireTime',
  [SETTLEMENT_EXPIRATION_TYPE_INPUT]: 'changedSettlementExpireType',
  [SETTLEMENT_DATE_INPUT]: 'changedSettlementExpireDate',
  [SETTLEMENT_TIME_INPUT]: 'changedSettlementExpireTime',
};
export const PRICE_INPUTS = [PRICE_INPUT, LIMIT_PRICE_INPUT, STOP_PRICE_INPUT, ...SETTLEMENT_PRICE_INPUTS];
export const EXPIRATION_INPUTS = [EXPIRATION_TYPE_INPUT, SETTLEMENT_EXPIRATION_TYPE_INPUT];
export const VALIDATED_INPUTS = [COUNT_INPUT, ...TIME_INPUTS, ...DATE_INPUTS, ...PRICE_INPUTS, ...EXPIRATION_INPUTS];
export const LIMIT_PRICE_INPUTS = [LIMIT_PRICE_INPUT, SETTLEMENT_LIMIT_PRICE_INPUT];
export const STOP_PRICE_INPUTS = [STOP_PRICE_INPUT, SETTLEMENT_STOP_PRICE_INPUT];
export const ORDER_METHOD_INPUTS = [ORDER_METHOD_INPUT, SETTLEMENT_ORDER_METHOD_INPUT];

export const DEFAULT_INSTRUMENT_ID = Object.freeze({
  [FX]: 'USD/JPY',
  [ETF]: '1306.TKS/JPY',
  [CFD]: 'JPC/JPY',
});

export const SUCCESS_NOTIFICATION_MESSAGE = '注文を受け付けました。';

export const KEY_FOR_DEFAULT_SELECT_SIDE = 'DEFAULT_SELECT_SIDE';
export const KEY_FOR_DEFAULT_SELECT_TRADE_METHOD = 'DEFAULT_SELECT_TRADE_METHOD';

export const KEY_FOR_DEFAULT_SELECTED_FX_INSTRUMENT_ID = 'KEY_FOR_DEFAULT_SELECTED_FX_INSTRUMENT_ID';
export const KEY_FOR_DEFAULT_SELECTED_ETF_INSTRUMENT_ID = 'KEY_FOR_DEFAULT_SELECTED_ETF_INSTRUMENT_ID';
export const KEY_FOR_DEFAULT_SELECTED_CFD_INSTRUMENT_ID = 'KEY_FOR_DEFAULT_SELECTED_CFD_INSTRUMENT_ID';
export const KEY_FOR_DEFAULT_SELECTED_INSTRUMENT_ID = {
  [FX]: KEY_FOR_DEFAULT_SELECTED_FX_INSTRUMENT_ID,
  [ETF]: KEY_FOR_DEFAULT_SELECTED_ETF_INSTRUMENT_ID,
  [CFD]: KEY_FOR_DEFAULT_SELECTED_CFD_INSTRUMENT_ID,
};

export const SORT_DESCENDING = 'desc';
export const SORT_ASCENDING = 'asc';

export const DEFAULT_EXECUTIONS_TABLE_SORT_BY = 'execTime';
export const DEFAULT_EXECUTIONS_TABLE_SORT_DIR = SORT_DESCENDING;

export const KEY_FOR_DEFAULT_FX_EXECUTIONS_TABLE_SORT_BY = 'DEFAULT_FX_EXECUTIONS_TABLE_SORT_BY';
export const KEY_FOR_DEFAULT_ETF_EXECUTIONS_TABLE_SORT_BY = 'DEFAULT_ETF_EXECUTIONS_TABLE_SORT_BY';
export const KEY_FOR_DEFAULT_CFD_EXECUTIONS_TABLE_SORT_BY = 'DEFAULT_CFD_EXECUTIONS_TABLE_SORT_BY';
export const KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY = {
  [FX]: KEY_FOR_DEFAULT_FX_EXECUTIONS_TABLE_SORT_BY,
  [ETF]: KEY_FOR_DEFAULT_ETF_EXECUTIONS_TABLE_SORT_BY,
  [CFD]: KEY_FOR_DEFAULT_CFD_EXECUTIONS_TABLE_SORT_BY,
};

export const KEY_FOR_DEFAULT_FX_EXECUTIONS_TABLE_SORT_DIR = 'DEFAULT_FX_EXECUTIONS_TABLE_SORT_DIR';
export const KEY_FOR_DEFAULT_ETF_EXECUTIONS_TABLE_SORT_DIR = 'DEFAULT_ETF_EXECUTIONS_TABLE_SORT_DIR';
export const KEY_FOR_DEFAULT_CFD_EXECUTIONS_TABLE_SORT_DIR = 'DEFAULT_CFD_EXECUTIONS_TABLE_SORT_DIR';
export const KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR = {
  [FX]: KEY_FOR_DEFAULT_FX_EXECUTIONS_TABLE_SORT_DIR,
  [ETF]: KEY_FOR_DEFAULT_ETF_EXECUTIONS_TABLE_SORT_DIR,
  [CFD]: KEY_FOR_DEFAULT_CFD_EXECUTIONS_TABLE_SORT_DIR,
};

export const KEY_FOR_DEFAULT_FX_EXECUTIONS_TABLE_FILTERING_VALUES = 'DEFAULT_FX_EXECUTIONS_TABLE_FILTERING_VALUES';
export const KEY_FOR_DEFAULT_ETF_EXECUTIONS_TABLE_FILTERING_VALUES = 'DEFAULT_ETF_EXECUTIONS_TABLE_FILTERING_VALUES';
export const KEY_FOR_DEFAULT_CFD_EXECUTIONS_TABLE_FILTERING_VALUES = 'DEFAULT_CFD_EXECUTIONS_TABLE_FILTERING_VALUES';
export const KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES = {
  [FX]: KEY_FOR_DEFAULT_FX_EXECUTIONS_TABLE_FILTERING_VALUES,
  [ETF]: KEY_FOR_DEFAULT_ETF_EXECUTIONS_TABLE_FILTERING_VALUES,
  [CFD]: KEY_FOR_DEFAULT_CFD_EXECUTIONS_TABLE_FILTERING_VALUES,
};

export const DEFAULT_POSITIONS_TABLE_SORT_BY = 'execTime';
export const DEFAULT_POSITIONS_TABLE_SORT_DIR = SORT_DESCENDING;

export const KEY_FOR_DEFAULT_FX_POSITIONS_TABLE_SORT_BY = 'DEFAULT_FX_POSITIONS_TABLE_SORT_BY';
export const KEY_FOR_DEFAULT_ETF_POSITIONS_TABLE_SORT_BY = 'DEFAULT_ETF_POSITIONS_TABLE_SORT_BY';
export const KEY_FOR_DEFAULT_CFD_POSITIONS_TABLE_SORT_BY = 'DEFAULT_CFD_POSITIONS_TABLE_SORT_BY';
export const KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY = {
  [FX]: KEY_FOR_DEFAULT_FX_POSITIONS_TABLE_SORT_BY,
  [ETF]: KEY_FOR_DEFAULT_ETF_POSITIONS_TABLE_SORT_BY,
  [CFD]: KEY_FOR_DEFAULT_CFD_POSITIONS_TABLE_SORT_BY,
};

export const KEY_FOR_DEFAULT_FX_POSITIONS_TABLE_SORT_DIR = 'DEFAULT_FX_POSITIONS_TABLE_SORT_DIR';
export const KEY_FOR_DEFAULT_ETF_POSITIONS_TABLE_SORT_DIR = 'DEFAULT_ETF_POSITIONS_TABLE_SORT_DIR';
export const KEY_FOR_DEFAULT_CFD_POSITIONS_TABLE_SORT_DIR = 'DEFAULT_CFD_POSITIONS_TABLE_SORT_DIR';
export const KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR = {
  [FX]: KEY_FOR_DEFAULT_FX_POSITIONS_TABLE_SORT_DIR,
  [ETF]: KEY_FOR_DEFAULT_ETF_POSITIONS_TABLE_SORT_DIR,
  [CFD]: KEY_FOR_DEFAULT_CFD_POSITIONS_TABLE_SORT_DIR,
};

export const KEY_FOR_DEFAULT_FX_POSITIONS_TABLE_FILTERING_VALUES = 'DEFAULT_FX_POSITIONS_TABLE_FILTERING_VALUES';
export const KEY_FOR_DEFAULT_ETF_POSITIONS_TABLE_FILTERING_VALUES = 'DEFAULT_ETF_POSITIONS_TABLE_FILTERING_VALUES';
export const KEY_FOR_DEFAULT_CFD_POSITIONS_TABLE_FILTERING_VALUES = 'DEFAULT_CFD_POSITIONS_TABLE_FILTERING_VALUES';
export const KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES = {
  [FX]: KEY_FOR_DEFAULT_FX_POSITIONS_TABLE_FILTERING_VALUES,
  [ETF]: KEY_FOR_DEFAULT_ETF_POSITIONS_TABLE_FILTERING_VALUES,
  [CFD]: KEY_FOR_DEFAULT_CFD_POSITIONS_TABLE_FILTERING_VALUES,
};

export const DEFAULT_ORDERS_TABLE_SORT_BY = 'orderTime';
export const DEFAULT_ORDERS_TABLE_SORT_DIR = SORT_DESCENDING;
export const DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED = true;

export const KEY_FOR_DEFAULT_FX_ORDERS_TABLE_SORT_BY = 'DEFAULT_FX_ORDERS_TABLE_SORT_BY';
export const KEY_FOR_DEFAULT_ETF_ORDERS_TABLE_SORT_BY = 'DEFAULT_ETF_ORDERS_TABLE_SORT_BY';
export const KEY_FOR_DEFAULT_CFD_ORDERS_TABLE_SORT_BY = 'DEFAULT_CFD_ORDERS_TABLE_SORT_BY';
export const KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY = {
  [FX]: KEY_FOR_DEFAULT_FX_ORDERS_TABLE_SORT_BY,
  [ETF]: KEY_FOR_DEFAULT_ETF_ORDERS_TABLE_SORT_BY,
  [CFD]: KEY_FOR_DEFAULT_CFD_ORDERS_TABLE_SORT_BY,
};

export const KEY_FOR_DEFAULT_FX_ORDERS_TABLE_SORT_DIR = 'DEFAULT_FX_ORDERS_TABLE_SORT_DIR';
export const KEY_FOR_DEFAULT_ETF_ORDERS_TABLE_SORT_DIR = 'DEFAULT_ETF_ORDERS_TABLE_SORT_DIR';
export const KEY_FOR_DEFAULT_CFD_ORDERS_TABLE_SORT_DIR = 'DEFAULT_CFD_ORDERS_TABLE_SORT_DIR';
export const KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR = {
  [FX]: KEY_FOR_DEFAULT_FX_ORDERS_TABLE_SORT_DIR,
  [ETF]: KEY_FOR_DEFAULT_ETF_ORDERS_TABLE_SORT_DIR,
  [CFD]: KEY_FOR_DEFAULT_CFD_ORDERS_TABLE_SORT_DIR,
};

export const KEY_FOR_DEFAULT_FX_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED =
  'DEFAULT_FX_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED';
export const KEY_FOR_DEFAULT_ETF_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED =
  'DEFAULT_ETF_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED';
export const KEY_FOR_DEFAULT_CFD_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED =
  'DEFAULT_CFD_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED';
export const KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED = {
  [FX]: KEY_FOR_DEFAULT_FX_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
  [ETF]: KEY_FOR_DEFAULT_ETF_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
  [CFD]: KEY_FOR_DEFAULT_CFD_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
};

export const KEY_FOR_DEFAULT_FX_ORDERS_TABLE_FILTERING_VALUES = 'DEFAULT_FX_ORDERS_TABLE_FILTERING_VALUES';
export const KEY_FOR_DEFAULT_ETF_ORDERS_TABLE_FILTERING_VALUES = 'DEFAULT_ETF_ORDERS_TABLE_FILTERING_VALUES';
export const KEY_FOR_DEFAULT_CFD_ORDERS_TABLE_FILTERING_VALUES = 'DEFAULT_CFD_ORDERS_TABLE_FILTERING_VALUES';
export const KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES = {
  [FX]: KEY_FOR_DEFAULT_FX_ORDERS_TABLE_FILTERING_VALUES,
  [ETF]: KEY_FOR_DEFAULT_ETF_ORDERS_TABLE_FILTERING_VALUES,
  [CFD]: KEY_FOR_DEFAULT_CFD_ORDERS_TABLE_FILTERING_VALUES,
};

export const DEFAULT_TRADE_METHODS_TABLE_FILTER = [];
export const DEFAULT_INSTRUMENT_ID_TABLE_FILTER = '';

export const KEY_FOR_DEFAULT_FX_TRADE_METHODS_TABLE_FILTER = 'FX_TRADE_METHODS_TABLE_FILTER';
export const KEY_FOR_DEFAULT_ETF_TRADE_METHODS_TABLE_FILTER = 'ETF_TRADE_METHODS_TABLE_FILTER';
export const KEY_FOR_DEFAULT_CFD_TRADE_METHODS_TABLE_FILTER = 'CFD_TRADE_METHODS_TABLE_FILTER';
export const KEY_FOR_DEFAULT_TRADE_METHODS_TABLE_FILTERS = {
  [FX]: KEY_FOR_DEFAULT_FX_TRADE_METHODS_TABLE_FILTER,
  [ETF]: KEY_FOR_DEFAULT_ETF_TRADE_METHODS_TABLE_FILTER,
  [CFD]: KEY_FOR_DEFAULT_CFD_TRADE_METHODS_TABLE_FILTER,
};

export const KEY_FOR_DEFAULT_FX_INSTRUMENT_ID_TABLE_FILTER = 'FX_INSTRUMENT_ID_TABLE_FILTER';
export const KEY_FOR_DEFAULT_ETF_INSTRUMENT_ID_TABLE_FILTER = 'ETF_INSTRUMENT_ID_TABLE_FILTER';
export const KEY_FOR_DEFAULT_CFD_INSTRUMENT_ID_TABLE_FILTER = 'CFD_INSTRUMENT_ID_TABLE_FILTER';
export const KEY_FOR_DEFAULT_INSTRUMENT_ID_TABLE_FILTERS = {
  [FX]: KEY_FOR_DEFAULT_FX_INSTRUMENT_ID_TABLE_FILTER,
  [ETF]: KEY_FOR_DEFAULT_ETF_INSTRUMENT_ID_TABLE_FILTER,
  [CFD]: KEY_FOR_DEFAULT_CFD_INSTRUMENT_ID_TABLE_FILTER,
};

export const DEFAULT_TABLE_ID = TRADE_TABLES.POSITIONS.ID;
export const DEFAULT_TABLE_INFO_ID = TRADE_INFO_TABLES.POSITIONS_DETAILS.ID;

export const OPTIONS_MANUAL_TRADE_TABLES = [
  { id: TRADE_TABLES.EXECUTIONS.ID, value: '約定照会' },
  { id: TRADE_TABLES.POSITIONS.ID, value: '建玉照会' },
  { id: TRADE_TABLES.ORDERS.ID, value: '注文照会' },
];

export const MAPPING_POSITION_SUMMARY_SWAP_PL = {
  [FX]: '未実現スワップ(円)',
  [ETF]: '未実現金利(円)',
  [CFD]: `未実現金利
  ・配当相当額(円)`,
};

// it's inner id, converted to boolean false/true
export const OPTIONS_ORDERS_TABLE_ACTIVE_ORDERS_FILTERING = [
  { id: 0, label: '全注文' },
  { id: 1, label: '注文中' },
];

export const POSITION_DETAILS_MODAL_STATUS = {
  positionDetails: 'positionDetails',
  closePosition: 'closePosition',
  confirmClosePosition: 'confirmClosePosition',
};

export const DISPLAYED_CURRENCY_PAIR_STATUS = '1';

export const MAPPING_TABLE_COLUMN_NAMES_MAIN = {
  INSTRUMENT_ID: {
    ID: 'instrumentId',
    LABEL: '銘柄',
  },
  TRADE_METHOD: {
    ID: 'tradeMethod',
    LABEL: '種類',
  },
  TRADE_PRICE: {
    ID: 'tradePrice',
    LABEL: '取引価格',
  },
  SIDE: {
    ID: 'side',
    LABEL: '売買',
  },
  IS_CLOSE: {
    ID: 'isClose',
    // web use different label
    LABEL: '新規/決済',
  },
  COMPOSITE_TYPE_NAME: {
    ID: 'compositeTypeName',
    LABEL: '注文種別',
  },
  TYPE: {
    ID: 'type',
    LABEL: '注文条件',
  },
  STATUS: {
    ID: 'status',
    LABEL: '注文状況',
  },
  QUANTITY: {
    ID: 'quantity',
    // web use different label
    LABEL: '数量',
  },
  PRICE: {
    ID: 'price',
    LABEL: '注文価格',
  },
  ORDER_TIME: {
    ID: 'orderTime',
    LABEL: '注文日時',
  },
  EXEC_TIME: {
    ID: 'execTime',
    LABEL: '約定日時',
  },
  SETTING_QUANTITY: {
    ID: 'settlingQuantity',
    LABEL: '注文中数量(万)',
  },
  SETTING_QUANTITY_ETF: {
    ID: 'settlingQuantity',
    LABEL: '注文中数量(口)',
  },
  SETTING_QUANTITY_CFD: {
    ID: 'settlingQuantity',
    LABEL: '注文中数量(Lot)',
  },
  PL: {
    ID: 'pl',
    LABEL: '評価損益(円)',
  },
  UNREALIZED_SWAP_PL: {
    ID: 'unrealizedSwapPl',
    LABEL: '未実現スワップ(円)',
  },
  UNREALIZED_INTEREST_PL: {
    ID: 'unrealizedSwapPl',
    LABEL: '未実現金利(円)',
  },
  UNREALIZED_INTEREST_PL_CFD: {
    ID: 'unrealizedSwapPl',
    LABEL: '未実現金利・配当相当額(円)',
  },
  EXPIRE_TIME: {
    // web use different id
    ID: {
      TYPE: 'expireType',
      TIME: 'expireTime',
    },
    LABEL: '期限',
  },
  AP_NAME: {
    ID: 'apName',
    LABEL: '注文名',
  },
  DIVIDEND: {
    ID: 'dividend',
    LABEL: {
      [ETF]: '分配相当額(円)',
      [CFD]: '配当相当額(円)',
    },
  },
};

export const SORT_BUTTON_EXECUTIONS_DATA = {
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.EXEC_TIME.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.EXEC_TIME.LABEL,
};

export const SORT_BUTTON_POSITIONS_DATA_FX = {
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.QUANTITY.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.QUANTITY.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.SETTING_QUANTITY.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.SETTING_QUANTITY.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.TRADE_PRICE.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.TRADE_PRICE.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.PL.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.PL.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.EXEC_TIME.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.EXEC_TIME.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.UNREALIZED_SWAP_PL.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.UNREALIZED_SWAP_PL.LABEL,
};

export const SORT_BUTTON_POSITIONS_DATA_ETF = {
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.QUANTITY.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.QUANTITY.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.SETTING_QUANTITY_ETF.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.SETTING_QUANTITY_ETF.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.TRADE_PRICE.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.TRADE_PRICE.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.PL.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.PL.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.DIVIDEND.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.DIVIDEND.LABEL[ETF],
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.EXEC_TIME.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.EXEC_TIME.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.UNREALIZED_INTEREST_PL.ID]:
    MAPPING_TABLE_COLUMN_NAMES_MAIN.UNREALIZED_INTEREST_PL.LABEL,
};

export const SORT_BUTTON_POSITIONS_DATA_CFD = {
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.QUANTITY.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.QUANTITY.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.SETTING_QUANTITY_CFD.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.SETTING_QUANTITY_CFD.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.TRADE_PRICE.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.TRADE_PRICE.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.PL.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.PL.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.EXEC_TIME.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.EXEC_TIME.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.UNREALIZED_INTEREST_PL.ID]:
    MAPPING_TABLE_COLUMN_NAMES_MAIN.UNREALIZED_INTEREST_PL_CFD.LABEL,
};

export const SORT_BUTTON_POSITIONS_DATA = {
  [FX]: SORT_BUTTON_POSITIONS_DATA_FX,
  [ETF]: SORT_BUTTON_POSITIONS_DATA_ETF,
  [CFD]: SORT_BUTTON_POSITIONS_DATA_CFD,
};

export const SORT_BUTTON_ORDERS_DATA = {
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.STATUS.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.STATUS.LABEL,
  [MAPPING_TABLE_COLUMN_NAMES_MAIN.ORDER_TIME.ID]: MAPPING_TABLE_COLUMN_NAMES_MAIN.ORDER_TIME.LABEL,
};

export const CLOSE_ORDER_LOGIC_VALUES = {
  PAYMENT_METHOD: 'paymentMethod',
  ORDER_METHOD: 'orderMethod',
  COUNT: 'count',
  CLOSE_PRICE: 'closePrice',
  CLOSE_LIMIT_PRICE: 'closeLimitPrice',
  CLOSE_STOP_PRICE: 'closeStopPrice',
  SETTLEMENT_EXPIRATION_TYPE: 'settlementExpirationType',
  DYNAMIC_QUANTITY_STEP: 'dynamicQuantityStep',
  SELECTED_DATE: 'selectedDate',
  SELECTED_TIME: 'selectedTime',
};

export const FULL_SCREEN_COLUMN_NAMES = {
  INSTRUMENT_ID: 'instrumentId',
  BID: 'bid',
  ASK: 'ask',
  SWAP: 'swap',
  HIGH: 'bidHigh',
  LOW: 'askLow',
  CLOSE_BID: 'bidClose',
  PREVIOUS_DAY: 'previousDay',
};

export const INTEGER_PRECISIONS = [1, 10];

export const DEFAULT_QUANTITY_MULTIPLIERS = {
  '1306.TKS/JPY': 10,
};

export const QUANTITY_PRECISION_KEEP = 10000;
export const DEFAULT_TRADING_RESOLUTION = 'D';

export const ALL_TRADE_METHODS = {
  [FX]: getTradeMethodOptionIds(TRADE_METHOD_FILTER_INITIAL_STATES[FX]),
  [ETF]: getTradeMethodOptionIds(TRADE_METHOD_FILTER_INITIAL_STATES[ETF]),
  [CFD]: getTradeMethodOptionIds(TRADE_METHOD_FILTER_INITIAL_STATES[CFD]),
};
