import { memo, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useUuid } from 'shared-modules/services/hooks';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './deleteIconButton.module.scss';

export const DeleteIconButton = memo(({ onClick, disabled }) => {
  const tooltipId = useUuid();
  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick?.();
    }
  }, [disabled, onClick]);
  const handleKeyDown = useKeyDownHandler(handleClick);
  return (
    <div className={styles.container} data-for={tooltipId} data-tip="削除">
      <i
        tabIndex={0}
        role="button"
        className={classNames('material-icons-outlined', styles.icon, { [styles.disabled]: disabled })}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        delete
      </i>
      <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid" multiline data-html />
    </div>
  );
});

DeleteIconButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

DeleteIconButton.defaultProps = {
  disabled: false,
  onClick: undefined,
};
