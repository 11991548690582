import React, { memo } from 'react';
import styles from '../steps.module.scss';

const Step3 = () => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <p>
          インジケーターとはチャートでテクニカル分析を行うための指標のことです。
          <br />
          テクニカルビルダーでは買い売りそれぞれ８種類のインジケーターから選択できます。
        </p>
        <p>
          <span className={styles.bold}>【買シグナル】</span>
          <br />
          ・単純移動平均線：短期が長期を下から上へ突き抜ける
          <br />
          ・ボリンジャーバンド：終値がボリンジャーバンドの+2σを下から上へ突き抜ける
          <br />
          ・一目均衡表：転換線が基準線を下から上へ突き抜ける
          <br />
          ・DMI：+DIが-DIを下から上へ突き抜ける
          <br />
          ・MACD：MACDラインがシグナルラインを下から上へ突き抜ける
          <br />
          ・RSI：RSIが30以下
          <br />
          ・ストキャスティクス：%Kが20%以下かつ%Kが%Dを上回る
          <br />
          ・モメンタム：0以下から0を超過する
        </p>
        <p>
          <span className={styles.bold}>【売シグナル】</span>
          <br />
          ・単純移動平均線：短期が長期を上から下へ突き抜ける
          <br />
          ・ボリンジャーバンド：終値がボリンジャーバンドの-2σを上から下へ突き抜ける
          <br />
          ・一目均衡表：転換線が基準線を上から下へ突き抜ける
          <br />
          ・DMI：+DIが-DIを上から下へ突き抜ける
          <br />
          ・MACD：MACDラインがシグナルラインを上から下へ突き抜ける
          <br />
          ・RSI：RSIが70以上
          <br />
          ・ストキャスティクス：%Kが80%以上かつ%Kが%Dを下回る
          <br />
          ・モメンタム：0以上から0未満になる
        </p>
      </div>
    </div>
  );
};

export default memo(Step3);
