import { CFD, ETF, FX } from 'shared-modules/constants';
import CustomFlag from './CustomFlag/CustomFlag';
import CustomFlagCFD from './CustomFlagCFD';
import CustomFlagETF from './CustomFlagETF';

export const CustomFlags = {
  [FX]: CustomFlag,
  [ETF]: CustomFlagETF,
  [CFD]: CustomFlagCFD,
};
