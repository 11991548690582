import { memo } from 'react';
import PropTypes from 'prop-types';
import { useUuid } from 'shared-modules/services/hooks';

export const GradientDefs = memo(({ patternId, color1, color2, color3, color4, size }) => {
  const uuid = useUuid();
  const gradientId1 = `${uuid}_1`;
  const gradientId2 = `${uuid}_2`;
  const gradientId3 = `${uuid}_3`;

  // サイズは暫定でちょうど良さそうな値にしているが、改善の余地あり
  const partitionSize = size / 3;
  const partitionSize1 = partitionSize * 0.9;
  const partitionSize2 = partitionSize * 1.3;
  const partitionSize3 = partitionSize * 0.8;

  return (
    <defs>
      <linearGradient id={gradientId1}>
        <stop offset="0%" stopColor={color1} />
        <stop offset="100%" stopColor={color2} />
      </linearGradient>
      <linearGradient id={gradientId2}>
        <stop offset="0%" stopColor={color2} />
        <stop offset="100%" stopColor={color3} />
      </linearGradient>
      <linearGradient id={gradientId3}>
        <stop offset="0%" stopColor={color3} />
        <stop offset="100%" stopColor={color4} />
      </linearGradient>
      <pattern id={patternId} x="0" y="0" width={size} height={size} patternUnits="userSpaceOnUse">
        <g>
          <rect
            shapeRendering="crispEdges"
            x="0"
            y="0"
            width={partitionSize1}
            height={size}
            fill={`url(#${gradientId1})`}
          />
          <rect
            shapeRendering="crispEdges"
            x={partitionSize1}
            y={0}
            width={partitionSize2}
            height={size}
            fill={`url(#${gradientId2})`}
          />
          <rect
            shapeRendering="crispEdges"
            x={partitionSize1 + partitionSize2}
            y={0}
            width={partitionSize3}
            height={size}
            fill={`url(#${gradientId3})`}
          />
        </g>
      </pattern>
    </defs>
  );
});

GradientDefs.propTypes = {
  patternId: PropTypes.string.isRequired,
  color1: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired,
  color3: PropTypes.string.isRequired,
  color4: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};
