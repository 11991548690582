import React, { memo, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  CFD,
  ETF,
  EXECUTIONS_EMPTY_MESSAGE,
  EXECUTIONS_EMPTY_MESSAGE_WRONG_PERIOD,
  FX,
} from 'shared-modules/constants';
import {
  DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
  SORT_DESCENDING,
  SORT_ASCENDING,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR,
} from 'shared-modules/constants/manualTrade';
import {
  isMoreThanOneYearAgo,
  saveDefaultValuesFromLocalStorage,
  saveJsonStyleDefaultValuesToLocalStorage,
} from 'shared-modules/services';
import {
  changeExecutionsTableMetaInfo,
  getExecutionsTableDataRequest,
} from 'shared-modules/redux/actions/manualTradeActions';

import PropTypes from 'prop-types';
import styles from '../Table/table.module.scss';
import { Table } from '../Table';
import { getExecutionsTableTemplate } from '../../services/tableTemplate';

const DEFAULT_SORTING = [
  {
    id: DEFAULT_EXECUTIONS_TABLE_SORT_BY,
    desc: DEFAULT_EXECUTIONS_TABLE_SORT_DIR === SORT_DESCENDING,
  },
];

const RESIZED = {
  [FX]: {
    KEY: 'manualTradeTradeFXHistory',
    COLUMNS: [101, 60, 86, 40, 40, 80, 80, 80, 80, 110],
  },
  [ETF]: {
    KEY: 'manualTradeTradeETFHistory',
    COLUMNS: [101, 60, 86, 40, 40, 80, 80, 80, 80, 110],
  },
  [CFD]: {
    KEY: 'manualTradeTradeCFDHistory',
    COLUMNS: [101, 60, 86, 40, 40, 80, 80, 80, 80, 110],
  },
};

const TableExecutions = ({ isManual }) => {
  const dispatch = useDispatch();

  const serviceId = useSelector((state) => state.auth.serviceId);
  const tableData = useSelector((state) => state.manualTrade.executionsData[serviceId]);
  const tableMetaInfo = useSelector((state) => state.manualTrade.executionsDataMetaInfo[serviceId]);

  const emptyText = useMemo(() => {
    if (isMoreThanOneYearAgo(tableMetaInfo.fromDate)) {
      return EXECUTIONS_EMPTY_MESSAGE_WRONG_PERIOD;
    }
    return EXECUTIONS_EMPTY_MESSAGE;
  }, [tableMetaInfo]);

  useEffect(() => {
    dispatch(getExecutionsTableDataRequest({ isFirstData: true, isManual }));
  }, [dispatch, isManual, serviceId]);

  const getAnotherPartOfData = useCallback(() => {
    dispatch(getExecutionsTableDataRequest({ isFirstData: false, isManual }));
  }, [dispatch, isManual]);

  const columns = useMemo(() => getExecutionsTableTemplate(serviceId, isManual), [isManual, serviceId]);

  const resized = useMemo(() => {
    const { KEY, COLUMNS } = RESIZED[serviceId];
    return {
      key: KEY,
      default: [...COLUMNS],
    };
  }, [serviceId]);

  const defaultSorting = useMemo(() => {
    if (tableMetaInfo.sortBy == null || tableMetaInfo.sortDir == null) {
      return DEFAULT_SORTING;
    }
    return [{ id: tableMetaInfo.sortBy, desc: tableMetaInfo.sortDir === SORT_DESCENDING }];
  }, [tableMetaInfo]);

  const filterHandler = useCallback(
    ({ key, value }) => {
      if (['isClose', 'side', 'tradeMethods', 'instrumentId'].includes(key)) {
        dispatch(changeExecutionsTableMetaInfo({ key, value, serviceId }));
      }
      dispatch(getExecutionsTableDataRequest({ isFirstData: true, isManual }));
      const localStorageKey = KEY_FOR_DEFAULT_EXECUTIONS_TABLE_FILTERING_VALUES[serviceId];
      saveJsonStyleDefaultValuesToLocalStorage({ key, value, localStorageKey });
    },
    [dispatch, isManual, serviceId],
  );

  const sortingHandler = useCallback(
    ({ id: sortBy, desc: isDesc }) => {
      const sortDir = isDesc ? SORT_DESCENDING : SORT_ASCENDING;
      const { sortBy: previousSortBy, sortDir: previousSortDir } = tableMetaInfo;
      if (sortBy === previousSortBy && sortDir === previousSortDir) {
        return;
      }

      batch(() => {
        dispatch(changeExecutionsTableMetaInfo({ key: 'sortBy', value: sortBy, serviceId }));
        dispatch(changeExecutionsTableMetaInfo({ key: 'sortDir', value: sortDir, serviceId }));
        dispatch(getExecutionsTableDataRequest({ isFirstData: true, isManual }));
      });

      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_BY[serviceId],
        value: sortBy,
      });
      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_EXECUTIONS_TABLE_SORT_DIR[serviceId],
        value: sortDir,
      });
    },
    [tableMetaInfo, serviceId, dispatch, isManual],
  );

  return (
    <>
      <Table
        key={serviceId}
        tableData={tableData}
        columns={columns}
        resized={resized}
        emptyText={emptyText}
        className={styles.table}
        tableMetaInfo={tableMetaInfo}
        paginationCallback={getAnotherPartOfData}
        defaultSorting={defaultSorting}
        useServerSorting
        sortingHandler={sortingHandler}
        filterHandler={filterHandler}
      />
    </>
  );
};

TableExecutions.propTypes = {
  isManual: PropTypes.bool,
};

TableExecutions.defaultProps = {
  isManual: false,
};

export default memo(TableExecutions);
