import { memo } from 'react';
import { useExecutionTableDataLab } from 'shared-modules/hooks/select';
import { CommonTable } from './CommonTable';

export const LabTable = memo(() => {
  const { simulationTradesData, autoTradeSettingsData, helpers, loading } = useExecutionTableDataLab();
  return (
    <CommonTable data={simulationTradesData} settingsData={autoTradeSettingsData} helpers={helpers} loading={loading} />
  );
});
