import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import Modal from '../../../../components/Modal';
import CustomButton from '../../../../components/CustomButton';
import styles from './builderWarningInfo.module.scss';

const BUTTON_WIDTH = 312;

const BuilderWarningInfo = ({ isOpen, closeModal }) => (
  <Modal size={MODAL_SIZES.WIDTH_640} isOpen={isOpen} closeModal={closeModal} title="注意事項">
    <div className={styles.bodyWrapper}>
      <div className={styles.text}>
        {/* eslint-disable-next-line max-len */}
        本機能は、過去のFX・CFD・ETFの1分足レートをもとにスプレッド、スワップポイント(FX)、手数料(FX)、金利・配当相当額(CFD)、金利・分配相当額(ETF)を加味した数値でバックテストを行った結果を表示しています。将来の結果を保証するものではありませんのでご注意ください。また、バックテストは実際の取引と異なります。
      </div>
      <div className={styles.text}>
        {/* eslint-disable-next-line max-len */}
        「ビルダー」のシミュレーションにおいて、エントリー価格を「前日終値」からの値幅で作成された場合、エントリー価格は「指定したシミュレーション期間の始値」を使用して計算します。そのため、実際に発注する価格はシミュレーションとは異なりますので、ご注意ください。
        <div>
          {/* eslint-disable-next-line max-len */}
          エントリー価格を「価格指定」で作成された場合、実際に発注する注文タイプは、その時のレートをもとに指値・逆指値を判断します。そのため、実際に発注する価格はシミュレーションとは異なりますので、ご注意ください。
        </div>
      </div>
      <CustomButton width={BUTTON_WIDTH} onClick={closeModal} className={styles.button}>
        閉じる
      </CustomButton>
    </div>
  </Modal>
);

BuilderWarningInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(BuilderWarningInfo);
