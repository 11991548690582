import React, { memo } from 'react';
import styles from '../steps.module.scss';

const Step5 = () => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <div>
          <p>
            選択したテクニカル指標に基づきシグナルが発信されると、シグナルが発生した時点の終値を挟んで、レンジ幅の範囲内に自動売買注文が作成されます。
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(Step5);
