import Decimal from 'decimal.js';
import { CHART_MAKE_BUY_SELL_MAIN, ETF, ZONE_PROTECTOR } from '../../../../constants';
import { ceil, getBaseQuantity, getSignificantDigits, isJPY } from '../../constants';
import Logic from './Logic';
import { Range, RANGE_POSITION } from './Range';

const EtfZoneProtector = (instrumentId, inputHigh, inputLow, instrumentSetting) => {
  const digits = getSignificantDigits(ETF, instrumentId, instrumentSetting?.pricePrecision);
  const high = ceil(inputHigh, digits);
  const low = ceil(inputLow, digits);
  const logic = Logic(ZONE_PROTECTOR, high, low);
  const quantity = getBaseQuantity(ETF, instrumentId);
  const range = Range(
    instrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.BUY,
    high,
    low,
    quantity,
    RANGE_POSITION.ISOLATION_BOTTOM,
  );
  let protectLine = 0;
  let estLossAmountTotal = 0;
  return {
    backupDefaultSettings() {
      range.backupDefaultSettings();
    },
    calcAndSetRangeSettings(step) {
      // Stepでレンジの幅、その幅から本数を計算
      this.calcAndSetWidth(step);
      this.calcAndSetNumOfOrders();
    },
    ajustMaxMin(max) {
      range.setMax(ceil(max, digits));
      range.ajustMin();
    },
    calcAndSetWidth(step) {
      range.setWidth(ceil(Decimal.mul(high, Decimal.add(0.005, step)), digits));
    },
    calcAndSetNumOfOrders() {
      range.calcAndSetNumOfOrders();
    },
    calcAndSetProtectLine() {
      protectLine = ceil(Decimal.mul(range.getMin(), 0.9), digits);
    },
    getNumOfOrders() {
      return range.getNumOfOrders();
    },
    backupCurrentSettings() {
      range.backupCurrentSettings();
    },
    rollbackSettings() {
      range.rollbackSettings();
      this.calcAndSetProtectLine();
    },
    getEstOperatingFunds(marginRequiredTotal, yenConvRate) {
      return ceil(Decimal.sub(marginRequiredTotal, Decimal.mul(estLossAmountTotal, yenConvRate || 1)), 0);
    },
    calcAndSetTp() {
      let sp = 0.2;
      if (isJPY(instrumentId)) {
        sp = 10;
      }
      const tp = [];
      tp.push(Math.min(ceil(Math.max(Decimal.mul(high, 0.006), Decimal.mul(sp, 2)), digits), range.getRangeWidth()));
      tp.push(Math.min(ceil(Math.max(Decimal.mul(high, 0.0085), Decimal.mul(sp, 3)), digits), range.getRangeWidth()));
      tp.push(Math.min(ceil(Decimal.mul(tp[1], 2), digits), range.getRangeWidth()));
      range.setTp(tp);
    },
    getAllRanges() {
      return [range];
    },
    getProtectLine() {
      return protectLine;
    },
    ...logic,
    calcAfterFixedWidthProcess() {
      this.ajustMaxMin(high);
      this.calcAndSetProtectLine();
    },
    createUnsortedAps() {
      const area1 = {
        max: range.getMax(),
        min: Decimal.sub(range.getMax(), Decimal.mul(Decimal.sub(range.getMax(), range.getMin()), Decimal.div(1, 3))),
      };
      const area2 = {
        max: area1.min,
        min: Decimal.sub(range.getMax(), Decimal.mul(Decimal.sub(range.getMax(), range.getMin()), Decimal.div(2, 3))),
      };
      const area3 = {
        max: area2.min,
        min: Decimal.sub(range.getMax(), Decimal.sub(range.getMax(), range.getMin())),
      };
      const zoneAps = range.createAps();
      estLossAmountTotal = 0;
      const result = zoneAps.map((x) => {
        const item = { ...x };
        if (area2.max >= item.entryPrice1 && area2.min < item.entryPrice1) {
          item.quantity = Decimal.mul(item.quantity, 2).toNumber();
        } else if (area3.max >= item.entryPrice1) {
          item.quantity = Decimal.mul(item.quantity, 3).toNumber();
        }
        return item;
      });
      result.forEach((x) => {
        estLossAmountTotal = Decimal.add(
          estLossAmountTotal,
          Decimal.mul(Decimal.sub(protectLine, x.entryPrice1), x.quantity),
        ).toNumber();
      });
      return result;
    },
  };
};

export default EtfZoneProtector;
