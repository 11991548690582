import { memo, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { formatPrice } from 'shared-modules/services';
import { isDisplayedNumber } from 'shared-modules/utils';
import styles from './priceText.module.scss';

export const PriceText = memo(({ value, style, className, symbolClassName, withoutSymbol, allowZero }) => {
  const [price, isEmpty] = useMemo(() => {
    if (!isDisplayedNumber(value)) {
      return ['-', true];
    }
    return formatPrice({ value, allowZero });
  }, [value, allowZero]);

  return (
    <div style={style} className={classNames(styles.text, className)}>
      {price}
      {!isEmpty && !withoutSymbol && (
        <span style={style} className={classNames(styles.symbol, symbolClassName)}>
          円
        </span>
      )}
    </div>
  );
});

PriceText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.shape({}),
  className: PropTypes.string,
  symbolClassName: PropTypes.string,
  withoutSymbol: PropTypes.bool,
  allowZero: PropTypes.bool,
};

PriceText.defaultProps = {
  value: undefined,
  style: undefined,
  className: undefined,
  symbolClassName: undefined,
  withoutSymbol: false,
  allowZero: false,
};
