import { DEFAULT_TABLE_ID } from 'shared-modules/constants/portfolio';
import { CHANGE_SELECTED_PORTFOLIO_TABLE } from '../actionConstants/portfolioConstants';

const initialState = {
  selectedTable: DEFAULT_TABLE_ID,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_SELECTED_PORTFOLIO_TABLE: {
      const { tableId } = payload;
      return {
        ...state,
        selectedTable: tableId,
      };
    }
    default: {
      return state;
    }
  }
};
