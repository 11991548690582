import { createAdditionalParams, invokeApi, get, post } from './index';

// todo: move all trading api to this file
export const executeCurrentCartItems = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  const requestBody = { cart: true };

  return invokeApi({}, `${serviceId}/ap_groups`, post, additionalParams, requestBody);
};

export const getReportsList = async ({ type, startDate, endDate, serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi(
    {},
    `${serviceId}/reports?type=${type}&startDate=${startDate}&endDate=${endDate}`,
    get,
    additionalParams,
  );
};

export const getReportFile = async ({ type, reportId, startTime, endTime, serviceId }) => {
  const additionalParams = await createAdditionalParams();
  let requestQuery = `type=${type}`;
  if (reportId) {
    requestQuery += `&reportId=${reportId}`;
  }
  if (startTime) {
    requestQuery += `&startTime=${startTime}`;
  }
  if (endTime) {
    requestQuery += `&endTime=${endTime}`;
  }

  return invokeApi({}, `${serviceId}/reports/download?${requestQuery}`, get, additionalParams);
};
