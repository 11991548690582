import { connect } from 'react-redux';
import { getUserSSOMoneyHatchRequest } from '../../redux/actions';
import SSOSubmissionPage from '../../components/SSOSubmissionPage/SSOSubmissionPage';

const wrapper = connect(
  (state) => ({ ssoState: state.userSSO.moneyHatch, formId: 'ssoMoneyHatchForm' }),
  (dispatch) => ({
    refreshSsoState() {
      dispatch(getUserSSOMoneyHatchRequest());
    },
  }),
);

export default wrapper(SSOSubmissionPage);
