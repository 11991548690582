/* eslint-disable-next-line import/no-unresolved */
import { createElement, useMemo } from 'react';
import { LABEL_DOING_WELL, LABEL_LESS_TRADING, PERIOD, STYLE } from '../constants';
import { isDoingWell, isLessTrading } from '../utils';

const makeConvertMap = (def) =>
  Object.keys(def).reduce((obj, key) => ({ ...obj, [def[key].value]: def[key].label }), {});

const styleConvertMap = makeConvertMap(STYLE);

const periodConvertMap = makeConvertMap(PERIOD);

export const useFeatureTags = (FeatureTag, { styleValue, periodValue, recentRoi, closeTradeCount }) => {
  return useMemo(() => {
    const tags = [];
    if (styleValue != null && periodValue !== '') {
      const styleLabel = styleConvertMap[styleValue];
      if (styleLabel) {
        tags.push(createElement(FeatureTag, { key: 0, backgroundColor: '#85ac39', label: styleLabel }));
      }
    }
    if (periodValue != null && periodValue !== '') {
      const periodLabel = periodConvertMap[periodValue];
      if (periodLabel) {
        tags.push(createElement(FeatureTag, { key: 1, backgroundColor: '#ac5d39', label: periodLabel }));
      }
    }
    if (isDoingWell(recentRoi)) {
      tags.push(createElement(FeatureTag, { key: 3, backgroundColor: '#395cac', label: LABEL_DOING_WELL }));
    }
    if (closeTradeCount != null && isLessTrading(closeTradeCount)) {
      tags.push(createElement(FeatureTag, { key: 4, backgroundColor: '#ac3946', label: LABEL_LESS_TRADING }));
    }
    return tags;
  }, [FeatureTag, styleValue, periodValue, recentRoi, closeTradeCount]);
};
