import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRangeOutKeySet, useServiceId } from 'shared-modules/hooks';
import { AP_GROUP_ORDER, AP_GROUP_SOURCES, TRADE_TYPES } from 'shared-modules/constants';
import CustomCurrencyIcon from '../CustomCurrencyIcon/index';
import SelectionImage from '../SelectionImage/index';
import { Tag } from '../Tag';
import { RangeOutIcon, StatusIcon } from '../Icons';
import styles from './cardHeader.module.scss';

const CardHeader = ({
  displayedType,
  image,
  currency,
  activeCount,
  serviceIds,
  childNum,
  status,
  type,
  apGroupId,
  childApGroups,
  sourceType,
  isShare,
}) => {
  // currency が - (グループ) の場合、serviceId は nullish
  const serviceId = useServiceId(currency);
  const rangeOutKeySet = useRangeOutKeySet();

  const isRangeOut = useMemo(() => {
    if (childApGroups == null || childApGroups.length === 0) {
      return rangeOutKeySet.has(`${serviceId}_${apGroupId}`);
    }
    // グループの場合は全てレンジアウトであるかを判定
    return childApGroups
      .map((apGroup) => `${apGroup.serviceId}_${apGroup.id}`)
      .every((apGroupKey) => rangeOutKeySet.has(apGroupKey));
  }, [apGroupId, childApGroups, serviceId, rangeOutKeySet]);

  const isApGroup = type === TRADE_TYPES.AUTO.NAME;
  const active = activeCount > 0 || String(status) === AP_GROUP_ORDER.ACTIVITY.ACTIVE.ID;

  const icon = useMemo(() => {
    if (!isApGroup) {
      return null;
    }
    if (!active) {
      return <StatusIcon className={styles.status} active={active} />;
    }
    if (isRangeOut) {
      return <RangeOutIcon className={styles.rangeOut} />;
    }
    return null;
  }, [isApGroup, isRangeOut, active]);

  return (
    <div className={styles.grid}>
      <div className={styles.tags}>
        {serviceIds ? (
          serviceIds.map((sid) => <Tag key={sid} text={sid} className={styles.tag} />)
        ) : (
          <Tag text={serviceId} className={styles.tag} />
        )}

        <div className={styles.roundBadge}>{displayedType}</div>
      </div>
      <div className={styles.imageArea}>
        {serviceIds ? (
          <SelectionImage
            image={image}
            className={styles.currencyPairIcon}
            width="80px"
            isShare={isShare}
            serviceId={serviceId}
          />
        ) : (
          <CustomCurrencyIcon currencyPair={currency} className={styles.currencyPairIcon} />
        )}
        {icon}
      </div>
      <div className={styles.groupIcon}>
        {Number.isFinite(childNum) && (
          <>
            {((childNum > 1 && sourceType !== AP_GROUP_SOURCES.TECH.KEY) ||
              (childNum >= 1 && sourceType === AP_GROUP_SOURCES.TECH.KEY)) && (
              <i className="material-icons-outlined">{`filter_${childNum <= 9 ? childNum : '9_plus'}`}</i>
            )}
          </>
        )}
      </div>
    </div>
  );
};

CardHeader.propTypes = {
  displayedType: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  activeCount: PropTypes.number,
  serviceIds: PropTypes.arrayOf(PropTypes.string),
  childNum: PropTypes.number,
  status: PropTypes.string,
  type: PropTypes.string,
  apGroupId: PropTypes.string,
  childApGroups: PropTypes.arrayOf(PropTypes.shape({})),
  sourceType: PropTypes.string,
  isShare: PropTypes.bool,
};

CardHeader.defaultProps = {
  activeCount: 0,
  serviceIds: null,
  childNum: undefined,
  status: null,
  type: null,
  apGroupId: undefined,
  childApGroups: undefined,
  sourceType: undefined,
  isShare: false,
};

export default CardHeader;
