import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js';
import { v4 as uuid } from 'uuid';
import ReactTooltip from 'react-tooltip';
import { WIDTH } from 'shared-modules/constants';
import { numberExists } from 'shared-modules/utils';
import { setNumberInputValue } from 'shared-modules/services';
import styles from './inputNumber.module.scss';

const DEFAULT_NUMBER_INPUT_WIDTH = 100;
const ONLY_INTEGER_REG = /^\d$/;

const resolveStep = (step, inputValue, isIncrement = true) =>
  typeof step === 'function' ? step(inputValue, isIncrement) : step;

export const InputNumber = memo(
  ({
    className,
    value,
    width,
    onChange,
    disabled,
    name,
    errorMessages,
    validateFunction,
    min,
    max,
    step,
    withErrorTooltip,
    onBlurSendRequest,
    onlyIntegerAllowed,
    validateNegativeValues,
    disabledInput,
  }) => {
    const [error, changeError] = useState(null);
    const id = useRef(uuid()).current;
    const localValueRef = useRef(value);
    const intervalIdRef = useRef(null);
    const timeoutIdRef = useRef(null);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
      changeError((errorMessages ?? []).find((errorItem) => errorItem.inputName === name));
    }, [errorMessages, changeError, name]);

    const elementWidth = width || DEFAULT_NUMBER_INPUT_WIDTH;

    const handleSendRequest = useCallback(
      ({ target }) => {
        const validateResult = validateFunction(target.value);

        if (validateResult) {
          onBlurSendRequest(target.value);
        }
      },
      [onBlurSendRequest, validateFunction],
    );

    const handleFocus = useCallback(() => {
      setFocused(true);
    }, []);

    const handleBlur = useCallback(
      (e) => {
        setFocused(false);
        handleSendRequest(e);
      },
      [handleSendRequest],
    );

    const handleChange = useCallback(
      (event) => {
        setNumberInputValue({
          value: event.target.value,
          min,
          max,
          validate: validateFunction,
          allowDecimal: !onlyIntegerAllowed,
          allowNegative: validateNegativeValues,
          setValue: onChange,
        });
      },
      [onChange, validateFunction, max, min, validateNegativeValues, onlyIntegerAllowed],
    );

    const incrementValue = useCallback(() => {
      const localValue = localValueRef.current;
      const isNumberExist = numberExists(localValue);
      const prevValue = isNumberExist ? localValue : 0;
      const resolvedStep = resolveStep(step, localValue, true);
      const newValue = Decimal.add(Number(prevValue), resolvedStep).toNumber();
      const result = numberExists(max) && newValue > max ? max : newValue;
      const validateResult = validateFunction(result);
      localValueRef.current = result;
      onChange(result);
      if (validateResult) {
        onBlurSendRequest(result);
      }
    }, [onChange, validateFunction, max, step, onBlurSendRequest]);

    const decrementValue = useCallback(() => {
      const localValue = localValueRef.current;
      const isNumberExist = numberExists(localValue);
      const prevValue = isNumberExist ? localValue : 0;
      const resolvedStep = resolveStep(step, localValue, false);
      const newValue = Decimal.sub(Number(prevValue), resolvedStep).toNumber();
      const result = numberExists(min) && newValue < min ? min : newValue;
      const validateResult = validateFunction(result);
      localValueRef.current = result;
      onChange(result);
      if (validateResult) {
        onBlurSendRequest(result);
      }
    }, [onChange, validateFunction, min, step, onBlurSendRequest]);

    const stopCount = useCallback(() => {
      // clear the initial timer to prevent counting triggered by short time press
      if (timeoutIdRef.current != null) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
      // clear the loop timer to stop counting
      if (intervalIdRef.current != null) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    }, []);

    const handleMouseDownIncrement = useCallback(() => {
      stopCount();
      localValueRef.current = value;
      // wait 300 milliseconds before entering a long-press counting loop
      timeoutIdRef.current = setTimeout(() => {
        // start counting
        intervalIdRef.current = setInterval(() => {
          incrementValue();
        }, 200);
      }, 300);
    }, [incrementValue, value, stopCount]);

    const handleMouseDownDecrement = useCallback(() => {
      stopCount();
      localValueRef.current = value;
      // wait 300 milliseconds before entering a long-press counting loop
      timeoutIdRef.current = setTimeout(() => {
        // start counting
        intervalIdRef.current = setInterval(() => {
          decrementValue();
        }, 200);
      }, 300);
    }, [decrementValue, value, stopCount]);

    const handleKeyDown = useCallback(
      (e) => {
        if (e.key === 'Enter') {
          const validateResult = validateFunction(value);
          if (validateResult) {
            onBlurSendRequest(value);
          }
        }
        localValueRef.current = value;
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          incrementValue();
        }
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          decrementValue();
        }
        // Necessary for manual input, otherwise caret will move to the end after change fails
        if (onlyIntegerAllowed && e.key.length === 1 && !ONLY_INTEGER_REG.test(e.key)) {
          e.preventDefault();
        }
      },
      [incrementValue, decrementValue, validateFunction, onBlurSendRequest, value, onlyIntegerAllowed],
    );

    useEffect(() => {
      return () => {
        stopCount();
      };
    }, [stopCount]);

    const handleClass = classNames(styles.handle, { [styles.disabled]: disabled });
    return (
      <div
        className={classNames(styles.outerWrapper, className, {
          [styles.focused]: focused,
          [styles.isError]: error,
          [styles.disabled]: disabled,
        })}
      >
        <div
          aria-hidden
          className={handleClass}
          onClick={decrementValue}
          onMouseDown={handleMouseDownDecrement}
          onMouseUp={stopCount}
          onMouseOut={stopCount}
          onContextMenu={stopCount}
          onBlur={stopCount}
        >
          <i className="material-icons">remove</i>
        </div>
        <div className={styles.wrapper} style={{ width: elementWidth }}>
          <input
            title=""
            type="text"
            autoComplete="new-password"
            value={value}
            className={classNames(styles.input, styles.isNumberInput, {
              [styles.disabled]: disabled,
            })}
            disabled={disabled || disabledInput}
            style={{ width: elementWidth }}
            min={min}
            max={max}
            step={resolveStep(step)}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            data-for={id}
            data-tip={error && error.errorMessage}
          />
        </div>
        <div
          aria-hidden
          className={handleClass}
          onClick={incrementValue}
          onMouseDown={handleMouseDownIncrement}
          onMouseUp={stopCount}
          onMouseOut={stopCount}
          onContextMenu={stopCount}
          onBlur={stopCount}
        >
          <i className="material-icons">add</i>
        </div>
        {withErrorTooltip && error && error.errorMessage && (
          <ReactTooltip
            id={id}
            place="bottom"
            type="dark"
            effect="solid"
            multiline
            data-html
            className={styles.tooltip}
          />
        )}
      </div>
    );
  },
);

InputNumber.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  errorMessages: PropTypes.arrayOf(PropTypes.shape({})),
  validateFunction: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([WIDTH.PERCENTAGE_100, ''])]),
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.func, // (value: number, isIncrement: bool) => number
  ]),
  withErrorTooltip: PropTypes.bool,
  onBlurSendRequest: PropTypes.func,
  onlyIntegerAllowed: PropTypes.bool,
  validateNegativeValues: PropTypes.bool,
  disabledInput: PropTypes.bool,
};

InputNumber.defaultProps = {
  className: undefined,
  onChange: undefined,
  name: undefined,
  errorMessages: undefined,
  validateFunction: () => {},
  width: undefined,
  disabled: false,
  min: 0,
  max: undefined,
  step: 1,
  withErrorTooltip: false,
  onBlurSendRequest: () => {},
  onlyIntegerAllowed: false,
  validateNegativeValues: false,
  disabledInput: false,
};
