import { memo, useCallback, useState } from 'react';
import { CART_TABS, CART_TITLE } from 'shared-modules/constants/cart';
import { useChangeCartType } from 'shared-modules/services/hooks/cartLogic';
import { Tabs } from '../../../../components';
import { CurrentCart } from '../CurrentCart';
import { SavedCart } from '../SavedCart';
import styles from './sideMenu.module.scss';

const TAB_ITEMS = [
  { id: CART_TABS.CURRENT.ID, value: CART_TABS.CURRENT.NAME, children: <CurrentCart /> },
  { id: CART_TABS.SAVED.ID, value: CART_TABS.SAVED.NAME, children: <SavedCart /> },
];

export const SideMenu = memo(() => {
  const [tabWidth, setTabWidth] = useState(354); // ../cart.module.scss
  const { activeTab, changeActiveTab } = useChangeCartType();
  const handleResize = useCallback(({ width }) => {
    const length = TAB_ITEMS.length ?? 0;
    if (length > 0) {
      setTabWidth((width ?? 0) / length);
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.title}>{CART_TITLE}</div>
      <Tabs
        type="line"
        tabMinWidth={tabWidth}
        scrollWidth={tabWidth}
        items={TAB_ITEMS}
        activeKey={activeTab}
        onChange={changeActiveTab}
        onReady={handleResize}
        onResize={handleResize}
      />
    </div>
  );
});
