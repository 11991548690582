import Decimal from 'decimal.js';
import { CHART_MAKE_BUY_SELL_MAIN, ETF, PROTECTOR } from '../../../../constants';
import { ceil, getBaseQuantity, getSignificantDigits, isJPY } from '../../constants';
import Logic from './Logic';
import { Range, RANGE_POSITION } from './Range';

const EtfProtector = (instrumentId, inputHigh, inputLow, instrumentSetting) => {
  const digits = getSignificantDigits(ETF, instrumentId, instrumentSetting?.pricePrecision);
  const high = ceil(inputHigh, digits);
  const low = ceil(inputLow, digits);
  const logic = Logic(PROTECTOR, high, low);
  const quantity = getBaseQuantity(ETF, instrumentId);
  const range = Range(
    instrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.BUY,
    high,
    low,
    quantity,
    RANGE_POSITION.ISOLATION_BOTTOM,
  );
  let protectLine = 0;
  let estLossAmountTotal = 0;

  return {
    backupDefaultSettings() {
      range.backupDefaultSettings();
    },
    calcAndSetRangeSettings(step) {
      // Stepでレンジの幅、その幅から本数を計算
      this.calcAndSetWidth(step);
      this.calcAndSetNumOfOrders();
    },
    ajustMaxMin(max) {
      range.setMax(ceil(max, digits));
      range.ajustMin();
    },
    calcAndSetWidth(step) {
      range.setWidth(ceil(Decimal.mul(high, Decimal.add(0.005, step)), digits));
    },
    calcAndSetNumOfOrders() {
      range.calcAndSetNumOfOrders();
    },
    calcAndSetProtectLine() {
      protectLine = ceil(Decimal.mul(range.getMin(), 0.9), digits);
    },
    getNumOfOrders() {
      return range.getNumOfOrders();
    },
    backupCurrentSettings() {
      range.backupCurrentSettings();
    },
    rollbackSettings() {
      range.rollbackSettings();
      this.calcAndSetProtectLine();
    },
    getEstOperatingFunds(marginRequiredTotal, yenConvRate) {
      return ceil(Decimal.sub(marginRequiredTotal, Decimal.mul(estLossAmountTotal, yenConvRate || 1)), 0);
    },
    calcAndSetTp() {
      let sp = 0.2;
      if (isJPY(instrumentId)) {
        sp = 10;
      }
      const tp = [];
      tp.push(Math.min(ceil(Math.max(Decimal.mul(high, 0.006), Decimal.mul(sp, 2)), digits), range.getRangeWidth()));
      tp.push(Math.min(ceil(Math.max(Decimal.mul(high, 0.0085), Decimal.mul(sp, 3)), digits), range.getRangeWidth()));
      tp.push(Math.min(ceil(Decimal.mul(tp[1], 2), digits), range.getRangeWidth()));
      range.setTp(tp);
    },
    getAllRanges() {
      return [range];
    },
    getProtectLine() {
      return protectLine;
    },
    createUnsortedAps() {
      estLossAmountTotal = 0;
      const result = range.createAps();
      result.forEach((x) => {
        estLossAmountTotal = Decimal.add(
          estLossAmountTotal,
          Decimal.mul(Decimal.sub(protectLine, x.entryPrice1), x.quantity),
        ).toNumber();
      });
      return result;
    },
    ...logic,
    calcAfterFixedWidthProcess() {
      range.ajustMin();
      this.calcAndSetProtectLine();
    },
  };
};

export default EtfProtector;
