import { put, delay, select, all } from 'redux-saga/effects';
import { CHANNEL_BROWSER, CHANNEL_MOBILE_APP } from '../../../constants';
import { DELAY_FOR_UPDATE_MESSAGE } from '../../../constants/apiConstant';
import { TAB_INDIVIDUAL_NOTICE } from '../../../constants/message';
import Logger from '../../../services/Logger';
import { checkIsWebApp } from '../../../services';
import {
  checkInitialDepositRequest,
  getRangeOutsRequest,
  getUnreadCountRequest,
  refreshMessagesRequest,
} from '../../actions/messageActions';

export function* messageListenerHandler() {
  try {
    const channel = checkIsWebApp() ? CHANNEL_BROWSER : CHANNEL_MOBILE_APP;
    const getUnreadCountRequestPayload = { channel };
    try {
      yield all([
        put(getRangeOutsRequest()),
        put(checkInitialDepositRequest()),
        put(getUnreadCountRequest(getUnreadCountRequestPayload)),
      ]);
    } catch (e) {
      // エラーが発生しても処理は継続
      Logger.error(e);
    }
    while (true) {
      try {
        while (true) {
          yield delay(DELAY_FOR_UPDATE_MESSAGE);
          const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
          const hasError = yield select((state) => state.socket.hasError);
          if (!isAuthenticated || hasError) {
            break;
          }
          yield put(getUnreadCountRequest(getUnreadCountRequestPayload));
          const autoRefresh = yield select((state) => state.message.autoRefresh);
          if (autoRefresh) {
            const currentTab = yield select((state) => state.message.currentTab);
            const isIndividual = currentTab === TAB_INDIVIDUAL_NOTICE;
            yield put(refreshMessagesRequest({ isIndividual, channel }));
          }
        }
      } catch (e) {
        // エラーが発生しても処理は継続
        Logger.error(e);
      }
    }
  } catch (e) {
    Logger.error(e);
  }
}
