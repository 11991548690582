import { memo, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useValueToColor } from '../../../hooks';
import styles from './rankText.module.scss';

const RANKS = ['-', 'Info', 'Low', 'Medium', 'High', 'Critical'];

export const RankText = memo(({ value, className }) => {
  const { getRankColor } = useValueToColor();
  const style = useMemo(() => ({ color: getRankColor(value) }), [value, getRankColor]);
  return (
    <div style={style} className={classNames(styles.text, className)}>
      {RANKS[value]}
    </div>
  );
});

RankText.propTypes = {
  value: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  className: PropTypes.string,
};

RankText.defaultProps = {
  value: undefined,
  className: undefined,
};
