// eslint-disable-next-line import/no-unresolved
import { useCallback, useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { useAccountInfo } from './useAccountInfo';
import { getMargin } from '../api/portfolioApi';
import { ALERT_RATE, CFD, ETF, FX, ONE_HUNDRED } from '../constants';

export const useCategory = () => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const accountInfo = useAccountInfo();
  const getMarginData = useCallback(async () => {
    let alertFlg = false;
    if (isAuth) {
      const fxResponse = accountInfo[FX].isNotAvailable ? null : await getMargin(FX);
      const etfResponse = accountInfo[ETF].isNotAvailable ? null : await getMargin(ETF);
      const cfdResponse = accountInfo[CFD].isNotAvailable ? null : await getMargin(CFD);
      const isNotExistsMarginRatioFX = fxResponse == null ? true : fxResponse.data.summary.marginRatio == null;
      const isNotExistsMarginRatioETF = etfResponse == null ? true : etfResponse.data.summary.marginRatio == null;
      const isNotExistsMarginRatioCFD = cfdResponse == null ? true : cfdResponse.data.summary.marginRatio == null;

      if (!isNotExistsMarginRatioFX) {
        const effectiveMarginRateFX = fxResponse?.data?.summary?.marginRatio * ONE_HUNDRED;
        alertFlg = effectiveMarginRateFX <= ALERT_RATE;
      }
      if (!isNotExistsMarginRatioETF) {
        const effectiveMarginRateETF = etfResponse.data.summary.marginRatio * ONE_HUNDRED;
        alertFlg = alertFlg || effectiveMarginRateETF <= ALERT_RATE;
      }
      if (!isNotExistsMarginRatioCFD) {
        const effectiveMarginRateCFD = cfdResponse.data.summary.marginRatio * ONE_HUNDRED;
        alertFlg = alertFlg || effectiveMarginRateCFD <= ALERT_RATE;
      }
    }

    return alertFlg;
  }, [accountInfo, isAuth]);
  return useMemo(
    () => ({
      getMarginData,
    }),
    [getMarginData],
  );
};
