import { SYSTEM_NOTIFICATION_TYPES } from '../../constants/core';
import { SET_SYSTEM_NOTIFICATION_MODAL_VISIBLE } from '../actionConstants/systemConstants';

const initialState = {
  systemNotificationModalVisible: {
    ...Object.values(SYSTEM_NOTIFICATION_TYPES).reduce(
      (info, type) => ({
        ...info,
        [type]: false,
      }),
      {},
    ),
  },
};

export const system = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SYSTEM_NOTIFICATION_MODAL_VISIBLE: {
      const { notificationType, visible } = payload;
      return {
        ...state,
        systemNotificationModalVisible: {
          ...state.systemNotificationModalVisible,
          [notificationType]: visible,
        },
      };
    }
    default: {
      return state;
    }
  }
};
