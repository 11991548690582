import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UPDATE_MOBILE_CHART } from 'shared-modules/constants/apiConstant';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { isJsonString } from 'shared-modules/services';
import { getOrdersSuccess } from 'shared-modules/redux/orders';
import {
  socketConnectionRequest,
  getInstrumentListSuccess,
  createInstrumentsOptions,
  getRatesSuccess,
  getPositionsSuccess,
  changeChartResolution,
} from '../../redux/actions';
import Chart from '../../components/Chart';
import styles from './mobileChart.module.scss';

function updateChartRelatedGlobalData(dispatch, payload) {
  const { orders, positions, rates, instrumentList, serviceId, resolution } = payload;
  if (orders) dispatch(getOrdersSuccess({ serviceId, data: orders[serviceId], lastQuery: orders.lastQuery }));
  dispatch(getPositionsSuccess({ serviceId, positions }));
  dispatch(getRatesSuccess({ rates }));
  dispatch(getInstrumentListSuccess({ instrumentList }));
  ALL_SERVICES.forEach((service) => {
    dispatch(createInstrumentsOptions({ serviceId: service, options: payload[`${service}InstrumentsOptions`] }));
  });
  dispatch(changeChartResolution({ resolution }));
}

const MobileChart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(socketConnectionRequest({ isPublic: true }));

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(UPDATE_MOBILE_CHART);
    }
  }, [dispatch]);

  const [instrumentId, changeInstrumentId] = useState(null);
  const [chartType, changeChartType] = useState(null);
  const [selectedSide, changeSelectedSide] = useState(null);
  const [serviceId, changeServiceId] = useState(null);
  const [onlyManualPositions, changeOnlyManualPositions] = useState(null);
  const [onlyApGroupPositions, changeOnlyApGroupPositions] = useState(null);
  const [isMobileAuthenticated, setMobileAuthenticated] = useState(null);

  const windowEventListener = useCallback(
    ({ data }) => {
      if (!data || !isJsonString(data)) {
        return;
      }
      const parsedMessage = JSON.parse(data);

      switch (parsedMessage.message) {
        case UPDATE_MOBILE_CHART: {
          const { payload } = parsedMessage;
          changeInstrumentId(payload.instrumentId);
          changeChartType(payload.chartType);
          changeSelectedSide(payload.selectedSide);
          changeServiceId(payload.serviceId);
          changeOnlyManualPositions(payload.onlyManualPositions);
          changeOnlyApGroupPositions(payload.onlyApGroupPositions);
          setMobileAuthenticated(payload.isMobileAuthenticated);

          updateChartRelatedGlobalData(dispatch, payload);

          break;
        }
        default:
          break;
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', windowEventListener);
    document.addEventListener('message', windowEventListener);
    return () => {
      window.removeEventListener('message', windowEventListener);
      document.removeEventListener('message', windowEventListener);
    };
  }, [windowEventListener]);

  return (
    <div className={styles.chartWrapper}>
      {instrumentId && serviceId && (
        <Chart
          key={serviceId}
          serviceId={serviceId}
          selectedInstrumentId={instrumentId}
          selectedSide={selectedSide}
          chartType={chartType}
          onlyManualPositions={onlyManualPositions}
          onlyApGroupPositions={onlyApGroupPositions}
          isMobileAuthenticated={isMobileAuthenticated}
          withPositions
          isMobile
        />
      )}
    </div>
  );
};

export default memo(MobileChart);
