import { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ETF, LAB_SIMULATION_EMPTY_MESSAGE, MIXED, SWAP_INTEREST_LABEL_MAP } from 'shared-modules/constants';
import {
  formatDateTimeWithoutDivider,
  getMultiServiceQuantityUnit,
  toCommaSeparatedNumber,
} from 'shared-modules/services';
import { useSelectedShareItem, useSelectedStrategyGroup } from 'shared-modules/hooks';
import { compareServiceId } from 'shared-modules/utils';
import { orderNameWithToolTips } from '../../../../../services/tableTemplate';
import { Table } from '../../../../../components';
import BuySellItem from '../../../../../components/BuySellItem/BuySellItem';
import HighlightValue from '../../../../../components/HighlightValue';
import InstrumentPrecisionTableCell from '../../../../../components/InstrumentPrecisionTableCell';
import styles from './commonTable.module.scss';

const DEFAULT_SORTING = [{ id: 'closeTime', desc: true }];

const RESIZED = {
  key: 'executionTable',
  default: [70, 119, 70, 83, 116, 108, 103, 70, 90, 78, 70],
};
const RESIZED_FOR_MIXED = {
  key: 'executionTableMixed',
  default: [70, 92, 70, 70, 111, 90, 98, 70, 87, 75, 70, 70],
};

const getQuantityLabel = (selectedServiceIds, isMultiService) => {
  return `数量(${getMultiServiceQuantityUnit(selectedServiceIds, isMultiService)})`;
};

const getSwapInterestLabel = (selectedServiceIds, isMultiService) => {
  if (!isMultiService) {
    return `${SWAP_INTEREST_LABEL_MAP[selectedServiceIds[0]]}(円)`;
  }
  const swapInterestLabel = selectedServiceIds
    .map((serviceId) => SWAP_INTEREST_LABEL_MAP[serviceId])
    .reduce((label, nextLabel) => `${label}/${nextLabel}`);
  return `${swapInterestLabel}(円)`;
};

export const CommonTable = memo(({ data, settingsData, helpers, loading }) => {
  const selectedShareItem = useSelectedShareItem();
  const [strategyGroup] = useSelectedStrategyGroup();
  const selectedType = strategyGroup?.serviceId;
  const sortedServiceIds = useMemo(
    () => strategyGroup?.serviceIds?.sort(compareServiceId) ?? [],
    [strategyGroup?.serviceIds],
  );
  const isMultiService = sortedServiceIds.length > 1;

  const tableMetaInfo = useMemo(
    () => ({
      loading,
      pageNumber: 1,
      totalPages: 1,
    }),
    [loading],
  );

  const getOrderName = useCallback(
    (target) => {
      const { itemId } = target;
      const targetIdName = selectedShareItem ? 'id' : 'strategyId';
      const targetId = Number(target[targetIdName]);
      const itemListValue = settingsData.find((value) => value.itemId === itemId && value.strategyId === targetId);
      if (itemListValue && itemListValue.name) {
        return orderNameWithToolTips(itemListValue.name);
      }
      return '-';
    },
    [settingsData, selectedShareItem],
  );

  const columns = useMemo(() => {
    const result = [
      {
        Header: '銘柄',
        accessor: 'instrumentDisplayName',
        Cell: ({ cell: { value } }) => value || '-',
        disableSortBy: true,
      },
      {
        Header: '注文名',
        accessor: 'orderName',
        Cell: ({
          row: {
            original: { itemId, strategyId, id },
          },
        }) => getOrderName({ itemId, strategyId, id }),
        disableSortBy: true,
      },
      {
        Header: '売買',
        accessor: 'side',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (value ? <BuySellItem type={Number(value)} /> : '-'),
        disableSortBy: true,
      },
      {
        Header: getQuantityLabel(sortedServiceIds, isMultiService),
        accessor: 'quantity',
        Cell: ({
          row: {
            original: { quantity, strategySets },
          },
        }) => helpers.getQuantity(quantity, strategySets),
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '新規約定日時',
        accessor: 'openTime',
        Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '新規約定価格',
        accessor: 'openPrice',
        Cell: InstrumentPrecisionTableCell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '決済約定日時',
        accessor: 'closeTime',
        Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '決済約定価格',
        accessor: 'closePrice',
        Cell: InstrumentPrecisionTableCell,
        isNumber: true,
        disableSortBy: true,
      },
    ];
    if (sortedServiceIds.includes(ETF)) {
      result.push({
        Header: '分配相当額(円)',
        accessor: 'dividend',
        Cell: ({
          row: {
            original: { dividend, strategySets },
          },
        }) => (dividend && strategySets ? helpers.getCommissionOrDividend(dividend, strategySets) : '-'),
        isNumber: true,
        disableSortBy: true,
      });
    }
    result.push({
      Header: getSwapInterestLabel(sortedServiceIds, isMultiService),
      accessor: 'swapInterest',
      Cell: ({
        row: {
          original: { swapInterest, strategySets },
        },
      }) =>
        swapInterest && strategySets
          ? toCommaSeparatedNumber(helpers.getSwapInterest(swapInterest, strategySets))
          : '-',
      isNumber: true,
      disableSortBy: true,
    });
    result.push({
      Header: '実現損益(円)',
      accessor: 'realizedPl',
      Cell: ({
        // eslint-disable-next-line react/prop-types
        row: {
          // eslint-disable-next-line react/prop-types
          original: { realizedPl, strategySets },
        },
      }) =>
        realizedPl && strategySets ? (
          <HighlightValue
            value={helpers.getRealizedProfitLoss(realizedPl, strategySets)}
            isToFormat
            withoutPlus
            withYenIcon={false}
          />
        ) : (
          '-'
        ),
      isNumber: true,
      disableSortBy: true,
    });
    return result;
  }, [getOrderName, helpers, isMultiService, sortedServiceIds]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>直近の約定</div>
      <>
        {data == null ? (
          <div className={styles.tradesDataEmptyMessageWrapper}>
            <span>{LAB_SIMULATION_EMPTY_MESSAGE}</span>
          </div>
        ) : (
          <Table
            tableData={data}
            columns={columns}
            resized={selectedType === MIXED ? RESIZED_FOR_MIXED : RESIZED}
            defaultSorting={DEFAULT_SORTING}
            tableMetaInfo={tableMetaInfo}
          />
        )}
      </>
    </div>
  );
});

CommonTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  settingsData: PropTypes.arrayOf(PropTypes.shape({})),
  helpers: PropTypes.shape({
    getQuantity: PropTypes.func.isRequired,
    getCommissionOrDividend: PropTypes.func.isRequired,
    getSwapInterest: PropTypes.func.isRequired,
    getRealizedProfitLoss: PropTypes.func.isRequired,
  }),
  loading: PropTypes.bool,
};

CommonTable.defaultProps = {
  data: undefined,
  settingsData: undefined,
  helpers: undefined,
  loading: false,
};
