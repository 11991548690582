import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AP_GROUP_SOURCES, MODAL_SIZES } from 'shared-modules/constants';
import { useServiceId } from 'shared-modules/hooks';
import { tutorialPriceChartTab, tutorialProfitLossTab } from '../../../../constants/tutorial/classNames';
import { ChartDescription } from '../../../../screens/AutoSelect/components/ChartDescription';
import Modal from '../../../../components/Modal';
import { Tabs } from '../../../../components';
import TutorialPriceChart from './components/TutorialPriceChart';
import TutorialProfitLossChart from './components/TutorialProfitLossChart';
import TutorialTables from './components/TutorialTables';
import styles from './tutorialPortfolioDetail.module.scss';
import TutorialCard from './components/TutorialCard';

const TAB_PRICE = 'price';
const TAB_REALIZED_PL = 'realizedPl';

const TutorialPortfolioDetail = ({ isOpen, closeModal }) => {
  const cardData = useSelector((state) => state.webTutorial.cardData);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const { id, instrumentId: currency } = cardData || {};
  const serviceId = useServiceId(currency);

  const shapeCardData = useMemo(() => {
    const instrumentInfo = Object.values(instrumentList).find(
      (instrument) => cardData.instrumentId === instrument.instrumentId,
    );

    return {
      ...cardData,
      ...instrumentInfo,
      id: cardData.id,
      type: AP_GROUP_SOURCES.SELECT.NAME,
      currency: cardData.instrumentId,
      groupName: cardData.name,
      count: cardData.positionQuantity,
      realizedProfitLoss: cardData.totalRealizedPnl || '-',
      creationTime: cardData.entryDate ? new Date(cardData.entryDate).toLocaleDateString('ja') : '-',
      totalCount: cardData.totalApCount || 0,
      activeCount: cardData.activeApCount || 0,
    };
  }, [cardData, instrumentList]);

  const title = useMemo(() => `${cardData.name}`, [cardData]);
  const [key, setKey] = useState('price');

  const tabItems = useMemo(() => {
    return [
      {
        id: TAB_PRICE,
        className: tutorialPriceChartTab,
        label: 'プライスチャート',
        children: <TutorialPriceChart selectedInstrumentId={currency} apGroupId={id} serviceId={serviceId} />,
      },
      {
        id: TAB_REALIZED_PL,
        className: tutorialProfitLossTab,
        label: '実現損益',
        children: <TutorialProfitLossChart />,
      },
    ];
  }, [currency, id, serviceId]);

  const toolbar = useMemo(() => {
    if (key !== TAB_PRICE) {
      return null;
    }
    return <ChartDescription />;
  }, [key]);

  return (
    <Modal size={MODAL_SIZES.WIDTH_1024} isOpen={isOpen} closeModal={closeModal} title={title} isOverlayModal>
      <div className={styles.wrapper}>
        <TutorialCard data={shapeCardData} closeModal={closeModal} />
        <div className={styles.chartTabs}>
          <Tabs containerClassName={styles.tabs} items={tabItems} activeKey={key} onChange={setKey} toolbar={toolbar} />
        </div>
        <TutorialTables serviceId={serviceId} />
      </div>
    </Modal>
  );
};
TutorialPortfolioDetail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(TutorialPortfolioDetail);
