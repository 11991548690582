import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSimulationChartDataSelection, useSimulationSummarySelection } from 'shared-modules/hooks/select';
import {
  changeSelectedTermIdAutoTrade,
  getSelectionModalInfoRequest,
} from 'shared-modules/redux/actions/autoSelectActions';
import { CommonChart } from './CommonChart';

export const SelectionChart = memo(() => {
  const dispatch = useDispatch();
  const termOptions = useSelector((state) => state.constants.selectionTermOptions);
  const termId = useSelector((state) => state.autoSelect.selectedTermId);
  const { chartData, simulationData, serviceId, loading, selectionId, selectionVersion, strategyList, defaultSets } =
    useSimulationChartDataSelection();
  const simulationSummary = useSimulationSummarySelection();

  const handleChangeTermId = useCallback(
    (newTermId) => {
      dispatch(changeSelectedTermIdAutoTrade({ termId: newTermId }));
      dispatch(getSelectionModalInfoRequest({ selectionId, selectionVersion, termId: newTermId, termIdLoader: true }));
    },
    [dispatch, selectionId, selectionVersion],
  );

  return (
    <CommonChart
      chartData={chartData}
      simulationData={simulationData}
      simulationSummary={simulationSummary}
      strategyList={strategyList}
      defaultSets={defaultSets}
      serviceId={serviceId}
      loading={loading}
      termId={termId}
      termOptions={termOptions}
      onChangeTermId={handleChangeTermId}
    />
  );
});
