import React, { memo } from 'react';
import styles from '../steps.module.scss';

const Step1 = () => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <p>
          自動売買注文の新規取引の方向を選びます。
          <br />
          買：価格の上昇で利益を得ます（新規買い → 決済売り）
          <br />
          売：価格の下落で利益を得ます（新規売り → 決済買い）
        </p>
      </div>
    </div>
  );
};

export default memo(Step1);
