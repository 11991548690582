import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MODAL_SIZES, OK_BUTTON_TEXT } from 'shared-modules/constants';
import { REPLACEMENT_TEXT_REG } from 'shared-modules/constants/errorMesages';
import { useReloadApp } from 'shared-modules/hooks';
import { logoutUser, handleRedirect } from '../../../../redux/actions';
import { Button, Modal } from '../../../../components';
import styles from './errorInfo.module.scss';

const BUTTON_WIDTH = 312;

const ErrorInfo = ({
  isOpen,
  closeModal,
  message: messageRaw,
  title,
  forceLogout,
  withRefreshButton,
  linkURL,
  buttonText,
}) => {
  const dispatch = useDispatch();
  const reloadApp = useReloadApp();

  const handleClose = useCallback(() => {
    closeModal();
    if (forceLogout) {
      dispatch(logoutUser({ successAction: handleRedirect({ url: '/login', isInner: false }) }));
    }
  }, [closeModal, forceLogout, dispatch]);

  const reloadPage = useCallback(() => {
    reloadApp();
  }, [reloadApp]);

  const message = useMemo(() => {
    if (!linkURL || linkURL === '') {
      return messageRaw;
    }

    const messageParts = messageRaw.split(REPLACEMENT_TEXT_REG);

    return (
      <>
        {messageParts[0]}
        <a href={linkURL} className={styles.link}>
          {messageParts[1]}
        </a>
        {messageParts[2]}
      </>
    );
  }, [messageRaw, linkURL]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      size={MODAL_SIZES.WIDTH_400_600}
      isOutsideClickDontClose
      withoutCloseButton
      isOverlap
      isOverlayModal
      isErrorNotification
    >
      <div className={styles.wrapper}>
        <div className={styles.errorMessage}>{message}</div>
        <div className={styles.buttonsContainer}>
          {withRefreshButton && (
            <Button width={BUTTON_WIDTH} onClick={reloadPage} secondary>
              更新
            </Button>
          )}
          {!withRefreshButton && (
            <Button onClick={handleClose} secondary>
              {buttonText || OK_BUTTON_TEXT}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

ErrorInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  forceLogout: PropTypes.bool.isRequired,
  withRefreshButton: PropTypes.bool.isRequired,
  linkURL: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
};

ErrorInfo.defaultProps = {
  buttonText: '',
};

export default memo(ErrorInfo);
