/* eslint-disable global-require */
const labImages = [
  { name: 'icon_a1.png', uri: require('./defaultIconImages/icon_a1.png') },
  { name: 'icon_a2.png', uri: require('./defaultIconImages/icon_a2.png') },
  { name: 'icon_a3.png', uri: require('./defaultIconImages/icon_a3.png') },
  { name: 'icon_a4.png', uri: require('./defaultIconImages/icon_a4.png') },
  { name: 'icon_a5.png', uri: require('./defaultIconImages/icon_a5.png') },
  { name: 'icon_f1.png', uri: require('./defaultIconImages/icon_f1.png') },
  { name: 'icon_f2.png', uri: require('./defaultIconImages/icon_f2.png') },
  { name: 'icon_f3.png', uri: require('./defaultIconImages/icon_f3.png') },
  { name: 'icon_f4.png', uri: require('./defaultIconImages/icon_f4.png') },
  { name: 'icon_f5.png', uri: require('./defaultIconImages/icon_f5.png') },
  { name: 'icon_h1.png', uri: require('./defaultIconImages/icon_h1.png') },
  { name: 'icon_h2.png', uri: require('./defaultIconImages/icon_h2.png') },
  { name: 'icon_h3.png', uri: require('./defaultIconImages/icon_h3.png') },
  { name: 'icon_h4.png', uri: require('./defaultIconImages/icon_h4.png') },
  { name: 'icon_h5.png', uri: require('./defaultIconImages/icon_h5.png') },
  { name: 'icon_r1.png', uri: require('./defaultIconImages/icon_r1.png') },
  { name: 'icon_r2.png', uri: require('./defaultIconImages/icon_r2.png') },
  { name: 'icon_r3.png', uri: require('./defaultIconImages/icon_r3.png') },
  { name: 'icon_r4.png', uri: require('./defaultIconImages/icon_r4.png') },
  { name: 'icon_r5.png', uri: require('./defaultIconImages/icon_r5.png') },
];

export default labImages;
