import React from 'react';
import PropTypes from 'prop-types';
import { CFD } from 'shared-modules/constants';

export const QuantityLabel = ({ serviceId, quantityUnit, quantityUnitConvFactor }) => {
  return (
    <div>
      <div>{`数量(${quantityUnit})`}</div>
      {serviceId === CFD && <div>{`1Lot=${quantityUnitConvFactor}`}</div>}
    </div>
  );
};

QuantityLabel.propTypes = {
  serviceId: PropTypes.string.isRequired,
  quantityUnit: PropTypes.string.isRequired,
  quantityUnitConvFactor: PropTypes.number.isRequired,
};
