import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { OPTIONS_BUY_SELL_CHART } from 'shared-modules/constants';
import LabeledSwitch from '../LabeledSwitch/LabeledSwitch';

const [SELL_OPTION, BUY_OPTION] = OPTIONS_BUY_SELL_CHART;

const BuySellSwitch = (props) => {
  const { isSellDisabled, isBuyDisabled, activeItemId, dataCustomSelector, onChange, itemClassName, isNotSelectable } =
    props;

  const options = useMemo(
    () => [
      { ...SELL_OPTION, isDisabled: isSellDisabled },
      { ...BUY_OPTION, isDisabled: isBuyDisabled },
    ],
    [isBuyDisabled, isSellDisabled],
  );

  return (
    <LabeledSwitch
      activeItemId={activeItemId}
      dataCustomSelector={dataCustomSelector}
      options={options}
      onChange={onChange}
      itemClassName={itemClassName}
      showLockIcon={isBuyDisabled || isSellDisabled}
      isLighter
      isNotSelectable={isNotSelectable}
    />
  );
};

BuySellSwitch.propTypes = {
  activeItemId: PropTypes.number.isRequired,
  dataCustomSelector: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isBuyDisabled: PropTypes.bool,
  isSellDisabled: PropTypes.bool,
  itemClassName: PropTypes.string,
  isNotSelectable: PropTypes.bool,
};

BuySellSwitch.defaultProps = {
  isBuyDisabled: false,
  isSellDisabled: false,
  itemClassName: '',
  dataCustomSelector: '',
  isNotSelectable: false,
};

export default memo(BuySellSwitch);
