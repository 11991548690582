import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { REPLACEMENT_TEXT_REG } from 'shared-modules/constants/errorMesages';
import styles from './maintenanceErrorText.module.scss';

export const MaintenanceErrorText = memo(({ message: messageRaw, linkURL }) => {
  const message = useMemo(() => {
    const messageParts = messageRaw.split(REPLACEMENT_TEXT_REG);
    return (
      <>
        {messageParts[0]}
        <a href={linkURL} className={styles.link} target="_blank" rel="noreferrer">
          {messageParts[1]}
        </a>
        {messageParts[2]}
      </>
    );
  }, [messageRaw, linkURL]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.errorMessageText}>{message}</div>
    </div>
  );
});

MaintenanceErrorText.propTypes = {
  message: PropTypes.string.isRequired,
  linkURL: PropTypes.string.isRequired,
};
