import { call, put, select } from 'redux-saga/effects';
import { ORDER_TYPES, SUCCESS_NOTIFICATION_MESSAGE } from '../../../constants/manualTrade';
import { ORDER_METHOD_MAIN } from '../../../constants';
import { createOcoOrder } from '../../../api/manualTradeApi';
import { createOrderStartLoading, createOrderStopLoading } from '../../actions/manualTradeActions';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { errorHandler } from '../errorSaga';
import { createDateString } from '../../../services';

function* createOcoOrderRequestHandler() {
  try {
    yield put(createOrderStartLoading());
    const serviceId = yield select((state) => state.auth.serviceId);
    const instrumentId = yield select((state) => state.manualTrade.selectedInstrumentId[serviceId]);
    const side = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.BUY_SELL],
    );
    const quantity = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.COUNT],
    );
    const limitPrice = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.LIMIT_PRICE],
    );
    const stopPrice = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.STOP_PRICE],
    );
    const expirationType = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.EXPIRATION_TYPE],
    );
    const selectedDate = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.SELECTED_DATE],
    );
    const selectedTime = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.OCO.name][ORDER_TYPES.OCO.inputs.SELECTED_TIME],
    );
    const expireTime = createDateString(selectedDate, selectedTime);

    const requestBody = {
      instrumentId,
      quantity,
      order1: {
        side,
        type: ORDER_METHOD_MAIN.LIMIT.ID,
        price: limitPrice,
        expireType: expirationType,
        expireTime,
      },
      order2: {
        side,
        type: ORDER_METHOD_MAIN.STOP_LIMIT.ID,
        price: stopPrice,
        expireType: expirationType,
        expireTime,
      },
    };

    yield call(createOcoOrder, { requestBody, serviceId });
    yield put(sendNotificationSuccess({ message: SUCCESS_NOTIFICATION_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e, form: ORDER_TYPES.OCO.name });
  } finally {
    yield put(createOrderStopLoading());
  }
}

export default createOcoOrderRequestHandler;
