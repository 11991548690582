export const SEQUENTIAL_MODALS = {
  Advertisements: 'advertisement',
  MessageModal: 'messageModal',
  Recommend: 'recommend',
};

export const STATUS = {
  INIT: 'init',
  NOT_READY: 'not-ready',
  PROCESSING: 'processing',
  ALL_DONE: 'all-done',
};
