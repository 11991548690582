import Decimal from 'decimal.js';
import { BUY_SELL_MAIN, CHART_RESOLUTION_MAIN, CHART_TYPES_MAIN } from '../constants';
import { ENTRY_PRICE_VALUES } from '../constants/builder';

export const DEFAULT_BACKGROUND_TRANSPARENCY = 60;

export const SELECTED_SIDE = BUY_SELL_MAIN.BUY.CHART_ID;
export const OPPOSITE_SIDE = BUY_SELL_MAIN.SELL.CHART_ID;
export const RESOLUTION = CHART_RESOLUTION_MAIN.DAYS_1.TV_ID;
export const chartType = CHART_TYPES_MAIN.CANDLESTICK.ID;

export const PRICE_CHART_COLORS = {
  AQUA: '#00FFFF',
  BLUE: '#0000FF',
  GREEN: '#008000',
  KHAKI: '#F0E68C',
  MAGENTA: '#FF00FF',
  MIDNIGHT_EXPRESS: '#141823',
  PINK: '#FFC0CB',
  RED: '#b91b2a',
  SUMMER_SKY: '#4094E8',
  TRANSPARENT: 'rgba(0, 0, 0, 0)',
  TRANSLUCENT_WHITE: 'rgba(255, 255, 255, 0.75)',
  YELLOW: '#ffff00',
};

export const basePriceLineProps = {
  linecolor: PRICE_CHART_COLORS.GREEN,
  linewidth: 1,
  linestyle: 1,
  showPrice: true,
  showLabel: false,
  text: '',
  frozen: true,
};

export const sellAverageTradePriceLineProps = {
  linecolor: PRICE_CHART_COLORS.BLUE,
  linewidth: 1,
  linestyle: 1,
  showPrice: true,
  showLabel: false,
  text: '',
  frozen: true,
};

export const buyAverageTradePriceLineProps = {
  linecolor: PRICE_CHART_COLORS.RED,
  linewidth: 1,
  linestyle: 1,
  showPrice: true,
  showLabel: false,
  text: '',
  frozen: true,
};

export const PRICE_AREA_OPTIONS = {
  shape: 'price_range',
  lock: true,
  disableSelection: true,
};

export const AREA_STYLE_OPTIONS = {
  // drawBorder: false,
  linecolor: PRICE_CHART_COLORS.TRANSPARENT,
  // transparency: 80,
  extendRight: true,
  extendLeft: true,
  textcolor: PRICE_CHART_COLORS.TRANSPARENT,
  labelBackgroundColor: PRICE_CHART_COLORS.TRANSPARENT,
};

export const areaOptionsOco = {
  ...PRICE_AREA_OPTIONS,
  overrides: {
    ...AREA_STYLE_OPTIONS,
    backgroundColor: PRICE_CHART_COLORS.YELLOW,
    backgroundTransparency: DEFAULT_BACKGROUND_TRANSPARENCY,
  },
};

export const orderLineColor = (id) => (id === BUY_SELL_MAIN.BUY.ID ? PRICE_CHART_COLORS.RED : PRICE_CHART_COLORS.BLUE);
export const orderSettingLineColor = (id) =>
  id === BUY_SELL_MAIN.BUY.ID ? PRICE_CHART_COLORS.MAGENTA : PRICE_CHART_COLORS.SUMMER_SKY;
export const getColorBySide = (id) =>
  id === BUY_SELL_MAIN.BUY.ID ? PRICE_CHART_COLORS.MAGENTA : PRICE_CHART_COLORS.SUMMER_SKY;
export const isEmpty = (obj) => {
  if (obj === undefined || obj === null) {
    return true;
  }

  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getActiveOrderName = ({ ocoIsChecked, entryPriceTypeId }) => {
  if (entryPriceTypeId === ENTRY_PRICE_VALUES.PREVIOUS_DAY.ID) {
    return 'Order';
  }

  return ocoIsChecked ? 'Order.1' : 'Order';
};

export const decimalRounding = (decimalObject, precision) => {
  if (precision < 0) {
    return Number(decimalObject) - (Number(decimalObject) % 10 ** -precision);
  }

  return Number(decimalObject.toDP(precision, Decimal.ROUND_DOWN));
};
