// eslint-disable-next-line import/no-unresolved
import { useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { getInstrumentShortName } from '../utils';

export const useInstrumentShortName = (instrumentId) => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  return useMemo(() => getInstrumentShortName(instrumentId, instrumentList), [instrumentId, instrumentList]);
};
