import { ALL_SERVICES } from 'shared-modules/constants/core';
import { getServiceName } from 'shared-modules/utils';
import {
  OPEN_CONFIRMATION_MODAL,
  CLOSE_CONFIRMATION_MODAL,
  OPEN_INPUT_CONFIRMATION_MODAL,
  CLOSE_INPUT_CONFIRMATION_MODAL,
  OPEN_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL,
  CLOSE_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL,
  OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL,
  CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL,
  OPEN_BUILDER_CONFIGURATION_FLOW_MODAL,
  CLOSE_BUILDER_CONFIGURATION_FLOW_MODAL,
  OPEN_BUILDER_ADD_TO_CART_MODAL,
  CLOSE_BUILDER_ADD_TO_CART_MODAL,
  OPEN_BUILDER_ADD_TO_PORTFOLIO_MODAL,
  CLOSE_BUILDER_ADD_TO_PORTFOLIO_MODAL,
  OPEN_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL,
  CLOSE_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL,
  OPEN_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL,
  CLOSE_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL,
  OPEN_CHART_MAKE_ADD_TO_CART_MODAL,
  CLOSE_CHART_MAKE_ADD_TO_CART_MODAL,
  OPEN_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL,
  CLOSE_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL,
  OPEN_TECH_ADD_TO_CART_MODAL,
  CLOSE_TECH_ADD_TO_CART_MODAL,
  OPEN_TECH_ADD_TO_PORTFOLIO_MODAL,
  CLOSE_TECH_ADD_TO_PORTFOLIO_MODAL,
  OPEN_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL,
  CLOSE_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL,
  OPEN_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL,
  CLOSE_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL,
  OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER,
  CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER,
  OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION,
  CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION,
  OPEN_MANUAL_TRADE_CURRENCY_SETTINGS,
  CLOSE_MANUAL_TRADE_CURRENCY_SETTINGS,
  OPEN_MANUAL_TRADE_CONFIG,
  CLOSE_MANUAL_TRADE_CONFIG,
  OPEN_MANUAL_TRADE_NEWS,
  CLOSE_MANUAL_TRADE_NEWS,
  OPEN_MANUAL_TRADE_CONFIRMATION,
  CLOSE_MANUAL_TRADE_CONFIRMATION,
  OPEN_MANUAL_TRADE_TABLE_CHANGE_ORDER,
  CLOSE_MANUAL_TRADE_TABLE_CHANGE_ORDER,
  OPEN_BULK_CHANGE,
  CLOSE_BULK_CHANGE,
  OPEN_BULK_CHANGE_CONFIRM_CHECK,
  CLOSE_BULK_CHANGE_CONFIRM_CHECK,
  OPEN_BULK_CHANGE_SELECT,
  CLOSE_BULK_CHANGE_SELECT,
  OPEN_BULK_CHANGE_FAIL,
  CLOSE_BULK_CHANGE_FAIL,
  OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE,
  CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE,
  OPEN_AUTO_SELECT_RUN_NOW_CONFIRMATION,
  CLOSE_AUTO_SELECT_RUN_NOW_CONFIRMATION,
  OPEN_AUTO_SELECT_ADD_TO_CART_CONFIRMATION,
  CLOSE_AUTO_SELECT_ADD_TO_CART_CONFIRMATION,
  OPEN_HELP_INFO,
  CLOSE_HELP_INFO,
  OPEN_COPYRIGHT_INFO,
  CLOSE_COPYRIGHT_INFO,
  OPEN_AGREEMENT_INFO,
  CLOSE_AGREEMENT_INFO,
  OPEN_NOTIFICATION_SETTINGS,
  CLOSE_NOTIFICATION_SETTINGS,
  OPEN_REPORTS_DETAILS,
  CLOSE_REPORTS_DETAILS,
  OPEN_BUILDER_WARNING_INFO,
  CLOSE_BUILDER_WARNING_INFO,
  OPEN_AUTO_SELECT_WARNING_INFO,
  CLOSE_AUTO_SELECT_WARNING_INFO,
  OPEN_STRATEGIES_SUCCESS,
  CLOSE_STRATEGIES_SUCCESS,
  OPEN_YOUTUBE_MODAL,
  CLOSE_YOUTUBE_MODAL,
  OPEN_LAB_TAG_INFO_MODAL,
  CLOSE_LAB_TAG_INFO_MODAL,
  OPEN_RANGE_OUT_CONFIRMATION,
  CLOSE_RANGE_OUT_CONFIRMATION,
  OPEN_TECH_LOGIC_INFO,
  CLOSE_TECH_LOGIC_INFO,
  OPEN_TUTORIAL_MODAL,
  CLOSE_TUTORIAL_MODAL,
  OPEN_TUTORIAL_PORTFOLIO_DETAIL_MODAL,
  CLOSE_TUTORIAL_PORTFOLIO_DETAIL_MODAL,
  OPEN_TECH_BUILDER_CONFIGURATION_FLOW_MODAL,
  CLOSE_TECH_BUILDER_CONFIGURATION_FLOW_MODAL,
  OPEN_BEGIN_DEPOSIT_MODAL,
  CLOSE_BEGIN_DEPOSIT_MODAL,
  OPEN_BEGIN_RUN_AUTO_MODAL,
  CLOSE_BEGIN_RUN_AUTO_MODAL,
  OPEN_TECH_BUILDER_WARNING_INFO,
  CLOSE_TECH_BUILDER_WARNING_INFO,
  OPEN_SEARCH_AUTO_SELECT_RULE_NAME_MODAL,
  CLOSE_SEARCH_AUTO_SELECT_RULE_NAME_MODAL,
} from '../actionConstants/modalConstants';

export const initialState = {
  confirmation: {
    isOpen: false,
    title: null,
    callback: null,
    bodyText: null,
    buttonBackText: null,
    buttonNextText: null,
    isOverlap: false,
    successButtonIsGreen: false,
  },
  inputConfirmation: {
    isOpen: false,
    title: null,
    callback: null,
    bodyText: null,
    buttonBackText: null,
    buttonNextText: null,
    value: '',
    isOverlap: false,
  },
  portfolioManualTradeDetail: {
    isOpen: false,
    data: null,
  },
  portfolioAutoTradeDetail: {
    isOpen: false,
    data: null,
  },
  builderConfigurationFlow: {
    isOpen: false,
  },
  techBuilderConfigurationFlow: {
    isOpen: false,
  },
  builderAddToCart: {
    isOpen: false,
    isOrderSetting: false,
  },
  builderAddToPortfolio: {
    isOpen: false,
    isOrderSetting: false,
  },
  builderAddToPortfolioSuccess: {
    isOpen: false,
    orderName: '',
  },
  builderAddToPortfolioFail: {
    isOpen: false,
    orderName: '',
  },
  chartMakeAddToCart: {
    isOpen: false,
    data: {
      fundIndication: 0,
      requiredMargin: 0,
      sets: 1,
      callback: null,
      selectionId: null,
      selectionVersion: null,
    },
  },
  chartMakeRunNowConfirmation: {
    isOpen: false,
    data: {
      fundIndication: 0,
      requiredMargin: 0,
      sets: 1,
      callback: null,
    },
  },
  techAddToCart: {
    isOpen: false,
    isOrderSetting: false,
  },
  techAddToPortfolio: {
    isOpen: false,
    isOrderSetting: false,
  },
  techAddToPortfolioSuccess: {
    isOpen: false,
    orderName: '',
  },
  techAddToPortfolioFail: {
    isOpen: false,
    orderName: '',
  },
  manualTradeTableCloseOrder: {
    isOpen: false,
    positionInfo: {},
  },
  manualTradeTableCloseOrderConfirmation: {
    isOpen: false,
    callback: null,
    data: {},
  },
  manualTradeCurrencySettingsModal: {
    isOpen: false,
  },
  manualTradeConfigModal: {
    isOpen: false,
  },
  manualTradeNewsModal: {
    isOpen: false,
  },
  manualTradeTableChangeOrder: {
    isOpen: false,
    orderId: null,
    isManual: false,
  },
  manualTradeConfirmation: {
    isOpen: false,
    callback: null,
    orderType: null,
  },
  multiEdit: {
    isOpen: false,
  },
  multiEditComfirmCheck: {
    isOpen: false,
    rowNum: 0,
  },
  multiEditSelect: {
    isOpen: false,
    callback: null,
  },
  multiEditFail: {
    isOpen: false,
  },
  autoSelectDetailsModal: {
    isOpen: false,
    data: {
      selectionId: null,
      selectionVersion: null,
      name: '',
    },
  },
  portfolioAutoTradeDetailOrderTypeModal: {
    isOpen: false,
    data: {},
  },
  autoSelectRunNowConfirmation: {
    isOpen: false,
    data: {
      margin: 0,
      marginRequired: 0,
      sets: 1,
      callback: null,
      isLab: false,
    },
  },
  autoSelectAddToCartConfirmation: {
    isOpen: false,
    data: {
      margin: 0,
      marginRequired: 0,
      sets: 1,
      callback: null,
      isLaunchInsideDetailModal: true,
      selectionId: null,
      selectionVersion: null,
      isLab: false,
    },
  },
  helpInfo: {
    isOpen: false,
  },
  copyrightInfo: {
    isOpen: false,
    isOverlap: false,
  },
  notificationSettings: {
    isOpen: false,
  },
  agreementInfo: {
    isOpen: false,
  },
  reportsDetails: {
    isOpen: false,
  },
  builderWarningInfo: {
    isOpen: false,
  },
  techBuilderWarningInfo: {
    isOpen: false,
  },
  autoSelectWarningInfo: {
    isOpen: false,
    termEndDate: 'XXXX年YY月ZZ日',
    isLabPreview: false,
  },
  strategiesSuccess: {
    isOpen: false,
    resultArray: [],
  },
  youtube: {
    isOpen: false,
    url: '',
  },
  labTagInfo: {
    isOpen: false,
  },
  iconCrop: {
    isOpen: false,
  },
  rangeOutConfirmation: {
    isOpen: false,
    messageId: null,
    linkUrl: null,
    parameters: null,
    serviceValues: [],
  },
  techLogicInfo: {
    isOpen: false,
  },
  tutorialModal: {
    isOpen: false,
  },
  tutorialPortfolioDetail: {
    isOpen: false,
  },
  beginDepositModal: {
    isOpen: false,
  },
  beginRunAutoModal: {
    isOpen: false,
  },
  searchAutoSelectRuleNameModal: {
    isOpen: false,
    autoSelectData: null,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OPEN_CONFIRMATION_MODAL: {
      const { callback, title, bodyText, buttonBackText, buttonNextText, isOverlap, successButtonIsGreen } = payload;
      return {
        ...state,
        confirmation: {
          isOpen: true,
          title,
          callback,
          bodyText,
          buttonBackText,
          buttonNextText,
          isOverlap,
          successButtonIsGreen,
        },
      };
    }
    case CLOSE_CONFIRMATION_MODAL: {
      return {
        ...state,
        confirmation: {
          isOpen: false,
          title: null,
          callback: null,
          bodyText: null,
          buttonBackText: null,
          buttonNextText: null,
          isOverlap: false,
        },
      };
    }
    case OPEN_INPUT_CONFIRMATION_MODAL: {
      const { callback, title, bodyText, buttonBackText, buttonNextText, value, passValueWithKey, isOverlap } = payload;
      return {
        ...state,
        inputConfirmation: {
          isOpen: true,
          title,
          callback,
          bodyText,
          buttonBackText,
          buttonNextText,
          value,
          passValueWithKey,
          isOverlap,
        },
      };
    }
    case CLOSE_INPUT_CONFIRMATION_MODAL: {
      return {
        ...state,
        inputConfirmation: {
          isOpen: false,
          title: null,
          callback: null,
          bodyText: null,
          buttonBackText: null,
          buttonNextText: null,
          value: '',
          isOverlap: false,
        },
      };
    }
    case OPEN_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL: {
      const { data } = payload;
      return {
        ...state,
        portfolioManualTradeDetail: {
          isOpen: true,
          data,
        },
      };
    }
    case CLOSE_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL: {
      return {
        ...state,
        portfolioManualTradeDetail: {
          isOpen: false,
          data: null,
        },
      };
    }
    case OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL: {
      const { data, withoutModal } = payload;
      return {
        ...state,
        portfolioAutoTradeDetail: {
          isOpen: !withoutModal,
          data,
        },
      };
    }
    case CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL: {
      return {
        ...state,
        portfolioAutoTradeDetail: {
          isOpen: false,
          data: null,
        },
      };
    }
    case OPEN_BUILDER_CONFIGURATION_FLOW_MODAL: {
      return {
        ...state,
        builderConfigurationFlow: {
          isOpen: true,
        },
      };
    }
    case CLOSE_BUILDER_CONFIGURATION_FLOW_MODAL: {
      return {
        ...state,
        builderConfigurationFlow: {
          isOpen: false,
        },
      };
    }
    case OPEN_TECH_BUILDER_CONFIGURATION_FLOW_MODAL: {
      return {
        ...state,
        techBuilderConfigurationFlow: {
          isOpen: true,
        },
      };
    }
    case CLOSE_TECH_BUILDER_CONFIGURATION_FLOW_MODAL: {
      return {
        ...state,
        techBuilderConfigurationFlow: {
          isOpen: false,
        },
      };
    }
    case OPEN_BUILDER_ADD_TO_CART_MODAL: {
      const { isOrderSetting } = payload;
      return {
        ...state,
        builderAddToCart: {
          isOpen: true,
          isOrderSetting,
        },
      };
    }
    case CLOSE_BUILDER_ADD_TO_CART_MODAL: {
      return {
        ...state,
        builderAddToCart: {
          isOpen: false,
          isOrderSetting: false,
        },
      };
    }
    case OPEN_BUILDER_ADD_TO_PORTFOLIO_MODAL: {
      const { isOrderSetting } = payload;
      return {
        ...state,
        builderAddToPortfolio: {
          isOpen: true,
          isOrderSetting,
        },
      };
    }
    case CLOSE_BUILDER_ADD_TO_PORTFOLIO_MODAL: {
      return {
        ...state,
        builderAddToPortfolio: {
          isOpen: false,
          isOrderSetting: false,
        },
      };
    }
    case OPEN_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL: {
      const { orderName } = payload;

      return {
        ...state,
        builderAddToPortfolioSuccess: {
          isOpen: true,
          orderName,
        },
      };
    }
    case CLOSE_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL: {
      return {
        ...state,
        builderAddToPortfolioSuccess: {
          isOpen: false,
          orderName: '',
        },
      };
    }
    case OPEN_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL: {
      const { orderName } = payload;

      return {
        ...state,
        builderAddToPortfolioFail: {
          isOpen: true,
          orderName,
        },
      };
    }
    case CLOSE_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL: {
      return {
        ...state,
        builderAddToPortfolioFail: {
          isOpen: false,
          orderName: '',
        },
      };
    }
    case OPEN_CHART_MAKE_ADD_TO_CART_MODAL: {
      const {
        fundIndication,
        requiredMargin,
        sets,
        isLaunchInsideDetailModal,
        callback,
        selectionId,
        selectionVersion,
      } = payload;
      return {
        ...state,
        chartMakeAddToCart: {
          isOpen: true,
          data: {
            fundIndication,
            requiredMargin,
            sets,
            callback,
            isLaunchInsideDetailModal,
            selectionId,
            selectionVersion,
          },
        },
      };
    }
    case CLOSE_CHART_MAKE_ADD_TO_CART_MODAL: {
      return {
        ...state,
        chartMakeAddToCart: {
          ...initialState.chartMakeAddToCart,
        },
      };
    }
    case OPEN_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL: {
      const { fundIndication, requiredMargin, sets, callback } = payload;
      return {
        ...state,
        chartMakeRunNowConfirmation: {
          isOpen: true,
          data: {
            fundIndication,
            requiredMargin,
            sets,
            callback,
          },
        },
      };
    }
    case CLOSE_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL: {
      return {
        ...state,
        chartMakeRunNowConfirmation: { ...initialState.chartMakeRunNowConfirmation },
      };
    }
    case OPEN_TECH_ADD_TO_CART_MODAL: {
      const { isOrderSetting } = payload;

      return {
        ...state,
        techAddToCart: {
          isOpen: true,
          isOrderSetting,
        },
      };
    }
    case CLOSE_TECH_ADD_TO_CART_MODAL: {
      return {
        ...state,
        techAddToCart: {
          isOpen: false,
          isOrderSetting: false,
        },
      };
    }
    case OPEN_TECH_ADD_TO_PORTFOLIO_MODAL: {
      const { isOrderSetting } = payload;

      return {
        ...state,
        techAddToPortfolio: {
          isOpen: true,
          isOrderSetting,
        },
      };
    }
    case CLOSE_TECH_ADD_TO_PORTFOLIO_MODAL: {
      return {
        ...state,
        techAddToPortfolio: {
          isOpen: false,
          isOrderSetting: false,
        },
      };
    }
    case OPEN_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL: {
      const { orderName } = payload;
      return {
        ...state,
        techAddToPortfolioSuccess: {
          isOpen: true,
          orderName,
        },
      };
    }
    case CLOSE_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL: {
      return {
        ...state,
        techAddToPortfolioSuccess: {
          isOpen: false,
          orderName: '',
        },
      };
    }
    case OPEN_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL: {
      const { orderName } = payload;

      return {
        ...state,
        techAddToPortfolioFail: {
          isOpen: true,
          orderName,
        },
      };
    }
    case CLOSE_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL: {
      return {
        ...state,
        techAddToPortfolioFail: {
          isOpen: false,
          orderName: '',
        },
      };
    }
    case OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER: {
      const { positionInfo } = payload;
      return {
        ...state,
        manualTradeTableCloseOrder: {
          isOpen: true,
          positionInfo,
        },
      };
    }
    case CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER: {
      return {
        ...state,
        manualTradeTableCloseOrder: {
          ...state.manualTradeTableCloseOrder,
          isOpen: false,
        },
      };
    }
    case OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION: {
      const { callback, data } = payload;
      return {
        ...state,
        manualTradeTableCloseOrderConfirmation: {
          isOpen: true,
          callback,
          data,
        },
      };
    }
    case CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION: {
      return {
        ...state,
        manualTradeTableCloseOrderConfirmation: {
          ...state.manualTradeTableCloseOrderConfirmation,
          isOpen: false,
          callback: null,
        },
      };
    }
    case OPEN_MANUAL_TRADE_CURRENCY_SETTINGS: {
      return {
        ...state,
        manualTradeCurrencySettingsModal: {
          isOpen: true,
        },
      };
    }
    case CLOSE_MANUAL_TRADE_CURRENCY_SETTINGS: {
      return {
        ...state,
        manualTradeCurrencySettingsModal: {
          isOpen: false,
        },
      };
    }
    case OPEN_MANUAL_TRADE_CONFIG: {
      return {
        ...state,
        manualTradeConfigModal: {
          isOpen: true,
        },
      };
    }
    case CLOSE_MANUAL_TRADE_CONFIG: {
      return {
        ...state,
        manualTradeConfigModal: {
          isOpen: false,
        },
      };
    }
    case OPEN_MANUAL_TRADE_NEWS: {
      return {
        ...state,
        manualTradeNewsModal: {
          isOpen: true,
        },
      };
    }
    case CLOSE_MANUAL_TRADE_NEWS: {
      return {
        ...state,
        manualTradeNewsModal: {
          isOpen: false,
        },
      };
    }
    case OPEN_MANUAL_TRADE_TABLE_CHANGE_ORDER: {
      const { orderId, isManual } = payload;
      return {
        ...state,
        manualTradeTableChangeOrder: {
          isOpen: true,
          orderId,
          isManual: isManual === true,
        },
      };
    }
    case CLOSE_MANUAL_TRADE_TABLE_CHANGE_ORDER: {
      return {
        ...state,
        manualTradeTableChangeOrder: {
          isOpen: false,
          orderId: null,
          isManual: false,
        },
      };
    }
    case OPEN_MANUAL_TRADE_CONFIRMATION: {
      const { callback, orderType } = payload;

      return {
        ...state,
        manualTradeConfirmation: {
          isOpen: true,
          callback,
          orderType,
        },
      };
    }
    case CLOSE_MANUAL_TRADE_CONFIRMATION: {
      return {
        ...state,
        manualTradeConfirmation: {
          isOpen: false,
          callback: null,
        },
      };
    }
    case OPEN_BULK_CHANGE: {
      return {
        ...state,
        multiEdit: {
          isOpen: true,
        },
      };
    }
    case CLOSE_BULK_CHANGE: {
      return {
        ...state,
        multiEdit: {
          isOpen: false,
        },
      };
    }
    case OPEN_BULK_CHANGE_CONFIRM_CHECK: {
      const { rowNum } = payload;
      return {
        ...state,
        multiEditComfirmCheck: {
          isOpen: true,
          rowNum,
        },
      };
    }
    case CLOSE_BULK_CHANGE_CONFIRM_CHECK: {
      return {
        ...state,
        multiEditComfirmCheck: {
          isOpen: false,
        },
      };
    }
    case OPEN_BULK_CHANGE_SELECT: {
      const { callback, selectedRows } = payload;

      return {
        ...state,
        multiEditSelect: {
          isOpen: true,
          callback,
          selectedRows,
        },
      };
    }
    case CLOSE_BULK_CHANGE_SELECT: {
      return {
        ...state,
        multiEditSelect: {
          isOpen: false,
          callback: null,
        },
      };
    }
    case OPEN_BULK_CHANGE_FAIL: {
      return {
        ...state,
        multiEditFail: {
          isOpen: true,
        },
      };
    }
    case CLOSE_BULK_CHANGE_FAIL: {
      return {
        ...state,
        multiEditFail: {
          isOpen: false,
        },
      };
    }
    case OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE: {
      const {
        name,
        instrumentId,
        side,
        quantity,
        entryPrice1,
        entryPrice2,
        tp,
        sl,
        follow,
        counter,
        counterPrice,
        tradePrice,
        status,
        positionId,
        latestNewOrderPrice1,
        latestNewOrderPrice2,
        id,
        orderStatus,
      } = payload;
      return {
        ...state,
        portfolioAutoTradeDetailOrderTypeModal: {
          isOpen: true,
          data: {
            name,
            instrumentId,
            side,
            quantity,
            entryPrice1,
            entryPrice2,
            tp,
            sl,
            follow,
            counter,
            counterPrice,
            tradePrice,
            status,
            positionId,
            latestNewOrderPrice1,
            latestNewOrderPrice2,
            id,
            orderStatus,
          },
        },
      };
    }
    case CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE: {
      return {
        ...state,
        portfolioAutoTradeDetailOrderTypeModal: {
          ...state.portfolioAutoTradeDetailOrderTypeModal,
          isOpen: false,
        },
      };
    }
    case OPEN_AUTO_SELECT_RUN_NOW_CONFIRMATION: {
      const { marginRecommended, marginRequired, sets, callback, isLab = false } = payload;
      // 性能に問題が出るようであればリテラルに展開する
      const serviceSpecifics = ALL_SERVICES.reduce((acc, curr) => {
        const serviceName = getServiceName(curr);
        const marginRecommendedKey = `marginRecommended${serviceName}`;
        const marginRequiredKey = `marginRequired${serviceName}`;
        return {
          ...acc,
          [marginRecommendedKey]: payload[marginRecommendedKey],
          [marginRequiredKey]: payload[marginRequiredKey],
        };
      }, {});
      return {
        ...state,
        autoSelectRunNowConfirmation: {
          isOpen: true,
          data: {
            marginRecommended,
            marginRequired,
            sets,
            callback,
            isLab,
            ...serviceSpecifics,
          },
        },
      };
    }
    case CLOSE_AUTO_SELECT_RUN_NOW_CONFIRMATION: {
      return {
        ...state,
        autoSelectRunNowConfirmation: {
          isOpen: false,
          data: {
            margin: 0,
            marginRequired: 0,
            sets: 1,
            callback: null,
            // TODO CFD marginRecommended もクリアが必要なのでは？
            ...ALL_SERVICES.reduce((acc, curr) => ({ ...acc, [`marginRequired${getServiceName(curr)}`]: 0 })),
          },
        },
      };
    }
    case OPEN_AUTO_SELECT_ADD_TO_CART_CONFIRMATION: {
      const {
        margin,
        marginRequired,
        sets,
        isLaunchInsideDetailModal,
        callback,
        selectionId,
        selectionVersion,
        isLab = false,
      } = payload;
      return {
        ...state,
        autoSelectAddToCartConfirmation: {
          isOpen: true,
          data: {
            margin,
            marginRequired,
            sets,
            isLaunchInsideDetailModal,
            callback,
            selectionId,
            selectionVersion,
            isLab,
          },
        },
      };
    }
    case CLOSE_AUTO_SELECT_ADD_TO_CART_CONFIRMATION: {
      return {
        ...state,
        autoSelectAddToCartConfirmation: {
          isOpen: false,
          data: {
            ...state.autoSelectAddToCartConfirmation.data,
            margin: 0,
            marginRequired: 0,
            sets: 1,
            callback: null,
          },
        },
      };
    }
    case OPEN_HELP_INFO: {
      return {
        ...state,
        helpInfo: {
          isOpen: true,
        },
      };
    }
    case CLOSE_HELP_INFO: {
      return {
        ...state,
        helpInfo: {
          isOpen: false,
        },
      };
    }
    case OPEN_COPYRIGHT_INFO: {
      const { isOverlap } = payload;
      return {
        ...state,
        copyrightInfo: {
          isOpen: true,
          isOverlap,
        },
      };
    }
    case CLOSE_COPYRIGHT_INFO: {
      return {
        ...state,
        copyrightInfo: {
          isOpen: false,
        },
      };
    }
    case OPEN_NOTIFICATION_SETTINGS: {
      return {
        ...state,
        notificationSettings: {
          isOpen: true,
        },
      };
    }
    case CLOSE_NOTIFICATION_SETTINGS: {
      return {
        ...state,
        notificationSettings: {
          isOpen: false,
        },
      };
    }
    case OPEN_AGREEMENT_INFO: {
      return {
        ...state,
        agreementInfo: {
          isOpen: true,
        },
      };
    }
    case CLOSE_AGREEMENT_INFO: {
      return {
        ...state,
        agreementInfo: {
          isOpen: false,
        },
      };
    }
    case OPEN_REPORTS_DETAILS: {
      return {
        ...state,
        reportsDetails: {
          isOpen: true,
        },
      };
    }
    case CLOSE_REPORTS_DETAILS: {
      return {
        ...state,
        reportsDetails: {
          isOpen: false,
        },
      };
    }
    case OPEN_BUILDER_WARNING_INFO: {
      return {
        ...state,
        builderWarningInfo: {
          isOpen: true,
        },
      };
    }
    case CLOSE_BUILDER_WARNING_INFO: {
      return {
        ...state,
        builderWarningInfo: {
          isOpen: false,
        },
      };
    }
    case OPEN_TECH_BUILDER_WARNING_INFO: {
      return {
        ...state,
        techBuilderWarningInfo: {
          isOpen: true,
        },
      };
    }
    case CLOSE_TECH_BUILDER_WARNING_INFO: {
      return {
        ...state,
        techBuilderWarningInfo: {
          isOpen: false,
        },
      };
    }
    case OPEN_AUTO_SELECT_WARNING_INFO: {
      const { termEndDate, isLabPreview = false } = payload;
      return {
        ...state,
        autoSelectWarningInfo: {
          isOpen: true,
          termEndDate,
          isLabPreview,
        },
      };
    }
    case CLOSE_AUTO_SELECT_WARNING_INFO: {
      return {
        ...state,
        autoSelectWarningInfo: {
          isOpen: false,
          termEndDate: 'XXXX年YY月ZZ日',
        },
      };
    }
    case OPEN_STRATEGIES_SUCCESS: {
      const { resultArray } = payload;

      return {
        ...state,
        strategiesSuccess: {
          isOpen: true,
          resultArray,
        },
      };
    }
    case CLOSE_STRATEGIES_SUCCESS: {
      return {
        ...state,
        strategiesSuccess: {
          isOpen: false,
          resultArray: [],
        },
      };
    }
    case OPEN_YOUTUBE_MODAL: {
      const { url } = payload;

      return {
        ...state,
        youtube: {
          isOpen: true,
          url,
        },
      };
    }
    case CLOSE_YOUTUBE_MODAL: {
      return {
        ...state,
        youtube: {
          isOpen: false,
          url: '',
        },
      };
    }
    case OPEN_LAB_TAG_INFO_MODAL: {
      return {
        ...state,
        labTagInfo: {
          isOpen: true,
        },
      };
    }
    case CLOSE_LAB_TAG_INFO_MODAL: {
      return {
        ...state,
        labTagInfo: {
          isOpen: false,
        },
      };
    }
    case OPEN_RANGE_OUT_CONFIRMATION: {
      const { parameters, linkUrl, messageId, serviceValues } = payload;
      return {
        ...state,
        rangeOutConfirmation: {
          isOpen: true,
          messageId,
          linkUrl,
          parameters,
          serviceValues,
        },
      };
    }
    case CLOSE_RANGE_OUT_CONFIRMATION: {
      return {
        ...state,
        rangeOutConfirmation: {
          isOpen: false,
          messageId: null,
          linkUrl: null,
          parameters: null,
          serviceValues: [],
        },
      };
    }
    case OPEN_TECH_LOGIC_INFO: {
      return {
        ...state,
        techLogicInfo: {
          isOpen: true,
        },
      };
    }
    case CLOSE_TECH_LOGIC_INFO: {
      return {
        ...state,
        techLogicInfo: {
          isOpen: false,
        },
      };
    }
    case OPEN_TUTORIAL_MODAL: {
      return {
        ...state,
        tutorialModal: {
          isOpen: true,
        },
      };
    }
    case CLOSE_TUTORIAL_MODAL: {
      return {
        ...state,
        tutorialModal: {
          isOpen: false,
        },
      };
    }
    case OPEN_TUTORIAL_PORTFOLIO_DETAIL_MODAL: {
      return {
        ...state,
        tutorialPortfolioDetail: {
          isOpen: true,
        },
      };
    }
    case CLOSE_TUTORIAL_PORTFOLIO_DETAIL_MODAL: {
      return {
        ...state,
        tutorialPortfolioDetail: {
          isOpen: false,
        },
      };
    }
    case OPEN_BEGIN_DEPOSIT_MODAL: {
      return {
        ...state,
        beginDepositModal: {
          isOpen: true,
        },
      };
    }
    case CLOSE_BEGIN_DEPOSIT_MODAL: {
      return {
        ...state,
        beginDepositModal: {
          isOpen: false,
        },
      };
    }
    case OPEN_BEGIN_RUN_AUTO_MODAL: {
      return {
        ...state,
        beginRunAutoModal: {
          isOpen: true,
        },
      };
    }
    case CLOSE_BEGIN_RUN_AUTO_MODAL: {
      return {
        ...state,
        beginRunAutoModal: {
          isOpen: false,
        },
      };
    }
    case OPEN_SEARCH_AUTO_SELECT_RULE_NAME_MODAL: {
      const { autoSelectData } = payload;
      return {
        ...state,
        searchAutoSelectRuleNameModal: {
          isOpen: true,
          autoSelectData,
        },
      };
    }
    case CLOSE_SEARCH_AUTO_SELECT_RULE_NAME_MODAL: {
      return {
        ...state,
        searchAutoSelectRuleNameModal: {
          isOpen: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
