import React, { memo, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './progress.module.scss';

export const Progress = memo(({ pages, currentPage, onNotFound }) => {
  const activePage = useMemo(() => pages.find(({ id }) => id === currentPage), [pages, currentPage]);
  const progress = useMemo(
    () => (
      <div className={styles.container}>
        <div className={styles.label}>{activePage?.title}</div>
        <div className={styles.progress}>
          {pages.map(({ id }) => (
            <div key={id} className={classNames(styles.dot, { [styles.active]: id === currentPage })}>
              ●
            </div>
          ))}
        </div>
      </div>
    ),
    [activePage, pages, currentPage],
  );

  useEffect(() => {
    if (activePage == null) {
      onNotFound();
    }
  }, [activePage, onNotFound]);

  if (activePage == null) {
    return null;
  }
  const { component: Component } = activePage;
  return <Component progress={progress} />;
});

Progress.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
  currentPage: PropTypes.number.isRequired,
  onNotFound: PropTypes.func.isRequired,
};
