import React, { memo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from '../../../../components';
import styles from './preventActionsScreen.module.scss';

const PreventKeyPressEventComponent = memo(() => {
  const preventKeyPress = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', preventKeyPress);
    return () => {
      document.removeEventListener('keydown', preventKeyPress);
    };
  }, [preventKeyPress]);
});

// add in this components another flags, where prevent user actions is needed
const PreventActionsScreen = () => {
  const isLoading = useSelector((state) => state.cart.currentDataExecuteIsLoading);

  return (
    <>
      {isLoading && (
        <>
          <PreventKeyPressEventComponent />
          <div className={styles.wrapper}>
            <Spin className={styles.loader} />
          </div>
        </>
      )}
    </>
  );
};

export default memo(PreventActionsScreen);
