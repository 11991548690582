/* eslint-disable-next-line import/no-unresolved */
import { useEffect, useRef, useState } from 'react';

export const useThrottle = (value, ms) => {
  const [state, setState] = useState(value);
  const timeoutRef = useRef(null);
  const nextValueRef = useRef(null);
  const hasNextValueRef = useRef(false);

  useEffect(() => {
    if (timeoutRef.current == null) {
      setState(value);
      const cb = () => {
        if (hasNextValueRef.current) {
          hasNextValueRef.current = false;
          setState(nextValueRef.current);
          timeoutRef.current = setTimeout(cb, ms);
        } else {
          timeoutRef.current = undefined;
        }
      };
      timeoutRef.current = setTimeout(cb, ms);
    } else {
      nextValueRef.current = value;
      hasNextValueRef.current = true;
    }
  }, [value, ms]);

  useEffect(
    () => () => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    },
    [],
  );

  return state;
};
