/* eslint-disable global-require */
const labImagesHelper = {
  'icon_a1.png': require('./defaultIconImages/icon_a1.png'),
  'icon_a2.png': require('./defaultIconImages/icon_a2.png'),
  'icon_a3.png': require('./defaultIconImages/icon_a3.png'),
  'icon_a4.png': require('./defaultIconImages/icon_a4.png'),
  'icon_a5.png': require('./defaultIconImages/icon_a5.png'),
  'icon_f1.png': require('./defaultIconImages/icon_f1.png'),
  'icon_f2.png': require('./defaultIconImages/icon_f2.png'),
  'icon_f3.png': require('./defaultIconImages/icon_f3.png'),
  'icon_f4.png': require('./defaultIconImages/icon_f4.png'),
  'icon_f5.png': require('./defaultIconImages/icon_f5.png'),
  'icon_h1.png': require('./defaultIconImages/icon_h1.png'),
  'icon_h2.png': require('./defaultIconImages/icon_h2.png'),
  'icon_h3.png': require('./defaultIconImages/icon_h3.png'),
  'icon_h4.png': require('./defaultIconImages/icon_h4.png'),
  'icon_h5.png': require('./defaultIconImages/icon_h5.png'),
  'icon_r1.png': require('./defaultIconImages/icon_r1.png'),
  'icon_r2.png': require('./defaultIconImages/icon_r2.png'),
  'icon_r3.png': require('./defaultIconImages/icon_r3.png'),
  'icon_r4.png': require('./defaultIconImages/icon_r4.png'),
  'icon_r5.png': require('./defaultIconImages/icon_r5.png'),
  excellent: require('./performanceImages/excellent.png'),
  verygood: require('./performanceImages/verygood.png'),
  good: require('./performanceImages/good.png'),
  bad: require('./performanceImages/bad.png'),
  'step1.png': require('./stepImages/icon_step1.png'),
  'step2.png': require('./stepImages/icon_step2.png'),
  'step3.png': require('./stepImages/icon_step3.png'),
  'step4.png': require('./stepImages/icon_step4.png'),
  'icon_crown.png': require('../badgeImages/icon_crown.png'),
  'addLabs.png': require('../buttonImages/addLabs.png'),
  'builders_lab_banner.png': require('./builders_lab_banner.png'),
  'builders_lab_banner_ETF.png': require('./builders_lab_banner_ETF.png'),
  // TODO CFD 暫定で ETF のコピー
  'builders_lab_banner_CFD.png': require('./builders_lab_banner_CFD.png'),
  'iconSelect.png': require('./iconSelect.png'),
};

export default labImagesHelper;
