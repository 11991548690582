import { memo } from 'react';
import { Spin } from '../../components';
import styles from './loading.module.scss';

const Loading = () => {
  return (
    <div className={styles.wrapper}>
      <Spin className={styles.loader} />
    </div>
  );
};

export default memo(Loading);
