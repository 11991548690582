import { memo, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TRADE_TABLES } from 'shared-modules/constants';
import { changeSelectedPortfolioAutoTradeTable } from '../../../../../../redux/actions';
import { Tabs } from '../../../../../../components';
import TutorialTableExecutions from './components/TutorialTableExecutions';
import TutorialTableOrders from './components/TutorialTableOrders';
import TutorialTablePositions from './components/TutorialTablePositions';
import { TutorialTableWrapper } from './components/TutorialTableWrapper';
import styles from './tutorialTables.module.scss';
import { tutorialExecutions, tutorialOrders, tutorialPositions } from '../../../../../../constants/tutorial/classNames';

const TutorialTables = ({ serviceId }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.webPortfolio.selectedTable);

  const changeActiveTab = useCallback(
    (tableId) => {
      dispatch(changeSelectedPortfolioAutoTradeTable({ tableId: Number(tableId) }));
    },
    [dispatch],
  );

  const tabItems = useMemo(() => {
    return [
      {
        id: TRADE_TABLES.ORDERS.ID,
        className: tutorialOrders,
        label: '注文設定',
        children: (
          <TutorialTableWrapper serviceId={serviceId}>
            <TutorialTableOrders serviceId={serviceId} />
          </TutorialTableWrapper>
        ),
      },
      {
        id: TRADE_TABLES.EXECUTIONS.ID,
        className: tutorialExecutions,
        label: '約定照会',
        children: (
          <TutorialTableWrapper serviceId={serviceId}>
            <TutorialTableExecutions serviceId={serviceId} />
          </TutorialTableWrapper>
        ),
      },
      {
        id: TRADE_TABLES.POSITIONS.ID,
        className: tutorialPositions,
        label: '建玉照会',
        children: (
          <TutorialTableWrapper serviceId={serviceId}>
            <TutorialTablePositions serviceId={serviceId} />
          </TutorialTableWrapper>
        ),
      },
    ];
  }, [serviceId]);

  return (
    <div className={styles.wrapper}>
      <Tabs containerClassName={styles.tabs} items={tabItems} activeKey={activeTab} onChange={changeActiveTab} />
    </div>
  );
};

TutorialTables.propTypes = {
  serviceId: PropTypes.string.isRequired,
};

export default memo(TutorialTables);
