import { CALL_HISTORY_METHOD } from '../actionConstants/routerConstants';

export const routerMiddleware = (history) => (_store) => (next) => (action) => {
  if (action.type !== CALL_HISTORY_METHOD) {
    return next(action);
  }

  const {
    payload: { method, args },
  } = action;
  history[method](...args);
  return null;
};
