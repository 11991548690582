import {
  START_LOADING,
  END_LOADING,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  RESET_LOGIN_ERROR,
  LOGOUT_USER,
  CLEAR_REDUX_STORE,
  GET_INITIAL_REQUESTS,
  GET_REFETCH_INITIAL_REQUESTS,
  GET_PUBLIC_INITIAL_REQUESTS,
  CHANGE_LOGIN_VALUES,
  LOGIN_FLOW_REQUEST,
  LOGIN_FLOW_START_LOADING,
  LOGIN_FLOW_STOP_LOADING,
  CHANGE_SERVICE_ID_REQUEST,
  CHANGE_SERVICE_ID_SUCCESS,
  RESET_IS_AUTHENTICATED_STATUS,
  UPDATE_USERNAME,
  UPDATE_AUTH_CONTEXT,
  CLEAR_STORAGE,
  OPEN_LOGIN_ALERT,
  CLOSE_LOGIN_ALERT,
  CHANGE_PORTAL_ID,
} from '../actionConstants/authConstants';

export const authStartLoading = () => ({ type: START_LOADING });
export const authEndLoading = () => ({ type: END_LOADING });

export const loginUserRequest = ({
  login,
  password,
  loginCheckbox,
  passwordCheckbox,
  accountType,
  successCallback,
  errorCallback,
  isMobile = false,
  isEmergency = false,
}) => ({
  type: LOGIN_USER_REQUEST,
  payload: {
    login,
    password,
    loginCheckbox,
    passwordCheckbox,
    accountType,
    successCallback,
    errorCallback,
    isMobile,
    isEmergency,
  },
});
export const loginUserSuccess = () => ({ type: LOGIN_USER_SUCCESS });
export const loginUserFailed = (payload) => ({ type: LOGIN_USER_FAILED, payload });

export const changeLoginValues = ({ inputName, value }) => ({
  type: CHANGE_LOGIN_VALUES,
  payload: { inputName, value },
});

export const changePortalId = ({ portalId }) => ({
  type: CHANGE_PORTAL_ID,
  payload: { portalId },
});

export const resetLoginError = (payload) => ({ type: RESET_LOGIN_ERROR, payload });

export const logoutUser = ({ successCallback, successAction } = {}) => ({
  type: LOGOUT_USER,
  // to not affect other pure logout function elsewhere:
  payload: { successCallback, successAction },
});

export const clearReduxStore = (force) => ({ type: CLEAR_REDUX_STORE, payload: { force: force === true } });

export const clearStorage = () => ({ type: CLEAR_STORAGE });

export const getInitialRequests = ({ withoutMasterData } = {}) => ({
  type: GET_INITIAL_REQUESTS,
  payload: { isRefetch: false, withoutMasterData: !!withoutMasterData },
});
export const getRefetchInitialRequests = () => ({
  type: GET_REFETCH_INITIAL_REQUESTS,
  payload: { isRefetch: true },
});
export const getPublicInitialRequests = () => ({ type: GET_PUBLIC_INITIAL_REQUESTS });

export const loginFlowRequest = ({ callbackAction, startup, sessionExpiryErrorHandler } = {}) => ({
  type: LOGIN_FLOW_REQUEST,
  payload: { callbackAction, startup, sessionExpiryErrorHandler },
});
export const loginFlowStartLoading = () => ({ type: LOGIN_FLOW_START_LOADING });
export const loginFlowStopLoading = () => ({ type: LOGIN_FLOW_STOP_LOADING });

export const changeServiceIdRequest = ({ serviceId, localMode, reload, successActionCreator }) => ({
  type: CHANGE_SERVICE_ID_REQUEST,
  payload: { serviceId, localMode, reload, successActionCreator },
});
export const changeServiceIdSuccess = ({ serviceId, localMode }) => ({
  type: CHANGE_SERVICE_ID_SUCCESS,
  payload: { serviceId, localMode },
});

export const resetIsAuthenticatedStatus = () => ({ type: RESET_IS_AUTHENTICATED_STATUS });

export const updateUsername = ({ username }) => ({ type: UPDATE_USERNAME, payload: { username } });

export const updateAuthContext = (authContext) => ({ type: UPDATE_AUTH_CONTEXT, payload: authContext });

export const openLoginAlert = () => ({ type: OPEN_LOGIN_ALERT });
export const closeLoginAlert = () => ({ type: CLOSE_LOGIN_ALERT });
