import Decimal from 'decimal.js';
import {
  FX,
  CHART_MAKE_CHANGE_TARGET_OPTIONS,
  CHART_MAKE_MAX_NUM_OF_ORDERS,
  CHART_MAKE_LOGIC_NAME,
} from '../../../../constants/index';
import logicData from '../logics.json';

const Logic = (inputType, inputHigh, inputLow) => {
  const type = inputType;
  const high = inputHigh;
  const low = inputLow;
  return {
    calc(hendou) {
      let step = 0;
      do {
        // 近似1日変動値幅とstepでレンジの幅、その幅から本数を計算
        this.calcAndSetRangeSettings(hendou, step);

        // stepを0.1加算
        step = Decimal.add(step, 0.1).toNumber();
      } while (this.getNumOfOrders() > 100);
      this.calcAfterFixedWidthProcess();
      this.calcAndSetTp();
      this.backupDefaultSettings();
      this.setMaxMinForDisp();
    },
    calcAndSetTp() {
      this.getAllRanges().forEach((x) => x.calcAndSetTp());
    },
    setMaxMinForDisp() {
      this.getAllRanges().forEach((x) => {
        x.setMinForDisp(x.getMin());
        x.setMaxForDisp(x.getMax());
      });
    },
    recalc(settings) {
      this.backupCurrentSettings();
      const validateSettingsResult = this.validateSettings(settings);
      if (!validateSettingsResult.isValid) {
        return validateSettingsResult;
      }
      const recalcResource = this.getRecalcResource(settings);
      if (!recalcResource.isValid) {
        return recalcResource;
      }

      if (settings.createAps && Object.entries(settings.createAps).filter((x) => x !== null).length) {
        recalcResource.targetRange.setApSettings(settings.createAps);
      }
      if (!settings.recalc || !Object.entries(settings.recalc).filter((x) => x !== null).length) {
        return { isValid: true };
      }
      if (settings.recalc.numOfOrders) {
        const validateNumOfOrdersResult = this.validateNumOfOrders(settings.target, settings.recalc.numOfOrders);
        if (!validateNumOfOrdersResult.isValid) {
          this.rollbackSettings();
          return validateNumOfOrdersResult;
        }
      }

      // ターゲットレンジ再計算
      const recalcResult = recalcResource.targetRange.recalcWithStartPrice(
        settings.recalc.startPrice,
        settings.recalc.rangeWidth,
        settings.recalc.numOfOrders,
      );
      if (!recalcResult.isValid) {
        this.rollbackSettings();
        return recalcResult;
      }

      // ターゲットより上のレンジはターゲットのmaxを基準にずらす
      let prevMax = recalcResource.targetRange.getMax();
      recalcResource.upperRanges.forEach((x) => {
        x.recalc(prevMax, null, null, false);
        x.setMaxForDisp(x.getMax());
        x.setMinForDisp(x.getMin());
        prevMax = x.getMax();
      });
      // ターゲットより下のレンジはターゲットのminを基準にずらす
      let prevMin = recalcResource.targetRange.getMin();
      recalcResource.lowerRanges.forEach((x) => {
        x.recalc(null, prevMin, null, false);
        x.setMaxForDisp(x.getMax());
        x.setMinForDisp(x.getMin());
        prevMin = x.getMin();
      });
      // レンジチェック
      const validateRangeResult = this.validateRange();
      if (!validateRangeResult.isValid) {
        this.rollbackSettings();
        return validateRangeResult;
      }
      return { isValid: true };
    },
    validateRange() {
      let result = { isValid: true };
      if (this.getAllRanges().some((x) => x.getMax() <= 0 || x.getMin() <= 0)) {
        result = {
          isValid: false,
          errorMessage: 'この設定では価格が範囲外となるレンジが存在するため注文変更できません。',
        };
      }
      if (this.getAllRanges().some((x) => x.getNumOfOrders() === 0)) {
        result = {
          isValid: false,
          errorMessage: 'この設定では本数が0となるレンジが存在するため注文変更できません。',
        };
      }
      return result;
    },
    validateNumOfOrders(targetRangeId, newNumOfOrders) {
      const targetRange = this.getRangeById(targetRangeId);
      if (!targetRange) {
        return { isValid: false, errorMessage: 'レンジ指定が不正です。' };
      }
      return this.validateNumOfOrdersByRange(targetRange, newNumOfOrders);
    },
    validateNumOfOrdersByRange(targetRange, newNumOfOrders) {
      if (newNumOfOrders < 1) {
        return {
          isValid: false,
          errorMessage: '注文本数は1本以上で設定してください。',
        };
      }
      const otherRangeNumOfOrders = this.getAllRanges()
        .filter((x) => x !== targetRange)
        .map((x) => x.getNumOfOrders())
        ?.reduce((x, y) => x + y, 0);
      if (otherRangeNumOfOrders + newNumOfOrders > CHART_MAKE_MAX_NUM_OF_ORDERS) {
        return {
          isValid: false,
          errorMessage: `本数はロジック合計${CHART_MAKE_MAX_NUM_OF_ORDERS}本以下でご設定ください。`,
        };
      }
      if (targetRange.validateNumOfOrders) {
        return targetRange.validateNumOfOrders(newNumOfOrders);
      }
      return { isValid: true };
    },
    getMinRangeWidth(targetRangeId, newNumOfOrders) {
      const targetRange = this.getRangeById(targetRangeId);
      if (!targetRange) {
        return { isValid: false, errorMessage: 'レンジ指定が不正です。' };
      }
      const validateNumOfOrdersResult = this.validateNumOfOrdersByRange(targetRange, newNumOfOrders);
      if (!validateNumOfOrdersResult.isValid) {
        return validateNumOfOrdersResult;
      }
      return targetRange.getMinRangeWidth(newNumOfOrders);
    },
    validateSettings(settings) {
      if (!settings || !settings.recalc) {
        return { isValid: false, errorMessage: '注文変更設定が不正です。' };
      }
      if (!CHART_MAKE_CHANGE_TARGET_OPTIONS[FX][type].some((x) => x.id === settings.target)) {
        return { isValid: false, errorMessage: 'レンジ指定が不正です。' };
      }
      const errors = [];
      if (settings.recalc.startPrice !== null && settings.recalc.startPrice < 0) {
        errors.push('価格帯');
      }
      if (settings.recalc.rangeWidth !== null && settings.recalc.rangeWidth < 0) {
        errors.push('レンジ幅');
      }
      if (settings.recalc.numOfOrders !== null && settings.recalc.numOfOrders <= 0) {
        errors.push('注文本数');
      }
      if (settings.createAps) {
        if (settings.createAps.quantity !== null && settings.createAps.quantity <= 0) {
          errors.push('数量');
        }
      }
      if (errors.length > 0) {
        return { isValid: false, errorMessage: `${errors.join(', ')}の設定が不正です。` };
      }
      return { isValid: true };
    },
    getRangeList() {
      return Object.entries(CHART_MAKE_CHANGE_TARGET_OPTIONS[FX][type]).map((x) => x[1]);
    },
    getRangeById(rangeId) {
      if (!CHART_MAKE_CHANGE_TARGET_OPTIONS[FX][type].some((x) => x.id === rangeId)) {
        return null;
      }
      return this.getAllRanges()[this.getRangeList().findIndex((x) => x.id === rangeId)];
    },
    reset() {
      this.getAllRanges().forEach((x) => x.reset());
    },
    getSettings(rangeId) {
      return this.getRangeById(rangeId)?.getSettings();
    },
    getAllSettings() {
      return this.getAllRanges().map((x) => x.getSettings());
    },
    getHigh() {
      return high;
    },
    getLow() {
      return low;
    },
    getType() {
      return type;
    },
    getName() {
      return CHART_MAKE_LOGIC_NAME[type];
    },
    getDispInfo() {
      const data = logicData.filter((x) => x.name === type)[0];
      return {
        id: data.id,
        title: this.getName(),
        image: data.image,
        description: data.description,
        descriptionImage: data.descriptionImage,
      };
    },
    getStartPriceHelpText() {
      const data = logicData.filter((x) => x.name === type)[0];
      return data.startPriceHelpText;
    },
    getNumOfOrdersHelpText() {
      const data = logicData.filter((x) => x.name === type)[0];
      return data.numOfOrdersHelpText;
    },
    createAps() {
      return this.createUnsortedAps().sort((a, b) => {
        if (a.side > b.side) {
          return -1;
        }
        if (a.side < b.side) {
          return 1;
        }
        if (a.entryPrice1 > b.entryPrice1) {
          return -1;
        }
        if (a.entryPrice1 < b.entryPrice1) {
          return 1;
        }
        return 0;
      });
    },
  };
};
export default Logic;
