import React, { memo } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { FX } from 'shared-modules/constants';
import styles from '../steps.module.scss';

const Step4 = ({ serviceId }) => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <div>
          <p>
            指定したレンジ幅の中に何本の注文を設定するかを決めます。
            <br />
            注文は等間隔に設定されます。
            <br />
            注文間隔{serviceId === FX && '（pips）'}＝レンジ幅÷本数
          </p>
          {serviceId === FX && (
            <p className={styles.subContent}>
              <br />※ pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

Step4.propTypes = {
  serviceId: propTypes.string,
};

Step4.defaultProps = {
  serviceId: '',
};

export default memo(Step4);
