import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { batch, useDispatch, useSelector } from 'react-redux';
import { COLORS, MODAL_SIZES, WIDTH } from 'shared-modules/constants';
import { changeBeforePortfolioDataSave, removeFailData } from 'shared-modules/redux/multiEdit';
import { CONNECTION_TIMEOUT_CODE } from 'shared-modules/constants/apiConstant';
import { closePortfolioAutoTradeDetailModal, openMultiEdit } from '../../../../redux/actions';
import Modal from '../../../../components/Modal';
import CustomButton from '../../../../components/CustomButton';
import styles from './multiEditFail.module.scss';

const MultiEditFail = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const messageTimeOut = '設定変更に失敗した可能性があります。注文設定をご確認ください。';
  const selectedApGroupData = useSelector((state) => state.portfolio.selectedApGroupData);
  const { data: portfolioAutoTradeCardData } = useSelector((state) => state.modals.portfolioAutoTradeDetail);

  const reChange = useCallback(() => {
    batch(() => {
      closeModal();
      dispatch(changeBeforePortfolioDataSave({ cardData: portfolioAutoTradeCardData, selectedApGroupData }));
      dispatch(closePortfolioAutoTradeDetailModal());
      dispatch(openMultiEdit());
    });
  }, [dispatch, closeModal, selectedApGroupData, portfolioAutoTradeCardData]);

  const onClose = useCallback(() => {
    dispatch(removeFailData());
    closeModal();
  }, [dispatch, closeModal]);

  const failData = useSelector((state) => state.multiEdit.failData);
  const failedMessage = useCallback(() => {
    const element = failData.map((item) => {
      const message = `注文名：${item.apName}\n${
        item.statusCode === CONNECTION_TIMEOUT_CODE ? messageTimeOut : item.failedMessage
      }\n`;
      return (
        <div key={item.apId} className={styles.failedMessage}>
          {message}
        </div>
      );
    });
    return element;
  }, [failData]);

  const title = `一括変更失敗`;

  return (
    <Modal isOpen={isOpen} title={title} isOverlap size={MODAL_SIZES.WIDTH_400} withoutCloseButton>
      <div className={styles.wrapper}>変更に失敗した注文設定があります。</div>
      <div className={styles.failedMessageArea}>{failedMessage()}</div>
      <div className={styles.buttonRow}>
        <CustomButton onClick={onClose} width={WIDTH.PERCENTAGE_48}>
          閉じる
        </CustomButton>
        <CustomButton onClick={reChange} width={WIDTH.PERCENTAGE_48} color={COLORS.GREEN}>
          編集する
        </CustomButton>
      </div>
    </Modal>
  );
};

MultiEditFail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(MultiEditFail);
