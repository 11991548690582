import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDangerLevelNotificationCard } from 'shared-modules/hooks/portfolio';
import { push } from 'shared-modules/redux/actions/routerActions';
import { changeServiceIdRequest } from 'shared-modules/redux/actions';
import { useSummaryInfoByServiceId } from 'shared-modules/services/hooks';
import { CASH } from '../../../../constants';
import { mockSummaryInfoHasApGroup, mockSummaryInfoNoApGroup } from '../../../../constants/tutorial/otherMockData';
import { ForwardLink } from '../../../../components';
import styles from './dangerLevelNotificationCard.module.scss';

export const DangerLevelNotificationCard = memo(({ serviceId, hasApGroup }) => {
  const dispatch = useDispatch();
  const actualSummaryInfo = useSummaryInfoByServiceId(serviceId);
  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);
  const mockSummaryInfo = hasApGroup ? mockSummaryInfoHasApGroup[serviceId] : mockSummaryInfoNoApGroup[serviceId];
  const summaryInfo = tutorialMode ? mockSummaryInfo : actualSummaryInfo;
  const { hide, message, label } = useDangerLevelNotificationCard({ serviceId, data: summaryInfo });
  const handleClick = useCallback(() => {
    dispatch(push(`/${CASH}`));
    dispatch(changeServiceIdRequest({ serviceId }));
  }, [dispatch, serviceId]);
  if (hide) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.rowAlignLeft}>
        <div className={styles.text}>{message}</div>
      </div>
      <div className={styles.rowAlignRight}>
        <ForwardLink className={styles.forwardLink} onClick={handleClick}>
          {label}
        </ForwardLink>
      </div>
    </div>
  );
});

DangerLevelNotificationCard.propTypes = {
  serviceId: PropTypes.string.isRequired,
  hasApGroup: PropTypes.bool,
};

DangerLevelNotificationCard.defaultProps = {
  hasApGroup: false,
};
