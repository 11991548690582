import React, { memo, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { MODAL_SIZES, WIDTH, MAIL_ACTIONS, MAIL_SETTINGS_NO_EMAIL_SELECTED } from 'shared-modules/constants';
import { processMailList, sortMailActions, processMailActionsBeforeUpdating } from 'shared-modules/services';
import { getServiceName } from 'shared-modules/utils';
import { useAccountInfo } from 'shared-modules/hooks/useAccountInfo';
import { useCheckAndNotifyAvailableService } from 'shared-modules/hooks/service';
import {
  updateUserMailSettingsRequest,
  sendNotificationError,
  getUserMailSettingsRequest,
} from '../../../../redux/actions';
import { Button, Modal, Spin, Tabs } from '../../../../components';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import styles from './notificationSettings.module.scss';

const DELAY_CHANGING_DISPLAY_ITEMS = 1000;

const CheckboxWrapper = ({ item, onChangeHandler }) => {
  const handleToggleCheckbox = useCallback(() => {
    onChangeHandler((prevValue) =>
      prevValue.map((currencyPair) => {
        if (currencyPair.id === item.id) {
          return {
            ...currencyPair,
            selected: !currencyPair.selected,
          };
        }
        return currencyPair;
      }),
    );
  }, [onChangeHandler, item.id]);

  return (
    <CustomCheckbox
      isChecked={item.selected}
      onChange={item.disabled ? () => {} : handleToggleCheckbox} // if it's disabled, don't trigger any function
      label={item.label}
      className={styles.checkbox}
      labelClassName={styles.labelClassName}
      isDisabled={item.disabled}
      isHidden={item.hidden}
    />
  );
};
CheckboxWrapper.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  item: PropTypes.shape({
    selected: PropTypes.bool.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
  }).isRequired,
};

const NotificationSettings = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();

  const [locallySelectedMailingList, changeLocallySelectedMailingList] = useState([]);
  const [locallySelectedType, changeLocallySelectedType] = useState([]);
  const [changingDisplayItems, setChangingDisplayItems] = useState(false);

  const storeServiceId = useSelector((state) => state.auth.serviceId);
  // localServiceIdを依存関係に加えると不要な副作用が発生するため仕方なく値を更新してもレンダリングが走らないrefを用意
  const localServiceIdRef = useRef('');
  const { addresses, mailActions: mailActionsSource, isLoading } = useSelector((state) => state.settings.mailSettings);
  const accountInfo = useAccountInfo();

  const { primaryMailAddress, secondaryMailAddress } = addresses[localServiceIdRef.current || storeServiceId];

  const changeServiceId = useCallback(
    (serviceId) => {
      // 背景表示のポートフォリオを更新させないためにlocalのserviceIdを変更する
      localServiceIdRef.current = serviceId;
      dispatch(getUserMailSettingsRequest({ serviceId }));
    },
    [dispatch],
  );

  // モーダルOpen時の初回メール設定取得
  useEffect(() => {
    if (isOpen && localServiceIdRef.current !== storeServiceId) {
      changeServiceId(storeServiceId);
    }
  }, [dispatch, isOpen, accountInfo, changeServiceId, storeServiceId]);

  useEffect(() => {
    setChangingDisplayItems(true);

    const processedMailList = processMailList(
      primaryMailAddress,
      secondaryMailAddress,
      mailActionsSource[localServiceIdRef.current],
    );
    const sortedMailActions = sortMailActions(mailActionsSource[localServiceIdRef.current]);

    changeLocallySelectedMailingList(processedMailList);
    changeLocallySelectedType(sortedMailActions);

    const timeoutId = setTimeout(() => {
      setChangingDisplayItems(false);
    }, DELAY_CHANGING_DISPLAY_ITEMS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    primaryMailAddress,
    secondaryMailAddress,
    mailActionsSource,
    isOpen,
    changeLocallySelectedMailingList,
    changeLocallySelectedType,
  ]);

  const checkAndNotifyAvailableService = useCheckAndNotifyAvailableService();
  const onChangeTab = useCallback(
    (tabServiceId) => {
      if (!isOpen) {
        return;
      }
      if (checkAndNotifyAvailableService(accountInfo, tabServiceId)) {
        return;
      }
      changeServiceId(tabServiceId);
    },
    [isOpen, accountInfo, changeServiceId, checkAndNotifyAvailableService],
  );

  const handleUpdateNotificationSettings = useCallback(() => {
    const isValid = locallySelectedMailingList.some((item) => item.selected);

    if (!isValid) {
      dispatch(sendNotificationError({ message: MAIL_SETTINGS_NO_EMAIL_SELECTED }));
      return;
    }

    if (checkAndNotifyAvailableService(accountInfo, localServiceIdRef.current)) {
      return;
    }

    const processedMailActions = processMailActionsBeforeUpdating(locallySelectedMailingList, locallySelectedType);

    dispatch(
      updateUserMailSettingsRequest({
        primaryMailAddress,
        secondaryMailAddress,
        mailActions: processedMailActions,
        serviceId: localServiceIdRef.current,
      }),
    );
  }, [
    locallySelectedMailingList,
    locallySelectedType,
    accountInfo,
    dispatch,
    primaryMailAddress,
    secondaryMailAddress,
    checkAndNotifyAvailableService,
  ]);

  const displayCheckboxes = useMemo(
    () =>
      MAIL_ACTIONS.reduce((acc, item) => {
        const action = locallySelectedType.find((el) => el.id === item.id);
        if (action) acc.push(action);
        return acc;
      }, []),
    [locallySelectedType],
  );

  const tabItem = useMemo(
    () => (
      <div className={styles.wrapper}>
        <div className={styles.mailingListHeader}>通知配信先</div>

        {isLoading || changingDisplayItems ? (
          <div className={styles.emptyBlock}>
            <Spin className={styles.loader} />
          </div>
        ) : (
          <>
            <div className={styles.mailingListCheckboxContainer}>
              {locallySelectedMailingList.map((item) => (
                <CheckboxWrapper key={item.id} onChangeHandler={changeLocallySelectedMailingList} item={item} />
              ))}
            </div>
            <div className={styles.typeHeader}>通知種別</div>
            <div className={styles.typeCheckboxContainer}>
              <div className={styles.checkboxWrapper}>
                {displayCheckboxes.map((item) => (
                  <CheckboxWrapper key={item.id} onChangeHandler={changeLocallySelectedType} item={item} />
                ))}
              </div>
            </div>
            <Button width={WIDTH.PERCENTAGE_100} className={styles.button} onClick={handleUpdateNotificationSettings}>
              保存
            </Button>
          </>
        )}
      </div>
    ),
    [
      isLoading,
      changingDisplayItems,
      locallySelectedMailingList,
      changeLocallySelectedMailingList,
      displayCheckboxes,
      changeLocallySelectedType,
      handleUpdateNotificationSettings,
    ],
  );

  const tabsOptions = useMemo(
    () =>
      ALL_SERVICES.map((service) => ({
        id: service,
        value: getServiceName(service),
        children: tabItem,
        isVisuallyDisabled: accountInfo[service].isNotAvailable,
      })),
    [accountInfo, tabItem],
  );

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} size={MODAL_SIZES.WIDTH_512} title="メール通知設定">
      <Tabs
        containerClassName={styles.tabs}
        items={tabsOptions}
        activeKey={localServiceIdRef.current}
        onChange={onChangeTab}
      />
    </Modal>
  );
};
NotificationSettings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(NotificationSettings);
