import PropTypes from 'prop-types';
import React, { useMemo, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveSelectedTableRows } from 'shared-modules/redux/multiEdit';
import { store } from '../../../../../../../redux/store';
import CustomCheckbox from '../../../../../../../components/CustomCheckbox';
import { openMultiEditCheckComfirm } from '../../../../../../../redux/actions';

const MultiEditTableCheckbox = ({ rowNum }) => {
  const dispatch = useDispatch();

  const currentRow = useSelector((state) => state.multiEdit.selectedTableRows[rowNum]);
  const isChecked = useMemo(() => currentRow?.isChecked ?? false, [currentRow]);
  const key = store.getState().multiEdit.selectedTableRows[0].checkKey;

  const { existingCheckbox } = useSelector((state) => state.multiEdit);
  const { isAllSameValue } = useSelector((state) => state.multiEdit.selectedTableRows[0]);

  const onChange = useCallback(() => {
    if (rowNum === 0) {
      // header checked
      if (isAllSameValue) dispatch(saveSelectedTableRows({ rowNum }));
      else dispatch(openMultiEditCheckComfirm({ rowNum }));
      return;
    }
    if (existingCheckbox === null || existingCheckbox[key] === currentRow[key]) {
      // row check and never checked
      dispatch(saveSelectedTableRows({ rowNum }));
    } else {
      dispatch(openMultiEditCheckComfirm({ rowNum }));
    }
  }, [rowNum, existingCheckbox, currentRow, key, dispatch, isAllSameValue]);

  return <CustomCheckbox isChecked={isChecked} onChange={onChange} />;
};

MultiEditTableCheckbox.propTypes = {
  rowNum: PropTypes.number,
};

MultiEditTableCheckbox.defaultProps = {
  rowNum: 0,
};

export default memo(MultiEditTableCheckbox);
