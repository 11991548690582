import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Spin } from '../Spin';
import styles from './table.module.scss';

export const TableLoader = memo(({ loading, style }) => {
  return (
    loading && (
      <div className={classNames(styles.loaderContainer, style)}>
        <Spin className={styles.loader} />
      </div>
    )
  );
});

TableLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  style: PropTypes.string,
};

TableLoader.defaultProps = {
  style: '',
};
