import { COMPLETE_INITIALIZATION, START_INITIALIZATION } from '../actionConstants/eventConstants';

export const initialState = {
  isInitializing: false,
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case START_INITIALIZATION: {
      return {
        ...state,
        isInitializing: true,
      };
    }
    case COMPLETE_INITIALIZATION: {
      return {
        ...state,
        isInitializing: false,
      };
    }
    default: {
      return state;
    }
  }
};
