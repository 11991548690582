import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  MODAL_SIZES,
  LAB_POST_SUCCESS_TITLE,
  LAB_POST_SUCCESS_ROW1,
  LAB_POST_SUCCESS_ROW2,
} from 'shared-modules/constants';
import { Button, Modal } from '../../../../../../components';
import icon from '../../../../../../assets/builder/done.png';
import styles from './postLabSuccessModal.module.scss';

export const PostLabSuccessModal = memo(({ isOpen, closeModal, okHandler }) => {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      size={MODAL_SIZES.WIDTH_400}
      isOverlap
      isOutsideClickDontClose
      withoutCloseButton
    >
      <div className={styles.body}>
        <div className={styles.title}>{LAB_POST_SUCCESS_TITLE}</div>
        <div className={styles.msg}>
          <div>{LAB_POST_SUCCESS_ROW1}</div>
          <div>{LAB_POST_SUCCESS_ROW2}</div>
        </div>
        <img src={icon} className={styles.icon} alt="完了" />
        <div className={styles.buttonArea}>
          <Button onClick={okHandler}>OK</Button>
        </div>
      </div>
    </Modal>
  );
});

PostLabSuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  okHandler: PropTypes.func.isRequired,
};
