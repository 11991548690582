import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BUY_SELL_CHART_ID_BY_ID } from 'shared-modules/constants';
import { UPDATE_BUILDER_MOBILE_CHART } from 'shared-modules/constants/apiConstant';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { isJsonString } from 'shared-modules/services';
import { useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import {
  socketConnectionRequest,
  getInstrumentListSuccess,
  createInstrumentsOptions,
  getRatesSuccess,
} from '../../redux/actions';
import { TechPriceChart } from '../../components';
import styles from './techBuilderChart.module.scss';

const TechBuilderChart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // socketをpublicモードで接続する
    dispatch(socketConnectionRequest({ isPublic: true }));

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(UPDATE_BUILDER_MOBILE_CHART);
    }
  }, [dispatch]);

  const instrumentOptions = useApOrManualInstrumentOptions(false);
  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const allRequestsCompleted = useMemo(
    () => Boolean(Object.keys(instrumentList).length && Object.values(instrumentOptions).every((o) => o.length)),
    [instrumentList, instrumentOptions],
  );

  const [activeCurrency, setActiveCurrency] = useState(null);
  const [selectedSellBuyId, setSelectedSellBuyId] = useState(null);
  const [barType, setBarType] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [indicator1, setIndicator1] = useState(null);
  const [indicator2, setIndicator2] = useState(null);

  const allVariableNotNull = ![activeCurrency, selectedSellBuyId, barType, serviceId, indicator1, indicator2].some(
    (i) => i === null,
  );

  const windowEventListener = useCallback(
    ({ data }) => {
      if (!data || !isJsonString(data)) {
        return;
      }
      const parsedMessage = JSON.parse(data);
      if (parsedMessage.message === UPDATE_BUILDER_MOBILE_CHART) {
        setActiveCurrency(parsedMessage.payload.activeCurrency);
        setSelectedSellBuyId(parsedMessage.payload.selectedSellBuyId);
        setBarType(parsedMessage.payload.barType);
        setServiceId(parsedMessage.payload.serviceId);
        setIndicator1(parsedMessage.payload.indicator1);
        setIndicator2(parsedMessage.payload.indicator2);
        dispatch(getRatesSuccess({ rates: parsedMessage.payload.rates }));
        dispatch(getInstrumentListSuccess({ instrumentList: parsedMessage.payload.instrumentList }));
        ALL_SERVICES.forEach((service) => {
          dispatch(
            createInstrumentsOptions({
              serviceId: service,
              options: parsedMessage.payload[`${service}InstrumentsOptions`],
            }),
          );
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', windowEventListener);
    document.addEventListener('message', windowEventListener);
    return () => {
      window.removeEventListener('message', windowEventListener);
      document.removeEventListener('message', windowEventListener);
    };
  }, [windowEventListener]);

  return (
    <div className={styles.chartWrapper}>
      {allVariableNotNull && allRequestsCompleted && (
        <TechPriceChart
          selectedCurrencyPairId={activeCurrency}
          selectedSide={BUY_SELL_CHART_ID_BY_ID[selectedSellBuyId]}
          resolution={barType}
          serviceId={serviceId}
          instrumentOptions={instrumentOptions}
          instrumentList={instrumentList}
          indicator1={indicator1}
          indicator2={indicator2}
          isMobile
        />
      )}
    </div>
  );
};

export default memo(TechBuilderChart);
