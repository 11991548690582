import { memo } from 'react';
import PropTypes from 'prop-types';
import { TECH_BASE_PRICE_LITERAL } from 'shared-modules/constants/builder';
import ValueWithPrecision from '../ValueWithPrecision';

export const TechEntryPriceTableCell = memo(({ cell }) => {
  const symbol = cell.value.substring(TECH_BASE_PRICE_LITERAL.length, TECH_BASE_PRICE_LITERAL.length + 1);

  const sliceIndex = cell.value.indexOf(symbol) + 1;
  const value = Number(cell.value.slice(sliceIndex));
  return (
    <>
      <div>スタート価格</div>
      {symbol}
      {value ? (
        <ValueWithPrecision
          value={Number.isFinite(value) && value !== null ? value : null}
          instrumentId={cell.row.original.instrumentId}
        />
      ) : (
        `0`
      )}
    </>
  );
});

TechEntryPriceTableCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    row: PropTypes.shape({
      original: PropTypes.shape({
        instrumentId: PropTypes.string,
      }),
    }),
  }).isRequired,
};
