import { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { BUY_SELL_VALUE, TRADE_TYPES, AP_GROUP_SOURCES, FX } from 'shared-modules/constants';
import { checkForInteger, getServiceQuantityUnit } from 'shared-modules/services';
import {
  useCalculateUnrealizedProfitLossForPortfolioCard,
  useCalculateQuantityForPortfolioCard,
} from 'shared-modules/services/hooks';
import { getIsTechCalc, useUnrealizedProfitLossForParentGroup } from 'shared-modules/hooks/portfolio';
import { tutorialCard } from '../../../../constants/tutorial/classNames';
import CardHeader from '../../../../components/CardHeader';
import { ColoredPriceText, NumberText } from '../../../../components/Texts';
import { openPortfolioAutoTradeDetailModal, openPortfolioManualTradeDetailModal } from '../../../../redux/actions';
import styles from './portfolioCard.module.scss';

const PortfolioCard = ({ isGrouped, cardData, onClick, isTech }) => {
  const {
    type,
    currency,
    groupName,
    count,
    realizedProfitLoss,
    buySell,
    id,
    image,
    activeCount,
    serviceIds,
    sourceType,
    status,
    apGroupIdList,
    children,
    parentName,
    parentImage,
    parentId,
  } = cardData;

  const instrumentList = useSelector((state) => state.settings.instrumentList);
  let serviceId = null;
  if (serviceIds) {
    if (serviceIds.length === 1) {
      [serviceId] = serviceIds;
    }
  } else {
    serviceId = instrumentList[currency]?.serviceId;
  }

  const displayedType = useMemo(() => {
    if (type === TRADE_TYPES.TRADE.NAME) {
      return `${type}(${BUY_SELL_VALUE[buySell]})`;
    }
    return type;
  }, [type, buySell]);

  const isTechCalc = getIsTechCalc(cardData);

  const unrealizedProfitLoss = useCalculateUnrealizedProfitLossForPortfolioCard({
    instrumentId: currency,
    apGroupId: id,
    buySell,
    apGroupIdList,
    isTechCalc,
  });

  const calculateQuantity = useCalculateQuantityForPortfolioCard({
    instrumentId: currency,
    apGroupId: id,
    buySell,
    defaultValue: count,
    apGroupIdList,
    isTechCalc,
  });

  let instrument = null;
  if (cardData.children == null) {
    instrument = instrumentList?.[currency];
  } else {
    // children != null の場合 currency === '-'
    const currencySet = new Set(cardData.children.map((child) => child.currency));
    if (currencySet.size === 1) {
      instrument = instrumentList?.[Array.from(currencySet.values())[0]];
    }
  }
  const symbolName = instrument?.serviceId === FX ? instrument?.symbol : instrument?.shortName;

  const dispatch = useDispatch();
  const handleOpenModal = useCallback(() => {
    if (isTech || children?.length || (sourceType === AP_GROUP_SOURCES.TECH.KEY && apGroupIdList)) {
      onClick();
      return;
    }

    if (type === TRADE_TYPES.TRADE.NAME) {
      dispatch(openPortfolioManualTradeDetailModal({ data: cardData }));
    } else {
      dispatch(openPortfolioAutoTradeDetailModal({ data: cardData }));
    }
  }, [dispatch, cardData, type, onClick, sourceType, children?.length, apGroupIdList, isTech]);

  const childNum = useMemo(() => {
    if (Number.isFinite(apGroupIdList?.length)) {
      return apGroupIdList?.length;
    }
    if (Number.isFinite(children?.length)) {
      return children?.length;
    }

    return null;
  }, [apGroupIdList, children]);

  const {
    needParentCalculation,
    unrealizedProfitLoss: unrealizedProfitLossForParentGroup,
    observer,
  } = useUnrealizedProfitLossForParentGroup(cardData);

  const unrealizedPnl = useMemo(() => {
    if (needParentCalculation) {
      return unrealizedProfitLossForParentGroup;
    }
    return unrealizedProfitLoss;
  }, [needParentCalculation, unrealizedProfitLoss, unrealizedProfitLossForParentGroup]);

  return (
    <div className={classNames(styles.card, tutorialCard)} aria-hidden="true" onClick={handleOpenModal}>
      {observer}
      <CardHeader
        displayedType={displayedType}
        image={(parentId && childNum) || (parentId && children?.length == null && isGrouped) ? parentImage : image}
        currency={currency}
        activeCount={activeCount}
        serviceIds={serviceIds}
        childNum={childNum}
        status={status}
        type={type}
        apGroupId={id}
        childApGroups={children}
        sourceType={sourceType}
        isShare={sourceType === 'lab'}
      />

      {type === TRADE_TYPES.AUTO.NAME && (
        <div className={styles.groupName} data-tip={parentId && childNum ? parentName : groupName} data-for={id}>
          {parentId && childNum ? parentName : groupName}
          <ReactTooltip id={id} />
        </div>
      )}
      {symbolName && <p className={styles.symbolName}>{symbolName}</p>}
      {!(serviceIds && serviceIds.length > 1) && (
        <div className={styles.row}>
          <div className={styles.label}>建玉数量</div>
          <NumberText
            className={styles.value}
            symbolClassName={styles.symbol}
            value={checkForInteger(calculateQuantity === 0 ? '-' : calculateQuantity)}
            symbol={getServiceQuantityUnit(serviceId)}
          />
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.label}>評価損益</div>
        <ColoredPriceText className={styles.value} symbolClassName={styles.symbol} value={unrealizedPnl} />
      </div>
      {type === TRADE_TYPES.AUTO.NAME ? (
        <div className={styles.row}>
          <div className={styles.label}>実現損益</div>
          <ColoredPriceText className={styles.value} symbolClassName={styles.symbol} value={realizedProfitLoss} />
        </div>
      ) : (
        <p className={styles.spacer} />
      )}
    </div>
  );
};
PortfolioCard.propTypes = {
  cardData: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    currency: PropTypes.string.isRequired,
    groupName: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    realizedProfitLoss: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    buySell: PropTypes.string,
    creationTime: PropTypes.string,
    number: PropTypes.string,
    image: PropTypes.string,
    shortName: PropTypes.string,
    activeCount: PropTypes.number,
    serviceIds: PropTypes.arrayOf(PropTypes.string),
    sourceType: PropTypes.string,
    apGroupIdList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    status: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    parentName: PropTypes.string,
    parentImage: PropTypes.string,
    parentId: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func,
  isTech: PropTypes.bool,
  isGrouped: PropTypes.bool,
};

PortfolioCard.defaultProps = {
  onClick: () => {},
  isTech: false,
  isGrouped: false,
};

export default memo(PortfolioCard);
