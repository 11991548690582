import { createContext, useCallback, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { nop } from 'shared-modules/utils';
import Logger from 'shared-modules/services/Logger';

export const AppHeaderHeightContext = createContext({ headerHeight: 0, setHeaderHeight: () => {} });

export const AppHeaderHeightProvider = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  return (
    <AppHeaderHeightContext.Provider value={{ headerHeight, setHeaderHeight }}>
      {children}
    </AppHeaderHeightContext.Provider>
  );
};

AppHeaderHeightProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// 共通化を考えないのであればこれは不要
export const AppCssVariablesContext = createContext({ theme: undefined, variables: {}, getVariable: () => null });

export const AppCssVariablesProvider = ({ root, theme, children }) => {
  const getVariable = useCallback(
    (key) => {
      const cssStyleDeclaration = getComputedStyle(root);
      const variable = cssStyleDeclaration.getPropertyValue(key);
      return variable ? `${variable}`.trim() : variable;
    },
    [root],
  );

  // 都度解決はしんどそうなので、使いそうなのをキャッシュしておく
  const variables = useMemo(
    () => ({
      currentTheme: theme ?? 'default',
      '--color-white': getVariable('--color-white'),
      '--color-black': getVariable('--color-black'),
      '--color-future': getVariable('--color-future'),
      '--color-text-primary': getVariable('--color-text-primary'),
      '--color-text-normal': getVariable('--color-text-normal'),
      '--color-text-ask': getVariable('--color-text-ask'),
      '--color-text-bid': getVariable('--color-text-bid'),
      '--color-text-plus': getVariable('--color-text-plus'),
      '--color-text-minus': getVariable('--color-text-minus'),
      '--color-text-danger': getVariable('--color-text-danger'),
      '--color-text-safe': getVariable('--color-text-safe'),
      '--color-text-gray': getVariable('--color-text-gray'),
    }),
    [theme, getVariable],
  );

  return (
    <AppCssVariablesContext.Provider value={{ variables, getVariable }}>{children}</AppCssVariablesContext.Provider>
  );
};

AppCssVariablesProvider.propTypes = {
  root: PropTypes.instanceOf(Element).isRequired,
  theme: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AppCssVariablesProvider.defaultProps = {
  theme: undefined,
};

export const TableRefContext = createContext(null);

export const TableRefProvider = ({ children }) => {
  const tableRef = useRef(null);
  return <TableRefContext.Provider value={tableRef}>{children}</TableRefContext.Provider>;
};

TableRefProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SubWindowManagementContext = createContext({ addSubWindow: nop, closeAllSubWindows: nop });

const isAvailableSubWindow = (subWindow) => subWindow != null && !subWindow.closed;

export const SubWindowManagementProvider = ({ children }) => {
  const subWindowsRef = useRef([]);

  const addSubWindow = useCallback((newSubWindow) => {
    if (isAvailableSubWindow(newSubWindow)) {
      subWindowsRef.current = subWindowsRef.current.filter(isAvailableSubWindow).concat([newSubWindow]);
    }
  }, []);

  const closeAllSubWindows = useCallback(() => {
    subWindowsRef.current = subWindowsRef.current.filter(isAvailableSubWindow);
    while (subWindowsRef.current.length > 0) {
      const subWindow = subWindowsRef.current.pop();
      if (subWindow == null) {
        return;
      }
      if (isAvailableSubWindow(subWindow)) {
        try {
          subWindow.close();
        } catch (error) {
          Logger.error(error);
        }
      }
    }
  }, []);

  return (
    <SubWindowManagementContext.Provider value={{ addSubWindow, closeAllSubWindows }}>
      {children}
    </SubWindowManagementContext.Provider>
  );
};

SubWindowManagementProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
