import {
  CHANGE_ACTIVE_PAGE,
  CHANGE_ACTIVE_TABLE,
  RESET_WEB_BUILDER_OPTIONS,
  ADD_DRAWING_POINT,
  CHANGE_SELECT_RECOMMEND,
  CHANGE_RECOMMEND_LOGIC_LIST,
  CHANGE_RECOMMEND_MODAL,
  CHANGE_ACTIVE_TAB,
} from '../actionConstants/builderConstants';

export const changeBuilderActivePage = ({ activePage }) => ({ type: CHANGE_ACTIVE_PAGE, payload: { activePage } });

export const changeBuilderActiveTable = ({ tableId }) => ({ type: CHANGE_ACTIVE_TABLE, payload: { tableId } });

export const addDrawingPoint = ({ sketchData }) => ({ type: ADD_DRAWING_POINT, payload: { sketchData } });

export const changeSelectRecommend = ({ selectRecommend }) => ({
  type: CHANGE_SELECT_RECOMMEND,
  payload: { selectRecommend },
});

export const changeRecommendLogicList = ({ recommendLogicList }) => ({
  type: CHANGE_RECOMMEND_LOGIC_LIST,
  payload: { recommendLogicList },
});

export const changeRecommendModal = ({ isOpenRecommendModal }) => ({
  type: CHANGE_RECOMMEND_MODAL,
  payload: { isOpenRecommendModal },
});

export const resetWebBuilderOptions = () => ({ type: RESET_WEB_BUILDER_OPTIONS });

export const changeActiveTab = ({ activeTab }) => ({
  type: CHANGE_ACTIVE_TAB,
  payload: { activeTab },
});
