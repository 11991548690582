export const KEY_FOR_DEFAULT_CHART_RESOLUTION = 'KEY_FOR_DEFAULT_CHART_RESOLUTION';

export const KEY_FOR_CHART_INDICATORS = 'KEY_FOR_CHART_INDICATORS';

export const KEY_FOR_DISPLAY_POSITIONS = 'KEY_FOR_DISPLAY_POSITIONS';
export const KEY_FOR_BK_DISPLAY_POSITIONS = 'KEY_FOR_BK_DISPLAY_POSITIONS';
export const KEY_FOR_BK_DISPLAY_NEW_ORDERS = 'KEY_FOR_BK_DISPLAY_NEW_ORDERS';
export const KEY_FOR_BK_DISPLAY_CLOSED_ORDERS = 'KEY_FOR_BK_DISPLAY_CLOSED_ORDERS';
export const DEFAULT_DISPLAY_POSITIONS = true;

export const KEY_FOR_DISPLAY_NEW_ORDERS = 'KEY_FOR_DISPLAY_NEW_ORDERS';
export const DEFAULT_DISPLAY_NEW_ORDERS = true;
export const KEY_FOR_DISPLAY_CLOSED_ORDERS = 'KEY_FOR_DISPLAY_CLOSED_ORDERS';
export const DEFAULT_DISPLAY_CLOSED_ORDERS = true;

export const STANDARD_FONT_SIZE = 11;

export const TOOLTIP_HOW_TO_READ_CHART = `現在値と注文のエントリー価格がチャート上に表示されます。
現在値：緑
新規買エントリー価格：ピンク
新規売エントリー価格：水色`;

export const TOOLTIP_HOW_TO_READ_CHART_WITH_SETTLEMENT = `現在値と注文のエントリー価格・決済注文価格がチャート上に表示されます。
現在値：緑
新規買エントリー価格：ピンク
新規売エントリー価格：水色
決済注文価格：黄色`;
