import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getJapaneseFormatDate } from 'shared-modules/services';
import styles from './demo.module.scss';

const Demo = ({ termEndDate, comment, isPreview }) => {
  return (
    <div className={styles.wrapper}>
      <>
        {comment && (
          <div className={styles.demoWrapper}>
            <div className={styles.demoTextWrapper}>
              <span className={styles.demoTitle}>ビルダーの概要</span>

              <span className={styles.demoDesc} style={{ whiteSpace: 'pre-line', lineBreak: 'anywhere' }}>
                {comment?.replace(/<br ?\/?>/g, '\n')}
              </span>
            </div>
          </div>
        )}
        <div className={styles.divider} />
        <div className={styles.bottomText}>
          <div>
            本機能は､過去のFX・CFD・ETFの1分足レートをもとにスプレッド､スワップポイント(FX)
            ､手数料(FX)､金利・配当相当額(CFD)､金利・分配相当額(ETF)を加味した数値でバックテストを行った結果を表示しています｡
            将来の結果を保証するものではありませんのでご注意ください｡また､バックテストは実際の取引と異なります｡
            {isPreview &&
              'ビルダーズシェアロジックにおける「稼働日」は､ビルダーズシェアのポートフォリオに含まれる個々のロジックにおける初回稼働日のうち､最も若い日付を採用します｡'}
            更新日：
            {getJapaneseFormatDate(termEndDate)}
          </div>
          {!isPreview && (
            <>
              <div>ロジックの説明動画はイメージです｡</div>
              <div>
                各自動売買ルールは不定期に入れ替えやロジック設定値の更新をおこないます｡
                セレクト一覧には､常に最新パラメーターの自動売買セレクトが表示されます｡
              </div>
              <div>
                一部の自動売買ルールは､両建てとなるタイミングがあるため
                ､スプレッドや金利(CFD･ETF)が二重にかかる､スワップポイント(FX)で支払いが生じるなどのデメリットがございます｡
                また､一部のルールを除いて､それぞれの自動売買注文に損切りの設定はされていません｡注文内容の詳細は各ルールの注文設定からご確認ください｡
              </div>
              <div>上記をご理解いただき､ご自身の責任と判断でご利用ください｡</div>
            </>
          )}
        </div>
      </>
    </div>
  );
};

Demo.propTypes = {
  termEndDate: PropTypes.string,
  comment: PropTypes.string,
  isPreview: PropTypes.bool,
};
Demo.defaultProps = {
  termEndDate: '',
  comment: '',
  isPreview: false,
};

export default memo(Demo);
