import { call, put, select } from 'redux-saga/effects';
import { getRangeOuts } from '../../../api/messageApi';
import { errorHandler } from '../errorSaga';
import { getRangeOutsSuccess } from '../../actions/messageActions';

export function* getRangeOutsRequestHandler() {
  try {
    const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
    if (!isAuthenticated) {
      // 未ログイン時はスキップ
      return;
    }
    const { data: rangeOutState } = yield call(getRangeOuts);
    yield put(getRangeOutsSuccess({ rangeOutState }));
  } catch (error) {
    yield call(errorHandler, { error });
  }
}
