import React, { memo } from 'react';
import styles from '../steps.module.scss';

const Step4 = () => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <div>
          <p>反対シグナル発生時は自動売買注文が稼働停止となり、未約定の新規注文が取消されます。</p>
          <p>
            ON：反対シグナル発生時は自動売買の稼働停止となり、その自動売買が保有していた建玉を成行決済します。
            <br />
            OFF：反対シグナル発生時は自動売買の稼働停止のみ行い、その自動売買が保有していた建玉は決済されません。
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(Step4);
