import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FX } from 'shared-modules/constants';
import { useServiceId } from 'shared-modules/hooks';
import { checkForInteger, getServiceQuantityUnit } from 'shared-modules/services';
import { tutorialCardDetail } from '../../../../../constants/tutorial/classNames';
import CardHeader from '../../../../../components/CardHeader';
import OptionsDropdown from '../../../../../components/OptionsDropdown';
import { ColoredPriceText, NumberText } from '../../../../../components/Texts';
import styles from '../../PortfolioAutoTradeDetail/components/Card/card.module.scss';
import { UNREALIZED_PNL } from '../../../../../constants/tutorial/otherMockData';

// @deprecated
const TutorialCard = ({ data }) => {
  const {
    type,
    currency,
    count,
    creationTime,
    realizedProfitLoss = '-',
    totalCount = 0,
    activeCount = 0,
    image,
    shortName,
  } = data || {};

  const serviceId = useServiceId(currency);
  const unrealizedProfitLoss = UNREALIZED_PNL;
  const symbolName = serviceId === FX ? `${shortName}（${currency}）` : shortName;

  return (
    <div className="card card--small" aria-hidden="true">
      <CardHeader displayedType={type} image={image} currency={currency} activeCount={activeCount} />

      <p className="symbol mb-5">{symbolName}</p>
      <div className={tutorialCardDetail}>
        <div className={styles.row}>
          <div className={styles.label}>建玉数量</div>
          <NumberText
            className={styles.value}
            symbolClassName={styles.symbol}
            value={checkForInteger(count === 0 ? '-' : count)}
            symbol={getServiceQuantityUnit(serviceId)}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>評価損益</div>
          <ColoredPriceText className={styles.value} symbolClassName={styles.symbol} value={unrealizedProfitLoss} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>実現損益</div>
          <ColoredPriceText className={styles.value} symbolClassName={styles.symbol} value={realizedProfitLoss} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>本数</div>
          {/* TODO without 3桁カンマ */}
          <div className={styles.value}>{`${totalCount}(${activeCount}稼働中)`}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>稼働日</div>
          <div className={styles.value}>{creationTime}</div>
        </div>
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, position: 'relative' }}
      >
        <button
          type="button"
          className="btn btn-outline-danger"
          style={{ fontWeight: 600, fontSize: '1.4rem', border: 0 }}
          onClick={() => {}}
        >
          全停止する
        </button>
        <div style={{ position: 'absolute', right: 0 }}>
          <OptionsDropdown isBig isNonBordered onClick={() => {}} options={[]} />
        </div>
      </div>
    </div>
  );
};

TutorialCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    realizedProfitLoss: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    buySell: PropTypes.string,
    creationTime: PropTypes.string,
    number: PropTypes.string,
    image: PropTypes.string,
    shortName: PropTypes.string,
    sourceType: PropTypes.string,
    activeCount: PropTypes.number,
  }).isRequired,
};

export default memo(TutorialCard);
