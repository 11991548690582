import { memo } from 'react';
import { SplitPane } from '../../../components';
import { OrderSettingTable } from './OrderSettingTable';
import { PriceChart } from './PriceChart';

export const LogicSetting = memo(() => (
  <SplitPane split="horizontal" defaultSize="60%" minSize={200}>
    <PriceChart />
    <OrderSettingTable />
  </SplitPane>
));
