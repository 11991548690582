import React, { memo, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { MODAL_SIZES_AVAILABLE_VALUES, MODAL_SIZES, COLORS, YOUTUBE_REG } from 'shared-modules/constants';
import { openYoutubeModal } from '../../redux/actions';
import CustomButton from '../CustomButton';
import styles from './modal.module.scss';
import { tutorialCommonModalClose } from '../../constants/tutorial/classNames';

const ModalContainer = ({
  isOpen,
  closeModal,
  title,
  isCenteredTitle,
  children,
  size,
  footer,
  isOverlap,
  isOverlayModal,
  isErrorNotification,
  isOutsideClickDontClose,
  withoutBodyPadding,
  isTopAligned,
  withoutCloseButton,
  isMobile,
  linkName,
  linkUrl,
  titleStyle,
}) => {
  const dispatch = useDispatch();

  const handleStopPropagation = useCallback((e) => e.stopPropagation(), []);
  const handleClose = useCallback(() => closeModal && closeModal(), [closeModal]);

  const openLink = useCallback(() => {
    if (!linkUrl || !linkName) {
      return;
    }

    const isYoutubeLink = YOUTUBE_REG.test(linkUrl);

    if (isYoutubeLink) {
      dispatch(openYoutubeModal({ url: linkUrl }));
      return;
    }

    window.open(linkUrl, linkName);
  }, [linkName, linkUrl, dispatch]);

  return (
    <Modal
      backdrop={isOutsideClickDontClose ? 'static' : true}
      show={isOpen}
      onHide={closeModal}
      centered
      onClick={handleStopPropagation}
      size={MODAL_SIZES[size]}
      dialogClassName={classNames(
        styles.wrapper,
        { [styles.isMobile]: isMobile },
        { [styles.isTopAligned]: isTopAligned },
        { [styles.customModalDialog]: size === MODAL_SIZES.MAX_CONTENT },
        { [styles.width294]: size === MODAL_SIZES.WIDTH_294 },
        { [styles.width360]: size === MODAL_SIZES.WIDTH_360 },
        { [styles.width400]: size === MODAL_SIZES.WIDTH_400 },
        { [styles.width450]: size === MODAL_SIZES.WIDTH_450 },
        { [styles.width512]: size === MODAL_SIZES.WIDTH_512 },
        { [styles.width640]: size === MODAL_SIZES.WIDTH_640 },
        { [styles.width710]: size === MODAL_SIZES.WIDTH_710 },
        { [styles.width960]: size === MODAL_SIZES.WIDTH_960 },
        { [styles.width1024]: size === MODAL_SIZES.WIDTH_1024 },
        { [styles.width1127]: size === MODAL_SIZES.WIDTH_1127 },
        { [styles.width83vw_1600]: size === MODAL_SIZES.WIDTH83VW_1600 },
        { [styles.width400_600]: size === MODAL_SIZES.WIDTH_400_600 },
      )}
      backdropClassName={classNames(
        styles.backdrop,
        { [styles.overlapBd]: isOverlap },
        { [styles.overlayModal]: isOverlayModal },
        { [styles.errorNotificationBd]: isErrorNotification },
      )}
      className={classNames({ [styles.errorNotificationModal]: isErrorNotification })}
    >
      <Modal.Header closeButton={false} bsPrefix={classNames(styles.header, { [styles.isMobile]: isMobile })}>
        {isCenteredTitle ? (
          <div className={classNames(styles.centeredTitle, titleStyle)}>{title}</div>
        ) : (
          <div className={classNames(styles.title, titleStyle)}>{title}</div>
        )}
        {!withoutCloseButton && (
          <i
            aria-hidden
            className={classNames('material-icons-outlined', styles.close, tutorialCommonModalClose)}
            onClick={handleClose}
          >
            clear
          </i>
        )}

        {linkName && linkUrl && (
          <CustomButton
            color={COLORS.RED}
            onClick={openLink}
            className={classNames(styles.linkButton, { [styles.withoutCloseButton]: withoutCloseButton })}
          >
            {linkName}
          </CustomButton>
        )}
      </Modal.Header>
      <Modal.Body
        bsPrefix={classNames(
          styles.body,
          { [styles.withoutPadding]: withoutBodyPadding },
          { [styles.isMobile]: isMobile },
        )}
      >
        {children}
      </Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};
ModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  isCenteredTitle: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
  size: PropTypes.oneOf(MODAL_SIZES_AVAILABLE_VALUES),
  footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  isOverlap: PropTypes.bool,
  isOverlayModal: PropTypes.bool,
  isErrorNotification: PropTypes.bool,
  isTopAligned: PropTypes.bool,
  isOutsideClickDontClose: PropTypes.bool,
  withoutBodyPadding: PropTypes.bool,
  withoutCloseButton: PropTypes.bool,
  isMobile: PropTypes.bool,
  linkName: PropTypes.string,
  linkUrl: PropTypes.string,
  titleStyle: PropTypes.string,
};
ModalContainer.defaultProps = {
  closeModal: () => {},
  title: '',
  isCenteredTitle: false,
  size: '',
  footer: '',
  isOverlap: false,
  isOverlayModal: false,
  isErrorNotification: false,
  isTopAligned: false,
  isOutsideClickDontClose: false,
  withoutBodyPadding: false,
  withoutCloseButton: false,
  isMobile: false,
  linkName: '',
  linkUrl: '',
  titleStyle: undefined,
};

export default memo(ModalContainer);
