import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Decimal from 'decimal.js';
import HighlightValue from '../HighlightValue';

const TotalPlCellValue = memo(({ instrumentId, positionId, unrealizedSwapPl, isToFormat }) => {
  const plValue = useSelector(
    (state) => state.currencies.positionsUnrealizedProfitLoss?.[instrumentId]?.[positionId]?.unrealizedProfitLoss,
  );

  const value = Decimal.add(Math.floor(plValue), Math.floor(unrealizedSwapPl)).toNumber();

  return <HighlightValue value={value} isToFormat={isToFormat} />;
});

TotalPlCellValue.propTypes = {
  instrumentId: PropTypes.string,
  positionId: PropTypes.string,
  unrealizedSwapPl: PropTypes.number,
  isToFormat: PropTypes.bool,
};

TotalPlCellValue.defaultProps = {
  instrumentId: '',
  positionId: '',
  unrealizedSwapPl: 0,
  isToFormat: false,
};

export default memo(TotalPlCellValue);
