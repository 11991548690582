import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import {
  SORT_ASCENDING,
  SORT_DESCENDING,
  DEFAULT_POSITIONS_TABLE_SORT_BY,
  DEFAULT_POSITIONS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR,
} from 'shared-modules/constants/manualTrade';
import { CFD, ETF, FX, POSITIONS_EMPTY_MESSAGE } from 'shared-modules/constants';
import {
  saveDefaultValuesFromLocalStorage,
  saveJsonStyleDefaultValuesToLocalStorage,
  sortPositionsTable,
} from 'shared-modules/services';
import { changePositionsTableMetaInfo } from 'shared-modules/redux/actions/manualTradeActions';
import PropTypes from 'prop-types';
import styles from '../Table/table.module.scss';
import { getPositionsTableTemplate } from '../../services/tableTemplate';
import { Table } from '../Table';
import { filterPosition } from '../TableComponents/common';
import PositionCheckbox from './components/PositionCheckbox';
import DeleteButton from './components/DeleteButton/DeleteButton';

const SIZE_SETTINGS = {
  [FX]: {
    key: 'manualTradeOpenPositions',
    default: [80, 60, 24, 64, 64, 76, 76, 80, 68, 84, 88, 120, 140, 80],
  },
  [ETF]: {
    key: 'manualTradeETFOpenPositions',
    default: [80, 60, 24, 64, 64, 76, 76, 84, 68, 84, 84, 88, 120, 140, 80],
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    key: 'manualTradeCFDOpenPositions',
    default: [80, 60, 24, 64, 64, 76, 76, 84, 68, 84, 84, 88, 120, 140, 80],
  },
};

const checkboxColumn = {
  id: 'selection',
  // eslint-disable-next-line react/prop-types
  Header: ({ getToggleAllRowsSelectedProps, data }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PositionCheckbox {...getToggleAllRowsSelectedProps()} tableData={data} isToggleAll />
  ),
  // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
  Cell: ({ row }) => <PositionCheckbox {...row.getToggleRowSelectedProps()} positionId={row.original.positionId} />,
  sticky: 'right',
  maxWidth: 80,
  Footer: '',
};

const closeAllColumn = {
  id: 'filter',
  Header: '',
  // eslint-disable-next-line
  Cell: ({ row: { original } }) => <DeleteButton original={original} />,
  disableSortBy: true,
  minWidth: 140,
  sticky: 'right',
  Footer: '',
};

const stickyColumns = [closeAllColumn, checkboxColumn];

const DEFAULT_SORTING = [
  {
    id: DEFAULT_POSITIONS_TABLE_SORT_BY,
    desc: DEFAULT_POSITIONS_TABLE_SORT_DIR === SORT_DESCENDING,
  },
];

const TablePositions = ({ isManual }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const dispatch = useDispatch();
  const tableDataRaw = useSelector((state) => state.currencies.positions[serviceId]);
  const tableMetaInfo = useSelector((state) => state.manualTrade.positionsDataMetaInfo[serviceId]);
  const selectedInfoTable = useSelector((state) => state.manualTrade.selectedInfoTable);
  const [hasValueRef, setHasValueRef] = useState(false);
  const [isInclude, setIsInclude] = useState([]);
  const [isFirstLoading, setIsFirstLoading] = useState([true]);

  const columns = useMemo(() => getPositionsTableTemplate(serviceId, isManual), [isManual, serviceId]);

  const resized = SIZE_SETTINGS[serviceId];

  const defaultSorting = useMemo(() => {
    if (tableMetaInfo.sortBy == null || tableMetaInfo.sortDir == null) {
      return DEFAULT_SORTING;
    }
    return [
      {
        id: tableMetaInfo.sortBy,
        desc: tableMetaInfo.sortDir === SORT_DESCENDING,
      },
    ];
  }, [tableMetaInfo]);

  const filterHandler = useCallback(
    ({ key, value }) => {
      if (['tradeMethods', 'instrumentId', 'side'].includes(key)) {
        dispatch(changePositionsTableMetaInfo({ key, value, serviceId }));
      }
      const localStorageKey = KEY_FOR_DEFAULT_POSITIONS_TABLE_FILTERING_VALUES[serviceId];
      saveJsonStyleDefaultValuesToLocalStorage({ key, value, localStorageKey });
    },
    [dispatch, serviceId],
  );

  const sortingHandler = useCallback(
    ({ id: sortBy, desc: isDesc }) => {
      const sortDir = isDesc ? SORT_DESCENDING : SORT_ASCENDING;
      const { sortBy: previousSortBy, sortDir: previousSortDir } = tableMetaInfo;
      if (sortBy === previousSortBy && sortDir === previousSortDir) {
        return;
      }

      batch(() => {
        dispatch(changePositionsTableMetaInfo({ key: 'sortBy', value: sortBy, serviceId }));
        dispatch(changePositionsTableMetaInfo({ key: 'sortDir', value: sortDir, serviceId }));
      });
      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_BY[serviceId],
        value: sortBy,
      });
      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_POSITIONS_TABLE_SORT_DIR[serviceId],
        value: sortDir,
      });
    },
    [dispatch, tableMetaInfo, serviceId],
  );

  useEffect(() => {
    setHasValueRef(false);
    setIsInclude([]);
  }, [serviceId, selectedInfoTable]);

  useEffect(() => {
    setIsFirstLoading(true);
  }, [serviceId]);

  const positionsUnrealizedProfitLossContainer = useRef({});

  const positionsUnrealizedProfitLoss = useSelector((state) => state.currencies.positionsUnrealizedProfitLoss);
  useEffect(() => {
    positionsUnrealizedProfitLossContainer.current = positionsUnrealizedProfitLoss;
    const instrumentListForService = Object.values(instrumentList)
      .filter((item) => item.serviceId === serviceId)
      .map((e) => e.instrumentId);

    if (tableMetaInfo.sortBy === 'pl') {
      setTimeout(
        () => {
          if (isInclude.length === 0) {
            setIsInclude(
              Object.keys(positionsUnrealizedProfitLossContainer.current).filter((x) => {
                return instrumentListForService.includes(x);
              }),
            );

            setHasValueRef(true);

            if (isFirstLoading) {
              setIsFirstLoading(false);
            }
          }
        },
        isFirstLoading ? 2000 : 1000,
      );
    } else {
      setHasValueRef(true);
    }
  }, [positionsUnrealizedProfitLoss, serviceId, instrumentList, isInclude, tableMetaInfo, hasValueRef, isFirstLoading]);

  const tableData = useMemo(() => {
    if (!hasValueRef) return [];

    const { sortBy, sortDir, tradeMethods, instrumentId, side } = tableMetaInfo;

    const filteredTableData = tableDataRaw
      .filter((item) => filterPosition({ item, tradeMethods, instrumentId, side, isManual }))
      .map((data) => ({ ...data, totalPl: data.pl + data.unrealizedSwapPl }));

    return [
      ...filteredTableData.sort((a, b) =>
        sortPositionsTable({
          a,
          b,
          sortBy,
          sortDir,
          plRef: positionsUnrealizedProfitLossContainer,
        }),
      ),
    ];
  }, [tableDataRaw, tableMetaInfo, hasValueRef, isManual]);

  return (
    <Table
      key={serviceId}
      tableData={tableData}
      columns={columns}
      resized={resized}
      emptyText={POSITIONS_EMPTY_MESSAGE}
      className={styles.table}
      tableMetaInfo={tableMetaInfo}
      defaultSorting={defaultSorting}
      filterHandler={filterHandler}
      sortingHandler={sortingHandler}
      stickyColumns={stickyColumns}
      useServerSorting
      withCheckboxes
    />
  );
};

TablePositions.propTypes = {
  isManual: PropTypes.bool,
};

TablePositions.defaultProps = {
  isManual: false,
};

export default memo(TablePositions);
