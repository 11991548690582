import {
  GET_CURRENT_CART_ITEMS_COUNT_SUCCESS,
  GET_CURRENT_CART_START_LOADING,
  GET_CURRENT_CART_STOP_LOADING,
  GET_CURRENT_CART_SUCCESS,
  UPDATE_CURRENT_CART_STRATEGY_SETS_LOCALLY,
  SAVE_CURRENT_CART_START_LOADING,
  SAVE_CURRENT_CART_STOP_LOADING,
  DELETE_CURRENT_CART_START_LOADING,
  DELETE_CURRENT_CART_STOP_LOADING,
  EXECUTE_CART_ITEMS_START_LOADING,
  EXECUTE_CART_ITEMS_STOP_LOADING,
  GET_HISTORY_CART_START_LOADING,
  GET_HISTORY_CART_STOP_LOADING,
  GET_HISTORY_CART_SUCCESS,
  GET_SAVED_CART_START_LOADING,
  GET_SAVED_CART_STOP_LOADING,
  GET_SAVED_CART_SUCCESS,
  DELETE_CURRENT_CART_ITEM_START_LOADING,
  DELETE_CURRENT_CART_ITEM_STOP_LOADING,
  SET_CART_DATA_SERVICE_ID,
  CHANGE_CURRENT_CART_TERM_START_LOADING,
  CHANGE_CURRENT_CART_TERM_STOP_LOADING,
  CHANGE_SELECTED_TERM_ID,
} from '../actionConstants/cartConstants';
import { FX } from '../../constants';

export const initialState = {
  currentData: {},
  selectedTermId: null,
  currentDataServiceId: FX,
  currentDataItemsCount: 0,
  currentDataIsLoading: false,
  currentDataChangeTermIdIsLoading: false,
  currentDataSaveIsLoading: false,
  currentDataDeleteIsLoading: false,
  currentDataDeleteItem: {
    itemId: null,
    isLoading: false,
  },
  currentDataExecuteIsLoading: false,
  historyData: [],
  historyDataIsLoading: false,
  savedData: [],
  savedDataIsLoading: false,
  changingSummaryInfoLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CURRENT_CART_ITEMS_COUNT_SUCCESS: {
      const { itemsCount } = payload;
      return {
        ...state,
        currentDataItemsCount: itemsCount,
      };
    }
    case SET_CART_DATA_SERVICE_ID: {
      const { serviceId } = payload;
      return {
        ...state,
        currentDataServiceId: serviceId,
      };
    }
    case GET_CURRENT_CART_START_LOADING: {
      return {
        ...state,
        currentDataIsLoading: true,
      };
    }
    case GET_CURRENT_CART_STOP_LOADING: {
      return {
        ...state,
        currentDataIsLoading: false,
      };
    }
    case CHANGE_CURRENT_CART_TERM_START_LOADING: {
      return {
        ...state,
        currentDataChangeTermIdIsLoading: true,
      };
    }
    case CHANGE_CURRENT_CART_TERM_STOP_LOADING: {
      return {
        ...state,
        currentDataChangeTermIdIsLoading: false,
      };
    }
    case CHANGE_SELECTED_TERM_ID: {
      const { termId } = payload;

      return {
        ...state,
        selectedTermId: termId,
      };
    }
    case GET_CURRENT_CART_SUCCESS: {
      const { currentData, termId } = payload;

      return {
        ...state,
        currentData: {
          ...state.currentData,
          [termId]: currentData.map(({ parentSets, strategySets, ...rest }) => {
            const baseSets = parentSets == null ? null : strategySets / parentSets;
            return {
              baseSets,
              strategySets,
              parentSets,
              ...rest,
            };
          }),
        },
      };
    }
    case UPDATE_CURRENT_CART_STRATEGY_SETS_LOCALLY: {
      const { itemId, value } = payload;
      return {
        ...state,
        currentData: Object.fromEntries(
          Object.entries(state.currentData).map(([key, itemList]) => [
            key,
            itemList.map((item) => {
              let { strategySets, parentSets } = item;
              if (item.itemId === itemId) {
                if (parentSets == null) {
                  strategySets = value;
                } else {
                  parentSets = value;
                  strategySets = item.baseSets * value;
                }
              }
              return { ...item, strategySets, parentSets };
            }),
          ]),
        ),
      };
    }
    case SAVE_CURRENT_CART_START_LOADING: {
      return {
        ...state,
        currentDataSaveIsLoading: true,
      };
    }
    case SAVE_CURRENT_CART_STOP_LOADING: {
      return {
        ...state,
        currentDataSaveIsLoading: false,
      };
    }
    case DELETE_CURRENT_CART_START_LOADING: {
      return {
        ...state,
        currentDataDeleteIsLoading: true,
      };
    }
    case DELETE_CURRENT_CART_STOP_LOADING: {
      return {
        ...state,
        currentDataDeleteIsLoading: false,
      };
    }
    case DELETE_CURRENT_CART_ITEM_START_LOADING: {
      return {
        ...state,
        currentDataDeleteItem: {
          itemId: payload.itemId,
          isLoading: true,
        },
      };
    }
    case DELETE_CURRENT_CART_ITEM_STOP_LOADING: {
      return {
        ...state,
        currentDataDeleteItem: {
          itemId: null,
          isLoading: false,
        },
      };
    }
    case EXECUTE_CART_ITEMS_START_LOADING: {
      return {
        ...state,
        currentDataExecuteIsLoading: true,
      };
    }
    case EXECUTE_CART_ITEMS_STOP_LOADING: {
      return {
        ...state,
        currentDataExecuteIsLoading: false,
      };
    }
    case GET_HISTORY_CART_START_LOADING: {
      return {
        ...state,
        historyDataIsLoading: true,
      };
    }
    case GET_HISTORY_CART_STOP_LOADING: {
      return {
        ...state,
        historyDataIsLoading: false,
      };
    }
    case GET_HISTORY_CART_SUCCESS: {
      const { historyData } = payload;
      return {
        ...state,
        historyData,
      };
    }
    case GET_SAVED_CART_START_LOADING: {
      return {
        ...state,
        savedDataIsLoading: true,
      };
    }
    case GET_SAVED_CART_STOP_LOADING: {
      return {
        ...state,
        savedDataIsLoading: false,
      };
    }
    case GET_SAVED_CART_SUCCESS: {
      const { savedData } = payload;
      return {
        ...state,
        savedData,
      };
    }
    default: {
      return state;
    }
  }
};
