import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { SSO_MY_PAGE_URL } from 'shared-modules/constants';
import { push, replace } from '../../redux/actions';
import { Button } from '../../components';
import styles from './notFound.module.scss';

const NotFound = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(replace('/404'));
  }, [dispatch]);

  const handleBack = useCallback(() => {
    dispatch(push(`/${SSO_MY_PAGE_URL}`));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>404 ページが見つかりません</div>
      <div className={styles.text}>
        お探しのページは見つかりませんでした。入力したアドレスが間違っているか、ページが移動した可能性があります。
      </div>
      <Button className={styles.button} onClick={handleBack} secondary>
        <i className={classNames('material-icons', styles.linkIcon)}>open_in_new</i>
        マイページに戻る
      </Button>
    </div>
  );
};

export default memo(NotFound);
