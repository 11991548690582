import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserSSOMyPageRequest } from '../../redux/actions';
import { Spin } from '../../components';
import styles from './ssoMyPage.module.scss';

const SSOMyPage = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const { url, token, hash } = useSelector((state) => state.userSSO.userSSOMyPage);
  const [mobileData, setMobileData] = useState(null);

  const ssoMyPageFormRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search.substr(1));
    const isMobile = urlParams.get('isMobile');

    if (isMobile) {
      setMobileData({
        url: urlParams.get('url'),
        token: urlParams.get('token'),
        hash: urlParams.get('hash'),
      });
    } else dispatch(getUserSSOMyPageRequest());
  }, [dispatch, location.search]);

  useEffect(() => {
    if ((url && token && hash) || mobileData) {
      ssoMyPageFormRef.current.submit();
    }
  }, [url, token, hash, mobileData]);

  return (
    <div className={styles.wrapper}>
      <Spin className={styles.loader} />
      {/* Hidden form for submitting SSO */}
      <form
        id="ssoMyPageForm"
        action={mobileData?.url ?? url}
        method="post"
        ref={ssoMyPageFormRef}
        style={{ visibility: 'hidden' }}
      >
        <input type="hidden" name="data" value={mobileData?.token ?? token} />
        <input type="hidden" name="messageDigest" value={mobileData?.hash ?? hash} />
      </form>
    </div>
  );
};

export default memo(SSOMyPage);
