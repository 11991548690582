export const START_LOADING = 'AUTH/START_LOADING';
export const END_LOADING = 'AUTH/END_LOADING';

export const LOGIN_USER_REQUEST = 'AUTH/LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'AUTH/LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'AUTH/LOGIN_USER_FAILED';

export const RESET_LOGIN_ERROR = 'AUTH/RESET_LOGIN_ERROR';

export const CHANGE_LOGIN_VALUES = 'AUTH/CHANGE_LOGIN_VALUES';

export const CHANGE_PORTAL_ID = 'AUTH/CHANGE_PORTAL_ID';

export const LOGOUT_USER = 'AUTH/LOGOUT_USER';
export const CLEAR_REDUX_STORE = 'AUTH/CLEAR_REDUX_STORE';
export const CLEAR_STORAGE = 'AUTH/CLEAR_STORAGE';

export const GET_INITIAL_REQUESTS = 'AUTH/GET_INITIAL_REQUESTS';
export const GET_REFETCH_INITIAL_REQUESTS = 'AUTH/GET_REFETCH_INITIAL_REQUESTS';
export const GET_PUBLIC_INITIAL_REQUESTS = 'AUTH/GET_PUBLIC_INITIAL_REQUESTS';

export const LOGIN_FLOW_REQUEST = 'AUTH/LOGIN_FLOW_REQUEST';
export const LOGIN_FLOW_START_LOADING = 'AUTH/LOGIN_FLOW_START_LOADING';
export const LOGIN_FLOW_STOP_LOADING = 'AUTH/LOGIN_FLOW_STOP_LOADING';

export const CHANGE_SERVICE_ID_REQUEST = 'AUTH/CHANGE_SERVICE_ID_REQUEST';
export const CHANGE_SERVICE_ID_SUCCESS = 'AUTH/CHANGE_SERVICE_ID_SUCCESS';

export const RESET_IS_AUTHENTICATED_STATUS = 'AUTH/RESET_IS_AUTHENTICATED_STATUS';

export const UPDATE_USERNAME = 'AUTH/UPDATE_USERNAME';

export const UPDATE_AUTH_CONTEXT = 'AUTH/UPDATE_AUTH_CONTEXT';

export const OPEN_LOGIN_ALERT = 'AUTH/OPEN_LOGIN_ALERT';
export const CLOSE_LOGIN_ALERT = 'AUTH/CLOSE_LOGIN_ALERT';
