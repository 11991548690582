import { CHANGE_NEWS_TAB } from '../actionConstants/manualTradeConstants';

const initialState = {
  newsActiveTabId: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_NEWS_TAB: {
      const { tab } = payload;
      return {
        ...state,
        newsActiveTabId: tab,
      };
    }
    default: {
      return state;
    }
  }
};
