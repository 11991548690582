import React, { memo, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CFD,
  ETF,
  EXECUTIONS_EMPTY_MESSAGE,
  EXECUTIONS_EMPTY_MESSAGE_WRONG_PERIOD,
  FX,
} from 'shared-modules/constants';
import { SORT_DESCENDING, SORT_ASCENDING } from 'shared-modules/constants/manualTrade';
import {
  DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY,
  DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR,
} from 'shared-modules/constants/portfolio';
import { getTimeYearsDifference } from 'shared-modules/services';
import { getExecutionsInfoRequest, changeSelectedExecutionsMetaInfo } from '../../../../../../../../redux/actions';
import Loader from '../Loader';
import styles from '../../detailTable.module.scss';
import { getModalExecutionsTableTemplate } from '../../../../../../../../services/tableTemplate';
import { Table } from '../../../../../../../../components';

const DEFAULT_SORTING = [
  {
    id: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_BY,
    desc: DEFAULT_PORTFOLIO_EXECUTIONS_TABLE_SORT_DIR === SORT_DESCENDING,
  },
];

const RESIZED = {
  [FX]: {
    KEY: 'portfolioFXAutoTradeExecutionsTable',
    COLUMNS: [97, 97, 97, 85, 97, 97, 97, 97, 97, 105],
  },
  [ETF]: {
    KEY: 'portfolioETFAutoTradeExecutionsTable',
    COLUMNS: [97, 97, 97, 90, 97, 97, 97, 97, 97, 105],
  },
  // TODO CFD 暫定で ETF のコピー
  [CFD]: {
    KEY: 'portfolioCFDAutoTradeExecutionsTable',
    COLUMNS: [97, 97, 97, 90, 97, 97, 97, 97, 105],
  },
};

const TableExecutions = ({ id, serviceId }) => {
  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.portfolio.selectedApGroupExecutionsData);
  const tableMetaInfo = useSelector((state) => state.portfolio.selectedApGroupExecutionsMetaInfo[serviceId]);
  const { id: selectedApGroupId } = useSelector((state) => state.portfolio.selectedApGroupData);

  const datesSearchIsInvalid = useMemo(
    () => getTimeYearsDifference(tableMetaInfo.toDate, tableMetaInfo.fromDate),
    [tableMetaInfo],
  );

  const emptyText = datesSearchIsInvalid ? EXECUTIONS_EMPTY_MESSAGE_WRONG_PERIOD : EXECUTIONS_EMPTY_MESSAGE;

  const isLoading = useMemo(() => {
    const isIdMismatch = selectedApGroupId !== id;
    return !selectedApGroupId || isIdMismatch;
  }, [selectedApGroupId, id]);

  useEffect(() => {
    dispatch(getExecutionsInfoRequest({ apGroupId: selectedApGroupId, isFirstData: true, serviceId }));
  }, [dispatch, serviceId, selectedApGroupId]);

  const getAnotherPartOfData = useCallback(() => {
    dispatch(getExecutionsInfoRequest({ apGroupId: selectedApGroupId, isFirstData: false, serviceId }));
  }, [dispatch, selectedApGroupId, serviceId]);

  const columns = useMemo(() => getModalExecutionsTableTemplate(serviceId), [serviceId]);
  const defaultSorting = useMemo(() => {
    if (tableMetaInfo.sortBy == null || tableMetaInfo.sortDir == null) {
      return DEFAULT_SORTING;
    }
    return [{ id: tableMetaInfo.sortBy, desc: tableMetaInfo.sortDir === SORT_DESCENDING }];
  }, [tableMetaInfo]);

  const resized = useMemo(() => {
    const { KEY, COLUMNS } = RESIZED[serviceId];
    return {
      key: KEY,
      default: [...COLUMNS],
    };
  }, [serviceId]);

  const sortingHandler = useCallback(
    ({ id: sortBy, desc: isDesc }) => {
      const sortDir = isDesc ? SORT_DESCENDING : SORT_ASCENDING;
      const { sortBy: previousSortBy, sortDir: previousSortDir } = tableMetaInfo;
      if (sortBy === previousSortBy && sortDir === previousSortDir) {
        return;
      }
      batch(() => {
        dispatch(changeSelectedExecutionsMetaInfo({ key: 'sortBy', value: sortBy, serviceId }));
        dispatch(changeSelectedExecutionsMetaInfo({ key: 'sortDir', value: sortDir, serviceId }));
        dispatch(getExecutionsInfoRequest({ apGroupId: selectedApGroupId, isFirstData: true, serviceId }));
      });
    },
    [dispatch, tableMetaInfo, selectedApGroupId, serviceId],
  );

  const filterHandler = useCallback(
    ({ key, value }) => {
      if (['isClose', 'side'].includes(key)) {
        dispatch(changeSelectedExecutionsMetaInfo({ key, value, serviceId }));
      }

      dispatch(getExecutionsInfoRequest({ apGroupId: selectedApGroupId, isFirstData: true, serviceId }));
    },
    [dispatch, selectedApGroupId, serviceId],
  );

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Table
        key={serviceId}
        tableData={datesSearchIsInvalid ? [] : tableData}
        columns={columns}
        resized={resized}
        emptyText={emptyText}
        className={styles.table}
        tableMetaInfo={tableMetaInfo}
        paginationCallback={getAnotherPartOfData}
        defaultSorting={defaultSorting}
        useServerSorting
        sortingHandler={sortingHandler}
        filterHandler={filterHandler}
      />
    </>
  );
};

TableExecutions.propTypes = {
  id: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
};

export default memo(TableExecutions);
