import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_BUILDER_MOBILE_CHART } from 'shared-modules/constants/apiConstant';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { isJsonString } from 'shared-modules/services';
import { useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';

import {
  socketConnectionRequest,
  getInstrumentListSuccess,
  createInstrumentsOptions,
  getRatesSuccess,
} from '../../redux/actions';
import SinglePriceChart from '../../components/SinglePriceChart';
import MultiPriceChart from '../../components/MultiPriceChart';
import styles from './builderChart.module.scss';

const BuilderChart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(socketConnectionRequest({ isPublic: true }));

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(UPDATE_BUILDER_MOBILE_CHART);
    }
  }, [dispatch]);

  const instrumentOptions = useApOrManualInstrumentOptions(false);
  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const allRequestsCompleted = useMemo(
    () => Object.keys(instrumentList).length && Object.values(instrumentOptions).every((o) => o.length),
    [instrumentList, instrumentOptions],
  );

  const [isSingleStrategy, changeIsSingleStrategy] = useState(null);
  const [selectedCurrencyPairId, changeSelectedCurrencyPairId] = useState(null);
  const [serviceId, changeServiceId] = useState(null);
  const [orderSettingsList, changeOrderSettingsList] = useState(null);
  const [logicGroupsList, changeLogicGroupsList] = useState(null);
  const [singleOrderSelectedSellBuyId, changeSingleOrderSelectedSellBuyId] = useState(null);
  const [singleOrderQuantity, changeSingleOrderQuantity] = useState(null);
  const [singleOrderOcoIsChecked, changeSingleOrderOcoIsChecked] = useState(null);
  const [singleOrderOcoValue, changeSingleOrderOcoValue] = useState(null);
  const [singleOrderEntryPriceTypeId, changeSingleOrderEntryPriceTypeId] = useState(null);
  const [singleOrderBasePrice, changeSingleOrderBasePrice] = useState(null);
  const [singleOrderPrice, changeSingleOrderPrice] = useState(null);
  const [multiOrderOcoIsChecked, changeMultiOrderOcoIsChecked] = useState(null);
  const [multiOrderIsBuy, changeMultiOrderIsBuy] = useState(null);
  const [multiOrderBasePrice, changeMultiOrderBasePrice] = useState(null);
  const [multiOrderBasePriceEntryPriceValue, changeMultiOrderBasePriceEntryPriceValue] = useState(null);
  const [multiOrderRangeSpreadPriceValue, changeMultiOrderRangeSpreadPriceValue] = useState(null);

  const allVariableNotNull = useMemo(
    () =>
      ![
        isSingleStrategy,
        selectedCurrencyPairId,
        serviceId,
        orderSettingsList,
        logicGroupsList,
        singleOrderSelectedSellBuyId,
        singleOrderQuantity,
        singleOrderOcoIsChecked,
        singleOrderOcoValue,
        singleOrderEntryPriceTypeId,
        singleOrderBasePrice,
        singleOrderPrice,
        multiOrderOcoIsChecked,
        multiOrderIsBuy,
        multiOrderBasePrice,
        multiOrderBasePriceEntryPriceValue,
        multiOrderRangeSpreadPriceValue,
      ].some((i) => i === null),
    [
      isSingleStrategy,
      selectedCurrencyPairId,
      serviceId,
      orderSettingsList,
      logicGroupsList,
      singleOrderSelectedSellBuyId,
      singleOrderQuantity,
      singleOrderOcoIsChecked,
      singleOrderOcoValue,
      singleOrderEntryPriceTypeId,
      singleOrderBasePrice,
      singleOrderPrice,
      multiOrderOcoIsChecked,
      multiOrderIsBuy,
      multiOrderBasePrice,
      multiOrderBasePriceEntryPriceValue,
      multiOrderRangeSpreadPriceValue,
    ],
  );

  const windowEventListener = useCallback(
    ({ data }) => {
      if (!data || !isJsonString(data)) {
        return;
      }
      const parsedMessage = JSON.parse(data);

      if (parsedMessage.message === UPDATE_BUILDER_MOBILE_CHART) {
        changeIsSingleStrategy(parsedMessage.payload.isSingleStrategy);
        changeSelectedCurrencyPairId(parsedMessage.payload.selectedCurrencyPairId);
        changeServiceId(parsedMessage.payload.serviceId);
        changeOrderSettingsList(parsedMessage.payload.orderSettingsList);
        changeLogicGroupsList(parsedMessage.payload.logicGroupsList);
        changeSingleOrderSelectedSellBuyId(parsedMessage.payload.singleOrderSelectedSellBuyId);
        changeSingleOrderQuantity(parsedMessage.payload.singleOrderQuantity);
        changeSingleOrderOcoIsChecked(parsedMessage.payload.singleOrderOcoIsChecked);
        changeSingleOrderOcoValue(parsedMessage.payload.singleOrderOcoValue);
        changeSingleOrderEntryPriceTypeId(parsedMessage.payload.singleOrderEntryPriceTypeId);
        changeSingleOrderBasePrice(parsedMessage.payload.singleOrderBasePrice);
        changeSingleOrderPrice(parsedMessage.payload.singleOrderPrice);
        changeMultiOrderOcoIsChecked(parsedMessage.payload.multiOrderOcoIsChecked);
        changeMultiOrderIsBuy(parsedMessage.payload.multiOrderIsBuy);
        changeMultiOrderBasePrice(parsedMessage.payload.multiOrderBasePrice);
        changeMultiOrderBasePriceEntryPriceValue(parsedMessage.payload.multiOrderBasePriceEntryPriceValue);
        changeMultiOrderRangeSpreadPriceValue(parsedMessage.payload.multiOrderRangeSpreadPriceValue);
        dispatch(getRatesSuccess({ rates: parsedMessage.payload.rates }));
        dispatch(getInstrumentListSuccess({ instrumentList: parsedMessage.payload.instrumentList }));
        ALL_SERVICES.forEach((service) => {
          dispatch(
            createInstrumentsOptions({
              serviceId: service,
              options: parsedMessage.payload[`${service}InstrumentsOptions`],
            }),
          );
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', windowEventListener);
    document.addEventListener('message', windowEventListener);
    return () => {
      window.removeEventListener('message', windowEventListener);
      document.removeEventListener('message', windowEventListener);
    };
  }, [windowEventListener]);

  return (
    <div className={styles.chartWrapper}>
      {allVariableNotNull && allRequestsCompleted && (
        <>
          {isSingleStrategy ? (
            <SinglePriceChart
              selectedCurrencyPairId={selectedCurrencyPairId}
              orderSettingsList={orderSettingsList}
              instrumentOptions={instrumentOptions}
              instrumentList={instrumentList}
              serviceId={serviceId}
              selectedSellBuyId={singleOrderSelectedSellBuyId}
              quantity={singleOrderQuantity}
              ocoIsChecked={singleOrderOcoIsChecked}
              ocoValue={singleOrderOcoValue}
              entryPriceTypeId={singleOrderEntryPriceTypeId}
              basePrice={singleOrderBasePrice}
              price={singleOrderPrice}
              isMobile
            />
          ) : (
            <MultiPriceChart
              selectedCurrencyPairId={selectedCurrencyPairId}
              orderSettingsList={orderSettingsList}
              logicGroupsList={logicGroupsList}
              instrumentOptions={instrumentOptions}
              instrumentList={instrumentList}
              serviceId={serviceId}
              ocoIsChecked={multiOrderOcoIsChecked}
              buySell={multiOrderIsBuy}
              chartBuySell={multiOrderIsBuy}
              basePrice={multiOrderBasePrice}
              entryPriceValue={multiOrderBasePriceEntryPriceValue}
              rangeSpreadPriceValue={multiOrderRangeSpreadPriceValue}
              isMobile
            />
          )}
        </>
      )}
    </div>
  );
};

export default memo(BuilderChart);
