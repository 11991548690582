import Decimal from 'decimal.js';
import {
  GET_RATES_START_LOADING,
  GET_RATES_STOP_LOADING,
  GET_RATES_SUCCESS,
  GET_POSITIONS_SUCCESS,
  GET_POSITION_BY_ID_SUCCESS,
  UPDATE_UNREALIZED_PROFIT_LOSS_VALUES,
  REPLACE_MARGIN_RATES,
  GET_EOD_RATES_SUCCESS,
} from '../actionConstants/currenciesConstants';
import { POSITION_DATA_ACTIONS } from '../../constants';
import { ALL_SERVICES } from '../../constants/core';

export const makeInitialState = () => ({
  ratesIsLoading: false,
  rates: {},
  eodRates: {},
  positions: ALL_SERVICES.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {}),
  positionsUnrealizedProfitLoss: {},
  marginRates: {},
});

const initialState = makeInitialState();

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RATES_START_LOADING: {
      return {
        ...state,
        ratesIsLoading: true,
      };
    }
    case GET_RATES_STOP_LOADING: {
      return {
        ...state,
        ratesIsLoading: false,
      };
    }
    case GET_RATES_SUCCESS: {
      const { rates } = payload;

      return {
        ...state,
        rates,
      };
    }
    case GET_EOD_RATES_SUCCESS: {
      const { eodRates } = payload;

      return {
        ...state,
        eodRates,
      };
    }
    case GET_POSITIONS_SUCCESS: {
      const { positions, positionsUnrealizedProfitLoss, serviceId } = payload;
      const plCopy = { ...state.positionsUnrealizedProfitLoss };
      // 建玉がなくなった場合に対応するため、リクエストしたサービスの銘柄のみ全削除
      Object.keys(plCopy).forEach((instrumentId) => {
        if (Object.values(plCopy[instrumentId]).some((p) => p.serviceId === serviceId)) {
          delete plCopy[instrumentId];
        }
      });

      return {
        ...state,
        positions: {
          ...state.positions,
          [serviceId]: positions,
        },
        positionsUnrealizedProfitLoss: {
          ...plCopy,
          ...positionsUnrealizedProfitLoss,
        },
      };
    }
    case GET_POSITION_BY_ID_SUCCESS: {
      const { positionId, operation, positionInfo = {}, serviceId } = payload;

      let newPositionsData = [...state.positions[serviceId]];
      const updatedPositionsUnrealizedProfitLoss = { ...state.positionsUnrealizedProfitLoss };

      switch (operation) {
        case POSITION_DATA_ACTIONS.ADD_NEW: {
          if (!newPositionsData.some((data) => data.positionId === positionInfo.positionId)) {
            newPositionsData.push(positionInfo);
          }

          if (!updatedPositionsUnrealizedProfitLoss[positionInfo.instrumentId]) {
            updatedPositionsUnrealizedProfitLoss[positionInfo.instrumentId] = {};
          }
          updatedPositionsUnrealizedProfitLoss[positionInfo.instrumentId][positionId] = {
            quantity: positionInfo.quantity,
            tradePrice: positionInfo.tradePrice,
            side: positionInfo.side,
            unrealizedProfitLoss: null,
            apGroupId: positionInfo.apGroupId,
            serviceId,
          };
          break;
        }
        case POSITION_DATA_ACTIONS.UPDATE: {
          updatedPositionsUnrealizedProfitLoss[positionInfo.instrumentId][positionId].quantity = positionInfo.quantity;
          newPositionsData = newPositionsData.map((item) => {
            if (item.positionId === positionId) {
              return positionInfo;
            }
            return item;
          });
          break;
        }
        case POSITION_DATA_ACTIONS.POSITION_NOT_EXIST: {
          newPositionsData = newPositionsData.filter((item) => item.positionId !== positionId);

          if (updatedPositionsUnrealizedProfitLoss[positionInfo.instrumentId]) {
            delete updatedPositionsUnrealizedProfitLoss[positionInfo.instrumentId][positionId];

            if (!Object.keys(updatedPositionsUnrealizedProfitLoss[positionInfo.instrumentId]).length) {
              delete updatedPositionsUnrealizedProfitLoss[positionInfo.instrumentId];
            }
          }
          break;
        }
        case POSITION_DATA_ACTIONS.DELETE: {
          newPositionsData = newPositionsData.reduce((acc, item) => {
            if (item.positionId !== positionId) {
              acc.push(item);
              return acc;
            }

            const newQuantity = new Decimal(item.quantity).sub(positionInfo.quantity).toNumber();
            const isQuantityMoreThen0 = newQuantity > 0;

            if (isQuantityMoreThen0) {
              acc.push({
                ...item,
                quantity: newQuantity,
              });

              updatedPositionsUnrealizedProfitLoss[item.instrumentId][positionId].quantity = newQuantity;
            } else if (updatedPositionsUnrealizedProfitLoss[item.instrumentId]) {
              delete updatedPositionsUnrealizedProfitLoss[item.instrumentId][positionId];

              if (!Object.keys(updatedPositionsUnrealizedProfitLoss[item.instrumentId]).length) {
                delete updatedPositionsUnrealizedProfitLoss[item.instrumentId];
              }
            }

            return acc;
          }, []);
          break;
        }
        default: {
          // empty
        }
      }

      return {
        ...state,
        positions: {
          ...state.positions,
          [serviceId]: newPositionsData,
        },
        positionsUnrealizedProfitLoss: updatedPositionsUnrealizedProfitLoss,
      };
    }
    case UPDATE_UNREALIZED_PROFIT_LOSS_VALUES: {
      const { positionsUnrealizedProfitLoss } = payload;
      return {
        ...state,
        positionsUnrealizedProfitLoss: {
          ...state.positionsUnrealizedProfitLoss,
          ...positionsUnrealizedProfitLoss,
        },
      };
    }
    case REPLACE_MARGIN_RATES: {
      const { marginRates } = payload;
      return {
        ...state,
        marginRates,
      };
    }
    default: {
      return state;
    }
  }
};
