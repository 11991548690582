import { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { valueToPercent } from 'shared-modules/utils';
import { useValueToColor } from '../../../hooks';
import styles from './riskAssessmentIcon.module.scss';

const size = 38;
const strokeWidth = 3;
const halfSize = size / 2;
const cx = halfSize;
const cy = halfSize;
const radius = halfSize - strokeWidth / 2;
const circumference = 2 * radius * Math.PI;
const min = 0;
const max = 5;

const getPosition = (value) => {
  let v = value ?? 0;
  if (v > max) {
    v = max;
  }
  const percent = valueToPercent(v, min, max);
  return (percent * circumference) / 100;
};

export const RiskAssessmentIcon = memo(({ value }) => {
  const prevValueRef = useRef(value);
  const [position, setPosition] = useState(0);
  const { getRankColor } = useValueToColor();
  const [circleColor, setCircleColor] = useState(getRankColor(value));
  const color = useMemo(() => getRankColor(value), [value, getRankColor]);

  useEffect(() => {
    const isZero = value === 0;
    if (prevValueRef.current === 0 && isZero) {
      return;
    }
    setPosition(getPosition(value));
    const newCircleColor = getRankColor(value);
    if (isZero) {
      setTimeout(() => setCircleColor(newCircleColor), 200);
    } else {
      setCircleColor(newCircleColor);
    }
    prevValueRef.current = value;
  }, [value, getRankColor]);

  return (
    <div className={styles.container}>
      <svg className={styles.svg} width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle className={styles.bgCircle} r={radius} cx={cx} cy={cy} fill="transparent" strokeWidth={strokeWidth} />
        <circle
          className={styles.circle}
          r={radius}
          cx={cx}
          cy={cy}
          fill="transparent"
          stroke={circleColor}
          strokeWidth={strokeWidth}
          strokeDasharray={`${position} ${circumference}`}
        />
      </svg>
      <div className={styles.text} style={{ color }}>
        {value}
      </div>
    </div>
  );
});

RiskAssessmentIcon.propTypes = {
  value: PropTypes.oneOf([0, 1, 2, 3, 4, 5]).isRequired,
};
