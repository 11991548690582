import React, { memo } from 'react';
import styles from './spinner.module.scss';

export const Spinner = memo(() => {
  return (
    <div className={styles.spinner}>
      {Array.from({ length: 12 }, (_v, k) => k + 1).map((seq) => (
        <div key={seq} className={styles[`bar${seq}`]} />
      ))}
    </div>
  );
});
