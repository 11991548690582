import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FX } from 'shared-modules/constants';
import InstrumentPrecisionTableCell from '../InstrumentPrecisionTableCell';

const PipsSpecialTableCell = ({ cell }) => {
  if (cell.row.original.serviceId === FX) {
    return cell.value || '-';
  }
  return <InstrumentPrecisionTableCell cell={cell} />;
};

PipsSpecialTableCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    row: PropTypes.shape({
      original: PropTypes.shape({
        serviceId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default memo(PipsSpecialTableCell);
