import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { REPLACEMENT_TEXT_REG } from 'shared-modules/constants/errorMesages';
import styles from './errorText.module.scss';

export const ErrorText = memo(({ title, message: messageRaw, linkURL }) => {
  const message = useMemo(() => {
    if (!linkURL) {
      return messageRaw;
    }

    const messageParts = messageRaw.split(REPLACEMENT_TEXT_REG);

    return (
      <>
        {messageParts[0]}
        <a href={linkURL} className={styles.link}>
          {messageParts[1]}
        </a>
        {messageParts[2]}
      </>
    );
  }, [messageRaw, linkURL]);

  return (
    <div className={styles.textWrapper}>
      <div className={styles.errorMessageText}>
        {title && (
          <>
            <div className={styles.title}>{title}</div>
            <br />
          </>
        )}
        {message}
      </div>
    </div>
  );
});

ErrorText.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  linkURL: PropTypes.string,
};

ErrorText.defaultProps = {
  title: '',
  linkURL: undefined,
};
