import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { WIDTH, MODAL_SIZES } from 'shared-modules/constants';
import { useBuilderAddToCartLogic } from 'shared-modules/services/hooks/builder';
import { Button, CustomTooltip, InputText, InputNumber, Modal } from '../../../../components';
import styles from './techAddToCart.module.scss';

const TechAddToCart = ({ isOpen, closeModal, isOrderSetting }) => {
  const isTechBuilder = true;
  const addToCartLogic = useBuilderAddToCartLogic({ isModalOpen: isOpen, closeModal, isTechBuilder });

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title={addToCartLogic.modalTitle} size={MODAL_SIZES.WIDTH_400}>
      <div className={styles.row}>{addToCartLogic.name.label}</div>
      <InputText
        className={styles.row}
        value={addToCartLogic.name.get}
        onChange={addToCartLogic.name.set}
        width={WIDTH.PERCENTAGE_100}
        name={addToCartLogic.name.name}
        errorMessages={addToCartLogic.errorMessages}
        validateFunction={addToCartLogic.name.validateFunction}
        withErrorTooltip
      />

      <div className={styles.row}>
        <div>{addToCartLogic.sets.label}</div>
        <InputNumber
          value={addToCartLogic.sets.get}
          onChange={addToCartLogic.sets.set}
          name={addToCartLogic.sets.name}
          validateFunction={addToCartLogic.sets.validateFunction}
          errorMessages={addToCartLogic.errorMessages}
          onlyIntegerAllowed
          withErrorTooltip
        />
      </div>

      {!isOrderSetting && (
        <div className={styles.row}>
          <div className={styles.marginLabel}>
            {addToCartLogic.margin.label}
            <CustomTooltip message={addToCartLogic.margin.tooltip} className={styles.infoWrapper}>
              <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
            </CustomTooltip>
          </div>
          <div className={styles.marginValue}>{addToCartLogic.margin.get}</div>
        </div>
      )}

      <div className={styles.row}>
        <div className={styles.marginLabel}>
          {addToCartLogic.marginRequired.label}
          <CustomTooltip message={addToCartLogic.marginRequired.tooltip} className={styles.infoWrapper}>
            <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
          </CustomTooltip>
        </div>
        <div className={styles.marginValue}>{addToCartLogic.marginRequired.get}</div>
      </div>

      <Button
        onClick={addToCartLogic.techSubmit.handler}
        width={WIDTH.PERCENTAGE_100}
        className={styles.addButton}
        loading={addToCartLogic.techSubmit.isLoading}
        disabled={addToCartLogic.techSubmit.isDisabled}
      >
        {addToCartLogic.techSubmit.label}
      </Button>
    </Modal>
  );
};

TechAddToCart.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOrderSetting: PropTypes.bool.isRequired,
};

export default memo(TechAddToCart);
