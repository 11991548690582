// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import Decimal from 'decimal.js';
import { BUY_SELL_MAIN } from '../constants';
import { numberExists } from '../utils';

export const useMarginRequirement = (serviceId, instrumentId, buySell, quantity) => {
  const marginGroupId = useSelector((state) => state.settings.accountInfo[serviceId]?.marginGroupId);
  const marginObj = useSelector((state) => state.settings.instrumentList[instrumentId]?.margins?.[marginGroupId]);
  const rtMargin = useSelector((state) => state.currencies.marginRates[instrumentId]);
  if (!marginObj || !numberExists(quantity) || !rtMargin) {
    return 0;
  }

  const margin = buySell === BUY_SELL_MAIN.BUY.ID ? rtMargin.bidMargin : rtMargin.askMargin;
  if (margin == null) {
    return 0;
  }
  return new Decimal(quantity).mul(margin).ceil(1).toNumber();
};

export const useManualMarginRequirement = (serviceId, instrumentId, quantity, price) => {
  const marginGroupId = useSelector((state) => state.settings.accountInfo[serviceId]?.marginGroupId);
  const marginObj = useSelector((state) => state.settings.instrumentList[instrumentId]?.margins?.[marginGroupId]);
  const rtMargin = useSelector((state) => state.currencies.marginRates[instrumentId]);
  if (!marginObj || !numberExists(quantity) || !numberExists(price) || !rtMargin) {
    return 0;
  }
  if (rtMargin.margin == null) {
    return 0;
  }
  return new Decimal(quantity).mul(price).mul(rtMargin.margin).ceil(1).toNumber();
};
