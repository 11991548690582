import { get, invokeApi } from './index';

export const getCategoryList = ({ pageSize, pageNumber, contentsLimit, alertFlg, userId }) => {
  let requestQuery = `?pageSize=${pageSize}&pageNumber=${pageNumber}&contentsLimit=
  ${contentsLimit}&alertFlg=${alertFlg}`;
  if (userId) {
    requestQuery += `&userId=${userId}`;
  }
  return invokeApi({}, `public/contents/categories${requestQuery}`, get, {});
};

export const getContentsList = ({ categoryId, pageNumber, pageSize, alertFlg, userId }) => {
  let requestQuery = `?pageSize=${pageSize}&pageNumber=${pageNumber}&alertFlg=${alertFlg}`;
  if (userId) {
    requestQuery += `&userId=${userId.replace('sso_', '')}`;
  }
  return invokeApi({}, `public/contents/${categoryId}${requestQuery}`, get, {});
};
