export const SOCKET_CONNECTION_REQUEST = 'SOCKET/SOCKET_CONNECTION_REQUEST';
export const SOCKET_DISCONNECT_REQUEST = 'SOCKET/SOCKET_DISCONNECT_REQUEST';

export const HANDLE_ORDER_SOCKET_MESSAGE = 'SOCKET/HANDLE_ORDER_SOCKET_MESSAGE';
export const HANDLE_ACCOUNT_SOCKET_MESSAGE = 'SOCKET/HANDLE_ACCOUNT_SOCKET_MESSAGE';
export const HANDLE_SETTINGS_SOCKET_MESSAGE = 'SOCKET/HANDLE_SETTINGS_SOCKET_MESSAGE';
export const HANDLE_AP_SOCKET_MESSAGE = 'SOCKET/HANDLE_AP_SOCKET_MESSAGE';
export const HANDLE_TECHNICAL_BUILDER_SOCKET_MESSAGE = 'SOCKET/HANDLE_TECHNICAL_BUILDER_SOCKET_MESSAGE';
export const HANDLE_CART_ITEMS_SOCKET_MESSAGE = 'SOCKET/HANDLE_CART_ITEMS_SOCKET_MESSAGE';

export const OPEN_SOCKET_ERROR_MODAL = 'SOCKET/OPEN_SOCKET_ERROR_MODAL';
export const CLOSE_SOCKET_ERROR_MODAL = 'SOCKET/CLOSE_SOCKET_ERROR_MODAL';

export const RECONNECT_SOCKET_REQUEST = 'SOCKET/RECONNECT_SOCKET_REQUEST';
