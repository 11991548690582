import { forwardRef, memo, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './editableInput.module.scss';
import { useKeyDownHandler } from '../../services/hooks';

export const EditableInput = memo(
  forwardRef(
    (
      {
        inputRef,
        editable,
        label,
        value,
        disabled,
        className,
        textClassName,
        width,
        multiLine,
        editOnly,
        onClick,
        children,
      },
      ref,
    ) => {
      const handleClick = useCallback(
        (e) => {
          if (disabled) {
            return;
          }
          if (onClick) {
            onClick(e);
          }
        },
        [disabled, onClick],
      );

      useEffect(() => {
        if (disabled) {
          return;
        }
        if (!editOnly && editable) {
          inputRef.current?.focus();
        }
      }, [inputRef, disabled, editOnly, editable]);

      const handleKeyDown = useKeyDownHandler(handleClick);

      const input = useMemo(() => {
        if (editOnly || editable) {
          return children;
        }
        return (
          <div className={classNames(styles.inputWrapper, { [styles.multi]: multiLine })} style={{ width }}>
            <div className={classNames(styles.input, textClassName, { [styles.multi]: multiLine })}>
              {value?.replace(/<br ?\/?>/g, '\n')}
            </div>
            <i
              ref={ref}
              className={classNames(styles.icon, 'material-icons', { [styles.disabled]: disabled })}
              tabIndex={disabled ? -1 : 0}
              role="button"
              onClick={handleClick}
              onKeyDown={handleKeyDown}
            >
              edit
            </i>
          </div>
        );
      }, [
        ref,
        editable,
        handleClick,
        handleKeyDown,
        value,
        disabled,
        textClassName,
        width,
        multiLine,
        editOnly,
        children,
      ]);

      return (
        <div className={classNames(styles.container, className)}>
          {label != null && <div className={styles.label}>{label}</div>}
          <div
            className={classNames(styles.value, {
              [styles.readonly]: editOnly || !editable,
              [styles.disabled]: disabled,
            })}
          >
            {input}
          </div>
        </div>
      );
    },
  ),
);

EditableInput.propTypes = {
  inputRef: PropTypes.shape({ current: PropTypes.shape({ focus: PropTypes.func }) }).isRequired,
  editable: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiLine: PropTypes.bool,
  editOnly: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

EditableInput.defaultProps = {
  editable: undefined,
  label: undefined,
  value: undefined,
  disabled: false,
  className: undefined,
  textClassName: undefined,
  width: 'auto',
  multiLine: false,
  editOnly: false,
  onClick: undefined,
};
