export const GET_REPORTS_LIST_REQUEST = 'REPORTS/GET_REPORTS_LIST_REQUEST';
export const GET_REPORTS_LIST_REQUEST_START_LOADING = 'REPORTS/GET_REPORTS_LIST_REQUEST_START_LOADING';
export const GET_REPORTS_LIST_REQUEST_END_LOADING = 'REPORTS/GET_REPORTS_LIST_REQUEST_END_LOADING';
export const GET_REPORTS_LIST_SUCCESS = 'REPORTS/GET_REPORTS_LIST_SUCCESS';
export const CLEAR_REPORTS_LIST = 'REPORTS/CLEAR_REPORTS_LIST';

export const GET_REPORT_FILE_REQUEST = 'REPORTS/GET_REPORT_FILE_REQUEST';
export const GET_REPORT_FILE_REQUEST_START_LOADING = 'REPORTS/GET_REPORT_FILE_REQUEST_START_LOADING';
export const GET_REPORT_FILE_REQUEST_END_LOADING = 'REPORTS/GET_REPORT_FILE_REQUEST_END_LOADING';
export const GET_REPORT_FILE_SUCCESS = 'REPORTS/GET_REPORT_FILE_SUCCESS';
export const CLEAR_REPORT_FILE = 'REPORTS/CLEAR_REPORT_FILE';
