import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTableLoading } from 'shared-modules/hooks/select';
import { useSelectionsTableData } from 'shared-modules/services/hooks/autoSelectDetails';
import { CommonTable } from './CommonTable';

export const SelectionTable = memo(() => {
  const loading = useTableLoading();
  const termId = useSelector((state) => state.autoSelect.selectedTermId);
  const { simulationTradesData, autoTradeSettingsData, helpers } = useSelectionsTableData(termId);
  return (
    <CommonTable data={simulationTradesData} settingsData={autoTradeSettingsData} helpers={helpers} loading={loading} />
  );
});
