import React, { memo } from 'react';
import PropTypes from 'prop-types';

const LoadMoreButton = memo(({ onLoadMore }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="loadingSection-VX3x4wdz" onClick={onLoadMore}>
      <button
        type="button"
        /* eslint-disable-next-line max-len */
        className=" btn btn-secondary button-YKkCvwjV size-xlarge-YKkCvwjV color-gray-YKkCvwjV variant-secondary-YKkCvwjV stretch-YKkCvwjV"
      >
        <span className="content-YKkCvwjV">もっと見る</span>
      </button>
    </div>
  );
});

LoadMoreButton.propTypes = {
  onLoadMore: PropTypes.func.isRequired,
};

export default LoadMoreButton;
