import { combineReducers } from 'redux';
import { CLEAR_REDUX_STORE } from 'shared-modules/redux/actionConstants/authConstants';
import advertisement from 'shared-modules/redux/reducers/advertisement';
import auth from 'shared-modules/redux/reducers/auth';
import autoSelect from 'shared-modules/redux/reducers/autoSelect';
import cart from 'shared-modules/redux/reducers/cart';
import cash from 'shared-modules/redux/cash';
import chart from 'shared-modules/redux/reducers/chart';
import constants from 'shared-modules/redux/reducers/constants';
import currencies from 'shared-modules/redux/reducers/currencies';
import error from 'shared-modules/redux/reducers/error';
import manualTrade from 'shared-modules/redux/reducers/manualTrade';
import portfolio from 'shared-modules/redux/reducers/portfolio';
import settings from 'shared-modules/redux/reducers/settings';
import socket from 'shared-modules/redux/reducers/socket';
import tech from 'shared-modules/redux/tech';
import reports from 'shared-modules/redux/reducers/reports';
import userSSO from 'shared-modules/redux/reducers/userSSO';
import builder from 'shared-modules/redux/reducers/builder';
import message from 'shared-modules/redux/reducers/message';
import orders from 'shared-modules/redux/orders';
import multiEdit from 'shared-modules/redux/multiEdit';
import labs from 'shared-modules/redux/labs';
import guide from 'shared-modules/redux/reducers/guide';
import tutorial from 'shared-modules/redux/reducers/tutorial';
import event from 'shared-modules/redux/reducers/event';
import recommend from 'shared-modules/redux/reducers/recommend';
import sequentialModals from 'shared-modules/redux/reducers/sequentialModals';
import { system } from 'shared-modules/redux/reducers/system';
import { createRouterReducer } from 'shared-modules/redux/reducers/router';
import { createScreenReducer } from 'shared-modules/redux/reducers/screen';
import { pathnameToScreenId } from '../../services';
import webBuilder from './webBuilder';
import webPortfolio from './webPortfolio';
import webManualTrade from './webManualTrade';
import webCash from './webCash';
import webTutorial from './webTutorial';
import modals from './modals';

export const appReducer = (history) =>
  combineReducers({
    advertisement,
    auth,
    autoSelect,
    builder,
    webBuilder,
    cart,
    cash,
    webCash,
    chart,
    constants,
    currencies,
    error,
    manualTrade,
    webManualTrade,
    modals,
    portfolio,
    webPortfolio,
    orders,
    multiEdit,
    labs,
    guide,
    reports,
    tutorial,
    webTutorial,
    router: createRouterReducer(history),
    tech,
    settings,
    socket,
    userSSO,
    message,
    screen: createScreenReducer(() => {
      const screen = pathnameToScreenId(history.location?.pathname);
      return { screen };
    }),
    event,
    system,
    recommend,
    sequentialModals,
  });

const rootReducer = (history) => {
  const combinedReducer = appReducer(history);
  return (state, action) => {
    if (action.type === CLEAR_REDUX_STORE) {
      return combinedReducer(undefined, action);
    }
    return combinedReducer(state, action);
  };
};

export default rootReducer;
