import {
  CHANGE_CHART_RESOLUTION,
  TOGGLE_DISPLAY_CLOSED_ORDERS_FLAG,
  TOGGLE_DISPLAY_NEW_ORDERS_FLAG,
  TOGGLE_DISPLAY_POSITIONS_FLAG,
  CHANGE_DISPLAY_CLOSED_ORDERS_FLAG,
  CHANGE_DISPLAY_NEW_ORDERS_FLAG,
  CHANGE_DISPLAY_POSITIONS_FLAG,
  CHANGE_CHART_RESOLUTION_SELECT,
} from '../actionConstants/chartConstants';

export const changeChartResolution = ({ resolution }) => ({
  type: CHANGE_CHART_RESOLUTION,
  payload: { resolution },
});

export const changeChartResolutionSelect = ({ resolutionSelect }) => ({
  type: CHANGE_CHART_RESOLUTION_SELECT,
  payload: { resolutionSelect },
});

export const toggleDisplayPositionsFlag = () => ({
  type: TOGGLE_DISPLAY_POSITIONS_FLAG,
});

export const toggleDisplayClosedOrdersFlag = () => ({
  type: TOGGLE_DISPLAY_CLOSED_ORDERS_FLAG,
});

export const toggleDisplayNewOrdersFlag = () => ({
  type: TOGGLE_DISPLAY_NEW_ORDERS_FLAG,
});

export const changeDisplayClosedOrdersFlag = (displayClosedOrders) => ({
  type: CHANGE_DISPLAY_CLOSED_ORDERS_FLAG,
  payload: { displayClosedOrders },
});

export const changeDisplayNewOrdersFlag = (displayNewOrders) => ({
  type: CHANGE_DISPLAY_NEW_ORDERS_FLAG,
  payload: { displayNewOrders },
});

export const changeDisplayPositionsFlag = (displayPositions) => ({
  type: CHANGE_DISPLAY_POSITIONS_FLAG,
  payload: { displayPositions },
});
