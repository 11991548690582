export const GET_SELECTION_TAGS_REQUEST_START_LOADING = 'AUTO_SELECT/GET_SELECTION_TAGS_REQUEST_START_LOADING';
export const GET_SELECTION_TAGS_REQUEST_END_LOADING = 'AUTO_SELECT/GET_SELECTION_TAGS_REQUEST_END_LOADING';
export const GET_SELECTION_TAGS_REQUEST = 'AUTO_SELECT/GET_SELECTION_TAGS_REQUEST';
export const GET_SELECTION_TAGS_SUCCESS = 'AUTO_SELECT/GET_SELECTION_TAGS_SUCCESS';

export const GET_SELECTIONS_BY_PARAMS_REQUEST_START_LOADING =
  'AUTO_SELECT/GET_SELECTIONS_BY_PARAMS_REQUEST_START_LOADING';
export const GET_SELECTIONS_BY_PARAMS_REQUEST_END_LOADING = 'AUTO_SELECT/GET_SELECTIONS_BY_PARAMS_REQUEST_END_LOADING';
export const GET_SELECTIONS_BY_PARAMS_REQUEST = 'AUTO_SELECT/GET_SELECTIONS_BY_PARAMS_REQUEST';
export const GET_SELECTIONS_BY_PARAMS_SUCCESS = 'AUTO_SELECT/GET_SELECTIONS_BY_PARAMS_SUCCESS';

export const GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_START_LOADING =
  'AUTO_SELECT/GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_START_LOADING';
export const GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_STOP_LOADING =
  'AUTO_SELECT/GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_STOP_LOADING';
export const GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_REQUEST =
  'AUTO_SELECT/GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_REQUEST';
export const GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_SUCCESS =
  'AUTO_SELECT/GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_SUCCESS';

export const ADD_TO_CART_REQUEST = 'AUTO_SELECT/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_REQUEST_START_LOADING = 'AUTO_SELECT/ADD_TO_CART_REQUEST_START_LOADING';
export const ADD_TO_CART_REQUEST_END_LOADING = 'AUTO_SELECT/ADD_TO_CART_REQUEST_END_LOADING';

export const GET_SELECTION_MODAL_INFO_REQUEST_START_LOADING =
  'AUTO_SELECT/GET_SELECTION_MODAL_INFO_REQUEST_START_LOADING';
export const GET_SELECTION_MODAL_INFO_REQUEST_STOP_LOADING =
  'AUTO_SELECT/GET_SELECTION_MODAL_INFO_REQUEST_STOP_LOADING';
export const GET_SELECTION_MODAL_TERM_INFO_REQUEST_START_LOADING =
  'AUTO_SELECT/GET_SELECTION_MODAL_TERM_INFO_REQUEST_START_LOADING';
export const GET_SELECTION_MODAL_TERM_INFO_REQUEST_STOP_LOADING =
  'AUTO_SELECT/GET_SELECTION_MODAL_TERM_INFO_REQUEST_STOP_LOADING';
export const GET_SELECTION_MODAL_INFO_REQUEST = 'AUTO_SELECT/GET_SELECTION_INFO_REQUEST';
export const GET_SELECTION_MODAL_INFO_SUCCESS = 'AUTO_SELECT/GET_SELECTION_INFO_SUCCESS';

export const CLEAR_SELECTION_MODAL_INFO = 'AUTO_SELECT/CLEAR_SELECTION_MODAL_INFO';

export const CREATE_SELECTION_AP_GROUP_REQUEST = 'AUTO_SELECT/CREATE_SELECTION_AP_GROUP_REQUEST';
export const CREATE_SELECTION_AP_GROUP_REQUEST_START_LOADING =
  'AUTO_SELECT/CREATE_SELECTION_AP_GROUP_REQUEST_START_LOADING';
export const CREATE_SELECTION_AP_GROUP_REQUEST_END_LOADING =
  'AUTO_SELECT/CREATE_SELECTION_AP_GROUP_REQUEST_END_LOADING';

export const CHANGE_SELECTED_INSTRUMENT_ID = 'AUTO_SELECT/CHANGE_SELECTED_INSTRUMENT_ID';
export const CHANGE_SELECTED_TERM_ID = 'AUTO_SELECT/CHANGE_SELECTED_TERM_ID';

export const SET_FILTER_KEYWORD = 'AUTO_SELECT/SET_FILTER_KEYWORD';
export const REMOVE_FILTER_KEYWORD = 'AUTO_SELECT/REMOVE_FILTER_KEYWORD';

export const SET_INITIAL_TAG = 'AUTO_SELECT/SET_INITIAL_TAG';
export const REMOVE_INITAL_TAG = 'AUTO_SELECT/REMOVE_INITAL_TAG';

export const SAVE_FILTER_CONDITION = 'AUTO_SELECT/SAVE_FILTER_CONDITION';
export const CLEAR_FILTER_CONDITION = 'AUTO_SELECT/CLEAR_FILTER_CONDITION';
export const SAVE_SORT_ORDER = 'AUTO_SELECT/SAVE_SORT_ORDER';
export const CLEAR_SORT_ORDER = 'AUTO_SELECT/CLEAR_SORT_ORDER';

export const SAVE_SEARCH_AUTO_SELECT_DATA = 'AUTO_SELECT/SAVE_SEARCH_AUTO_SELECT_DATA';
export const CLEAR_SEARCH_AUTO_SELECT_DATA = 'AUTO_SELECT/CLEAR_SEARCH_AUTO_SELECT_DATA';

export const CHANGE_PARENT_TAG_ID = 'AUTO_SELECT/CHANGE_PARENT_TAG_ID';

export const NAVIGATE_TO_RECOMMEND_SELECT_START = 'AUTO_SELECT/NAVIGATE_TO_RECOMMEND_SELECT_START';
export const NAVIGATE_TO_RECOMMEND_SELECT_END = 'AUTO_SELECT/NAVIGATE_TO_RECOMMEND_SELECT_END';
