import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';

import { deleteSelectedCategoryId } from 'shared-modules/redux/actions/guideActions';

import CategoryDetails from './CategoryDetails';
import CategoryList from './CategoryList';

const Media = memo(() => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(deleteSelectedCategoryId());
    };
  }, [dispatch]);

  return (
    <div>
      <Route path={`${path}/:categoryId`} exact component={CategoryDetails} />
      <Route path={`${path}`} exact component={CategoryList} />
    </div>
  );
});

export default Media;
