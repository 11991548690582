import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { fromCommaSeparatedServiceIdToServiceIds } from 'shared-modules/utils/guide';
import { GUIDE_LINK_TYPE_VIDEO, YOUTUBE_REG } from 'shared-modules/constants';
import { openYoutubeModal } from '../../../redux/actions';
import { ReactComponent as Youtube } from '../../../assets/youtube.svg';
import { Tag } from '../../../components';
import styles from '../media.module.scss';

const GridItems = memo(({ items }) => {
  const dispatch = useDispatch();

  const openLink = useCallback(
    (externalLinkUrl, externalLinkName) => {
      if (!externalLinkUrl || !externalLinkName) {
        return;
      }

      const isYoutubeLink = YOUTUBE_REG.test(externalLinkUrl);

      if (isYoutubeLink) {
        dispatch(openYoutubeModal({ url: externalLinkUrl }));
        return;
      }

      window.open(externalLinkUrl, externalLinkName);
    },
    [dispatch],
  );

  const articleList = useMemo(() => orderBy(items, (i) => i.publishedTime, 'desc'), [items]);

  return (
    <div className={styles.grid}>
      {articleList.map((item) => (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <article
          key={String(item.itemId)}
          className={styles.article}
          onClick={() => openLink(item.linkUrl, 'linkName')}
        >
          <div className={styles.imageContainer}>
            <img src={item.thumbnailUrl} loading="lazy" className={styles.thumbnail} alt="" />
            {item?.type === GUIDE_LINK_TYPE_VIDEO && (
              <button type="button" className="ytp-large-play-button ytp-button" aria-label="Play">
                <Youtube className={styles.path} />
              </button>
            )}
          </div>

          <div className={styles.postTime}>
            <span className={styles.triauto}>
              <div className={styles.tags}>
                {fromCommaSeparatedServiceIdToServiceIds(item?.serviceId).map((serviceId) => (
                  <Tag key={serviceId} className={styles.serviceTag} text={serviceId} />
                ))}
              </div>
              &nbsp;&nbsp;
            </span>
          </div>
          <p className={styles.articleTitle}>{item.title}</p>
        </article>
      ))}
    </div>
  );
});

GridItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default GridItems;
