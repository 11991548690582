import React, { memo, useCallback, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NavBar from '../NavBar';
import SideBarExpanded from '../SideBarExpanded';
import IconButton from '../../../../components/IconButton';
import styles from './sideBar.module.scss';

const SideBar = ({ containerClass }) => {
  const [sideBarIsOpen, changeSideBarStatus] = useState(false);

  const handleOpenSideBar = useCallback(
    (e) => {
      e.stopPropagation();
      changeSideBarStatus(true);
    },
    [changeSideBarStatus],
  );

  const handleCloseSideBar = useCallback(() => {
    changeSideBarStatus(false);
  }, [changeSideBarStatus]);

  useEffect(() => {
    window.addEventListener('click', handleCloseSideBar);
    return () => {
      window.removeEventListener('click', handleCloseSideBar);
    };
  }, [handleCloseSideBar]);

  const cartItemsCount = useSelector((state) => state.cart.currentDataItemsCount);

  return (
    <div className={classNames(styles.wrapper, containerClass)}>
      {!sideBarIsOpen ? (
        <IconButton iconName="menu" onClick={handleOpenSideBar} className={styles.menuIcon} />
      ) : (
        <div />
      )}
      {!sideBarIsOpen ? (
        <NavBar cartItemsCount={cartItemsCount} />
      ) : (
        createPortal(<SideBarExpanded handleCloseSideBar={handleCloseSideBar} />, document.body)
      )}
    </div>
  );
};
SideBar.propTypes = {
  containerClass: PropTypes.string.isRequired,
};

export default memo(SideBar);
