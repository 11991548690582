/* eslint-disable react/prop-types */
import React, { memo, useCallback, useMemo } from 'react';
import { batch, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { AP_GROUP_ORDER, AP_GROUP_SOURCES, COUNTER_NAME, COUNTER_PRICE_NAME } from 'shared-modules/constants';
import { multiEditApGroupItemRequest } from 'shared-modules/redux/multiEdit';
import { ORDER_DETAILS_EDIT_HELPERS as editHelpers } from 'shared-modules/services';
import {
  openConfirmationModal,
  closeMultiEdit,
  clearSelectedApGroupData,
  openPortfolioAutoTradeDetailModal,
  openMultiEditFail,
  getApGroupByIdRequest,
} from '../../../../../../../redux/actions';
import styles from './multiEditTableFooter.module.scss';
import { Button } from '../../../../../../../components';
import { FOOTER_HEIGHT, FOOTER_PADDING } from '../../../constants';

const MultiEditTableFooter = ({ propTableRowsRef: tableRowsRef }) => {
  const dispatch = useDispatch();
  const { instrumentId } = useSelector((state) => state.portfolio.selectedApGroupData);

  const { id: groupId, sourceType, apList } = useSelector((state) => state.portfolio.selectedApGroupData);
  const cardData = useSelector((state) => state.multiEdit.portfolioAutoTradeCardData);

  const isMoneyHatch = useMemo(() => sourceType === AP_GROUP_SOURCES.MONEY_HATCH.KEY, [sourceType]);

  const hasError = useSelector((state) => state.multiEdit.tableHasErrorArray).some((row) => row.hasError);

  const isOpenTechLogicInfo = useSelector((state) => state.modals.techLogicInfo.isOpen);

  const closeMultiEditModal = useCallback(() => {
    dispatch(closeMultiEdit());
    if (!isOpenTechLogicInfo) {
      dispatch(clearSelectedApGroupData());
      dispatch(openPortfolioAutoTradeDetailModal({ data: cardData }));
    } else {
      dispatch(
        getApGroupByIdRequest({
          id: groupId,
          serviceId: cardData.serviceId,
          status: cardData.activeCount === 0 ? '0' : '1',
        }),
      );
    }
  }, [dispatch, cardData, isOpenTechLogicInfo, groupId]);

  const multiEditExecuteSuccess = useCallback(() => {
    batch(() => {
      closeMultiEditModal();
    });
  }, [closeMultiEditModal]);

  const multiEditExecuteFail = useCallback(() => {
    batch(() => {
      closeMultiEditModal();
      dispatch(openMultiEditFail());
    });
  }, [dispatch, closeMultiEditModal]);

  const multiEditExecute = useCallback(() => {
    const requestParamList = [];

    apList
      .filter((filterAp) => !(filterAp.status === AP_GROUP_ORDER.ACTIVITY.INACTIVE.ID && filterAp.positionId))
      .forEach((apItem) => {
        const isActive = apItem.status === AP_GROUP_ORDER.ACTIVITY.ACTIVE.ID;
        const firstOrderIsActive = apItem.orderStatus === AP_GROUP_ORDER.STATUS.FIRST_ORDER.ID;

        const priceIsDisabled = (isActive && !firstOrderIsActive) || isMoneyHatch;
        const isSingle = isActive ? !apItem.latestNewOrderPrice2 : !apItem.entryPrice2;

        const apId = apItem.id;
        const changeAp = tableRowsRef.current[apId];

        let pushData;
        const counterKeyName = changeAp.counterType === 0 ? COUNTER_NAME : COUNTER_PRICE_NAME;
        const dataTemplate = {
          quantity: editHelpers.convertToNumberOrEmptyString(changeAp.quantity),
          tp: editHelpers.convertToNumberOrEmptyString(changeAp.tp),
          sl: editHelpers.convertToNumberOrEmptyString(changeAp.sl),
          follow: editHelpers.convertToNumberOrEmptyString(changeAp.follow),
          [counterKeyName]: editHelpers.convertToNumberOrEmptyString(changeAp.counter),
        };

        const targetPrice1 = priceIsDisabled
          ? apItem.entryPrice1
          : editHelpers.convertToNumberOrEmptyString(changeAp.entryPrice1);
        if (changeAp.isSomeChanged) {
          if (isSingle && !priceIsDisabled) {
            pushData = {
              apId,
              data: {
                ...dataTemplate,
                instrumentId,
                entryPrice1: targetPrice1,
              },
            };
          } else {
            const targetPrice2 = priceIsDisabled
              ? apItem.entryPrice2
              : editHelpers.convertToNumberOrEmptyString(changeAp.entryPrice2);
            pushData = {
              apId,
              data: {
                ...dataTemplate,
                instrumentId,
                entryPrice1: targetPrice1,
                entryPrice2: targetPrice2,
              },
            };
          }

          requestParamList.push(pushData);
        }
      });

    const payload = {
      groupId,
      dataList: requestParamList,
      successCallback: multiEditExecuteSuccess,
      failCallback: multiEditExecuteFail,
    };
    dispatch(multiEditApGroupItemRequest(payload));
  }, [
    apList,
    groupId,
    multiEditExecuteSuccess,
    multiEditExecuteFail,
    dispatch,
    isMoneyHatch,
    tableRowsRef,
    instrumentId,
  ]);

  const onClickDone = useCallback(() => {
    dispatch(
      openConfirmationModal({
        title: '確認',
        callback: multiEditExecute,
        bodyText: '注文設定を変更いたします。\nよろしいですか。',
        buttonBackText: '戻る',
        buttonNextText: 'はい',
        successButtonIsGreen: true,
        isOverlap: true,
      }),
    );
  }, [dispatch, multiEditExecute]);

  return (
    <div className={styles.wrapper} style={{ height: FOOTER_HEIGHT }}>
      <div style={{ padding: FOOTER_PADDING }}>
        <Button width={200} onClick={onClickDone} tabIndex="0" disabled={hasError}>
          変更内容を確定
        </Button>
      </div>
    </div>
  );
};

MultiEditTableFooter.propTypes = {
  propTableRowsRef: PropTypes.shape({}).isRequired,
};

export default memo(MultiEditTableFooter);
