import { SEQUENTIAL_MODALS, STATUS } from '../../constants/sequentialModals';
import { DONE_SUCCESS, RESET, SET_READY, SET_STATUS } from '../actionConstants/sequentialModals';

export const initialState = Object.freeze({
  [SEQUENTIAL_MODALS.Advertisements]: false,
  [SEQUENTIAL_MODALS.MessageModal]: false,
  [SEQUENTIAL_MODALS.Recommend]: false,
  ready: true,
  status: STATUS.INIT,
});

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return initialState;
    case DONE_SUCCESS: {
      const { modalId, toNotReady } = payload;
      const ready = toNotReady ? false : state.ready;
      return {
        ...state,
        [modalId]: true,
        ready,
      };
    }
    case SET_READY: {
      const { ready } = payload;
      return {
        ...state,
        ready: !!ready,
      };
    }
    case SET_STATUS: {
      const { status } = payload;
      return {
        ...state,
        status,
      };
    }
    default: {
      return state;
    }
  }
};
