import { put, takeEvery, delay } from 'redux-saga/effects';
import { DONE_REQUEST } from '../actionConstants/sequentialModals';
import { doneSuccessSequentialModal, setReadySequentialModal } from '../actions';

function* setDoneRequestHandler(action) {
  const {
    payload: { modalId, delay: delayTime },
  } = action;
  const toNotReady = !!delayTime;
  yield put(doneSuccessSequentialModal({ modalId, toNotReady }));
  if (delayTime) {
    yield delay(delayTime);
    yield put(setReadySequentialModal({ ready: true }));
  }
}

export default function* sequentialModalsSaga() {
  yield takeEvery(DONE_REQUEST, setDoneRequestHandler);
}
