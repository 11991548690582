import { call, put, select } from 'redux-saga/effects';
import { BUY_SELL_MAIN, EXPIRATION_TYPE_MAIN, ORDER_METHOD_MAIN, ORDER_TYPES_MAIN } from '../../../constants';
import { CLOSE_ORDER_FORM } from '../../../constants/manualTrade';
import { createCloseOrder } from '../../../api/manualTradeApi';
import {
  clearStatusAfterClosing,
  createCloseOrderStartLoading,
  createCloseOrderStopLoading,
} from '../../actions/manualTradeActions';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { errorHandler } from '../errorSaga';
import { createDateString } from '../../../services';

function* createCloseOrderRequestHandler(action) {
  try {
    yield put(createCloseOrderStartLoading());

    const {
      payload: {
        callback,
        data: {
          positionId,
          instrumentId,
          side,
          quantity,
          paymentMethod,
          orderMethod,
          closePrice,
          closeLimitPrice,
          closeStopPrice,
          settlementExpirationType,
          selectedDate,
          selectedTime,
        },
      },
    } = action;

    const serviceId = yield select((state) => state.auth.serviceId);

    const requestBody = {
      targetId: positionId,
      instrumentId,
      quantity,
    };
    let routePath;
    const expireTime = createDateString(selectedDate, selectedTime);

    switch (paymentMethod) {
      case ORDER_TYPES_MAIN.MARKET_ORDER.ID: {
        routePath = 'single';
        requestBody.side = side;
        requestBody.type = ORDER_METHOD_MAIN.MARKET.ID;
        requestBody.price = yield select(
          (state) =>
            state.currencies.rates[instrumentId][
              side === BUY_SELL_MAIN.SELL.ID ? BUY_SELL_MAIN.SELL.KEY : BUY_SELL_MAIN.BUY.KEY
            ],
        );
        requestBody.expireType = EXPIRATION_TYPE_MAIN.DAY.ID;
        break;
      }
      case ORDER_TYPES_MAIN.STANDARD.ID: {
        routePath = 'single';
        requestBody.side = side;
        requestBody.type = orderMethod;
        requestBody.price = closePrice;
        requestBody.expireType = settlementExpirationType;
        requestBody.expireTime = expireTime;
        break;
      }
      case ORDER_TYPES_MAIN.OCO.ID: {
        routePath = 'oco';
        requestBody.order1 = {
          side,
          type: ORDER_METHOD_MAIN.LIMIT.ID,
          price: closeLimitPrice,
          expireType: settlementExpirationType,
          expireTime,
        };
        requestBody.order2 = {
          side,
          type: ORDER_METHOD_MAIN.STOP_LIMIT.ID,
          price: closeStopPrice,
          expireType: settlementExpirationType,
          expireTime,
        };
        break;
      }
      default: {
        // empty
      }
    }
    yield call(createCloseOrder, { routePath, requestBody, serviceId });
    yield put(clearStatusAfterClosing());
    yield put(sendNotificationSuccess({ message: '決済注文が発注されました。' }));
    if (callback) callback();
  } catch (e) {
    yield call(errorHandler, { error: e, form: CLOSE_ORDER_FORM });
  } finally {
    yield put(createCloseOrderStopLoading());
  }
}

export default createCloseOrderRequestHandler;
