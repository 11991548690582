import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import Modal from '../../../../components/Modal';
import styles from './socketError.module.scss';

const SocketError = ({ isOpen, isPublic }) => {
  if (isPublic) {
    return (
      <Modal isOpen={isOpen} title="接続中..." size={MODAL_SIZES.MAX_CONTENT} withoutCloseButton isMobile>
        <div className={styles.mobileWrapper}>接続できない場合はインターネット環境をご確認ください。</div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} title="接続中..." isOverlap size={MODAL_SIZES.MAX_CONTENT} withoutCloseButton>
      <div className={styles.wrapper}>接続できない場合はインターネット環境をご確認ください。</div>
    </Modal>
  );
};
SocketError.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool.isRequired,
};

export default memo(SocketError);
