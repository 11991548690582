import { memo, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  deletePageInfo,
  getContentsListRequest,
  setSelectedCategoryId,
} from 'shared-modules/redux/actions/guideActions';
import { useCategory } from 'shared-modules/hooks/guide';
import { MEDIA, WEB_GUIDE_ARTICLE_LIST_PAGE_SIZE } from '../../constants';
import { Spin } from '../../components';
import GridItems from './components/GridItems';
import LoadMoreButton from './components/LoadMoreButton';
import styles from './media.module.scss';

const CategoryDetails = memo(({ match }) => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const portalId = useSelector((state) => state.auth.portalId);
  const userId = isAuth ? portalId : null;
  const { categoryId } = match.params;
  const {
    pageInfo: { totalSize },
    categoryList,
  } = useSelector((state) => state.guide);
  const alertFlgRef = useRef(false);

  const category = categoryList?.filter((c) => c.categoryId === Number(categoryId))?.[0];
  const articles = category?.items || [];

  const dispatch = useDispatch();
  const { getMarginData } = useCategory();

  const handleLoadMore = () => {
    dispatch(
      getContentsListRequest({
        categoryId: Number(categoryId),
        pageSize: WEB_GUIDE_ARTICLE_LIST_PAGE_SIZE,
        alertFlg: alertFlgRef.current,
        userId,
      }),
    );
  };

  useEffect(() => {
    async function getContentsList() {
      const alertFlg = await getMarginData();
      alertFlgRef.current = alertFlg;
      batch(() => {
        dispatch(
          getContentsListRequest({
            categoryId: Number(categoryId),
            pageSize: WEB_GUIDE_ARTICLE_LIST_PAGE_SIZE,
            isFirstData: true,
            alertFlg,
            userId,
          }),
        );
        dispatch(setSelectedCategoryId({ categoryId }));
      });
    }
    getContentsList();
  }, [categoryId, getMarginData, userId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(deletePageInfo());
    };
  }, [dispatch]);

  return (
    <div className={classNames('container-fluid', styles.wrapper)}>
      {Object.keys(categoryList).length === 1 && categoryList[0].categoryId === null ? (
        <Spin className={styles.loader}>Loading...</Spin>
      ) : (
        <>
          <div style={{ padding: 12 }}>
            <Link
              to={`/${MEDIA}`}
              className={styles.categoryLink}
              style={{ textDecoration: 'none', fontSize: 20, display: 'flex', marginLeft: 10 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                <i className="material-icons">chevron_left</i>
                一覧へ戻る
              </div>
            </Link>
          </div>
          <div key={categoryId}>
            <div className={styles.dividedLine} />
            <div className={styles.items}>
              <h2 className={styles.h2}>{category?.categoryName}</h2>
              <h3 className={styles.h3}>{category?.description}</h3>
              <GridItems items={articles} />
            </div>
          </div>
          {totalSize > articles.length && totalSize > WEB_GUIDE_ARTICLE_LIST_PAGE_SIZE && (
            <div style={{ paddingRight: '10%', paddingLeft: '10%' }}>
              <LoadMoreButton onLoadMore={handleLoadMore} />
            </div>
          )}

          <div className={styles.footer} />
        </>
      )}
    </div>
  );
});

CategoryDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default CategoryDetails;
