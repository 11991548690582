import { all, put, call, takeEvery, retry, select, takeLatest, delay } from 'redux-saga/effects';
import Decimal from 'decimal.js';
import {
  CFD,
  CFD_ADDITIONAL_INSTRUMENT_IDS,
  DEFAULT_QUANTITY_UNIT_CONV_FACTOR,
  ETF,
  ETF_ADDITIONAL_INSTRUMENT_ID,
  FX,
  POSITION_DATA_ACTIONS,
} from '../../constants';
import {
  DELAY_FOR_UPDATE_MARGIN,
  ORDERS_SOCKET_MESSAGES,
  RETRY_DELAY,
  RETRY_MAX_TRIES,
} from '../../constants/apiConstant';
import {
  GET_POSITION_BY_ID_REQUEST,
  GET_POSITIONS_REQUEST,
  GET_RATES_REQUEST,
  CALC_INSTRUMENT_MARGIN_PERIODICALLY,
  CALC_PUBLIC_INSTRUMENT_MARGIN_PERIODICALLY,
} from '../actionConstants/currenciesConstants';
import {
  getEodRatesSuccess,
  getPositionByIdSuccess,
  getPositionsSuccess,
  getRatesStartLoading,
  getRatesStopLoading,
  getRatesSuccess,
  replaceMarginRates,
} from '../actions/currenciesActions';
import { errorHandler } from './errorSaga';
import { getOrderInfo, getPositions, getPositionsOrderById, getRates } from '../../api/manualTradeApi';
import { getPositionsStartLoading, getPositionsStopLoading } from '../actions/manualTradeActions';
import Logger from '../../services/Logger';
import { getRateConversion } from '../../utils';
import { PERSONAL_MARGIN_GROUP_ID } from '../../constants/core';
import { getAccountInfo } from './common';

function* getRatesRequestHandler(action) {
  const { payload: { isPublic } = {} } = action;
  try {
    yield put(getRatesStartLoading());
    const accountInfo = yield* getAccountInfo();

    const data = [];
    if (!accountInfo[FX].isMaintenance) {
      const { data: FXRates } = yield call(getRates, { isPublic, serviceId: FX });
      data.push(...FXRates);
    }
    if (!accountInfo[ETF].isMaintenance) {
      const { data: ETFRates } = yield call(getRates, { isPublic, serviceId: ETF });
      data.push(
        ...ETFRates.map((item) => {
          if (item.instrumentId === ETF_ADDITIONAL_INSTRUMENT_ID) {
            return {
              ...item,
              instrumentId: `${ETF}.${ETF_ADDITIONAL_INSTRUMENT_ID}`,
            };
          }
          return item;
        }),
      );
    }
    if (!accountInfo[CFD].isMaintenance) {
      const { data: CFDRates } = yield call(getRates, { isPublic, serviceId: CFD });
      data.push(
        ...CFDRates.map((item) => {
          if (CFD_ADDITIONAL_INSTRUMENT_IDS[item.instrumentId]) {
            return {
              ...item,
              instrumentId: `${CFD}.${item.instrumentId}`,
            };
          }
          return item;
        }),
      );
    }

    const rates = data.reduce((acc, pair) => {
      acc[pair.instrumentId] = pair;
      delete acc[pair.instrumentId].instrumentId;
      return acc;
    }, {});

    const eodRates = { ...rates };

    yield put(getRatesSuccess({ rates }));
    yield put(getEodRatesSuccess({ eodRates }));
  } catch (e) {
    yield call(errorHandler, { error: e, isInitialRequest: true });
  } finally {
    yield put(getRatesStopLoading());
  }
}

export function* getPositionsRequestHandler(action) {
  const tutorialMode = yield select((state) => state.tutorial.tutorialMode);
  if (tutorialMode) {
    return;
  }

  let serviceId = action?.payload?.serviceId;
  if (!serviceId) {
    serviceId = yield select((state) => state.auth.serviceId);
  }

  try {
    const accountInfo = yield* getAccountInfo();
    yield put(getPositionsStartLoading({ serviceId }));
    yield put(getPositionsSuccess({ positions: [], positionsUnrealizedProfitLoss: {}, serviceId }));
    if (accountInfo[serviceId].isNotAvailable) {
      return;
    }

    let positions;
    const { data } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getPositions, { pageNumber: 1, serviceId });
    const { list, pageInfo } = data;
    const firstResult = list ?? [];
    const { totalPages } = pageInfo ?? { totalPages: 0 };
    positions = [...firstResult];
    if (totalPages > 1) {
      const promiseArray = [];
      for (let i = 2; i <= totalPages; i += 1) {
        promiseArray.push(() => retry(RETRY_MAX_TRIES, RETRY_DELAY, getPositions, { pageNumber: i, serviceId }));
      }
      const promiseResult = yield all(promiseArray.map((item) => item()));
      promiseResult.forEach((partialResult) => {
        const {
          data: { list: partialList },
        } = partialResult;
        positions = [...positions, ...partialList];
      });
    }

    const positionsUnrealizedProfitLoss = positions.reduce((acc, item) => {
      if (!acc[item.instrumentId]) {
        acc[item.instrumentId] = {};
      }
      acc[item.instrumentId][item.positionId] = {
        quantity: item.quantity,
        tradePrice: item.tradePrice,
        side: item.side,
        unrealizedProfitLoss: null,
        apGroupId: item.apGroupId,
        serviceId,
      };
      return acc;
    }, {});

    yield put(getPositionsSuccess({ positions, positionsUnrealizedProfitLoss, serviceId }));
  } catch (e) {
    yield call(errorHandler, { error: e, isInitialRequest: true });
  } finally {
    yield put(getPositionsStopLoading({ serviceId }));
  }
}

export function* getPositionByIdRequestHandler(action) {
  try {
    const {
      payload: { orderId, status, closePositionId, closePositionQuantity, serviceId },
    } = action;

    const accountInfo = yield* getAccountInfo();
    if (accountInfo[serviceId].isNotAvailable) {
      return;
    }

    const positions = yield select((state) => state.currencies.positions[serviceId]);

    let positionId;
    let operation;

    if (closePositionId) {
      if (status === ORDERS_SOCKET_MESSAGES.FILLED) {
        const quantity = positions?.find((item) => item.positionId === closePositionId)?.quantity;

        if (quantity == null) return;

        yield put(
          getPositionByIdSuccess({
            positionId: closePositionId,
            operation: POSITION_DATA_ACTIONS.DELETE,
            positionInfo: { quantity: closePositionQuantity },
            serviceId,
          }),
        );

        // no position request if fully closed
        if (quantity && Number(quantity) === Number(closePositionQuantity)) return;
      } else if (status === ORDERS_SOCKET_MESSAGES.EXPIRED) {
        const position = positions?.find((item) => item.positionId === closePositionId);

        if (!position) return;

        const newSettlingQuantity = new Decimal(position.settlingQuantity).sub(closePositionQuantity).toNumber();
        position.settlingQuantity = newSettlingQuantity;

        yield put(
          getPositionByIdSuccess({
            positionId: closePositionId,
            operation: POSITION_DATA_ACTIONS.UPDATE,
            positionInfo: position,
            serviceId,
          }),
        );
      } else if (status !== ORDERS_SOCKET_MESSAGES.CANCELED) {
        return;
      }
    }

    const { data } = yield call(getOrderInfo, { orderId, serviceId });
    const orderInfo = data.find((orderItem) => orderItem.orderId === orderId);

    switch (status) {
      case ORDERS_SOCKET_MESSAGES.FILLED: {
        if (!orderInfo.closePositionId) {
          positionId = orderInfo.orderId;
          operation = POSITION_DATA_ACTIONS.ADD_NEW;
        } else {
          // partially closed
          positionId = orderInfo.closePositionId;
          operation = POSITION_DATA_ACTIONS.UPDATE;
        }
        break;
      }
      case ORDERS_SOCKET_MESSAGES.CANCELED: {
        if (orderInfo.closePositionId) {
          positionId = orderInfo.closePositionId;
          operation = POSITION_DATA_ACTIONS.UPDATE;
        } else {
          return;
        }
        break;
      }
      case ORDERS_SOCKET_MESSAGES.ACTIVE: {
        if (orderInfo.closePositionId) {
          positionId = orderInfo.closePositionId;
          operation = POSITION_DATA_ACTIONS.UPDATE;
        } else {
          return;
        }
        break;
      }
      default: {
        return;
      }
    }

    let positionInfo;
    try {
      const {
        data: [apiResult],
      } = yield call(getPositionsOrderById, { positionId, serviceId });

      if (apiResult == null) return;

      positionInfo = apiResult;
    } catch (e) {
      if (e.response?.status === 404) {
        operation = POSITION_DATA_ACTIONS.POSITION_NOT_EXIST;
        positionInfo = { instrumentId: orderInfo.instrumentId };
      } else {
        throw e;
      }
    }

    yield put(getPositionByIdSuccess({ positionId, operation, positionInfo, serviceId }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

function* calcInstrumentMargin(checkAuth, getMarginGroupId) {
  const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
  const hasError = yield select((state) => state.socket.hasError);
  if (checkAuth(isAuthenticated) || hasError) {
    return false;
  }

  const accountInfo = yield* getAccountInfo(true);
  const rates = yield select((state) => state.currencies.rates);
  const instrumentList = yield select((state) => state.settings.instrumentList);
  const marginRates = {};

  Object.entries(instrumentList ?? {}).forEach(([instrumentId, instrumentDetail]) => {
    if (!rates[instrumentId]) {
      return;
    }
    const { serviceId } = instrumentDetail;
    const aInfo = accountInfo[serviceId];
    if (aInfo == null) {
      return;
    }
    if (aInfo.isNotAvailable) {
      return;
    }

    const marginGroupId = getMarginGroupId(aInfo, serviceId);
    const quantityMultiplier =
      instrumentList[instrumentId]?.quantityUnitConvFactor ?? DEFAULT_QUANTITY_UNIT_CONV_FACTOR;
    const { marginRatio } = instrumentDetail.margins[marginGroupId] ?? {};

    const convertRate = getRateConversion({ instrumentId, instrumentList, serviceId, rates, accountInfo });
    if (convertRate == null) {
      return;
    }

    marginRates[instrumentId] = {
      margin: new Decimal(convertRate)
        .mul(marginRatio ?? 0)
        .mul(quantityMultiplier)
        .toNumber(),
    };
  });

  yield put(replaceMarginRates({ marginRates }));
  return true;
}

export function* calcInstrumentMarginPeriodically() {
  const checkAuth = (isAuthenticated) => !isAuthenticated;
  const getMarginGroupId = (info) => info.marginGroupId;
  try {
    while (true) {
      const canBeContinued = yield* calcInstrumentMargin(checkAuth, getMarginGroupId);
      if (!canBeContinued) {
        break;
      }
      yield delay(DELAY_FOR_UPDATE_MARGIN);
    }
  } catch (e) {
    Logger.error(e);
  }
}

export function* calcPublicInstrumentMarginPeriodically() {
  const checkAuth = (isAuthenticated) => isAuthenticated;
  const getMarginGroupId = (_info, serviceId) => PERSONAL_MARGIN_GROUP_ID[serviceId];
  try {
    while (true) {
      const canBeContinued = yield* calcInstrumentMargin(checkAuth, getMarginGroupId);
      if (!canBeContinued) {
        break;
      }
      yield delay(DELAY_FOR_UPDATE_MARGIN);
    }
  } catch (e) {
    Logger.error(e);
  }
}

export default function* currenciesSagaHandler() {
  yield takeEvery(GET_RATES_REQUEST, getRatesRequestHandler);
  yield takeEvery(GET_POSITIONS_REQUEST, getPositionsRequestHandler);
  yield takeEvery(GET_POSITION_BY_ID_REQUEST, getPositionByIdRequestHandler);
  yield takeLatest(CALC_INSTRUMENT_MARGIN_PERIODICALLY, calcInstrumentMarginPeriodically);
  yield takeLatest(CALC_PUBLIC_INSTRUMENT_MARGIN_PERIODICALLY, calcPublicInstrumentMarginPeriodically);
}
