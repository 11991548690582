import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

export const Circle = memo(
  ({ className, patternId, r, cx, cy, stroke, strokeWidth, strokeDasharray, strokeDashoffset }) => {
    const style = useMemo(() => {
      if (patternId) {
        return { stroke: `url(#${patternId})` };
      }
      if (stroke) {
        return { stroke };
      }
      return undefined;
    }, [patternId, stroke]);

    const computedStrokeDashoffset = useMemo(() => {
      if (strokeDashoffset == null) {
        return -9;
      }
      return strokeDashoffset - 9;
    }, [strokeDashoffset]);

    return (
      <path
        className={className}
        style={style}
        fill="transparent"
        strokeLinecap="round"
        strokeDasharray={strokeDasharray}
        strokeDashoffset={computedStrokeDashoffset}
        strokeWidth={strokeWidth}
        d={`M ${cx} ${cy} A ${r} ${r} 0 1 1 ${cx - 0.001} ${cy}`}
      />
    );
  },
);

Circle.propTypes = {
  r: PropTypes.number.isRequired,
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
  strokeDasharray: PropTypes.string,
  strokeDashoffset: PropTypes.number,
  patternId: PropTypes.string,
  className: PropTypes.string,
};

Circle.defaultProps = {
  stroke: undefined,
  strokeWidth: undefined,
  strokeDasharray: undefined,
  strokeDashoffset: undefined,
  patternId: undefined,
  className: undefined,
};
