import { LOCATION_CHANGE, CALL_HISTORY_METHOD } from '../actionConstants/routerConstants';

export const onLocationChanged = (location, action, isFirstRendering = false) => ({
  type: LOCATION_CHANGE,
  payload: {
    location,
    action,
    isFirstRendering,
  },
});

const updateLocation = (method) => {
  return (...args) => ({
    type: CALL_HISTORY_METHOD,
    payload: {
      method,
      args,
    },
  });
};

export const push = updateLocation('push');
export const replace = updateLocation('replace');
