import { call, put, select } from 'redux-saga/effects';
import { ORDER_TYPES, SUCCESS_NOTIFICATION_MESSAGE } from '../../../constants/manualTrade';
import { createSingleOrder } from '../../../api/manualTradeApi';
import { createOrderStartLoading, createOrderStopLoading } from '../../actions/manualTradeActions';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { errorHandler } from '../errorSaga';
import { createDateString } from '../../../services';

function* createStandardOrderRequestHandler() {
  try {
    yield put(createOrderStartLoading());
    const serviceId = yield select((state) => state.auth.serviceId);
    const instrumentId = yield select((state) => state.manualTrade.selectedInstrumentId[serviceId]);
    const side = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.STANDARD.name][ORDER_TYPES.STANDARD.inputs.BUY_SELL],
    );
    const type = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.STANDARD.name][ORDER_TYPES.STANDARD.inputs.ORDER_METHOD],
    );
    const quantity = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.STANDARD.name][ORDER_TYPES.STANDARD.inputs.COUNT],
    );
    const price = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.STANDARD.name][ORDER_TYPES.STANDARD.inputs.PRICE],
    );
    const expirationType = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.STANDARD.name][ORDER_TYPES.STANDARD.inputs.EXPIRATION_TYPE],
    );
    const selectedDate = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.STANDARD.name][ORDER_TYPES.STANDARD.inputs.SELECTED_DATE],
    );
    const selectedTime = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.STANDARD.name][ORDER_TYPES.STANDARD.inputs.SELECTED_TIME],
    );

    const expireTime = createDateString(selectedDate, selectedTime);

    const requestBody = {
      instrumentId,
      side,
      type,
      quantity,
      price,
      expireType: expirationType,
      expireTime,
    };

    yield call(createSingleOrder, { requestBody, serviceId });
    yield put(sendNotificationSuccess({ message: SUCCESS_NOTIFICATION_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e, form: ORDER_TYPES.STANDARD.name });
  } finally {
    yield put(createOrderStopLoading());
  }
}

export default createStandardOrderRequestHandler;
