/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useEffect, useMemo } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useDispatch, useSelector } from 'react-redux';
import { makeNotificationErrorParams } from '../utils/service';
import { sendNotificationError } from '../redux/actions/notificationActions';
import { useAccountInfo } from './useAccountInfo';
import { ALL_SERVICES } from '../constants/core';
import {
  LOSSCUT_MODAL_BUTTON,
  LOSSCUT_MODAL_MESSAGE,
  LOSSCUT_MODAL_TITLE,
  MAINTENANCE_ERROR_MESSAGE_WEB,
  MAINTENANCE_MODAL_BUTTON,
  MAINTENANCE_MODAL_TITLE,
  NOT_EXIST_ERROR_MESSAGES_WEB,
  REPLACEMENT_TEXT_REG,
} from '../constants/errorMesages';
import { MAINTENANCE_INFOPAGE_URL, MAINTENANCE_PAGE_URL, MY_PAGE_HOME_URL } from '../config';
import { handleRedirect } from '../redux/actions/commonActions';
import { checkIsWebApp } from '../services';
import { isTrue } from '../utils';

export const useCheckAndNotifyAvailableService = () => {
  const dispatch = useDispatch();
  return useCallback(
    (accountInfo, serviceId) => {
      const errorParams = makeNotificationErrorParams({ accountInfo, serviceId });
      if (errorParams) {
        dispatch(sendNotificationError(errorParams));
      }
      return !!errorParams;
    },
    [dispatch],
  );
};

const isNotAvailableDefaultFunc = (accountInfo) => accountInfo.isNotAvailable;
const evaluateErrorDefaultFunc = makeNotificationErrorParams;

export const useCheckServiceAvailability = () => {
  const accountInfo = useAccountInfo();
  return useCallback(
    ({ currentServiceId, serviceId, isNotAvailableFunc, evaluateErrorFunc }) => {
      const isNotAvailable = isNotAvailableFunc ?? isNotAvailableDefaultFunc;
      const evaluateError = evaluateErrorFunc ?? evaluateErrorDefaultFunc;
      const errorParams = evaluateError({ accountInfo, serviceId });
      if (errorParams) {
        if (isNotAvailable(accountInfo[currentServiceId])) {
          // 現在のサービスが無効の場合
          const otherServices = ALL_SERVICES.filter((service) => service !== serviceId && service !== currentServiceId);
          const otherAvailableService = otherServices.find((service) => !isNotAvailable(accountInfo[service]));
          if (otherAvailableService) {
            // 他に有効なサービスがある場合
            return [errorParams, otherAvailableService];
          }
        }
        return [errorParams, currentServiceId];
      }
      return [errorParams, serviceId];
    },
    [accountInfo],
  );
};

export const useMaintenanceMessages = () => {
  return useMemo(() => {
    const title = MAINTENANCE_MODAL_TITLE;
    const [line, line3, line4] = MAINTENANCE_ERROR_MESSAGE_WEB.split(REPLACEMENT_TEXT_REG);
    const [line1, line2] = line.split(/(?=\n)/);
    const linkURL = MAINTENANCE_INFOPAGE_URL;
    const buttonLabel = MAINTENANCE_MODAL_BUTTON;
    return {
      title,
      line1,
      line2,
      line3,
      line4,
      linkURL,
      buttonLabel,
    };
  }, []);
};

export const useLossCutMessages = () => {
  return useMemo(() => {
    const title = LOSSCUT_MODAL_TITLE;
    const [line1, line2] = LOSSCUT_MODAL_MESSAGE.split('\n');
    const buttonLabel = LOSSCUT_MODAL_BUTTON;
    return {
      title,
      line1,
      line2,
      buttonLabel,
    };
  }, []);
};

export const useDisabledInfo = (accountInfo, serviceId) => {
  const { isMaintenance, isLossCut, notExist } = accountInfo;
  return useMemo(() => {
    let message;
    let linkURL;
    if (notExist) {
      message = NOT_EXIST_ERROR_MESSAGES_WEB[serviceId].split(REPLACEMENT_TEXT_REG);
      linkURL = MY_PAGE_HOME_URL;
    } else if (isMaintenance) {
      message = MAINTENANCE_ERROR_MESSAGE_WEB.split(REPLACEMENT_TEXT_REG);
      linkURL = MAINTENANCE_INFOPAGE_URL;
    } else if (isLossCut) {
      message = LOSSCUT_MODAL_MESSAGE;
    }
    return {
      message,
      linkURL,
    };
  }, [notExist, isLossCut, isMaintenance, serviceId]);
};

export const useRedirectAllMaintenance = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.router.location.query.isMobile);
  const accountInfo = useAccountInfo();
  useEffect(() => {
    const isAllMaintenance = ALL_SERVICES.filter((service) => !accountInfo[service].notExist).every(
      (service) => accountInfo[service].isMaintenance,
    );
    // mobile の chart 画面では redirect させない
    if (isAllMaintenance && checkIsWebApp() && !isTrue(isMobile)) {
      dispatch(handleRedirect({ url: MAINTENANCE_PAGE_URL, isInner: false }));
    }
  }, [dispatch, accountInfo, isMobile]);
};
