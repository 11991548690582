import { CFD, ETF, FX } from '../../constants';
import {
  CREATE_INSTRUMENTS_OPTIONS,
  CREATE_SELECTION_TERMS_OPTIONS,
  SET_DEFAULT_SELECTION_TERM,
} from '../actionConstants/constantsConstants';

export const initialState = {
  [FX]: {
    instrumentsOptions: [],
  },
  [ETF]: {
    instrumentsOptions: [],
  },
  [CFD]: {
    instrumentsOptions: [],
  },
  selectionTermOptions: [],
  defaultSelectionTermId: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_INSTRUMENTS_OPTIONS: {
      const { serviceId, options } = payload;
      return {
        ...state,
        [serviceId]: {
          instrumentsOptions: options,
        },
      };
    }
    case CREATE_SELECTION_TERMS_OPTIONS: {
      const { termList } = payload;
      return {
        ...state,
        selectionTermOptions: termList,
      };
    }
    case SET_DEFAULT_SELECTION_TERM: {
      const { termId } = payload;
      return {
        ...state,
        defaultSelectionTermId: termId,
      };
    }
    default: {
      return state;
    }
  }
};
