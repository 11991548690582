import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './empty.module.scss';

export const Empty = memo(({ title, desc }) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={styles.desc}>{desc}</div>
  </div>
));

Empty.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};
