import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './multiEditTableHeader.module.scss';
import IconButton from '../../../../../../../components/IconButton';
import { openMultiEditSelectModal } from '../../../../../../../redux/actions';
import { DEFAULT_CHART_HEIGHT, SPACER } from '../../../constants';

const MultiEditTableHeader = ({ bodyHeight, chartHeight, setChartHeight, propTableRowsRef }) => {
  const dispatch = useDispatch();
  const selectedTableRows = useSelector((state) => state.multiEdit.selectedTableRows);

  const isSelectMultiEditDisabled = useMemo(
    () => selectedTableRows.filter((row) => row.isChecked === true).length === 0,
    [selectedTableRows],
  );

  const handleZoomOut = useCallback(() => {
    setChartHeight(0);
  }, [setChartHeight]);

  const handleZoomIn = useCallback(() => {
    const threshold = bodyHeight - SPACER;
    if (threshold < DEFAULT_CHART_HEIGHT) {
      setChartHeight(threshold / 2);
    } else {
      setChartHeight(DEFAULT_CHART_HEIGHT);
    }
  }, [setChartHeight, bodyHeight]);

  const openSelectMultiEditModal = useCallback(() => {
    const selectedRows = selectedTableRows
      .filter((row) => row.isChecked === true && row.apId)
      .map((row) => {
        const { quantity, tp, sl, follow, counter, counterType } = propTableRowsRef.current[row.apId];

        return {
          quantity,
          tp,
          sl,
          follow,
          counter,
          counterType,
        };
      });
    dispatch(openMultiEditSelectModal({ callback: null, selectedRows }));
  }, [dispatch, propTableRowsRef, selectedTableRows]);
  const editButton = (
    <button
      type="button"
      className="btn btn-secondary btn-lg button--secondary ms-auto me-4"
      onClick={openSelectMultiEditModal}
      disabled={isSelectMultiEditDisabled}
    >
      選択項目の一括設定
    </button>
  );

  return (
    <div>
      <div className={styles.expandWrapper}>
        <div className={styles.label}>{`チャートを${chartHeight ? '非' : ''}表示`}</div>
        <IconButton
          className={styles.right}
          iconName={chartHeight !== 0 ? 'expand_less' : 'expand_more'}
          onClick={chartHeight !== 0 ? handleZoomOut : handleZoomIn}
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.title}>変更したい注文をチェックして一括設定をしてください。個別編集も可能です。</div>
        {editButton}
      </div>
    </div>
  );
};

MultiEditTableHeader.propTypes = {
  bodyHeight: PropTypes.number,
  chartHeight: PropTypes.number,
  setChartHeight: PropTypes.func,
  propTableRowsRef: PropTypes.shape({ current: PropTypes.shape({}).isRequired }).isRequired,
};

MultiEditTableHeader.defaultProps = {
  bodyHeight: 0,
  chartHeight: 0,
  setChartHeight() {},
};

export default memo(MultiEditTableHeader);
