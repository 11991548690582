import {
  OPEN_CONFIRMATION_MODAL,
  CLOSE_CONFIRMATION_MODAL,
  OPEN_INPUT_CONFIRMATION_MODAL,
  CLOSE_INPUT_CONFIRMATION_MODAL,
  OPEN_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL,
  CLOSE_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL,
  OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL,
  CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL,
  OPEN_BUILDER_CONFIGURATION_FLOW_MODAL,
  CLOSE_BUILDER_CONFIGURATION_FLOW_MODAL,
  OPEN_BUILDER_ADD_TO_CART_MODAL,
  CLOSE_BUILDER_ADD_TO_CART_MODAL,
  OPEN_BUILDER_ADD_TO_PORTFOLIO_MODAL,
  CLOSE_BUILDER_ADD_TO_PORTFOLIO_MODAL,
  OPEN_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL,
  CLOSE_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL,
  OPEN_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL,
  CLOSE_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL,
  OPEN_CHART_MAKE_ADD_TO_CART_MODAL,
  CLOSE_CHART_MAKE_ADD_TO_CART_MODAL,
  OPEN_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL,
  CLOSE_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL,
  OPEN_TECH_ADD_TO_CART_MODAL,
  CLOSE_TECH_ADD_TO_CART_MODAL,
  OPEN_TECH_ADD_TO_PORTFOLIO_MODAL,
  CLOSE_TECH_ADD_TO_PORTFOLIO_MODAL,
  OPEN_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL,
  CLOSE_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL,
  OPEN_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL,
  CLOSE_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL,
  OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER,
  CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER,
  OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION,
  CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION,
  OPEN_MANUAL_TRADE_CURRENCY_SETTINGS,
  CLOSE_MANUAL_TRADE_CURRENCY_SETTINGS,
  OPEN_MANUAL_TRADE_CONFIG,
  CLOSE_MANUAL_TRADE_CONFIG,
  OPEN_MANUAL_TRADE_NEWS,
  CLOSE_MANUAL_TRADE_NEWS,
  OPEN_MANUAL_TRADE_CONFIRMATION,
  CLOSE_MANUAL_TRADE_CONFIRMATION,
  OPEN_MANUAL_TRADE_TABLE_CHANGE_ORDER,
  CLOSE_MANUAL_TRADE_TABLE_CHANGE_ORDER,
  OPEN_BULK_CHANGE,
  CLOSE_BULK_CHANGE,
  OPEN_BULK_CHANGE_CONFIRM_CHECK,
  CLOSE_BULK_CHANGE_CONFIRM_CHECK,
  OPEN_BULK_CHANGE_SELECT,
  CLOSE_BULK_CHANGE_SELECT,
  OPEN_BULK_CHANGE_FAIL,
  CLOSE_BULK_CHANGE_FAIL,
  OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE,
  CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE,
  OPEN_AUTO_SELECT_RUN_NOW_CONFIRMATION,
  CLOSE_AUTO_SELECT_RUN_NOW_CONFIRMATION,
  OPEN_AUTO_SELECT_ADD_TO_CART_CONFIRMATION,
  CLOSE_AUTO_SELECT_ADD_TO_CART_CONFIRMATION,
  OPEN_HELP_INFO,
  CLOSE_HELP_INFO,
  OPEN_COPYRIGHT_INFO,
  CLOSE_COPYRIGHT_INFO,
  OPEN_AGREEMENT_INFO,
  CLOSE_AGREEMENT_INFO,
  OPEN_NOTIFICATION_SETTINGS,
  CLOSE_NOTIFICATION_SETTINGS,
  OPEN_REPORTS_DETAILS,
  CLOSE_REPORTS_DETAILS,
  OPEN_BUILDER_WARNING_INFO,
  CLOSE_BUILDER_WARNING_INFO,
  OPEN_AUTO_SELECT_WARNING_INFO,
  CLOSE_AUTO_SELECT_WARNING_INFO,
  OPEN_STRATEGIES_SUCCESS,
  CLOSE_STRATEGIES_SUCCESS,
  OPEN_YOUTUBE_MODAL,
  CLOSE_YOUTUBE_MODAL,
  OPEN_LAB_TAG_INFO_MODAL,
  CLOSE_LAB_TAG_INFO_MODAL,
  OPEN_RANGE_OUT_CONFIRMATION,
  CLOSE_RANGE_OUT_CONFIRMATION,
  OPEN_TECH_LOGIC_INFO,
  CLOSE_TECH_LOGIC_INFO,
  OPEN_TUTORIAL_MODAL,
  CLOSE_TUTORIAL_MODAL,
  OPEN_TUTORIAL_PORTFOLIO_DETAIL_MODAL,
  CLOSE_TUTORIAL_PORTFOLIO_DETAIL_MODAL,
  OPEN_TECH_BUILDER_CONFIGURATION_FLOW_MODAL,
  CLOSE_TECH_BUILDER_CONFIGURATION_FLOW_MODAL,
  OPEN_BEGIN_DEPOSIT_MODAL,
  CLOSE_BEGIN_DEPOSIT_MODAL,
  OPEN_BEGIN_RUN_AUTO_MODAL,
  CLOSE_BEGIN_RUN_AUTO_MODAL,
  OPEN_TECH_BUILDER_WARNING_INFO,
  CLOSE_TECH_BUILDER_WARNING_INFO,
  OPEN_SEARCH_AUTO_SELECT_RULE_NAME_MODAL,
  CLOSE_SEARCH_AUTO_SELECT_RULE_NAME_MODAL,
} from '../actionConstants/modalConstants';

export const openConfirmationModal = ({
  title,
  callback,
  bodyText,
  buttonBackText,
  buttonNextText,
  isOverlap,
  successButtonIsGreen,
}) => ({
  type: OPEN_CONFIRMATION_MODAL,
  payload: { title, callback, bodyText, buttonBackText, buttonNextText, isOverlap, successButtonIsGreen },
});
export const closeConfirmationModal = () => ({ type: CLOSE_CONFIRMATION_MODAL });

export const openInputConfirmationModal = ({
  title,
  callback,
  bodyText,
  buttonBackText,
  buttonNextText,
  value,
  passValueWithKey,
  isOverlap,
}) => ({
  type: OPEN_INPUT_CONFIRMATION_MODAL,
  payload: { title, callback, bodyText, buttonBackText, buttonNextText, value, passValueWithKey, isOverlap },
});
export const closeInputConfirmationModal = () => ({ type: CLOSE_INPUT_CONFIRMATION_MODAL });

export const openPortfolioManualTradeDetailModal = ({ data }) => ({
  type: OPEN_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL,
  payload: { data },
});
export const closePortfolioManualTradeDetailModal = () => ({ type: CLOSE_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL });

export const openPortfolioAutoTradeDetailModal = ({ data, withoutModal = false }) => ({
  type: OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL,
  payload: { data, withoutModal },
});
export const closePortfolioAutoTradeDetailModal = () => ({ type: CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL });

export const openBuilderConfigurationFlowModal = () => ({ type: OPEN_BUILDER_CONFIGURATION_FLOW_MODAL });
export const closeBuilderConfigurationFlowModal = () => ({ type: CLOSE_BUILDER_CONFIGURATION_FLOW_MODAL });

export const openTechBuilderConfigurationFlowModal = () => ({ type: OPEN_TECH_BUILDER_CONFIGURATION_FLOW_MODAL });
export const closeTechBuilderConfigurationFlowModal = () => ({ type: CLOSE_TECH_BUILDER_CONFIGURATION_FLOW_MODAL });

export const openBuilderAddToCartModal = (isOrderSetting) => ({
  type: OPEN_BUILDER_ADD_TO_CART_MODAL,
  payload: { isOrderSetting },
});
export const closeBuilderAddToCartModal = () => ({ type: CLOSE_BUILDER_ADD_TO_CART_MODAL });

export const openBuilderAddToPortfolioModal = (isOrderSetting) => ({
  type: OPEN_BUILDER_ADD_TO_PORTFOLIO_MODAL,
  payload: { isOrderSetting },
});
export const closeBuilderAddToPortfolioModal = () => ({ type: CLOSE_BUILDER_ADD_TO_PORTFOLIO_MODAL });

export const openBuilderAddToPortfolioSuccessModal = ({ orderName }) => ({
  type: OPEN_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL,
  payload: { orderName },
});
export const closeBuilderAddToPortfolioSuccessModal = () => ({ type: CLOSE_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL });

export const openBuilderAddToPortfolioFailModal = ({ orderName }) => ({
  type: OPEN_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL,
  payload: { orderName },
});
export const closeBuilderAddToPortfolioFailModal = () => ({ type: CLOSE_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL });

export const openChartMakeAddToCartModal = ({
  fundIndication,
  requiredMargin,
  sets,
  callback,
  isLaunchInsideDetailModal,
  selectionId,
  selectionVersion,
}) => ({
  type: OPEN_CHART_MAKE_ADD_TO_CART_MODAL,
  payload: {
    fundIndication,
    requiredMargin,
    sets,
    callback,
    isLaunchInsideDetailModal,
    selectionId,
    selectionVersion,
  },
});
export const closeChartMakeAddToCartModal = () => ({ type: CLOSE_CHART_MAKE_ADD_TO_CART_MODAL });

export const openChartMakeRunNowConfirmationModal = ({ fundIndication, requiredMargin, sets, callback }) => ({
  type: OPEN_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL,
  payload: { fundIndication, requiredMargin, sets, callback },
});
export const closeChartMakeRunNowConfirmationModal = () => ({ type: CLOSE_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL });

export const openTechAddToCartModal = (isOrderSetting) => ({
  type: OPEN_TECH_ADD_TO_CART_MODAL,
  payload: { isOrderSetting },
});
export const closeTechAddToCartModal = () => ({ type: CLOSE_TECH_ADD_TO_CART_MODAL });

export const openTechAddToPortfolioModal = (isOrderSetting) => ({
  type: OPEN_TECH_ADD_TO_PORTFOLIO_MODAL,
  payload: { isOrderSetting },
});
export const closeTechAddToPortfolioModal = () => ({ type: CLOSE_TECH_ADD_TO_PORTFOLIO_MODAL });

export const openTechAddToPortfolioSuccessModal = ({ orderName }) => ({
  type: OPEN_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL,
  payload: { orderName },
});
export const closeTechAddToPortfolioSuccessModal = () => ({ type: CLOSE_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL });

export const openTechAddToPortfolioFailModal = ({ orderName }) => ({
  type: OPEN_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL,
  payload: { orderName },
});
export const closeTechAddToPortfolioFailModal = () => ({ type: CLOSE_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL });

export const openManualTradeTableCloseOrder = ({ positionInfo }) => ({
  type: OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER,
  payload: { positionInfo },
});
export const closeManualTradeTableCloseOrder = () => ({ type: CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER });

export const openManualTradeTableCloseOrderConfirmation = ({ callback, data }) => ({
  type: OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION,
  payload: { callback, data },
});
export const closeManualTradeTableCloseOrderConfirmation = () => ({
  type: CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION,
});

export const openManualTradeCurrencySettings = () => ({
  type: OPEN_MANUAL_TRADE_CURRENCY_SETTINGS,
});
export const closeManualTradeCurrencySettings = () => ({
  type: CLOSE_MANUAL_TRADE_CURRENCY_SETTINGS,
});

export const openManualTradeConfig = () => ({ type: OPEN_MANUAL_TRADE_CONFIG });
export const closeManualTradeConfig = () => ({ type: CLOSE_MANUAL_TRADE_CONFIG });

export const openManualTradeNews = () => ({ type: OPEN_MANUAL_TRADE_NEWS });
export const closeManualTradeNews = () => ({ type: CLOSE_MANUAL_TRADE_NEWS });

export const openManualTradeConfirmation = ({ callback, orderType }) => ({
  type: OPEN_MANUAL_TRADE_CONFIRMATION,
  payload: { callback, orderType },
});
export const closeManualTradeConfirmation = () => ({ type: CLOSE_MANUAL_TRADE_CONFIRMATION });

export const openManualTradeTableChangeOrder = ({ orderId, isManual }) => ({
  type: OPEN_MANUAL_TRADE_TABLE_CHANGE_ORDER,
  payload: { orderId, isManual },
});
export const closeManualTradeTableChangeOrder = () => ({ type: CLOSE_MANUAL_TRADE_TABLE_CHANGE_ORDER });

export const openMultiEditSelectModal = ({ callback, selectedRows }) => ({
  type: OPEN_BULK_CHANGE_SELECT,
  payload: { callback, selectedRows },
});
export const closeMultiEditSelect = () => ({ type: CLOSE_BULK_CHANGE_SELECT });

export const openMultiEdit = () => ({ type: OPEN_BULK_CHANGE });
export const closeMultiEdit = () => ({ type: CLOSE_BULK_CHANGE });

export const openMultiEditFail = () => ({ type: OPEN_BULK_CHANGE_FAIL });
export const closeMultiEditFail = () => ({ type: CLOSE_BULK_CHANGE_FAIL });

export const openMultiEditCheckComfirm = ({ rowNum }) => ({
  type: OPEN_BULK_CHANGE_CONFIRM_CHECK,
  payload: { rowNum },
});
export const closeMultiEditCheckComfirm = () => ({ type: CLOSE_BULK_CHANGE_CONFIRM_CHECK });

export const openPortfolioAutoTradeDetailOrderType = ({
  name,
  instrumentId,
  side,
  quantity,
  entryPrice1,
  entryPrice2,
  tp,
  sl,
  follow,
  counter,
  counterPrice,
  tradePrice,
  status,
  positionId,
  latestNewOrderPrice1,
  latestNewOrderPrice2,
  id,
  orderStatus,
}) => ({
  type: OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE,
  payload: {
    name,
    instrumentId,
    side,
    quantity,
    entryPrice1,
    entryPrice2,
    tp,
    sl,
    follow,
    counter,
    counterPrice,
    tradePrice,
    status,
    positionId,
    latestNewOrderPrice1,
    latestNewOrderPrice2,
    id,
    orderStatus,
  },
});
export const closePortfolioAutoTradeDetailOrderType = () => ({ type: CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE });

// TODO CFD typescript であれば。。。
export const openAutoSelectRunNowConfirmation = ({
  marginRecommended,
  marginRecommendedFX,
  marginRecommendedETF,
  marginRecommendedCFD,
  marginRequired,
  marginRequiredFX,
  marginRequiredETF,
  marginRequiredCFD,
  sets,
  callback,
  isLab,
}) => ({
  type: OPEN_AUTO_SELECT_RUN_NOW_CONFIRMATION,
  payload: {
    marginRecommended,
    marginRecommendedFX,
    marginRecommendedETF,
    marginRecommendedCFD,
    marginRequired,
    marginRequiredFX,
    marginRequiredETF,
    marginRequiredCFD,
    sets,
    callback,
    isLab,
  },
});
export const closeAutoSelectRunNowConfirmation = () => ({
  type: CLOSE_AUTO_SELECT_RUN_NOW_CONFIRMATION,
});

export const openAutoSelectAddToCartConfirmation = ({
  margin,
  marginRequired,
  sets,
  callback,
  isLaunchInsideDetailModal,
  selectionId,
  selectionVersion,
  isLab,
}) => ({
  type: OPEN_AUTO_SELECT_ADD_TO_CART_CONFIRMATION,
  payload: {
    margin,
    marginRequired,
    sets,
    callback,
    isLaunchInsideDetailModal,
    selectionId,
    selectionVersion,
    isLab,
  },
});
export const closeAutoSelectAddToCartConfirmation = () => ({
  type: CLOSE_AUTO_SELECT_ADD_TO_CART_CONFIRMATION,
});

export const openHelpInfo = () => ({ type: OPEN_HELP_INFO });
export const closeHelpInfo = () => ({ type: CLOSE_HELP_INFO });

export const openCopyrightInfo = ({ isOverlap }) => ({ type: OPEN_COPYRIGHT_INFO, payload: { isOverlap } });
export const closeCopyrightInfo = () => ({ type: CLOSE_COPYRIGHT_INFO });

export const openNotificationSettings = () => ({ type: OPEN_NOTIFICATION_SETTINGS });
export const closeNotificationSettings = () => ({ type: CLOSE_NOTIFICATION_SETTINGS });

export const openAgreementInfo = () => ({ type: OPEN_AGREEMENT_INFO });
export const closeAgreementInfo = () => ({ type: CLOSE_AGREEMENT_INFO });

export const openReportsDetails = () => ({ type: OPEN_REPORTS_DETAILS });
export const closeReportsDetails = () => ({ type: CLOSE_REPORTS_DETAILS });

export const openBuilderWarningInfo = () => ({ type: OPEN_BUILDER_WARNING_INFO });
export const closeBuilderWarningInfo = () => ({ type: CLOSE_BUILDER_WARNING_INFO });

export const openTechBuilderWarningInfo = () => ({ type: OPEN_TECH_BUILDER_WARNING_INFO });
export const closeTechBuilderWarningInfo = () => ({ type: CLOSE_TECH_BUILDER_WARNING_INFO });

export const openAutoSelectWarningInfo = ({ termEndDate, isLabPreview }) => ({
  type: OPEN_AUTO_SELECT_WARNING_INFO,
  payload: { termEndDate, isLabPreview },
});
export const closeAutoSelectWarningInfo = () => ({ type: CLOSE_AUTO_SELECT_WARNING_INFO });

export const openStrategiesSuccessModal = ({ resultArray }) => ({
  type: OPEN_STRATEGIES_SUCCESS,
  payload: { resultArray },
});
export const closeStrategiesSuccessModal = () => ({ type: CLOSE_STRATEGIES_SUCCESS });

export const openYoutubeModal = ({ url }) => ({ type: OPEN_YOUTUBE_MODAL, payload: { url } });
export const closeYoutubeModal = () => ({ type: CLOSE_YOUTUBE_MODAL });

export const openLabTagInfoModal = () => ({ type: OPEN_LAB_TAG_INFO_MODAL });
export const closeLabTagInfoModal = () => ({ type: CLOSE_LAB_TAG_INFO_MODAL });

export const openRangeOutConfirmation = ({ linkUrl, messageId, parameters, serviceValues }) => ({
  type: OPEN_RANGE_OUT_CONFIRMATION,
  payload: { linkUrl, messageId, parameters, serviceValues },
});
export const closeRangeOutConfirmation = () => ({ type: CLOSE_RANGE_OUT_CONFIRMATION });

export const openTechLogicInfoModal = () => ({ type: OPEN_TECH_LOGIC_INFO });

export const closeTechLogicInfoModal = () => ({ type: CLOSE_TECH_LOGIC_INFO });

export const openTutorialModal = () => ({ type: OPEN_TUTORIAL_MODAL });
export const closeTutorialModal = () => ({ type: CLOSE_TUTORIAL_MODAL });

export const openTutorialPortfolioDetailModal = () => ({ type: OPEN_TUTORIAL_PORTFOLIO_DETAIL_MODAL });
export const closeTutorialPortfolioDetailModal = () => ({ type: CLOSE_TUTORIAL_PORTFOLIO_DETAIL_MODAL });

export const openBeginDepositModal = () => ({ type: OPEN_BEGIN_DEPOSIT_MODAL });
export const closeBeginDepositModal = () => ({ type: CLOSE_BEGIN_DEPOSIT_MODAL });

export const openBeginRunAutoModal = () => ({ type: OPEN_BEGIN_RUN_AUTO_MODAL });
export const closeBeginRunAutoModal = () => ({ type: CLOSE_BEGIN_RUN_AUTO_MODAL });

export const openSearchAutoSelectRuleNameModal = ({ autoSelectData }) => ({
  type: OPEN_SEARCH_AUTO_SELECT_RULE_NAME_MODAL,
  payload: { autoSelectData },
});
export const closeSearchAutoSelectRuleNameModal = () => ({ type: CLOSE_SEARCH_AUTO_SELECT_RULE_NAME_MODAL });
