import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ValueWithPrecision from '../../../../../../components/ValueWithPrecision';
import { ReactComponent as DownArrow } from '../../../../../../assets/downArrow.svg';
import { ReactComponent as UpArrow } from '../../../../../../assets/upArrow.svg';
import styles from './priceValue.module.scss';

export const PriceValue = memo(({ priceValue, instrumentId, previousValue, label, isReversed }) => (
  <div className={classNames('position-relative', { [styles.priceContainer]: true, [styles.isReversed]: isReversed })}>
    <div className={styles.topArrowLabel}>
      <span className={styles.differentPriceLabel}>{label}</span>
    </div>
    <span className={styles.priceValue}>
      {Boolean(previousValue) && priceValue < previousValue && (
        <DownArrow className={styles.downArrow} key={priceValue} />
      )}
      {Boolean(previousValue) && priceValue > previousValue && <UpArrow className={styles.upArrow} key={priceValue} />}
      <ValueWithPrecision value={priceValue} instrumentId={instrumentId} />
    </span>
  </div>
));

PriceValue.propTypes = {
  label: PropTypes.string.isRequired,
  priceValue: PropTypes.number,
  instrumentId: PropTypes.string.isRequired,
  previousValue: PropTypes.number,
  isReversed: PropTypes.bool,
};

PriceValue.defaultProps = {
  priceValue: 0,
  previousValue: 0,
  isReversed: false,
};
