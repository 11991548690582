import { put, select, call } from 'redux-saga/effects';
import { getOrders } from '../../../api/manualTradeApi';
import {
  getOrdersTableDataSuccess,
  ordersTableStartLoading,
  ordersTableStopLoading,
} from '../../actions/manualTradeActions';
import { errorHandler } from '../errorSaga';
import { toTradeMethodsParameter } from './utils';
import { removeSuffix } from '../../../hooks/symbol';

export default function* getOrdersTableDataRequestHandler(action) {
  const serviceId = yield select((state) => state.auth.serviceId);
  try {
    yield put(ordersTableStartLoading({ serviceId }));
    const tableMetaInfo = yield select((state) => state.manualTrade.ordersDataMetaInfo[serviceId]);

    const {
      payload: { isFirstData, isManual },
    } = action;
    if (isFirstData) {
      yield put(getOrdersTableDataSuccess({ data: [], totalPages: 1, pageNumber: 1, isFirstData, serviceId }));
    }

    const newPageNumber = isFirstData ? 1 : tableMetaInfo.pageNumber + 1;
    if (!isFirstData && newPageNumber > tableMetaInfo.totalPages) {
      return;
    }

    const {
      data: {
        list: data,
        pageInfo: { totalPages, pageNumber },
      },
    } = yield call(getOrders, {
      pageNumber: newPageNumber,
      sortBy: tableMetaInfo.sortBy,
      sortDir: tableMetaInfo.sortDir,
      isActiveOrderSelected: tableMetaInfo.isActiveOrderSelected,
      instrumentId: removeSuffix(tableMetaInfo.instrumentId),
      tradeMethods: toTradeMethodsParameter(tableMetaInfo.tradeMethods, serviceId, isManual),
      serviceId,
    });

    yield put(getOrdersTableDataSuccess({ data, totalPages, pageNumber, isFirstData, serviceId }));
  } catch (e) {
    yield call(errorHandler, { error: e });

    yield put(getOrdersTableDataSuccess({ data: [], totalPages: 1, pageNumber: 1, isFirstData: true, serviceId }));
  } finally {
    yield put(ordersTableStopLoading({ serviceId }));
  }
}
