import { memo } from 'react';
import PropTypes from 'prop-types';
import { useServiceName } from 'shared-modules/hooks';
import { useSummaryInfoByServiceId } from 'shared-modules/services/hooks';
import { TOOLTIP_MESSAGE } from 'shared-modules/constants';
import InfoTooltip from '../InfoTooltip';
import { ColoredPriceText, MarginRateText, MarginText } from '../../../../components/Texts';
import styles from './accountDetailHeader.module.scss';

export const AccountDetailHeader = memo(({ serviceId }) => {
  const summaryInfo = useSummaryInfoByServiceId(serviceId);
  const {
    effectiveMargin,
    effectiveMarginRate,
    positionRequiredMargin,
    orderingRequiredMargin,
    receivedMargin,
    unsettledPl,
    orderableMargin,
  } = summaryInfo;
  const serviceName = useServiceName(serviceId);
  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div>
          <div className={styles.item}>
            <div className={styles.row}>
              <div className={styles.label}>証拠金預託額</div>
              <InfoTooltip content={TOOLTIP_MESSAGE[`RECEIVED_MARGIN_${serviceName}`]} />
            </div>
            <div className={styles.row}>
              <MarginText className={styles.value} symbolClassName={styles.symbol} value={receivedMargin} />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.row}>
              <div className={styles.label}>評価損益</div>
              <InfoTooltip content={TOOLTIP_MESSAGE.UNSETTLED_PL} />
            </div>
            <div className={styles.row}>
              <ColoredPriceText className={styles.value} symbolClassName={styles.symbol} value={unsettledPl} />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.item}>
            <div className={styles.row}>
              <div className={styles.label}>有効証拠金</div>
              <InfoTooltip content={TOOLTIP_MESSAGE[`EFFECTIVE_MARGIN_${serviceName}`]} />
            </div>
            <div className={styles.row}>
              <MarginText className={styles.value} symbolClassName={styles.symbol} value={effectiveMargin} />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.row}>
              <div className={styles.label}>必要証拠金</div>
              <InfoTooltip content={TOOLTIP_MESSAGE.POSITION_REQUIRED_MARGIN} />
            </div>
            <div className={styles.row}>
              <MarginText className={styles.value} symbolClassName={styles.symbol} value={positionRequiredMargin} />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.item}>
            <div className={styles.row}>
              <div className={styles.label}>有効比率</div>
              <InfoTooltip content={TOOLTIP_MESSAGE.EFFECTIVE_MARGIN_RATE} />
            </div>
            <div className={styles.row}>
              <MarginRateText className={styles.value} symbolClassName={styles.symbol} value={effectiveMarginRate} />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.row}>
              <div className={styles.label}>発注可能額</div>
              <InfoTooltip content={TOOLTIP_MESSAGE.ORDERABLE_MARGIN} />
            </div>
            <div className={styles.row}>
              <MarginText className={styles.value} symbolClassName={styles.symbol} value={orderableMargin} />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.item} />
          <div className={styles.item}>
            <div className={styles.row}>
              <div className={styles.label}>発注証拠金</div>
              <InfoTooltip content={TOOLTIP_MESSAGE.ORDERING_REQUIRED_MARGIN} />
            </div>
            <div className={styles.row}>
              <MarginText className={styles.value} symbolClassName={styles.symbol} value={orderingRequiredMargin} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

AccountDetailHeader.propTypes = {
  serviceId: PropTypes.string.isRequired,
};
