import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CART_TABS } from 'shared-modules/constants/cart';
import { useCurrentCart, useChangeCartType } from 'shared-modules/services/hooks/cartLogic';
import { getCurrentCartRequest, openConfirmationModal } from '../../../../redux/actions';
import { LinkButton } from '../../../../components';
import { CartList } from '../CartList';
import { EmptyMessage } from '../EmptyMessage';
import { Footer } from './Footer';
import { Item } from './Item';
import styles from './currentCart.module.scss';

export const CurrentCart = memo(() => {
  const dispatch = useDispatch();
  const { errorMessages, changeErrorMessages } = useChangeCartType();
  const isLoading = useSelector((state) => state.cart.currentDataIsLoading);
  const termId = useSelector((state) => state.constants.defaultSelectionTermId);
  const { currentCartData, deleteCurrentCart, saveCurrentCart, isLoadingSaveCurrentCart, isLoadingDeleteCurrentCart } =
    useCurrentCart(CART_TABS.CURRENT.ID);
  const isCartItemsExecuting = useSelector((state) => state.cart.currentDataExecuteIsLoading);
  const currentDataDeleteItem = useSelector((state) => state.cart.currentDataDeleteItem);
  const isDeletingAnyItem = currentDataDeleteItem?.isLoading === true;
  const isDeleting = isDeletingAnyItem || isLoadingDeleteCurrentCart;
  // 稼働リクエスト・保存リクエスト・削除リクエスト中やカートにデータがない場合は削除ボタン非活性
  const disabledDelete = !currentCartData.length || isCartItemsExecuting || isLoadingSaveCurrentCart || isDeleting;
  // 保存リクエスト・削除リクエスト中やカートにデータがない場合は保存ボタン非活性
  const disabledSave = !currentCartData.length || isLoadingSaveCurrentCart || isDeleting;
  // 稼働リクエスト・削除リクエスト中やカートにデータがない場合は稼働ボタン非活性
  const disabledAllRun = !currentCartData.length || isCartItemsExecuting || isDeleting;

  const handleDeleteButtonClick = useCallback(() => {
    dispatch(
      openConfirmationModal({
        title: 'カート削除',
        bodyText: 'カートの中身を空にします。よろしいですか？',
        buttonBackText: '戻る',
        buttonNextText: '削除する',
        callback: deleteCurrentCart,
      }),
    );
  }, [dispatch, deleteCurrentCart]);

  const listContainer = useMemo(() => {
    return currentCartData.map((item) => (
      <Item
        key={item.itemId}
        item={item}
        disabledSave={disabledSave}
        disabledDelete={disabledDelete}
        resetErrorFunction={changeErrorMessages}
        errorMessages={errorMessages}
      />
    ));
  }, [currentCartData, disabledSave, disabledDelete, changeErrorMessages, errorMessages]);

  const content = useMemo(() => {
    if (listContainer?.length > 0) {
      return (
        <>
          {listContainer}
          <div className={styles.linkButton}>
            <LinkButton disabled={disabledDelete} onClick={handleDeleteButtonClick} danger>
              一括削除
            </LinkButton>
          </div>
        </>
      );
    }
    return (
      <EmptyMessage>
        <div>
          カートに追加されている
          <br />
          自動売買はありません
        </div>
      </EmptyMessage>
    );
  }, [listContainer, disabledDelete, handleDeleteButtonClick]);

  useEffect(() => {
    dispatch(getCurrentCartRequest({ termId }));
  }, [dispatch, termId]);

  return (
    <CartList
      loading={isLoading}
      content={content}
      footer={
        <Footer
          errorMessages={errorMessages}
          onSave={saveCurrentCart}
          disabledAllSave={disabledSave}
          disabledAllRun={disabledAllRun}
        />
      }
    />
  );
});
