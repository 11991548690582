import {
  GET_RATES_START_LOADING,
  GET_RATES_STOP_LOADING,
  GET_RATES_REQUEST,
  GET_RATES_SUCCESS,
  UPDATE_RATES_VALUES,
  GET_POSITIONS_REQUEST,
  GET_POSITIONS_SUCCESS,
  GET_POSITION_BY_ID_REQUEST,
  GET_POSITION_BY_ID_SUCCESS,
  UPDATE_UNREALIZED_PROFIT_LOSS_VALUES,
  REPLACE_MARGIN_RATES,
  CALC_INSTRUMENT_MARGIN_PERIODICALLY,
  CALC_PUBLIC_INSTRUMENT_MARGIN_PERIODICALLY,
  GET_EOD_RATES_SUCCESS,
} from '../actionConstants/currenciesConstants';

export const getRatesStartLoading = () => ({ type: GET_RATES_START_LOADING });
export const getRatesStopLoading = () => ({ type: GET_RATES_STOP_LOADING });
export const getRatesRequest = ({ isPublic = false } = {}) => ({
  type: GET_RATES_REQUEST,
  payload: { isPublic },
});
export const getRatesSuccess = ({ rates }) => ({ type: GET_RATES_SUCCESS, payload: { rates } });
export const getEodRatesSuccess = ({ eodRates }) => ({ type: GET_EOD_RATES_SUCCESS, payload: { eodRates } });

export const updateRatesValues = ({ currencyRates }) => ({
  type: UPDATE_RATES_VALUES,
  payload: { currencyRates },
});

export const getPositionsRequest = (payload) => {
  const serviceId = payload?.serviceId;
  return { type: GET_POSITIONS_REQUEST, payload: { serviceId } };
};
export const getPositionsSuccess = ({ positions, positionsUnrealizedProfitLoss, serviceId }) => ({
  type: GET_POSITIONS_SUCCESS,
  payload: { positions, positionsUnrealizedProfitLoss, serviceId },
});

export const getPositionByIdRequest = ({ orderId, status, closePositionId, closePositionQuantity, serviceId }) => ({
  type: GET_POSITION_BY_ID_REQUEST,
  payload: { orderId, status, closePositionId, closePositionQuantity, serviceId },
});
export const getPositionByIdSuccess = ({ positionId, operation, positionInfo, serviceId }) => ({
  type: GET_POSITION_BY_ID_SUCCESS,
  payload: { positionId, operation, positionInfo, serviceId },
});

export const updateUnrealizedProfitLossValues = ({ positionsUnrealizedProfitLoss }) => ({
  type: UPDATE_UNREALIZED_PROFIT_LOSS_VALUES,
  payload: { positionsUnrealizedProfitLoss },
});

export const calcInstrumentMarginPeriodically = () => ({
  type: CALC_INSTRUMENT_MARGIN_PERIODICALLY,
});

export const calcPublicInstrumentMarginPeriodically = () => ({
  type: CALC_PUBLIC_INSTRUMENT_MARGIN_PERIODICALLY,
});

export const replaceMarginRates = ({ marginRates }) => ({
  type: REPLACE_MARGIN_RATES,
  payload: { marginRates },
});
