import React, { memo } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { CFD, ETF, FX } from 'shared-modules/constants';
import styles from '../steps.module.scss';

const Step2 = ({ serviceId }) => {
  const getMessagePriceRange = () => {
    if (serviceId === FX) {
      return '値幅はpipsで指定します。';
    }
    if (serviceId === ETF) {
      return '値幅は円（日本銘柄）またはドル(米国銘柄）で指定します。';
    }
    if (serviceId === CFD) {
      return '';
    }
    return null;
  };
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <p>複数の注文を設定するレンジ幅の開始位置を指定します。</p>
        <p>
          <span className={styles.bold}>【買いの場合】</span>
          <br />
          <span>レンジ幅の上限値を意味します。上限値から買い下がるように指定した本数の買い注文が設定されます。</span>
        </p>
        <p>
          <span className={styles.bold}>【売りの場合】</span>
          <br />
          <span>レンジ幅の下限値を意味します。下限値から売り上がるように指定した本数の売り注文が設定されます。</span>
        </p>
        <p>
          <span className={styles.bold}>【価格指定】</span>
          <br />
          <span>スタート価格を価格で指定します。</span>
        </p>
        <p>
          <span className={styles.bold}>【前日終値】</span>
          <br />
          <span>
            前日終値から指定した値幅分離れたところにスタート価格を設定します。
            {getMessagePriceRange()}
          </span>
        </p>
        <br />
        <p className={styles.subContent}>
          ※スタート価格はOCO注文にすることも可能です
          <br />
          {serviceId === FX && '※ pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'}
        </p>
      </div>
    </div>
  );
};

Step2.propTypes = {
  serviceId: propTypes.string,
};

Step2.defaultProps = {
  serviceId: '',
};

export default memo(Step2);
