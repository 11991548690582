import intersectionBy from 'lodash/intersectionBy';
import React, { memo, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { POSITIONS_ARE_STILL_DELETING } from 'shared-modules/constants/errorMesages';
import { toHalfwidthKana } from 'japanese-string-utils';
import {
  BUY_SELL_VALUE,
  TRADE_METHOD_VALUE,
  CLOSE_POSITIONS_MAX_AMOUNT,
  POSITION_DELETION_EXPIRE_TIME,
  FX,
} from 'shared-modules/constants';
import { calculateSpread, getServiceQuantityUnit } from 'shared-modules/services';
import { useCalculateUnrealizedProfitLossForPortfolioCard } from 'shared-modules/services/hooks';
import { useServiceId } from 'shared-modules/hooks';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import {
  openConfirmationModal,
  openErrorInfoModal,
  deleteAllManualTradePositionsRequest,
  closePortfolioManualTradeDetailModal,
} from '../../../../redux/actions';
import { PriceValues } from './components/PriceValues';
import CardHeader from '../../../../components/CardHeader';
import { ColoredPriceText, NumberText } from '../../../../components/Texts';
import styles from './portfolioManualTradeDetailCard.module.scss';

const PortfolioManualTradeDetailCard = ({ data }) => {
  const dispatch = useDispatch();
  const { buySell, type, currency, shortName, image } = data || {};
  const symbol = removeSuffix(currency);
  // TODO CFD 円転用のものが入ってきていないか要確認
  const serviceId = useServiceId(currency);
  /* TODO CFD FXかそうでないかの判定で問題ないか要確認 */
  const isFX = serviceId === FX;
  const currencyPrices = useSelector((state) => state.currencies.rates[currency] || {});
  const deletingPositions = useSelector((state) => state.manualTrade.deletingPositions);

  const isLoadingPositionsDeleting = useSelector((state) => state.portfolio.deleteApGroupIsLoading);

  const selectedPositionsData = useSelector((state) => state.currencies.positions[serviceId]);

  const calculatedCount = useMemo(
    () =>
      selectedPositionsData.reduce((acc, position) => {
        const isRelatedToCard =
          position.instrumentId === currency &&
          position.side === buySell &&
          TRADE_METHOD_VALUE[position.tradeMethod] === type;

        return isRelatedToCard ? Number((acc + position.quantity).toFixed(1)) : acc;
      }, null),
    [selectedPositionsData, type, currency, buySell],
  );

  const cardRelatedPositions = useMemo(
    () =>
      selectedPositionsData.filter(
        (position) =>
          position.instrumentId === currency &&
          position.side === buySell &&
          TRADE_METHOD_VALUE[position.tradeMethod] === type,
      ),
    [selectedPositionsData, type, currency, buySell],
  );

  const unrealizedProfitLoss = useCalculateUnrealizedProfitLossForPortfolioCard({
    instrumentId: currency,
    buySell,
  });

  const closeManualTradeModal = useCallback(() => dispatch(closePortfolioManualTradeDetailModal()), [dispatch]);

  const deleteAllPositions = useCallback(
    () =>
      dispatch(
        deleteAllManualTradePositionsRequest({
          instrumentIdAndSide: {
            instrumentId: currency,
            side: buySell,
          },
          callback: closeManualTradeModal,
          successMessage: '注文を受け付けました。',
          serviceId,
        }),
      ),
    [dispatch, currency, buySell, closeManualTradeModal, serviceId],
  );

  const openDeleteAllPositionsModal = useCallback(() => {
    // create array of positions IDs that will be deleted
    const selectedPositions = cardRelatedPositions.map((el) => ({ positionId: el.positionId }));

    // check if we have in this array position that is being deleted right now
    const hasDeletingPosition = Boolean(
      intersectionBy(deletingPositions, selectedPositions, 'positionId').find(
        (el) => Date.now() - el.deletionStartedAt <= POSITION_DELETION_EXPIRE_TIME,
      ),
    );

    if (hasDeletingPosition) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: POSITIONS_ARE_STILL_DELETING,
        }),
      );
    } else if (cardRelatedPositions.length > CLOSE_POSITIONS_MAX_AMOUNT) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: '建玉が100件を超えています。建玉を100件以内にしてから発注してください。',
        }),
      );
    } else
      dispatch(
        openConfirmationModal({
          title: '全決済注文',
          bodyText: `グループの全ての建玉を成行決済します。よろしいですか？
					大量の決済を行う場合は画面上の建玉が消えるまでに時間がかかる場合がございます。`,
          callback: deleteAllPositions,
          buttonBackText: '戻る',
          buttonNextText: '決済',
          isOverlap: true,
        }),
      );
  }, [cardRelatedPositions, deletingPositions, dispatch, deleteAllPositions]);

  // todo: when add valid data move to services
  const displayedType = useMemo(() => {
    if (type === 'トレード') {
      return `${type}(${BUY_SELL_VALUE[buySell]})`;
    }
    return type;
  }, [type, buySell]);

  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const Price = PriceValues[serviceId];
  return (
    <div className="card card--small" aria-hidden="true">
      <CardHeader displayedType={displayedType} image={image} currency={currency} />

      <p className="symbol">{!isFX && shortName ? toHalfwidthKana(shortName) : symbol}</p>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: isFX ? 2 : 10 }}>
        <div className={classNames(styles.priceDifferenceRow, { [styles[serviceId]]: !!styles[serviceId] })}>
          <Price
            label="BID"
            priceValue={currencyPrices.bid}
            instrumentId={currency}
            previousValue={currencyPrices.previousBid}
          />
          <div className={classNames(styles.calculatedDifference, { [styles[serviceId]]: !!styles[serviceId] })}>
            {calculateSpread({
              buyPrice: currencyPrices.ask,
              sellPrice: currencyPrices.bid,
              instrumentId: currency,
              instrumentList,
            })}
          </div>
          <Price
            label="ASK"
            priceValue={currencyPrices.ask}
            instrumentId={currency}
            previousValue={currencyPrices.previousAsk}
            isReversed
          />
        </div>
      </div>
      <div className={styles.itemRow}>
        <div className={styles.label}>建玉数量</div>
        <NumberText
          className={styles.value}
          symbolClassName={styles.symbol}
          value={calculatedCount}
          symbol={getServiceQuantityUnit(serviceId)}
        />
      </div>
      <div className={styles.itemRow}>
        <div className={styles.label}>評価損益</div>
        <ColoredPriceText className={styles.value} symbolClassName={styles.symbol} value={unrealizedProfitLoss} />
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, position: 'relative' }}
      >
        <button
          type="button"
          className="btn btn-outline-danger"
          style={{ fontWeight: 600, fontSize: '1.4rem', border: 0 }}
          onClick={openDeleteAllPositionsModal}
          disabled={!cardRelatedPositions.length || isLoadingPositionsDeleting}
        >
          全決済する
        </button>
      </div>
    </div>
  );
};

PortfolioManualTradeDetailCard.propTypes = {
  data: PropTypes.shape({
    buySell: PropTypes.string,
    type: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
};

PortfolioManualTradeDetailCard.defaultProps = {
  data: {},
};

export default memo(PortfolioManualTradeDetailCard);
