import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  ALL_CATEGORY,
  MODAL_SIZES,
  COLORS,
  NEWS_CATEGORIES,
  NEWS_TAB_LABELS,
  NEWS_SOURCE_TO_SERVICES,
} from 'shared-modules/constants';
import { isNotAvailableNews } from 'shared-modules/utils/service';
import { useAccountInfo } from 'shared-modules/hooks';
import { useNewsData } from 'shared-modules/hooks/news';
import { NewsContext, NewsProvider } from 'shared-modules/contexts';
import { getNewsRequest, changeNewsTab, openCopyrightInfo } from '../../../../redux/actions';
import { Modal, Spin, Tabs } from '../../../../components';
import CustomSelect from '../../../../components/CustomSelect';
import CustomAccordion from '../../../../components/CustomAccordion';
import CustomButton from '../../../../components/CustomButton';
import { BUTTON_WIDTH } from './constants';
import styles from './manualTradeNews.module.scss';

const convertData = (data) =>
  data.map(({ id, time, category, title, body }) => ({
    key: id,
    title: (
      <div className={styles.accordionTitle}>
        <div className={styles.accordionDate}>{time}</div>
        <div className={styles.accordionCategories}>{category}</div>
        <div className={styles.accordionNewsTitle}>{title}</div>
      </div>
    ),
    // eslint-disable-next-line react/no-danger
    body: <div className={styles.accordionBody} dangerouslySetInnerHTML={{ __html: body }} />,
  }));

const NewsContent = memo(() => {
  const dispatch = useDispatch();
  const [activeTab] = useContext(NewsContext);
  const [openedNews, setOpenedNews] = useState([]);
  const isLoading = useSelector((state) => state.manualTrade.loadingNewsData);
  const categories = NEWS_CATEGORIES[activeTab];
  const [selectedCategory, setCategory] = useState(ALL_CATEGORY.value);
  const newsData = useNewsData({ categories, selectedCategory });
  const displayedData = useMemo(() => convertData(newsData), [newsData]);

  useEffect(() => {
    setOpenedNews([]);
    setCategory(ALL_CATEGORY.value);
  }, [activeTab]);

  const refreshNews = useCallback(() => {
    dispatch(getNewsRequest());
  }, [dispatch]);

  const handleCopyrightInfo = useCallback(
    () =>
      dispatch(
        openCopyrightInfo({
          isOverlap: true,
        }),
      ),
    [dispatch],
  );

  return (
    <div className={styles.container}>
      <div className={styles.controlBlock}>
        <CustomSelect
          placeholder="カテゴリーを選択する"
          options={categories}
          selectItemId={selectedCategory}
          onChange={setCategory}
          width={200}
          isLighter
        />
        <CustomButton
          width={BUTTON_WIDTH}
          color={COLORS.LIGHT_GREY}
          onClick={refreshNews}
          className={styles.refreshButton}
        >
          <i className={classNames('material-icons', styles.icon)}>refresh</i>
          更新
        </CustomButton>
      </div>
      <div className={styles.titleRow}>
        <div className={styles.dateCell}>日時</div>
        <div className={styles.categoriesCell}>カテゴリー</div>
        <div className={styles.newsTitleCell}>ニュースタイトル</div>
      </div>
      <div className={styles.accordionWrapper}>
        {isLoading && (
          <div className={styles.emptyBlock}>
            <Spin className={styles.loader} />
          </div>
        )}
        {!isLoading && Boolean(displayedData.length) && (
          <CustomAccordion options={displayedData} defaultActiveKeys={openedNews} onChangeActiveKeys={setOpenedNews} />
        )}
        {!isLoading && !displayedData.length && <div className={styles.emptyBlock}>No data</div>}
      </div>

      <div className={styles.copyrightBtnWrapper}>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleCopyrightInfo}
          style={{ fontSize: 10, padding: '3.75px 7.5px' }}
        >
          著作権について
        </button>
      </div>
    </div>
  );
});

const News = ({ isOpen, closeModal }) => {
  const [activeTab, setActiveTab] = useContext(NewsContext);
  const accountInfo = useAccountInfo();

  const tabItems = useMemo(() => {
    return Object.keys(NEWS_TAB_LABELS).map((tab) => {
      const services = NEWS_SOURCE_TO_SERVICES[tab];
      return {
        id: tab,
        value: NEWS_TAB_LABELS[tab],
        isVisuallyDisabled: services.every((service) => isNotAvailableNews(accountInfo[service])),
        children: <NewsContent />,
      };
    });
  }, [accountInfo]);

  return (
    <Modal isTopAligned size={MODAL_SIZES.WIDTH_1024} isOpen={isOpen} closeModal={closeModal} title="ニュース">
      <Tabs containerClassName={styles.tabs} items={tabItems} activeKey={activeTab} onChange={setActiveTab} />
    </Modal>
  );
};

News.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const TAB_SELECTOR = (state) => state.webManualTrade.newsActiveTabId;

const ManualTradeNews = memo(({ isOpen, closeModal }) => (
  <NewsProvider tabSelector={TAB_SELECTOR} changeTabAction={changeNewsTab}>
    <News isOpen={isOpen} closeModal={closeModal} />
  </NewsProvider>
));

ManualTradeNews.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(ManualTradeNews);
