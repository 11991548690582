import { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './rangeOutAlertIcon.module.scss';

export const RangeOutAlertIcon = memo(({ width, height }) => (
  <div style={{ width, height }} className={styles.container}>
    <svg
      className={styles.svg}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles.path}
        // eslint-disable-next-line max-len
        d="M0 13.8L7.99875 0L15.9975 13.8H0ZM2.03984 12.6171H13.9577L7.99875 2.36571L2.03984 12.6171ZM7.99875 11.677C8.17936 11.677 8.33076 11.6159 8.45297 11.4938C8.57517 11.3717 8.63627 11.2205 8.63627 11.04C8.63627 10.8595 8.57517 10.7083 8.45297 10.5862C8.33076 10.4641 8.17936 10.403 7.99875 10.403C7.81814 10.403 7.66673 10.4641 7.54453 10.5862C7.42233 10.7083 7.36123 10.8595 7.36123 11.04C7.36123 11.2205 7.42233 11.3717 7.54453 11.4938C7.66673 11.6159 7.81814 11.677 7.99875 11.677ZM7.40681 9.61446H8.59069V5.6716H7.40681V9.61446Z"
      />
    </svg>
  </div>
));

RangeOutAlertIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

RangeOutAlertIcon.defaultProps = {
  width: 16,
  height: 14,
};
