import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './CustomTooltip.module.scss';

const CustomTooltip = ({ children, message, className, placement }) => {
  const renderTooltip = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip {...props} arrowProps={{ style: { display: 'none' } }}>
      <div className="tooltip-content custom-tooltip">{message}</div>
    </Tooltip>
  );

  return (
    <OverlayTrigger placement={placement} overlay={renderTooltip}>
      <div className={classNames(styles.childrenWrapper, className)}>{children}</div>
    </OverlayTrigger>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom']),
};

CustomTooltip.defaultProps = {
  message: '',
  className: '',
  placement: 'top',
};

export default memo(CustomTooltip);
