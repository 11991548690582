/* eslint-disable-next-line import/no-unresolved */
import { createContext, createElement, memo } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import PropTypes from 'prop-types';
import { useNewsTab } from '../hooks/news';

export const NewsContext = createContext([]);

export const NewsProvider = memo(({ children, tabSelector, changeTabAction }) => {
  const value = useNewsTab({ tabSelector, changeTabAction });
  return createElement(NewsContext.Provider, { value }, children);
});

NewsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  tabSelector: PropTypes.func.isRequired,
  changeTabAction: PropTypes.func.isRequired,
};
