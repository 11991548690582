import React, { memo, useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { AP_GROUP_SOURCES, MODAL_SIZES } from 'shared-modules/constants';
import { date3MonthBefore } from 'shared-modules/services';
import { useServiceId } from 'shared-modules/hooks';
import {
  resetSelectedApGroupMetaInfo,
  resetSelectedExecutionsMetaInfo,
  resetSelectedPositionsMetaInfo,
} from 'shared-modules/redux/actions/portfolioActions';
import {
  getApGroupByIdRequest,
  clearSelectedApGroupData,
  getPlExecutionsInfoRequest,
  changeSelectedApGroupStatus,
} from '../../../../redux/actions';
import { ChartDescription, DescriptionType } from '../../../../screens/AutoSelect/components/ChartDescription';
import Modal from '../../../../components/Modal';
import { Tabs } from '../../../../components';
import DetailChart from './components/DetailChart';
import DetailTable from './components/DetailTable';
import TradingViewChart from './components/TradingViewChart';
import styles from './portfolioAutoTradeDetail.module.scss';
import Card from './components/Card';

const TAB_PRICE = 'price';
const TAB_REALIZED_PL = 'realizedPl';

const PortfolioAutoTradeDetail = ({ isOpen, closeModal, data }) => {
  const dispatch = useDispatch();

  const { id, currency } = data || {};
  const serviceId = useServiceId(currency);

  const [cardData, setCardData] = useState(data);

  const selectedApGroup = useSelector((state) => state.portfolio.selectedApGroupData);
  useEffect(() => {
    if (isOpen && data) {
      setCardData(data);
      dispatch(getApGroupByIdRequest({ id, serviceId, status: data.activeCount === 0 ? '0' : '1' }));
      dispatch(
        getPlExecutionsInfoRequest({ apGroupId: id, fromDate: date3MonthBefore(), toDate: new Date(), serviceId }),
      );
    }
  }, [isOpen, data, dispatch, id, serviceId]);
  useEffect(() => {
    if (isOpen && selectedApGroup.id) {
      setCardData({
        ...data,
        id: selectedApGroup.id,
        type:
          selectedApGroup.sourceType === AP_GROUP_SOURCES.MONEY_HATCH.KEY
            ? AP_GROUP_SOURCES.MONEY_HATCH.NAME
            : AP_GROUP_SOURCES.SELECT.NAME,
        currency: selectedApGroup.instrumentId,
        groupName: selectedApGroup.name,
        count: selectedApGroup.positionQuantity || '-',
        realizedProfitLoss: selectedApGroup.totalRealizedPnl || '-',
        creationTime: selectedApGroup.entryDate ? new Date(selectedApGroup.entryDate).toLocaleDateString('ja') : '-',
        totalCount: selectedApGroup.totalApCount || 0,
        activeCount: selectedApGroup.activeApCount || 0,
        status: selectedApGroup.status || data.status,
      });
    }
    dispatch(changeSelectedApGroupStatus({ status: selectedApGroup.status }));
  }, [dispatch, isOpen, selectedApGroup, data]);

  const onModalClose = useCallback(() => {
    dispatch(clearSelectedApGroupData());
    // 絞込みリセット
    dispatch(resetSelectedExecutionsMetaInfo({ serviceId }));
    dispatch(resetSelectedApGroupMetaInfo({ serviceId }));
    dispatch(resetSelectedPositionsMetaInfo({ serviceId }));

    closeModal();
  }, [dispatch, closeModal, serviceId]);

  const title = useMemo(() => `${cardData.groupName}`, [cardData]);
  const [key, setKey] = useState('price');

  const tabItems = useMemo(() => {
    return [
      {
        id: TAB_PRICE,
        label: 'プライスチャート',
        children: <TradingViewChart selectedInstrumentId={currency} apGroupId={id} serviceId={serviceId} />,
      },
      {
        id: TAB_REALIZED_PL,
        label: '実現損益',
        children: <DetailChart />,
      },
    ];
  }, [currency, id, serviceId]);

  const toolbar = useMemo(() => {
    if (key !== TAB_PRICE) {
      return null;
    }
    return <ChartDescription descriptionType={DescriptionType.settlement} />;
  }, [key]);

  return (
    <Modal size={MODAL_SIZES.WIDTH_1024} isOpen={isOpen} closeModal={onModalClose} title={title} isOverlayModal>
      <div className={styles.wrapper}>
        <Card data={cardData} closeModal={onModalClose} />
        <div className={styles.chartTabs}>
          <Tabs containerClassName={styles.tabs} items={tabItems} activeKey={key} onChange={setKey} toolbar={toolbar} />
        </div>
        <DetailTable id={id} serviceId={serviceId} />
      </div>
    </Modal>
  );
};

PortfolioAutoTradeDetail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    groupName: PropTypes.string.isRequired,
    activeCount: PropTypes.number.isRequired,
    status: PropTypes.string,
  }),
};
PortfolioAutoTradeDetail.defaultProps = {
  data: {},
};

export default memo(PortfolioAutoTradeDetail);
