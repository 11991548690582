import {
  GET_CATEGORY_LIST_START_LOADING,
  GET_CATEGORY_LIST_END_LOADING,
  GET_CATEGORY_LIST_REQUEST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CONTENTS_LIST_REQUEST,
  GET_CONTENTS_LIST_SUCCESS,
  SET_SELECTED_CATEGORY_ID,
  DELETE_SELECTED_CATEGORY_ID,
  DELETE_PAGE_INFO,
} from '../actionConstants/guideConstants';

export const getCategoryListStartLoading = () => ({ type: GET_CATEGORY_LIST_START_LOADING });
export const getCategoryListEndLoading = () => ({ type: GET_CATEGORY_LIST_END_LOADING });
export const getCategoryListRequest = ({ pageSize, contentsLimit, alertFlg }) => ({
  type: GET_CATEGORY_LIST_REQUEST,
  payload: { pageSize, contentsLimit, alertFlg },
});
export const getCategoryListSuccess = ({ pageInfo, categoryList }) => ({
  type: GET_CATEGORY_LIST_SUCCESS,
  payload: { pageInfo, categoryList },
});
export const getContentsListRequest = ({ categoryId, pageSize, isFirstData = false, alertFlg, userId }) => ({
  type: GET_CONTENTS_LIST_REQUEST,
  payload: { categoryId, pageSize, isFirstData, alertFlg, userId },
});
export const getContentsListSuccess = ({ pageInfo, category, isFirstData }) => ({
  type: GET_CONTENTS_LIST_SUCCESS,
  payload: { pageInfo, category, isFirstData },
});
export const setSelectedCategoryId = ({ categoryId }) => ({
  type: SET_SELECTED_CATEGORY_ID,
  payload: { categoryId },
});
export const deleteSelectedCategoryId = () => ({ type: DELETE_SELECTED_CATEGORY_ID });

export const deletePageInfo = () => ({ type: DELETE_PAGE_INFO });
