import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrdersRequest } from 'shared-modules/redux/orders';
import { DEFAULT_TRADING_RESOLUTION } from 'shared-modules/constants/manualTrade';
import SideMenu from './components/SideMenu';
import NewsRow from './components/NewsRow';
import CurrencyPair from './components/CurrencyPair';
import Chart from '../../components/Chart';
import Table from './components/Table';
import styles from './manualTrade.module.scss';
import { SplitPane } from '../../components';

const ManualTrade = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const selectedInstrumentId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  const selectedSide = useSelector((state) => state.manualTrade.selectedSide);
  const resolution = useSelector((state) => state.chart.resolution);
  const dispatch = useDispatch();

  useEffect(() => {
    const payload = { serviceId, params: { instrumentId: selectedInstrumentId } };
    dispatch(getOrdersRequest(payload));
  }, [dispatch, serviceId, selectedInstrumentId]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.sideMenu}>
        <SideMenu />
      </div>
      <div className={styles.content}>
        <SplitPane split="horizontal" defaultSize="60%" minSize={200}>
          <div className={styles.upperPane}>
            <div className={styles.newsRow}>
              <NewsRow />
            </div>
            <div className={styles.chartRow}>
              <div className={styles.currencyPair}>
                <CurrencyPair />
              </div>
              <div className={styles.chart}>
                <Chart
                  key={serviceId}
                  serviceId={serviceId}
                  selectedInstrumentId={selectedInstrumentId}
                  selectedSide={selectedSide}
                  withPositions
                  resolution={resolution ?? DEFAULT_TRADING_RESOLUTION}
                />
              </div>
            </div>
          </div>
          <div className={styles.table}>
            <Table />
          </div>
        </SplitPane>
      </div>
    </div>
  );
};

export default memo(ManualTrade);
