import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UPDATE_MOBILE_CHART } from 'shared-modules/constants/apiConstant';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { isJsonString } from 'shared-modules/services';
import { getPublishedLabDetailsSuccess } from 'shared-modules/redux/labs';
import { BUY_SELL_MAIN } from 'shared-modules/constants';
import {
  changeServiceIdSuccess,
  createInstrumentsOptions,
  getInstrumentListSuccess,
  getRatesSuccess,
  socketConnectionRequest,
} from '../../redux/actions';
import LabChart from '../../appWrappers/ModalContainer/components/LabDetailModal/components/LabChart';
import styles from '../MobileChart/mobileChart.module.scss';

function updateChartRelatedGlobalData(dispatch, payload) {
  const { serviceId, simulationData, rates, instrumentList } = payload;
  dispatch(getPublishedLabDetailsSuccess(simulationData));
  dispatch(changeServiceIdSuccess({ serviceId }));
  dispatch(getRatesSuccess({ rates }));
  dispatch(getInstrumentListSuccess({ instrumentList }));
  ALL_SERVICES.forEach((service) => {
    dispatch(createInstrumentsOptions({ serviceId: service, options: payload[`${service}InstrumentsOptions`] }));
  });
}

const MobileLabPriceChart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(socketConnectionRequest({ isPublic: true }));

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(UPDATE_MOBILE_CHART);
    }
  }, [dispatch]);

  const [serviceId, changeServiceId] = useState(null);
  const [strategy, changeStrategy] = useState({});
  const [chartSide, changeChartSide] = useState(BUY_SELL_MAIN.SELL.CHART_ID);

  const windowEventListener = useCallback(
    ({ data }) => {
      if (!data || !isJsonString(data)) {
        return;
      }
      const parsedMessage = JSON.parse(data);

      switch (parsedMessage.message) {
        case UPDATE_MOBILE_CHART: {
          const { payload } = parsedMessage;
          const {
            setServiceId,
            simulationData: { strategyList },
            selectedStrategyId,
            selectedSide,
          } = payload;

          updateChartRelatedGlobalData(dispatch, payload);
          changeServiceId(setServiceId);
          changeChartSide(selectedSide);
          const foundStrategy = strategyList?.find((s) => s.id === String(selectedStrategyId));
          changeStrategy(foundStrategy);
          break;
        }
        default:
          break;
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', windowEventListener);
    document.addEventListener('message', windowEventListener);
    return () => {
      window.removeEventListener('message', windowEventListener);
      document.removeEventListener('message', windowEventListener);
    };
  }, [windowEventListener]);

  return (
    <div className={styles.chartWrapper}>
      <LabChart
        key={serviceId}
        strategy={strategy}
        chartSide={chartSide}
        disableHeaderWidget
        disableLeftToolbar
        isMobile
        withoutIndicators
      />
    </div>
  );
};

export default memo(MobileLabPriceChart);
