import Decimal from 'decimal.js';
import {
  FX,
  ETF,
  CFD,
  CORE_RANGER,
  CORE_RANGER_BULL,
  CORE_RANGER_BEAR,
  HALF,
  SWAPPER,
  PROTECTOR,
  ZONE_PROTECTOR,
  HEDGER,
} from '../../constants/index';
import CoreRanger from './logics/fx/CoreRanger';
import OneSideCoreRanger from './logics/fx/OneSideCoreRanger';
import Half from './logics/fx/Half';
import Swapper from './logics/fx/Swapper';
import EtfCoreRanger from './logics/etf/CoreRanger';
import EtfProtector from './logics/etf/Protector';
import EtfZoneProtector from './logics/etf/ZoneProtector';
import EtfHedger from './logics/etf/Hedger';
import CfdCoreRanger from './logics/cfd/CoreRanger';
import CfdProtector from './logics/cfd/Protector';
import CfdZoneProtector from './logics/cfd/ZoneProtector';
import CfdHedger from './logics/cfd/Hedger';
import { floor, getSignificantDigits, priceToPips } from './constants';

export const recommendFxLogicTypes = (drawingDataSummary, instrumentId) => {
  const result = [];
  const bullBasePrice = Decimal.sub(
    drawingDataSummary.high,
    Decimal.mul(Decimal.sub(drawingDataSummary.high, drawingDataSummary.low), 0.2),
  ).toNumber();
  const bearBasePrice = Decimal.add(
    drawingDataSummary.low,
    Decimal.mul(Decimal.sub(drawingDataSummary.high, drawingDataSummary.low), 0.2),
  ).toNumber();
  if (drawingDataSummary.lastPrice > drawingDataSummary.midPrice) {
    if (drawingDataSummary.modePrice > bullBasePrice) {
      result.push(CORE_RANGER_BULL);
      result.push(CORE_RANGER);
      result.push(HALF);
    } else {
      result.push(CORE_RANGER);
      result.push(HALF);
      result.push(CORE_RANGER_BULL);
    }
  } else if (drawingDataSummary.modePrice < bearBasePrice) {
    result.push(CORE_RANGER_BEAR);
    result.push(CORE_RANGER);
    result.push(HALF);
  } else {
    result.push(CORE_RANGER);
    result.push(HALF);
    result.push(CORE_RANGER_BEAR);
  }
  if (instrumentId === 'TRY_AP/JPY' || instrumentId === 'ZAR_AP/JPY') {
    result.push(SWAPPER);
  }

  return result;
};

export const recommendEtfLogicTypes = (instrumentId) => {
  const result = [];
  if (
    instrumentId === '1570.TKS/JPY' ||
    instrumentId === '1357.TKS/JPY' ||
    instrumentId === 'ARKK.ARC/USD' ||
    instrumentId === 'VXX.ARC/USD'
  ) {
    result.push(PROTECTOR);
    result.push(ZONE_PROTECTOR);
  } else if (instrumentId === 'SLV.ARC/USD' || instrumentId === 'GLD.ARC/USD') {
    result.push(CORE_RANGER);
    result.push(PROTECTOR);
    result.push(ZONE_PROTECTOR);
    result.push(HEDGER);
  } else {
    result.push(PROTECTOR);
    result.push(ZONE_PROTECTOR);
    result.push(HEDGER);
  }
  return result;
};

export const recommendCfdLogicTypes = () => {
  const result = [];
  result.push(CORE_RANGER);
  result.push(PROTECTOR);
  result.push(ZONE_PROTECTOR);
  result.push(HEDGER);
  return result;
};

export const createFxLogic = (logicType, drawingDataSummary, instrumentId, instrumentSetting) => {
  let logic;
  switch (logicType) {
    case CORE_RANGER:
      logic = CoreRanger(
        instrumentId,
        drawingDataSummary.modePrice,
        drawingDataSummary.high,
        drawingDataSummary.low,
        instrumentSetting,
      );
      break;
    case CORE_RANGER_BULL:
      logic = OneSideCoreRanger(
        true,
        instrumentId,
        drawingDataSummary.modePrice,
        drawingDataSummary.midPrice,
        drawingDataSummary.high,
        drawingDataSummary.low,
        instrumentSetting,
      );
      break;
    case CORE_RANGER_BEAR:
      logic = OneSideCoreRanger(
        false,
        instrumentId,
        drawingDataSummary.modePrice,
        drawingDataSummary.midPrice,
        drawingDataSummary.high,
        drawingDataSummary.low,
        instrumentSetting,
      );
      break;
    case HALF:
      logic = Half(instrumentId, drawingDataSummary.high, drawingDataSummary.low, instrumentSetting);
      break;
    case SWAPPER:
      logic = Swapper(instrumentId, drawingDataSummary.high, drawingDataSummary.low, instrumentSetting);
      break;
    default:
      logic = null;
  }
  logic?.calc(drawingDataSummary.dailyPipsRange);
  return logic;
};

export const createEtfLogic = (logicType, drawingDataSummary, instrumentId, instrumentSetting) => {
  let logic;
  switch (logicType) {
    case PROTECTOR:
      logic = EtfProtector(instrumentId, drawingDataSummary.high, drawingDataSummary.low, instrumentSetting);
      break;
    case ZONE_PROTECTOR:
      logic = EtfZoneProtector(instrumentId, drawingDataSummary.high, drawingDataSummary.low, instrumentSetting);
      break;
    case HEDGER:
      logic = EtfHedger(instrumentId, drawingDataSummary.high, drawingDataSummary.low, instrumentSetting);
      break;
    case CORE_RANGER:
      logic = EtfCoreRanger(
        instrumentId,
        drawingDataSummary.modePrice,
        drawingDataSummary.high,
        drawingDataSummary.low,
        instrumentSetting,
      );
      break;
    default:
      logic = null;
  }
  logic?.calc(drawingDataSummary.dailyPipsRange);
  return logic;
};

export const createCfdLogic = (logicType, drawingDataSummary, instrumentId, instrumentSetting) => {
  let logic;
  switch (logicType) {
    case PROTECTOR:
      logic = CfdProtector(instrumentId, drawingDataSummary.high, drawingDataSummary.low, instrumentSetting);
      break;
    case ZONE_PROTECTOR:
      logic = CfdZoneProtector(instrumentId, drawingDataSummary.high, drawingDataSummary.low, instrumentSetting);
      break;
    case HEDGER:
      logic = CfdHedger(instrumentId, drawingDataSummary.high, drawingDataSummary.low, instrumentSetting);
      break;
    case CORE_RANGER:
      logic = CfdCoreRanger(
        instrumentId,
        drawingDataSummary.modePrice,
        drawingDataSummary.high,
        drawingDataSummary.low,
        instrumentSetting,
      );
      break;
    default:
      logic = null;
  }
  logic?.calc(drawingDataSummary.dailyPipsRange);
  return logic;
};

const calcModePrice = (drawingData, min, max, digits) => {
  const markPoint = Decimal.div(Decimal.sub(max, min), 10);
  const markList = [
    min,
    Decimal.add(min, markPoint).toNumber(),
    Decimal.add(min, Decimal.mul(markPoint, 2)).toNumber(),
    Decimal.add(min, Decimal.mul(markPoint, 3)).toNumber(),
    Decimal.add(min, Decimal.mul(markPoint, 4)).toNumber(),
    Decimal.add(min, Decimal.mul(markPoint, 5)).toNumber(),
    Decimal.add(min, Decimal.mul(markPoint, 6)).toNumber(),
    Decimal.add(min, Decimal.mul(markPoint, 7)).toNumber(),
    Decimal.add(min, Decimal.mul(markPoint, 8)).toNumber(),
    Decimal.add(min, Decimal.mul(markPoint, 9)).toNumber(),
  ];

  // 10分割処理
  /* eslint-disable no-param-reassign */
  const countTmp = drawingData
    .map((x) => markList.filter((y) => y <= x.value).slice(-1)[0])
    .reduce((prev, current) => {
      prev[current] = (prev[current] || 0) + 1;
      return prev;
    }, {});
  /* eslint-enable no-param-reassign */
  // 上記処理だと分割範囲に点がない場合、リストから漏れるため、漏れた部分を0埋めする
  const countList = markList.map((x) => (countTmp[x] ? countTmp[x] : 0));

  const maxCount = Math.max(...Object.values(countTmp));
  const maxCountAreas = Object.entries(countTmp).filter((x) => x[1] === maxCount);

  let modePrice = 0;

  // 最大の点を持つ価格帯が1つなら、その価格帯の中央値を最頻価格とする。
  // そうでないなら重心を求めて、重心を最頻価格とする。
  if (maxCountAreas.length === 1) {
    modePrice = floor(Decimal.add(new Decimal(maxCountAreas[0][0]), Decimal.div(markPoint, 2)).toNumber(), digits);
  } else {
    // 重心算出処理
    const divisor = countList.reduce((x, y) => Decimal.add(x, y).toNumber());
    let dividend = 0;
    countList.forEach((x, i) => {
      dividend = Decimal.add(dividend, Decimal.mul(x, Decimal.add(i, 0.5)));
    });

    // 重心から最頻価格を求める
    modePrice = floor(
      Decimal.add(min, Decimal.div(Decimal.mul(Decimal.sub(max, min), Decimal.div(dividend, divisor)), 10)).toNumber(),
      digits,
    );
  }
  return modePrice;
};

export const summarizeDrawingData = (drawingData, instrumentId, instrumentSetting, serviceId) => {
  const digits = getSignificantDigits(serviceId, instrumentId, instrumentSetting?.pricePrecision);
  const max = floor(Math.max(...drawingData.map((x) => x.value)), digits);
  const min = floor(Math.min(...drawingData.map((x) => x.value)), digits);
  const modePrice = calcModePrice(drawingData, min, max, digits);
  const midPrice = floor(Decimal.add(min, Decimal.div(Decimal.sub(max, min), 2)).toNumber(), digits);
  const lastPrice = floor(drawingData[drawingData.length - 1].value, digits);
  // 近似1日変動pips
  /* eslint-disable no-param-reassign */
  const dailyPipsRange = floor(
    priceToPips(
      Decimal.div(
        drawingData
          .map((x) => x.value)
          .reduce((x, y, i, array) => {
            return Decimal.add(x || 0, array[i - 1] ? Math.abs(Decimal.sub(y, array[i - 1])) : 0);
          }, 0),
        drawingData.length - 1,
      ),
      instrumentId,
      serviceId,
    ),
    // TODO CFD FXとそれ以外の判定で問題ないか要確認
    serviceId === FX ? 1 : digits,
  );
  /* eslint-enable no-param-reassign */
  return {
    low: min,
    high: max,
    dailyPipsRange,
    modePrice,
    lastPrice,
    midPrice,
  };
};

const recommendLogicTypesByServiceId = (serviceId, drawingDataSummary, instrumentId) => {
  switch (serviceId) {
    case FX:
      return recommendFxLogicTypes(drawingDataSummary, instrumentId);
    case ETF:
      return recommendEtfLogicTypes(instrumentId);
    case CFD:
      return recommendCfdLogicTypes();
    default:
      return null;
  }
};

const createLogicByServiceId = (serviceId, logic, drawingDataSummary, instrumentId, instrumentSetting) => {
  switch (serviceId) {
    case FX:
      return createFxLogic(logic, drawingDataSummary, instrumentId, instrumentSetting);
    case ETF:
      return createEtfLogic(logic, drawingDataSummary, instrumentId, instrumentSetting);
    case CFD:
      return createCfdLogic(logic, drawingDataSummary, instrumentId, instrumentSetting);
    default:
      return null;
  }
};

/**
 * チャートメイクレコメンドヘルパー
 */
const convertDrawingToLogics = (drawingData, instrumentId, serviceId, instrumentSetting) => {
  const drawingDataSummary = summarizeDrawingData(drawingData, instrumentId, instrumentSetting, serviceId);
  const logics = recommendLogicTypesByServiceId(serviceId, drawingDataSummary, instrumentId);
  const result = [];
  logics.forEach((x) => {
    const logic = createLogicByServiceId(serviceId, x, drawingDataSummary, instrumentId, instrumentSetting);
    if (logic.validateRange().isValid) {
      result.push(logic);
    }
  });

  const itemList = result.map((x) => x.getDispInfo());
  return [itemList, result];
};

export default convertDrawingToLogics;
