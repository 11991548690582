import { createAdditionalParams, invokeApi, get, patch, deleteMethod, post } from './index';

export const getCurrencyCartItemsCount = async () => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `cart/grouped_items_count`, get, additionalParams);
};

export const getCurrentCart = async ({ termId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `cart/group?chartsFlg=true&simulationTermId=${termId}`, get, additionalParams);
};

export const updateCurrencyCartItemStrategySets = async ({ itemId, requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `cart/items/${itemId}`, patch, additionalParams, requestBody);
};

export const deleteCurrentCartItem = async ({ itemId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `cart/items/${itemId}`, deleteMethod, additionalParams);
};

export const saveCurrentCart = async ({ requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `cart/logs?clearFlg=false`, post, additionalParams, requestBody);
};

export const deleteCurrentCart = async () => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `cart`, deleteMethod, additionalParams);
};

export const getHistoryCart = async () => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `cart/logs/group?type=2`, get, additionalParams);
};

export const addHistoryOrSavedToCart = async ({ requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `cart`, post, additionalParams, {
    ...(requestBody || {}),
    considerParent: true,
  });
};

export const getSavedCart = async () => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `cart/logs/group?type=1`, get, additionalParams);
};

export const deleteSavedCartItem = async ({ logId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `cart/logs/${logId}`, deleteMethod, additionalParams);
};
