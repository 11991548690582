import PropTypes from 'prop-types';
import { useToInstrumentPrecision } from 'shared-modules/services/hooks';

const CounterValue = ({ counter: value, counterPrice, instrumentId }) => {
  const round = useToInstrumentPrecision(instrumentId, '-');
  return counterPrice ? `(${round(counterPrice)})` : round(value);
};

CounterValue.propTypes = {
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  counterPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  instrumentId: PropTypes.string,
};

export default CounterValue;
