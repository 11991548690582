import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './numberTotal.module.scss';

const NumberTotal = ({ label, value }) => {
  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.label}>{label}</div>
      {value}
    </div>
  );
};
NumberTotal.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.element, PropTypes.number]).isRequired,
};

export default memo(NumberTotal);
