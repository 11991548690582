import PropTypes from 'prop-types';
import { useToInstrumentPrecision } from 'shared-modules/services/hooks';

const CounterValueCell = ({ cell }) => {
  const { counterPrice, instrumentId } = cell.row.original;
  const round = useToInstrumentPrecision(instrumentId, '-');
  return counterPrice ? `(${round(counterPrice)})` : round(cell.value);
};

CounterValueCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    row: PropTypes.shape({
      original: PropTypes.shape({
        counterPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        instrumentId: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default CounterValueCell;
