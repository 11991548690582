import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSelectProvider } from 'shared-modules/providers';
import { clearSelectionModalInfo, getSelectionTagsRequest } from '../../redux/actions';
import { SplitPane } from '../../components';
import { SideMenu } from './components/SideMenu';
import { Detail } from './components/Detail';
import styles from './autoSelect.module.scss';

const AutoSelect = () => {
  const dispatch = useDispatch();
  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);

  useEffect(() => {
    if (!tutorialMode) {
      dispatch(clearSelectionModalInfo());
    }
    dispatch(getSelectionTagsRequest());
  }, [dispatch, tutorialMode]);

  return (
    <AutoSelectProvider>
      <div className={styles.container}>
        <div className={styles.content}>
          <SplitPane defaultSize={386} minSize={386} maxSize={500}>
            <div className={styles.sideMenu}>
              <SideMenu />
            </div>
            <Detail />
          </SplitPane>
        </div>
      </div>
    </AutoSelectProvider>
  );
};

export default memo(AutoSelect);
