import React, { memo, useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { BUY_SELL_CHART_VALUE } from 'shared-modules/constants';
import { ENTRY_PRICE_VALUES } from 'shared-modules/constants/builder';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { useInstrumentShortName } from 'shared-modules/hooks';
import styleConstants from 'shared-modules/styles/_constants.scss';
import { widget } from 'shared-modules/thirdPartyLibrary/TradingView/charting_library';
import {
  PRICE_CHART_COLORS,
  basePriceLineProps,
  SELECTED_SIDE,
  chartType,
  orderSettingLineColor,
  isEmpty,
  getActiveOrderName,
} from 'shared-modules/services/builder';
import { useEffectSetChildTabIndex } from '../../services/hooks';
import { Spin } from '../Spin';
import datafeed from '../Chart/datafeed';
import styles from './singlePriceChart.module.scss';

const SinglePriceChart = ({
  selectedCurrencyPairId,
  resolution,
  orderSettingsList,
  instrumentList,
  instrumentOptions,
  serviceId,
  selectedSellBuyId,
  quantity,
  ocoIsChecked,
  ocoValue,
  entryPriceTypeId,
  basePrice,
  price,
  isMobile,
}) => {
  const containerId = useMemo(() => uuid(), []);
  const [loading, setLoading] = useState(true);

  const tvWidgetRef = useRef({});
  const basePriceLineRef = useRef({});
  const orderLineRef = useRef({});
  const ocoLineRef = useRef({});
  const orderSettingsListRef = useRef({});
  const chartContainerRef = useEffectSetChildTabIndex('-1');

  const shortName = useInstrumentShortName(selectedCurrencyPairId);
  const symbol = useMemo(() => `${shortName}(${BUY_SELL_CHART_VALUE[SELECTED_SIDE]})`, [shortName]);

  useEffect(() => {
    if (
      ALL_SERVICES.some((service) => !instrumentOptions[service].length) ||
      !selectedCurrencyPairId ||
      tvWidgetRef.current.headerReady
    ) {
      return;
    }

    const widgetOptions = {
      symbol,
      datafeed: datafeed({ instrumentOptions, isMobile, serviceId, instrumentList }),
      interval: resolution,
      container: containerId,
      library_path: '/charting_library/',
      custom_css_url: isMobile ? 'mobileCustomStyle.css' : 'customStyle.css',
      timezone: 'Asia/Tokyo',
      locale: 'ja',
      disabled_features: [
        'display_market_status',
        'items_favoriting',
        'header_screenshot',
        'header_saveload',
        'header_undo_redo',
        'header_fullscreen_button',
        'header_compare',
        'timeframes_toolbar',
        'study_dialog_search_control',
        'create_volume_indicator_by_default',
        'header_widget',
        'left_toolbar',
        'header_symbol_search',
        'header_settings',
        'symbol_search_hot_key',
      ],
      autosize: true,
      theme: 'Dark',
      overrides: {
        'mainSeriesProperties.lineStyle.color': styleConstants.WEB_WHITE,
        'mainSeriesProperties.candleStyle.upColor': styleConstants.WEB_PINKISH_ORANGE,
        'mainSeriesProperties.candleStyle.downColor': styleConstants.WEB_DARK_SKY_BLUE,
        'mainSeriesProperties.candleStyle.drawWick': true,
        'mainSeriesProperties.candleStyle.borderUpColor': styleConstants.WEB_PINKISH_ORANGE,
        'mainSeriesProperties.candleStyle.borderDownColor': styleConstants.WEB_DARK_SKY_BLUE,
        'mainSeriesProperties.candleStyle.wickUpColor': styleConstants.WEB_PINKISH_ORANGE,
        'mainSeriesProperties.candleStyle.wickDownColor': styleConstants.WEB_DARK_SKY_BLUE,
        'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false,
        'mainSeriesProperties.haStyle.upColor': styleConstants.WEB_PINKISH_ORANGE,
        'mainSeriesProperties.haStyle.downColor': styleConstants.WEB_DARK_SKY_BLUE,
        'mainSeriesProperties.haStyle.borderUpColor': styleConstants.WEB_PINKISH_ORANGE,
        'mainSeriesProperties.haStyle.borderDownColor': styleConstants.WEB_DARK_SKY_BLUE,
        'mainSeriesProperties.haStyle.wickUpColor': styleConstants.WEB_PINKISH_ORANGE,
        'mainSeriesProperties.haStyle.wickDownColor': styleConstants.WEB_DARK_SKY_BLUE,
        'linetoolfibretracement.level2.coeff': 0.236,
        'linetoolfibretracement.level3.coeff': 0.382,
        'linetoolfibretracement.level4.coeff': 0.5,
        'linetoolfibretracement.level5.coeff': 0.618,
        'linetoolfibretracement.level6.coeff': 0.764,
        'linetoolfibretracement.level7.coeff': 1,
        'linetoolfibretracement.level8.coeff': 1.5,
        'linetoolfibretracement.level9.coeff': 2,
        'linetoolfibretracement.level10.visible': false,
        'linetoolfibretracement.level11.visible': false,
        'mainSeriesProperties.showPriceLine': false,
        'scalesProperties.showSeriesLastValue': false,
        'mainSeriesProperties.priceAxisProperties.alignLabels': false,
        'paneProperties.legendProperties.showSeriesOHLC': true,
        'paneProperties.legendProperties.showBarChange': true,
      },
    };
    tvWidgetRef.current = new widget(widgetOptions); // eslint-disable-line

    tvWidgetRef.current.onChartReady(() => {
      setLoading(false);
    });
  }, [containerId, instrumentOptions, selectedCurrencyPairId, instrumentList, serviceId, resolution, symbol, isMobile]);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }

    tvWidgetRef.current.onChartReady(() => {
      tvWidgetRef.current.setSymbol(symbol, resolution);
    });
  }, [symbol, resolution]);

  useEffect(() => {
    return () => {
      if (tvWidgetRef.current.remove) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = {};
      }
    };
  }, []);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    tvWidgetRef.current.onChartReady(() => {
      tvWidgetRef.current.chart().setChartType(chartType);
    });
  }, []);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    tvWidgetRef.current.onChartReady(() => {
      if (isEmpty(basePriceLineRef.current)) {
        const newLineId = tvWidgetRef.current
          .activeChart()
          .createShape(
            { price: basePrice },
            { shape: 'horizontal_line', lock: true, disableSelection: true, overrides: basePriceLineProps },
          );
        basePriceLineRef.current = tvWidgetRef.current.activeChart().getShapeById(newLineId);
        basePriceLineRef.current.sendToBack();
      } else {
        basePriceLineRef.current.setPoints([{ price: basePrice }]);
      }
    });
  }, [basePrice]);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    tvWidgetRef.current.onChartReady(() => {
      const color = orderSettingLineColor(selectedSellBuyId);

      if (isEmpty(orderLineRef.current)) {
        orderLineRef.current = tvWidgetRef.current
          .activeChart()
          .createOrderLine()
          .setText(getActiveOrderName({ ocoIsChecked, entryPriceTypeId }))
          .setQuantity(quantity)
          .setPrice(price)
          .setLineColor(color)
          .setBodyBorderColor(color)
          .setBodyTextColor(color)
          .setQuantityBorderColor(color)
          .setQuantityTextColor(color)
          .setQuantityBackgroundColor(PRICE_CHART_COLORS.TRANSLUCENT_WHITE)
          .setLineStyle(2)
          .setLineLength(1);
      } else {
        orderLineRef.current
          .setText(getActiveOrderName({ ocoIsChecked, entryPriceTypeId }))
          .setQuantity(quantity)
          .setLineColor(color)
          .setBodyBorderColor(color)
          .setBodyTextColor(color)
          .setQuantityBorderColor(color)
          .setQuantityTextColor(color)
          .setQuantityBackgroundColor(PRICE_CHART_COLORS.TRANSLUCENT_WHITE)
          .setPrice(price);
      }
    });
  }, [quantity, price, selectedSellBuyId, entryPriceTypeId, ocoIsChecked]);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    tvWidgetRef.current.onChartReady(() => {
      if (ocoIsChecked && entryPriceTypeId !== ENTRY_PRICE_VALUES.PREVIOUS_DAY.ID) {
        const color = orderSettingLineColor(selectedSellBuyId);

        if (isEmpty(ocoLineRef.current)) {
          ocoLineRef.current = tvWidgetRef.current
            .activeChart()
            .createOrderLine()
            .setQuantity(quantity)
            .setPrice(ocoValue)
            .setText('Order.2')
            .setLineColor(color)
            .setBodyBorderColor(color)
            .setBodyTextColor(color)
            .setQuantityBorderColor(color)
            .setQuantityTextColor(color)
            .setQuantityBackgroundColor(PRICE_CHART_COLORS.TRANSLUCENT_WHITE)
            .setLineStyle(2)
            .setLineLength(1);
        } else {
          ocoLineRef.current
            .setQuantity(quantity)
            .setPrice(ocoValue)
            .setLineColor(color)
            .setBodyBorderColor(color)
            .setBodyTextColor(color)
            .setQuantityTextColor(color)
            .setQuantityBorderColor(color)
            .setQuantityBackgroundColor(PRICE_CHART_COLORS.TRANSLUCENT_WHITE);
        }
      } else if (!isEmpty(ocoLineRef.current)) {
        ocoLineRef.current.remove();
        ocoLineRef.current = {};
      }
    });
  }, [ocoIsChecked, quantity, ocoValue, selectedSellBuyId, entryPriceTypeId]);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    tvWidgetRef.current.onChartReady(() => {
      if (orderSettingsListRef.current.length) {
        orderSettingsListRef.current.forEach((line) => (line.remove ? line.remove() : line.forEach((l) => l.remove())));
      }

      orderSettingsListRef.current = orderSettingsList.map((setting, index) => {
        const color = orderSettingLineColor(setting.buySell);

        if (setting.entryPrice2) {
          const orderLine = tvWidgetRef.current
            .activeChart()
            .createOrderLine()
            .setQuantity(setting.amount)
            .setPrice(setting.entryPrice1)
            .setText(`Order ${index + 1}.1`)
            .setLineStyle(2)
            .setLineLength(1)
            .setLineColor(color)
            .setBodyBorderColor(color)
            .setBodyTextColor(color)
            .setQuantityBorderColor(color)
            .setQuantityTextColor(color)
            .setQuantityBackgroundColor(PRICE_CHART_COLORS.TRANSLUCENT_WHITE);
          const ocoLine = tvWidgetRef.current
            .activeChart()
            .createOrderLine()
            .setQuantity(setting.amount)
            .setPrice(setting.entryPrice2)
            .setText(`Order ${index + 1}.2`)
            .setLineStyle(2)
            .setLineLength(1)
            .setLineColor(color)
            .setBodyBorderColor(color)
            .setBodyTextColor(color)
            .setQuantityBorderColor(color)
            .setQuantityTextColor(color)
            .setQuantityBackgroundColor(PRICE_CHART_COLORS.TRANSLUCENT_WHITE);
          return [orderLine, ocoLine];
        }

        return tvWidgetRef.current
          .activeChart()
          .createOrderLine()
          .setQuantity(setting.amount)
          .setPrice(setting.entryPrice1)
          .setText(`Order ${index + 1}`)
          .setLineStyle(2)
          .setLineLength(1)
          .setLineColor(color)
          .setBodyBorderColor(color)
          .setBodyTextColor(color)
          .setQuantityBorderColor(color)
          .setQuantityTextColor(color)
          .setQuantityBackgroundColor(PRICE_CHART_COLORS.TRANSLUCENT_WHITE);
      });
    });
  }, [orderSettingsList]);

  return (
    <div className={classNames(styles.wrapper, { [styles.loading]: loading })}>
      {loading && <Spin className={styles.loader} />}
      <div className={classNames(styles.chartAreaWrapper, { [styles.displayNone]: loading })}>
        <div id={containerId} className={styles.chartContainer} ref={chartContainerRef} />
      </div>
    </div>
  );
};
SinglePriceChart.propTypes = {
  selectedCurrencyPairId: PropTypes.string.isRequired,
  orderSettingsList: PropTypes.arrayOf(PropTypes.shape({})),
  instrumentList: PropTypes.shape({}).isRequired,
  instrumentOptions: PropTypes.shape(
    ALL_SERVICES.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      {},
    ),
  ).isRequired,
  serviceId: PropTypes.string.isRequired,
  resolution: PropTypes.string.isRequired,
  selectedSellBuyId: PropTypes.number.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  ocoIsChecked: PropTypes.bool.isRequired,
  ocoValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  entryPriceTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  basePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isMobile: PropTypes.bool,
};
SinglePriceChart.defaultProps = {
  orderSettingsList: [],
  isMobile: false,
};

export default memo(SinglePriceChart);
