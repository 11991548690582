import React, { memo, useEffect, useRef, useCallback } from 'react';
import * as LightweightCharts from 'lightweight-charts';
import PropTypes from 'prop-types';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import styles from './chartMakeSummaryPage.module.scss';

const ACTIVE_OPACITY = 0.65;
const INACTIVE_OPACITY = 0.2;

const SummaryChart = ({ instrumentName, historyPoints, ranges, precision }) => {
  const chartRef = useRef(null);

  const calcVisibleRange = (calcRanges) => {
    const rangeMaxPrice = Math.max(...calcRanges.map((range) => range.max));
    const rangeMinPrice = Math.min(...calcRanges.map((range) => range.min));
    return {
      maxValue: rangeMaxPrice,
      minValue: rangeMinPrice,
    };
  };

  const createChart = useCallback(() => {
    if (ranges.length === 0) return;
    const container = document.getElementById('container');
    if (chartRef.current) {
      const { maxValue, minValue } = calcVisibleRange(ranges);
      chartRef.current.invisibleRangeAreaSeries.applyOptions({
        autoscaleInfoProvider: () => ({
          priceRange: {
            maxValue,
            minValue,
          },
        }),
      });
      setTimeout(() => {
        chartRef.current.chart.resize(container.clientWidth, container.clientHeight, true);
        ranges.forEach(({ min, max, color, isActive }, index) => {
          const corePriceUpperY = chartRef.current.historicalLineSeries.priceToCoordinate(max);
          const corePriceLowerY = chartRef.current.historicalLineSeries.priceToCoordinate(min);
          const coreRangeHeight = Math.abs(corePriceUpperY - corePriceLowerY);
          const rangeDiv = document.getElementById(`core-range${index}`);
          if (!rangeDiv) return;
          rangeDiv.className = 'core-range';
          rangeDiv.style.width = `${chartRef.current.chart.timeScale().width()}px`;
          rangeDiv.style.height = `${coreRangeHeight}px`;
          rangeDiv.style.top = `${corePriceUpperY}px`;
          rangeDiv.style.backgroundColor = color;
          rangeDiv.style.opacity = isActive ? ACTIVE_OPACITY : INACTIVE_OPACITY;
          if (isActive) {
            chartRef.current.minPriceLine.applyOptions({
              price: min,
              color,
            });
            chartRef.current.maxPriceLine.applyOptions({
              price: max,
              color,
            });
          }
        });
      }, 200);

      return;
    }

    const chart = LightweightCharts.createChart(container, {
      crosshair: {
        mode: LightweightCharts.CrosshairMode.Normal,
      },
      layout: {
        backgroundColor: '#171717',
        textColor: 'rgba(255, 255, 255, 0.8)',
      },
      grid: {
        vertLines: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
        horzLines: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
      rightPriceScale: {
        borderColor: 'rgba(255, 255, 255, 0.8)',
      },
      timeScale: {
        borderColor: 'rgba(255, 255, 255, 0.8)',
        rightOffset: 0,
        barSpacing: 6,
        minBarSpacing: 0.5,
        fixLeftEdge: !1,
        fixRightEdge: !1,
        lockVisibleTimeRangeOnResize: !1,
        rightBarStaysOnScroll: !1,
        borderVisible: !0,
        visible: !0,
        timeVisible: !1,
        secondsVisible: !0,
        shiftVisibleRangeOnNewBar: !0,
      },
      autoScale: true,
      localization: {
        locale: 'ja-JP',
        dateFormat: 'yyyy-MM-dd',
        priceFormatter: (price) => price.toFixed(precision),
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
        horzTouchDrag: false,
        vertTouchDrag: false,
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
    });

    // currency name label
    const legend = document.createElement('div');
    legend.className = 'three-line-legend';
    const rect = container.getBoundingClientRect();
    const offsetX = rect.left;
    const offsetY = rect.top;
    container.appendChild(legend);
    legend.style.display = 'block';
    legend.style.left = `${offsetX + 3}px`;
    legend.style.top = `${offsetY + 3}px`;
    // eslint-disable-next-line
    legend.innerHTML = `<div style="font-size: 16px;margin: 4px 0px;color: #fff;width: 200px">${removeSuffix(
      instrumentName,
    )}(ASK)・1日</div>`;

    // drawed chart line
    const historicalLineSeries = chart.addLineSeries({
      priceLineVisible: false,
      crosshairMarkerVisible: false,
      lastValueVisible: false,
      color: '#58DEFC',
      priceScaleId: 'right',
      scaleMargins: {
        top: 0,
        bottom: 0,
      },
      priceLineStyle: LightweightCharts.LineStyle.Solid,
    });
    historicalLineSeries.setData(historyPoints);

    chart
      .timeScale()
      .setVisibleRange({ from: historyPoints[0].time, to: historyPoints[historyPoints.length - 1].time });

    // set visible price range
    const { maxValue, minValue } = calcVisibleRange(ranges);
    const invisibleRangeAreaSeries = chart.addAreaSeries({
      lastValueVisible: false,
      priceLineVisible: false,
      crosshairMarkerVisible: false,
      topColor: 'transparent',
      bottomColor: 'transparent',
      lineColor: 'transparent',
      priceScaleId: 'right',
      scaleMargins: {
        top: 0,
        bottom: 0,
      },
      autoscaleInfoProvider: () => ({
        priceRange: {
          maxValue,
          minValue,
        },
      }),
    });
    invisibleRangeAreaSeries.setData([
      { time: historyPoints[0].time, value: maxValue },
      { time: historyPoints[historyPoints.length - 1].time, value: maxValue },
    ]);

    // range area
    const innerContainer = container.querySelectorAll('div > table > tr > td')[1];
    let minPriceLine;
    let maxPriceLine;
    ranges.forEach(({ min, max, color, isActive }, index) => {
      const corePriceUpperY = historicalLineSeries.priceToCoordinate(max);
      const corePriceLowerY = historicalLineSeries.priceToCoordinate(min);
      const coreRangeHeight = Math.abs(corePriceUpperY - corePriceLowerY);
      const rangeDiv = document.createElement('div');
      if (!rangeDiv) return;
      rangeDiv.id = `core-range${index}`;
      rangeDiv.className = 'core-range';
      const timeScaleWidth = chart.timeScale().width();
      const rectWidth = Math.abs(window.innerWidth - timeScaleWidth) < 5 ? timeScaleWidth - 50 : timeScaleWidth;
      rangeDiv.style.width = `${rectWidth}px`;
      rangeDiv.style.height = `${coreRangeHeight}px`;
      rangeDiv.style.top = `${corePriceUpperY}px`;
      rangeDiv.style.backgroundColor = color;
      rangeDiv.style.opacity = isActive ? ACTIVE_OPACITY : INACTIVE_OPACITY;
      if (isActive) {
        minPriceLine = historicalLineSeries.createPriceLine({
          price: min,
          color,
        });
        maxPriceLine = historicalLineSeries.createPriceLine({
          price: max,
          color,
        });
      }

      innerContainer.appendChild(rangeDiv);

      chartRef.current = { chart, invisibleRangeAreaSeries, historicalLineSeries, minPriceLine, maxPriceLine };
    });
  }, [ranges, historyPoints, instrumentName, precision]);

  useEffect(() => {
    createChart();
    const resizeTimeout = setTimeout(() => {
      const container = document.getElementById('container');
      if (chartRef?.current?.chart?.resize) {
        chartRef.current.chart.resize(container.clientWidth, container.clientHeight, true);
      }
    }, 200);
    return () => clearTimeout(resizeTimeout);
  }, [createChart, ranges]);

  return <div id="container" className={styles.chartContainer} />;
};

export default memo(SummaryChart);

SummaryChart.propTypes = {
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  historyPoints: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.shape({
        day: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
      }).isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  instrumentName: PropTypes.string.isRequired,
  precision: PropTypes.number.isRequired,
};
