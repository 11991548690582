import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './distributionLegend.module.scss';

export const DistributionLegend = memo(({ color, name, value }) => (
  <div className={styles.container}>
    <div style={{ backgroundColor: color }} className={classNames(styles.circle)} />
    <div className={styles.item}>
      <div className={styles.label}>{name}</div>
      <div className={styles.valueArea}>
        <div className={styles.value}>{value}</div>
        <div className={styles.symbol}>%</div>
      </div>
    </div>
  </div>
));

DistributionLegend.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string,
};

DistributionLegend.defaultProps = {
  color: undefined,
};
