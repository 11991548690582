import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DATA_SIZE_10MB, MODAL_SIZES } from 'shared-modules/constants';
import labImages from '../../../../assets/labImages/labImages';
import labImagesHelper from '../../../../assets/labImages/labImagesHelper';
import styles from './iconChoiceModal.module.scss';
import { isValidImage } from '../../../../services';
import IconCropModal from '../IconCropModal';

const handleStopPropagation = (e) => e.stopPropagation();

export const IconChoiceModal = memo(({ isOpen, closeModal, finishCallback }) => {
  const isMountedRef = useRef(false);
  const inputRef = useRef(null);
  const sourceRef = useRef(null);
  const [isOpenIconCrop, setOpenIconCrop] = useState(false);
  const [selectFileSource, setSelectFileSource] = useState(null);
  const [selectFileName, setSelectFileName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      if (sourceRef.current) {
        URL.revokeObjectURL(sourceRef.current);
      }
    };
  }, []);

  const handleClickUpload = useCallback(() => {
    if (inputRef.current) {
      setErrorMessage(null);
      inputRef.current.value = '';
      inputRef.current.click();
    }
  }, []);

  const handleChangeFile = useCallback(async (e) => {
    if (!isMountedRef.current) {
      return;
    }
    const file = e.target?.files?.[0];
    if (sourceRef.current) {
      URL.revokeObjectURL(sourceRef.current);
    }
    const source = URL.createObjectURL(file);
    sourceRef.current = source;
    if (!(await isValidImage(source))) {
      setErrorMessage('無効な画像ファイルです。別の画像ファイルを選択してください。');
      return;
    }
    if (file.size > DATA_SIZE_10MB) {
      setErrorMessage('JPG・PNGで10MB以内の画像を登録してください。');
      return;
    }
    setErrorMessage(null);
    setSelectFileName(file.name);
    setSelectFileSource(source);
    setOpenIconCrop(true);
  }, []);

  const hideIconCropModal = useCallback(() => {
    setOpenIconCrop(false);
  }, []);

  const finishIconCropModal = useCallback(
    (uri) => {
      finishCallback(uri, selectFileName);
      closeModal();
    },
    [selectFileName, finishCallback, closeModal],
  );

  return (
    <>
      <Modal
        show={isOpen}
        onHide={closeModal}
        centered
        onClick={handleStopPropagation}
        size={MODAL_SIZES.LARGE}
        dialogClassName={styles.modal}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton={false} bsPrefix={styles.header}>
          <i aria-hidden className={classNames('material-icons-outlined', styles.close)} onClick={closeModal}>
            clear
          </i>
        </Modal.Header>
        <Modal.Body bsPrefix={styles.body}>
          <div className={styles.title}>アイコンを選択してください</div>
          <div className={styles.content}>
            {errorMessage && <div className={styles.errorMessages}>{errorMessage}</div>}
            <div className={styles.grid}>
              <div className={styles.iconContainer} onClick={handleClickUpload} role="button" tabIndex={0} aria-hidden>
                <img
                  className={classNames(styles.iconImage, styles.upload)}
                  src={labImagesHelper['iconSelect.png']}
                  alt="アイコン選択"
                />
                <div className={styles.description}>
                  <div className={styles.headline}>アップロード</div>
                  <div className={styles.detail}>
                    <div>10MB以下の</div>
                    <div>JPG/PNGファイル</div>
                  </div>
                </div>
                <div className={styles.plusIconWrapper}>
                  <i className={classNames('material-icons-outlined', styles.plusIcon)}>add_circle</i>
                </div>
                <input
                  type="file"
                  accept=".jpg,.png"
                  ref={inputRef}
                  className={styles.hidden}
                  onChange={handleChangeFile}
                />
              </div>
              {labImages.map(({ name, uri }) => (
                <div
                  key={name}
                  className={styles.iconContainer}
                  onClick={() => {
                    finishCallback(uri, name);
                    closeModal();
                  }}
                  role="button"
                  tabIndex={0}
                  aria-hidden
                >
                  <img className={styles.iconImage} src={uri} alt={name} />
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {isOpenIconCrop && selectFileSource && (
        <IconCropModal
          sourceBlobUrl={selectFileSource}
          isOpen={isOpenIconCrop}
          closeModal={hideIconCropModal}
          finishCallback={finishIconCropModal}
        />
      )}
    </>
  );
});

IconChoiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  finishCallback: PropTypes.func.isRequired,
};
