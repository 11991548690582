import React, { memo, useState, useRef, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BUILDER_PAGES } from 'shared-modules/constants/builder';
import { BUY_SELL_MAIN, CHART_RESOLUTION_MAIN, CFD } from 'shared-modules/constants';
import { getLastYearRange } from 'shared-modules/services';
import convertDrawingToLogics from 'shared-modules/services/chartMakeLogic/LogicRecommender';
import {
  changeBuilderActivePage,
  resetBuilderActiveCurrency,
  addDrawingPoint,
  changeSelectRecommend,
  changeRecommendLogicList,
  changeRecommendModal,
  sendNotificationError,
} from '../../../redux/actions';
import styles from './chartMakeDrawPage.module.scss';
import LightSketchboard from './LightSketchboard';
import { store } from '../../../redux/store';
import RecommendModal from './RecommendModal';
import { Button } from '../../../components';

const buttonWidth = 200;

const ChartMakeDrawPage = ({ drawingPoints, setDrawingPoints }) => {
  const dispatch = useDispatch();
  const sketchboardRef = useRef(null);
  const [selectedRecommend, setSelectedRecommend] = useState(null);
  const [drawingCompleted, setDrawingCompleted] = useState(false);
  const handleDrawingEnd = (isDone) => {
    if (drawingCompleted !== isDone) setDrawingCompleted(isDone);
  };

  const isOpenRecommend = useSelector((state) => state.webBuilder.isOpenRecommendModal);
  const recommendLogicItems = useSelector((state) => state.webBuilder.recommendLogicList);
  const sketchData = useSelector((state) => state.webBuilder.sketchData);

  const isSketchDataExist = Boolean(sketchData.length);

  const { activeCurrency: instrumentId } = store.getState().builder;
  const serviceId = useSelector((state) => state.auth.serviceId);

  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const instrumentSettings = instrumentList[instrumentId];

  const openPreviousPage = useCallback(() => {
    batch(() => {
      dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.CHART_MAKE_INSTRUMENT_CHOICE_PAGE.ID }));
      dispatch(resetBuilderActiveCurrency());
    });
  }, [dispatch]);

  const openNextPage = useCallback(() => {
    dispatch(changeSelectRecommend({ selectRecommend: selectedRecommend }));
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.CHART_MAKE_SUMMARY_PAGE.ID }));
  }, [dispatch, selectedRecommend]);

  const openRecommendModal = useCallback(
    (recommendLogicList) => {
      const completedDrawingData = sketchboardRef.current.drawingDataCompletion();

      batch(() => {
        dispatch(changeRecommendLogicList({ recommendLogicList }));
        dispatch(changeRecommendModal({ isOpenRecommendModal: true }));
        dispatch(addDrawingPoint({ sketchData: completedDrawingData }));
      });
    },
    [dispatch],
  );

  const closeRecommendModal = useCallback(() => {
    batch(() => {
      dispatch(addDrawingPoint({ sketchData: [] }));
      dispatch(changeRecommendModal({ isOpenRecommendModal: false }));
      dispatch(changeRecommendLogicList({ recommendLogicList: [] }));
    });
  }, [dispatch]);

  const { startTime, endTime } = getLastYearRange();
  return (
    <div className={classNames(styles.pageWrapper)}>
      <div className={styles.title}>
        {!isOpenRecommend ? (
          <>
            <span>STEP 2/4</span>
            <span>1年分の値動きを予測してチャートを描いてみましょう</span>
          </>
        ) : (
          <>
            <span>STEP 3/4</span>
            <span>自分に合ったロジックを選択しましょう</span>
          </>
        )}
      </div>
      <div className={classNames(styles.chartWrapper, { [styles.opacityHalf]: isOpenRecommend })}>
        <LightSketchboard
          askBid={BUY_SELL_MAIN.BUY.CHART_ID}
          serviceId={serviceId}
          instrumentId={instrumentId}
          resolution={CHART_RESOLUTION_MAIN.DAYS_1.ID}
          startTime={startTime}
          endTime={endTime}
          initialDrawingPoints={drawingPoints}
          onChange={setDrawingPoints}
          ref={sketchboardRef}
          onDrawingEnd={handleDrawingEnd}
        />
      </div>

      <div className={classNames(styles.buttonGroup, 'd-flex', 'justify-content-start')}>
        <Button
          className={styles.buttonInnerStyle}
          onClick={() => {
            if (isOpenRecommend) {
              closeRecommendModal();
              setSelectedRecommend(null);
              return;
            }

            setDrawingPoints([]);
            openPreviousPage();
          }}
          width={buttonWidth}
          secondary
        >
          戻る
        </Button>
        {!isOpenRecommend && (
          <Button
            className={styles.buttonInnerStyle}
            onClick={() => {
              sketchboardRef.current.resetBoard();
              handleDrawingEnd(false);
            }}
            width={buttonWidth}
            secondary
          >
            やり直す
          </Button>
        )}
        <Button
          className={styles.buttonInnerStyle}
          onClick={() => {
            if (isOpenRecommend) {
              openNextPage();
              return;
            }
            const [itemList] = convertDrawingToLogics(
              drawingPoints,
              instrumentId,
              serviceId,
              serviceId === CFD ? instrumentSettings : undefined,
            );
            if (itemList.length === 0) {
              dispatch(
                sendNotificationError({
                  title: '',
                  message: '該当するロジックはありません。やり直すボタンを押下して再度チャートを描画してください。',
                }),
              );
              return;
            }
            openRecommendModal(itemList);
          }}
          disabled={!(drawingCompleted || isSketchDataExist) || (isOpenRecommend && !selectedRecommend)}
        >
          次のステップへ
        </Button>
      </div>
      {isOpenRecommend && (
        <RecommendModal
          isOpen={isOpenRecommend}
          closeModal={() => {
            closeRecommendModal();
          }}
          recommendLogicItems={recommendLogicItems}
          selectRecommend={selectedRecommend}
          onSelect={(logicId) => {
            setSelectedRecommend(logicId);
          }}
        />
      )}
    </div>
  );
};

ChartMakeDrawPage.propTypes = {
  drawingPoints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setDrawingPoints: PropTypes.func.isRequired,
};

export default memo(ChartMakeDrawPage);
