import React, { memo, useCallback, useMemo, useEffect } from 'react';
import Markdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { markAsReadRequest } from 'shared-modules/redux/actions/messageActions';
import { CHANNEL_BROWSER, MESSAGE_TYPE } from 'shared-modules/constants';
import { getSlashedDateStringWithoutTime } from 'shared-modules/services';
import styles from './Accordion.module.scss';
import RangeOutContainer from '../RangeOutContainer';
import CustomAccordion from '../../../components/CustomAccordion';
import UnreadLabel from '../UnreadLabel';

const CustomLink = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

CustomLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const convertData = (messages) =>
  messages.map((message) => {
    const { messageId, messageType, startTime, title, body, status, linkUrl } = message;
    return {
      key: messageId,
      title: (
        <div className={styles.accordionTitle}>
          <div className={styles.accordionDate}>{getSlashedDateStringWithoutTime(status?.startTime ?? startTime)}</div>
          <div className={styles.unread}>
            <UnreadLabel readFlg={!!status?.readFlg} />
          </div>
          <div className={styles.title}>{title}</div>
        </div>
      ),
      body:
        messageType === MESSAGE_TYPE.RANGE_OUT ? (
          <RangeOutContainer message={message} />
        ) : (
          <div className={styles.accordionBody}>
            <div>
              <Markdown components={{ a: CustomLink }}>{body}</Markdown>
            </div>
            {linkUrl && (
              <div className={styles.bottomLink}>
                詳細は
                <a href={linkUrl} target="_blank" rel="noopener noreferrer" className={styles.linkButton}>
                  こちら
                </a>
              </div>
            )}
          </div>
        ),
    };
  });

export const Accordion = memo(({ messages, defaultOpenedMessageId }) => {
  const dispatch = useDispatch();
  const displayedData = useMemo(() => convertData(messages), [messages]);
  const isUnreadDefault = useMemo(() => {
    if (messages?.length && defaultOpenedMessageId) {
      const found = messages.find((m) => m.messageId === defaultOpenedMessageId && m.status?.readFlg === false);
      if (found) {
        return true;
      }
    }
    return false;
  }, [messages, defaultOpenedMessageId]);

  const defaultActiveKeys = useMemo(
    () => (isUnreadDefault ? [defaultOpenedMessageId] : undefined),
    [isUnreadDefault, defaultOpenedMessageId],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isUnreadDefault) {
        dispatch(
          markAsReadRequest({ messageId: defaultOpenedMessageId, isIndividual: true, channel: CHANNEL_BROWSER }),
        );
      }
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, isUnreadDefault, defaultOpenedMessageId]);

  const onOpen = useCallback(
    (messageId) => {
      dispatch(markAsReadRequest({ messageId, isIndividual: true, channel: CHANNEL_BROWSER }));
    },
    [dispatch],
  );

  return <CustomAccordion options={displayedData} defaultActiveKeys={defaultActiveKeys} onOpen={onOpen} />;
});

Accordion.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      messageId: PropTypes.number.isRequired,
      messageType: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      action: PropTypes.string,
      linkUrl: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      status: PropTypes.shape({
        startTime: PropTypes.string,
        readFlg: PropTypes.bool,
        parameters: PropTypes.string,
      }),
      targetServices: PropTypes.arrayOf(
        PropTypes.shape({
          serviceId: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  defaultOpenedMessageId: PropTypes.number,
};

Accordion.defaultProps = {
  defaultOpenedMessageId: undefined,
};
