import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersRequest } from 'shared-modules/redux/orders';
import { FX, ETF, CFD } from 'shared-modules/constants';
import { POPUP_MESSAGES } from '../../constants';
import {
  replace,
  getInstrumentListRequest,
  changeSelectedSide,
  socketConnectionRequest,
  getRatesRequest,
  changeServiceIdSuccess,
  getPositionsRequest,
  updateServiceMaintenanceStatus,
} from '../../redux/actions';
import Chart from '../../components/Chart';
import styles from './manualTradeChart.module.scss';

const ChartModal = () => {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.router.location.query);
  const serviceId = query?.serviceId;
  const selectedInstrumentId = query?.selectedInstrumentId;
  const isFXMaintenance = query?.isFXMaintenance === 'true';
  const isETFMaintenance = query?.isETFMaintenance === 'true';
  const isCFDMaintenance = query?.isCFDMaintenance === 'true';
  const selectedSide = Number(query?.selectedSide);
  const onlyApGroupPositions = query?.onlyApGroupPositions;
  const onlyManualPositions = Number(query?.onlyManualPositions);
  const chartProps = { serviceId };

  if (selectedInstrumentId) chartProps.selectedInstrumentId = selectedInstrumentId;
  if (selectedSide) chartProps.selectedSide = selectedSide;
  if (onlyApGroupPositions) chartProps.onlyApGroupPositions = onlyApGroupPositions;
  if (onlyManualPositions) chartProps.onlyManualPositions = onlyManualPositions;

  useEffect(() => {
    if (!window.opener) {
      dispatch(replace('/404'));
    } else {
      if (isFXMaintenance) dispatch(updateServiceMaintenanceStatus({ serviceId: FX, isMaintenance: true }));
      if (isETFMaintenance) dispatch(updateServiceMaintenanceStatus({ serviceId: ETF, isMaintenance: true }));
      if (isCFDMaintenance) dispatch(updateServiceMaintenanceStatus({ serviceId: CFD, isMaintenance: true }));

      dispatch(getInstrumentListRequest());
      dispatch(getRatesRequest());
      dispatch(getPositionsRequest({ serviceId }));
      dispatch(socketConnectionRequest());

      if (onlyApGroupPositions) {
        const payload = { serviceId, params: { instrumentId: selectedInstrumentId, apGroupId: onlyApGroupPositions } };
        dispatch(getOrdersRequest(payload));
      } else if (onlyManualPositions) {
        const payload = {
          serviceId,
          params: { instrumentId: selectedInstrumentId, tradeMethod: '1' },
        };
        dispatch(getOrdersRequest(payload));
      } else {
        const payload = { serviceId, params: { instrumentId: selectedInstrumentId } };
        dispatch(getOrdersRequest(payload));
      }
      window.opener.postMessage({ message: POPUP_MESSAGES.GET_CHART_TYPE_REQUEST }, '*');
    }
  }, [
    dispatch,
    onlyApGroupPositions,
    onlyManualPositions,
    serviceId,
    selectedInstrumentId,
    isFXMaintenance,
    isETFMaintenance,
    isCFDMaintenance,
  ]);

  const windowEventListener = useCallback(
    ({ data }) => {
      switch (data.message) {
        case POPUP_MESSAGES.GET_CHART_TYPE_SUCCESS: {
          dispatch(changeSelectedSide({ id: data.payload.selectedSide }));
          dispatch(changeServiceIdSuccess({ serviceId: data.payload.serviceId }));
          break;
        }
        default: {
          // empty
        }
      }
    },
    [dispatch],
  );
  useEffect(() => {
    window.addEventListener('message', windowEventListener);
    return () => {
      window.removeEventListener('message', windowEventListener);
    };
  }, [windowEventListener]);

  return (
    <div className={styles.chartWrapper}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Chart {...chartProps} isFullScreen withPositions />
    </div>
  );
};

export default memo(ChartModal);
