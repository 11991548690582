// eslint-disable-next-line import/no-unresolved
import { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useDispatch } from 'react-redux';
import { setSystemNotificationModalVisible } from '../redux/actions';

export const useSystemNotificationModalInfo = ({ notificationType, visible }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSystemNotificationModalVisible({ notificationType, visible }));
  }, [dispatch, notificationType, visible]);
};
