import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './labelWithHelp.module.scss';

export const LabelWithHelp = memo(({ className, label, onClick }) => {
  const handleKeyDown = useKeyDownHandler(onClick);

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.label}>{label}</div>
      <i
        aria-hidden
        className={classNames('material-icons-outlined', styles.icon)}
        onClick={onClick}
        onKeyDown={handleKeyDown}
      >
        help_outline
      </i>
    </div>
  );
});

LabelWithHelp.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

LabelWithHelp.defaultProps = {
  className: undefined,
  label: undefined,
};
