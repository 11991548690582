import { memo } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useUuid } from 'shared-modules/services/hooks';
import styles from './helpTooltipIcon.module.scss';

export const HelpTooltipIcon = memo(({ className, tip, place }) => {
  const uuid = useUuid();
  return (
    <>
      <div className={classNames(styles.container, className)}>
        <div data-for={uuid} data-tip={tip}>
          <i className="material-icons-outlined">help_outline</i>
        </div>
      </div>
      <ReactTooltip id={uuid} className={styles.tip} place={place} />
    </>
  );
});

HelpTooltipIcon.propTypes = {
  tip: PropTypes.string,
  place: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  className: PropTypes.string,
};

HelpTooltipIcon.defaultProps = {
  tip: undefined,
  place: 'top',
  className: undefined,
};
