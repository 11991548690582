import { memo, useCallback, useEffect, useMemo, useState, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useBlockLayout, useResizeColumns, useRowSelect, useSortBy, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useDebouncedCallback } from 'shared-modules/services/hooks';
import { TableRefContext } from '../../contexts';
import { saveSelectedTableRows } from '../../redux/actions';
import { TableLoader } from './TableLoader';
import { EmptyText } from './EmptyText';
import styles from './table.module.scss';

// @see table.module.scss
const SCROLL_WIDTH = 6;
const RESIZER_WIDTH = 10;
const DEFAULT_ROW_HIGHT = 40;

const defaultColumnParams = {
  minWidth: 70,
  width: 100,
  maxWidth: 400,
};

export const Table = memo(
  ({
    columns,
    tableData,
    emptyText,
    className,
    resized,
    tableMetaInfo,
    paginationCallback,
    defaultSorting,
    useServerSorting,
    sortingHandler,
    onSort,
    filterHandler,
    withCheckboxes,
    maxColumnWidth,
    /**
     * This prop is used only for TablePositions because of checkbox column.
     * In any other places use 'sticky: right' in 'columns' prop
     */
    stickyColumns,
    rowHight,
  }) => {
    const { pageNumber, totalPages, loading: isLoading } = tableMetaInfo;
    const { key: resizedKey, default: resizedDefault } = resized;

    const dispatch = useDispatch();

    const [width, setWidth] = useState(0);

    const defaultColumn = useMemo(() => ({ ...defaultColumnParams, maxWidth: maxColumnWidth }), [maxColumnWidth]);

    const columnWithWidth = useMemo(() => {
      if (!columns || !width) {
        return [];
      }
      let resizedArray = resizedDefault;

      const savedResizeArray = localStorage.getItem(resizedKey);
      if (savedResizeArray) resizedArray = JSON.parse(savedResizeArray);

      const sumWidth = resizedArray.reduce((acc, item) => acc + item, 0);
      const isLastColumnCanReize = !stickyColumns?.length && !columns[columns.length - 1].disableResizing;
      const scrollSpace = Math.max(SCROLL_WIDTH, isLastColumnCanReize ? RESIZER_WIDTH : 0); // どちらか大きい方

      resizedArray = resizedArray.map((cell) => Math.floor((cell / sumWidth) * (width - scrollSpace)));

      return columns.map((item, index) => {
        const maxWidth = Math.min(item?.maxWidth ?? Infinity, defaultColumn.maxWidth);
        const minWidth = Math.max(item?.minWidth ?? -Infinity, defaultColumn.minWidth);

        let dynWidth = resizedArray[index] > maxWidth ? maxWidth : resizedArray[index];
        dynWidth = resizedArray[index] < minWidth ? minWidth : dynWidth;

        return { ...item, width: dynWidth };
      });
    }, [columns, width, resizedDefault, resizedKey, defaultColumn.maxWidth, defaultColumn.minWidth, stickyColumns]);

    const tableRef = useContext(TableRefContext);

    const table = useTable(
      {
        columns: columnWithWidth,
        data: tableData,
        defaultColumn,
        disableMultiSort: true,
        initialState: { sortBy: defaultSorting },
        manualSortBy: useServerSorting,
        disableSortRemove: true,
        tableMetaInfo,
        filterHandler,
        sortingHandler,
        onSort,
      },
      useSortBy,
      useRowSelect,
      useBlockLayout,
      useResizeColumns,
      useSticky,
      (hooks) => {
        if (withCheckboxes) hooks.visibleColumns.push((hooksColumns) => [...hooksColumns, ...stickyColumns]);
      },
    );

    useEffect(() => {
      if (tableRef) {
        tableRef.current = table;
      }
    }, [table, tableRef]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      totalColumnsWidth,
      state: { sortBy },
    } = table;

    useEffect(() => {
      if (withCheckboxes) {
        const selectedRows = selectedFlatRows.map((el) => el.original);
        dispatch(saveSelectedTableRows({ rows: selectedRows }));
      }
    }, [dispatch, selectedFlatRows, withCheckboxes]);

    useEffect(() => {
      if (onSort && sortBy[0]) {
        sortingHandler(sortBy[0]);
      }
    }, [onSort, sortBy, sortingHandler]);

    const saveResizedValue = useDebouncedCallback((data) => {
      localStorage.setItem(resizedKey, JSON.stringify(data));
    }, 1000);

    const hasMorePages = useMemo(() => {
      if (!pageNumber || !totalPages) return false;
      return pageNumber < totalPages;
    }, [pageNumber, totalPages]);

    const isEmptyTable = tableData.length === 0;

    const isPaginatedLoading = hasMorePages && isLoading;
    const isTableLoading = isEmptyTable && isLoading;
    const isEmptyTextShown = isEmptyTable && !isLoading;

    // INFINITE LIST PROPS
    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = (index) => !hasMorePages || index < rows.length;

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const tempItemCount = hasMorePages ? rows.length + 1 : rows.length;
    const itemCount = Number.isFinite(tempItemCount) ? tempItemCount : 0;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = isPaginatedLoading ? () => {} : paginationCallback;

    const hasFooter = footerGroups.every((group) => {
      if (
        group.headers.every(
          (column) => !column.Footer.length || column.Footer.name === 'emptyRenderer' || column.Footer.name === '-',
        )
      ) {
        return false;
      }
      return true;
    });

    const renderRow = ({ index, style }) => {
      if (!isItemLoaded(index)) {
        return (
          // need to apply "absolute" position styles assigned by react-table
          <div style={style}>
            <TableLoader loading={isPaginatedLoading} style={styles.hasMorePages} />
          </div>
        );
      }

      const row = rows[index];
      prepareRow(row);

      // eslint-disable-next-line react/jsx-props-no-spreading
      const { style: rowStyle, ...restRow } = row.getRowProps({ style });

      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div {...restRow} style={{ ...rowStyle, width: totalColumnsWidth }} className={styles.tr}>
          {row.cells.map(({ value, getCellProps, column, render }, cellIndex, cells) => {
            const { style: cellStyle, ...restCell } = getCellProps();
            // define right position on sticky columns
            let localCellStyles = { ...cellStyle };
            if (column.sticky) {
              localCellStyles = {
                ...localCellStyles,
                right: cells?.[cellIndex + 1]?.column?.totalWidth ?? 0,
              };
            }

            return (
              <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...restCell}
                key={column.id + value}
                style={localCellStyles}
                className={classNames(styles.td, {
                  [styles.isNumber]: column.isNumber,
                  [styles.isOrderName]: column.isOrderName,
                  [styles.stickyColumn]: column.sticky,
                })}
              >
                <div
                  className={classNames(styles.cellWrapper, {
                    [styles.isNumber]: column.isNumber,
                    [styles.isOrderName]: column.isOrderName,
                  })}
                >
                  {render('Cell')}
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    const handleResize = useCallback((size) => {
      if (Number.isFinite(size?.width)) {
        setWidth(size.width);
      }
    }, []);

    return (
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      <div {...getTableProps()} className={classNames(styles.table, styles.sticky, className)}>
        <AutoSizer onResize={handleResize}>
          {({ height }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
              itemCount={itemCount}
              threshold={5}
            >
              {({ onItemsRendered, ref: infiniteListRef }) => (
                <List
                  height={height}
                  itemCount={itemCount}
                  itemSize={rowHight}
                  width={width}
                  onItemsRendered={onItemsRendered}
                  ref={infiniteListRef}
                  // virtualized list creates additional 'div' wrappers - it breaks sticky column functionality
                  // so the workaround is to move table's header and body inside of innerElementType
                  innerElementType={({ children, style, ...rest }) => (
                    <>
                      <div className={styles.header}>
                        <div style={{ width: totalColumnsWidth }}>
                          {headerGroups.map((headerGroup) => {
                            const { headers, getHeaderGroupProps } = headerGroup;
                            // totalWidth for sticky column
                            if (saveResizedValue) saveResizedValue(headers.map((column) => column.totalWidth));
                            return (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              <div {...getHeaderGroupProps()} className={styles.tr}>
                                {headers.map((column, index) => {
                                  const sortByToggleProps = column.getSortByToggleProps();
                                  const { style: headerStyles, ...restHeaderProps } = column.getHeaderProps();
                                  // define right position on sticky columns
                                  let localHeaderStyles = { ...sortByToggleProps.style, ...headerStyles };
                                  if (column.sticky) {
                                    localHeaderStyles = {
                                      ...localHeaderStyles,
                                      right: headers[index + 1]?.totalWidth ?? 0,
                                    };
                                  }

                                  return (
                                    <div
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...restHeaderProps}
                                      style={localHeaderStyles}
                                      className={classNames(
                                        styles.th,
                                        styles.cellWrapper,
                                        styles.headerCell,
                                        {
                                          [styles.isNumber]: column.isNumber,
                                          [styles.sortedDesc]: column.isSortedDesc,
                                          [styles.isSortedAsc]: column.isSorted && !column.isSortedDesc,
                                          // [styles.sortedDesc]: tableMetaInfo.desc,
                                          // [styles.isSortedAsc]:
                                          //   tableMetaInfo.sortBy === column.id && !tableMetaInfo.desc,
                                          [styles.stickyColumn]: Boolean(column.sticky),
                                        },
                                        column.className,
                                      )}
                                      key={`${column.id}Header`}
                                    >
                                      {column.render('Header')}
                                      {column.canResize && (
                                        <div
                                          // eslint-disable-next-line react/jsx-props-no-spreading
                                          {...column.getResizerProps()}
                                          className={styles.resizer}
                                        />
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>

                        {hasFooter && (
                          <div style={{ width: totalColumnsWidth }}>
                            {footerGroups.map((group) => (
                              <div
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...group.getFooterGroupProps()}
                                className={styles.tr}
                              >
                                {group.headers.map((column) => (
                                  <div
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...column.getFooterProps()}
                                    key={`${column.id}Footer`}
                                    className={classNames(
                                      styles.th,
                                      styles.footer,
                                      styles.cellWrapper,
                                      styles.headerCell,
                                      column.classname,
                                    )}
                                  >
                                    {column.render('Footer')}
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <TableLoader loading={isTableLoading} />
                      <EmptyText isEmptyTextShown={isEmptyTextShown} emptyText={emptyText} />

                      {!isEmptyTable && (
                        <div className={styles.body}>
                          <div
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...getTableBodyProps()}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                            style={style}
                          >
                            {children}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                >
                  {renderRow}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
    );
  },
);

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      Cell: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      isNumber: PropTypes.bool,
      disableSortBy: PropTypes.bool,
      className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      sticky: PropTypes.oneOf(['left', 'right']),
      disableResizing: PropTypes.bool,
    }),
  ).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emptyText: PropTypes.string,
  resized: PropTypes.shape({
    key: PropTypes.string.isRequired,
    default: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  className: PropTypes.string,
  tableMetaInfo: PropTypes.shape({
    loading: PropTypes.bool,
    pageNumber: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  paginationCallback: PropTypes.func,
  defaultSorting: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, desc: PropTypes.bool.isRequired }),
  ),
  useServerSorting: PropTypes.bool,
  sortingHandler: PropTypes.func,
  onSort: PropTypes.bool,
  filterHandler: PropTypes.func,
  withCheckboxes: PropTypes.bool,
  maxColumnWidth: PropTypes.number,
  stickyColumns: PropTypes.arrayOf(PropTypes.shape({})),
  rowHight: PropTypes.number,
};

Table.defaultProps = {
  className: '',
  emptyText: '',
  tableMetaInfo: {
    loading: false,
    pageNumber: 1,
    totalPages: 1,
  },
  paginationCallback: null,
  defaultSorting: [],
  useServerSorting: false,
  sortingHandler: () => {},
  filterHandler: () => {},
  onSort: false,
  withCheckboxes: false,
  maxColumnWidth: defaultColumnParams.maxWidth,
  stickyColumns: [],
  rowHight: DEFAULT_ROW_HIGHT,
};
