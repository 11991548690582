/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useMemo, useState } from 'react';
import { DEPOSIT_TRANSFER } from '../constants';
import { isShowDepositTransferLink } from '../utils';

export const useDepositTransfer = (hide, alwaysShow) => {
  const [showLink, setShowLink] = useState(alwaysShow === true);
  const [tooltip, setTooltip] = useState('');
  const [danger, setDanger] = useState(false);
  const [attention, setAttention] = useState(null);

  const handleChangeAttention = useCallback(
    (nextAttention) => {
      setAttention(nextAttention);
      const show = isShowDepositTransferLink(nextAttention?.level);
      setDanger(show);
      if (alwaysShow) {
        setShowLink(true);
      } else if (hide) {
        setShowLink(false);
      } else {
        setShowLink(show);
      }
      setTooltip(nextAttention?.tooltip ?? '');
    },
    [hide, alwaysShow],
  );

  return useMemo(() => {
    return {
      label: DEPOSIT_TRANSFER,
      tooltip,
      showLink,
      danger,
      handleChangeAttention,
      attention,
    };
  }, [showLink, tooltip, danger, handleChangeAttention, attention]);
};
