import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BUILDER_CONFIGRATION_FLOW_MAIN_TITLE,
  CONFIGURATION_FLOW_TITLE,
  TECH_BUILDER_FLOW_PAGES,
  TECH_CONFIGURATION_FLOW_SUB_TITLE,
} from 'shared-modules/constants/builder';
import { MODAL_SIZES } from 'shared-modules/constants';
import { useServiceName } from 'shared-modules/hooks';
import { Button, Modal } from '../../../../components';
import topImage from '../../../../assets/builder/asset-selection.png';
import Step1 from './components/Step1';
import Step10 from './components/Step10';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import Step7 from './components/Step7';
import Step8 from './components/Step8';
import Step9 from './components/Step9';
import Top from './components/Top';
import Step11 from './components/Step11';
import infoImagesHelper from '../../../../assets/builderInfo/infoImagesHelper';
import styles from './techBuilderConfigurationFlow.module.scss';

const TechBuilderConfigurationFlow = ({ isOpen, closeModal }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const serviceName = useServiceName(serviceId);

  const title = {
    1: CONFIGURATION_FLOW_TITLE.BUY_SELL_SIGNAL,
    2: CONFIGURATION_FLOW_TITLE.BAR_TYPE,
    3: CONFIGURATION_FLOW_TITLE.INDICATOR,
    4: CONFIGURATION_FLOW_TITLE.ALL_SETTLE,
    5: CONFIGURATION_FLOW_TITLE.RANGE_WIDTH,
    6: CONFIGURATION_FLOW_TITLE.ITEMS_COUNT,
    7: CONFIGURATION_FLOW_TITLE.QUANTITY[serviceId],
    8: CONFIGURATION_FLOW_TITLE.PROFIT_MARGIN,
    9: CONFIGURATION_FLOW_TITLE.STOP_LOSS,
    10: CONFIGURATION_FLOW_TITLE.FOLLOW,
    11: CONFIGURATION_FLOW_TITLE.COUNTER,
  };

  const contents = {
    0: <Top />,
    1: <Step1 />,
    2: <Step2 />,
    3: <Step3 />,
    4: <Step4 />,
    5: <Step5 />,
    6: <Step6 serviceId={serviceId} />,
    7: <Step7 serviceId={serviceId} />,
    8: <Step8 serviceId={serviceId} />,
    9: <Step9 serviceId={serviceId} />,
    10: <Step10 serviceId={serviceId} />,
    11: <Step11 serviceId={serviceId} />,
  };

  const images = {
    0: [topImage],
    1: [infoImagesHelper[`multi${serviceName}_buySell.png`]],
    2: [infoImagesHelper[`tech${serviceName}_barType.png`]],
    3: [infoImagesHelper[`tech${serviceName}_indicator1.png`], infoImagesHelper[`tech${serviceName}_indicator2.png`]],
    4: [infoImagesHelper[`tech${serviceName}_allSettle.png`]],
    5: [infoImagesHelper[`tech${serviceName}_rangeSpread.png`]],
    6: [infoImagesHelper[`tech${serviceName}_items_count.png`]],
    7: [infoImagesHelper[`multi${serviceName}_quantity.png`]],
    8: [infoImagesHelper[`multi${serviceName}_profitMargin.png`]],
    9: [infoImagesHelper[`multi${serviceName}_stopLoss.png`]],
    10: [infoImagesHelper[`multi${serviceName}_follow.png`]],
    11: [infoImagesHelper[`multi${serviceName}_counter.png`], infoImagesHelper[`multi${serviceName}_counter_fix.png`]],
  };

  const optional = [9];
  const followOrCounter = [10, 11];

  const [currentPage, changeCurrentPage] = useState(TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE);

  const mainTitle = useMemo(() => {
    if ([1, 2, 3, 4].includes(currentPage)) return BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.TECH;
    if ([5, 6, 7].includes(currentPage)) return BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.ORDER;
    if ([8, 9].includes(currentPage)) return BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.SETTLEMENT;
    if ([10, 11].includes(currentPage)) return BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.REENTRY;
    return '';
  }, [currentPage]);

  const goTop = () => {
    changeCurrentPage(TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE);
  };
  const goBack = useCallback(() => {
    changeCurrentPage(currentPage - 1);
  }, [currentPage]);
  const goNext = useCallback(() => {
    changeCurrentPage(currentPage + 1);
  }, [currentPage]);

  const closePanel = () => {
    closeModal();
  };

  const closeCurrentPage = () =>
    currentPage === TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE ? closeModal() : goTop();

  const onNextFlow = () => {
    if (currentPage === TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_STEP11_PAGE.PAGE) {
      closeModal();
    } else {
      goNext();
    }
  };
  const nextButton = () => {
    switch (currentPage) {
      case TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE:
        return 'はじめる';
      case TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_STEP11_PAGE.PAGE:
        return 'とじる';
      default:
        return '次へ';
    }
  };

  const progress = () => {
    const items = [];
    for (let i = 0; i < 11; i += 1) {
      if (i + 1 === currentPage) {
        items.push(
          <span className={styles.active} key={i}>
            ●
          </span>,
        );
      } else {
        items.push(
          <span className={styles.dot} key={i}>
            ●
          </span>,
        );
      }
    }
    return <p>{items}</p>;
  };

  return (
    <>
      {isOpen && (
        <Modal
          title={
            currentPage === TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE
              ? ''
              : `STEP${currentPage}`
          }
          isCenteredTitle
          closeModal={closePanel}
          isOpen={isOpen}
          onHide={closeCurrentPage}
          centered
          size={MODAL_SIZES.WIDTH_1024}
          aria-labelledby="contained-modal-title-vcenter"
        >
          {!(currentPage === TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE) && (
            <div className={styles.progress}>{progress()}</div>
          )}
          <div className={styles.modalComponent}>
            <div className={styles.leftComponent}>
              {currentPage === TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE ? (
                <div className={styles.titleArea}>
                  <div className={styles.topTitle}>{TECH_CONFIGURATION_FLOW_SUB_TITLE[currentPage]}</div>
                </div>
              ) : (
                <div className={styles.titleArea}>
                  <div className={styles.mainTitle}>■{mainTitle}</div>
                  <div className={styles.subTitle}>{TECH_CONFIGURATION_FLOW_SUB_TITLE[currentPage]}</div>
                  <div className={styles.title}>ー{title[currentPage]}ー</div>
                  {optional.includes(currentPage) && <p className={styles.title}>※設定は任意です。</p>}
                  {followOrCounter.includes(currentPage) && (
                    <p className={styles.title}>※STEP10かSTEP11のいずれかの設定が必要です。</p>
                  )}
                </div>
              )}
              <div className={styles.imageArea}>
                <div>
                  {images[currentPage].map((img) => (
                    <img
                      src={img}
                      alt={title[currentPage]}
                      className={images[currentPage].length > 1 ? styles.imgSmall : styles.img}
                    />
                  ))}
                </div>
              </div>
              <div className={styles.buttonArea}>
                <div className={styles.button}>
                  {currentPage === TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE ? (
                    <Button onClick={onNextFlow} className={styles.topButton}>
                      {nextButton()}
                    </Button>
                  ) : (
                    <Button onClick={onNextFlow}>{nextButton()}</Button>
                  )}
                </div>
                {!(currentPage === TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE) && (
                  <div className={styles.button}>
                    <Button onClick={goBack} secondary>
                      {currentPage === TECH_BUILDER_FLOW_PAGES.BUILDERS_CONFIGURATION_FLOW_STEP1_PAGE.PAGE
                        ? '設定の流れに戻る'
                        : `STEP${currentPage - 1}に戻る`}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.rightComponent}>
              <div>
                <div>{contents[currentPage]}</div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

TechBuilderConfigurationFlow.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(TechBuilderConfigurationFlow);
