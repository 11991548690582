import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { markAsReadRequest } from 'shared-modules/redux/actions/messageActions';
import { TAB_INDIVIDUAL_NOTICE } from 'shared-modules/constants/message';
import { CHANNEL_BROWSER, MODAL_SIZES } from 'shared-modules/constants';
import { useRangeOutList } from 'shared-modules/hooks/message';
import { Modal } from '../../../../components';
import styles from './rangeOutConfirmation.module.scss';

export const RangeOutConfirmation = memo(({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { parameters, linkUrl, messageId, serviceValues } = useSelector((state) => state.modals.rangeOutConfirmation);
  const currentTab = useSelector((state) => state.message.currentTab);
  const apGroups = useRangeOutList({ parameters, serviceValues });

  const handleClick = useCallback(() => {
    window.open(linkUrl, messageId);
  }, [linkUrl, messageId]);

  useEffect(() => {
    if (isOpen) {
      const isIndividual = currentTab === TAB_INDIVIDUAL_NOTICE;
      dispatch(markAsReadRequest({ messageId, isIndividual, channel: CHANNEL_BROWSER }));
    }
  }, [dispatch, currentTab, messageId, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      size={MODAL_SIZES.WIDTH_640}
      title="レンジアウトをした自動売買グループがあります"
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>注文の状況はホーム画面のポートフォリオ内にて確認できます。</div>
          <div className={styles.card}>
            <ul className={styles.list}>
              {apGroups.map(({ id, name }) => (
                <li key={id}>
                  <div className={styles.name}>{name}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {linkUrl && (
          <div className={styles.footer}>
            ※レンジアウトについては
            <div className={styles.link} onClick={handleClick} role="button" aria-hidden>
              こちら
            </div>
            をご確認ください
          </div>
        )}
      </div>
    </Modal>
  );
});

RangeOutConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
