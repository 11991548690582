import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumberToDisplayedString } from 'shared-modules/services';
import { useSummaryInfo } from 'shared-modules/services/hooks';
import classNames from 'classnames';
import styles from './headerInfo.module.scss';
import { mockSummaryInfoNoApGroup } from '../../../../constants/tutorial/otherMockData';

const HeaderInfo = () => {
  const actualSummaryInfo = useSummaryInfo();

  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);
  const modeServiceId = useSelector(({ auth }) => auth.serviceId);
  // tutorialモード時にmockを使用する
  const summaryInfo = tutorialMode ? mockSummaryInfoNoApGroup[modeServiceId] : actualSummaryInfo;

  const {
    receivedMargin,
    positionRequiredMargin,
    orderingRequiredMargin,
    unsettledPl,
    effectiveMargin,
    effectiveMarginRate,
    orderableMargin,
  } = summaryInfo;

  const [showPairs, setShowPairs] = useState(false);

  const pairList = [
    { id: 1, label: '証拠金預託額', value: receivedMargin },
    { id: 2, label: '評価損益', value: unsettledPl },
    { id: 3, label: '発注証拠金', value: orderingRequiredMargin },
    { id: 4, label: '必要証拠金', value: positionRequiredMargin },
    { id: 5, label: '有効証拠金額', value: effectiveMargin },
    { id: 6, label: '有効比率', value: effectiveMarginRate },
    { id: 7, label: '発注可能額', value: orderableMargin },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title}>口座情報</div>
        <div className={styles.currency}>{modeServiceId}</div>
        <div className={styles.pairsWrapper}>
          {pairList
            .filter((pair) => pair.id === 5 || pair.id === 6)
            .map((pair) => (
              <div key={pair.id} className={styles.pair} style={{ gridArea: `pair-${pair.id}` }}>
                <div>{pair.label}</div>
                <div className={styles.pairValue}>
                  {formatNumberToDisplayedString({ value: pair.value, withoutPlus: true })}
                </div>
              </div>
            ))}
        </div>
        <span
          className={classNames('material-icons-outlined', styles.dropDownIcon)}
          onClick={() => setShowPairs(!showPairs)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShowPairs(!showPairs);
            }
          }}
          role="button"
          tabIndex={0}
        >
          arrow_drop_down
        </span>
      </div>
      {showPairs && (
        <div className={styles.modal}>
          <div className={styles.modalTitle}>口座情報</div>
          {pairList.map((pair) => (
            <div key={pair.id} className={styles.pair}>
              <div>{pair.label}</div>
              <div className={styles.pairValue}>
                {formatNumberToDisplayedString({ value: pair.value, withoutPlus: true })}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default memo(HeaderInfo);
