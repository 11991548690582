import { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import styles from './riskReturnIcon.module.scss';

export const RiskReturnIcon = memo(({ value }) => {
  if (value >= 3) {
    return <Icon name="check" wrapperClass={styles.excellentWrapper} />;
  }
  if (value >= 2) {
    return <Icon name="check" wrapperClass={styles.veryGoodWrapper} />;
  }
  if (value >= 1) {
    return <Icon name="check" wrapperClass={styles.goodWrapper} iconClass={styles.goodIcon} />;
  }
  return <Icon name="priority_high" wrapperClass={styles.badWrapper} />;
});

RiskReturnIcon.propTypes = {
  value: PropTypes.number,
};

RiskReturnIcon.defaultProps = {
  value: undefined,
};
