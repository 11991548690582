import React, { memo, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as LightweightCharts from 'lightweight-charts';
import _, { values } from 'lodash';
import PropTypes from 'prop-types';

import { toHalfwidthKana } from 'japanese-string-utils';
import { getChartData } from 'shared-modules/api/chartApi';
import {
  BUY_SELL_MAIN,
  CHART_MAKE_ETF_NOT_YEN_PAIR_MULTIPLIER,
  CHART_MAKE_CFD_NOT_YEN_PAIR_MULTIPLIER,
  CHART_RESOLUTION_MAIN,
  COUNTRY_TYPE,
  ETF,
  FX,
  CFD,
  SPREAD_NOT_YEN_PAIR_MULTIPLIER,
  SPREAD_YEN_PAIR_MULTIPLIER,
} from 'shared-modules/constants';
import { BUILDER_EXCHANGE_TYPES } from 'shared-modules/constants/builder';
import { useBuilderPricePrecision } from 'shared-modules/services/hooks/builder';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import { store } from '../../../redux/store';
import { getBuilderChartDataSuccess } from '../../../redux/actions';

let historyPoints = [];
let drawingPoints = [];
let invisiblePoints = [];

export const rightBoundAreaSeriesOptions = {
  lastValueVisible: false,
  topColor: 'rgba(189, 195, 199, 1)',
  bottomColor: 'rgba(189, 195, 199, 1)',
  lineColor: 'rgba(189, 195, 199, 1)',
  priceLineVisible: false,
  crosshairMarkerVisible: false,
  priceScaleId: 'right',
  scaleMargins: {
    top: 0,
    bottom: 0,
  },
};

export const shadowAreaSeriesOptions = {
  lastValueVisible: false,
  priceLineVisible: false,
  crosshairMarkerVisible: false,
  topColor: 'rgba(55, 55, 55, 0.5)',
  bottomColor: 'rgba(55, 55, 55, 0.5)',
  lineColor: 'rgba(55, 55, 55, 0.5)',
  lineWidth: 2,
  priceScaleId: 'right',
  scaleMargins: {
    top: 0,
    bottom: 0,
  },
};

export function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export function formatDateWithoutDash(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('');
}

export function toYYYYmmDD(date) {
  if (date.day) {
    const { day, month, year } = date;
    return toYYYYmmDD(formatDate(new Date(year, month - 1, day)));
  }

  if (typeof date === 'string') return Number(date.replace(/-/g, ''));

  let temp = date;
  if (typeof date === 'number') {
    const [firstPart] = String(date).split('.');

    if (firstPart.length === 10) temp = Number(firstPart) * 1000;
  }

  return Number(formatDateWithoutDash(temp));
}

export const dateEq = (time, dest) => {
  const { year, day, month } = time;
  const formattedDateStr = year ? toYYYYmmDD(new Date(year, month - 1, day)) : toYYYYmmDD(new Date(time * 1000));
  return formattedDateStr === toYYYYmmDD(dest);
};

export function toUTCTimestamp(time) {
  if (time.day) {
    const { day, month, year } = time;
    return new Date(year, month - 1, day).getTime() / 1000;
  }

  if (!Number.isNaN(time)) {
    return time;
  }

  return new Date(time).getTime() / 1000;
}

export const getPrice = (time, points, chart) => {
  const start = chart.timeScale().timeToCoordinate(points[0].time);
  const inputTime = chart.timeScale().timeToCoordinate(time);

  if (start > inputTime) return points[0].value;

  for (let i = 0; i < points.length; i += 1) {
    const x = chart.timeScale().timeToCoordinate(points[i].time);

    if (toYYYYmmDD(points[i].time) === toYYYYmmDD(time)) return points[i].value;

    if (x > inputTime) {
      const k =
        (points[i].value * 100000 - points[i - 1].value * 100000) /
        (x - chart.timeScale().timeToCoordinate(points[i - 1].time));
      const b = points[i].value * 100000 - k * x;

      return (k * inputTime + b) / 100000;
    }
  }
  return undefined;
};

export function addDays(date, days) {
  const dat = new Date(date.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
}

export function isWeekend(date) {
  const dayOfWeek = date.getDay();

  return dayOfWeek === 6 || dayOfWeek === 0;
}

export function getDates(startDate, stopDate) {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    if (!isWeekend(currentDate)) dateArray.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}

export function containsPoint(arr, point) {
  return (
    arr.findIndex(
      (p) => toYYYYmmDD(p.time) === toYYYYmmDD(point.time) && Math.abs(p.value - point.value) < 0.00000001,
    ) !== -1
  );
}

export function containsTime(arr, point) {
  return arr.findIndex((p) => toYYYYmmDD(p.time) === toYYYYmmDD(point.time)) !== -1;
}

export function containsAllPoints(expanded, original) {
  return original.forEach((point) => containsPoint(expanded, point));
}

export function getChartSettingPriceRange(serviceId, instrumentId, lastEodPrice, chartMakeSetting) {
  const { rangePips, maxRate, minRate } = chartMakeSetting;
  let multiplier;
  // TODO CFD追加対応。ETF用に定数が分かれていたため一応CFD用の分岐を作る
  switch (serviceId) {
    case FX:
      multiplier = instrumentId.endsWith(COUNTRY_TYPE.JPY)
        ? SPREAD_YEN_PAIR_MULTIPLIER
        : SPREAD_NOT_YEN_PAIR_MULTIPLIER;
      break;
    case ETF:
      multiplier = instrumentId.endsWith(COUNTRY_TYPE.JPY) ? 1 : CHART_MAKE_ETF_NOT_YEN_PAIR_MULTIPLIER;
      break;
    case CFD:
      multiplier = instrumentId.endsWith(COUNTRY_TYPE.JPY) ? 1 : CHART_MAKE_CFD_NOT_YEN_PAIR_MULTIPLIER;
      break;
    default:
      break;
  }

  const settingMaxPrice = lastEodPrice + (rangePips * maxRate) / multiplier;
  let minRangePips = (rangePips * minRate) / multiplier;
  if (serviceId !== FX) {
    minRangePips /= 2;
  }

  const settingMinPrice = lastEodPrice - minRangePips;
  return {
    settingMaxPrice: settingMaxPrice < 0 ? 0 : settingMaxPrice,
    settingMinPrice: settingMinPrice < 0 ? 0 : settingMinPrice,
  };
}

export const createPointer = () => {
  const pointer = document.createElement('div');
  pointer.id = 'circlePointer';
  pointer.className = 'circle-pointer';
  return pointer;
};

const createLightChart = (
  startTime,
  endTime,
  instrumentId,
  onChange,
  serviceId,
  precision,
  onDrawingEnd,
  chartMakeSetting,
  initialDrawingPoints,
) => {
  const currentDate = new Date();
  const historyPrices = historyPoints.map((point) => point.value);

  const prevDayEndPrice = historyPoints[historyPoints.length - 1].value;
  const { settingMaxPrice, settingMinPrice } = getChartSettingPriceRange(
    serviceId,
    instrumentId,
    prevDayEndPrice,
    chartMakeSetting,
  );
  const maxPrice = Math.max(...historyPrices, settingMaxPrice);
  const minPrice = Math.min(...historyPrices, settingMinPrice);
  const marginVerticalRatioCalc = (maxPrice / minPrice) * 0.002;
  const marginVerticalRatio = Number.isFinite(marginVerticalRatioCalc) ? marginVerticalRatioCalc : 0;

  const dateArray = getDates(currentDate, addDays(currentDate, 395));
  drawingPoints =
    initialDrawingPoints && initialDrawingPoints.length
      ? initialDrawingPoints
      : [historyPoints[historyPoints.length - 1]];
  invisiblePoints = dateArray.map((date) => ({ time: formatDate(date) }));

  const rightTimeBound = formatDate(addDays(currentDate, 365));
  const chartElement = document.getElementById('chart');
  let offsetX = 57;
  let offsetY = 57;

  const pointer = createPointer();

  if (!chartElement) return null;
  const rect = chartElement.getBoundingClientRect();
  offsetX = rect.left;
  offsetY = rect.top;

  const bottomBarHeight = 60;
  const chartWidth = window.innerWidth - offsetX;
  const chartHeight = window.innerHeight - offsetY - bottomBarHeight;
  const barSpacing = 3.3;

  const chart = LightweightCharts.createChart(chartElement, {
    width: chartWidth,
    height: chartHeight,
    crosshair: {
      mode: LightweightCharts.CrosshairMode.Normal,
    },
    layout: {
      backgroundColor: '#171717',
      textColor: 'rgba(255, 255, 255, 0.8)',
    },
    grid: {
      vertLines: {
        color: 'rgba(255, 255, 255, 0.2)',
      },
      horzLines: {
        color: 'rgba(255, 255, 255, 0.2)',
      },
    },
    rightPriceScale: {
      borderColor: 'rgba(255, 255, 255, 0.8)',
      scaleMargins: {
        top: 0,
        bottom: 0,
      },
    },
    timeScale: {
      borderColor: 'rgba(255, 255, 255, 0.8)',
      barSpacing,
    },
    autoScale: true,
    localization: {
      locale: 'ja-JP',
      dateFormat: 'yyyy-MM-dd',
      priceFormatter: (price) => price.toFixed(precision),
    },
    handleScroll: {
      mouseWheel: false,
      pressedMouseMove: false,
      horzTouchDrag: false,
      vertTouchDrag: false,
    },
    handleScale: {
      axisPressedMouseMove: false,
      mouseWheel: false,
      pinch: false,
    },
  });

  const historicalBarsLineSeries = chart.addLineSeries({
    color: '#4094E8',
    priceScaleId: 'right',
    scaleMargins: {
      top: 0,
      bottom: 0,
    },
    priceLineStyle: LightweightCharts.LineStyle.Solid,
  });

  historicalBarsLineSeries.setData(historyPoints);

  const invisibleLineSeries = chart.addLineSeries({
    visible: false,
    priceScaleId: 'right',
    scaleMargins: {
      top: 0,
      bottom: 0,
    },
  });

  invisibleLineSeries.setData(invisiblePoints);

  const mainLineSeries = chart.addLineSeries({
    color: '#fff',
    priceScaleId: 'right',
    scaleMargins: {
      top: 0,
      bottom: 0,
    },
    lineWidth: 2,
  });
  mainLineSeries.setData(drawingPoints);

  const shadowAreaSeries = chart.addAreaSeries(shadowAreaSeriesOptions);
  const upperBound = maxPrice * (1 + marginVerticalRatio);

  shadowAreaSeries.setData([
    { time: formatDate(startTime), value: upperBound },
    { time: formatDate(endTime), value: upperBound },
  ]);

  const rightBoundAreaSeries = chart.addAreaSeries({
    ...rightBoundAreaSeriesOptions,
    autoscaleInfoProvider: () => ({
      priceRange: {
        maxValue: upperBound,
        minValue: minPrice * (1 - marginVerticalRatio),
      },
    }),
  });

  const rightBoundPoint = { time: rightTimeBound, value: upperBound };
  rightBoundAreaSeries.setData([rightBoundPoint]);
  const lastDay = toYYYYmmDD(rightTimeBound);

  const canvas = document.getElementsByTagName('canvas')[1];

  canvas.addEventListener(
    'mousedown',
    function mousedown(mousedownEvent) {
      this.down = true;
      this.X = mousedownEvent.pageX;
      this.Y = mousedownEvent.pageY;
    },
    0,
  );
  canvas.addEventListener(
    'mouseup',
    function mouseup() {
      this.down = false;
      onDrawingEnd(
        drawingPoints.length >= 2 ? dateEq(drawingPoints[drawingPoints.length - 1].time, rightTimeBound) : false,
      );
    },
    0,
  );
  canvas.addEventListener(
    'mouseout',
    function mouseout() {
      this.down = false;
      onDrawingEnd(
        drawingPoints.length >= 2 ? dateEq(drawingPoints[drawingPoints.length - 1].time, rightTimeBound) : false,
      );
    },
    0,
  );
  canvas.addEventListener(
    'mousemove',
    function mousemove(mousemoveEvent) {
      this.style.cursor = 'pointer';
      if (this.down) {
        const x = mousemoveEvent.offsetX;
        const y = mousemoveEvent.offsetY;

        try {
          const time = chart.timeScale().coordinateToTime(x);
          const value = mainLineSeries.coordinateToPrice(y);

          const point = {
            time,
            value,
          };
          const { year, day, month } = point.time;
          const timestamp = year ? toYYYYmmDD(new Date(year, month - 1, day)) : toYYYYmmDD(new Date(point.time * 1000));

          if (timestamp > lastDay) {
            if (drawingPoints.length < 2 || containsTime(drawingPoints, rightBoundPoint)) return;

            const lastPoint = drawingPoints[drawingPoints.length - 1];

            const k =
              (value * 100000 - lastPoint.value * 100000) /
              (new Date(year, month - 1, day).getTime() -
                new Date(lastPoint.time.year, lastPoint.time.month - 1, lastPoint.time.day).getTime());
            const b =
              value * 100000 -
              k *
                (new Date(year, month - 1, day).getTime() -
                  new Date(
                    rightBoundPoint.time.year,
                    rightBoundPoint.time.month - 1,
                    rightBoundPoint.time.day,
                  ).getTime());

            const price = (lastPoint.value + b) / 100000;
            point.time = rightTimeBound;
            point.value = price;
          }
          if (containsTime(drawingPoints, point) || Number.isNaN(point.value)) return;
          if (point.value < minPrice) point.value = minPrice;
          if (point.value > maxPrice) point.value = maxPrice;
          mainLineSeries.update(point);
          drawingPoints.push(point);
          this.X = x;
          this.Y = y;
          pointer.style.left = `${chart.timeScale().timeToCoordinate(point.time) - 16}px`;
          pointer.style.top = `${mainLineSeries.priceToCoordinate(point.value) - 16}px`;

          // onChange callback
          onChange(drawingPoints);
        } catch (e) {
          // Logger.error(e);
        }
      }
    },
    0,
  );

  const {
    settings: { instrumentList },
  } = store.getState();
  const isFX = serviceId === FX;
  const currencyName = isFX ? removeSuffix(instrumentId) : toHalfwidthKana(instrumentList[instrumentId]?.shortName);

  const legend = document.createElement('div');
  legend.className = 'three-line-legend';
  legend.style.display = 'block';
  legend.style.left = `3px`;
  legend.style.top = `3px`;
  // eslint-disable-next-line
  legend.innerHTML = `<div style="font-size: 16px;margin: 4px 0px;color: #fff;width: 200px">${currencyName}(ASK)・1日</div>`;

  mainLineSeries.resetPointer = () => {
    const [lastPoint] = drawingPoints.length > 0 ? drawingPoints.slice(-1) : historyPoints.slice(-1);

    const startingX = chart.timeScale().timeToCoordinate(lastPoint.time);
    const startingY = historicalBarsLineSeries.priceToCoordinate(lastPoint.value);
    pointer.style.left = `${startingX - 16}px`;
    pointer.style.top = `${startingY - 16}px`;
  };
  chart
    .timeScale()
    .setVisibleRange({ from: historyPoints[0].time, to: invisiblePoints[invisiblePoints.length - 1].time });
  const innerContainer = chartElement.querySelectorAll('div > table > tr > td')[1];
  innerContainer.appendChild(pointer);
  innerContainer.appendChild(legend);

  mainLineSeries.chart = chart;
  setTimeout(mainLineSeries.resetPointer, 200);

  return mainLineSeries;
};

const LightSketchboard = forwardRef(
  (
    { askBid, serviceId, instrumentId, resolution, startTime, endTime, onChange, onDrawingEnd, initialDrawingPoints },
    ref,
  ) => {
    const lineSeries = useRef();
    const { pricePrecision } = useBuilderPricePrecision();
    const dispatch = useDispatch();
    const {
      settings: {
        [serviceId]: { chartMakeSettings },
      },
    } = store.getState();

    const resetBoard = useCallback(() => {
      if (!lineSeries.current) return;
      drawingPoints.length = 0;
      drawingPoints.push(historyPoints[historyPoints.length - 1]);
      lineSeries.current.setData(drawingPoints);
      setTimeout(lineSeries.current.resetPointer, 200);
      onChange([]);
    }, [onChange]);

    const drawingDataCompletion = useCallback(
      () =>
        invisiblePoints
          .map((x) => ({ time: x.time, value: getPrice(x.time, drawingPoints, lineSeries.current.chart) }))
          .filter((p) => p.value !== undefined),
      [],
    );

    const initSketchboard = useCallback(async () => {
      if (lineSeries.current) return;
      lineSeries.current = true;
      const { data } = await getChartData({
        instrumentId,
        resolution,
        askBid,
        startTime,
        endTime,
        isMobile: false,
        serviceId,
      });

      dispatch(getBuilderChartDataSuccess({ chartData: data }));

      historyPoints = _.uniqBy(
        data.map((bar) => ({ time: formatDate(new Date(bar.startTime)), value: bar.close })),
        'time',
      );
      if (moment(endTime).isSame(historyPoints[historyPoints.length - 1].time, 'day'))
        historyPoints = historyPoints.slice(0, -1);

      const chartMakeSetting = chartMakeSettings.find((s) => s.instrumentId === instrumentId);
      lineSeries.current = createLightChart(
        startTime,
        endTime,
        instrumentId,
        onChange,
        serviceId,
        pricePrecision,
        onDrawingEnd,
        chartMakeSetting,
        initialDrawingPoints,
      );
    }, [
      chartMakeSettings,
      dispatch,
      askBid,
      serviceId,
      instrumentId,
      resolution,
      startTime,
      endTime,
      onChange,
      pricePrecision,
      onDrawingEnd,
      initialDrawingPoints,
    ]);

    useImperativeHandle(ref, () => ({
      resetBoard,
      drawingDataCompletion,
    }));

    useEffect(() => {
      initSketchboard();

      let timeout;
      const handleResize = () => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
          const chartElement = document.getElementById('chart');
          const rect = chartElement ? chartElement.getBoundingClientRect() : { left: 56, top: 57 };
          const offsetX = rect.left;
          const offsetY = rect.top;
          const bottomBarHeight = 60;
          const numberOfBars = historyPoints.length + invisiblePoints.length;
          const chartWidth = window.innerWidth - offsetX;
          const chartHeight = window.innerHeight - offsetY - bottomBarHeight;
          const priceScaleWidth = 56;
          const barSpacing = (chartWidth - priceScaleWidth) / numberOfBars;
          lineSeries.current.chart.applyOptions({
            width: chartWidth,
            height: chartHeight,
            timeScale: {
              borderColor: 'rgba(255, 255, 255, 0.8)',
              barSpacing,
            },
          });
          setTimeout(lineSeries.current.resetPointer, 200);
        }, 200);
      };
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, [initSketchboard]);

    return (
      <>
        <div
          style={{
            height: 'calc(100vh - 196px)',
            backgroundColor: 'rgba(54, 54, 54, 1)',
            width: '100%',
          }}
          id="chart"
        />
      </>
    );
  },
);

LightSketchboard.propTypes = {
  instrumentId: PropTypes.string.isRequired,
  askBid: PropTypes.oneOf([BUY_SELL_MAIN.SELL.CHART_ID, BUY_SELL_MAIN.BUY.CHART_ID]).isRequired,
  serviceId: PropTypes.oneOf([
    BUILDER_EXCHANGE_TYPES.FX.NAME,
    BUILDER_EXCHANGE_TYPES.ETF.NAME,
    BUILDER_EXCHANGE_TYPES.CFD.NAME,
  ]).isRequired,
  resolution: PropTypes.oneOf(values(CHART_RESOLUTION_MAIN).map((o) => o.ID)).isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onDrawingEnd: PropTypes.func.isRequired,
  initialDrawingPoints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

LightSketchboard.defaultProps = {
  onChange: () => {},
};

export default memo(LightSketchboard);
