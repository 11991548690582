/* eslint-disable-next-line import/no-unresolved */
import { useEffect, useRef, useState } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { makeAccountInfo } from '../utils';

export const useAccountInfo = () => {
  const prevAccountInfoRef = useRef({});
  const maintenanceState = useSelector((state) => state.settings.maintenanceInfo);
  const accountState = useSelector((state) => state.settings.accountInfo);
  const loginValues = useSelector((state) => state.auth.loginValues);
  const [accountInfo, setAccountInfo] = useState(makeAccountInfo({ maintenanceState, accountState, loginValues }));

  useEffect(() => {
    const newAccountInfo = makeAccountInfo({ maintenanceState, accountState, loginValues });
    if (!isEqual(newAccountInfo, prevAccountInfoRef.current)) {
      prevAccountInfoRef.current = newAccountInfo;
      setAccountInfo(newAccountInfo);
    }
  }, [maintenanceState, accountState, loginValues]);

  return accountInfo;
};
