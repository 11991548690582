import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useValueToColor } from '../../hooks';
import { PriceText } from './PriceText';

export const ColoredPriceText = memo(({ value, className, symbolClassName, withoutSymbol, allowZero }) => {
  const { getNumericalValueColor } = useValueToColor();
  const style = useMemo(() => {
    return { color: getNumericalValueColor(value) };
  }, [value, getNumericalValueColor]);
  return (
    <PriceText
      style={style}
      className={className}
      symbolClassName={symbolClassName}
      value={value}
      withoutSymbol={withoutSymbol}
      allowZero={allowZero}
    />
  );
});

ColoredPriceText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  symbolClassName: PropTypes.string,
  withoutSymbol: PropTypes.bool,
  allowZero: PropTypes.bool,
};

ColoredPriceText.defaultProps = {
  value: undefined,
  className: undefined,
  symbolClassName: undefined,
  withoutSymbol: false,
  allowZero: false,
};
