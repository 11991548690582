import _ from 'lodash';
import moment from 'moment';
import { AP_SUFFIX, M_SUFFIX } from '../constants';

const getChartData = (items) => {
  let sumByDate = {};

  items
    .filter(({ pl, swapPl }) => pl != null || swapPl !== 0)
    .forEach((item) => {
      if (!(item.date in sumByDate)) sumByDate = { ...sumByDate, [item.date]: item.pl + item.swapPl };
      else sumByDate[item.date] += item.pl + item.swapPl;
    });

  const formatted = Object.entries(sumByDate).map(([time, value]) => ({ time, value }));

  return [...formatted];
};

const changeInstrumentId = (instrumentId, instrumentList) => {
  if (instrumentId?.includes(M_SUFFIX)) {
    const modifiedInstrumentId = instrumentId.replace(M_SUFFIX, AP_SUFFIX);
    const originItem = instrumentList?.[instrumentId];
    const modifiedItem = instrumentList?.[modifiedInstrumentId];
    if (
      originItem &&
      modifiedItem &&
      originItem?.serviceId === modifiedItem?.serviceId &&
      originItem?.symbol === modifiedItem?.symbol
    ) {
      return modifiedInstrumentId;
    }
    return instrumentId;
  }
  return instrumentId;
};

const getInstrumentsPl = (items, { doSummaryInstrumentId = false, instrumentList }) => {
  let result = {};
  const orders = Object.keys(instrumentList); // APIのresponseがsortNo順となっている想定
  const order = (id) => orders.indexOf(id);

  items.forEach((item) => {
    const { pl = 0, swapPl = 0, openExecutionCount = 0, closeExecutionCount = 0 } = item;
    const key =
      doSummaryInstrumentId && instrumentList
        ? changeInstrumentId(item.instrumentId, instrumentList)
        : item.instrumentId;
    if (!(key in result)) result = { ...result, [key]: { pl, swapPl, openExecutionCount, closeExecutionCount } };
    else {
      const prev = result[key];
      result[key] = {
        pl: prev.pl + pl,
        swapPl: prev.swapPl + swapPl,
        openExecutionCount: prev.openExecutionCount + openExecutionCount,
        closeExecutionCount: prev.closeExecutionCount + closeExecutionCount,
      };
    }
  });

  return Object.entries(result)
    .map(([instrumentId, summary]) => ({ instrumentId, ...summary }))
    .sort(({ instrumentId: a }, { instrumentId: b }) => order(a) - order(b));
};

const getTotalPl = (items) => {
  const initialValue = 0;
  return items.reduce((accumulator, currentItem) => accumulator + currentItem.pl, initialValue);
};

const getTotalSwapPl = (items) => {
  const initialValue = 0;
  return items.reduce((accumulator, currentItem) => accumulator + (currentItem.swapPl ?? 0), initialValue);
};

const getNumberOfEntries = (items) => {
  const initialValue = 0;
  return items.reduce((accumulator, currentItem) => accumulator + currentItem.openExecutionCount, initialValue);
};

const getNumberOfExits = (items) => {
  const initialValue = 0;
  return items.reduce((accumulator, currentItem) => accumulator + currentItem.closeExecutionCount, initialValue);
};

const current = moment();
export const today = current.toDate();
export const defaultFromDate = moment().subtract(1, 'year').add(1, 'day').toDate();

const currentMonth = Number(current.format('M'));

const monthOptions = _.range(12)
  .map((n) => {
    let year = current.year();
    let month = currentMonth - n;

    if (month < 1) {
      month += 12;
      year -= 1;
    }

    const monthStr = month > 9 ? String(month) : `0${month}`;

    return { label: `${year}年${month}月`, value: `${year}-${monthStr}-` };
  })
  .reverse();

export const isSameDate = (currentFrontDate, dateStr) => {
  // compare currentFrontDate(YYYYMMDD) vs 'dateStr(YYYY-MM-DD)
  return currentFrontDate && dateStr && currentFrontDate === dateStr.replaceAll('-', '');
};

const profitLossUtils = {
  getChartData,
  getInstrumentsPl,
  getNumberOfEntries,
  getNumberOfExits,
  getTotalPl,
  getTotalSwapPl,
  monthOptions,
};

export default profitLossUtils;
