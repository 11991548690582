import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { getAllLabsRequest, getMyLabsRequestLoadingSelector } from 'shared-modules/redux/labs';
import { Spin } from '../../../../components';
import { Welcome } from './Welcome';
import { Strategies } from './Strategies';
import styles from './buildersSharePage.module.scss';

export const BuildersSharePage = memo(() => {
  const dispatch = useDispatch();
  const loading = useSelector(getMyLabsRequestLoadingSelector);
  const myLabs = useSelector((state) => state.labs.myLabs);

  useEffect(() => {
    dispatch(getAllLabsRequest());
  }, [dispatch]);

  if (loading) {
    return (
      <div className={styles.container}>
        <Spin />
      </div>
    );
  }
  if (ALL_SERVICES.some((serviceId) => !_.isEmpty(myLabs[serviceId]))) {
    return <Strategies />;
  }
  return <Welcome />;
});
