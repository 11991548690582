import React, { memo, useMemo, useCallback, useRef, useState } from 'react';
import SelectComponent from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import styleConstants from 'shared-modules/styles/_constants.scss';
import { Option as BaseOption, ValueContainer as BaseValueContainer, DropdownIndicator } from './components';
import styles from './customSelect.module.scss';
import { CLEAR, DEFAULT_COLORS, LIGHTER_COLORS } from './constants';

// todo: change name, we have CustomSelect and Selector
const CustomSelect = ({
  instanceId,
  placeholder,
  options,
  isFullWidth,
  isClearable,
  className,
  isSearchable,
  selectItemId,
  onChange,
  height,
  maxHeight,
  isDisabled,
  hasError: hasErrorFlag,
  errorMessage,
  isLighter,
  isDarker,
  width,
  disabledValues,
  withErrorTooltip,
  dataCustomSelector,
  renderBeforeValue,
  renderBeforeOption,
  menuMinWidth,
  menuPlacement,
}) => {
  const id = useRef(uuid()).current;
  const [menuIsOpen, setMenuStatus] = useState(false);
  const widthValue = useMemo(() => (isFullWidth ? '100%' : width), [width, isFullWidth]);
  const optionsWithClear = useMemo(
    () => (isClearable ? [{ label: CLEAR, value: '', isClearOption: true }, ...options] : options),
    [isClearable, options],
  );
  const onOptionChange = useCallback(
    (option) => {
      onChange(option.label === CLEAR ? null : option.value);
    },
    [onChange],
  );

  const displayedValue = useMemo(
    () => options?.find((option) => option?.value === selectItemId) || null,
    [selectItemId, options],
  );

  const setMenuIsOpened = useCallback(() => setMenuStatus(true), []);
  const setMenuIsClosed = useCallback(() => setMenuStatus(false), []);

  const Option = useCallback(
    (props) => (
      <BaseOption
        renderBeforeOption={renderBeforeOption}
        disabledValues={disabledValues}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    ),
    [renderBeforeOption, disabledValues],
  );

  const ValueContainer = useCallback(
    (props) => (
      <BaseValueContainer
        renderBeforeValue={renderBeforeValue}
        dataCustomSelector={dataCustomSelector}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    ),
    [renderBeforeValue, dataCustomSelector],
  );

  const components = useMemo(() => ({ DropdownIndicator, Option, ValueContainer }), [Option, ValueContainer]);

  const hasError = Boolean(hasErrorFlag || errorMessage);

  const dynamicStyles = useMemo(() => {
    const { mainThemeColor, mainThemeHoverColor } = isLighter ? LIGHTER_COLORS : DEFAULT_COLORS;
    const inputColor = isDarker ? '#313131' : mainThemeColor;

    return {
      indicatorSeparator: () => {},
      container: (baseContainer) => ({
        ...baseContainer,
        fontSize: 13,
      }),
      control: (baseControl, stateControl) => ({
        ...baseControl,
        height,
        width: widthValue,
        backgroundColor: stateControl.isFocused ? mainThemeHoverColor : inputColor,
        borderRadius: 6,
        borderBottomLeftRadius: stateControl.menuIsOpen && 0,
        borderBottomRightRadius: stateControl.menuIsOpen && 0,
        border: hasError ? `2px solid ${styleConstants.WEB_INPUT_ERROR}` : '0 none',
        boxShadow: 'none',
        cursor: 'pointer',
        opacity: isDisabled && 0.5,
        '&:hover': {
          backgroundColor: mainThemeHoverColor,
        },
      }),
      menu: (baseMenu) => ({
        ...baseMenu,
        backgroundColor: mainThemeColor,
        borderRadius: 0,
        margin: 0,
        width: widthValue,
        zIndex: 100,
        minWidth: menuMinWidth,
      }),
      menuList: (baseMenuList) => ({
        ...baseMenuList,
        padding: 0,
        MsOverflowStyle: 'none',
        scrollbarWidth: 'none',
        maxHeight,
      }),
      option: (baseOption, optionState) => ({
        ...baseOption,
        color: optionState.label === CLEAR ? styleConstants.WEB_WARM_GREY : styleConstants.WEB_WHITE,
        boxShadow: `inset 0 -1px 0 0 ${styleConstants.WEB_SHADOW_LABEL_COLOR}`,
        backgroundColor: optionState.isFocused && mainThemeHoverColor,
        cursor: optionState.isDisabled ? 'default' : 'pointer',
        '&:active': {
          backgroundColor: !optionState.isDisabled && mainThemeHoverColor,
        },
        opacity: optionState.isDisabled && 0.5,
        display: 'flex',
        alignItems: 'center',
      }),
      singleValue: (singleValueBase) => ({
        ...singleValueBase,
        color: styleConstants.WEB_WHITE,
      }),
      placeholder: (placeholderBase) => ({
        ...placeholderBase,
        color: styleConstants.WEB_WHITE,
      }),
      valueContainer: (baseValueContainer) => ({
        ...baseValueContainer,
        padding: '2px 12px',
      }),
    };
  }, [isLighter, isDarker, height, widthValue, hasError, isDisabled, menuMinWidth, maxHeight]);

  return (
    <>
      <div
        className={classNames({ [styles.isFullWidth]: isFullWidth })}
        data-for={id}
        data-tip={hasErrorFlag && errorMessage}
      >
        <SelectComponent
          instanceId={instanceId}
          inputId={instanceId}
          className={classNames(styles.wrapper, className)}
          components={components}
          styles={dynamicStyles}
          options={optionsWithClear}
          placeholder={placeholder}
          isSearchable={isSearchable}
          value={displayedValue}
          onChange={onOptionChange}
          isDisabled={isDisabled}
          menuPlacement={menuPlacement}
          blurInputOnSelect
          onMenuOpen={setMenuIsOpened}
          onMenuClose={setMenuIsClosed}
        />
        {!withErrorTooltip && hasError && <div className={styles.errorMessage}>{errorMessage}</div>}
      </div>
      {withErrorTooltip && !menuIsOpen && hasError && (
        <ReactTooltip
          id={id}
          place="bottom"
          type="dark"
          effect="solid"
          multiline
          data-html
          insecure
          className={styles.tooltip}
        />
      )}
    </>
  );
};

CustomSelect.propTypes = {
  instanceId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  renderBeforeOption: PropTypes.func,
  renderBeforeValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      isDisabled: PropTypes.bool,
    }),
  ),
  menuMinWidth: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  className: PropTypes.string,
  selectItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLighter: PropTypes.bool,
  isDarker: PropTypes.bool,
  width: PropTypes.number,
  disabledValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  withErrorTooltip: PropTypes.bool,
  dataCustomSelector: PropTypes.string,
  menuPlacement: PropTypes.string,
};

CustomSelect.defaultProps = {
  instanceId: '', // Prefix for option items e.g. {your-id}-value https://react-select.com/props#option -> instanceId
  placeholder: '',
  options: [],
  isFullWidth: false,
  isClearable: false,
  isSearchable: false,
  className: '',
  selectItemId: null,
  height: 40,
  maxHeight: 300,
  isDisabled: false,
  hasError: false,
  errorMessage: '',
  isLighter: false,
  isDarker: false,
  width: 160,
  menuMinWidth: 'unset',
  disabledValues: [],
  withErrorTooltip: false,
  dataCustomSelector: '',
  renderBeforeOption: null,
  renderBeforeValue: null,
  menuPlacement: 'auto',
};

export default memo(CustomSelect);
