import React, { memo, useCallback, useRef, forwardRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import {
  BUTTON_COLORS_AVAILABLE_VALUES,
  BUTTON_ROLES_AVAILABLE_VALUES,
  BUTTON_WIDTH_AVAILABLE_VALUES,
  COLORS,
  WIDTH,
} from 'shared-modules/constants';
import styles from './customButton.module.scss';

const COLOR_STYLES = {
  [COLORS.LIGHT_GREY]: styles.isLightGrey,
  [COLORS.GREEN]: styles.isGreen,
  [COLORS.WHITE]: styles.isWhite,
  [COLORS.BLUE]: styles.isBlue,
  [COLORS.DARK_SKY_BLUE]: styles.isDarkSkyBlue,
  [COLORS.TRANSPARENT]: styles.isTransparent,
  [COLORS.RED]: styles.isRed,
};

function tryToFocus(innerRef) {
  if (innerRef.current?.focus) {
    innerRef.current.focus();
    return true;
  }
  return false;
}

const ButtonWithTooltip = memo(({ tooltip, children, width, className }) => {
  const tooltipId = useRef(uuid()).current;

  return (
    <>
      <div data-for={tooltipId} data-tip={tooltip} style={{ width }} className={className}>
        {children}
      </div>
      <ReactTooltip id={tooltipId} />
    </>
  );
});

ButtonWithTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.oneOf(BUTTON_WIDTH_AVAILABLE_VALUES), PropTypes.number]),
  className: PropTypes.string,
};

ButtonWithTooltip.defaultProps = {
  width: '',
  className: '',
};

const CustomButton = forwardRef((props, ref) => {
  const {
    children,
    color,
    isSmall,
    isHighlighted,
    className,
    onClick,
    onFocus,
    isDisabled,
    buttonRole,
    width,
    isOutlined,
    isLoading,
    tooltip,
    tabIndex,
    lookDisabledOnly,
    buttonClassName,
    hasFocus,
    focusIndex,
    setFocus,
  } = props;

  const handleClick = useCallback(
    (e) => {
      if ((isDisabled || isLoading) && !lookDisabledOnly) {
        return;
      }
      onClick(e);
      e.currentTarget.blur();
    },
    [onClick, isDisabled, isLoading, lookDisabledOnly],
  );

  const innerRef = useRef(null);

  useEffect(() => {
    if (hasFocus) {
      // Move element into view when it is focused
      const isSuccess = tryToFocus(innerRef);
      if (!isSuccess) return () => tryToFocus(innerRef);
    }
    return undefined;
  }, [hasFocus, focusIndex]);

  useEffect(() => {
    if (focusIndex) setFocus(focusIndex);
  }, [focusIndex, setFocus]);

  const handleFocus = useCallback(
    (event) => {
      onFocus(focusIndex, event);
    },
    [focusIndex, onFocus],
  );

  const renderedButton = (
    <button
      // eslint-disable-next-line react/button-has-type
      type={buttonRole}
      tabIndex={tabIndex}
      ref={ref || innerRef}
      className={classNames(
        styles.button,
        COLOR_STYLES[color],
        {
          [className]: !tooltip,
          [styles.isSmall]: isSmall,
          [styles.isOutlined]: isOutlined,
          [styles.isDisabled]: isDisabled || isLoading,
          [styles.lookDisabledOnly]: lookDisabledOnly,
          [styles.isHighlighted]: isHighlighted,
        },
        buttonClassName,
      )}
      style={tooltip ? { width: WIDTH.PERCENTAGE_100 } : { width }}
      onClick={handleClick}
      onFocus={handleFocus}
      disabled={(isDisabled || isLoading) && !lookDisabledOnly}
    >
      {isLoading && (
        <div
          className={classNames('spinner-border', styles.loader, {
            [styles.isSmall]: isSmall,
            [styles.isBlack]: color === COLORS.WHITE,
          })}
          role="status"
        >
          <span className="sr-only" />
        </div>
      )}
      {color === COLORS.TRANSPARENT ? <div className={styles.transparentChildrenWrapper}>{children}</div> : children}
    </button>
  );

  return tooltip ? (
    <ButtonWithTooltip tooltip={tooltip} width={width} className={className}>
      {renderedButton}
    </ButtonWithTooltip>
  ) : (
    renderedButton
  );
});

CustomButton.propTypes = {
  color: PropTypes.oneOf(BUTTON_COLORS_AVAILABLE_VALUES),
  isSmall: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isDisabled: PropTypes.bool,
  buttonRole: PropTypes.oneOf(BUTTON_ROLES_AVAILABLE_VALUES),
  width: PropTypes.oneOfType([PropTypes.oneOf(BUTTON_WIDTH_AVAILABLE_VALUES), PropTypes.number]),
  isOutlined: PropTypes.bool,
  isLoading: PropTypes.bool,
  buttonClassName: PropTypes.string,
  tooltip: PropTypes.string,
  tabIndex: PropTypes.string,
  lookDisabledOnly: PropTypes.bool,
  hasFocus: PropTypes.bool,
  focusIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setFocus: PropTypes.func,
};

CustomButton.defaultProps = {
  color: '',
  isSmall: false,
  isHighlighted: false,
  className: '',
  onClick: () => {},
  onFocus: () => {},
  children: '',
  isDisabled: false,
  buttonRole: 'button',
  width: '',
  buttonClassName: '',
  isOutlined: false,
  isLoading: false,
  tabIndex: null,
  tooltip: '',
  lookDisabledOnly: false,
  hasFocus: false,
  focusIndex: null,
  setFocus: () => {},
};

export default memo(CustomButton);
