import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useKeyDownHandler } from '../../../../services/hooks';
import { openSearchAutoSelectRuleNameModal } from '../../../../redux/actions';
import styles from './searchButton.module.scss';

export const SearchButton = memo(({ autoSelectData }) => {
  const dispatch = useDispatch();
  const isSearched = autoSelectData.search.get;

  const handleOpen = useCallback(() => {
    dispatch(openSearchAutoSelectRuleNameModal({ autoSelectData }));
  }, [dispatch, autoSelectData]);

  const handleKeyDownOpen = useKeyDownHandler(handleOpen);

  return (
    <>
      <div className={styles.search} role="button" tabIndex={0} onClick={handleOpen} onKeyDown={handleKeyDownOpen}>
        <i
          className={classNames('material-icons-outlined', styles.icon, {
            [styles.filtered]: isSearched,
          })}
        >
          search
        </i>
        <div className={classNames({ [styles.filtered]: isSearched })}>検索</div>
      </div>
    </>
  );
});

SearchButton.propTypes = {
  autoSelectData: PropTypes.shape({
    search: PropTypes.shape({
      get: PropTypes.string.isRequired,
      set: PropTypes.func.isRequired,
      placeholder: PropTypes.string.isRequired,
      modal: PropTypes.shape({ title: PropTypes.string.isRequired, submit: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
  }).isRequired,
};
