import { call, put, select } from 'redux-saga/effects';
import { ORDER_TYPES, SUCCESS_NOTIFICATION_MESSAGE } from '../../../constants/manualTrade';
import { BUY_SELL_MAIN, EXPIRATION_TYPE_MAIN, ORDER_METHOD_MAIN } from '../../../constants';
import { createSingleOrder } from '../../../api/manualTradeApi';
import { createOrderStartLoading, createOrderStopLoading } from '../../actions/manualTradeActions';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { errorHandler } from '../errorSaga';

function* createMarketOrderRequestHandler() {
  try {
    yield put(createOrderStartLoading());
    const serviceId = yield select((state) => state.auth.serviceId);
    const instrumentId = yield select((state) => state.manualTrade.selectedInstrumentId[serviceId]);
    const side = yield select(
      (state) =>
        state.manualTrade.createOrders[ORDER_TYPES.MARKET_ORDER.name][ORDER_TYPES.MARKET_ORDER.inputs.BUY_SELL],
    );

    const quantity = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.MARKET_ORDER.name][ORDER_TYPES.MARKET_ORDER.inputs.COUNT],
    );
    const currentPair = yield select((state) => state.currencies.rates[instrumentId]);
    const price = side === BUY_SELL_MAIN.SELL.ID ? currentPair.bid : currentPair.ask;
    const { x, y } = yield select((state) => state.manualTrade.createOrderClickedEventPosition);
    const requestBody = {
      instrumentId,
      side,
      quantity,
      price,
      type: ORDER_METHOD_MAIN.MARKET.ID,
      expireType: EXPIRATION_TYPE_MAIN.DAY.ID,
      ...(Number.isFinite(x) && Number.isFinite(y) ? { x, y } : {}),
    };
    yield call(createSingleOrder, { requestBody, serviceId });
    yield put(sendNotificationSuccess({ message: SUCCESS_NOTIFICATION_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e, form: ORDER_TYPES.MARKET_ORDER.name });
  } finally {
    yield put(createOrderStopLoading());
  }
}

export default createMarketOrderRequestHandler;
