import {
  CHANGE_ACTIVE_CURRENCY,
  CHANGE_EXCHANGE_TYPE,
  CHANGE_ORDER_TYPE,
  CHANGE_SINGLE_ORDER_OPTION,
  CHANGE_MULTI_ORDER_OPTION,
  CHANGE_TECH_ORDER_OPTION,
  CHANGE_ORDER_EDIT_STATUS,
  CHANGE_SELECTED_TERM_ID,
  CHANGE_SINGLE_ORDER_VALIDATION_ERRORS,
  CHANGE_MULTI_ORDER_VALIDATION_ERRORS,
  RESET_ACTIVE_CURRENCY,
  RESET_EXCHANGE_TYPE,
  RESET_ORDER_TYPE,
  RESET_OPTIONS,
  RESET_SINGLE_ORDER_OPTIONS,
  RESET_MULTI_ORDER_OPTIONS,
  RESET_ORDERS_TABLES,
  RESET_CHART_DATA,
  RESET_SINGLE_ORDER_VALIDATION_ERRORS,
  RESET_MULTI_ORDER_VALIDATION_ERRORS,
  ADD_ORDER_SETTINGS_LIST,
  ADD_ORDER_SETTINGS,
  ADD_LOGIC_GROUP,
  DELETE_ORDER_SETTINGS_BY_ID,
  DELETE_LOGIC_GROUP_BY_ID,
  GET_CHART_DATA_SUCCESS,
  GET_CHART_DATA_REQUEST_START_LOADING,
  GET_CHART_DATA_REQUEST_END_LOADING,
  GET_SIMULATION_DATA_REQUEST_START_LOADING,
  GET_SIMULATION_DATA_REQUEST_END_LOADING,
  GET_SIMULATION_DATA_SUCCESS,
  RESET_SIMULATION_DATA,
  ADD_TO_CART_REQUEST_START_LOADING,
  ADD_TO_CART_REQUEST_END_LOADING,
  ADD_TO_PORTFOLIO_REQUEST_START_LOADING,
  ADD_TO_PORTFOLIO_REQUEST_END_LOADING,
  GET_CLOSE_RATE_DATA_SUCCESS,
  ADD_TECH_LOGIC_GROUP,
  RESET_TECH_ORDER_OPTIONS,
  DELETE_CHART_MAKE_ORDER_SETTINGS_BY_ID,
} from '../actionConstants/builderConstants';
import { BUY_SELL_MAIN, CHART_RESOLUTION_MAIN, INDICATORS_TYPES, OPTIONS_COUNTER_TYPE } from '../../constants';
import {
  BUILDER_ORDER_TYPES,
  ENTRY_PRICE_VALUES,
  BUILDER_ORDER_CONFIGURATION_OPTIONS,
  BUILDER_ORDER_CONFIGURATION_VALIDATED_OPTIONS,
} from '../../constants/builder';

const initialState = {
  exchangeType: null,
  orderType: BUILDER_ORDER_TYPES.MULTI.ID,
  activeCurrency: '',
  singleOrder: {
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.SELECTED_SELL_BUY_ID]: BUY_SELL_MAIN.BUY.ID,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.AMOUNT]: 1,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.ENTRY_PRICE_TYPE_ID]: ENTRY_PRICE_VALUES.DESIGNATION.ID,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.ENTRY_PRICE_VALUE]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.ENTRY_PRICE_PIPS_VALUE]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.OCO_IS_CHECKED]: false,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.OCO_VALUE]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.PROFIT_MARGIN]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.STOP_LOSS_SPREAD_IS_CHECKED]: false,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.STOP_LOSS_SPREAD]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.FOLLOW_VALUE_IS_CHECKED]: false,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.FOLLOW_VALUE]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_VALUE_IS_CHECKED]: true,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_VALUE_TYPE_ID]: OPTIONS_COUNTER_TYPE[0].id,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_VALUE]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_PRICE_VALUE]: 0,
  },
  multiOrder: {
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.SELECTED_SELL_BUY_ID]: BUY_SELL_MAIN.BUY.ID,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.AMOUNT]: 0.1,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_TYPE_ID]: ENTRY_PRICE_VALUES.DESIGNATION.ID,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_VALUE]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_PIPS_VALUE]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.OCO_IS_CHECKED]: false,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.PROFIT_MARGIN]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.FOLLOW_VALUE_IS_CHECKED]: false,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.FOLLOW_VALUE]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_VALUE_IS_CHECKED]: true,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_VALUE]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.RANGE_SPREAD]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ITEMS_COUNT]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.STOP_LOSS_SPREAD_IS_CHECKED]: false,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.STOP_LOSS_SPREAD]: 0,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_IS_FIXED]: true,
  },
  techOrder: {
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.INDICATOR1]: INDICATORS_TYPES.MOVING_AVERAGE.VALUE,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.INDICATOR2]: '',
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.BAR_TYPE]: CHART_RESOLUTION_MAIN.DAYS_1.TV_ID,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.IS_ALL_SETTLEMENT]: true,
    [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.COUNTER_IS_FIXED]: false,
  },
  singleOrderValidationErrors: BUILDER_ORDER_CONFIGURATION_VALIDATED_OPTIONS.SINGLE.reduce((acc, orderOption) => {
    acc[orderOption] = {
      errorMessage: '',
      hasValidationError: false,
    };
    return acc;
  }, {}),
  multiOrderValidationErrors: BUILDER_ORDER_CONFIGURATION_VALIDATED_OPTIONS.MULTI.reduce((acc, orderOption) => {
    acc[orderOption] = {
      errorMessage: '',
      hasValidationError: false,
    };
    return acc;
  }, {}),
  orderSettingsList: [],
  logicGroupsList: [],
  chartDataIsLoading: false,
  chartData: [],
  askCloseRate: 0,
  bidCloseRate: 0,
  simulationDataIsLoading: false,
  simulationData: {},
  allowOrderEdit: true,
  addToCartIsLoading: false,
  addToPortfolioIsLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_ACTIVE_CURRENCY: {
      const { activeCurrency } = payload;
      return {
        ...state,
        activeCurrency,
      };
    }
    case CHANGE_EXCHANGE_TYPE: {
      const { exchangeType } = payload;
      return {
        ...state,
        exchangeType,
      };
    }
    case CHANGE_ORDER_TYPE: {
      const { orderType } = payload;
      return {
        ...state,
        orderType,
      };
    }
    case RESET_ACTIVE_CURRENCY: {
      return {
        ...state,
        activeCurrency: initialState.activeCurrency,
      };
    }
    case RESET_EXCHANGE_TYPE: {
      return {
        ...state,
        exchangeType: initialState.exchangeType,
      };
    }
    case RESET_ORDER_TYPE: {
      return {
        ...state,
        orderType: initialState.orderType,
      };
    }
    case RESET_OPTIONS: {
      return initialState;
    }
    case RESET_SINGLE_ORDER_OPTIONS: {
      return {
        ...state,
        singleOrder: initialState.singleOrder,
      };
    }
    case RESET_MULTI_ORDER_OPTIONS: {
      return {
        ...state,
        multiOrder: initialState.multiOrder,
      };
    }
    case RESET_TECH_ORDER_OPTIONS: {
      return {
        ...state,
        techOrder: initialState.techOrder,
      };
    }
    case RESET_ORDERS_TABLES: {
      return {
        ...state,
        orderSettingsList: [],
        logicGroupsList: [],
      };
    }
    case RESET_SINGLE_ORDER_VALIDATION_ERRORS: {
      return {
        ...state,
        singleOrderValidationErrors: initialState.singleOrderValidationErrors,
      };
    }
    case RESET_MULTI_ORDER_VALIDATION_ERRORS: {
      return {
        ...state,
        multiOrderValidationErrors: initialState.multiOrderValidationErrors,
      };
    }
    case CHANGE_SINGLE_ORDER_OPTION: {
      const { fieldName, value } = payload;

      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          [fieldName]: value,
        },
      };
    }
    case CHANGE_MULTI_ORDER_OPTION: {
      const { fieldName, value } = payload;

      return {
        ...state,
        multiOrder: {
          ...state.multiOrder,
          [fieldName]: value,
        },
      };
    }

    case CHANGE_SELECTED_TERM_ID: {
      const { termId } = payload;
      return {
        ...state,
        selectedTermId: termId,
      };
    }

    case CHANGE_TECH_ORDER_OPTION: {
      const { fieldName, value } = payload;

      return {
        ...state,
        techOrder: {
          ...state.techOrder,
          [fieldName]: value,
        },
      };
    }
    case CHANGE_SINGLE_ORDER_VALIDATION_ERRORS: {
      const { fieldName, errorMessage, hasValidationError } = payload;

      return {
        ...state,
        singleOrderValidationErrors: {
          ...state.singleOrderValidationErrors,
          [fieldName]: { errorMessage, hasValidationError },
        },
      };
    }
    case CHANGE_MULTI_ORDER_VALIDATION_ERRORS: {
      const { fieldName, errorMessage, hasValidationError } = payload;

      return {
        ...state,
        multiOrderValidationErrors: {
          ...state.multiOrderValidationErrors,
          [fieldName]: { errorMessage, hasValidationError },
        },
      };
    }
    case ADD_ORDER_SETTINGS_LIST: {
      const { orderSettings } = payload;

      return {
        ...state,
        orderSettingsList: orderSettings,
      };
    }
    case ADD_ORDER_SETTINGS: {
      const {
        instrumentId,
        realInstrumentId,
        buySell,
        amount,
        entryPriceTypeId,
        entryPricePipsValue,
        entryPrice1,
        entryPrice2,
        profitMargin,
        stopLossRange,
        follow,
        counter,
        counterPrice,
        id,
      } = payload;

      return {
        ...state,
        orderSettingsList: [
          ...state.orderSettingsList,
          {
            instrumentId,
            realInstrumentId,
            buySell,
            amount,
            entryPriceTypeId,
            entryPricePipsValue,
            entryPrice1,
            entryPrice2,
            profitMargin,
            stopLossRange,
            follow,
            counter,
            counterPrice,
            id,
          },
        ],
      };
    }
    case ADD_LOGIC_GROUP: {
      const { orderSettings, logicGroup } = payload;

      return {
        ...state,
        orderSettingsList: [...state.orderSettingsList, ...orderSettings],
        logicGroupsList: [...state.logicGroupsList, logicGroup],
      };
    }
    case ADD_TECH_LOGIC_GROUP: {
      const { orderSettings, logicGroup } = payload;

      return {
        ...state,
        orderSettingsList: [...orderSettings],
        logicGroupsList: [logicGroup],
      };
    }
    case DELETE_ORDER_SETTINGS_BY_ID: {
      const { id } = payload;

      return {
        ...state,
        orderSettingsList: state.orderSettingsList.filter(({ id: orderId }) => orderId !== id),
      };
    }
    case DELETE_CHART_MAKE_ORDER_SETTINGS_BY_ID: {
      const { id: byId } = payload;

      return {
        ...state,
        orderSettingsList: state.orderSettingsList.filter(({ id }) => id !== String(byId)),
      };
    }
    case DELETE_LOGIC_GROUP_BY_ID: {
      const { id } = payload;

      return {
        ...state,
        logicGroupsList: state.logicGroupsList.filter(({ id: logicGroupId }) => logicGroupId !== id),
        orderSettingsList: state.orderSettingsList.filter(({ logicGroupId }) => logicGroupId !== id),
      };
    }
    case GET_CHART_DATA_REQUEST_START_LOADING: {
      return {
        ...state,
        chartDataIsLoading: true,
      };
    }
    case GET_CHART_DATA_REQUEST_END_LOADING: {
      return {
        ...state,
        chartDataIsLoading: false,
      };
    }
    case GET_CHART_DATA_SUCCESS: {
      const { chartData } = payload;
      return {
        ...state,
        chartData,
      };
    }
    case GET_CLOSE_RATE_DATA_SUCCESS: {
      const { askCloseRate, bidCloseRate } = payload;
      return {
        ...state,
        askCloseRate,
        bidCloseRate,
      };
    }
    case RESET_CHART_DATA: {
      return {
        ...state,
        chartData: [],
      };
    }
    case GET_SIMULATION_DATA_REQUEST_START_LOADING: {
      return {
        ...state,
        simulationDataIsLoading: true,
      };
    }
    case GET_SIMULATION_DATA_REQUEST_END_LOADING: {
      return {
        ...state,
        simulationDataIsLoading: false,
      };
    }
    case GET_SIMULATION_DATA_SUCCESS: {
      const { simulationData, termId } = payload;

      return {
        ...state,
        simulationData: {
          ...state.simulationData,
          [termId]: simulationData,
        },
      };
    }
    case RESET_SIMULATION_DATA: {
      return {
        ...state,
        simulationData: {},
      };
    }
    case CHANGE_ORDER_EDIT_STATUS: {
      const { allowOrderEdit } = payload;

      return {
        ...state,
        allowOrderEdit,
      };
    }
    case ADD_TO_CART_REQUEST_START_LOADING: {
      return {
        ...state,
        addToCartIsLoading: true,
      };
    }
    case ADD_TO_CART_REQUEST_END_LOADING: {
      return {
        ...state,
        addToCartIsLoading: false,
      };
    }
    case ADD_TO_PORTFOLIO_REQUEST_START_LOADING: {
      return {
        ...state,
        addToPortfolioIsLoading: true,
      };
    }
    case ADD_TO_PORTFOLIO_REQUEST_END_LOADING: {
      return {
        ...state,
        addToPortfolioIsLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
