import { memo, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { formatPrice } from 'shared-modules/services';
import { isDisplayedNumber } from 'shared-modules/utils';
import styles from './marginText.module.scss';

export const MarginText = memo(({ value, className, symbolClassName }) => {
  const [price, isEmpty] = useMemo(() => {
    if (!isDisplayedNumber(value)) {
      return ['-', true];
    }
    return formatPrice({ value, withoutPlus: true });
  }, [value]);
  return (
    <div className={classNames(styles.text, className)}>
      {price}
      {!isEmpty && <span className={classNames(styles.symbol, symbolClassName)}>円</span>}
    </div>
  );
});

MarginText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  symbolClassName: PropTypes.string,
};

MarginText.defaultProps = {
  value: undefined,
  className: undefined,
  symbolClassName: undefined,
};
