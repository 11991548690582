import { ENABLE_TUTORIAL_MODE, DISABLE_TUTORIAL_MODE } from 'shared-modules/redux/actionConstants/tutorialConstants';
import {
  WEB_TUTORIAL_OPEN_SELECT_PAGE,
  WEB_TUTORIAL_OPEN_STRATEGIES_SUCCESS,
  WEB_TUTORIAL_OPEN_PORTFOLIO,
  WEB_TUTORIAL_OPEN_PORTFOLIO_DETAIL,
  SET_TUTORIAL_CARD_DATA,
  REMOVE_TUTORIAL_CARD_DATA,
  SET_TUTORIAL_TABLES_DATA,
  REMOVE_TUTORIAL_TABLES_DATA,
  SET_PROFIT_LOSS_CHART_DATA,
  REMOVE_PROFIT_LOSS_CHART_DATA,
  WEB_TUTORIAL_OPEN_HOME,
  SET_TUTORIAL_IS_READY_AUTO_SELECT,
} from '../actionConstants/tutorialConstants';

export const tutorialOpenSelectPage = () => ({ type: WEB_TUTORIAL_OPEN_SELECT_PAGE });
export const tutorialOpenHomePage = () => ({ type: WEB_TUTORIAL_OPEN_HOME });
export const tutorialOpenStrategiesSuccess = () => ({ type: WEB_TUTORIAL_OPEN_STRATEGIES_SUCCESS });
export const tutorialOpenPortfolio = () => ({ type: WEB_TUTORIAL_OPEN_PORTFOLIO });
export const tutorialOpenPortfolioDetail = () => ({ type: WEB_TUTORIAL_OPEN_PORTFOLIO_DETAIL });

export const setTutorialCardData = ({ cardData }) => ({ type: SET_TUTORIAL_CARD_DATA, payload: { cardData } });
export const removeTutorialCardData = () => ({ type: REMOVE_TUTORIAL_CARD_DATA });

export const setTutorialTablesData = ({ orderData, positionsData, executionData }) => ({
  type: SET_TUTORIAL_TABLES_DATA,
  payload: { orderData, positionsData, executionData },
});
export const removeTutorialTablesData = () => ({ type: REMOVE_TUTORIAL_TABLES_DATA });

export const setTutorialProfitLossChartData = ({ profitLossChartData }) => ({
  type: SET_PROFIT_LOSS_CHART_DATA,
  payload: { profitLossChartData },
});
export const removeTutorialProfitLossChartData = () => ({ type: REMOVE_PROFIT_LOSS_CHART_DATA });

export const enableTutorialMode = () => ({ type: ENABLE_TUTORIAL_MODE });
export const disableTutorialMode = () => ({ type: DISABLE_TUTORIAL_MODE });

export const setTutorialIsReadyAutoSelect = ({ isReadyAutoSelect }) => ({
  type: SET_TUTORIAL_IS_READY_AUTO_SELECT,
  payload: { isReadyAutoSelect },
});
