import { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './linkButton.module.scss';

export const LinkButton = memo(({ className, disabled, onClick, children, danger, primary }) => {
  const [pressed, setPressed] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);

  const handleIn = useCallback(() => {
    if (disabled) {
      return;
    }
    setMouseDown(true);
  }, [disabled]);

  const handleOut = useCallback(() => {
    if (disabled) {
      return;
    }
    setMouseDown(false);
  }, [disabled]);

  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }
    onClick?.();
    setPressed(true);
  }, [onClick, disabled]);
  const handleKeyDown = useKeyDownHandler(handleClick);

  useEffect(() => {
    if (pressed) {
      setTimeout(() => setPressed(false), 500);
    }
  }, [pressed]);

  return (
    <div
      role="button"
      tabIndex={disabled ? -1 : 0}
      className={classNames(
        styles.container,
        { [styles.danger]: danger },
        { [styles.primary]: primary },
        { [styles.pressed]: pressed || mouseDown, [styles.disabled]: disabled },
      )}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseUp={handleOut}
      onMouseDown={handleIn}
      onMouseOut={handleOut}
      onBlur={handleOut}
    >
      <div className={classNames(styles.wrapper, className)}>{children}</div>
    </div>
  );
});

LinkButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  danger: PropTypes.bool,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

LinkButton.defaultProps = {
  className: undefined,
  disabled: false,
  danger: false,
  primary: false,
  onClick: undefined,
};
