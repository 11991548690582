import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { WIDTH } from 'shared-modules/constants';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import ReactTooltip from 'react-tooltip';
import styles from './inputText.module.scss';

export const InputText = memo(
  ({
    className,
    type,
    label,
    placeholder,
    value,
    width,
    onChange,
    disabled,
    name,
    errorMessages,
    validateFunction,
    isSearch,
    withErrorTooltip,
    onBlurSendRequest,
    inputClassName,
    errorTooltipClassName,
  }) => {
    const [error, changeError] = useState(null);
    const id = useRef(uuid()).current;

    useEffect(() => {
      changeError(errorMessages.find((errorItem) => errorItem.inputName === name));
    }, [errorMessages, changeError, name]);

    const elementWidth = width || WIDTH.PERCENTAGE_100;

    const handleChange = useCallback((event) => onChange(event.target.value), [onChange]);

    const keyDownHandler = useCallback(
      (e) => {
        if (e.key === 'Enter') {
          const validateResult = validateFunction(value);
          if (validateResult) {
            onBlurSendRequest(value);
          }
        }
      },
      [validateFunction, onBlurSendRequest, value],
    );

    const handleSendRequest = useCallback(
      ({ target }) => {
        const validateResult = validateFunction(target.value);
        if (validateResult) {
          onBlurSendRequest(target.value);
        }
      },
      [onBlurSendRequest, validateFunction],
    );

    return (
      <>
        <div className={classNames(styles.wrapper, className)} style={{ width: elementWidth }}>
          {label && <div className={classNames(styles.label, { [styles.disabled]: disabled })}>{label}</div>}
          <input
            title=""
            type={type ?? 'text'}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            className={classNames(
              styles.input,
              {
                [styles.isSearchInput]: isSearch,
                [styles.disabled]: disabled,
                [styles.isError]: error,
              },
              inputClassName,
            )}
            autoComplete="new-password"
            disabled={disabled}
            style={{ width: elementWidth }}
            data-for={id}
            data-tip={error && error.errorMessage}
            onKeyDown={keyDownHandler}
            onBlur={handleSendRequest}
          />
          {isSearch && (
            <i
              className={classNames('material-icons', styles.searchIcon, {
                [styles.disabled]: disabled,
                [styles.withLabel]: label,
              })}
            >
              search
            </i>
          )}
          {error && error.errorMessage && !withErrorTooltip && <div className={styles.error}>{error.errorMessage}</div>}
        </div>
        {withErrorTooltip && error && error.errorMessage && (
          <ReactTooltip
            id={id}
            place="bottom"
            type="dark"
            effect="solid"
            multiline
            data-html
            className={classNames(styles.tooltip, errorTooltipClassName)}
          />
        )}
      </>
    );
  },
);

InputText.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  errorMessages: PropTypes.arrayOf(PropTypes.shape({})),
  validateFunction: PropTypes.func,
  placeholder: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([WIDTH.PERCENTAGE_100, ''])]),
  disabled: PropTypes.bool,
  isSearch: PropTypes.bool,
  withErrorTooltip: PropTypes.bool,
  onBlurSendRequest: PropTypes.func,
  inputClassName: PropTypes.string,
  errorTooltipClassName: PropTypes.string,
};

InputText.defaultProps = {
  className: '',
  type: undefined,
  label: '',
  onChange: () => {},
  name: null,
  errorMessages: [],
  validateFunction: () => {},
  placeholder: '',
  width: '',
  disabled: false,
  isSearch: false,
  withErrorTooltip: false,
  onBlurSendRequest: () => {},
  inputClassName: '',
  errorTooltipClassName: '',
};
