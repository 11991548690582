import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { useDispatch, batch, useSelector } from 'react-redux';
import { TAB_INDIVIDUAL_NOTICE } from 'shared-modules/constants/message';
import { SEQUENTIAL_MODALS } from 'shared-modules/constants/sequentialModals';
import { doneRequestSequentialModal } from 'shared-modules/redux/actions';
import { setCurrentTab, setDefaultOpendMessageId } from 'shared-modules/redux/actions/messageActions';
import { push } from 'shared-modules/redux/actions/routerActions';
import { useKeyPressHandler } from '../../../../services/hooks';
import icon from '../../../../assets/message/mail.png';
import styles from './messageModal.module.scss';
import { Button } from '../../../../components';
import { MESSAGE } from '../../../../constants';

const MessageModal = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { messageId, title } = useSelector((state) => state.message.messageModalInfo);

  const onCloseClick = useCallback(
    (e) => {
      batch(() => {
        closeModal();
        dispatch(doneRequestSequentialModal({ modalId: SEQUENTIAL_MODALS.MessageModal }));
      });
      e.currentTarget.blur();
    },
    [closeModal, dispatch],
  );
  const handlePressClose = useKeyPressHandler(onCloseClick);

  const onClick = useCallback(() => {
    closeModal();
    dispatch(doneRequestSequentialModal({ modalId: SEQUENTIAL_MODALS.MessageModal }));
    dispatch(setCurrentTab({ currentTab: TAB_INDIVIDUAL_NOTICE }));
    dispatch(setDefaultOpendMessageId({ defaultOpenedMessageId: messageId }));
    dispatch(push(`/${MESSAGE}`));
  }, [dispatch, closeModal, messageId]);

  return (
    <Modal
      backdrop="static"
      show={isOpen}
      onHide={closeModal}
      centered
      dialogClassName={styles.wrapper}
      backdropClassName={styles.backdrop}
    >
      <Modal.Header bsPrefix={styles.header}>
        <div className={styles.headerClose}>
          <i
            className={classNames('material-icons', styles.closeIcon)}
            tabIndex={0}
            role="button"
            onClick={onCloseClick}
            onKeyDown={handlePressClose}
          >
            clear
          </i>
        </div>
        <div className={styles.headerImage}>
          <img src={icon} alt="message" />
        </div>
        <div className={styles.headerTitle}>
          <span>{title}</span>
        </div>
      </Modal.Header>
      <Modal.Body bsPrefix={classNames(styles.body)}>
        <div className={styles.bottomControl}>
          <Button className={styles.button} onClick={onClick}>
            詳細を見る
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

MessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(MessageModal);
