import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import { CONFIRM_MESSAGES } from 'shared-modules/constants/select';
import { Button, Modal } from '../../../../components';
import styles from './confirmModal.module.scss';

export const ConfirmModal = memo(({ visible, onClose, onClick }) => {
  const handleClick = useCallback(() => {
    onClick();
    onClose();
  }, [onClick, onClose]);

  return (
    <Modal isOpen={visible} title="確認" closeModal={onClose} size={MODAL_SIZES.WIDTH_512} isOverlap>
      <div className={styles.content}>
        {CONFIRM_MESSAGES.map((line, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className={styles.message}>
            {line}
          </div>
        ))}
        <div className={styles.buttonArea}>
          <Button className={styles.button} onClick={onClose} secondary>
            キャンセル
          </Button>
          <Button className={styles.button} onClick={handleClick}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
});

ConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
