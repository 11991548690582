import { memo } from 'react';
import PropTypes from 'prop-types';
import { Circle } from './Circle';

export const HandleCircle = memo(({ className, position, color, width, circleInfo: { r, cx, cy, circumference } }) => (
  <Circle
    className={className}
    r={r}
    cx={cx}
    cy={cy}
    stroke={color}
    strokeWidth={width}
    strokeDasharray={`0 ${circumference}`}
    strokeDashoffset={-position}
  />
));

HandleCircle.propTypes = {
  className: PropTypes.string,
  position: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  circleInfo: PropTypes.shape({
    r: PropTypes.number.isRequired,
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    circumference: PropTypes.number.isRequired,
  }).isRequired,
};

HandleCircle.defaultProps = {
  className: undefined,
};
