import { get, post, invokeApi, createAdditionalParams } from './index';

export const getCashSession = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `${serviceId}/session_id`, get, additionalParams);
};

export const getBankList = async ({ serviceId, marginGroup }) => {
  const additionalParams = await createAdditionalParams();

  const requestQuery = `?marginGroup=${marginGroup}`;

  return invokeApi({}, `${serviceId}/deposits/bank${requestQuery}`, get, additionalParams);
};

export const getCanDeposit = async ({ serviceId, sessionId }) => {
  const additionalParams = await createAdditionalParams();

  const requestQuery = `?sessionId=${sessionId}`;

  return invokeApi({}, `${serviceId}/deposits/can_deposit${requestQuery}`, get, additionalParams);
};

export const getBankInfo = async ({ serviceId, bankCode, sessionId }) => {
  const additionalParams = await createAdditionalParams();

  const requestQuery = `?sessionId=${sessionId}`;

  return invokeApi({}, `${serviceId}/deposits/bank/${bankCode}${requestQuery}`, get, additionalParams);
};

export const getDepositOnlyBankInfo = async ({ serviceId, sessionId }) => {
  const additionalParams = await createAdditionalParams();

  const requestQuery = `?sessionId=${sessionId}`;

  return invokeApi({}, `${serviceId}/deposits/deposit_bank_info${requestQuery}`, get, additionalParams);
};

export const getRulesAgree = async ({ serviceId, sessionId }) => {
  const additionalParams = await createAdditionalParams();

  const requestQuery = `?sessionId=${sessionId}`;

  return invokeApi({}, `${serviceId}/deposits/disclaimers${requestQuery}`, get, additionalParams);
};

export const postRulesAgree = async ({ serviceId, sessionId, disclaimerCd, readTs }) => {
  const additionalParams = await createAdditionalParams();
  const requestBody = [{ disclaimerCd, readTs }];
  const requestQuery = `?sessionId=${sessionId}`;

  return invokeApi(
    {},
    `${serviceId}/deposits/disclaimers_read_flg${requestQuery}`,
    post,
    additionalParams,
    requestBody,
  );
};
