// eslint-disable-next-line import/no-unresolved
import { useState, useEffect, useMemo, useCallback } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector, useDispatch } from 'react-redux';
import {
  MARGIN_TOOLTIP,
  RECOMMENDED_MARGIN_LABEL,
  REQUIRED_MARGIN_LABEL,
  REQUIRED_MARGIN_TOOLTIP,
} from '../../constants';
import { getSelectionForValidatingAddToCartRequest } from '../../redux/actions/autoSelectActions';
import { calculateMaxItemForCart } from '../index';
import { useTotalMarginForModal } from '.';
import { useCalculatingMarginByStrategyList } from '../../hooks';
import { useSimulationChartDataLab, useSimulationChartDataSelection } from '../../hooks/select';

const INPUT_NAME = 'count';
const MIN_COUNT_VALUE = 1;

const useAutoSelectAddToCartConfirmation = ({ isOpen, data, onClose }) => {
  const { sets, isLaunchInsideDetailModal, callback, selectionId, selectionVersion, isLab } = data;

  const dispatch = useDispatch();

  const [countValue, setCount] = useState(MIN_COUNT_VALUE);
  const [errorMessages, changeErrorMessages] = useState([]);

  useEffect(() => {
    if (isOpen) {
      changeErrorMessages([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !isLaunchInsideDetailModal) {
      dispatch(getSelectionForValidatingAddToCartRequest({ selectionId, selectionVersion }));
    }
  }, [isOpen, dispatch, selectionId, selectionVersion, isLaunchInsideDetailModal]);

  const isDisableConfirmButton = useMemo(() => Boolean(errorMessages.length), [errorMessages]);

  useEffect(() => {
    if (sets) {
      setCount(sets);
    }
  }, [sets, isOpen]);

  const isLoading = useSelector((state) => state.autoSelect.addToCartStrategyInfo.loading);

  // input validation block
  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const termId = useSelector((state) => state.autoSelect.selectedTermId);
  const strategyListFromDetailInfo = useSelector((state) => state.autoSelect.selectionModalInfo[termId]?.strategyList);
  const strategyListFromRequest = useSelector((state) => state.autoSelect.addToCartStrategyInfo?.strategyList);

  const labStrategyList = useSelector((state) => state.labs.publishedLabs.simulation.result.strategyList);

  const selectionSimulationData = useSimulationChartDataSelection(countValue);
  const { simulationData } = useSimulationChartDataLab(countValue);
  const labMarginRecommended = simulationData?.marginRecommended ?? {};
  const { marginRecommended: selectionMarginRecommended } = selectionSimulationData.simulationData ?? {};

  let strategyList;
  let marginRecommended;
  if (isLab) {
    strategyList =
      labStrategyList?.map((strategy) => {
        const { strategySets, ...strategyDetail } = strategy;
        return {
          strategySets,
          strategyDetail,
        };
      }) ?? [];
    marginRecommended = labMarginRecommended;
  } else {
    strategyList = isLaunchInsideDetailModal ? strategyListFromDetailInfo : strategyListFromRequest;
    marginRecommended = selectionMarginRecommended;
  }

  const totalMarginRequired = useCalculatingMarginByStrategyList(strategyList, countValue);

  const totalMarginRecommended = useTotalMarginForModal(isOpen, marginRecommended, countValue);

  const calculatedMaxValue = useMemo(() => {
    if (!isOpen) {
      return null;
    }

    return calculateMaxItemForCart({ strategyList, instrumentList });
  }, [isOpen, strategyList, instrumentList]);

  const validateInput = useCallback(
    (value) => {
      if (MIN_COUNT_VALUE <= value && value <= calculatedMaxValue) {
        changeErrorMessages([]);
      } else {
        changeErrorMessages([
          { inputName: INPUT_NAME, errorMessage: `セット数は1以上${calculatedMaxValue}までご設定ください。` },
        ]);
      }
    },
    [calculatedMaxValue],
  );

  const closeModalAndRefreshState = useCallback(() => {
    onClose();
    setCount(sets ?? MIN_COUNT_VALUE);
  }, [onClose, sets]);

  const onConfirm = useCallback(() => {
    if (errorMessages.length) return;
    closeModalAndRefreshState();

    if (callback) callback(countValue);
  }, [callback, countValue, closeModalAndRefreshState, errorMessages]);

  return useMemo(
    () => ({
      modalTitle: 'カート追加 確認',
      isLoading,
      errorMessages,
      countInput: {
        get: countValue,
        set: setCount,
        label: 'セット数',
        name: INPUT_NAME,
        validateFunction: validateInput,
      },
      totalMargin: {
        get: totalMarginRecommended,
        label: RECOMMENDED_MARGIN_LABEL,
        tooltip: MARGIN_TOOLTIP,
      },
      totalMarginRequired: {
        get: totalMarginRequired,
        label: REQUIRED_MARGIN_LABEL,
        tooltip: REQUIRED_MARGIN_TOOLTIP,
      },
      cancelButton: {
        label: '戻る',
        handler: closeModalAndRefreshState,
      },
      submitButton: {
        label: 'カートに追加',
        isDisabled: isDisableConfirmButton,
        handler: onConfirm,
      },
    }),
    [
      closeModalAndRefreshState,
      countValue,
      errorMessages,
      isDisableConfirmButton,
      isLoading,
      onConfirm,
      totalMarginRecommended,
      totalMarginRequired,
      validateInput,
    ],
  );
};

export default useAutoSelectAddToCartConfirmation;
