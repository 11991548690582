import React, { memo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SSO_LOGIN_ACCOUNT_TYPE } from 'shared-modules/constants/apiConstant';
import { loginUserRequest, replace } from '../../redux/actions';
import { store } from '../../redux/store';
import { Spin } from '../../components';
import styles from './ssoAuthPage.module.scss';
import { HOME } from '../../constants';

const SSOAuthPage = () => {
  const dispatch = useDispatch();
  const parsedQuery = store.getState().router.location.query;
  const successCallback = useCallback(() => {
    // Redirect to root page, AWS authenticatedUser object is already fine now
    dispatch(replace(`/${HOME}`));
  }, [dispatch]);

  const errorCallback = useCallback(() => {
    // Just redirect to root page, login form will show up - todo: show error messsage?
    dispatch(replace(`/${HOME}`));
  }, [dispatch]);

  const processSSOLogin = useCallback(() => {
    const { data, portalId } = parsedQuery;
    if (!data || !portalId) {
      // Only continue when there are enough parameters
      dispatch(replace(`/${HOME}`));
      return;
    }

    dispatch(
      loginUserRequest({
        login: portalId,
        password: data,
        accountType: SSO_LOGIN_ACCOUNT_TYPE,
        successCallback,
        errorCallback,
      }),
    );
  }, [dispatch, parsedQuery, successCallback, errorCallback]);

  useEffect(() => {
    processSSOLogin();
  }, [processSSOLogin]);

  return (
    <div className={styles.wrapper}>
      <Spin className={styles.loader} />
    </div>
  );
};

export default memo(SSOAuthPage);
