import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { QUICK_DEPOSIT_FAILED_URL, QUICK_DEPOSIT_SUCCESS_URL, URL_SSO_DEPOSIT_RT } from 'shared-modules/config';
import { DEPOSIT_TARGET_LIST } from 'shared-modules/constants/cash';
import { getSystemKbnByServiceId } from 'shared-modules/utils';

import { getUserSSOCashflowRequest } from '../../redux/actions';
import { CASH } from '../../constants';
import { Spin } from '../../components';
import styles from './ssoQuickDeposit.module.scss';

const getSystemKbn = (mobileData, serviceId) => {
  if (mobileData?.systemKbn != null) {
    return mobileData.systemKbn;
  }
  return getSystemKbnByServiceId(serviceId);
};

const SSOQuickDeposit = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const { url, token, hash } = useSelector((state) => state.userSSO.userSSOCashflow);
  const [mobileData, setMobileData] = useState(null);
  const portalId = useSelector((state) => state.auth.username);

  const ssoQuickDepositFormRef = useRef(null);

  const { depositSourceId, depositTargetId, paymentAmount } = useSelector((state) => state.cash);

  const serviceId = useMemo(
    () => DEPOSIT_TARGET_LIST.filter((f) => f.id === depositTargetId)[0]?.serviceId,
    [depositTargetId],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search.substr(1));
    const isMobile = urlParams.get('isMobile');

    if (isMobile) {
      setMobileData({
        url: urlParams.get('url'),
        token: urlParams.get('token'),
        hash: urlParams.get('hash'),
        portalId: urlParams.get('portalId').replace('sso_', ''),
        systemKbn: urlParams.get('systemKbn'),
      });
    } else {
      dispatch(
        getUserSSOCashflowRequest({
          serviceId,
          bankCode: depositSourceId,
          amount: paymentAmount,
          redirectKey: URL_SSO_DEPOSIT_RT,
        }),
      );
    }
  }, [dispatch, location.search, depositSourceId, depositTargetId, paymentAmount, serviceId]);

  useEffect(() => {
    if ((url && token && hash) || mobileData) {
      window.history.replaceState(null, '', `${CASH}`);
      ssoQuickDepositFormRef.current.submit();
    }
  }, [url, token, hash, mobileData]);

  return (
    <div className={styles.wrapper}>
      <Spin className={styles.loader} />
      {/* Hidden form for submitting SSO */}
      <form
        id="ssoQuickDeposit"
        action={mobileData?.url ?? url}
        method="get"
        ref={ssoQuickDepositFormRef}
        style={{ visibility: 'hidden' }}
      >
        <input type="hidden" name="data" value={mobileData?.token ?? token} />
        <input type="hidden" name="messageDigest" value={mobileData?.hash ?? hash} />
        <input type="hidden" name="portalId" value={mobileData?.portalId ?? portalId?.replace('sso_', '')} />
        <input type="hidden" name="successUrl" value={QUICK_DEPOSIT_SUCCESS_URL} />
        <input type="hidden" name="failUrl" value={QUICK_DEPOSIT_FAILED_URL} />
        <input type="hidden" name="systemKbn" value={getSystemKbn(mobileData, serviceId)} />
      </form>
    </div>
  );
};

export default memo(SSOQuickDeposit);
