/* eslint-disable */
import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { AP_GROUP_ORDER, BUY_SELL_MAIN } from '../constants';

export const changeBeforePortfolioDataSave = createAction('BULK_CHANGE/CHANGE_BEFORE_PORTFOLIO_DATA_SAVE');
export const saveApData = createAction('BULK_CHANGE/SAVE_AP_DATA');
export const removeApData = createAction('BULK_CHANGE/REMOVE_AP_DATA');
export const multiEditApGroupItemRequest = createAction('BULK_CHANGE/BULK_CHANGE_AP_GROUP_ITEM_REQUEST');
export const multiEditApGroupItemFail = createAction('BULK_CHANGE/BULK_CHANGE_AP_GROUP_ITEM_FAIL');
export const removeFailData = createAction('BULK_CHANGE/REMOVE_ERROR_AP_ID');
export const startLoading = createAction('BULK_CHANGE/START_LOADING');
export const stopLoading = createAction('BULK_CHANGE/STOP_LOADING');
export const initialSelectedTableRows = createAction('BULK_CHANGE/INITIAL_SELECTED_TABLE_ROWS');
export const saveSelectedTableRows = createAction('BULK_CHANGE/SAVE_SELECTED_TABLE_ROWS');
export const checkResetSelectedTableRows = createAction('BULK_CHANGE/CHECK_RESET_SELECTED_TABLE_ROWS');
export const checkAp = createAction('BULK_CHANGE/CHECK_AP');
export const unselectAll = createAction('BULK_CHANGE/UNSELECT_ALL');
export const selectAll = createAction('BULK_CHANGE/SELECT_ALL');
export const switchSelectedTableRows = createAction('BULK_CHANGE/SWITCH_EXISTING_CHECKBOX_STATUS');
export const clearExistingCheckboxStatus = createAction('BULK_CHANGE/CLEAR_EXISTING_CHECKBOX_STATUS');
export const initialTableHasErrorArray = createAction('BULK_CHANGE/INITIAL_TABLE_HAS_ERROR_ARRAY');
export const updateTableHasErrorArray = createAction('BULK_CHANGE/UPDATE_TABLE_HAS_ERROR_ARRAY');
export const toggleEditMode = createAction('BULK_CHANGE/TOGGLE_EDIT_MODE');
export const clearEditMode = createAction('BULK_CHANGE/CLEAR_EDIT_MODE');

export default createReducer(
  {
    portfolioAutoTradeCardData: {},
    beforeSelectedApGroupData: {},
    apData: {},
    failData: [],
    isLoading: false,
    selectedTableRows: [],
    existingCheckbox: null,
    tableHasErrorArray: [],
    isEditMode: false,
    selectedApIdList: [],
  },
  {
    [changeBeforePortfolioDataSave.type]: (multiEdit, action) => {
      const { cardData, selectedApGroupData } = action.payload;
      multiEdit.portfolioAutoTradeCardData = cardData;
      multiEdit.beforeSelectedApGroupData = selectedApGroupData;
    },
    [saveApData.type]: (multiEdit, action) => {
      const { changeApData, isPatch } = action.payload;
      multiEdit.apData = changeApData;
      multiEdit.isPatch = isPatch;
    },
    [multiEditApGroupItemFail.type]: (multiEdit, action) => {
      const { failData } = action.payload;
      multiEdit.failData = failData;
    },
    [removeFailData.type]: (multiEdit) => {
      multiEdit.failData = [];
    },
    [removeApData.type]: (multiEdit) => {
      multiEdit.apData = {};
    },
    [startLoading.type]: (multiEdit) => {
      multiEdit.isLoading = true;
    },
    [stopLoading.type]: (multiEdit) => {
      multiEdit.isLoading = false;
    },
    [initialSelectedTableRows.type]: (multiEdit, action) => {
      const { rows } = action.payload;
      multiEdit.selectedTableRows = rows;
    },
    [checkResetSelectedTableRows.type]: (multiEdit) => {
      multiEdit.selectedTableRows.map((row) => (row.isChecked = false));
    },
    [saveSelectedTableRows.type]: (multiEdit, action) => {
      const { rowNum } = action.payload;
      const nextCheckStatus = !multiEdit.selectedTableRows[rowNum]?.isChecked;
      if (rowNum === 0) {
        // header check
        // all check change
        multiEdit.selectedTableRows.map((row) => (row.isChecked = nextCheckStatus));
        multiEdit.existingCheckbox = multiEdit.selectedTableRows[1];
      } else {
        // row check
        if (nextCheckStatus) {
          // existingCheckbox(save checked object) when checked
          multiEdit.existingCheckbox = multiEdit.selectedTableRows[rowNum];
        } else {
          // existingCheckbox is null when all unchecked
          let currentCheckedNum = 0;
          multiEdit.selectedTableRows.forEach((row) => {
            if (row.isChecked) currentCheckedNum++;
          });
          if (currentCheckedNum <= 1) multiEdit.existingCheckbox = null;
        }

        // update check
        multiEdit.selectedTableRows[rowNum].isChecked = nextCheckStatus;

        // check header when all body checked
        const checkStatusWithoutHeaderAndCurrent = multiEdit.selectedTableRows.filter(
          (row, index) => index !== 0 && index !== rowNum,
        );
        const isAllStatusChecked = checkStatusWithoutHeaderAndCurrent.every((row) => row.isChecked === true);
        if (isAllStatusChecked) {
          multiEdit.selectedTableRows[0].isChecked = nextCheckStatus;
        }
      }
    },
    [switchSelectedTableRows.type]: (multiEdit, action) => {
      const { rowNum, value } = action.payload;
      const key = multiEdit.selectedTableRows[0].checkKey;
      if (rowNum === 0) {
        // header check
        multiEdit.selectedTableRows.map((row) => (row.isChecked = false));
        multiEdit.selectedTableRows.map((row, index) => {
          if (index !== 0 && row[key] === value) row.isChecked = true;
        });
        multiEdit.existingCheckbox = multiEdit.selectedTableRows.find((row, index) => {
          if (index !== 0) return row[key] === value;
        });
      } else {
        // row check
        if (multiEdit.selectedTableRows[rowNum][key] === value) {
          multiEdit.selectedTableRows.map((row) => (row.isChecked = false));
          multiEdit.selectedTableRows[rowNum].isChecked = true;
          multiEdit.existingCheckbox = multiEdit.selectedTableRows[rowNum];
        }
      }
    },
    [clearExistingCheckboxStatus.type]: (multiEdit) => {
      multiEdit.existingCheckbox = null;
    },
    [initialTableHasErrorArray.type]: (multiEdit, action) => {
      const { tableHasErrorArray } = action.payload;
      multiEdit.tableHasErrorArray = tableHasErrorArray;
    },
    [updateTableHasErrorArray.type]: (multiEdit, action) => {
      const { rowNum, hasError } = action.payload;
      if (multiEdit.tableHasErrorArray[rowNum] == null) {
        multiEdit.tableHasErrorArray[rowNum] = {};
      }
      multiEdit.tableHasErrorArray[rowNum].hasError = hasError;
    },
    [toggleEditMode.type]: (multiEdit, action) => {
      multiEdit.isEditMode = !multiEdit.isEditMode;
    },
    [clearEditMode.type]: (multiEdit, action) => {
      multiEdit.isEditMode = false;
    },
    [checkAp.type]: (multiEdit, action) => {
      const { apId } = action.payload;

      if (multiEdit.selectedApIdList.includes(apId)) {
        const index = multiEdit.selectedApIdList.indexOf(apId);
        multiEdit.selectedApIdList.splice(index, 1);
      } else multiEdit.selectedApIdList.push(apId);
    },
    [unselectAll.type]: (multiEdit, action) => {
      multiEdit.selectedApIdList = [];
    },
    [selectAll.type]: (multiEdit, action) => {
      const { selectedApIdList } = action.payload;
      multiEdit.selectedApIdList = selectedApIdList ?? [];
    },
  },
);

export const selectedRowsSelector = (state) => state.modals.multiEditSelect.selectedRows;
export const selectedApsSelector = createSelector(
  (state) => state.multiEdit.selectedApIdList,
  (state) => state.portfolio.selectedApGroupData.apList,
  (selectedApIdList, apList) => {
    return apList?.filter((ap) => selectedApIdList.includes(ap.id));
  },
);
export const sourceTypeSelector = (state) => state.portfolio.selectedApGroupData.sourceType;
export const isEditModeSelector = (state) => state.multiEdit.isEditMode;
export const selectedApIdListSelector = (state) => state.multiEdit.selectedApIdList;
export const isSellSideSelector = createSelector(
  (state) => state.multiEdit.selectedApIdList,
  (state) => state.portfolio.selectedApGroupData.apList,
  (selectedApIdList, apList) => {
    if (selectedApIdList.length === 0 || !apList) {
      return false;
    }
    return Number(apList.find((ap) => ap.id === selectedApIdList[0])?.side) === BUY_SELL_MAIN.SELL.ID;
  },
);

export const isInactiveOrFirstCloseOrderSelector = createSelector(
  (state) => state.multiEdit.selectedApIdList,
  (state) => state.portfolio.selectedApGroupData.apList,
  (selectedApIdList, apList) => {
    if (selectedApIdList.length === 0 || !apList) return false;

    const row = apList.find((ap) => ap.id === selectedApIdList[0]);

    if (!row) return false;

    return (
      row.status === AP_GROUP_ORDER.ACTIVITY.INACTIVE.ID ||
      (row.orderStatus === AP_GROUP_ORDER.STATUS.CLOSE_ORDER.ID && row.latestNewOrderPrice1 === null)
    );
  },
);

export const multiEditLoadingSelector = (state) => state.multiEdit.isLoading;
