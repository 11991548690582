import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBuilderChartDataRequest } from '../../redux/actions';
import { SplitPane } from '../../components';
import { TechSideMenu, TechChart } from './component';
import { ChartSettings } from '../Builder/components/ConfigurationPage/ChartSettings';
import { TechFooter } from './component/TechFooter';
import styles from './techBuilder.module.scss';

const TechBuilder = () => {
  const dispatch = useDispatch();

  const barType = useSelector((state) => state.builder.techOrder.barType);
  useEffect(() => {
    dispatch(getBuilderChartDataRequest());
  }, [dispatch, barType]);

  return (
    <div className={styles.container}>
      <TechSideMenu />
      <div className={styles.detail}>
        <div className={styles.content}>
          <SplitPane split="horizontal" defaultSize="60%" minSize={200}>
            <TechChart />
            <ChartSettings tableType="tech" />
          </SplitPane>
        </div>
        <div className={styles.footer}>
          <TechFooter />
        </div>
      </div>
    </div>
  );
};

export default memo(TechBuilder);
