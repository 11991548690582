import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEqual } from 'shared-modules/utils';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './itemButton.module.scss';

export const ItemButton = memo(
  ({
    item: { id, value, badge, disabled, visuallyDisabled },
    activeId,
    className,
    disabled: disabledAll,
    onClick,
    onActive,
  }) => {
    const buttonRef = useRef(null);

    const active = useMemo(() => isEqual(activeId, id), [activeId, id]);

    const handleClick = useCallback(() => {
      if (disabled || disabledAll) {
        return;
      }
      if (!active) {
        onClick?.(id);
      }
    }, [active, id, disabled, disabledAll, onClick]);

    const handleKeyDown = useKeyDownHandler(handleClick);

    const computedClassName = useMemo(() => {
      return classNames(
        styles.button,
        { [styles.active]: active, [styles.disabled]: disabled || disabledAll || visuallyDisabled },
        className,
      );
    }, [active, className, disabled, disabledAll, visuallyDisabled]);

    useEffect(() => {
      if (active) {
        const { offsetLeft, clientLeft, clientWidth } = buttonRef.current;
        onActive?.({ offsetLeft, clientLeft, clientWidth });
      }
    }, [active, onActive]);

    return (
      <>
        <button
          ref={buttonRef}
          type="button"
          className={computedClassName}
          disabled={disabled || disabledAll}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          {value}
        </button>
        {!(disabled || visuallyDisabled) && badge && (
          <div className={styles.badgeContainer}>
            <div className={styles.badge}>{badge}</div>
          </div>
        )}
      </>
    );
  },
);

ItemButton.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    value: PropTypes.string,
    badge: PropTypes.string,
    disabled: PropTypes.bool,
    visuallyDisabled: PropTypes.bool,
  }).isRequired,
  activeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onActive: PropTypes.func,
};

ItemButton.defaultProps = {
  activeId: undefined,
  className: undefined,
  disabled: false,
  onClick: undefined,
  onActive: undefined,
};
