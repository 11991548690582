/* eslint-disable */
import { createAction, createReducer } from '@reduxjs/toolkit';

export const setData = createAction('CASH/SET_DATA');
export const resetData = createAction('CASH/RESET_DATA');

export const getCashInitRequest = createAction('CASH/GET_CASH_INIT_REQUEST');

export const getCashSessionRequest = createAction('CASH/GET_CASH_SESSION');
export const getCashSessionRequestSuccess = createAction('CASH/GET_CASH_SESSION_SUCCESS')

export const getBankListRequest = createAction('CASH/GET_BANK_LIST');
export const getBankListRequestSuccess = createAction('CASH/GET_BANK_LIST_SUCCESS');

export const getCanDepositRequest = createAction('CASH/GET_CAN_DEPOSIT');
export const getCanDepositRequestSuccess = createAction('CASH/GET_CAN_DEPOSIT_SUCCESS');

export const getBankInfoRequest = createAction('CASH/GET_BANK_INFO');
export const getBankInfoRequestSuccess = createAction('CASH/GET_BANK_INFO_SUCCESS');

export const getDepositOnlyBankInfoRequest = createAction('CASH/CASH/GET_DEPOSIT_ONLY_BANK_INFO');
export const getDepositOnlyBankInfoRequestSuccess = createAction('CASH/GET_DEPOSIT_ONLY_BANK_INFO_SUCCESS');

export const getCashMenuMarginInfoRequest = createAction('CASH/GET_CASH_MENU_MARGIN_INFO')
export const cashStartLoading = createAction('CASH/CASH_START_LOADING');
export const cashEndLoading = createAction('CASH/CASH_END_LOADING')

export const changeIsMarginRefreshed = createAction('CASH/CHANGE_IS_MARGIN_REFRESHED');

export const getQuickDepositTermsAgreeRequest = createAction('CASH/GET_TERMS_AGREE_REQUEST');
export const postQuickDepositTermsAgreeRequest = createAction('CASH/POST_TERMS_AGREE_REQUEST');
export const getQuickDepositTermsAgreeRequestSuccess = createAction('CASH/GET_TERMS_AGREE_REQUEST_SUCCESS');
export const setQuickDepositTermsReadTime = createAction('CASH/SET_TERMS_READ_TIME')
export const changeQuickDepositTermsAgreed = createAction('CASH/CHANGE_QUICK_DEPOSIT_TERMS_AGREED');

const initialState = {
  sessionId: '',
  isQuickDepositTermsAgreed: true,
  bankList: [],
  isSuccess: false,
  isLoading: false,
  depositOnlyBankInfo: {
    bankName: '',
    bankCd: '',
    branchName: '',
    branchCd: '',
    accountType: '',
    bankAccountNumber: '',
    accountHolderKana: '',
    priorityServiceCd: '',
  },
  disclaimer: {
    linkUrl: '',
    disclaimerCd: 0,
    readTs: '',
  },
  bankInfo: {
    bankName: '',
    branchName: '',
    accountType: '',
    bankAccountNumber: '',
    accountHolderKana: '',
    priorityServiceCd: '',
  },
  bankHash: '',
  depositSourceId: 0,
  depositTargetId: 0,
  paymentAmount: '',
  isMarginRefreshed: true,
}

Object.freeze(initialState);

export default createReducer(initialState, {
  [setData.type]: (cash, action) => {
    const { depositSourceId, depositTargetId, paymentAmount } = action.payload;

    cash.depositSourceId = depositSourceId;
    cash.depositTargetId = depositTargetId;
    cash.paymentAmount = paymentAmount;
  },
  [resetData.type]: cash => {
    cash.depositSourceId = 0;
    cash.depositTargetId = 0;
    cash.paymentAmount = '';
  },
  [getCashSessionRequestSuccess.type]: (cash, action) => {
    const { sessionId } = action.payload;
    cash.sessionId = sessionId;
  },
  [getBankListRequestSuccess.type]: (cash, action) => {
    const { bankList } = action.payload;
    cash.bankList = bankList;
  },
  [getCanDepositRequestSuccess.type]: (cash, action) => {
    const { isSuccess } = action.payload;
    cash.isSuccess = isSuccess;
  },
  [getBankInfoRequestSuccess.type]: (cash, action) => {
    const {
      bankName,
      branchName,
      bankAccKbn: accountType,
      exaccNo: bankAccountNumber,
      exaccName: accountHolderKana,
      priorityServiceCd,
    } = action.payload?.bankInfo;

    cash.bankInfo.bankName = bankName;
    cash.bankInfo.branchName = branchName;
    cash.bankInfo.accountType = accountType;
    cash.bankInfo.bankAccountNumber = bankAccountNumber;
    cash.bankInfo.accountHolderKana = accountHolderKana;
    cash.bankInfo.priorityServiceCd = priorityServiceCd;
  },
  [getDepositOnlyBankInfoRequestSuccess.type]: (cash, action) => {
    const {
      bankName,
      bankCd,
      branchName,
      branchCd,
      bankAccKbn: accountType,
      exaccNo: bankAccountNumber,
      exaccName: accountHolderKana,
      priorityServiceCd,
    } = action.payload.depositOnlyBankInfo;

    cash.depositOnlyBankInfo.bankName = bankName;
    cash.depositOnlyBankInfo.bankCd = bankCd;
    cash.depositOnlyBankInfo.branchName = branchName;
    cash.depositOnlyBankInfo.branchCd = branchCd;
    cash.depositOnlyBankInfo.accountType = accountType;
    cash.depositOnlyBankInfo.bankAccountNumber = bankAccountNumber;
    cash.depositOnlyBankInfo.accountHolderKana = accountHolderKana;
    cash.depositOnlyBankInfo.priorityServiceCd = priorityServiceCd;
  },
  [cashStartLoading.type]: cash => {
    cash.isLoading = true;
  },
  [cashEndLoading.type]: cash => {
    cash.isLoading = false;
  },
  [changeIsMarginRefreshed.type]: (cash, action) => {
    const { isMarginRefreshed } = action.payload;
    cash.isMarginRefreshed = isMarginRefreshed;
  },
  [getQuickDepositTermsAgreeRequestSuccess.type]: (cash, action) => {
    const { linkUrl, disclaimerCd } = action.payload;

    cash.disclaimer.linkUrl = linkUrl;
    cash.disclaimer.disclaimerCd = disclaimerCd;
  },
  [setQuickDepositTermsReadTime.type]: (cash, action) => {
    const { readTs } = action.payload;

    cash.disclaimer.readTs = readTs;
  },
  [changeQuickDepositTermsAgreed.type]: (cash, action) => {
    const { readFlg } = action.payload;

    cash.isQuickDepositTermsAgreed = readFlg;
  },
});
