/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import {
  COUNTRY_TYPE,
  FX,
  NOT_PAIRED_YEN_PIP_MULTIPLIER,
  PAIRED_YEN_PIP_MULTIPLIER,
  TRADE_METHODS,
} from '../../constants';
import { useInstrumentSettings } from './index';
import { useServiceId } from '../../hooks';

export const useMultiEditPipConversion = (currencyPair) => {
  const serviceId = useServiceId(currencyPair);
  return useMemo(() => {
    if (serviceId === FX) {
      return currencyPair && currencyPair.endsWith(COUNTRY_TYPE.JPY)
        ? PAIRED_YEN_PIP_MULTIPLIER
        : NOT_PAIRED_YEN_PIP_MULTIPLIER;
    }
    return 1;
  }, [currencyPair, serviceId]);
};

export const useMultiEditPricePrecision = (instrumentId) => {
  const pipConversion = useMultiEditPipConversion(instrumentId);
  const { pricePrecision: initialPrecision = 0.01 } = useInstrumentSettings(instrumentId, TRADE_METHODS.AP.ID);

  const pricePrecision = useMemo(() => {
    const logResult = Math.log10(initialPrecision);

    return (logResult === 0 ? 0 : -logResult) ?? 0;
  }, [initialPrecision]);
  const pipsPrecision = useMemo(() => {
    const pricePipsConversion = Number(Decimal.div(initialPrecision, pipConversion));
    const logResult = Math.log10(pricePipsConversion);

    return (logResult === 0 ? 0 : -logResult) ?? 0;
  }, [initialPrecision, pipConversion]);

  const initialPipsPrecision = useMemo(() => 10 ** -pipsPrecision, [pipsPrecision]);

  return useMemo(
    () => ({
      pricePrecision,
      pipsPrecision,
      initialPrecision,
      initialPipsPrecision,
    }),
    [pricePrecision, pipsPrecision, initialPrecision, initialPipsPrecision],
  );
};
