import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { FX } from 'shared-modules/constants';
import styles from '../steps.module.scss';

const Step6 = ({ serviceId }) => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <p>
          指定したレンジ幅の中に何本の注文を設定するかを決めます。注文は等間隔に設定されます。
          <br />
          注文間隔{serviceId === FX && '（pips）'}＝レンジ幅÷本数
        </p>
        {serviceId === FX && (
          <p className={styles.subContent}>
            <br />※ pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）
          </p>
        )}
      </div>
    </div>
  );
};

Step6.propTypes = {
  serviceId: PropTypes.string,
};

Step6.defaultProps = {
  serviceId: '',
};

export default memo(Step6);
