import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_INSTRUMENT_ID } from 'shared-modules/constants/manualTrade';
import { BUY_SELL_MAIN, FX } from 'shared-modules/constants';
import Chart from '../../../../components/Chart';
import styles from './portfolioManualTradeDetailChart.module.scss';

const PortfolioManualTradeDetailChart = ({ selectedInstrumentId, buySell, serviceId }) => {
  const chartSide = useMemo(() => {
    return Number(buySell) === BUY_SELL_MAIN.SELL.ID ? BUY_SELL_MAIN.BUY.CHART_ID : BUY_SELL_MAIN.SELL.CHART_ID;
  }, [buySell]);

  return (
    <div className={styles.chart}>
      <Chart
        serviceId={serviceId}
        selectedInstrumentId={selectedInstrumentId}
        selectedSide={chartSide}
        withPositions
        onlyManualPositions={Number(buySell)}
      />
    </div>
  );
};

PortfolioManualTradeDetailChart.propTypes = {
  serviceId: PropTypes.string.isRequired,
  selectedInstrumentId: PropTypes.string,
  buySell: PropTypes.string,
};

PortfolioManualTradeDetailChart.defaultProps = {
  selectedInstrumentId: DEFAULT_INSTRUMENT_ID[FX],
  buySell: String(BUY_SELL_MAIN.SELL.ID),
};

export default memo(PortfolioManualTradeDetailChart);
