import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useCheckOnPositionIsBeingDeleted } from 'shared-modules/services/hooks';
import CustomCheckbox from '../../../CustomCheckbox';

const PositionCheckbox = ({ checked: isChecked, onChange, positionId, isToggleAll, tableData }) => {
  const checkOnPositionIsBeingDeleted = useCheckOnPositionIsBeingDeleted();
  const handleChange = useCallback(
    (checked) => {
      const toggleCheckbox = () => {
        onChange({ target: { checked } });
      };

      if (isToggleAll && !checked) {
        toggleCheckbox();
      } else {
        checkOnPositionIsBeingDeleted({
          positionId,
          successCallback: toggleCheckbox,
          tableData,
          isToggleAll,
        });
      }
    },
    [isToggleAll, positionId, tableData, onChange, checkOnPositionIsBeingDeleted],
  );
  return <CustomCheckbox isChecked={isChecked} onChange={handleChange} />;
};

PositionCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  positionId: PropTypes.string,
  isToggleAll: PropTypes.bool,
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
};

PositionCheckbox.defaultProps = {
  positionId: '',
  isToggleAll: false,
  tableData: [],
};

export default memo(PositionCheckbox);
