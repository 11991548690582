import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { WIDTH, MODAL_SIZES } from 'shared-modules/constants';
import { useChartMakeAddToCartLogic } from 'shared-modules/services/hooks/chartMake';
import Modal from '../../../../components/Modal';
import CustomInput from '../../../../components/CustomInput';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import styles from './chartMakeAddToCart.module.scss';
import { Button, InputNumber } from '../../../../components';

const ChartMakerAddToCart = ({ isOpen, closeModal }) => {
  const addToCartLogic = useChartMakeAddToCartLogic({ isModalOpen: isOpen, closeModal });

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title={addToCartLogic.modalTitle} size={MODAL_SIZES.WIDTH_360}>
      <div className={styles.row}>{addToCartLogic.name.label}</div>
      <CustomInput
        className={styles.row}
        value={addToCartLogic.name.get}
        onChange={addToCartLogic.name.set}
        width={WIDTH.PERCENTAGE_100}
        name={addToCartLogic.name.name}
        errorMessages={addToCartLogic.errorMessages}
        validateFunction={addToCartLogic.name.validateFunction}
        withErrorTooltip
      />

      <div className={styles.row}>
        <div>{addToCartLogic.sets.label}</div>
        <InputNumber
          value={addToCartLogic.sets.get}
          onChange={addToCartLogic.sets.set}
          name={addToCartLogic.sets.name}
          validateFunction={addToCartLogic.sets.validateFunction}
          errorMessages={addToCartLogic.errorMessages}
          onlyIntegerAllowed
          withErrorTooltip
        />
      </div>

      {Boolean(addToCartLogic.fundIndication.get) && (
        <div className={styles.row}>
          <div className={styles.marginLabel}>
            {addToCartLogic.fundIndication.label}
            <CustomTooltip message={addToCartLogic.fundIndication.tooltip} className={styles.infoWrapper}>
              <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
            </CustomTooltip>
          </div>
          <div className={styles.marginValue}>{addToCartLogic.fundIndication.get}</div>
        </div>
      )}

      <div className={styles.row}>
        <div className={styles.marginLabel}>
          {addToCartLogic.requiredMargin.label}
          <CustomTooltip message={addToCartLogic.requiredMargin.tooltip} className={styles.infoWrapper}>
            <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
          </CustomTooltip>
        </div>
        <div className={styles.marginValue}>{addToCartLogic.requiredMargin.get}</div>
      </div>

      <div className="d-flex justify-content-center">
        <Button
          onClick={addToCartLogic.submit.handler}
          className={styles.addButton}
          loading={addToCartLogic.submit.isLoading}
          disabled={addToCartLogic.submit.isDisabled}
        >
          {addToCartLogic.submit.label}
        </Button>
      </div>
    </Modal>
  );
};

ChartMakerAddToCart.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(ChartMakerAddToCart);
