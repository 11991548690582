import { call, put, select } from 'redux-saga/effects';
import { ORDER_TYPES, SUCCESS_NOTIFICATION_MESSAGE } from '../../../constants/manualTrade';
import { BUY_SELL_MAIN, ORDER_METHOD_MAIN } from '../../../constants';
import { createIFOOrder } from '../../../api/manualTradeApi';
import { createOrderStartLoading, createOrderStopLoading } from '../../actions/manualTradeActions';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { errorHandler } from '../errorSaga';
import { createDateString } from '../../../services';

function* creteIFOOrderRequestHandler() {
  try {
    yield put(createOrderStartLoading());
    const serviceId = yield select((state) => state.auth.serviceId);
    const instrumentId = yield select((state) => state.manualTrade.selectedInstrumentId[serviceId]);
    const side = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.BUY_SELL],
    );
    const type = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.ORDER_METHOD],
    );
    const quantity = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.COUNT],
    );
    const price = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.PRICE],
    );
    const expirationType = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.EXPIRATION_TYPE],
    );
    const selectedDate = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SELECTED_DATE],
    );
    const selectedTime = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SELECTED_TIME],
    );
    const settlementLimitPrice = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SETTLEMENT_LIMIT_PRICE],
    );
    const settlementStopPrice = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SETTLEMENT_STOP_PRICE],
    );
    const settlementExpirationType = yield select(
      (state) =>
        state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SETTLEMENT_EXPIRATION_TYPE],
    );
    const settlementSelectedDate = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SETTLEMENT_SELECTED_DATE],
    );
    const settlementSelectedTime = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.IFO.name][ORDER_TYPES.IFO.inputs.SETTLEMENT_SELECTED_TIME],
    );

    const expireTime = createDateString(selectedDate, selectedTime);
    const settlementExpireTime = createDateString(settlementSelectedDate, settlementSelectedTime);

    const requestBody = {
      instrumentId,
      quantity,
      newOrder: {
        side,
        type,
        price,
        expireType: expirationType,
        expireTime,
      },
      closeOrder1: {
        side: side === BUY_SELL_MAIN.SELL.ID ? BUY_SELL_MAIN.BUY.ID : BUY_SELL_MAIN.SELL.ID,
        type: ORDER_METHOD_MAIN.LIMIT.ID,
        price: settlementLimitPrice,
        expireType: settlementExpirationType,
        expireTime: settlementExpireTime,
      },
      closeOrder2: {
        side: side === BUY_SELL_MAIN.SELL.ID ? BUY_SELL_MAIN.BUY.ID : BUY_SELL_MAIN.SELL.ID,
        type: ORDER_METHOD_MAIN.STOP_LIMIT.ID,
        price: settlementStopPrice,
        expireType: settlementExpirationType,
        expireTime: settlementExpireTime,
      },
    };

    yield call(createIFOOrder, { requestBody, serviceId });
    yield put(sendNotificationSuccess({ message: SUCCESS_NOTIFICATION_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e, form: ORDER_TYPES.IFO.name });
  } finally {
    yield put(createOrderStopLoading());
  }
}

export default creteIFOOrderRequestHandler;
