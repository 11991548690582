import React, { memo, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isArray } from 'shared-modules/utils';
import { ScrollableItems } from '../ScrollableItems';
import { ItemButton } from './ItemButton';
import styles from './itemButtons.module.scss';

export const ItemButtons = memo(({ options, activeId, className, noScrollClassName, disabled, onChange }) => {
  const [element, setElement] = useState(null);

  const handleClick = useCallback(
    (id) => {
      onChange?.(id);
    },
    [onChange],
  );

  const handleActive = useCallback((activeElement) => {
    setElement(activeElement);
  }, []);

  const items = useMemo(() => {
    return options.map((item, index) => {
      const isFirst = index === 0;
      const isLast = index === options.length - 1;
      const { id, className: buttonClassName } = item;
      const key = isArray(id) ? `[${id.join(',')}]` : id;
      return (
        <ItemButton
          key={key}
          className={classNames(buttonClassName, {
            [styles.button]: !isFirst && !isLast,
            [styles.first]: isFirst && !isLast,
            [styles.last]: isLast && !isFirst,
          })}
          item={item}
          activeId={activeId}
          disabled={disabled}
          onClick={handleClick}
          onActive={handleActive}
        />
      );
    });
  }, [options, activeId, disabled, handleClick, handleActive]);

  return (
    <ScrollableItems
      activeElement={element}
      items={items}
      containerClassName={className}
      noScrollContainerClassName={noScrollClassName}
    />
  );
});

ItemButtons.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ]),
      value: PropTypes.string,
      badge: PropTypes.string,
      disabled: PropTypes.bool,
      className: PropTypes.string,
    }),
  ).isRequired,
  activeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  className: PropTypes.string,
  noScrollClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

ItemButtons.defaultProps = {
  activeId: 0,
  className: undefined,
  noScrollClassName: undefined,
  disabled: false,
  onChange: undefined,
};
