import {
  CREATE_INSTRUMENTS_OPTIONS,
  CREATE_SELECTION_TERMS_OPTIONS,
  SET_DEFAULT_SELECTION_TERM,
} from '../actionConstants/constantsConstants';

export const createInstrumentsOptions = ({ serviceId, options }) => ({
  type: CREATE_INSTRUMENTS_OPTIONS,
  payload: { serviceId, options },
});

export const createSelectionTermsOptions = ({ termList }) => ({
  type: CREATE_SELECTION_TERMS_OPTIONS,
  payload: { termList },
});

export const setDefaultSelectionTerm = ({ termId }) => ({ type: SET_DEFAULT_SELECTION_TERM, payload: { termId } });
