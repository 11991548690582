import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { BUY_SELL_MAIN } from 'shared-modules/constants';
import { usePricesForBuySell } from 'shared-modules/services/hooks';
import ValueWithPrecision from '../ValueWithPrecision/ValueWithPrecision';

const CurrentPriceCellValue = memo(({ instrumentId, side, value }) => {
  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);
  const { sellPrice, buyPrice } = usePricesForBuySell({ currencyPair: instrumentId });

  let fixedValue = '';
  if (tutorialMode && value) {
    fixedValue = value;
  } else {
    fixedValue = Number(side) === BUY_SELL_MAIN.BUY.ID ? sellPrice : buyPrice;
  }

  return <ValueWithPrecision instrumentId={instrumentId} value={fixedValue} />;
});

CurrentPriceCellValue.propTypes = {
  instrumentId: PropTypes.string,
  side: PropTypes.string,
  value: PropTypes.string,
};

CurrentPriceCellValue.defaultProps = {
  instrumentId: '',
  side: '',
  value: '',
};

export default CurrentPriceCellValue;
