import { call, put, select } from 'redux-saga/effects';
import { ORDER_TYPES, SUCCESS_NOTIFICATION_MESSAGE } from '../../../constants/manualTrade';
import { createFIFOOrder } from '../../../api/manualTradeApi';
import { createOrderStartLoading, createOrderStopLoading } from '../../actions/manualTradeActions';
import { sendNotificationSuccess } from '../../actions/notificationActions';
import { errorHandler } from '../errorSaga';

function* createFIFOOrderRequestHandler() {
  try {
    yield put(createOrderStartLoading());

    const serviceId = yield select((state) => state.auth.serviceId);
    const instrumentId = yield select((state) => state.manualTrade.selectedInstrumentId[serviceId]);
    const side = yield select(
      (state) =>
        state.manualTrade.createOrders[ORDER_TYPES.MARKET_ORDER.name][ORDER_TYPES.MARKET_ORDER.inputs.BUY_SELL],
    );
    const quantity = yield select(
      (state) => state.manualTrade.createOrders[ORDER_TYPES.MARKET_ORDER.name][ORDER_TYPES.MARKET_ORDER.inputs.COUNT],
    );

    const requestBody = {
      instrumentId,
      side,
      quantity,
    };

    yield call(createFIFOOrder, { requestBody, serviceId });
    yield put(sendNotificationSuccess({ message: SUCCESS_NOTIFICATION_MESSAGE }));
  } catch (e) {
    yield call(errorHandler, { error: e, form: ORDER_TYPES.MARKET_ORDER.name });
  } finally {
    yield put(createOrderStopLoading());
  }
}

export default createFIFOOrderRequestHandler;
