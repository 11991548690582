import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BUILDER_CONFIGRATION_FLOW_MAIN_TITLE,
  BUILDER_CONFIGURATION_FLOW_SUB_TITLE,
  BUILDER_PAGES,
  CONFIGURATION_FLOW_TITLE,
} from 'shared-modules/constants/builder';
import { MODAL_SIZES } from 'shared-modules/constants';
import { useServiceName } from 'shared-modules/hooks';
import { Button, Modal } from '../../../../components';
import topImage from '../../../../assets/builder/asset-selection.png';
import infoImagesHelper from '../../../../assets/builderInfo/infoImagesHelper';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import Step7 from './components/Step7';
import Step8 from './components/Step8';
import Step9 from './components/Step9';
import Top from './components/Top';
import styles from './builderConfigurationFlow.module.scss';

const BuilderConfigurationFlow = ({ isOpen, closeModal }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const serviceName = useServiceName(serviceId);

  const title = {
    1: CONFIGURATION_FLOW_TITLE.BUY_SELL,
    2: CONFIGURATION_FLOW_TITLE.PRICE_RANGE,
    3: CONFIGURATION_FLOW_TITLE.RANGE_WIDTH,
    4: CONFIGURATION_FLOW_TITLE.ITEMS_COUNT,
    5: CONFIGURATION_FLOW_TITLE.QUANTITY[serviceId],
    6: CONFIGURATION_FLOW_TITLE.PROFIT_MARGIN,
    7: CONFIGURATION_FLOW_TITLE.STOP_LOSS,
    8: CONFIGURATION_FLOW_TITLE.FOLLOW,
    9: CONFIGURATION_FLOW_TITLE.COUNTER,
  };

  const contents = {
    0: <Top />,
    1: <Step1 />,
    2: <Step2 serviceId={serviceId} />,
    3: <Step3 serviceId={serviceId} />,
    4: <Step4 serviceId={serviceId} />,
    5: <Step5 serviceId={serviceId} />,
    6: <Step6 serviceId={serviceId} />,
    7: <Step7 serviceId={serviceId} />,
    8: <Step8 serviceId={serviceId} />,
    9: <Step9 serviceId={serviceId} />,
  };

  const images = {
    0: topImage,
    1: infoImagesHelper[`multi${serviceName}_buySell.png`],
    2: infoImagesHelper[`multi${serviceName}_entry.png`],
    3: infoImagesHelper[`multi${serviceName}_rangeSpread.png`],
    4: infoImagesHelper[`multi${serviceName}_items_count.png`],
    5: infoImagesHelper[`multi${serviceName}_quantity.png`],
    6: infoImagesHelper[`multi${serviceName}_profitMargin.png`],
    7: infoImagesHelper[`multi${serviceName}_stopLoss.png`],
    8: infoImagesHelper[`multi${serviceName}_follow.png`],
    9: [infoImagesHelper[`multi${serviceName}_counter.png`], infoImagesHelper[`multi${serviceName}_counter_fix.png`]],
  };

  const optional = [7];
  const followOrCounter = [8, 9];

  const [currentPage, changeCurrentPage] = useState(BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE);

  const mainTitle = useMemo(() => {
    if ([1, 2, 3, 4, 5].includes(currentPage)) return BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.ORDER;
    if ([6, 7].includes(currentPage)) return BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.SETTLEMENT;
    if ([8, 9].includes(currentPage)) return BUILDER_CONFIGRATION_FLOW_MAIN_TITLE.REENTRY;
    return '';
  }, [currentPage]);

  const goTop = () => {
    changeCurrentPage(BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE);
  };
  const goBack = useCallback(() => {
    changeCurrentPage(currentPage - 1);
  }, [currentPage]);
  const goNext = useCallback(() => {
    changeCurrentPage(currentPage + 1);
  }, [currentPage]);

  const closePanel = () => {
    closeModal();
  };

  const closeCurrentPage = () =>
    currentPage === BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE ? closeModal() : goTop();

  const onNextFlow = () => {
    if (currentPage === BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_STEP9_PAGE.PAGE) {
      closeModal();
    } else {
      goNext();
    }
  };
  const nextButton = () => {
    switch (currentPage) {
      case BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE:
        return 'はじめる';
      case BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_STEP9_PAGE.PAGE:
        return 'とじる';
      default:
        return '次へ';
    }
  };

  const progress = () => {
    const items = [];
    for (let i = 0; i < 9; i += 1) {
      if (i + 1 === currentPage) {
        items.push(
          <span className={styles.active} key={i}>
            ●
          </span>,
        );
      } else {
        items.push(
          <span className={styles.dot} key={i}>
            ●
          </span>,
        );
      }
    }
    return <p>{items}</p>;
  };

  return (
    <>
      {isOpen && (
        <Modal
          title={currentPage === BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE ? '' : `STEP${currentPage}`}
          isCenteredTitle
          closeModal={closePanel}
          isOpen={isOpen}
          onHide={closeCurrentPage}
          centered
          size={MODAL_SIZES.WIDTH_1024}
          aria-labelledby="contained-modal-title-vcenter"
        >
          {!(currentPage === BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE) && (
            <div className={styles.progress}>{progress()}</div>
          )}
          <div className={styles.modalComponent}>
            <div className={styles.leftComponent}>
              {currentPage === BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE ? (
                <div className={styles.titleArea}>
                  <div className={styles.topTitle}>{BUILDER_CONFIGURATION_FLOW_SUB_TITLE[currentPage]}</div>
                </div>
              ) : (
                <div className={styles.titleArea}>
                  <div className={styles.mainTitle}>■{mainTitle}</div>
                  <div className={styles.subTitle}>{BUILDER_CONFIGURATION_FLOW_SUB_TITLE[currentPage]}</div>
                  <div className={styles.title}>ー{title[currentPage]}ー</div>
                  {optional.includes(currentPage) && <p className={styles.title}>※設定は任意です。</p>}
                  {followOrCounter.includes(currentPage) && (
                    <p className={styles.title}>※STEP8かSTEP9のいずれかの設定が必要です。</p>
                  )}
                </div>
              )}
              <div className={styles.imageArea}>
                {currentPage === BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_STEP9_PAGE.PAGE ? (
                  <div>
                    <img src={images[currentPage][0]} alt={title[currentPage]} className={styles.imgSmall} />
                    <img src={images[currentPage][1]} alt={title[currentPage]} className={styles.imgSmall} />
                  </div>
                ) : (
                  <img src={images[currentPage]} alt={title[currentPage]} className={styles.img} />
                )}
              </div>
              <div className={styles.buttonArea}>
                <div className={styles.button}>
                  {currentPage === BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE ? (
                    <Button onClick={onNextFlow} className={styles.topButton}>
                      {nextButton()}
                    </Button>
                  ) : (
                    <Button onClick={onNextFlow}>{nextButton()}</Button>
                  )}
                </div>
                {!(currentPage === BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_TOP_PAGE.PAGE) && (
                  <div className={styles.button}>
                    <Button onClick={goBack} secondary>
                      {currentPage === BUILDER_PAGES.BUILDERS_CONFIGURATION_FLOW_STEP1_PAGE.PAGE
                        ? '設定の流れに戻る'
                        : `STEP${currentPage - 1}に戻る`}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.rightComponent}>
              <div>
                <div>{contents[currentPage]}</div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

BuilderConfigurationFlow.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(BuilderConfigurationFlow);
