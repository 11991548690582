import { CFD, ETF, FX } from 'shared-modules/constants';
import CurrencyPairItemFX from './CurrencyPairItemFX';
import CurrencyPairItemETF from './CurrencyPairItemETF';
import CurrencyPairItemCFD from './CurrencyPairItemCFD';

export const CurrencyPairItems = {
  [FX]: CurrencyPairItemFX,
  [ETF]: CurrencyPairItemETF,
  [CFD]: CurrencyPairItemCFD,
};
