import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useKeyPressHandler } from '../../services/hooks';
import styles from './iconButton.module.scss';

const IconButton = ({ iconName, onClick, className }) => {
  const handleClick = useCallback(
    (e) => {
      onClick(e);
      e.currentTarget.blur();
    },
    [onClick],
  );
  const handleKeyPress = useKeyPressHandler(handleClick);

  return (
    <i
      className={classNames('material-icons', styles.icon, className)}
      tabIndex={0}
      role="button"
      onClick={handleClick}
      onKeyPress={handleKeyPress}
    >
      {iconName}
    </i>
  );
};
IconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
IconButton.defaultProps = {
  onClick: () => {},
  className: '',
};

export default memo(IconButton);
