import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useAutoSelectAddToCartConfirmation from 'shared-modules/services/hooks/autoSelectAddToCartConfirmation';
import { MODAL_SIZES } from 'shared-modules/constants';
import { Button, InputNumber, Modal, Spin } from '../../../../components';
import { MarginText } from '../../../../components/Texts';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import styles from './autoSelectAddToCartConfirmation.module.scss';

const AutoSelectAddToCartConfirmation = ({ isOpen, closeModal, data }) => {
  const modalData = useAutoSelectAddToCartConfirmation({ isOpen, data, onClose: closeModal });
  return (
    <Modal
      isOpen={isOpen}
      closeModal={modalData.cancelButton.handler}
      size={MODAL_SIZES.WIDTH_360}
      title={modalData.modalTitle}
      isOverlap
    >
      <div className={styles.body}>
        {modalData.isLoading ? (
          <Spin className={styles.loader} />
        ) : (
          <>
            <div className={styles.row}>
              <div className={styles.label}>{modalData.countInput.label}</div>
              <InputNumber
                name={modalData.countInput.name}
                errorMessages={modalData.errorMessages}
                validateFunction={modalData.countInput.validateFunction}
                value={modalData.countInput.get}
                onChange={modalData.countInput.set}
                withErrorTooltip
                onlyIntegerAllowed
              />
            </div>
            <div className={styles.row}>
              <div className={styles.marginLabel}>
                <div className={styles.itemLabel}>{modalData.totalMargin.label}</div>
                <CustomTooltip
                  message={modalData.totalMargin.tooltip}
                  className={styles.infoWrapper}
                  placement="bottom"
                >
                  <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
                </CustomTooltip>
              </div>
              <MarginText className={styles.value} value={modalData.totalMargin.get} />
            </div>
            <div className={styles.row}>
              <div className={styles.marginLabel}>
                <div className={styles.itemLabel}>{modalData.totalMarginRequired.label}</div>
                <CustomTooltip
                  message={modalData.totalMarginRequired.tooltip}
                  className={styles.infoWrapper}
                  placement="bottom"
                >
                  <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
                </CustomTooltip>
              </div>
              <MarginText className={styles.value} value={modalData.totalMarginRequired.get} />
            </div>
            <div className={styles.buttonArea}>
              <Button onClick={modalData.submitButton.handler} disabled={modalData.submitButton.isDisabled}>
                {modalData.submitButton.label}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

AutoSelectAddToCartConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    margin: PropTypes.number.isRequired,
    marginRequired: PropTypes.number.isRequired,
    sets: PropTypes.number.isRequired,
    callback: PropTypes.func,
    isLaunchInsideDetailModal: PropTypes.bool.isRequired,
    selectionId: PropTypes.number,
    selectionVersion: PropTypes.number,
  }).isRequired,
};

export default memo(AutoSelectAddToCartConfirmation);
