import { CFD, ETF, FX } from '.';
import { QUICK_DEPOSIT_INFO_CFD_LINK, QUICK_DEPOSIT_INFO_ETF_LINK, QUICK_DEPOSIT_INFO_FX_LINK } from '../config';

// ServiceIdEnum の value
export const ALL_VALUE = '0';
export const FX_VALUE = '1';
export const ETF_VALUE = '2';
export const CFD_VALUE = '3';

export const SERVICE_ID_TO_VALUE = Object.freeze({
  [FX]: FX_VALUE,
  [ETF]: ETF_VALUE,
  [CFD]: CFD_VALUE,
});

export const SERVICE_VALUE_TO_ID = Object.freeze({
  [FX_VALUE]: FX,
  [ETF_VALUE]: ETF,
  [CFD_VALUE]: CFD,
});

export const ALL = 'すべて';
export const ALL_SERVICES = Object.freeze([FX, CFD, ETF]);

export const ASSET_TYPES = Object.freeze({
  NONE: Object.freeze({ KEY: 'none', NAME: '指定なし' }),
  ...ALL_SERVICES.reduce((acc, curr) => {
    const serviceName = curr.toUpperCase();
    return {
      ...acc,
      [serviceName]: Object.freeze({ KEY: curr, NAME: serviceName }),
    };
  }, {}),
});

export const ASSET_TYPE_OPTIONS = Object.freeze([
  Object.freeze({ label: ASSET_TYPES.NONE.NAME, value: ASSET_TYPES.NONE.KEY }),
  ...ALL_SERVICES.map((service) => {
    const serviceName = service.toUpperCase();
    return Object.freeze({ label: ASSET_TYPES[serviceName].NAME, value: ASSET_TYPES[serviceName].KEY });
  }),
]);

// ++ を利用しているのは Object.keys の length で max no 取得を保証するため
let serviceIota = 0;
export const SERVICE_ORDERS = Object.freeze(
  ALL_SERVICES.reduce(
    (orders, service) => ({
      ...orders,
      [service]: serviceIota++, // eslint-disable-line
    }),
    {},
  ),
);

// server side の MarginGroupEnum
export const MARGIN_GROUPS = Object.freeze({
  FX_PERSONAL: { code: '1', value: 'MARGIN01', serviceId: FX },
  FX_CORPORATE: { code: '2', value: 'MARGIN02', serviceId: FX },
  ETF_PERSONAL: { code: '1', value: 'MARGIN03', serviceId: ETF },
  ETF_CORPORATE: { code: '2', value: 'MARGIN04', serviceId: ETF },
  CFD_PERSONAL: { code: '1', value: 'MARGIN03', serviceId: CFD },
  CFD_CORPORATE: { code: '2', value: 'MARGIN04', serviceId: CFD },
});

export const PERSONAL_MARGIN_GROUP_ID = Object.freeze(
  ALL_SERVICES.reduce((acc, curr) => {
    return { ...acc, [curr]: MARGIN_GROUPS[`${curr.toUpperCase()}_PERSONAL`].value };
  }, {}),
);

export const QUICK_DEPOSIT_INFO_LINKS = Object.freeze({
  [FX]: QUICK_DEPOSIT_INFO_FX_LINK,
  [ETF]: QUICK_DEPOSIT_INFO_ETF_LINK,
  [CFD]: QUICK_DEPOSIT_INFO_CFD_LINK,
});

export const KEY_SYSTEM_RELOADED = 'system.reloaded';

export const SYSTEM_NOTIFICATION_TYPES = Object.freeze({
  MAINTENANCE: 'maintenance',
  LOSS_CUT: 'lossCut',
});
