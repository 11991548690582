import Decimal from 'decimal.js';
import {
  CHART_MAKE_DEFAULT_WIDTH,
  HALF,
  FX,
  CHART_MAKE_CHANGE_TARGET_OPTIONS,
  CHART_MAKE_BUY_SELL_MAIN,
} from '../../../../constants/index';
import { ceil, floor, getSignificantDigits, getBaseQuantity } from '../../constants';
import Logic from './Logic';
import { Range, RANGE_POSITION } from './Range';

const HalfBottomRange = (instrumentId, instrumentSetting, side, min, max, quantity, rangePosition) => {
  const range = Range(instrumentId, instrumentSetting, side, min, max, quantity, rangePosition);
  const digits = getSignificantDigits(FX, instrumentId, instrumentSetting?.pricePrecision);

  return {
    ...range,
    // override
    ajustMin() {
      this.setMin(
        ceil(
          Decimal.sub(this.getMax(), Decimal.mul(Decimal.sub(this.getNumOfOrders(), 1), this.getWidthPrice())),
          digits,
        ),
      );
    },
    validateRangeWidth(rangeWidth, numOfOrders) {
      if (
        (numOfOrders === 1 && ceil(rangeWidth, 1) < CHART_MAKE_DEFAULT_WIDTH) ||
        ceil(Decimal.div(rangeWidth, Decimal.sub(numOfOrders, 1)), 1) < CHART_MAKE_DEFAULT_WIDTH
      ) {
        return {
          isValid: false,
          errorMessage: 'この設定では注文幅が狭くなり過ぎます。レンジ幅を広くするか本数を少なくして下さい。',
        };
      }
      return { isValid: true };
    },
    setNumOfOrdersAjustWidth(inputNumOfOrders) {
      const validateResult = this.validateRangeWidth(this.getRangeWidth(), inputNumOfOrders);
      if (!validateResult.isValid) {
        return validateResult;
      }
      this.setNumOfOrders(inputNumOfOrders);
      this.setWidth(
        this.getNumOfOrders() === 1
          ? this.getRangeWidth()
          : ceil(Decimal.div(this.getRangeWidth(), Decimal.sub(this.getNumOfOrders(), 1)), 1),
      );
      return { isValid: true };
    },
    getMinRangeWidth(inputNumOfOrders) {
      return ceil(Decimal.mul(CHART_MAKE_DEFAULT_WIDTH, Decimal.sub(inputNumOfOrders, 1)), 1);
    },
  };
};

const Half = (instrumentId, inputHigh, inputLow, instrumentSetting) => {
  const digits = getSignificantDigits(FX, instrumentId, instrumentSetting?.pricePrecision);
  const high = ceil(inputHigh, digits);
  const low = ceil(inputLow, digits);
  const logic = Logic(HALF, high, low);
  const sellRange = Range(
    instrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.SELL,
    0,
    0,
    getBaseQuantity(FX, instrumentId),
    RANGE_POSITION.TOP,
  );
  const buyRange = HalfBottomRange(
    instrumentId,
    instrumentSetting,
    CHART_MAKE_BUY_SELL_MAIN.BUY,
    0,
    0,
    getBaseQuantity(FX, instrumentId),
    RANGE_POSITION.ISOLATION_BOTTOM,
  );
  return {
    getNumOfOrders() {
      return sellRange.getNumOfOrders() + buyRange.getNumOfOrders();
    },
    getAllRanges() {
      return [sellRange, buyRange];
    },
    getRecalcResource(settings) {
      let targetRange; // 注文変更ターゲットレンジ
      const upperRanges = []; // ターゲットレンジより上のレンジ
      const lowerRanges = []; // ターゲットレンジより下のレンジ
      let newNumOfOrders; // 合計本数バリデーション用 ターゲットレンジの注文本数
      let otherNumOfOrders; // 合計本数バリデーション用 ターゲットレンジ以外の注文本数
      // ターゲットのレンジ別の処理
      switch (settings.target) {
        case CHART_MAKE_CHANGE_TARGET_OPTIONS[FX][HALF][0].id:
          otherNumOfOrders = buyRange.getNumOfOrders();
          newNumOfOrders = settings.recalc.numOfOrders;
          targetRange = sellRange;
          lowerRanges.push(buyRange);
          break;
        case CHART_MAKE_CHANGE_TARGET_OPTIONS[FX][HALF][1].id:
          otherNumOfOrders = sellRange.getNumOfOrders();
          newNumOfOrders = settings.recalc.numOfOrders;
          targetRange = buyRange;
          upperRanges.push(sellRange);
          break;
        default:
          return null;
      }
      return {
        isValid: true,
        targetRange,
        upperRanges,
        lowerRanges,
        newNumOfOrders,
        otherNumOfOrders,
      };
    },
    backupCurrentSettings() {
      buyRange.backupCurrentSettings();
      sellRange.backupCurrentSettings();
    },
    rollbackSettings() {
      buyRange.rollbackSettings();
      sellRange.rollbackSettings();
    },
    backupDefaultSettings() {
      buyRange.backupDefaultSettings();
      sellRange.backupDefaultSettings();
    },
    calcAndSetRangeSettings(hendou, step) {
      this.calcAndSetWidth(hendou, step);
      this.calcAndSetNumOfOrders();
    },
    calcAndSetWidth(hendou, step) {
      sellRange.setWidth(Math.max(ceil(Decimal.div(hendou, Decimal.sub(4, step)), 1), CHART_MAKE_DEFAULT_WIDTH));
      buyRange.setWidth(sellRange.getWidth());
    },
    calcAndSetNumOfOrders() {
      const numOfOrders =
        floor(
          Decimal.div(
            ceil(Decimal.div(Decimal.sub(this.getHigh(), this.getLow()), sellRange.getWidthPrice()), 0),
            2,
          ).toNumber(),
          0,
        ) + 1 || 1;
      sellRange.setNumOfOrders(numOfOrders);
      buyRange.setNumOfOrders(numOfOrders);
    },
    ajustMaxMin(upperRangeMax) {
      sellRange.setMax(ceil(upperRangeMax, digits));
      sellRange.ajustMin();
      buyRange.setMax(sellRange.getMin());
      buyRange.ajustMin();
    },
    calcAfterFixedWidthProcess() {
      this.ajustMaxMin(this.getHigh());
    },
    createUnsortedAps() {
      const sellRangeAps = sellRange.createAps();
      const buyRangeAps = buyRange.createAps();
      return sellRangeAps.concat(buyRangeAps);
    },
    ...logic,
  };
};

export default Half;
