import {
  GET_REPORTS_LIST_REQUEST_START_LOADING,
  GET_REPORTS_LIST_REQUEST_END_LOADING,
  GET_REPORTS_LIST_SUCCESS,
  CLEAR_REPORTS_LIST,
  GET_REPORT_FILE_REQUEST_START_LOADING,
  GET_REPORT_FILE_REQUEST_END_LOADING,
  GET_REPORT_FILE_SUCCESS,
  CLEAR_REPORT_FILE,
} from '../actionConstants/reportsConstants';

export const initialState = {
  list: [],
  isListLoading: false,
  file: {},
  fileMetaData: {
    loading: false,
    id: null,
    name: '',
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_REPORTS_LIST_SUCCESS: {
      const { reportsList } = payload;
      return {
        ...state,
        list: reportsList,
      };
    }
    case CLEAR_REPORTS_LIST: {
      return {
        ...state,
        list: [],
      };
    }
    case GET_REPORTS_LIST_REQUEST_START_LOADING: {
      return {
        ...state,
        isListLoading: true,
      };
    }
    case GET_REPORTS_LIST_REQUEST_END_LOADING: {
      return {
        ...state,
        isListLoading: false,
      };
    }
    case GET_REPORT_FILE_REQUEST_START_LOADING: {
      const { id, name } = payload;
      return {
        ...state,
        fileMetaData: {
          loading: true,
          id,
          name,
        },
      };
    }
    case GET_REPORT_FILE_REQUEST_END_LOADING: {
      return {
        ...state,
        fileMetaData: {
          ...state.fileMetaData,
          loading: false,
        },
      };
    }
    case GET_REPORT_FILE_SUCCESS: {
      const { fileData } = payload;
      return {
        ...state,
        file: fileData,
      };
    }
    case CLEAR_REPORT_FILE: {
      return {
        ...state,
        file: {},
      };
    }
    default: {
      return state;
    }
  }
};
