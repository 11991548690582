import React, { memo, forwardRef, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { useKeyPressHandler } from '../../services/hooks';
import { Spin } from '../Spin';
import styles from './optionsDropdown.module.scss';

const CustomToggle = forwardRef(({ onClick, isBig, isGrey, isLoading }, ref) => {
  const handleClick = useCallback(
    (e) => {
      if (isLoading) {
        return;
      }
      e.preventDefault();
      onClick(e);
    },
    [onClick, isLoading],
  );
  const handlePress = useKeyPressHandler(handleClick);

  return (
    <>
      {isLoading ? (
        <Spin className={styles.loader} />
      ) : (
        <i
          className={classNames('material-icons', styles.icon, { [styles.isBig]: isBig }, { [styles.isGrey]: isGrey })}
          ref={ref}
          onClick={handleClick}
          tabIndex={0}
          onKeyPress={handlePress}
          role="button"
        >
          more_horiz
        </i>
      )}
    </>
  );
});
CustomToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  isBig: PropTypes.bool.isRequired,
  isGrey: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const OptionsDropdown = ({
  options,
  onClick,
  className,
  isBig,
  isGrey,
  isNonBordered,
  isLoading,
  inTable,
  disabledOptionValue,
  onToggle,
}) => {
  const customId = useRef(uuid());

  const [show, setShow] = useState(false);

  const toggle = useCallback(() => {
    setShow(!show);
    onToggle(!show);
  }, [show, onToggle]);

  const onBlur = useCallback(() => {
    setTimeout(() => {
      setShow(false);
    }, 150);
  }, []);

  return (
    <div className={classNames(styles.wrapper, { [styles.isBig]: isBig }, className)}>
      <Dropdown align="end" drop={inTable ? 'left' : 'down'} autoClose onBlur={onBlur} show={show} onToggle={toggle}>
        <Dropdown.Toggle id={customId} as={CustomToggle} isBig={isBig} isGrey={isGrey} isLoading={isLoading} />
        <Dropdown.Menu
          bsPrefix={classNames('dropdown-menu', styles.itemList, {
            [styles.inTable]: inTable,
            [styles.isNonBordered]: isNonBordered,
          })}
        >
          {options.map((item) => (
            <Dropdown.Item
              onClick={() => onClick(item.value)}
              eventKey={item.value}
              key={item.value}
              bsPrefix={classNames('dropdown-item', styles.item, {
                [styles.isBig]: isBig,
                [styles.isNonBordered]: isNonBordered,
                [styles.isDisabled]: item.value === disabledOptionValue,
              })}
              disabled={item.value === disabledOptionValue}
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
OptionsDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
  isBig: PropTypes.bool,
  isGrey: PropTypes.bool,
  isLoading: PropTypes.bool,
  isNonBordered: PropTypes.bool,
  inTable: PropTypes.bool,
  disabledOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
OptionsDropdown.defaultProps = {
  className: '',
  isBig: false,
  isGrey: false,
  isLoading: false,
  isNonBordered: false,
  inTable: false,
  disabledOptionValue: '',
  onToggle: () => {},
};

export default memo(OptionsDropdown);
