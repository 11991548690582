// eslint-disable-next-line import/no-unresolved
import { useMemo, useCallback } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { formatNumberToDisplayedString, multiplyAndRoundDecimalsIfAllExist } from '../index';
import { useGetInstrumentDisplayName } from '../../hooks';

export const useSelectionsTableData = (termId) => {
  const { [termId]: { strategyList, defaultSets } = {} } = useSelector((state) => state.autoSelect.selectionModalInfo);

  const getInstrumentDisplayName = useGetInstrumentDisplayName();

  const { simulationTradesData, autoTradeSettingsData, orderGroupsData } = useMemo(() => {
    if (!strategyList) return { simulationTradesData: [], autoTradeSettingsData: [], orderGroupsData: [] };

    return strategyList.reduce(
      (data, strategy) => {
        const {
          strategyId,
          strategySets,
          strategyDetail: { simulationTradeList, itemList, name, instrumentId, serviceId },
        } = strategy;

        if (simulationTradeList) {
          data.simulationTradesData.push(
            ...simulationTradeList.map((item) => ({
              ...item,
              strategyId,
              strategySets,
              instrumentDisplayName: getInstrumentDisplayName(item.instrumentId, serviceId),
            })),
          );
        }
        if (itemList) {
          data.autoTradeSettingsData.push(
            ...itemList.map((item) => ({
              ...item,
              strategySets,
              instrumentDisplayName: getInstrumentDisplayName(item.instrumentId, serviceId),
              serviceId,
            })),
          );
        }
        data.orderGroupsData.push({
          strategySets,
          name,
          instrumentId,
          instrumentDisplayName: getInstrumentDisplayName(instrumentId, serviceId),
        });
        return data;
      },
      { simulationTradesData: [], autoTradeSettingsData: [], orderGroupsData: [] },
    );
  }, [strategyList, getInstrumentDisplayName]);

  const getQuantity = useCallback(
    (quantity, strategySets) => multiplyAndRoundDecimalsIfAllExist([quantity, strategySets, defaultSets]),
    [defaultSets],
  );

  const getStrategySets = useCallback(
    (value) => multiplyAndRoundDecimalsIfAllExist([value, defaultSets]),
    [defaultSets],
  );

  const getCommissionOrDividend = useCallback(
    (commissionOrDividend, strategySets) => {
      const value = multiplyAndRoundDecimalsIfAllExist([commissionOrDividend, strategySets, defaultSets]);
      if (value === '-') return '-';
      return formatNumberToDisplayedString({ value, withoutPlus: true, withYenIcon: false });
    },
    [defaultSets],
  );

  const getRealizedProfitLoss = useCallback(
    (realizedPl, strategySets) => multiplyAndRoundDecimalsIfAllExist([realizedPl, strategySets, defaultSets]),
    [defaultSets],
  );

  const getSwapInterest = useCallback(
    (swapInterest, strategySets) => multiplyAndRoundDecimalsIfAllExist([swapInterest, strategySets, defaultSets]),
    [defaultSets],
  );

  return useMemo(
    () => ({
      simulationTradesData,
      autoTradeSettingsData,
      orderGroupsData,
      helpers: {
        getQuantity,
        getStrategySets,
        getCommissionOrDividend,
        getRealizedProfitLoss,
        getSwapInterest,
      },
    }),
    [
      simulationTradesData,
      autoTradeSettingsData,
      orderGroupsData,
      getQuantity,
      getStrategySets,
      getCommissionOrDividend,
      getRealizedProfitLoss,
      getSwapInterest,
    ],
  );
};

export const useLabTableData = (strategyList, defaultSets = 1, service) => {
  const getInstrumentDisplayName = useGetInstrumentDisplayName();

  const { simulationTradesData, autoTradeSettingsData, orderGroupsData } = useMemo(() => {
    if (!strategyList) return { simulationTradesData: [], autoTradeSettingsData: [], orderGroupsData: [] };

    return strategyList.reduce(
      (data, strategy) => {
        const { id, strategySets, simulationTradeList, itemList, name, instrumentId, serviceId } = strategy;

        if (simulationTradeList) {
          data.simulationTradesData.push(
            ...simulationTradeList.map((item) => ({
              ...item,
              id,
              strategySets,
              instrumentDisplayName: getInstrumentDisplayName(item.instrumentId, service),
            })),
          );
        }
        if (itemList) {
          data.autoTradeSettingsData.push(
            ...itemList.map((item) => ({
              ...item,
              strategySets,
              instrumentDisplayName: getInstrumentDisplayName(item.instrumentId, service),
              serviceId,
            })),
          );
        }
        data.orderGroupsData.push({
          strategySets,
          name,
          instrumentId,
          instrumentDisplayName: getInstrumentDisplayName(instrumentId, service),
        });
        return data;
      },
      { simulationTradesData: [], autoTradeSettingsData: [], orderGroupsData: [] },
    );
  }, [strategyList, getInstrumentDisplayName, service]);

  const getQuantity = useCallback(
    (quantity, strategySets) => multiplyAndRoundDecimalsIfAllExist([quantity, strategySets, defaultSets]),
    [defaultSets],
  );

  const getStrategySets = useCallback(
    (value) => multiplyAndRoundDecimalsIfAllExist([value, defaultSets]),
    [defaultSets],
  );

  const getCommissionOrDividend = useCallback(
    (commissionOrDividend, strategySets) => {
      const value = multiplyAndRoundDecimalsIfAllExist([commissionOrDividend, strategySets, defaultSets]);
      if (value === '-') return '-';
      return formatNumberToDisplayedString({ value, withoutPlus: true, withYenIcon: false });
    },
    [defaultSets],
  );

  const getRealizedProfitLoss = useCallback(
    (realizedPl, strategySets) => multiplyAndRoundDecimalsIfAllExist([realizedPl, strategySets, defaultSets]),
    [defaultSets],
  );

  const getSwapInterest = useCallback(
    (swapInterest, strategySets) => multiplyAndRoundDecimalsIfAllExist([swapInterest, strategySets, defaultSets]),
    [defaultSets],
  );

  return useMemo(
    () => ({
      simulationTradesData,
      autoTradeSettingsData,
      orderGroupsData,
      helpers: {
        getQuantity,
        getStrategySets,
        getCommissionOrDividend,
        getRealizedProfitLoss,
        getSwapInterest,
      },
    }),
    [
      simulationTradesData,
      autoTradeSettingsData,
      orderGroupsData,
      getQuantity,
      getStrategySets,
      getCommissionOrDividend,
      getRealizedProfitLoss,
      getSwapInterest,
    ],
  );
};
