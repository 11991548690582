import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FX } from 'shared-modules/constants';
import { useSelectedStrategyGroup } from 'shared-modules/hooks';
import { getMultiServiceQuantityUnit } from 'shared-modules/services';
import { compareServiceId } from 'shared-modules/utils';
import { Table } from '../../../../../components';
import { tutorialOrderTable } from '../../../../../constants/tutorial/classNames';
import { getSettingsTableTemplate } from '../../../../../services/tableTemplate';
import styles from './commonTable.module.scss';

const DEFAULT_SORTING = [{ id: 'name', desc: false }];

const RESIZED = {
  key: 'orderSettingTable',
  default: [90, 110, 60, 70, 70, 70, 70, 70, 70, 70, 70, 100],
};

export const CommonTable = memo(({ data, helpers, loading }) => {
  const [strategyGroup] = useSelectedStrategyGroup();
  const selectedType = strategyGroup?.serviceId;
  const sortedServiceIds = useMemo(
    () => strategyGroup?.serviceIds?.sort(compareServiceId) ?? [],
    [strategyGroup?.serviceIds],
  );
  const isMultiService = sortedServiceIds.length > 1;

  const tableMetaInfo = useMemo(
    () => ({
      loading,
      pageNumber: 1,
      totalPages: 1,
    }),
    [loading],
  );

  const columns = useMemo(
    () =>
      getSettingsTableTemplate(selectedType).map((columnDef) => {
        const newColumnDef = { ...columnDef, disableSortBy: true };

        switch (columnDef.accessor) {
          case 'quantity':
            newColumnDef.Cell = (cellProps) => {
              const { quantity, strategySets } = cellProps.row.original;
              return helpers.getQuantity(quantity, strategySets);
            };
            newColumnDef.Header = `数量(${getMultiServiceQuantityUnit(sortedServiceIds, isMultiService)})`;
            break;
          case 'tp':
            if (selectedType !== FX) {
              newColumnDef.Header = '利確幅';
            }
            break;
          case 'sl':
            if (selectedType !== FX) {
              newColumnDef.Header = '損切幅';
            }
            break;
          default:
            break;
        }
        return newColumnDef;
      }),
    [selectedType, helpers, isMultiService, sortedServiceIds],
  );

  if (data == null || helpers == null) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>注文設定</div>
      <Table
        tableData={data}
        columns={columns}
        defaultSorting={DEFAULT_SORTING}
        resized={RESIZED}
        tableMetaInfo={tableMetaInfo}
        className={tutorialOrderTable}
      />
    </div>
  );
});

CommonTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  helpers: PropTypes.shape({
    getQuantity: PropTypes.func.isRequired,
  }),
  loading: PropTypes.bool,
};

CommonTable.defaultProps = {
  data: undefined,
  helpers: undefined,
  loading: false,
};
