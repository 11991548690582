import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as DownArrow } from '../../../../assets/downArrow.svg';
import { ReactComponent as UpArrow } from '../../../../assets/upArrow.svg';
import { useKeyPressHandler } from '../../../../services/hooks';
import styles from './priceForManual.module.scss';

const ARROW_SIZE = 20;

const PriceForManual = ({ label, formatValue, value, prevValue, isBuy, alignedTo, isDisabled, isGrey, onPress }) => {
  const handleEnterKey = useKeyPressHandler(onPress);

  return (
    <div
      tabIndex={isDisabled ? -1 : 0}
      role="button"
      className={classNames(styles.wrapper, {
        [styles.notActive]: isDisabled,
        [styles.alignRight]: alignedTo === 'right',
        [styles.isBuy]: isBuy,
        [styles.grey]: isGrey,
      })}
      onClick={isDisabled ? null : onPress}
      onKeyPress={isDisabled ? null : handleEnterKey}
    >
      <div className={styles.type}>{label}</div>
      <div className={styles.value}>{formatValue ? formatValue(value) : value}</div>
      {prevValue && value < prevValue && (
        <DownArrow width={ARROW_SIZE} height={ARROW_SIZE} className={styles.arrowIcon} key={value} />
      )}
      {prevValue && value > prevValue && (
        <UpArrow
          width={ARROW_SIZE}
          height={ARROW_SIZE}
          className={classNames(styles.arrowIcon, styles.isRising)}
          key={value}
        />
      )}
    </div>
  );
};

PriceForManual.propTypes = {
  isDisabled: PropTypes.bool,
  isBuy: PropTypes.bool,
  isGrey: PropTypes.bool,
  formatValue: PropTypes.func,
  prevValue: PropTypes.number,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  alignedTo: PropTypes.oneOf(['left', 'right']),
  onPress: PropTypes.func.isRequired,
};

PriceForManual.defaultProps = {
  isDisabled: false,
  isBuy: false,
  isGrey: false,
  prevValue: null,
  alignedTo: 'left',
  formatValue: null,
};

export default PriceForManual;
